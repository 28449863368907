import { useEffect, useState } from "react";
import swal from "sweetalert";
import api from '../../../services/squareOauthApi';
import { Show_Loader } from "../../Loader/showLoader";

const OauthSettings = () => {

    const [isSquareOauthExists, setIsSquareOauthExists] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [markCheckbox, setMarkCheckbox] = useState(false);
    useEffect(()=> {
        (async()=>{
            const response = await api.verifySquareOauthTokenExists();
            if(response.status == 200)
            {
                setIsSquareOauthExists(200);
                setMarkCheckbox(true);
            }
            else 
                setIsSquareOauthExists(400);
            setIsLoading(false);
        })();
    },[])

    const authoriseSquareAccount = async () => {
        window.location.replace(window.ENV.processorInfo?.squareOauthUrl);
    }

    const revokeAccesstoken = async () => {
        setIsLoading(true);
        const response = await api.revokeAccessToken();
        if(response && response.status == 200){
            setMarkCheckbox(false);
            setIsSquareOauthExists(400);
            setIsLoading(false);
            swal({
                title: "Success",
                text: "You successfully revoked the access token",
                icon: "success",
                dangerMode: false,
              })
        }
    }

    return (
        <>
            {isLoading && <Show_Loader/>}
            <div className="squareAuthParent">
                {isSquareOauthExists == 400 && <button className="squareAuthBtn" onClick={authoriseSquareAccount}>Connect Square Account</button>}
                {isSquareOauthExists == 200 && 
                    <>
                        <button className="squareAuthBtn" onClick={revokeAccesstoken}>Revoke Access Token</button>
                        <i className="fa fa-check check-icon" aria-hidden="true"></i>
                    </>
                }
            </div>
        </>
    )
}

export default OauthSettings;
