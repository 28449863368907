import { useState, Fragment } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import loadsStore from '../../../stores/loads-store'

const NestedMenu = ({ loadNum, loads, handleCopyClick, handleCopyPasteClick, txt, id }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadOptions, setLoadOptions] = useState([]);

    const handleClick = (event) => {
        //alert('handle click');
        setAnchorEl(event.currentTarget);
        var latestLoads = loadsStore.getActiveLoadsForDisplay();
        const result = latestLoads.map(x => {
            const item = latestLoads.find(({ loadNum }) => loadNum === x.loadNum);
            return item ? item : x;
        });
        console.log(result);
        setLoadOptions(result);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let icon = ""
    if (Boolean(anchorEl)) {
        icon = "fa-angle-down"
    } else {
        icon = "fa-angle-right"
    }

    var latestLoads = loadsStore.getActiveLoadsForDisplay();
    const result = latestLoads.map(x => {
        const item = latestLoads.find(({ loadNum }) => loadNum === x.loadNum);
        return item ? item : x;
    });

    return (
        <Fragment>
            <div aria-controls={id} aria-haspopup="true" onClick={(e) => { { handleClick(e); handleCopyClick() } }} >
                <li style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <span style={{ color: txt === 'Move' ? 'red' : 'auto' }} > {txt} </span>
                    <span><i style={{ fontSize: 16 }} className={`fa ${icon}`} /></span>
                </li>
            </div>
            <Menu id={id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} >
                {
                    loadOptions && loadOptions.map((load, i) => {
                        if (load.loadNum != loadNum) {
                            return (
                                <MenuItem onClick={() => handleCopyPasteClick(load.loadNum)} >
                                    {"Load " + load.loadNum + " " + load.plane + " " + load.planeLoadNum}
                                </MenuItem>
                            )
                        }
                    })
                }
            </Menu>
        </Fragment>
    )
}
export default NestedMenu
