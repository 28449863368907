/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

import jumperStore from '../../../stores/jumpers-store';

import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';
//import "../../css/glyphicon.css";


class EditJumper extends Component {

    constructor(props) {

        let ratingArray = [
            { text: 'A', checked: false, ratingNumber: '' },
            { text: 'B', checked: false, ratingNumber: '' },
            { text: 'C', checked: false, ratingNumber: '' },
            { text: 'D', checked: false, ratingNumber: '' },
            { text: 'TANDEM INSTRUCTOR', checked: false, ratingNumber: '' },
            { text: 'AFF INSTRUCTOR', checked: false, ratingNumber: '' },
            { text: 'PILOT', checked: false, ratingNumber: '' },
            { text: 'COACH', checked: false, ratingNumber: '' },
            { text: 'SOLO', checked: false, ratingNumber: '' },
            { text: 'AFF STUDENT', checked: false, ratingNumber: '' },
            { text: 'TANDEM STUDENT', checked: false, ratingNumber: '' },
            { text: 'VIDEOGRAPHER', checked: false, ratingNumber: '' },
            { text: 'PROVISIONAL', checked: false, ratingNumber: '' },
            { text: 'PACKER', checked: false, ratingNumber: '' }
        ];

        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        const { ratings = [] } = this.props.jumperDetail || {};
        //let newItems = this.state.items.slice();
        let newItems = ratingArray.slice();
        for (var item in ratings) {
            var index = newItems.map(e => e.text).indexOf(ratings[item].rating)
            ratingArray[index].checked = !ratingArray[index].checked;
            ratingArray[index].ratingNumber = ratings[item].number;
        }

        this.state = {
            isModalOpen: false,
            items: ratingArray
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            jumpType: null
        });
    }



    handleSubmit(values, jumperDetails) {

        this.toggleModal();

        var payload = {};
        let rating = this.state.items.filter(item => item.checked && item.ratingNumber !== '');
        let ratingArray = [];

        for (var item in rating) {
            ratingArray.push({ "rating": rating[item].text, "number": rating[item].ratingNumber })
        }

        ratingArray = ratingArray.filter(rating => jumperDetails.ratings.findIndex(r => r.rating === rating.rating) < 0);

        //alert(JSON.stringify(ratingArray));
        //alert(JSON.stringify(jumperDetails.ratings));

        ratingArray.forEach(rating => {
            //alert('calling jumperstore Add rating ' + JSON.stringify(rating));
            jumperStore.AddRating(jumperDetails.uspaMemberNumber, rating);
        });
    }

    onToggle(index, e) {
        let newItems = this.state.items.slice();
        newItems[index].checked = !newItems[index].checked

        this.setState({ items: newItems })
    }

    renderCheckbox = () => {

        // alert("CheckBOxRating"+ JSON.stringify(this.state.items))
        return this.state.items.map((item, index) => {
            //return newItems.map((item, index) => {
            var ratingName = item.text + "_" + index;
            var ratingText = "txt_" + item.text + "_" + index;
            return (
                <Row className="form-group" key={index}>

                    <Col sm={1}>
                        <input type="checkbox" id={ratingName} name={ratingName} onChange={this.onToggle.bind(this, index)} checked={item.checked} />
                    </Col>

                    <Col sm={4}>
                        <label style={{ marginLeft: "10px" }}>{item.text}</label>
                    </Col>
                    <Col sm={7}>
                        <input type="text" id={ratingText} name={ratingText} className="rating-input"
                            style={{ width: "100% !important" }} onChange={this.handleChange.bind(this, index)} value={item.ratingNumber} />
                    </Col>
                </Row>
            )
        })
    }

    onToggle(index, e) {
        let newItems = this.state.items.slice();
        newItems[index].checked = !newItems[index].checked
        this.setState({
            items: newItems
        })
        //console.log("Checkeditem" + JSON.stringify(this.state.items.filter(item => item.checked)));
    }

    handleChange(index, e) {
        let newItems = this.state.items.slice();
        newItems[index].ratingNumber = e.target.value
        this.setState({
            items: newItems
        })
        //console.log("Checkeditem" + JSON.stringify(this.state.items.filter(item => item.checked)));
    }

    render() {
        //console.log(JSON.stringify(this.props.jumperDetail));
        //alert(JSON.stringify(this.props.jumperDetail));
        const { firstname = '', lastname = '', uspaMemberNumber = '', ratings = [] } = this.props.jumperDetail || {};

        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Edit Jumper`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values, this.props.jumperDetail)}>
                        <Row className="form-group">
                            <Label htmlFor="firstname" md={3}>First Name</Label>
                            <Col md={9}>
                                <Control.input name="firstname" model=".firstname" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} value={firstname} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="lastname" md={3}>Last Name</Label>
                            <Col md={9}>
                                <Control.input name="lastname" model=".lastname" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} value={lastname} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="UpsaMemberNumber" md={3}>UPSA Number</Label>
                            <Col md={9}>
                                <Control.input name="uspaMemberNumber" model=".uspaMemberNumber" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} value={uspaMemberNumber} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="pilot" md={3}>Rating</Label>
                        </Row>
                        {this.renderCheckbox()}
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {/* <button className="btn customButton rightButton" style={{ borderRadius: '100px', width: "94%" }} onClick={this.toggleModal}>
                    Edit Jumper
                </button> */}

                {/* <Button onClick={this.toggleModal}>
                    <Glyphicon glyph="align-center" />
                </Button> */}
                <span onClick={this.toggleModal}>
                    <i className="fa fa-edit" style={{ fontSize: '23px' }}></i>
                </span>

                {/* <span className="glyphicon glyphicon-edit"></span>     */}

            </React.Fragment>
        );
    }
}

export default EditJumper