/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
	Card, CardImg, CardText, CardBody,
	CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import LoadStore from '../../../stores/loads-store';
import axios from 'axios';
import Select from 'react-select';
import ItemModal from './customModal';
import Api from '../../../services/reservationapi';
import { ClipLoader } from 'react-spinners';
import loadsStore from '../../../stores/loads-store';
import { locales } from 'moment';
// import { updateCompletedCoachJump } from '../../../actions/updateCompletedJump'
import { connect } from 'react-redux';

class UpdateJumperAmount extends Component {

	constructor(props) {

		super(props);
		this.PayLoadId = '';
		this.PayLoadAmount = '';
		this.toggleModal = this.toggleModal.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleError = this.toggleError.bind(this);
		this.handlePriceUpdate = this.handlePriceUpdate.bind(this);

		// this.paymentType = ["Credit", "Debit", "Cash", "Card on file"];
		this.state = {
			isModalOpen: false,
			isSelected: false,
			errors: {},
			amount: '',
			isloading: false,
			errorOpen: false,
			isCompleted: props.isCompleted,

		}
		this.loadEvents = [
			{ name: loadsStore.getEventPrefix('loadPriceEvent'), func: this.handlePriceUpdate },
		];
	}

	toggleModal(e) {
		this.setState({
			isModalOpen: !this.state.isModalOpen,
		});
	}

	handlePriceUpdate(data) {
		if (data === true) {
			this.setState({
				isloading: false,
				isModalOpen: false
			})
		}
		else {
			this.setState({
				isloading: false,
				isModalOpen: false
			})
		}
	}


	componentWillUnmount() {
		this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));

	}

	componentDidMount() {
		this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func));
	}

	handleSubmit(values, loadNum, jumpId, jumpType, jumpData, loadId, isCompleted, isUltra, isWingsuit, isToday) {

		this.setState({ isloading: true });
		var today = new Date();
		var isWeekend = (today.getDay() % 6 === 0);
		var payload = {};

		payload.price = parseInt(values.amount);
		if (jumpType === 'funJump') {
			var jumpPostBody = { basePrice: Number(values.amount) };
			loadsStore.updateFunJump(loadNum, jumpId, jumpPostBody)
				.then(result => {
					this.setState({ isloading: false });
				})
				.then(result => {
					// console.log('Price Updated');
					this.setState({ isloading: false });
				})
		}
		else if (jumpType === 'affJump') {

			if (isCompleted) {
				var jumpPostBody = {
					mainInstructorId: jumpData.mainInstructor._id,
					secondaryInstructorId: jumpData.secondaryInstructor._id,
					videographerId: jumpData.videographer._id,
					studentId: jumpData.student._id,
					price: parseInt(values.amount),
					isWeekend: isWeekend,
					level: jumpData.level,
					isToday: isToday
				}

				this.props.updateCompletedAffJump(loadId, jumpId, jumpPostBody);
			}
			else {
				loadsStore.updateAffJump(loadNum, jumpId, payload)
					.then(result => {
						this.setState({ isloading: false });
					})
					.then(result => {
						// console.log('Price Updated');
						this.setState({ isloading: false });

					})
			}
		}
		else if (jumpType === 'coachJump') {
			if (isCompleted) {

				var jumpPostBody = {
					coachId: jumpData.coach._id,
					jumperId: jumpData.jumper._id,
					price: parseInt(values.amount),
					isWingsuit: isWingsuit,
					isToday: isToday
				};

				var cjumpId = jumpData.jumpId;
				var loadId = loadId;

				this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);

			}
			else {
				loadsStore.updateCoachJump(loadNum, jumpId, payload)
					.then(result => {
						this.setState({ isloading: false });
					})
					.then(result => {
						// console.log('Price Updated');
						this.setState({ isloading: false });
					})
			}
		}
		else if (jumpType === 'tandemJump') {

			if (isCompleted) {
				//alert(isUltra);
				var cjumpId = jumpData.jumpId;
				var loadId = loadId;
				var jumpPostBody = {
					videographerId: jumpData.videographer._id,
					instructorId: jumpData.instructor._id,
					studentId: jumpData.student._id,
					price: parseInt(values.amount),
					isWeekend: isWeekend,
					isUltra: isUltra,
					isToday: isToday,
					isPriceUpdate: true
				}

				this.props.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody);
			}
			else {
				loadsStore.updateTandemJump(loadNum, jumpId, payload)
					.then(result => {
						this.setState({ isloading: false });
					})
					.then(result => {
						// console.log('Price Updated');
						this.setState({ isloading: false });
					})
			}
		}
	}


	handleValidation(name) {
		let fields = this.state;
		let errors = fields.errors;
		if (fields[name] === '') errors[name] = "This field is required";
		else if (name === 'amount' && !fields[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Amount should be enter in two decimal places.";
		else errors[name] = "";
		this.setState({ errors: errors });
	}

	handleInput = (event, key) => {
		var cache = { ...this.state };
		cache[key] = event.target.value;
		this.setState({ ...cache }, () => {
			this.handleValidation(key);
		});
	};

	toggleError() {
		this.setState({
			errorOpen: !this.state.errorOpen
		});
	}

	// componentWillReceiveProps(){
	// 	alert(this.props.showUpdate);

	// 	if(this.props.showUpdate)
	// 	{
	// 		this.setState({
	// 			isModalOpen: this.props.showUpdate
	// 		})
	// 	}
	// }

	render() {
		const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

		const { jumpData, loadId, isCompleted, isUltra, isWingsuit, isToday } = this.props;

		return (
			<React.Fragment>
				<ItemModal
					isModalOpen={this.state.isModalOpen}
					title={`Update Jumper Price`}
				>

					<div className='complete-loading'>
						<ClipLoader
							css={override}
							sizeUnit={"px"}
							size={35}
							color={'#fff'}
							loading={this.state.isloading}
						/>
					</div>

					<LocalForm onSubmit={(values) => this.handleSubmit(values, this.props.loadNum, this.props.jumpId, this.props.jumpType, jumpData, loadId, isCompleted, isUltra, isWingsuit, isToday)}>

						<Row className="form-group">
							<Label htmlFor="pilot" md={4}>Enter Amount :</Label>
							<Col md={8}>
								<Control.input name="amount" model=".amount" className="form-control"
									mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.value}
									onChange={(e) => this.handleInput(e, 'amount')} autoComplete="off" />
								<span className="text-danger error-validation">{this.state.errors["amount"]}</span>
							</Col>
						</Row>

						<Row className="form-group m-t-2-rem">
							<Col md={{ size: 9 }}>
								{
									this.state.errors["amount"] == '' ?
										<button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
										:
										<button className="btn btn-bg lightColor createButton" type="submit" disabled>SUBMIT</button>
								}
								<button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
							</Col>
						</Row>
					</LocalForm>
				</ItemModal>
				<Modal isOpen={this.state.errorOpen} toggle={this.toggleError} className={this.props.className}>
					<ModalHeader toggle={this.toggleError} className="error-jumperHeader">
						Warning
					</ModalHeader>
					<ModalBody>
						<p style={{ color: 'red', fontSize: '20px', marginLeft: '1%' }}>Something Wrong happened while charging Jumper !</p>
					</ModalBody>
				</Modal>

				{/* <span onClick={this.toggleModal} style={{ cursor: 'pointer', marginLeft: '2px' }}>
					<i className="fa fa-edit"></i>
				</span> */}
				<span onClick={this.toggleModal} id={"update-Amount" + this.props.jumpId}>
				</span>
			</React.Fragment>
		);
	}
}

// export default UpdateJumperAmount

const mstp = state => {
	return {
		state
	}
}
const mdtp = dispatch => {
	return {
		updateCompletedCoachJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)),
		updateCompletedAffJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedAffJump(loadId, cjumpId, jumpPostBody)),
		updateCompleteTandemJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody)),

	}
}

export default connect(mstp, mdtp)(UpdateJumperAmount)
