import React, { Component } from 'react';

import { DropTarget, DragSource } from 'react-dnd'
import RemovableJumper from './removableJumper'
import EditableFunJumper from './editableFunJumper'
import loadsStore from '../../../stores/loads-store'
import AddJumpers from './addJumpers'
import EditablePrice from './editablePrice'
import EditableJumper from './editableJumper'


const Types = {
    ITEM: 'FunJump'
};

const itemSource = {
    beginDrag(props) {
        //console.log('dragging');
        const item = props
        return item
    },
    endDrag(props, monitor, component) {
        //console.log('endrag')
        if (!monitor.didDrop()) {
            return;
        }
        //return props.handleJumperDrop(props)
        return props;
    }
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

class RenderJumperSource extends Component {


    // handleCreateJumper(payload) {
    //     var that = this;
    //     var jumperArray = [];
    //     jumperArray.push(payload.jumperId);
    //     var jumpPostBody = {
    //         //jumperIds: jumperArray
    //         "gearRentals": [],
    //         "jumperIds": jumperArray
    //     }

    //     var sourceLoadNum = payload.oldLoadNum;
    //     var sourceJumpId = payload.oldJumpId;
    //     var jumperId = payload.jumperId;

    //     Promise.all([loadsStore.addJumpers(payload.loadNum, payload.jumpId, jumpPostBody)])
    //         .then(function (result) {
    //             console.log(result);
    //             if (result[0] === true) {
    //                 that.handleJumperDrop(sourceLoadNum, sourceJumpId, jumperId);
    //             }
    //         })
    // }

    // handleJumperDrop(loadNum, jumpId, jumperId) {
    //     loadsStore.removeJumper(loadNum, jumpId, jumperId);
    // }

    handleCreateJumper(loadNum, jumpId, jumpPostBody) {
        var that = this;
        Promise.all([loadsStore.addJumpers(loadNum, jumpId, jumpPostBody)])
            .then(function (result) {
                // console.log(result);
                if (result[0] === true) {
                    // console.log(result[0]);
                }
            })
    }

    handleJumperRemove(payload) {
        // loadsStore.removeJumper(loadNum, jumpId, jumperId);
        // console.log('Test');
        var that = this;
        var jumperArray = [];
        jumperArray.push(payload.jumperId);
        var jumpPostBody = {
            //jumperIds: jumperArray
            "gearRentals": [],
            "jumperIds": [{
                jumperId: payload.jumper._id,
                price: payload.jumper.price,
                block: payload.jumper.block,
                blockId: payload.jumper.blockId,
                gearPrice: payload.jumper.gearPrice,
                gearType: payload.jumper.gearType,
                isLOing: payload.jumper.isLOing,
                tracked: payload.jumper.tracked
            }]
        }

        if (payload.jumper.studentgearRental) {
            jumpPostBody.studentgearRentals = [payload.jumper._id];
        }

        if (payload.jumper.sportgearRental) {
            jumpPostBody.sportgearRentals = [payload.jumper._id];
        }

        var jumpId = payload.jumpId;
        var loadNum = payload.loadNum;

        var sourceLoadNum = payload.oldLoadNum;
        var sourceJumpId = payload.oldJumpId;
        var jumperId = payload.jumper._id;

        if (sourceLoadNum === loadNum) {
            jumpPostBody.allowClosedOverride = true;
        }

        loadsStore.addJumpers(loadNum, jumpId, jumpPostBody)
            .then(r => {
                if (r) {
                    loadsStore.removeJumper(sourceLoadNum, sourceJumpId, jumperId)
                }
            })
    }

    render() {
        const { jump, jumpType, loadNum, jumpId, disabled, jumperOptions, instructorOptions, isDragging, connectDragSource, src, loadId, isCompleted, loadsToShow, moreThan4, columnsPerGroup, fontSize, isInline } = this.props;
        const opacity = isDragging ? 0 : 1;
        const jumperLength = jump.jumpers.length;
        //console.log('render jumper source');

        const jumpers = jump.jumpers.map((jumper, index) => {
            var jumperClass = 'jumper-load';
            let flexStart = ''
            let showDotMenu = ''
            //console.log(jumper);
            var gearLabel = "G1";
            if (jumper.sportgearRental) {
                gearLabel = "G2";
            }

            if (jumper.pending || jumper.isLOing || jumper.tracked) {
                jumperClass = 'jumper-load-pending';
            }

            if (isInline) {
                showDotMenu = 'show-dot-menu'
                // flexStart = 'flex-start'
            }

            if (jumper.firstname === "HOLD" && jumper.lastname === "HOLD") {
                return (
                    <ul className={`jumper-load ${flexStart}`} key={jumper._id}>
                        <li key={jumper._id}>
                            <EditableFunJumper columnsPerGroup={columnsPerGroup} isToday={this.props.isToday} editMode={true} type={"funjump"} label={"V"} loadNum={loadNum} jumpId={jumpId} jumper={jumper} options={jumperOptions} handleCreateJumper={this.handleCreateJumper} handleJumperRemove={this.handleJumperRemove} />
                        </li>
                    </ul>
                )
            }
            else {
                return (
                    <ul className={jumperClass + " " + flexStart} key={"fun" + jumper._id}>
                        <li>
                            {/* <RemovableJumper loadNum={loadNum} jumpId={jumpId} jumper={jumper} handleCreateJumper={this.handleCreateJumper}  handleJumperRemove={this.handleJumperRemove} isCompleted={this.props.isCompleted} /> */}
                            <RemovableJumper columnsPerGroup={columnsPerGroup} isToday={this.props.isToday} loadNum={loadNum} jumpType={jumpType} jumpId={jumpId}
                                jumper={jumper} loadId={loadId} jump={jump}
                                handleCreateJumper={this.handleCreateJumper} handleJumperRemove={this.handleJumperRemove} isCompleted={isCompleted}
                                jumpId={jumpId}
                                loadId={loadId}
                                loadsToShow={loadsToShow}
                                gearLabel={gearLabel}

                                disabled={this.props.disabled}
                                jumperOptions={this.props.jumperOptions}
                                fontSize={this.props.fontSize}
                                isInline={this.props.isInline}
                                jumperLength={this.props.jumperLength}
                                isEditt={true}
                                activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                            />
                        </li>
                    </ul>
                )
            }
            // return (
            //     <ul className="jumper-load" key={jumper._id}>
            //         <li>
            //             <RemovableJumper isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} jumper={jumper} loadId={loadId} funJump={funJump}
            //             handleCreateJumper={this.handleCreateJumper}  handleJumperRemove={this.handleJumperRemove} isCompleted={isCompleted}/>
            //         </li>
            //     </ul>
            // );
        });

        // return connectDragSource(

        return (
            // {}
            <div>

                {jumpers}
                {
                    ["Solo Student"].indexOf(jump.jumpType) >= 0 ? null
                        :
                        <AddJumpers
                            activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                            columnsPerGroup={columnsPerGroup}
                            isToday={this.props.isToday}
                            loadsToShow={loadsToShow}
                            loadNum={loadNum} jump={jump} jumpType={jumpType} jumpId={jumpId} disabled={disabled} jumperOptions={jumperOptions} fontSize={fontSize}
                            loadId={this.props.loadId}
                            isCompleted={this.props.isCompleted}
                            isInline={isInline}
                            jumperLength={jumperLength} />
                }
            </div>
        )
    }
}

// export default DragSource(Types.ITEM, itemSource, collect)(RenderJumperSource)
export default RenderJumperSource;
