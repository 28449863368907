import React, { Component } from 'react';
import background from './images/background.png';
import logo from './images/logo.svg';
import './css/App.css';
import Report from './reports/Reports';
import LoadManagerCenter from './loadManager/LoadManagerCenter';
import LoadManagerRight from './loadManager/LoadManagerRight';
import SchedulesCenter from './schedules/schedulesCenter';
import SchedulesRight from './schedules/schedulesRight';
import AccountsCenter from './accounts/accountsCenter';
import AccountsRight from './accounts/accountsRight';
import Admin from './admin/Admin';
import Store from './store/Store';
import Rigs from './rigs/Rigs';
import Login from './login/Login1';
import reservation from './schedules/reservation/reservation';
import booking from './BookingComponent/booking';
import giftCard from './BookingComponent/giftcard';

import schedulesStore from '../stores/schedule-store';
import socketIOClient from "socket.io-client";
import loadsStore from '../stores/loads-store';
import jumpersStore from '../stores/jumpers-store';
import reservationsStore from '../stores/reservation-store';
import accountsStore from '../stores/account-store';

import itemStore from '../stores/item-store';
import reportsStore from '../stores/report-store';
import authStore from '../stores/auth-store';
import MyDropzone from './loadManager/upload'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux';
import { store } from '../stores/index';
import { ScaleLoader } from 'react-spinners';
import LoginLoader from './login/LoginLoader';
import ReservationList from './schedules/ReservationList';
import CalenderEvents from './schedules/CalenderEvents/Events'
import TermsConditions from './schedules/Terms_Conditions/termsConditions';
import EmailTemplates from './schedules/EmailTemplates';
import smsTextTemplates from './schedules/smsTextTemplates';
import urlgetter from '../services/getMainUrl';
import OauthRedirect from './admin/oauthSettings/OauthRedirect';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalBody: <h1 style={{ color: 'white' }}>Currently Not available</h1>,
      width: window.innerWidth,
      open: false,
      rightOpen: false,
      loggedIn: false,
      configLoaded: false,
      ip: ''
    }

    this.handleSession = this.handleSession.bind(this);
    this.handleGetIp = this.handleGetIp.bind(this);

    this.loadEvents = [
      { name: loadsStore.getEventPrefix('clearSession'), func: this.handleSession },
      { name: loadsStore.getEventPrefix('configLoaded'), func: this.handleConfigLoaded },

    ]

    this.noComponentCenter = <div className="col-lg-8 col-md-8 col-sm-12">
      <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
      <div className="row" style={{ width: '80%', margin: 'auto' }}><h1 style={{ color: 'white' }}>Currently Not available</h1></div>
    </div>;
    this.noComponentRight = <div className="col-lg-2 col-md-2 col-sm-12 blueBg fullHeightPanes rightCol" style={{ padding: '0px' }}><div style={{ minHeight: 200, padding: '0px' }}><h5 style={{ text: 'white', textAlign: 'center', marginTop: '50vh' }}>Not available</h5></div></div>;

    this.menu = {
      'LOAD MANAGER': {
        center: LoadManagerCenter,
        //center: Report,
        right: LoadManagerRight,
        noMargin: false,
        path: '/load-manager',
        style: {}
      },
      'TANDEM/AFF SCHEDULES': {
        center: SchedulesCenter,
        right: SchedulesRight,
        noMargin: true,
        path: '/schedules-tandem-aff',
        style: {}
      },
      'VIEW ACCOUNTS': {
        center: AccountsCenter,
        right: AccountsRight,
        noMargin: false,
        path: '/view-accounts',
        style: {}
      },
      'REPORTS': {
        center: Report,
        noMargin: false,
        path: '/reports',
        style: {}
      },
      'ADMIN': {
        center: () => this.noComponentCenter,
        right: () => this.noComponentRight,
        noMargin: false,
        path: '/dizio-admin',
        style: {}
      },
      'STORE': {
        center: () => this.noComponentCenter,
        right: () => this.noComponentRight,
        noMargin: false,
        path: '/store',
        style: {}
      },
      'RIGS': {
        center: () => this.noComponentCenter,
        right: () => this.noComponentRight,
        noMargin: false,
        path: '/rigs',
        style: {}
      }
    }
    this.authEvents = [
      { name: authStore.getEventPrefix('loggedIn'), func: this.handleLogin },
      { name: authStore.getEventPrefix('loggedOut'), func: this.handleLogout },
      { name: authStore.getEventPrefix('getIp'), func: this.handleGetIp }

    ];
  
  }

  handleConfigLoaded = () => {
    //alert(JSON.stringify(window.ENV, null,2));
    this.setState({configLoaded: true})
  }
  handleSession(data) {
    this.setState({
      isLoading: false
    })
  }

  handleGetIp(data) {

    console.log('ip data');
    console.log(data);

    console.log(window.ENV?.localIp);

    var ip = authStore.getIpFromAuthStore();

    this.setState({ ip: ip });
  }

  handleClick() {
    this.setState({
      open: !this.state.open
    });
  }

  handleRightClick() {
    this.setState({
      rightOpen: !this.state.rightOpen
    });
  }

  componentWillMount = () => {
    schedulesStore.on('ERROR', this.handleError);
    window.addEventListener('resize', this.handleWindowSizeChange);
    window.MainApp = this;
  }

  componentWillUnmount = () => {
    this.authEvents.forEach((evnt) => authStore.removeListener(evnt.name, evnt.func));
    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
  }

  componentDidMount = () => {

    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))

    var token = localStorage.getItem("Auth-token");

    //authStore.getIp();

    if (token === null) {
      this.setState({
        isLoading: false
      })
    }
    this.authEvents.forEach((evnt) => authStore.on(evnt.name, evnt.func))

    // if(authStore.isLoggedIn){
    //console.log(localStorage.getItem("Auth-token"));
    //console.log("today is day: ")
    var today = new Date();
    //console.log(today.getDay());

    this.loadInfo();


  }

  loadInfo = () => {
    var promises1 = [];
    var promises2 = [];
    var promises3 = [];
    var promises4 = [];
    loadsStore.loadManifestConfig()
    const socket = socketIOClient(urlgetter.getMainApiUrl());
    promises1.push(jumpersStore.loadAllJumpers(), loadsStore.loadSettings(), loadsStore.loadFunJumpTypes(), authStore.getSettingsByUser() );
    Promise.all(promises1)
      .then(done => {
        this.setState({configLoaded:true});
        console.log('done loading settings');
        console.log('done loading all jumpers');
        console.log('done loading tandems');
        promises2.push(itemStore.loadCategories(), itemStore.loadItems(), reportsStore.loadPrices(), reservationsStore.getAllGrouponPackages(),)
        Promise.all(promises2)
          .then(ret => {
            console.log('done loading waivers, categories, items, prices');
            promises3.push(jumpersStore.loadTandemStudents(), loadsStore.loadPlanes(), reportsStore.loadDropzoneTodayActivity())
            Promise.all(promises3)
              .then(ret => {
                console.log("dong loading activities");
                promises4.push(jumpersStore.loadAllDailyActivities(), jumpersStore.loadRepeatTandemStudents(), loadsStore.loadAllExistingLoads());
                Promise.all(promises2)
                  .then(loads => {
                    jumpersStore.loadTodayWaivers();


                    console.log('after load dropzone today activity');
                    loadsStore.setListeners(socket);
                    jumpersStore.setListeners(socket);
                    reportsStore.setListeners(socket);
                    schedulesStore.setListeners(socket);
                    itemStore.setListeners(socket);
                    authStore.setListeners(socket)
                    authStore.dataLoaded(); // emit data loaded event
                    jumpersStore.dataLoaded();
                    this.setState({ isLoading: false })

                  })
              })
          })
      })

  }
  IsEnableLoader = (isLoading) => {
    this.setState({
      isLoading: isLoading
    }, () => {
      return true
    })
  }

  handleLogout = () => {
    this.setState({ isLoading: true });
  }

  handleLogin = () => {
    //console.log('in handle login');
    this.setState({ isLoading: true });
    
    this.loadInfo();
    /*
    loadsStore.loadSettings()
      .then(settings => {
        console.log('after load settings');
        return jumpersStore.loadAllJumpers();
      })
      .then((jumpersLoaded) => {
        console.log('after loadAll Jumpers');
        return jumpersStore.loadTandemStudents();
      })
      .then(studentsLoaded => {
        console.log('after load tandem students');
        return jumpersStore.loadTodayWaivers();
      })
      .then((ret) => {
        console.log('after load all existing loads');
        return itemStore.loadCategories();
      })
      .then((ret) => {
        console.log('after load categories');
        return itemStore.loadItems();
      })
      .then(data => {
        console.log('after load items');
        return reportsStore.loadPrices();
      })
      .then(data => {
        console.log('after load prices');
        return jumpersStore.loadAllDailyActivities();
      })
      .then(data => {
        console.log('after load all daily activities');
        return loadsStore.loadPlanes();
      })
      .then(data => {
        console.log('after load planes');
        return reportsStore.loadDropzoneTodayActivity();
      })
      .then((studentsLoaded) => {
        console.log("after load today waivers");
        return loadsStore.loadAllExistingLoads();
      })
      .then(loads => {
        console.log('after load dropzone today activity');
        loadsStore.setListeners(socket);
        jumpersStore.setListeners(socket);
        reportsStore.setListeners(socket);
        authStore.dataLoaded(); // emit data loaded event
        this.setState({ isLoading: false })
  
      })
      .then(data => {
        //console.log('all data loaded');
      }).catch(error => {
        // console.log('all data loaded error',error);
  
      })
    */
  }

  handleError = (e) => {
    if (e.alert) {
      alert(e.message);
    } else {
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleKeyDown(e) {
    var disabledArrowButton = localStorage.getItem('disabledArrowButton')
    if (disabledArrowButton === false) {
      if (e.keyCode === 37) {
        document.getElementsByClassName('carousel-back-btn').click();
      }
      else if (e.keyCode === 39) {
        document.getElementsByClassName('carousel-next-btn').click();
      }
    }
  }
  render() {
    var token = localStorage.getItem("Auth-token");


    var menuKeys = Object.keys(this.menu);
    const { width } = this.state;
    const isMobile = width <= 500;
    const isAuthenticated = true;
    var configLoaded = this.state.configLoaded;
    return (

      
        <Provider store={store}>

        <Router>
        {this.state.configLoaded?<div className="App"
          // onKeyDown={(e) => this.handleKeyDown(e)}
          >

            <Route exact path="/" render={() => token === null ? <Login /> : <LoadManagerCenter />} />
            <Route exact path="/login" component={Login} />
            <Route path="/upload_media" component={MyDropzone} />
            <Route path="/load-manager" component={LoadManagerCenter} />
            <Route path="/waitlist" component={LoadManagerCenter} />
            <Route path="/completed-loads" component={LoadManagerCenter} />
            <Route path="/reports" component={Report} />
            <Route path="/schedules-tandem-aff" component={SchedulesCenter} history={this.props.history} />
            <Route path="/terms-conditions" component={TermsConditions} />
            <Route path="/calender-events" component={CalenderEvents} />
            <Route path="/view-accounts" component={AccountsCenter} />
            <Route path="/admin" component={Admin} />
            <Route path="/store" component={Store} />
            <Route path="/rigs" component={Rigs} />
            <Route exact path="/reservation" component={reservation} />
            <Route path="/reservations/reservationlist" component={ReservationList} />
            <Route path="/reservations/emailtemplatelist" component={EmailTemplates} />
            <Route path="/reservations/smsTextTemplates" component={smsTextTemplates} />
            <Route path="/booking" component={booking} />
            <Route path="/giftCard" component={giftCard} />
            <Route path="/oauth2-redirect" component={OauthRedirect}/>
          </div>:<div>config loading</div>}
        </Router>
      </Provider>
    
    );
  }
}

export default App;
