import React, { Fragment, Component } from 'react';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
} from '@material-ui/core';

import {
  JUMPER_LOGS,
  NUMBER_SLOTS,
  STAFF_SUMMARY,
  JUMPER_STATEMENT,
  INVOICE_BY_CATEGORY,
  DETAIL_BY_CATEGORY,
  GIFT_CARD,
  TANDAM_STUDENT
} from '../../../constant/reports';
import { styles } from './modalStyle';
import AutoComplete from './AutoComplete';
import { DateRangePicker, SingleDatePicker } from 'react-dates'
import AsyncSelect from 'react-select/lib/Async';

import loadsStore from '../../../../stores/loads-store';
import jumpersStore from '../../../../stores/jumpers-store';
import Select, { components } from 'react-select';
import { closeModal } from '../../../../actions/reports';
import reportStore from '../../../../stores/report-store';
import moment from 'moment';

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 30
  }),
  menu: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
    height: 730
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    padding: 20,
    minHeight: 700
  })
};

class FormDialog extends Component {
  constructor() {
    super();
    this.state = {
      jumperOptions: [],

      defaultOptions: [],

      tandemStudentOptions: [],

      width: window.innerWidth,

      JumpType: '',
    };
    this.inputValue = '';

    //this.handleNewActivity = this.handleNewActivity.bind(this);

    this.jumperEvents = [
      { name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded }
    ];

    // this.loadEvents=[
    //     {name:reportStore.getEventPrefix('loaderActivity'), func:this.handleNewActivity},
    // ];
  }

  state = {
    open: false,
    endDate: undefined,
    startDate: undefined,
    jumper_name: undefined,
    jumper_id: undefined
  };



  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
      startDate: undefined,
      endDate: undefined,
    })
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  modalContent = () => {
    let { modalType } = this.props;

    switch (modalType) {
      case INVOICE_BY_CATEGORY: {
        return false;
      }
      case JUMPER_LOGS: {
        return true;
      }
      case JUMPER_STATEMENT: {
        return true;
      }
      case STAFF_SUMMARY: {
        return false;
      }
      case NUMBER_SLOTS: {
        return false;
      }
      case DETAIL_BY_CATEGORY: {
        return false;
      }
      case GIFT_CARD: {
        return false;
      }
      default:
        return false;
    }
  }


  startDateHandler = ({ target: { value } }) => {
    this.setState({ startDate: value })
  }

  endDateHandler = ({ target: { value } }) => {
    this.setState({ endDate: value })
  }

  componentWillMount = () => {
    window.addEventListener('resize', this.handleWindowSizeChange);
    //this.loadEvents.forEach((evnt)=>loadsStore.on(evnt.name,evnt.func))
  }


  componentDidMount = () => {
    //console.log('in components did mount accounts');

    //this.loadEvents.forEach((evnt)=>loadsStore.on(evnt.name,evnt.func))
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
    var options = jumpersStore.getJumperOptions();

    this.setState({ jumperOptions: options, defaultOptions: options.slice(0, 50) });
    //console.log(this.state.defaultOptions);
    //console.log(this.state.jumperOptions);
    window.addEventListener('resize', this.handleWindowSizeChange);
    // if(window.globals && window.globals['accountsState']){
    //     this.setState({...window.globals['accountsState']});
    // }
  }

  componentWillUnmount = () => {
    //console.log('in components will unmount accounts');
    window.addEventListener('resize', this.handleWindowSizeChange);
    //this.jumperEvents.forEach((evnt)=>jumpersStore.removeListener(evnt.name,evnt.func));
    //this.loadEvents.forEach((evnt)=>loadsStore.removeListener(evnt.name,evnt.func));
  }

  componentDidUpdate = (prevProps, prevState) => {
    let { modalType } = this.props;
    if (prevProps.modalType !== modalType) {
      this.setState({ open: true })
    }

  }

  // handleNewActivity =(activityString)=>{
  // 	//console.log('in handleNewActivity');
  //     //console.log(activityString);
  //     if(activityString)
  //     {
  //         this.setState({
  //             isloading: false,
  //             open: false
  //         })
  //     }
  // }

  handleJumpersLoaded = (jumperOptions) => {
    //console.log('in handle jumpers loaded in accounts');
    var defaultOptions = jumperOptions.slice(0, 50);
    this.setState({
      jumperOptions: jumperOptions,
      defaultOptions: defaultOptions
    });
  }

  filterJumpers = (inputValue, jumperOptions) => {
    //console.log("in filterJumpers");
    if (inputValue) {
      //console.log(inputValue);
      var filteredOptions = jumperOptions.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase()));
      //d//console.log(filteredOptions);
      if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
      else return filteredOptions;
    }
    //console.log('no inputValue');
    return jumperOptions.slice(0, 30);
  };

  promiseOptions = (inputValue, jumperOptions) => {
    //console.log('in pomiseOptions');
    //console.log(this.inputValue);
    return new Promise(resolve => {
      resolve(this.filterJumpers(this.inputValue, jumperOptions));
    });
  }

  handleInputChange = (newValue) => {
    //console.log('handleInputChange');
    this.inputValue = newValue;
  };

  handleSelect = (val) => {
    // alert(val.value._id);
    this.setState({ jumper_id: val.value._id, jumper_name: val.label });
    // jumpersStore.getDailyActivity(val.value._id);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleModal() {
    this.setState({
      open: false
    })
  }


  handletTypeSelect = (event) => {
    var type = event.target.value;
    this.setState({
      JumpType: type
    })
  };
  loadTandemStudents = (startDate, endDate) => {
    // console.log("🚀 ~ file: Modal.js ~ line 248 ~ FormDialog ~ startDate,endDate", startDate.format(),endDate.format())
    let { modalType } = this.props;
    if (modalType === TANDAM_STUDENT) {
      // console.log("called: ", TANDAM_STUDENT)
      jumpersStore.loadTandemByDate(startDate.format(), endDate.format())
        .then(r => {
          // data loads in TandemStudentTable
          // console.log("isLoaded: ", r);
        })


    }
    // debugger
  }
  handleModalDate = (startDate, endDate) => {
    // console.log("🚀 ~ file: Modal.js ~ line 254 ~ FormDialog ~ startDate,endDate", startDate,endDate)
    this.setState({
      startDate, endDate, open: false,
    },
      () => {
        const { modalType } = this.props;
        const { startDate, endDate, jumper_id, jumper_name, JumpType } = this.state
        this.props.postModal({ modalType, startDate, endDate, jumper_id, jumper_name, JumpType }
        )
      })
  }
  todayDate = () => {
    let startDate = moment().startOf('day');
    let endDate = moment()
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  MonthToDate = () => {
    let startDate = moment().startOf('Month');
    let endDate = moment()
    console.log(startDate, endDate, "MonthToDate")
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  lastMonthDate = () => {
    let startDate = moment(new Date()).subtract(1, 'months').startOf('month')
    let endDate = moment(new Date()).subtract(1, 'months').endOf('month')
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  lastYearDate = () => {
    let startDate = moment(new Date()).subtract(1, 'year').startOf('year')
    let endDate = moment(new Date()).subtract(1, 'year').endOf('year')
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  yearToDate = () => {
    let startDate = moment().startOf('year');
    let endDate = moment()
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  q1 = () => {
    let startDate = moment().quarter(1).startOf('quarter');
    let endDate = moment().quarter(1).endOf('quarter');
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  q2 = () => {
    let startDate = moment().quarter(2).startOf('quarter');
    let endDate = moment().quarter(2).endOf('quarter');
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  q3 = () => {
    let startDate = moment().quarter(3).startOf('quarter');
    let endDate = moment().quarter(3).endOf('quarter');
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  q4 = () => {
    let startDate = moment().quarter(4).startOf('quarter');
    let endDate = moment().quarter(4).endOf('quarter');
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }
  submitDateRange = () => {
    const { startDate, endDate } = this.state;
    this.loadTandemStudents(startDate, endDate);
    this.handleModalDate(startDate, endDate);
  }

  DayLoadHistory = (day) => {
    let startDate, endDate;

    if (day === 1) {
      startDate = moment().subtract(1, 'days').startOf('day')
      endDate = moment().subtract(1, 'days').endOf('day')
    }
    else if (day === 2) {
      startDate = moment().subtract(2, 'days').startOf('day')
      endDate = moment().subtract(2, 'days').endOf('day')
    }
    else if (day === 3) {
      startDate = moment().subtract(3, 'days').startOf('day')
      endDate = moment().subtract(3, 'days').endOf('day')
    }
    else if (day === 4) {
      startDate = moment().subtract(4, 'days').startOf('day')
      endDate = moment().subtract(4, 'days').endOf('day')
    }
    this.setState({
      startDate: startDate,
      endDate: endDate
    },
      () => {
        // console.log("Today Date", this.state.startDate, this.state.endDate)
        const { classes, modalType } = this.props;
        const { startDate, endDate, jumper_id, jumper_name, JumpType } = this.state
        this.props.postModal({ modalType, startDate, endDate, jumper_id, jumper_name, JumpType })
      })

    // console.log("Today Date", this.state.startDate, this.state.endDate)
  }
  closeDateModal = () => {
    const { modalType } = this.props;
    const { startDate, endDate, jumper_id, jumper_name } = this.state;
    this.props.closeModal({ modalType, startDate, endDate, jumper_id, jumper_name })
    this.setState({ open: false })
  }
  hideModal = (e) => {
    let classes = e.target.classList;
    if (classes.contains("customModal") && classes.contains("visible")) {
      this.closeDateModal();
    }
  }

  render() {
    const { classes, modalType } = this.props;
    const { startDate, endDate, jumper_id, jumper_name, JumpType } = this.state;
    const { width } = this.state;
    const isMobile = width <= 500;
    const DateOrientation = isMobile ? "vertical" : "horizontal";
    //console.log("CloseModal:"+ JSON.stringify(this.props));

    return (
      <div className={`customModal ${this.state.open ? "visible" : ""}`} onClick={this.hideModal}>
        <div className="modalBodyContainer">
          <div style={{ textAlign: 'right', cursor: 'pointer' }}>
            <span onClick={this.closeDateModal}>
              <i className="fa fa-close"></i>
            </span>
          </div>

          {
            this.modalContent() ?
              // <div className={classes.field}>
              //     <div
              //         className={classes.itemBox}>
              //         Jumper Name
              //     </div>
              //     <div className={classes.itemBox}> : </div>
              //     <div className={classes.itemBox}>
              //         <AutoComplete />
              //     </div>
              // </div>

              <div style={{ "width": "100%", marginTop: "10px" }}>
                <AsyncSelect loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
                  styles={customStyles} onInputChange={(val) => this.handleInputChange(val)} defaultOptions={this.state.defaultOptions}
                  onChange={(val) => this.handleSelect(val)}
                />
              </div>
              :
              undefined
          }


          {
            modalType === "DETAIL BY CATEGORY" &&
            <select onChange={this.handletTypeSelect} className="invoiceFrom form-control" style={{ width: '100%' }} >
              <option>Select Type</option>
              <option>fun jumps</option>
              <option>tandem jumps</option>
              <option>hopNPops</option>
              <option>AFF jumps</option>
              <option>coach jumps</option>
              <option>collections</option>
              <option>Tandem payouts</option>
              <option>Jumper payouts</option>
              <option>credits</option>
              <option>countersales</option>
            </select>
          }

          {
            modalType === "LOAD HISTORY" ?
              <div>
                <div className="row">
                  {/* <div className="col-2"> */}
                  <Button
                    onClick={() => {
                      this.DayLoadHistory(1)

                    }}
                    className={classes.dateBtnHistory}

                  >
                    1 Day Ago
                  </Button>
                  {/* </div> */}
                  {/* <div className="col-2"> */}
                  <Button
                    onClick={() => {
                      this.DayLoadHistory(2)
                    }}
                    className={classes.dateBtnHistory}

                  >
                    2 Day Ago
                  </Button>
                  {/* </div> */}
                  {/* <div className="col-2"> */}
                  <Button
                    onClick={() => {
                      this.DayLoadHistory(3)
                    }}
                    className={classes.dateBtnHistory}

                  >
                    3 Day Ago
                  </Button>
                  {/* </div> */}

                  {/* </div> */}
                  {/* <div className="col-2"> */}
                  <Button
                    onClick={() => {
                      this.DayLoadHistory(4)
                    }}
                    className={classes.dateBtnHistory}

                  >
                    4 Day Ago
                  </Button>
                  {/* </div> */}
                </div>

                <div className="row">
                  <div className="col-12" style={{ paddingTop: '14px' }}>Date</div>
                  <div className="col-12">
                    {
                      isMobile ?
                        <SingleDatePicker
                          date={this.state.startDate}
                          id="your_unique_start_date_id"
                          onDateChange={date => {
                            let startDate = moment(date).startOf("day")
                            let endDate = moment(date).endOf("day")
                            this.setState({
                              startDate: startDate,
                              endDate: endDate
                            })
                          }}
                          focused={this.state.focused}
                          // isOutsideRange={() => false}
                          onFocusChange={({ focused }) => this.setState({ focused })}
                          isOutsideRange={(day) => day.isAfter(moment())}
                        />
                        :
                        <SingleDatePicker
                          date={this.state.startDate}
                          id="your_unique_start_date_id"
                          onDateChange={date => {
                            let startDate = moment(date).startOf("day")
                            let endDate = moment(date).endOf("day")
                            this.setState({
                              startDate: startDate,
                              endDate: endDate
                            })
                          }}
                          focused={this.state.focused}
                          // isOutsideRange={() => false}
                          onFocusChange={({ focused }) => this.setState({ focused })}
                          isOutsideRange={(day) => day.isAfter(moment())}
                        />
                    }
                  </div>
                </div>
                <div className="row">
                  <Button
                    onClick={() => this.props.postModal({ modalType, startDate, endDate, jumper_id, jumper_name, JumpType })}
                    className={classes.btn}>
                    Done
                  </Button>
                </div>
              </div>
              :
              <div>
                <div className="row">
                  {/* <div className="col-2"> */}
                  <Button
                    onClick={() => { this.todayDate() }}
                    className={classes.dateBtn}
                  >
                    Today
                  </Button>
                  {/* </div> */}
                  {/* <div className="col-2"> */}
                  <Button
                    onClick={() => { this.MonthToDate() }}
                    className={classes.dateBtn}
                  >
                    Month To Date
                  </Button>
                  {/* </div> */}
                  {/* <div className="col-2"> */}
                  <Button
                    onClick={() => { this.yearToDate() }}
                    className={classes.dateBtn}
                  >
                    Year To Date
                  </Button>
                  <Button
                    onClick={() => { this.lastMonthDate() }}
                    className={classes.dateBtn}
                  >
                    Last Month
                  </Button>
                </div>
                <div className="row">
                  {/* <div className="col-3"> */}
                  <Button
                    onClick={() => {
                      this.q1()
                    }}
                    className={classes.dateBtnQuater}

                  >
                    Q1
                  </Button>
                  {/* </div> */}
                  {/* <div className="col-4"> */}
                  <Button
                    onClick={() => {
                      this.q2()
                    }}
                    className={classes.dateBtnQuater}

                  >
                    Q2
                  </Button>
                  {/* </div> */}
                  {/* <div className="col-4"> */}
                  <Button
                    onClick={() => {
                      this.q3()
                    }}
                    className={classes.dateBtnQuater}

                  >
                    Q3
                  </Button>
                  {/* </div> */}
                  {/* <div className="col-4"> */}
                  <Button
                    onClick={() => {
                      this.q4()
                    }}
                    className={classes.dateBtnQuater}

                  >
                    Q4
                  </Button>
                  <Button
                    onClick={() => { this.lastYearDate() }}
                    className={classes.dateBtn}
                  >
                    Last Year
                  </Button>
                </div>
                <div className="row">
                  <div className="col-12" style={{ paddingTop: '14px' }}>Start & End Dates</div>
                  <div className="col-12">
                    <DateRangePicker
                      startDate={this.state.startDate}
                      startDateId="your_unique_start_date_id"
                      endDate={this.state.endDate}
                      endDateId="your_unique_end_date_id"
                      onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                      focusedInput={this.state.focusedInput}
                      isOutsideRange={() => false}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      orientation={DateOrientation}
                      minimumNights={0}
                    />

                  </div>
                </div>
                <div className="row">
                  <Button
                    onClick={this.submitDateRange}
                    className={classes.btn}>
                    Done
                  </Button>
                </div>
              </div>
          }
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.Reports.modal
});

export default connect(mapStateToProps, null)(withStyles(styles)(FormDialog));


// const mapStateToProps = ({ state }) => ({
//     open: state.Reports.modal
// });

// const mapDispatchToProps = (dispatch) => ({
//     closeModal: () => dispatch(closeModal())
// });

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FormDialog));
