import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import background from '../images/background.svg';
import logo from '../images/logo.svg';
import scheduleStore from '../../stores/schedule-store';

import './reservationlist.css';
import LeftMenu from '../menu/LeftMenu1';
import DonutLoader from '../loadManager/DonutLoader';
import ExpandedComponent from './ExpandedComponent';
import FilterComponent from './FilterComponent';

const customStyles = {
    headCells: {
        style: {
            fontWeight: '600',
            fontSize: "1rem",
            letterSpacing: "1px",
        },
    }
};

export default class ReservationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            isLoading: true,
            tableData: [],
            startTime: null,
            endTime: null,
            filterBy: null,
        }
        this.scheduleEvents = [
            {
                name: scheduleStore.getEventPrefix("reservations"), func: this.handleGetReservationsEvent
            }
        ];
    }
    sortPaid = (a, b) => {
        // slice start from index=1
        a = a.paid.slice(1);
        b = b.paid.slice(1);
        a = Number(a);
        b = Number(b);
        return a - b;
    }
    sortRefund = (a, b) => {
        // slice start from index=1
        a = a.refundAmount.slice(1);
        b = b.refundAmount.slice(1);
        a = Number(a);
        b = Number(b);
        return a - b;
    }
    sortMadeDate = (a, b) => {
        a = this.returnTimeInteger(a.made_date)
        b = this.returnTimeInteger(b.made_date);
        // console.log(a, 'a');
        // console.log(b,'b')
        let ans = 0;
        if (a < b) {
            ans = -1;
        }
        else {
            ans = 1;
        }
        // console.log('ans:', ans)
        return ans;
    }
    sortResDate = (a, b) => {
        // console.log(a.res_date, b.res_date, 'resdate')
        a = this.returnTimeInteger(a.res_date)
        b = this.returnTimeInteger(b.res_date);
        // console.log(a, 'a');
        // console.log(b,'b')
        let ans = 0;
        if (a < b) {
            ans = -1;
        }
        else {
            ans = 1;
        }
        // console.log('ans:', ans)
        return ans;
    }
    returnTimeInteger = (value) => {
        let ar = value.split('/');
        let h = ar[1] + "/" + ar[0] + "/" + ar[2];
        return new Date(h).getTime();
    }
    changeFilterOn = (type) => {
        this.setState({ filterBy: type })
    }



    filterReservation = (startTime, endTime) => {
        console.log("🚀 ~ file: ReservationList.js ~ line 90 ~ ReservationList ~ startTime, endTime", startTime, endTime)

        if (startTime && endTime) {
            this.setState({
                startTime,
                endTime,
            })

            let payload={
                startTime,
                endTime,
                filterBy: this.state.filterBy
            }

            scheduleStore.getReservations(payload);

        }
        else {
            this.setState({
                startTime: null,
                endTime: null
            })
        }
    }


    componentDidMount = () => {
        // console.log('REsERVATionlist: ', this.state.isLoading);
        if (this.state.isLoading) { 
            scheduleStore.getLatestReservations();
        }
        this.scheduleEvents.forEach((evnt) => scheduleStore.on(evnt.name, evnt.func));
    }

    componentWillUnmount = () => {
        this.scheduleEvents.forEach((evnt) => scheduleStore.removeListener(evnt.name, evnt.func));
    }
    handleGetReservationsEvent = (data) => {

        // Loads from database or api
        console.log("🚀 ~ file: ReservationList.js ~ line 135 ~ ReservationList ~ data", data)
        let tableData = this.generateTableData(data);
        this.setState({ dataList: data, isLoading: false, tableData });
    }
    contextActions = () => {
        return <div>
            <button className="btn btn-warning" onClick={this.delete}>Delete</button>
        </div>
    }
    delete = (e) => {
        alert("Delete Clicked")
    }
    returnColumns = () => {
        return [
            // {
            //     name: 'Index',
            //     selector: 'id',
            //     sortable: true,
            //     center: true,
            // },
            {
                name: 'Date Made',
                selector: 'made_date',
                sortable: true,
                center: true,
                sortFunction: this.sortMadeDate,
            },
            {
                name: 'Reservation Date',
                selector: 'res_date',
                sortable: true,
                center: true,
                sortFunction: this.sortResDate,
            },
            {
                name: 'Name',
                selector: 'name',
                sortable: true,
                center: true,
            },
            {
                name: "Phone",
                selector: "phone",
                sortable: true,
                center: true,
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
                center: true,
            },
            {
                name: 'Refund',
                selector: 'refundAmount',
                sortable: true,
                sortFunction: this.sortRefund,
                center: true,
            },

            {
                name: 'Paid',
                selector: 'paid',
                sortable: true,
                sortFunction: this.sortPaid,
                center: true,
            },
        ];
    }
    makeDollarPrice = (value) => {
        if (value === undefined) {
            value = '0'
        }
        value = '$' + value.toFixed(2);
        return value;
    }
    generateTableData = (data) => {
        // selectors: id, made_date, res_date,name,email,paid
        // console.log("DATA: ", data);
        let tableData = data.map((item, idx) => {

            let made_date = new Date(item.createdAt);
            let res_date = item.tandemStudents && item.tandemStudents.length > 0 ? new Date(item.tandemStudents[0].reservationTime) : new Date(item.reservationTime);
            let offsetInHours = res_date.getTimezoneOffset() / 60;
            res_date.setHours(res_date.getHours() + offsetInHours);

            // made_date = made_date.toLocaleDateString() +" "+made_date.toLocaleTimeString() ;
            made_date = made_date.toLocaleDateString()
            res_date = res_date.toLocaleDateString();
            let name, email, paid = undefined, phone;
            let id = idx + 1;
            if (item.tandemStudents.length > 0) {
                name = item.tandemStudents[0].firstname + " " + item.tandemStudents[0].lastname;
                email = item.tandemStudents[0].email;
                phone = item.tandemStudents[0].phone;
            }
            else if (item.students.length > 0) {
                name = item.students[0].firstname + " " + item.students[0].lastname;
                email = item.students[0].email;
                phone = item.students[0].phone;
            }

            paid = this.makeDollarPrice(item.totalValue)
            // Extended Data Used in ExtendedComponent
            let invoices = item.invoice;
            let totalFees = item.totalFees;
            let totalValue = item.totalValue;
            let site = item.site;
            let tandemStudents = item.tandemStudents;
            let students = item.students;
            let totalTax = item.totalTax;
            let refundAmount = this.makeDollarPrice(item.refundAmount)

            return {
                id, made_date, res_date, name, email, paid, phone, refundAmount,
                invoices, totalFees, totalValue, totalTax, site, tandemStudents, students
            }
        })

        // console.log(tableData,'tableDATA')
        return tableData
    }
    returnTableData = () => {
        let { startTime, endTime, tableData, filterBy } = this.state;
        // console.log("🚀 ~ file: ReservationList.js ~ line 233 ~ ReservationList ~ filterBy", filterBy)
        /*if (filterBy !== 'none' && startTime && endTime) {
            startTime = this.returnTimeInteger(startTime);
            endTime = this.returnTimeInteger(endTime);
            // if not null filter according to date ranges
            let newTableData = tableData.filter(ele => {
                let tempTime = filterBy === 'res_date' ? ele.res_date : ele.made_date;
                tempTime = this.returnTimeInteger(tempTime);
                if (tempTime >= startTime && tempTime <= endTime) {
                    return true;
                }
                else return false;
            })
            return newTableData;
        }
        else {
            return this.state.tableData;
        }*/
        return this.state.tableData;

    }

    render() {
        const isMobile = window.innerWidth <= 500;

        return (
            <div className="row reservation-list-container" style={{ backgroundImage: `url(${background})` }}>

                {
                    isMobile ?
                        <div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
                            <div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
                                <LeftMenu showCreate={true} />
                            </div>
                        </div>
                        :
                        <div className="blueBg fullHeightPanes left-container">
                            <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
                            <LeftMenu showCreate={true} />
                        </div>
                }

                <div className="middle-container-no-rightmenu" style={{ padding: "0 15px" }}>
                    <DonutLoader isLoading={this.state.isLoading} addClass='sample1' />

                    <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
                    <FilterComponent
                        filterReservation={(s, e) => this.filterReservation(s, e)}
                        changeFilterOn={(t) => this.changeFilterOn(t)}
                    />
                    <div className="card">
                        <div className="card-body datatable-react">
                            <DataTable
                                title={'View Reservations'}
                                compact={true}
                                center={true}
                                striped={true}
                                responsive={true}
                                pagination={true}
                                expandableRows={true}
                                expandOnRowClicked={true}
                                paginationPerPage={15}
                                selectableRows
                                persistTableHead
                                contextActions={this.contextActions()}
                                customStyles={customStyles}
                                expandableRowsComponent={<ExpandedComponent />}
                                data={this.returnTableData()}
                                columns={this.returnColumns()}
                            />
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}
