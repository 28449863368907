import { create } from 'apisauce';
import authStore from '../../../stores/auth-store';
import Emitter from '../../Common/emitterServices';
import swal from 'sweetalert';

import urlgetter from '../../../services/getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});


const addToken = () => {
    let token = authStore.getAuthToken();
    if (token == '') {
        token = localStorage.getItem("Auth-token");
    }
    api.setHeader('Authorization', 'Bearer ' + token);
}

export const saveCondition = async (newCondition, setNewCondition, setEmptyTextField) => {
    if (newCondition === "") {
        setEmptyTextField(true)
        return;
    }
    addToken();
    let body = {
        newCondition
    }
    let response = await api.post("/conditions", body)
    if (response.status === 200) {
        setNewCondition("");
        Emitter.emit("conditions_changed")
    } else console.log(response, "<========saved condition error")
}

export const deleteCondition = async (event, conditionId, setIsOpenModal) => {
    let id = conditionId
    addToken();
    let response = await api.delete(`/conditions/${id}`)
    if (response.status === 200) {
        setIsOpenModal(false)
        Emitter.emit("conditions_changed")
    } else {
        setIsOpenModal(false)
        console.log(response, "<=========Condition Delete Error")
    }
}

export const editCondition = (event, condition) => {
    Emitter.emit("update_condition", condition)
}

export const updateCondition = async (newCondition, conditionId, setNewCondition,
    setEmptyTextField, setIsUpdate) => {
    console.log("update condition call")
    if (newCondition === "") {
        setEmptyTextField(true)
        setIsUpdate(false)
        return
    }

    const body = {
        description: newCondition,
        condition_id: conditionId
    }
    addToken();
    let response = await api.put("/conditions", body)
    if (response.status === 200) {
        setNewCondition("");
        setIsUpdate(false)
        Emitter.emit("conditions_changed")
    }
    else swal({
        title: "Error",
        text: "Something failed, please try again",
        icon: "error",
        dangerMode: true,
    });
}

export const handleInputChange = (event, setNewCondition, setEmptyTextField) => {
    setEmptyTextField(false);
    setNewCondition(event.target.value);
}

export const getConditions = async () => {
    addToken();
    let response = await api.get("/conditions")
    if (response.status === 200) {
        return response.data
    } else {
        console.log(response, "<=========Get Conditions Error")
    }
}

export const getAllConditions = async (setConditions) => {
    let _conditions = await getConditions();
    setConditions(_conditions.data)
    return;
}

