import {
  OPEN_MODAL,
  EDIT_RANGE,
  CLOSE_MODAL,
  DATE_SUBMIT,
  JUMPER_LOGS,
  JUMPER_NAME,
  JUMPER_STATEMENT
} from '../components/constant/reports';
import * as types from '../actions/Types'

const initialState = {
  completedLoadJumperChangeConfirmModal: false,
  modal: false,
  submit: false,
  dateRange: [],
  jumperName: '',
  loadHistory: [],
  editType: false
}

export default (state = initialState, action) => {
  // const a = state.loadHistory.find(v => v._id === action.payload[0]._id) = action.payload[0]
  switch (action.type) {
    case EDIT_RANGE:
      return { ...state, modal: true, submit: false };

    case JUMPER_NAME:
      return { ...state, jumperName: action.jumperName };
    case DATE_SUBMIT: {
      let { payload, payload: { startDate, endDate, modalType } } = action;

      const ranges = state.dateRange;
      if (startDate && endDate) {
        let index = ranges.findIndex(range => range.modalType === modalType);
        if (modalType === JUMPER_LOGS || modalType === JUMPER_STATEMENT) {
          payload.jumperName = state.jumperName;
        }
        index < 0 ? ranges.push(payload) : ranges.splice(index, 1, payload);
        return { ...state, modal: false, submit: true, dateRange: ranges };
      }
      else {
        return { ...state, modal: false, submit: true, jumperName: '' };
      }
    }
    case OPEN_MODAL:
      {

        const { modalType } = action;

        let ranges = state.dateRange;
        let hasFound = ranges
          .findIndex(
            range => range.modalType === modalType
          );
        let shouldModalOpen = hasFound < 0 ?
          { ...state, dateRange: [], modal: true, submit: false }
          :
          { ...state, dateRange: [], modal: false, submit: true };
        return shouldModalOpen;
      }
    case CLOSE_MODAL:
      {
        return { ...state, modal: false, initialized: true, submit: false };
      }
    case types.GET_LOAD_HISTORY:
      {

        return {
          ...state,
          loadHistory: action.payload
        }
      }
    case "completedLoadJumperChangeConfirmModal": {
      return {
        ...state,
        completedLoadJumperChangeConfirmModal: action.payload.isModal,
        editType: action.payload.editType
        //confirm: action.payload.confrim
      }
    }
    case types.UPDATE_LOAD_HISTORY: {
      var result = state.loadHistory.filter(function (obj) {
        return obj._id !== action.payload[0]._id;
      });
      return {
        ...state,
        //loadHistory: [...result, action.payload[0]]
        loadHistory: [action.payload[0]]
      }
    }
    default:
      return state
  }
};
