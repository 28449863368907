/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import AccountApi from '../../../services/accountapi';


import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import ItemModal from './customModal';
import reservationStore from '../../../stores/reservation-store';
import Api from '../../../services/reservationapi';
import { ClipLoader } from 'react-spinners';


class RefundModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      Jumper_id: '',
      errors: '',
      amount: this.props.transactionAmount,
      transactionId: this.props.transactionId,
      isloading: false
    }
    // this.accountEvents = [
    //   { name: AccountStore.getEventPrefix('chargeCompleted'), func: this.handleChargeCompleted }
    // ];
    // alert(this.props.transactionAmount);
  }


  toggleModal = (e) => {
    //alert();
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  handleInput = (e) => {
    this.setState({
      amount: e.target.value
    })
  }


  clickRefund = (value) => {

    // var data = this.props.data;
    // var chargetHistory = data.chargeHistory;
    // var chargeValue = chargetHistory[chargetHistory.length - 1];;
    // var amount = chargeValue.amount;
    // var tId = chargeValue.transactionId;
    // this.transactionId = tId;
    // this.amount = amount;

    // var data = this.props.data;
    // var chargetHistory = data.chargeHistory;
    // var chargeValue = chargetHistory[chargetHistory.length - 1];
    this.setState({
      isloading: true
    })
    var amount = this.props.transactionAmount;
    var tId = this.props.transactionId;
    this.transactionId = tId;
    //this.amount = this.pro.amount;
    if (tId != "") {
      var body = {
        TransactionId: tId
      }

      var response = Api.cancelAuthorizeAndCapture(body).then(this.processUndoResponse);
      // var body = this.cancelAuthorizeCaptureBody();
      // var body = AccountApi.cancelAuthorizeCaptureBody(tId);

      // //console.log(body);

      // var response = Api.cancelAuthorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), tId, body).then(this.processUndoResponse);
    }
    else {
      //console.log('Something Went Wrong !');
    }
  }

  // transferAuthorizeCaptureBody = () => {
  // 	return {
  // 		"$type": "ReturnById,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
  // 		"ApplicationProfileId": reservationStore.getApplicationProfileId(),
  // 		"MerchantProfileId": reservationStore.getMerchantProfileId(),
  // 		"DifferenceData": {
  // 			"$type":
  // 				"BankcardReturn,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
  // 			"TransactionId": this.props.transactionId,
  // 			"TransactionDateTime": '',
  // 			"Amount": this.props.transactionAmount.toFixed(2),
  // 			"Addendum": null
  // 		}
  // 	}
  // }


  // cancelAuthorizeCaptureBody = () => {
  // 	return {
  // 		"$type": "Undo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
  // 		"ApplicationProfileId": reservationStore.getApplicationProfileId(),
  // 		"DifferenceData": {
  // 			"$type":
  // 				"BankcardUndo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
  // 			"TransactionId": this.props.transactionId,
  // 			"Addendum": null
  // 		}
  // 	}
  // }

  processPaymentResponse = (r) => {
    //console.log(r);
    if (r.data !== undefined && r.data.Status === "Successful") {
      // console.log(r);
      alert('Refund Successfully');
    }
    else {
      alert('something failed, please try again');
    }
    this.setState({
      isloading: false,
      isModalOpen: false
    })
  }

  processUndoResponse = (r) => {
    // console.log(r);
    if (r.status !== undefined && r.status === 200) {
      alert('Refund Successfully');
    }
    else {
      if (r.status === 400) {
        var transactionId = this.props.transactionId;
        var amount = this.props.transactionAmount.toFixed(2);
        //var body = this.transferAuthorizeCaptureBody();
        // var body = AccountApi.transferAuthorizeCaptureBody(transactionId,amount);
        // var response = Api.authorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), body).then(this.processPaymentResponse);
        var body = {
          transactionId: transactionId,
          refundAmount: amount
        };

        console.log(body);
        var response = Api.authorizeAndCapture("refund", body).then(this.processPaymentResponse);
      }
    }
    this.setState({
      isloading: false,
      isModalOpen: false
    })
  }

  render() {
    //console.log("this.props.transactionAmount refund",this.props.transactionAmount,this.props.transactionId);

    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <React.Fragment>
        <ItemModal
          isModalOpen={this.state.isModalOpen}
          title={`Charge Jumper`}
        >

          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>

          <LocalForm onSubmit={(values) => this.clickRefund(values)}>

            <Row className="form-group">
              <Label htmlFor="pilot" md={4}>Enter Amount :</Label>
              <Col md={8}>
                <Control.input name="amount" model=".amount" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }} value={this.props.transactionAmount}
                  onChange={(e) => this.handleInput(e)} autoComplete="off" />
                {/* <span className="text-danger error-validation">{this.state.errors["amount"]}</span> */}
              </Col>
            </Row>

            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 9 }}>
                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>

          </LocalForm>
        </ItemModal>

        {/* <Modal isOpen={this.state.errorOpen} toggle={this.toggleError} className={this.props.className}>
          <ModalHeader toggle={this.toggleError} className="error-jumperHeader">
            Warning
          </ModalHeader>
          <ModalBody>
            <p style={{ color: 'red', fontSize: '20px', marginLeft: '1%' }}>Something Wrong happened while charging Jumper !</p>
          </ModalBody>
        </Modal> */}


        <button className="btn customButton rightButton" style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764' }} onClick={() => this.toggleModal()}>
          Refund
        </button>
      </React.Fragment>
    );
  }
}

export default RefundModal