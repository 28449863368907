import loadsStore from "../../../stores/loads-store"

const multiselect = (dragabbleItems, loadNum) => {
  let items = dragabbleItems
  let Jupms = []
  items.map((el, ind) => {
    if (el.jumpType === "tandemJump") {
      let payload = {};
      payload = el.tandemJump
      let today = new Date();
      let isWeekend = (today.getDay() % 6) === 0;
      payload.isWeekend = isWeekend
      payload.instructorId = el.tandemJump.instructor['_id']
      payload.studentId = el.tandemJump.student['_id']
      payload.videographerId = el.tandemJump.videographer?.['_id']
      payload.type = el.jumpType
      payload.jumpId = el.jumpId
      Jupms.push(payload)
    } else if (el.jumpType === "funJump") {
      var payload = {};
      payload.jumperIds = el.funJump.jumpers.map(jumper => {
        return {
          jumperId: jumper._id,
          price: jumper.price,
          block: jumper.block,
          blockId: jumper.blockId,
          gearPrice: jumper.gearPrice,
          gearType: jumper.gearType,
          isLOing: jumper.isLOing,
          isVideographer: jumper.isVideographer,
          chargeTo: jumper.chargeTo,
          tracked: jumper.tracked
        }
      });
      payload.jumpType = el.funJump.jumpType;
      payload.gearRentals = el.funJump.gearRentals;
      payload.studentgearRentals = el.funJump.studentgearRentals;
      payload.sportgearRentals = el.funJump.sportgearRentals;
      payload.altitude = el.funJump.altitude;
      payload.teamName = el.funJump.teamName;
      payload.teamType = el.funJump.teamType;
      payload.teamId = el.funJump.teamId;
      payload.copy = true;
      payload.type = el.jumpType
      payload.jumpId = el.jumpId
      Jupms.push(payload)
    } else if (el.jumpType === "affJump") {
      var payload = {};
      payload = el.affJump;
      if (el.affJump.mainInstructor) {
        payload.mainInstructorId = el.affJump.mainInstructor["_id"]
      }
      payload.studentId = el.affJump.student["_id"]
      if (el.affJump.secondaryInstructor) {
        payload.secondaryInstructorId = el.affJump.secondaryInstructor["_id"]
      }
      payload.videographerId = el.affJump.videographer["_id"];
      payload.copy = true;
      payload.type = el.jumpType
      payload.jumpId = el.jumpId
      Jupms.push(payload)
    } else if (el.jumpType === "coachJump") {
      var payload = {};
      payload = el.coachJump
      payload.coachId = el.coachJump.coach["_id"]
      payload.jumperId = el.coachJump.jumper["_id"]
      payload.type = el.jumpType
      payload.jumpId = el.jumpId
      Jupms.push(payload)
    }
  })
  loadsStore.copyValue = Jupms;
  if (loadNum !== undefined) {
    loadsStore.cut = true;
    loadsStore.cutValue = { loadNum }
  } else {
    loadsStore.copied = true;
    loadsStore.cut = false;
  }
}

export default multiselect;
