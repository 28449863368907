/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

import itemStore from '../../../stores/item-store';
import scheduleStore from '../../../stores/schedule-store'
import AdminModal from './customModal';
import swal from 'sweetalert';
class EditItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            eventsList: []
        }
    }

    componentDidMount = () => {
        scheduleStore.getEvents()
            .then(res => {
                this.setState({ eventsList: res?.data?.filter(el => el?.packageCategory == "Tandem") })
            })
    }

    toggleModal = (e) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    handleSubmit = (values) => {
        //console.log("in handle submit load"+ values);
        console.log(values, '<<<<<<<<<<<<values>>>>>>>>>')
        console.log(values.calenderEvent, '+++++++++++++++')
        if (!values.altitude || !values.calenderEvent || values.altitude === "none" || values.calenderEvent === "none") {

            swal(
                {
                    title: "Validation error",
                    text: "Please check altitude and calender events must be selected",
                    icon: "error",
                }
            )
            return;
        }
        this.toggleModal();
        var payload = {};
        if (values !== undefined) {
            payload.item = values.item;
            payload.displayText = values.displayText;
            payload.category = "tandem package";
            payload.price = Number(values.price);
            payload.weekendPrice = Number(values.weekendPrice);
            payload.videoType = values.videoType;
            payload.videoPrice = Number(values.videoPrice);
            payload.pay = Number(values.instructorPay);
            payload.videoPay = Number(values.videoPay);
            payload.wristcamPay = Number(values.wristcamPay);
            payload.discountCode = values.discountCode
            payload.altitude = values.altitude
            payload.calenderEvent = values.calenderEvent
            payload.description = values.description;
            payload.isBookable = values.bookable;
            payload.isTaxable = values.taxable;
        }
        if (this.props.isEdit) {
            itemStore.updateItem(this.props.item._id, payload);
        }
        else {
            if (payload.videoType == "") {
                payload.videoType = "none";
            }
            itemStore.createItem(payload);
        }
    }

    returnKeyPress = (evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '+') && evt.preventDefault()

    render() {
        var item = { item: "", price: "", weekendPrice: "", videoType: "", videoPrice: "", instructorPay: "", videoPay: "", wristcamPay: "", description: "", isBookable: false, isTaxable: false };
        if (this.props.item !== undefined) {
            item = this.props.item;
        }
        var altitudes = itemStore.getAltitudes();
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Tandem Package`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="item" md={3}>Item</Label>
                            <Col md={9}>
                                <Control.input name="item" model=".item" className="form-control" defaultValue={item.item}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="item" md={3}>Display Text</Label>
                            <Col md={9}>
                                <Control.input name="displayText" model=".displayText" className="form-control" defaultValue={item.displayText}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price" onKeyDown={(evt) => this.returnKeyPress(evt)} type="number" step="any" model=".price" className="form-control" defaultValue={item.price}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="weekendPrice" md={3}>Weekend Price</Label>
                            <Col md={9}>
                                <Control.input name="weekendPrice" onKeyDown={(evt) => this.returnKeyPress(evt)} type="number" step="any" model=".weekendPrice" className="form-control" defaultValue={item.weekendPrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>


                        <Row className="form-group">
                            <Label htmlFor="videoType" md={3}>Video Type</Label>
                            <Col md={9}>
                                <Control.select name="videoType" model=".videoType" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.videoType}>
                                    <option value="none">none</option>
                                    <option value="wristcam">wristcam</option>
                                    <option value="outside">outside</option>
                                    <option value="both">both</option>
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="videoPrice" md={3}>Video Price</Label>
                            <Col md={9}>
                                <Control.input onKeyDown={(evt) => this.returnKeyPress(evt)} type="number" step="any" name="videoPrice" model=".videoPrice" className="form-control" defaultValue={item.videoPrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="instructorPay" md={3}>Instructor Pay</Label>
                            <Col md={9}>
                                <Control.input onKeyDown={(evt) => this.returnKeyPress(evt)} type="number" step="any" name="instructorPay" model=".instructorPay" className="form-control" defaultValue={item.pay}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="videoPay" md={3}>Video Pay</Label>
                            <Col md={9}>
                                <Control.input onKeyDown={(evt) => this.returnKeyPress(evt)} type="number" step="any" name="videoPay" model=".videoPay" className="form-control" defaultValue={item.videoPay}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="wristcamPay" md={3}>Wristcam Pay</Label>
                            <Col md={9}>
                                <Control.input onKeyDown={(evt) => this.returnKeyPress(evt)} type="number" step="any" name="wristcamPay" model=".wristcamPay" className="form-control" defaultValue={item.wristcamPay}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="code" md={3}>Discount Code</Label>
                            <Col md={9}>
                                <Control.input name="discountCode" model=".discountCode" className="form-control" defaultValue={item.discountCode}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="altitude" md={3}>Altitude</Label>
                            <Col md={9}>
                                <Control.select name="altitude" model=".altitude" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.altitude}>
                                    <option value="none">none</option>
                                    {altitudes.map(alt => <option value={alt.name}>{alt.name}</option>)}
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="calenderEvent" md={3}>Calender events</Label>
                            <Col md={9}>
                                <Control.select name="calenderEvent" model=".calenderEvent" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.calenderEvent}>
                                    <option value="none">none</option>
                                    {this.state.eventsList.map(evt => <option value={evt.eventName}>{evt.eventName}</option>)}
                                </Control.select>
                            </Col>
                        </Row>


                        <Row className="form-group">
                            <Label htmlFor="description" md={3}>Description</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.textarea name="description" model=".description" className="form-control" defaultValue={item.description}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="bookable" md={3}>Bookable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="bookable" model=".bookable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isBookable}
                                    autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="taxable" md={3}>Taxable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="taxable" model=".taxable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isTaxable}
                                    autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {!this.props.isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </React.Fragment>
        );
    }
}

export default EditItem
