/**
 * http://usejsdoc.org/
 */
import React, { Component, Fragment } from 'react';
import {
    Col, Row, Form, FormGroup, Label, Input, FormFeedback
} from 'reactstrap';
import Select from 'react-select';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import Fuse from "fuse.js";

import TeamModal from './customModal';
import loadsStore from '../../../stores/loads-store';
import jumpersStore from '../../../stores/jumpers-store';
import itemStore from '../../../stores/item-store'
import swal from 'sweetalert';

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 30
    }),
    menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
        height: 730
    }),
    menuList: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        padding: 20,
        minHeight: 700
    })
};
class CreateTeams extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            name: "",
            jumperSelected: [],
            videographer: "",
            videographerChargeTo: '',
            videographerSlotPrice: '',
            videographerSlotPriceError: '',
            slotPrice: "",
            teamType: "",
            compType: "",
            jumpType: "",
            error: {},
            isUpdatesNeeded: false,
            isError: false,
            row: [{ jumper: '', slotPrice: '', chargeTo: '', error: '', priceError: '', isVideographer: false }],
            altitudeType: ""
        }
        this.inputValue = '';
        this.fuse = null;
    }

    toggleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen })
    }

    checkifOk = () => {
        //alert("is ok");
        let { videographer, teamType, name, compType, jumpType, row } = this.state;
        console.log(videographer, "videographer- checkifOk")
        let errObj = {}
        let isError = true
        let ids = row.map(el => {
            return el?.jumper?.value?.id
        })
        if (!name) {
            errObj["name"] = "Please enter valid name"

        }

        if (!teamType || teamType == "none") {
            errObj["teamType"] = "Please enter valid team"
        }

        if (jumpType === "none") {
            errObj["jumpType"] = "Please enter valid Jump Type"
        }

        // if (videographer === "") {
        //     errObj["videographer"] = "Must Select Videographer"

        // } else if (ids.includes(videographer.value.id)) {
        //     errObj["videographer"] = "Videographer Cant be jumper"

        // }


        const d = [...row];
        
        let jumperIds = d.map(d =>  {
            if(d.jumper){
                return d.jumper?.value?.id
            }
            else{
                return d.jumperId
            }
        });

        console.log(d);
        console.log(jumperIds);
        if(jumperIds.length !== (new Set(jumperIds)).size){
            errObj[`duplicateError`] = "please remove duplicate jumper";
            swal({
                title: "Warning",
                text: "duplicate jumpers",
                icon: "warning",
                dangerMode: false,
            })
            //swal('duplicate jumpers listed');
        }
    

        d.forEach((el, i) => {
            if (el.jumper === '') {
                el.error = "please select jumper"
                errObj[`jumperError${i}`] = "please select jumper"
            }
            else {
                el.error = null
            }
            if (!el.slotPrice) {
                el.priceError = "please enter valid slot price"
                errObj[`priceError${i}`] = "please enter valid slot price"
            } else {
                el.priceError = null
            }
        })

        if (errObj && Object.keys(errObj).length === 0 && errObj.constructor === Object) {
            isError = true
        } else {
            isError = false
        }
        this.setState({ error: errObj, row: d })
        return isError
    }
    handleSubmit = (e) => {
        let { jumperSelected, videographerSlotPrice, videographerChargeTo, slotPrice, teamType, jumpType, name, compType, row, altitudeType } = { ...this.state };
        let { isEdit, item } = this.props;
        e.preventDefault();
        if (this.checkifOk()) {
            let payload = {}

            payload.teamname = name;
            payload.firstname = name;
            payload.nickname = name;
            
            payload.teamMembersInfo = row.map(rw => {
                return {
                    jumperId: rw.jumper?.value?.id || rw.jumperId,
                    nickname: rw.jumper?.value?.nickname || rw.nickname,
                    firstname: rw.jumper?.value?.firstname || rw.firstname,
                    lastname: rw.jumper?.value?.lastname || rw.lastname,
                    slotPrice: rw.slotPrice,
                    chargeTo: rw.chargeTo,
                    isVideographer: rw.isVideographer ? rw.isVideographer : false
                }
            });

            // payload.videographerInfo ={
            //   jumperId:  videographer?.value?.id,
            //   nickname: videographer?.value?.nickname,
            //   firstname: videographer?.value?.firstname,
            //   lastname:  videographer?.value?.lastname,
            //   slotPrice: Number(videographerSlotPrice),
            //   chargeTo:  videographerChargeTo
            // };
            payload.teamType = teamType;
            payload.jumpType = jumpType;
            payload.compType = compType;
            payload.isTeam = true;
            payload.defaultAltitude = altitudeType;

            // if (isEdit) {
            //     payload.id = item._id;
            //     payload.isEdit = true;
            // }
            //loadsStore.createEditTeams(payload)
            if (isEdit) {
                jumpersStore.updateJumper(item._id, payload)
                    .then(r => {
                        this.setState({ isModalOpen: false })
                        this.props.updateItem(r, isEdit)
                    })
            } else {
                jumpersStore.CreateJumper(payload)
                    .then(r => {
                        if (r.createdAt) {
                            this.setState({
                                name: "",
                                jumperSelected: [],
                                videographer: "",
                                slotPrice: "",
                                teamType: "",
                                jumpType: "",
                                isModalOpen: false,
                                error: {},
                                isUpdatesNeeded: true,
                                compType: "",
                                row: [{ jumper: '', slotPrice: '', error: '', priceError: '', isVideographer: false }],
                            }, () => {
                                this.props.updateItem(r, isEdit);
                            })
                        }
                    })
            }

        }
    }
    componentDidMount() {
        let { item } = this.props;
        if (item) {
            let filterVideographer = this.props.jumperOptions.find(data => data?.value?.id === item?.videographerInfo?.jumperId)
            let filterJumper = []
            item?.teamMembersInfo?.map(i => {
                let dataJumper = this.props.jumperOptions.find(data => data?.value?.id === i?.jumperId)
                filterJumper.push(dataJumper)
            })
            this.setState({
                name: item.firstname,
                jumperSelected: filterJumper,
                videographer: filterVideographer,
                slotPrice: item.slotPrice,
                teamType: item.teamType,
                jumpType: item.jumpType,
                // videographerSlotPrice: item.videographerInfo.slotPrice,
                // videographerChargeTo: item.videographerInfo.chargeTo,
                compType: item.compType,
                altitudeType: item.defaultAltitude,
                row: item.teamMembersInfo,
            })
        }
        this.setFuse(this.props.jumperOptions);
    }
    componentDidUpdate(prevProps, prevState) {
        let { item } = this.props;
        let filterJumper = []
        item?.teamMembersInfo?.map(i => {
            let dataJumper = this.props.jumperOptions.find(data => data?.value?.id === i?.jumperId)
            filterJumper.push(dataJumper)
        })
        if (prevProps.item !== this.props.item) {
            let filterVideographer = this.props.jumperOptions.find(data => data?.value?.id === item?.videographerInfo?.jumperId)
            this.setState({
                name: item.firstname,
                jumperSelected: filterJumper,
                videographer: filterVideographer,
                slotPrice: item.slotPrice,
                teamType: item.teamType,
                jumpType: item.jumpType,
                // videographerSlotPrice: item.videographerInfo.slotPrice,
                compType: item.compType,
                altitudeType: item.defaultAltitude,
                row: item.teamMembersInfo,
            })
        }
        this.setFuse(this.props.jumperOptions);
    }
    Option = (props) => {
        // console.log(props);
        return (
            <div className="row">
                <div className="col-12">
                    <components.Option {...props} />
                </div>
            </div>
        );
    }
    handleChange = (e, type) => {

        let value = e.target.value;
        if (type === 'name') {
            this.setState({ name: value, isError: false })
        }
        else {
            this.setState({
                slotPrice: value,
                isError: false
            })
        }
    }
    renderTypes = () => {
        let options = ['2-way FS', '4-way FS', '4-way FS Open', '4-way FS Advanced', '4-way FS Beginner',
            '8-way FS', '8-way FS Open', '8-way FS Advanced ', '8-way FS Intermediate', '6-way Speed Formation',
            "10-way FS", "16-way FS", '2-way VFS',
            "4-way VFS", '4-way VFS Open', '4-way VFS Advanced', '4-way VFS Intermediate',
            '2 way-MFS', '2 way-MFS Open', '2 way-MFS Advanced',
            'Artistic Freestyle', "Artistic Freeflying",
            '2-Way Sequential Open', '2-Way Sequential Pro-Am', '4-Way Sequential', '4-Way Rotation',
            'Individual Accuracy', "Team Accuracy",
            'Acrobatic Wingsuit', 'Performance Wingsuit Open', 'Performance Wingsuit Advanced', "Performance Wingsuit",
            'Speed',
            'Canopy', "Canopy Freestyle"];
        let typeList = options.map((ele, idx) => {
            return <option key={idx} value={ele}>{ele}</option>
        })
        return typeList;
    }

    renderJumpTypes = () => {
        let options = loadsStore.getFunJumpTypes();
        let typeList = options.map((ele, idx) => {
            return <option key={idx} value={ele}>{ele}</option>
        })
        return typeList;
    }
    handleJumperChange = (value, index) => {
        console.log('handleJumperChange');
        console.log(value);
        console.log(index);
        let newRows = [...this.state.row];
        console.log(newRows);
        newRows[index].jumper = value;
        this.setState({ jumperSelected: value, isError: false, row: newRows })
    }
    handleVideographer = (value) => {
        console.log('handle videographer');
        console.log(value);
        this.setState({ videographer: value, isError: false })
    }
    handleTeamType = (e) => {
        let value = e.target.value;
        this.setState({ teamType: value, isError: false })
    }
    handleJumpType = (e) => {
        let value = e.target.value;
        this.setState({ jumpType: value, isError: false })
    }
    handleComp = (e) => {
        let value = e.target.value;
        this.setState({ compType: value, isError: false })
    }
    handleAltitude = (e) => {
        let value = e.target.value;
        this.setState({ altitudeType: value, isError: false })
    }

    isError = (type) => {
        let { error, isError } = this.state;
        // console.log("🚀 ~ file: EmailModal.js ~ line 52 ~ EmailModal ~ error", error,type)
        if (isError && error[type]) {
            let text = error[type].text;
            return text;
        }

    }


    setFuse = (jumperOptions) => {
        var options = {
            shouldSort: true,
            tokenize: true,
            threshold: 0.1,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 2,
            keys: [
                "value.manifestNumber",
                "value.nickname",
                "value.lastname"
            ]
        };

        this.fuse = new Fuse(jumperOptions, options);
    }
    filterJumpers = (inputValue, jumperOptions) => {
        console.log('in create team filter jumpers');
        console.log(inputValue);

        if (inputValue) {
            //var filteredOptions = this.fuse.search(inputValue.toLowerCase());
            var filteredOptions = jumperOptions.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase()));
            console.log(filteredOptions);
            if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
            else return filteredOptions;
        }
        return jumperOptions;
    };

    promiseOptions = (inputValue, jumperOptions) => {
        return new Promise(resolve => {
            resolve(this.filterJumpers(this.inputValue, jumperOptions));
        });
    }
    handleInputChange = (newValue) => {
        this.inputValue = newValue;
        //this.valRef.focus();
    };


    returnVideoOptions = () => {
        let { jumperOptions } = this.props;
        let { jumperSelected } = this.state;
        if (jumperOptions.length > 0 && jumperSelected.length > 0) {
            let filteredArr = jumperOptions.filter(ele => {
                if (jumperSelected.findIndex(v => v.value.id === ele.value.id) === -1) {
                    return true;
                }
                else {
                    return false;
                }
            })
            return filteredArr;
        }
        else {
            return jumperOptions;
        }
    }
    addRow = (i, e) => {
        let r = [...this.state.row, { jumper: '', slotPrice: '', chargeTo: '' }]
        this.setState({
            row: r
        })
        // if (i >= 7) {
        //     e.preventDefault()
        // } else {
        //     let r = [...this.state.row, { jumper: '', slotPrice: '', chargeTo: '' }]
        //     this.setState({
        //         row: r
        //     })
        // }
    }

    deleteRow = (ind, e) => {
        let r = [...this.state.row]
        const fR = r.filter((r, i) => i !== ind)
        this.setState({
            row: fR
        })

    }
    handleSlotChange = (e, i) => {
        let newRows = [...this.state.row]
        newRows[i]['slotPrice'] = e.target.value
        this.setState({ row: newRows })
    }

    handleChargeToSelect = (e, i) => {
        console.log('handle Charge To select');
        console.log(e.target.value);
        let newRows = [...this.state.row]
        newRows[i]['chargeTo'] = e.target.value
        this.setState({ row: newRows })
    }

    handleVideoChargeToSelect = (e, i) => {
        console.log('handle Videographer Charge To select');
        this.setState({ videographerChargeTo: e.target.value })
    }

    handleVideographerSlotChange = (e, i) => {
        this.setState({ videographerSlotPrice: e.target.value })
    }
    returnCompetitionOptions = () => {
        return [
            { label: 'Regular', value: 'regular' },
            { label: 'Nationals 2021', value: 'nationals_2021' },
            { label: 'Mundial 2022', value: 'mundil_2022' }
        ]
    }
    handleCompType = (values) => {
        this.setState({ compType: [values], isError: false })
    }

    handleVideographerState = (e, i) => {
        let newRows = [...this.state.row]
        newRows[i]['isVideographer'] = e.target.checked
        this.setState({ row: newRows })
    }

    render() {
        let altitude = itemStore.getAltitudes()
        let { isModalOpen, isError, jumperSelected, videographer, name, slotPrice, teamType, compType, altitudeType, jumpType, row } = this.state;
        let { isEdit, jumperOptions, item } = this.props;
        return (
            <React.Fragment>
                <TeamModal
                    isModalOpen={isModalOpen}
                    title={isEdit ? 'Edit Teams' : 'Create Teams'}
                    classes="teams-modal"
                >
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup row>
                            <Label for="name" sm={3}>Name</Label>
                            <Col sm={9}>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="name"
                                    value={name}
                                    onChange={(e) => this.handleChange(e, 'name')}
                                />
                                <p style={{ color: 'red' }} >{this.state.error["name"]}</p>
                            </Col>
                        </FormGroup>

                        {
                            row.map((el, i) => {
                                return (
                                    <Fragment>
                                        <div style={{
                                            border: '1px solid #fff',
                                            borderRadius: '8px',
                                            padding: '8px',
                                            marginBottom: '8px'
                                        }} >
                                            <FormGroup row>
                                                <Label for="jumpers" sm={3}>Jumpers: </Label>
                                                <Col sm={9}>
                                                    <AsyncSelect
                                                        loadOptions={(newValue) => this.promiseOptions(newValue, this.props.jumperOptions)}
                                                        onInputChange={(val) => this.handleInputChange(val)}
                                                        onChange={(val) => this.handleJumperChange(val, i)}
                                                        placeholder={this.props.placeholder}
                                                        defaultOptions={this.props.jumperOptions.slice(0, 10)}
                                                        components={{ Option: this.Option }}
                                                        styles={customStyles}
                                                        value={jumperSelected[i]}
                                                    />
                                                    <p style={{ color: 'red' }} > {el.error}</p>
                                                </Col>
                                                <Label for="slotprice" sm={3}>Slot price: </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        type="number"
                                                        name="slotprice"
                                                        id="slotprice"
                                                        placeholder="slotprice"
                                                        // min={0}
                                                        value={el.slotPrice}
                                                        onChange={(e) => this.handleSlotChange(e, i)}
                                                    />
                                                    <p style={{ color: 'red' }} > {el.priceError}</p>
                                                </Col>

                                                <Label for="chargeto" sm={3}>charge to : </Label>
                                                <Col sm={9}>
                                                    <Input type="select" value={el.chargeTo} name="select" id="chargeto" onChange={(e) => this.handleChargeToSelect(e, i)}>
                                                        <option value="none">none</option>
                                                        <option value="individual">individual account</option>
                                                        <option value="team">team account</option>
                                                    </Input>
                                                    <p style={{ color: 'red' }} >{this.state.error["chargeTo"]}</p>
                                                </Col>
                                                <Label for="chargeto" sm={3}>Videographer </Label>
                                                <Col sm={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Input
                                                        style={{ margin: 0, cursor: 'pointer', width: 16, height: 16 }}
                                                        type="checkbox" name="checkbox" id="videographer"
                                                        onChange={(e) => this.handleVideographerState(e, i)}
                                                        value={el.isVideographer}
                                                        defaultChecked={el.isVideographer}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 8 }} >
                                            {this.state.row.length > 1 && <div style={{ cursor: 'pointer', padding: '0 20px' }} className="">
                                                <i onClick={(e) => this.deleteRow(i, e)} className="fa fa-minus-circle"></i>
                                            </div>}
                                            {((this.state.row.length - 1 === i)) ? <div style={{ cursor: 'pointer' }} className="">
                                                <i onClick={(e) => this.addRow(i, e)} className="fa fa-plus-circle"></i>
                                            </div> : null}
                                        </div>

                                    </Fragment>
                                )
                            })
                        }



                        <FormGroup row>
                            <Label for="teamtype" sm={3}>Team Type: </Label>
                            <Col sm={9}>
                                <Input type="select" value={teamType} name="select" id="teamtype" onChange={this.handleTeamType}>
                                    <option value="none">none</option>
                                    {this.renderTypes()}
                                </Input>
                                <p style={{ color: 'red' }} >{this.state.error["teamType"]}</p>
                            </Col>
                            <Label for="jumptype" sm={3}>Jump Type: </Label>
                            <Col sm={9}>
                                <Input type="select" value={jumpType} name="select" id="jumptype" onChange={this.handleJumpType}>
                                    <option value="none">none</option>
                                    {this.renderJumpTypes()}
                                </Input>
                                <p style={{ color: 'red' }} >{this.state.error["jumpType"]}</p>
                            </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                            <Label for="videographer" sm={3}>Videographer: </Label>
                            <Col sm={9}>
                                <AsyncSelect
                                    loadOptions={(newValue) => this.promiseOptions(newValue, this.props.jumperOptions)}
                                    onInputChange={(val) => this.handleInputChange(val)}
                                    onChange={(val) => this.handleVideographer(val)}
                                    placeholder={this.props.placeholder}
                                    defaultOptions={this.props.jumperOptions.slice(0, 10)}
                                    components={{ Option: this.Option }}
                                    styles={customStyles}
                                    // defaultValue={videoGrapher}
                                    value={videographer}
                  							/>
                                <p style={{color: 'red'}} >{this.state.error["videographer"]}</p>
                            </Col>
                            <Label for="slotprice" sm={3}>Slot price: </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="slotprice"
                                    id="slotprice"
                                    placeholder="slotprice"
                                    min={0}
                                    value={this.state.videographerSlotPrice}
                                    onChange={(e) => this.handleVideographerSlotChange(e)}
                                />
                                <p style={{ color: 'red' }} > {this.state.videographerSlotPriceError}</p>
                            </Col>
                            <Label for="videochargeto" sm={3}>Videographer charge to : </Label>
                            <Col sm={9}>
                                <Input type="select" value={videographerChargeTo} name="select" id="videochargeto" onChange={(e) => this.handleVideoChargeToSelect(e)}>
                                    <option value="individual">individual account</option>
                                    <option value="team">team account</option>
                                </Input>
                                <p style={{ color: 'red' }} >{this.state.error["VideographerChargeTo"]}</p>
                            </Col>
                        </FormGroup> */}
                        <FormGroup row>
                            <Label sm={3}>Default Altitude: </Label>
                            <Col sm={9}>
                                <Input type="select" value={altitudeType} name="select" id="altitudeType" onChange={this.handleAltitude}>
                                    <option value="none">none</option>
                                    {altitude?.map(i => {
                                        return <option value={i.name}>{i.name}</option>
                                    })}
                                </Input>
                                <p style={{ color: 'red' }} >{this.state.error["altitudeType"]}</p>
                            </Col>
                        </FormGroup>
                        {window.ENV?.arizona && <FormGroup row>
                            <Label for="comptype" sm={3}>Competition: </Label>
                            <Col sm={9}>
                                <Input type="select" value={compType} name="select" id="comptype" onChange={this.handleComp}>
                                    <option value="none">none</option>
                                    <option value="nationals2021">Nationals 2021</option>
                                    <option value="mundial2022">Mundial 2022</option>
                                </Input>
                                <p style={{ color: 'red' }} >{this.state.error["compType"]}</p>
                            </Col>
                        </FormGroup>}

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button disabled={isError} className="btn btn-bg lightColor createButton" type="submit" >
                                    SUBMIT
                                </button>
                                <button
                                    onClick={(e) => { e.preventDefault(); this.toggleModal(); }}
                                    className="btn btn-link"
                                >
                                    CANCEL
                                </button>
                            </Col>
                        </Row>
                    </Form>

                </TeamModal>
                {!isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </React.Fragment>
        );
    }
}

export default CreateTeams
