import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { jsonToCSV } from 'react-papaparse';

import FileSaver from 'file-saver';

import {
    Paper,
    Table,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
    JUMPER_LOGS,
    NUMBER_SLOTS,
    STAFF_SUMMARY,
    JUMPER_STATEMENT,
    INVOICE_BY_CATEGORY,
    LOAD_HISTORY,
    DETAIL_BY_CATEGORY,
    GIFT_CARD,
    TANDAM_STUDENT,
    SLOTS_BY_PLANE,
    JUMPER_STATE,
    TANDEM_STATE
} from '../../../constant/reports';

import JumperLogs from './JumperLogs';
import FormDialog from '../modal/Modal';
import NumberSlots from './NumberSlots';
import { styles } from './DataTableStyle';
import StaffSummary from './StaffSummary';
import JumperStatement from './JumperStatement';
import InvoiceByCategory from './InvoiceByCategory';
import DetailByCategory from './DetailByCategory';
import JumperState from './JumperState';
import TandemState from './TandemState';
import LoadHistory from './LoadHistory';
import { rangeSubmit, editModal, closeModal } from '../../../../actions/reports';
import reportStore from '../../../../stores/report-store';
//import { ClipLoader } from 'react-spinners';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JumperStatementPdf from '../../../PrintPdf/jumperStatmentPdf'
import GiftCardTable from './GiftCardTable';
import TandemStudentTable from './TandemStudentTable';
import DonutLoader from '../../../loadManager/DonutLoader';
import EmailModal from './EmailModal';
import SlotByPlane from './SlotByPlane';


let data = localStorage.getItem("StaffData")
let header = JSON.parse(data)?.headers ? JSON.parse(data)?.headers : []

class DataTable extends Component {
    constructor(props) {
        super(props);
        //alert(JSON.stringify(this.props.data))
        this.state = {
            submit: undefined,
            data: this.props.data,
            dateRange: [],
            csvPrintLoading: false,
            pdfPrintLoading: false,
            categoryDetailsData: [],
            categoryDetailsHeader: [],
            headers: [],
            count: 0
            // isDetailByCat:false,
            // isloading:false
        };
        this.reportEvent = [
            { name: reportStore.getEventPrefix('reportDetails'), func: this.handleReportDetails }
        ];
    }
    componentDidMount = () => {
        this.reportEvent.forEach((evnt) => reportStore.on(evnt.name, evnt.func))
    }

    componentWillUnmount = () => {
        this.reportEvent.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }
    handleReportDetails = (report) => {
        this.setState({ categoryDetailsData: report.data, categoryDetailsHeader: report.headers, _categoryDetailsHeader: report._headers });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {


        this.setState({
            submit: nextProps.submit,
            dateRange: nextProps.dateRange
        })
    }


    ComponentRenderer = () => {

        const { tableType, data } = this.props;
        let { submit, dateRange } = this.state;

        switch (tableType) {
            case SLOTS_BY_PLANE: {
                return <SlotByPlane
                    range={this.state}
                />
            }
            case JUMPER_STATE: {
                return <JumperState />
            }
            case TANDEM_STATE: {
                return <TandemState />
            }
            case INVOICE_BY_CATEGORY: {
                return <InvoiceByCategory
                    data={data}
                    dateParam={dateRange}
                    isModal={this.props.open}
                    range={this.state}
                    submit={submit}
                />;
            }
            case JUMPER_LOGS: {
                return <JumperLogs
                    data={data}
                    dateParam={dateRange}
                    isModal={this.props.open}
                    range={this.state}
                    submit={submit} />;
            }
            case STAFF_SUMMARY: {
                return <StaffSummary
                    data={data}
                    dateParam={dateRange}
                    isModal={this.props.open}
                    range={this.state}
                    submit={submit}
                    setHeaders={(data) => this.setState({ headers: data })}
                    heads={this.state.headers}
                />;
            }
            case NUMBER_SLOTS: {
                return <NumberSlots
                    data={data}
                    dateParam={dateRange}
                    isModal={this.props.open}
                    range={this.state}
                    submit={submit} />;
            }
            case JUMPER_STATEMENT: {
                return <JumperStatement
                    data={data}
                    dateParam={dateRange}
                    isModal={this.props.open}
                    range={this.state}
                    submit={submit}
                />;
            }
            case LOAD_HISTORY: {
                return <LoadHistory
                    data={data}
                    isModal={this.props.open}
                    range={this.state}
                    submit={submit}
                />;
            }
            case DETAIL_BY_CATEGORY: {
                return <DetailByCategory
                    data={data}
                    isModal={this.props.open}
                    range={this.state}
                    submit={submit}
                    type={this.props.tableType}
                />;
            }
            case GIFT_CARD: {
                return <GiftCardTable
                    data={data}
                    isModal={this.props.open}
                    dateRange={this.state.dateRange}
                    submit={true}
                />;
            }
            case TANDAM_STUDENT: {
                return <TandemStudentTable
                    tandemStudents={data}
                    isModal={this.props.open}
                    dateRange={this.state.dateRange}
                    submit={true}
                />;
            }
            default:
                return [];
        }
    }



    subHeadings = () => {
        const { tableType } = this.props;
        switch (tableType) {
            case SLOTS_BY_PLANE: {
                return ['plane', 'loads', 'funJumpsSlots', 'hopNPopSlots', 'tandemSlots', 'studentSlots', 'outsideVideoSlots', 'total'];
            }
            case INVOICE_BY_CATEGORY: {
                if(window.ENV.secondSalesTax){
                    return ['', 'Count', "Sales Tax", 'Airport Facilities Fees', 'Subtotal', 'Total'];
                }
                else{
                    return ['', 'Count', 'Tax', 'Subtotal', 'Total'];
                }
            }
            case JUMPER_LOGS: {
                return ['Jump Type', 'Date'];
            }
            case STAFF_SUMMARY: {
                let headr = []
                if (this.state?.headers?.length > 0) {
                    this.state?.headers?.map(i => {
                        headr.push(i.replace(/([A-Z])/g, ' $1').trim())
                    })
                }
                return this.state.headers?.length > 0 ? ["member", ...headr, 'incurred', 'collection', 'earned', 'payout', 'Transfer In', 'Transfer Out'] : ["member", ...header, 'incurred', 'collection', 'earned', 'payout', 'Transfer In', 'Transfer Out']
            }
            case NUMBER_SLOTS: {
                return ['Total Slots'];
            }
            case JUMPER_STATEMENT: {
                return ['Jump Type', 'Date', 'Price', 'Load Num'];
            }
            case GIFT_CARD: {
                return ['Coupon Code', 'Name', 'Status', 'Expiry In Days', 'Price', 'Tax Amount', 'Total Booking Fee', 'Refunded Amount', 'Refunded Tax', 'Refunded Booking Fee', 'Refunded On Date', "Purchased On", "Tandem Package Name", "Video Package Name", 'Purchaser', 'Recipient', 'Card used', 'Resend Email', 'Refund'];
            }
            case TANDAM_STUDENT: {
                return ['FirstName', 'LastName', 'Email', 'Phone', 'TI', 'OV', 'tandem package', 'Address', 'Last Jump Date']
            }
            // case DETAIL_BY_CATEGORY: {
            //     return ['Jump Type', 'Date', 'Price', 'Load Num'];
            // }
            default:
                return [];
        }
    }

    renderName = () => {
        let { dateRange } = this.state;
        const { classes, editModal } = this.props;
        const index = dateRange
            .findIndex(range => {
                //if ((range.modalType === JUMPER_LOGS) || (range.modalType === JUMPER_STATEMENT)) {
                if ((range.modalType === JUMPER_LOGS)) {
                    return true;
                }
            });
        if (index >= 0) {
            return (
                <Button
                    style={{ marginLeft: '30px' }}
                    className={`${classes.editButton} ${classes.left}`}>
                    {dateRange[index].jumper_name}
                </Button>
            )
        }
    }

    renderStatement = () => {
        let { dateRange } = this.state;
        const { classes, editModal } = this.props;
        const index = dateRange
            .findIndex(range => {
                //if ((range.modalType === JUMPER_LOGS) || (range.modalType === JUMPER_STATEMENT)) {
                if ((range.modalType === JUMPER_STATEMENT)) {
                    return true;
                }
            });
        if (index >= 0) {
            return (
                <Button
                    style={{ marginLeft: '30px' }}
                    className={`${classes.editButton} ${classes.left}`}>
                    {dateRange[index].jumper_name}
                </Button>
            )
        }
    }

    printCateogry = async () => {
        let { dateRange } = this.state;
        let startDate = '';
        let endDate = '';
        const index = dateRange.findIndex(range => range.modalType === INVOICE_BY_CATEGORY);
        let sDate = new Date(dateRange[index].startDate);
        sDate = sDate.setHours(0, 0, 0, 0);
        startDate = new Date(sDate);
        startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        let eDate = new Date(dateRange[index].endDate);
        eDate = eDate.setHours(23, 59, 59, 999);
        endDate = new Date(eDate);
        endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
        this.setState({
            pdfPrintLoading: true
        })
        Promise.all([reportStore.getInvoiceByCategoryPDF(startDate, endDate)]).then(data => {
            console.log("🚀 ~ file: DataTable.js ~ line 269 ~ DataTable ~ Promise.all ~ data", data)
            FileSaver.saveAs(data[0], `Invoice_${startDate}-${endDate}.pdf`);
            this.setState({
                pdfPrintLoading: false
            })
        })

    }


    printJumperLogs = async () => {
        let { dateRange } = this.state;
        let startDate = '';
        let endDate = '';
        const index = dateRange.findIndex(range => range.modalType === JUMPER_STATEMENT);

        let sDate = new Date(dateRange[index].startDate);
        sDate = sDate.setHours(0, 0, 0, 0);
        startDate = new Date(sDate);
        startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        let eDate = new Date(dateRange[index].endDate);
        eDate = eDate.setHours(23, 59, 59, 999);
        endDate = new Date(eDate);
        endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
        this.setState({
            pdfPrintLoading: true
        })
        Promise.all([reportStore.GetJumperLogsPDF(startDate, endDate, dateRange[index].jumper_id)]).then(data => {
            FileSaver.saveAs(data[0], `Jumper_Statement_${startDate}-${endDate}.pdf`);
            this.setState({
                pdfPrintLoading: false
            })
        })
    }

    printSlotReport = () => {
        let { dateRange } = this.state;
        let startDate = '';
        let endDate = '';
        const index = dateRange.findIndex(range => range.modalType === SLOTS_BY_PLANE);
        let sDate = new Date(dateRange[index].startDate);
        sDate = sDate.setHours(0, 0, 0, 0);
        startDate = new Date(sDate);
        startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        let eDate = new Date(dateRange[index].endDate);
        eDate = eDate.setHours(23, 59, 59, 999);
        endDate = new Date(eDate);
        endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
        // this.setState({
        //     csvPrintLoading: true
        // })
        Promise.all([reportStore.GetPlaneReportCSV(startDate, endDate)]).then(data => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Slot_By_Plane_${startDate}-${endDate}.csv`);
            // Append to html page
            document.body.appendChild(link);
            // Force download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            this.setState({
                csvPrintLoading: false
            })
        })
    }

    printSummary = async () => {
        let { dateRange } = this.state;
        let startDate = '';
        let endDate = '';
        const index = dateRange.findIndex(range => range.modalType === STAFF_SUMMARY);
        let sDate = new Date(dateRange[index].startDate);
        sDate = sDate.setHours(0, 0, 0, 0);
        startDate = new Date(sDate);
        startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        let eDate = new Date(dateRange[index].endDate);
        eDate = eDate.setHours(23, 59, 59, 999);
        endDate = new Date(eDate);
        endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
        this.setState({
            csvPrintLoading: true
        })
        Promise.all([reportStore.GetStaffSummaryCSV(startDate, endDate)]).then(data => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Staff_Summmary_${startDate}-${endDate}.csv`);
            // Append to html page
            document.body.appendChild(link);
            // Force download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            this.setState({
                csvPrintLoading: false
            })
        })
    }

    async printCategoryDetail() {
        //document.getElementById('summaryPanel').style.display = 'block';

        document.getElementById('btn-detail-print').style.visibility = 'hidden'
        var pdfName = document.getElementById('btn-detail-print').getAttribute('data-pdf') + ".pdf";
        var slides = document.getElementsByClassName('detialsCategoryPanel');
        var doc = new jsPDF("p", "mm", "a4");
        for (var i = 0; i < slides.length; i++) {
            //console.log(slides.item(i));
            var newSlide = slides.item(i)
            newSlide.style.display = 'block';
            await new Promise(function (resolve) {
                html2canvas(newSlide)
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        var imgWidth = 210;
                        var pageHeight = 310;
                        var imgHeight = (canvas.height * imgWidth / canvas.width);
                        var heightLeft = imgHeight;
                        var position = 0;
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        if (i == slides.length - 1) {
                            doc.save(pdfName);

                        } else {
                            doc.addPage();
                        }
                        resolve();
                    })
            })
            newSlide.style.display = 'none';
        }

        //document.getElementById('summaryPanel').style.display = 'none';
        document.getElementById('btn-detail-print').style.visibility = 'visible'
    }

    printSlots() {
        const input = document.getElementById('print-report');
        document.getElementById('btn-slots-print').style.visibility = 'hidden'
        var pdfName = document.getElementById('btn-slots-print').getAttribute('data-pdf') + ".pdf";

        //document.getElementById('date-print').style.visibility='hidden'
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                //document.getElementById('date-print').style.visibility='visible'
                document.getElementById('btn-slots-print').style.visibility = 'visible'
                //document.write('<img src="'+imgData+'"/>');
                // const pdf = new jsPDF();
                // //pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.addImage(imgData, 'JPEG', 10, 10, 180, 80);  // 180x150 mm @ (10,10)mm

                // //pdf.output('dataurlnewwindow');
                // pdf.save(pdfName);

                var imgWidth = 210;
                var pageHeight = 310;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;

                //enter code here

                var doc = new jsPDF("p", "mm", "a4");
                var position = 0;

                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 20, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                doc.save(pdfName);

            });
    }
    renderDate = () => {

        let { dateRange } = this.state;
        const { tableType, classes, editModal, submit } = this.props;
        //alert(JSON.stringify(editModal))
        const index = dateRange
            .findIndex(range => range.modalType === tableType);
        if (index >= 0) {
            return (
                <Button
                    id="date-print"
                    className={classes.editButton}
                    onClick={editModal}>
                    {moment(dateRange[index].startDate).format('L')} - {moment(dateRange[index].endDate).format('L')}
                </Button>
            )
        }
        else if (submit) {
            return (
                <Button
                    className={classes.editButton}
                    onClick={editModal}>
                    No Date has been selected...!
                </Button>
            )
        }
    }
    renderPrint = () => {
        let { dateRange } = this.state;
        const { tableType, classes, editModal, submit } = this.props;

        const index = dateRange
            .findIndex(range => range.modalType === tableType);

        if (index >= 0) {
            switch (tableType) {
                case SLOTS_BY_PLANE: {

                    let startDate = this.dateToPrint(dateRange[index].startDate);
                    let endDate = this.dateToPrint(dateRange[index].endDate);
                    var csvName = "Slot_By_Plane_" + startDate + "_" + endDate;

                    return <Button id="btn-summary-print" data-pdf={csvName} className={classes.editButton} onClick={this.printSlotReport}>{
                        this.state.csvPrintLoading ? 'Loading CSV' : 'Download CSV'
                    }</Button>
                }
                case INVOICE_BY_CATEGORY: {

                    let startDate = this.dateToPrint(dateRange[index].startDate);
                    let endDate = this.dateToPrint(dateRange[index].endDate);
                    var pdfName = "Invoice_By_Category_" + startDate + "_" + endDate;

                    return <Button id="btn-category-print" data-pdf={pdfName} className={classes.editButton} style={{ marginBottom: '4px' }} onClick={this.printCateogry}>{
                        this.state.pdfPrintLoading ? 'Loading PDF' : 'Download PDF'
                    }</Button>
                }
                case JUMPER_LOGS: {

                    let startDate = this.dateToPrint(dateRange[index].startDate);
                    let endDate = this.dateToPrint(dateRange[index].endDate);
                    var pdfName = "Jumper_Logs_" + startDate + "_" + endDate;

                    return <Button id="btn-logs-print" data-pdf={pdfName} className={classes.editButton} style={{ marginBottom: '4px' }} onClick={this.printJumperLogs}>Print</Button>
                }
                case STAFF_SUMMARY: {
                    let startDate = this.dateToPrint(dateRange[index].startDate);
                    let endDate = this.dateToPrint(dateRange[index].endDate);
                    var pdfName = "Staff_Summary_" + startDate + "_" + endDate;

                    return <React.Fragment>
                        <Button id="btn-summary-print" data-pdf={pdfName} className={classes.editButton} onClick={this.printSummary}>{
                            this.state.csvPrintLoading ? 'Loading CSV' : 'Download CSV'
                        }</Button>
                        {/* <span id="btn-summary-print-loader" style={{ visibility: "hidden" }} >
                            <DonutLoader isLoading={true} />
                        </span> */}
                        {/* <Button style={{ visibility: 'hidden' }}  >Loading...</Button> */}
                    </React.Fragment>
                }
                // case STAFF_DATA  : {
                //     LET startdate = startDate.toISO
                // }
                case NUMBER_SLOTS: {

                    let startDate = this.dateToPrint(dateRange[index].startDate);
                    let endDate = this.dateToPrint(dateRange[index].endDate);
                    var pdfName = "Number_Slots_" + startDate + "_" + endDate;

                    return <Button id="btn-slots-print" data-pdf={pdfName} className={classes.editButton} style={{ marginBottom: '4px' }} onClick={this.printSlots}>Print</Button>
                }
                case JUMPER_STATEMENT: {

                    let startDate = this.dateToPrint(dateRange[index].startDate);
                    let endDate = this.dateToPrint(dateRange[index].endDate);
                    var pdfName = "Jumper_Statement_" + startDate + "_" + endDate;

                    return <Button id="btn-logs-print" data-pdf={pdfName} className={classes.editButton} style={{ marginBottom: '4px' }} onClick={this.printJumperLogs}>{
                        this.state.pdfPrintLoading ? 'Loading PDF' : 'Download PDF'
                    }</Button>
                }
                case DETAIL_BY_CATEGORY: {
                    let JumpType = dateRange[index].JumpType;
                    let startDate = this.dateToPrint(dateRange[index].startDate);
                    let endDate = this.dateToPrint(dateRange[index].endDate);
                    var pdfName = "DETAIL_BY_CATEGORY" + startDate + "_" + endDate;
                    return <Fragment>
                        <Button id="btn-detail-print" data-pdf={pdfName} className={classes.editButton} style={{ marginBottom: '4px' }}
                            data-type={JumpType} onClick={this.printCategoryDetail}>Print</Button>

                        <Button className={classes.editButton} style={{ marginLeft: '4px', pointerEvents: 'none', marginBottom: '4px' }}>
                            {JumpType}</Button>
                    </Fragment>
                }
                default:
                    return [];
            }
        }
    }
    dateToPrint = (date) => {
        date = new Date(date);
        return date.toISOString('yyyy-mm-dd');
    }

    modalContent = () => {
        let { modalType } = this.props;

        switch (modalType) {
            case INVOICE_BY_CATEGORY: {
                return false;
            }
            case JUMPER_LOGS: {
                return true;
            }
            case JUMPER_STATEMENT: {
                return true;
            }
            case STAFF_SUMMARY: {
                return false;
            }
            case NUMBER_SLOTS: {
                return false;
            }
            case DETAIL_BY_CATEGORY: {
                return false;
            }
            default:
                return false;
        }
    }
    createFileName = (filename) => {
        let { dateRange } = this.state;
        let { tableType } = this.props;
        const index = dateRange.findIndex(range => range.modalType === tableType);
        if (index > -1) {
            let startDate = this.dateToPrint(dateRange[index].startDate);
            let endDate = this.dateToPrint(dateRange[index].endDate);
            filename = filename + startDate + "_" + endDate;
        }
        return filename;
    }
    createTandemStudentCSV = () => {
        let { data } = this.props;
        let filename = this.createFileName("TandemStudent_Detail");

        let tableCols = ['First Name', 'Last Name', "Email", "Phone", "Address", "Last Jump Date"]
        let dataCols = ['firstname', 'lastname', 'email', 'phone', 'streetAddress', 'lastJumpDate']
        let csvData = [];
        csvData = data.map((ele) => {
            let newOjb = {};
            tableCols.map((e, idx) => {
                let value = ''
                if (dataCols[idx] === 'lastJumpDate') {
                    let date = ele[dataCols[idx]] ? ele[dataCols[idx]] : null;
                    console.log('DATE: ', date);
                    value = date === null ? '' : moment(date).format('DD-MM-YYYY')
                }
                else {
                    value = ele[dataCols[idx]];
                }

                newOjb[e] = value;
            })
            return newOjb;
        })
        // console.log("Stirng: ", JSON.stringify(csvData));
        let csv = jsonToCSV(JSON.stringify(csvData));
        let csvFile = new Blob([csv], { type: "text/csv" });
        filename = filename + ".csv";
        this.downloadFile(csvFile, filename);
    }
    makeUpperCase = (text) => {
        return text[0].toUpperCase() + text.slice(1);
    }
    createFunJumpsCSV = (fileKey) => {
        let { categoryDetailsData, categoryDetailsHeader } = this.state;
        let filename = this.createFileName(fileKey);
        let dataCols = ['name', 'date', 'time', 'jumpType', 'price', 'loadNum', "planeLoad"];
        let csvData = [];
        csvData = categoryDetailsData.map((ele) => {
            let newOjb = {};
            categoryDetailsHeader.map((e, idx) => {
                let value = ''
                if (dataCols[idx] === 'date') {
                    let date = ele[dataCols[idx]] ? ele[dataCols[idx]] : null;
                    value = date === null ? '' : moment(date).format('DD-MM-YYYY')
                }
                else {
                    value = ele[dataCols[idx]] || "-";
                }
                if(e === 'fees') e = "Booking Fees"
                let key = this.makeUpperCase(e);
                newOjb[key] = value;
            })
            return newOjb;
        })
        let csv = jsonToCSV(JSON.stringify(csvData));
        let csvFile = new Blob([csv], { type: "text/csv" });
        filename = filename + ".csv";
        this.downloadFile(csvFile, filename); 
    }
    createCollectionsCSV = (fileKey) => {
        let { categoryDetailsData, categoryDetailsHeader } = this.state;
        let filename = this.createFileName(fileKey);
        let dataCols = ['name', 'date', 'time', 'amount', 'tax', 'fees', 'processingFees', 'total', 'transactionType', 'memo', 'cardholdName', 'maskedCard'];
        let csvData = [];
        csvData = categoryDetailsData.map((ele) => {
            let newOjb = {};
            categoryDetailsHeader.map((e, idx) => {
                let value = ''
                if (dataCols[idx] === 'date') {
                    let date = ele[dataCols[idx]] ? ele[dataCols[idx]] : null;
                    value = date === null ? '' : moment(date).format('DD-MM-YYYY')
                }
                else {
                    value = ele[dataCols[idx]] || "-";
                }
                if(e === 'fees') e = "Booking Fees"
                let key = this.makeUpperCase(e);
                newOjb[key] = value;
            })
            return newOjb;
        })
        let csv = jsonToCSV(JSON.stringify(csvData));
        let csvFile = new Blob([csv], { type: "text/csv" });
        filename = filename + ".csv";
        this.downloadFile(csvFile, filename);
    }

    createGiftCardCSV = () => {
        let filename = this.createFileName("GiftCard_Detail");
        const {data, dateRange } = this.props;
        let newArray = data;
        if (dateRange.length) {
            let index = dateRange.findIndex(ele => ele.modalType === GIFT_CARD);
            let endDate = new Date(dateRange[index].endDate).getTime()
            let startDate = new Date(dateRange[index].startDate).getTime()
            newArray = data.filter(val => {
              let createdDate = new Date(val.createdAt).getTime()
              let result = createdDate < endDate && createdDate > startDate
              return result;
            })
        } 
        const giftCardHeaders = ['Coupon Code', 'Name', 'Status', 'Expiry In Days', 'Price', 'Tax Amount', 'Total Booking Fee', 'Refunded Amount', 'Refunded Tax', 'Refunded Booking Fee', 'Refunded Date On', 'Created At', 'Tandem Package Name', 'Video Package Name', 'Purchaser', 'Recipient', 'Card Used']
        const dataCols = ['couponCode', 'description', 'status', 'expiryInDays', 'price', 'taxAmount', 'totalBookingFee', 'refundAmount', 'refundTax', 'refundBookingfees', 'refundedDate', "createdAt", "tandemPackageName", "videoPackageName", 'name', 'email', 'cardData'];
        const csvData = newArray.map((ele) => {
            let newOjb = {};
            giftCardHeaders.map((e, idx) => {
                let value = ''
                if (dataCols[idx] === 'refundedDate' || dataCols[idx] === 'createdAt') {
                    let date = ele[dataCols[idx]] ? ele[dataCols[idx]] : null;
                    value = date === null ? '-' : moment(date).format('DD-MM-YYYY')
                }
                else {
                    value = ele[dataCols[idx]] || "-";
                }
                let key = this.makeUpperCase(e);
                newOjb[key] = value;
            })
            return newOjb;
        })
        let csv = jsonToCSV(JSON.stringify(csvData));
        let csvFile = new Blob([csv], { type: "text/csv" });
        filename = filename + ".csv";
        this.downloadFile(csvFile, filename);
    }

    downloadFile = (file, filename) => {
        let downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(file);
        console.log("POSYUIghj==>", window.URL.createObjectURL(file))
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    createCSV = () => {
        let { tableType, dateRange } = this.props;
        let JumpType = ''
        if (dateRange[0]) {
            //isDetail = dateRange[0].modalType === "DETAIL BY CATEGORY" ? true : false;
            JumpType = dateRange[0].JumpType;
        }
        if (tableType === TANDAM_STUDENT) {
            this.createTandemStudentCSV();
        }
        if(tableType === GIFT_CARD) {
            this.createGiftCardCSV();
        }
        if (tableType === DETAIL_BY_CATEGORY && JumpType === "fun jumps") {
            this.createFunJumpsCSV("Jumps_");
        }
        if (tableType === DETAIL_BY_CATEGORY && JumpType === "collections") {
            this.createCollectionsCSV("Collections_");
        }
        if (tableType === DETAIL_BY_CATEGORY && JumpType === "Jumper payouts") {
            this.createCollectionsCSV("Jumpers_Payout");
        }
        if (tableType === DETAIL_BY_CATEGORY && JumpType === "Tandem payouts") {
            this.createCollectionsCSV("Tandem_Payout");
        }
    }
    DownloadCsvBtn = (col4) => {
        let { tableType, classes } = this.props
        if (tableType === TANDAM_STUDENT || tableType === DETAIL_BY_CATEGORY || tableType === GIFT_CARD) {
            return <TableCell
                component="th"
                colSpan={col4}
                className={classes.reportTableCell}>

                <button
                    className="btn btn-info btn-small"
                    onClick={this.createCSV}
                >
                    Download CSV
                </button>
            </TableCell>
        }
        else {
            return null
        }
    }

    returnClass = () => {
        let { tableType, classes } = this.props;
        if (tableType === "GIFT CARD") {
            return classes.giftTableClass
        } else {
            return classes.table
        }
    }

    render() {
        let { classes, tableType, dateRange } = this.props;
        var isDetail = false;
        var JumpType = '';

        if (dateRange[0]) {
            //isDetail = dateRange[0].modalType === "DETAIL BY CATEGORY" ? true : false;
            JumpType = dateRange[0].JumpType;
        }

        const index = this.state.dateRange
            .findIndex(range => range.modalType === tableType);

        const tableStyle = { width: '1322px', overflow: 'scroll' }
        // const override = `
        // display: block;
        // margin: 0 auto;
        // border-color: red;
        // `;

        var nameWidth = 1;

        var col1 = 1;
        var col2 = 2;
        var col3 = '';
        var col4 = '';
        var HeadingColSpan = "1";

        if (tableType === "JUMPER STATEMENT") {
            //total is 12
            col1 = 4;
            col2 = 4;
            col3 = 4;
        }
        else if (tableType === "DETAIL BY CATEGORY" && JumpType === "AFF jumps") {
            isDetail = true;
            col1 = 3;
            col2 = 12;
            col3 = 4;
        }
        else if (tableType === "DETAIL BY CATEGORY" && JumpType === "tandem jumps") {
            //isDetail= true;
            col1 = 4;
            col2 = 12;
            col3 = 3;
        }
        else if (tableType === "DETAIL BY CATEGORY" && JumpType === "coach jumps") {
            //isDetail= true;
            col1 = 2;
            col2 = 11;
            col3 = 3;
        }
        else if (tableType === DETAIL_BY_CATEGORY && JumpType === "collections") {
            col1 = 4;
            col2 = 8;
            col3 = 4;
            col4 = 3;
        }
        else if (tableType === "STAFF SUMMARY" || tableType === "DETAIL BY CATEGORY") {
            //staffsummary total 19 columns
            col1 = 3;
            col2 = 6;
            col3 = 4;
        }
        else if (tableType === "LOAD HISTORY") {
            col1 = 2;
            col2 = 8;
            col3 = 2;
        } else if (tableType === "GIFT CARD") {
            //total 14*2
            col1 = 7;
            col2 = 14;
            col3 = 7;
            HeadingColSpan = "2"
        }
        else if (tableType === TANDAM_STUDENT) {
            //as total heading are 9*2
            col1 = 4;
            col2 = 6;
            col3 = 5;
            col4 = 3;
            HeadingColSpan = '2';
        }

        return (
            <Paper className={`${classes.paper} middleDataContainer`} style={this.props.tableType !== "DETAIL BY CATEGORY" ? { height: '96%', minHeight: '96%' } : { maxHeight: '96%', background: "white" }}>
                <Fragment>

                    <Table
                        id="print-report"
                        // className={classes.table}
                        className={this.returnClass()}
                        style={isDetail ? tableStyle : this.props.tableType === "DETAIL BY CATEGORY" ? { width: "100%", margin: "0px", background: "white", paddingTop: "10px", borderBottom: "1px solid gray" } : { width: "100%", margin: "0px", paddingTop: "10px" }} >
                        <TableHead>
                            <TableRow key={'headTableRowReports'}>
                                <TableCell
                                    key={"headTableReportsCell1"}
                                    colSpan={col1}
                                    className={classes.reportTableCell}>
                                    {this.renderPrint()}
                                    {tableType == "JUMPER LOGS" ? this.renderName() : ''}
                                    {tableType == "JUMPER STATEMENT" ? this.renderStatement() : ''}
                                </TableCell>
                                <TableCell
                                    key={"headTableReportsCell3"}
                                    colSpan={col3}
                                    className={classes.reportTableCell}>
                                    <Fragment>
                                        {this.renderDate()}
                                    </Fragment>
                                </TableCell>

                                <TableCell
                                    key={"headTableReportsCell2"}
                                    colSpan={col2}
                                    className={classes.reportTableCell}>
                                    <h1
                                        className={classes.reportHeadingOne}>
                                        Reports
                                    </h1>
                                </TableCell>
                                {this.DownloadCsvBtn(col4)}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.TableBody}>
                            {
                                tableType !== "JUMPER STATEMENT" && this?.subHeadings().length > 0 &&
                                <TableRow key={'headTableRowSubHeadings'} >
                                    {this?.subHeadings()?.map((subHeading, index) => {
                                        return <TableCell
                                            key={`subheading${index}`}
                                            colSpan={HeadingColSpan}
                                            //style={subHeading === "Created At" || "Tandem Package Name" || "Video Package Name" ? {textAlign: 'center'} : {}}
                                            component="th"
                                            className={tableType == "STAFF SUMMARY" ? classes.staffsubHeadings : classes.subHeadings}
                                        // className={classes.detailSubHeadings}
                                        >
                                            {subHeading}
                                        </TableCell>
                                    })}
                                </TableRow>
                            }
                            {this.props.tableType !== "DETAIL BY CATEGORY" && this.ComponentRenderer()}
                            {!["JUMPER STATE", "TANDEM STATE"].includes(tableType) && <TableRow key={"modalrow"}>
                                <TableCell style={{ border: 'none' }} key={"modalCell"}>
                                    <FormDialog
                                        modalType={tableType}
                                        closeModal={this.props.closeModal}
                                        postModal={(data) => { this.setState({ count: this.state.count + 1 }); this.props.postModal(data) }}
                                        endDateHandler={this.endDateHandler}
                                        startDateHandler={this.startDateHandler}
                                    />
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                    {this.props.tableType === "DETAIL BY CATEGORY" && this.ComponentRenderer()}
                </Fragment>
            </Paper>
        );
    }
}

const mapStateToProps = ({ Reports }) => ({
    open: Reports.modal,
    submit: Reports.submit,
    dateRange: Reports.dateRange
});

const mapDispatchToProps = (dispatch) => ({
    postModal: (dateRange) => dispatch(rangeSubmit(dateRange)),
    closeModal: () => dispatch(closeModal()),
    editModal: () => dispatch(editModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DataTable));
