import swal from "sweetalert";
import schedulesStore from '../stores/schedule-store';


export const handleAddJumper = async (e, state, setState, selectedEvent) => {

  let type = "tandem";
  e.preventDefault()
  const errors = {
    firstname: '',
    lastname: '',
    adult: '',
    weight: '',
    tandemPackage: '',
    phone: 'This field is required',
    streetAddress: 'This field is required',
    email: 'This field is required',
    zipcode: 'This field is required',
    video: '',
    noVideoOptions: ''
  }

  const { firstName, lastName, above18, below230, tandemPackage, videoOption } = e.target.elements;
  //if (!firstName.value || !lastName.value || !above18.checked || !below230.checked || (tandemPackage?.value === "none" && selectedEvent?.eventCategory !== "AFF")) {
  if (!firstName.value || !lastName.value || (tandemPackage?.value === "none" && selectedEvent?.eventCategory !== "AFF")) {

    swal({
      title: "Error",
      text: "Please fill all the necessary fields",
      icon: "error",
      dangerMode: true,
    });
    return;
  }

  const jumper = {
    firstname: firstName.value,
    lastname: lastName.value,
    phone: " ",
    streetAddress: '',
    email: '',
    zipcode: '',
    error: errors,
    adult: true,
    weight: true,
    tandemPackage: tandemPackage?.value,
    video: videoOption?.value,
    noVideoOptions: videoOption?.value !== "none" ? false : true,
    totalPaid: 0,
    totalTandemPaid: 0,
    totalTandemTaxPaid: 0,
    totalVideoPaid: 0,
    totalVideoTaxPaid: 0,
    totalTaxPaid: 0,
  }

  if (selectedEvent?.eventCategory === "AFF") {
    type = "AFF";
  }

  const payload = {
    type: type,
    jumper: jumper,
    reservationId: state.reservation._id,
    selectedResEventId: state.selectedResEventId
  }

  const result = await schedulesStore.addJumperToReservations(payload);
  if (!result.succeed) {
    swal({
      title: "Error",
      text: "Something went wrong,Please try again",
      icon: "error",
      dangerMode: true,
    });

  } else {
    swal({
      title: "Success",
      text: "Jumper added Successfully",
      icon: "success",
      dangerMode: false,
    });
    const event = {
      start: selectedEvent.datetime,
      resource: {
        _id: selectedEvent._id
      }
    }
    await schedulesStore.fetchData(selectedEvent.datetime);
    await schedulesStore.fetchPassengers(event);
    setState({ isAddJumperModalOpen: false })
  }
}