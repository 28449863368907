import { useState, useRef, useEffect } from "react";
import CustomModal from "./../Common/CustomModal";

import {
  Input,
  Row,
  Col,
  Label,
  ModalBody,
  FormGroup,
} from "reactstrap";
import scheduleStore from "../../stores/schedule-store";
import DonutLoader from "../loadManager/DonutLoader";
import "./smsTextTemplates.css";

export default function SMSModal({
//   state,
  handleChange,
  handleSumbit,
  addEditLoading,
//   setIsModalOpen,
  isModalOpen,
  handleCancel,
//   HTMLmarkup,
//   setHTMLmarkup,
  modalData,
  handleEditSubmit,
//   setModalData,
  formValues,
  setFormValues,
}) {

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    if (modalData?.name) {
      setFormValues({  name: modalData?.name, content: modalData?.content});
   }
  }, [modalData]);

return (
    <>
      <CustomModal
        className="SMS_Modal"
        size="xl"
        isModalOpen={isModalOpen || !!modalData?.name}
        title={"Add SMS Text Templates"}
        backgroundColor="white"
        color="black"
      >
        <ModalBody>
          <div className="Donutcss">
            <DonutLoader isLoading={addEditLoading} addClass="sample1" />
          </div>
          <FormGroup floating>
            <Label style={{ color: "black" }}>Name</Label>
            <Input className="inputttt"
              style={{
                backgroundColor: "white",
                width: '30rem',
                color: "black",
                marginBottom: "1rem",
              }}
              id="name"
              name="name"
              value={formValues?.name}
              type="text"
              onChange={handleChange}
            />
          <label style={{ 
          color: "black",
          display: 'inline-block',
          marginBottom: '0.5rem', }}>Content </label>
          <textarea className="textareaaa"
           rows={7}
           name="content"
           value={formValues?.content} 
           type="text"
           id="content" 
            onChange={handleChange}/>
          </FormGroup>
          <Row className="form-group m-t-2-rem">
            <Col md={{ size: 10 }}>
              <button
                className="btn btn-bg lightColor createButton"
                type="submit"
                //   disabled={ formValues?.name ? false:true}
                onClick={() => {
                  console.log("formValues===>", formValues);
                  console.log(modalData, "modalData1");
                  if (modalData?.name) {
                    console.log(modalData, "modalData2");
                    handleEditSubmit();
                  } else {
                    // console.log(modalData,"modalData3")
                    handleSumbit();
                  }
                }}
              >
                SUBMIT
              </button>

              <button
                // disabled={loading}
                onClick={handleCancel}
                className="btn btn-link"
                type="button"
              >
                CANCEL
              </button>
            </Col>
          </Row>
          <div
            className="preview"
            // dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></div>
        </ModalBody>
      </CustomModal>
    </>
  );
}
