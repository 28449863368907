
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { LocalForm } from 'react-redux-form';
import CustomModal from '../../Common/CustomModal';

class DeleteEvent extends Component {
    state = {
        isModalOpen: false
    }

    toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    deleteEvent = (payload) => {
        this.props.deleteEvent(payload)
        if (!this.props.loading) this.setState({ isModalOpen: false })
    }

    render() {
        const { item } = this.props;
        const { isModalOpen } = this.state;
        return (
            <React.Fragment>
                <CustomModal
                    isModalOpen={isModalOpen}
                >
                    <p>Are you Sure you want to delete {item.eventName} ! </p>
                    <LocalForm
                        onSubmit={(values) => this.deleteEvent(item)}
                    >
                        <Row className="form-group">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >Submit</button>
                                <button type="button" onClick={this.toggleModal} className="btn btn-link">Cancel</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </CustomModal>
                <span onClick={this.toggleModal}>
                    <i className="fa fa-trash"></i>
                </span>
            </React.Fragment>
        );
    }
}

export default DeleteEvent

