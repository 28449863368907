/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';


import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import DatePicker from "react-datepicker";
import itemStore from '../../../stores/item-store';
import Api from '../../../services/itemsapi';
import axios from 'axios';
import Select from 'react-select';
import cc from 'coupon-code';
import AdminModal from './customModal';

import "react-datepicker/dist/react-datepicker.css";

class CreateDiscountCode extends Component {

  constructor(props) {


    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isModalOpen: false,
      loading: false,
      error: ''
    }
  }

  toggleModal(e) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }



  handleSubmit(values) {
    var payload = {};
    var item = this.props.item;

    this.setState({
      loading: true
    });

    if (!values.name || !values.name.trim()) {
      return this.setState({ error: 'Please enter a name' })
    }
    if (!values.amount || values.amount === 0) {
      return this.setState({ error: 'Please enter an amount' })
    }
    if (!values.code || !values.code.trim()) {
      return this.setState({ error: 'Please enter a unique code' })
    }
    if (!this.state.startDate && !item.startDate) {
      return this.setState({ error: 'Please select a start date' })
    }
    if (!this.state.endDate && !item.endDate) {
      return this.setState({ error: 'Please select a end date' })
    }

    this.setState({ error: '' });

    Api.verifyDiscountCode(values.code).then((r) => {
      this.setState({
        loading: false
      });

      if (r.status == 200) {
        if (r.data.found && item.code !== values.code) {
          return this.setState({ error: r.data.error || 'Discount code already exists' });
        }
        if (this.props.isEdit && values !== undefined) {
          payload.item = values.item;
          payload.category = "discounts";
          payload.name = values.name;
          payload.code = values.code.toLowerCase();
          payload.amount = Number(values.amount);
          payload.startDate = this.state.startDate || item.startDate;
          payload.endDate = this.state.endDate || item.endDate;
          payload.quantity = Number(values.quantity);
          payload.description = values.description;
          itemStore.updateItem(this.props.item._id, payload);
        } else if (values != undefined) {
          payload.item = values.item;
          payload.category = "discounts";
          payload.name = values.name;
          payload.code = values.code.toLowerCase();
          payload.amount = Number(values.amount);
          payload.startDate = this.state.startDate || item.startDate;
          payload.endDate = this.state.endDate || item.endDate;
          payload.quantity = Number(values.quantity);
          payload.description = values.description;
          itemStore.createItem(payload);
        }
        this.toggleModal();
        this.setState({
          loading: false,
          error: ''
        })
        console.log(payload);
      } else {
        this.setState({
          loading: false,
          error: 'There has been an error, please retry'
        })
      }
    })
  }

  render() {
    var item = this.props.item || '';
    return (
      <React.Fragment>
        <AdminModal
          isModalOpen={this.state.isModalOpen}
          title={`Discount Codes`}
        >
          {this.state.error && <div style={{ marginBottom: '20px', color: 'red' }}>{this.state.error}</div>}
          <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
            <Row className="form-group">
              <Label htmlFor="name" md={3}>Name</Label>
              <Col md={9}>
                <Control.input name="name" model=".name" className="form-control" defaultValue={item.name}
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="code" md={3}>Code</Label>
              <Col md={9}>
                <Control.input name="code" model=".code" className="form-control" defaultValue={item.code || cc.generate({ parts: 4 }).toLowerCase()}
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="startDate" md={3}>Start Date</Label>
              <Col md={9}>
                <DatePicker
                  selected={this.state.startDate || item.startDate}
                  minDate={new Date}
                  onChange={(date) => this.setState({ startDate: date })}
                  className="form-control"
                  name="startDate"
                  model=".startDate" />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="endDate" md={3}>End Date</Label>
              <Col md={9}>
                <DatePicker
                  selected={this.state.endDate || item.endDate}
                  minDate={new Date}
                  onChange={(date) => this.setState({ endDate: date })}
                  className="form-control"
                  name="endDate"
                  model=".endDate" />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="amount" md={3}>Amount</Label>
              <Col md={9}>
                <Control.input type="number" name="amount" model=".amount" className="form-control" defaultValue={item.amount}
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="quantity" md={3}>Quantity</Label>
              <Col md={9}>
                <Control.input type="number" name="quantity" model=".quantity" className="form-control" defaultValue={item.quantity || 0}
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="description" md={3}>Description</Label>
              <Col md={9}>
                <Control.input name="description" model=".description" className="form-control" defaultValue={item.description}
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
              </Col>
            </Row>
            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 10 }}>
                <button className="btn btn-bg lightColor createButton" disabled={this.state.loading} type="submit" >
                  {this.state.loading ? 'Saving...' : 'SUBMIT'}
                </button>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>
          </LocalForm>
        </AdminModal>
        {!this.props.isEdit ?
          <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
            <i className="fa fa-plus"></i>
          </button>
          :
          <span onClick={this.toggleModal}>
            <i className="fa fa-edit"></i>
          </span>
        }
      </React.Fragment>
    );
  }
}

export default CreateDiscountCode