import React, { useEffect, useState } from 'react'
import reportStore from '../../../../stores/report-store';
import JumperStore from '../../../../stores/jumpers-store';
import DonutLoader from '../../../loadManager/DonutLoader';

const JumperState = () => {

    const [jumpers, setJumpers] = useState([])
    const [jumperType, setJumperType] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        apiCall()
    }, [])

    const apiCall = () => {
        setIsLoading(true)
        reportStore.GetJumperState().then(async res => {
            setJumpers(res.data)
            setJumperType(res.type)
            setIsLoading(false)
        })
    }

    const renderDetail = (data) => {

        let array = data.sort(function (a, b) {
            var keyA = new Date(a.count),
                keyB = new Date(b.count);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0
        });

        return array?.map((i, ind) => {
            let jumperInfo = JumperStore.getJumperById(i.jumperId);
            let name = jumperInfo !== undefined ? jumperInfo.firstname + ' ' + jumperInfo.lastname : '';
            if (ind < 10) {
                return <p key={ind}>{name} - {i.count}</p>
            }
        })
    }

    const renderDetailByType = (data) => {

        let array = data.sort(function (a, b) {
            var keyA = new Date(a.count),
                keyB = new Date(b.count);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0
        });

        return array?.map((i, ind) => {
            if (ind < 10) {
                return <p key={ind}>{i.jumpType} - {i.count}</p>
            }
        })
    }

    const renderData = () => {
        return Object.keys(jumpers)?.map(i => {
            return <div style={{ marginRight: '70px' }}>
                <h5>{i}</h5>
                {renderDetail(jumpers[i])}
            </div>
        })
    }

    const CountByType = () => {
        return Object.keys(jumperType)?.map(i => {
            return <div style={{ marginRight: '70px' }}>
                <h5>{i}</h5>
                {renderDetailByType(jumperType[i])}
            </div>
        })
    }

    return <>
        <DonutLoader isLoading={isLoading} addClass={'jumperStat'} />
        <div style={{ display: 'table', width: '800px', marginTop: '-40px', marginLeft: '40px' }}>
            <div style={{ display: 'flex' }}>
                {renderData()}
            </div>
            <div style={{ marginTop: '15px' }}>
                <h5>JumpCounts by Type</h5>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    {CountByType()}
                </div>
            </div>
        </div>
    </>
}


export default JumperState;