import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Alert } from 'reactstrap';
import HamburgerMenu from 'react-hamburger-menu';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './admin.css';
import jumpersStore from '../../stores/jumpers-store';
import loadStore from '../../stores/loads-store';
import reportStore from '../../stores/report-store';
import loadsStore from '../../stores/loads-store';
import Api from '../../services/reservationapi';

import logo from '../images/logo.svg';
import background from '../images/background.svg';
import LeftMenu from '../menu/LeftMenu1';
import ErrorModal from '../sub-components/Modal/ErrorModal';
import Settings from './adminComponents/Settings';
import AdminAlert from './adminComponents/Alerts';
import AdminPage from './adminComponents/Admin';
import Teams from './adminComponents/Teams';
import OauthSettings from './oauthSettings/OauthSettings';
class Admin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth,

			open: false,

			rightOpen: false,

			jumperOptions: [],

			adminDefaultOptions: [],

			tandemStudentOptions: [],

			currentJumper: [],

			cardArray: [],

			priceSchedule: [],

			paySchedule: [],

			completedLoads: [],

			jumperAlert: false,

			visible: true,

			warning: false,

			velocityButton: false,

			settings: [],

			filterItem: [
				{ text: 'Tandem Instrutor', checked: false },
				{ text: 'Aff Instrutor', checked: false },
				{ text: 'Fun Jumper', checked: false },
				{ text: 'Staff', checked: false },
				{ text: 'Coach', checked: false }
			],
			accountArray: [],
			modal: false,
		};

		this.inputValue = '';
		this.jumperEvents = [
			{ name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded },
			{ name: jumpersStore.getEventPrefix('jumperUpdated'), func: this.handleJumperUpdated },
			{ name: jumpersStore.getEventPrefix('jumperCreated'), func: this.handleJumperCreated },
			{ name: jumpersStore.getEventPrefix('warningEvent'), func: this.handleWarning },
			{ name: jumpersStore.getEventPrefix('deleteCardEvent'), func: this.handleDeleteCard },
			{ name: jumpersStore.getEventPrefix('deleteAccountEvent'), func: this.handleDeleteAccount },
			{ name: jumpersStore.getEventPrefix('cardAddEvent'), func: this.handelCardAdd },
			{ name: jumpersStore.getEventPrefix('accountAddEvent'), func: this.handelAccountAdd }

		];

		this.loadEvents = [
			{ name: loadStore.getEventPrefix('getExistingLoads'), func: this.handleExistingLoads },
			{ name: loadStore.getEventPrefix('completeLoad'), func: this.handleCompleteLoad },
			{ name: loadStore.getEventPrefix('warningEvent'), func: this.handleWarning },
			{ name: loadStore.getEventPrefix('settingUpdated'), func: this.handleSettingUpdate },
			{ name: loadStore.getEventPrefix('settingsLoaded'), func: this.handleSettingUpdate },

		];
		this.reportEvents = [
			{ name: reportStore.getEventPrefix('priceActivity'), func: this.handlePriceLoaded },
			{ name: reportStore.getEventPrefix('warningEvent'), func: this.handleWarning }
		];
	}

	handleWarning = () => {
		this.setState({
			warning: true
		})
		setTimeout(() => {
			this.setState({
				warning: false
			})
		}, 2000)
	}
	handleDeleteCard = (data) => {
		jumpersStore.loadAllJumpers();
		this.setState({
			cardArray: data.cardsOnFile
		})
	}
	handleDeleteAccount = (data) => {
		jumpersStore.loadAllJumpers();
		this.setState({
			accountArray: data.accountOnFile,
			currentJumper: data
		});
	}
	handelCardAdd = (data) => {
		this.setState({
			cardArray: data
		})
	}

	handelAccountAdd = (data) => {
		this.setState({
			accountArray: data
		})
	}
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	handleExistingLoads = (loads) => {
		this.setState({ completedLoads: loadsStore.completedLoads });
	}

	handleCompleteLoad = (loads) => {
		////
		this.setState({ completedLoads: loads.completedLoads });
	}
	handleSettingUpdate = (settings) => {
		this.setState({ settings: settings });
	}

	componentDidMount = () => {
		this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
		this.loadEvents.forEach((evnt) => loadStore.on(evnt.name, evnt.func));
		this.reportEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
		var jumperOptions = jumpersStore.getJumperOptions();
		var settings = loadsStore.getSettings();
		let paySchedule = reportStore.getPaySchedule();
		let priceSchedule = reportStore.getPriceSchedule();
		this.setState({ jumperOptions, adminDefaultOptions: jumperOptions.slice(0, 50), settings, paySchedule, priceSchedule });

		if (window.globals && window.globals['accountsState']) {
			this.setState({ ...window.globals['accountsState'] });
		}
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount = () => {

		// let velocityScript = document.getElementById('velocityScript');
		// // let velocityButton = document.getElementById('btn_AddCard');
		// if (velocityScript) {
		// 	velocityScript.remove();
		// 	// velocityButton.remove();
		// }

		window.removeEventListener('resize', this.handleWindowSizeChange);
		this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
		this.loadEvents.forEach((evnt) => loadStore.removeListener(evnt.name, evnt.func));
		this.reportEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
	}
	componentWillMount = () => {

		// var aScript = document.createElement('script');
		// aScript.type = 'text/javascript';
		// aScript.src = "https://api.cert.nabcommerce.com/1.3/button.js";
		// aScript.setAttribute('id', 'velocityScript');
		// document.head.appendChild(aScript);

		// var elem = document.createElement("button");
		// elem.setAttribute("className", "velocity-button");
		// elem.setAttribute("id", "btn_AddCard");
		// elem.setAttribute("data-amount", "1");
		// elem.setAttribute("data-terminal-profile-id", "15617");
		// elem.setAttribute("data-callback-function", "onPaymentCompletion");

		// document.body.append(elem);
		// var aScript = document.createElement('script');
		// aScript.setAttribute('id', 'ReservationScript');
		// aScript.type = 'text/javascript';
		// aScript.src = "./paymentResponse.js";
		// document.head.appendChild(aScript);
		// aScript.onload = function() {
		//     document.getElementById("").InnerHTML = ;
		// };

		this.setState({
			velocityButton: true
		})

		window.addEventListener('resize', this.handleWindowSizeChange);

		var loadPriceValue = [];

		var planArray = loadStore.planes;
		if (planArray.length == 0) {
			//loadStore.loadPlanes();
			var that = this;
			Promise.all([loadStore.loadPlanes()])
				.then(function (result) {
					////
					loadPriceValue = result[0]
				})
		}

		var completedArray = loadsStore.completedLoads;
		var priceArray = reportStore.priceSchedule;
		if (priceArray.length == 0) {
			var data = [];
			var that = this;
			Promise.all([reportStore.loadPrices()])
				.then(function (result) {
					////
					that.setState({
						priceSchedule: result[0].priceSchedule,
						paySchedule: result[0].paySchedule,
						completedLoads: completedArray
					})
				})
		}
		else {
			this.setState({
				priceSchedule: priceArray.priceSchedule,
				paySchedule: priceArray.paySchedule,
				completedLoads: completedArray
			})
		}
		if (window.ENV?.processorInfo.processor === "nab") {
			Api.startSession()
				.then(res => {

				});
		}
		// var profileId, merchantProfileId, serviceID;

		// Api.getIdentityInfo()
		// 	.then(info => {
		// 		reservationStore.setProcessorVals(info.data);
		// 		profileId = info.data.applicationProfileId;
		// 		merchantProfileId = info.data.merchantProfileId;
		// 		serviceID = info.data.ServiceID;
		// 		return Api.startSession(info.data.IdentityToken);
		// 	})
		// 	.then(r => {
		// 		if (r.data) {
		// 			reservationStore.setTokENV?.l(r.data);
		// 		}
		// 		////
		// 		return Api.getApplicationData(reservationStore.getTokENV?.l(), profileId);
		// 	})
		// 	.then(r => {
		// 		var applicationData = r.data;
		// 		////
		// 		return Api.getServiceInformation(reservationStore.getTokENV?.l());
		// 	})
		// 	.then(r => {
		// 		return Api.isMerchantProfileInitialized(reservationStore.getTokENV?.l(), merchantProfileId, serviceID);
		// 	})
		// 	.then(r => {
		// 		////
		// 	})
	}
	handleJumperCreated = (data) => {
		this.setState({
			jumperAlert: true,
			currentJumper: data,
			cardArray: data.cardsOnFile
		}, () => {
			if (document.getElementsByClassName("rating-button")[0]) {
				document.getElementsByClassName("rating-button")[0].click();
			}
		})
		setTimeout(() => {
			this.setState({ jumperAlert: false });
		}, 2000)
	}

	handleJumpersLoaded = (jumperOptions) => {
		var adminDefaultOptions = jumperOptions.slice(0, 50);
		this.setState({
			jumperOptions: jumperOptions,
			adminDefaultOptions: adminDefaultOptions
		});
	}

	handleJumperUpdated = (jumper) => {
		this.setState({ currentJumper: jumper, cardArray: jumper.cardsOnFile });
	}

	handlePriceLoaded = (priceOption) => {
		var that = this;
		Promise.all([reportStore.loadPrices()])
			.then(function (result) {
				////
				that.setState({
					priceSchedule: result[0].priceSchedule,
					paySchedule: result[0].paySchedule
				})
			})
	}

	onToggle = (index, e) => {
		let newItems = this.state.filterItem.slice();
		newItems[index].checked = !newItems[index].checked
		this.setState({
			filterItem: newItems
		})
		//this.filterJumperOptions(this.state.jumperOptions, this.state.dailyActivities);
	}

	renderCheckbox = () => {
		return this.state.filterItem.map((item, index) => {
			var ratingName = item.text + "_" + index;
			var ratingText = "txt_" + item.text + "_" + index;
			return (
				<div className="col-4" style={{ marginBottom: '12px' }}>
					<input type="checkbox" id={ratingName} name={ratingName} onChange={this.onToggle.bind(this, index)} checked={item.checked} />
					<label style={{ marginLeft: "6px", position: 'absolute' }}>{item.text}</label>
				</div>
			)
		})
	}

	checkForNegatives = (price) => {

		if (price === undefined || price === null) {
			price = 0;
		}

		let priceCell = (price < 0) ?
			{
				value: `($${Math.abs(price).toFixed(2)})`
			} :
			{
				value: `$${price.toFixed(2)}`
			}

		if (price < 0) {
			return <span style={{ color: 'red' }}> {priceCell.value}</span>;
		}
		return <span>{priceCell.value}</span>;
	}

	filterJumpers = (inputValue, jumperOptions) => {
		if (inputValue) {
			//
			var filteredOptions = jumperOptions.filter(i =>
				i.label.toLowerCase().includes(inputValue.toLowerCase()));

			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
			else return filteredOptions;
		}
		return jumperOptions.slice(0, 30);
	};

	handleClick = () => {
		this.setState({
			open: !this.state.open
		});
	}

	handleRightClick = () => {
		this.setState({
			rightOpen: !this.state.rightOpen
		});
	}

	promiseOptions = (inputValue, jumperOptions) => {
		return new Promise(resolve => {
			resolve(this.filterJumpers(this.inputValue, jumperOptions));
		});
	}

	handleInputChange = (newValue) => {
		//const inputValue = newValue.replace(/\W/g, '');
		//this.promiseOptions(newValue, jumperOptions);
		this.inputValue = newValue;
		//return inputValue;
	};

	handleSelect = (val) => {
		let jumperOptions
		jumpersStore.loadAllJumpers().then(r => {
			jumperOptions = jumpersStore.getJumperOptions()
			this.setState({ adminDefaultOptions: jumperOptions.slice(0, 50) })
			let selectedVal = jumperOptions.filter(i => i.label === val.label)
			if (val.value !== undefined) {
				////alert(JSON.stringify(val.value));
				// this.onJumperChanged(val.value);
				// jumpersStore.getDailyActivity(val.value._id);
				this.setState({
					currentJumper: selectedVal[0]?.value,
					cardArray: val.value.cardsOnFile
				}, () => {
					this.setState({ modal: true })
				});

				//document.getElementsByClassName("rating-button")[0].click()
			}
		})


	}

	checkExpiry = (activeID) => {
		var waiverExpiry = [];
		var uspaExpiry = [];
		for (var item in activeID) {
			let jumperInfo = jumpersStore.getJumperById(activeID[item]);
			// let jumperInfo= jumpersStore.getJumperById(item);

			var date = new Date();
			if (jumperInfo !== undefined) {
				if (!jumperInfo.isStaff) {
					if (jumperInfo.uspaExpiry !== undefined && jumperInfo.waiverDate !== undefined) {

						var uspaDate = new Date(jumperInfo.uspaExpiry);
						var uspaResult = date.valueOf() > uspaDate.valueOf();

						var waiverDate = new Date(jumperInfo.waiverDate);
						var waiverResult = date.valueOf() > waiverDate.valueOf();
						//
						if (uspaResult === true && waiverResult === true) {
							waiverExpiry.push({ label: "USPA membership and Medical waiver has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
						}
						else if (uspaResult === true) {
							waiverExpiry.push({ label: "USPA membership has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
						}
						else if (waiverResult === true) {
							waiverExpiry.push({ label: "Medical waiver has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
						}
					}
					else if (jumperInfo.uspaExpiry !== undefined && jumperInfo.waiverDate === undefined) {
						var uspaDate = new Date(jumperInfo.uspaExpiry);
						var uspaResult = date.valueOf() > uspaDate.valueOf();
						if (uspaResult) {
							waiverExpiry.push({ label: "USPA membership has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
						}
					}
					else if (jumperInfo.uspaExpiry === undefined && jumperInfo.waiverDate !== undefined) {
						var waiverDate = new Date(jumperInfo.waiverDate);
						var waiverResult = date.valueOf() > waiverDate.valueOf();
						if (waiverResult) {
							waiverExpiry.push({ label: "Medical waiver has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
						}
					}
				}
			}
		}

		//var resultArray= { "Uspa" : uspaExpiry , "Waiver" : waiverExpiry };
		// return (
		// 	<Alert color="success">
		// 		USPA membership has been expired for {item}.
		//  	</Alert>
		// )

		return (
			waiverExpiry.length > 0 && waiverExpiry.map((item, index) => {
				return (
					<Alert color="warning" style={{ width: '90%' }} isOpen={this.state.visible}  >
						<i className="fa fa-close" onClick={this.handleRemove.bind(this)} data-key={item.id} style={{ position: 'absolute', right: '10px', top: '2px', cursor: 'pointer' }}>
						</i>
						{item.label}
					</Alert>
				)
			})
		)
	}

	onDismiss = () => {
		// this.setState({ visible: false });
	}

	handleRemove = (e) => {
		var currentKey = e.currentTarget.attributes['data-key'].value;
		var objectId = JSON.parse(localStorage.getItem("ExpiryID"));
		var newArray = objectId.filter((item) => item !== currentKey);
		localStorage.setItem("ExpiryID", JSON.stringify(newArray));
		this.renderAlert();
		this.forceUpdate();
	}

	closeModal = (status) => {
		this.setState({ modal: status })
	}

	render() {

		const { width, warning } = this.state;
		const isMobile = width <= 500;

		return (
			<div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
				{
					isMobile ?
						<div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
							<div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
								<LeftMenu routeHistory={this.props.history} />
							</div>
						</div>
						:
						<div className="blueBg fullHeightPanes left-container">
							<img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
							<LeftMenu routeHistory={this.props.history} showCreate={true} />
						</div>
				}

				<div className="middle-container-no-rightmenu" style={{ overflow: 'scroll' }}>
					{
						isMobile ?
							<div className="row" style={{ width: "100%" }}>
								<div className="col-md-12">
									<div className="hamBurger-menu">
										<HamburgerMenu
											isOpen={this.state.open}
											menuClicked={this.handleClick.bind(this)}
											width={22}
											height={17}
											strokeWidth={1}
											rotate={0}
											color='white'
											borderRadius={0}
											animationDuration={0.5}
										/>
									</div>
									<div className="hamBurger-right">
										<HamburgerMenu
											isOpen={this.state.rightOpen}
											menuClicked={this.handleRightClick.bind(this)}
											width={22}
											height={17}
											strokeWidth={1}
											rotate={0}
											color='white'
											borderRadius={0}
											animationDuration={0.5}
										/>
									</div>
								</div>
							</div>
							:
							''
					}

					<img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />

					{
						this.state.jumperAlert ?
							<Alert color="success" className="alert-complete">
								Jumper Added Successfully !
							</Alert>
							: ''
					}


					<div className="row justify-content-center admin-container">
						<Switch>
							<Route
								path='/admin/alerts'
								render={() => <AdminAlert checkExpiry={this.checkExpiry} />}
							/>
							<Route
								path='/admin/settings'
								render={() => <Settings settings={this.state.settings} />}
							/>
							<Route
								path='/admin/oauth_settings'
								render={() => <OauthSettings />}
							/>
							<Route
								path='/admin/teams'
								render={() => <Teams />}
							/>

							<Route
								path='/admin/national2021'
								render={() => <Teams path={'national2021'} />}
							/>
							<Route
								path='/admin/mundial2022'
								render={() => <Teams path={'mundial2022'} />}
							/>


							{/* KEEP '/' ROUTE ALWAYS LAST */}
							<Route
								path='/'
								render={() => <AdminPage
									handleInputChange={this.handleInputChange}
									promiseOptions={this.promiseOptions}
									renderCheckbox={this.renderCheckbox}
									handleSelect={this.handleSelect}
									adminDefaultOptions={this.state.adminDefaultOptions}
									accountArray={this.state.accountArray}
									cardArray={this.state.cardArray}
									currentJumper={this.state.currentJumper}
									jumperOptions={this.state.jumperOptions}
									modal={this.state.modal}
									closeModal={this.closeModal}
								/>}
							/>
						</Switch>
					</div>
				</div>
				{warning && <ErrorModal show={warning} />}
			</div>
		);
	}
}

export default Admin;
