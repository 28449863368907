import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function TandemJump({ packagesList, createJump, disabled, loadsToShow }) {
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);
    let styledObject = {}
    let itemClass = ''
    if (loadsToShow > 3) {
        styledObject = { overflow: 'auto', maxHeight: '400px', left: '0', padding: 4 }
        itemClass = 'reducePadding'
    } else {
        styledObject = { overflow: 'auto', maxHeight: '400px', }
    }
    const {fontSize} = JSON.parse(localStorage.getItem('jumperDetails'))
    return (
        <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="jump-create select-field"
        >
            <DropdownToggle caret disabled={disabled}>
                <i className="fa fa-plus" /> TANDEM
            </DropdownToggle>
            <DropdownMenu style={styledObject}>
                {packagesList.map((p, i) => {
                    return <DropdownItem className={itemClass} style={{fontSize: `${fontSize}px`}} onClick={() => createJump(p._id)} key={`${i}_createTandem`}>
                        <i className="fa fa-plus" />
                        <strong>{" "}{p.item.toUpperCase()}</strong>
                    </DropdownItem>
                })}
            </DropdownMenu>
        </ButtonDropdown>
    );
}
export default TandemJump;