import React, { Component } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Dropdown, DropdownItem } from 'reactstrap';

// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import BigCalendar from 'react-big-calendar';

import moment from 'moment-timezone';
import schedulesStore from '../../stores/schedule-store';
import reservationStore from '../../stores/reservation-store';

import logo from '../images/logo.svg';
import background from '../images/background.svg';
import SchedulerRight from './schedulesRight';
import LeftMenu from '../menu/LeftMenu1';
import HamburgerMenu from 'react-hamburger-menu';
import Reservation from './reservation/reservation';
import 'react-big-calendar-like-google/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar-like-google';
import booking from '../BookingComponent/booking';
import ErrorModal from '../../components/sub-components/Modal/ErrorModal';
import RightColumnCards from '../../components/sub-components/cards/rightColumnCards'
import Switch from 'rc-switch';
import SearchReservations from './searchReservation';
import IFrame from './Iframe';
import { Fragment } from 'react';
import FileSaver from 'file-saver';
import Send_Multiple_ReminderEmails from './sendMultipleReminderEmail';


const localizer = BigCalendar.momentLocalizer(moment);

BigCalendar.momentLocalizer(moment)

window.moment = moment;

const minTime = new Date();

minTime.setHours(7, 0, 0);

const maxTime = new Date();

maxTime.setHours(20, 0, 0);
/**
 * returns an object which creates elements of an event.
 * @param {*} d
 */

function prepareEventData(d) {
  var now = moment.utc();
  //get the zone offsets for this time, in minutes
  var serverOffset = moment.tz.zone(d.timeZone).utcOffset(now);

  var localOffset = moment.tz.zone(moment.tz.guess()).utcOffset(now);

  var dstOffset = 0;
  if ((d.timeZone === "Etc/UTC" || d.timeZone === "Africa/Abidjan") && moment(d.datetime).isDST() && !moment().isDST()) {
    //need to subtract
    dstOffset = -60;
  }
  if ((d.timeZone === "Etc/UTC" || d.timeZone === "Africa/Abidjan") && !moment(d.datetime).isDST() && moment().isDST()) {
    //need to add
    dstOffset = +60;
  }

  var payload = {
    // title: `${d.seatsRemaining} seats remaining - ${d.eventName}`,
    title: `${d.totalSeats - d.seatsRemaining}/${d.totalSeats} ${d.eventName}`,
    start: moment(d.datetime)
      .tz(d.timeZone)
      .add(localOffset - serverOffset + dstOffset, "minutes")
      .toDate(),
    end: moment(d.datetime)
      .tz(d.timeZone)
      .add(localOffset - serverOffset + dstOffset, "minutes")
      .add(0.5, "hours")
      .toDate(),
    eventName: d.eventName,
    seatsRemaining: d.seatsRemaining,
    timeZone: d.timeZone,
    allDay: false,
    id: d._id,
    isBlackedOut: d.isBlackedOut,
    isFull: d.isFull,
    reservedSeat: d.reservations.length,
    resource: { _id: d._id, seatsRemaining: d.seatsRemaining },
    totalSeats: d.totalSeats,
    eventCategory: d.eventCategory
  };
  return payload;
}


class SchedulesCenter extends Component {
  constructor(props) {
    super(props);
    this.currentSelectedDate = false;
    this.selectedTimeSlot = false;
    this.handlePdf = this.handlePdf.bind(this);
    this.state = {
      dropdownOpen: false,
      eventFilter: 'All',
      events: [],
      view: 'month',
      width: window.innerWidth,
      open: false,
      passengers: [],
      rightOpen: false,
      currentDate: '',
      showSchedular: true,
      warning: false,
      modelOpen: false,
      selectedEventId: '',
      ReservedEvent: [],
      incompleteReservation: [],
      isBlackout: false,
      isFull: false,
      selectedSlots: [],
      selectedMonth: null,
      isShowIframe: false,
      isSendEmailModalOpen: false,
      eventsList: [],
      openTo: '',
      singleTimeSlots: false,
      emailTemplates: [],
      selectedTemplate: "",
    };

    // list all events that needs to be listend to
    this.events = [
      { name: schedulesStore.getEventPrefix('fetchData'), func: this.handleMonthChangeResponse },
      { name: schedulesStore.getEventPrefix('createEvent'), func: this.handleNewEvents },
      { name: schedulesStore.getEventPrefix('warningEvent'), func: this.handleWarning },
      { name: schedulesStore.getEventPrefix('blackOutEvent'), func: this.handleBlackout },
      { name: schedulesStore.getEventPrefix('slotblackOutEvent'), func: this.handleSlotBlackout },
      { name: schedulesStore.getEventPrefix('fetchPassengers'), func: this.handleEventSelectResponse },
      { name: schedulesStore.getEventPrefix('slotDeletedEvent'), func: this.handleDeleteById },

      // { name: schedulesStore.getEventPrefix('IncompleteResevation'), func: this.handleIncompleteReservation }
    ];

    this.reservationEvents = [
      { name: reservationStore.getEventPrefix('warningEvent'), func: this.handleWarning },
      { name: reservationStore.getEventPrefix('slotChangeEvent'), func: this.handleSlotChange },
    ];

  }


  evalEventDivProps = (event, start, end, isSelected) => {
    console.log("Calender Event", event)
    // console.log("Reservation Event", this.state.ReservedEvent)
    if(event.isFull){
      return {
        className: 'isFull'
      }
    }
    if (this.state.ReservedEvent && this.state.ReservedEvent.length > 0) {
      if (this.state.ReservedEvent.filter((x) => { return new Date(x.start).toDateString() === new Date(event.start).toDateString() && x.id === event.id }).length > 0) {
        // if(new Date(event.start).toDateString()===new Date(this.state.ReservedEvent.start).toDateString()){
        return {
          className: 'ReservedDates'
        }
        // }
      }
    }
    return {
      className: event.isBlackedOut ? 'isBlackedOut' : 'noBlackout'
    };
  }

  handleSlotChange = (date) => {
    schedulesStore.fetchData(date);
  }

  handleWarning = () => {
    this.setState({
      warning: true
    })

    setTimeout(() => {
      this.setState({
        warning: false
      })
    }, 2000)
  }

  handleBlackout = () => {
    // schedulesStore.fetchData(new Date());
    let selectedDate = this.state.selectedMonth ? new Date(this.state.selectedMonth) : new Date()
    schedulesStore.fetchData(selectedDate);
    if (this.state.isBlackout) {
      //alert("BlackOut Successfully")
    }
    else {
      //alert("Undo BlackOut Successfully")
    }
  }

  handleSlotBlackout = (blackout) => {
    // schedulesStore.fetchData(new Date());
    let selectedDate = this.state.selectedMonth ? new Date(this.state.selectedMonth) : new Date()
    schedulesStore.fetchData(selectedDate);
    this.toggleModal();
    if (blackout) {
      //alert("BlackOut Successfully")
    }
    else {
      //alert("Undo BlackOut Successfully")
    }
  }
  loadEmailsTemplates = () => {
    console.log("calledschedulesStore");
    schedulesStore
      .getemailtemplates()
      .then((resp) => {
        console.log(resp, "resprespresprespresprespresp");
        this.setState({
          emailTemplates: resp.data,
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };


  componentWillMount = () => {
    //console.log('add listeners for scheduler');
    schedulesStore.fetchData(new Date());
    // schedulesStore.IncompleteResevation();
    this.events.forEach((evnt) => schedulesStore.on(evnt.name, evnt.func))
    this.reservationEvents.forEach((evnt) => reservationStore.on(evnt.name, evnt.func))

    window.addEventListener('resize', this.handleWindowSizeChange);

    let eventDate = schedulesStore.getEventPrefix('fetchReserveDate');
    schedulesStore.on(eventDate, this.handleReserveDate);

    let eventReservation = schedulesStore.getEventPrefix('showReservation');
    schedulesStore.on(eventReservation, this.showReservation);

    let eventClose = schedulesStore.getEventPrefix('closeReservationEvent');
    schedulesStore.on(eventClose, this.handleReservationClose);

    // let eventIncompleteResevation = schedulesStore.getEventPrefix('IncompleteResevation');
    // schedulesStore.on(eventIncompleteResevation, this.handleIncompleteReservation);

    var reservedDateList = this.state.events.length > 0 ? this.state.events.filter((x) => { return x.reservedSeat > 0 }) : [];
    this.setState({
      ReservedEvent: reservedDateList
    })
    schedulesStore.getEvents()
      .then(res => {
        this.setState({ eventsList: res.data })
      })

  }
  handleDeleteById = () => {
    let selectedDate = this.state.selectedMonth ? new Date(this.state.selectedMonth) : new Date()
    schedulesStore.fetchData(selectedDate);
    this.toggleModal();
  }

  handleReserveDate = (d) => {
    //alert("CurrentDatecenter:"+d);
    this.setState({ currentDate: d })
  };

  showReservation = (d) => {
    //alert("CurrentDatecenter:"+d);
    this.setState({ showSchedular: false })
  };

  componentWillUnmount = () => {
    this.events.forEach((evnt) => schedulesStore.removeListener(evnt.name, evnt.func));
    this.reservationEvents.forEach((evnt) => reservationStore.removeListener(evnt.name, evnt.func));
    //window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  searchedTimeslots = (slots) => {
    // when searching any string slots are coming
    this.setState({
      passengers: slots,
    })
  }
  loadTandemStudents = (reservation) => {
    // reservation is particular when a option is selected in search
    let passengers = this.state.passengers;
    let newArr = []
    passengers.forEach(val => {
      // console.log("loadTandemStudents",val,reservation);
      // filtered reservation and timeslots according to reservation selected
      if (val.updatedAt === reservation.updatedAt) {
        newArr.push({
          ...val,
          reservations: [reservation]
        })
      }
    })
    // console.log("newARRRR: ",newArr);
    this.setState({
      passengers: newArr,
    })

  }
  showIframe = (val) => {
    this.setState({ isShowIframe: true, openTo: val })
  }
  hideIFrame = () => {
    this.setState({ isShowIframe: false })
  }
  handleReservationClose = () => {
    this.setState({ showSchedular: true })
  };

  handleNewEvents = (r) => {
    var events = r.map((slot) => prepareEventData(slot));
    var preLoadedEvents = [...this.state.events, ...events];
    this.setState({ events: preLoadedEvents }, () => {
      var reservedDateList = this.state.events.length > 0 ? this.state.events.filter((x) => { return x.reservedSeat > 0 }) : [];
      this.setState({
        ReservedEvent: reservedDateList
      })
    });
  }

  handleMonthChangeResponse = (data) => {
    //console.log("🚀 ~ file: schedulesCenter.js ~ line 279 ~ SchedulesCenter ~ data", data)
    var processed = data.map((date) => prepareEventData(date));
    if (processed.length > 0) this.setState({ events: processed }, () => {
      var reservedDateList = this.state.events.length > 0 ? this.state.events.filter((x) => { return x.reservedSeat > 0 }) : [];
      this.setState({
        ReservedEvent: reservedDateList
      })
      this.insertTotalSeat();
    });

  }

  handleMonthChange = (date) => {
    //console.log('change');
    // console.log("🚀 ~ file: schedulesCenter.js ~ line 289 ~ SchedulesCenter ~ date", date)
    schedulesStore.fetchData(date);
    this.setState({
      selectedMonth: date
    })
  }


  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  componentDidMount = () => {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.loadEmailsTemplates();
  }


  handleClick() {
    this.setState({
      open: !this.state.open
    });
  }

  handleRightClick() {
    this.setState({
      rightOpen: !this.state.rightOpen
    });
  }

  toggleModal = () => {
    this.setState({ modelOpen: !this.state.modelOpen });
  }

  handleEventSelectResponse = (data) => {
    //alert('in handleEventSelectResponse');
    this.setState({ passengers: data.timeslots });
  };

  handleIncompleteReservation = (data) => {
    this.setState({ incompleteReservation: data })
  };

  handleEventSelect = (event) => {
    this.selectedTimeSlot = moment(event.start).format("hh:mm a");
    this.currentSelectedDate = new moment(event.start).format("YYYY-MM-DD");
    console.log(event, "<====event1111111")
    // console.log("handleEventSelect: ",event)
    schedulesStore.fetchPassengers(event)
      .then(r => {

        var timeSlots = [];
        timeSlots = r.data.timeslots;
        var noBlackoutEvent = timeSlots.filter(value => { return !value.isBlackedOut });
        if (noBlackoutEvent.length > 0) {
          this.setState({ isBlackout: false });
        }
        else {
          this.setState({ isBlackout: true });
        }

        var noIsFullEvent = timeSlots.filter(value => { return !value.isFull });
        if (noIsFullEvent.length > 0) {
          this.setState({ isFull: false });
        }
        else {
          this.setState({ isFull: true });
        }

        this.setState({ selectedEventId: event.id });
        this.toggleModal();
      });

  }

  onFilterChange = (e) => this.setState({ eventFilter: e.target.value })

  toggleDropDown = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });
  
  handleBlackoutSelectedDate = (selectedDate) => {
    // console.log('Click', this.state.currentDate);
    var payload = {
      day: selectedDate,
      isBlackedOut: this.state.isBlackout
    }
    schedulesStore.blackOutSlots(payload);
    this.toggleModal();
  }

  handleSetFullSelectedDate = (selectedDate) => {
    // console.log('Click', this.state.currentDate);
    var payload = {
      day: selectedDate,
      isFull: this.state.isFull
    }
    schedulesStore.setFullSlots(payload);
    this.toggleModal();
  }

  handlePdf = (date) => {
    reservationStore.generateReservationPdf(date).then(data => {
      FileSaver.saveAs(data, `reservation_${date}.pdf`)
    })
  }

  bookings = (id, singleTimeSlots, _selectedEmailTemplate) => {
    this.setState({ selectedTemplate: _selectedEmailTemplate, isSendEmailModalOpen: true, selectedEventId: id, singleTimeSlots: singleTimeSlots, selectedEmailTemplate: _selectedEmailTemplate });
  };

  insertTotalSeat = () => {
    const bookingObj = this.getDayWiseBooking()
    const monthRows = document.querySelectorAll('.rbc-month-view .rbc-month-row');
    for (let i = 0; i < monthRows.length; i++) {
      const rowContent = monthRows[i]?.querySelectorAll('.rbc-row-content .rbc-row')[0];
      const numRBCRows = monthRows[i]?.querySelectorAll('.rbc-row-content .rbc-row');
      if (numRBCRows && numRBCRows.length > 1) {
        let dateCell = rowContent?.querySelectorAll('.rbc-date-cell');
        for (let x = 0; x < 7; x++) {
          if (dateCell && dateCell[x] && !dateCell[x].classList.contains("rbc-off-range")) {
            const curDate = dateCell[x].getElementsByTagName("a")[0].innerText;
            const spanEle = dateCell[x].getElementsByTagName("span")[0];
            spanEle && spanEle.parentNode.removeChild(spanEle);
            const dataEle = dateCell[x].innerHTML;
            if (bookingObj[curDate] && (bookingObj[curDate].tandemBooking !== undefined || bookingObj[curDate].affBooking !== undefined)) {
              dateCell[x].innerHTML = `<span class='bookedSeats'>T: <strong>${(bookingObj[curDate]?.tandemBooking || 0)} </strong> S: <strong>${(bookingObj[curDate]?.affBooking || 0)}</strong></span>` + dataEle;
            }
          }
        }
      }
    }
  }

  getDayWiseBooking = () => {
    const totalEvents = this.state.events;
    const bookingObj = {}
    totalEvents.forEach(item => {
      let curDate = (item.start)?.getDate();
      curDate < 10 && (curDate = "0" + curDate);
      if (item.eventCategory === "Tandem") {
        bookingObj[curDate] = {
          ...bookingObj[curDate],
          tandemBooking: (bookingObj[curDate]?.tandemBooking || 0) + (item.totalSeats - item.seatsRemaining)
        };
      } else if (item.eventCategory === "AFF") {
        bookingObj[curDate] = {
          ...bookingObj[curDate],
          affBooking: (bookingObj[curDate]?.affBooking || 0) + (item.totalSeats - item.seatsRemaining)
        };
      }
    });
    return bookingObj;
  }

  render() {
    const { width, warning, events, eventFilter, ReservedEvent } = this.state;
    let filteredEvents = events;
    if (eventFilter !== 'All') {
      filteredEvents = events.filter((itm) => {
        if (itm.eventName === 'AFF') itm.eventName = 'AFF First Jump Course'
        if (itm.eventName === 'Tandem') itm.eventName = 'Tandem Jump'
        return itm.eventName === eventFilter
      });
    }
    let leftContainer = 'left-container'
    const isMobile = width <= 500;
    return (
      <React.Fragment>
        {this.state.isSendEmailModalOpen && (
          <Send_Multiple_ReminderEmails
            currentSelectedDate={this.currentSelectedDate}
            isMemoModalOpen={this.state.isSendEmailModalOpen}
            selectedTemplate={this.state.emailTemplates.find((temp) => temp._id == this.state.selectedTemplate)}
            state={this.state}
            setState={this.setState.bind(this)}
          />
        )}
        <Modal
          isOpen={this.state.modelOpen}
          toggle={this.toggleModal}
          size='lg'
        >
          <ModalHeader toggle={this.toggleModal} style={{ color: "#105ec1" }}>
            More Details ({this.currentSelectedDate})
          </ModalHeader>
          <ModalBody style={{ position: "relative", color: "#105ec1" }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <button className="btn btn-primary mr-1" onClick={this.bookings}>
                Send Reminder Email
              </button> */}
              {/* <select
                style={{ width: "210px" }}
                className="form-control mx-2"
                value={"always"}
                onChange={(e) => {
                 this.bookings(undefined,undefined, e.target?.value)
                }}
              >
                <option value="always">Send Reminder Email</option>
                {this.state.emailTemplates?.map((template) => (
                  <option value={template?._id}>{template.name}</option>
                ))}
              </select> */}
              <SendEmailSelect
                templates={this.state.emailTemplates}
                onSelect={(value) => {
                  this.bookings(undefined, undefined, value);
                }}
              />
              <Switch
                onChange={() => {
                  this.setState({ isFull: !this.state.isFull }, () => {
                    this.handleSetFullSelectedDate(this.currentSelectedDate)
                  });
                }}
                disabled={false}
                checkedChildren="Unset Full"
                unCheckedChildren="Set Full"
                checked={this.state.isFull}
                className="btn btn-primary mr-1"
              />
              <Switch
                onChange={() => {
                  this.setState({ isBlackout: !this.state.isBlackout }, () => {
                    this.handleBlackoutSelectedDate(this.currentSelectedDate)
                  });
                }}
                disabled={false}
                checkedChildren="Undo Blackout"
                unCheckedChildren="Blackout"
                checked={this.state.isBlackout}
                className="btn btn-primary mr-1"
              />
              <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.handlePdf(this.currentSelectedDate) }}>Print</button>
            </div>
            {this.currentSelectedDate &&
              <RightColumnCards
                selectedTimeSlot={this.selectedTimeSlot}
                isCollapsable={true}
                emailTemplates={this.state.emailTemplates}
                event={this.state.passengers}
                currentDate={this.currentSelectedDate}
                edit={true}
                isCheckBoxShow={true}
                templates={this.state.emailTemplates}
                _bookings={this.bookings}
              />
            }
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.toggleModal}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>


        <div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>

          <IFrame isShowIframe={this.state.isShowIframe} hideIframe={this.hideIFrame} openTo={this.state.openTo} />


          {
            isMobile ?
              <div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
                <div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
                  <LeftMenu routeHistory={this.props.history} showCreate={true} />
                </div>
              </div>
              :
              <div className={`blueBg fullHeightPanes ${leftContainer}`}>
                <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
                <LeftMenu routeHistory={this.props.history} showCreate={true} />
              </div>
          }

          <div className="middle-container">
            {
              isMobile ?
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-md-12">

                    <div className="hamBurger-menu">
                      <HamburgerMenu
                        isOpen={this.state.open}
                        menuClicked={this.handleClick.bind(this)}
                        width={22}
                        height={17}
                        strokeWidth={1}
                        rotate={0}
                        color='white'
                        borderRadius={0}
                        animationDuration={0.5}
                      />

                    </div>
                    <div className="hamBurger-right">
                      <HamburgerMenu
                        isOpen={this.state.rightOpen}
                        menuClicked={this.handleRightClick.bind(this)}
                        width={22}
                        height={17}
                        strokeWidth={1}
                        rotate={0}
                        color='white'
                        borderRadius={0}
                        animationDuration={0.5}
                      />
                    </div>
                  </div>
                </div>
                :
                ''
            }
            <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
            {
              this.state.showSchedular ?
                <div className="row big-calender" style={{ margin: 'auto' }}>
                  <div className="col-md-12">
                    <div style={{ maxWidth: 310 }}>
                      <FormGroup>
                        <div className="row mt-2">
                          <div className="col-4 p-0">
                            <Label for="selectEvent" style={{ color: 'white' }}>Filter By</Label>
                            <Input type="select" name="select" id="selectEvent" onChange={this.onFilterChange}>
                              <option value="All">All</option>
                              {/* <option value="Tandem">Tandem Jump</option>
                              <option value="AFF">AFF First Jump Course</option> */}
                              {
                                this.state.eventsList.map((evt, i) => {
                                  if (evt.eventName === 'Tandem') evt.eventName = "Tandem Jump"
                                  if (evt.eventName === 'AFF') evt.eventName = "AFF First Jump Course"
                                  return (
                                    <Fragment key={i + 1} >
                                      <option value={evt.eventName}>{evt.eventName}</option>
                                    </Fragment>
                                  )
                                })
                              }
                              {/* <option value="AFF">Pilot Course</option>
                              <option value="AFF">Coach Course</option> */}
                            </Input>
                          </div>
                          <div className="col-8">
                            <Label for="searchReservation" style={{ color: 'white' }}>
                              Search reservation:
                            </Label>
                            <SearchReservations
                              edit={true}
                              placeholder="search reservations"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                  </div>

                  <div
                    className="col"
                    style={{
                      height: '80vh',
                      background: 'white',
                      padding: 0,
                      borderRadius: 0
                    }}
                  >
                    <BigCalendar
                      localizer={localizer}
                      events={filteredEvents}
                      drilldownView="day"
                      startAccessor="start"
                      endAccessor="end"
                      showAllEvents
                      onShowMore={(events, date) => alert(date)}
                      eventPropGetter={this.evalEventDivProps}
                      onSelectEvent={this.handleEventSelect}
                      onNavigate={this.handleMonthChange}
                      onView={(e) => schedulesStore.updateActiveView({ view: e })}
                      step={30}
                      timeslots={filteredEvents.length}
                      defaultDate={new Date()}
                      allDay={false}
                      popup={true}
                      min={minTime}
                      max={maxTime}
                    />
                  </div>
                </div>
                :
                <Reservation scheduleDate={this.state.currentDate} />
            }
          </div>


          {
            isMobile ?
              <div className={` ${this.state.rightOpen ? "right-menu" : "hideSideBar"}`}>
                <div className={`${this.state.rightOpen ? "show-panel" : "hide-Panel"}`}>
                  <div className="col-lg-2 col-md-2 col-sm-12 blueBg fullHeightPanes rightCol" style={{ padding: '0px' }}>
                    <SchedulerRight selectedEventId={this.state.selectedEventId} history={this.props.history} showIframe={this.showIframe} />
                  </div>
                </div>
              </div>
              :
              <div className="blueBg fullHeightPanes rightCol" style={{ padding: '0px' }}>
                <SchedulerRight selectedEventId={this.state.selectedEventId} history={this.props.history} showIframe={this.showIframe} />
              </div>
          }

          {warning && <ErrorModal show={warning} />}
          {this.insertTotalSeat()}

        </div>
      </React.Fragment>
    );
  }
}

export default SchedulesCenter;

export const SendEmailSelect = ({ onSelect, templates, asSelect = false }) => {
  return (

    <select
      style={{ width: "210px" }}
      className="form-control"
      onChange={(e) => {
        onSelect(e.target?.value);
      }}
      {...(!asSelect ? { value: "always" } : {})}
    >
      {!asSelect ? <option value="always">Select Email Template</option> : <option value="always">--select template--</option>}
      {!asSelect ? <option value="default-template">Default Reminder Email</option> : <option value="default-template">Default Reminder Email</option>}
      {templates?.map((template) => (
        <option value={template?._id}>{template.name}</option>
      ))}
    </select>
  );
};


