import { EventEmitter } from 'events';

import Api from '../services/reportsapi';
import * as moment from 'moment';
import swal from 'sweetalert';


class ReportStore extends EventEmitter {
	constructor() {
		super();
		this.prefix = 'REPORTS_STORE_';
		this.paySchedule = [];
		this.priceSchedule = [];
		this.itemsSchedule = [];
		this.dropzoneTodayActivity = {};
		this.JumperLogs = [];
	}


	setListeners = (socket) => {
		socket.on("getInvoiceByCategory", data => {
			//console.log(data);
			//var loading = true;
			//this.emitResults(loading,'loaderActivity');
		});
		socket.on('updateReservationInfo', data => {
			console.log('updateReservatioInfo');
			// console.log('update reservation info ');
			// console.log(data);
			this.setDropzoneTodayActivity(data.dropzoneTodayActivity);
			this.emitResults(this.getDropzoneTodayActivity(), 'loadDropzoneDailyActivity');
		})
	}

	loadPrices = () => {
		//console.log('in load Prices');
		return new Promise((resolve, reject) => {
			Api.loadPrices().then((r) => {
				if (r.status == 200) {
					var prices = r.data;
					this.paySchedule = r.data.paySchedule;
					this.priceSchedule = r.data.priceSchedule;
					this.itemsSchedule = r.data.itemsSchedule;
					//console.log("price data:" + JSON.stringify(r.data))
					this.emitResults(true, 'schedulesLoaded')
					resolve(prices);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getFunJumpDetails = (startDate, endDate, subType) => {
		console.log('store get fun jump details');
		return new Promise((resolve, reject) => {
			Api.GetFunJumpDetails(startDate, endDate, subType).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	resendGiftcardEmail = (payload) => {
		return new Promise((reslove, reject) => {
			Api.resendGiftcardEmail(payload)
				.then(r => {
					// console.log("🚀 ~ file: report-store.js ~ line 89 ~ ReportStore ~ returnnewPromise ~ r", r)
					if (r.data.status === "success") {
						this.emitResults(payload, 'giftcardEmailSuccess');
					}
					else {
						throw new Error('server error')
					}
				})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				})

		})
	}
	getAffDetails = (startDate, endDate) => {
		return new Promise((resolve, reject) => {
			Api.GetAffDetails(startDate, endDate).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getTandemDetails = (startDate, endDate, subType) => {
		return new Promise((resolve, reject) => {
			Api.GetTandemDetails(startDate, endDate, subType).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getCoachJumpDetails = (startDate, endDate, subType) => {
		return new Promise((resolve, reject) => {
			Api.GetCoachJumpDetails(startDate, endDate, subType).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getCollectionDetails = (startDate, endDate, subType) => {
		return new Promise((resolve, reject) => {
			Api.GetCollectionDetails(startDate, endDate, subType).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getCountersaleDetails = (startDate, endDate, subType) => {
		return new Promise((resolve, reject) => {
			Api.GetCountersaleDetails(startDate, endDate, subType).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getJumperPayouts = (startDate, endDate, subType) => {
		return new Promise((resolve, reject) => {
			Api.GetJumperPayouts(startDate, endDate, subType).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					console.log(err, "err")
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getTandemPayouts = (startDate, endDate, subType) => {
		return new Promise((resolve, reject) => {
			Api.GetTandemPayouts(startDate, endDate, subType).then((r) => {
				if (r.status == 200) {
					//console.log("categoryapidata:"+JSON.stringify(r.data))
					this.emitResults(r.data, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					console.log(err, "err")
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getInvoiceByCategory = (startDate, endDate) => {
		return new Promise((resolve, reject) => {
			Api.GetInvoiceByCategory(startDate, endDate).then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					//console.log("categoryapidata:"+JSON.stringify(r.data))

					resolve(invoice);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getInvoiceByCategoryPDF = (startDate, endDate) => {
		return new Promise((resolve, reject) => {
			Api.GetInvoiceByCategoryPDF(startDate, endDate).then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					//console.log("categoryapidata:"+JSON.stringify(r.data))

					resolve(invoice);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not get Invoice report, please contact support.' });
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetDateOfLastZeroPostedBalance = (id) => {
		return new Promise((resolve, reject) => {
			Api.GetDateOfLastZeroPostedBalance(id).then((r) => {
				if (r.status == 200) {
					var startDate = r.data;
					resolve(startDate);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Jumper Logs, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetTandemLogs = (id) => {
		// console.log("🚀 ~ file: report-store.js ~ line 301 ~ ReportStore ~ start, end, id", start, end, id)
		return new Promise((resolve, reject) => {
			Api.GetTandemLogs(id).then((r) => {
				// console.log("🚀 ~ file: report-store.js ~ line 304 ~ ReportStore ~ Api.GetJumperLogs ~ r", r)
				if (r.status == 200) {
					this.JumperLogs = r.data;
					this.emitResults(r.data, 'jumperLogEvent');
					resolve(r.data);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not get Jumper Logs, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	GetJumperLastFewLogs = (numLogs, id) => {
		return new Promise((resolve, reject) => {
			Api.GetJumperLastFewLogs(numLogs, id).then((r) => {
				// console.log("🚀 ~ file: report-store.js ~ line 304 ~ ReportStore ~ Api.GetJumperLogs ~ r", r)
				if (r.status == 200) {
					this.JumperLogs = r.data;
					this.emitResults(r.data, 'jumperLogEvent');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Jumper Logs, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	GetJumperLogs = (start, end, id) => {
		// console.log("🚀 ~ file: report-store.js ~ line 301 ~ ReportStore ~ start, end, id", start, end, id)
		return new Promise((resolve, reject) => {
			Api.GetJumperLogs(start, end, id).then((r) => {
				// console.log("🚀 ~ file: report-store.js ~ line 304 ~ ReportStore ~ Api.GetJumperLogs ~ r", r)
				if (r.status == 200) {
					this.JumperLogs = r.data;
					this.emitResults(r.data, 'jumperLogEvent');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Jumper Logs, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	GetJumperLogsPDF = (start, end, id) => {
		return new Promise((resolve, reject) => {
			Api.GetJumperLogsPDF(start, end, id).then((r) => {
				if (r.status == 200) {
					var jumperLogs = r.data;
					this.JumperLogs = r.data;
					this.emitResults(jumperLogs, 'jumperLogEvent');
					resolve(jumperLogs);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not get Jumper Logs, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	GetTodayJumperLogs = (end, id) => {
		return new Promise((resolve, reject) => {
			Api.GetTodayJumperLogs(end, id).then((r) => {
				if (r.status == 200) {
					var jumperLogs = r.data;
					this.JumperLogs = r.data;
					this.emitResults(jumperLogs, 'jumperLogEvent');
					resolve(jumperLogs);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Jumper Logs, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	GetSlots = (start, end) => {
		return new Promise((resolve, reject) => {
			Api.GetSlots(start, end).then((r) => {
				if (r.status == 200) {
					var slots = r.data;
					////console.log("apidata:"+JSON.stringify(r.data))
					resolve(slots);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Slots, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetPlaneReport = (start, end, subType) => {
		return new Promise((resolve, reject) => {
			Api.GetPlaneReport(start, end).then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					resolve(invoice);
					subType && this.emitResults(invoice, 'reportDetails');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Plane Report, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetJumperState = () => {
		return new Promise((resolve, reject) => {
			Api.GetJumperState().then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					console.log("Jumper State", invoice)
					resolve(invoice);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Jumper State, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetTandemState = () => {
		return new Promise((resolve, reject) => {
			Api.GetTandemState().then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					console.log("Tandem State", invoice)
					resolve(invoice);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Tandem State, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	GetStaffSummary = (start, end) => {
		return new Promise((resolve, reject) => {
			Api.GetStaffSummary(start, end).then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					console.log("from staff sumery aa", invoice)

					// console.log()
					//console.log("apidata:"+JSON.stringify(r.data))
					resolve(invoice);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Staff summary, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetPlaneReportCSV = (start, end) => {
		return new Promise((resolve, reject) => {
			Api.GetPlaneReportCSV(start, end).then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					resolve(invoice);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get plane report csv, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetStaffSummaryCSV = (start, end) => {
		return new Promise((resolve, reject) => {
			Api.GetStaffSummaryCSV(start, end).then((r) => {
				if (r.status == 200) {
					var invoice = r.data;
					console.log("from staff sumery aa", invoice)

					// console.log()
					//console.log("apidata:"+JSON.stringify(r.data))
					resolve(invoice);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not get Staff summary, please contact support.' });
					resolve(false);
				}
				var loading = true;
				this.emitResults(loading, 'loaderActivity');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	UpdatePaySchedule = (id, priceBody) => {
		return new Promise((resolve, reject) => {
			Api.updatePaySchedule(id, priceBody).then((r) => {
				if (r.status == 200) {
					//console.log('Update PaySchedule');
					var status = true;
					this.emitResults(status, 'priceActivity');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Price could not be updated, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	UpdatePriceSchedule = (id, priceBody) => {
		return new Promise((resolve, reject) => {
			Api.updatePriceSchedule(id, priceBody).then((r) => {
				if (r.status == 200) {
					//console.log('updated price');
					var status = true;
					this.emitResults(status, 'priceActivity');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Price could not be updated, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	getCoupons = () => {
		return new Promise((resolve, reject) => {
			Api.getCoupons().then((r) => {
				if (r.status == 200) {
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	loadDropzoneTodayActivity = () => {
		return new Promise((resolve, reject) => {
			Api.getDropzoneTodayActivity().then((r) => {
				if (r.status == 200) {
					this.setDropzoneTodayActivity(r.data);
					this.emitResults(this.getDropzoneTodayActivity(), 'loadDropzoneDailyActivity');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	getGiftPurchaseByGiftcardId = (id) => {
		return new Promise((resolve, reject) => {
			Api.getGiftPurchaseByGiftcardId(id)
				.then(r => {
					resolve(r);
					if (r.data.status === "success") { }
					this.emitResults(r.data, "giftpurchase_loaded")
				})
				.catch(err => {
					this.emitError({ message: 'Server error' });
					reject(err);
				})
		})
	}

	refundPaymentNGiftCard = (transactionId, payload) => {
        return new Promise((resolve, reject) => {
            Api.refundPaymentNGiftCard(transactionId, payload).then((res) => {
                if(res.status === 200) {
                    this.emitResults(payload, "giftcard_refunded");
                    resolve(res);
                }else {
					resolve(res.data)
				}
            })
            .catch((err) => {
                this.emitError({ message: 'Server error' });
				reject(err);
            })
        })
    }

	refundGiftcards = (payload) => {
		return new Promise((resolve, reject) => {
			Api.refundGiftcards(payload)
				.then(r => {
					if (r.data.status === "success") {
						resolve(true);
						this.emitResults(payload, "giftcard_refunded")
					}
				})
				.catch(err => {
					this.emitError({ message: 'Server error' });
					reject(false);
				})
		})
	}

	getDropzoneTodayActivity = () => {
		return this.dropzoneTodayActivity;
	}

	setDropzoneTodayActivity = (dropzoneTodayActivity) => {
		//console.log('in set DropzoneTodayActivity');
		this.dropzoneTodayActivity = dropzoneTodayActivity;
	}

	clearState = () => {
		this.paySchedule = [];
		this.priceSchedule = [];
		this.dropzoneTodayActivity = {};
	}

	loginExpired = () => {
		//alert(JSON.stringify(localStorage, null,2));
		if(localStorage.getItem('isAuth')){
			localStorage.clear();
			swal({
				title: "LOGIN EXPIRED",
				text: "please re-login",
				icon: "warning",
				dangerMode: true,
			})
		}

		//window.location =  window.location.origin
		//window.location.reload();
	}

	emitError = (err) => {
		var payload = { ...err };
		this.emit('ERROR', payload);
	}
	getPaySchedule = () => {
		return this.paySchedule
	}
	getPriceSchedule = () => {
		return this.priceSchedule
	}
	getEventPrefix = (func) => {
		return `${this.prefix}${func}`;
	}

	emitResults = (r, func) => {
		if (!r) {
			//console.log(r);
			this.emitError({ alert: true, message: 'No Slots found' });
		}
		this.setMaxListeners(1000);
		this.emit(`${this.prefix}${func}`, r);
	}

	debug = (r) => {
		//console.log(r.data);
	}
}

const reportStore = new ReportStore();
window.reportStore = reportStore;
export default reportStore;
