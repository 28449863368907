/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

import jumpersStore from '../../../stores/jumpers-store';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';
import AdminModal from './customModal';
import { ClipLoader } from 'react-spinners';
import loadsStore from '../../../stores/loads-store';


class DeleteJumper extends Component {

  constructor(props) {
    super(props);
    // this.errortoggle = this.errortoggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      uspaMemberNumber: '',
      isModalOpen: false,
      isloading: false,
      isError: false,
    }

    this.jumperEvents = [
      { name: jumpersStore.getEventPrefix('deleteEvent'), func: this.handleDelete },
      { name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumper }
    ];
  }

  toggleModal(e) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  componentDidMount = () => {
    //console.log('in components did mount accounts');
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
  }

  componentWillUnmount = () => {
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
  }

  handleDelete = (result) => {
    console.log("Deleted", result);
    jumpersStore.loadAllJumpers();
  }

  handleJumper = () => {
    this.setState({
      isloading: false,
      isModalOpen: false
    });
  }

  handleSubmit() {
    var id = this.props.jumperDetail._id;
    this.setState({ isloading: true });
    // var payload = {};
    if (id) {
      jumpersStore.deleteJumper(id);
      this.props.closeModal(false)
      // .then((res)=>{
      //   //console.log(res);
      //   setTimeout(() => {
      //     this.setState({ isloading: false,isModalOpen:false });
      //   },3000)
      // });
    }
    else {
      this.setState({ isloading: false, isModalOpen: false });
    }
  }

  // errortoggle() {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal
  //   }));
  // }

  render() {
    console.log("PROPS", this.props);

    const { jumperDetail } = this.props;

    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    return (
      <React.Fragment>
        <AdminModal
          isModalOpen={this.state.isModalOpen}
          title={`Delete Jumper`}
        >

          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>
          {/* <LocalForm onSubmit={(values) => this.handleSubmit()}> */}
          <p>Are you Sure you want to delete Jumper {jumperDetail?.firstname}  ! </p>
          <Row className="form-group m-t-2-rem">
            <Col md={{ size: 10 }}>
              <button className="btn btn-bg lightColor createButton" type="button" onClick={this.handleSubmit}>YES</button>
              <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
            </Col>
          </Row>
          {/* </LocalForm> */}
        </AdminModal>
        <span style={{ position: 'absolute', left: '15px' }}>
          <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
            <i className="fa fa-trash
            "></i>
          </button>
        </span>
      </React.Fragment>
    );
  }
}

export default DeleteJumper