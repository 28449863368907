/**
 * http://usejsdoc.org/
 */
import { create } from 'apisauce';
import authStore from '../stores/auth-store';
import reservationStore from '../stores/reservation-store';
// define the api
import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const func = {};

func.loadAllJumpers = () => {
	// console.log('in loadAllJumpers');
	return api.get('/jumpers');
}

func.initializeLocalJumpers = () => {
	// console.log('in initializeLocalJumpers');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/initLocal');
}

func.loadTandemByDate = (startDate, endDate) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getTandemStudents?start=' + startDate + '&end=' + endDate);
}

func.loadTandems = (tandemIds) => {
	// console.log('in initializeLocalJumpers');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	var body ={
		tandemIds
	}

	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/jumpers/loadTandems', body);
}



func.loadTodayTandemStudents = () => {
	// console.log('in initializeLocalJumpers');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	let sDate = new Date();
	sDate.setHours(0, 0, 0, 0);
	sDate.setMinutes(sDate.getMinutes() - sDate.getTimezoneOffset());
	let startDate = new Date(sDate);
	startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
	//alert(startDate);

	let eDate = new Date();
	eDate = eDate.setHours(23, 59, 59, 999);
	let endDate = new Date(eDate);
	endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/loadTodayTandemStudents?start=' + startDate + '&end=' + endDate);
}

func.getDailyActivity = (jumperId) => {
	// console.log('in get Daily Activity');
	return api.get('/reports/getDailyActivities/' + jumperId);
}

func.getFunJumps = (iOptions) => {

	const { istoday, isPositive, isNegative, isStaff, isStudent, hasTickets } = iOptions;
	
	// Construct the query string based on the options provided
	const queryParams = {
		istoday,
		isPositive,
		isNegative,
		isStaff,
		isStudent,
		hasTickets
	};
	
	// Filter out undefined values from the queryParams object
	const filteredParams = Object.keys(queryParams)
		.filter(key => queryParams[key] !== undefined)
		.map(key => `${key}=${queryParams[key]}`)
		.join('&');

	// Make the API call with the constructed query parameters
	return api.get(`/jumpers/funJumps?${filteredParams}`);

};

func.getTandemJumps = (iOptions) => {

	const { istoday, isPositive, isNegative } = iOptions;
	
	// Construct the query string based on the options provided
	const queryParams = {
		istoday,
		isPositive,
		isNegative
	};
	
	// Filter out undefined values from the queryParams object
	const filteredParams = Object.keys(queryParams)
		.filter(key => queryParams[key] !== undefined)
		.map(key => `${key}=${queryParams[key]}`)
		.join('&');

	// Make the API call with the constructed query parameters
	return api.get(`/jumpers/tandemJumps?${filteredParams}`);

};


func.getJumpWithSuggestions = (payload) => {
	// console.log('in get Daily Activity');
	return api.post('/reports/getJumpWithSuggestions/', payload);
}

func.getTandemDailyActivity = (jumperId) => {
	// console.log('in get Tandem Daily Activity');
	return api.get('/reports/getTandemDailyActivities/' + jumperId);
}

func.getAllDailyActivity = () => {
	// console.log('in get Daily Activity');
	return api.get('/reports/getAllDailyActivities/');
}

func.sendTandemsToPublicJumper = (tandems) => {
	// console.log('send Tandems to Public Jumper');
	return api.post('/jumpers/sendTandemStudents', tandems);
}

func.tellJumperAppToLoadJumpers = () => {
	// console.log('send Tandems to Public Jumper');
	return api.get('/jumpers/tellJumperAppToLoadJumpers');
}

func.deleteJumperLocally = (id) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/jumpers/delete/' + id);
}

func.getLastTransaction = (id) => {
	// console.log('get last Transaction');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/getlastcharge/' + id);
}

func.getTandemLastCharge = (id) => {
	// console.log('get last Tandem Transaction');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/getlastStudentcharge/' + id);
}


func.loadGlobalJumperOptions = (queryString) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/global/?searchString=' + queryString);
}

func.getTandemOptions = (queryString) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/tandems/?searchString=' + queryString);
}

func.getJumperOptions = (queryString) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/find/?searchString=' + queryString);
}

func.getReservationOptions = (queryString) => {
	console.log('in search reservations options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	console.log("querystring: ", queryString);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/reservations/?searchString=' + queryString);
}

func.searchTandemWaivers = (firstname, lastname) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/searchTandemWaivers/?firstname=' + firstname + "&lastname=" + lastname);
}

func.searchStudentWaivers = (firstname, lastname) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/searchStudentWaivers/?firstname=' + firstname + "&lastname=" + lastname);
}

func.attachWaiver = (payload) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/jumpers/attachWaiver/', payload);
}

func.attachStudentWaiver = (payload) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/jumpers/attachStudentWaiver/', payload);
}

func.setStudentToLicensedStatus = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/setStudentToLicensedStatus/' + id);
}

func.updateManifestNumber = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/updateManifestNumber/' + id);
}

func.getRepeatTandems = () => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/repeatTandems');
}

func.createTandemWalkin = (jumperBody) => {
	//alert('in create Jumper api');
	//console.log("in Create Jumper Activity");
	//console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/jumpers/local/tandem', jumperBody);
}


func.updateWalkinTandemInfo = (id, jumperBody) => {
	//alert('in create Jumper api');
	//console.log("in Create Jumper Activity");
	//console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/jumpers/local/tandem/' + id, jumperBody);
}

func.updateTandemStudentInfo = (id, jumperBody) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/jumpers/local/tandemStudent/' + id, jumperBody);
}
func.createJumper = (jumperBody) => {
	//alert('in create Jumper api');
	//console.log("in Create Jumper Activity");
	//console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/jumpers/local', jumperBody);
}



func.updateJumpRequest = (id, payload) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/jumpers/updateJumpRequest/' + id, payload);
}

func.updateStaffOrderNumber = (id, payload) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/jumpers/updateStaffOrderNumber/' + id, payload);
}

func.checkin = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/checkin/' + id);
}

func.checkout = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/checkout/' + id);
}



func.trackjumper = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/trackjumper/' + id);
}

func.stoptrackingjumper = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/stoptrackingjumper/' + id);
}

func.LOcheckin = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/locheckin/' + id);
}

func.LOcheckout = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/locheckout/' + id);
}

func.teamcheckin = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/teamcheckin/' + id);
}

func.teamcheckout = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/teamcheckout/' + id);
}

func.OVcheckin = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/ovcheckin/' + id);
}

func.OVcheckout = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/ovcheckout/' + id);
}

func.checkoutall = (id) => {
	// console.log('in load global jumper options');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/checkoutall/');
}

func.addCardOnFile = (id, tokenBody) => {
	//alert('in create Jumper api');
	// console.log("in Update Card Token api");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/' + id + '/addCardOnFile', tokenBody);
}

func.RemoveCardOnFile = (jumperid, id) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('jumpers/' + jumperid + '/cardOnFile/' + id);
}

func.RemoveAccountOnFile = (jumperid, id) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('jumpers/' + jumperid + '/AccountOnFile/' + id);
}

func.addAccountOnFile = (id, tokenBody) => {
	//alert('in create Jumper api');
	// console.log("in Update Card Token api");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/' + id + '/addAccountOnFile', tokenBody);
}

func.syncWithUspa = (id, tokenBody) => {
	//alert('in create Jumper api');
	// console.log("in Update Card Token api");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('jumpers/' + id + '/syncWithUspa');
}

func.addRating = (memberNumber, ratingBody) => {
	// console.log('add Rating');
	// console.log("JumperID: " + memberNumber);
	// console.log(JSON.stringify(ratingBody));
	return api.post('/jumpers/' + memberNumber + '/addRating', ratingBody);
}

func.removeJoinTandems = (id, payload) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	console.log('removeJoinTandems');

	console.log(id);
	console.log(payload);

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/removeJoinedTandems/' + id, payload);
}


func.mergeJumpers = (id, payload) => {
	console.log('merge jumpers api call');
	console.log(id);
	console.log(payload);
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/mergeJumpers/' + id, payload);
}

func.mergeTandems = (id, payload) => {

	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/mergeTandems/' + id, payload);
}

func.joinTandems = (id, payload) => {

	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/joinTandems/' + id, payload);
}

func.toggleStudentWaitingStatus = (id) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('jumpers/toggleStudentWaitingStatus/' + id);
}


func.getStudentLogs = (id) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('jumpers/logs/' + id);
}

func.submitStudentLog = (id, payload) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/logs/' + id, payload)
}

func.UpdateJumper = (id, jumperBody) => {
	// return api.put('/jumpers/'+memberNumber+'/addRating',ratingBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('jumpers/local/' + id, jumperBody);
}

func.TransferCredit = (creditBody) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/transferCredit', creditBody);
}


func.UpgradeBlockTickets = (jumperId, body) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)

	return api.post('jumpers/local/upgradeBlockTickets/'+ jumperId, body);

}

func.ReturnBlockTickets = (jumperId, body) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/returnBlockTickets/'+ jumperId, body);
}

func.RemoveBlockTickets = (jumperId, body) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/removeBlockTickets/'+jumperId, body);
}

func.TransferBlockTickets = (creditBody) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/transferBlockTickets', creditBody);
}



func.TransferCreditToTandem = (creditBody) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/transferCreditToTandem', creditBody);
}

func.TransferInternalTandem = (creditBody) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/transferInternalTandem', creditBody);
}


func.setAffPriceType = (id, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	//console.log(token);
	console.log(body);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/setAffPriceType/' + id, body);
}

func.setTandemStudentPackage = (id, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/setTandemStudentPackage/' + id, body);
}

func.setPackagePrice = (id, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/setPackagePrice/' + id, body);
}

func.addPayBeforeItem = (id, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/addPayBeforeItem/' + id, body);
}

func.applyGiftCode = (id, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/applyGiftCode/' + id, body);
}

func.addTip = (creditBody) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('jumpers/local/addTip', creditBody);
}

func.addMediaUrl = (id, body) => {
	//alert('in create Jumper api');
	// console.log("In Transfer Credit");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('jumpers/addMediaUrl/' + id, body);
}

func.getWaivers = (jumperId) => {
	console.log('service get waivers');
	return api.get(`/jumpers/local/${jumperId}/getWaivers`)
}

func.getWaiverById = (waiverId) => {
	console.log('service get waivers');
	return api.get(`/jumpers/local/getWaiverById/${waiverId}`)
}

func.getTodayWaivers = () => {
	console.log('get today waivers');
	return api.get('/jumpers/local/getTodayWaivers')
}

func.downloadWaivers = () => {
	console.log('get today waivers');
	return api.get('/jumpers/local/downloadCleverWaivers')
}

func.printWaiverOneSheet = (waiverId) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/local/printWaiverOneSheet/' + waiverId, {}, {
		responseType: 'blob', headers: {
			'Accept': 'application/pdf'
		}
	});
}

func.sendRenewWaiver = (waiverId) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/jumpers/local/sendRenewWaiver/' + waiverId);
}

func.toggleWaiverReviewStatus = (siteWaiverId) => {
	return api.get(`/jumpers/local/toggleWaiverReviewStatus/${siteWaiverId}`);
}

func.sendReviewSms = (body) => {
	console.log('get today waivers');

	return api.post('/jumpers/local/sendReviewSms', body)
}

func.createAuthorizeCaptureBody = (tokenBody) => {
	var amount = "1.00"
	var expiry = tokenBody.Month + tokenBody.year;
	return {
		"$type": "AuthorizeAndCaptureTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
		SessionToken: reservationStore.getTokENV?.l(),
		ApplicationProfileId: reservationStore.getApplicationProfileId(),
		MerchantProfileId: reservationStore.getMerchantProfileId(),
		Transaction: {
			"$type": "BankcardTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
			TenderData: {
				PaymentAccountDataToken: null,
				SecurePaymentAccountData: null,
				CardData: {
					CardType: tokenBody.cardType,//this.state.cardType.toString(), //visa
					CardholderName: tokenBody.fullName,
					PAN: tokenBody.cardNumber,//this.state.cardNumber.toString(), // 4000000000000002
					Expire: expiry,  // 1220
				},
				CardSecurityData: {
					AVSData: {
						CardholderName: tokenBody.fullName,
					},
					CVDataProvided: "Provided",
					CVData: tokenBody.Cvv,
				}
			},
			TransactionData: {
				Amount: amount,
				CurrencyCode: "USD",
				TransactionDateTime: new Date().toISOString(),
				CustomerPresent: "Ecommerce",
				EmployeeId: "12345",
				EntryMode: "Keyed",
				OrderNumber: "12345",
				SignatureCaptured: false,
			}
		}
	}
}

func.cancelAuthorizeCaptureBody = (TransactionId) => {
	return {
		"$type": "Undo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
		"ApplicationProfileId": reservationStore.getApplicationProfileId(),
		"DifferenceData": {
			"$type":
				"BankcardUndo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
			"TransactionId": TransactionId,
			"Addendum": null
		}
	}
}


export default func;
