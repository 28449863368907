import React, { useEffect, useState } from 'react'
import jumpersStore from '../../../stores/jumpers-store'
import RatingModal from '../../admin/modals/ratingModal'

const JumperProfile = (props) => {

    const [jumper, setJumper] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        console.log('in use effect jumper Profile');
        //let data = jumpersStore.getJumperById(props.jumperId)
        let data = props.currentJumper
        setJumper(data)
    }, [props.currentJumper])

    const toggleModal = () => {
        console.log('in use effect jumper Profile');
        //let data = jumpersStore.getJumperById(props.jumperId);
        let data = props.currentJumper
        setJumper(data);
        setIsOpen(true)
    }

    return <>
        <button
            className="btn customButton rightButton"
            style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764', width: 230, margin: 'inherit' }}
            onClick={() => toggleModal()}
        >
            View Profile
        </button>
        {isOpen && <RatingModal jumperValue={jumper} closeModal={setIsOpen} />}
    </>
}

export default JumperProfile;
