/**
 * http://usejsdoc.org/
 */

/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';


import {
  Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, } from 'react-redux-form';
import ItemModal from './customModal';
import { ClipLoader } from 'react-spinners';
import loadsStore from '../../../stores/loads-store';

class MultiPlanes extends Component {

  constructor(props) {

    super(props);
    this.PayLoadId = '';
    this.PayLoadAmount = '';
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleError = this.toggleError.bind(this);
    this.handlePlaneUpdate = this.handlePlaneUpdate.bind(this);

    this.state = {
      isModalOpen: false,
      isSelected: false,
      errors: {},
      planes: '',
      isloading: false,
      errorOpen: false
    }
    this.loadEvents = [
      { name: loadsStore.getEventPrefix('planeActivity'), func: this.handlePlaneUpdate },
    ];
  }

  toggleModal(e) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  handlePlaneUpdate(data) {
    if (data === true) {
      this.setState({
        isloading: false,
        isModalOpen: false
      })
    }
    else {
      this.setState({
        isloading: false,
        isModalOpen: false
      })
    }
  }

  componentWillUnmount() {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));

  }

  componentDidMount() {
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func));
  }

  handleSubmit(values, loadNum, jumpId, jumpType) {

    this.setState({ isloading: true });

    var payload = {};

    console.log(jumpId);

    payload.numPlanes = Number(values.planes);


    loadsStore.UpdateLoad(loadNum, payload)
      .then(result => {
        this.setState({ isloading: false });
      })
      .then(result => {
        // console.log('Capacity Updated');
        this.setState({ isloading: false });
      })
  }


  handleValidation(name) {
    let fields = this.state;
    let errors = fields.errors;
    if (fields[name] === '') errors[name] = "This field is required";
    else errors[name] = "";
    this.setState({ errors: errors });
  }

  handleInput = (event, key) => {
    var cache = { ...this.state };
    cache[key] = event.target.value;
    this.setState({ ...cache }, () => {
      this.handleValidation(key);
    });
  };

  toggleError() {
    this.setState({
      errorOpen: !this.state.errorOpen
    });
  }

  render() {
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

    return (
      <React.Fragment>
        <ItemModal
          isModalOpen={this.state.isModalOpen}
          title={`Update planes`}
        >

          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>

          <LocalForm onSubmit={(values) => this.handleSubmit(values, this.props.loadNum, this.props.jumpId, this.props.jumpType)}>

            <Row className="form-group">
              <Label htmlFor="pilot" md={4}>Planes :</Label>
              <Col md={8}>
                <Control.input name="planes" model=".planes" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.value}
                  onChange={(e) => this.handleInput(e, 'planes')} autoComplete="off" />
                <span className="text-danger error-validation">{this.state.errors["planes"]}</span>
              </Col>
            </Row>

            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 9 }}>
                {
                  this.state.errors["planes"] == '' ?
                    <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                    :
                    <button className="btn btn-bg lightColor createButton" type="submit" disabled>SUBMIT</button>
                }
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>
          </LocalForm>
        </ItemModal>
        <Modal isOpen={this.state.errorOpen} toggle={this.toggleError} className={this.props.className}>
          <ModalHeader toggle={this.toggleError} className="error-jumperHeader">
            Warning
          </ModalHeader>
          <ModalBody>
            <p style={{ color: 'red', fontSize: '20px', marginLeft: '1%' }}>Something Wrong happened while charging Jumper !</p>
          </ModalBody>
        </Modal>

        {/* <span onClick={this.toggleModal} style={{ cursor: 'pointer', marginLeft: '2px', position: 'absolute', top: '3px' }}>
          <i className="fa fa-edit"></i>
        </span> */}
        <span onClick={this.toggleModal} id={"load-multi-plane" + this.props.loadNum}></span>
      </React.Fragment>
    );
  }
}

export default MultiPlanes
