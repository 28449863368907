import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import loadsStore from '../../../stores/loads-store';
import JmpModal from '../../loadManager/modals/customModal';
// import SweetAlert from 'sweetalert2-react';
import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';

const SweetAlert = withSwalInstance(swal);

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: this.props.show,
    }
  }



  render() {
    return (
      <div style={{ color: 'red' }}>
        <SweetAlert
          show={this.state.isModalOpen}
          title="Server Error"
          //text="Internal Server Error"
          type="error"
          icon="warning"
          dangerMode={true}
          confirmButtonColor="#3085d6"
          onConfirm={() => this.setState({ isModalOpen: false })}
        />
      </div>
    );
  }
}

export default ErrorModal