import React, { Component } from 'react';



import ReactDOM from 'react-dom';
// import JmpModal from './modals/customModal';
import {
  Card, CardBody, Row, Col, Badge, Modal, ModalHeader, ModalBody, CardFooter
} from 'reactstrap';

import { DragSource } from 'react-dnd'
import CancelJump from './modals/cancelJump'
import EditableJumper from './modals/editableJumper'
import CreatableJumper from './modals/creatableJumper'
import UpdateAmount from './modals/updateAmount';
import EditablePrice from './modals/editablePrice';
import UpdateNote from './modals/updateNote';

import EditableTandemPackage from './modals/editableAffJump'
// import GooglePicker from 'react-google-picker';
import Axios from 'axios';
import MyDropzone from './upload'
import loadsStore from '../../stores/loads-store'
import jumpersStore from '../../stores/jumpers-store'
import itemStore from '../../stores/item-store'
import { connect } from 'react-redux'
import { returnClasses } from './helper/schoolwaitlist';
import TandemNestedMenu from './subComponents/NestedMenus'
import returnLoadSettings from './helper/returnLoadSettings'
import multiselect from './helper/multiselectJumps'
import multiCopyPasteClick from './helper/multiCopyPaste'
// import loadsStore from '../../stores/loads-store'


const Types = {
  ITEM: 'JUMP'
};
const itemSource = {
  beginDrag(props) {
    const dragabbleItems = loadsStore.getDraggable();
    console.log(dragabbleItems, "dragabbleItems")
    if (dragabbleItems?.length > 0) {
      return { items: dragabbleItems }
    } else {
      return props;
    }
  },
  endDrag(props, monitor, component) {
    // if (!monitor.didDrop()) {
    //   props.clearItemSelection()
    //   return;
    // }
    // return props;
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      // props.clearItemSelection();
      loadsStore.clearDraggable()
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}


class RenderTandemJump extends Component {

  constructor() {
    super();
    this.state = {
      optionMenu: false,
      showUpdate: false,
      uploadVideo: false,
      oToken: "",
      albumId: "",
      media: [],
      videosUploaded: false,
      copyOptionSub: false,
      cutOptionSub: false
    }
    // this.handleCopyCutClick = this.handleCopyCutClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    // document.addEventListener('mouseleave', this.handleClickOutside, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    // document.removeEventListener('mouseleave', this.handleClickOutside, true);
  }

  setUploadVideo = (d) => {
    this.setState({
      uploadVideo: d
    })
  }

  addOverweight = (loadNum, jumpId, val) => {
    var jumpPostBody = { isOverweight: true };
    loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
      .then(ret => {
        this.setState({
          optionMenu: false
        });
      })
  }

  updateCallRequest = (loadNum, jumpId, val) => {
    var jumpPostBody = { callRequest: val };

    let data = loadsStore.getDraggable()
    let index = data.findIndex(i => i.jumpId === jumpId)

    if (index === -1) {
      loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
        .then(ret => {
          this.setState({
            optionMenu: false
          });
        })
    } else {
      data.map((i, ind) => {
        let type = ''
        if (i.jumpType === 'coachJump') {
          type = 'coachJumps'
        } else if (i.jumpType === 'tandemJump') {
          type = 'tandemJumps'
        } else if (i.jumpType === 'affJump') {
          type = 'affJumps'
        }
        loadsStore.updateJumps(loadNum, type, i.jumpId, jumpPostBody)
          .then(ret => {
            this.setState({
              optionMenu: false
            });
            if (ind === data.length - 1) {
              // this.props.clearItemSelection()
              loadsStore.clearDraggable()
            }
          })
      })
    }
  }

  removeVideoPackage = (isToday, isCompleted, jump, loadNum, loadId, jumpId) => {
    if (isCompleted) {
      var today = new Date();
      var isWeekend = today.getDay() % 6 === 0;
      var jumpPostBody = {
        instructorId: jump.instructor._id,
        studentId: jump.student._id,
        videographerId: jump.videographer._id,
        price: jump.price,
        videoPrice: jump.videoPrice,
        isWeekend: isWeekend,
        isUltra: jump.isUltra,
        isToday: isToday,
        isPriceUpdate: true,
        instructorPay: jump.instructorPay,
        videoPay: jump.videoPay,
        wristcamPay: jump.wristcamPay,
        overweightPay: jump.overweightPay,
        overweightPrice: jump.overweightPrice,
        videoType: jump.videoType,
        isOverweight: jump.isOverweight,
        tandemPackageId: jump.tandemPackageId
      }
      this.props.updateCompleteTandemJump(loadId, jumpId, jumpPostBody)
        .then(ret => {
          //alert(ret);
          this.setState({
            optionMenu: false
          });
        })
    }
    else {
      var jumpPostBody = { removeVideoFromJump: true };
      loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
        .then(ret => {
          this.setState({
            optionMenu: false
          });
        })
    }

  }

  addVideoPackage = (isToday, isCompleted, jump, loadNum, loadId, jumpId, videoPackageId) => {
    if (isCompleted) {
      var today = new Date();
      var isWeekend = today.getDay() % 6 === 0;
      var jumpPostBody = {
        instructorId: jump.instructor._id,
        studentId: jump.student._id,
        videographerId: jump.videographer._id,
        price: jump.price,
        videoPrice: jump.videoPrice,
        isWeekend: isWeekend,
        isUltra: jump.isUltra,
        isToday: isToday,
        isPriceUpdate: true,
        instructorPay: jump.instructorPay,
        videoPay: jump.videoPay,
        wristcamPay: jump.wristcamPay,
        overweightPay: jump.overweightPay,
        overweightPrice: jump.overweightPrice,
        videoType: jump.videoType,
        isOverweight: jump.isOverweight,
        videoPackageId: videoPackageId,
        tandemPackageId: jump.tandemPackageId,
        editPrice: true
      }
      this.props.updateCompleteTandemJump(loadId, jumpId, jumpPostBody)
        .then(ret => {
          //alert(ret);
          this.setState({
            optionMenu: false
          });
        })
    }
    else {
      var jumpPostBody = { videoPackageId: videoPackageId };
      loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
        .then(ret => {
          this.setState({
            optionMenu: false
          });
        })
    }

  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    let id;
    if (document.querySelector('#copy-tandem-menu')) {
      id = document.querySelector('#copy-tandem-menu')
    } else {
      id = document.querySelector('#move-tandem-menu')
    }
    if (!domNode || !domNode.contains(event.target)) {
      if (this.state.optionMenu) {
        if (!id) {
          this.setState({
            optionMenu: false,
            optionSub: false
          });
        }
      }
    }
  }

  // handleEventClick = () => {
  //   if (this.state.optionMenu) {
  //     this.setState({
  //       optionMenu: false
  //     })
  //   }
  // }

  checkForNegatives = (price, videoPrice) => {
    //console.log('check for negatives: ' + price);
    if (price != undefined) {

      if (videoPrice !== undefined) {
        price += videoPrice;
      }

      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        }
        :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <Badge style={{ color: 'red' }}> {priceCell.value}</Badge>;
      }

      return <Badge>{priceCell.value}</Badge>;
    }
    else {
      return '';
    }
  }

  toggleOptions = () => {
    this.setState({
      optionMenu: !this.state.optionMenu
    })
  }

  renderTandemLabel = (tandemJump) => {
    if (tandemJump.isCombo) {
      return tandemJump.tandemPackageName;
    }
    else if (tandemJump.videoType !== 'none') {
      return tandemJump.tandemPackageName + " w/ " + tandemJump.videoPackageName;
    }
    else {
      return tandemJump.tandemPackageName;
    }
  }

  // handleBlur = () => {
  //   if(this.state.optionMenu)
  //   {
  //     this.setState({
  //       optionMenu:false
  //     })
  //   }
  // }

  handleUpdate = (jumpid) => {
    // this.setState({
    //   showUpdate:true
    // },()=>{
    //   alert(this.state.showUpdate);
    // })
    document.getElementById(jumpid).click();
  }

  handleDelete = (jumpId) => {
    document.getElementById("delete-jump" + jumpId).click()
  }

  handleCopyClick = (tandem = this.props.tandemJump, loadNum = this.props.loadNum, uid = -1000) => {
    this.setState({
      copyOptionSub: true
    })
    const dragItem = loadsStore.getDraggable()
    if (dragItem.length > 0) {
      multiselect(dragItem)
    } else {
      var payload = {};
      payload = tandem
      var today = new Date();
      var isWeekend = today.getDay() % 6 === 0;
      payload.isWeekend = isWeekend
      payload.instructorId = tandem.instructor['_id']
      payload.studentId = tandem.student['_id'];
      if (tandem.videographer && tandem.videographer['_id']) {
        payload.videographerId = tandem.videographer['_id']
      }
      payload.note = tandem.note;

      loadsStore.copied = true;
      loadsStore.copyValue = payload;
      loadsStore.cut = false;
    }

    if (uid !== -1000) {
      var element = document.getElementById(uid);
      element.style.display = "none";
    }
  }

  handleCutClick = (tandem = this.props.tandemJump, loadNum = this.props.loadNum, uid, jumpId = this.props.jumpId) => {
    this.setState({
      cutOptionSub: true
    })
    const dragItem = loadsStore.getDraggable()

    if (dragItem.length > 0) {
      multiselect(dragItem, loadNum)
    } else {
      let payload = {};
      payload = tandem
      let today = new Date();
      let isWeekend = today.getDay() % 6 === 0;
      payload.isWeekend = isWeekend
      payload.instructorId = tandem.instructor['_id']
      payload.studentId = tandem.student['_id'];
      if (tandem.videographer && tandem.videographer['_id']) {
        payload.videographerId = tandem.videographer['_id'];
      }
      payload.note = tandem.note;
      loadsStore.cut = true;
      loadsStore.cutValue = { loadNum, jumpId }
      loadsStore.copyValue = payload;
    }
  }

  handleCopyPasteClick = (loadNum) => {
    this.setState({
      optionMenu: !this.state.optionMenu,
      copyOptionSub: false,
      cutOptionSub: false
    })
    var Value = loadsStore.copyValue;
    if (Value?.length && Value.length > 0) {
      multiCopyPasteClick(Value, loadNum)
    } else {
      if (Value.tandemPackageName) {
        loadsStore.createTandemJump(loadNum, Value).then(d => {
          console.log("TandemJump in pasted", d);
          if (d) {
            if (loadsStore.cut) {
              loadsStore.cut = false;
              const { loadNum, jumpId } = loadsStore.cutValue;
              loadsStore.cancelTandemJump(loadNum, jumpId);
            }
          }
        })
      }
    }
  };

  sendReviewSms = (studentId, reviewType) => {
    var body = {
      studentId: studentId,
      reviewType: reviewType
    }
    jumpersStore.sendReviewSms(body)
      .then(ret => {
        this.setState({
          optionMenu: false
        });
      })
  }

  handleItem = (e, jump) => {
    // this.props.setDragabble(jump)
    loadsStore.setDragabble(jump)
  }

  isChecked = (item) => {
    let index = this.props?.dragabbleItems?.findIndex(i => i.jumpId === item.jumpId)
    // let index = loadsStore.getDraggable().findIndex(i => i.jumpId === item.jumpId)
    if (index === -1) {
      return false
    } else {
      return true
    }
  }

  render() {
    console.log('in render tandem jump: load ' + this.props.loadNum);
    const { isToday, isCompleted, tandemJump, loadNum, loadId, jumpId, tandemInstructorOptions, videographerOptions, isDragging, connectDragSource, tandemStudentOptions, price, discountValue, isRightMenu, isCenter, loadsToShow, columnsPerGroup, fontSize, isToShow, isInline } = this.props;

    if (isCompleted) {
      console.log('rendering completed tandem');
    }
    else {
      console.log('rendering tandem');
    }


    var videoPrice;
    const self = this
    var uid = "tandem" + loadNum + "_" + jumpId;
    if (tandemJump.videoPrice !== undefined) {
      videoPrice = tandemJump.videoPrice
    }
    var checkedInOptions = jumpersStore.getTandemInstructorCheckedInOptions();
    var IsEventEnable = this.props.isCompleted ? 'none' : '';
    // console.log("tandemJump");
    // console.log(tandemJump);

    var tandemVideoMenu = [];

    var videoPackages = itemStore.getVideoPackages();

    if (tandemJump.videoPackageName !== undefined && !isCompleted) {

      tandemVideoMenu.push(<li onClick={() => this.removeVideoPackage(isToday, isCompleted, tandemJump, loadNum, loadId, jumpId)}>
        <span>remove video</span>
      </li>)

    }

    tandemVideoMenu = [...tandemVideoMenu, ...videoPackages.map(pkg => {
      return (
        <li onClick={() => this.addVideoPackage(isToday, isCompleted, tandemJump, loadNum, loadId, jumpId, pkg._id)}>
          <span>add {pkg.item}</span>
        </li>
      );

    })];

    var callRequestMenu = null;
    if (this.props.schoolWaitlist) {
      var activeLoads = loadsStore.getActiveLoadsForDisplay();
      console.log(activeLoads);
      var callRequestMenuString = ['hold', 'any 20', 'any 30', 'any 40'];
      activeLoads.forEach(ld => {
        callRequestMenuString.push('next available after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('20 after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('30 after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('40 after ' + ld.plane + "-" + ld.planeLoadNum);
      })
      var callRequestMenu = callRequestMenuString.map(str => {
        return (
          <li onClick={() => this.updateCallRequest(loadNum, jumpId, str)}>
            <span>{str}</span>
          </li>
        )
      })
    }
    //console.log(tandemJump);

    var backgroundBaseColor = '17A589';

    var backgroundInt = parseInt(backgroundBaseColor, 16);

    var altInt = parseInt(tandemJump.altitude, 16);
    var backgroundColor;
    if (altInt < 18) {
      backgroundColor = '#13B239';
    }
    else if (altInt >= 18 && altInt <= 23) {
      backgroundColor = '#FF6347';
    }
    else {
      backgroundColor = '#1E90FF';
    }

    var reviewSmsSent = jumpersStore.getReviewSmsSent(tandemJump.student._id);

    const { settingsMoreThan4, settingsFunjumpClass, settingsShowDotMenu, settingsSetPos, settingsStyledObject } = returnLoadSettings(loadsToShow, columnsPerGroup, isInline, fontSize)

    return connectDragSource(
      <div className={returnClasses(isRightMenu, isCenter)}>
        {/* {
          this.state.optionSub &&
          <ul className="menu-option" style={{ zIndex: '100', position: 'absolute', top: '111px', left: '111px' }} onMouseLeave={()=>{this.setState({optionSub:!this.state.optionSub})}}>
            {
              this.props.loads.map((load, i) => {
                if (load.loadNum != loadNum) {
                  return (<li>
                    <span onClick={() => this.handleCopyPasteClick(load.loadNum)} >{"Load " + load.loadNum}</span>
                  </li>)
                }
              })
            }
          </ul>
        } */}
        {
          this.state.optionMenu &&
          <ul className={loadsToShow > 3 ? "menu-option-moreloads" : "menu-option"}>

            <li>
              <span style={{ color: 'red' }} onClick={() => this.handleDelete(jumpId)}>Delete</span>
            </li>
            {!this.props.isCompleted && <li>
              <span onClick={() => this.handleUpdate("update-note" + jumpId)}>add note</span>
            </li>}
            <TandemNestedMenu
              txt={"Copy"}
              loadNum={this.props.loadNum}
              loads={loadsStore.getActiveLoadsForDisplay()}
              handleCopyClick={this.handleCopyClick}
              handleCopyPasteClick={this.handleCopyPasteClick}
              id={"copy-tandem-menu"}
            />
            <TandemNestedMenu
              txt={"Move"}
              loadNum={this.props.loadNum}
              loads={loadsStore.getActiveLoadsForDisplay()}
              handleCopyClick={this.handleCutClick}
              handleCopyPasteClick={this.handleCopyPasteClick}
              id={"move-tandem-menu"}
            />
            {/* <li style={{ position: "relative" }} onMouseLeave={() => { setTimeout(() => { this.setState({ copyOptionSub: false }) }, 500) }}>
              <span onClick={() => this.handleCopyClick(tandemJump, loadNum, -1000)} onMouseOver={() => { setTimeout(() => { this.handleCopyClick(tandemJump, loadNum, -1000) }, 500) }} >Copy</span>
              {
                this.state.copyOptionSub &&
                <ul className="menu-option" style={{ zIndex: '100', position: 'absolute', top: '0px', right: '211px' }} onMouseLeave={() => { setTimeout(() => { this.setState({ copyOptionSub: false }) }, 500) }}>
                  {
                    this.props.loads && this.props.loads.map((load, i) => {
                      if (load.loadNum != loadNum) {
                        return (<li>
                          <span onClick={() => this.handleCopyPasteClick(load.loadNum)} >{"Load " + load.loadNum + " " + load.plane + " " + load.planeLoadNum}</span>
                        </li>)
                      }
                    })
                  }
                </ul>
              }
            </li> */}

            {/* {!this.props.isCompleted && <li style={{ position: "relative" }} onMouseLeave={() => { setTimeout(() => { this.setState({ cutOptionSub: false }) }, 500) }}>
              <span style={{ color: 'red' }} onClick={() => this.handleCutClick(tandemJump, loadNum, uid, jumpId)} onMouseOver={() => { setTimeout(() => { this.handleCutClick(tandemJump, loadNum, uid, jumpId) }, 500) }}>Move</span>
              {
                this.state.cutOptionSub &&
                <ul className="menu-option" style={{ zIndex: '100', position: 'absolute', top: '0px', right: '211px' }} onMouseLeave={() => { setTimeout(() => { this.setState({ cutOptionSub: false }) }, 500) }}>
                  {
                    this.props.loads && this.props.loads.map((load, i) => {
                      if (load.loadNum != loadNum) {
                        return (<li>
                          <span onClick={() => this.handleCopyPasteClick(load.loadNum)} >{"Load " + load.loadNum + " " + load.plane + " " + load.planeLoadNum}</span>
                        </li>)
                      }
                    })
                  }
                </ul>
              }
            </li>} */}

            <li onClick={() => this.addOverweight(loadNum, jumpId, true)}>
              <span >Add Over weight charges</span>
            </li>

            {
              this.props.isCompleted && !reviewSmsSent &&
              <li onClick={() => this.sendReviewSms(tandemJump.student._id, 'google')}>
                <span >Request google review</span>
              </li>
            }
            {
              this.props.isCompleted && !reviewSmsSent &&
              <li onClick={() => this.sendReviewSms(tandemJump.student._id, 'yelp')}>
                <span >Request yelp review</span>
              </li>
            }
            {
              this.props.isCompleted && this.props.tandemJump.albumUrl &&
              <li onClick={() => {
                var win = window.open(this.props.tandemJump.albumUrl, '_blank');
                win.focus();
              }}>
                <span >View Media</span>
              </li>
            }
            {tandemVideoMenu}
            {callRequestMenu}

          </ul>
        }
        <Card className="jumpcard" onClick={this.handleBlur}>
          <CardBody style={{ padding: '0px !important' }}>
            <div className={settingsMoreThan4 ? "" : "row"} style={(this.props.load.isWaitlist && this.props.isCenter) ? { fontSize: 16 } : {}}>


              <CancelJump
                isToday={this.props.isToday}
                isCompleted={this.props.isCompleted}
                loadId={this.props.loadId}
                loadNum={loadNum}
                jumpId={jumpId}
                // clearItemSelection={this.props.clearItemSelection}
                jumpType={"tandemJump"}
              // selectedItems={this.props.dragabbleItems}
              />
              <UpdateNote showUpdate={this.state.showUpdate} isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"tandemJump"}
                isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={tandemJump} />
              {/* <Col xs="3" md="12" lg="3" className="jumptype" style={{padding: '0 0 0 9px' }}>
                {this.renderTandemLabel(tandemJump)}
                {price !== undefined ? this.checkForNegatives(price, videoPrice) : ''}
              </Col> */}
              <div className={settingsMoreThan4 ? "funjumptypemoreload" : `${settingsFunjumpClass} col`} style={{ backgroundColor: backgroundColor }}>

                <EditableTandemPackage
                  type={"tandem"}
                  label={"I1"}
                  isToday={this.props.isToday}
                  loadNum={loadNum}
                  jumpId={jumpId}
                  isCompleted={this.props.isCompleted}
                  loadId={this.props.loadId}
                  jump={tandemJump}
                  packageName={tandemJump.tandemPackageName}
                />

                {tandemJump.videoPackageName}

              </div>
              <div className={settingsMoreThan4 ? "" : "cardmiddle col"} style={{ margin: 'auto' }}>
                <ul className={`tandem-load ${settingsShowDotMenu}`}>
                  {columnsPerGroup === 1 ?
                    <li style={{ settingsStyledObject, display: "flex", justifyContent: "space-between" }} >
                      <div style={{ display: "flex" }}>
                        <EditableJumper
                          type={"tandem"}
                          label={"I"}
                          isToday={this.props.isToday}
                          loadNum={loadNum}
                          jumpId={jumpId}
                          activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                          tandemJump={tandemJump}
                          schoolWaitlist={this.props.schoolWaitlist}
                          jumper={tandemJump.instructor}
                          isCompleted={this.props.isCompleted}
                          isUltra={tandemJump.isUltra}
                          loadId={this.props.loadId}
                          defaultOptions={checkedInOptions}
                          price={tandemJump.instructorPay}
                          dontShowId={true}
                        />
                        {!this.props.schoolWaitlist ? <div>{tandemJump.instructor.memberId}</div> : null}
                      </div>

                      <div className={'justify-middle'} >

                        <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.instructorPay} type="tandem" label="I" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />

                        {
                          tandemJump.videoType === "wristcam" || tandemJump.videoType === "both" ?
                            <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.wristcamPay} type="tandem" label="WC" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                            :
                            null
                        }
                        {
                          tandemJump.isOverweight ?
                            <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.overweightPay} type="tandem" label="OI" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                            :
                            null
                        }
                        {
                          tandemJump.tipPay ?
                            <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.tipPay} type="tandem" label="TP" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                            :
                            null
                        }</div>
                    </li> : <li>
                      <EditableJumper
                        type={"tandem"}
                        label={"I"}
                        isToday={this.props.isToday}
                        loadNum={loadNum}
                        jumpId={jumpId}
                        activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                        tandemJump={tandemJump}
                        schoolWaitlist={this.props.schoolWaitlist}
                        jumper={tandemJump.instructor}
                        isCompleted={this.props.isCompleted}
                        isUltra={tandemJump.isUltra}
                        loadId={this.props.loadId}
                        defaultOptions={checkedInOptions}
                        price={tandemJump.instructorPay}
                        dontShowId={true}
                      />

                      <div className={'justify-middle'} >
                        {!this.props.schoolWaitlist ? <div>{tandemJump.instructor.memberId}</div> : null}
                        <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.instructorPay} type="tandem" label="I" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                      </div>
                      {
                        tandemJump.videoType === "wristcam" || tandemJump.videoType === "both" ?
                          <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.wristcamPay} type="tandem" label="WC" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                          :
                          null
                      }
                      {
                        tandemJump.isOverweight ?
                          <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.overweightPay} type="tandem" label="OI" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                          :
                          null
                      }
                      {
                        tandemJump.tipPay ?
                          <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.tipPay} type="tandem" label="TP" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                          :
                          null
                      }
                    </li>}
                  {columnsPerGroup === 1 ? <li style={{ settingsStyledObject, display: "flex", justifyContent: "space-between" }}>
                    <div style={{ pointerEvents: IsEventEnable }}>
                      <CreatableJumper
                        type={"tandem"}
                        loadNum={loadNum}
                        isToday={this.props.isToday}
                        jumpId={jumpId}
                        jumper={tandemJump.student}
                        isCompleted={this.props.isCompleted}
                        isUltra={tandemJump.isUltra}
                        loadId={this.props.loadId}
                        price={tandemJump.price + tandemJump.videoPrice} />
                    </div>
                    <div style={{ display: "flex" }}>
                      <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.price} type="tandem" label="S" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.student._id} />
                      {
                        (tandemJump.videoType === "wristcam" || tandemJump.videoType === "both" || tandemJump.videoType === "outside") && !tandemJump.isCombo ?
                          <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.videoPrice} type="tandem" label="SV" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                          :
                          null
                      }
                      {
                        tandemJump.isOverweight ?
                          <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.overweightPrice} type="tandem" label="OS" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                          :
                          null
                      }
                      {
                        tandemJump.tip ?
                          <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.tip} type="tandem" label="T" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.instructor._id} />
                          :
                          null
                      }
                      {
                        discountValue > 0 && (
                          <span className="editablePrice" style={{ "color": "lightsalmon" }}>D: (${discountValue})</span>
                        )
                      }
                    </div>

                  </li> : <li>
                    <div style={{ pointerEvents: IsEventEnable }}>
                      <CreatableJumper
                        type={"tandem"}
                        loadNum={loadNum}
                        isToday={this.props.isToday}
                        jumpId={jumpId}
                        jumper={tandemJump.student}
                        isCompleted={this.props.isCompleted}
                        isUltra={tandemJump.isUltra}
                        loadId={this.props.loadId}
                        price={tandemJump.price + tandemJump.videoPrice} />
                    </div>
                    <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.price} type="tandem" label="S" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.student._id} />
                    {
                      (tandemJump.videoType === "wristcam" || tandemJump.videoType === "both" || tandemJump.videoType === "outside") && !tandemJump.isCombo ?
                        <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.videoPrice} type="tandem" label="SV" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.student._id} />
                        :
                        null
                    }
                    {
                      tandemJump.isOverweight ?
                        <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.overweightPrice} type="tandem" label="OS" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.student._id} />
                        :
                        null
                    }
                    {
                      tandemJump.tip ?
                        <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.tip} type="tandem" label="T" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.student._id} />
                        :
                        null
                    }
                    {
                      discountValue > 0 && (
                        <span className="editablePrice" style={{ "color": "lightsalmon" }}>D: (${discountValue})</span>
                      )
                    }


                  </li>}
                  {
                    (tandemJump.videoType === "outside" || tandemJump.videoType === "both") && tandemJump.videographer !== undefined ?
                      <li style={columnsPerGroup === 1 ? { settingsStyledObject, display: "flex", justifyContent: "space-between" } : {}}>
                        <div style={columnsPerGroup === 1 ? { display: "flex" } : {}}>
                          <EditableJumper schoolWaitlist={this.props.schoolWaitlist} isToday={this.props.isToday} type={"tandem"} label={"V"} loadNum={loadNum} jumpId={jumpId} tandemJump={tandemJump} jumper={tandemJump.videographer}
                            isCompleted={this.props.isCompleted} loadId={this.props.loadId} isUltra={tandemJump.isUltra} price={tandemJump.videoPay}
                            activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                            dontShowId={true}
                          />
                          {!this.props.schoolWaitlist && columnsPerGroup === 1 ? <div>{tandemJump.videographer.memberId}</div> : null}
                        </div>


                        <div className={'justify-middle'} >
                          {!this.props.schoolWaitlist && columnsPerGroup !== 1 ? <div>{tandemJump.videographer.memberId}</div> : null}
                          <EditablePrice isToday={this.props.isToday} isUltra={tandemJump.isUltra} jump={tandemJump} isCompleted={this.props.isCompleted} price={tandemJump.videoPay} type="tandem" label="V" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={tandemJump.videographer._id} />
                        </div>
                      </li> : null
                  }

                  {/* {tandemJump.videoPackageName ? <i className="fa fa-3x fa-video-camera"> </i> : null} */}
                  {tandemJump.studentWeight && tandemJump.studentWeight !== "true" ? <React.Fragment><span>Weight: {tandemJump.studentWeight}</span></React.Fragment> : null}

                </ul>
                <div className={settingsMoreThan4 ? "right-menu-morethan4" : `right-menu ${settingsSetPos}`} >
                  {!isCompleted && <div style={{ position: 'absolute', right: 0, top: -18 }}>
                    <input className="selectable" type="checkbox" style={{ width: 11, height: 11 }} onChange={(e) => this.handleItem(e, this.props)} />
                  </div>}
                  <span onClick={this.toggleOptions}>
                    <i className="fa fa-ellipsis-v"></i>
                  </span>
                </div>

              </div>
              {/* <Col xs="1" md="2" lg="1" className="jumpClose" style={{ paddingLeft: '4px' }}>
                <UpdateAmount isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"tandemJump"}
                  isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={tandemJump} isUltra= {tandemJump.isUltra} />
                {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} jumpType={"tandemJump"} />}
              </Col> */}
            </div>

            {tandemJump.startedAt ?
              <Row>
                <Col>
                  {window.ENV?.arizona && tandemJump.startedAt ? <Badge style={{ fontSize: '14px' }}>{new Date(tandemJump.startedAt).toLocaleTimeString()}</Badge> : null}
                  {tandemJump.callRequest ? <Badge style={{ fontSize: '14px' }}>{tandemJump.callRequest}</Badge> : null}
                </Col>
              </Row> : null
            }
          </CardBody>
          {tandemJump.note ?
            <CardFooter className="fun-card-footer" >{tandemJump.note}</CardFooter> : null}
        </Card>
        <Modal id="modal-report"
          aria-labelledby="example-custom-modal-styling-title"
          isOpen={this.state.uploadVideo}
          style={{ zIndex: '10503434', right: '28%' }}>
          <ModalHeader>Upload Media</ModalHeader>
          <ModalBody>
            <div style={{ width: "100%" }}>

              <MyDropzone
                loadNum={this.props.loadNum}
                jumpId={this.props.jumpId}
                isCompleted={this.props.isCompleted}
                jumpAlbumId={this.props.tandemJump.albumId}
              />
            </div>
            {/* <div>
              <ShowMedia data={this.state.media} />
            </div> */}
            <Row className="form-group m-t-1-rem">
              <Col md={{ size: 10 }}>
                <button onClick={(e) => { e.preventDefault(); this.setUploadVideo(false); }} className="btn btn-bg lightColor createButton ">CLOSE</button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
const mstp = state => {
  return {
    mediaInfo: state.mediaUpload
  }
}
const mdtp = dispatch => {
  return {
    mediaUpload: (d) => dispatch({ type: "MEDIA_UPLOAD", payload: d }),
    updateCompleteTandemJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody))
  }
}

export default connect(mstp, mdtp)(DragSource(Types.ITEM, itemSource, collect)(RenderTandemJump))
