/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ClipLoader } from 'react-spinners';
import Fuse from "fuse.js";
import {
    Card, CardBody, CardHeader,
    Input, Button, ButtonGroup, Col, Row, Label
} from 'reactstrap';
import { LocalForm, } from 'react-redux-form';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import jumpersStore from '../../../stores/jumpers-store';

import loadsStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';
import '../../css/loadManager.css'
const customStyles = {
    control: (base, state) => ({
        ...base,
        fontSize: "1.2em"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: "0px" 
      }),
    clearIndicator: base => ({
    ...base,
    padding: "0px" 
    }),
}
class CreateTeamJumpCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            jumpType: null,
            affLevel: null,
            load: props.load,
            isloading: false,
            ErrorMessage: '',
            isValid: true,
            jumpType: "Undecided",
            jumpers: [],
            menuPlacement: "bottom",
            teams: [],
            teamsOptions: [],
            selectedTeam: {},
            videoGraphers: [],
            jumpersSelected: [],
        };
        this.inputValue = '';
        this.gearIds = [];
        this.studentgearIds = [];
        this.sportgearIds = [];

        this.jumpersInput = {};
        //this.timerId;
        this.fuseJumpers = null;
        this.fuseTeams = null;
        this.loadEvents = [
            { name: loadsStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
        ];
        this.JumpTypes = [
            "Wingsuit", "Belly", "Freefly", "Tracking", "HopNPop", "HopNPop Student", "Solo Student", "High Pull"
        ];
    }


    componentWillMount = () => {
        this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func));
    }


    componentDidMount = () => {

        if (this.valRef) {
            this.valRef.focus();
        }

        var teamOptions = jumpersStore.getTeamOptions();
        var jumperOptions = jumpersStore.getJumperOptions().filter(opt => opt.value.isTeam !== true);

        if (this.props.activeJumperIdsOnLoad) {
            jumperOptions = jumperOptions.filter(
                option =>
                    this.props.activeJumperIdsOnLoad.findIndex(
                        id => option.value._id === id
                    ) === -1
            );
        }

        console.log(teamOptions);
        this.setState({ jumperOptions: jumperOptions, teamOptions });
        this.setFuseJumpers(jumperOptions);
        this.setFuseTeams(teamOptions);

        // if (ReactDOM.findDOMNode(this.valRef).getBoundingClientRect().y > window.innerHeight / 2) {
        //     this.setState({ menuPlacement: "top" });
        // }
        // else {
        //     this.setState({ menuPlacement: "bottom" });
        // }

    }
    componentWillUnmount = () => {
        this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    }

    cancelOrSubmit = () => {
        if (this.state.jumpersSelected.length > 0) {
            var jumpType, teamType;

            if (this.state.jumpType === "Undecided") jumpType = 'Undecided';
            else jumpType = this.state.jumpType

            let selectedTeam = this.state.selectedTeam
            console.log(selectedTeam, "selectedTeam")


            let jumperIds = [], videographerIds = [];

            this.state.jumpersSelected.map(i => {
                jumperIds.push(i.value._id);
            })

            this.state.videoGraphers.map(i => {
                videographerIds.push(i.value._id)
            })

            // if(Array.isArray(this.state.videoGraphers)){
            //     this.state.videoGraphers.map(i => {
            //       videographerIds.push(i.value._id)
            //     })
            // } else {
            //     videographerIds.push(this.state.videoGraphers.value._id)
            // }

            var jumpPostBody = {
                jumpType: selectedTeam?.value?.jumpType,
                jumperIds: jumperIds,
                videographerIds: videographerIds,
                altitude: selectedTeam?.value?.defaultAltitude ? selectedTeam?.value?.defaultAltitude : itemStore.getDefaultAltitude().name,
                teamName: selectedTeam?.value?.teamname,
                teamId: selectedTeam?.value?._id,
                teamType: selectedTeam.value.teamType
            };

            jumpPostBody.teamType = selectedTeam.value.teamType;

            // let videoGId = this.state.videoGraphers[0].value._id;

            //jumpPostBody.jumperIds.push(videoGId);

            if (selectedTeam.value.jumpType === "HopNPop" || selectedTeam.value.jumpType === 'HopNPop Student') {
                jumpPostBody.altitude = selectedTeam?.value?.defaultAltitude ? selectedTeam?.value?.defaultAltitude : itemStore.getLowAltitude().name;
            }

            jumpPostBody.studentgearRentals = this.studentgearIds.filter(id => jumpPostBody.jumperIds.indexOf(id) >= 0);
            jumpPostBody.sportgearRentals = this.sportgearIds.filter(id => jumpPostBody.jumperIds.indexOf(id) >= 0);

            loadsStore.createFunJump(this.state.load.loadNum, jumpPostBody);
            this.setState({ videoGraphers: [], jumpersSelected: [], selectedTeam: {} })
        }
        else {
            this.setState({
                isloading: false,
                ErrorMessage: 'Invalid Input !',
                isValid: false
            })

            //this.formDispatch(actions.reset('createjump.jumpers'));
        }

        this.cancelCreateJump();
    }
    onRadioBtnClick(rSelected) {
        var jumpTypes = this.JumpTypes;

        this.setState({
            rSelected,
            jumpType: jumpTypes[rSelected - 1]
        });
    }

    setFuseJumpers = (jumperOptions) => {
        var options = {
            shouldSort: true,
            tokenize: false,
            threshold: 0.1,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "label",
                "value.manifestNumber"
            ]
        };

        this.fuseJumpers = new Fuse(jumperOptions, options); // "list" is the item array
    }
    setFuseTeams = (teamsOptions) => {
        var options = {
            shouldSort: true,
            tokenize: false,
            threshold: 0.1,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "label",
            ]
        };

        this.fuseTeams = new Fuse(teamsOptions, options); // "list" is the item array
    }

    handleNewActivity = (activityString) => {
        if (activityString) {
            this.setState({
                isloading: false,
                isModalOpen: false
            })
        }
    }

    updateGearValue = (value) => {
        var id = value.target.name.substr(5);
        var type = value.target.name.substr(0, 5);

        if (type === "gear1") {
            if (value.target.checked) {
                var old = this.gearIds
                this.studentgearIds = [...old, id]
            }
            else {
                this.studentgearIds = this.studentgearIds.filter(gearId => gearId !== id);
            }
        }

        else if (type === "gear2") {
            if (value.target.checked) {
                var old = this.gearIds
                this.sportgearIds = [...old, id]
            }
            else {
                this.sportgearIds = this.sportgearIds.filter(gearId => gearId !== id);
            }
        }

    }

    Option = (props) => {
        var studentgearModel = ".gear1" + props.data.value._id;
        var studentname = "gear1" + props.data.value._id;

        var sportgearModel = ".gear2" + props.data.value._id;
        var sportname = "gear2" + props.data.value._id;

        var isChecked = false;

        this.gearIds.length > 0 && this.gearIds.map(i => {
            if (i === props.data.value._id) {
                isChecked = true
            }
        })

        this.studentgearIds.length > 0 && this.studentgearIds.map(i => {
            if (i === props.data.value._id) {
                isChecked = true
            }
        })

        this.sportgearIds.length > 0 && this.sportgearIds.map(i => {
            if (i === props.data.value._id) {
                isChecked = true
            }
        })
        const { fSize } = JSON.parse(localStorage.getItem('jumperDetails'))
        let fontSize = fSize + 1;
        return (
            <div className="row">
                <div className="col-4" style={{ fontSize: `${fontSize}px` }}>
                    <components.Option {...props} />
                </div>
                <div className="col-3" style={{ paddingTop: '6px', fontSize: `${fontSize}px` }}>
                    <div className="row">
                        student gear <input type="checkbox"
                            style={{ zIndex: 800 }}
                            name={studentname}
                            // checked={isChecked}
                            value={true}
                            onChange={value => { this.updateGearValue(value) }}
                        />
                    </div >
                    <div className="row" style={{ paddingTop: '6px', fontSize: `${fontSize}px` }}>

                        sport gear rental <input type="checkbox"
                            style={{ zIndex: 800 }}
                            name={sportname}
                            // checked={isChecked}
                            value={true}
                            onChange={value => { this.updateGearValue(value) }}
                        />
                    </div>
                </div>

            </div>
        );
    }

    MultiValueLabel = (props) => {
    let { loadsToShow } = this.props;
	let screenWidth = window.innerWidth;
    let title = null,widthClass='',bigScreen=''
    if (screenWidth >= 1440 && this.props.loadsToShow >= 3 ){
        title = props.children
        widthClass = 'widthForMoreThan5'
    }
    else if (screenWidth >= 1440 && this.props.loadsToShow < 3){
        title = null
        widthClass = 'widthForLessThan5'
    }
    if (screenWidth >= 1440 && this.props.loadsToShow >= 6){
        title = props.children
        bigScreen = 'widthForMoreThan7'
    }
    if(screenWidth >= 1440 && this.props.loadsToShow == 7 ){
		title = props.children;
		widthClass = "widthForLessThan1920-7";
	}
        return (
            <div className={`row ${widthClass}`}style={{ margin: '0px'}}>
              <div className={`col-12 ${bigScreen}`} style={{ padding:'0px' }}  title={title}><components.MultiValueLabel {...props} /></div>
                {this.studentgearIds.indexOf(props.data.value._id) > -1 || this.sportgearIds.indexOf(props.data.value._id) > -1 ? <div className="col-12">-G</div> : null}
            </div>
        );
    }
    filterTeams = (inputValue, teamsOptions) => {
        if (inputValue) {
            let filteredOptions = this.fuseTeams.search(inputValue.toLowerCase());
            if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
            else return filteredOptions;
        }
        return teamsOptions
    }
    promiseTeamOptions = (inputValue, teamsOptions) => {
        return new Promise(resolve => {
            resolve(this.filterTeams(this.inputValue, teamsOptions));
        });
    }
    filterJumpers = (inputValue, jumperOptions) => {
        if (inputValue) {
            var filteredOptions = this.fuseJumpers.search(inputValue.toLowerCase());
            if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
            else return filteredOptions;
        }
        return jumperOptions;
    };

    promiseOptions = (inputValue, jumperOptions) => {
        return new Promise(resolve => {
            resolve(this.filterJumpers(this.inputValue, jumperOptions));
        });
    }

    handleInputChange = (newValue) => {
        this.inputValue = newValue;
        // if (this.valRef) {
        //     this.valRef.focus();
        // }
    };

    handleChange = (values, action) => {
        // this.valRef.focus();
        if (action === 'jumpers') {
            this.setState({ jumpersSelected: values })
        }
        else {
            this.setState({ videoGraphers: values })
        }
    }
    cancelCreateJump = () => {
        loadsStore.cancelCreateJump({ loadNum: this.props.load.loadNum, type: "teamjump" });
        this.setState({ videoGraphers: [], jumpersSelected: [], selectedTeam: {} })
    }

    updateJumpType = (value) => {
        this.setState({ jumpType: value.target.value });
    }

    // attach = (node) => {
    //     node.focus();
    // }
    // focusInput = (component) => {
    //     if (component)
    //         component.focus();
    // }
    handleTeamChange = (values) => {
        //alert('team change');
        let { jumperOptions } = this.state;

        var teams = jumpersStore.getTeams();
        let selectedTeam = teams.filter(ele => ele._id === values.value._id);

        // let videoGraphers = jumperOptions.filter(ele => ele.value._id === selectedTeam[0].videoGrapher)
        let filteredObj = { videoGraphers: [], jumpers: [] };

        // let videoGraphers = this.state.jumperOptions.find(i => i.value._id === selectedTeam[0].videographerInfo.jumperId)
        // videoGraphers && filteredObj.videoGraphers.push(videoGraphers)

        selectedTeam[0].teamMembersInfo?.map(i => {
            let Jumpers = jumperOptions.find(item => item.value._id === i.jumperId)
            if (!i.isVideographer) {
                Jumpers && filteredObj.jumpers.push(Jumpers);
            } else {
                Jumpers && filteredObj.videoGraphers.push(Jumpers);
            }
        })

        // this.state.jumperOptions?.forEach(ele => {
        //     if (ele.value._id === selectedTeam[0].videographerInfo._id) {
        //         filteredObj.videoGraphers.push(ele);
        //     }
        //     debugger
        //     selectedTeam[0].teamMembersInfo?.map(i => {
        //         let index = selectedTeam[0].teamMembersInfo.indexOf(ele.value._id);
        //         if (index !== -1) {
        //             filteredObj.jumpers.push(ele);
        //         }
        //     })
        //     // let index = selectedTeam[0].teamMembersInfo.indexOf(ele.value._id);
        //     // if (index !== -1) {
        //     //     filteredObj.jumpers.push(ele);
        //     // }
        // })
        this.setState({
            selectedTeam: values,videoGraphers: filteredObj.videoGraphers,jumpersSelected: filteredObj.jumpers,
        });
    }

    render() {
        const override = `
            display: block;
            margin: 0 auto;
            border-color: red;
            `;
        let { videoGraphers, jumpersSelected, selectedTeam, jumperOptions, teamOptions } = this.state;

        const moreThan3 = this.props.loadsToShow > 3 ? true : false
        const customClass = moreThan3 ? 'outer' : 'inner'
        const { fontSize } = JSON.parse(localStorage.getItem('jumperDetails'))
        return (
            <React.Fragment>
                <Card className={`funjumpcard ${customClass} isJumpOpen isTeamJumpOpen-${this.props.loadNum}`}
                    style={this.props.loadsToShow > 3 ? { position: 'absolute', width: '100%', zIndex: 99, top: '15%', right: 0 } : {}}
                >
                    <CardHeader className="jumpcardheader align-items-center row">
                        <div className="col-12">
                            Team Jump
                        </div>
                        {/* <div className="col-2">
							<i onClick={this.cancelCreateJump} className="fa fa-close"></i>
						</div> */}
                    </CardHeader>
                    <CardBody className="custom-card-body" style={{ padding: '10px' }}>
                        <div className='complete-loading'>
                            <ClipLoader css={override} sizeUnit={"px"}  size={35}
                                color={'#fff'}loading={this.state.isloading}
                            />
                        </div>
                        <LocalForm form="createjump" model="createjump">
                            {/* <Row className="form-group">
                                <Label htmlFor="jumpType" md={12} style={{ paddingLeft: '21px' }}> <strong>Jump Type </strong></Label>
                                <Col md={12}>
                                    <ButtonGroup className="row jump-type-group" style={{ fontWeight: 'strong', padding: '0px 20px' }}>
                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', marginLeft: '4px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(1)} active={this.state.rSelected === 1}><strong>Wingsuit</strong></Button>

                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', marginLeft: '4px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(2)} active={this.state.rSelected === 2}><strong>Belly</strong></Button>

                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', marginLeft: '4px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(3)} active={this.state.rSelected === 3}><strong>Freefly</strong></Button>

                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(4)} active={this.state.rSelected === 4}><strong>Tracking</strong></Button>

                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(5)} active={this.state.rSelected === 5}><strong>HopNPop</strong></Button>

                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(8)} active={this.state.rSelected === 8}><strong>High Pull</strong></Button>

                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', fontSize: '1em', marginLeft: '4px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(7)} active={this.state.rSelected === 7}><strong>Solo Student</strong></Button>

                                        <Button color="primary" class="team-jump-btns" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(6)} active={this.state.rSelected === 6}><strong>HopNPop Student</strong></Button>
                                    </ButtonGroup>
                                </Col>
                            </Row> */}
                            <Row className="form-group" style={{ paddingLeft: '8px', paddingRight: '7px' }}>
                                <Label htmlFor="jumpType" md={12} ><strong>Team </strong></Label>
                                <Col md={12}>
                                    {/* <Select
                                        options={teamsOptions}
                                        ref={(value) => this.valRef = value}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        styles={customStyles}
                                        onChange={(values, action) => { this.handleTeamChange(values) }}
                                    /> */}
                                    <AsyncSelect
                                        ref={this.props.forwardRef}
                                        loadOptions={(newValue) => this.promiseTeamOptions(newValue, teamOptions)}
                                        defaultOptions={teamOptions?.slice(0, 10)}
                                        // ref={(value) => this.valRef = value}
                                        onChange={(values, action) => { this.handleTeamChange(values) }}
                                        value={selectedTeam}
                                        onInputChange={(newValue) => this.handleInputChange(newValue)}
                                    />

                                </Col>
                            </Row>
                            <Row className="form-group" style={{ paddingLeft: '8px', paddingRight: '7px' }}>
                                <Label htmlFor="jumpType" md={12} ><strong>Jumpers </strong></Label>
                                <Col md={12}>
                                    <AsyncSelect
                                        model=".jumpers"
                                        name="jumpers"
                                        loadOptions={(newValue) => this.promiseOptions(newValue, jumperOptions)}
                                        isMulti
                                        defaultOptions={jumperOptions?.slice(0, 10)}
                                        onChange={(values, action) => this.handleChange(values, 'jumpers')}
                                        onInputChange={(newValue) => this.handleInputChange(newValue)}
                                        components={{ Option: this.Option, MultiValueLabel: this.MultiValueLabel }}
                                        value={jumpersSelected}
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        menuPlacement="top"
                                        menuPlacement={this.state.menuPlacement}
                                        hideSelectedOptions={false}
                                    />
                                </Col>
                            </Row>

                            <Row className="form-group" style={{ paddingLeft: '8px', paddingRight: '7px' }}>
                                <Label htmlFor="jumpType" md={12} ><strong>Videographer </strong></Label>
                                <Col md={12}>
                                    {/* <Select
                                        options={this.state.jumperOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        styles={customStyles}
                                        value={videoGraphers}
                                        onChange={(values, action) => { this.handeTeamChange(values) }}
                                    /> */}
                                    <AsyncSelect
                                        loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
                                        defaultOptions={this.state?.jumperOptions?.slice(0, 10)}
                                        isMulti
                                        onChange={(values, action) => this.handleChange(values, 'video')}
                                        onInputChange={(newValue) => this.handleInputChange(newValue)}
                                        components={{ Option: this.Option, MultiValueLabel: this.MultiValueLabel }}
                                        value={videoGraphers}
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        menuPlacement="top"
                                        menuPlacement={this.state.menuPlacement}
                                        hideSelectedOptions={false}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group m-t-2-rem">
                                <Col md={{ size: 10 }}>
                                    <button className="btn btn-bg btn-manifest" style={{ fontSize: `${fontSize}px` }} onClick={() => this.cancelOrSubmit()}>Manifest</button>
                                    <button onClick={(e) => { e.preventDefault(); this.cancelCreateJump() }} className="btn manifest-cancel" style={{ fontSize: `${fontSize}px` }}>CANCEL</button>
                                </Col>
                            </Row>
                        </LocalForm>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default React.forwardRef((props, ref) => <CreateTeamJumpCard {...props} forwardRef={ref} />)
