import React, { Component, createElement } from "react";

import Api from '../../../services/reservationapi';
import jumperStore from '../../../stores/jumpers-store';
import swal from "sweetalert";

// import {forte} from 'https://sandbox.forte.net/api/js/v1';

class ForteACH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiLoginId: "",
      isAccountModalOpen: false,
      accountNumber: '',
      routingNumber: '',
      accountType: '',
      jumper: {}
    }
    this.onSubmitedAccount = this.onSubmitedAccount.bind(this);
    this.onCloseAccountModal = this.onCloseAccountModal.bind(this);
  }
  componentWillMount() {
    if (window.ENV?.ACHProcessorInfo) {
      this.setState({
        apiLoginId: window.ENV?.ACHProcessorInfo.apiLoginID
      })
    }
    window.forteACH = this;
    this.setState({
      jumper: this.props.jumper
    })
  }
  onSubmitedAccount = () => {
    var data = {
      api_login_id: this.state.apiLoginId,
      account_number: this.state.accountNumber,
      routing_number: this.state.routingNumber,
      account_type: this.state.accountType
    }
    window.forteAccount(data);
  }
  onTokenCreated = (response) => {
    //do real transaction here by using the onetime_token
    console.log("The onetime_token created: " + response.onetime_token);
    var FullName = this.state.jumper.firstname + " " + this.state.jumper.lastname
    var payLoad = {
      "notes": "Adding Account",
      "echeck": {
        "one_time_token": response.onetime_token,
        "account_holder": FullName
      }
    }
    var accountNumber = "xxxxxxxxx" + response.last_4;
    Api.createPermanentForteToken(payLoad)
      .then(res => {
        console.log(res);
        if (res.status == 200) {
          var payLoad = {
            AccountNumber: accountNumber,
            paymethodToken: res.data.paymethod_token
          }
          jumperStore.addAccountOnFile(this.state.jumper._id, payLoad)
            .then(res => {
              if (res) {
                this.setState({
                  isAccountModalOpen: false
                })
                window.onClose();
                swal("Account Added Sccessfully", "success")
              }
            })
        }
        else {
          swal("Fail To Add Account", "error")
        }
      })
      .catch(err => {
        console.log(err);
        swal("Fail To Add Account", "error")
      })
  }

  onTokenFailed = (response) => {
    //handle error here
    alert("Getting onetime_token failed: " + response.response_description);
    swal("Fail To Add Account Due Invalid Data", "fail")
  }

  AddAccount = () => {
    this.setState({
      isAccountModalOpen: true
    })
    window.forteUI();
    window.forteACH = this;
  }

  onCloseAccountModal = (e) => {
    this.setState({
      isAccountModalOpen: false
    })
  }

  render() {
    const { apiLoginID, isAccountModalOpen } = this.state;
    return (
      <React.Fragment>
        <button className="btn cardButton jumper-add-button"
          type="button"
          style={{ marginLeft: '5px', padding: '0.15rem 0.35rem', marginBottom: '8px' }}
          onClick={this.AddAccount}
        >
          <i className="fa fa-plus"></i>Account
        </button>
      </React.Fragment>
    )
  }
}

export default ForteACH