/**
 * http://usejsdoc.org/
 */
import { EventEmitter } from 'events';

import Api from '../services/authapi';

class Auth extends EventEmitter {
    constructor() {
        super();
        this.prefix = 'AUTH_STORE_';
        this.auth_token = "";
        this.isLoggedIn = false;
        this.ip = "";
    }
    setListeners = (socket) => {

        socket.on('user_settings', settings => {
            alert('user setting changed');
            console.log('user_settings event');
            let payload = { data: settings }
            this.emitResults(payload, 'settings')
        })
    }
    login = (payload) => {
        return new Promise((resolve, reject) => {
            Api.login(payload).then((r) => {
                if (r.status == 200) {
                    //console.log(r.data);
                    if (r.data.success) {
                        this.auth_token = r.data.token;
                        this.isLoggedIn = true;
                        this.emitResults({ message: "logged in" }, "loggedIn")
                        resolve(r.data);
                    }
                    reject({ message: "incorrect credentials" });

                } else {
                    //console.log(r);
                    this.emitError({ message: 'Could not sign in, please contact support.' });
                    resolve(r.data);
                }
            })
                .catch((err) => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    getIp = () => {
        //alert('getting ip');
        return new Promise((resolve, reject) => {
            Api.getIp().then((r) => {
                if (r.status == 200) {
                    //console.log(r.data);
                    //alert(JSON.stringify(r.data));
                    console.log(r.data);
                    console.log(r.data.ip);
                    this.ip = r.data.ip;
                    this.emitResults(r.data, "getIp");
                    resolve(r.data);
                    reject({ message: "incorrect credentials" });

                } else {
                    //console.log(r);
                    alert('error');
                    console.log(r.data);
                    this.emitError({ message: 'Could not sign in, please contact support.' });
                    resolve(r.data);
                }
            })
                .catch((err) => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }
    getSettingsByUser = () => {
        return new Promise((resolve, reject) => {
            Api.getSettingsByUser()
                .then(res => {
                    if (res.status === 200) {
                        if (res.data) {
                            let payload = { data: res.data }
                            this.emitResults(payload, 'settings')

                        }
                        else {
                            this.emitError({ message: "Please Try again" })
                        }
                    }
                    resolve(true);
                })
                .catch(err => {
                    this.emitError({ message: 'Server error' })
                    reject(err);
                })
        })
    }
    updateSettingsOfUser = (payload) => {
        return new Promise((resolve, reject) => {
            Api.updateSettingsOfUser(payload)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data) {
                            let payload = { data: res.data }
                            this.emitResults(payload, 'settings')
                        }
                        else {
                            this.emitError({ message: "Please Try again" })
                        }
                    }
                })
                .catch(err => {
                    this.emitError({ message: 'Server error' })
                    reject(err);
                })
        })
    }

    ChangeUserSettings = (payload) => {
        return new Promise((resolve, reject) => {
            Api.changeSettings(payload).then((r) => {
                if (r.status == 200) {
                    if (r.data) {
                        //alert('user setting changed');

                        // this.emitResults({ message: "Please check email to verify" }, "loggedIn")
                        resolve(r);
                    }
                } else {
                    //console.log(r);
                    this.emitError({ message: 'Could not change settings, please contact support.' });
                    resolve(r.data);
                }
            })
                .catch((err) => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    getIpFromAuthStore = () => {
        return this.ip;
    }

    dataLoaded = () => {
        this.emitResults({ message: "data Loaded" }, "dataLoaded");
    }

    getAuthToken = () => {
        return this.auth_token;
    }

    isLoggedIn = () => {
        return this.isLoggedIn;
    }

    emitError = (err) => {
        var payload = { ...err };
        this.emit('ERROR', payload);
    }

    getEventPrefix = (func) => {
        return `${this.prefix}${func}`;
    }

    emitResults = (r, func) => {
        if (!r.data) {
            //console.log(r);
            this.emitError({ alert: true, message: 'No Slots found' });
        }
        this.setMaxListeners(1000);
        this.emit(`${this.prefix}${func}`, r.data);
    }

    emitDate = (r, func) => {

        this.emit(`${this.prefix}${func}`, r);
    }

    debug = (r) => {
        //console.log(r.data);
    }
}

const authStore = new Auth();
window.authStore = authStore;
export default authStore;
