export const styles = theme => ({
    paper: {
        border: 0,
        // width: '86%',
        width: '92%',
        minWidth: 400,
        // maxHeight: 520,
        // height: 519,
        height: '70%',
        maxHeight: '70.1%',
        borderRadius: 3,
        margin: '2% auto',
        overflowY: 'auto',
        backgroundColor: '#E7F2F6'
    },
    table: {
        padding: 0,
        width: '100%',
        color: '#03173F',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        // tableLayout: 'fixed',
    },
    giftTableClass: {
        padding: 0,
        width: '100%',
        color: '#03173F',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        tableLayout: 'auto',
    },
    reportTableCell: {
        textAlign: 'center',
        padding: '1rem 0rem',
        borderBottom: 'none',
    },
    reportHeadingOne: {
        color: '#03173F',
        fontWeight: '500',
        textAlign: 'center',
        fontFamily: 'Roboto',
    },
    subHeadings: {
        fontSize: 18,
        color: '#03173F',
        fontWeight: '500',
        borderBottom: 'none',
        textTransform: 'capitalize',
        padding: '0.5rem 0em 0rem 2.8rem',
    },
    detailSubHeadings: {
        fontSize: 19,
        color: '#03173F',
        fontWeight: '500',
        borderBottom: 'none',
        textTransform: 'capitalize',
        padding: '0.5rem 0em 0rem 2.0rem',
        overflowWrap: 'break-word'

    },
    giftCardHeadings: {
        fontSize: 15,
        color: '#03173F',
        fontWeight: '500',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
        textTransform: 'capitalize',
        padding: '0.5rem 0em 0rem 2.8rem',
        overflowWrap: 'break-word'

    },
    detailtableCell: {
        fontSize: 18,
        color: '#03173F',
        textTransform: 'capitalize',
        padding: '0rem 0em 0rem 2.0em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
    },
    staffsubHeadings: {
        fontSize: 14,
        color: '#03173F',
        fontWeight: '500',
        borderBottom: 'none',
        textTransform: 'capitalize',
        padding: '0.5rem 0em 0rem 0.5rem',
    },
    staffpdfsubHeadings: {
        fontSize: 14,
        color: '#03173F',
        fontWeight: '500',
        borderBottom: 'none',
        textTransform: 'capitalize',
        padding: '0.5rem 0em 0rem 0.8rem'
    },
    sectionHeading: {
        fontSize: 22,
        color: '#03173F',
        fontWeight: '500',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        padding: '0rem 0em 0rem 2.5em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
        paddingLeft: '24px'
    },
    dateHeading: {
        fontSize: 24,
        color: '#03173F',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        padding: '1rem 0em 1rem 2.5em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
        paddingLeft: '24px'
    },
    summaryHeading: {
        fontSize: 22,
        color: '#03173F',
        fontWeight: '500',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        padding: '1rem 0em 1rem 2.8em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
        paddingLeft: '24px'
    },
    tableCell: {
        fontSize: 18,
        color: '#03173F',
        textTransform: 'capitalize',
        // padding: '1rem 0em 1rem 2.7em',
        padding: '0rem 0em 0rem 2.7em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
    },
    pdftableCell: {
        fontSize: 18,
        color: '#03173F',
        textTransform: 'capitalize',
        // padding: '1rem 0em 1rem 2.7em',
        padding: '0rem 0em 0rem 2.7em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
    },
    stafftableCell: {
        fontSize: 14,
        color: '#03173F',
        textTransform: 'capitalize',
        padding: '0rem 0em 0rem 0.7em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
    },
    tableCellNegative: {
        color: 'red',
    },
    tableCellNegativeGreen: {
        color: 'green',
    },
    sectionTotal: {
        fontSize: 18,
        fontWeight: '500',
        textTransform: 'capitalize',
        padding: '0rem 0em 0rem 2.5em',
        borderBottom: '0.5px solid rgba(88,88,140,0.2)',
    },
    editButton: {
        flexGrow: '1',
        color: '#FFFFFF',
        fontSize: 18,
        backgroundColor: '#00419D'
    },
    pdfRow: {
        lineHeight: 1.2,
        height: '42px'
    }

});
