import React, { Component } from 'react'
import { Table } from 'reactstrap';
import QRCode from 'qrcode.react';
import CreateItem from '../modals/createItem';
import RemoveItem from '../modals/removeItem';
import DataTable from 'react-data-table-component';
import categoryStore from '../../../stores/category-store';

const customStyles = {
    headCells: {
        style: {
            fontWeight: '600',
            fontSize: "1rem",
            letterSpacing: "1px",
            textAlign: 'center'
        },
    },
    rows: {
        style: {
            padding: '10px 0'
        },
    },
};

export default class Inventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],
        }
    }

    componentDidMount = () => {
        categoryStore.getCategories()
            .then(res => {
                this.setState({ categoryList: res })
            })
    }

    makeQrString = (item) => {
        let str = JSON.stringify(item);
        return str;
    }

    itemColumns = () => {
        return [
            {
                name: '',
                center: true,
                selector: 'serial',
                width: '60px'
            },
            {
                name: 'Item',
                selector: 'item',
                sortable: true,
            },
            {
                name: 'Category',
                selector: 'category',
                sortable: true,
                center: true,
            },
            {
                name: 'Price',
                selector: 'price',
                center: true,
            },
            {
                name: 'Tax',
                selector: 'isTaxable',
                center: true,
                cell: (row) => row.isTaxable ? "yes" : "no",
            },
            {
                name: 'Quantity',
                selector: 'quantity',
                center: true,
            },
            {
                name: 'Track Quantity',
                selector: 'trackingQuantity',
                center: true,
                cell: (row) => row.trackingQuantity ? "yes" : "no"
            },
            {
                name: 'Edit',
                center: true,
                cell: (row) => <CreateItem isEdit={true} item={row} categories={this.state.categoryList} />
            },
            {
                name: 'Delete',
                selector: 'delete',
                center: true,
                cell: (row) => <RemoveItem item={row} />
            }
        ]
    }

    render() {
        let { countersaleItems } = this.props;
        countersaleItems.forEach((item, index) => { item.serial = index + 1; })
        console.log(countersaleItems, "<=========countersaleItems");
        return (
            <>
                <div className="card inventoryTable">
                    <CreateItem isEdit={false} categories={this.state.categoryList} />
                    <DataTable
                        title={'Inventory'}
                        center={true}
                        striped={true}
                        responsive={true}
                        pagination={true}
                        paginationPerPage={25}
                        customStyles={customStyles}
                        data={countersaleItems}
                        columns={this.itemColumns()}
                    />
                </div>
            </>
        )
    }
}
