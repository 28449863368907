import React, { Component } from 'react'
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';

import CreateJumper from '../modals/createJumper';
import RatingModal from '../modals/ratingModal';

export default class Admin extends Component {

    Option = (props) => {
        return (
            <div className="row">

                <div className="col-12">
                    <components.Option {...props} />
                </div>
            </div>
        );
    }
    Menu = (props) => {
        ////console.log(props);
        let { renderCheckbox } = this.props;
        return (
            <div>
                <div className="row custom-header" style={{ height: '100px' }}>
                    {renderCheckbox()}
                </div>
                <components.Menu {...props}>
                    {props.children}
                </components.Menu>
            </div>
        );
    }

    render() {
        const customStyles = {
            control: base => ({
                ...base,
                minHeight: 30
            }),
            menu: (provided, state) => ({
                ...provided,
                borderBottom: "1px dotted pink",
                color: state.isSelected ? "red" : "blue",
                padding: 20,
                height: 730,
                marginTop: 0,
                background: "#E7F2F6"
            }),
            menuList: (provided, state) => ({
                ...provided,
                borderBottom: "1px dotted pink",
                padding: 20,
                minHeight: 700
            })
        };
        let { adminDefaultOptions, handleInputChange, handleSelect, promiseOptions, cardArray, accountArray, currentJumper, jumperOptions } = this.props;
        return (
            <React.Fragment>
                <div className="col-sm-12 col-md-8">
                    <div className="admin-selector">
                        <div className="account-header">
                            <CreateJumper />
                            <span>ADMIN ACTIVITY</span>
                            {/* <ul id="velocityPanel"></ul>
									{	
										this.state.velocityButton &&
										<button className="velocity-button" 
											id="btn_AddCard"
											data-amount='1'
											data-terminal-profile-id="15617" data-use-test-values="true"
											data-callback-function="onPaymentCompletion"
										>
											Pay
										</button>
									}	 */}
                        </div>

                        <AsyncSelect
                            loadOptions={(newValue) => promiseOptions(newValue, jumperOptions)}
                            styles={customStyles}
                            onInputChange={(val) => handleInputChange(val)}
                            menuIsOpen
                            defaultOptions={adminDefaultOptions}
                            onChange={(val) => handleSelect(val)}
                            // components={{ Option: this.Option }}
                            components={{ Option: this.Option, Menu: this.Menu }}
                            modal={this.props.modal}
                        />

                        {this.props.modal && <RatingModal jumperValue={currentJumper} cardArray={cardArray} accountArray={accountArray} closeModal={this.props.closeModal} />}
                    </div>
                </div>
            </React.Fragment>

        )
    }
}
