import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, TableHead } from '@material-ui/core';
import { styles } from './DataTableStyle';
import reportStore from '../../../../stores/report-store';
import * as constants from '../../../constant/reports';
// import JumperStatement from '../../../PrintPdf/jumperStatmentPdf'
import loadsStore from '../../../../stores/loads-store';
import DonutLoader from '../../../loadManager/DonutLoader';

class JumperStatement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: [],
            Isdata: false,
            isloading: false,

        }
        this.loadEvents = [
            { name: reportStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
        ];

    }

    checkForNegatives = (price) => {
        if(price === undefined || price === null){
            return `$0.00`
        }
        let priceCell = (price < 0) ?
            {
                value: `($${Math.abs(price).toFixed(2)})`
            } :
            {
                value: `$${price.toFixed(2)}`
            }

        if (price < 0) {
            return <span style={{ color: 'red' }}> {priceCell.value}</span>;
        }
        return priceCell.value;
    }

    handleCheckbox = (event, type) => {
        let elements;
        if (document.getElementById(type).checked) {
            document.getElementById(type).checked = true
            elements = document.getElementsByClassName(type);
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = "table-row"
            }
            // event.target.checked=event.target.checked
            setTimeout(() => {
                document.getElementById(type).checked = true
            }, 1000)
            var checkboxes = document.querySelectorAll('input[type=checkbox]'); {
                for (let i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].id != type) {
                        if (checkboxes[i].checked) {
                            elements = document.getElementsByClassName(checkboxes[i].id);
                            for (let j = 0; j < elements.length; j++) {
                                elements[j].style.display = "table-row"
                            }
                        } else {
                            elements = document.getElementsByClassName(checkboxes[i].id);
                            for (let j = 0; j < elements.length; j++) {
                                elements[j].style.display = "none"
                            }
                        }

                    }
                }
            }
        }
        else {
            var checkboxes = document.querySelectorAll('input[type=checkbox]');
            let isAllUncheked = false
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    isAllUncheked = true
                    break;
                }
            }
            if (isAllUncheked) {
                document.getElementById(type).checked = false
                elements = document.getElementsByClassName(type);
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.display = "none"
                }
                setTimeout(() => {
                    document.getElementById(type).checked = false
                }, 1000)
            }
            else {
                for (let i = 0; i < checkboxes.length; i++) {
                    var id = checkboxes[i].id;
                    elements = document.getElementsByClassName(id);
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].style.display = "table-row"
                    }
                }
            }
            // event.target.checked=event.target.checked
        }
    }

    filterSummary = () => {
        const { classes } = this.props;

        let summaryArray = [];
        let summaryDetail = [];
        let taxAmount = undefined;

        if (this.state.Isdata === true) {
            summaryDetail.push(
                <Fragment>
                    <TableRow key={'summaryHeader'}>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="11"
                            component="th"
                            className={classes.sectionHeading}>
                            <h4>Summary</h4>
                        </TableCell>
                    </TableRow>
                    <TableRow key={'summaryHeader'}>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="1"
                            component="th"
                            className={classes.subHeadings}>
                            {/* <Checkbox className="selectCheckbox" /> */}
                        </TableCell>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="3"
                            component="th"
                            className={classes.subHeadings}>
                            Type
                        </TableCell>
                        <TableCell
                            key={`summaryCell2`}
                            colSpan="3"
                            component="th"
                            className={classes.subHeadings}>
                            Value
                        </TableCell>
                        <TableCell
                            key={`summaryCell2`}
                            colSpan="3"
                            component="th"
                            className={classes.subHeadings}>
                            Tax
                        </TableCell>
                        <TableCell
                            key={`summaryCell3`}
                            colSpan="2"
                            component="th"
                            className={classes.subHeadings}>
                            Count
                        </TableCell>
                    </TableRow>
                </Fragment>
            )

            this.filterDataArray = this.state.filterData;
            // console.log("🚀  JumperStatement ~ this.filterDataArray", this.filterDataArray)
            for (var item in this.filterDataArray) {
                // console.log('ITME:: ', item)
                var logArray = this.filterDataArray[item];

                if (item === "summary") {
                    for (var sitem in logArray) {
                        if (logArray[sitem].count > 0) {
                            summaryArray.push({
                                type: sitem,
                                value: logArray[sitem].value,
                                count: logArray[sitem].count,
                            });
                        }
                    }
                }
                else {
                    if (logArray.endDayTaxBalance) {
                        taxAmount = Number(logArray.endDayTaxBalance);
                    }
                }

            }
        }

        var isCount = false;
        for (var i = 0; i < summaryArray.length; i++) {
            if (summaryArray[i].count > 0) {
                isCount = true;
                let type = summaryArray[i].type
                summaryDetail.push(
                    <TableRow key={`summaryRow${i}`}>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="1"
                            component="th"
                            className={classes.subHeadings}>
                            <input type="checkbox" id={type} onClick={(event) => this.handleCheckbox(event, type)} />
                            {/* <Checkbox id={type} className="selectCheckbox" checked={true} onClick={(event=>this.handleCheckbox(event,type))}/> */}
                        </TableCell>
                        <TableCell
                            key={`summaryCell1${i}`}
                            component="th"
                            colSpan="3"
                            className={classes.tableCell}>
                            {summaryArray[i].type}
                        </TableCell>
                        <TableCell
                            key={`summaryCell2${i}`}
                            colSpan="3"
                            component="th"
                            className={classes.tableCell}>
                            {this.checkForNegatives(summaryArray[i].value)}
                        </TableCell>


                        <TableCell
                            key={`summaryCell3${i}`}
                            colSpan="3"
                            component="th"
                            className={classes.tableCell}>
                            {taxAmount ? this.checkForNegatives(taxAmount) : ''}
                        </TableCell>

                        <TableCell
                            key={`summaryCell4${i}`}
                            colSpan="2"
                            component="th"
                            className={classes.tableCell}>
                            {summaryArray[i].count}
                        </TableCell>
                    </TableRow>
                )
            }
        }

        if (!isCount) {
            summaryDetail = [];
        }
        else {
            summaryDetail.push(
                <TableRow key={`summaryfooter`}>
                    <TableCell
                        key={`summaryfooterCell`}
                        component="th"
                        colSpan="8"
                        className={classes.subHeadings}>
                    </TableCell>
                </TableRow>
            )
        }
        return summaryDetail;
    }
    formatDate(input) {
        if (input.toString().length == 1) {
            return '0' + input;
        }
        else {
            return input;
        }
    }
    renderFormatDate = (desc) => {
        let date;
        if (desc.createdAt !== undefined) {
            date = new Date(desc.createdAt);
        }
        else {
            date = new Date(desc.date);
        }

        let newDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());

        return newDate;

    }
    filterData = () => {
        let filterOutput = [];
        let jumperArray = [];
        let summaryArray = [];


        if (this.state.Isdata === true) {
            this.filterDataArray = this.state.filterData;
            // console.log("this.filterDataArray", this.filterDataArray)
            for (var sortItem in this.filterDataArray) {
                if (sortItem !== 'summary') {
                    jumperArray.push({ label: this.DateConvert(sortItem), type: 'date', jumperStatement: [], summary: [] })
                    var statments = this.filterDataArray[sortItem];
                    for (var item in statments) {
                        filterOutput = [];
                        var logArray = statments[item];
                        if (item !== 'chargeHistory') {
                            for (var items in logArray) {
                                var desc = logArray[items];
                                let JumperInfo = [];
                                // console.log("desc logArray[items] ", desc)
                                if (desc.jumperIds !== undefined) {
                                    var otherJumper = desc.jumperIds;
                                    for (var oitem in otherJumper) {
                                        var name = otherJumper[oitem].jumperId?.firstname + ' ' + otherJumper[oitem].jumperId?.lastname;
                                        JumperInfo.push({ jumperName: name })
                                    }
                                }
                                var type = '';
                                if (desc.type != undefined) {
                                    if (desc.type === "aff main" || desc.type === "aff student" || desc.type === "stp main" || desc.type === "stp student") {
                                        type = desc.type + ' level ' + desc.level;
                                    }
                                    else {
                                        type = desc.type;
                                    }
                                }
                                else {
                                    type = desc.jumpType;
                                }
                                // console.log("DATESS: ", desc.createdAt);
                                var localDate = this.renderFormatDate(desc);

                                filterOutput.push({ id: desc._id, type: type, tax: desc.tax, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo, transactionType: desc.transactionType, description: desc.description })
                            }
                            if (logArray.length > 0) {
                                jumperArray.push({ label: item, jumperStatement: filterOutput, summary: summaryArray })
                            }

                        }

                    }
                    for (var item in statments) {
                        filterOutput = [];
                        var logArray = statments[item];
                        if (item === 'chargeHistory') {
                            for (var items in logArray) {
                                var desc = logArray[items];
                                let JumperInfo = [];
                                // console.log("desc logArray[items] ", desc)
                                if (desc.jumperIds !== undefined) {
                                    var otherJumper = desc.jumperIds;
                                    for (var oitem in otherJumper) {
                                        var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
                                        JumperInfo.push({ jumperName: name })
                                    }
                                }
                                var type = '';
                                if (desc.type != undefined) {
                                    if (desc.type === "aff main" || desc.type === "aff student" || desc.type === "stp main" || desc.type === "stp student") {
                                        type = desc.type + ' level ' + desc.level;
                                    }
                                    else {
                                        type = desc.type;
                                    }
                                }
                                else {
                                    type = desc.jumpType;
                                }

                                var localDate = this.renderFormatDate(desc);

                                filterOutput.push({ id: desc._id, type: type, tax: desc.tax, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo, transactionType: desc.transactionType, description: desc.description })
                            }
                            if (logArray.length > 0) {
                                jumperArray.push({ label: item, jumperStatement: filterOutput, summary: summaryArray })
                            }
                        }

                    }

                    jumperArray.push({
                        label: 'EndDayBalance',
                        value: statments.endDayBalance,
                        taxAmount: statments.endDayTaxBalance,
                        totalAmount: statments.endDayBalance + statments.endDayTaxBalance,
                        jumperStatement: [],
                        summary: []
                    })

                    //sortedArray.push(jumperArray);
                }
            }
        }
        // return filterOutput;
        return jumperArray;
    }


    renderReportHeader = () => {
        const { range, classes } = this.props;
        const index = range.dateRange.findIndex(range => range.modalType === constants.JUMPER_STATEMENT);
        if (index != -1)
            return (
                <TableHead key={`statementHeader`}>
                    <TableRow key={'summaryHeader'}>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="2"
                            component="th"
                            className={classes.subHeadings}>
                            <b>{range.dateRange[index].jumper_name}</b>
                        </TableCell>
                        <TableCell
                            style={{ textAlign: 'center' }}
                            key={`summaryCell1`}
                            colSpan="4"
                            component="th"
                            className={classes.subHeadings}>
                            <h3>Jumper Statement</h3>
                        </TableCell>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="2"
                            component="th"
                            className={classes.subHeadings}>
                            <b>({moment(range.dateRange[index].startDate).format('L')} - {moment(range.dateRange[index].endDate).format('L')})</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
            )
        else
            return null
    }

    DateConvert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    filterPdfData = () => {
        const { classes } = this.props;

        let filterDataArray = [];
        let filterOutput = [];
        let jumperArray = [];
        let mainArray = [];
        let summaryArray = [];
        let summaryDetail = [];
        let sortedArray = [];

        if (this.state.Isdata === true) {
            // this.filterDataArray = this.state.filterData;
            this.filterDataArray = this.state.filterData;
            for (var sortItem in this.filterDataArray) {
                //jumperArray = [];
                if (sortItem !== 'summary') {
                    // console.log(this.DateConvert(sortItem))
                    jumperArray.push({ label: this.DateConvert(sortItem), type: 'date', jumperStatement: [], summary: [] })
                    var statments = this.filterDataArray[sortItem];
                    for (var item in statments) {
                        filterOutput = [];
                        var logArray = statments[item];
                        if (item !== 'chargeHistory') {
                            for (var items in logArray) {
                                var desc = logArray[items];
                                let JumperInfo = [];

                                if (desc.jumperIds !== undefined) {
                                    var otherJumper = desc.jumperIds;
                                    for (var oitem in otherJumper) {
                                        var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
                                        JumperInfo.push({ jumperName: name })
                                    }
                                }
                                var type = '';
                                if (desc.type != undefined) {
                                    if (desc.type === "aff main") {
                                        type = desc.type + ' ' + desc.level;
                                    }
                                    else {
                                        type = desc.type;
                                    }
                                }
                                else {
                                    type = desc.jumpType;
                                }

                                let localDate = this.renderFormatDate(desc);

                                filterOutput.push({ id: desc._id, type: type, tax: desc.tax, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo, transactionType: desc.transactionType, description: desc.description })
                            }
                            if (logArray.length > 0) {
                                jumperArray.push({ label: item, jumperStatement: filterOutput, summary: summaryArray })
                            }
                        }

                    }
                    for (var item in statments) {
                        filterOutput = [];
                        var logArray = statments[item];
                        if (item === 'chargeHistory') {
                            for (var items in logArray) {
                                var desc = logArray[items];
                                let JumperInfo = [];

                                if (desc.jumperIds !== undefined) {
                                    var otherJumper = desc.jumperIds;
                                    for (var oitem in otherJumper) {
                                        var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
                                        JumperInfo.push({ jumperName: name })
                                    }
                                }

                                var type = '';
                                if (desc.type != undefined) {
                                    if (desc.type === "aff main") {
                                        type = desc.type + ' ' + desc.level;
                                    }
                                    else {
                                        type = desc.type;
                                    }
                                }
                                else {
                                    type = desc.jumpType;
                                }

                                let localDate = this.renderFormatDate(desc);

                                filterOutput.push({ id: desc._id, type: type, tax: desc.tax, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo, transactionType: desc.transactionType, description: desc.description })
                            }
                            if (logArray.length > 0) {
                                jumperArray.push({ label: item, jumperStatement: filterOutput, summary: summaryArray })
                            }
                        }

                    }
                    jumperArray.push({ label: 'EndDayBalance', value: statments.endDayBalance, jumperStatement: [], summary: [] })

                    //sortedArray.push(jumperArray);
                }
                else {
                    var logArray = this.filterDataArray[sortItem];
                    for (var sitem in logArray) {
                        summaryArray.push({
                            type: sitem, value: logArray[sitem].value, count: logArray[sitem].count
                        });
                    }
                }
            }
        }

        let rows = [];
        var rowlength = 2;
        var childrow = [];

        rows.push(
            this.renderReportHeader()
        )

        rows.push(
            <TableHead key={`statementHeader`}>
                <TableRow key={'summaryHeader'} >
                    <TableCell
                        key={`summaryCell1`}
                        colSpan="8"
                        component="th"
                        className={classes.sectionHeading}>
                        <h4>Summary</h4>
                    </TableCell>
                </TableRow>
                <TableRow key={'statementHeaderRow'}>
                    <TableCell key={'statementHeaderCell1'} component="th" className={classes.subHeadings}>
                        Type
                    </TableCell>
                    <TableCell key={'statementHeaderCell2'} component="th" className={classes.subHeadings}></TableCell>
                    <TableCell key={'statementHeaderCell3'} component="th" className={classes.subHeadings}></TableCell>
                    <TableCell key={'statementHeaderCell4'} component="th" className={classes.subHeadings}>
                        Value
                    </TableCell>
                    <TableCell key={'statementHeaderCell5'} component="th" className={classes.subHeadings}></TableCell>
                    <TableCell key={'statementHeaderCell6'} component="th" className={classes.subHeadings}></TableCell>
                    <TableCell key={'statementHeaderCell7'} component="th" className={classes.subHeadings}>
                        Count
                    </TableCell>
                </TableRow>
            </TableHead>
        )


        // rowlength = rowlength + 4;
        var isCount = false;
        for (var i = 0; i < summaryArray.length; i++) {
            if (summaryArray[i].count > 0) {
                isCount = true;
                rowlength++;
                rows.push(
                    <Fragment key={`fragment${i}`}>
                        <TableRow key={`pdfStatmentRow${i}`} className={classes.pdfRow}>
                            <TableCell
                                key={`pdfStatmentCell1${i}`}
                                component="th"
                                className={classes.pdftableCell}>
                                {summaryArray[i].type}
                            </TableCell>
                            <TableCell key={`pdfStatmentCell2${i}`} component="th" className={classes.pdftableCell}></TableCell>
                            <TableCell component="th" className={classes.pdftableCell}></TableCell>
                            <TableCell
                                key={`pdfStatmentCell3${i}`}
                                component="th"
                                className={classes.pdftableCell}>
                                {this.checkForNegatives(summaryArray[i].value)}
                            </TableCell>
                            <TableCell key={`pdfStatmentCell4${i}`} component="th" className={classes.pdftableCell}></TableCell>
                            <TableCell key={`pdfStatmentCell5${i}`} component="th" className={classes.pdftableCell}></TableCell>
                            <TableCell
                                key={`pdfStatmentCell6${i}`}
                                component="th"
                                className={classes.pdftableCell}>
                                {summaryArray[i].count}
                            </TableCell>
                        </TableRow>
                    </Fragment>
                )
            }

            if (rowlength >= 27) {
                mainArray.push(
                    <div key={`jumperLogPanel${i}`} className="jumperLogPanel" style={{ width: '25cm', margin: '20px 20px 20px 20px', padding: '20px 20px 0px 30px' }}>
                        <div key={`innerLogPanel${i}`} style={{ height: '10px' }}></div>
                        {rows}
                        <div key={`innerLogPanela${i}`} style={{ height: '20px' }}></div>
                    </div>);
                rowlength = 1;
                childrow = [];
                rows = [];
            }
        }

        if (!isCount) {
            rowlength = 1;
            mainArray = [];
        }
        else {
            rowlength++;
        }

        rows.push(
            <TableHead>
                <TableRow key={'pjumperlogHeader'}>
                    <TableCell
                        key={'pjumperlogHeaderCell1'}
                        component="th"
                        className={classes.subHeadings}>
                        Jump Type
                    </TableCell>
                    <TableCell className={classes.subHeadings} key={'pjumperlogHeaderCell1a'}> </TableCell>
                    <TableCell
                        key={'pjumperlogHeaderCell2'}
                        component="th"
                        className={classes.subHeadings}>
                        Date
                    </TableCell>
                    <TableCell className={classes.subHeadings} key={'pjumperlogHeaderCell2a'} colSpan="2"> </TableCell>
                    <TableCell
                        key={'pjumperlogHeaderCell3'}
                        component="th"
                        className={classes.subHeadings}>
                        Price
                    </TableCell>
                    <TableCell className={classes.subHeadings} key={'pjumperlogHeaderCell3a'}> </TableCell>
                    <TableCell
                        key={'pjumperlogHeaderCell5'}
                        component="th"
                        className={classes.subHeadings}>
                        Tax
                    </TableCell>
                    <TableCell className={classes.subHeadings} key={'pjumperlogHeaderCell5'}> </TableCell>
                    <TableCell
                        key={'pjumperlogHeaderCell4'}
                        component="th"
                        className={classes.subHeadings}>
                        Load Num
                    </TableCell>
                    <TableCell className={classes.subHeadings} key={'pjumperlogHeaderCell4a'}> </TableCell>
                </TableRow>
            </TableHead>
        )


        for (var index = 0; index < jumperArray.length; index++) {
            rowlength++;
            childrow = [];
            var jumperList = jumperArray[index].jumperStatement;
            for (var j = 0; j < jumperList.length; j++) {
                rowlength++;
                var otherJumpers = "";
                var otherJumpcount = 0;
                childrow.push(
                    <Fragment>
                        <TableRow
                            className={classes.pdfRow}
                            key={`pjumperlogsrow${j + index}`}>
                            <TableCell
                                colSpan="2"
                                className={classes.tableCell}
                                key={`pjumperlogscolumn1${j + index}`}
                            >
                                {jumperList[j].type}
                            </TableCell>
                            {/* <TableCell className={classes.tableCell} key={`pjumperlogscolumn1a${j + index}`}> </TableCell> */}
                            <TableCell
                                colSpan="2"
                                className={classes.tableCell}
                                key={`pjumperlogscolumn2${j + index}`}
                            >
                                {jumperList[j].date}
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`pjumperlogscolumn1b${j + index}`}> </TableCell>
                            <TableCell
                                className={classes.tableCell}
                                key={`pjumperlogscolumn2${j + index}`}
                            >
                                {jumperList[j].price != undefined ? this.checkForNegatives(jumperList[j].price) : ''}
                                {jumperList[j].amount != undefined ? this.checkForNegatives(jumperList[j].amount) : ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`pjumperlogscolumn1c${j + index}`}> </TableCell>
                            {/* <TableCell className={classes.tableCell} key={`pjumperlogscolumn1b${j + index}`}> </TableCell> */}
                            <TableCell
                                className={classes.tableCell}
                                key={`pjumperlogscolumn2${j + index}`}
                            >
                                {jumperList[j].loadNum != undefined ? jumperList[j].loadNum : ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`pjumperlogscolumn1d${j + index}`}> </TableCell>
                        </TableRow>
                        {
                            jumperList[j].type.trim() === "charge" &&
                            <TableRow
                                className={classes.pdfRow}
                                key={`pjumperlogsrow${j + index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`pjumperlogscolumnr1${j + index}`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {jumperList[j].memo !== undefined ? jumperList[j].memo + "," : ''} {jumperList[j].transactionType}
                                </TableCell>
                            </TableRow>
                        }

                        {
                            jumperList[j].type.trim() === "transfer" &&
                            <TableRow
                                className={classes.pdfRow}
                                key={`pjumpertransferrow${j + index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`pjumpertransfercolumnr1${j + index}`}
                                    style={{ paddingLeft: '60px' }}
                                >

                                    {jumperList[j].memo !== "" ? "Memo :" + jumperList[j].memo + " " : ''}

                                    {
                                        jumperList[j].memo && jumperList[j].description &&
                                        <br />
                                    }

                                    {jumperList[j].description ? " Description :" + jumperList[j].description : ''}

                                </TableCell>
                            </TableRow>
                        }


                        {
                            jumperList[j].type.trim() == "miscellaneous" && jumperList[j].memo &&
                            <TableRow
                                className={classes.pdfRow}
                                key={`jumperlogsrow${index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumnr1${index}`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {jumperList[j].memo}
                                </TableCell>
                            </TableRow>

                        }

                        {
                            jumperList[j].type.trim() == "adjustment" && jumperList[j].memo &&
                            <TableRow
                                className={classes.pdfRow}
                                key={`jumperlogsrow${index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumnr1${index}`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {jumperList[j].memo}
                                </TableCell>
                            </TableRow>
                        }

                        {/* {
                            jumperList[j].JumpType === "funJumps" && jumperList[j].JumperArray.length > 0
                                ?
                                this.renderOtherJumper(jumperList[j].JumperArray, "P" + j)

                                :
                                ''
                        } */}
                        {/* {otherJumper !== '' &&
                            <TableRow key={`otherJumperRow${"P" + j}`}>
                                <TableCell
                                    key={`otherjumperCell${"P" + j}`}
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumn2`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {otherJumpers}
                                </TableCell>
                            </TableRow>
                        } */}
                    </Fragment>);

                {
                    jumperList[j].JumpType === "funJumps" && jumperList[j].JumperArray.length > 0 &&
                        jumperList[j].JumperArray.map((item) => {
                            otherJumpcount++
                            if (otherJumpers === "") {
                                otherJumpers = item.jumperName;
                            }
                            else {
                                otherJumpers = otherJumpers + ', ' + item.jumperName;
                            }

                            if (otherJumpcount > 9) {
                                rowlength++;
                                otherJumpcount = 0;
                            }
                            else if (jumperList[j].JumperArray[jumperList[j].JumperArray.length - 1].jumperName === item.jumperName) {
                                rowlength++;
                            }

                            if (rowlength >= 27 && otherJumpcount > 8) {
                                {
                                    otherJumpers !== '' &&
                                        childrow.push(
                                            <TableRow key={`otherJumperRow${"P" + j}`} className={classes.pdfRow}>
                                                <TableCell
                                                    key={`otherjumperCell${"P" + j}`}
                                                    colSpan={8}
                                                    className={classes.tableCell}
                                                    key={`jumperlogscolumn2`}
                                                    style={{ paddingLeft: '60px' }}
                                                >
                                                    {otherJumpers}
                                                </TableCell>
                                            </TableRow>)
                                    otherJumpers = '';
                                }

                                rows.push(
                                    <Fragment key={`pFragment${index}`}>
                                        <TableRow
                                            className={classes.pdfRow}
                                            key={`logpdf${index}`}>
                                            <TableCell
                                                className={jumperArray[index].type === 'date' ? classes.dateHeading : classes.sectionHeading}
                                                key={`logCell${index}`}
                                                colSpan={8}>
                                                {jumperArray[index].label}
                                            </TableCell>
                                        </TableRow>
                                        {childrow}

                                    </Fragment>)



                                mainArray.push(
                                    <div className="jumperLogPanel" style={{ width: '25cm', margin: '20px 20px 20px 20px', padding: '20px 20px 0px 30px' }}>
                                        <div style={{ height: '10px' }}></div>
                                        {rows}
                                    </div>);
                                rowlength = 1;
                                childrow = [];
                                rows = [];
                            }
                        })
                }

                {
                    otherJumpers !== '' &&
                        childrow.push(
                            <TableRow key={`otherJumperRow${"P" + j}`} className={classes.pdfRow}>
                                <TableCell
                                    key={`otherjumperCell${"P" + j}`}
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumn2`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {otherJumpers}
                                </TableCell>
                            </TableRow>)
                    otherJumpers = '';
                }

                if (jumperList[j].type.trim() === "charge") { rowlength++; }
                if (jumperList[j].type.trim() === "miscellaneous" && jumperList[j].memo) { rowlength++; }
                if (jumperList[j].type.trim() === "adjustment" && jumperList[j].memo) { rowlength++; }

                // if (jumperList[j].JumperArray.length > 0) {
                //     var otherlength = Math.ceil(jumperList[j].JumperArray.length / 10);
                //     rowlength = rowlength + otherlength;
                //     console.log("JumperListJArray", rowlength, otherlength)
                // }

                if (rowlength >= 27) {
                    rows.push(
                        <Fragment key={`pFragment${index}`}>
                            <TableRow
                                className={classes.pdfRow}
                                key={`logpdf${index}`}>
                                <TableCell
                                    className={classes.sectionHeading}
                                    key={`logCell${index}`}
                                    colSpan={8}
                                >
                                    {jumperArray[index].label}
                                </TableCell>
                            </TableRow>
                            {childrow}
                        </Fragment>)
                    mainArray.push(
                        <div className="jumperLogPanel" style={{ width: '25cm', margin: '20px 20px 20px 20px', padding: '20px 20px 0px 30px' }}>
                            <div style={{ height: '10px' }}></div>
                            {rows}
                        </div>);
                    rowlength = 1;
                    childrow = [];
                    rows = [];
                }
                else if (j === jumperList.length - 1 && index == jumperArray.length - 1) {
                    rows.push(
                        <Fragment key={`pFragment${index}`}>
                            <TableRow
                                className={classes.pdfRow}
                                key={`logpdf${index}`}>
                                <TableCell
                                    className={classes.sectionHeading}
                                    key={`logCell${index}`}
                                    colSpan={8}
                                >
                                    {jumperArray[index].label}
                                </TableCell>
                            </TableRow>
                            {childrow}
                        </Fragment>)
                    mainArray.push(
                        <div className="jumperLogPanel" style={{ width: '25cm', margin: '20px 20px 0px 20px', padding: '20px 20px 0px 30px' }}>
                            <div style={{ height: '10px' }}></div>
                            {rows}
                        </div>);
                    rowlength = 1;
                    childrow = [];
                    rows = [];
                }
            }

            if (jumperArray[index].label === 'EndDayBalance') {
                rows.push(<TableRow
                    className={classes.pdfRow}
                    key={`eodbalancepdf${index}`}>
                    <TableCell
                        className={classes.sectionHeading}
                        key={`eodBalanceCell${index}`}
                        colSpan={8}>
                        {jumperArray[index].label} : {this.checkForNegatives(jumperArray[index].value)}
                    </TableCell>
                </TableRow>)
                rowlength++;
            }
            else {
                rows.push(
                    <Fragment>
                        <TableRow
                            className={classes.pdfRow}
                            key={`logpdf${index}`}>
                            <TableCell
                                className={jumperArray[index].type === 'date' ? classes.dateHeading : classes.sectionHeading}
                                key={`logCell${index}`}
                                colSpan={8}
                            >
                                {jumperArray[index].label}
                            </TableCell>
                        </TableRow>
                        {childrow}
                    </Fragment>)
            }

            if (index === jumperArray.length - 1) {
                mainArray.push(
                    <div className="jumperLogPanel" style={{ width: '25cm', margin: '20px 20px 0px 20px', padding: '20px 20px 0px 30px' }}>
                        <div style={{ height: '10px' }}></div>
                        {rows}
                    </div>);
            }

        }
        // console.log(rows);


        return mainArray;
    }
    renderJumper = (items, value) => {
        let { classes } = this.props;
        var rows = [];
        //return
        items.jumperStatement &&
            items.jumperStatement.map((item, index) => {
                // let { tax, price } = this.checkForNegatives(item.tax, item.price, classes.tableCell);
                // console.log("ITEMS: " + index, item)
                rows.push(
                    <Fragment key={`jumperfragment${index}`}>
                        <TableRow
                            className={items.label}
                            key={`jumperlogsrow${index}`}>
                            <TableCell
                                colSpan={2}
                                className={classes.tableCell}
                                key={`jumperlogscolumn1${index}`}
                            >
                                {item.type}
                            </TableCell>

                            <TableCell
                                colSpan={3}
                                className={classes.tableCell}
                                key={`jumperlogscolumn2${index}`}
                            >
                                {item.date}
                            </TableCell>

                            <TableCell
                                colSpan={2}
                                className={classes.tableCell}
                                key={`jumperlogscolumn2${index}`}
                            >
                                {item.price != undefined ? this.checkForNegatives(item.price) : ''}
                                {item.amount != undefined ? this.checkForNegatives(item.amount) : ''}
                            </TableCell>
                            <TableCell colSpan={2} className={classes.tableCell}></TableCell>
                            <TableCell
                                colSpan={2}
                                className={classes.tableCell}
                                key={`jumperlogscolumn2${index}`}
                            >
                                {item.loadNum != undefined ? item.loadNum : ''}
                            </TableCell>
                        </TableRow>
                        {
                            item.type.trim() === "charge" &&
                            <TableRow className={items.label}
                                key={`jumperlogsrow${index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumnr1${index}`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {item.memo !== undefined ? item.memo + "," : ''} {item.transactionType}
                                </TableCell>
                            </TableRow>
                        }

                        {
                            item.type == "miscellaneous" && item.memo &&
                            <TableRow className={items.label}
                                key={`jumperlogsrow${index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumnr1${index}`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {item.memo}
                                </TableCell>
                            </TableRow>
                        }

                        {
                            item.type == "adjustment" && item.memo &&
                            <TableRow className={items.label}
                                key={`jumperlogsrow${index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumnr1${index}`}
                                    style={{ paddingLeft: '60px' }}
                                >
                                    {item.memo}
                                </TableCell>
                            </TableRow>
                        }

                        {
                            item.type.trim() === "transfer" &&
                            <TableRow className={items.label}
                                key={`pjumpertransferrow${index}`}>
                                <TableCell
                                    colSpan={8}
                                    className={classes.tableCell}
                                    key={`pjumpertransfercolumnr1${index}`}
                                    style={{ paddingLeft: '60px' }}
                                >

                                    {item.memo !== "" ? "Memo :" + item.memo + " " : ''}

                                    {
                                        item.memo && item.description &&
                                        <br />
                                    }

                                    {item.description ? "Description :" + item.description : ''}
                                </TableCell>
                            </TableRow>
                        }

                        {
                            item.JumpType === "funJumps"
                                ?
                                this.renderOtherJumper(item.JumperArray, index, items)
                                :
                                ''
                        }
                    </Fragment>
                )

                // if(items.jumperStatement.length - 1 === index)
                // {
                //     value &&  rows.push(
                //         <TableRow
                //             key={`eodBalancerow${value}`}>
                //             <TableCell
                //                 className={classes.sectionHeading}
                //                 key={`eodColumn${value}`}
                //                 colSpan={8}
                //             >
                //                 EndDayBalance :  {value}
                //             </TableCell>

                //         </TableRow>
                //     )
                // }
            })

        return rows;
    }

    renderOtherJumper = (JumperArray, index, items) => {
        let { classes } = this.props;
        var otherJumpers = "";

        JumperArray.map((item) => {
            if (otherJumpers === "") {
                otherJumpers = item.jumperName;
            }
            else {
                otherJumpers = otherJumpers + ', ' + item.jumperName;
            }
        })

        return (
            <TableRow key={`otherJumperRow${index}`} className={items.label}>
                <TableCell
                    key={`otherjumperCell${index}`}
                    colSpan={8}
                    className={classes.tableCell}
                    key={`jumperlogscolumn2`}
                    style={{ paddingLeft: '60px' }}
                >
                    {otherJumpers}
                </TableCell>
            </TableRow>
        )
    }

    convertCase = (stringValue) => (stringValue.toLowerCase())

    dateConverter = (date) => {
        let dateInMilliseconds = new Date(date).getTime();
        return dateInMilliseconds;
    }

    formatDates = (date, type) => {
        date = new Date(date);
        if (type === "start") {
            date = date.setHours(0, 0, 0, 0);
        }
        else {
            date = date.setHours(23, 59, 59, 999);
        }
        date = new Date(date);
        date = date.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
        return date;
    }
    componentDidUpdate(prevProps, prevState) {
        const { range, isModal, submit } = this.props;
        // // console.log("🚀 ~ file: JumperStatement.js ~ line 1235 ~ JumperStatement ~ componentDidUpdate ~ range", range)
        let startDate = '';
        let endDate = '';
        let JumperId = '';
        // console.log("isMOal: ", prevProps.isModal, isModal);
        if (prevProps.isModal && !isModal) {
            // if modal is closed now
            if (range.dateRange.length > 0 && submit) {
                const index = range.dateRange.findIndex(range => range.modalType === constants.JUMPER_STATEMENT);
                if (index > -1) {
                    this.setState({ isloading: true })

                    JumperId = range.dateRange[index].jumper_id;

                    startDate = this.formatDates(range.dateRange[index].startDate, 'start');
                    endDate = this.formatDates(range.dateRange[index].endDate, 'end');
                    var that = this;
                    var data = [];
                    Promise.all([reportStore.GetJumperLogs(startDate, endDate, JumperId)])
                        .then(function (result) {
                            data = result[0];
                            // console.log("JumperLogData", data);
                            localStorage.setItem("JumperStatementData", JSON.stringify(data));
                            that.setState({ filterData: data, Isdata: true, isloading: false });
                            that.forceUpdate();
                        })
                }
            }
            else {
                var data = localStorage.getItem("JumperStatementData");
                if (data !== null) {
                    this.setState({ filterData: JSON.parse(data), Isdata: true });
                }
            }
        }

    }

    componentWillUnmount = () => {
        this.loadEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }

    handleNewActivity = (activityString) => {
        this.setState({
            isloading: false,
        })

    }

    componentWillMount() {
        this.loadEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func))

        // let { dateParam, range } = this.props;
        // var startDate = '';
        // var endDate = '';
        // let JumperId = '';


        // if (dateParam.length > 0) {

        //     const index = dateParam
        //         .findIndex(range => range.modalType === constants.JUMPER_STATEMENT);

        //     if (index > -1) {

        //         var data = localStorage.getItem("JumperStatementData");
        //         if (data !== null) {
        //             this.setState({ filterData: JSON.parse(data), Isdata: true });
        //         }

        //         // this.setState({ isloading: true })
        //         // JumperId = dateParam[index].jumper_id;
        //         // let sDate = new Date(dateParam[index].startDate);
        //         // sDate = sDate.setHours(0, 0, 0, 0);
        //         // startDate = new Date(sDate);
        //         // startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // let eDate = new Date(dateParam[index].endDate);
        //         // eDate = eDate.setHours(23, 59, 59, 999);
        //         // endDate = new Date(eDate);
        //         // endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // var that = this;
        //         // var data = [];
        //         // Promise.all([reportStore.GetJumperLogs(startDate, endDate, JumperId)])
        //         //     .then(function (result) {
        //         //         data = result[0];
        //         //         that.setState({ filterData: data, Isdata: true});
        //         //         that.forceUpdate();
        //         //     })
        //     }
        // }
    }

    render() {

        const { isloading, filterData } = this.state;
        // console.log("🚀 ~ file: JumperStatement.js ~ line 1283 ~ JumperStatement ~ render ~ filterData", filterData)
        const { classes, submit, data, range } = this.props;
        // console.log("Filtered DAta: ", this.filterData())
        return (

            <Fragment>
                <div className='report-loading inreports'>
                    <DonutLoader isLoading={this.state.isloading} />
                </div>

                <Fragment>
                    {submit && this.filterSummary()}
                </Fragment>

                {submit &&
                    <TableRow key={'headTableRowSubHeadings'}>
                        <TableCell
                            colSpan="2"
                            component="th"
                            className={classes.subHeadings}>
                            Jump Type
                        </TableCell>
                        <TableCell
                            colSpan="3"
                            component="th"
                            className={classes.subHeadings}>
                            Date
                        </TableCell>
                        <TableCell
                            colSpan="2"
                            component="th"
                            className={classes.subHeadings}>
                            Price
                        </TableCell>
                        <TableCell
                            colSpan="2"
                            component="th"
                            className={classes.subHeadings}>
                            Tax
                        </TableCell>
                        <TableCell
                            component="th"
                            colSpan="2"
                            className={classes.subHeadings}>
                            Load Num
                        </TableCell>
                    </TableRow>

                }

                {submit && !isloading && this.filterData().map((row, index) => {
                    // console.log("Row" + index, row);
                    return (
                        <Fragment>
                            <TableRow
                                className={row.label}
                                key={`jumperlogsrow${index}`}>
                                {
                                    row.label !== 'EndDayBalance' ?
                                        <TableCell
                                            className={row.type === 'date' ? classes.dateHeading : classes.sectionHeading}
                                            key={`jumperlogscolumn1${index}`}
                                            colSpan={12}
                                        >
                                            {row.label}
                                        </TableCell>
                                        :
                                        <TableCell
                                            className={row.type === 'date' ? classes.dateHeading : classes.sectionHeading}
                                            key={`jumperlogscolumn1${index}`}
                                            colSpan={12}
                                        >
                                            {/* {row.label} : {this.checkForNegatives(row.value)} */}
                                            {row.label.toLocaleUpperCase()} :
                                            {this.checkForNegatives(row.value)}  {loadsStore.isTaxEnabled() && row.taxAmount ?
                                                this.checkForNegatives(row.taxAmount) : null}

                                            {loadsStore.isTaxEnabled() && row.taxAmount ? this.checkForNegatives(row.totalAmount) : null}
                                        </TableCell>

                                }
                            </TableRow>
                            {this.renderJumper(row)}
                        </Fragment>
                    )
                })
                }
                <div id="logprintPanel" style={{ display: 'none' }}>
                    {this.filterPdfData()}
                </div>
            </Fragment>

        )
    }
}

export default withStyles(styles)(JumperStatement);

//export default JumperStatement;
