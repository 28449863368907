import React, { Component } from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap';
import DatePicker from 'reactstrap-date-picker'

import schedulesStore from '../../../stores/schedule-store';

export default class ButtonComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
            series_type: "none",
            selectValue: "blackout",
            startValue: new Date().toISOString(),
            endValue: new Date().toISOString(),
        }
    }

    showDialog = () => {
        let { event } = this.props;
        this.setState({
            isDialogOpen: true,
            startValue: new Date(event.datetime).toISOString(),
            endValue: new Date(event.datetime).toISOString(),
        })
    }
    handleSelect = (e) => {
        let value = e.target.value;
        let series_type, selectValue;
        if (value === 'delete_series') {
            series_type = 'delete'
        }
        else if (value === 'blackout_series') {
            series_type = 'blackout'
        } else {
            series_type = "none";
            selectValue = value;
        }
        this.setState({ series_type, selectValue })
    }
    handleSelectSubmit = () => {
        let { event } = this.props;
        let { series_type, selectValue } = this.state;
        if (series_type === 'none') {
            let eventId = event._id;
            //if single
            if (selectValue === 'delete') {
                // console.log('delete one')
                schedulesStore.deleteSlotById({ eventId })
            }
            else {
                //if blackout
                let isblackout = !event.isBlackedOut;

                let payload = {
                    slotId: eventId,
                    isBlackedOut: isblackout
                }
                schedulesStore.blackOutSlotById(payload);
                // console.log('blockoutone')
            }
        } else {
            // if series
            this.showDialog()
        }
    }
    handleDateChange = (v, type) => {
        if (type === 'start') {
            this.setState({ startValue: v, endValue: v })
        }
        else {
            this.setState({ endValue: v })
        }
    }
    hideDialog = () => {
        this.setState({ isDialogOpen: false })
    }

    submit = () => {
        let { series_type, startValue, endValue } = this.state;
        let { event } = this.props;

        let selectedTime = event.datetime.slice(10, event.datetime.length - 1);

        if (startValue.slice(0, 10) === endValue.slice(0, 10)) {
            // if same day increase endvalue by 1 day
            let d = new Date(endValue);
            d = d.getTime();
            d = d + (24 * 60 * 60 * 1000);
            endValue = new Date(d).toISOString();
        }

        // console.log(this.props);
        // console.log('ButtonCompon: ', type, startValue, endValue, selectedTime, event.eventName);

        let payload = { startValue, endValue, selectedTime, eventName: event.eventName };
        // console.log("payload: ",payload)

        if (series_type === "delete") {
            // console.log('delete series')
            schedulesStore.deleteSeriesByDate(payload)
        }
        else {
            // console.log('blakcout sereis')
            //if blackout
            schedulesStore.blackOutSeriesByDate(payload);
        }
    }

    render() {
        let { event, isCheckBoxShow } = this.props;
        // console.log("🚀 ~ file: ButtonComponents.js ~ line 111 ~ ButtonComponents ~ render ~ isBlackedOut", event.isBlackedOut)

        let { isDialogOpen, series_type, startValue, endValue } = this.state;
        // console.log("🚀 ~ file: ButtonComponents.js ~ line 87 ~ ButtonComponents ~ render ~ series_type", series_type,selectValue)

        return (
            isCheckBoxShow ?
                <div className="col-md-6 col-lg-4 btns-container">
                    <FormGroup>
                        <Label for="select-filter">Select Action</Label>
                        <div className="action-container">
                            <Input type="select" name="select" id="select-filter"
                                onChange={this.handleSelect}>
                                <option value="blackout">{event.isBlackedOut ? 'Undo Blackout' : 'Blackout'}</option>
                                <option value="blackout_series">Blackout Series</option>
                                <option value="delete">Delete</option>
                                <option value="delete_series">Delete Series</option>
                            </Input>
                            <Button className="btn-success" onClick={this.handleSelectSubmit}>Submit</Button>
                        </div>
                    </FormGroup>

                    {isDialogOpen ?
                        <div className="dialog-container">
                            <h5>{series_type === 'delete' ? 'Delete' : 'BlackOut'} Series</h5>
                            <FormGroup>
                                <Label>Start Date</Label>
                                <DatePicker id="example-datepicker"
                                    value={startValue}
                                    onChange={(v) => this.handleDateChange(v, 'start')} />
                            </FormGroup>
                            <FormGroup>
                                <Label>End Date</Label>
                                <DatePicker id="example-datepicker"
                                    value={endValue}
                                    onChange={(v) => this.handleDateChange(v, 'end')}
                                />
                            </FormGroup>
                            <button className="btn btn-primary" onClick={this.submit}>Submit</button>
                            <button className="btn btn-danger" onClick={this.hideDialog}>Cancel</button>
                        </div>
                        : null}
                </div >

                : null
        )
    }
}
