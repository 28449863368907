/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import loadsStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';
import ReactDOM from 'react-dom';
import { addCompletedJumpers } from '../../../actions/updateCompletedJump'
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'

class EditableAltitude extends Component {

  constructor(props) {
    super(props);
    this.setEditable = this.setEditable.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      editMode: false,
      load: props.load,
      altitudes: []
    };
  }

  setEditable() {
    this.setState({
      editMode: !this.state.editMode
    });
  }



  handleSubmit(loadNum, jumpId, jumperId) {
    this.setEditable();
    // console.log(loadNum + " jumpId: " + jumpId + " jumperId: " + jumperId);
    loadsStore.removeJumper(loadNum, jumpId, jumperId);
  }
  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    })
  }
  changeJumper = (isCompleted) => {
    if (isCompleted) {
      // this.props.openModel({ isModal: true, editType: false })
      this.setState({
        isModalOpen: true
      })
    }
    else {
      this.setState({
        editMode: !this.state.editMode
      })
    }
  }
  updateJumpType = (value, loadNum, jumpId) => {
    if (value !== "Select..") {
      var jumpBody = {
        jumpType: value
      }
      var that = this;
      var type = value;
      if (this.props.isCompleted) {
        const jumpersIds = this.props.funJump.jumpers.map(index => {
          return index._id
        })
        var jumpPostBody = {
          jumperIds: jumpersIds, //value.map(jumperOption => jumperOption.value._id),
          jumpType: type,
          gearRentals: this.props.funJump.gearRentals
        };
        if (this.props.isToday) {
          jumpPostBody.isToday = true
        }
        var jumpId = this.props.funJump.jumpId;
        var loadId = this.props.loadId;
        this.setState({
          editMode: !this.state.editMode
        });
        this.props.addCompletedJumpers(loadId, jumpId, jumpPostBody);
      }
      else {
        Promise.all([loadsStore.updateFunJump(loadNum, jumpId, jumpBody)])
          .then(function (result) {
            var data = result[0];
            if (data === true) {
              that.setState({ editMode: !that.state.editMode });
            }
            else {
              that.setState({ editMode: !that.state.editMode });
            }
          })
      }
    }
  };
  componentDidMount() {
    var altitudes = itemStore.getAltitudes();
    this.setState({ altitudes: altitudes });
    document.addEventListener('click', this.handleClickOutside, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        editMode: false
      });
    }
  }

  renderJumpSize = () => {
    if (this.props.jumpType === "Belly" || this.props.jumpType === "Freefly" || this.props.jumpType === "Wingsuit" || this.props.jumpType === "Tracking") {
      if (this.props.funJump.jumpers.length == 1) {
        return "Solo";
      }
      else {
        return this.props.funJump.jumpers.length + "-way";
      }
    }
    return null;
  };
  setAltitude = (loadNum, jumpId, altitude, superType) => {
    console.log('in set altitude');
    if (superType === "funJump") {
      loadsStore.updateFunJump(loadNum, jumpId, { altitude: altitude });
    }
    else if (superType === "tandem") {
      loadsStore.updateTandemJump(loadNum, jumpId, { altitude: altitude })
    }
    else if (superType === "student") {
      loadsStore.updateAffJump(loadNum, jumpId, { altitude: altitude })
    }
    else if (superType === "coach") {
      loadsStore.updateCoachJump(loadNum, jumpId, { altitude: altitude })
    }
    else {
      loadsStore.updateFunJump(loadNum, jumpId, { altitude: altitude });
    }
    this.setState({ editMode: false })
  }
  render() {
    var altitudeMenu = this.state.altitudes.map(alt =>
      <a className="dropdown-item" onClick={() => { this.setAltitude(this.props.loadNum, this.props.jumpId, alt.name, this.props.superType) }} >{alt.name}</a>
    )

    return (
      <React.Fragment>
        {
          !this.state.editMode ?
            // <div className="jumpType-button" onClick={()=>this.changeJumper(this.props.isCompleted)} style={{ padding: '0 1 0 4px' }}>
            //   {this.renderJumpSize()} {this.props.jumpType}
            // </div>
            <div className="funjumpType-button" onClick={() => !this.props.isCompleted && this.changeJumper(this.props.isCompleted)} style={{ padding: '0 1 0 4px' }}>
              {this.props.altitude}
            </div>
            :
            <div className="dropdown-menu1" onBlur={() => { this.setState({ editMode: true }) }}>
              <a className="dropdown-item">Select..</a>
              {altitudeMenu}
            </div>
        }
        <LoadUpdateModal
          isOpen={this.state.isModalOpen}
          onClick={this.wantsEidtable}
        />
      </React.Fragment>
    );
  }
}

const mstp = state => {
  return {
    state
  }
}
const mdtp = dispatch => {
  return {
    addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
  }
}

//  export default EditableFunJumper;
export default connect(mstp, mdtp)(EditableAltitude)


