
import { Table } from 'reactstrap'
import RenderConditions from './renderConditions'


const TermsConditionsTable = () => {

    return (
        <div className="terms_conditions_table_main">

            <Table className="terms_conditions_table">
                <thead className="terms_conditions_heading">
                    <tr>
                        <th>Terms & Conditions</th>
                    </tr>
                </thead>
                <tbody className="terms_conditions_body">
                    <RenderConditions />
                </tbody>
            </Table>
        </div>
    )
}

export default TermsConditionsTable;