/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import jumpersStore from '../../../stores/jumpers-store';

import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

import loadsStore from '../../../stores/loads-store';
import JmpModal from './customModal';
import Fuse from "fuse.js";
import ReactDOM from 'react-dom';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';


const customStyles = {
    control: base => ({
        ...base,
        minHeight: 30
    }),
    menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
        height: 730
    }),
    menuList: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        padding: 20,
        minHeight: 700
    })
};

class AddJumpers extends Component {

    constructor(props) {

        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTransferCredit = this.handleTransferCredit.bind(this);
        this.toggleError = this.toggleError.bind(this);
        // this.updateGear = this.updateGear.bind(this);
        // this.MultiValueLabel = this.MultiValueLabel.bind(this);

        this.state = {
            isModalOpen: false,
            isConfirmModalOpen: false,
            jumperOptions: [],
            tandemOptions: [],
            adminDefaultOptions: [],
            ErrorMessage: '',
            placement: 'bottom',
            errors: {},
            fromId: '',
            toId: '',
            isToTandem: false,
            isFromTandem: false,
            isloading: false,
            errorOpen: false,
            transferValue: 0,
            transferTaxValue: 0
        };

        this.handleJumpersLoaded = this.handleJumpersLoaded.bind(this);

        this.jumperEvents = [
            { name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded },
            { name: jumpersStore.getEventPrefix('creditTransferEvent'), func: this.handleTransferCredit }
        ];
    }


    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            isToTandem: false,
            isFromTandem: false
        });
        // this.gearIds.length = 0;
    }
    toggleConfirmModal = () => {
        this.setState({
            isConfirmModalOpen: !this.state.isConfirmModalOpen,
        });
    }

    handleSubmit = (newValue) => {
        var payLoad = {}
        console.log(this.state);
        payLoad.mergeToId = this.state.toId;

        console.log(payLoad);
        jumpersStore.mergeJumpers(this.props.jumperId, payLoad);
        this.props.closeModal();

    };

    componentDidMount = () => {
        //console.log('in components did mount accounts');
        this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));

        var options = jumpersStore.getJumperOptions().filter(option => option?.value?._id.toString() !== this.props.jumperId?.toString());

        var tandemOptions = jumpersStore.getTandemStudentOptions().filter(option => option.value.toString() !== this.props.jumperId.toString());
        console.log('in component will receive props');
        var toId = '';
        if (this.props.tandemInstructorId !== undefined) {
            toId = this.props.tandemInstructorId;
            this.setState({
                fromId: this.props.jumperId,
                toId: toId
            })
        }
        this.setState({ jumperOptions: options, tandemOptions: tandemOptions, adminDefaultOptions: options.slice(0, 50) });
    }

    componentWillUnmount = () => {
        this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
    }

    /*
    componentWillReceiveProps()
    {
      console.log('in component will receive props');
        var toId = '';
        if(this.props.tandemInstructorId !== undefined){
            toId = this.props.tandemInstructorId;
        this.setState({
            fromId: this.props.jumperId,
            toId: toId
        })
        }

    }*/

    filterJumpers = (inputValue, jumperOptions) => {
        //console.log("in filterJumpers");
        if (inputValue) {
            //console.log(inputValue);
            var filteredOptions = jumperOptions.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase()));
            ////console.log(filteredOptions);
            if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
            else return filteredOptions;
        }
        //console.log('no inputValue');
        return jumperOptions.slice(0, 30);
    };

    promiseOptions = (inputValue, jumperOptions) => {
        //console.log('in pomiseOptions');
        //console.log(this.inputValue);
        return new Promise(resolve => {
            resolve(this.filterJumpers(this.inputValue, jumperOptions));
        });
    }

    handleInputChange = (newValue) => {
        //console.log('handleInputChange');

        //const inputValue = newValue.replace(/\W/g, '');
        ////console.log(inputValue);

        //this.promiseOptions(newValue, jumperOptions);
        this.inputValue = newValue;
        //return inputValue;
    };

    handleFromSelect = (val) => {
        //console.log('in handleSelect');
        if (val.value !== undefined) {
            ////alert(JSON.stringify(val.value));
            // this.onJumperChanged(val.value);
            // jumpersStore.getDailyActivity(val.value._id);
            this.setState({
                fromId: val.value._id
            });
            //console.log(JSON.stringify(val.value));
        }
    }

    handleToSelect = (val) => {
        console.log('in handleSelect');
        console.log(val);
        if (val.value !== undefined) {
            ////alert(JSON.stringify(val.value));
            // this.onJumperChanged(val.value);
            // jumpersStore.getDailyActivity(val.value._id);
            var transferValue = jumpersStore.getDailyActivityFrontEnd(val.value._id);
            var stateUpdate = {
                toId: val.value._id,
            }
            var transferTaxValue = 0;
            if (loadsStore.isTaxEnabled()) {
                transferTaxValue = jumpersStore.getDailyActivityTaxFrontEnd(val.value._id);
                stateUpdate = {
                    toId: val.value._id
                }
            }
            console.log(stateUpdate);
            this.setState(stateUpdate, () => {
                console.log(this.state);
            });
            //console.log(JSON.stringify(val.value));
        }
    }

    handleToTandemSelect = (val) => {
        console.log('in handleTo Select Merge');
        console.log(val);
        if (val.value !== undefined) {
            // alert(JSON.stringify(val.value));
            // this.onJumperChanged(val.value);
            // jumpersStore.getDailyActivity(val.value._id);
            var transferValue = jumpersStore.getTandemDailyActivityFrontEnd(val.value);
            var stateUpdate = {
                toId: val.value,
            }
            var transferTaxValue = 0;
            if (loadsStore.isTaxEnabled()) {
                transferTaxValue = jumpersStore.getTandemDailyActivityTaxFrontEnd(val.value);
                stateUpdate = {
                    toId: val.value,
                }
            }

            this.setState(stateUpdate);
            //console.log(JSON.stringify(val.value));
        }
    }

    Option = (props) => {
        //console.log(props);
        return (
            <div className="row">
                <div className="col-12">
                    <components.Option {...props} />
                </div>
            </div>
        );
    }

    handleJumpersLoaded(jumperOptions) {
        //console.log('in handle jumpers loaded in accounts');
        var adminDefaultOptions = jumperOptions.slice(0, 50);
        //alert('handle Jumpers Loaded');
        this.setState({
            jumperOptions: jumperOptions,
            adminDefaultOptions: adminDefaultOptions
        });
    }

    toggleError() {
        this.setState({
            errorOpen: !this.state.errorOpen
        });
    }

    handleChange = (e) => {
        this.setState({
            transferValue: e.target.value
        })
    }

    handleTaxChange = (e) => {
        this.setState({
            transferTaxValue: e.target.value
        })
    }

    handleTransferCredit(data) {
        if (data === true) {
            this.setState({
                isloading: false,
                isModalOpen: false
            })
        }
        else {
            this.setState({
                isloading: false,
                isModalOpen: false,
                errorOpen: true
            })
            //console.log('fail');
        }
    }

    handleInput = (event, key) => {
        var cache = { ...this.state };
        cache[key] = event.target.value;
        this.setState({ ...cache }, () => {
            this.handleValidation(key);
        });
        // if (!this.state.errors.length)
        //     tandemData.updateFormWithIndex(cache, this.props.index - 1);
    };

    handleValidation(name) {
        let fields = this.state;
        let errors = fields.errors;
        if (fields[name] === '') errors[name] = "This field is required";
        else if (name === 'amount' && !fields[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Amount should be enter in two decimal places.";
        else errors[name] = "";
        this.setState({ errors: errors });
    }



    render() {
        var screenWidth = window.innerWidth;
        var defaultValue = '';
        if (this.state.fromId && this.state.jumperOptions.length > 0 && !this.props.isTandemStudent) {
            var defaultValue = this.state.jumperOptions.filter(x => x.value._id === this.state.fromId)[0].label;
        }

        var defaultTandemInstructor = '';



        if (this.props.isTandemStudent && this.state.jumperOptions.length > 0 && this.props.tandemInstructorId !== '') {
            console.log(this.props.tandemInstructorId);
            console.log(this.state.jumperOptions.map(option => option._id));
            var defaultTandemInstructor = this.state.jumperOptions.filter(x => x.value._id.toString() === this.props.tandemInstructorId.toString())[0].label;
        }

        var modalMargin = '25%';
        if (screenWidth >= 1920) {
            modalMargin = '65%';
        }

        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
        let fromJumper = jumpersStore.getJumperById(this.props.jumperId);
        let toJumper = jumpersStore.getJumperById(this.state.toId);
        return (
            <React.Fragment>
                <JmpModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Merge Jumpers`}
                >
                    <div className='complete-loading'>
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={35}
                            color={'#fff'}
                            loading={this.state.isloading}
                        />
                    </div>
                    <LocalForm >


                        <Row className="form-group">
                            <Label htmlFor="To" md={3}>jumper to merge:</Label>
                            <Col md={9}>
                                <AsyncSelect loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
                                    styles={customStyles} onInputChange={(val) => this.handleInputChange(val)}
                                    defaultOptions={this.state.adminDefaultOptions} onChange={(val) => this.handleToSelect(val)} components={{ Option: this.Option }}
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" onClick={(e) => { e.preventDefault(); this.toggleConfirmModal(); }} >Submit</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">Cancel</button>
                            </Col>
                        </Row>

                    </LocalForm>
                </JmpModal>
                <Modal isOpen={this.state.errorOpen} toggle={this.toggleError} className={this.props.className}>
                    <ModalHeader toggle={this.toggleError} className="error-jumperHeader">
                        Error
                    </ModalHeader>
                    <ModalBody>
                        <p style={{ color: 'red', fontSize: '20px', marginLeft: '1%' }}>Something Wrong happened while Transfer Credits !</p>
                    </ModalBody>
                </Modal>
                <JmpModal
                    isModalOpen={this.state.isConfirmModalOpen}
                    title={`WARNING: MERGING JUMPERS IS IRREVERSIBLE`}
                >
                    <p>
                        Merging <strong>{fromJumper?.firstname + " " + fromJumper?.lastname + " " + fromJumper?.manifestNumber}</strong> with <strong>{toJumper?.firstname + " " + toJumper?.lastname + " " + toJumper?.manifestNumber}</strong>
                    </p>
                    <p>
                        are you sure you want to continue?
                    </p>
                    <Row className="form-group m-t-1-rem">
                        <Col md={{ size: 10 }}>
                            <button className="btn btn-bg lightColor createButton" type="submit" onClick={(values) => { this.handleSubmit(values); this.toggleConfirmModal() }} >MERGE</button>
                            <button onClick={(e) => { e.preventDefault(); this.toggleConfirmModal() }} className="btn btn-link">CANCEL</button>
                        </Col>
                    </Row>
                </JmpModal>
                <button className="btn transferButton rightButton" style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764' }} onClick={this.toggleModal} type="submit" disabled={this.props.disabled}>
                    Merge Duplicates
                </button>
                {/* <button className="btn customButton rightButton" style={{ borderRadius: '4px',backgroundColor: '#A7F6FF',color: '#082764',marginTop:'65%',marginBottom:'8px' }} onClick={this.toggleModal}>
                    TRANSFER CREDIT
                </button> */}
            </React.Fragment>
        );
    }
}

export default AddJumpers
