import { Component, Fragment } from 'react'
import { Control, LocalForm } from 'react-redux-form';
import { Col, Row, Label } from 'reactstrap'

import CustomModal from '../../Common/CustomModal';

class CreateEvent extends Component {
    state = {
        isModalOpen: false,
        loading: false,
        errorMsg: false
    }

    categoryArray = [
        { id: 1, label: 'Tandem Package', value: 'Tandem' },
        { id: 2, label: 'Aff Package', value: 'AFF' },
        { id: 3, label: 'Fun jumps', value: 'FunJump' }
    ]

    toggleModal = (e) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            errorMsg: false
        });
    }

    handleCancel = () => {
        this.setState({
            loading: false,
        })
        this.toggleModal();
    }

    handleSubmit = (values, type, originalEventName) => {
        console.log(values, "<======values")
        if (!values.eventName || !values.packageCategory || values.packageCategory === 'none') {
            this.setState({ errorMsg: true, loading: false })
            return
        }
        
        let payload = {
            eventName: values.eventName.trim(),
            packageCategory: values.packageCategory,
            depositAmount: Number(values.depositAmount),
            originalEventName       
        }

        if (type === 'edit') payload._id = this.props.data._id
        this.props.addEditEvent(type, payload)
        if (!this.props.loading) {
            this.setState({ isModalOpen: false })
        }
    }

    render() {
        let item = {};
        const { type, data } = this.props
        const { loading, errorMsg } = this.state
        if (data !== undefined) {
            item = { ...data };
        }
        return (
            <Fragment>
                <CustomModal
                    isModalOpen={this.state.isModalOpen}
                    title={type === 'edit' ? "Edit Calender event" : "Create Calender event"}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values, type, item.eventName)}>
                        <Row className="form-group">
                            <Label htmlFor="eventName" md={3}>Event Name</Label>
                            <Col md={9}>
                                {console.log(this.props.priority, this.props.priority2, "<===========")}
                                <Control.input
                                    name="eventName"
                                    model=".eventName"
                                    className="form-control"
                                    disabled={(this.props.priority || this.props.priority2) ? true : false}
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item?.eventName || ''} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="packageCategory" md={3}>Category</Label>
                            <Col md={9}>
                                <Control.select onChange={() => this.setState({ errorMsg: false })}
                                    name="packageCategory" model=".packageCategory" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    autoComplete="off"
                                    disabled={(this.props.priority || this.props.priority2) ? true : false}
                                    defaultValue={item.packageCategory || ''}
                                >
                                    <option value="none">none</option>
                                    {this.categoryArray.map((pkg, idx) => <option key={idx} value={pkg.value}>{pkg.label}</option>)}
                                </Control.select>
                            </Col>
                        </Row>


                        <Row className="form-group">
                            <Label htmlFor="depositAmount" md={3}>Deposit Amount</Label>
                            <Col md={9}>
                                <Control.input
                                    name="depositAmount"
                                    model=".depositAmount"
                                    className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item?.depositAmount || ""} autoComplete="off" />
                            </Col>
                        </Row>


                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button disabled={loading} className="btn btn-bg lightColor createButton" type="submit" >
                                    SUBMIT
                                </button>
                                <button disabled={loading}
                                    onClick={this.handleCancel}
                                    className="btn btn-link"
                                    type="button"
                                >
                                    CANCEL
                                </button>
                            </Col>
                        </Row>
                        {
                            errorMsg && <div>
                                <Label htmlFor="description" md={3} />
                                <Label md={9} style={{ color: '#FF9494' }}>
                                    please fill all the details
                                </Label></div>

                        }
                    </LocalForm>
                </CustomModal>
                {
                    type === 'add' ?
                        <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                            <i className="fa fa-plus"></i>
                        </button>
                        :
                        <span onClick={this.toggleModal}>
                            <i className="fa fa-edit"></i>
                        </span>
                }
            </Fragment>
        )
    }
}
export default CreateEvent