import { create } from 'apisauce';
import authStore from '../stores/auth-store';
import urlgetter from './getMainUrl';

// define the api

let payAPI, payTransAPI

const mainAPI = create({
	baseURL: urlgetter.getMainApiUrl()
});

const func = {};

func.setApi = () => {
	if(window.ENV?.processorInfo){
		payAPI = create({
			baseURL: window.ENV?.processorInfo.paymentsApiUrl
		});
		
		payTransAPI = create({
			baseURL: window.ENV?.processorInfo.apiUrl
		});
	}
}

func.GetTimeslots = (date) => {
	return mainAPI.get(`/timeslots/?day=${date}`);
};

func.getMonthlySlots = (date) => {
	console.log('getMonthlySlots reservatonsapi');

	return mainAPI.get(`/timeslots/?startDay=${date.start}&endDay=${date.end}`)
};

func.getIdentityInfo = () => {
	return mainAPI.get('/processorInfo');
};

func.generateReservationPdf = (date) => {
	mainAPI.setHeaders({
		'Accept': 'application/pdf',
	});
	return mainAPI.get(`/reservations/generatePdf/?day=${date}`, {}, {
		responseType: 'blob', 
	});
}


func.getsuspendedreservation = (id) => {
	// mainAPI.setHeaders({
	// 	'Accept': 'application/pdf',
	// });
	return mainAPI.get(`/reservations/getsuspendedreservation`);
}


// func.startSession = (identityToken) => {
// 	// console.log(identityToken);

// 	payAPI.setHeaders({
// 		Authorization: 'Basic ' + Buffer.from(identityToken + ':test').toString('base64'),
// 		Accept: 'application/json'
// 	});

// 	return payAPI.get('/SvcInfo/token');
// };

func.startSession = () => {
	mainAPI.setHeaders({
		Accept: 'application/json',
		"Content-Type": 'application/json'
	});
	return mainAPI.get(`nabProxy/proxy/token`);
};

func.getApplicationData = (sessionToken, profileId) => {

	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.get(`/SvcInfo/appProfile/${profileId}`);
}

func.saveApplicationData = (sessionToken, applicationData) => {

	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.put('/SvcInfo/appProfile', applicationData);
}

func.generateReservationPdf = (date) => {
	mainAPI.setHeaders({
		'Accept': 'application/pdf',
	});
	return mainAPI.get(`/reservations/generatePdf/?day=${date}`, {}, {
		responseType: 'blob',
	});
}

func.getServiceInformation = (sessionToken) => {
	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.get('/SvcInfo/serviceInformation');
}

func.isMerchantProfileInitialized = (sessionToken, merchantProfileId, serviceId) => {
	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.get(`/SvcInfo/merchProfile/${merchantProfileId}/OK?serviceId=${serviceId}`);
}

func.authorizeAndCapture = (type, body) => {
	// console.log('in authorize and capture');
	mainAPI.setHeaders({
		Accept: 'application/json',
		"Content-Type": 'application/json'
	});
	return mainAPI.post(`nabProxy/proxy/Txn/${type}`, body);
}

func.transnationalTransaction = (body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	mainAPI.setHeader('Authorization', 'Bearer ' + token)
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`transnational/proxy/transaction`, body);
}

func.transGetTransaction = (body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	mainAPI.setHeaders({
		"Content-Type": 'application/json',
		'Authorization': 'Bearer ' + token
	})
	return mainAPI.post(`transnational/proxy/transaction/search`, body);
}

func.transAuthorizeAndCaptureRefund = (transactionId, body) => {
	// console.log('in authorize and capture');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	mainAPI.setHeaders({
		"Content-Type": 'application/json',
		'Authorization': 'Bearer ' + token
	})
	return mainAPI.post(`transnational/proxy/transaction/${transactionId}/refund`, body);
}

func.transAuthorizeAndCaptureRefundRes = (transactionId, body) => {
	// console.log('in authorize and capture');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	mainAPI.setHeaders({
		"Content-Type": 'application/json',
		'Authorization': 'Bearer ' + token
	})
	return mainAPI.post(`transnational/proxy/transaction/${transactionId}/refundRes`, body);
}

func.squareAuthorizeAndCaptureRefundRes = (transactionId, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	mainAPI.setHeaders({
		"Content-Type": 'application/json',
		'Authorization': 'Bearer ' + token
	})
	return mainAPI.post(`oauthToken/square/${transactionId}/refundRes`, body);
}

func.transAuthorizeAndCaptureVoid = (transactionId, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	mainAPI.setHeaders({
		"Content-Type": 'application/json',
		'Authorization': 'Bearer ' + token
	})
	return mainAPI.post(`transnational/proxy/transaction/${transactionId}/void`, body);
}

func.cancelAuthorizeAndCapture = (body) => {
	// console.log('in authorize and capture');
	mainAPI.setHeaders({
		Accept: 'application/json',
		"Content-Type": 'application/json'
	});
	return mainAPI.put(`nabProxy/proxy/Txn`, body);
}


func.getReservation = (id) => {
	return mainAPI.get('/reservations/' + id);
}


func.cardConnetcAuthTransaction = (body) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`cardConnectProxy/proxy/auth`, body);
}

func.cardConnetcCaptureTransaction = (body) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`cardConnectProxy/proxy/capture`, body);
}

func.cardConnetcGetTransaction = (transactionId) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.get(`cardConnectProxy/proxy/inquire/${transactionId}`);
}

func.cardConnetVoidTransaction = (payload) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`cardConnectProxy/proxy/void`, payload);
}

func.cardConnetRefundTransaction = (payload) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`cardConnectProxy/proxy/refund`, payload);
}

func.makeReservation = (reservationBody) => {
	// console.log("in make Reservation Activity");
	return mainAPI.post('/reservations', reservationBody);
}

func.cancelReservation = (reservationId, payload) => {
	// console.log('in CancelReservation');
	return mainAPI.put('/reservations/' + reservationId + '/cancel', payload);
}

func.changeReservation = (reservationBody) => {
	// console.log('in changeReservation');
	return mainAPI.put('/reservations/' + reservationBody.id + '/changeReservation', reservationBody);
}

func.sendEmail = (body) => {
	return mainAPI.post(`/sendEmail`, body);
}

func.UpdateGiftCard = (body) => {
	console.log("in Update Gift Card");
	return mainAPI.put('/giftcards', body);
}

func.verifyGiftCard = (cardBody) => {
	console.log("in make Reservation Activity");
	return mainAPI.post('/giftcards/verify', cardBody);
}

func.getAllGrouponPackages = () => {
	return mainAPI.get('/groupon');
}

func.createGroupon = (payload) => {
	return mainAPI.post('/groupon', payload);
}

func.createPermanentForteToken = (payload) => {
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`forteAchProxy/proxy/paymentmethods`, payload);
}

func.createCustomerToken = (payload) => {
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`forteAchProxy/proxy/customer`, payload);
}

func.ForteAchTransaction = (payload) => {
	
	var token = authStore.getAuthToken();
	
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}

	mainAPI.setHeaders({
		"Content-Type": 'application/json',
		'Authorization': 'Bearer ' + token
	})

	return mainAPI.post(`forteAchProxy/proxy/transaction`, payload);
}

func.ForteAchVoidTransaction = (transactionId, payload) => {
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.put(`forteAchProxy/proxy/transaction/${transactionId}`, payload);
}

func.addReservationMemo = (slotId, payload) => {
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`/reservations/reservationMemo/${slotId}`, payload);
}

func.updateReservationMemo = (slotId, payload) => {
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.put(`/reservations/reservationMemo/${slotId}`, payload);
}


func.sendConfirmationEmail = (payload) => {
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`/reservations/sendEmail`, payload);
}

func.sendIsSuspended = (payload) => {
	console.log(payload,"=->>>payload")
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`/reservations/sendIsSuspended`, payload);
}

func.sendConfirmationEmailWithTemplate = (payload) => {
	console.log(payload,'payloadpayloadpayloadpayloadpayload')
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`/reservations/sendEmailWithTemplate`, payload);
}

func.sendDefaultConfirmationEmail = (payload) => {
	console.log(payload,'payloadpayloadpayloadpayloadpayload')
	mainAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return mainAPI.post(`/reservations/sendDefaultReminderEmail`, payload);
}


export default func;
