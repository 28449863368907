import React, { Component } from 'react'

class SmallModal extends Component {

    showActive = () => {
        let { isModalOpen } = this.props;
        if (isModalOpen) {
            return 'small-modal active';
        }
        else {
            return 'small-modal'
        }
    }
    render() {

        return (
            <div className={this.showActive()}>
                {this.props.children}
            </div>
        )
    }
}
export default SmallModal;