import React, { Component } from 'react';
import tandemStore from '../../../stores/reservation-store';
import schedulesStore from '../../../stores/schedule-store';
import reportStore from '../../../stores/report-store';
import jumpersStore from '../../../stores/jumpers-store';
import CreditCard from './creditCard';
import { ScaleLoader } from 'react-spinners';

class Invoice extends Component {
  constructor(props) {
    super(props);

    var priceSchedule = this.props.priceArray;
    var tandemWeekend = this.getPrice(priceSchedule, "tandem weekend");
    var tandemWeekday = this.getPrice(priceSchedule, "tandem weekday");
    var video = this.getPrice(priceSchedule, "video");
    var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
    this.taxPerc = parseInt(scheduleFee);

    if (this.props.eventName.trim() == 'Tandem') {
      this.tandemUnitCost = this.props.isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }
    // else if(this.props.eventName.trim() == 'Tandem')
    // {
    //    this.tandemUnitCost = parseInt(tandemWeekend);
    // }
    this.tandemVideoCost = parseInt(video);
    this.state = {
      passengers: this.props.data.passengers || 0,
      ticketCount: this.props.data.ticketCount || 0,
      videoCount: this.props.data.videoCount || 0,
      slotId: this.props.data.slotId || 0,
      payload: [],
      isloading: false
    };
  }

  getPrice(priceSchedule, type) {
    var index = priceSchedule.findIndex(x => x.type == type);
    var price = priceSchedule[index].price;
    return price;
  }

  calculateTax() {
    var subTotal =
      this.tandemUnitCost * this.state.ticketCount +
      this.state.videoCount * this.tandemVideoCost;
    return (this.taxPerc / 100) * subTotal;
  }

  calculateTotal() {
    var subTotal =
      this.tandemUnitCost * this.state.ticketCount +
      this.state.videoCount * this.tandemVideoCost;
    var payload = [];
    var cost = subTotal + this.calculateTax();

    let totalCost = parseInt(cost)
    return cost;
  }

  handlePassangers() {
    this.setState({
      isloading: true
    })
    var payload = [];
    var user = this.state.passengers;
    let videosCount = parseInt(this.state.videoCount);
    var passangerBody = [];

    //calculate cost.
    var subTotal =
      this.tandemUnitCost * this.state.ticketCount +
      this.state.videoCount * this.tandemVideoCost;
    var payload = [];
    var totalTax = this.calculateTax();
    var cost = subTotal + totalTax;
    let totalCost = parseInt(cost)

    this.state.passengers.forEach(element => {
      if (element.video === true) {
        element.totalPaid = this.tandemUnitCost + (this.state.videoCount * this.tandemVideoCost);
      }
      else {
        element.totalPaid = this.tandemUnitCost;
      }
      passangerBody.push(element);
    })

    var payload = {
      passengers: passangerBody,
      ticketCount: this.state.ticketCount,
      videoCount: this.state.videoCount,
      slotId: this.state.slotId,
      deposit: false,
      amount: totalCost,
      totalFees: totalTax,
      transactionId: 'Transaction123',
      eventName: this.props.eventName
    };

    tandemStore.MakeReservation(payload)
      .then(reservation => {
        this.setState({
          isloading: false
        })
        return jumpersStore.loadTandemStudents();
      })
      .then(loaded => {
        this.setState({
          isloading: false
        })
        return jumpersStore.sendTandemsToPublicJumper();
      })
      .then(loaded => {
      })
      .catch(err => console.log(err));

  }


  handleCancel() {
    schedulesStore.closeReservation();
  }


  render() {
    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <div className="row justify-content-center" style={{ marginTop: '-50px' }}>

        {/* <div className='sweet-loading' style={{zIndex:99,position:'relative',top:'400px',right:'200px'}}>
          <ScaleLoader
            height={35}
            width={8}
            margin={'2px'}
            radius={2}
            color={'#123abc'}
            loading={this.state.isloading}
          />
        </div> */}

        {/* <h4
          className="col-md-12 text-center"
          style={{
            fontWeight: 400,
            letterSpacing: 1,
            backgroundColor: '#00419d',
            color: 'white',
            paddingTop: 30,
            paddingBottom: 30
          }}
        >
          SCHEDULE YOUR JUMP
        </h4> */}
        <div className="col-md-12">

          {/* <button onClick={this.props.goBack} className="checkoutButton subscribe btn btn-primary btn-block" type="button"> BACK </button> */}

          <div className="card cardOverRide">
            <div className="card-header">ORDER SUMMARY</div>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Count</th>
                  <th>Price</th>
                  {/* <th>Tax</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tandem</td>
                  <td>{this.state.ticketCount}</td>
                  <td>${(this.tandemUnitCost * this.state.ticketCount).toFixed(2)}</td>

                </tr>
                <tr>
                  <td>Video</td>
                  <td>{this.state.videoCount}</td>
                  <td>${(this.state.videoCount * this.tandemVideoCost).toFixed(2)}</td>
                  {/* <td></td> */}
                </tr>
                <tr>
                  <td>Fees</td>
                  <td></td>
                  <td>${this.calculateTax().toFixed(2)}</td>
                  {/* <td></td> */}
                </tr>
                <tr>
                  <td>TOTAL :</td>
                  <td></td>
                  <td>${this.calculateTotal().toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-6">
            <button
              style={{width:'150px'}}
              className="checkoutButton btn btn-primary btn-lg"
              onClick={() => { this.handlePassangers(this) }}
            >
              Done
          </button>
          </div>
          <div className="col-md-6">
            <button
              style={{width:'150px'}}
              className="checkoutButton btn btn-primary btn-lg"
              onClick={() => { this.handleCancel() }}
            >
              Cancel
          </button>
          </div>
        </div> */}

        <div style={{ margin: 'auto' }}>
          {this.props.giftCard
            ?
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 20, marginLeft: '26%' }}>
                <span className="customHeading" style={{ marginLeft: '6px' }}>
                  ENTER CODE
                </span>
                <br />
                <input
                  style={{ maxWidth: '9em', display: 'inline-block' }}
                  onChange={(e) => this.handleCardCode(e)}
                  value={this.state.cardCode}
                  className="form-control col-md-12"
                  placeholder="Gift Card Code"
                  name="cardCode"
                />
                <br />
                {
                  this.state.errorMessage &&
                  <span className="text-danger error-validation ">Invalid Gift Card Code !</span>
                }
              </div>


              <div className="col-md-12">
                <button
                  className="checkoutButton btn btn-primary btn-lg"
                  onClick={this.handleFormData}
                >
                  Reserve Now
                </button>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-md-12">
                <button
                  className="checkoutButton btn btn-primary btn-lg"
                  onClick={this.handleFormData}
                >
                  Pay Now
                </button>
              </div>
            </div>
          }
        </div>

      </div>
    );
  }
}

export default Invoice;
