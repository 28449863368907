import React, { useState,useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row,} from 'reactstrap';
import { Table } from 'reactstrap';
import Api from '../../services/reservationapi';
import { slideDown, slideUp } from '../animation/anim';
import reservationStore from '../../stores/reservation-store';
import RightColumnCards from '../../components/sub-components/cards/rightColumnCards';
// import { SendEmailSelect } from "../schedules/schedulesCenter";
import "./suspendreservation.css";
import Send_Multiple_ReminderEmails from './sendMultipleReminderEmail';
import schedulesStore from '../../stores/schedule-store';
import ChangeSlot from './reservation/changeSlot'
import moment from 'moment';

const SuspendReservation = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [expanded, setExpanded] = useState(false);
    const [rowIndex,setrowIndex] = useState (-1);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const[currentSelectedDate , setcurrentSelectedDate]=useState(false);
    const [isSendEmailModalOpen, setisSendEmailModalOpen]=useState(false);
    const [isloading, setisloading]=useState(false);
    const[state,setState]=useState({
      isSendEmailModalOpen: false,
      showModal: false,
        passengers: [],
        date: new Date(),
        slotDates: [],
        allowedDates: [],
        newSlotId: '',
        message: '',
        // oldSlotId: this.props.oldSlotId,
      });
    const [selectedTemplate , setselectedTemplate]=useState("")
    const [singleTimeSlots, setSingleTimeSlots] = useState(null);
    const [ isOpen, setIsOpen]=useState(false);
    const [suspendData,setSuspendData] = useState([])
    const [error ,setError]=useState('')
    

    useEffect(( ) => {
      if(state.isSendEmailModalOpen === false){
        setisSendEmailModalOpen(false)
      }
    }, [state.isSendEmailModalOpen])

    const ToggleExpander = (e,index) =>{
        if (e.target.type === 'checkbox') return;
        setrowIndex(index)

        if (!expanded) {
            this?.setState(
              { expanded: true },
              () => {
                if (this.refs.expanderBody) {
                  slideDown(this.refs.expanderBody);
                }
              }
            );
          } else {
            slideUp(this.refs.expanderBody, {
              onComplete: () => { this?.setState({ expanded: false }); }
            });
          }
    }

   const handleWindowSizeChange = () => {
      setState({ width: window.innerWidth });
    };

 
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    loadEmailsTemplates();

  }, []);



    const getScheduleReservation = () =>{
        Api.getsuspendedreservation().then(res=>{
          console.log(res,"=->>>>>>resres")
            setSuspendData(res.data)
        })
        setIsModalOpen((prev)=> !prev)
      }
     const  bookings = (id, singleTimeSlots , _selectedEmailTemplate) => {
       setisSendEmailModalOpen(true)
       setSingleTimeSlots(singleTimeSlots)
       setState({ selectedTemplate: _selectedEmailTemplate, selectedEventId: id, singleTimeSlots: singleTimeSlots,selectedEmailTemplate: _selectedEmailTemplate });
      };

      const handleCheck = (event, item) => {
        const copySuspendData = [...suspendData]
        setSuspendData(copySuspendData?.map((r) => {
          const res = r?.reservations?.map(i => {
            if(i?.suspended && i?._id === item?._id) {
              return {...i, isChecked: event?.checked}
            }
            return i
          })
          return {...r, reservations: res}
        }))
      }
 
    const handleSuspended = (item, eventId) => {
      console.log(eventId,"eventId334")
      console.log(item,"<====itemmm")
      let payload={
        suspended: !item.suspended,
        id: item._id,
        eventId: eventId,
      }
      reservationStore.sendIsSuspended(payload).then(res => {
        console.log(payload,"payload344545")
        Api.getsuspendedreservation().then(res=>{
            setSuspendData(res.data)
        })
      })
    }

    const allSucessSuspended =(item, eventId)=>{
      let payload={
        suspended: false,
        id: item._id,
        eventId: eventId,
        changeSlot: true
      }
      reservationStore.sendIsSuspended(payload).then(res => {
        console.log(payload,"payload344545")
        Api.getsuspendedreservation().then(res=>{
            setSuspendData(res.data)
        })
      })
    }
      const loadEmailsTemplates = () => {
        console.log("calledschedulesStore");
        schedulesStore
          .getemailtemplates()
          .then((resp) => {
            console.log(resp, "resprespresprespresprespresp");
            setEmailTemplates(resp.data)
          })
          .finally(() => {
            // setIsLoading(false);
          });
      };

      const isAllChecked = React.useMemo(() => {
        let copyData = [...suspendData]
        let suspendItems = copyData.map((items) => {
          return items?.reservations})
          if(suspendItems.filter((i) => i.length > 0)?.flat(5).every(t => t?.isChecked)){
            return true
          }else {
            return false
          }
      }, [suspendData])

      const handleSelectAll = (event) => {
        const copySuspendData = [...suspendData]
        setSuspendData(copySuspendData?.map((r) => {
          const res = r?.reservations?.map(i => {
            if(i?.suspended) {
              return {...i, isChecked: event.target.checked}
            }
            return i
          })
          return {...r, reservations: res}
        }))
      }
      console.log(isOpen,"====>isOpen")
   
  return (
    <>
    <div> 
    {isSendEmailModalOpen && (
          <Send_Multiple_ReminderEmails
          currentSelectedDate={currentSelectedDate}
          isMemoModalOpen={isSendEmailModalOpen}
          selectedTemplate={emailTemplates.find((temp) => temp._id == state.selectedTemplate)}
            state={{
              passengers: suspendData
            }}
            setState={setState}
            isSuspendedData={true}
            // passengers={suspendData}
            // setState={this.setState.bind(this)}
          />
        )}
         <Modal id="modal-report" className="suspend-reservation-list" aria-labelledby="example-custom-modal-styling-title"
          isOpen={isModalOpen} style={{ zIndex: '10503434', right: '28%' }}>
          <ModalHeader tag="div" style={{ position: 'relative', width: '100% !important'}}>
            <div style={{display: "flex",justifyContent: "space-between" }}>
            <div>
            <h5>VIEW SUSPEND RESERVATIONS</h5>
            <button className="close" onClick={ ()=>getScheduleReservation()} 
            style={{ position: 'absolute', left: '25%', top: '14px', color: 'white' }}>x</button>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <SendEmailSelect
                templates={emailTemplates}
                onSelect={(value) => {
                  bookings(undefined, undefined, value);
                }}
              />
              <div style={{paddingLeft: '30px'}}>
                <input 
                type="checkbox" style={{marginRight: '8px'}} onClick={(e) => handleSelectAll(e)} checked={isAllChecked} 
                />Select All
              </div>
              </div>
              </div>
          </ModalHeader>
          <ModalBody>
            {
                <Row className="form-group">
                  <Col md={12}>
                    <Table striped style={{ borderTopStyle: "hidden" }}>
                      <thead>
                        <tr>
                          <th>Date made</th>
                          <th>Reservaton Date</th>
                          <th>Name</th>
                          <th>No. Of Ticket</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Paid</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                          suspendData?.map(suspendReservation => { 
                            console.log(suspendReservation,"suspendReservation54")
                           return suspendReservation?.reservations?.map((item,index)=>{
                            var madeDate = new Date(item.createdAt);
                            var resDate = new Date(item.datetime);
                            return (
                              <tr onClick={(e) => ToggleExpander(e, index)} key={`${item._id}${index}`}>

                                {
                                  item?.tandemStudents?.length > 0 &&
                                  <React.Fragment>
                                    <td>{madeDate.toLocaleDateString()} {madeDate.toLocaleTimeString()}</td>
                                    <td>{resDate.toLocaleDateString()} {resDate.toLocaleTimeString()}</td>
                                    <td>{item.tandemStudents[0].firstname + " " + item.tandemStudents[0].lastname}</td>
                                    <td>{item.tandemStudents.length}</td>
                                    <td>{item.tandemStudents[0].phone}</td>
                                    <td>{item.tandemStudents[0].email}</td>
                                    <td>${item.tandemStudents[0].totalPaid}</td>
                                    <td>
                                      <input type="checkbox"   id={item.tandemStudents[0]._id} name={item.tandemStudents[0]._id} 
                                      onClick={(e) => handleCheck(e.target, item)} 
                                      checked={item.isChecked} 
                                      />
                                      <button style={{ background: "none", border: "none", cursor: 'pointer', color : 'white',marginLeft: "28px"}} onClick={()=>handleSuspended(item, suspendReservation._id)}><i class="fa fa-play" aria-hidden="true"></i></button>
                                      <span style={{marginLeft:"10px"}}> <ChangeSlot
                                       oldSlotId={suspendReservation?._id|| ""} 
                                       reservationId={item?._id || ""} 
                                       currentDate={new moment(new Date()).format("YYYY-MM-DD") || ""} 
                                       eventName={suspendReservation?.eventName || "" }
                                       eventCategory={suspendReservation?.eventCategory ||""} 
                                       suspendReservation={false} 
                                       isUseButton={true}
                                       allSucess ={()=> allSucessSuspended(item, suspendReservation._id)}
                                       
                                       /></span>
                                    </td>
                                  </React.Fragment>
                                }
                                {
                                  item.students.length > 0 &&
                                  <React.Fragment>
                                     <td>{item.students.length}</td>
                                    <td>{item.students[0].firstname + " " + item.students[0].lastname}</td>
                                    <td>{item.students[0].phone}</td>
                                    <td>{item.students[0].email}</td>
                                    <td>${item.students[0].totalPaid ? item.students[0].totalPaid : item.jumpsPrice}</td>
                                    <td>
                                      <input type="checkbox" id={item.students[0]._id} name={item.students[0]._id} 
                                      onChange={(e) => handleCheck(e.target, item)}
                                       checked={item.isChecked} 
                                       />
                                      <button style={{ background: "none", border: "none", cursor: 'pointer', color : 'white',marginLeft: "28px"}} onClick={()=>handleSuspended(item, suspendReservation._id)}><i class="fa fa-play" aria-hidden="true"></i></button>
                                     <span style={{marginLeft:"10px"}}> <ChangeSlot
                                       oldSlotId={suspendReservation?._id || ""} 
                                       reservationId={item?._id || ""} 
                                       currentDate={new moment(new Date()).format("YYYY-MM-DD") || ""} 
                                       eventName={suspendReservation?.eventName || "" }
                                       eventCategory={suspendReservation?.eventCategory || ""} 
                                       suspendReservation={false} 
                                       isUseButton={true}
                                       allSucess = {()=> allSucessSuspended(item, suspendReservation._id)}
                                       /></span>
                                    </td>
                                  </React.Fragment>
                                }
                            </tr>
                              )
                            })  
                          })

                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
            }
          </ModalBody>
        </Modal>
              <button  onClick={()=>getScheduleReservation()}className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                VIEW SUSPENDED RESERVATIONS
              </button>
              {/* <GenerateReservationListList/> */}
              
    </div>
    </>

  )
}

export default SuspendReservation

export const SendEmailSelect = ({ onSelect, templates, asSelect = false }) => {
  return (

    <select
      style={{ width: "210px" }}
      className="form-control"
      onChange={(e) => {
        onSelect(e.target?.value);
      }}
      {...(!asSelect ? { value: "always" } : {})}
    >
      {!asSelect ? <option value="always">Select Email Template</option> : <option value="always">--select template--</option>}
      {!asSelect ? <option value="default-template">Default Reminder Email</option> : <option value="default-template">Default Reminder Email</option>}
      {templates?.map((template) => (
        <option value={template?._id}>{template.name}</option>
      ))}
    </select>
  );
};