import React, { useEffect, useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import itemStore from "../../../stores/item-store";
import { handleAddJumper } from "../../../utils/handleAddJumper";


const AddJumper = ({ isMemoModalOpen, setState, state }) => {

  const [tandemPackages, setTandemPackages] = useState([]);
  const [videoPackages, setVideoPackages] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});

  useEffect(() => {
    let event = state?.event.find(evt => evt._id === state.selectedResEventId);
    setSelectedEvent(event)
    const all_tandem_Packages = itemStore.getTandemPackages();
    const all_video_Packages = itemStore.getVideoPackages();
    const tandem_packages = all_tandem_Packages.filter(item => item.isBookable);
    const video_packages = all_video_Packages.filter(item => item.isBookable);

    setTandemPackages(tandem_packages);
    setVideoPackages(video_packages);
  }, [state])

  return (
    <Modal className='jumper_modal' isOpen={isMemoModalOpen}>
      <ModalHeader className="modal-header" style={{ justifyContent: "center", padding: "1rem 0 1rem 0", width: "100%", color: "#105ec1" }}>Add Jumper
        <div className="closing-modal" onClick={() => setState({ isAddJumperModalOpen: false })}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      </ModalHeader>
      <form onSubmit={(values) => handleAddJumper(values, state, setState, selectedEvent)}>
        <ModalBody className="modal-body" style={{ color: "#105ec1" }}>
          <div className=" jumper_modal_primary">

            <div className="jumper_modal_name">
              <input type="text" className="input_name" placeholder=" First Name*" name="firstName" />
            </div>

            <div className="jumper_modal_name">
              <input type="text" className="input_name" placeholder=" Last Name*" name="lastName" />
            </div>

            {/*
            <div className="jumper_modal_row " >
              <label className=" jumper_checkbox">Jumper is above 18 year old*</label>
              <label className="container">
                <input type="checkbox" name='above18' />
                <span className="checkmark"></span>
              </label>
            </div>

            <div className="jumper_modal_row ">
              <label className=" jumper_checkbox" >Jumper is below  230 lbs*</label>
              <label className="container">
                <input type="checkbox" name="below230" />
                <span className="checkmark"></span>
              </label>
            </div>
            */}

            {selectedEvent?.eventCategory !== "AFF" &&
              <>
                <div className="jumper_modal_row ">
                  <label className="label-tandem" >Select Tandem Option*</label>
                  <select className="select-tandem" name='tandemPackage' >
                    <option value='none'>none</option>
                    {tandemPackages.map(tP => <option value={tP.item}>{tP.item}</option>)}
                  </select>
                </div>

                <div className="jumper_modal_row" style={{ marginBottom: "0px" }}>
                  <label className="label-video ">Select Video Option</label>
                  <select className="select-video" name='videoOption'>
                    <option value='none'>none</option>
                    {videoPackages.map(tP => <option value={tP.item}>{tP.item}</option>)}
                  </select>
                </div></>
            }
          </div>
        </ModalBody>
        <ModalFooter className="jumper_modal_footer">
          <Button className="jumper-add-btn" color="primary" type="submit">Add</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddJumper;