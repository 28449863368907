import React from 'react';

import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { withStyles } from '@material-ui/core/styles';

import {
  TextField,
  Paper,
  MenuItem,
} from '@material-ui/core';
import { styles } from './modalStyle';
import { languages } from '../../../../testData/TestData'
import { getJumperName } from '../../../../actions/reports';

const renderSuggestion = suggestion => (
  <MenuItem>
    {suggestion.name}
  </MenuItem>
);

const getSuggestionValue = suggestion => suggestion.name;

const renderInputComponent = (inputProps) => {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      required
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.inputProps,
        },
      }}
      {...other}
    />
  );
}

class AutoComplete extends React.Component {
  state = {
    open: false,
    value: '',
    suggestions: [],

  };
  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    let { value } = nextProps;
    this.setState({ value });
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : languages.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };


  onChange = (event, { newValue }) => {
    this.props.getJumperName(newValue);
  };


  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };


  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const { classes } = this.props;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: suggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
    };
    const inputProps = {
      classes,
      onChange: this.onChange,
      value,
      placeholder: 'Search a Jumper',
      type: 'search',
    };

    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={inputProps}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <Paper {...containerProps} square>
            {children}
          </Paper>
        )} />
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  getJumperName: (jumperName) => dispatch(getJumperName(jumperName))
});
const mapStateToProps = (state) => ({
  value: state.Reports.jumperName
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AutoComplete));
