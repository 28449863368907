import { EventEmitter } from "events";


import Api from "../services/schedulerapi";
import * as moment from "moment";
import { resolve } from "url";
import swal from 'sweetalert';

class Schedules extends EventEmitter {
  constructor() {
    super();
    this.prefix = "SCHEDULE_STORE_";
  }

  fetchCreateEventDefaults = () => {
    return {
      focused: false, //needed for datepicker
      startDate: null,
      endDate: null,
      eventType: "",
      startTime: "",
      seats: 0,
      reccurence: "",
      days: [],
      eventCategory: '',
      row: [{ startTime: '', seats: 0 }],
      bool: false
    };
  };

  setListeners = (socket) => {
    socket.on('updateReservationInfo', data => {
      //update reservation list
      this.getLatestReservations();
    })
  }
  updateActiveView = e => {
    //console.log("update Active View");
    this.activeView = e.view;
  };

  fetchData = start => {
    var d = new moment(start);
    let startMonth = d
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    let endMonth = d
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");
    var date = {
      start: startMonth,
      end: endMonth
    };

    console.log('schedule store fetching lotta data');
    Api.getMonthlySlots(date).then(r => {
      //console.log(r);
      this.emitResults(r, "fetchData");
    });

  };

  createEvent = data => {
    try {
      data.map(payload => {
        payload.eventStart = payload.eventStart.format("YYYY-MM-DD");
        payload.eventEnd = payload.eventEnd.format("YYYY-MM-DD");
        payload.recurrence = payload.recurrence.toLowerCase().replace("-", "");
        payload.eventTime = payload.eventTime + ":00";
        payload.eventCategory = payload.eventCategory
        //console.log('before submit ',payload);
        Api.createEventSlot(payload).then(r => {
          if (r.status == 200) {
            this.emitResults(r, "createEvent");
          } else if (r.status === 500 || r.status === 404) {
            this.emitResults(true, "warningEvent");
          } else {
            this.emitError({
              message: "Event slot could not be created, please contact support."
            });
          }
        });
      })
    } catch (e) {
      this.emitError({ message: "Incomplete or Invalid data present" });
    }
  };

  fetchPassengers = event => {
    console.log('in schedule store fetch passengers');

    console.log(event, "<====event...")
    var d = new moment(event.start).format("YYYY-MM-DD");
    console.log("EVENT START: ", event.start)
    return new Promise((resolve, reject) => {
      Api.getPassengersOnDate(d).then(r => {

        if (this.activeView == "day" && event.resource._id) {
          r.data.timeslots = r.data.timeslots.filter(
            item => item._id === event.resource._id
          );
        }
        var payload = { date: d };
        this.emitResults(r, "fetchPassengers");
        this.emitDate(payload, "fetchReserveDate");
        resolve(r);
        //this.emitDate(d, 'fetchReserveDate');
      });
    })

  };

  fetchTodayReservation = () => {
    console.log('in schedule store fetch today reservations');
    var d = new moment(new Date()).format("YYYY-MM-DD");
    Api.getPassengersOnDate(d).then(r => {
      //alert('fetching reservation');
      console.log(r);
      if (r.data) {
        r.data.timeslots = r.data.timeslots.filter(
          x => x.reservations.length > 0
        );
        this.emitBlackoutResult(r, "todayReservationEvent");
      }
    });
  };

  blackOutSlots = payload => {
    //var d = new moment(event.start).format("YYYY-MM-DD");
    var blackout = payload.isBlackedOut;

    Api.blackoutSlot(payload)
      .then(r => {
        if (r.status === 200) {
          console.log(r);
          this.emitDate(blackout, "blackOutEvent");
        }
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  setFullSlots = payload => {
    //var d = new moment(event.start).format("YYYY-MM-DD");
    var isfull = payload.isFull;

    Api.setFullSlot(payload)
      .then(r => {
        if (r.status === 200) {
          console.log(r);
          this.emitDate(isfull, "blackOutEvent");
        }
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  modifyTimeslot = async (payload) => {
    try {
      const resp = await Api.modifyTimeslot(payload);
      if (resp.status === 200) {
        return resp.status;
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (err) {
      console.log(err, "<==err");
      throw err;
    }

  };

  blackOutSlotById = payload => {
    //var d = new moment(event.start).format("YYYY-MM-DD");
    var blackout = payload.isBlackedOut;

    Api.blackoutSlotId(payload)
      .then(r => {
        if (r.status === 200) {
          console.log(r);
          this.emitDate(blackout, "slotblackOutEvent");
        }
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  blackOutSeriesByDate = payload => {
    Api.blackOutSeriesByDate(payload)
      .then(r => {
        console.log("🚀 ~ file: schedule-store.js ~ line 145 ~ Schedules ~ r", r)
        if (r.status === 200 && r.data.status === 'success') {
          this.emitDate(payload, "slotblackOutEvent")
        }
        else {
          this.emitError("Deleting timeslot failed", r.data)
        }

      })
      .catch(err => {
        console.log("🚀 ~ file: schedule-store.js ~ line 148 ~ Schedules ~ err", err)
        this.emitError("Deleting timeslot failed", err)

      })
  }
  deleteSlotById = payload => {

    Api.deleteSlotById(payload)
      .then(r => {
        // console.log("🚀 ~ file: schedule-store.js ~ line 145 ~ Schedules ~ r", r)

        if (r.status === 200 && r.data.status === 'success') {
          this.emitDate(payload, "slotDeletedEvent")
        }
        else {
          this.emitError("Deleting timeslot failed", r.data)
        }
      })
      .catch(err => this.emitError("Deleting timeslot failed", err))
  }
  deleteSeriesByDate = payload => {
    Api.deleteSeriesByDate(payload)
      .then(r => {
        console.log("🚀 ~ file: schedule-store.js ~ line 168 ~ Schedules ~ r", r)
        if (r.status === 200 && r.data.status === "success") {
          this.emitDate(payload, "slotDeletedEvent")
        }
        else {
          this.emitError("Deleting timeslot by series failed", r.data)
        }
      })
      .catch(err => {
        console.log("🚀 ~ file: schedule-store.js ~ line 172 ~ Schedules ~ err", err)

      })
  }

  fetchReservation = event => {
    //var d = new moment(event.start).format("YYYY-MM-DD");
    console.log('in schedule store fetch fetchReservation');

    Api.getPassengersOnDate(event.date).then(r => {
      console.log(r, "<====rrrrr")
      // if (event.id) {
      //   r.data.timeslots = r.data.timeslots.filter(
      //     item => item._id === event.id
      //   );
      // }
      var isBlackout = r?.data?.timeslots?.[0]
        ? r?.data?.timeslots?.[0]?.isBlackedOut
        : false;

      var payload = {
        date: event.date,
        blackOut: isBlackout
      };
      this.emitResults(r, "fetchPassengers");
      this.emitDate(payload, "fetchReserveDate");
      this.emitDate(event.date, 'fetchReserveDate');
    });
  };

  removeJumper = (payload) => {
    return new Promise((resolve, reject) => {
      Api.removeJumperFromReservations(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getsuspendedreservation = () => {
    Api.getsuspendedreservation().then(r => {
      if (r.status === 200) {
        var reservations = r.data;
        this.emitResults(r, "reservations");
      }
    })
  }


  getReservations = (payload) => {
    console.log('getting reservations - store')
    Api.getReservations(payload).then(r => {
      if (r.status === 200) {
        var reservations = r.data;
        // console.log(reservations);
        this.emitResults(r, "reservations");
      }
    })
  }

  getLatestReservations = () => {
    console.log('getting reservations - store')
    Api.getLatestReservations().then(r => {
      if (r.status === 200) {
        var reservations = r.data;
        // console.log(reservations);
        this.emitResults(r, "reservations");
      }
    })
  }

  IncompleteResevation = () => {
    return new Promise((resolve, reject) => {
      console.log("IncompleteResevation");
      Api.getIncompleteReservation().then(r => {
        if (r.status === 200) {
          var reservations = r.data;
          console.log(reservations);
          resolve(reservations);
          // this.emitResults(r, "IncompleteResevation");
        }
      })
    })
  }

  postPassangerEvent = event => {
    var show = true;
    this.emitDate(show, "showReservation");
  };

  closeReservation = event => {
    this.fetchData(new Date());
    this.emitDate(false, "closeReservationEvent");
  };

  emitError = err => {
    var payload = { ...err };
    this.emit("ERROR", payload);
  };



  getEventPrefix = func => {
    return `${this.prefix}${func}`;
  };

  emitResults = (r, func) => {
    if (!r.data) {
      //console.log(r);
      this.emitError({ alert: true, message: "No Slots found" });
    }
    this.setMaxListeners(1000);
    this.emit(`${this.prefix}${func}`, r.data);
  };

  emitBlackoutResult = (r, func) => {
    this.setMaxListeners(1000);
    this.emit(`${this.prefix}${func}`, r.data);
  };

  emitDate = (r, func) => {
    this.setMaxListeners(1000);
    this.emit(`${this.prefix}${func}`, r);
  };

  debug = r => {
    //console.log(r.data);
  };

  //Calender Events Calls
  createCalenderEvent = (payload) => {
    return new Promise((resolve, reject) => {
      Api.createCalenderEvent(payload)
        .then(r => {
          if (r.status == 200) {
            return r.data;
          }
          else {
            throw new Error("server Error")
          }
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          this.emitError({ message: 'Create Calender Event Error!' });
          reject(err);
        })
    })
  }

  getEvents = () => {
    return new Promise((resolve, reject) => {
      Api.getCalenderEvents().then(r => {
        if (r.status === 200) {
          var events = r.data;
          resolve(events);
        }
      })
      // .catch(err => this.emitError("Deleting timeslot failed", err))
    })
  }

  deleteCalenderEvent = (item) => {
    return new Promise((resolve, reject) => {
      Api.deleteCalenderEvent(item)
        .then(r => {
          if (r.status === 200) {
            var events = r.data;
            resolve(events);
          }
          else if (r.status === 400) {
            swal({
              title: "Error",
              text: r.data.message,
              icon: "warning",
              dangerMode: false,
            })
            reject(r.message);
          }
        })
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  updateCalenderEvent = (payload) => {
    return new Promise((resolve, reject) => {
      Api.updateCalenderEvent(payload)
        .then(r => {
          if (r.status === 200) {
            var events = r.data;
            resolve(events);
          }
        })
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  addJumperToReservations = (payload) => {
    return new Promise((resolve, reject) => {
      Api.addJumperToReservations(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  addemailtemplates = (payload) => {
    console.log(payload, "ha")
    return new Promise((resolve, reject) => {
      Api.addemailtemplates(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  getemailtemplates = (payload) => {
    console.log(payload, "ha")
    return new Promise((resolve, reject) => {
      Api.getemailtemplates(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  getSingleEmail = (payload) => {
    console.log(payload, "ha")
    return new Promise((resolve, reject) => {
      Api.getSingleEmail(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }
  deleteemailtemplates = (payload) => {
    return new Promise((resolve, reject) => {
      Api.deleteemailtemplates(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  patchemailtemplates = (payload) => {
    return new Promise((resolve, reject) => {
      Api.patchemailtemplates(payload)
        .then(r => {
          if (r.status === 200) {
            var events = r.data;
            resolve(events);
          }
        })
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  addsmsTextTemplates = (payload) => {
    console.log(payload, "hfvfdvfvfa")
    return new Promise((resolve, reject) => {
      Api.addsmsTextTemplates(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  getsmsTextTemplates = (payload) => {
    console.log(payload, "hfkjjkjkddsfda")
    return new Promise((resolve, reject) => {
      Api.getsmsTextTemplates(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  deletesmsTextTemplates = (payload) => {
    return new Promise((resolve, reject) => {
      Api.deletesmsTextTemplates(payload)
        .then(r => {
          if (r.status === 200 || r.status === 400) {
            resolve(r.data);
          }
        })
        .catch(err => {
          reject(err);
        });
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  patchsmsTextTemplates = (payload) => {
    return new Promise((resolve, reject) => {
      Api.patchsmsTextTemplates(payload)
        .then(r => {
          if (r.status === 200) {
            var events = r.data;
            resolve(events);
          }
        })
      // .catch(err => this.emitError("Deleting Event failed", err))
    })
  }

  //Create here API

}




const tandemData = new Schedules();
window.tandemData = tandemData;
export default tandemData;
