import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import background from "../images/background.svg";
import logo from "../images/logo.svg";
import "./smsTextTemplates.css";
import LeftMenu from "../menu/LeftMenu1";
import DonutLoader from "../loadManager/DonutLoader";
import swal from "sweetalert";
import scheduleStore from "../../stores/schedule-store";
import SMSModal from "./smsTextTemplatesModal";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "1rem",
      letterSpacing: "1px",
    },
  },
};
export default function ReservationList(props) {
  const [content, setContent] = useState(null);
  const [convertedContent, setConvertedContent] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [state, setState] = useState({
    loading: false,
    isLoading: false,
    errorMsg: false,
  });

  const [modalData, setModalData] = useState(null);
  const [addEditLoading, setaddEditLoading] = useState(false);

  const deleteConfirmation = (data) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want t delte this template?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
    }).then((willDelete) => {
      console.log("willDelete===>", willDelete, data);
      if (willDelete) {
        setIsLoading(true);
        scheduleStore
          .deletesmsTextTemplates({ _id: data._id })
          .then((resp) => {
            console.log("RESP===>", resp);
            loadEmails();
            swal(
              "Deleted!",
              "Your SMS Template has been deleted!",
              "success"
            );
          })
          .catch((err) => {
            swal("Something went wrong!!", "fail");
          })
          .finally(() => {
            setIsLoading(false);
            //clossmodal
          });
      } else {
        // swal(
        //   "NOT OUNRddd!",
        //   "Your imaginary file has been deleted!",
        //   "success"
        // );
      }
    });
  };

  let tableData = [];
  const [userData, setUserData] = useState([]);

  console.log(userData, "userDatauserDatauserDatauserDatauserData");

  const loadEmails = () => {
    setIsLoading(true);
    scheduleStore
      .getsmsTextTemplates()
      .then((resp) => {
        console.log(resp, "resmnkjnknnnp?.dataresp?.datarcddfvdesp?.data");
        setUserData(resp.data);
        //   console.log(res.data,"resp?.dataresp?.datarcddfvdesp?.data")
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadEmails();
  }, [scheduleStore]);
  console.log("userDafdvdfgbfbfgbgfta===>", userData);
  const toggleModal = (e) => {
    setIsModalOpen(true);
    setState((prev) => ({
      ...prev,
      isModalOpen: setIsModalOpen(false),
      errorMsg: false,
    }));
    console.log(isModalOpen, "ismodal after");
  };

  const handleEditSubmit = () => {
    setaddEditLoading(false);
    scheduleStore
      ?.patchsmsTextTemplates({
        id: modalData?._id,
        name: formValues?.name,
        content: formValues?.content,
      })
      ?.then((resp) => {
        if (resp) {
          setUserData(resp?.data);
          setFormValues({});
        }
        console.log("resp=rtyhftyhfy==>", resp);
        setModalData(null);
        loadEmails();
      })
      .finally(() => {
        setaddEditLoading(false);
      });
      setFormValues({});
  };

  const handleCancel = () => {
    setState((prev) => ({ ...prev, loading: false }));
    toggleModal();
    setModalData(null);
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
      [e.target.content]: e.target.content,
    });
    console.log(formValues, "formvalues");
  };
  const returnColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        width: "300px",
      },
      {
        name: "Content",
        selector: "content",
        sortable: true,
        width: "600px",
      },
      {
        name: "Action",
        selector: "action",
        width: "200px",
        sortable: true,
        cell: (row) => (
          <div>
            <i onClick={() => setModalData(row)} className="fa fa-pencil" />
            <i
              onClick={() => deleteConfirmation(row)}
              className="fa fa-trash ml-4"
            />
          </div>
        ),
      },
    ];
  };

  const returnTableData = () => {
    console.log(userData, "userDatauserDataufvdrvserData");
    return userData || [];
  };
  const handleSumbit = () => {
    console.log("CALEED", content, formValues);
    setaddEditLoading(false);
    scheduleStore
      .addsmsTextTemplates({
        name: formValues.name,
        content: formValues.content,
      })
      .then((resp) => {
        setIsModalOpen(false);
        loadEmails();
      })
      .catch((err) => console.log("err=>", err))
      .finally(() => {
        setaddEditLoading(false);

        setIsModalOpen(false);
      });
      setFormValues({})
  };

  const isMobile = window.innerWidth <= 500;

  return (
    <div
      className="row reservation-list-container"
      style={{ backgroundImage: `url(${background})` }}
    >
      {isMobile ? (
        <div
          className={`blueBg fullHeightPanes ${
            this.state.open ? "mobileLeftBar" : "hideSideBar"
          }`}
        >
          <div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
            <LeftMenu showCreate={true} />
          </div>
        </div>
      ) : (
        <div className="blueBg fullHeightPanes left-container">
          <img
            src={logo}
            alt="logo"
            style={{ height: 60, margin: "auto", display: "block" }}
          />
          <LeftMenu showCreate={true} />
        </div>
      )}

      <div
        className="middle-container-no-rightmenu"
        style={{ padding: "0 15px" }}
      >
        <DonutLoader isLoading={isLoading} addClass="sample1" />

        <img
          src={logo}
          alt="logo"
          style={{ height: 60, margin: "auto", display: "block" }}
        />

        <div className="card">
          <div className="card-body datatable-react">
            <DataTable
              title={
                <div className="d-flex align-items-center">
                  <p className="m-0">SMS Text Templates</p>
                  <span className="ml-4">
                    <i
                      className="fa fa-plus"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          isModalOpen: setIsModalOpen(true),
                        }));
                      }}
                    />
                  </span>
                </div>
              }
              compact={true}
              center={true}
              striped={true}
              responsive={true}
              pagination={true}
              expandOnRowClicked={true}
              paginationPerPage={15}
              selectableRows
              persistTableHead
              customStyles={customStyles}
              columns={returnColumns()}
              data={returnTableData()}
            />
          </div>
        </div>
      </div>
      <SMSModal
        modalData={modalData}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleEditSubmit={handleEditSubmit}
        setModalData={setModalData}
        state={state}
        setFormValues={setFormValues}
        formValues={formValues}
        toggleModal={toggleModal}
        handleSumbit={handleSumbit}
        addEditLoading={addEditLoading}
        setState={setState}
        handleCancel={handleCancel}
        handleChange={handleChange}
      />
    </div>
  );
}
