import api from '../../../services/squareOauthApi';
import {useEffect} from 'react';
import logo from '../../images/logo.svg';
import FixedLeftMenu from '../../Common/Fixed_Left_Menu/fixedLeftMenu';
import './OauthSettings.css'

let accessCode;
const OauthRedirect = ({ history }) => {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        accessCode = urlParams.get('code');
        (async () => {
            const oauthTokenResp = await api.getOauthTokenFromSquare(accessCode);
        })();
    },[])



    return (
        <div className="oauth_Settings_main">
            <FixedLeftMenu parHistory={history} />
            <div className="right_container">
            <div className="dizio_logo">
                <img src={logo} alt="logo" className={"dizio_logo"} />
            </div>
            <div className="oauth_redirect_right_container_bottom">
                <p>Congrats!&nbsp;&nbsp;Oauth has been successful</p>
                <p>Code is:- {accessCode}</p>
            </div>
            </div>
        </div>
    )
}

export default OauthRedirect;