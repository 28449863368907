import React, { Component } from "react";
import loadsStore from "../../../stores/loads-store";

class EditableLoadPlane extends Component {
  constructor(props) {
    super(props);
    this.updatePlaneType = this.updatePlaneType.bind(this);
    this.state = {
      editMode: false,
      load: props.load,
      planeOption: [],
      planeName: this.props.Plane,
    };
    this.loadEvents = [
      {
        name: loadsStore.getEventPrefix("planesLoaded"), func: this.handlePlanesLoaded
      }
    ];
  }

  setEditable = () => {
    if (!this.props.isCompleted) {
      this.setState({
        editMode: !this.state.editMode
      });
    }
  };

  componentWillMount() {
    var planArray = loadsStore.planes;
    if (planArray.length == 0) {
      var that = this;
      Promise.all([loadsStore.loadPlanes()]).then(function (result) {
        that.setState({
          planeOption: loadsStore.getPlanesOptions()
        });
      });
    }

    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    this.loadEvents.forEach(evnt =>
      loadsStore.removeListener(evnt.name, evnt.func)
    );
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  /**
   * Set the plane list selector ref
   */
  setPlaneListRef = node => {
    this.planeListRef = node;
  };

  handleClickOutside = event => {
    if (this.planeListRef && !this.planeListRef.contains(event.target)) {
      this.setState({
        editMode: false
      });
    }
  };

  handlePlanesLoaded = planeOption => {
    var option = [];

    var planeDisplaySetting = loadsStore.getPlaneDisplaySetting();

    if (planeDisplaySetting.value === "plane type") {
      planeOption.forEach(element => {
        option.push({ label: element.plane });
      });
    } else if (planeDisplaySetting.value === "plane name") {
      planeOption.forEach(element => {
        option.push({ label: element.name });
      });
    }

    alert(option);

    this.setState({
      planeOption: option
    });
  };

  renderPlanes = planeOptions => {
    var planeArray = [];
    if (planeOptions.length > 0) {
      planeArray = planeOptions;
    } else {
      planeArray = loadsStore.planes;
    }

    planeArray = loadsStore.planes;

    var planeDisplaySetting = loadsStore.getPlaneDisplaySetting();

    if (planeArray.length > 0) {
      var planes = "";

      if (planeDisplaySetting.value === "plane type") {
        planes = planeArray.map(plane => {
          return (
            <a
              className="dropdown-item"
              onClick={() => {
                this.updatePlaneType(plane.plane, this.props.loadNum, plane.tail);
              }}
            >
              {plane.plane + "-" + plane.name}
            </a>
          );
        });
      } else if (planeDisplaySetting.value === "plane name") {
        planes = planeArray.map(plane => {
          return (
            <a
              className="dropdown-item"
              onClick={() => {
                this.updatePlaneType(plane.name, this.props.loadNum, plane.tail);
              }}
            >
              {plane.plane + "-" + plane.name}
            </a>
          );
        });
      }
      return <div className={this.props.showPlaneLoadNumOnly ? "dropdown-plan-show-config-T" : "dropdown-plan"}>{planes}</div>;
    } else {
      return "";
    }
  };

  updatePlaneType = (value, loadNum, tail) => {
    if (value !== "Select..") {
      var planeName = value;

      var planeBody = {
        plane: value
      };

      var planes = loadsStore.getPlanes();

      var planeDisplaySetting = loadsStore.getPlaneDisplaySetting();

      if (planeDisplaySetting.value === "plane type") {
        var plane = planes.filter(plane => plane.plane === value)[0];
        planeBody.capacity = plane.capacity;
        planeBody.price = plane.price;
        planeBody.registrationNumber = tail;
      } else if (planeDisplaySetting.value === "plane name") {
        var plane = planes.filter(plane => plane.name === value)[0];
        planeBody.capacity = plane.capacity;
        planeBody.price = plane.price;
        planeBody.registrationNumber = tail;

      }

      var that = this;
      Promise.all([loadsStore.updatePlaneType(loadNum, planeBody)]).then(
        function (result) {

          var data = result[0];

          if (data === true) {
            that.setState({
              editMode: false,
              planeName: planeName
            });
          } else {
            that.setState({ editMode: false });
          }
        }
      );
    }
  };

  render() {
    let { editMode, planeOption } = this.state;
    const { Plane, planeLoadNum, Tail, tailLoadNum } = this.props

    return (

      <p onClick={this.setEditable} ref={this.setPlaneListRef} style={{ color: this.props.planeColor }}>
        {
          this.props.showPlaneLoadNumOnly ?
            <>{Plane}-{planeLoadNum} <br /> {Tail}-{tailLoadNum}</>
            : <>{Plane}-{planeLoadNum} -{Tail}-{tailLoadNum}</>
        }
        {editMode ? this.renderPlanes(planeOption) : null}
      </p>
    );
  }
}

export default EditableLoadPlane;
