import React, { Component } from 'react'
import { Table } from 'reactstrap';
import CreateGrouponPackage from '../modals/createGrouponPackage';
import CreateDiscountCode from '../modals/createDiscountCode';
import RemoveItem from '../modals/removeItem';

export default class GrouponDeals extends Component {
    renderGrouponPackages = () => {
        let { categories, grouponPackages } = this.props;

        if (grouponPackages != undefined) {
            return grouponPackages.map((item, index) => {
                item.category = 'groupon';
                return (
                    <tr key={`discountRow${index}`}>
                        <th key={`discountCell1${index}`} scope="row" data-id={item._id}>{index + 1}</th>
                        <td key={`discountCell2${index}`}>{item.packageName}</td>
                        <td key={`discountCell3${index}`}>{item.numTickets}</td>
                        <td key={`discountCell3${index}`}>{item.numCodes}</td>
                        <td key={`discountCell3${index}`}>{item.numRedeemed}</td>
                        <td key={`discountCell4${index}`} >${Math.abs(item.price).toFixed(2)}</td>
                        <td key={`discountCell5${index}`}>{item.tandemPackageName}</td>
                        <td key={`discountCell7${index}`}>{item.videoPackageName}</td>

                        <td key={`discountCell9${index}`}>
                            <CreateDiscountCode isEdit={true} item={item} categories={categories} />&nbsp;&nbsp;
                            <RemoveItem item={item} />
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    render() {

        return (
            <Table >
                <thead className="main-heading">
                    <tr>
                        <th><CreateGrouponPackage filename="test" /></th>
                        <th>Name</th>
                        <th>Tickets</th>
                        <th>Codes</th>
                        <th>Num redeemed</th>
                        <th>Price</th>
                        <th>Tandem Package</th>
                        <th>Video Package</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="priceHeading">
                        <td colSpan="3">Groupon Deals</td>
                    </tr>
                    {this.renderGrouponPackages()}
                </tbody>
            </Table>
        )
    }
}
