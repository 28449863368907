import React, { Component } from 'react';

import Calendar from 'react-calendar';
import DateSelector from './dateselector';
import Api from '../../../services/reservationapi';

import TicketForm from './ticketForm';
import PassengerForm from './passengerForm';
import tandemDataStore from '../../../stores/reservation-store';
import tandemData from '../../../stores/reservation-store';
import logo from '../../images/logo.svg';
import background from '../../images/background.svg';
import Invoice from './invoice';
import * as moment from 'moment';
import schedulesStore from '../../../stores/schedule-store';
import reportStore from '../../../stores/report-store';
import loadsStore from '../../../stores/loads-store';

import { ScaleLoader } from 'react-spinners';
import swal from 'sweetalert';
import NabVelocity from '../../sub-components/nab/nabVelocity'
import { vi } from 'date-fns/esm/locale';


class reservation extends Component {
  messages = {
    NoSlotAvilable: (
      <div className="alert alert-warning">No Slots available for this day</div>
    ),
    Initial: ''
  };

  state = {
    date: null,
    slotDates: [],
    ticketCount: 0,
    videoCount: 0,
    message: null,
    enablePurchase: false,
    hideMainForm: false,
    finalForm: tandemDataStore.getAll(),
    allowedDates: [],
    chkFormVal: false,
    width: window.innerWidth,
    open: false,
    isOpened: false,
    EventName: '',
    loadPrices: [],
    totalAmount: 0,
    totalTax: 0,
    isWeekEnd: false,
    isloading: false,
    giftCard: false,
    cardCode: '',
    errorMessage: false,
    checkoutId: '',
    deposit: false,
    tandemUnitPrice: 0,
    tandemVideoPrice: 0
  };


  updateAllowedDates = (r) => {
    window.debugthis = r.data;
    // console.log(r.data);
    var allowedDates = r.data.map(elem => elem.datetime.split('T')[0]);
    // console.log(allowedDates);
    this.setState({ allowedDates: allowedDates, slotDates: [] });
  }


  processSlotResponse = (r) => {
    this.setState({ slotDates: r.data, finalForm: {}, isOpened: true });
    r.data.length === 0 &&
      this.setState({ message: this.messages.NoSlotAvilable });
    tandemData.clearAll();
  };

  handleClick = (slot, eventName) => {
    // handle when a date is selected
    var cache = { ...this.finalForm };
    cache.timeslotId = slot._id;
    // this.setState({ finalForm: cache, EventName: eventName });

    this.setState({ finalForm: cache, EventName: eventName }, () => {
      this.CalculatePrice(this.state.ticketCount, this.state.videoCount, this.state.deposit);
    });
    tandemDataStore.updateFinalForm(cache);
  };


  // handleTicketUpdate = (val) => this.setState({ ticketCount: val });
  // handleVideoUpdate = (val) => this.setState({ videoCount: val });

  handleTicketUpdate = (val) => {
    const { finalForm } = this.state;
    if (val === undefined) {
      val = 0;
    }
    var Count = 0;
    finalForm.passengers.forEach(element => {
      if (element.video === true) {
        Count = Count + 1;
      }
    })

    this.setState({ ticketCount: val, videoCount: Count })
    this.CalculatePrice(val, Count, this.state.deposit);
  };

  handleVideoUpdate = (val) => {

    if (val === undefined) {
      val = 0;
    }
    this.setState({ videoCount: val })
    //if (this.state.ticketCount > 0 && val > 0) {
    this.CalculatePrice(this.state.ticketCount, val, this.state.deposit);
    //}
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillMount = () => {
    var schdate = new Date(this.props.scheduleDate.date);
    var weekEnd = (schdate.getDay() % 6 === 0);

    if (weekEnd) {
      this.setState({
        isWeekEnd: true
      })
    }
    else {
      this.setState({
        isWeekEnd: false
      })
    }

    window.removeEventListener('resize', this.handleWindowSizeChange);
    tandemData.on('childFormUpdate', this.handleFormData);

    var d = new moment();
    var startMonth = d.clone().startOf('month').format("YYYY-MM-DD");
    var endMonth = d.clone().endOf('month').format("YYYY-MM-DD");
    console.log('reservations 149 be causing some problems');
    Api.getMonthlySlots({ start: startMonth, end: endMonth }).then(this.updateAllowedDates);
    if (window.ENV?.processorInfo.processor === "nab") {
      Api.startSession()
        .then(res => {
          console.log(res);
        });
    }
    // var profileId, merchantProfileId, serviceID;

    // Api.getIdentityInfo()
    //   .then(info => {
    //     tandemDataStore.setProcessorVals(info.data);
    //     profileId = info.data.applicationProfileId;
    //     merchantProfileId = info.data.merchantProfileId;
    //     serviceID = info.data.ServiceID;
    //     return Api.startSession(info.data.IdentityToken);
    //   })
    //   .then(r => {
    //     if (r.data) {
    //       tandemDataStore.setTokENV?.l(r.data);
    //     }
    //     return Api.getApplicationData(tandemDataStore.getTokENV?.l(), profileId);
    //   })
    //   .then(r => {
    //     var applicationData = r.data;
    //     return Api.getServiceInformation(tandemDataStore.getTokENV?.l());
    //   })
    //   .then(r => {
    //     return Api.isMerchantProfileInitialized(tandemDataStore.getTokENV?.l(), merchantProfileId, serviceID);
    //   })
    //   .then(r => {
    //     //console.log(r.data);
    //   })

    var priceArray = reportStore.priceSchedule;
    if (priceArray.length == 0) {
      var data = [];
      var that = this;
      Promise.all([reportStore.loadPrices()])
        .then(function (result) {
          data = result[0];
          that.setState({ loadPrices: data.priceSchedule });
        })
    }
    else {
      this.setState({ loadPrices: priceArray });
    }
  }

  componentDidUpdate() {
    if (!this.state.isOpened) {
      Api.GetTimeslots(this.props.scheduleDate.date).then(this.processSlotResponse);
    }
  }

  handleBackEvent() {
    schedulesStore.closeReservation();
  }

  handleToken = (r) => {
    // console.log(r.data)
    if (r.data) {
      var token = r.data.slice(1, -1);
      // console.log(token);
      tandemDataStore.setTokENV?.l(token);

    } else {
      alert('Something went wrong Please reload, Some of the services are not available');
    }
  }

  updateAvailableDatesSlots = (value) => {
    var d = new moment(value.activeStartDate);
    var startMonth = d.clone().startOf('month').format("YYYY-MM-DD");
    var endMonth = d.clone().endOf('month').format("YYYY-MM-DD");
    console.log('reservations 227 be causing some problems');

    Api.getMonthlySlots({ start: startMonth, end: endMonth }).then(this.updateAllowedDates)
  }

  validateEmail = (value) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }

  // handleFormData = (passengers) => {
  //   if (passengers.target && passengers.target.className.indexOf('checkoutButton') > -1) {
  //     this.setState({ chkFormVal: true });
  //     var cache = { ...this.state };
  //     cache.finalForm = tandemDataStore.getAll().finalForm;
  //     this.setState({ ...cache });
  //     var passed = 0;
  //     for (let i = 0; i < this.state.ticketCount; i++) {
  //       var fdata = cache.finalForm.passengers[i];
  //       if (
  //         fdata &&
  //         fdata.firstname &&
  //         fdata.lastname &&
  //         fdata.email &&
  //         fdata.weight &&
  //         fdata.adult &&
  //         this.validateEmail(fdata.email)
  //       ) {
  //         passed = passed + 1;
  //         if (passed == this.state.ticketCount) this.setState({ enablePurchase: true }, () => { this.handleSummaryButton() });
  //         else this.setState({ chkFormVal: true });
  //       } else {
  //         this.setState({ enablePurchase: false });
  //         this.setState({ chkFormVal: true });
  //       }
  //     }
  //   } else {
  //     return;
  //   }
  // };

  handleFormData = (passengers) => {
    if (passengers.target && passengers.target.className.indexOf('checkoutButton') > -1) {
      this.setState({ chkFormVal: true });
      var cache = { ...this.state };
      cache.finalForm = tandemDataStore.getAll().finalForm;
      this.setState({ ...cache });
      var passed = 0;
      for (let i = 0; i < this.state.ticketCount; i++) {
        var fdata = cache.finalForm.passengers[i];
        if (
          fdata &&
          fdata.firstname &&
          fdata.lastname &&
          fdata.email &&
          fdata.weight &&
          fdata.adult &&
          this.validateEmail(fdata.email)
        ) {
          passed = passed + 1;
          if (passed == this.state.ticketCount) this.setState({ enablePurchase: true }, () => { this.handleSummaryButton() });
          else this.setState({ chkFormVal: true });
        }
        else if (
          fdata &&
          fdata.firstname &&
          fdata.lastname &&
          fdata.email &&
          fdata.weight &&
          fdata.adult &&
          this.validateEmail(fdata.email)
        ) {
          passed = passed + 1;
          if (passed == this.state.ticketCount) this.setState({ enablePurchase: true }, () => { this.handleSummaryButton() });
          else this.setState({ chkFormVal: true });
        }
        else {
          this.setState({ enablePurchase: false });
          this.setState({ chkFormVal: true });
        }
      }
    } else {
      return;
    }
  };

  handleSummaryButton = () => {
    // if (this.state.enablePurchase) {
    //   this.setState({ hideMainForm: !this.state.hideMainForm });
    // }
    if (this.state.enablePurchase && !this.state.giftCard) {
      document.getElementsByClassName("velocity-button")[0].click();
    }
    else {
      const { finalForm, isWeekEnd, EventName } = this.state;
      //alert('Gift Card');
      var eventType = '';
      if (EventName === 'Tandem') {
        eventType = isWeekEnd ? 'weekend Tandem' : 'weekday Tandem'
      }
      else {
        eventType = EventName;
      }

      var payload = {
        "code": this.state.cardCode.trim(),
        "lastname": finalForm.passengers[0].lastname,
        "firstname": finalForm.passengers[0].firstname,
        "giftType": eventType //this.state.EventName
      }

      tandemDataStore.VerifyGiftCard(payload).then((res) => {
        if (res !== false) {
          this.handleReservation();
          var payload = {
            cardId: res[0]._id,
            isUsed: true
          }
          tandemDataStore.UpdateGiftCard(payload);
        }
        else {
          console.log("Invalid Code");
          this.setState({
            errorMessage: true
          });

          setTimeout(() => {
            this.setState({
              errorMessage: false
            })
          }, 5000)
        }
      }).catch(ex => {
        console.log(ex);
      });
    }
  };

  // renderButton = () => {
  //   if (this.state.ticketCount && this.state.ticketCount > 0) {
  //     return (
  //       <div className="col-md-12">
  //         <button
  //           className="checkoutButton btn btn-primary btn-lg"
  //           onClick={this.handleFormData}
  //         >
  //           MAKE RESERVATION
  //         </button>
  //       </div>
  //     );
  //   }
  // };

  checkDateAvailability = ({ date }) => {
    let d = new moment(date);
    return (this.state.allowedDates.indexOf(d.format("YYYY-MM-DD")) == -1)
  }

  showMainForm = () => {
    this.setState({ hideMainForm: false });
  }

  handleClick() {
    this.setState({
      open: !this.state.open
    });

  }

  handleDeposit = (val) => {
    if (val === true) {
      this.CalculatePrice(this.state.ticketCount, this.state.videoCount, val);
    }
    this.setState({ deposit: val, giftCard: false })
  }

  handleFullDeposit = (val) => {
    if (val === false) {
      this.CalculatePrice(this.state.ticketCount, this.state.videoCount, val);
    }
    this.setState({ deposit: val, giftCard: false })
  }

  handleGiftCard = (val) => {
    if (val === 'giftCard') {
      this.CalculatePrice(1, this.state.videoCount, false);
    }
    this.setState({ deposit: false, ticketCount: 1, videoCount: 0, giftCard: true })
  }


  CalculatePrice(ticket, video, deposit) {
    const { isWeekEnd } = this.state;
    var priceSchedule = reportStore.priceSchedule;
    var tandemWeekend = this.getPrice(priceSchedule, "tandem weekend");
    var tandemWeekday = this.getPrice(priceSchedule, "tandem weekday");
    var tandemVideoCost = this.getPrice(priceSchedule, "video");
    var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
    var AffFee = this.getPrice(priceSchedule, "AFF1");

    var taxPerc = parseInt(scheduleFee);
    var tandemUnitCost = 0;

    if (this.state.EventName.trim() == 'Tandem') {
      tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }
    else if (this.state.EventName.trim() == 'AFF') {
      tandemUnitCost = parseInt(AffFee);
    }

    if (deposit === true) {
      tandemUnitCost = 50;
      var taxValue =
        tandemUnitCost * ticket +
        video * tandemVideoCost;
      var taxPrice = (taxPerc / 100) * taxValue;


      var subTotal =
        tandemUnitCost * ticket +
        video * tandemVideoCost;
      var totalPrice = subTotal + taxPrice;
      this.setState({ totalAmount: totalPrice, totalTax: taxPrice })
    }
    else {
      var taxValue =
        tandemUnitCost * ticket +
        video * tandemVideoCost;
      var taxPrice = (taxPerc / 100) * taxValue;


      var subTotal =
        tandemUnitCost * ticket +
        video * tandemVideoCost;
      var totalPrice = subTotal + taxPrice;
      this.setState({ totalAmount: totalPrice, totalTax: taxPrice, tandemUnitPrice: tandemUnitCost, tandemVideoPrice: tandemVideoCost })
    }
  }

  getPrice(priceSchedule, type) {
    var index = priceSchedule.findIndex(x => x.type == type);
    var price = priceSchedule[index].price;
    return price;
  }

  onPaymentCompletion = (r) => {
    const { checkoutId } = this.state;
    if (r.Status === "Successful" && checkoutId !== r.TransactionId) {
      this.handleReservation(r);
    }
    else if (checkoutId === r.TransactionId) {
      console.log('Error');
    }
  }

  handleCardCode = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleReservation = (r) => {
    var { finalForm, hideMainForm, totalAmount, totalTax, giftCard, checkoutId, deposit, isWeekEnd, videoCount, tandemUnitPrice, tandemVideoPrice } = this.state;
    var payload = {};
    var submit = false;
    if (giftCard) {
      var transactionId = 'GiftCardTransaction';
      var amount = totalAmount;
      submit = true;
    }
    else if (r.Status === "Successful" && checkoutId !== r.TransactionId) {
      var transactionId = r.TransactionId; //e.target.getAttribute("data-tid");
      var amount = r.Amount; //e.target.getAttribute("amount");
      submit = true;
    }

    // var priceSchedule = tandemDataStore.priceSchedule;
    // var tandemWeekend = this.getPrice(priceSchedule, "tandem weekend");
    // var tandemWeekday = this.getPrice(priceSchedule, "tandem weekday");
    // var tandemVideoCost = this.getPrice(priceSchedule, "video");
    // var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
    // var AffFee = this.getPrice(priceSchedule, "AFF1");

    // var taxPerc = parseInt(scheduleFee);
    // var tandemUnitCost = 0;

    // if (this.state.EventName.trim() == 'Tandem') {
    //   tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    // }
    // else if (this.state.EventName.trim() == 'AFF') {
    //   tandemUnitCost = parseInt(AffFee);
    // }

    if (transactionId && amount && submit) {
      var email = '';
      var name = '';
      var othername = '';
      var eventName = '';
      var ticketCount = '';
      var videoCount = '';
      var slotDates = '';
      var passangerBody = [];

      finalForm.passengers.forEach(element => {

        console.log(element);
        if (element.video === true) {
          element.totalPaid = deposit ? 50 + (this.state.videoCount * tandemVideoPrice) : tandemUnitPrice + (this.state.videoCount * tandemVideoPrice);
        }
        else {
          element.totalPaid = deposit ? 50 : tandemUnitPrice;
        }
        passangerBody.push(element);

        if (email == '') {
          email = element.email;
          name = element.firstname + " " + element.lastname;
          amount = amount;
          eventName = this.state.EventName;
          ticketCount = this.state.ticketCount;
          videoCount = this.state.videoCount;
          slotDates = this.state.slotDates;
        }
        else {
          email = email + ' ,' + element.email;
          othername += `<p className="subText" style="font-family:'Opens Sans', sans-serif;margin: 0 auto; margin-top: 15px; text-align: center;font-size: 1.5em;text-align: center;padding-bottom: 0.2em"><b
              style="font-weight: 700; font-family: inherit;padding-bottom: 0.4em">Name: </b> ${element.firstname + " " + element.lastname}</p>`;
        }
      });

      var date = new Date(this.state.slotDates[0].datetime);
      var month = date.toLocaleString('en-us', { month: 'long' });
      var Subject = "Inovice Report";
      var Message = "Your Reservation Has Been Successfull for Date : " + date.getDate() + ' ' + month + ' ' + date.getFullYear() + ' ' + 'at' + ' ' + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + " and The Invoice generated is : " + totalAmount + " ";
      var emailBody = {
        email: email,
        name: name,
        othername: othername,
        subject: Subject,
        amount: amount,
        eventName: eventName,
        ticketCount: ticketCount,
        videoCount: videoCount,
        message: Message,
        slotDates: slotDates
      };

      payload = {
        passengers: passangerBody,//finalForm.passengers,
        ticketCount: this.state.ticketCount,
        videoCount: this.state.videoCount,
        slotId: finalForm.timeslotId,
        deposit: this.state.deposit,
        amount: amount,
        totalFees: this.state.totalTax,
        transactionId: transactionId,
        eventName: this.state.EventName
      };

      tandemDataStore.MakeReservation(payload).then((res) => {
        if (res === true) {
          tandemDataStore.sendEmail(emailBody);
          swal({
            title: "Success",
            text: "Thank for creating your skydiving reservations \n Please check your email for confirmation and waiver signing \n We look forward to seeing you soon",
            icon: "success",
            dangerMode: false,
          })
        }
        else {
          console.log('Error');
        }
      });

      this.setState({
        ticketCount: 0,
        videoCount: 0,
        deposit: false,
        message: null,
        enablePurchase: false,
        hideMainForm: false,
        giftCard: false,
        cardCode: '',
        errorMessage: false,
        slotDates: [],
        checkoutId: transactionId
      })
    }
  }

  renderSlotSelector = () => {
    const { finalForm } = this.state;
    if (this.state.slotDates.length > 0) {
      return this.state.slotDates.map((slot) => (
        <div key={slot._id}>
          <DateSelector
            selected={finalForm.timeslotId === slot._id}
            handleClick={this.handleClick}
            slot={slot}
          />
        </div>
      ));
    } else {
      return this.state.message || this.messages.Initial;
    }
  };


  renderSlotSelector = (isTandem) => {
    const { finalForm } = this.state;
    var tandemSlots;
    var affSlots;
    var slots;
    if (isTandem) {
      slots = this.state.slotDates.filter(slot => slot.eventName === "Tandem");
    }
    else {
      slots = this.state.slotDates.filter(slot => slot.eventName !== "Tandem");
    }
    if (slots.length > 0) {
      this.SELECTTIME.scrollIntoView({ block: 'start', behavior: 'smooth' });

      return slots.map((slot) => (
        <div key={slot._id}>
          <DateSelector
            selected={finalForm.timeslotId === slot._id}
            handleClick={this.handleClick}
            slot={slot}
          />
        </div>
      ));
    } else {
      return this.state.message;
    }
  };


  renderMainForm = (finalForm) => {
    return (
      <div className="row" style={{ marginBottom: 50 }}>
        <h4
          className="col-md-12 text-center"
          style={{
            fontWeight: 400,
            letterSpacing: 1,
            backgroundColor: '#00419d',
            color: 'white',
            paddingTop: 30,
            paddingBottom: 30
          }}
        >
          SCHEDULE YOUR JUMP : {this.props.scheduleDate.date}
        </h4>
        <div className="col-md-12 p-4 containerPadding">
          {this.state.slotDates.length > 0 && (
            <div className="row">
              <hr />
              <div className="col-md-9">
                <span className="customHeading">SELECT TIME</span>
              </div>
              <div className="col-md-3" style={{ textAlign: 'right' }}>
                <button onClick={this.handleBackEvent} className="customBackButton" type="button"> BACK </button>
              </div>
            </div>
          )}
          {this.renderSlotSelector()}
        </div>
        {this.state.slotDates.length > 0 &&
          finalForm.timeslotId && (
            <TicketForm
              className="containerPadding"
              handleTicketUpdate={this.handleTicketUpdate}
              ticketNumber={this.state.ticketCount}
              videoCount={this.state.videoCount}
              handleDeposit={this.handleDeposit}
              handleFullDeposit={this.handleFullDeposit}
              handleGiftCard={this.handleGiftCard}
            />
          )}
        {finalForm.timeslotId && (
          <PassengerForm
            passengerCount={this.state.ticketCount}
            chkFormVal={this.state.chkFormVal}
            handleVideoUpdate={this.handleVideoUpdate}
          />
        )}
        {/* {this.renderButton()} */}

        {this.state.ticketCount > 0 && this.state.slotId !== '' && this.renderInvoice()}
        {this.state.ticketCount > 0 && this.state.slotId !== '' && this.renderButton()}
      </div>
    );
  };


  renderButton = () => {
    if (this.state.ticketCount && this.state.ticketCount > 0) {
      return (
        <div style={{ margin: 'auto' }}>
          {this.state.giftCard
            ?
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 20, marginLeft: '26%' }}>
                <span className="customHeading" style={{ marginLeft: '6px' }}>
                  ENTER CODE
                </span>
                <br />
                <input
                  style={{ maxWidth: '9em', display: 'inline-block' }}
                  onChange={(e) => this.handleCardCode(e)}
                  value={this.state.cardCode}
                  className="form-control col-md-12"
                  placeholder="Gift Card Code"
                  name="cardCode"
                  autoComplete="off"
                />
                <br />
                {
                  this.state.errorMessage &&
                  <span className="text-danger error-validation ">Invalid Gift Card Code !</span>
                }
              </div>


              <div className="col-md-12">
                <button
                  className="checkoutButton btn btn-primary btn-lg"
                  onClick={this.handleFormData}
                >
                  Reserve Now
                </button>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-md-12">
                <button
                  className="checkoutButton btn btn-primary btn-lg"
                  onClick={this.handleFormData}
                >
                  Pay Now
                </button>
              </div>
            </div>
          }
        </div>
      );
    }
  };


  renderInvoice = () => {
    const { isWeekEnd } = this.state;

    var priceSchedule = reportStore.priceSchedule;
    // console.log("price schedule=====>>", priceSchedule)
    var tandemWeekend = this.getPrice(priceSchedule, "tandem weekend");
    var tandemWeekday = this.getPrice(priceSchedule, "tandem weekday");
    var tandemVideoCost = this.getPrice(priceSchedule, "video");
    var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
    var AffFee = this.getPrice(priceSchedule, "AFF1");

    var taxPerc = parseInt(scheduleFee);
    var tandemUnitCost = 0;

    if (this.state.EventName.trim() == 'Tandem') {
      tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }
    else if (this.state.EventName.trim() == 'AFF') {
      tandemUnitCost = parseInt(AffFee);
    }

    return (
      <div className="col-md-12">
        <div className="card cardOverRide">
          <div className="card-header">ORDER SUMMARY</div>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Count</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tandem</td>
                <td>{this.state.ticketCount}</td>
                <td>${this.state.deposit === true ? (50 * this.state.ticketCount).toFixed(2) : (tandemUnitCost * this.state.ticketCount).toFixed(2)}</td>
              </tr>

              <tr>
                <td>Video</td>
                <td>{this.state.videoCount}</td>
                <td>${(this.state.videoCount * tandemVideoCost).toFixed(2)}</td>
              </tr>

              <tr>
                <td>Fees</td>
                <td></td>
                <td>${this.state.totalTax.toFixed(2)}</td>
              </tr>
              <tr>
                <td>TOTAL :</td>
                <td></td>
                <td>${this.state.totalAmount.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render() {

    var { finalForm, totalAmount } = this.state;
    var payload = {};
    const { width } = this.state;
    const isMobile = width <= 500;
    if (this.state.hideMainForm) {
      payload = {
        passengers: this.state.finalForm.passengers,
        ticketCount: this.state.ticketCount,
        videoCount: this.state.videoCount,
        slotId: this.state.finalForm.timeslotId
      };
    }

    return (
      <div className="row" style={{ minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>

        {/* <div className='sweet-loading' style={{ zIndex: 99, position: 'relative', top: '400px', right: '200px' }}>
          <ScaleLoader
            height={35}
            width={8}
            margin={'2px'}
            radius={2}
            color={'#123abc'}
            loading={this.state.isloading}
          />
        </div> */}

        <div
          className="col-md-12"
          style={{
            backgroundColor: 'rgba(235, 244, 247, 0.72)',
            overflowX: 'hidden',
            position: 'relative',
            minHeight: window.innerHeight
          }}
        >
          {/* {!this.state.hideMainForm ? (this.renderMainForm(finalForm)) : (<Invoice  goBack={this.showMainForm} isWeekEnd={this.state.isWeekEnd} eventName={this.state.EventName} priceArray={this.state.loadPrices} data={payload} />)} */}

          {this.renderMainForm(finalForm)}

          {/* {this.state.ticketCount > 0 && this.state.slotId !== '' && <Invoice giftCard={this.state.giftCard}  goBack={this.showMainForm} isWeekEnd={this.state.isWeekEnd} eventName={this.state.EventName}
               priceArray={this.state.loadPrices} data={payload} />} */}

          {/* {
            !this.state.hideMainForm ?
            (this.renderMainForm(finalForm)) :
            (<Invoice  goBack={this.showMainForm} isWeekEnd={this.state.isWeekEnd} eventName={this.state.EventName}
               priceArray={this.state.loadPrices} data={payload} />)
          } */}

          <span style={{ display: 'none' }}>
            <NabVelocity
              amount={totalAmount}
              terminalProfileId={loadsStore.getNabHostedFormTerminalId()}
              onProcessPayment={this.onPaymentCompletion}
              text="Card"
            />
          </span>


        </div>
      </div>
      //</div>
    );
  }
}

export default reservation;
