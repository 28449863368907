import React, { Component } from 'react';
import moment from 'moment';
import loadsStore from '../../stores/loads-store';
import CreateLoad from './modals/createLoad';
import ResetDay from './modals/resetDay';
import DetailsModal from './modals/reportModals/detailsModal';
import jumpersStore from '../../stores/jumpers-store';
import reportsStore from '../../stores/report-store';
import scheduleStore from '../../stores/schedule-store';
import RightColumnCards from '../sub-components/cards/rightColumnCards'
import ViewTodayWaiver from './modals/viewTodayWaivers';
import StaffRotation from './modals/staffRotation';
import Api from '../../services/reservationapi';
import reservationStore from '../../stores/reservation-store';
import SubmitStudentLog from './modals/submitStudentLog';
import Load from './LoadComponent';
import '../css/rightMenu.css'
import {
  Badge, Row, Col
} from 'reactstrap';
import DraggableStaff from './modals/draggableStaff';
import EditableRequest from './modals/editableRequest';
import Send_Multiple_ReminderEmails from '../schedules/sendMultipleReminderEmail';
import schedulesStore from '../../stores/schedule-store';

class LoadManagerRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailTemplates: [],
      totalFunJumps: 0,
      totalReviewSms: 0,
      totalPlaneSlots: 0,
      totalObservers: 0,
      totalTandemJumps: 0,
      totalTandemUltraJumps: 0,
      totalAffJumps: 0,
      totalLoads: 0,
      totalStudentGearRentals: 0,
      totalSportGearRentals: 0,
      totalVideos: 0,
      totalWristcams: 0,
      totalCoached: 0,
      totalCounterSales: 0,
      totalTandemReservations: 0,
      totalAffReservations: 0,
      totalCredit: 0,
      totalTaxCredit: 0,
      totalDebit: 0,
      checkPayout: 0,
      cashPayout: 0,
      totalPayout: 0,
      cardCollected: 0,
      cardTaxCollected: 0,
      cashCollected: 0,
      cashTaxCollected: 0,
      checkCollected: 0,
      checkTaxCollected: 0,
      onlineCollected: 0,
      onlineTaxCollected: 0,
      onlineFeesCollected: 0,
      totalCollected: 0,
      totalTaxCollected: 0,
      recentActivities: [],
      pilotOptions: [],
      planeOption: [],
      passengers: [],
      tabValue: 0,
      waiversData: [],
      activeStudents: [],
      activeTIs: [],
      activeAFFIs: [],
      activeCoaches: [],
      activeVideographers: [],
      activePackers: [],
      activeLOs: [],
      activeTeams: [],
      trackedJumpers: [],
      currentDate: null,
      schoolWaitlist: null,
      key: new Date().getTime(),
      jumperWaitlist: null,
      isSendEmailModalOpen : false,
      singleTimeSlots : false,
      selectedEventId : "",
      events:[],
    };

    this.handleNewActivity = this.handleNewActivity.bind(this);
    this.handleCompleteLoad = this.handleCompleteLoad.bind(this);
    this.handleResetDay = this.handleResetDay.bind(this);
    this.handlePilotsLoaded = this.handlePilotsLoaded.bind(this);
    this.handlePlanesLoaded = this.handlePlanesLoaded.bind(this);

    this.loadEvents = [
      { name: loadsStore.getEventPrefix('newActivity'), func: this.handleNewActivity },
      { name: loadsStore.getEventPrefix('completeLoadRight'), func: this.handleCompleteLoad },
      { name: loadsStore.getEventPrefix('resetDay'), func: this.handleResetDay },
      { name: loadsStore.getEventPrefix('planesLoaded'), func: this.handlePlanesLoaded },
      { name: loadsStore.getEventPrefix('getWaitlists'), func: this.handleWaitlists },
      { name: loadsStore.getEventPrefix('updateLoad_0'), func: this.handleUpdateSchoolWaitlist }
    ];
    this.jumperEvents = [
      { name: jumpersStore.getEventPrefix('pilotsLoaded'), func: this.handlePilotsLoaded },
      { name: jumpersStore.getEventPrefix('waiverUpdate'), func: this.handleWaiverUpdate },
      { name: jumpersStore.getEventPrefix('studentStatusUpdate'), func: this.handleStudentStatusUpdate },
      { name: jumpersStore.getEventPrefix('staffStatusUpdate'), func: this.handleStaffStatusUpdate },
      { name: jumpersStore.getEventPrefix('dropzoneDailyActivityUpdate'), func: this.handleCompleteLoad }
    ];
    this.reportsEvents = [
      { name: reportsStore.getEventPrefix('loadDropzoneDailyActivity'), func: this.handleLoadDropzoneTodayActivity }
    ];
    this.scheduleEvents = [
      { name: scheduleStore.getEventPrefix('todayReservationEvent'), func: this.handleReservationEvent },
      { name: scheduleStore.getEventPrefix('fetchPassengers'), func: this.handleEventSelectResponse },

    ];
  }

  componentDidUpdate = () => {
    window.globals = { ...window.globals };
    window.globals.LoadManagerRight = this.state;
  }

  componentWillMount = () => {
    //alert('load manager right component about to mount');
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func));
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
    this.reportsEvents.forEach((evnt) => reportsStore.on(evnt.name, evnt.func));
    this.scheduleEvents.forEach((evnt) => scheduleStore.on(evnt.name, evnt.func));

    console.log('about to get today timeslots from load manager right');
    //alert('about to fetch today reservations');
    scheduleStore.fetchTodayReservation();

    var waivers = jumpersStore.getTodayWaivers();

    if (window.globals && window.globals['LoadManagerRight']) {
      this.setState({ ...window.globals['LoadManagerRight'] });
    }
    // jumpersStore
    console.log('load manager right will mount');
    this.setState({
      pilotOptions: jumpersStore.getData('pilotOptions'),
      planeOption: loadsStore.getPlanesOptions(),
      waiversData: waivers,
      activeStudents: jumpersStore.getTodayActiveStudents(),
      activeTIs: jumpersStore.getTodayActiveTIs(),
      activeCoaches: jumpersStore.getTodayActiveCoaches(),
      activeAFFIs: jumpersStore.getTodayActiveAFFIs(),
      activeVideographer: jumpersStore.getTodayActiveVideographers(),
      activeLOs: jumpersStore.getTodayActiveLOs(),
      activeTeams: jumpersStore.getTodayActiveTeams(),
      trackedJumpers: jumpersStore.getTodayTrackedJumpers(),
      activePackers: jumpersStore.getTodayActivePackers(),
      schoolWaitlist: loadsStore.getSchoolWaitlist()
    });

    var dropzoneTodayActivity = {};
    dropzoneTodayActivity.summary = reportsStore.getDropzoneTodayActivity();
    //alert('component will mount');
    this.handleCompleteLoad(dropzoneTodayActivity);
    // var profileId, merchantProfileId, serviceID;
    /*if (window.ENV?.processorInfo.processor === "nab") {
      Api.startSession()
        .then(res => {
          console.log(res);
        });
    }*/



    // Api.getIdentityInfo()
    // 	.then(info => {
    // 		reservationStore.setProcessorVals(info.data);
    // 		profileId = info.data.applicationProfileId;
    // 		merchantProfileId = info.data.merchantProfileId;
    // 		serviceID = info.data.ServiceID;
    // 		return Api.startSession(info.data.IdentityToken);
    // 	})
    // 	.then(r => {
    // 		if (r.data) {
    // 			reservationStore.setTokENV?.l(r.data);
    // 		}
    // 		////console.log(reservationStore.getTokENV?.l());
    // 		return Api.getApplicationData(reservationStore.getTokENV?.l(), profileId);
    // 	})
    // 	.then(r => {
    // 		var applicationData = r.data;
    // 		////console.log(applicationData);
    // 		return Api.getServiceInformation(reservationStore.getTokENV?.l());
    // 	})
    // 	.then(r => {
    // 		return Api.isMerchantProfileInitialized(reservationStore.getTokENV?.l(), merchantProfileId, serviceID);
    // 	})
    // 	.then(r => {
    // 		////console.log(r.data);
    // 	})
  }

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
    this.reportsEvents.forEach((evnt) => reportsStore.removeListener(evnt.name, evnt.func));
    this.scheduleEvents.forEach((evnt) => scheduleStore.removeListener(evnt.name, evnt.func));
  }
  handleUpdateSchoolWaitlist = (waitlist) => {

    ////console.log("loadsloadsloadsloadsloads", load)
    //alert('handle update school waitlist');
    console.log(waitlist);
    this.setState({ schoolWaitlist: waitlist });
  }

  handleWaiverUpdate = (update) => {
    console.log('wavier added');
    //alert('waiver Added');
    var waivers = jumpersStore.getTodayWaivers();
    this.setState({ waiversData: waivers, Isdata: false })
  }

  handleWaitlists = (waitlists) => {
    console.log('handle waitlists');
    console.log(waitlists);
    // ////console.log("handleExistingLoads",loads)
    var usingWaitlist = false;

    this.setState({ schoolWaitlist: waitlists.schoolWaitlist, jumperWaitlist: waitlists.jumperWaitlist });
  }

  handleEventSelectResponse = (data) => {
    this.setState({ passengers: data.timeslots })
  };
  handlePilotsLoaded = (pilotOptions) => {
    //console.log('in handleLoadPilots');
    this.setState({
      pilotOptions: pilotOptions,
    });
  }
  handlePlanesLoaded = (planeOption) => {
    var option = [];
    planeOption.forEach(element => {
      option.push({ label: element.plane })
    })

    this.setState({
      planeOption: option,
    });
  }
  handleNewActivity = (activityString) => {
    var recentActivities = [...this.state.recentActivities];
    recentActivities.unshift(activityString);
    if (recentActivities.length > 10) {
      recentActivities.pop();
    }
    this.setState({
      recentActivities: recentActivities
    });
    localStorage.setItem("RecentActivites", recentActivities);
  }

  getTodayActiveStudents = () => {
  }
  handleStudentStatusUpdate = () => {
    this.setState({ activeStudents: jumpersStore.getTodayActiveStudents() });
  }
  handleStaffStatusUpdate = () => {
    console.log('handle staff status update');
    this.setState({
      activeTIs: jumpersStore.getTodayActiveTIs(),
      activeAFFIs: jumpersStore.getTodayActiveAFFIs(),
      activeCoaches: jumpersStore.getTodayActiveCoaches(),
      activeVideographers: jumpersStore.getTodayActiveVideographers(),
      activeLOs: jumpersStore.getTodayActiveLOs(),
      activeTeams: jumpersStore.getTodayActiveTeams(),
      activePackers: jumpersStore.getTodayActivePackers(),
      trackedJumpers: jumpersStore.getTodayTrackedJumpers()

    });
  }
  handleCompleteLoad(data) {
    if (data.summary) {
      var loadArray = [];
      loadArray.push({
        totalFunJumps: data.summary.numJumps,
        totalPlaneSlots: data.summary.numPlaneSlots,
        totalObservers: data.summary.numObservers,
        totalTandemJumps: data.summary.numTandems,
        totalTandemUltraJumps: data.summary.numTandemUltras,
        totalAffJumps: data.summary.numAffs,
        totalLoads: data.summary.numLoads,
        totalStudentGearRentals: data.summary.numStudentGearRentals,
        totalSportGearRentals: data.summary.numSportGearRentals,
        totalWristcams: data.summary.numWristcams,
        totalVideos: data.summary.numVideos,
        totalCoached: data.summary.numCoached,
        totalWingsuitCoached: data.summary.numWingsuitCoached,
        totalHopNPops: data.summary.numHopNpop,
        totalSolos: data.summary.numSolos,
        totalTandemReservations: data.summary.numTandemReservations,
        totalAffReservations: data.summary.numAffReservations,
        totalCounterSales: data.summary.counterSales,
        totalCredit: data.summary.totalCredit,
        totalTaxCredit: data.summary.totalTaxCredit,
        totalDebit: data.summary.totalDebit,
        cashPayout: data.summary.cashPayout,
        checkPayout: data.summary.checkPayout,
        totalPayout: data.summary.totalPayout,
        cashCollected: data.summary.cashCollected,
        cashTaxCollected: data.summary.cashTaxCollected,
        cardCollected: data.summary.cardCollected,
        cardTaxCollected: data.summary.cardTaxCollected,
        checkCollected: data.summary.checkCollected,
        checkTaxCollected: data.summary.checkTaxCollected,
        onlineCollected: data.summary.onlineCollected,
        onlineTaxCollected: data.summary.onlineTaxCollected,
        onlineFeesCollected: data.summary.onlineFeesCollected,
        totalCollected: data.summary.totalCollected,
        totalTaxCollected: data.summary.totalTaxCollected,
        totalReviewSms: data.summary.numReviewSmsSent
      })
      this.setState({
        totalFunJumps: data.summary.numJumps,
        totalPlaneSlots: data.summary.numPlaneSlots,
        totalObservers: data.summary.numObservers,
        totalTandemJumps: data.summary.numTandems,
        totalTandemUltraJumps: data.summary.numTandemUltras,
        totalAffJumps: data.summary.numAffs,
        totalLoads: data.summary.numLoads,
        totalGearRentals: data.summary.numGearRentals,
        totalStudentGearRentals: data.summary.numStudentGearRentals,
        totalSportGearRentals: data.summary.numSportGearRentals,
        totalWristcams: data.summary.numWristcams,
        totalVideos: data.summary.numVideos,
        totalCoached: data.summary.numCoached,
        totalWingsuitCoached: data.summary.numWingsuitCoached,
        totalHopNPops: data.summary.numHopNpop,
        totalSolos: data.summary.numSolos,
        totalTandemReservations: data.summary.numTandemReservations,
        totalAffReservations: data.summary.numAffReservations,
        totalCounterSales: data.summary.counterSales,
        totalCredit: data.summary.totalCredit,
        totalTaxCredit: data.summary.totalTaxCredit,
        totalDebit: data.summary.totalDebit,
        cashPayout: data.summary.cashPayout,
        checkPayout: data.summary.checkPayout,
        totalPayout: data.summary.totalPayout,
        cashCollected: data.summary.cashCollected,
        cashTaxCollected: data.summary.cashTaxCollected,
        cardCollected: data.summary.cardCollected,
        cardTaxCollected: data.summary.cardTaxCollected,
        checkCollected: data.summary.checkCollected,
        checkTaxCollected: data.summary.checkTaxCollected,
        onlineCollected: data.summary.onlineCollected,
        onlineTaxCollected: data.summary.onlineTaxCollected,
        onlineFeesCollected: data.summary.onlineFeesCollected,
        totalCollected: data.summary.totalCollected,
        totalTaxCollected: data.summary.totalTaxCollected,
        totalReviewSms: data.summary.numReviewSmsSent

      });
      localStorage.setItem("RecentJumps", JSON.stringify(loadArray));

      if (data.load) {
        this.setState({
          activeStudents: jumpersStore.getTodayActiveStudents(),
          activeTIs: jumpersStore.getTodayActiveTIs(),
          activeAFFIs: jumpersStore.getTodayActiveAFFIs(),
          activeCoaches: jumpersStore.getTodayActiveCoaches(),
          activeLOs: jumpersStore.getTodayActiveLOs(),
          activeTeams: jumpersStore.getTodayActiveTeams(),
          activeVideographers: jumpersStore.getTodayActiveVideographers(),
          activePackers: jumpersStore.getTodayActivePackers(),
          trackedJumpers: jumpersStore.getTodayTrackedJumpers()
        });
      }
    }
  }
  handleLogClick = (jumpid) => {
    document.getElementById(jumpid).click();
  }
  handleLoadDropzoneTodayActivity = (data) => {
    var r = {};
    r.summary = data;
    this.handleCompleteLoad(r);
  }

  handleReservationEvent = (data) => {
    console.log("in handle today reservations");
    //alert("in handle today reservations load manager right");
    console.log(data.timeslots);
    //alert('handle today reservations');
    let currentDate = new moment(new Date()).format("YYYY-MM-DD");

    this.setState({
      passengers: data.timeslots,
      currentDate,
    })
  }

  handleResetDay(data) {
    this.handleCompleteLoad(data);
  }

  checkForNegatives = (price) => {
    let priceCell = (price < 0) ?
      {
        value: `($${Math.abs(price).toFixed(2)})`
      } :
      {
        value: `$${price.toFixed(2)}`
      }

    if (price < 0) {
      return <span style={{ color: 'salmon' }}> {priceCell.value}</span>;
    }
    return priceCell.value;
  }

  loadEmailsTemplates = () => {
    console.log("calledschedulesStore");
    schedulesStore
      .getemailtemplates()
      .then((resp) => {
        console.log(resp, "resprespresprespresprespresp");
        this.setState({
          emailTemplates: resp.data,
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  changeTab(value) {
    this.setState({ tabValue: value })
    if (value === 3) {
      this.setState({
        activeStudents: jumpersStore.getTodayActiveStudents()
      });
    }
    if (value === 4) {
      this.setState({
        activeTIs: jumpersStore.getTodayActiveTIs(),
        activeAFFIs: jumpersStore.getTodayActiveAFFIs(),
        activeCoaches: jumpersStore.getTodayActiveCoaches(),
        activeLOs: jumpersStore.getTodayActiveLOs(),
        activeTeams: jumpersStore.getTodayActiveTeams(),
        activeVideographers: jumpersStore.getTodayActiveVideographers(),
        activePackers: jumpersStore.getTodayActivePackers(),
        trackedJumpers: jumpersStore.getTodayTrackedJumpers()
      });
    }
  }
  componentDidMount(){
    this.loadEmailsTemplates()
  }

  bookings = (id,singleTimeSlots,_selectedEmailTemplate) => {
    this.setState({
      selectedTemplate: _selectedEmailTemplate,
      selectedEmailTemplate: _selectedEmailTemplate,
      isSendEmailModalOpen : true,
      selectedEventId : id,
      singleTimeSlots : singleTimeSlots,
      events: this.state.passengers,
    });
  };

  returnBadge = () => {
    return <span class="badge badge-danger">
      {this.state.schoolWaitlist ? this.state.schoolWaitlist.totalJumpers : null}
    </span>
  }
  render() {
    var activityHistory = [];
    var recentJumps = [];
    var localActivity = localStorage.getItem("RecentActivites");
    if (localActivity != null) {
      activityHistory = localActivity.split(',');
    }
    var localjumps = localStorage.getItem("RecentJumps");

    if (localjumps != null) {
      recentJumps = JSON.parse(localjumps);
    }

    const recentActivities =
      activityHistory.map((activity, i) =>
        <li
          key={i}
          style={{
            padding: '5px',
            background: 'rgb(5, 91, 187)',
            borderRadius: '5px',
            paddingLeft: '15px',
            marginBottom: '5px'
          }}
        >
          {activity}
        </li>
      );

      const currentDate = new Date();

    return (
      <React.Fragment>
        {this.state.isSendEmailModalOpen && (
          <Send_Multiple_ReminderEmails currentSelectedDate={moment(currentDate).format("YYYY-MM-DD")} isMemoModalOpen = {this.state.isSendEmailModalOpen} state={this.state} setState={this.setState.bind(this)} selectedTemplate={this.state.emailTemplates.find((temp) => temp._id == this.state.selectedTemplate)} /> 
        )}
        <div className="tabsCon">
          <ul>
            {window.ENV?.ewaivers === false ?
              <li className={this.state.tabValue === 0 && 'active'} onClick={this.changeTab.bind(this, 0)}>Walkins</li> :
              <li className={this.state.tabValue === 0 && 'active'} onClick={this.changeTab.bind(this, 0)}>Waivers</li>
            }
            <li className={this.state.tabValue === 1 && 'active'} onClick={this.changeTab.bind(this, 1)}>Day Summary</li>
            <li className={this.state.tabValue === 2 && 'active'} onClick={this.changeTab.bind(this, 2)}>Reservation</li>
          </ul>
          <ul>
            <li className={this.state.tabValue === 3 && 'active'} onClick={this.changeTab.bind(this, 3)}>Students</li>
            <li className={this.state.tabValue === 4 && 'active'} onClick={this.changeTab.bind(this, 4)}>Staff</li>
            {window.ENV?.enableTeamFeature ? <li className={this.state.tabValue === 6 && 'active'} onClick={this.changeTab.bind(this, 6)}>Teams/LOs/etc..</li> : null}
            {window.ENV?.schoolWaitlist ?
              <li
                className={this.state.tabValue === 5 ? 'active with-badge' : 'with-badge'}
                onClick={this.changeTab.bind(this, 5)}>
                School Waitlist
                {this.returnBadge()}

              </li> : null
            }
          </ul>
        </div>

        <div className="tabContentCon">
          {this.state.tabValue === 0 &&
            <ViewTodayWaiver data={this.state.waiversData} />
          }
          {this.state.tabValue === 1 &&
            <div className="loadSummary" style={{ overflowY: 'auto' }}>
              <table align="left">
                <tbody align="left">
                  <tr>
                    <td className="without-burger">LOADS : </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalLoads}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">OBSERVERS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalObservers}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">REVIEW SMS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalReviewSms}</td>
                  </tr>
                  <tr>
                    <td><DetailsModal reportType={"planeSlots"} subType={"planeSlots"} labelText={"PLANE SLOTS :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalPlaneSlots}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType={"funJumps"} subType={"hopnpop"} labelText={"HOP N POPS :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalHopNPops}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType={"funJumps"} subType={"student"} labelText={"SOLO STUDENT :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalSolos}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType={"funJumps"} subType={"regular"} labelText={"FUN JUMPS :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalFunJumps}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType={"tandem"} subType={"tandem"} labelText={"TANDEM JUMPS :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalTandemJumps}</td>
                  </tr>
                  <tr>
                    {window.ENV?.isSTP ? <td>
                      <DetailsModal reportType={"aff"} labelText={"STP JUMPS :"} /> </td> : <td>
                      <DetailsModal reportType={"aff"} labelText={"AFF JUMPS :"} /> </td>}
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalAffJumps}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType={"coach"} subType={"regular"} labelText={"COACH JUMPS :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalCoached}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType={"coach"} subType={"wingsuit"} labelText={"WINGSUIT COACH JUMPS :"} />
                    </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalWingsuitCoached}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">WRISTCAM JUMPS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalWristcams}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">VIDEOS JUMPS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalVideos}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">STUDENT GEAR RENTALS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalStudentGearRentals}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">SPORT GEAR RENTALS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalSportGearRentals}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">TANDEM RESERVATIONS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalTandemReservations}</td>
                  </tr>
                  <tr>
                    <td className="without-burger">AFF RESERVATIONS :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalAffReservations}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType={"countersales"} subType={"all"} labelText={"COUNTER SALES :"} />
                    </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalCounterSales}</td>
                  </tr>

                  <tr style={{ marginTop: 5 }}>
                    <td className="without-burger" style={{ paddingTop: 10 }}> TOTAL CREDIT :</td>
                    <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.totalCredit > 0 ? this.checkForNegatives(this.state.totalCredit) : 0}</td>
                  </tr>
                  {loadsStore.isTaxEnabled() ?
                    <tr style={{ marginTop: 5 }}>
                      <td className="without-burger" style={{ paddingTop: 10 }}> TOTAL TAX  :</td>
                      <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.totalTaxCredit > 0 ? this.checkForNegatives(this.state.totalTaxCredit) : 0}</td>
                    </tr> : null
                  }
                  <tr>
                    <td className="without-burger">TOTAL DEBIT :</td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalDebit < 0 ? this.checkForNegatives(this.state.totalDebit) : 0}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"Cash Payout"} labelText={"CASH PAYOUTS :"} />
                    </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.cashPayout > 0 ? this.checkForNegatives(this.state.cashPayout) : 0}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"Check Payout"} labelText={"CHECK PAYOUTS :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.checkPayout > 0 ? this.checkForNegatives(this.state.checkPayout) : 0}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"Payout"} labelText={"PAYOUTS :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalPayout > 0 ? this.checkForNegatives(this.state.totalPayout) : 0}</td>
                  </tr>
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"Cash"} labelText={"CASH COLLECTED :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.cashCollected > 0 ? this.checkForNegatives(this.state.cashCollected) : 0}</td>
                  </tr>
                  {loadsStore.isTaxEnabled() ?
                    <tr style={{ marginTop: 5 }}>
                      <td className="without-burger" style={{ paddingTop: 10 }}> CASH TAX COLLECTED :</td>
                      <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.cashTaxCollected > 0 ? this.checkForNegatives(this.state.cashTaxCollected) : 0}</td>
                    </tr> : null
                  }
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"Card"} labelText={"CARD COLLECTED :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.cardCollected > 0 ? this.checkForNegatives(this.state.cardCollected) : 0}</td>
                  </tr>
                  {loadsStore.isTaxEnabled() ?
                    <tr style={{ marginTop: 5 }}>
                      <td className="without-burger" style={{ paddingTop: 10 }}> CARD TAX COLLECTED :</td>
                      <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.cardTaxCollected > 0 ? this.checkForNegatives(this.state.cardTaxCollected) : 0}</td>
                    </tr> : null
                  }
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"Check"} labelText={"CHECK COLLECTED :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.checkCollected > 0 ? this.checkForNegatives(this.state.checkCollected) : 0}</td>
                  </tr>
                  {loadsStore.isTaxEnabled() ?
                    <tr style={{ marginTop: 5 }}>
                      <td className="without-burger" style={{ paddingTop: 10 }}> CHECK TAX COLLECTED :</td>
                      <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.checkTaxCollected > 0 ? this.checkForNegatives(this.state.checkTaxCollected) : 0}</td>
                    </tr> : null
                  }
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"Online"} labelText={"ONLINE COLLECTED :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.onlineCollected > 0 ? this.checkForNegatives(this.state.onlineCollected) : 0}</td>
                  </tr>
                  <tr style={{ marginTop: 5 }}>
                    <td className="without-burger" style={{ paddingTop: 10 }}> ONLINE FEES COLLECTED :</td>
                    <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.onlineFeesCollected > 0 ? this.checkForNegatives(this.state.onlineFeesCollected) : 0}</td>
                  </tr>
                  {loadsStore.isTaxEnabled() ?
                    <tr style={{ marginTop: 5 }}>
                      <td className="without-burger" style={{ paddingTop: 10 }}> ONLINE TAX COLLECTED :</td>
                      <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.onlineTaxCollected > 0 ? this.checkForNegatives(this.state.onlineTaxCollected) : 0}</td>
                    </tr> : null
                  }
                  <tr>
                    <td>
                      <DetailsModal reportType="collections" subType={"all"} labelText={"TOTAL COLLECTED :"} /> </td>
                    <td style={{ paddingLeft: '20px' }}>{this.state.totalCollected > 0 ? this.checkForNegatives(this.state.totalCollected) : 0}</td>
                  </tr>
                  {loadsStore.isTaxEnabled() ?
                    <tr style={{ marginTop: 5 }}>
                      <td className="without-burger" style={{ paddingTop: 10 }}> TOTAL TAX COLLECTED :</td>
                      <td style={{ paddingTop: 10, paddingLeft: '20px' }}>{this.state.totalTaxCollected > 0 ? this.checkForNegatives(this.state.totalTaxCollected) : 0}</td>
                    </tr> : null
                  }
                </tbody>
              </table>
            </div>
          }
          {(this.state.tabValue === 2 && this.state.passengers != undefined) ?
            <RightColumnCards
              event={this.state.passengers}
              currentDate={this.state.currentDate}
              style={{ background: 'white' }}
              isCollapsable={true}
              edit={true}
              _bookings={this.bookings}  
              templates={this.state.emailTemplates} 
              emailTemplates={this.state.emailTemplates}

            />
            :
            ''
          }
          {
            (this.state.tabValue === 3 && this.state.passengers != undefined) ?
              <React.Fragment>

                <h2>Waiting</h2><br />
                {this.state.activeStudents.filter(student => student.manifestStatus.length === 0 && (student.studentJumpCount === 0 || student.waiting)).map(student => {
                  return (<React.Fragment><DraggableStaff jumperId={student.jumperId} name={student.name} /></React.Fragment>)
                })}
                <br />
                <br />
                <h2>Manifested</h2><br />
                {this.state.activeStudents.filter(student => student.manifestStatus.length > 0).map(student => {
                  return (<React.Fragment><DraggableStaff jumperId={student.jumperId} name={student.name} />-<h5>load <span style={{ fontSize: '18px' }}>{student.manifestStatus[0].load} {student.manifestStatus[0].planeLoad}</span></h5></React.Fragment>)
                })}
                <br />
                <br />
                <h2>Jumped</h2><br />
                {this.state.activeStudents.filter(student => !student.waiting && student.manifestStatus.length == 0 && student.studentJumpCount > 0).map(student => {
                  return (<React.Fragment><DraggableStaff jumperId={student.jumperId} name={student.name} />-<h5><Badge>{student.studentJumpCount} jumps</Badge>{student.jumpedStatus.length > 0 ? <React.Fragment>last jump: load {student.jumpedStatus[student.jumpedStatus.length - 1].load} {student.jumpedStatus[student.jumpedStatus.length - 1].planeLoad}</React.Fragment> : null}<br /></h5></React.Fragment>)
                })}
              </React.Fragment>
              : null
          }
          {this.state.tabValue === 4 &&
            <StaffRotation activePackers={this.state.activePackers} activeTIs={this.state.activeTIs} activeAFFIs={this.state.activeAFFIs} activeTeams={this.state.activeTeams} activeLOs={this.state.activeLOs} activeCoaches={this.state.activeCoaches} activeVideographers={this.state.activeVideographers} />
          }
          {this.state.tabValue === 6 &&
            <React.Fragment>

              <h2>LOs</h2><br />
              {this.state.activeLOs.map(jumper => {
                return (
                  <React.Fragment>
                    <DraggableStaff jumperId={jumper.jumperId} name={jumper.name} />
                    <Row><Col md={6}>JUMPS:</Col><Col><strong>{jumper.numFunJumps}-{jumper.funJumpManifestedCount}</strong></Col></Row>
                    <Row><Col md={6}>JUMPS REQUESTED:</Col><Col><EditableRequest type={"jumprequest"} jumperId={jumper.jumperId} value={jumper.jumpRequest} /></Col></Row>
                    <Row><Col md={6}>CALL REQUESTED:</Col><Col><EditableRequest type={"callrequest"} jumperId={jumper.jumperId} value={jumper.callRequest} /></Col></Row>
                  </React.Fragment>
                )
              })}
              <br />
              <br />
              <h2>Teams</h2><br />
              {this.state.activeTeams.map(jumper => {
                return (
                  <React.Fragment>
                    <DraggableStaff jumperId={jumper.jumperId} name={jumper.name} isTeam={true} />
                    <Row><Col md={6}>JUMPS:</Col><Col><strong>{jumper.teamJumps.count}-{jumper.teamManifestedCount}</strong></Col></Row>
                    <Row><Col md={6}>JUMPS REQUESTED:</Col><Col><EditableRequest type={"jumprequest"} jumperId={jumper.jumperId} value={jumper.jumpRequest} /></Col></Row>
                    <Row><Col md={6}>CALL REQUESTED:</Col><Col><EditableRequest type={"callrequest"} jumperId={jumper.jumperId} value={jumper.callRequest} /></Col></Row>
                  </React.Fragment>
                )
              })}
              <br />
              <br />
              <h2>Tracking</h2><br />
              {this.state.trackedJumpers.map(jumper => {
                return (
                  <React.Fragment>
                    <DraggableStaff jumperId={jumper.jumperId} name={jumper.name} isTeam={true} />
                    <Row><Col md={6}>JUMPS:</Col><Col><strong>{jumper.numFunJumps}-{jumper.funJumpManifestedCount}</strong></Col></Row>
                    <Row><Col md={6}>SLOT PRICE:</Col><Col><EditableRequest type={"slotprice"} jumperId={jumper.jumperId} value={jumper.todaySlotPrice!==undefined && !isNaN(Number(jumper.todaySlotPrice)) ? jumper.todaySlotPrice : 'none'} /></Col></Row>
                    <Row><Col md={6}>JUMPS REQUESTED:</Col><Col><EditableRequest type={"jumprequest"} jumperId={jumper.jumperId} value={jumper.jumpRequest} /></Col></Row>
                    <Row><Col md={6}>CALL REQUESTED:</Col><Col><EditableRequest type={"callrequest"} jumperId={jumper.jumperId} value={jumper.callRequest} /></Col></Row>
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          }
          {this.state.tabValue === 5 && window.ENV?.schoolWaitlist &&
            <Load
              key={this.state.key}
              history={this.props.history}
              isCompleted={false}
              isHistory={false}
              load={loadsStore.getSchoolWaitlist()}
              loadNum={0}
              loads={loadsStore.getActiveLoadsForDisplay()}
              jumperOptions={jumpersStore.getJumperOptions()}
              tandemInstructorOptions={jumpersStore.getTandemInstructorCheckedInOptions()}
              tandemStudentOptions={jumpersStore.getTandemStudentOptions()}
              affInstructorOptions={jumpersStore.getAffInstructorCheckedInOptions()}
              affStudentOptions={jumpersStore.getAffStudentOptions()}
              iadStudentOptions={jumpersStore.getAffStudentOptions()}
              videographerOptions={jumpersStore.getVideographerCheckedInOptions()}
              coachOptions={jumpersStore.getCoachCheckedInOptions()}
              isRightMenu={true}
            />
          }
        </div>

        <div className="resetBtn" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <ResetDay />
        </div>
      </React.Fragment>
    );
  }
}

export default LoadManagerRight;
