import React, { Component } from 'react'
import { Table } from 'reactstrap';

import CreateAltitude from '../modals/createAltitude';
import RemoveItem from '../modals/removeItem';

export default class Altitudes extends Component {

    renderAltitudes = () => {
        let { altitudes, checkForNegatives, categories } = this.props;
        if (altitudes && altitudes.length > 0) {
            return altitudes.map((item, index) => {
                return (
                    <tr key={`planRow${index}`}>

                        <th scope="row" data-id={item._id}>
                            {index + 1}
                        </th>
                        <td >
                            {item.name}
                        </td>
                        <td >
                            {item.startAltitude}
                        </td>
                        <td>
                            {item.endAltitude}
                        </td>
                        <td>
                            {checkForNegatives(item.price)}
                        </td>
                        <td >
                            {item.isDefault ? "yes" : "no"}
                        </td>
                        <td >
                            <CreateAltitude isEdit={true} item={item} categories={categories} />
                        </td>
                        <td>
                            <RemoveItem item={item} itemType={"altitude"} />
                        </td>
                    </tr>
                )
            })
        }
        else {
            return null
        }
    }
    render() {
        let { categories } = this.props;
        return (
            <Table >
                <thead className="main-heading">
                    <tr>
                        <th><CreateAltitude categories={categories} /></th>
                        <th>Name</th>
                        <th>Start altitude</th>
                        <th>End altitude</th>
                        <th>Price</th>
                        <th>Default</th>
                        <th colSpan="2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="priceHeading"><td colSpan="3">Altitudes</td></tr>
                    {this.renderAltitudes()}
                </tbody>
            </Table>
        )
    }
}
