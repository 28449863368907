
import React, { Component, useState, useEffect, useRef } from 'react';
import { Collapse, Button } from 'reactstrap';
import ChangeSlot from '../../schedules/reservation/changeSlot';
import moment from 'moment-timezone';
import DeleteReservation from './deleteReservation';
import jumpersStore from '../../../stores/jumpers-store';
import schedulesStore from '../../../stores/schedule-store';
import reservationStore from '../../../stores/reservation-store';
import ViewWaiver from "../../accounts/modals/viewWaiver";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  Card, CardBody, Row, Col, Badge, Modal, ModalHeader, ModalBody, Label, ModalFooter, Tooltip, UncontrolledTooltip
} from 'reactstrap';
import scheduleStore from '../../../stores/schedule-store';

import AdminModal from '../../admin/modals/customModal';
import swal from 'sweetalert';
import './rightColumnCards.css';
import ButtonComponents from './ButtonComponents';
import { DeleteMemo } from './deleteMemo';
import Send_Confirmation_Email from '../../sendConfirmationEmail/sendConfirmationEmail';
import AddJumper from '../../schedules/reservation/addJumper';
import DeleteModal from '../Modal/DeleteModal';
import Api from '../../../services/reservationapi';
import { SendEmailSelect } from '../../schedules/schedulesCenter';
import ChangeTimeslotSeatCapacity from '../../schedules/reservation/changeTimeslotSeatCapacity';

class RightColumnCard extends Component {

  constructor(props) {
    super(props);
    this.updateSuspended = this.updateSuspended.bind(this);
    this.ReservationId = '';
    this.reservationInfo = '';
    this.slotId = '';
    this.currentSlotDate = '';
    this.state = {
      TransactionId: '',
      TransactionDateTime: '',
      Amount: 0,
      slotId: this.props.key,
      dropdownOpenStates: {},
      dropdownOptions: [],
      loading: false,
      suspendedIs: {},
      isAttachModalOpen: false,      //state related to attach waiver confirmation modal
      reservationName: null,
      waiverName: null,
      reservationJumperId: null,
      waiverJumperId: null,
      reservationType: null,
      eventId: null,
      reservationId: null,
      memo: null,
      isMemoModalOpen: false,
      isSendEmailModalOpen: false,
      isAddJumperModalOpen: false,
      changeSeatCapacityModalOpen: false,
      selectedResEventId: "",
      changeSeatCapOfEvent: {},
      emailId: "",
      reservation: {},
      event: [],
      suspendData: [],
      idObj: {},
      eventData: [],
      reservationData: [],
      totalBook: {},
      blackoutId: {}
    };
    this.jumperEvents = [
      { name: jumpersStore.getEventPrefix('waiverSearch'), func: this.handleWaiverSearchResult }
    ];
    //this.handleClick = this.handleClick.bind(this);
  }


  componentWillMount = () => {
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));

  }

  componentWillUnmount = () => {
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
  }

  componentDidMount() {
    if (this.props.event?.length) {
      let idObj = {}
      let blackoutId = {}
      console.log(this.props.event, "=->this.props.event")
      // let totalBook = {}
      const resArrays = this.props.event.forEach(r => {
        blackoutId[r._id] = r.isBlackedOut;
        r.reservations.forEach(d => {
          idObj[d._id] = d.suspended;
        })
      })
      const resArray = this.props.event.map(r => {
        return r.reservations;
      })
      this.setState({ idObj, blackoutId })
      this.setState({ eventData: this.props.event })
    }
  }


  getJumperDetail(jumperId, type, props) {
    const event = {
      start: props.currentDate,
      resource: {
        _id: props.eventId
      }
    }
    if (type === "Tandem") {
      jumpersStore.getDailyTandemActivity(jumperId, event);
    }
    else if (type === "Experienced" || type === "AFF") {
      jumpersStore.getDailyActivity(jumperId);
    }
  }


  componentWillReceiveProps(nextProps) {
    this.setState({ eventData: nextProps.event })
  }

  async removeJumper(jumperId, type, props, distribute) {
    const payload = {
      jumperId: jumperId,
      type: type,
      values: props,
      distribute: distribute
    }
    const result = await scheduleStore.removeJumper(payload);
    if (!result.succeed) {
      swal({
        title: "Error",
        text: "Something went wrong,Please try again",
        icon: "error",
        dangerMode: true,
      });
    } else {
      swal({
        title: "Success",
        text: "Jumper removed Successfully",
        icon: "success",
        dangerMode: false,
        closeModal: true,
      });
      const event = {
        start: props.currentDate,
        resource: {
          _id: props.eventId
        }
      }
      await schedulesStore.fetchPassengers(event);
    }
  }

  handleWaiverSearchResult = (options) => {
    //alert('waiver search results');
    // console.log(options);
    this.setState({ dropdownOptions: options, loading: false });
  }

  attachWaiver = (reservationJumperId, waiverJumperId, type) => {
    var payload = {
      reservationJumperId: reservationJumperId,
      waiverJumperId: waiverJumperId
    }
    if (type === "tandem") {
      jumpersStore.attachWaiver(payload);
      scheduleStore.fetchTodayReservation();
    }
    else {
      jumpersStore.attachStudentWaiver(payload);
    }
    this.setState({ isAttachModalOpen: false });

  }

  toggle = (val, id, firstname, lastname, type) => {
    // console.log('in toggle');
    // console.log(val);

    var dropDownStates = this.state.dropdownOpenStates;
    if (dropDownStates[id]) {
      dropDownStates[id] = !dropDownStates[id];
      if (dropDownStates[id]) {
        this.setState({ loading: true });
        if (type === "tandem") {
          jumpersStore.searchTandemWaivers(firstname, lastname);
        }
        else {
          jumpersStore.searchStudentWaivers(firstname, lastname);
        }
      }
      else {
        this.setState({ loading: false });
      }
    }
    else {
      dropDownStates[id] = true;
      this.setState({ loading: true });
      if (type === "tandem") {
        jumpersStore.searchTandemWaivers(firstname, lastname)
      }
      else {
        jumpersStore.searchStudentWaivers(firstname, lastname);
      }
    }
    // console.log(dropDownStates);
    this.setState({ dropdownOpenStates: dropDownStates });
  }

  renderWaiverDropDown2 = (firstname, lastname, id, type) => {

    return (
      <Dropdown size="sm" isOpen={this.state.dropdownOpenStates[id]} toggle={(val) => this.toggle(val, id, firstname, lastname, type)}>
        <DropdownToggle caret>
          Attach Waiver
        </DropdownToggle>
        <DropdownMenu>
          {this.state.loading ? <DropdownItem>Please Wait ...</DropdownItem> : this.state.dropdownOptions.map(option => <DropdownItem onClick={() => this.openAttachWaiverConfirmationModal(firstname + " " + lastname, id, option.firstname + " " + option.lastname, option._id, type)}>{option.firstname} {option.lastname}</DropdownItem>)}
        </DropdownMenu>
      </Dropdown>
    );

  }

  renderWaiverDropDownStudents = (firstname, lastname, id) => {

    return (
      <Dropdown size="sm" isOpen={this.state.dropdownOpenStates[id]} toggle2={(val) => this.toggle(val, id, firstname, lastname)}>
        <DropdownToggle caret>
          Attach Waiver
        </DropdownToggle>
        <DropdownMenu>
          {this.state.loading ? <DropdownItem>Please Wait ...</DropdownItem> : this.state.dropdownOptions.map(option => <DropdownItem onClick={() => this.openAttachWaiverConfirmationModal(firstname + " " + lastname, id, option.firstname + " " + option.lastname, option._id)}>{option.firstname} {option.lastname}</DropdownItem>)}
        </DropdownMenu>
      </Dropdown>
    );

  }

  renderWaiverDropDown = (firstname, lastname) => {
    jumpersStore.searchTandemWaivers(firstname, lastname)
      .then(options => {

        // console.log(options);
        return (
          <Dropdown size="sm" >
            <DropdownToggle caret>
              Attach Waiver
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>TEST</DropdownItem>
              {options.map(option => { return (<DropdownItem>{option.firstname} {option.lastname}</DropdownItem>) })}
            </DropdownMenu>
          </Dropdown>);
      })
  }

  onMemoAdd = (eventId, reservationId) => {
    this.setState({
      eventId: eventId,
      reservationId: reservationId,
      isMemoModalOpen: !this.state.isMemoModalOpen
    })

  }
  add_jumper = (reservation, eventId) => {
    this.setState({ isAddJumperModalOpen: true, reservation: reservation, event: this.props.event, selectedResEventId: eventId })
  }

  sendEmail = (email, reservation) => {
    this.setState({ isSendEmailModalOpen: true, emailId: email, reservation: reservation, event: this.props.event })
  }

  updateSuspended(suspended) {
    this.setState({ suspendedIs: suspended })
  }

  updateIds = (data) => {

    this.setState({
      idObj: data
    })
  }
  updateTickets = (data) => {
    this.setState({ reservationData: data.reservations })
  }

  openChangeTimeslotSeatCapModal = (event) => {
    this.setState({ changeSeatCapOfEvent: event, changeSeatCapacityModalOpen: true });
  }

  generateList({ reservations, _id: eventId, eventName, totalSeats, seatsRemaining, eventCategory, datetime }, bookings) {
    let rows = [];
    let box = [];
    var indx = 0;

    for (var i = 0; i < reservations.length; i++) {
      let squares = [];
      var reservation = reservations[i];
      let updateIsSuspend = reservations[i].suspended;
      if (this.state.suspendedIs._id === reservations[i]._id) {
        updateIsSuspend = this.state.suspendedIs.suspended;
      }
      else if (this.state.suspendedIs._id !== reservations[i]._id) {
        updateIsSuspend = reservations[i].suspended;
      }
      var isCancel = reservations[i].cancelled;
      const isPaused = this.state.idObj[reservations[i]._id]
      const discount = reservations[i].discount ? <tr><td>Discount:</td><td> -${reservations[i].discountValue.toFixed(2)}</td></tr> : null;
      const discountValue = reservations[i].discountValue ? reservations[i].discountValue : 0;

      box.push(
        <div key={`_box_${i}`} className={`card  ${(isCancel) ? "rightCancelCards" : "rightColumnCards"}`} style={{ width: '100%' }}>
          <div className={`${isPaused ? "suspended-body" : "card-body"}`} style={{ padding: "0px" }}>
            {this.props.isCollapsable && <React.Fragment>
              <ul>
                <GenerateReservationListList
                  updateIds={this.updateIds}
                  updateTickets={this.updateTickets}
                  reservation={reservations[i]}
                  bookings={bookings[i]}
                  index={i}
                  updateSuspended={this.updateSuspended}
                  isPaused={isPaused}
                  key={i}
                  idObj={this.state.idObj}
                  totalBook={this.state.totalBook}
                  eventId={eventId}
                  eventName={eventName}
                  totalSeats={totalSeats}
                  seatsRemaining={seatsRemaining}
                  edit={this.props.edit}
                  showSingle={this.props.showSingle}
                  handleSelect={this.props.handleSelect}
                  currentDate={this.props.currentDate}
                  onMemoAdd={this.onMemoAdd}
                  add_jumper={this.add_jumper}
                  sendEmail={this.sendEmail}
                  datetime={datetime}
                  loading={this.state.loading}
                  dropdownOpenStates={this.state.dropdownOpenStates}
                  dropdownOptions={this.state.dropdownOptions}
                  openAttachWaiverConfirmationModal={this.openAttachWaiverConfirmationModal}
                  closeAttachWaiverModal={this.closeAttachWaiverModal}
                  renderWaiverDropDown={this.renderWaiverDropDown}
                  renderWaiverDropDown2={this.renderWaiverDropDown2}
                  getJumperDetail={this.getJumperDetail}
                  removeJumper={this.removeJumper}
                  eventCategory={eventCategory}
                />
              </ul>
            </React.Fragment>}
          </div>
        </div>);
    }
    rows.push(box);
    return rows;
  }


  openAttachWaiverConfirmationModal = (reservationName, reservationId, waiverName, waiverId, type) => {
    this.setState({ reservationName: reservationName, waiverName: waiverName, reservationJumperId: reservationId, waiverJumperId: waiverId, isAttachModalOpen: !this.state.isAttachModalOpen, reservationType: type })
  }

  closeAttachWaiverModal = () => {
    this.setState({ isAttachModalOpen: false });
  }

  handleMemoChange = (memo) => {
    this.setState({
      memo: memo
    })
  }

  onSubmitMemo = () => {
    if (this.state.memo) {
      var payLoad = {
        id: this.state.reservationId,
        memo: this.state.memo
      }
      reservationStore.addReservationMemo(this.state.eventId, payLoad)
        .then(result => {
          // var eventObj=this.props.event.filter(x=>{ return x._id===this.state.eventId})
          var timeSlot = {
            id: this.state.eventId,
            date: this.props.currentDate
          }
          timeSlot.date = this.props.currentDate ? this.props.currentDate : new moment(new Date()).format("YYYY-MM-DD")
          schedulesStore.fetchReservation(timeSlot);
          this.setState({
            isMemoModalOpen: false,
            memo: ""
          })
        })
        .catch(err => {
          swal({
            title: "Error",
            text: "Something goes wrong please try later",
            icon: "error",
            dangerMode: true,
          });
        })
    }
    else {
      swal({
        title: "Error",
        text: "Memo is empty",
        icon: "error",
        dangerMode: true,
      });
    }

  }
  closeMemoModal = () => {
    this.setState({ isMemoModalOpen: !this.state.isMemoModalOpen })
  }

  render() {

    const { event = [{}] } = this.props;
    const totalAvailable = event.reduce((total, itm) => total + itm.totalSeats, 0);
    const totalboookings = (this.state.eventData || []).map(e => (e.reservations || []).reduce((total, itm) => total + (itm.cancelled ? 0 : itm.ticketCount), 0))
    const sortedEvent = event.sort((a, b) => (new Date(a.datetime).getTime() - new Date(b.datetime).getTime()))
    const totalBooked = (totalboookings || []).reduce((a, b) => a + b, 0)
    return (
      <React.Fragment>
        <Send_Confirmation_Email templates={this.props.templates} isMemoModalOpen={this.state.isSendEmailModalOpen} state={this.state} setState={this.setState.bind(this)} />
        <AddJumper isMemoModalOpen={this.state.isAddJumperModalOpen} state={this.state} setState={this.setState.bind(this)} />
        <ChangeTimeslotSeatCapacity isChangeSeatCapacityModalOpen={this.state.changeSeatCapacityModalOpen} state={this.state} setState={this.setState.bind(this)} />
        <Modal id="modal-main1" isOpen={this.state.isMemoModalOpen} >
          <ModalHeader style={{ paddingLeft: '42%' }}>Add Memo</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="pilot" className="col-md-4 ">Enter Memo :</label>
              <div className="col-md-8">
                <input type="text" name="amount" className="form-control"
                  value={this.state.memo}
                  onChange={(e) => this.handleMemoChange(e.target.value)} autoComplete="off" />

              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onSubmitMemo} >Submit</Button>
            <Button color="primary" onClick={this.closeMemoModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <AdminModal isModalOpen={this.state.isAttachModalOpen}
          title={`Attach Waiver confirmation`}  >

          <Row className="form-group m-t-2-rem">
            <Col md={{ size: 9 }}>
              <span>attach {this.state.waiverName} waiver to {this.state.reservationName}</span>
            </Col>
          </Row>
          <Row className="form-group m-t-2-rem">
            <Col md={{ size: 9 }}>
              <button onClick={(e) => { e.preventDefault(); this.attachWaiver(this.state.reservationJumperId, this.state.waiverJumperId, this.state.reservationType); }} className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
              <button onClick={(e) => { e.preventDefault(); this.closeAttachWaiverModal(); }} className="btn btn-link">CANCEL</button>
            </Col>
          </Row>

        </AdminModal>

        <h5> Booking Summary : {totalBooked}/{totalAvailable}</h5>
        {
          (event !== undefined && event.length > 0)
            ? sortedEvent.map((evnt, i) => (
              <CollapsableDiv
                style={this.props.style}
                emailTemplates={this.props?.emailTemplates || []}
                key={`__collapsableDiv_${evnt._id}`}
                _bookings={this.props._bookings}
                event={evnt}
                selectedTimeSlot={this.props.selectedTimeSlot}
                title={moment(evnt.datetime).tz(evnt.timeZone).format("hh:mm a")}
                isCollapsable={this.props.isCollapsable || false}
                isCheckBoxShow={this.props.isCheckBoxShow || false}
                bookings={<span style={{ float: 'right' }}><button className="bookingsBtn" onClick={() => this.openChangeTimeslotSeatCapModal(evnt)}>Bookings {totalboookings[i]}/{evnt.totalSeats}</button></span>}
                eventName={<span> {evnt.eventName} </span>}

                body={
                  <div key={`__${evnt._id}`}>
                    <div style={{ textAlign: "left", fontSize: "19px" }}>
                      {!this.props.isCollapsable && evnt.reservations.length > 0 && moment(evnt.datetime).tz(evnt.timeZone).format("hh:mm a")}
                    </div>
                    <div key={evnt._id}>
                      {this.generateList(evnt, totalboookings)}
                    </div>
                  </div>
                }
              />
            ))
            : null}
      </React.Fragment>
    );
  }
}

const GenerateReservationListList = (props) => {

  const [isCollapse, setIsCollapse] = useState(false);
  const [reservationList, setReservationList] = useState([]);
  const [reservation, setReservation] = useState({});
  const [isToolTip, setIsToolTip] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [distribute, setDistribute] = useState(false);
  const [itemId, setItemId] = useState(null)
  const [isSuspended, setIsSuspended] = useState(false);
  const [suspendData, setSuspendData] = useState([]);

  let email = "";


  useEffect(() => {
    setIsSuspended(props.isPaused)
  })

  const updateMemo = (reservation, index) => {
    let memo = [...reservation.memo];
    memo.splice(index, 1)
    const payload = {
      id: reservation._id,
      memo: memo
    }
    reservationStore.updateReservationMemo(props.eventId, payload).then((response) => {
      var timeSlot = {
        id: props.eventId,
        date: props.currentDate
      }
      timeSlot.date = props.currentDate ? props.currentDate : new moment(new Date()).format("YYYY-MM-DD")
      schedulesStore.fetchReservation(timeSlot);

    })
  }

  useEffect(() => {
    renderList();
  }, [props.reservation, props.dropdownOpenStates, props.dropDownOptions])




  const onCollasable = () => setIsCollapse(!isCollapse);
  var squares = [];
  var reservationName = {};
  // console.log("PROPPPPS: ",props)
  const renderList = () => {
    var reservation = props.reservation;
    var isCancel = reservation.cancelled;

    const handleReservation = (bool) => {
      let ticketCount = 0;

      let payload = {
        suspended: bool,
        id: reservation._id,
        eventId: props.eventId,
      }
      props.idObj[reservation._id] = bool
      reservationStore.sendIsSuspended(payload).then(res => {
        props.updateIds({ ...props.idObj, [reservation._id]: bool })
        let filteredRes = res.reservations.filter((e) => e._id === reservation._id);
        props.reservation.ticketCount = filteredRes[0].ticketCount;
        props.updateTickets(res)
        schedulesStore.fetchData(props.datetime);
        return props.reservation.ticketCount;
      })
    }

    const discount = reservation.discount ? <tr><td>Discount:</td><td> -${reservation.discountValue.toFixed(2)}</td></tr> : null;
    const discountValue = reservation.discountValue ? reservation.discountValue : 0;

    reservation.eventName = props.eventName;
    reservation.evenCategory = props.eventCategory;
    reservation.datetime = props.datetime;

    if (props.edit) {
      //alert(props.showSingle);

      squares.push(
        <div>
          <span id="DisabledAutoHideExample" href="#" style={{ cursor: 'pointer', color: 'blue', marginLeft: "2%" }}
            onClick={() => props.onMemoAdd(props.eventId, reservation._id)} too
          >
            <i className="fa fa-sticky-note" ></i>
          </span>
          <UncontrolledTooltip placement="right" autohide={false} target="DisabledAutoHideExample">
            Add Memo
          </UncontrolledTooltip>
        </div>
        // <Button color="primary" onClick={() => props.onMemoAdd(props.eventId, reservation._id)}><i classNames="fa fa-sticky-note"></i></Button>
      )

      if (reservation.memo)
        squares.push(
          reservation.memo.map((item, index) => (
            <li className="reserved-list" style={{ display: "flex", alignItems: "center", marginBottom: "0px" }}>Memo: {item} <i className="fa fa-trash" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => updateMemo(reservation, index)}></i></li>
          ))
        )

      squares.push(<li key={`_squares_${props.index}`} className="reserved-list">


        {
          (props.idObj[reservation._id]) ?
            <div style={{ position: "absolute", cursor: "pointer", color: "blue", top: "24px", right: "12px" }}>
              <button style={{ background: "none", border: "none", color: "blue" }} onClick={() => handleReservation(false)}><i class="fa fa-play" aria-hidden="true"></i></button>
            </div> : null
        }
        {!(props.idObj[reservation._id]) && !isCancel ?
          <div style={{ position: "absolute", cursor: "pointer", color: "blue", top: "24px", right: "12px" }}>
            <button style={{ background: "none", border: "none", color: "blue" }} onClick={() => handleReservation(true)}><i class="fa fa-pause" aria-hidden="true"></i></button>
          </div> : null
        }
        {
          !isCancel &&
          <DeleteReservation reservation={reservation} currentDate={props.currentDate} suspendReservation={props.idObj[reservation._id]} showSingle={props.showSingle} handleSelect={props.handleSelect} />
        }
        {(props.idObj[reservation._id]) ? "" : <div className='add_btn_primary'>
          <span className='add_btn' onClick={() => props.add_jumper(reservation, props.eventId, props.eventName)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
          </span>
        </div>}
        {(props.idObj[reservation._id]) ? <div className="send_email_btn_primary">
          <span className="send_email_btn" style={{ right: '40px' }} onClick={() => props.sendEmail(email, reservation)}>
            <i className="fa fa-paper-plane"></i>
          </span>
          <span className="send_email_text">Send Reservation<br /> Reminder Email</span>
        </div> : <div className="send_email_btn_primary">
          <span className="send_email_btn" style={{ right: '102px' }} onClick={() => props.sendEmail(email, reservation)}>
            <i className="fa fa-paper-plane"></i>
          </span>
          <span className="send_email_text">Send Reservation<br /> Reminder Email</span>
        </div>}
        <ChangeSlot oldSlotId={props.eventId} reservationId={reservation._id} currentDate={props.currentDate} eventName={props.eventName} eventCategory={props.eventCategory} suspendReservation={props.idObj[reservation._id]} />
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th style={{ textAlign: 'center' }}>Count</th>
              <th style={{ textAlign: 'right' }}>Price</th>
            </tr>
          </thead>
          <tbody>
            {/* {
            this.state.deposit === false ? */}

            {
              reservation.invoice.map(pkg => {
                return (
                  <tr>
                    <td>{pkg.item}</td>
                    <td style={{ textAlign: 'center' }}>{pkg.count === 0 ? "-" : pkg.count}</td>
                    <td style={{ textAlign: 'right' }}>${pkg.value?.toFixed(2)}</td>
                  </tr>
                )
              })
            }
            <tr>
              <td>Booking Fees:</td>
              <td style={{ textAlign: 'center' }}></td>
              <td style={{ textAlign: 'right' }}>${reservation.totalFees.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Processing Fees:</td>
              <td style={{ textAlign: 'center' }}></td>
              <td style={{ textAlign: 'right' }}>${reservation.processingFees.toFixed(2)}</td>
            </tr>
            {
              reservation.totalTax ?
                <tr>
                  <td>Tax:</td>
                  <td style={{ textAlign: 'center' }}></td>
                  <td style={{ textAlign: 'right' }}>${reservation.totalTax.toFixed(2)}</td>
                </tr>
                : null
            }
            <tr>
              <td>Total:</td>
              <td style={{ textAlign: 'center' }}></td>
              <td style={{ textAlign: 'right' }}>${(reservation.totalValue + reservation.totalFees + reservation.totalTax + (reservation.processingFees ? reservation.processingFees : 0)).toFixed(2)}</td>
            </tr>
            {reservation.refundAmount ?
              <tr>
                <td>Refund Amount:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(reservation.refundAmount).toFixed(2)}</td>
              </tr>
              : null
            }
            {reservation.refundFeesAmount ?
              <tr>
                <td>Refund Fees Amount:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(reservation.refundFeesAmount).toFixed(2)}</td>
              </tr>
              : null
            }
            {reservation.refundTaxAmount ?
              <tr>
                <td>Refund Tax Amount:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(reservation.refundTaxAmount).toFixed(2)}</td>
              </tr>
              : null
            }

          </tbody>
        </table>
      </li>)
    }
    else {

      squares.push(
        <div><span id="DisabledAutoHideExample" href="#" style={{ cursor: 'pointer', color: 'blue', marginLeft: "2%" }} onClick={() => props.onMemoAdd(props.eventId, reservation._id)} too>
          <i className="fa fa-sticky-note" ></i>
        </span>
          <UncontrolledTooltip placement="right" autohide={false} target="DisabledAutoHideExample">
            Add Memo
          </UncontrolledTooltip>
        </div>
      )

      if (reservation.memo) {
        squares.push(
          <li className="reserved-list">Memo: {reservation.memo}</li>
        )
      }

      squares.push(<li key={`_squares_${props.index}`} className="reserved-list">
        <table className="bookings-table-right">
          {
            reservation.invoice.map(pkg => {
              return (
                <tr>
                  <td>{pkg.item}</td>
                  <td style={{ textAlign: 'center' }}>{pkg.count}</td>
                  <td style={{ textAlign: 'right' }}>${(pkg.value).toFixed(2)}</td>
                </tr>
              )
            })
          }
          <tr>
            <td>Fees:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${reservation.totalFees.toFixed(2)}</td>
          </tr>
          {discount}
          <tr>
            <td>Total:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(reservation.totalValue + reservation.totalFees + reservation.totalTax + (reservation.processingFees ? reservation.processingFees : 0)).toFixed(2)}</td>
          </tr>
          {reservation.refundAmount ?
            <tr>
              <td>Refund Amount:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(reservation.refundAmount).toFixed(2)}</td>
            </tr>
            : null
          }
          {reservation.refundFeesAmount ?
            <tr>
              <td>Refund Fees Amount:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(reservation.refundFeesAmount).toFixed(2)}</td>
            </tr>
            : null
          }
          {reservation.refundTaxAmount ?
            <tr>
              <td>Refund Tax Amount:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(reservation.refundTaxAmount).toFixed(2)}</td>
            </tr>
            : null
          }
        </table>
      </li>)
    }

    squares.push(<li className="reserved-list">made on: {new Date(reservation.createdAt).toLocaleDateString() + " " + new Date(reservation.createdAt).toLocaleTimeString()}</li>)

    if (window.ENV.tenant === "montereybay") {
      squares.push(<li className="reserved-list">terms and conditions accepted on: {new Date(reservation.createdAt).toLocaleDateString() + " " + new Date(reservation.createdAt).toLocaleTimeString()}</li>)
    }

    if (reservation.refundedAt) {
      squares.push(<li className="reserved-list">refunded on: {new Date(reservation.refundedAt).toLocaleDateString() + " " + new Date(reservation.refundedAt).toLocaleTimeString()}</li>)
    }

    if (reservation.fromIncompleteReservationEmail) {
      squares.push(<li className="reserved-list">made from : automated followup email</li>)
    }

    squares.push(<li className="reserved-list">ticket count: {reservation.ticketCount}</li>)

    squares.push(<li className="reserved-list">method: {reservation.method}</li>)

    if (reservation.site) {
      squares.push(<li className="reserved-list">site: {reservation.site}</li>)
    }

    squares.push(<li className="reserved-list">masked card: {reservation.maskedCardNum}</li>)
    if (reservation.grouponPackageName) {
      squares.push(<li className="reserved-list">groupon package: {reservation.grouponPackageName}</li>)
    }
    if (reservation.grouponCode) {
      squares.push(<li className="reserved-list">groupon code: {reservation.grouponCode}</li>)
    }

    if (reservation.giftPackageName) {
      squares.push(<li className="reserved-list">giftcard name: {reservation.giftPackageName}</li>)
    }

    if (reservation.giftCode) {
      squares.push(<li className="reserved-list">giftcard code: {reservation.giftCode}</li>)
    }

    if (reservation.transactionError) {
      squares.push(<li className="reserved-list">Transaction Error: {reservation.transactionError}</li>)
    }

    if (props.eventName.toLowerCase() === "tandem" || props?.eventCategory?.toLowerCase() === "tandem") {

      for (var j = 0; j < reservation.tandemStudents.length; j++) {
        reservationName = props.reservation.tandemStudents[0];
        //setReservation(reservationName);
        let itm = props.reservation.tandemStudents[j];
        if (itm.email !== " " && itm.email) {
          email = itm.email
        }
        if (itm.tandemWaiverId === undefined && itm.siteWaiverId === undefined) {
          var student = jumpersStore.searchWaiver(itm._id);
          if (student.tandemWaiverId !== undefined || student.siteWaiverId !== undefined) {
            itm.siteWaiverId = student.siteWaiverId;
            itm.tandemWaiverId = student.tandemWaiverId;

            itm.email = student.email;
            itm.phone = student.phone;
          }
        }


        squares.push(<li key={`_reserved_${j}`} className="reserved-list" key={itm._id}>
          <b>Students:</b>
          <div style={{ display: "flex" }}>
            <div >
              <Button color="primary"><a onClick={(e) => { e.preventDefault(); props.getJumperDetail(itm._id, "Tandem", props); }}>{itm.firstname} {itm.lastname}</a></Button>
              {itm.video !== "none" ? `[${itm.video}]` : ''}
              {itm.siteWaiverId !== undefined && itm.siteWaiverId !== "" && itm.siteWaiverId !== null ? <span>View Site Waiver <ViewWaiver waiverId={itm.siteWaiverId} /> </span> : null}
              {itm.tandemWaiverId !== undefined && itm.tandemWaiverId !== "" && itm.tandemWaiverId !== null ? <span>View Tandem Waiver <ViewWaiver waiverId={itm.tandemWaiverId} /> </span> : null}
              {props.renderWaiverDropDown2(itm.firstname, itm.lastname, itm._id, 'tandem')}
              <span>{itm.email} </span>
              <span>{itm.phone}</span>
            </div>
            {j !== 0 &&
              <>
                <div style={{ display: "flex", flexDirection: "column" }} className="trash_icon">
                  <i className="fa fa-trash fa_fa_trash" color="primary" style={{ marginLeft: "20px" }} onClick={(e) => { e.preventDefault(); handleDelete(itm._id, "false") }}></i>
                  <span className="remove_jumper">Remove<br /> Jumper</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }} className="remove_icon">
                  <i className="fa fa-remove fa_fa_trash" color="primary" onClick={(e) => { e.preventDefault(); handleDelete(itm._id, "true") }}></i>
                  <span className="distribute_amount">Remove Jumper<br />Distribute Amount  </span>
                </div>
              </>}
          </div>
          <br />
        </li>);
      }

    }


    if (props.eventName.toLowerCase() === "aff" || props?.eventCategory?.toLowerCase() === "aff") {


      for (var j = 0; j < reservation.students.length; j++) {
        reservationName = props.reservation.students[0];
        //setReservation(reservationName);

        let itm = props.reservation.students[j];
        if (itm.email !== " " && itm.email) { email = itm.email }
        if (itm.siteWaiverId === undefined) {
          var waiverRow = jumpersStore.searchWaiver(itm._id);
          if (waiverRow !== undefined) {
            itm.siteWaiverId = waiverRow.siteWaiverId;
          }
        }
        squares.push(<li key={`_reserved_${j}`} className="reserved-list" key={itm._id}>
          <b>Students:</b>
          <span><Button color="primary"><a onClick={(e) => { e.preventDefault(); props.getJumperDetail(itm._id, "AFF", props); }}>{itm.firstname} {itm.lastname}</a></Button> {itm.video && itm.video !== "none" ? `/(w ${itm.video})` : ''}</span>
          {itm.siteWaiverId !== undefined ? <span>View Site Waiver <ViewWaiver waiverId={itm.siteWaiverId} /> </span> : null}
          {props.renderWaiverDropDown2(itm.firstname, itm.lastname, itm._id, 'student')}
          <span>{itm.email} </span>
          <span>{itm.phone}</span>
        </li>);
      }

    }

    return (squares);
    //setReservationList(squares)
  }

  const returnStudentName = () => {
    var result;

    if ((props.eventName.toLowerCase() === "tandem" || props?.eventCategory?.toLowerCase() === "tandem") && props.reservation.tandemStudents !== undefined && props.reservation.tandemStudents[0] !== undefined) {
      result = props.reservation.tandemStudents[0].firstname + " " + props.reservation.tandemStudents[0].lastname
    }

    else if (props.reservation.students !== undefined && props.reservation.students[0] !== undefined) {
      result = props.reservation.students[0].firstname + " " + props.reservation.students[0].lastname

    }

    if (result !== undefined) {
      let m = result.split(' ')
      if (m[0].length > 15) {
        return <div class="col-md-7 pr-0 handle-font">{result} {props.isPaused === true ? "(Suspended)" : ''}</div>
      }
      else {
        return <div class="col-md-7 pr-0">{result} {props.isPaused === true ? "(Suspended)" : ''}</div>
      }
    }

  }


  var numCheckedIn = 0, numManifested = 0, numJumped = 0;
  if (props.eventCategory.toLowerCase() === "tandem") {
    numCheckedIn = jumpersStore.getTotalTandemCheckedIn(props.reservation.tandemStudents);
    numManifested = jumpersStore.getTotalTandemManifested(props.reservation.tandemStudents);
    numJumped = jumpersStore.getTotalTandemJumped(props.reservation.tandemStudents);
  }
  else if (props.eventCategory.toLowerCase() === "aff") {
    numCheckedIn = jumpersStore.getTotalStudentCheckedIn(props.reservation.students);
    numManifested = jumpersStore.getTotalStudentManifested(props.reservation.students);
    numJumped = jumpersStore.getTotalStudentJumped(props.reservation.students);
  }

  const handleDelete = (id, distributeAmount) => {
    setIsModalOpen(true)
    setItemId(id)
    setDistribute(distributeAmount);
  }

  const confirmDelete = () => {
    props.removeJumper(itemId, "Tandem", props, distribute);
    setIsModalOpen(false)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <React.Fragment>
      <DeleteModal isOpenModal={isModalOpen} confirmDelete={confirmDelete} closeModal={closeModal} title="remove jumper" />
      <h6 style={{ textTransform: 'capitalize', textAlign: 'left' }} onClick={() => { setIsCollapse(!isCollapse) }}>
        <div className="row">
          {returnStudentName()}
          <div class="col-md-3 p-0" style={{ textAlign: "center" }}>
            <p>${(props.reservation.totalValue).toFixed(2)}</p>
          </div>

          {props.reservation.ticketCount ?
            <div class="col-md-2 p-0" style={{ textAlign: "center" }}>
              {console.log(props.isPaused, '===>props')}

              <p>{props.reservation.ticketCount}</p>
            </div> :
            <div class="col-md-2 p-0" style={{ textAlign: "center" }}>
              <p>
                {props.eventName.toLowerCase() === "tandem" ? props.reservation.tandemStudents.length : props.reservation.students.length}

              </p>
            </div>
          }
        </div>

        {numManifested > 0 || numJumped > 0 ?
          <div className="row">
            <div className="col"><h5>{numJumped > 0 ? <Badge color="success">{numJumped} jumped</Badge> : null} {numManifested > 0 ? <Badge color="primary" style={{ scaleX: 4.7, scaleY: 4.7 }}>{numManifested} manifested</Badge> : null}</h5></div>
          </div>
          : null
        }
        {numCheckedIn > 0 && numManifested === 0 && numJumped === 0 ?
          <div className="row">
            <div className="col"><h5><Badge color="info">{numCheckedIn} checked in</Badge></h5></div>
          </div>
          : null
        }
      </h6>

      {/* {console.log("420 reservation", props, props.reservation.invoice, reservation.invoice, reservationList)} */}
      {/* {props.reservation.invoice.map((value,index)=>{
  return <h2>{value[index]}</h2>
 })} */}

      <Collapse isOpen={isCollapse}>

        {
          <h5 className="card-title" style={{ textTransform: 'capitalize', textAlign: 'left' }}>
            {props.eventName}
            {/* {
        !this.props.isCollapsable &&
        <React.Fragment>
          <br /><span style={{ fontSize: '1.2rem', color: '#353535' }}>Bookings :{props.totalSeats - props.seatsRemaining}/{props.totalSeats}</span>
        </React.Fragment>
      } */}
          </h5>

        }
        {renderList()}
        {

          /*reservationList*/
        }
      </Collapse>
    </React.Fragment>
  )
}

const CollapsableDiv = (props) => {
  const [isOpen, setIsOpen] = useState(props.selectedTimeSlot === props.title);
  const [isChecked, setIsChecked] = useStateCallback(null);

  const toggle = () => {
    // console.log("collapsable div")
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    setIsChecked(props.event.isBlackedOut);
  }, [])

  const handleSendEmail = (value) => {
    props._bookings(props.event._id, true, value);
  }

  return (
    <React.Fragment>

      {
        !props.isCollapsable ?
          props.body :
          <div style={{ ...props.style }} className="closable-container">

            <div class="row">
              <div className={props.isCheckBoxShow ? "col-md-6 col-lg-8" : "col-md-12"}>
                <Button block color="link" onClick={toggle}
                  style={{ textAlign: 'left' }}>
                  {props.eventName}
                  {props.title}
                  {props.bookings}
                </Button>

                {/* <button className="timeSlotBtn btn mr-1"
                  onClick={handleSendEmail}
                >Send Reminder Email</button> */}
                <SendEmailSelect onSelect={handleSendEmail} templates={props?.emailTemplates || []} />
              </div>
              <ButtonComponents {...props} />
            </div>
            {props.body || <h6>No Bookings yet</h6>}
          </div>
      }
    </React.Fragment>
  );
}

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = (state, cb) => {
    cbRef.current = cb;
    setState(state);
  };

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}


export default RightColumnCard;
