import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "../css/login.css";
import { Redirect } from 'react-router';
import background from '../images/background.svg';
import logo from '../images/logo.svg';
import authStore from '../../stores/auth-store';
import jumpersStore from '../../stores/jumpers-store';
import loadsStore from '../../stores/loads-store';
import reportsStore from '../../stores/report-store';
import { red } from "@material-ui/core/colors";


export default class Login extends Component {
    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this);
        this.state = {
            username: "",
            password: "",
            Invalid: false,
            modal: false,
        };
    }


    handleBack() {
        this.props.history.push('/schedules-tandem-aff')
    }

    render() {
        return (
            <div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
                <button onClick={this.handleBack} className="ifram-bookingBack subscribe btn btn-primary" type="button" style={{ position: 'absolute', right: '19px', top: '-2px' }}>X</button>
                <iframe src="http://localhost:5004/" width="100%" height="768"></iframe>
            </div>

        );
    }
}