/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
    Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import reportStore from '../../../stores/report-store';

import AdminModal from './customModal';

class EditPrice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            errors: {}
        }
    }

    toggleModal = (e) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    handleSubmit = (values) => {
        //console.log("in handle submit load"+ values);
        const { errors } = this.state;
        var payload = {};
        var errorMesage = {};
        let id = this.props.item._id;
        if (values != undefined && values.price !== undefined && errors["price"] !== "") {
            // console.log(values.price);
            if (this.props.updateType === "PaySchedule") {
                payload.price = parseInt(values.price);
                reportStore.UpdatePaySchedule(id, payload);
            }
            else if (this.props.updateType === "PriceSchedule") {
                payload.price = parseInt(values.price);
                payload.type = this.props?.item?.type;
                payload.isTaxable = values.taxable ? true : false;
                reportStore.UpdatePriceSchedule(id, payload);
            }
            // errorMesage.price = "";

            this.setState({
                // errors:errorMesage,
                isModalOpen: !this.state.isModalOpen,
            })
        }
        else {
            errorMesage.price = "Prices Can not be empty";
            this.setState({
                errors: errorMesage
            })

            setTimeout(() => {
                errorMesage.price = "";
                this.setState({ errors: errorMesage });
            }, 2000)
        }
    }

    handleValidation(name) {
        let fields = this.state;
        let errors = fields.errors;
        if (fields[name] === '') errors[name] = "This field is required";
        else if (name === 'price' && !fields[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Price should be enter in two decimal places.";
        else errors[name] = "";
        this.setState({ errors: errors });
    }

    handleInput = (event, key) => {
        var cache = { ...this.state };
        cache[key] = event.target.value;
        this.setState({ ...cache }, () => {
            this.handleValidation(key);
        });
    };

    render() {
        let { updateType } = this.props;
        let item = { price: "", isTaxable: false, type: "" };
        if (this.props.item !== undefined) {
            item = this.props.item;
        }
        // console.log("ITMES: ", item);
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Edit Price`}>

                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="type" md={3}>Type</Label>
                            <Col md={9}>
                                <Control.input
                                    name="type"
                                    model=".type"
                                    className="form-control"
                                    defaultValue={item.type}
                                    value={item.type}
                                    required
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input
                                    name="price"
                                    model=".price"
                                    type="number"
                                    className="form-control"
                                    defaultValue={item.price}
                                    required
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                />

                            </Col>
                        </Row>
                        {
                            updateType === "PriceSchedule" ?
                                <Row className="form-group">
                                    <Label htmlFor="taxable" md={3}>Taxable</Label>
                                    <Col md={9} style={{ marginTop: '7px' }}>
                                        <Control.checkbox name="taxable" model=".taxable"
                                            mapProps={{ onChange: (props) => props.onChange }}
                                            defaultValue={item.isTaxable}
                                            autoComplete="off" />
                                    </Col>
                                </Row>
                                : null
                        }

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>

                    </LocalForm>
                </AdminModal>
                <span onClick={this.toggleModal}>
                    <i className="fa fa-edit"></i>
                </span>
            </React.Fragment>
        );
    }
}

export default EditPrice
