
import { EventEmitter } from 'events';
import Api from '../services/accountapi';
import * as moment from 'moment';
import TerminalApi from '../services/terminalapi';
import reportsStore from '../stores/report-store';
import jumperStore from '../stores/jumpers-store';
import TransnationalTerminalApi from '../services/gotnpayTerminalapi';
import * as JSPM from 'jsprintmanager';
import swal from 'sweetalert';

class AccountStore extends EventEmitter {
    constructor() {
        super();
        this.prefix = 'ACCOUNT_STORE_';
        this.JSPM_WS_Status = null;
        this.JSPM_WS_Port = 0;
        if (window.ENV?.arizona) {

            var wlPort = (new URLSearchParams(window.location.search)).get('wlport');

            if (wlPort == null) wlPort = 25443;


            this.JSPM_WS_Port = wlPort;

            //alert('before JSPM start');
            JSPM.JSPrintManager.auto_reconnect = true;
            JSPM.JSPrintManager.start(true, 'localhost', wlPort);

            JSPM.JSPrintManager.MainApp = this;
            JSPM.JSPrintManager.WS.onOpen = function () {
                JSPM.JSPrintManager.MainApp.jspmWsStatusChanged(JSPM.WSStatus[JSPM.JSPrintManager.WS.status]);
                //accountsStore.jspmWsStatusChanged(JSPM.WSStatus[JSPM.JSPrintManager.WS.status]);
            };
        }
    }

    initJSPM = () => {
        console.log('initing JSPM');
        if (window.ENV?.arizona) {
            console.log('arizona');
            var wlPort = (new URLSearchParams(window.location.search)).get('wlport');

            if (wlPort == null) wlPort = 25443;


            this.JSPM_WS_Port = wlPort;

            //alert('before JSPM start');
            JSPM.JSPrintManager.auto_reconnect = true;
            JSPM.JSPrintManager.start(true, 'localhost', wlPort);

            JSPM.JSPrintManager.MainApp = this;
            JSPM.JSPrintManager.WS.onOpen = function () {
                JSPM.JSPrintManager.MainApp.jspmWsStatusChanged(JSPM.WSStatus[JSPM.JSPrintManager.WS.status]);
                //accountsStore.jspmWsStatusChanged(JSPM.WSStatus[JSPM.JSPrintManager.WS.status]);
            };
        }
    }

    printReceipt = (charge) => {
        console.log(JSPM.JSPrintManager.websocket_status, "<<-websocket -status");

        let itemizedArray = [];
        Object.keys(charge.itemized).forEach(key => {
            itemizedArray.push(...charge.itemized[key]);
        })
        console.log(itemizedArray);
        let itmCmd = '';
        var newLine = '\x0A';
        var subtotal = 0;
        var tax = 0;
        var total = 0;

        itemizedArray.forEach(item => {
            subtotal += item.price;
            tax += item.tax;
            var itemPadded = item.item.padEnd(20, " ");
            var qtyPadded = item.qty.toString().padEnd(7, " ");
            var pricePadded = item.price.toFixed(2);
            itmCmd += `${itemPadded}   ${qtyPadded}   $${pricePadded}`;
            itmCmd += newLine;
        })

        total = subtotal + tax;
        itmCmd += newLine;
        itmCmd += `SUBTOTAL             $${subtotal.toFixed(2)}`;
        itmCmd += newLine;
        itmCmd += `TAX                  $${tax.toFixed(2)}`;
        itmCmd += newLine;
        itmCmd += `TOTAL                $${total.toFixed(2)}`;
        itmCmd += newLine + newLine;
        var transactionTypeString = charge.transactionType;
        if (charge.maskedCardNum) {
            transactionTypeString += "- " + charge.maskedCardNum
        }
        itmCmd += `Paid with            ${transactionTypeString}`;

        let role = localStorage.getItem("role");
        var businessName = 'Para Drop Corp, DBA Skydive Arizona'
        if (role === "school") {
            businessName = 'Adventures In Skydiving at Skydive Arizona'
        }

        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
            console.log('about to print, wstatus is open');
            var cpj = new JSPM.ClientPrintJob();
            cpj.clientPrinter = new JSPM.SerialPortPrinter("COM6", parseInt(9600), JSPM.Serial.Parity["None"], JSPM.Serial.StopBits["One"], JSPM.Serial.DataBits["Eight"], JSPM.Serial.Handshake["None"]);
            var esc = '\x1B'; //ESC byte in hex notation
            var newLine = '\x0A'; //LF byte in hex notation

            var cmds = esc + "@"; //Initializes the printer (ESC @)
            cmds += esc + '!' + '\x18'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
            cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
            cmds += newLine + newLine;
            cmds += (new Date()).toLocaleDateString() + " " + (new Date()).toLocaleTimeString();
            cmds += newLine;
            cmds += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
            cmds += 'SKYDIVE ARIZONA'; //text to print
            cmds += newLine + newLine;
            cmds += esc + '\x61' + '\x31';
            cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
            cmds += businessName; //text to print
            cmds += newLine;
            cmds += '4900 N. Taylor Street';
            cmds += newLine;
            cmds += 'Eloy, AZ 85131';
            cmds += newLine + newLine;
            cmds += esc + '\x61' + '\x00';
            cmds += itmCmd;
            cmds += newLine + newLine;
            cmds += newLine + newLine;
            cmds += newLine + newLine;
            cmds += newLine + newLine;
            cmds += newLine + newLine;

            cpj.printerCommands = cmds;
            //Send print job to printer!
            cpj.sendToClient();
        }
    }
    printTickets = (blockName, unitPrice, ticketsRemaining, name, paymentType) => {
        console.log('in print tickets');
        function get_labelCode(x_pos, y_pos, text, font_id, font_size, rotation = 0) {

            function __adjust_number_length(value, length) {
                while (value.length < length) {
                    value = '0' + value
                }
                return value
            }
            var rot_value = 1  // default = no rotation
            if (rotation == 90) {
                rot_value = 2
            }
            else if (rotation == 180) {
                rot_value = 3
            }
            else if (rotation == 270) {
                rot_value = 4
            }

            // Adjust lengths
            x_pos = __adjust_number_length(x_pos.toString(), 4)
            y_pos = __adjust_number_length(y_pos.toString(), 4)

            var size = '000'
            var width_multiplier = 1
            var height_multiplier = 1
            if (font_id === 9) {
                size = 'A' + __adjust_number_length(font_size.toString(), 2)
            }
            else {
                if (font_size.toString().length == 2) {
                    width_multiplier = font_size[0]
                    height_multiplier = font_size[1]
                }
            }

            var data = rot_value.toString() + font_id.toString() + width_multiplier.toString() + height_multiplier.toString() + size + y_pos + x_pos + text;

            return data
        }

        console.log('JSMP defined');
        console.log(JSPM);

        console.log(JSPM.JSPrintManager.websocket_status, "<<-websocket -status");
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
            console.log('about to print, wstatus is open');
            var cpj = new JSPM.ClientPrintJob();
            cpj.clientPrinter = new JSPM.SerialPortPrinter("COM7", parseInt(9600), JSPM.Serial.Parity["None"], JSPM.Serial.StopBits["One"], JSPM.Serial.DataBits["Eight"], JSPM.Serial.Handshake["None"]);
            var labelFormatString = get_labelCode(140, 500, blockName, 9, "30", 90);
            console.log(labelFormatString);

            var labelFormatString2 = get_labelCode(10, 400, name, 9, "18", 90);
            console.log(labelFormatString2);

            var today = new Date();
            var labelFormatString3 = get_labelCode(45, 530, today.toLocaleDateString("en-US"), 9, "12", 90);
            console.log(labelFormatString3);

            var labelFormatString4 = get_labelCode(28, 530, `$${unitPrice}`, 9, "12", 90);
            console.log(labelFormatString4);

            var labelFormatString5 = get_labelCode(10, 530, paymentType, 9, "12", 90);
            console.log(labelFormatString5);

            var labelFormatString6 = get_labelCode(90, 480, 'Have A Good Jump & Be Safe', 9, "12", 90);
            console.log(labelFormatString6);

            var labelFormatString7 = get_labelCode(75, 480, 'Do Not Leave In Heat/Direct Sunlight', 9, "10", 90);
            console.log(labelFormatString7);

            var numTicketsCmd = "Q0002\x0d";
            if (ticketsRemaining < 10) {
                numTicketsCmd = `Q000${ticketsRemaining}\x0d`;
            }
            else if (ticketsRemaining >= 10 && ticketsRemaining < 100) {
                numTicketsCmd = `Q00${ticketsRemaining}\x0d`;
            }
            else if (ticketsRemaining >= 100 && ticketsRemaining < 1000) {
                numTicketsCmd = `Q0${ticketsRemaining}\x0d`;
            }
            else {
                alert('psychotic');
                console.log('must be psycho to be in here')
                numTicketsCmd = `Q${ticketsRemaining}\x0d`;
            }

            console.log(numTicketsCmd);
            var cmds = '';
            cmds += '\x02L\x0d';
            cmds += 'H09\x0d';
            cmds += 'D11\x0d';
            cmds += labelFormatString;
            cmds += '\x0d';
            cmds += labelFormatString2;
            cmds += '\x0d';
            cmds += labelFormatString3;
            cmds += '\x0d';
            cmds += labelFormatString4;
            cmds += '\x0d';
            cmds += labelFormatString5;
            cmds += '\x0d';
            cmds += labelFormatString6;
            cmds += '\x0d';
            cmds += labelFormatString7;
            cmds += '\x0d';
            cmds += '1a6210000000050';
            cmds += '590';
            cmds += 'PCS\x0d';
            cmds += numTicketsCmd;
            cmds += 'E\x0d';


            cpj.printerCommands = cmds;
            //Send print job to printer!
            cpj.sendToClient();
        }

    }

    jspmWsStatusChanged = (status) => {
        //alert(status);
        this.JSPM_WS_Status = status;
    }


    processCharge = (id, r, payload, isloading = false) => {
        console.log('in processCharge frontend');
        //var activeLoadDisplay = this.transformLoad(r.data);
        //var newActivity = "Load " + r.data.loadNum + " created";
        //this.emitResults(newActivity, 'newActivity');
        //this.emitResults(activeLoadDisplay,'createLoad');
        if (!isloading) {
            this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'chargeAdded');
        }
        // this.emitResults(true, 'chargeCompleted');
        reportsStore.setDropzoneTodayActivity(r.data.summary);
        if (window.ENV?.arizona && Number(r.data?.chargeBody?.amount) > 0.01) {
            console.log(r.data.dailyActivity);

            var itemizedSales = [];

            Object.keys(payload.itemized).forEach(key => {
                itemizedSales.push(...payload.itemized[key]);
            })

            console.log(itemizedSales);
            let role = localStorage.getItem("role");

            r.data.dailyActivity?.blocks?.forEach(block => {
                if (role !== "school" && itemizedSales.some(item => item._id?.toString() === block.saleId?.toString())) {
                    var counterSale = r.data.dailyActivity?.countersales.filter(sale => sale._id === block.saleId)[0];
                    console.log(counterSale);
                    if (counterSale !== undefined && (counterSale.price - counterSale.totalPaid) < 0.01 && (counterSale.taxAmount - counterSale.totalTaxPaid) < 0.01) {
                        this.printTickets(block.blockName, block.unitPrice, block.ticketsRemaining, r.data.dailyActivity?.name, payload.transactionType);
                    }
                }
            })
        }
    }

    ChargeJumper = (id, payload, isloading) => {
        console.log('in accouont store charge jumper');
        console.log(payload);
        return new Promise((resolve, reject) => {
            Api.ChargeJumper(id, payload).then((r) => {
                if (r.status == 200) {
                    console.log('after charge jumper backend')
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    if (!isloading) {
                        this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'chargeAdded');
                    }
                    // this.emitResults(true, 'chargeCompleted');
                    reportsStore.setDropzoneTodayActivity(r.data.summary);
                    if (window.ENV?.arizona && Number(payload.amount) > 0.01) {
                        console.log(r.data.dailyActivity);

                        var itemizedSales = [];

                        Object.keys(payload.itemized).forEach(key => {
                            itemizedSales.push(...payload.itemized[key]);
                        })

                        console.log(itemizedSales);
                        let role = localStorage.getItem("role");

                        r.data.dailyActivity?.blocks?.forEach(block => {
                            if (role !== "school" && itemizedSales.some(item => item._id?.toString() === block.saleId?.toString())) {
                                var counterSale = r.data.dailyActivity?.countersales.filter(sale => sale._id === block.saleId)[0];
                                console.log(counterSale);
                                if (counterSale !== undefined && (counterSale.price - counterSale.totalPaid) < 0.01 && (counterSale.taxAmount - counterSale.totalTaxPaid) < 0.01) {
                                    this.printTickets(block.blockName, block.unitPrice, block.ticketsRemaining, r.data.dailyActivity?.name, payload.transactionType);
                                }
                            }
                        })
                    }
                    resolve(r.data);

                    //console.log('after recording charge jumper');
                }

                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                }
                else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                    resolve(false);
                }
            })
                .catch((err) => {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }


    TransnationalVoidTransaction = (transactionId, payload, jumperId, isTandemStudent, isloading = false) => {
        return new Promise((resolve, reject) => {
            TransnationalTerminalApi.voidTransaction(transactionId, payload, jumperId, isTandemStudent).then((r) => {
                if (r.status == 200) {
                    console.log('after charge jumper backend')
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    if (!isloading) {
                        this.emitResults({ id: jumperId, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'chargeAdded');
                    }
                    // this.emitResults(true, 'chargeCompleted');
                    reportsStore.setDropzoneTodayActivity(r.data.summary);
                    if (window.ENV?.arizona && Number(payload.amount) > 0.01) {
                        console.log(r.data.dailyActivity);

                        var itemizedSales = [];

                        Object.keys(payload.itemized).forEach(key => {
                            itemizedSales.push(...payload.itemized[key]);
                        })

                        console.log(itemizedSales);
                        let role = localStorage.getItem("role");

                        r.data.dailyActivity?.blocks?.forEach(block => {
                            if (role !== "school" && itemizedSales.some(item => item._id?.toString() === block.saleId?.toString())) {
                                this.printTickets(block.blockName, block.unitPrice, block.ticketsRemaining, r.data.dailyActivity?.name, payload.transactionType);
                            }
                        })

                    }
                    resolve(r.data);

                    //console.log('after recording charge jumper');
                }
                else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                    resolve(false);
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    TransnationalPartialRefundTransaction = (chargeValue, itemized, refundAmount, refundTax, jumperId, isTandemStudent, isloading = false) => {
        console.log('in TransnationalPartialRefundTransaction');
        return new Promise((resolve, reject) => {
            let payload = {
                chargeValue,
                itemized,
                jumperId,
                isTandemStudent,
                refundAmount,
                refundTax
            }
            TransnationalTerminalApi.transAuthorizeAndCapturePartialRefund(payload).then((r) => {
                console.log('after api call');
                if (r.status == 200) {
                    console.log('after charge jumper backend')
                    if (!isloading) {
                        this.emitResults({ id: jumperId, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'chargeAdded');
                    }
                    // this.emitResults(true, 'chargeCompleted');
                    reportsStore.setDropzoneTodayActivity(r.data.summary);
                    resolve(r);

                    //console.log('after recording charge jumper');
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                    resolve(false);
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }





    TransnationalTerminalTransactionSearch = (searchBody) => {
        return new Promise((resolve, reject) => {
            TransnationalTerminalApi.searchTransaction(searchBody).then((r) => {
                if (r.status == 200) {
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    //console.log(r.data);
                    this.emitResults(true, 'searchedAgain');
                    resolve(r.data);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    TransnationalTerminalTransaction = (amount, orderId, taxAmount) => {
        return new Promise((resolve, reject) => {
            TransnationalTerminalApi.postTransaction(amount, orderId, taxAmount).then((r) => {
                if (r.status == 200) {
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    this.emitResults(true, 'chargeCompleted');
                    resolve(r.data);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    TerminalAuthorizeAndCapture = (type, amount) => {
        return new Promise((resolve, reject) => {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
            TerminalApi.AuthorizeAndCapture(type, amount).then((r) => {
                if (r.status == 200) {
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    process.env.NODE_TLS_REJECT_UNAUTHORIZED = undefined;
                    this.emitResults(true, 'chargeCompleted');
                    resolve(r.data);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    NABReturnUnlinked = (amount) => {
        return new Promise((resolve, reject) => {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
            TerminalApi.ReturnUnlinked(amount).then((r) => {
                if (r.status == 200) {
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    process.env.NODE_TLS_REJECT_UNAUTHORIZED = undefined;
                    this.emitResults(true, 'chargeCompleted');
                    resolve(r.data);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    NABVoid = (transcationId) => {
        return new Promise((resolve, reject) => {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
            TerminalApi.Void(transcationId).then((r) => {
                if (r.status == 200) {
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    process.env.NODE_TLS_REJECT_UNAUTHORIZED = undefined;
                    this.emitResults(true, 'chargeCompleted');
                    resolve(r.data);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    NABReturnById = (transcationId, value) => {
        return new Promise((resolve, reject) => {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
            TerminalApi.ReturnById(transcationId, value).then((r) => {
                if (r.status == 200) {
                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    //this.emitResults(newActivity, 'newActivity');
                    //this.emitResults(activeLoadDisplay,'createLoad');
                    process.env.NODE_TLS_REJECT_UNAUTHORIZED = undefined;
                    this.emitResults(true, 'chargeCompleted');
                    resolve(r.data);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    // this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitResults(false, 'chargeCompleted');
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    DeleteItemForTandemStudent = (jumperId, itemId) => {
        return new Promise((resolve, reject) => {
            Api.deleteItemForTandemStudent(jumperId, itemId).then((r) => {
                if (r.status == 200) {
                    //console.log(r.data);

                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    var dailyActivity = r.data.dailyActivity;
                    dailyActivity.isTandemStudent = true;
                    this.emitResults({ id: jumperId, dailyActivity: dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'itemsAdded');
                    //console.log(r.data.summary);
                    reportsStore.setDropzoneTodayActivity(r.data.summary);
                    //this.emitResults(activeLoadDisplay,'createLoad');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else {
                    this.emitError({ message: 'Item could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    executeEmitter(jumperId, result) {
        this.emitResults({ id: jumperId, dailyActivity: result.dailyActivity, otherActivities: result.otherAccounts, tandemGroup: result.tandemGroup }, 'chargeAdded');
    }

    AddItemForTandemStudent = (id, payload) => {
        return new Promise((resolve, reject) => {
            Api.AddItemForTandemStudent(id, payload).then((r) => {
                if (r.status == 200) {
                    //console.log(r.data);

                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    var dailyActivity = r.data.dailyActivity;
                    dailyActivity.isTandemStudent = true;
                    jumperStore.setTandemStudentDailyActivity(r.data.dailyActivity);
                    this.emitResults({ id: id, dailyActivity: dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'itemsAdded');
                    //console.log(r.data.summary);
                    reportsStore.setDropzoneTodayActivity(r.data.summary);
                    resolve(true);
                    //this.emitResults(activeLoadDisplay,'createLoad');
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    this.emitError({ message: 'Item could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    AddItem = (id, payload) => {
        return new Promise((resolve, reject) => {
            Api.AddItem(id, payload).then((r) => {
                if (r.status == 200) {
                    jumperStore.setDailyActivity(r.data.dailyActivity);

                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    this.emitResults({ id: id, dailyActivity: r.data.dailyActivity }, 'itemsAdded');
                    //console.log(r.data.summary);
                    reportsStore.setDropzoneTodayActivity(r.data.summary);
                    resolve(true)
                    //this.emitResults(activeLoadDisplay,'createLoad');
                } else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else {
                    this.emitError({ message: 'Item could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    DeleteItem = (jumperId, itemId) => {
        return new Promise((resolve, reject) => {
            Api.DeleteItem(jumperId, itemId).then((r) => {
                if (r.status == 200) {
                    //console.log(r.data);

                    //var activeLoadDisplay = this.transformLoad(r.data);
                    //var newActivity = "Load " + r.data.loadNum + " created";
                    this.emitResults({ id: jumperId, dailyActivity: r.data.dailyActivity }, 'itemsAdded');
                    //console.log(r.data.summary);
                    reportsStore.setDropzoneTodayActivity(r.data.summary);
                    //this.emitResults(activeLoadDisplay,'createLoad');
                } else if (r.status == 401) {
                    this.loginExpired();
                    this.emitResults(true, 'clearSession');
                } else if (r.status === 500 || r.status === 404) {
                    if (r.data.message) {
                        swal({
                            title: "Warning",
                            text: r.data.message,
                            icon: "warning",
                            dangerMode: false,
                        })
                        //this.emitResults(true, 'ticketsReturnedEvent');
                    }
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                } else {
                    this.emitError({ message: 'Item could not be created, please contact support.' });
                }
            })
                .catch((err) => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    emitError = (err) => {
        var payload = { ...err };
        this.emit('ERROR', payload);
    }
    loginExpired = () => {
        //alert(JSON.stringify(localStorage, null,2));
        if (localStorage.getItem('isAuth')) {
            localStorage.clear();
            swal({
                title: "LOGIN EXPIRED",
                text: "please re-login",
                icon: "warning",
                dangerMode: true,
            })
        }

        //window.location =  window.location.origin
        //window.location.reload();
    }
    getEventPrefix = (func) => {
        return `${this.prefix}${func}`;
    }

    emitResults = (r, func) => {
        if (!r) {
            //console.log(r);
            this.emitError({ alert: true, message: 'No Slots found' });
        }
        this.setMaxListeners(1000);
        //console.log('about to emit');
        this.emit(`${this.prefix}${func}`, r);
    }
}

const accountStore = new AccountStore();
window.accountStore = accountStore;
export default accountStore;
