import React, { Component } from 'react';
import { Control, LocalForm } from 'react-redux-form';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import loadsStore from '../../../stores/loads-store';
import itemsStore from '../../../stores/item-store';
import jumpersStore from '../../../stores/jumpers-store';

import Fuse from "fuse.js";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import LoadUpdateModal from './loadModal'

class AddJumpers extends Component {

	constructor(props) {

		super(props);
		this.toggleModal = this.toggleModal.bind(this);
		this.updateGearValue = this.updateGearValue.bind(this);
		this.MultiValueLabel = this.MultiValueLabel.bind(this);
		this.isCompleted = this.props.isCompleted;
		this.jump = this.props.jump;
		this.state = {
			isModalOpen: false,
			jumpType: null,
			jumperOptions: [],
			ErrorMessage: '',
			isValid: true,
			editMode: false,
			jumpers: [],
			jumpId: props.jumpId,
			loadNum: props.loadNum,
			placement: 'bottom'
		};
		this.jumperEvents = [
			{ name: jumpersStore.getEventPrefix('tandemStudentsLoaded'), func: this.handleStudentsLoaded },
			{ name: jumpersStore.getEventPrefix('tandemStudentCreated'), func: this.handleStudentsLoaded }
		];
		this.timerId = null;
		this.valRef = null;
		this.inputValue = "";
		this.gearIds = [];

		this.studentgearIds = [];
		this.sportgearIds = [];

		this.fuse = null;
		this.isConfirm = false;
	}


	handleStudentsLoaded = () => {
		console.log('handle observers loaded');
		//alert('handle observers loaded');
		if (this.props.type === "observer") {
			this.setState({
				jumperOptions: jumpersStore.getTandemStudentOptions()
			});
		}
	}


	componentDidMount = () => {
		this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));

		if (this.props.type === "observer") {
			var jumperOptions = jumpersStore.getTandemStudentOptions();
			if (this.props.activeJumperIdsOnLoad) {
				jumperOptions = jumperOptions.filter(
					option =>
						this.props.activeJumperIdsOnLoad.findIndex(
							id => option.value._id === id
						) === -1
				);
			}
		}
		else {
			var jumperOptions = jumpersStore.getJumperOptions().filter(opt => opt.value.isTeam !== true);
			if (this.props.activeJumperIdsOnLoad) {
				jumperOptions = jumperOptions.filter(
					option =>
						this.props.activeJumperIdsOnLoad.findIndex(
							id => option.value._id === id
						) === -1
				);
			}
		}

		this.setState({ jumperOptions: jumperOptions });
		this.setFuse(jumperOptions);
	}

	componentWillUnmount = () => {
		this.jumperEvents.forEach(evnt =>
			jumpersStore.removeListener(evnt.name, evnt.func));
	}

	componentDidUpdate = () => {
		if (this.valRef !== null) {
			this.valRef.focus();
		}
	}


	updateJumperOptions = () => {
		console.log('in add jumper update Jumper Options');

		//alert('in update jumper options');
		if (this.props.type === "observer") {
			var jumperOptions = jumpersStore.getTandemStudentOptions();
			if (this.props.activeJumperIdsOnLoad) {
				jumperOptions = jumperOptions.filter(
					option =>
						this.props.activeJumperIdsOnLoad.findIndex(
							id => option.value._id === id
						) === -1
				);
			}
		}
		else {
			var jumperOptions = jumpersStore.getJumperOptions().filter(opt => opt.value.isTeam !== true);
			if (this.props.activeJumperIdsOnLoad) {
				jumperOptions = jumperOptions.filter(
					option =>
						this.props.activeJumperIdsOnLoad.findIndex(
							id => option.value._id === id
						) === -1
				);
			}
		}

		this.setState({ jumperOptions: jumperOptions });
		this.setFuse(jumperOptions);
	}


	setFuse = (jumperOptions) => {
		var options = {
			shouldSort: true,
			tokenize: false,
			threshold: 0.1,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: [
				"label",
				"value.manifestNumber"
			]
		};

		this.fuse = new Fuse(jumperOptions, options);
	}

	Option = (props) => {
		var studentgearModel = ".gear1" + props.data.value._id;
		var studentname = "gear1" + props.data.value._id;

		var sportgearModel = ".gear2" + props.data.value._id;
		var sportname = "gear2" + props.data.value._id;

		var isChecked = false;

		this.gearIds.length > 0 && this.gearIds.map(i => {
			if (i === props.data.value._id) {
				isChecked = true
			}
		})

		this.studentgearIds.length > 0 && this.studentgearIds.map(i => {
			if (i === props.data.value._id) {
				isChecked = true
			}
		})

		this.sportgearIds.length > 0 && this.sportgearIds.map(i => {
			if (i === props.data.value._id) {
				isChecked = true
			}
		})

		// console.log("this.gearIdsthis.gearIds", props.data.value._id, this.gearIds);
		return (
			<div className="row">
				<div className="col-5">
					<components.Option {...props} />
				</div>
				<div className="col-7" style={{ paddingTop: '6px' }}>
					<div className="row" style={{ alignItems: 'center' }}>
						student gear <input type="checkbox"
							style={{ zIndex: 800 }}
							name={studentname}
							// checked={isChecked}
							value={true}
							onChange={value => { this.updateGearValue(value) }}
						/>
					</div >
					<div className="row" style={{ paddingTop: '6px', alignItems: 'center' }}>

						sport gear rental <input type="checkbox"
							style={{ zIndex: 800 }}
							name={sportname}
							// checked={isChecked}
							value={true}
							onChange={value => { this.updateGearValue(value) }}
						/>
					</div>
				</div>

			</div>
		);
	}

	MultiValueLabel = (props) => {
		// console.log(props);
		// console.log(props.data);

		return (
			<div className="row">
				<div className="col-12"><components.MultiValueLabel {...props} /></div>
				{this.studentgearIds.indexOf(props.data.value._id) > -1 || this.sportgearIds.indexOf(props.data.value._id) > -1 ? <div className="col-12">-G</div> : null}
			</div>
		);
	}

	toggleModal() {
		this.setState({
			isModalOpen: !this.state.isModalOpen,
			jumpType: null
		});
		this.gearIds.length = 0;
	}

	updateGearValue(value) {
		// return false
		var id = value.target.name.substr(5);
		var type = value.target.name.substr(0, 5);

		if (type === "gear1") {
			if (value.target.checked) {
				var old = this.gearIds
				this.studentgearIds = [...old, id]
				// console.log("gearIdsgearIds",this.gearIds)
			}
			else {
				this.studentgearIds = this.studentgearIds.filter(gearId => gearId !== id);
			}
		}

		else if (type === "gear2") {
			if (value.target.checked) {
				var old = this.gearIds
				this.sportgearIds = [...old, id]
				// console.log("gearIdsgearIds",this.gearIds)
			}
			else {
				this.sportgearIds = this.sportgearIds.filter(gearId => gearId !== id);
			}
		}
		this.timerId = setTimeout(this.cancelOrSubmit, 5000);
	}

	wantsEidtable = (data) => {
		this.setState({
			editMode: data,
			isModalOpen: false
		})
		if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
			this.setState({ placement: "top" });
		}
		else {
			this.setState({ placement: "bottom" });
		}
		this.isConfirm = false;
	}

	addHoldJumper = (isCompleted) => {
		if (isCompleted) {
			// this.props.openModel({ isModal: true, editType: false })
			this.setState({
				isModalOpen: true
			})
		}
		else {
			//alert('adding jumper');
			if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
				this.setState({ placement: "top" });
			}
			else {
				this.setState({ placement: "bottom" });
			}
			this.updateJumperOptions();
			this.setState({ editMode: true });
			this.isConfirm = false;
		}
	}


	filterJumpers = (inputValue, jumperOptions) => {
		if (inputValue) {
			var filteredOptions = this.fuse.search(inputValue.toLowerCase());
			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
			else return filteredOptions;
		}
		return jumperOptions;
	};


	promiseOptions = (inputValue, jumperOptions) => {
		return new Promise(resolve => {
			resolve(this.filterJumpers(this.inputValue, jumperOptions));
		});
	};

	cancelOrSubmit = () => {
		if (this.state.jumpers.length > 0) {
			var jumpPostBody = {
				jumperIds: this.state.jumpers.map(jumperOption => jumperOption.value._id)
			};
			jumpPostBody.gearRentals = this.gearIds.filter(id => jumpPostBody.jumperIds.indexOf(id) >= 0);
			loadsStore.addJumpers(this.state.loadNum, this.state.jumpId, jumpPostBody);
		}
		else {
			this.setState({
				isloading: false,
				ErrorMessage: 'Invalid Input !',
				isValid: false
			})
		}
		this.isConfirm = false;
		this.setState({ editMode: false, jumpers: [] });
	}

	handleChange = (value, isCompleted, jump, jumpType, loadId, isToday) => {
		if (value.length > 0) {
			if (isCompleted) {
				const oldJumpers = jump.jumpers.map(a => {
					return {
						jumperId: a._id,
						price: a.price,
						gearPrice: a.gearPrice,
						gearType: a.gearType,
						block: a.block,
						blockId: a.blockId,
						isVideographer: a.isVideographer,
						tracked: a.tracked,
						isLOing: a.isLOing,
						chargeTo: a.chargeTo
					}
				})
				var jumperToAdd = { jumperId: value[0].value._id, price: 25.00 };

				if (this.studentgearIds.includes(jumperToAdd.jumperId)) {
					jumperToAdd.gearPrice = itemsStore.getStudentGearPrice();
					jumperToAdd.gearType = 'student';
				}
				if (this.sportgearIds.includes(jumperToAdd.jumperId)) {
					jumperToAdd.gearPrice = itemsStore.getSportGearPrice();
					jumperToAdd.gearType = 'sport';
				}


				var jumpPostBody = {
					jumperIds: [...oldJumpers, jumperToAdd],
					jumpType: jump.jumpType
				};

				jumpPostBody.teamName = jump.teamName;
				jumpPostBody.teamId = jump.teamId;
				jumpPostBody.loadNum = jump.loadNum;
				jumpPostBody.planeLoad = jump.planeLoad;

				jumpPostBody.editPrice = true;
				if (isToday) {
					jumpPostBody.isToday = true
				}

				var jumpId = jump.jumpId;
				var loadId = loadId;

				// console.log('studentgear Reantals completed load');
				// console.log(this.studentgearIds);
				jumpPostBody.gearRentals = this.gearIds.filter(id => jumpPostBody.jumperIds.indexOf(id) >= 0);

				jumpPostBody.studentgearRentals = [... this.props.jump.studentgearRentals, ...this.studentgearIds];

				jumpPostBody.sportgearRentals = [... this.props.jump.sportgearRentals, ...this.sportgearIds];

				this.props.addCompletedJumpers(loadId, jumpId, jumpPostBody);
			}
			else {
				if (jumpType === "iadJump") {
					var jumpPostBody = {
						jumperIds: value.map(jumperOption => jumperOption.value._id)
					};
					var jumpId = jump.jumpId;
					var loadId = loadId;
					loadsStore.addJumpersToIadJump(this.state.loadNum, jumpId, jumpPostBody);
				}
				else {
					var jumpPostBody = {
						jumperIds: value.map(jumperOption => jumperOption.value._id)
					};
					var jumpId = jump.jumpId;
					var loadId = loadId;
					jumpPostBody.gearRentals = this.gearIds.filter(id => jumpPostBody.jumperIds.indexOf(id) >= 0);
					jumpPostBody.studentgearRentals = this.studentgearIds.filter(id => jumpPostBody.jumperIds.indexOf(id) >= 0);
					jumpPostBody.sportgearRentals = this.sportgearIds.filter(id => jumpPostBody.jumperIds.indexOf(id) >= 0);
					if (!!this.props.removeJumper) {
						const oldJumperId = this.props.jumper._id
						const newJumperId = value[0].value._id
						jumpPostBody = { oldJumperId, newJumperId }
						loadsStore.updateFunJump(this.state.loadNum, jumpId, jumpPostBody)
					} else {
						loadsStore.addJumpers(this.state.loadNum, jumpId, jumpPostBody)
					}
				}

			}
		}
		else {
			this.setState({
				isloading: false,
				ErrorMessage: 'Invalid Input !',
				isValid: false
			})
		}
		this.setState({ editMode: false, jumpers: [] });
	}

	handleInputChange = (newValue) => {
		this.inputValue = newValue;
		this.valRef.focus();
	};

	onBlur = (mode, isCompleted) => {
		//if(this.state.editMode && !this.props.isCompleted)
		//{
		if (mode && !isCompleted) {
			this.setState({ editMode: false });
		}
		else if (mode && this.isConfirm && isCompleted) {
			this.setState({ editMode: false });
			this.isConfirm = false;
		}
		else {
			if (this.props.setAddjumperState) {
				this.props.setAddjumperState(false)
			}
			this.isConfirm = true;
		}
	}

	handleObserverSelect = (val) => {
		var payload = { observerId: val.value };
		loadsStore.addObserver(this.props.loadNum, payload);
		this.setState({ editMode: false });
	}

	iconStyle = () => {
		let iconStyle = {}
		if (this.props.loadsToShow > 3 && this.props.columnsPerGroup === 1 && this.props.fontSize < 10) {
			iconStyle.fontSize = 12
		}
		return iconStyle
	}

	render() {
		let customStyles = {
			backgroundColor: "inherit",
			color: "black",
			position: 'absolute',
		}
		const selectCustomStyles = {
			option: (provided, state) => ({
				...provided,
				// fontSize: 200
			}),
			control: (base, state) => ({
				...base,
				fontSize: "16px",
				width: 100
			}),
			menu: (provided, state) => ({
				...provided,
				width: 275
			}),
		};
		let iconStyle = {}
		let iconFont = ''

		if (this.props.loadsToShow > 3) {
			customStyles.top = this.props.type != 'observer' ? '-5%' : '0'
			customStyles.left = this.props.type != 'observer' ? '0%' : '0'

			if (this.props.loadsToShow > 3 && this.props.columnsPerGroup === 1 && this.props.fontSize < 10) {

				customStyles.top = this.props.type != 'observer' ? '35%' : '0'
				customStyles.left = this.props.type != 'observer' ? '-12%' : '0'
				// iconStyle.fontSize = 12
				iconFont = 'add-btn-icon'
				iconStyle = {
					fontSize: 12
				}
			}
			if (this.props.isInline) {
				customStyles.top = this.props.type != 'observer' ? '55%' : '0'
				customStyles.left = this.props.type != 'observer' ? '-10%' : '0'
			}
		}
		else {
			customStyles.top = this.props.type != 'observer' ? '50%' : '-5%'
			customStyles.left = this.props.type != 'observer' ? '-6%' : '-5%'
		}
		if (this.props.loadsToShow > 3 && this.props.fontSize < 14) {
			iconStyle.fontSize = 12
			iconFont = 'add-btn-icon'
			customStyles.top = this.props.type != 'observer' ? '-18%' : '0'
			// debugger
		}
		// console.log("iconStyle==>", iconStyle, iconFont)
		const editMode = () => {
			if (this.props.editModeForAddJumper) {
				return this.props.editModeForAddJumper
			} else {
				return this.state.editMode
			}
		}
		return (
			<React.Fragment>
				{
					!editMode() ?
						// <button className="btn lightBlueButton jumper-name" style={this.props.jumperLength<4?{borderRadius: "100%",width: "8%",position: "absolute",top: "14%" }:{borderRadius: "100%",width: "8%",position:'absolute',top:'5%',left:'84%'}} onClick={() => this.addHoldJumper(this.props.isCompleted)} type="submit" disabled={this.props.disabled}>
						<button className="btn jumper-name"
							// style={{ backgroundColor: "inherit", color: "black", position: 'absolute', top: '-5%', left: '-5%' }}
							style={customStyles}
							onClick={() => this.addHoldJumper(this.props.isCompleted)} type="submit" disabled={this.props.disabled}>
							<i style={this.iconStyle()} className={`fa fa-plus ${iconFont}`}></i>
						</button>
						:
						// <button className="btn lightBlueButton jumper-name" disabled={this.props.disabled}>
						<button disabled={this.props.disabled} style={{ paddingLeft: '0px !important' }}>
							<LocalForm form="addjumpers" model="addjumpers" getDispatch={dispatch => this.formDispatch = dispatch} >
								{this.props.type !== "observer" ?
									<AsyncSelect model=".jumper" name="jumper" ref={(value) => this.valRef = value} loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
										ref={(value) => this.valRef = value}
										defaultOptions={this.state.jumperOptions.slice(0, 10)}
										onChange={(value) => this.handleChange(value, this.props.isCompleted, this.props.jump, this.props.jumpType, this.props.loadId, this.props.isToday)}
										isMulti
										onInputChange={(newValue) => this.handleInputChange(newValue)}
										styles={selectCustomStyles}
										components={{ Option: this.Option, MultiValueLabel: this.MultiValueLabel }}
										menuPortalTarget={document.body}
										// menuPosition="fixed"
										menuPlacement={this.state.placement}
										onBlur={() => this.onBlur(this.state.editMode, this.props.isCompleted)}
									//onBlur={this.onBlur}
									/>
									:
									<Select
										ref={(value) => this.valRef = value}
										isClearable
										onChange={(val) => this.handleObserverSelect(val)}
										options={this.state.jumperOptions}
										onBlur={() => this.onBlur(this.state.editMode, this.props.isCompleted)}
									/>

								}
							</LocalForm>
						</button>
				}
				<LoadUpdateModal
					isOpen={this.state.isModalOpen}
					onClick={this.wantsEidtable}
				/>
			</React.Fragment>
		);
	}
}
const mstp = state => {
	return {
		state
	}
}
const mdtp = dispatch => {
	return {
		addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
	}
}
export default connect(mstp, mdtp)(AddJumpers)
