import React, { Component } from 'react'
import { Table } from 'reactstrap';

import RemoveItem from '../modals/removeItem';
import CreateBlock from '../modals/createBlock';
import { Show_Loader } from '../../Loader/showLoader';

export default class Blocks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }


    renderBlocks = () => {
        let { blocks, checkForNegatives } = this.props;

        if (blocks != undefined) {
            return blocks.map((item, index) => {
                return (
                    <tr key={`planRow${index}`}>
                        <th key={`planCell1${index}`} scope="row" data-id={item._id}>{index + 1}</th>
                        <td key={`planCell2${index}`}>{item.item}</td>
                        <td key={`planCell2${index}`}>{item.tickets}</td>
                        <td key={`planCell2${index}`}>{item.affTickets}</td>
                        <td key={`planCell2${index}`}>{item.coachTickets}</td>
                        <td key={`planCell2${index}`}>{item.soloTickets}</td>
                        <td key={`planCell3${index}`}>{checkForNegatives(item.price)}</td>
                        <td key={`planCell2${index}`}>{item.plane}</td>
                        <td key={`planCell2${index}`}>{item.forBoogie ? 'yes' : 'no'}</td>
                        <td key={`planCell2${index}`}>{item.isTaxable ? 'yes' : 'no'}</td>
                        <td key={`planCell4${index}`}>{item.expiryInDays}</td>
                        <td key={`planCell12${index}`}>{item.isDefault ? 'yes' : 'no'}</td>
                        <td key={`planCell11${index}`}>
                            <CreateBlock isEdit={true} item={item} setState={this.setState.bind(this)} />&nbsp;&nbsp;
                            <RemoveItem item={item} itemType="block" />
                        </td>

                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    render() {
        let { categories, planes } = this.props;
        return (
            <>
                {this.state.isLoading ? <Show_Loader createBlock={true} /> : ""}
                <Table >
                    <thead className="main-heading">
                        <tr>
                            <th><CreateBlock categories={categories} planes={planes} setState={this.setState.bind(this)} /></th>
                            <th>Name</th>
                            <th>Regular tickets</th>
                            <th>Aff tickets</th>
                            <th>Coach tickets</th>
                            <th>Solo tickets</th>
                            <th>Price</th>
                            <th>Plane Specific</th>
                            <th>Boogie</th>
                            <th>taxable</th>
                            <th>Expiry</th>
                            <th>Default</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="priceHeading"><td colSpan="3">Blocks</td></tr>
                        {this.renderBlocks()}
                    </tbody>
                </Table>
            </>
        )
    }
}
