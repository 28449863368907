import React, { Component } from 'react';
import { Table } from 'reactstrap';

import ChangeableSetting from '../modals/changeableSetting';
import ChangeableTextSetting from '../modals/changeableTextSetting';

export default class Settings extends Component {
    renderSettings = () => {
        let { settings } = this.props;
        if (settings != undefined) {
            return settings.map((item, index) => {
                let name = item.name;
                if(window.ENV.secondSalesTax){
                  if(name === "tax rate (percentage)"){
                    name = "airport facilities fee (percentage)";
                  }
                }
                return (
                    <tr key={`planRow${index}`}>
                        <td key={`planCell2${index}`}> {name}</td>
                        {item.type === "select" ?
                            <td key={`planCell3${index}`}>
                                <ChangeableSetting settingId={item._id} value={item.value} options={item.options} type={item.type} />
                            </td> : null}
                        {item.type === "text" ? <td key={`planCell3${index}`}><ChangeableTextSetting settingId={item._id} value={item.value} options={item.options} type={item.type} /></td> : null}
                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    render() {
        return (
            <div className="col-6" style={{ padding: '11px' }}>
                <Table>
                    <thead className="main-heading">
                        <tr>
                            <th>Setting</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderSettings()}
                    </tbody>
                </Table>
            </div>

        )
    }
}
