import { LocalForm } from "react-redux-form";
import { Col, Row } from "reactstrap";
import CustomModal from "../../Common/CustomModal";

const DeleteModal = (props) => {

    const title = props.title ? "remove jumper" : "delete this";
    return (
        <div>
            <CustomModal isModalOpen={props.isOpenModal} title={"Warning"}>
                <p className="warning_ques">Are you sure you want to {title} ?</p>
                <LocalForm>
                    <Row>
                        <Col className="warning-modal-col">
                            <button onClick={() => props.confirmDelete()} >Submit</button>
                            <button onClick={() => props.closeModal()}>Cancel</button>
                        </Col>
                    </Row>
                </LocalForm>
            </CustomModal>
        </div>
    )
}


export default DeleteModal;