import { EventEmitter } from 'events';

import Api from '../services/loadsapi';

import api2 from '../services/gotnpayTerminalapi';
import api3 from '../services/reservationapi';
import api4 from '../services/waiverapi';

import jumpersStore from './jumpers-store';
import reportsStore from './report-store';
import accountsStore from './account-store';

import { updateLoadHistory } from '../actions/reports'
import swal from 'sweetalert';
import * as JSPM from 'jsprintmanager';

class Loads extends EventEmitter {
	constructor() {
		super();
		this.prefix = 'LOADS_STORE_';
		this.dailyActivities = {};
		this.planes = [];
		this.loads = [];
		this.completedLoads = [];
		this.schoolWaitlist = [];
		this.completedLoadArray = [];
		this.expiryID = [];
		//this.funLoadNum = '';
		this.cutValue = null
		this.copied = false;
		this.cut = false;
		this.deleteJumpId = null
		this.copyValue = {};
		this.displayLoader = false;
		this.settings = [];
		this.jumperManifestMethodSetting = 'regular';
		this.planeDisplaySetting = 'plane type';
		this.widget = null;
		this.loadsPerPage = 3;
		this.enableTax = false;
		this.taxRate = 0;
		this.ewaivers = true;
		this.ovSeparateCheckin = false;
		this.teams = [];
		this.dragabbleItems = []
	}

	setListeners = (socket) => {
		//alert('setting setListerner in load');
		socket.on("createActiveLoad", data => {
			console.log('createActiveLoad socket event');
			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);

			var activeLoadsDisplay = data;
			this.setActiveLoadsForDisplay(activeLoadsDisplay);
			const { loadsPerPage } = JSON.parse(localStorage.getItem('jumperDetails'))
			console.log('loadsPerPage: ' + loadsPerPage);

			if (activeLoadsDisplay.length % loadsPerPage === 1) {
				console.log('setting whole slide');
				this.emitResults(activeLoadsDisplay, 'createLoad');
			}
			else {
				console.log('creating single load component')
				this.emitResults(activeLoadsDisplay[activeLoadsDisplay.length - 1], 'createLoad_' + (activeLoadsDisplay.length - 1));
			}
		});
		socket.on("cancelLoad", data => {
			console.log('cancelLoad socket event');

			var loads = data;

			var activeLoadsDisplay = data;

			this.setActiveLoadsForDisplay(activeLoadsDisplay);


			var loading = true;

			const { loadsPerPage } = JSON.parse(localStorage.getItem('jumperDetails'))


			if (activeLoadsDisplay.length % loadsPerPage === 0) {
				this.emitResults(activeLoadsDisplay, 'createLoad', true);
			}
			else {
				activeLoadsDisplay.forEach((ld, indx) => {
					this.emitResults(ld, 'createLoad_' + (indx));
				})
				this.emitResults({}, 'createLoad_' + (activeLoadsDisplay.length));
			}
			jumpersStore.loadAllDailyActivities();
		});

		socket.on('updateActiveLoads', data => {
			console.log('updateActiveLoads socket event');
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadsDisplay = data;
			console.log(activeLoadsDisplay);
			//var newActivity = "new Tandem Jump Created on load" + data.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			this.updateActiveLoadsForDisplay(activeLoadsDisplay);
			console.log(this.loads);

			console.log(this.loads);
			activeLoadsDisplay.forEach(activeLoad => {
				this.emitResults(activeLoad, 'updateLoad_' + activeLoad.loadNum);
			})
			jumpersStore.loadAllDailyActivities();

		})

		socket.on("loadStatusEvent", data => {
			console.log('loadStatusEvent socket event');
			/*
			if (localStorage.getItem("role") === "school") {
				swal({
					title: "Warning",
					text: data.message,
					icon: "warning",
					dangerMode: false,
				})
			}*/
		})

		socket.on('updateInAir', data => {
			console.log('updateInAir socket event');
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadsDisplay = data;
			//var newActivity = "new Tandem Jump Created on load" + data.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			this.updateActiveLoadsForDisplay(activeLoadsDisplay);
			console.log('updateActiveLoad socket');
			console.log(this.loads);
			activeLoadsDisplay.forEach(activeLoad => {
				this.emitResults(activeLoad, 'updateLoad_' + activeLoad.loadNum);
			})
		})

		socket.on('updateActiveLoad', data => {
			console.log('updateActiveLoad socket event');

			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);

			//var newActivity = "new Tandem Jump Created on load" + data.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			console.log(this.loads);
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);

		})

		socket.on("loadStatusUpdate", data => {
			console.log('loadStatusUpdate socket event');

			//alert('load status update');
			//console.log('in closeLoad socket event');
			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			//var newActivity = "new Tandem Jump Created on load" + data.loadNum;
			////this.emitResults(newActivity, 'newActivity');

			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
		});


		socket.on("closeLoad", data => {
			console.log('closeLoad socket event');

			//console.log('in closeLoad socket event');
			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);

			console.log(activeLoadDisplay)
			//var newActivity = "new Tandem Jump Created on load" + data.loadNum;
			////this.emitResults(newActivity, 'newActivity');

			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);

		});

		socket.on("openLoad", data => {
			console.log('openLoad socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			//var newActivity = "new Tandem Jump Created on load" + data.loadNum;
			////this.emitResults(newActivity, 'newActivity');

			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
		});


		socket.on("createTandemJump", data => {
			console.log('createTandemJump socket event');

			//console.log(data);
			//var newActivity = "New Tandem Jump Created on load" + data.loadNum;
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;

			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}
			////this.emitResults(newActivity, 'newActivity');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);


		});
		socket.on("createCoachJump", data => {
			console.log('createCoachJump socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;

			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);

		});
		socket.on("createAffJump", data => {
			console.log('createAffJump socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;
			//alert('create aff');

			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);


		});

		socket.on("createFunJump", data => {
			console.log('createFunJump event socket event');

			//console.log(data.load);
			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);

			var newActivity = data.funJump.jumpType + " jump created on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			console.log('eventname: ' + 'updateLoad' + activeLoadDisplay.loadNum)
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);

		});

		socket.on("createIadJump", data => {
			console.log('createIadJump socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);

			var newActivity = "Iad jump created on load " + data.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);

		});

		socket.on("updateIadJumps", data => {
			console.log('updateIadJumps socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);

			var newActivity = "Iad jump created on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);

		});

		socket.on("removeObserver", data => {
			console.log('removeObserver socket event');
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);

			console.log(activeLoadDisplay);
			var newActivity = "Observer removed jump, on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);

		});

		socket.on("addObserver", data => {
			console.log('addObserver event socket event');
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			console.log(activeLoadDisplay);
			var newActivity = "Jumpers added to IAD jump, on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);

		});

		socket.on('settingsUpdate', data => {
			console.log('in settingsUpdate event socket event');
			//var activeLoads = data;
			this.settings = data;

			this.emitResults(this.settings, 'settingUpdated');
		})

		socket.on("addJumpers", data => {
			console.log('add jumper event socket event');
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);

			var newActivity = "Jumpers added to IAD jump, on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);

		});

		socket.on("addIadStudent", data => {
			console.log('addIadStudent socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			var newActivity = "Jumpers added to " + data.iadJump.jumpType + " jump, on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
		});

		socket.on("removeJumper", data => {
			console.log('removeJumper socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			var newActivity = "Jumper removed from " + data.funJump.jumpType + " jump, on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);

		});

		socket.on("approveJumper", data => {
			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			var newActivity = "Jumper approved from " + data.funJump.jumpType + " jump, on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			this.emitResults(activeLoadDisplay, 'updateLoad');
		});

		socket.on("deleteTandemJump", data => {
			console.log('deleteTandemJump socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;
			//var newActivity = "New Tandem Jump Created on load" + data.loadNum;
			//var activeLoadDisplay = this.transformLoad(data);

			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}

			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);


		});

		socket.on("updateTandemJump", data => {
			console.log("updateTandemJump 1112 socket event", data);
			////alert(JSON.stringify(data));
			// var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			//alert(JSON.stringify(activeLoadDisplay));
			//var newActivity = "tandem Jump updated on load" + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//var newActivity = "New Tandem Jump Created on load" + data.loadNum;
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data.load;
			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);



		});

		socket.on("deleteCoachJump", data => {
			console.log('deleteCoachJump socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;
			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);


		});

		socket.on("updateCoachJump", data => {
			console.log('updateCoachJump socket event');
			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}

			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);


		});

		socket.on("updateFunJump", data => {
			console.log('updateFunJump socket event');

			//console.log(data);
			//alert(JSON.stringify(data));
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			//alert(JSON.stringify(activeLoadDisplay));
			var newActivity = "Fun Jump updated on load" + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);

		});

		/*
		socket.on("updateCompletedLoads", data => {
			console.log("updateCompletedLoads 161 socket event", data)
			this.emitResults(data, 'updateCompletedLoads');
			jumpersStore.loadAllDailyActivities();
			//reportsStore.setDropzoneTodayActivity(data.summary);
			//this.updateCompletedLoadsForDisplay();
			//updateLoadHistory(data);
		})*/

		socket.on("updateCompletedLoad", data => {
			console.log("updateCompletedLoad 161 socket event", data)
			this.emitResults(data.load, 'updateLoad_' + data.load.loadNum);
			jumpersStore.loadAllDailyActivities();
			this.updateCompletedLoadsForDisplay([data.load]);
			reportsStore.setDropzoneTodayActivity(data.summary);
			//updateLoadHistory(data);
		})

		socket.on("deleteAffJump", data => {
			console.log('deleteAffJump socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data);
			var activeLoadDisplay = data;
			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);

		});



		socket.on("updateAffJump", data => {
			console.log('updateAffJump socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;

			if (activeLoadDisplay.loadNum === 0) {
				this.schoolWaitlist = activeLoadDisplay;
			}
			else {
				this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			}
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);

		});



		socket.on("deleteFunJump", data => {
			console.log('delete Fun Jump Event socket event');

			//console.log(data);
			//var activeLoadDisplay = this.transformLoad(data.load);
			var activeLoadDisplay = data.load;
			this.updateActiveLoadsForDisplay([activeLoadDisplay]);
			var newActivity = data.funJump.jumpType + " jump cancelled on load " + data.load.loadNum;
			////this.emitResults(newActivity, 'newActivity');
			//this.emitResults(activeLoadDisplay, 'updateLoad');
			this.emitResults(activeLoadDisplay, 'updateLoad_' + activeLoadDisplay.loadNum);
			this.emitResults("", 'staffStatusUpdate_' + activeLoadDisplay.loadNum);

		});


		socket.on('updateTimers', data => {
			console.log('in updateTimers event socket event');
			var activeLoads = data;

			if (this.isEdit) {
				this.loadQueue = activeLoads;
				this.loadEvent = 'updateAllLoads';
			}
			else {
				activeLoads.forEach(activeLoad => {
					if (!activeLoad.isPaused) {
						this.emitResults(activeLoad, 'updateLoad_' + activeLoad.loadNum);
					}
				})
			}
		})

		socket.on('updateAltitudes', data => {
			console.log('in updateAltitudes socket event');
			var activeLoads = data;
			this.updateActiveLoadsForDisplay(activeLoads);

			if (this.isEdit) {
				this.loadQueue = activeLoads;
				this.loadEvent = 'updateAltitudes';
			}
			else {
				activeLoads.forEach(activeLoad => {
					if (activeLoad.inAir) {
						this.emitResults(activeLoad, 'updateLoad_' + activeLoad.loadNum);
					}
				})
			}
		})

		socket.on('completeLoad', data => {
			console.log('completeLoad io event socket event');
			console.log(data);
			//alert('completeLoad io event');

			var newActivity = "Load " + data.load.loadNum + " completed";

			//console.log(data.tandemDailyActivities);
			//alert('receiving tandem daily activities after complete load');
			//jumpersStore.setAllDailyActivities(data.dailyActivities, data.tandemDailyActivities);

			var load = data.load;
			var loadNum = load.loadNum;
			this.loads = this.loads.filter(ld => ld.loadNum !== loadNum);
			data.loadTimes?.forEach((ldt, indx) => {
				this.loads[indx].timeRemaining = ldt.timeRemaining;
				this.loads[indx].isNext = ldt.isNext;
			})
			console.log(this.loads);
			var activeLoadDisplay = load;
			this.completedLoads.push(load);

			const { loadsPerPage } = JSON.parse(localStorage.getItem('jumperDetails'))
			var activeLoads = this.loads;
			var completedLoads = this.completedLoads;
			if (window.location.pathname === '/load-manager' && completedLoads.length > 12) {
				completedLoads = completedLoads.slice(-12);
			}

			if ((activeLoads.length % loadsPerPage === 0 || completedLoads.length % loadsPerPage === 1)) {
				this.emitResults({ load: data.load, completedLoads: this.completedLoads, activeLoads: this.loads }, 'completeLoad');
			}
			else {
				activeLoads.forEach((ld, indx) => {
					this.emitResults(ld, 'createLoad_' + (indx));
				})
				this.emitResults({}, 'createLoad_' + (activeLoads.length));

				console.log('running through completed loads');
				completedLoads.forEach((ld, indx) => {
					console.log(ld.loadNum);
					console.log(-(indx + 1));

					this.emitResults(ld, 'createLoad_' + (-(indx + 1)));
				})
			}

			//console.log(completedLoadsDisplay);
			////this.emitResults(newActivity, 'newActivity');
			this.emitResults(data, 'completeLoadRight');
			var loading = true;
			this.emitResults(loading, 'loaderActivity');
			reportsStore.setDropzoneTodayActivity(data.summary);
			jumpersStore.loadAllDailyActivities();
		})

		socket.on('reopenLoad', data => {
			console.log('reopenLoad socket event');
			console.log(data);
			//alert('completeLoad io event');

			var newActivity = "Load " + data.load.loadNum + " completed";

			//console.log(data.tandemDailyActivities);
			//alert('receiving tandem daily activities after complete load');
			//jumpersStore.setAllDailyActivities(data.dailyActivities, data.tandemDailyActivities);

			var load = data.load;
			this.completedLoads = this.completedLoads.filter(ld => ld.loadNum !== load.loadNum);
			this.loads.push(load);
			this.loads.sort((a, b) => a.loadNum - b.loadNum);
			data.loadTimes?.forEach((ldt, indx) => {
				this.loads[indx].timeRemaining = ldt.timeRemaining;
				this.loads[indx].isNext = ldt.isNext;
			})
			console.log(this.loads);
			var completedLoads = this.completedLoads;
			var activeLoads = this.loads;

			var activeLoadDisplay = load;


			const { loadsPerPage } = JSON.parse(localStorage.getItem('jumperDetails'))
			// var activeLoads = data.activeLoads;
			// var completedLoads = data.completedLoads;
			// if(completedLoads.length>12){
			// 	completedLoads = completedLoads.slice(-12);
			// }

			// if(activeLoads.length%loadsPerPage ===1 || (completedLoads.length%loadsPerPage ===0 && completedLoads.length<12)){
			// 	this.emitResults({ load: data.load, completedLoads:  data.completedLoads , activeLoads: data.activeLoads }, 'completeLoad');
			// }

			//this.emitResults({ load: data.load, completedLoads:  data.completedLoads , activeLoads: data.activeLoads }, 'completeLoadReopen');
			if (activeLoads.length % loadsPerPage === 1 || (completedLoads.length % loadsPerPage === 0)) {
				this.emitResults({ load: load, completedLoads: completedLoads, activeLoads: activeLoads }, 'completeLoadReopen');
			}
			else {
				let data = completedLoads
				if (window.location.pathname === '/load-manager' && completedLoads.length > 12) {
					data = completedLoads.slice(-12)
				}
				activeLoads.forEach((ld, indx) => {
					this.emitResults(ld, 'createLoad_' + (indx));
				})
				console.log('running through completed loads');
				data.forEach((ld, indx) => {
					console.log(ld.loadNum);
					console.log(-(indx + 1));
					this.emitResults(ld, 'createLoad_' + (-(indx + 1)));
				})
				this.emitResults({}, 'createLoad_' + (-data.length - 1));
			}

			//console.log(completedLoadsDisplay);
			////this.emitResults(newActivity, 'newActivity');
			this.emitResults(data, 'completeLoadRight');
			var loading = true;
			this.emitResults(loading, 'loaderActivity');
			reportsStore.setDropzoneTodayActivity(data.summary);
			jumpersStore.loadAllDailyActivities();

		})
	}

	resetActiveLoads = () => {
		return new Promise((resolve, reject) => {
			Api.resetLoads().then((r) => {
				if (r.status == 200) {
					var loads = r.data;
					var activeLoadsDisplay = r.data;
					/*
					loads.forEach((load)=>{
						activeLoadsDisplay.push(this.transformLoad(load));
					});*/

					var newActivity = "reset Loads";
					////this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'resetLoads');
					this.emitResults(r.data, 'updateLoad2');

					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					//jumpersStore.resetTodayWaivers();

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	resetDay = () => {
		return new Promise((resolve, reject) => {
			Api.resetDay().then((r) => {
				if (r.status == 200) {
					var loads = r.data.loads;
					var activeLoadsDisplay = r.data.loads;
					/*
					loads.forEach((load)=>{
						activeLoadsDisplay.push(this.transformLoad(load));
					});*/

					var newActivity = "Day reset";
					////this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'resetDay');

					reportsStore.setDropzoneTodayActivity(r.data.summary);
					jumpersStore.resetTodayWaivers();

					jumpersStore.loadAllJumpers()
						.then(ret => {
							resolve(loads);
						})
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	reloadDay = () => {
		
		return new Promise((resolve, reject) => {
			Api.reloadDay().then((r) => {
				if (r.status == 200) {
					window.location.reload(); 

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	getTaxRate = () => {
		return this.taxRate;
	}

	getOvSeparateCheckinSetting = () => {
		return this.ovSeparateCheckin;
	}

	getEwaiversSetting = () => {
		return this.ewaivers;
	}

	getSeparateEarnedBalanceSetting = () => {
		return this.useSeparateEarnedBalance;
	}

	getDownloadEwaiverSetting = () => {
		return this.downloadewaivers;
	}

	loadManifestConfig = () => {

		//alert('load manifest config');
		
		return new Promise((resolve, reject) => {
			Api.loadManifestConfig().then((r) => {
				if (r.status == 200) {
					window.ENV= r.data.manifestConfig;
					window.loadScripts();
					api2.setApi();
					api3.setApi();
					api4.setApi();
					accountsStore.initJSPM();
					this.emitResults({}, 'configLoaded');

					//alert(JSON.stringify(window.ENV?.null,2));
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
			})
		})
	}

	loadSettings = () => {
		return new Promise((resolve, reject) => {

			Api.loadSettings().then((r) => {
				if (r.status == 200) {

					this.settings = r.data.settings;
					this.jumperManifestMethodSetting = this.settings.filter(set => set.name === "jumper manifest method")[0];
					this.planeDisplaySetting = this.settings.filter(set => set.name === "plane display")[0];

					var loadsPerPageSetting = this.settings.filter(set => set.name === "loads per page - manifest")[0];
					if (loadsPerPageSetting) {
						this.loadsPerPage = loadsPerPageSetting.value;
					}

					var enableTaxSetting = this.settings.filter(set => set.name === "enable tax")[0];
					if (enableTaxSetting && enableTaxSetting.value && enableTaxSetting.value === "yes") {
						this.enableTax = true;
					}

					let pst = this.settings.filter(st => st.name === "tax rate (percentage)")[0];
					let pst2 = this.settings.filter(st => st.name === "sales tax (percentage)")[0];
					
					if(pst2 !== undefined && pst2.value !== undefined){
						this.taxRate = Number(pst2.value);
					}
					else if (pst !== undefined && pst.value !== undefined) {
						this.taxRate = Number(pst.value);
					}

					this.ewaivers = r.data.configSettings.ewaivers;
					this.ovSeparateCheckin = r.data.configSettings.ovSeparateCheckin;
					this.useSeparateEarnedBalance = r.data.configSettings.useSeparateEarnedBalance;
					this.downloadewaivers = r.data.configSettings.downloadewaivers;

					//alert(JSON.stringify(this.jumperManifestMethodSetting));
					//alert(JSON.stringify(this.settings));
					this.emitResults(this.settings, 'settingsLoaded');
					resolve(this.settings);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	changeSetting = (id, payload) => {
		return new Promise((resolve, reject) => {

			Api.changeSetting(id, payload).then((r) => {
				if (r.status == 200) {
					//this.setting = r.data;
					console.log('return after api call in changeSetting');
					console.log(r.data);
					var setting = this.settings.filter(setting => setting._id === id)[0];
					setting.value = r.data.value;

					if (setting.name === "enable tax") {
						if (setting.value === "yes") {
							this.enableTax = true;
						}
						else {
							this.enableTax = false;
						}
					}
					//this.jumperManifestMethodSetting = this.settings.filter(set => set.name === "jumper manifest method")[0];
					//alert(JSON.stringify(this.jumperManifestMethodSetting));
					//alert(JSON.stringify(this.settings));
					this.emitResults(this.settings, 'settingUpdated');
					resolve(this.settings);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	loadPlanes = () => {
		return new Promise((resolve, reject) => {

			Api.loadPlanes().then((r) => {
				if (r.status == 200) {
					var planesData = r.data;
					this.planes = r.data;
					this.emitResults(planesData, 'planesLoaded');
					resolve(planesData);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	UpdateLoadPlane = (id, planeBody) => {
		return new Promise((resolve, reject) => {
			Api.updatePlanes(id, planeBody).then((r) => {
				if (r.status == 200) {
					// var load = r.data;
					var status = true;

					this.emitResults(status, 'planeActivity');
					//console.log('updated load Plane')
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'plane could not be updated, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	UpdateLoad = (id, body) => {
		return new Promise((resolve, reject) => {
			Api.updateLoad(id, body).then((r) => {
				if (r.status == 200) {
					var status = true;
					var newActivity = "plane capacity Updated on Load" + id;
					////this.emitResults(newActivity, 'newActivity');
					//alert('update load');
					this.emitResults(status, 'planeActivity');
					//alert(JSON.stringify(body));
					if (body.numPlanes) {
						this.emitResults({ body }, 'PLANE_NUM_UPDATE');
					}

					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'plane could not be updated, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}


	CreatePlane = (planeBody) => {
		return new Promise((resolve, reject) => {
			Api.createPlanes(planeBody).then((r) => {
				if (r.status == 200) {
					//console.log('loadCreated')
					var status = true;
					this.emitResults(status, 'planeActivity');
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'plane could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	deletePlane = (id) => {
		return new Promise((resolve, reject) => {
			Api.deletePlanes(id).then((r) => {
				if (r.status == 200) {
					//console.log('plane delted')
					var status = true;
					this.emitResults(status, 'planeActivity');
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'plane could not be deleted, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}
	refreshExistingLoads = () => {
		console.log('in refresh existing loads');
		return new Promise((resolve, reject) => {

			var useWaitlist = false;
			if (this.jumperManifestMethodSetting.value === "waitlist") {
				useWaitlist = true;
			}

			console.log(this.jumperManifestMethodSetting);
			console.log(useWaitlist);
			Api.getAllActiveLoads(useWaitlist).then((r) => {
				console.log('after all getting loads');
				if (r.status == 200) {
					var activeLoadsDisplay = r.data.activeLoads;

					var completedLoadsDisplay = r.data.completedLoads;

					this.loads = activeLoadsDisplay;
					console.log('load all Existing');
					console.log(this.loads);

					this.schoolWaitlist = r.data.schoolWaitlist;
					//alert(JSON.stringify(activeLoadsDisplay));
					this.completedLoads = completedLoadsDisplay;
					console.log(this.completedLoads);
					//this.emitResults({ activeLoads: activeLoadsDisplay, completedLoads: completedLoadsDisplay }, 'getExistingLoads');
					//this.emitResults({ schoolWaitlist: this.schoolWaitlist, jumperWaitlist: this.jumperWaitlist }, 'getWaitlists');
					activeLoadsDisplay.forEach((activeLoad, indx) => {
						console.log('refreshing');
						console.log(activeLoad);
						this.emitResults(activeLoad, 'createLoad_' + (indx));
						//this.emitResults(activeLoad, 'updateLoad_' + activeLoad.loadNum);
					})
					const { loadsPerPage } = JSON.parse(localStorage.getItem('jumperDetails'));

					var nindx = activeLoadsDisplay.length;
					do {
						this.emitResults({}, 'createLoad_' + (nindx));
						nindx++;
					} while (nindx % loadsPerPage !== 0)

					console.log('running through completed loads');
					var completedLoadsRefresh = completedLoadsDisplay;
					if (window.location.pathname === '/load-manager' && completedLoadsDisplay.length > 12) {
						completedLoadsRefresh = completedLoadsDisplay.slice(-12);
					}
					completedLoadsRefresh.forEach((ld, indx) => {
						console.log(ld.loadNum);
						console.log(-(indx + 1));

						this.emitResults(ld, 'createLoad_' + (-(indx + 1)));
					})
					resolve(this.loads);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	loadAllExistingLoads = () => {
		//////alert('load All Existing Loads');
		return new Promise((resolve, reject) => {

			var useWaitlist = false;
			if (this.jumperManifestMethodSetting.value === "waitlist") {
				useWaitlist = true;
			}

			console.log(this.jumperManifestMethodSetting);
			console.log(useWaitlist);
			Api.getAllActiveLoads(useWaitlist).then((r) => {
				if (r.status == 200) {
					var activeLoadsDisplay = r.data.activeLoads;

					var completedLoadsDisplay = r.data.completedLoads;

					this.loads = activeLoadsDisplay;
					console.log('load all Existing');
					console.log(this.loads);

					this.schoolWaitlist = r.data.schoolWaitlist;
					//alert(JSON.stringify(activeLoadsDisplay));
					this.completedLoads = completedLoadsDisplay;
					console.log(this.completedLoads);

					this.emitResults({ activeLoads: activeLoadsDisplay, completedLoads: completedLoadsDisplay }, 'getExistingLoads');
					this.emitResults({ schoolWaitlist: this.schoolWaitlist, jumperWaitlist: this.jumperWaitlist }, 'getWaitlists');
					activeLoadsDisplay.forEach(activeLoad => {
						this.emitResults(activeLoad, 'updateLoad_' + activeLoad.loadNum);
					})
					resolve(this.loads);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	sendLoadSheet = (loadNum) => {
		return new Promise((resolve, reject) => {

			Api.sendLoadSheet(loadNum).then((r) => {
				if (r.status == 200) {
					swal({
						title: "Success",
						text: r.data.message,
						icon: "success",
						dangerMode: false,
					})
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	printCompletedLoadSheet = (loadId) => {
		return new Promise((resolve, reject) => {
			Api.printCompletedLoadSheet(loadId).then((r) => {
				if (r.status == 200) {
					const loadsData = r.data;
					swal({
						title: "Details Downloaded",
						text: r.data.message,
						icon: "success",
						dangerMode: false,
					})
					resolve(loadsData);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
    printLoadSheetDirect = (data) => {
        console.log(JSPM.JSPrintManager.websocket_status, "<<-websocket -status");
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
            console.log('about to load sheetprint, wstatus is open');
            var cpj = new JSPM.ClientPrintJob();
			cpj.clientPrinter = new JSPM.DefaultPrinter();
            var my_file = new JSPM.PrintFilePDF(data, JSPM.FileSourceType.BLOB, 'loadsheet.pdf', 1);
			cpj.files.push(my_file);
            cpj.sendToClient();
        }
    }
	printLoadSheet = (loadNum) => {
		return new Promise((resolve, reject) => {
			Api.printLoadSheet(loadNum).then((r) => {
				if (r.status == 200) {
					const loadsData = r.data;
					swal({
						title: "Details Downloaded",
						text: r.data.message,
						icon: "success",
						dangerMode: false,
					})
					if(window.ENV?.arizona){
						this.printLoadSheetDirect(loadsData);
					}
					resolve(loadsData);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	cancelLoad = (loadNum) => {
		return new Promise((resolve, reject) => {

			Api.cancelLoad(loadNum).then((r) => {
				if (r.status == 200) {
					var activeLoadsDisplay = r.data;

					var newActivity = "Load " + loadNum + " cancelled";
					////this.emitResults(newActivity, 'newActivity');
					//this.emitResults({ activeLoads: activeLoadsDisplay, completedLoads: this.completedLoads }, 'getExistingLoads');
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(activeLoadsDisplay);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	reopenLoad = (loadId) => {
		return new Promise((resolve, reject) => {

			Api.reopenLoad(loadId).then((r) => {
				if (r.status == 200) {
					var activeLoadsDisplay = r.data;

					var newActivity = "Load " + loadId + " cancelled";
					////this.emitResults(newActivity, 'newActivity');
					//this.emitResults({ activeLoads: activeLoadsDisplay, completedLoads: this.completedLoads }, 'getExistingLoads');
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(activeLoadsDisplay);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	completeLoad = (loadNum, date) => {
		return new Promise((resolve, reject) => {
			Api.completeLoad(loadNum, date).then(r => {
				if (r.status === 200) {
					resolve(true);
				}
				else if (r.status === 202) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be completed, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	setStatus = (loadNum, statusBody) => {
		return new Promise((resolve, reject) => {
			Api.setStatus(loadNum, statusBody).then(r => {
				if (r.status === 200) {
					var load = r.data;
					var activeLoadDisplay = r.data;
					var newActivity;
					if (statusBody.waitFuel)
						newActivity = "Load " + r.data.loadNum + " fuel status changed";
					else if (statusBody.gearUp)
						newActivity = "Load " + r.data.loadNum + " gear up status changed";

					////this.emitResults(newActivity, 'newActivity');
					// console.log(activeLoadDisplay);
					//this.emitResults(activeLoadDisplay, 'updateLoad_'+activeLoadDisplay.loadNum);
					resolve(true)
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be closed, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	closeLoad = (loadNum) => {
		return new Promise((resolve, reject) => {
			Api.closeLoad(loadNum).then(r => {
				if (r.status === 200) {
					var load = r.data;
					var activeLoadDisplay = r.data;
					var newActivity = "Load " + r.data.loadNum + " closed";
					////this.emitResults(newActivity, 'newActivity');
					//console.log(activeLoadDisplay);
					// this.emitResults(activeLoadDisplay, 'updateLoad');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be closed, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	openLoad = (loadNum) => {
		return new Promise((resolve, reject) => {
			Api.openLoad(loadNum).then(r => {
				if (r.status === 200) {
					var load = r.data;
					//var activeLoadDisplay = this.transformLoad(r.data);
					var activeLoadDisplay = r.data;
					var newActivity = "Load " + r.data.loadNum + " opened";
					////this.emitResults(newActivity, 'newActivity');
					//console.log(activeLoadDisplay);
					// this.emitResults(activeLoadDisplay, 'updateLoad');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be closed, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	createTandemJump = (loadNum, jumpPostBody) => {
		//alert('create tandem on load: ' + loadNum);
		return new Promise((resolve, reject) => {
			Api.createTandemJump(loadNum, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					//var activeLoadDisplay = this.transformLoad(r.data);
					////console.log(activeLoadDisplay);
					//this.emitResults(activeLoadDisplay,'updateLoad');
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					//this.emitResults(true, "completeJumperActivity");
					resolve(true);

				}
				else if (r.status == 400) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 412) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: "staff already Manifested",
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);

				}
				else if (r.status === 500 || r.status === 404) {
					//this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateJumps = (loadNum, jumps, jumpId, jumpPostBody) => {
		// console.log('update Tandem Jump');
		////this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateJumps(loadNum, jumps, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					console.log("after upldate", load)

					this.emitResults(true, 'loadPriceEvent');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 412) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: "staff already Manifested",
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
				}
				////this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					//this.emitResults(false, 'InitilizeLoader');
					reject(err)
				});
		});
	}

	updateTandemJump = (loadNum, jumpId, jumpPostBody) => {
		// console.log('update Tandem Jump');
		////this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateTandemJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					console.log("after upldate", load)
					this.emitResults(load.load, 'updateWiatListData');
					this.emitResults(true, 'loadPriceEvent');
					resolve(true);
				}
				else if (r.status == 400) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 412) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: "staff already Manifested",
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
				}
				////this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					//this.emitResults(false, 'InitilizeLoader');
					reject(err)
				});
		});
	}
	checkAlbumId = (loadNum, jumpId, jumpPostBody) => {
		// console.log('update Tandem Jump');
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateTandemJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					console.log("after upldate", load)
					this.emitResults(true, 'loadPriceEvent');
					resolve(load);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					//this.emitResults(false, 'InitilizeLoader');
					reject(err)
				});
		});
	}


	updateCompletedPilot = (loadId, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateCompletedPilot(loadId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					// console.log(r.data)
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					var newActivity = "tandem Jump updated on load";
					////this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'completeLoadRight');
					this.emitResults(true, 'loadPriceEvent');
					//this.emitResults(r.data.load, 'updateCompletedLoads');
					// updateLoadHistory(load);
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'completed Jump could not be Updated, please contact support.' });
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateCompleteTandemJump = (loadNum, jumpId, jumpPostBody) => dispach => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateCompletedTandem(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					// console.log(r.data)
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					//var newActivity = "tandem Jump updated on load";
					//	//this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'completeLoadRight');
					this.emitResults(true, 'loadPriceEvent');
					//this.emitResults(r.data.load, 'updateCompletedLoads');
					//updateLoadHistory(load);
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'completed Jump could not be Updated, please contact support.' });
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	deleteCompletedTandemJump = (loadNum, jumpId, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');
		console.log('delete completed tandem jump');

		return new Promise((resolve, reject) => {
			Api.deleteCompletedTandemJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					// console.log(r.data)
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					//	var newActivity = "tandem Jump updated on load";
					////this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'completeLoadRight');
					this.emitResults(true, 'loadPriceEvent');
					//this.emitResults(r.data.load, 'updateCompletedLoads');

					var loading = true;
					this.emitResults(loading, 'loaderActivity');

					// updateLoadHistory(load);
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'completed Jump could not be Updated, please contact support.' });
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateFunJump = (loadNum, jumpId, jumpPostBody) => {
		////alert('load store update tandem jump ' + JSON.stringify(jumpPostBody));
		//this.emitResults(true, 'InitilizeLoader');

		return new Promise((resolve, reject) => {
			Api.updateFunJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var data = r.data;
					var activeLoadDisplay = data.load;
					//var newActivity = "Fun Jump type update on : " + data.load.loadNum;
					////this.emitResults(newActivity, 'newActivity');
					//this.emitResults(activeLoadDisplay, 'updateLoad');
					this.emitResults(true, 'loadPriceEvent');

					if (jumpPostBody.jumpType)
						this.emitResults(true, 'updateJumpType');

					resolve(true)
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
					this.emitResults(false, 'loadPriceEvent');
					resolve(true)

				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	cancelTandemJump = (loadNum, jumpId) => {
		return new Promise((resolve, reject) => {
			Api.cancelTandemJump(loadNum, jumpId).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					//var activeLoadDisplay = this.transformLoad(r.data);
					////console.log(activeLoadDisplay);
					//this.emitResults(activeLoadDisplay,'updateLoad');
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	createCoachJump = (loadNum, jumpPostBody) => {
		return new Promise((resolve, reject) => {
			Api.createCoachJump(loadNum, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;

					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					//this.emitResults(true, "completeJumperActivity");
					resolve(true);

				}
				else if (r.status === 412) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: "staff already Manifested",
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateCoachJump = (loadNum, jumpId, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateCoachJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					this.emitResults(load.load, 'updateWiatListData');
					//var activeLoadDisplay = this.transformLoad(r.data);
					////console.log(activeLoadDisplay);
					//this.emitResults(activeLoadDisplay,'updateLoad');
					this.emitResults(true, 'loadPriceEvent');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);

				}
				else if (r.status === 412) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: "Coach already Manifested",
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);

				}
				else {

					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
					this.emitResults(false, 'loadPriceEvent');
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateCompletedCoachJump = (loadNum, jumpId, jumpPostBody) => dispatch => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateCompletedCoachJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					// console.log("get res updateCompletedCoachJump")
					var newActivity = "Coach Jump updated on load";
					//this.emitResults(newActivity, 'newActivity');
					this.emitResults(true, 'loadPriceEvent');
					this.emitResults(r.data, 'completeLoadRight');
					//this.emitResults(r.data.load, 'updateCompletedLoads');
					// updateLoadHistory(load);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				//this.emitResults(false, 'InitilizeLoader');

			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	deleteCompletedCoachJump = (loadNum, jumpId, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.deleteCompletedCoachJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					// console.log("get res updateCompletedCoachJump")
					var newActivity = "Coach Jump updated on load";
					//this.emitResults(newActivity, 'newActivity');
					this.emitResults(true, 'loadPriceEvent');
					this.emitResults(r.data, 'completeLoadRight');
					//this.emitResults(r.data.load, 'updateCompletedLoads');

					var loading = true;
					this.emitResults(loading, 'loaderActivity');

					// updateLoadHistory(load);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');

			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	cancelCoachJump = (loadNum, jumpId) => {
		return new Promise((resolve, reject) => {
			Api.cancelCoachJump(loadNum, jumpId).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateIadJump = (loadNum, jumpId, jumpPostBody) => {
		console.log(jumpPostBody);
		return new Promise((resolve, reject) => {
			Api.updateIadJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					this.emitResults(true, "completeJumperActivity");
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	createIadJump = (loadNum, jumpPostBody) => {
		return new Promise((resolve, reject) => {
			Api.createIadJump(loadNum, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					this.emitResults(true, "completeJumperActivity");
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}
	addJumpersToIadJump = (loadNum, jumpId, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.addJumpersToIadJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumpers could not be added, please contact support.' });
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	createAffJump = (loadNum, jumpPostBody) => {
		return new Promise((resolve, reject) => {
			Api.createAffJump(loadNum, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					//this.emitResults(true, "completeJumperActivity");
					resolve(true);
				}
				else if (r.status == 400) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 412) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: "staff already Manifested",
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateAffJump = (loadNum, jumpId, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');

		return new Promise((resolve, reject) => {
			Api.updateAffJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					this.emitResults(load.load, 'updateWiatListData');
					this.emitResults(true, 'loadPriceEvent');
					this.emitResults(true, 'updateAffLevel');
					resolve(true);
				}
				else if (r.status == 400) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);
				}
				else if (r.status == 401) {
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 412) {
					//this.emitResults(true, 'warningEvent');
					swal({
						title: "Warning",
						text: "staff already Manifested",
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
					this.emitResults(false, 'loadPriceEvent');
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	updateCompletedAffJump = (loadNum, jumpId, jumpPostBody) => dispach => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.updateCompletedAffJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					var newActivity = "Aff Jump updated on load";
					//this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'completeLoadRight');
					this.emitResults(true, 'loadPriceEvent');
					//this.emitResults(r.data.load, 'updateCompletedLoads');
					// updateLoadHistory(load)
					resolve(true)
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					//this.emitResults(false, 'InitilizeLoader');
					reject(err);
				});
		});
	}

	deleteCompletedAffJump = (loadNum, jumpId, body) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.deleteCompletedAffJump(loadNum, jumpId, body).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					var newActivity = "Aff Jump updated on load";
					//this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'completeLoadRight');
					this.emitResults(true, 'loadPriceEvent');
					//this.emitResults(r.data.load, 'updateCompletedLoads');
					var loading = true;
					this.emitResults(loading, 'loaderActivity');

					// updateLoadHistory(load)
					resolve(true)
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					//this.emitResults(false, 'InitilizeLoader');
					reject(err);
				});
		});
	}



	cancelAffJump = (loadNum, jumpId) => {
		return new Promise((resolve, reject) => {
			Api.cancelAffJump(loadNum, jumpId).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	createFunJump = (loadNum, jumpPostBody) => {
		////this.emitResults(true, 'InitilizeLoader');

		jumpPostBody.pending = false;

		return new Promise((resolve, reject) => {
			Api.createFunJump(loadNum, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.copyValue = {};
					this.copied = false;
					this.emitResults(loading, 'loaderActivity');
					if(r.data.message){
						swal({
							title: "Warning",
							text: r.data.message,
							icon: "warning",
							dangerMode: false,
						})
					}
					//this.emitResults(true, "completeJumperActivity");
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 400) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					if(r.data.message){
						swal({
							title: "Warning",
							text: r.data.message,
							icon: "warning",
							dangerMode: false,
						})
					}
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Fun Jump could not be created, please contact support.' });
					resolve(false);
				}
				////this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	addJumpers = (loadNum, jumpId, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.addJumpersToFunJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					if(r.data.message){
						swal({
							title: "Warning",
							text: r.data.message,
							icon: "warning",
							dangerMode: false,
						})
					}
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 400) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
					resolve(false);
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumpers could not be added, please contact support.' });
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	addCompletedJumpers = (loadNum, jumpId, jumpPostBody) => dispach => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.addCompletedJumpersToFunJump(loadNum, jumpId, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data.load;
					//jumpersStore.setAllDailyActivities(r.data.dailyActivities, r.data.tandemDailyActivities);
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					var newActivity = "Fun Jump updated on load";
					//this.emitResults(newActivity, 'newActivity');
					this.emitResults(r.data, 'completeLoadRight');
					//this.emitResults(r.data.load, 'updateCompletedLoads');
					// updateLoadHistory(load)
					// console.log(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					// console.log(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	approveJumper = (loadNum, jumpId, jumperId) => {
		//this.emitResults(true, 'InitilizeLoader');

		return new Promise((resolve, reject) => {
			Api.approveJumperForFunJump(loadNum, jumpId, jumperId).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumpers could not be added, please contact support.' });
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	removeJumper = (loadNum, jumpId, jumperId) => {
		//this.emitResults(true, 'InitilizeLoader');

		return new Promise((resolve, reject) => {
			Api.removeJumperFromFunJump(loadNum, jumpId, jumperId).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumpers could not be added, please contact support.' });
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	cancelFunJump = (loadNum, jumpId) => {
		return new Promise((resolve, reject) => {
			Api.cancelFunJump(loadNum, jumpId).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jump could not be cancelled, please contact support.' });
					resolve(false);
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	addObserver = (loadNum, jumpPostBody) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.addObserver(loadNum, jumpPostBody).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumpers could not be added, please contact support.' });
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	removeObserver = (loadNum, observerId) => {
		//this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.removeObserver(loadNum, observerId).then((r) => {
				if (r.status == 200) {
					var load = r.data;
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumpers could not be added, please contact support.' });
					resolve(false);
				}
				//this.emitResults(false, 'InitilizeLoader');
			})
				.catch(err => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err)
				});
		});
	}

	createLoad = (payload) => {
		////this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			Api.createActiveLoad(payload).then((r) => {
				if (r.status == 200) {
					//var activeLoadsDisplay = r.data;
					//this.loads = activeLoadsDisplay;
					console.log('createLoad');
					console.log(this.loads);
					var newActivity = "Load " + r.data.loadNum + " created";
					//this.emitResults(newActivity, 'newActivity');
					//this.emitResults(activeLoadsDisplay, 'createLoad');
					var loading = true;
					this.emitResults(loading, 'loaderActivity');
					resolve(true)
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
				////this.emitResults(false, 'InitilizeLoader');
			})
				.catch((err) => {
					//this.emitResults(false, 'InitilizeLoader');
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	loadFunJumpTypes = () => {
		return new Promise((resolve, reject) => {
			Api.loadFunJumpTypes().then((r) => {
				if (r.status == 200) {
					this.funJumpTypes = r.data;
					//alert('update plane type');
					//this.emitResults(true, 'updatePlaneActivity');
					//this.emitResults(newActivity, 'newActivity');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
					resolve(false);
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					resolve(false);
				});
		});
	}
	getFunJumpTypes = () => {
		return this.funJumpTypes;
	}

	updatePlaneType = (loadnum, planeBody) => {
		return new Promise((resolve, reject) => {
			Api.updatePlaneType(loadnum, planeBody).then((r) => {
				if (r.status == 200) {
					//alert('update plane type');
					//this.emitResults(true, 'updatePlaneActivity');
					var newActivity = "Plane type Updated on Load" + loadnum;
					//this.emitResults(newActivity, 'newActivity');
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status == 403) {
					this.unauthenitcated();
					//this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
					resolve(false);
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					resolve(false);
				});
		});
	}

	getCompletedLoad = (start, end) => {
		return new Promise((resolve, reject) => {
			Api.getCompletedLoad(start, end).then((r) => {
				if (r.status == 200) {
					resolve(r.data);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be fetched, please contact support.' });
					resolve(false);
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					resolve(false);
				});
		});
	}

	updateActiveLoadsForDisplay = (loads) => {
		loads.forEach(ld => {
			var indx = this.loads.findIndex(l => l.loadNum === ld.loadNum);
			if (indx >= 0) {
				this.loads[indx] = ld;
			}
		})
	}

	updateCompletedLoadsForDisplay = (loads) => {
		loads.forEach(ld => {
			var indx = this.completedLoads.findIndex(l => l.loadNum === ld.loadNum);
			if (indx >= 0) {
				this.completedLoads[indx] = ld;
			}
		})
	}
	setActiveLoadsForDisplay = (loads) => {
		this.loads = loads;
	}


	getSettings = () => {
		return this.settings;
	}

	getNextManifestNumber = () => {
		var manifestNumberSetting = this.settings.filter(setting => setting.name === "next manifest number")[0];
		if (manifestNumberSetting !== undefined && !isNaN(this.settings.filter(setting => setting.name === "next manifest number")[0].value)) {
			return Number(this.settings.filter(setting => setting.name === "next manifest number")[0].value);
		}
		else {
			return 2001;
		}

	}

	isTaxEnabled = () => {
		return this.enableTax;
	}

	saveFunJump = (funJump) => {
		this.savedFunJump = funJump;
	}

	getSavedFunJump = () => {
		return this.savedFunJump;
	}

	cancelCreateJump = (payload) => {
		this.emitResults(payload, 'cancelCreateJump');
	}

	cancelPreviousJump = (loadnum) => {
		this.emitResults(loadnum, 'cancelPreviousJump');
	}

	completeLoadChange = (data) => {
		this.emitResults(data, 'completeLoadChangeEvent');
	}



	getActiveLoadsForDisplay = () => {
		// console.log(this.loads);
		return this.loads;
	}
	getActiveLoadsForDisplayByLoadNum = (loadNum) => {
		// console.log(this.loads);
		return this.loads.filter(ld => ld.loadNum === loadNum)[0];
	}

	getSchoolWaitlist = () => {
		return this.schoolWaitlist;
	}

	getPlanesOptions = () => {
		var planeOptions = this.planes.map(plane => { return { label: plane.plane } });
		return planeOptions;
	}

	getPlanes = () => {
		return this.planes;
	}

	getPlaneDisplaySetting = () => {
		return this.planeDisplaySetting;
	}

	getPlaneCapacity = (searchPlane) => {
		var planes = this.planes.filter(plane => plane.plane === searchPlane);
		if (planes.length === 1) {
			return planes[0].capacity;
		}
		else return 0;
	}
	loadTeams = () => {
		return new Promise((resolve, reject) => {
			Api.loadTeams().then((r) => {
				// console.log("🚀 ~ file: loads-store.js ~ line 1990 ~ Loads ~ Api.loadTeams ~ r", r)
				if (r.status === 200) {
					resolve(r.data);
					// this.teams = r.data;
					this.emitResults(r.data, "teamsLoaded")
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					resolve(false);
				});
		});
	}
	createEditTeams = (payload) => {
		return new Promise((resolve, reject) => {
			Api.createEditTeams(payload).then((r) => {

				if (r.status === 200) {
					// console.log("🚀 ~ file: loads-store.js ~ line 2006 ~ Loads ~ Api.createEditTeams ~ r", r.data)
					resolve(r.data);
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					resolve(false);
				});
		});
	}
	deleteTeam = (id) => {
		return new Promise((resolve, reject) => {
			Api.deleteTeam(id).then((r) => {
				if (r.status === 200) {
					if (r.data.ok === 1) {
						resolve(true);
					}
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(false);
				});
		});
	}

	setDragabble = (item) => {
		let data = [...this.dragabbleItems]
		let index = data?.findIndex(i => i?.jumpId === item?.jumpId)
		if (index === -1) {
			data.push(item)
		} else {
			data.splice(index, 1)
		}
		this.dragabbleItems = data
	}

	getDraggable = () => {
		return this.dragabbleItems
	}

	clearDraggable = () => {
		this.dragabbleItems = []
		var inputs = document.querySelectorAll('.selectable');
		for (var i = 0; i < inputs.length; i++) {
			inputs[i].checked = false;
		}
	}

	setNowStatus = (loadNum, timeRemaining) => {
		var load = this.loads.filter(ld => ld.loadNum === loadNum)[0];
		if (load) {
			load.timeRemaining = timeRemaining
			this.emitResults(load, 'updateLoad_' + loadNum);
		}
	}

	getLoadNumByIndx = (indx) => {
		console.log(this.loads);
		if (this.loads[indx]) {
			return this.loads[indx].loadNum;
		}
		return -1;
	}

	clearState = () => {
		this.dailyActivities = {};
		this.planes = [];
		this.loads = [];
		this.completedLoadArray = [];
		this.emitResults(true, 'clearSession');
	}

	// storeFunJump = () => {
	// 	this.emitResults(this.funLoadNum, 'checkFunState');
	// }

	emitError = (err) => {
		this.emit('ERROR', err.message);
	}

	getEventPrefix = (func) => {
		return `${this.prefix}${func}`;
	}

	getNabHostedFormTerminalId = () => {
		if (localStorage.getItem("role") === "school" && window.ENV?.schoolProcessorInfo) {
			return window.ENV?.schoolProcessorInfo.paymentKey
		}
		else {
			return window.ENV?.processorInfo.paymentKey
		}
	}

	emitResults = (r, func) => {
		if (!r) {
			this.emitError({ alert: true, message: 'No data found' });
		}
		this.setMaxListeners(1000);
		this.emit(`${this.prefix}${func}`, r);
	}

	debug = (r) => {
	}
	loginExpired = () => {
		//alert(JSON.stringify(localStorage, null,2));
		if(localStorage.getItem('isAuth')){
			localStorage.clear();
			swal({
				title: "LOGIN EXPIRED",
				text: "please re-login",
				icon: "warning",
				dangerMode: true,
			})
		}

		//window.location =  window.location.origin
		//window.location.reload();
	}
	unauthenitcated = () => {
		swal({
			title: "UNAUTHENTICATED",
			text: "user doesn't have permissions for this action",
			icon: "warning",
			dangerMode: true,
		})
	}
	transformLoad(loadBackend) {
		var load = { _id: loadBackend._id, closed: loadBackend.closed, plane: loadBackend.plane, loadNum: loadBackend.loadNum, capacity: loadBackend.capacity, totalJumpers: loadBackend.totalJumpers, totalSlots: loadBackend.totalSlots, pilotId: loadBackend.pilotId, activeJumperIds: loadBackend.activeJumperIds };
		load.tandemJumps = loadBackend.tandemJumps.map((jump) => jumpersStore.mapTandemForRender(jump));
		load.coachJumps = loadBackend.coachJumps.map((jump) => jumpersStore.mapCoachJumpForRender(jump));
		load.affJumps = loadBackend.affJumps.map(jump => jumpersStore.mapAffForRender(jump));
		load.funJumps = loadBackend.funJumps.map(jump => jumpersStore.mapFunJumpForRender(jump));
		return load;
	}
}

const loadsStore = new Loads();
window.loadsStore = loadsStore;


export default loadsStore;
