/**
 * http://usejsdoc.org/
 */

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Control, LocalForm } from 'react-redux-form';
import Select from 'react-select';
import loadsStore from '../../../stores/loads-store';
import jumpersStore from '../../../stores/jumpers-store';
import ReactDOM from 'react-dom';
import LoadUpdateModal from './loadModal'

class EditablePrice extends Component {

  constructor(props) {


    super(props);
    this.handelConfirmSubmit = this.handelConfirmSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpen: false,
      jumpType: null,
      load: props.load,
      Price: props.Price,
      type: props.type,
      label: props.type,
      jumpId: props.jumpId,
      editMode: props.editMode,
      PriceId: '',
      loadNum: props.loadNum,
      menuPlacement: "bottom",
      level: props.level,
      isCompleted: props.isCompleted,
      student: props.student,
      warningPopUp: false
    };

    this.timerId = null;
    this.inputValue = '';
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    if (this.refInterval) {
      clearInterval(this.refInterval);
    }
    document.removeEventListener("click", this.handleClickOutside, true);
  }


  handleClickOutside = event => {
    if (this.valRef && !this.valRef.contains(event.target)) {
      this.setState({
        editMode: false
      });
    }
  }


  checkForNegatives = (price) => {
    if (price != undefined && !isNaN(price)) {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (window.ENV?.showGreenNegative) {
        if (price < 0) {
          return <span className="editablePrice" style={{ color: '#00FF00' }}> {priceCell.value}</span>;
        }
        return <span className="editablePrice" style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
      }
      else {
        if (price < 0) {
          return <span className="editablePrice" style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
        }
        return <span className="editablePrice" style={{ color: '#00FF00' }}> {priceCell.value}</span>;
      }

    }
    else if (isNaN(price)) {
      return 'NaN';
    }
    else {
      return '$0.00';
    }
  }

  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    }, () => {
      if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
        this.setState({ menuPlacement: "top" });
      }
      else {
        this.setState({ menuPlacement: "bottom" });
      }
    }
    )
  }

  changePrice = (isCompleted) => {

    localStorage.setItem("disabledArrowButton", true)
    if (isCompleted) {
      this.setState({
        isModalOpen: true
      })
    }
    else {
      if (!this.state.editMode) {
        this.setState({
          editMode: true
        })
      }
    }
  }

  handelConfirmSubmit() {
    this.setState({
      warningPopUp: !this.state.warningPopUp
    })
  }

  toggleModal() {
    this.setState({
      warningPopUp: !this.state.warningPopUp
    })
  }

  onBlur = () => {
    localStorage.setItem("disabledArrowButton", false)
    if (this.state.editMode && !this.props.isCompleted) {
      this.setState({
        editMode: false
      })
    }
  }


  handleInput(e, val) {
    alert(e);
  }

  handleSubmit(values, type, loadNum, jumpId, jumperId, loadId, label, jump, isCompleted, isUltra, isWingsuit, isToday) {
    this.setState({ editMode: !this.state.editMode });
    var today = new Date();
    var isWeekend = (today.getDay() % 6 === 0);
    if (type === "paybeforeOV") {
      var payload = {
        amount: Number(values.price),
        type: "overweight"
      };

      jumpersStore.addPayBeforeItem(jumperId, payload)
        .then(ret => {
          if (this.props.manifestStatus !== undefined && this.props.manifestStatus.length > 0) {

            var jumpPostBody = {
              overweightPrice: Number(values.price)
            };
            loadsStore.updateTandemJump(this.props.manifestStatus[0].load, this.props.manifestStatus[0].jumpId, jumpPostBody)

          }
          this.onBlur();
        })
    }
    else if (type === "tandemPackage") {
      var payload = {
        price: Number(values.price),
        type: "tandemPackage"
      }
      jumpersStore.setPackagePrice(jumperId, payload)
        .then(ret => {
          if (this.props.manifestStatus !== undefined && this.props.manifestStatus.length > 0) {

            var jumpPostBody = {
              price: Number(values.price)
            };
            loadsStore.updateTandemJump(this.props.manifestStatus[0].load, this.props.manifestStatus[0].jumpId, jumpPostBody)

          }

          this.onBlur();


        })
    }
    else if (type === "videoPackage") {
      var payload = {
        price: Number(values.price),
        type: "videoPackage"
      }
      jumpersStore.setPackagePrice(jumperId, payload)
        .then(ret => {
          if (this.props.manifestStatus !== undefined && this.props.manifestStatus.length > 0) {

            var jumpPostBody = {
              videoPrice: Number(values.price)
            };
            loadsStore.updateTandemJump(this.props.manifestStatus[0].load, this.props.manifestStatus[0].jumpId, jumpPostBody)

          }
          this.onBlur();
        })
    }
    else if (type == "funJump") {
      if (isCompleted) {
        var cjumpId = jumpId;
        var loadId = loadId;

        var jumpPostBody = {
          jumperIds: jump.jumpers.map(jumper => { return { jumperId: jumper._id, price: jumper.price, block: jumper.block, blockId: jumper.blockId, gearPrice: jumper.gearPrice, gearType: jumper.gearType, isVideographer: jumper.isVideographer, tracked: jumper.tracked, isLOing: jumper.isLOing, chargeTo: jumper.chargeTo } }),
          jumpType: jump.jumpType,
        }

        if (isToday) {
          jumpPostBody.isToday = true
        }

        jumpPostBody.gearRentals = jump.gearRentals;
        jumpPostBody.studentgearRentals = jump.studentgearRentals;
        jumpPostBody.sportgearRentals = jump.sportgearRentals;
        jumpPostBody.teamName = jump.teamName;
        jumpPostBody.teamId = jump.teamId;
        jumpPostBody.loadNum = jump.loadNum;
        jumpPostBody.planeLoad = jump.planeLoad;
        jumpPostBody.editPrice = true;
        var item = jumpPostBody.jumperIds.filter(id => id.jumperId === jumperId)[0];
        if (label === "JP") {
          if (values.price === 'block') {
            item.block = 'block';
            item.price = 0;
          }
          else {
            item.price = Number(values.price);
          }
        }
        else if (label[0] === "G") {
          item.gearPrice = Number(values.price)
        }
        this.props.addCompletedJumpers(loadId, jumpId, jumpPostBody);

      }
      else {
        var jumpPostBody = {
          jumperId: jumperId
        }

        if (label === "JP") {
          jumpPostBody.price = Number(values.price);
          //jumpPostBody.videoPrice = 0;
        }
        else if (label[0] === "G") {
          jumpPostBody.gearPrice = Number(values.price);
          //jumpPostBody.videoPrice = 0;
        }
        loadsStore.updateFunJump(loadNum, jumpId, jumpPostBody)
          .then(ret => {
            this.onBlur();
          })
      }
    }
    else if (type === 'tandem') {
      if (isCompleted) {

        //alert(isUltra);
        var cjumpId = jumpId;
        var loadId = loadId;
        var jumpPostBody = {
          _id: jump.jumpId,
          jumpId: jump.jumpId,
          instructorId: jump.instructor._id,
          studentId: jump.student._id,
          price: jump.price,
          videoPrice: jump.videoPrice,
          isWeekend: isWeekend,
          isUltra: isUltra,
          isToday: isToday,
          isPriceUpdate: true,
          instructorPay: jump.instructorPay,
          videoPay: jump.videoPay,
          wristcamPay: jump.wristcamPay,
          overweightPay: jump.overweightPay,
          overweightPrice: jump.overweightPrice,
          loadNum: jump.loadNum,
          planeLoad: jump.planeLoad,
          videoType: jump.videoType,
          isOverweight: jump.isOverweight,
          tandemPackageName: jump.tandemPackageName,
          altitude: jump.altitude
        }
        if (jump.videoPackageName) {
          jumpPostBody.videoPackageName = jump.videoPackageName;
        }
        if (jump.videographer._id) {
          jumpPostBody.videographerId = jump.videographer._id;
        }
        if (label === "S") {
          jumpPostBody.price = Number(values.price);
          //jumpPostBody.videoPrice = 0;
        }
        else if (label === "I") {
          jumpPostBody.instructorPay = Number(values.price);
        }
        else if (label === "V") {
          jumpPostBody.videoPay = Number(values.price);
        }
        else if (label === "SV") { // overweight pay for intructor
          jumpPostBody.videoPrice = Number(values.price)
        }
        else if (label === "OI") { // overweight pay for intructor
          jumpPostBody.overweightPay = Number(values.price)
        }
        else if (label === "WC") { // wristcam pay for intructor
          jumpPostBody.wristcamPay = Number(values.price)
        }
        else if (label === "OS") { //overweight price for intstructor
          jumpPostBody.overweightPrice = Number(values.price)
        }
        else if (label === "T") { //overweight price for intstructor
          jumpPostBody.tip = Number(values.price)
        }
        else if (label === "TP") { //overweight price for intstructor
          jumpPostBody.tipPay = Number(values.price)
        }
        jumpPostBody.editPrice = true;

        this.props.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody)
          .then(ret => {
            //alert(ret);
            this.onBlur();
          })
      }
      else {
        var jumpPostBody;
        if (label === "S") {
          jumpPostBody = {
            price: Number(values.price)
          };
        }
        else if (label === "SV") { // overweight pay for intructor
          jumpPostBody = {
            videoPrice: Number(values.price)
          };
        }
        else if (label === "I") {
          jumpPostBody = {
            instructorPay: Number(values.price)
          };
        }
        else if (label === "V") {
          jumpPostBody = {
            videoPay: Number(values.price)
          };
        }
        else if (label === "OI") { // overweight pay for intructor
          jumpPostBody = {
            overweightPay: Number(values.price)
          };
        }
        else if (label === "WC") { // wristcam pay for intructor
          jumpPostBody = {
            wristcamPay: Number(values.price)
          };
        }
        else if (label === "OS") { //overweight price for intstructor
          jumpPostBody = {
            overweightPrice: Number(values.price)
          };
        }
        else if (label === "T") { //overweight price for intstructor
          jumpPostBody = {
            tip: Number(values.price)
          };
        }
        else if (label === "TP") { //overweight price for intstructor
          jumpPostBody = {
            tipPay: Number(values.price)
          };
        }
        loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
          .then(ret => {
            if (label === "OS"){
              var newPayload = {
                amount: Number(values.price),
                type: "overweight"
              };
              jumpersStore.addPayBeforeItem(this.props.jumperId, newPayload)
            }
            this.onBlur();
          })
      }
    }
    else if (type === 'aff') {
      if (isCompleted) {

        //alert(isUltra);
        var cjumpId = jumpId;
        var loadId = loadId;
        var jumpPostBody = {
          _id: jump.jumpId,
          jumpId: jump.jumpId,
          studentId: jump.student._id,
          level: jump.level,
          price: jump.price,
          block: jump.block,
          blockId: jump.blockId,
          numInstructors: jump.numInstructors,
          videoPrice: jump.videoPrice,
          instructor1Pay: jump.instructor1Pay,
          instructor2Pay: jump.instructor2Pay,
          studentPackageName: jump.studentPackageName,
          studentPackageId: jump.studentPackageId,
          isToday: isToday,
          isPriceUpdate: true,
          videoPay: jump.videoPay,
          altitude: jump.altitude,
          loadNum: jump.loadNum,
          planeLoad: jump.planeLoad,
        }
        if (jump.mainInstructor !== undefined) {
          jumpPostBody.mainInstructorId = jump.mainInstructor._id;
        }
        if (jump.secondaryInstructor !== undefined) {
          jumpPostBody.secondaryInstructorId = jump.secondaryInstructor._id;
        }
        if (jump.videographer._id) {
          jumpPostBody.videographerId = jump.videographer._id;
        }
        if (label === "S") {
          jumpPostBody.price = Number(values.price);
          jumpPostBody.videoPrice = 0;
        }
        else if (label === "I1") {
          jumpPostBody.instructor1Pay = Number(values.price);
        }
        else if (label === "I2") {
          jumpPostBody.instructor2Pay = Number(values.price);
        }
        else if (label === "V") {
          jumpPostBody.videoPay = Number(values.price);
        }
        jumpPostBody.editPrice = true;

        this.props.updateCompletedAffJump(loadId, cjumpId, jumpPostBody)
          .then(ret => {
            //alert(ret);
            this.onBlur();
          })
      }

      else {
        if (label === "I1") {
          var jumpPostBody = {
            instructor1Pay: Number(values.price)
          };
        }
        else if (label === "I2") {
          var jumpPostBody = {
            instructor2Pay: Number(values.price)
          };
        }
        else if (label === "V") {
          var jumpPostBody = {
            videoPay: Number(values.price)
          };
        }
        if (label === "S") {
          var jumpPostBody = {
            price: Number(values.price)
          };

        }
        loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody)
          .then(ret => {
            this.onBlur();
          })
      }
    }
    else if (type === "coach") {
      if (isCompleted) {

        //alert(isUltra);
        var cjumpId = jumpId;
        var loadId = loadId;
        var jumpPostBody = {
          coachId: jump.coach._id,
          jumperId: jump.jumper._id,
          isWingsuit: isWingsuit,
          isToday: isToday,
          isPriceUpdate: true,
          videoPay: jump.videoPay,
          price: jump.price,
          coachPay: jump.coachPay
        }
        if (label === "J") {
          jumpPostBody.price = Number(values.price);
        }
        else if (label === "C") {
          jumpPostBody.coachPay = Number(values.price);
        }
        jumpPostBody.editPrice = true;

        this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)
          .then(ret => {
            //alert(ret);
            this.onBlur();
          })
      }
      else {
        if (label === "C") {
          var jumpPostBody = {
            coachPay: Number(values.price)
          };
        }
        else if (label === "J") {
          var jumpPostBody = {
            price: Number(values.price)
          };
        }
        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody)
          .then(ret => {
            this.onBlur();
          })
      }
    }
    else if (type === "iad") {
      if (isCompleted) {

        //alert(isUltra);
        var cjumpId = jumpId;
        var loadId = loadId;
        var jumpPostBody = {
          coachId: jump.coach._id,
          jumperId: jump.jumper._id,
          isWingsuit: isWingsuit,
          isToday: isToday,
          isPriceUpdate: true,
          videoPay: jump.videoPay,
          price: jump.price,
          coachPay: jump.coachPay
        }
        if (label === "J") {
          jumpPostBody.price = Number(values.price);
        }
        else if (label === "C") {
          jumpPostBody.coachPay = Number(values.price);
        }
        this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)
          .then(ret => {
            //alert(ret);
            this.onBlur();
          })
      }
      else {
        if (label === "I") {
          var jumpPostBody = {
            instructorPay: Number(values.price)
          };
        }
        else if (label === "S1") {
          var jumpPostBody = {
            price1: Number(values.price)
          };
        }
        else if (label === "S2") {
          var jumpPostBody = {
            price2: Number(values.price)
          };
        }
        else if (label === "S3") {
          var jumpPostBody = {
            price3: Number(values.price)
          };
        }
        loadsStore.updateIadJump(loadNum, jumpId, jumpPostBody)
          .then(ret => {
            this.onBlur();
          })
      }
    }
    else if (type === "pilot") {
      if (isCompleted) {
        var jumpPostBody = {
          pilotId: jumperId,
          pilotPay: Number(values.price),
          _id: this.props.loadId,
          plane: this.props.plane,
          planeLoadNum: this.props.planeLoadNum,
          loadNum: this.props.loadNum
        }
        if (this.props.isToday) {
          jumpPostBody.isToday = true
        }

        jumpPostBody.isPriceUpdate = false;

        var loadId = this.props.loadId;
        loadsStore.updateCompletedPilot(loadId, jumpPostBody);
      }
      else {
        var jumpPostBody = { pilotPay: Number(values.price) };
        loadsStore.UpdateLoad(loadNum, jumpPostBody);
      }
    }
    else if (type === "observer") {
      if (isCompleted) {

      }
      else {
        var jumpPostBody = {
          observerId: this.props.jumperId,
          price: Number(values.price)
        };

        loadsStore.UpdateLoad(loadNum, jumpPostBody)
          .then(ret => {
            this.onBlur();
          })
      }
    }
  }

  setRef = ref => {
    this.valRef = ref;

    if (this.refInterval) {
      clearInterval(this.refInterval);
    }

    this.refInterval = setInterval(() => {
      if (ref) {
        ref.focus();
      } else {
        clearInterval(this.refInterval);
      }
    })
  }

  returnKeyPress = (evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '+') && evt.preventDefault()

  render() {
    //alert(JSON.stringify(this.props.Price,2));
    return (
      <div ref={ref => this.valRef = ref}>
        {
          !this.state.editMode ?
            // <button className="btn lightBlueButton Price-name" onClick={(e) => { e.preventDefault(); this.changePrice(this.props.isCompleted); }} type="submit" disabled={this.props.disabled}>
            <div
              onClick={(e) => { e.preventDefault(); this.changePrice(this.props.isCompleted); }}
              disabled={this.props.disabled}>
              {(this.props.label !== undefined && this.props.label[0]) === "G" ? this.props.label : null}
              {this.props.label !== undefined && this.props.label[0] === "O" ? "OW" : null}
              {this.props.label !== undefined && this.props.label[0] === "W" ? "WC" : null}
              {this.props.label !== undefined && this.props.label === "SV" ? "V" : null}
              {this.props.label !== undefined && this.props.label === "T" ? "T" : null}
              {this.props.label !== undefined && this.props.label === "TP" ? "T" : null}
              {this.checkForNegatives(this.props.price)}
            </div>

            //</button>
            :
            // <button className="btn lightBlueButton Price-name" disabled={this.props.disabled}>
            <LocalForm form="editPrices" model="editPrices" getDispatch={dispatch => this.formDispatch = dispatch} onSubmit={(values) => this.handleSubmit(values, this.props.type, this.props.loadNum, this.props.jumpId, this.props.jumperId, this.props.loadId, this.props.label, this.props.jump, this.props.isCompleted, this.props.isUltra, this.props.isWingsuit, this.props.isToday)}>
              <Control.input name="price" onKeyDown={(evt) => this.returnKeyPress(evt)} type="number" step="any" model=".price" className="form-control" style={{ width: '80px' }}
                getRef={this.setRef}
                mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.price}
                autoComplete="off" onBlur={this.onBlur} />
            </LocalForm>
        }
        <LoadUpdateModal
          isOpen={this.state.isModalOpen}
          onClick={this.wantsEidtable}
        />
      </div>
    );
  }
}

const mstp = state => {
  return {
    state
  }
}
const mdtp = dispatch => {
  return {
    updateCompletedAffJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedAffJump(loadId, cjumpId, jumpPostBody)),
    updateCompleteTandemJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody)),
    updateCompletedCoachJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)),
    addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
  }
}

export default connect(mstp, mdtp)(EditablePrice)
