import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import CancelJump from './modals/cancelJump'
import EditableJumper from './modals/editableJumper'
import AffEditableJumper from './modals/editableAffJump'
import { Card, CardBody, Row, Col, CardFooter } from 'reactstrap'
import CreatableJumper from './modals/creatableJumper'
import UpdateAmount from './modals/updateAmount';
import SubmitStudentLog from './modals/submitStudentLog';
import { Badge } from 'reactstrap';
import ReactDOM from 'react-dom';
import EditablePrice from './modals/editablePrice'
import loadsStore from '../../stores/loads-store'
import itemStore from '../../stores/item-store'
import { returnClasses } from './helper/schoolwaitlist';
import AffNestedMenu from './subComponents/NestedMenus'
import returnLoadSettings from './helper/returnLoadSettings'
import multiselect from './helper/multiselectJumps'
import multiCopyPasteClick from './helper/multiCopyPaste'
import UpdateNote from './modals/updateNote';

var listStyle = { display: 'inline-block', marginRight: '5px', backgroundColor: 'none !important', fontSize: '12px', padding: '3px', borderRadius: '5px', marginBottom: '1px' }

const Types = {
  ITEM: 'JUMP'
};

const itemSource = {
  beginDrag(props) {
    const dragabbleItems = loadsStore.getDraggable();
    if (dragabbleItems?.length > 0) {
      return { items: dragabbleItems }
    } else {
      return props;
    }
  },
  endDrag(props, monitor, component) {
    // if (!monitor.didDrop()) {
    //   return;
    // }
    //return props;
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      // props.clearItemSelection();
      loadsStore.clearDraggable()
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}


class RenderAffJump extends Component {

  constructor() {
    super();
    this.state = {
      optionMenu: false,
      optionSub: false,
      copyOptionSub: false,
      cutOptionSub: false,
      anchorEl: null
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    let id;
    if (document.querySelector('#copy-aff-menu')) {
      id = document.querySelector('#copy-aff-menu')
    } else {
      id = document.querySelector('#move-aff-menu')
    }

    if (!domNode || !domNode.contains(event.target)) {
      if (this.state.optionMenu) {
        if (!id) {
          this.setState({
            optionMenu: false
          });
        }
      }
    }
  }

  handleUpdate = (jumpid) => {
    document.getElementById(jumpid).click();
  }

  handleDelete = (jumpId) => {
    document.getElementById(jumpId).click();
  }

  toggleOptions = () => {
    this.setState({
      optionMenu: !this.state.optionMenu
    })
  }

  checkForNegatives = (price, videoPrice) => {
    if (price != undefined) {
      if (videoPrice !== undefined) {
        price += videoPrice;
      }
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <Badge style={{ color: 'red' }}> {priceCell.value}</Badge>;
      }

      return <Badge>{priceCell.value}</Badge>;
    }
    else {
      return '';
    }
  }

  handleCopyClick = (affJump = this.props.affJump, loadNum = this.props.loadNum, uid = -1000) => {
    const dragItem = loadsStore.getDraggable()
    if (dragItem.length > 0) {
      multiselect(dragItem)
    } else {
      var payload = {};
      payload = JSON.parse(JSON.stringify(affJump));
      if (affJump.mainInstructor) {
        payload.mainInstructorId = affJump.mainInstructor["_id"]
      }
      payload.studentId = affJump.student["_id"]
      if (affJump.secondaryInstructor) {
        payload.secondaryInstructorId = affJump.secondaryInstructor["_id"]
      }
      payload.videographerId = affJump.videographer["_id"];
      payload.copy = true;
      payload.note = affJump.note;

      loadsStore.copied = true;
      loadsStore.cut = false;
      loadsStore.copyValue = payload;
    }

    //alert(JSON.stringify(payload));
    if (uid !== -1000) {
      var element = document.getElementById(uid);
      element.style.display = "none";
    }

  }
  handleCutClick = (affJump = this.props.affJump, loadNum = this.props.loadNum, jumpId = this.props.jumpId) => {
    this.setState({
      cutOptionSub: true
    })
    const dragItem = loadsStore.getDraggable()
    if (dragItem.length > 0) {
      multiselect(dragItem, loadNum)
    } else {
      var payload = {};
      payload = affJump;
      if (affJump.mainInstructor) {
        payload.mainInstructorId = affJump.mainInstructor["_id"];
      }
      payload.studentId = affJump.student["_id"]
      if (affJump.secondaryInstructor) {
        payload.secondaryInstructorId = affJump.secondaryInstructor["_id"]
      }
      payload.videographerId = affJump.videographer["_id"];
      payload.note = affJump.note;
      loadsStore.cut = true;
      loadsStore.cutValue = { loadNum, jumpId }
      loadsStore.copyValue = payload;
    }
  }

  handleCopyPasteClick = (loadNum) => {
    this.setState({
      optionMenu: !this.state.optionMenu,
      optionSub: false,
      copyOptionSub: false,
      cutOptionSub: false
    })
    var Value = loadsStore.copyValue;
    if (Value?.length && Value.length > 0) {
      multiCopyPasteClick(Value, loadNum)
    } else {
      loadsStore.createAffJump(loadNum, Value).then(d => {
        if (d) {
          if (loadsStore.cut) {
            loadsStore.cut = false;
            const { loadNum, jumpId } = loadsStore.cutValue;
            loadsStore.cancelAffJump(loadNum, jumpId);
          }
        }
      })
    }
  };

  updateCallRequest = (loadNum, jumpId, val) => {
    var jumpPostBody = { callRequest: val };
    let data = loadsStore.getDraggable()

    let index = data.findIndex(i => i.jumpId === jumpId)

    if (index === -1) {
      loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody)
        .then(ret => {
          this.setState({
            optionMenu: false
          });
        })
    } else {
      data.map((i, ind) => {
        let type = ''
        if (i.jumpType === 'coachJump') {
          type = 'coachJumps'
        } else if (i.jumpType === 'tandemJump') {
          type = 'tandemJumps'
        } else if (i.jumpType === 'affJump') {
          type = 'affJumps'
        }
        loadsStore.updateJumps(loadNum, type, i.jumpId, jumpPostBody)
          .then(ret => {
            this.setState({
              optionMenu: false
            });
            if (ind === data.length - 1) {
              // this.props.clearItemSelection()
              loadsStore.clearDraggable()
            }
          })
      })
    }
  }

  setPanchorEl = (data) => {
    this.setState({
      anchorEl: data
    })
  }

  handleItem = (e, jump) => {
    // this.props.setDragabble(jump)
    loadsStore.setDragabble(jump)
  }

  // isChecked = (item) => {
  //   let index = loadsStore.getDraggable()?.findIndex(i => i.jumpId === item.jumpId)
  //     if(index === -1){
  //       return false
  //     } else {
  //       return true
  //     }
  // }

  render() {
    console.log('in render aff jump: load ' + this.props.loadNum);
    const { affJump, isCompleted, loadNum, jumpId, affInstructorOptions, videographerOptions, affStudentOptions, connectDragSource, price, isCenter, isRightMenu, loadsToShow, columnsPerGroup, fontSize, isInline } = this.props;
    var videoPrice;

    if (isCompleted) {
      console.log('rendering completed aff');
    }
    else {
      console.log('rendering aff');
    }

    if (affJump.videoPrice !== undefined) {
      videoPrice = affJump.videoPrice
    }

    var instructor1Name = "";
    if (affJump.mainInstructor) {
      instructor1Name = affJump.mainInstructor.firstname + " " + affJump.mainInstructor.lastname;
    }
    var instructor2Name = "";
    if (affJump.secondaryInstructor) {
      instructor2Name = affJump.secondaryInstructor.firstname + " " + affJump.secondaryInstructor.lastname;
    }

    if (this.props.schoolWaitlist) {
      var activeLoads = loadsStore.getActiveLoadsForDisplay();
      console.log(activeLoads);
      var callRequestMenuString = ['hold', 'any 20', 'any 30', 'any 40'];
      activeLoads.forEach(ld => {
        callRequestMenuString.push('next available after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('20 after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('30 after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('40 after ' + ld.plane + "-" + ld.planeLoadNum);
      })
      var callRequestMenu = callRequestMenuString.map(str => {
        return (
          <li onClick={() => this.updateCallRequest(loadNum, jumpId, str)}>
            <span>{str}</span>
          </li>
        )
      })
    }

    const { settingsMoreThan4, settingsFunjumpClass, settingsShowDotMenu, settingsSetPos, settingsStyledObject } = returnLoadSettings(loadsToShow, columnsPerGroup, isInline, fontSize)
    return connectDragSource(
      <div className={returnClasses(isRightMenu, isCenter)}>

        {
          this.state.optionMenu &&
          <ul id={`copy-load-aff-Id-${loadNum}`} className={loadsToShow > 3 ? "menu-option-moreloads" : "menu-option"}>


            <li>
              <span onClick={() => this.handleUpdate("submit-log" + jumpId)}>View/Submit Log</span>
            </li>

            <li>
              <span style={{ color: 'red' }} onClick={() => this.handleDelete("delete-jump" + jumpId)}>Delete</span>
            </li>
            {!this.props.isCompleted && <li>
              <span onClick={() => this.handleUpdate("update-note" + jumpId)}>add note</span>
            </li>}
            <AffNestedMenu
              txt={"Copy"}
              loadNum={this.props.loadNum}
              loads={loadsStore.getActiveLoadsForDisplay()}
              handleCopyClick={this.handleCopyClick}
              handleCopyPasteClick={this.handleCopyPasteClick}
              id={"copy-aff-menu"}
            />
            <AffNestedMenu
              txt={"Move"}
              loadNum={this.props.loadNum}
              loads={loadsStore.getActiveLoadsForDisplay()}
              handleCopyClick={this.handleCutClick}
              handleCopyPasteClick={this.handleCopyPasteClick}
              id={"move-aff-menu"}
            />
            {/* <li style={{ position: "relative" }} onMouseLeave={() => setTimeout(() => { this.setState({ copyOptionSub: false }) }, 500)}>
              <span onClick={() => this.handleCopyClick(affJump, loadNum, -1000)} onMouseOver={() => setTimeout(() => { this.handleCopyClick(affJump, loadNum, -1000) }, 500)}>Copy</span>
              {
                this.state.copyOptionSub &&
                <ul className="menu-option" style={{ zIndex: '100', position: 'absolute', top: '0px', right: '211px' }} onMouseLeave={() => setTimeout(() => { this.setState({ copyOptionSub: false }) }, 500)}>
                  {
                    this.props.loads && this.props.loads.map((load, i) => {
                      if (load.loadNum != loadNum) {
                        return (<li>
                          <span onClick={() => this.handleCopyPasteClick(load.loadNum)}>{"Load " + load.loadNum + " " + load.plane + " " + load.planeLoadNum}</span>
                        </li>)
                      }
                    })
                  }
                </ul>
              }
            </li> */}

            {/* {!this.props.isCompleted && <li style={{ position: "relative" }} onMouseLeave={() => setTimeout(() => { this.setState({ cutOptionSub: false }) }, 500)}>
              <span style={{ color: 'red' }} onClick={() => this.handleCutClick(affJump, loadNum, jumpId)} onMouseOver={() => setTimeout(() => { this.handleCutClick(affJump, loadNum, jumpId) }, 500)}>Move</span>
              {
                this.state.cutOptionSub &&
                <ul className="menu-option" style={{ zIndex: '100', position: 'absolute', top: '0px', right: '211px' }} onMouseLeave={() => setTimeout(() => { this.setState({ cutOptionSub: false }) }, 500)}>
                  {
                    this.props.loads && this.props.loads.map((load, i) => {
                      if (load.loadNum != loadNum) {
                        return (<li>
                          <span onClick={() => this.handleCopyPasteClick(load.loadNum)}>{"Load " + load.loadNum + " " + load.plane + " " + load.planeLoadNum}</span>
                        </li>)
                      }
                    })
                  }
                </ul>
              }
            </li>} */}
            {callRequestMenu}



          </ul>
        }

        <Card className="jumpcard">
          <CardBody className="custom-card-body">
            <div className={settingsMoreThan4 ? "" : "row"} style={(this.props.load.isWaitlist && this.props.isCenter) ? { fontSize: 16 } : {}}>
              <SubmitStudentLog isToday={this.props.isToday} loadNum={loadNum} studentId={affJump.student._id} jumpId={jumpId} loadNum={loadNum} jumpType={"affJump"} level={this.props.affJump.studentPackageName}
                isCompleted={this.props.isCompleted} loadId={this.props.loadId} instructor1Name={instructor1Name} instructor2Name={instructor2Name} />
              <UpdateNote showUpdate={this.state.showUpdate} isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"affJump"}
                isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={affJump} />
              <CancelJump
                isToday={this.props.isToday}
                isCompleted={this.props.isCompleted}
                loadId={this.props.loadId}
                loadNum={loadNum}
                jumpId={jumpId}
                // clearItemSelection={this.props.clearItemSelection}
                // selectedItems={this.props.dragabbleItems}
                jumpType={"affJump"} />

              <div
                // className={moreThan4 ? "funjumptypemoreload" : "funjumptype col"}
                className={settingsMoreThan4 ? "funjumptypemoreload" : `${settingsFunjumpClass} col`}
                style={{ backgroundColor: '#D68910' }}>
                <AffEditableJumper
                  type={"aff"}
                  label={"I1"}
                  isToday={this.props.isToday}
                  level={affJump.level}
                  loadNum={loadNum}
                  jumpId={jumpId}
                  jumper={affJump.mainInstructor}
                  isCompleted={this.props.isCompleted}
                  loadId={this.props.loadId}
                  jump={this.props.affJump}
                  packageName={this.props.affJump.studentPackageName}
                  columnsPerGroup={columnsPerGroup}
                />
              </div>
              <div className={settingsMoreThan4 ? "" : "cardmiddle col"} style={{ margin: 'auto' }}>
                <ul className={`aff-load ${settingsShowDotMenu}`}>


                  {affJump.mainInstructor ?
                    <li style={columnsPerGroup === 1 ? { settingsStyledObject, justifyContent: "space-between", display: "flex" } : {}} >

                      <div style={columnsPerGroup === 1 ? { display: "flex" } : {}}>
                        <EditableJumper type={"aff"} label={"I1"}
                          isToday={this.props.isToday}
                          loadNum={loadNum}
                          jumpId={jumpId}
                          affJump={this.props.affJump}
                          jumper={affJump.mainInstructor}
                          student={affJump.student._id} level={affJump.level}
                          isCompleted={this.props.isCompleted}
                          loadId={this.props.loadId}
                          price={affJump.instructor1Pay}
                          columnsPerGroup={columnsPerGroup}
                          activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                          dontShowId={true}
                        />
                        {!this.props.schoolWaitlist && columnsPerGroup === 1 ? <div>{affJump.mainInstructor.memberId}</div> : null}
                      </div>

                      <div className={'justify-middle'}>
                        {!this.props.schoolWaitlist && columnsPerGroup !== 1 ? <div>{affJump.mainInstructor.memberId}</div> : null}
                        <EditablePrice jump={affJump} isToday={this.props.isToday} isCompleted={this.props.isCompleted} price={affJump.instructor1Pay} type="aff" label="I1" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={affJump.mainInstructor._id} />
                      </div>

                    </li>
                    : null
                  }


                  <li style={columnsPerGroup === 1 ? { settingsStyledObject, justifyContent: "space-between", display: "flex" } : {}}>
                    <CreatableJumper
                      isToday={this.props.isToday}
                      type={"aff"}
                      loadNum={loadNum}
                      jumpId={jumpId}
                      jumper={affJump.student}
                      level={affJump.level}
                      isCompleted={this.props.isCompleted}
                      loadId={this.props.loadId}
                      affJump={this.props.affJump}
                      activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                      price={affJump.price + affJump.videoPrice} />
                    {affJump.block === "" || affJump.block === undefined || affJump.block === null ?
                      <EditablePrice jump={affJump} isToday={this.props.isToday} isCompleted={this.props.isCompleted} price={affJump.price + affJump.videoPrice} type="aff" label="S" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={affJump.student._id} />
                      : <span>{affJump.block}</span>
                    }
                  </li>


                  {affJump.secondaryInstructor ?
                    // <ul className="aff-load">
                    <li style={columnsPerGroup === 1 ? { settingsStyledObject, justifyContent: "space-between", display: "flex" } : {}}>
                      <div style={columnsPerGroup === 1 ? { display: "flex" } : {}}>
                        <EditableJumper type={"aff"}
                          isToday={this.props.isToday}
                          label={"I2"} loadNum={loadNum}
                          jumpId={jumpId}
                          affJump={this.props.affJump}
                          jumper={affJump.secondaryInstructor}
                          student={affJump.student._id}
                          level={affJump.level}
                          isCompleted={this.props.isCompleted}
                          loadId={this.props.loadId}
                          price={affJump.instructor2Pay}
                          columnsPerGroup={columnsPerGroup}
                          activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                          dontShowId={true}
                        />
                        {!this.props.schoolWaitlist && columnsPerGroup === 1 ? <div>{affJump.secondaryInstructor.memberId}</div> : null}
                      </div>
                      <div className={'justify-middle'}>
                        {!this.props.schoolWaitlist && columnsPerGroup !== 1 ? <div>{affJump.secondaryInstructor.memberId}</div> : null}
                        <EditablePrice jump={affJump} isToday={this.props.isToday} isCompleted={this.props.isCompleted} price={affJump.instructor2Pay} type="aff" label="I2" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={affJump.secondaryInstructor._id} />
                      </div>
                    </li>
                    // </ul>
                    : null
                  }


                  {affJump.videographer ?
                    <li style={columnsPerGroup === 1 ? { settingsStyledObject, justifyContent: "space-between", display: "flex" } : {}}>
                      <div style={columnsPerGroup === 1 ? { display: "flex" } : {}}>
                        <EditableJumper
                          isToday={this.props.isToday}
                          type={"aff"}
                          label={"V"}
                          loadNum={loadNum}
                          jumpId={jumpId}
                          affJump={this.props.affJump}
                          level={affJump.level}
                          student={affJump.student._id}
                          jumper={affJump.videographer}
                          loadId={this.props.loadId}
                          isCompleted={this.props.isCompleted}
                          elementWidth={!affJump.secondaryInstructor ? { width: '170px' } : { width: '170px', position: "absolute", left: "58%", top: "-5%" }}
                          price={affJump.videoPay}
                          columnsPerGroup={columnsPerGroup}
                          activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                          dontShowId={true}
                        />
                        {!this.props.schoolWaitlist && columnsPerGroup === 1 ? <div>{affJump.videographer.memberId}</div> : null}
                      </div>
                      <div className={'justify-middle'}>
                        {!this.props.schoolWaitlist && affJump.mainInstructor && columnsPerGroup !== 1 ? <div>{affJump.mainInstructor.memberId}</div> : null}
                        <EditablePrice jump={affJump} isToday={this.props.isToday} isCompleted={this.props.isCompleted} price={affJump.videoPay} type="aff" label="V" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={affJump.videographer._id} />
                      </div>
                    </li>
                    : null
                  }
                </ul>

                <div className={settingsMoreThan4 ? "right-menu-morethan4" : `right-menu ${settingsSetPos}`}>
                  {!isCompleted && <div style={{ position: 'absolute', right: 0, top: -18 }}>
                    <input className="selectable" type="checkbox" style={{ width: 11, height: 11 }} onChange={(e) => this.handleItem(e, this.props)} />
                  </div>}
                  <span onClick={this.toggleOptions}>
                    <i className="fa fa-ellipsis-v"></i>
                  </span>
                </div>
              </div>
              {/* <Col xs="1" md="2" lg="1" className="jumpClose" style={{ paddingLeft: '4px' }}>
                <UpdateAmount isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"affJump"}
                  isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={this.props.affJump} />

                {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} jumpType={"affJump"} />}
              </Col> */}
            </div>
            {affJump.startedAt ?
              <Row>
                <Col>
                  {window.ENV?.arizona && affJump.startedAt ? <Badge style={{ fontSize: '14px' }}>{new Date(affJump.startedAt).toLocaleTimeString()}</Badge> : null}
                  {affJump.callRequest ? <Badge style={{ fontSize: '14px' }}>{affJump.callRequest}</Badge> : null}
                </Col>
              </Row> : null
            }
            {affJump.note ?
              <CardFooter className="fun-card-footer" >{affJump.note}</CardFooter> : null}
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default DragSource(Types.ITEM, itemSource, collect)(RenderAffJump)
