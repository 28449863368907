

/**
 * http://usejsdoc.org/
 */
import React, { Component, Fragment } from 'react';


import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label, Badge,
  ButtonGroup, UncontrolledTooltip
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import reportStore from '../../../stores/report-store';
import jumpersStore from '../../../stores/jumpers-store';

import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import ViewWaiver from "../../accounts/modals/viewWaiver";
import DraggableStaff from './draggableStaff';

class ViewTodayWaiver extends Component {

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpen: false,
      errors: '',
      amount: '',
      newJumperId: '',
      data: [],
      headers: [],
      selectedIds: [],
      summary: {},
      Isdata: false,
      isloading: false,
      shouldPulse: false,
      searchText: "",
      activeData: props.activeTIs,
      selected: 1,
      colors: ['#4598EA', '#93D46C', '#A46AC0 ', '#D595DC', '#9C487C', '#CD783D', "#A45C4C", '#CA782D', '#9E387B', '#CD701D', "#B28A4C", '#CA356D', '#CE741A', "#B2B24C", '#CA342A'],
    }

  }

  componentDidMount = () => {
    //this.waiverEvent.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
    this.setState({ shouldPulse: jumpersStore.shouldTodayWaiverPulse(), activeData: this.props.activeTIs });
  }

  componentWillUnmount = () => {
    var waivers = jumpersStore.getTodayWaivers();
    this.setState({ data: waivers, Isdata: false })

    //this.waiverEvent.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
  }



  toggleModal(e) {

    alert('viewTodayWaivers toggle Modal');
    var waivers = jumpersStore.getTodayWaivers();
    // reportStore.GetJumperLogs(startDate, endDate, jumperId)
    console.log("Api call", this.props.reportType)
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      data: waivers,
      Isdata: false,
      isloading: false
    })
  }

  componentWillReceiveProps(props) {
    // console.log("JumperId", this.props.jumperId);
    // const { jumperId } = this.props

    // if (jumperId) {
    //   this.setState({
    //     isloading: true,
    //     jumperId: jumperId,
    //     Isdata: false
    //   })
    // }
  }

  checkForNegatives = (price) => {

    let priceCell = (price < 0) ?
      {
        value: `($${Math.abs(price).toFixed(2)})`
      } :
      {
        value: `$${price.toFixed(2)}`
      }

    if (price < 0) {
      return <span style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
    }
    return priceCell.value;
  }


  // filterData = () => {
  //   let filterDataArray = [];
  //   let filterOutput = [];
  //   let jumperArray = [];

  //   if (this.state.Isdata === true) {
  //     this.filterDataArray = this.state.filterData;

  //     for (var item in this.filterDataArray) {
  //       filterOutput = [];
  //       var logArray = this.filterDataArray[item];
  //       for (var items in logArray) {
  //         var desc = logArray[items];
  //         let JumperInfo = [];

  //         if (desc.jumperIds !== undefined) {
  //           var otherJumper = desc.jumperIds;
  //           for (var oitem in otherJumper) {
  //             var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
  //             JumperInfo.push({ jumperName: name })
  //           }
  //         }

  //         var date = new Date(desc.createdAt);
  //         var localDate = '';
  //         var type = '';
  //         if (desc.type != undefined) {
  //           if (desc.type === "aff main") {
  //             type = desc.type + ' ' + desc.level;
  //           }
  //           else {
  //             type = desc.type;
  //           }
  //         }
  //         else {
  //           type = desc.jumpType;
  //         }

  //         if (desc.createdAt !== undefined) {
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }
  //         else {
  //           var date = new Date(desc.date);
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }

  //         filterOutput.push({ id: desc._id, type: type, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo })

  //       }
  //       if (logArray.length > 0) {
  //         jumperArray.push({ label: item, jumperStatement: filterOutput })
  //       }
  //     }
  //   }
  //   return jumperArray;
  // }

  formatDate(input) {
    if (input.toString().length == 1) {
      return '0' + input;
    }
    else {
      return input;
    }
  }
  ConvertDate(inputDate) {
    if (inputDate.toString().length > 0) {
      var d = new Date(inputDate);

      var localTime = d.toLocaleTimeString();

      // var localDate = new Date(d.toLocaleDateString()),
      //   month = '' + (localDate.getMonth() + 1),
      //   day = '' + localDate.getDate(),
      //   year = localDate.getFullYear();

      var datestring = d.toLocaleDateString();
      var dateParts = datestring.split("/");
      var dateObject = new Date(dateParts[2], dateParts[1] - 1, +dateParts[0]),
        month = '' + (dateObject.getMonth() + 1),
        day = '' + dateObject.getDate(),
        year = dateObject.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-') + ' ' + localTime;
    }
    else {
      return inputDate;
    }
  }

  onToggle = (item, val) => {
    console.log(item);
    jumpersStore.toggleWaiverReviewStatus(item.siteWaiverId)
      .then(todayWaivers => {
        this.setState({ data: todayWaivers });
      })
    console.log(val);
  }

  onSelect = (item, val) => {
    console.log(item);
    var selectedIds = this.state.selectedIds;
    var id = item.jumperId;
    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter(i => i !== id);
    }
    else {
      selectedIds.push(id);
    }
    console.log(selectedIds);
    this.setState({ selectedIds: selectedIds })

    console.log(val);
  }

  getJumperDetail(jumperId, type) {

    if (type.toLowerCase() === "tandem" || type.toLowerCase() === "ride along" || type.toLowerCase().includes("observer")) {

      if (this.state.selectedIds.length > 0) {
        var selectedIds = this.state.selectedIds.filter(id => id !== jumperId);
        var payload = { tandemIds: selectedIds };
        jumpersStore.joinTandems(jumperId, payload)
          .then(ret => {

            //alert('now geting daily activities');
            jumpersStore.getDailyTandemActivity(jumperId);
            this.setState({ selectedIds: [] });
          })
      }
      else {
        jumpersStore.getDailyTandemActivity(jumperId);
      }

    }

    else if (type.toLowerCase() === "experienced" || type.toLowerCase() === "aff") {
      jumpersStore.getDailyActivity(jumperId);
    }
  }

  handleRightClick = (jumperId, type) => {
    var selectedIds = this.state.selectedJumpers;
    selectedIds.push(jumperId);
    var data = this.state.data;
    var i = this.state.data.findIndex(r => r.jumperId === jumperId);

    alert('right click');
  }

  renderData = () => {
    let { classes } = this.props;
    console.log(this.props.data);
    var data = this.props.data;
    if (this.state.searchText.length > 0) {
      data = this.props.data.filter(dt => dt.name.toLowerCase().includes(this.state.searchText));
    }
    return data.map((item, index) => {
      var dsum = 1;

      var colorIndx = 0;
      if (item.groupId !== "0" && item.groupId !== undefined) {


        for (var i = 0; i < item.groupId.length; i++) {
          console.log(item.groupId.charCodeAt(i));
          dsum += ((item.groupId.charCodeAt(i)))
        }
        console.log(dsum);
        colorIndx = (dsum) % 15;
        if (colorIndx === 0) {
          colorIndx += 1;
        }
      }
      console.log(item.groupId);
      console.log(colorIndx);

      var backgroundColorUspa;

      var today = new Date();

      if (item.uspaExpiry) {
        var expiry = new Date(item.uspaExpiry);

        if (today < expiry) {
          backgroundColorUspa = "#008000";
        }
        else if (today >= expiry) {
          backgroundColorUspa = "#FF0000";
        }
      }


      return (
        <Fragment>
          <div key={`jumperlogsrow${index}`}>
            {

              /*
              Object.keys(item).filter(key => key !== "jumperId").map(key => {
  
                if (key.toLowerCase().includes("date")){
                  return <div className="dateCon">{(new Date(item[key])).toLocaleDateString()} {(new Date(item[key])).toLocaleTimeString()}</div>
                }
                else if(key ==="name"){
                  return <td><Button><a onClick={(e) => { e.preventDefault(); this.getJumperDetail(e, item.jumperId, item.type); }} onContextMenu={(e) => {e.preventDefault(); this.handleRightClick(item.jumperId, item.type)}}>{item[key]}</a></Button></td>
  
                }
                else if (key.toLowerCase().includes("reviewed")){
                  return   <Fragment>
                            <div className="halfCon"><input type="checkbox" id={item['siteWaiverId']} name={item['siteWaiverId']} onChange={(val) => this.onToggle(item, val)} checked={item[key]} /></div>
                            <div className="halfCon">Covid Waiver</div>
                          </Fragment>
                }
                else if(key.toLowerCase().includes("sitewaiverid") || key.toLowerCase().includes("tandemwaiverid") || key.toLowerCase().includes("covidwaiverid")) {
                  if(item[key]!==''){
                    return  <Fragment>
                              <div className="halfCon"><ViewWaiver waiverId={item[key]} /></div>
                              <div className="">Site Waiver</div>
                            </Fragment>
                  }
                  else{
                    return <Fragment>
                              <div className="halfCon">{item[key]}</div>
                              <div className="">Site Waiver</div>
                            </Fragment>
                  }
                }
  
                else{
                  return <div className="halfCon">{item[key]}</div>
                }
              })
              */
            }
            {

              <Card style={{ backgroundColor: this.state.colors[colorIndx] }} >
                <Row>
                  <Col>
                  </Col>
                  <Col>
                    {(item["type"] === "Tandem" || item["type"] === "Ride Along" || item["type"] === "Observer") && item["groupId"] === "0" ? <React.Fragment>Select <input type="checkbox" id={item['jumperId']} name={item['jumperId']} checked={this.state.selectedIds.includes(item['jumperId']) ? true : false} onChange={(val) => this.onSelect(item, val)} /> </React.Fragment> : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {(new Date(item["date"])).toLocaleDateString()} {(new Date(item["date"])).toLocaleTimeString()}
                  </Col>
                  <Col>
                    <strong>{item['type']}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button><a onClick={(e) => { e.preventDefault(); this.getJumperDetail(item.jumperId, item.type); }}>{item["name"]}</a></Button>
                  </Col>
                  {item['weight'] !== null && item['weight'] !== undefined ?
                    <Col>
                      Weight- {item['weight']}
                    </Col>
                    :
                    null
                  }
                </Row>
                <Row>
                  <Col>
                    {item["type"] === "Tandem" ? "Tandem Waiver" : null} {item['tandemWaiverId'] !== "" ? <ViewWaiver waiverId={item['tandemWaiverId']} /> : null}
                  </Col>
                  <Col>
                    Site Waiver {item['siteWaiverId'] !== "" ? <ViewWaiver waiverId={item['siteWaiverId']} /> : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Reviewed <input type="checkbox" id={item['siteWaiverId']} name={item['siteWaiverId']} onChange={(val) => this.onToggle(item, val)} checked={item["reviewed"]} />
                  </Col>
                  <Col>
                    Covid Waiver {item['covidWaiverId'] !== "" ? <ViewWaiver waiverId={item['covidWaiverId']} /> : null}
                  </Col>
                </Row>
                {
                  item.grouponCode ?

                    <Row>
                      <Col>
                        Groupon Code
                      </Col>
                      <Col>
                        {item.grouponCode}
                      </Col>
                    </Row> :
                    null
                }
                {
                  item.isUspaVerified ?
                    <Row>
                      <Col>
                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                          uspa verified
                        </span>
                      </Col>
                      <Col>
                        <span style={{ fontSize: '15px', backgroundColor: backgroundColorUspa }} className="badge">
                          exp: {(new Date(item.uspaExpiry)).toLocaleDateString()}
                        </span>
                      </Col>
                    </Row> : null
                }
                {
                  item['type'] === "Experienced" && !item.isUspaVerified ?
                    <Row>
                      <Col>
                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                          unable to verify with uspa
                        </span>
                      </Col>
                    </Row> : null
                }

                <br />
              </Card>
            }
          </div>
        </Fragment>
      )
    })
  }

  renderSummary = () => {
    return (
      <Fragment>
        <tr>
          {
            Object.keys(this.state.summary).map(key => {
              if (key.toLowerCase().includes("credit") || key.toLowerCase().includes("debit") || key.toLowerCase().includes("paid") || key.toLowerCase().includes("amount"))
                return <Fragment><td>{key}</td><td> {this.checkForNegatives(this.state.summary[key])} </td></Fragment>
              return <Fragment><td>{key}</td><td>{this.state.summary[key]}</td></Fragment>
            })
          }
        </tr>
      </Fragment>
    )
  }

  handleSearchText = (val) => {
    console.log(val);
  }
  getJumperDetail(jumperId) {
    jumpersStore.getDailyActivity(jumperId);
  }

  setData = (data, selected) => {
    console.log('in setData');
    console.log(data);
    this.setState({ activeData: data, selected: selected })
  }
  render() {

    console.log(this.props.data);
    const { Isdata, filterData, isloading } = this.state;

    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    var pulseClass = "btn customRightButton rightButton";

    if (this.state.shouldPulse) {
      pulseClass = "btn customRightButtonPulse rightButton";
    }
    else {
      pulseClass = "btn customRightButton rightButton";
    }
    var activeData = this.props.activeTIs;
    if (this.state.selected === 1) {
      activeData = this.props.activeTIs;
    }
    else if (this.state.selected === 2) {
      activeData = this.props.activeAFFIs;
    }
    else if (this.state.selected === 3) {
      activeData = this.props.activeCoaches;
    }
    else if (this.state.selected === 4) {
      activeData = this.props.activeVideographers;
    }
    else if (this.state.selected === 5) {
      activeData = this.props.activePackers;
    }
    else if (this.state.selected === 6) {
      activeData = this.props.activeLOs;
    }
    else if (this.state.selected === 7) {
      activeData = this.props.activeTeams;
    }

    return (
      <React.Fragment>
        <div className='complete-loading'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={35}
            color={'#fff'}
            loading={this.state.isloading}
          />
        </div>
        <ButtonGroup className="active-staff-btns-container" >
          <Button color="primary" onClick={() => this.setData(this.props.activeTIs, 1)} active={this.state.selected === 1}>TIs</Button>
          <Button color="primary" onClick={() => this.setData(this.props.activeAFFIs, 2)} active={this.state.selected === 2}> AFFIs</Button>
          <Button color="primary" onClick={() => this.setData(this.props.activeVideographers, 4)} active={this.state.selected === 4}>Videographer</Button>
          {window.ENV?.arizona ? null :
            <Button color="primary" onClick={() => this.setData(this.props.activeCoaches, 3)} active={this.state.selected === 3}>Coaches</Button>
          }
          <Button color="primary" onClick={() => this.setData(this.props.activePackers, 5)} active={this.state.selected === 5}>Packers</Button>

        </ButtonGroup>
        {/* <br/>
        <br/> */}
        <div className="checkin-active-staff-container" >
          {this.state.selected !== 5 && this.state.selected !== 6 && this.state.selected !== 7 && activeData.map((ti, i) => {
            return (
              <div className="staff-info-box">
                <DraggableStaff jumperId={ti.jumperId} name={ti.name} type={this.state.selected === 4 ? "video" : "tiaspi"} orderNumber={this.state.selected === 4 ? ti.checkedInOVOrderNumber : ti.checkedInOrderNumber} />
                {/* <br/> */}
                <Row style={{ width: "100%" }} >
                  {/* <Col md={3} ><Row><Col md={4}>TI:<strong>{ti.tandemJumps.count}</strong></Col></Row></Col> */}
                  <Col md={3} ><span >TOT&nbsp;</span><span><strong>{ti["tandem jumps"].count + ti.affiJumps + ti["video jumps"].count + ti["coach jumps"].count}-{ti.tiManifestedCount + ti.affiManifestedCount + ti.OVManifestedCount + ti.coachManifestedCount}</strong></span></Col>
                  <Col md={3} ><span >TI&nbsp;</span><span><strong>{ti["tandem jumps"].count}-{ti.tiManifestedCount}</strong></span></Col>
                  <Col md={3}><span>AFFI&nbsp;</span><span><strong>{ti.affiJumps}-{ti.affiManifestedCount}</strong></span></Col>
                  {window.ENV?.arizona ? null :
                    <Col md={3}><span>CH&nbsp;</span><span><strong>{ti['coach jumps'].count}-{ti.coachManifestedCount}</strong></span></Col>
                  }
                  <Col md={3}><span>V&nbsp;</span><span><strong>{ti["video jumps"].count}-{ti.OVManifestedCount}</strong></span></Col>
                  <Col md={3}><span>OW&nbsp;</span><span><strong>{ti["overweight pay"].count}</strong></span></Col>
                  {window.ENV?.dontshowwristcam ? null :
                    <Col md={3}><span>WC&nbsp;</span><span><strong>{ti["wrist cam"].count}-{ti.wristcamManifestedCount}</strong></span></Col>
                  }
                </Row>

                {/* <Row > */}
                {/* <Col><Row><Col md={4}>OW:</Col><Col><strong>{ti.overweightPay.count}</strong></Col></Row></Col> */}
                {/* <Col md={3}></Col> */}
                {/* <Col md={3}></Col> */}
                {/* <Col md={3}></Col> */}

                {/* </Row> */}
                {/* <div className="flex-this"> */}
                {ti.jumpedStatus.length > 0 ? [ti.jumpedStatus[ti.jumpedStatus.length - 1]].map((status, index) => {
                  var str = "last jump: " + status.type + " " + " on load " + status.load + " " + status.planeLoad;
                  let lastJumpTargetId = "last-jump-" + i + '-' + index
                  return (
                    <React.Fragment>
                      {/* <h4> */}
                      <Badge
                        style={{ margin: '0 5px', fontSize: 15 }}
                        id={lastJumpTargetId}
                        color="info">
                        {/* {str} */}
                        last jump
                      </Badge>
                      {/* </h4> */}

                      <UncontrolledTooltip placement="top" target={lastJumpTargetId}>
                        {str}
                      </UncontrolledTooltip>
                    </React.Fragment>
                  );
                }) : null}
                {ti.manifestStatus.map((status, idx) => {
                  let manifestTargetId = 'manifest-' + i + '-' + idx;

                  var str = "manifested: " + status.type + " " + " on load " + status.load + " " + status.planeLoad;

                  if (status.load === 0) {
                    str = "manifested: " + status.type + " on school waitlist";
                  }
                  return (
                    <React.Fragment>
                      {/* <h4> */}
                      <Badge
                        style={{ margin: '0 5px', fontSize: 15 }}
                        id={manifestTargetId}
                        color="info">
                        {"Manifest"}
                      </Badge>
                      {/* </h4> */}
                      <UncontrolledTooltip placement="top" target={manifestTargetId}>
                        {str}
                      </UncontrolledTooltip>
                    </React.Fragment>
                  );
                })}
                {/* </div> */}
                {/* <br/> */}
              </div>)
          })}
          {this.state.selected === 5 && activeData.map(ti => {
            return (
              <div style={{ fontSize: 17, fontWeight: 'strong' }}>
                <DraggableStaff jumperId={ti.jumperId} name={ti.name} />
                <br />
                <Row >
                  <Col><Row><Col md={6}>TAND:</Col><Col><strong>{ti["tandem jumps"].count}</strong></Col></Row></Col>
                  <Col><Row><Col md={6}>STUD:</Col><Col><strong>{ti.affiJumps}</strong></Col></Row></Col>
                  <Col><Row><Col md={6}>RENT:</Col><Col><strong>{ti["coach jumps"].count}</strong></Col></Row></Col>
                </Row>
                <br />
              </div>)
          })}

          {this.state.selected === 6 && activeData.map(ti => {
            return (
              <div className="staff-info-box">
                <DraggableStaff jumperId={ti.jumperId} name={ti.name} />
                <Row><Col md={6}>JUMPS:</Col><Col><strong>{ti.numFunJumps}</strong></Col></Row>
                <br />
              </div>)
          })}
          {this.state.selected === 7 && activeData.map(ti => {
            return (
              <div className="staff-info-box">
                <DraggableStaff jumperId={ti.jumperId} name={ti.name} />
                <Row><Col md={6}>JUMPS:</Col><Col><strong>{ti.numFunJumps}</strong></Col></Row>
                <br />
              </div>)
          })}
          {/* <br />
          <br /> */}
        </div>

      </React.Fragment>
    );
  }
}

export default ViewTodayWaiver
