import React, { Fragment, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, TableHead } from '@material-ui/core';
import reportStore from '../../../../stores/report-store';
import { styles } from './DataTableStyle';
import JumperStore from '../../../../stores/jumpers-store';
import * as constants from '../../../constant/reports';
// import { ClipLoader } from 'react-spinners';
import moment from 'moment';

import DonutLoader from '../../../loadManager/DonutLoader';

let heads = []
class StaffSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterData: null,
            Isdata: false,
            isloading: false,
            totalEarned: "",
            totalSpent: "",
            totalPayout: "",
            totalCollection: "",
        }
        this.handleNewActivity = this.handleNewActivity.bind(this);
        this.loadEvents = [
            { name: reportStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
        ];
    }

    componentWillReceiveProps(nextprops) {
        const { range } = this.props;
        var startDate = '';
        var endDate = '';
        // if (range.dateRange.length > 0) {
        if (range.dateRange.length > 0 && nextprops.isModal === false && nextprops.submit === true) {
            const index = range.dateRange.findIndex(range => range.modalType === constants.STAFF_SUMMARY);
            if (index > -1) {
                this.setState({ isloading: true })

                let sDate = new Date(range.dateRange[index].startDate);
                sDate = sDate.setHours(0, 0, 0, 0);
                startDate = new Date(sDate);
                startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                let eDate = new Date(range.dateRange[index].endDate);
                eDate = eDate.setHours(23, 59, 59, 999);
                endDate = new Date(eDate);
                endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                var that = this;
                var data = [];
                Promise.all([reportStore.GetStaffSummary(startDate, endDate)])
                    .then(function (result) {
                        data = result[0];
                        that.setState({
                            totalEarned: data.totalEarned,
                            totalSpent: data.totalSpent,
                            totalPayout: data.totalPayout,
                            totalCollection: data.totalCollection
                        })
                        var fDtata = data
                        localStorage.setItem("StaffData", JSON.stringify(data));
                        delete fDtata.totalEarned;
                        delete fDtata.totalSpent;
                        delete fDtata.totalPayout;
                        delete fDtata.totalCollection;
                        if (!that.state.filterData || (fDtata?.headers?.length !== that.props?.heads?.length)) {
                            that.props.setHeaders(fDtata.headers);
                        }
                        heads = fDtata.headers
                        that.setState({ filterData: fDtata, Isdata: true, });
                        that.forceUpdate();
                    })
            }
        }
        else {
            var data = localStorage.getItem("StaffData");
            if (data !== null) {
                this.setState({ filterData: JSON.parse(data), Isdata: true });
            }
        }
    }

    componentWillUnmount = () => {
        this.loadEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));

    }

    handleNewActivity = (activityString) => {
        this.setState({
            isloading: false,
        })
    }

    componentWillMount() {
        this.loadEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func))
        // let { dateParam } = this.props;
        // var startDate = '';
        // var endDate = '';
        // if (dateParam.length > 0) {
        //     const index = dateParam
        //         .findIndex(range => range.modalType === constants.STAFF_SUMMARY);
        //     if (index > -1) {

        //         var data = localStorage.getItem("StaffData");
        //         if (data !== null) {
        //            this.setState({
        //             totalEarned: JSON.parse(data).totalEarned,
        //             totalSpent: JSON.parse(data).totalSpent,
        //             totalPayout: JSON.parse(data).totalPayout,
        //             totalCollection: JSON.parse(data).totalCollection,
        //             })
        //             let newData = JSON.parse(data)
        //             delete newData.totalEarned
        //             delete newData.totalSpent
        //             delete newData.totalPayout
        //             delete newData.totalCollection
        //             heads = newData.headers
        //             this.setState({ filterData: newData, Isdata: true });
        //         }
        //         //this.setState({ isloading: true })
        //         // let sDate = new Date(dateParam[index].startDate);
        //         // sDate = sDate.setHours(0, 0, 0, 0);
        //         // startDate = new Date(sDate);
        //         // startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // let eDate = new Date(dateParam[index].endDate);
        //         // eDate = eDate.setHours(23, 59, 59, 999);
        //         // endDate = new Date(eDate);
        //         // endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // var that = this;
        //         // var data = [];
        //         // Promise.all([reportStore.GetStaffSummary(startDate, endDate)])
        //         //     .then(function (result) {
        //         //         data = result[0];
        //         //         that.setState({ filterData: data, Isdata: true  })
        //         //         that.forceUpdate();

        //         //     })
        //     }
        // }
    }

    filterOnRange = () => {
        let data = [];

        if (this.state.Isdata === true) {

            data = this.state.filterData;
            console.log(data);
            let filterArray = [];
            let newData = {}
            delete data.headers

            for (var item in data) {

                //let jumperInfo = JumperStore.getJumperById(item);

                const desc = Object.getOwnPropertyDescriptor(data, item);
                console.log(desc);
                //var name = jumperInfo !== undefined ? jumperInfo?.firstname + ' ' + jumperInfo?.lastname : '';


                filterArray.push({...desc.value })
                // filterArray.push({
                //     member: name, tandemCount: desc.value.tandemCount, earned: desc.value.earned, affMainCount: desc.value.affMainCount, affSecondaryCount: desc.value.affSecondaryCount,
                //     funJumpCount: desc.value.funJumpCount, hopnpopCount: desc.value.hopnpopCount, spent: desc.value.spent, tandemVideoCount: desc.value.tandemVideoCount, affVideoCount: desc.value.affVideoCount, affMainCount: desc.value.affMainCount,
                //     coachCount: desc.value.coachCount, gearCount: desc.value.gearCount, funJumpVal: desc.value.funJumpVal, hopnpopVal: desc.value.hopnpopVal,
                //     affInstructionPay: desc.value.affInstructionPay,
                //     riggerPay: desc.value.riggerPay,
                //     tandemPackingPay: desc.value.tandemPackingPay,
                //     studentPackingPay: desc.value.studentPackingPay,

                //     tandemPay: desc.value.tandemPay,
                //     pilotPay: desc.value.pilotPay,
                //     payout: desc.value.payout,
                //     tandemVideoPay: desc.value.tandemVideoPay,
                //     coachPay: desc.value.coachPay,
                //     affVideoPay: desc.value.affVideoPay,
                //     affMainPay: desc.value.affMainPay,
                //     affSecondaryPay: desc.value.affSecondaryPay,
                //     overweightPay: desc.value.overweightPay,
                //     wristcamPay: desc.value.wristcamPay
                // });

            }
            return filterArray;
        }
    }

    renderReportHeader = () => {
        const { range, classes } = this.props;
        const index = range.dateRange.findIndex(range => range.modalType === constants.STAFF_SUMMARY);
        if (index != -1)
            return (
                <TableHead key={`statementHeader`}>
                    <TableRow key={'summaryHeader'}>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="5"
                            component="th"
                            className={classes.subHeadings}>
                        </TableCell>
                        <TableCell
                            style={{ textAlign: 'center' }}
                            key={`summaryCell1`}
                            colSpan="5"
                            component="th"
                            className={classes.subHeadings}>
                            <h1>Staff Summary</h1>
                        </TableCell>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="5"
                            component="th"
                            className={classes.subHeadings}>
                            <b>({moment(range.dateRange[index].startDate).format('L')} - {moment(range.dateRange[index].endDate).format('L')})</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
            )
        else
            return null
    }

    filterPdfData = () => {
        const { classes } = this.props;

        let data = [];
        let jumperArray = [];
        let mainArray = [];

        if (this.state.Isdata === true) {
            data = this.state.filterData;
            for (var item in data) {

                let jumperInfo = JumperStore.getJumperById(item);

                const desc = Object.getOwnPropertyDescriptor(data, item);
                if (jumperInfo) {

                    jumperArray.push({ member: jumperInfo.firstname + ' ' + jumperInfo.lastname, ...desc.value })
                    //   jumperArray.push({
                    //       member: jumperInfo.firstname + ' ' + jumperInfo.lastname, tandemCount: desc.value.tandemCount, earned: desc.value.earned, affMainCount: desc.value.affMainCount, affSecondaryCount: desc.value.affSecondaryCount,
                    //       funJumpCount: desc.value.funJumpCount, hopnpopCount: desc.value.hopnpopCount, spent: desc.value.spent, tandemVideoCount: desc.value.tandemVideoCount, affVideoCount: desc.value.affVideoCount, affMainCount: desc.value.affMainCount,
                    //       coachCount: desc.value.coachCount, gearCount: desc.value.gearCount, affInstructionPay: desc.value.affInstructionPay, riggerPay: desc.value.riggerPay,
                    //       tandemPackingPay: desc.value.tandemPackingPay,
                    //       funJumpVal: desc.value.funJumpVal, hopnpopVal: desc.value.hopnpopVal,
                    //       studentPackingPay: desc.value.studentPackingPay,
                    //       tandemPay: desc.value.tandemPay,
                    //       pilotPay: desc.value.pilotPay,
                    //       payout: desc.value.payout,
                    //       tandemVideoPay: desc.value.tandemVideoPay,
                    //       coachPay: desc.value.coachPay,
                    //       affVideoPay: desc.value.affVideoPay,
                    //       affMainPay: desc.value.affMainPay,
                    //       affSecondaryPay: desc.value.affSecondaryPay,
                    //       overweightPay: desc.value.overweightPay,
                    //       wristcamPay: desc.value.wristcamPay
                    //   });
                }

            }
            // console.log(filterArray);
            let rows = [];
            var rowlength = 1;
            var childrow = [];
            var subHeadings = ['Member', 'Pilot Pay', 'Tandem', 'Tandem Video', 'Overweight', 'Wristcam', 'Aff Main', 'Aff Secondary', 'Aff Video', 'Fun Jump', 'Hopnpop Jump', 'Coach Count', 'AFF Instruction', 'Tandem Packing', 'Student Packing', 'Rigger', 'Spent', 'Earned', 'Total Payout'];

            rows.push(
                this.renderReportHeader()
            )

            rows.push(
                <TableRow key={'psumamryTableHeadings'}>
                    <TableCell style={{ width: "100px" }} component="th" key={'psumamryTablecell1'} className={classes.staffpdfsubHeadings}>Member </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell26'} className={classes.staffpdfsubHeadings}>Pilot Pay</TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell2'} className={classes.staffpdfsubHeadings}>Tandem </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell3'} className={classes.staffpdfsubHeadings}>Tandem Video </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell41'} className={classes.staffpdfsubHeadings}>Over Weight </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell24'} className={classes.staffpdfsubHeadings}>Wristcam </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell4'} className={classes.staffpdfsubHeadings}>Aff Main </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell5'} className={classes.staffpdfsubHeadings}>Aff Secondary </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell6'} className={classes.staffpdfsubHeadings}>Aff Video </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell9'} className={classes.staffpdfsubHeadings}>Coach Count </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell10'} className={classes.staffpdfsubHeadings}>AFF Instruction </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell11'} className={classes.staffpdfsubHeadings}>Tandem Packing </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell12'} className={classes.staffpdfsubHeadings}>Student Packing </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell13'} className={classes.staffpdfsubHeadings}>Rigger </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell7'} className={classes.staffpdfsubHeadings}>Fun Jump </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell8'} className={classes.staffpdfsubHeadings}>Hopnpop </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell14'} className={classes.staffpdfsubHeadings}>Spent </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell15'} className={classes.staffpdfsubHeadings}>Earned </TableCell>
                    <TableCell style={{ width: "64px", padding: "0.5px" }} component="th" key={'psumamryTablecell27'} className={classes.staffpdfsubHeadings}>Payout </TableCell>
                </TableRow>
            )
            for (var index = 0; index < jumperArray.length; index++) {
                rowlength++;
                childrow = [];
                rows.push(
                    <TableRow key={`pstaffrow${jumperArray[index].member}`}>
                        <TableCell
                            style={{ width: "100px" }}
                            key={`pstaffcell1${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].member}
                        </TableCell>
                        <TableCell
                            style={{ width: "100px" }}
                            key={`pstaffcell1${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].pilotPay ? `$${Math.abs(jumperArray[index].pilotPay).toFixed(2)}` : `$${0}`}
                        </TableCell>
                        <TableCell
                            style={{ width: "100px" }}
                            key={`pstaffcell1${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].member}
                        </TableCell>
                        {/* <TableCell
                            key={`pstaffcell2${index}`}
                            className={classes.stafftableCell}>*/}

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].tandemPay ? `$${Math.abs(jumperArray[index].tandemPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        {/* <TableCell
                            key={`pstaffcell3${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].tandemVideoCount ? jumperArray[index].tandemVideoCount : 0}
                        </TableCell> */}
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].tandemVideoPay ? `$${Math.abs(jumperArray[index].tandemVideoPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].overweightPay ? `$${Math.abs(jumperArray[index].overweightPay).toFixed(2)}` : `$${0}`}
                        </TableCell>
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].wristcamPay ? `$${Math.abs(jumperArray[index].wristcamPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        {/* <TableCell
                            key={`pstaffcell4${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].affMainCount ? jumperArray[index].affMainCount : 0}
                        </TableCell> */}
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].affMainPay ? `$${Math.abs(jumperArray[index].affMainPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        {/* <TableCell
                            key={`pstaffcell5${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].affSecondaryCount ? jumperArray[index].affSecondaryCount : 0}
                        </TableCell> */}
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].affSecondaryPay ? `$${Math.abs(jumperArray[index].affSecondaryPay).toFixed(2)}` : `$${0}`}
                        </TableCell>
                        {/* <TableCell
                            key={`pstaffcell6${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].affVideoCount ? jumperArray[index].affVideoCount : 0}
                        </TableCell> */}
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].affVideoPay ? `$${Math.abs(jumperArray[index].affVideoPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        {/* <TableCell
                            key={`pstaffcell9${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].coachCount ? jumperArray[index].coachCount : 0}
                        </TableCell> */}

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].coachPay ? `$${Math.abs(jumperArray[index].coachPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell10${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].affInstructionPay ? `$${Math.abs(jumperArray[index].affInstructionPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell11${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].tandemPackingPay ? `$${Math.abs(jumperArray[index].tandemPackingPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell12${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].studentPackingPay ? `$${Math.abs(jumperArray[index].studentPackingPay).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell13${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].riggerPay ? `$${Math.abs(jumperArray[index].riggerPay).toFixed(2)}` : `$${0}`}
                        </TableCell>
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell7${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].funJumpVal ? `$${Math.abs(jumperArray[index].funJumpVal).toFixed(2)}` : `$${0}`}
                        </TableCell>

                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell8${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].hopnpopVal ? `$${Math.abs(jumperArray[index].hopnpopVal).toFixed(2)}` : `$${0}`}
                        </TableCell>
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell14${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].incurred ? `$${Math.abs(jumperArray[index].incurred).toFixed(2)}` : `$${0}`}
                        </TableCell>
                        <TableCell
                            style={{ width: "64px", padding: "0.5px" }}
                            key={`pstaffcell15${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].earned ? `$${Math.abs(jumperArray[index].earned).toFixed(2)}` : `$${0}`}
                        </TableCell>
                        <TableCell
                            style={{ width: "100px" }}
                            key={`pstaffcell1${index}`}
                            className={classes.stafftableCell}>
                            {jumperArray[index].totalPayout ? `$${Math.abs(jumperArray[index].totalPayout).toFixed(2)}` : `$${0}`}
                        </TableCell>
                    </TableRow>
                )

                if (rowlength == 13) {
                    mainArray.push(
                        <div className="jumperSummary" key={`jumperSummary${index}`} style={{ width: '1470px', marginLeft: '0px' }}>
                            <div key={`innerjumperSummary${index}`} style={{ height: '30px' }}></div>
                            {rows}
                        </div>);
                    rowlength = 1;
                    childrow = [];
                    rows = [];
                }
                else if (index == jumperArray.length - 1) {
                    mainArray.push(
                        <div className="jumperSummary" key={`jumperSummary${index}`} style={{ width: '1470px', marginLeft: '0px' }}>
                            <div key={`innerjumperSummary${index}`} style={{ height: '30px' }}></div>
                            {rows}
                            <TableRow>
                                <TableCell className={classes.stafftableCell} colSpan={jumperArray.length} key="pstaffcell12_total">
                                    Total
                                </TableCell>
                                <TableCell style={{ width: "64px", padding: "0.5px" }} className={classes.stafftableCell} key="pstaffcell12_Spand">
                                    ${Math.abs(this.state.totalSpent).toFixed(2)}
                                </TableCell>
                                <TableCell style={{ width: "64px", padding: "0.5px" }} className={classes.stafftableCell} key="pstaffcell12_totalEarned">
                                    ${Math.abs(this.state.totalEarned).toFixed(2)}
                                </TableCell>
                                <TableCell style={{ width: "64px", padding: "0.5px" }} className={classes.stafftableCell} key="pstaffcell12_totalEarned">
                                    ${Math.abs(this.state.totalPayout).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        </div>);
                    rowlength = 1;
                    childrow = [];
                    rows = [];
                }
            }
            rows.push(

            )
            mainArray.push(
                <div className="jumperSummary" key={`jumperSummary_total`} style={{ width: '1470px', marginLeft: '0px' }}>
                    <div key={`innerjumperSummary_total`} style={{ height: '30px' }}></div>
                    {rows}
                    <TableRow>
                        <TableCell className={classes.stafftableCell} colSpan={16} key="pstaffcell12_total">
                            Total
                        </TableCell>
                        <TableCell className={classes.stafftableCell} key="pstaffcell12_Spand">
                            ${Math.abs(this.state.totalSpent).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.stafftableCell} key="pstaffcell12_totalEarned">
                            ${Math.abs(this.state.totalEarned).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.stafftableCell} key="pstaffcell12_totalEarned">
                            ${Math.abs(this.state.totalPayout).toFixed(2)}
                        </TableCell>
                    </TableRow>
                </div>);
        }

        return mainArray;
    }

    dateConverter = (date) => {
        let dateInMilliseconds = new Date(date).getTime();
        return dateInMilliseconds;
    }
    checkForNegatives = (price, key) => {
        if (price === null || price === undefined) {
            return key?.substring(key?.length - 5) === 'Count' ? 0 : `$${0}`
        }
        else {
            let priceCell = typeof (price) === 'string' ?
                {
                    value: price
                }
                :
                {
                    value: key?.substring(key?.length - 5) === 'Count' ? price : `$${Math.abs(price).toFixed(2)}`
                }

            if (price < 0) {
                return <span style={{ color: 'red' }}> {`(${priceCell.value})`}</span>;
            }
            return priceCell.value;
        }

    }
    getTableCell = (heads, row, classes, index) => {
        const newHeads = ['member', ...heads, 'incurred', 'collection', 'earned', 'payout', 'Transfer In', 'Transfer Out']
        return newHeads?.map((key, ind) => {
            let cellValue = key?.substring(key?.length - 5) === 'Count' ? 0 : `$${0}`;
            if (key in row) {
                cellValue = row[key]
            }
            return <TableCell
                key={`staffcell${index}${ind}`}
                className={classes.stafftableCell}>
                {this.checkForNegatives(cellValue, key)}
            </TableCell>
        })

    }
    render() {
        const { classes, data, submit, range } = this.props;
        const override = `
            display: block;
            margin: 0 auto;
            border-color: red;
        `;
        if (this.state.Isdata === true) {
            return (
                <Fragment>
                    {/* {submit && data && range.length > 0 && this.filterOnRange(range[0], data).map(row => { */}
                    <div className='report-loading inreports'>
                        {/* <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            loading={this.state.isloading}
                        /> */}
                        <DonutLoader isLoading={this.state.isloading} />
                    </div>
                    {submit && this.filterOnRange().map((row, index) => {
                        let style = (row.total < 0) ?
                            `${classes.tableCell} ${classes.tableCellNegative}`
                            :
                            classes.tableCell;
                        // let total = (row.total < 0) ?
                        //     `- $${Math.abs(row.total.toFixed(2))}` :
                        //     ` $${row.total.toFixed(2)}`;
                        return (
                            <Fragment key={`Fragment${index}`}>
                                <TableRow key={`staffrow${row.member}`}>
                                    {/* <TableCell
                                        key={`staffcell1${index}`}
                                        className={classes.stafftableCell}>
                                        {row.member}
                                    </TableCell> */}

                                    {this.getTableCell(heads, row, classes, index)}


                                    {/* <TableCell
                                        key={`staffcell1${index}`}
                                        className={classes.stafftableCell}>
                                        {row.pilotPay ? `$${Math.abs(row.pilotPay).toFixed(2)}` : `$${0}`}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell2${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.tandemPay ? Math.abs(row.tandemPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell3${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.tandemVideoPay ? Math.abs(row.tandemVideoPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                    	key={`staffcell3${index}`}
                                    	className={classes.stafftableCell}>
                                    	${row.overweightPay ? Math.abs(row.overweightPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                    	key={`staffcell3${index}`}
                                    	className={classes.stafftableCell}>
                                    	${row.wristcamPay ? Math.abs(row.wristcamPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell4${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.affMainPay ? Math.abs(row.affMainPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell5${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.affSecondaryPay ? Math.abs(row.affSecondaryPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell6${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.affVideoPay ? Math.abs(row.affVideoPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell8${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.coachPay ? Math.abs(row.coachPay).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell10${index}`}
                                        className={classes.stafftableCell}>
                                        {row.affInstructionPay ? `$${Math.abs(row.affInstructionPay).toFixed(2)}` : `$${0}`}
                                    </TableCell>

                                    <TableCell
                                        key={`staffcell11${index}`}
                                        className={classes.stafftableCell}>
                                        {row.tandemPackingPay ? `$${Math.abs(row.tandemPackingPay).toFixed(2)}` : `$${0}`}
                                    </TableCell>

                                    <TableCell
                                        key={`staffcell12${index}`}
                                        className={classes.stafftableCell}>
                                        {row.studentPackingPay ? `$${Math.abs(row.studentPackingPay).toFixed(2)}` : `$${0}`}
                                    </TableCell>

                                    <TableCell
                                        key={`staffcell13${index}`}
                                        className={classes.stafftableCell}>
                                        {row.riggerPay ? `$${Math.abs(row.riggerPay).toFixed(2)}` : `$${0}`}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell7${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.funJumpVal ? Math.abs(row.funJumpVal).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell8${index}`}
                                        className={classes.stafftableCell}>
                                        ${row.hopnpopVal ? Math.abs(row.hopnpopVal).toFixed(2) : 0}
                                    </TableCell> */}
                                    {/* <TableCell
                                        key={`staffcell14${index}`}
                                        className={classes.stafftableCell}>
                                        {row.spent ? `$${Math.abs(row.spent).toFixed(2)}` : `$${0}`}
                                    </TableCell>

                                    <TableCell
                                        key={`staffcell15${index}`}
                                        className={classes.stafftableCell}>
                                        {row.earned ? `$${Math.abs(row.earned).toFixed(2)}` : `$${0}`}
                                    </TableCell>
                                    <TableCell
                                        key={`staffcell15${index}`}
                                        className={classes.stafftableCell}>
                                        {row.payout ? `$${Math.abs(row.payout).toFixed(2)}` : `$${0}`}
                                    </TableCell> */}
                                </TableRow>
                            </Fragment>
                        )
                    })
                    }
                    <TableRow>
                        <TableCell colSpan={heads?.length + 1} className={classes.stafftableCell} key="pstaffcell12_total">
                            Total
                        </TableCell>
                        <TableCell className={classes.stafftableCell} key="pstaffcell12_Spand">
                            {/* ${Math.abs(this.state.totalSpent).toFixed(2)} */}
                            {this.checkForNegatives(this.state.totalSpent)}
                        </TableCell>
                        <TableCell className={classes.stafftableCell} key="pstaffcell12_totalEarned">
                            {/* ${Math.abs(this.state.totalEarned).toFixed(2)} */}
                            {this.checkForNegatives(this.state.totalCollection)}
                        </TableCell>
                        <TableCell className={classes.stafftableCell} key="pstaffcell12_totalEarned">
                            {/* ${Math.abs(this.state.totalEarned).toFixed(2)} */}
                            {this.checkForNegatives(this.state.totalEarned)}
                        </TableCell>
                        <TableCell className={classes.stafftableCell} key="pstaffcell12_totalEarned">
                            {/* ${Math.abs(this.state.totalPayout).toFixed(2)} */}
                            {this.checkForNegatives(this.state.totalPayout)}
                        </TableCell>
                    </TableRow>
                    <div id="summaryPanel" style={{ display: 'none' }}   >
                        {this.filterPdfData()}
                    </div>
                </Fragment>
            )
        }
        else {
            return (
                <div className='report-loading inreports'>
                    {/* <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={'#123abc'}
                        loading={this.state.isloading}
                    /> */}
                    <DonutLoader isLoading={this.state.isloading} />
                </div>
            )
        }
    }
}

export default withStyles(styles)(StaffSummary);
