import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import CancelJump from './modals/cancelJump'
import EditableJumper from './modals/editableJumper'
import EditableFunJumper from './modals/editableFunJumper'
import { Card, CardBody, Row, Col, CardFooter } from 'reactstrap'
import UpdateAmount from './modals/updateAmount';
import { Badge } from 'reactstrap';
import ReactDOM from 'react-dom';
import EditablePrice from './modals/editablePrice'
import loadsStore from '../../stores/loads-store'
import itemStore from '../../stores/item-store';
import { returnClasses } from './helper/schoolwaitlist';
import returnLoadSettings from './helper/returnLoadSettings'
import CoachNestedMenu from './subComponents/NestedMenus'
import multiselect from './helper/multiselectJumps'
import multiCopyPasteClick from './helper/multiCopyPaste'
import UpdateNote from './modals/updateNote';

// var listStyle = { display: 'inline-block', marginRight: '5px', backgroundColor: '#a0c2df', fontSize: '12px', padding: '3px', borderRadius: '5px', marginBottom: '1px' }

var listStyle = {};

const Types = {
  ITEM: 'JUMP'
};

const itemSource = {
  beginDrag(props) {
    //console.log('dragging');
    let dragabbleItems = loadsStore.getDraggable()
    if (dragabbleItems?.length > 0) {
      return { items: dragabbleItems }
    } else {
      return props;
    }
  },
  endDrag(props, monitor, component) {
    // if (!monitor.didDrop()) {
    //   props.clearItemSelection()
    //   return;
    // }
    // return props;
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      // props.clearItemSelection();
      loadsStore.clearDraggable()
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}



class RenderCoachJump extends Component {

  constructor() {
    super();
    this.state = {
      optionMenu: false,
      optionSub: false,
      copyOptionSub: false,
      cutOptionSub: false
    }
  }

  checkForNegatives = (price) => {
    //console.log('check for negatives: ' + price);
    if (price != undefined) {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <Badge style={{ color: 'red' }}> {priceCell.value}</Badge>;
      }
      return <Badge>{priceCell.value}</Badge>;
    }
    else {
      return '';
    }
  }

  toggleOptions = () => {
    this.setState({
      optionMenu: !this.state.optionMenu
    })
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    let id;

    if (document.querySelector('#copy-coach-menu')) {
      id = document.querySelector('#copy-coach-menu')
    } else {
      id = document.querySelector('#move-coach-menu')
    }
    if (!domNode || !domNode.contains(event.target)) {
      if (this.state.optionMenu) {
        if (!id) {
          this.setState({
            optionMenu: false
          });
        }
      }
    }
  }

  handleUpdate = (jumpid) => {
    // this.setState({
    //   showUpdate:true
    // },()=>{
    //   alert(this.state.showUpdate);
    // })
    document.getElementById(jumpid).click();
  }

  handleDelete = (jumpId) => {
    document.getElementById(jumpId).click();
  }
  // coachJump, loadNum, -1000
  handleCopyClick = (coach = this.props.coachJump, loadNum = this.props.loadNum, uid = -1000) => {
    this.setState({
      copyOptionSub: true
    })

    let dragItem = loadsStore.getDraggable()
    if (dragItem.length > 0) {
      multiselect(dragItem)
    } else {
      var payload = {};
      payload = coach
      payload.coachId = coach.coach["_id"]
      payload.jumperId = coach.jumper["_id"]
      payload.note = coach.note;
      loadsStore.copied = true;
      loadsStore.copyValue = payload;
      loadsStore.cut = false;
    }

    // this.props.toggleToster(true, "copied")
    // var element = document.getElementById(uid);
    // element.style.display = "none";
    if (uid !== -1000) {
      var element = document.getElementById(uid);
      element.style.display = "none";
    }

  }
  handleCutClick = (coach = this.props.coachJump, loadNum = this.props.loadNum, jumpId = this.props.jumpId) => {
    this.setState({
      cutOptionSub: true
    })

    let dragItem = loadsStore.getDraggable()
    if (dragItem.length > 0) {
      multiselect(dragItem, loadNum)
    } else {
      var payload = {};
      payload = coach
      payload.coachId = coach.coach["_id"]
      payload.jumperId = coach.jumper["_id"];
      payload.note = coach.note;

      loadsStore.cut = true;
      loadsStore.cutValue = { loadNum, jumpId }
      loadsStore.copyValue = payload;
    }
  }

  handleCopyPasteClick = (loadNum) => {
    this.setState({
      optionMenu: !this.state.optionMenu,
      optionSub: false,
      copyOptionSub: false,
      cutOptionSub: false
    })
    var Value = loadsStore.copyValue;
    if (Value?.length && Value.length > 0) {
      multiCopyPasteClick(Value, loadNum)
    } else {
      loadsStore.createCoachJump(loadNum, Value).then(d => {
        console.log("Coach in pasted", d);
        if (d) {
          if (loadsStore.cut) {
            loadsStore.cut = false;
            const { loadNum, jumpId } = loadsStore.cutValue;
            loadsStore.cancelCoachJump(loadNum, jumpId);
          }
        }
      })
    }
  };

  updateCallRequest = (loadNum, jumpId, val) => {
    var jumpPostBody = { callRequest: val };
    let data = loadsStore.getDraggable()
    let index = data.findIndex(i => i.jumpId === jumpId)

    if (index === -1) {
      loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody)
        .then(ret => {
          this.setState({
            optionMenu: false
          });
        })
    } else {
      data.map((i, ind) => {
        let type = ''
        if (i.jumpType === 'coachJump') {
          type = 'coachJumps'
        } else if (i.jumpType === 'tandemJump') {
          type = 'tandemJumps'
        } else if (i.jumpType === 'affJump') {
          type = 'affJumps'
        }
        loadsStore.updateJumps(loadNum, type, i.jumpId, jumpPostBody)
          .then(ret => {
            this.setState({
              optionMenu: false
            });
            if (ind === data.length - 1) {
              // this.props.clearItemSelection()
              loadsStore.clearDraggable()
            }
          })
      })
    }
  }

  handleItem = (e, jump) => {
    // this.props.setDragabble(jump)
    loadsStore.setDragabble(jump)
  }

  // isChecked = (item) => {
  //   let index = this.props?.dragabbleItems?.findIndex(i => i.jumpId === item.jumpId)
  //   if(index === -1){
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  render() {
    console.log('in render coach jump');
    const { coachJump, isCompleted, loadNum, jumpId, coachOptions, isDragging, connectDragSource, src, jumperOptions, price, isRightMenu, loadsToShow, fontSize, columnsPerGroup, isInline } = this.props;
    var callRequestMenu = null;
    if (this.props.schoolWaitlist) {
      var activeLoads = loadsStore.getActiveLoadsForDisplay();
      console.log(activeLoads);
      var callRequestMenuString = ['any 20', 'any 30', 'any 40'];
      activeLoads.forEach(ld => {
        callRequestMenuString.push('next available after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('20 after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('30 after ' + ld.plane + "-" + ld.planeLoadNum);
        callRequestMenuString.push('40 after ' + ld.plane + "-" + ld.planeLoadNum);
      })
      var callRequestMenu = callRequestMenuString.map(str => {
        return (
          <li onClick={() => this.updateCallRequest(loadNum, jumpId, str)}>
            <span>{str}</span>
          </li>
        )
      })
    }
    // const moreThan4 = loadsToShow > 3 ? true : false
    const { settingsMoreThan4, settingsFunjumpClass, settingsShowDotMenu, settingsSetPos, settingsStyledObject } = returnLoadSettings(loadsToShow, columnsPerGroup, isInline, fontSize)
    return connectDragSource(
      <div className={returnClasses(isRightMenu)}>

        {
          this.state.optionMenu &&
          <ul className={loadsToShow > 3 ? "menu-option-moreloads" : "menu-option"}>


            <li>
              <span style={{ color: 'red' }} onClick={() => this.handleDelete("delete-jump" + jumpId)}>Delete</span>
            </li>
            {!this.props.isCompleted && <li>
              <span onClick={() => this.handleUpdate("update-note" + jumpId)}>add note</span>
            </li>}
            <CoachNestedMenu
              txt={"Copy"}
              loadNum={this.props.loadNum}
              loads={loadsStore.getActiveLoadsForDisplay()}
              handleCopyClick={this.handleCopyClick}
              handleCopyPasteClick={this.handleCopyPasteClick}
              id={"copy-coach-menu"}
            />
            <CoachNestedMenu
              txt={"Move"}
              loadNum={this.props.loadNum}
              loads={loadsStore.getActiveLoadsForDisplay()}
              handleCopyClick={this.handleCutClick}
              handleCopyPasteClick={this.handleCopyPasteClick}
              id={"move-coach-menu"}
            />
            {/* <li style={{ position: "relative" }} onMouseLeave={() => setTimeout(() => { this.setState({ copyOptionSub: false }) }, 500)}>
              <span onClick={() => this.handleCopyClick(coachJump, loadNum, -1000)} onMouseOver={() => setTimeout(() => { this.handleCopyClick(coachJump, loadNum, -1000) }, 500)} >Copy</span>
              {
                this.state.copyOptionSub &&
                <ul className="menu-option" style={{ zIndex: '100', position: 'absolute', top: '0px', right: '211px' }} onMouseLeave={() => setTimeout(() => { this.setState({ copyOptionSub: false }) }, 500)}>
                  {
                    this.props.loads && this.props.loads.map((load, i) => {
                      if (load.loadNum != loadNum) {
                        return (<li>
                          <span onClick={() => this.handleCopyPasteClick(load.loadNum)}>{"Load " + load.loadNum + " " + load.plane + " " + load.planeLoadNum}</span>
                        </li>)
                      }
                    })
                  }
                </ul>
              }
            </li>
            {!this.props.isCompleted && <li style={{ position: "relative" }} onMouseLeave={() => setTimeout(() => { this.setState({ cutOptionSub: false }) }, 500)}>
              <span style={{ color: 'red' }} onClick={() => this.handleCutClick(coachJump, loadNum, jumpId)} onMouseOver={() => setTimeout(() => { this.handleCutClick(coachJump, loadNum, jumpId) }, 500)}>Move</span>
              {
                this.state.cutOptionSub &&
                <ul className="menu-option" style={{ zIndex: '100', position: 'absolute', top: '0px', right: '211px' }} onMouseLeave={() => setTimeout(() => { this.setState({ cutOptionSub: false }) }, 500)}>
                  {
                    this.props.loads && this.props.loads.map((load, i) => {
                      if (load.loadNum != loadNum) {
                        return (<li>
                          <span onClick={() => this.handleCopyPasteClick(load.loadNum)}>{"Load " + load.loadNum + " " + load.plane + " " + load.planeLoadNum}</span>
                        </li>)
                      }
                    })
                  }
                </ul>
              }
            </li>} */}
            {
              this.props.isCompleted && this.props.coachJump.albumUrl &&
              <li onClick={() => {
                var win = window.open(this.props.coachJump.albumUrl, '_blank');
                win.focus();
              }}>
                <span >View Media</span>
              </li>
            }
            {callRequestMenu}


          </ul>
        }
        <Card className="jumpcard">
          <CardBody className="custom-card-body">
            <div className={settingsMoreThan4 ? "" : "row"} style={(this.props.load.isWaitlist && this.props.isCenter) ? { fontSize: 16 } : {}}>
              <UpdateNote showUpdate={this.state.showUpdate} isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"coachJump"}
                isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={coachJump} />
              <CancelJump
                isCompleted={this.props.isCompleted}
                loadId={this.props.loadId}
                isToday={this.props.isToday}
                loadNum={loadNum}
                jumpId={jumpId}
                // clearItemSelection={this.props.clearItemSelection}
                // selectedItems={this.props.dragabbleItems}
                jumpType={"coachJump"} />


              {/* <Col xs="12" md="12" lg="3" className="jumptype" style={{padding: '0 0 0 14px' }}> */}
              <div className={settingsMoreThan4 ? "funjumptypemoreload" : `${settingsFunjumpClass} col`} style={{ backgroundColor: '#E8EEFF' }}>
                {coachJump.isWingsuit !== undefined && coachJump.isWingsuit ? 'Wingsuit Coach' : 'Coach'}
              </div>
              <div className={settingsMoreThan4 ? "" : "cardmiddle col"} style={{ margin: 'auto' }}>
                <div>
                  <ul className={`coach-load ${settingsShowDotMenu}`}>
                    <li style={columnsPerGroup === 1 ? { settingsStyledObject, justifyContent: "space-between", display: "flex" } : {}}>
                      <EditableJumper
                        isToday={this.props.isToday}
                        type={"coach"}
                        label={"C"}
                        loadNum={loadNum}
                        loadId={this.props.loadId}
                        jumpId={jumpId}
                        coachJump={coachJump}
                        jumper={coachJump.coach}
                        isWingsuit={coachJump.isWingsuit}
                        isCompleted={this.props.isCompleted}
                        price={coachJump.coachPay}
                        activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                      />
                      <EditablePrice isCompleted={this.props.isCompleted} isToday={this.props.isToday} isWingsuit={coachJump.isWingsuit} jump={coachJump} price={coachJump.coachPay} type="coach" label="C" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={coachJump.coach._id} />

                    </li>
                    <li style={columnsPerGroup === 1 ? { settingsStyledObject, justifyContent: "space-between", display: "flex" } : {}}>
                      <EditableFunJumper
                        isToday={this.props.isToday}
                        type={"coach"}
                        label={"J"}
                        loadNum={loadNum}
                        loadId={this.props.loadId}
                        jumpId={jumpId}
                        jump={coachJump}
                        jumper={coachJump.jumper}
                        isCompleted={this.props.isCompleted}
                        isWingsuit={coachJump.isWingsuit}
                        price={coachJump.price}
                        activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                      />
                      <EditablePrice isCompleted={this.props.isCompleted} isToday={this.props.isToday} isWingsuit={coachJump.isWingsuit} jump={coachJump} price={coachJump.price} type="coach" label="J" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={coachJump.jumper._id} />
                    </li>
                  </ul>


                </div>

                <div className={settingsMoreThan4 ? "right-menu-morethan4" : `right-menu ${settingsSetPos}`}>
                  {!isCompleted && <div style={{ position: 'absolute', right: 0, top: -18 }}>
                    <input className="selectable" type="checkbox" style={{ width: 11, height: 11 }} onChange={(e) => this.handleItem(e, this.props)} />
                  </div>}
                  <span onClick={this.toggleOptions}>
                    <i className="fa fa-ellipsis-v"></i>
                  </span>
                </div>
              </div>

              {/* <Col xs="1" md="2" lg="1" className="jumpClose" style={{ paddingLeft: '4px' }}>
                <UpdateAmount
                  isToday={this.props.isToday}
                  jumpId={jumpId}
                  loadNum={loadNum}
                  jumpType={"coachJump"}
                  isCompleted={this.props.isCompleted}
                  loadId={this.props.loadId}
                  jumpData={coachJump}
                  isWingsuit={coachJump.isWingsuit}
                />
                {!this.props.isCompleted &&  <CancelJump loadNum={loadNum} jumpId={jumpId} jumpType={"coachJump"} />}
              </Col> */}
            </div>
            {coachJump.startedAt ?
              <Row>
                <Col>
                  {window.ENV?.arizona && coachJump.startedAt ? <Badge style={{ fontSize: '14px' }}>{new Date(coachJump.startedAt).toLocaleTimeString()}</Badge> : null}
                  {coachJump.callRequest ? <Badge style={{ fontSize: '14px' }}>{coachJump.callRequest}</Badge> : null}
                </Col>
              </Row> : null
            }
            {coachJump.note ?
              <CardFooter className="fun-card-footer" >{coachJump.note}</CardFooter> : null}
          </CardBody>
        </Card>
      </div>
    )
  }
}


export default DragSource(Types.ITEM, itemSource, collect)(RenderCoachJump)
