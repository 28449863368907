import { EventEmitter } from 'events';


import api from '../services/reservationapi';
import schedulesStore from './schedule-store';
import jumperStore from './jumpers-store';
import swal from 'sweetalert';
import PAX from '../services/pax';
import cardConnectApi from '../services/cardConnectApi';
import tnpApi from '../services/gotnpayTerminalapi';
import reportsStore from '../stores/report-store';
import accountStore from '../stores/account-store';

// https://www.youtube.com/watch?v=bvEC6i7CUyE
class TerminalAdapter extends EventEmitter {
  constructor() {
    super();
    this.prefix = 'PROCESSOR_STORE';
    this.token = '';
    this.serviceID = '';
    this.applicationProfileId = '';
    this.serviceId = '';
    this.merchantProfileId = '';

    this.formData = {
      finalForm: {
        timeslotId: null,
        passengers: []
      }
    };
    this.grouponPackages = []
  }

  setListeners = (socket) => {
    socket.on('makeReservation', data => {
      this.emitResults(true, 'reservationEvent');
    });
  }

  getToken = () => {
    return this.token;
  }

  setToken = (val) => {
    this.token = val;
  }

  setProcessorVals = (values) => {
    this.serviceID = values.ServiceID;
    this.applicationProfileId = values.applicationProfileId;
    this.merchantProfileId = values.merchantProfileId;
  }

  getServiceId = () => {
    return this.serviceID;
  }

  getApplicationProfileId = () => {
    return this.applicationProfileId;
  }

  getMerchantProfileId = () => {
    return this.merchantProfileId;
  }

  clearAll = () => {

    this.formData = {
      finalForm: {
        timeslotId: null,
        passengers: []
      }
    };
  }

  getAll = () => {
    return this.formData;
  }

  updateFormWithIndex = (formData, index) => {
    this.formData.finalForm.passengers[index] = formData;
    this.emit('childFormUpdate', this.formData.finalForm.passengers);
  }

  updateFinalForm = (data) => {
    data.passengers || (data.passengers = []) // setting default
    this.formData.finalForm = data;
  }

  getFormData(index) {
    return this.formData.finalForm.passengers[index] || null;
  }



  searchTransaction = (searchBody) => {
    if (window.ENV?.processorInfo.processor === "transnational") {
      return new Promise((resolve, reject) => {
        tnpApi.searchTransaction(searchBody).then((result) => {
          console.log(result);
          if (result.status === 200) {
            var retObject = {
              transactionType: "Credit",
              transactionId: result.data.data[0].id,
              maskedCardNum: result.data.data[0].response_body.terminal.masked_card,
              transactionDateTime: new Date(),
              cardholderName: result.data.data[0].response_body.terminal.cardholder_name,
              amount: Number(result.data.data[0].amount - result.data.data[0].tax_amount) / 100,
              taxAmount: result.data.data[0].tax_amount ? result.data.data[0].tax_amount / 100 : 0
            }
            if (result.data.data[0].status !== "pending_settlement") {
              if (result.data.data[0].response_body.terminal.processor_response_text !== "") {
                retObject.memo = result.data.data[0].response_body.terminal.processor_response_text + " ($" + result.data.data[0].amount / 100 + ")";
              }
              else {
                retObject.memo = result.data.data[0].status + " ($" + result.data.data.amount / 100 + ")";
              }
              retObject.amount = 0.00;
              retObject.taxAmount = 0.00;
            }
            resolve(retObject);

          } else if (result.status === 500 || result.status === 404) {
            this.emitResults(true, 'warningEvent');
            resolve(false);
          } else {
            // this.emitResults(false, 'chargeCompleted');
            this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
          }
        })
          .catch((err) => {
            this.emitResults(false, 'chargeCompleted');
            this.emitError({ message: 'Incomplete or Invalid data present' });
            reject(err);
          });
      });
    }
  }

  doCredit(subamount, taxAmount, pfees, orderId, name, jumperId, itemized, isloading, isTandemStudent) {
    if (window.ENV?.processorInfo.processor === "transnational") {
      var payload = {
        amount: Math.round(((Number(subamount) + Number(taxAmount)) * 100) + Number(pfees)),
        taxAmount: taxAmount * 100,
        processingFees: pfees,
        orderId: orderId,
        name: name,
        transactionType: "Credit",
        jumperId,
        itemized,
        isTandemStudent
      }
      return new Promise((resolve, reject) => {
        tnpApi.postTransactionv2(payload)
          .then((r) => {
            if (r.status == 200) {
              console.log('after charge jumper backend');
              accountStore.processCharge(jumperId, r, payload, isloading = false)
              resolve(r.data);

              //console.log('after recording charge jumper');
            }
            else if (r.status === 500 || r.status === 404) {
              this.emitResults(true, 'warningEvent');
              resolve(false);
            }
            else if (r.status == 401) {
              this.loginExpired();
              this.emitResults(true, 'clearSession');
            }
            else {
              // this.emitResults(false, 'chargeCompleted');
              this.emitError({ message: 'ChargeJumper could not be created, please contact support.' });
              resolve(false);
            }
          })
          .catch((err) => {
            // this.emitResults(false, 'chargeCompleted');
            this.emitError({ message: 'Incomplete or Invalid data present' });
            reject(err);
          });
      })
    }
    if (window.ENV?.processorInfo.processor === "cardConnect") {
      var payload = {
        amount: subamount,
        orderId: orderId,
        name: name
      }
      return new Promise((resolve, reject) => {
        cardConnectApi.terminalAuthCapture(payload)
          .then(ret => {
            console.log(ret.data);
            var retObject = {
              transactionType: "Credit",
              transactionId: ret.data.retref,
              maskedCardNum: ret.data.token.slice(-4),
              transactionDateTime: new Date(),
              cardholderName: ret.data.name,
              amount: Number(ret.data.amount)
            }
            if (ret.data.respcode !== "000") {
              retObject.memo = "DECLINED";
            }
            resolve(retObject);
          })
      })
    }
    else if (window.ENV?.processorInfo.processor === "spoton") {
      var payload = {
        "command": "T00",
        "version": "1.28",
        "transactionType": "01",
        "amountInformation": { "TransactionAmount": subamount * 100, "TipAmount": "", "CashBackAmount": "", "MerchantFee": "", "TaxAmount": "", "FuelAmount": "" },
        "accountInformation": { "Account": "", "EXPD": "", "CVVCode": "", "EBTtype": "", "VoucherNumber": "", "Force": "", "FirstName": "", "LastName": "", "CountryCode": "", "State_ProvinceCode": "", "CityName": "", "EmailAddress": "" },
        "traceInformation": { "ReferenceNumber": "1", "InvoiceNumber": orderId, "AuthCode": "", "TransactionNumber": "", "TimeStamp": "", "ECRTransID": "" },
        "avsInformation": { "ZipCode": "", "Address": "", "Address2": "" },
        "cashierInformation": { "ClerkID": "", "ShiftID": "" },
        "commercialInformation": { "PONumber": "", "CustomerCode": "", "TaxExempt": "", "TaxExemptID": "", "MerchantTaxID": "", "DestinationZipCode": "", "ProductDescription": "" },
        "motoEcommerce": { "MOTO_E_CommerceMode": "", "TransactionType": "", "SecureType": "", "OrderNumber": "", "Installments": "", "CurrentInstallment": "" },
        "additionalInformation": { "TABLE": "", "GUEST": "", "SIGN": "", "TICKET": "", "HREF": "", "TIPREQ": "", "SIGNUPLOAD": "", "REPORTSTATUS": "", "TOKENREQUEST": "", "TOKEN": "", "CARDTYPE": "", "CARDTYPEBITMAP": "", "PASSTHRUDATA": "", "RETURNREASON": "", "ORIGTRANSDATE": "", "ORIGPAN": "", "ORIGEXPIRYDATE": "", "ORIGTRANSTIME": "", "DISPROGPROMPTS": "", "GATEWAYID": "", "GETSIGN": "", "ENTRYMODEBITMAP": "", "RECEIPTPRINT": "", "CPMODE": "", "ODOMETER": "", "VEHICLENO": "", "JOBNO": "", "DRIVERID": "", "EMPLOYEENO": "", "LICENSENO": "", "JOBID": "", "DEPARTMENTNO": "", "CUSTOMERDATA": "", "USERID": "", "VEHICLEID": "" }
      }
      var PacketageInfo = {
        'Initialize': {},
        'GetSignature': {},
        'DoSignature': {},
        'DoCredit': {}
      };
      return new Promise((resolve, reject) => {
        PAX.DoCredit(payload, function (response) {

          var i = 0, j = -1;
          PacketageInfo.DoCredit.Status = response[++i];
          PacketageInfo.DoCredit.Command = response[++i];
          PacketageInfo.DoCredit.Version = response[++i];
          PacketageInfo.DoCredit.ResponseCode = response[++i];
          PacketageInfo.DoCredit.ResponseMessage = response[++i];


          PacketageInfo.DoCredit.HostInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.HostInformation == '') {
            PacketageInfo.DoCredit.HostInformation = {};
            PacketageInfo.DoCredit.HostInformation.HostResponseCode = '';
            PacketageInfo.DoCredit.HostInformation.HostResponseMessage = '';
            PacketageInfo.DoCredit.HostInformation.AuthCode = '';
            PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = '';
            PacketageInfo.DoCredit.HostInformation.TraceNumber = '';
            PacketageInfo.DoCredit.HostInformation.BatchNumber = '';
          } else {
            PacketageInfo.DoCredit.HostInformation.HostResponseCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.HostResponseMessage = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.AuthCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.TraceNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.BatchNumber = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.TransactionType = (response[++i] != undefined) ? response[i] : '';

          PacketageInfo.DoCredit.AmountInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AmountInformation == '') {
            PacketageInfo.DoCredit.AmountInformation = {};
            PacketageInfo.DoCredit.AmountInformation.ApproveAmount = '';
            PacketageInfo.DoCredit.AmountInformation.AmountDue = '';
            PacketageInfo.DoCredit.AmountInformation.TipAmount = '';
            PacketageInfo.DoCredit.AmountInformation.CashBackAmount = '';
            PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = '';
            PacketageInfo.DoCredit.AmountInformation.TaxAmount = '';
            PacketageInfo.DoCredit.AmountInformation.Balance1 = '';
            PacketageInfo.DoCredit.AmountInformation.Balance2 = '';

          } else {
            j = -1;
            PacketageInfo.DoCredit.AmountInformation.ApproveAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.AmountDue = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.TipAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.CashBackAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.TaxAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.Balance1 = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.Balance2 = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.AccountInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AccountInformation == '') {
            PacketageInfo.DoCredit.AccountInformation = {};
            PacketageInfo.DoCredit.AccountInformation.Account = '';
            PacketageInfo.DoCredit.AccountInformation.EntryMode = '';
            PacketageInfo.DoCredit.AccountInformation.ExpireDate = '';
            PacketageInfo.DoCredit.AccountInformation.EBTtype = '';
            PacketageInfo.DoCredit.AccountInformation.VoucherNumber = '';
            PacketageInfo.DoCredit.AccountInformation.NewAccountNo = '';
            PacketageInfo.DoCredit.AccountInformation.CardType = '';
            PacketageInfo.DoCredit.AccountInformation.CardHolder = '';
            PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = '';
            PacketageInfo.DoCredit.AccountInformation.CVDMessage = '';
            PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = '';

          } else {
            j = -1;
            PacketageInfo.DoCredit.AccountInformation.Account = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.EntryMode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.ExpireDate = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.EBTtype = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.VoucherNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.NewAccountNo = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CardType = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CardHolder = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CVDMessage = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.TraceInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.TraceInformation == '') {
            PacketageInfo.DoCredit.TraceInformation = {};
            PacketageInfo.DoCredit.TraceInformation.TransactionNumber = '';
            PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = '';
            PacketageInfo.DoCredit.TraceInformation.TimeStamp = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.TraceInformation.TransactionNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.TraceInformation.TimeStamp = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          console.log('trace information');
          console.log(PacketageInfo.DoCredit.TraceInformation);

          PacketageInfo.DoCredit.AVSinformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AVSinformation == '') {
            PacketageInfo.DoCredit.AVSinformation = {};
            PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = '';
            PacketageInfo.DoCredit.AVSinformation.AVSMessage = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AVSinformation.AVSMessage = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.CommercialInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.CommercialInformation == '') {
            PacketageInfo.DoCredit.CommercialInformation = {};
            PacketageInfo.DoCredit.CommercialInformation.PONumber = '';
            PacketageInfo.DoCredit.CommercialInformation.CustomerCode = '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExempt = '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = '';
            PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = '';
            PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = '';
            PacketageInfo.DoCredit.CommercialInformation.ProductDescription = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.CommercialInformation.PONumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.CustomerCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExempt = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.ProductDescription = (response[i][++j] != undefined) ? response[i][j] : '';
          }
          console.log(PacketageInfo.DoCredit.CommercialInformation);

          PacketageInfo.DoCredit.motoEcommerce = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.motoEcommerce == '') {
            PacketageInfo.DoCredit.motoEcommerce = {};
            PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = '';
            PacketageInfo.DoCredit.motoEcommerce.TransactionType = '';
            PacketageInfo.DoCredit.motoEcommerce.SecureType = '';
            PacketageInfo.DoCredit.motoEcommerce.OrderNumber = '';
            PacketageInfo.DoCredit.motoEcommerce.Installments = '';
            PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.TransactionType = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.SecureType = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.OrderNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.Installments = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.AdditionalInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AdditionalInformation == '')
            PacketageInfo.DoCredit.AdditionalInformation = {};
          var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation, keyValue = [];
          for (i = 0; i < additionalInfoArr.length; i++) {
            keyValue = additionalInfoArr[i].split("=");
            PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
            keyValue = [];
          }
          console.log(PacketageInfo.DoCredit);
          var retObject = {
            transactionType: "Credit",
            transactionId: PacketageInfo.DoCredit.TraceInformation.TransactionNumber,
            maskedCardNum: PacketageInfo.DoCredit.AccountInformation.Account,
            transactionDateTime: PacketageInfo.DoCredit.TraceInformation.TimeStamp,
            cardholderName: PacketageInfo.DoCredit.AccountInformation.CardHolder,
            amount: PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
          }
          resolve(retObject);
        });
      })
    }

  }

  doVoidCredit(transactionId, amount) {
    if (window.ENV?.processorInfo.processor === "cardConnect") {
      return new Promise((resolve, reject) => {
        var payload = {
          retref: transactionId
        };
        cardConnectApi.cardConnetVoidTransaction(payload).then(this.processUndoResponse)
          .then(ret => {
            var retObject = {
              transactionType: "Credit",
              isRefund: true,
              transactionId: "",
              maskedCardNum: "",
              transactionDateTime: "",
              cardholderName: "",
              amount: amount
            }
            resolve(retObject);
          })
      })
    }

    else if (window.ENV?.processorInfo.processor === "spoton") {
      var payload = {
        "command": "T00",
        "version": "1.28",
        "transactionType": "16",
        "amountInformation": { "TransactionAmount": "", "TipAmount": "", "CashBackAmount": "", "MerchantFee": "", "TaxAmount": "", "FuelAmount": "" },
        "accountInformation": { "Account": "", "EXPD": "", "CVVCode": "", "EBTtype": "", "VoucherNumber": "", "Force": "", "FirstName": "", "LastName": "", "CountryCode": "", "State_ProvinceCode": "", "CityName": "", "EmailAddress": "" },
        "traceInformation": { "ReferenceNumber": "1", "InvoiceNumber": "", "AuthCode": "", "TransactionNumber": transactionId, "TimeStamp": "", "ECRTransID": "" },
        "avsInformation": { "ZipCode": "", "Address": "", "Address2": "" },
        "cashierInformation": { "ClerkID": "", "ShiftID": "" },
        "commercialInformation": { "PONumber": "", "CustomerCode": "", "TaxExempt": "", "TaxExemptID": "", "MerchantTaxID": "", "DestinationZipCode": "", "ProductDescription": "" },
        "motoEcommerce": { "MOTO_E_CommerceMode": "", "TransactionType": "", "SecureType": "", "OrderNumber": "", "Installments": "", "CurrentInstallment": "" },
        "additionalInformation": { "TABLE": "", "GUEST": "", "SIGN": "", "TICKET": "", "HREF": "", "TIPREQ": "", "SIGNUPLOAD": "", "REPORTSTATUS": "", "TOKENREQUEST": "", "TOKEN": "", "CARDTYPE": "", "CARDTYPEBITMAP": "", "PASSTHRUDATA": "", "RETURNREASON": "", "ORIGTRANSDATE": "", "ORIGPAN": "", "ORIGEXPIRYDATE": "", "ORIGTRANSTIME": "", "DISPROGPROMPTS": "", "GATEWAYID": "", "GETSIGN": "", "ENTRYMODEBITMAP": "", "RECEIPTPRINT": "", "CPMODE": "", "ODOMETER": "", "VEHICLENO": "", "JOBNO": "", "DRIVERID": "", "EMPLOYEENO": "", "LICENSENO": "", "JOBID": "", "DEPARTMENTNO": "", "CUSTOMERDATA": "", "USERID": "", "VEHICLEID": "" }
      }
      var PacketageInfo = {
        'Initialize': {},
        'GetSignature': {},
        'DoSignature': {},
        'DoCredit': {}
      };
      return new Promise((resolve, reject) => {
        PAX.DoCredit(payload, function (response) {

          var i = 0, j = -1;
          PacketageInfo.DoCredit.Status = response[++i];
          PacketageInfo.DoCredit.Command = response[++i];
          PacketageInfo.DoCredit.Version = response[++i];
          PacketageInfo.DoCredit.ResponseCode = response[++i];
          PacketageInfo.DoCredit.ResponseMessage = response[++i];


          PacketageInfo.DoCredit.HostInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.HostInformation == '') {
            PacketageInfo.DoCredit.HostInformation = {};
            PacketageInfo.DoCredit.HostInformation.HostResponseCode = '';
            PacketageInfo.DoCredit.HostInformation.HostResponseMessage = '';
            PacketageInfo.DoCredit.HostInformation.AuthCode = '';
            PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = '';
            PacketageInfo.DoCredit.HostInformation.TraceNumber = '';
            PacketageInfo.DoCredit.HostInformation.BatchNumber = '';
          } else {
            PacketageInfo.DoCredit.HostInformation.HostResponseCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.HostResponseMessage = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.AuthCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.TraceNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.HostInformation.BatchNumber = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.TransactionType = (response[++i] != undefined) ? response[i] : '';

          PacketageInfo.DoCredit.AmountInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AmountInformation == '') {
            PacketageInfo.DoCredit.AmountInformation = {};
            PacketageInfo.DoCredit.AmountInformation.ApproveAmount = '';
            PacketageInfo.DoCredit.AmountInformation.AmountDue = '';
            PacketageInfo.DoCredit.AmountInformation.TipAmount = '';
            PacketageInfo.DoCredit.AmountInformation.CashBackAmount = '';
            PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = '';
            PacketageInfo.DoCredit.AmountInformation.TaxAmount = '';
            PacketageInfo.DoCredit.AmountInformation.Balance1 = '';
            PacketageInfo.DoCredit.AmountInformation.Balance2 = '';

          } else {
            j = -1;
            PacketageInfo.DoCredit.AmountInformation.ApproveAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.AmountDue = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.TipAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.CashBackAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.TaxAmount = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.Balance1 = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AmountInformation.Balance2 = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.AccountInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AccountInformation == '') {
            PacketageInfo.DoCredit.AccountInformation = {};
            PacketageInfo.DoCredit.AccountInformation.Account = '';
            PacketageInfo.DoCredit.AccountInformation.EntryMode = '';
            PacketageInfo.DoCredit.AccountInformation.ExpireDate = '';
            PacketageInfo.DoCredit.AccountInformation.EBTtype = '';
            PacketageInfo.DoCredit.AccountInformation.VoucherNumber = '';
            PacketageInfo.DoCredit.AccountInformation.NewAccountNo = '';
            PacketageInfo.DoCredit.AccountInformation.CardType = '';
            PacketageInfo.DoCredit.AccountInformation.CardHolder = '';
            PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = '';
            PacketageInfo.DoCredit.AccountInformation.CVDMessage = '';
            PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = '';

          } else {
            j = -1;
            PacketageInfo.DoCredit.AccountInformation.Account = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.EntryMode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.ExpireDate = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.EBTtype = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.VoucherNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.NewAccountNo = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CardType = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CardHolder = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CVDMessage = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.TraceInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.TraceInformation == '') {
            PacketageInfo.DoCredit.TraceInformation = {};
            PacketageInfo.DoCredit.TraceInformation.TransactionNumber = '';
            PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = '';
            PacketageInfo.DoCredit.TraceInformation.TimeStamp = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.TraceInformation.TransactionNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.TraceInformation.TimeStamp = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          console.log('trace information');
          console.log(PacketageInfo.DoCredit.TraceInformation);

          PacketageInfo.DoCredit.AVSinformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AVSinformation == '') {
            PacketageInfo.DoCredit.AVSinformation = {};
            PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = '';
            PacketageInfo.DoCredit.AVSinformation.AVSMessage = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.AVSinformation.AVSMessage = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.CommercialInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.CommercialInformation == '') {
            PacketageInfo.DoCredit.CommercialInformation = {};
            PacketageInfo.DoCredit.CommercialInformation.PONumber = '';
            PacketageInfo.DoCredit.CommercialInformation.CustomerCode = '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExempt = '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = '';
            PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = '';
            PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = '';
            PacketageInfo.DoCredit.CommercialInformation.ProductDescription = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.CommercialInformation.PONumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.CustomerCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExempt = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.CommercialInformation.ProductDescription = (response[i][++j] != undefined) ? response[i][j] : '';
          }
          console.log(PacketageInfo.DoCredit.CommercialInformation);

          PacketageInfo.DoCredit.motoEcommerce = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.motoEcommerce == '') {
            PacketageInfo.DoCredit.motoEcommerce = {};
            PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = '';
            PacketageInfo.DoCredit.motoEcommerce.TransactionType = '';
            PacketageInfo.DoCredit.motoEcommerce.SecureType = '';
            PacketageInfo.DoCredit.motoEcommerce.OrderNumber = '';
            PacketageInfo.DoCredit.motoEcommerce.Installments = '';
            PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = '';
          } else {
            j = -1;
            PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.TransactionType = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.SecureType = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.OrderNumber = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.Installments = (response[i][++j] != undefined) ? response[i][j] : '';
            PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = (response[i][++j] != undefined) ? response[i][j] : '';
          }

          PacketageInfo.DoCredit.AdditionalInformation = (response[++i] != undefined) ? response[i] : '';
          if (PacketageInfo.DoCredit.AdditionalInformation == '')
            PacketageInfo.DoCredit.AdditionalInformation = {};
          var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation, keyValue = [];
          for (i = 0; i < additionalInfoArr.length; i++) {
            keyValue = additionalInfoArr[i].split("=");
            PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
            keyValue = [];
          }

          console.log(PacketageInfo.DoCredit);
          var retObject = {
            transactionType: "Credit",
            isRefund: true,
            transactionId: PacketageInfo.DoCredit.TraceInformation.TransactionNumber,
            maskedCardNum: PacketageInfo.DoCredit.AccountInformation.Account,
            transactionDateTime: PacketageInfo.DoCredit.TraceInformation.TimeStamp,
            cardholderName: PacketageInfo.DoCredit.AccountInformation.CardHolder,
            amount: -PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
          }
          resolve(retObject);
        });
      })
    }

  }


  MakeReservation = (payload) => {
    // let passangerBody = { timeslotId: payload[0].timeslotId, tandemStudents: payload[0].tandemStudents, totalPaid: payload[0].totalPaid, numVideos: payload[0].numVideos,transactionId:'Transaction123' };
    //let passangerBody = { timeslotId: payload[0].timeslotId, tandemStudents: payload[0].tandemStudents, totalPaid: 0, numVideos: payload[0].numVideos,transactionId:'Transaction123',TransactionDateTime:'Transaction_Time' };
    let passangerBody = {
      timeslotId: payload.slotId, students: payload.passengers, eventName: payload.eventName,
      totalCharge: parseInt(payload.amount), totalFees: parseInt(payload.totalFees),
      numVideos: parseInt(payload.videoCount), transactionId: payload.transactionId,
      TransactionDateTime: new Date().toISOString()
    };
    return new Promise((resolve, reject) => {
      api.makeReservation(passangerBody).then((r) => {
        if (r.status == 200) {
          schedulesStore.closeReservation();
          //jumperStore.addTandemStudents(r.data.students);
          //console.log(r);
          this.emitResults(true, 'reservationEvent');
          resolve(r.data.reservations);
        }
        else if (r.status == 401) {
          this.loginExpired();
          this.emitResults(true, 'clearSession');
        }
        else if (r.status === 500 || r.status === 404) {
          this.emitResults(true, 'warningEvent');
          resolve(false);
        }
        else {
          //console.log({ message: 'Reservation could not be created, please contact support.' });
          this.emitResults(false, 'reservationEvent');
          this.emitResults(true, 'warningEvent');
          resolve(false);
        }
      })
        .catch((err) => {
          //console.log({ message: 'Incomplete or Invalid data present' });
          this.emitResults(false, 'reservationEvent');
          reject(err);
        });
    });
  }

  VerifyGiftCard = (payload) => {
    return new Promise((resolve, reject) => {
      api.verifyGiftCard(payload).then((r) => {
        if (r.status == 200) {
          console.log(r);
          resolve(r.data);
        } else if (r.status == 401) {
          this.loginExpired();
          this.emitResults(true, 'clearSession');
        } else {
          resolve(false);
          console.log({ message: 'Invalid Card' });
        }
      })
        .catch((err) => {
          console.log({ message: 'Incomplete or Invalid data present' });
          reject(err);
        });
    });
  }


  getGrouponPackages = () => {
    return this.grouponPackages;
  }

  addReservationMemo = (slotId, payload) => {
    return new Promise((resolve, reject) => {
      api.addReservationMemo(slotId, payload).then((r) => {
        if (r.status === 200) {
          var result = r.data;
          swal({
            title: "Success",
            text: "Memo Added Successfully",
            icon: "success",
            dangerMode: false,
          })
          resolve(true);
        }
        else if (r.status === 500 || r.status === 404) {
          this.emitResults(true, 'warningEvent');
          resolve(false);
        }
        else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
        else {
          resolve(false);
        }
      })
        .catch(err => {
          this.emitError({ message: 'Incomplete or Invalid data present' });
          reject(err);
        });
    });
  }



  clearState = () => {
    this.token = '';
    this.serviceID = '';
    this.applicationProfileId = '';
    this.serviceId = '';
    this.merchantProfileId = '';
  }

  emitError = (err) => {
    var payload = { ...err };
    this.emit('ERROR', payload);
  }

  getEventPrefix = (func) => {
    return `${this.prefix}${func}`;
  }

	loginExpired = () => {
		//alert(JSON.stringify(localStorage, null,2));
		if(localStorage.getItem('isAuth')){
			localStorage.clear();
			swal({
				title: "LOGIN EXPIRED",
				text: "please re-login",
				icon: "warning",
				dangerMode: true,
			})
		}

		//window.location =  window.location.origin
		//window.location.reload();
	}
  
  emitResults = (r, func) => {
    //console.log(r);
    this.setMaxListeners(1000);
    this.emit(`${this.prefix}${func}`, r);
  }

  emitDate = (r, func) => {
    this.setMaxListeners(1000);
    this.emit(`${this.prefix}${func}`, r);
  }

  debug = (r) => {
    //console.log(r.data);
  }
}

const terminalAdapter = new TerminalAdapter();
window.TerminalAdapter = terminalAdapter;
export default terminalAdapter;
