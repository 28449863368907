/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';


import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';
import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';

class EditItem extends Component {

    constructor(props) {


        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }



    handleSubmit(values) {
        // console.log("in handle submit load"+ values);
        this.toggleModal();
        var payload = {};
        if (this.props.isEdit && values !== undefined) {
            payload.item = values.item;
            payload.category = values.category;
            payload.price = Number(values.price);
            payload.quantity = Number(values.quantity);
            payload.isTaxable = values.taxable ? true : false;
            payload.trackingQuantity = values.trackingQuantity ? true : false;
            console.log(payload);
            itemStore.updateItem(this.props.item._id, payload);
        }
        else if (values != undefined) {
            payload.item = values.item;
            payload.category = values.category;
            payload.price = Number(values.price);
            payload.quantity = Number(values.quantity);
            payload.trackingQuantity = values.trackingQuantity ? true : false;
            payload.isTaxable = values.taxable ? true : false;
            console.log(payload);
            itemStore.createItem(payload);
        }
    }

    render() {
        let item = { item: "", category: "staff", price: "", quantity: "", isTaxable: false, trackingQuantity: false };

        if (this.props.item !== undefined) {
            item = this.props.item;
            // console.log("🚀 ~ file: createItem.js ~ line 73 ~ EditItem ~ render ~ item", item)
        }

        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={this.props.isEdit ? "Edit item" : "Create item"}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="item" md={3}>Item</Label>
                            <Col md={9}>
                                <Control.input name="item" model=".item" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.item} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="category" md={3}>Category</Label>
                            <Col md={9}>
                                <Control.select name="category" model=".category" className="form-control" mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.category} autoComplete="off">
                                    {
                                        this.props.categories.map((category, index) => {
                                            return (
                                                <option key={index} value={category.category}>{category.category}</option>
                                            )
                                        })
                                    }
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price" model=".price" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.price} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="quantity" md={3}>Quantity</Label>
                            <Col md={9}>
                                <Control
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    className='form-control'
                                    model=".quantity"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.quantity}
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="tracking" md={3}>Tracking Quantity</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="tracking" model=".trackingQuantity"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.trackingQuantity}
                                    autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="taxable" md={3}>Taxable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="taxable" model=".taxable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isTaxable}
                                    autoComplete="off" />
                            </Col>
                        </Row>


                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>

                {!this.props.isEdit ?
                    // <button className="btn lightBlueButton jumper-add-button catagorybtn" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                    //     <i className="fa fa-plus"></i>
                    // </button>
                    <span
                        className="catagorybtn"
                        onClick={this.toggleModal}
                    >
                        <i className="fa fa-plus" ></i>
                    </span>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }

            </React.Fragment>
        );
    }
}

export default EditItem