/**
 * http://usejsdoc.org/
 */
import { EventEmitter } from 'events';

import Api from '../services/categoryapi';

class category extends EventEmitter {
    constructor() {
        super();
    }

    createCategory = (payload) => {
        return new Promise((resolve, reject) => {
            Api.createCategory(payload)
                .then((r) => {
                    console.log(r, "rrrrrrr>>>")
                    if (r.status == 200) {
                        resolve(r.data);
                    } else {
                        reject(r.data);
                    }
                })
                .catch((err) => {
                    console.log(err, "aslkdhjuagsdhasgh")
                    reject(err);
                });
        });
    }

    getCategories = () => {
        return new Promise((resolve, reject) => {
            Api.getCategories()
                .then((r) => {
                    if (r.status == 200) {
                        resolve(r.data);
                    } else {
                        reject(r.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    editCategory = (payload) => {
        return new Promise((resolve, reject) => {
            Api.editCategory(payload)
                .then((r) => {
                    if (r.status == 200) {
                        resolve(r.data);
                    } else {
                        reject(r.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    deleteCategory = (payload) => {
        return new Promise((resolve, reject) => {
            Api.deleteCategory(payload)
                .then((r) => {
                    if (r.status == 200) {
                        resolve(r.data);
                    } else {
                        reject(r.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

}

const categoryStores = new category();
window.categoryStores = categoryStores;
export default categoryStores;
