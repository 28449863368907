import './showLoader.css'
import { TailSpin } from 'react-loader-spinner'

export const Show_Loader = () => {
  return (
    <div id={"loader_overlay"}>
      <div className={"loader_main"}>
        <TailSpin color="blue" />
      </div>
    </div>
  )
}


