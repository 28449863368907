/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
	Card, CardImg, CardText, CardBody,
	CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import LoadStore from '../../../stores/loads-store';
import axios from 'axios';
import Select from 'react-select';
import ItemModal from './customModal';
import Api from '../../../services/reservationapi';
import { ClipLoader } from 'react-spinners';
import loadsStore from '../../../stores/loads-store';
import jumpersStore from '../../../stores/jumpers-store';

import { locales } from 'moment';
// import { updateCompletedCoachJump } from '../../../actions/updateCompletedJump'
import { connect } from 'react-redux';
import EditableOptions from './editableOptions';

import itemStore from '../../../stores/item-store';

class SubmitStudentLog extends Component {

	constructor(props) {

		super(props);
		this.PayLoadId = '';
		this.PayLoadAmount = '';
		this.toggleModal = this.toggleModal.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleError = this.toggleError.bind(this);
		this.handleLogSubmit = this.handleLogSubmit.bind(this);

		// this.paymentType = ["Credit", "Debit", "Cash", "Card on file"];
		this.state = {
			isModalOpen: false,
			isSelected: false,
			errors: {},
			amount: '',
			isloading: false,
			errorOpen: false,
			isCompleted: props.isCompleted,
			logs: []

		}
		this.jumperEvents = [
			{ name: jumpersStore.getEventPrefix('studentLogSubmitted'), func: this.handleLogSubmit },
		];
	}

	toggleModal(e) {
		jumpersStore.getStudentLogs(this.props.studentId);
		this.setState({
			isModalOpen: !this.state.isModalOpen,
		});
	}

	handleLogSubmit(logs) {
		this.setState({
			logs: logs,
			isloading: false
		})
	}

	componentWillUnmount() {
		this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));

	}

	componentDidMount() {
		this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
	}

	handleSubmit(values, loadNum, jumpId, studentId) {

		if (values.instructor1 !== undefined && values.instructor1 !== "" && values.canopy !== undefined && values.canopy !== "" && values.log !== undefined && values.log !== "" && values.level !== undefined) {
			this.setState({ isloading: true });

			var payload = {
				jumpId: jumpId,
				instructor1: values.instructor1,
				canopy: values.canopy,
				log: values.log,
				passed: values.passed,
				level: values.level
			};
			if (values.instructor2) {
				payload.instructor2 = values.instructor2
			}

			jumpersStore.submitStudentLog(studentId, payload);
		}

	}

	getActiveLoadOptions = () => {

		if (localStorage.getItem("role") === "school") {
			var options = ['school waitlist'];
			return options;
		}
		else {
			var options = ['school waitlist'];

			var loads = loadsStore.getActiveLoadsForDisplay();
			options.push(...loads.map(load => {
				var remainingSeats = load?.capacity - load?.totalJumpers;
				return "load " + load?.loadNum + " - " + load?.plane + " " + load?.planeLoadNum + " - " + remainingSeats + " seats remaining";
			}))
			return options;
		}

	}

	handleValidation(name) {
		let fields = this.state;
		let errors = fields.errors;
		if (fields[name] === '') errors[name] = "This field is required";
		else if (name === 'amount' && !fields[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Amount should be enter in two decimal places.";
		else errors[name] = "";
		this.setState({ errors: errors });
	}

	handleInput = (event, key) => {
		var cache = { ...this.state };
		cache[key] = event.target.value;
		this.setState({ ...cache }, () => {
			this.handleValidation(key);
		});
	};

	toggleError() {
		this.setState({
			errorOpen: !this.state.errorOpen
		});
	}

	// componentWillReceiveProps(){
	// 	alert(this.props.showUpdate);

	// 	if(this.props.showUpdate)
	// 	{
	// 		this.setState({
	// 			isModalOpen: this.props.showUpdate
	// 		})
	// 	}
	// }
	createCoachJump = (jumperId, option) => {

		var loadNum = 0;
		if (option === "school waitlist") {
			loadNum = 0;
		}
		else {
			var splitoptions = option.split(" ");
			loadNum = splitoptions[1];
		}


		var today = new Date();
		var payload = {
			jumperId: jumperId,

		}
		loadsStore.createCoachJump(loadNum, payload);

	}
	createJump = (jumperId, option) => {

		var loadNum;
		if (option === "school waitlist") {
			loadNum = 0;
		}
		else {
			var splitoptions = option.split(" ");

			loadNum = splitoptions[1];
		}



		var today = new Date();

		var studentPackages = itemStore.getStudentPackages();

		//find last passed student
		var lastPassedStudentPackageIndx = -1;

		if (this.state.logs.length == 0) {
			var payload = {
				studentId: jumperId,
				type: studentPackages[0].type,
				studentPackageId: studentPackages[0]._id,
				price: studentPackages[0].price
			}
			loadsStore.createAffJump(loadNum, payload);
		}

		else {
			for (var i = this.state.logs.length - 1; i >= 0; i--) {
				if (this.state.logs[i].passed) {
					lastPassedStudentPackageIndx = i
					break;
				}
			}

			if (lastPassedStudentPackageIndx >= 0) {
				var indx = studentPackages.findIndex(pk => this.state.logs[lastPassedStudentPackageIndx].level === pk.name);
				var studentPackage, nextStudentPackage;

				if (indx >= 0) {
					studentPackage = studentPackages[indx];
					if (indx < studentPackages.length - 1) {
						nextStudentPackage = studentPackages[indx + 1];
					}
					else {
						nextStudentPackage = studentPackages[indx];

					}
					var payload = {
						studentId: jumperId,
						type: nextStudentPackage.type,
						studentPackageId: nextStudentPackage._id,
						price: nextStudentPackage.price
					}

					loadsStore.createAffJump(loadNum, payload)
				}
			}
			else if (lastPassedStudentPackageIndx === -1) {

				var payload = {
					studentId: jumperId,
					type: studentPackages[0].type,
					studentPackageId: studentPackages[0]._id,
					price: studentPackages[0].price
				}

				loadsStore.createAffJump(loadNum, payload)
			}


		}
		//loadsStore.createTandemj
	}

	RenderLog = (props) => {
		return (
			<React.Fragment>
				<Card style={{ backgroundColor: '#4598EA', fontSize: '21px' }} >
					<CardBody >
						<Row>
							<Col md={4}>
								Date
							</Col>
							<Col>
								<strong>{new Date(props.log.date).toLocaleDateString()}</strong>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								Level
							</Col>
							<Col>
								<strong>{props.log.level}</strong>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								canopy
							</Col>
							<Col>
								<strong>{props.log.canopy}</strong>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								Instructor 1
							</Col>
							<Col>
								<strong>{props.log.instructor1}</strong>
							</Col>
						</Row>
						{props.log.instructor2 !== undefined ?
							<Row>
								<Col md={4}>
									Instructor 2
								</Col>
								<Col>
									<strong>{props.log.instructor2}</strong>
								</Col>
							</Row> : null
						}
						<Row>
							<Col md={4}>
								Log
							</Col>
							<Col>
								<strong>{props.log.log}</strong>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								Passed
							</Col>
							<Col>
								<strong>{props.log.passed ? "Yes" : "No"}</strong>
							</Col>
						</Row>

					</CardBody>
				</Card>
				<br />
			</React.Fragment>
		)
	}

	setStudentToLicensed = (id) => {
		jumpersStore.setStudentToLicensedStatus(id)
			.then(stud => {
				jumpersStore.getDailyActivity(id);

			})
	}
	render() {
		const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

		const { jumpData, loadId, isCompleted, isUltra, isWingsuit, isToday } = this.props;

		var logs = this.state.logs.map(log => {
			return <this.RenderLog log={log} />
		})

		return (
			<React.Fragment>
				<ItemModal
					isModalOpen={this.state.isModalOpen}
					title={`Student Log`}
				><button className="close" onClick={(e) => { e.preventDefault(); this.toggleModal(); }} style={{ position: 'absolute', left: '26%', top: '-50px', color: 'white' }}>x</button>

					<div className='complete-loading'>
						<ClipLoader
							css={override}
							sizeUnit={"px"}
							size={35}
							color={'#fff'}
							loading={this.state.isloading}
						/>
					</div>

					<h4>Logs </h4>

					<EditableOptions menuOptions={this.getActiveLoadOptions()} callback={this.createJump} studentId={this.props.studentId} text="+ manifest AFF" type="manifest student" />

					<EditableOptions menuOptions={this.getActiveLoadOptions()} callback={this.createCoachJump} studentId={this.props.studentId} text="+ manifest coach" type="manifest student" />

					<button className="btn btn-secondary btn-sm" style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }} onClick={(e) => { e.preventDefault(); jumpersStore.toggleStudentWaitingStatus(this.props.studentId) }} > set waiting status</button>

					<button className="btn btn-secondary btn-sm" style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }} onClick={(e) => { e.preventDefault(); this.setStudentToLicensed(this.props.studentId) }} > set to licensed status</button>

					<br />
					{logs}
					<br />
					{this.props.isCompleted ?
						<React.Fragment>
							<h4>Enter New Log: </h4>
							<br />

							<LocalForm onSubmit={(values) => this.handleSubmit(values, this.props.loadNum, this.props.jumpId, this.props.studentId)}>

								<Row className="form-group">
									<Label htmlFor="instructor1" md={3}>Instructor :</Label>
									<Col md={9}>
										<Control.input name="instructor1" model=".instructor1" className="form-control"
											mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.instructor1Name}
											autoComplete="off" />

									</Col>
								</Row>

								{this.props.instructor2Name && this.props.instructor2Name.length != "" ?
									<Row className="form-group">
										<Label htmlFor="instructor1" md={3}>Instructor 2 :</Label>
										<Col md={9}>
											<Control.input name="instructor2" model=".instructor2" className="form-control"
												mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.instructor2Name}
												autoComplete="off" />

										</Col>
									</Row> : null
								}

								<Row className="form-group">
									<Label htmlFor="level" md={3}>level :</Label>
									<Col md={9}>
										<Control.input name="level" model=".level" className="form-control"
											mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.level}
											autoComplete="off" />

									</Col>
								</Row>

								<Row className="form-group">
									<Label htmlFor="level" md={3}>Canopy :</Label>
									<Col md={9}>
										<Control.input name="canopy" model=".canopy" className="form-control"
											mapProps={{ onChange: (props) => props.onChange }}
											autoComplete="off" />

									</Col>
								</Row>

								<Row className="form-group">
									<Label htmlFor="log" md={3}>Log</Label>
									<Col md={9} style={{ marginTop: '7px' }}>
										<Control.textarea name="log" model=".log" className="form-control"
											mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
									</Col>
								</Row>

								<Row className="form-group">
									<Label htmlFor="passed" md={3}>Passed</Label>
									<Col md={9} style={{ marginTop: '7px' }}>
										<Control.checkbox name="passed" model=".passed"
											mapProps={{ onChange: (props) => props.onChange }} checked autoComplete="off" />
									</Col>
								</Row>


								<Row className="form-group m-t-2-rem">
									<Col md={{ size: 9 }}>


										<button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>

										<button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
									</Col>
								</Row>



							</LocalForm>
						</React.Fragment> : null
					}
				</ItemModal>
				<Modal isOpen={this.state.errorOpen} toggle={this.toggleError} className={this.props.className}>
					<ModalHeader toggle={this.toggleError} className="error-jumperHeader">
						Warning
					</ModalHeader>
					<ModalBody>
						<p style={{ color: 'red', fontSize: '20px', marginLeft: '1%' }}>Something Wrong happened while charging Jumper !</p>
					</ModalBody>
				</Modal>

				{/* <span onClick={this.toggleModal} style={{ cursor: 'pointer', marginLeft: '2px' }}>
					<i className="fa fa-edit"></i>
				</span> */}
				<span onClick={this.toggleModal} id={"submit-log" + this.props.jumpId}>
				</span>
			</React.Fragment>
		);
	}
}

// export default UpdateJumperAmount


export default SubmitStudentLog
