/**
 * http://usejsdoc.org/
 */
import React, { Component, memo } from 'react';
import DonutLoader from '../../loadManager/DonutLoader';
import AccountStore from '../../../stores/account-store';
import terminalAdapter from '../../../stores/terminalAdapter';
import ItemModal from './customModal';
import reservationStore from '../../../stores/reservation-store';
import loadsStore from '../../../stores/loads-store';
import Api from '../../../services/reservationapi';
import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type';
import swal from 'sweetalert';
import NabVelocity from '../../sub-components/nab/nabVelocity'
import stringify from 'fast-json-stable-stringify';
import ChargeModalOptions from './chargeModalOptions';
import { Control, LocalForm } from 'react-redux-form';
import ItemsDetails from './sub-comp/ChargeJumperItemsHelper';
import itemStore from '../../../stores/item-store';

var XMLParser = require('react-xml-parser');
// var clearData;
class ChargeJumper extends Component {

    constructor(props) {

        super(props);
        this.PayLoadId = '';
        this.chargeMemo = '';
        this.PayLoadAmount = '';
        this.PayLoadTaxAmount = '';
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        // this.paymentType = ["Credit", "Debit", "Cash", "Card on file"];
        this.cardDigit = '';
        this.jumperId = '';
        this.activityData = [];
        this.activityObj = {};
        this.state = {
            isModalOpen: false,
            isSelected: false,
            isCardProcessing: false,
            errors: {},
            amount: '',
            taxAmount: '',
            isloading: false,
            errorOpen: false,
            showCredit: false,
            memo: "",
            cardType: '',
            cardNumber: '',
            cardHolder: '',
            expire: '',
            checkedRadio:0,
            cvv: '',
            selYear: '',
            selMonth: '',
            cardIcon: '',
            transactionId: '',
            cashin: '',
            cashout: '',
            totalAmount: props.value + props.taxValue,
            newGroupStudentData: [],
            newSingleStudentData: [],
            newSelectedData: []
        };
        this.orderIdQueue = [];
        this.handleChargeCompleted = this.handleChargeCompleted.bind(this);
        this.accountEvents = [
            { name: AccountStore.getEventPrefix('chargeCompleted'), func: this.handleChargeCompleted }
        ];
    }

    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


    handleGroupedStudentData = (newGroupData) => {
        this.setState({ newGroupStudentData: newGroupData })
    }

    handleSingleStudentData = (newSingleData) => {
        this.setState({ newSingleStudentData: newSingleData })
    }

    handleSelectedItems = (newSelectedData) => {
        this.setState({
            newSelectedData: newSelectedData
        })
    }

    handleTandemPackageItemDetails = (i, itemsCount, taxTotal) => {
        if (i?.currentBalance + i?.currentTaxBalance - i?.totalPreviousPaid - i?.totalPreviousTaxPaid > 0.001) {
            let count = i?.currentBalance - i?.totalPreviousPaid;
            let tax = i?.currentTaxBalance - i?.totalPreviousTaxPaid
            itemsCount += count || 0
            taxTotal += tax || 0
        }
        if (i?.tandemPackage?.price + i?.tandemPackage?.taxAmount - i?.totalTandemPaid - i?.totalTandemTaxPaid > 0.001) {
            let count = i?.tandemPackage?.price - i?.totalTandemPaid
            let tax = i?.tandemPackage?.taxAmount - i?.totalTandemTaxPaid
            itemsCount += count || 0
            taxTotal += tax || 0
        }
        if (i?.videoPackage?.price + i?.videoPackage?.taxAmount - i?.totalVideoPaid - i?.totalVideoTaxPaid > 0.0001) {
            let count = i?.videoPackage?.price - i?.totalVideoPaid;
            let tax = i?.videoPackage?.taxAmount - i?.totalVideoTaxPaid;
            itemsCount += count || 0
            taxTotal += tax || 0
        }
        if (i?.payBeforeOverweight - i?.totalOverweightPaid > 0.001) {
            let count = i?.payBeforeOverweight - i?.totalOverweightPaid
            let tax = 0
            itemsCount += count || 0
            taxTotal += tax || 0
        }
        if (i?.countersales?.length > 0) {
            i?.countersales?.map(item => {
                if (item.price + item.taxAmount - item.totalPaid - item.totalTaxPaid > 0.0001) {
                    let count = item?.price - item.totalPaid
                    let tax = item?.taxAmount - item.totalTaxPaid
                    itemsCount += count || 0
                    taxTotal += tax || 0
                }
            })
        }
        {
            Object.keys(i).filter((key) => {
                if (i[key]?.hasOwnProperty("value") && i[key]?.hasOwnProperty("totalPaid") && i[key].value + i[key].taxAmount - i[key].totalPaid - i[key].totalTaxPaid > 0.0001) {
                    return true;
                }
            }).map(key => {
                if (i[key]?.value + i[key]?.taxAmount - i[key]?.totalPaid - i[key]?.totalTaxPaid > 0.0001) {
                    let count = i[key]?.value - i[key]?.totalPaid
                    let tax = i[key]?.taxAmount - i[key]?.totalTaxPaid
                    itemsCount += count || 0
                    taxTotal += tax || 0
                }
            })
        }
        return { itemsCount, taxTotal };
    }

    toggleModal(e) {
        if (!this.state.isModalOpen) { // modal about to open
            let itemsCount = 0
            let taxTotal = 0
            let isSelectedItems = (this.props.selectedItems?.length > 0) ? true : false;
            let isSelectedBalance = (this.props.selectedBalance?.length > 0) ? true : false;
            let isSelectedDues = (this.props.selectedDues?.tandem?.length > 0 || this.props.selectedDues?.video?.length > 0 || this.props.selectedDues?.overweight?.length > 0) ? true : false;
            const activity = this.props?.activityObj;
            if (this.props.selectedItems?.length > 0 || this.props.selectedDues?.tandem?.length > 0 || this.props.selectedDues?.video?.length > 0 || this.props.selectedDues?.overweight?.length > 0 || this.props.selectedBalance?.length > 0) {
                this.props.selectedItems?.map(i => {
                    let count = i.price - i.totalPaid
                    let tax = i.taxAmount - i.totalTaxPaid
                    itemsCount += count || 0
                    taxTotal += tax || 0
                })
                this.props.selectedDues?.tandem?.map(i => {
                    let count = i.price
                    let tax = i.taxAmount
                    itemsCount += count || 0
                    taxTotal += tax || 0
                })
                this.props.selectedDues?.video?.map(i => {
                    let count = i.price
                    let tax = i.taxAmount
                    itemsCount += count || 0
                    taxTotal += tax || 0
                })
                this.props.selectedDues?.overweight?.map(i => {
                    let count = i.price
                    let tax = i.taxAmount
                    itemsCount += count || 0
                    taxTotal += tax || 0
                })
                this.props.selectedBalance?.map(i => {
                    let count = i.price - i.previousPaid
                    let tax = i.taxAmount - i.previousTaxPaid
                    itemsCount += count || 0
                    taxTotal += tax || 0
                })
                this.setState({
                    amount: (itemsCount).toFixed(2),
                    taxAmount: (taxTotal).toFixed(2)
                })
            }
            else if (!isSelectedItems && !isSelectedDues && !isSelectedBalance) {
                let tandemStudentAmount;
                if (activity?.tandemStudentActivities?.length > 0) {
                    activity?.tandemStudentActivities?.map(i => {
                        tandemStudentAmount = this.handleTandemPackageItemDetails(i, itemsCount, taxTotal);
                        itemsCount = tandemStudentAmount.itemsCount;
                        taxTotal = tandemStudentAmount.taxTotal;
                    })
                    this.setState({
                        amount: (itemsCount).toFixed(2),
                        taxAmount: (taxTotal).toFixed(2),
                    })
                }
                else {
                    if (this.props.activity || this.props.activityObj) {
                        let i = this.props.activity || this.props.activityObj;
                        tandemStudentAmount = this.handleTandemPackageItemDetails(i, itemsCount, taxTotal);
                        itemsCount = tandemStudentAmount.itemsCount;
                        taxTotal = tandemStudentAmount.taxTotal;
                    }
                    this.setState({
                        amount: (itemsCount).toFixed(2),
                        taxAmount: (taxTotal).toFixed(2)
                    })
                }
            }
            else {
                if (!isNaN(Number(this.props.value))) {
                    this.setState({
                        amount: this.props.value,
                    });
                }
                if (!isNaN(Number(this.props.taxValue))) {
                    var taxValue = 0;
                    if (this.props.taxValue > 0) {
                        taxValue = this.props.taxValue;
                    }
                    this.setState({
                        taxAmount: taxValue
                    });
                }
            }
        }
        // debugger;
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            isloading: false,
            paymentType: '',
            cashin: '',
            cashout: ''
        }
            // ,() => {
            //     if(!this.state.isModalOpen){
            //         debugger;
            //         clearData =  setTimeout(() => {
            //             this.props.clearItems && this.props.clearItems()
            //             this.props.clearDues && this.props.clearDues()
            //             this.props.clearBalance && this.props.clearBalance()  
            //         }, 3000)
            //     }
            // }
        );
        // debugger;
    }

    changeRadio(e) {
        var value = e.target.value;
        if (value === "Card key in") {
            this.setState({ checkedRadio: e.target.value, showCredit: false });
        }
        else {
            this.setState({ checkedRadio: e.target.value, showCredit: false });
        }
    }
    checkForNegatives = (price) => {
        //alert(price);
        //console.log('check for negatives: ' + price);
        if (price != undefined && price !== "") {
            //alert(price);
            let priceCell = (price < 0) ?
                {
                    value: `($${Math.abs(price).toFixed(2)})`
                } :
                {
                    value: `$${price.toFixed(2)}`
                }

            if (price < 0) {
                return <span style={{ color: 'salmon' }}> {priceCell.value}</span>;
            }
            return priceCell.value;
        }
        else {
            return '$0.00';
        }
    }
    handleChargeCompleted(value) {
        if (value === false) {
            this.setState({
                errorOpen: true,
                isloading: false
            })
        }
        else {
            this.setState({
                isloading: false
            })
        }
    }

    handleYearSelect = (event) => this.setState({ selYear: event.target.value.slice(-2) })

    handleMonthSelect = (event) => {
        var month = parseInt(event.target.value);
        if (month > 9) {
            month = month.toString();
        } else {
            month = '0' + month.toString();
        }
        this.setState({ selMonth: month })
    };

    // componentWillMount = () => {

    //     var aScript = document.createElement('script');
    //     aScript.type = 'text/javascript';
    //     aScript.src = "https://api.cert.nabcommerce.com/1.3/button.js";
    //     aScript.setAttribute('id', 'velocityScript');
    //     document.head.appendChild(aScript);
    // }

    componentWillUnmount() {
        this.accountEvents.forEach((evnt) => AccountStore.removeListener(evnt.name, evnt.func));
        // clearTimeout(clearData)
    }

    componentDidMount() {
        this.accountEvents.forEach((evnt) => AccountStore.on(evnt.name, evnt.func));
    }

    handle_selectedItems_Dues = (i, options, ids) => {
        let data = {}
        let itemDetail = []
        if (ids[i?.id]?.filter(val => val?.id === i?.id)) {
            itemDetail = [...ids[i?.id]]
        }
        (options === "selectedItems") && (data._id = i._id);
        data.item = ((options === "selectedItems") && i?.item) || (options === "tandemDues" && "tandem balance") || (options === "videoDues" && "video balance") || (options === "overweightDues" && "overweight balance") || (options === "previousBalance" && "previous balance");
        data.qty = (options === "selectedItems") ? i?.count : 1;
        data.price = Number(i?.chargePrice || i?.overweightPrice || i?.tandemPrice || i?.videoPrice || i?.balancePrice);
        data.tax = Number(i?.chargeTaxAmount || i?.overweightTax || i?.videoTax || i?.tandemTax || i?.balanceTax);
        itemDetail.push(data);
        ids[i?.id] = [...itemDetail];
    }
    handleShowedItems = (itemDetail, i, ids) => {
        console.log('handleShowedItems');
        let data = {}
        if ((Number(i?.chargePreviousAmount) + Number(i?.chargePreviousTax) > 0.0001) || (Number(i?.newPreviousCharge?.previousAmount) + Number(i?.newPreviousCharge?.previousTax) > 0.001)) {
            data.item = "previous balance"
            data.qty = 1
            data.price = i?.newPreviousCharge ? Number(i?.newPreviousCharge?.previousAmount) : i?.chargePreviousAmount
            data.tax = i?.newPreviousCharge ? Number(i?.newPreviousCharge?.previousTax) : i?.chargePreviousTax
            itemDetail.push(data)
        }
        if ((Number(i?.chargeTandemAmount) + Number(i?.chargeTandemTax) > 0.001) || (Number(i?.newTandemPackage?.tandemPrice) + Number(i?.newTandemPackage?.tandemTax) > 0.001)) {
            data = {}
            data.item = "tandem balance"
            data.qty = 1
            data.price = i?.newTandemPackage ? Number(i?.newTandemPackage?.tandemPrice) : Number(i?.chargeTandemAmount)
            data.tax = i?.newTandemPackage ? Number(i?.newTandemPackage?.tandemTax) : Number(i?.chargeTandemTax)
            itemDetail.push(data)
        }
        if ((Number(i?.chargeVideo?.videoAmount) + Number(i?.chargeVideo?.videoTax) > 0.001) || (Number(i?.newVideoPackage?.videoAmount) + Number(i?.newVideoPackage?.videoTax)) > 0.001) {
            data = {}
            data.item = "video balance"
            data.qty = 1
            data.price = i?.newVideoPackage ? Number(i?.newVideoPackage.videoAmount) : Number(i?.chargeVideo?.videoAmount)
            data.tax = i?.newVideoPackage ? Number(i?.newVideoPackage.videoTax) : Number(i?.chargeVideo?.videoTax)
            itemDetail.push(data)
        }
        if ((Number(i?.chargeOverweightAmount) > 0.001) || (Number(i?.newOverweightDetail?.overweightPrice) > 0.001)) {
            data = {}
            data.item = "overweight balance"
            data.qty = 1
            data.price = i?.newOverweightDetail ? Number(i?.newOverweightDetail?.overweightPrice) : Number(i?.chargeOverweightAmount)
            data.tax = 0
            itemDetail.push(data)
        }
        if (i?.countersales?.length > 0 || i?.newCounterItems?.length > 0) {
            if (i?.newCounterItems) {
                i?.newCounterItems?.map(item => {
                    if (Number(item.itemPrice) + Number(item.itemTax) > 0.001) {
                        data = {}
                        data._id = item._id
                        data.item = item.item
                        data.qty = item.count
                        data.price = Number(item.itemPrice)
                        data.tax = Number(item.itemTax)
                        itemDetail.push(data)
                    }
                })
            }
            else {
                i?.chargeItem?.map(item => {
                    if (Number(item.amount) + Number(item.tax) > 0.001) {
                        data = {}
                        data._id = item._id
                        data.item = item.item
                        data.qty = item.count
                        data.price = Number(item.amount)
                        data.tax = Number(item.tax)
                        itemDetail.push(data)
                    }
                })
            }
        }
        {
            Object.keys(i).filter((key) => {
                if (i[key]?.hasOwnProperty("value") && i[key]?.hasOwnProperty("totalPaid") && i[key].value + i[key].taxAmount - i[key].totalPaid - i[key].totalTaxPaid > 0.0001) {
                    return true;
                }
            }).map(key => {
                if (i[key]?.value + i[key]?.taxAmount - i[key]?.totalPaid - i[key]?.totalTaxPaid > 0.0001) {
                    data = {}
                    data.item = key
                    data.qty = i[key]?.count
                    data.price = Number(i[key]?.newValue)
                    data.tax = Number(i[key]?.newTaxAmount)
                    itemDetail.push(data)
                }
            })
        }
        { i?.isTandemStudent ? (ids[i.studentId] = [...itemDetail]) : (ids[i.jumperId] = [...itemDetail]) }
    }

    getItemized = () => {
        let selectedItems = [...this.state.newSelectedData?.Items]
        let tandemDues = this.state.newSelectedData.tandemDues !== undefined && [...this.state?.newSelectedData?.tandemDues]
        let videoDues = this.state.newSelectedData.videoDues !== undefined && [...this.state?.newSelectedData?.videoDues]
        let overweightDues = this.state.newSelectedData.overweightDues !== undefined && [...this.state?.newSelectedData?.overweightDues]
        let previousBalance = this.state.newSelectedData.Balance !== undefined && [...this.state.newSelectedData?.Balance]

        if (selectedItems?.length > 0 || tandemDues?.length > 0 || videoDues?.length > 0 || overweightDues?.length > 0 || previousBalance?.length > 0) {
            console.log('in if');
            let ids = {}
            selectedItems !== undefined && selectedItems?.map(i => {
                this.handle_selectedItems_Dues(i, "selectedItems", ids)
            })
            previousBalance && previousBalance?.map(i => {
                this.handle_selectedItems_Dues(i, "previousBalance", ids)
            })
            tandemDues && tandemDues?.map(i => {
                this.handle_selectedItems_Dues(i, "tandemDues", ids)
            })
            videoDues && videoDues?.map(i => {
                this.handle_selectedItems_Dues(i, "videoDues", ids)
            })
            overweightDues && overweightDues?.map(i => {
                this.handle_selectedItems_Dues(i, "overweightDues", ids)
            })
            return ids;
        }
        else {
            console.log('in else get itemized');
            if (this.state.newGroupStudentData?.length > 0) {
                let ids = {}
                this.state.newGroupStudentData?.map(i => {
                    let itemDetail = []
                    if (ids[i.id]?.filter(val => val.id === i.id)) {
                        itemDetail = [...ids[i.id]]
                    }
                    this.handleShowedItems(itemDetail, i, ids)
                })
                return ids
            } else if (this.props.activity || this.state.newSingleStudentData) {
                let i = this.props.activity || this.state.newSingleStudentData
                let id = {}
                let itemDetail = []
                this.handleShowedItems(itemDetail, i, id)
                return id
            }
        }
        return {};
    }

    validateItemized(itemized) {
        //alert(JSON.stringify(itemized, null, 2));
        Object.keys(itemized).forEach(key => [
            itemized[key].forEach(item => {
                if (isNaN(Number(item.price))) {
                    return false;
                }
            })
        ])
        return true;
    }

    handleSubmit(values) {
        console.log(values, "=====>>>>>>>>>>valuesvalues")
        var values = JSON.parse(JSON.stringify(values));
        if (!values.amount) {
            values.amount = (this.state.amount) + ""
        }
        if (!values.taxAmount) {
            values.taxAmount = (this.state.taxAmount) + ""
        }
        this.setState({ isloading: true })
        var payload = {};
        var jumperId = this.props.jumperId;
        payload.itemized = this.getItemized();
        payload.amount = values.amount;
        if (!isNaN(values.taxAmount)) {
            payload.taxAmount = values.taxAmount;
        }
        else {
            payload.taxAmount = 0;
        }
        payload.isRefund = false;
        payload.transactionType = this.state.paymentType;

        if (this.state.paymentType === 'Cash') {
            payload.cashin = values.cashin
            payload.cashout = this.state.cashout
        }

        if (this.props.isTandemStudent) {
            payload.isTandemStudent = this.props.isTandemStudent;
        }

        if (values.memo !== "")
            payload.memo = values.memo
        
        let pfees = 0;
        if (window.ENV.processorInfo?.phoneCardFees && (this.state.paymentType !== "Cash" && this.state.paymentType !== "Check" && !this.state.paymentType?.includes("Payout") && !this.state.paymentType?.includes("Account"))) {
            pfees = Math.round((Number(this.state.amount) + Number(this.state.taxAmount)) * window.ENV.processorInfo?.phoneCardFees);
        }
        if (!this.validateItemized(payload.itemized)) {
            swal(
                {
                    title: "Validation error",
                    text: "please enter valid number in itemized charges",
                    icon: "error",
                }
            )
            return
        }

        if (this.state.errors.amount !== "" && Object.keys(this.state.errors).length > 0) {
            console.log(this.state.errors);
            alert('error');
            this.setState({ isloading: false });
            return;
        }
        if (values.amount < 0.00 && (this.state.paymentType === 'Cash' || this.state.paymentType === 'Credit' || this.state.paymentType === 'Debit' || this.state.paymentType === "Check" || this.state.paymentType === "Card Key In")) {
            swal(
                {
                    title: "Validation error",
                    text: "charge value must be positive",
                    icon: "error",
                }
            )
            this.setState({ isloading: false });

        }
        else if (values.amount >= 0.00 && (this.state.paymentType === "Cash Payout" || this.state.paymentType === "Check Payout" || (this.state.paymentType.indexOf("Account-") > -1))) {
            swal(

                {
                    title: "Validation error",
                    text: "payout value must be negative",
                    icon: "error",
                }
            )
            this.setState({ isloading: false });

        }
        else if (window.ENV?.terminalEnabled && (this.state.paymentType === 'Credit' || this.state.paymentType === 'Debit')) {
            if (window.ENV?.processorInfo.processor === "nab") {
                AccountStore.TerminalAuthorizeAndCapture(this.state.paymentType, values.amount)
                    .then(result => {

                        //console('printing result');
                        console.log(result);

                        var xml = new XMLParser().parseFromString(result);    // Assume xmlText contains the example XML
                        //console(xml);
                        payload.amount = values.amount;
                        payload.transactionType = this.state.paymentType;


                        const resp_text = xml.getElementsByTagName('AUTH_RESP_TEXT')[0].value;
                        if (resp_text.includes("APPROVAL") || resp_text.includes("APPROVED")) {
                            payload.transactionId = xml.getElementsByTagName('AUTH_GUID')[0].value;
                            payload.maskedCardNum = xml.getElementsByTagName('AUTH_MASKED_ACCOUNT_NBR')[0].value;
                            payload.transactionDateTime = xml.getElementsByTagName('AUTH_TRAN_DATE_GMT')[0].value;
                            return AccountStore.ChargeJumper(jumperId, payload);
                        }
                        else {
                            payload.amount = 0.00;
                            payload.transactionType = this.state.paymentType;
                            payload.memo = "DECLINED";
                            return AccountStore.ChargeJumper(jumperId, payload);
                            //console("error");
                        }
                        this.setState({ isloading: false });
                    })
                    .then(result => {
                        //console('jumper charged');
                        this.toggleModal();
                        this.setState({ isloading: false });
                    })
            }
            else {
                console.log(values.amount);
                this.setState({ isCardProcessing: true });
                var orderId = Math.floor(100000 + Math.random() * 900000);
                this.orderIdQueue.push(orderId);
                var taxAmount = 0;
                if (!isNaN(values.taxAmount)) {
                    taxAmount = values.taxAmount;
                }

                terminalAdapter.doCredit(values.amount, taxAmount, pfees, orderId.toString(), this.props.name, jumperId, payload.itemized, false, this.props.isTandemStudent)
                    .then(ret => {
                        ret.isTandemStudent = this.props.isTandemStudent;
                        if (ret.amount > 0) {
                            ret.itemized = payload.itemized;
                        }
                        //AccountStore.ChargeJumper(jumperId, ret);
                        if (this.state.isModalOpen) {
                            this.toggleModal();
                        }
                        /*
                        if (ret.memo?.toLowerCase().includes("time-out")) {
                            setTimeout(() => {
                                terminalAdapter.searchTransaction(
                                    {
                                        "order_id": {
                                            "operator": "=",
                                            "value": orderId.toString()
                                        },
                                        "amount": {
                                            "operator": "=",
                                            "value": parseInt((Number(values.amount) + Number(values.taxAmount)) * 100)
                                        }
                                    })
                                    .then(rt => {
                                        rt.isTandemStudent = this.props.isTandemStudent
                                        AccountStore.ChargeJumper(jumperId, rt);
                                        this.setState({ isloading: false, isCardProcessing: false });
                                    })
                            }, 5000);
                        }
                        else {
                            this.setState({ isloading: false, isCardProcessing: false });
                        }*/
                        this.setState({ isloading: false, isCardProcessing: false });
                    })
                    .catch(err => {
                        console.log(err);
                        if (this.state.isModalOpen) {
                            this.toggleModal();
                        }
                        this.setState({ isloading: false, isCardProcessing: false });
                    })
            }
        }
        else if (this.state.paymentType === 'Credit Manual' || this.state.paymentType === 'Military Account' || this.state.paymentType === 'Cash' || this.state.paymentType === 'Credit' || this.state.paymentType === 'Debit' || this.state.paymentType === "Check" || this.state.paymentType === "Cash Payout" || this.state.paymentType === "Check Payout") {
            AccountStore.ChargeJumper(jumperId, payload, true)
                .then(result => {
                    this.setState({ isloading: false, cashin: '', cashout: '', paymentType: '' });
                    this.toggleModal();
                    setTimeout(() => {
                        AccountStore.executeEmitter(jumperId, result);
                        this.props.clearItems && this.props.clearItems();
                        this.props.clearDues && this.props.clearDues();
                        this.props.clearBalance && this.props.clearBalance();
                    }, 100)

                });
        }
        else if (this.state.paymentType === "Card key in") {
            if (window.ENV?.processorInfo.processor === "transnational") {
                let role = localStorage.getItem("role");
                let publicKey = window.ENV?.processorInfo.publicKey;
                if (role === "school") {
                    publicKey = window.ENV?.schoolProcessorInfo?.publicKey;
                }
                const itemized = this.getItemized();
                window.transactionUI("charge", publicKey, "", itemized, values, pfees, jumperId, this.props.isTandemStudent);
                var modal = document.getElementById("myModal");
                // Get the button that opens the modal
                var btn = document.getElementById("myBtn");
                // Get the <span> element that closes the modal
                var span = document.getElementsByClassName("close")[0];
                // When the user clicks the button, open the modal
                // btn.onclick = function () {˚
                modal.style.display = "block";
                // }
                // When the user clicks on <span> (x), close the modal
                span.onclick = function () {
                    modal.style.display = "none";
                }
                window.chargeComponent = this;

                // When the user clicks anywhere outside of the modal, close it
                window.onclick = function (event) {
                    if (event.target == modal) {
                        modal.style.display = "none";
                    }
                }
            }
            else if (window.ENV?.processorInfo.processor == "cardConnect") {
                window.initializeCardConnect("charge");
                var modal = document.getElementById("myModalCardConnect");
                modal.style.display = "block";
                window.chargeComponent = this;
            }
        }
        else if (this.state.paymentType.indexOf("Dizio Payout") > -1) {
            //alert('in dizio payout');
            this.PayLoadId = jumperId;
            this.chargeMemo = values.memo;
            var dizioAch = this.props.dizioAchOnFile && this.props.dizioAchOnFile.length > 0 ? this.props.dizioAchOnFile[this.props.dizioAchOnFile.length - 1] : null;

            var payload = {
                "type": "sale",
                "currency": "USD",
                "amount": Number(values.amount.replace('-', '')) * 100,
                "payment_method": {
                    "customer": {
                        "id": dizioAch.customerId,
                        "payment_method_type": "ach",
                        "payment_method_id": dizioAch.payment_method_id
                    }
                }
            }
            var response = Api.transnationalTransaction(payload).then(this.processPaymentResponse);
        }
        else if (this.state.paymentType.indexOf("Account-") > -1) {
            var accountOnFile = this.props.accountOnFile && this.props.accountOnFile.length > 0 ? this.props.accountOnFile[this.props.accountOnFile.length - 1] : null;
            if (accountOnFile != null) {
                this.PayLoadId = jumperId;
                let payLoad = {
                    "action": "credit",
                    "authorization_amount": parseFloat(values.amount.replace('-', '')),
                    "paymethod_token": accountOnFile.paymethodToken
                }
                this.setState({
                    amount: values.amount
                })

                var response = Api.ForteAchTransaction(payLoad).then((r) => this.processPaymentResponse(r, null, values))
            }
        }
        else {
            var token = this.props.token;
            var Amount = values.amount;
            var taxAmount = values.taxAmount;
            if (token != undefined && token.length > 0) {
                this.setState({
                    paymentType: "Card On File"
                })
                if (window.ENV?.processorInfo.processor == 'transnational') {
                    this.PayLoadId = jumperId;
                    this.chargeMemo = values.memo;
                    var tokenParam = token[token.length - 1];
                    var payload = {
                        "transactionType": "Card On File",
                        "jumperId": jumperId,
                        "type": "sale",
                        "currency": "USD",
                        "amount": parseFloat(Amount) * 100,
                        "payment_method": {
                            "customer": {
                                "id": tokenParam.customerId,
                                "payment_method_type": "card",
                                "payment_method_id": tokenParam.cardId
                            }
                        },
                        "itemized": this.getItemized(),
                        "values": values,
                        "isTandemStudent": this.props.isTandemStudent
                    }
                    if (loadsStore.isTaxEnabled()) {
                        payload["amount"] += Math.round(parseFloat(taxAmount) * 100);
                        payload["tax_amount"] = Math.round(parseFloat(taxAmount) * 100);
                    }
                    if(pfees >0){
                        payload["amount"] += pfees;
                        /*payload["payment_adjustment"] = {
                            type: "flat",
                            value: pfees
                        }*/
                        payload["processingFees"] = pfees;
                    }
                    var response = Api.transnationalTransaction(payload).then(ret => this.processPaymentResponse(ret, this.getItemized(), values));
                }
                else if (window.ENV?.processorInfo.processor == 'cardConnect') {
                    this.PayLoadId = jumperId;
                    this.chargeMemo = values.memo;
                    var tokenParam = token[token.length - 1];
                    var payload = {
                        "profile": tokenParam.customerId,
                        "amount": Amount,
                        "currency": "USD",
                        "ecomind": "T"
                    }
                    var response = Api.cardConnetcAuthTransaction(payload).then(res => {
                        if (res) {
                            var captureBody = {
                                "retref": res.data.retref
                            }
                            return Api.cardConnetcCaptureTransaction(captureBody);
                        }
                        return null;
                    }).then(this.processPaymentResponse);
                }

                console.log("411 411", response)
                this.setState({ isloading: true });
            }
            else {
                this.toggleModal();
                this.setState({ isloading: false });
            }
        }

        var inputs = document.querySelectorAll('.selectedItems');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
        }
        // this.props.clearItems && this.props.clearItems()
        // this.props.clearDues && this.props.clearDues()
        // this.props.clearBalance && this.props.clearBalance()
    }


    createAuthorizeCaptureBody = (payload) => {
        var amount = "20.00"
        return {
            "$type": "AuthorizeAndCaptureTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
            SessionToken: reservationStore.getTokENV?.l(),
            ApplicationProfileId: reservationStore.getApplicationProfileId(),
            MerchantProfileId: reservationStore.getMerchantProfileId(),
            Transaction: {
                "$type": "BankcardTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
                TenderData: {
                    PaymentAccountDataToken: payload.token
                },
                TransactionData: {
                    Amount: payload.amount,
                    CurrencyCode: "USD",
                    TransactionDateTime: new Date().toISOString(),
                    CustomerPresent: "Ecommerce",
                    EmployeeId: "12345",
                    EntryMode: "Keyed",
                    OrderNumber: "12345",
                    SignatureCaptured: false,
                }
            }
        }
    }

    createCardAuthorizeCaptureBody = (tokenBody) => {
        //var amount = "17.00"
        var expiry = tokenBody.Month + tokenBody.year;


        return {
            "$type": "AuthorizeAndCaptureTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
            SessionToken: reservationStore.getTokENV?.l(),
            ApplicationProfileId: reservationStore.getApplicationProfileId(),
            MerchantProfileId: reservationStore.getMerchantProfileId(),
            Transaction: {
                "$type": "BankcardTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
                TenderData: {
                    PaymentAccountDataToken: null,
                    SecurePaymentAccountData: null,
                    CardData: {
                        CardType: tokenBody.cardType,//this.state.cardType.toString(), //visa
                        CardholderName: tokenBody.fullName,
                        PAN: tokenBody.cardNumber,//this.state.cardNumber.toString(), // 4000000000000002
                        Expire: expiry,  // 1220
                    },
                    CardSecurityData: {
                        AVSData: {
                            CardholderName: tokenBody.fullName,
                        },
                        CVDataProvided: "Provided",
                        CVData: tokenBody.Cvv,
                    }
                },
                TransactionData: {
                    Amount: tokenBody.amount,
                    CurrencyCode: "USD",
                    TransactionDateTime: new Date().toISOString(),
                    CustomerPresent: "Ecommerce",
                    EmployeeId: "12345",
                    EntryMode: "Keyed",
                    OrderNumber: "12345",
                    SignatureCaptured: false,
                }
            }
        }
    }

    processPaymentResponse = (r, itemized, values) => {
        var payload = {};
        if (!isNaN(values.taxAmount)) {
            payload.taxAmount = values.taxAmount;
        }

        if (this.state.paymentType.indexOf("Dizio Payout") > -1) {
            var jumperId = this.PayLoadId ? this.PayLoadId : this.props.jumperId;
            payload.amount = r.data.data && r.data.data.amount ? parseFloat(r.data.data.amount) / 100 : parseFloat(r.data.amount) / 100;
            if (r.data.data.tax_amount) {
                payload.taxAmount = parseFloat(r.data.data.tax_amount) / 100;
                payload.amount -= payload.taxAmount;
            }
            payload.amount = - payload.amount
            payload.transactionId = r.data.data && r.data.data.id ? r.data.data.id : r.data.id;
            payload.transactionType = this.state.paymentType;
            payload.memo = this.chargeMemo;
            payload.isPayout = true;
            payload.maskedCardNum = r.data.data && r.data.data.response_body.ach.masked_account_number ? r.data.data.response_body.ach.masked_account_number : null;
            if (this.props.isTandemStudent) {
                payload.isTandemStudent = this.props.isTandemStudent;
            }
            AccountStore.ChargeJumper(jumperId, payload);
            this.toggleModal();
            this.setState({ isloading: false });
        }
        else if (this.state.paymentType.indexOf('Account-') > -1) {
            if (r.status == 200 && r.data.response.response_code == 'A01') {
                var jumperId = this.PayLoadId;
                payload.amount = parseFloat(this.state.amount);
                payload.transactionId = r.data.transaction_id;
                payload.transactionType = this.state.paymentType;
                payload.forteAuthorizationCode = r.data.response.authorization_code;
                payload.memo = this.chargeMemo;
                payload.isPayout = true;
                AccountStore.ChargeJumper(jumperId, payload);
                this.toggleModal();
                this.setState({ isloading: false });
                swal(
                    {
                        title: "Success",
                        text: "Payout From Jumper Sucessfully Done",
                        icon: "success",
                    }
                )
            } else if(r.status === 403){
                swal({
                    title: "UNAUTHENTICATED",
                    text: "user doesn't have permissions for this action",
                    icon: "warning",
                    dangerMode: true,
                })
                this.setState({ isloading: false });

            }
            else {
                swal(
                    {
                        title: "Failed",
                        text: "Payout From Jumper Failed",
                        icon: "error",
                    }
                )
                this.setState({ isloading: false });
                console.log('check transaction response');
                console.log(r.data);
            }
        }
        else {
            if (window.ENV?.processorInfo.processor == 'nab' && r.data !== undefined && r.data.Status === "Successful") {
                var jumperId = this.PayLoadId;
                payload.amount = parseInt(this.PayLoadAmount);
                payload.taxAmount = parseInt(this.PayLoadTaxAmount);
                payload.transactionId = r.data.TransactionId;
                payload.transactionType = this.state.paymentType;
                payload.memo = this.chargeMemo;
                if (this.props.isTandemStudent) {
                    payload.isTandemStudent = this.props.isTandemStudent;
                }
                AccountStore.ChargeJumper(jumperId, payload);
                this.toggleModal();
                this.setState({ isloading: false });
            }
            else if (window.ENV?.processorInfo.processor === 'transnational' && r.data.dailyActivity) {
                console.log(r.data);
                var jumperId = this.PayLoadId ? this.PayLoadId : this.props.jumperId;
                payload.amount = r.data.chargeBody?.amount;
                if (r.data.chargeBody?.taxAmount) {
                    payload.taxAmount = parseFloat(r.data.chargeBody?.taxAmount);
                    payload.amount -= payload.taxAmount;
                }
                payload.transactionType = this.state.paymentType;

                if (itemized) {
                    payload.itemized = itemized;
                }

                if (this.props.isTandemStudent) {
                    payload.isTandemStudent = this.props.isTandemStudent;
                }

                AccountStore.processCharge(jumperId, r, payload);
                this.toggleModal();
                this.setState({ isloading: false });
            }
            else if (window.ENV?.processorInfo.processor === 'cardConnect' && r.data && r.data.resptext === "Approval" || r.resptext === "Approval") {
                var jumperId = this.PayLoadId ? this.PayLoadId : this.props.jumperId;;
                payload.amount = Number(r.data && r.data.amount ? r.data.amount : r.amount);
                payload.transactionId = r.data && r.data.retref ? r.data.retref : r.retref;
                payload.transactionType = this.state.paymentType;
                payload.memo = this.chargeMemo;

                payload.maskedCardNum = "xxxxxxxxxx" + (r && r.data && r.data.account ? r.data.account.slice(r.data.account.length - 4) : r.account.slice(r.account.length - 4));
                if (this.props.isTandemStudent) {
                    payload.isTandemStudent = this.props.isTandemStudent;
                }
                // no zipcode
                AccountStore.ChargeJumper(jumperId, payload);
                this.toggleModal();
                this.setState({ isloading: false });
            }
            else {
                console.log(r);
                alert('something failed, please try again');
                this.setState({ isloading: false, errorOpen: true });
            }
        }

    }

    handleChargeResponse = (r) => {
        const { transactionId } = this.state;

        if (r.Status === "Successful" && transactionId !== r.TransactionId) {
            var payload = {};
            var jumperId = this.PayLoadId;
            payload.amount = Number(this.PayLoadAmount);
            payload.taxAmount = Number(this.PayLoadTaxAmount);
            payload.transactionId = r.TransactionId;
            payload.transactionType = this.state.paymentType;
            payload.memo = this.chargeMemo;
            if (this.props.isTandemStudent) {
                payload.isTandemStudent = this.props.isTandemStudent;
            }
            AccountStore.ChargeJumper(jumperId, payload);
            this.toggleModal();
            this.setState({ isloading: false, transactionId: r.TransactionId });
        }
        else if (transactionId === r.TransactionId) {
            console.log('payment Done');
        }
        else {
            // swal({
            //     title: "Error",
            //     text: "Something failed, please try again",
            //     icon: "error",
            //     dangerMode: true,
            // });
            console.log('error while charge');
            this.setState({ isloading: false, errorOpen: true });
        }
    }

    renderRadio = () => {
        var options = [{ id: 1, nm: "Credit" }, { id: 2, nm: "Debit" }, { id: 3, nm: "Cash" }];

        const renderRadioGroup = options.map((item, i) => {
            return (
                <div key={item.id} onChange={(i) => this.callOptions(i)}>
                    <label className="radio-inline"><input type="radio" checked={this.state.checkedRadio == i} ref={(el) => this["myRadioRef" + i] = el} value={item.id} onChange={this.changeRadio} />{item.nm}</label>
                </div>
            );
        })
        return renderRadioGroup;
    }

    handleChangeRadio(changeEvent) {
        var currentVal = changeEvent.target.dataset.key;
        //console.log('payment type change', currentVal);
        if (currentVal === "Card key in") {
            //document.getElementById("btn_CardkeyIn").click();
            // this.setState({
            //     showCredit: true
            // })
        }
        else if (currentVal.indexOf("Account-") > -1) {
            this.setState({
                paymentType: currentVal
            })
        }
        else {
            this.setState({
                showCredit: false
            })
        }
    }

    handleValidation(name) {
        let fields = this.state;
        let errors = fields.errors;
        if (fields[name] === '') errors[name] = "This field is required";
        else if ((name === 'amount' || name === "taxAmount") && !fields[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Amount should be enter in two decimal places.";
        else errors[name] = "";
        this.setState({ errors: errors });
    }


    handleTaxAmountInput = (event, key) => {
        var cache = { ...this.state };
        cache[key] = event.target.value;
        if ((key === 'cashin' || key === 'amount') && (parseInt(cache['amount']) < parseInt(cache['cashin']))) {
            cache['cashout'] = stringify(Number(cache.cashin) - Number(cache.amount) - Number(cache.taxAmount)) > 0 ? stringify(Number(cache.cashin) - Number(cache.amount) - Number(cache.taxAmount)) : 0
        } else {
            cache['cashout'] = 0
        }
        this.setState({ ...cache }, () => {
            ['cashin', 'cashout'].includes.key && this.handleValidation(key);
            this.setState({ totalAmount: this.state.amount + this.state.taxAmount })
        });
    };


    toggleError() {
        this.setState({
            errorOpen: !this.state.errorOpen
        });
    }

    generateYears = () => {
        var currYear = new Date().getFullYear();
        var yearsArray = [];
        for (let i = 0; i < 20; i++) {
            yearsArray.push(currYear + i);
        }
        return yearsArray;
    }

    handleInput = (event, key) => {
        var data = {};
        if (key === 'cardNumber' && event.target.value % 1 !== 0) {
            data["cardType"] = '';
            this.setState(data);
            return; // since we dont have numeric value
        }

        var cardnumber = event.target.value;
        var cardType = creditCardType(cardnumber);

        // this.cardDigit= event.target.value;
        if (cardnumber != '' && cardType.length > 0) {
            if (cardType.length > 0) {
                if (cardType[0].type == 'american-express') {
                    data["cardType"] = 'AmericanExpress';
                    data["cardIcon"] = 'amex';
                }
                else {
                    data["cardType"] = cardType[0].type;
                    data["cardIcon"] = 'amex';
                }
                if (cardType[0].type == 'visa') {
                    data["cardIcon"] = 'visa';
                }
                if (cardType[0].type == 'mastercard') {
                    data["cardIcon"] = 'mastercard';
                }
                if (cardType[0].type == 'discover') {
                    data["cardIcon"] = 'discover';
                }
            }
        }
        else {
            data["cardType"] = '';
        }

        data[key] = event.target.value;
        this.setState(data);
    };

    handleUpdateTotalAmountGroupData = (items) => {
        let amount = 0;
        let tax = 0;
        items.forEach(item => {
            if (item?.chargeTandemAmount && ((Number(item.chargeTandemAmount) + Number(item.chargeTandemTax)) > 0.001)) {
                amount += Number(item.chargeTandemAmount)
            }
            if (item?.chargeOverweightAmount && ((Number(item.chargeOverweightAmount) + Number(item.chargeOverweightTax)) > 0.001)) {
                amount += Number(item.chargeOverweightAmount)
            }
            if (item?.chargeVideo?.videoAmount && ((Number(item?.chargeVideo.videoAmount) + Number(item?.chargeVideo.videoTax)) > 0.001)) {
                amount += Number(item.chargeVideo.videoAmount)
            }
            if (item?.chargePreviousAmount && ((Number(item.chargePreviousAmount) + Number(item.chargePreviousTax)) > 0.001)) {
                amount += Number(item.chargePreviousAmount)
            }
            if (item?.chargeItem.length > 0) {
                item.chargeItem.forEach(item => {
                    amount += Number(item.amount);
                })
            }
        })
        items.forEach(item => {
            if (item?.chargeTandemTax && ((Number(item?.chargeTandemAmount) + Number(item?.chargeTandemTax)) > 0.001)) {
                tax += Number(item.chargeTandemTax)
            }
            if (item?.chargeOverweightTax && ((Number(item.chargeOverweightAmount) + Number(item.chargeOverweightTax)) > 0.001)) {
                tax += Number(item.chargeOverweightTax)
            }
            if (item?.chargeVideo?.hasOwnProperty("videoTax") && ((Number(item?.chargeVideo.videoAmount) + Number(item?.chargeVideo.videoTax)) > 0.001)) {
                tax += Number(item.chargeVideo.videoTax)
            }
            if (item?.chargePreviousTax && ((Number(item.chargePreviousAmount) + Number(item.chargePreviousTax)) > 0.001)) {
                tax += Number(item.chargePreviousTax)
            }
            if (item?.chargeItem.length > 0) {
                item.chargeItem.forEach(item => {
                    tax += Number(item.tax);
                })
            }
        })
        this.setState({ amount: (amount).toFixed(2), taxAmount: (tax).toFixed(2) })
    }

    handleUpdateTotalAmountTaxSingleData = (items, key) => {
        const student_packages = itemStore.getStudentPackages();
        let activityObj = this.props.activityObj;
        let available_aff_packs = [];
        student_packages?.forEach(eachPack => {
            if (activityObj.hasOwnProperty(eachPack.name)) {
                let key = eachPack.name;
                if (activityObj[key].hasOwnProperty("count")) {
                    available_aff_packs.push(key)
                }
            }
        })
        let amount = 0;
        let tax = 0;
        if (items?.newTandemPackage && (Number(items?.newTandemPackage?.tandemPrice) > 0.001)) {
            amount += Number(items.newTandemPackage.tandemPrice)
            tax += Number(items.newTandemPackage.tandemTax)
        }
        if (items?.newVideoPackage && (Number(items?.newVideoPackage?.videoAmount) > 0.001)) {
            amount += Number(items?.newVideoPackage?.videoAmount)
            tax += Number(items?.newVideoPackage?.videoTax)
        }
        if (items?.newCounterItems?.length > 0) {
            items?.newCounterItems?.forEach(item => {
                amount += Number(item.itemPrice)
                tax += Number(item.itemTax)
            })
        }
        if (items?.newPreviousCharge && (Number(items?.newPreviousCharge?.previousAmount) + Number(items?.newPreviousCharge?.previousTax) > 0.001)) {
            amount += Number(items?.newPreviousCharge?.previousAmount)
            tax += Number(items?.newPreviousCharge?.previousTax)
        }
        if (items?.isTandemStudent) {
            if (items.newOverweightDetail && !isNaN(items.newOverweightDetail.overweightPrice) && (items?.newOverweightDetail?.overweightPrice > 0.001)) {
                amount += Number(items.newOverweightDetail.overweightPrice)
                tax += Number(items.newOverweightDetail.overweightTax)
            }
        }
        {
            Object.keys(items).filter((key) => {
                if (items[key]?.hasOwnProperty("value") && items[key]?.hasOwnProperty("totalPaid") && items[key].value + items[key].taxAmount - items[key].totalPaid - items[key].totalTaxPaid > 0.0001) {
                    return true;
                }
            }).map(key => {
                if (items[key]?.value + items[key]?.taxAmount - items[key]?.totalPaid - items[key]?.totalTaxPaid > 0.0001) {
                    amount += Number(items[key]?.newValue)
                    tax += Number(items[key]?.newTaxAmount)
                }
            })
        }
        this.setState({ amount: (amount).toFixed(2), taxAmount: (tax).toFixed(2) })
    }

    handleSelectedData = (data) => {
        let amount = 0;
        let tax = 0;
        if (data?.Items && data?.Items.length > 0) {
            data.Items.forEach(item => {
                amount += Number(item?.chargePrice)
                tax += Number(item?.chargeTaxAmount)
            })
        }
        if (data?.overweightDues && data?.overweightDues.length > 0) {
            data.overweightDues.forEach(item => {
                amount += Number(item?.overweightPrice)
                tax += Number(item?.overweightTax)
            })
        }
        if (data?.tandemDues && data?.tandemDues.length > 0) {
            data.tandemDues.forEach(item => {
                amount += Number(item?.tandemPrice)
                tax += Number(item?.tandemTax)
            })
        }
        if (data?.videoDues && data?.videoDues.length > 0) {
            data.videoDues.forEach(item => {
                amount += Number(item?.videoPrice)
                tax += Number(item?.videoTax)
            })
        }
        if (data?.Balance && data?.Balance.length > 0) {
            data.Balance.forEach(item => {
                amount += Number(item?.balancePrice)
                tax += Number(item?.balanceTax)
            })
        }
        this.setState({ amount: (amount).toFixed(2), taxAmount: (tax).toFixed(2) })
    }

    render() {
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
      ` ;

        var token = this.props.token;
        var accountOnFile = this.props.accountOnFile;
        var dizioAchOnFile = this.props.dizioAchOnFile

        var cardNumber = '';
        if (token != undefined && token != null && token.length > 0 && token[token.length - 1] !== null) {
            var card = token[token.length - 1].cardNumLast4;
            cardNumber = card;
        }

        var accountNumber = '';
        if (accountOnFile != undefined && accountOnFile.length > 0) {
            var account = accountOnFile[accountOnFile.length - 1].AccountNumber;
            accountNumber = "ACH Payout - Account-" + account;
        }

        var dizioAccountNumber = '';
        if (dizioAchOnFile != undefined && dizioAchOnFile.length > 0) {
            var account = dizioAchOnFile[dizioAchOnFile.length - 1].accountNumber;
            dizioAccountNumber = "Dizio Payout - Account-" + account;
        }

        var paymentType = [];
        if (cardNumber != '') {
            paymentType = ["Credit", cardNumber, "Cash", "Check", "Card key in", "Cash Payout", "Check Payout"];
        }
        else {
            paymentType = ["Credit", "Cash", "Check", "Card key in", "Cash Payout", "Check Payout"];
        }

        if (window.ENV?.arizona) {
            paymentType.unshift("Military Account");
        }
        if (window.ENV?.terminalEnabled) {
            paymentType.splice(paymentType.length - 2, 0, "Credit Manual");
        }
        if (accountNumber != '') {
            paymentType.push(accountNumber);
        }
        if (dizioAccountNumber !== '') {
            paymentType.push(dizioAccountNumber);
        }

        let itemsCount = 0
        let taxTotal = 0

        if (this.props.selectedItems?.length > 0) {
            this.props.selectedItems.map(i => {
                let count = i.price
                let tax = i.taxAmount
                itemsCount += count || 0
                taxTotal += tax || 0
            })
        }

        console.log('in chargeJumper amount', this.state.amount)
        console.log('in chargeJumper tax', this.state.taxAmount);

        let pfees = 0;
        if (window.ENV.processorInfo?.phoneCardFees && (this.state.paymentType !== "Cash" && this.state.paymentType !== "Check" && !this.state.paymentType?.includes("Payout") && !this.state.paymentType?.includes("Account"))) {
            pfees = (Number(this.state.amount) + Number(this.state.taxAmount)) * window.ENV.processorInfo?.phoneCardFees / 100;
        }
        return (
            <React.Fragment>
                <ItemModal isModalOpen={this.state.isModalOpen} title={`Charge Jumper`}>
                    <DonutLoader isLoading={this.state.isloading} />
                    <span id="btn_CardkeyIn" style={{ display: 'none' }}>
                        <NabVelocity
                            amount={1}
                            terminalProfileId={loadsStore.getNabHostedFormTerminalId()}
                            onProcessPayment={this.handleChargeResponse}
                            text="Card"
                        />
                    </span>
                    <ItemsDetails
                        studentDetails={this.props.activityObj?.tandemStudentActivities}
                        selectedItems={this.props.selectedItems}
                        selectedDues={this.props.selectedDues}
                        selectedBalance={this.props.selectedBalance}
                        activityData={this.props.activityObj}
                        checkForNegatives={this.checkForNegatives}
                        handleUpdateTotalAmountGroupData={this.handleUpdateTotalAmountGroupData}
                        handleSelectedData={this.handleSelectedData}
                        handleUpdateTotalAmountTaxSingleData={this.handleUpdateTotalAmountTaxSingleData}
                        handleUpdateSingleAmountData
                        setState={this.setState.bind(this)}
                        handleGroupedStudentData={this.handleGroupedStudentData}
                        handleSelectedItems={this.handleSelectedItems}
                        handleSingleStudentData={this.handleSingleStudentData}
                    />
                    {console.log('in chargeJumper amount', this.state.amount)}
                    <LocalForm onSubmitFailed={(err) => console.log(err, "<<<<<<<errrrrrrr")} onSubmit={(values) => this.handleSubmit(values)}>
                        <ChargeModalOptions
                            chargeAmount={this.state.amount}
                            chargeTax={this.state.taxAmount}
                            chargePFees={pfees}
                            handleChargeTaxAmount={this.handleTaxAmountInput}
                            chargeChangeRadio={this.handleChangeRadio}
                            chargePaymentType={this.state.paymentType}
                            chargeCashIn={this.state.cashin}
                            chargeCashOut={this.state.cashout}
                            chargeShowCredit={this.state.showCredit}
                            chargeInput={this.handleInput}
                            chargeMonthSelect={this.handleMonthSelect}
                            chargeMonths={this.months}
                            chargeYearSelect={this.handleYearSelect}
                            chargeGenerateYears={this.generateYears}
                            chargeCardIcon={this.state.cardIcon}
                            chargeTypePayment={paymentType}
                            chargeCardType={this.state.cardType}
                            chargeSelected={this.state.isSelected}
                            setState={this.setState.bind(this)}
                            chargeLoading={this.state.isloading}
                            handleChargeResponse={this.handleChargeResponse}
                            chargeCardProcessing={this.state.isCardProcessing}
                            chargeToggleModal={this.toggleModal}
                        />
                    </LocalForm>
                </ItemModal>
                <button
                    className={`btn ${this.props.tandemStudentButton ? 'chargeTandemBtn' : 'customButton newBtn rightButton'}`}
                    onClick={this.toggleModal}
                >
                    CHARGE/PAY JUMPER
                </button>
            </React.Fragment>
        );
    }
}

export default memo(ChargeJumper);