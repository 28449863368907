import React from 'react'
import QRCode from "react-qr-code";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import './index.css'

export class GeneratePdf extends React.Component {

    constructor(props) {
        super(props)

    }

    componentDidMount() {
        console.log(this.props, 'proooppppssss')

        setTimeout(() => {

            let base64Array = []
            let pageCount = Math.ceil(this.props.array.length / 6) || 1;

            console.log(pageCount, "pageCount pageCount pageCount")

            for (let index = 1; index <= pageCount; index++) {

                console.log(index, `page+${index}`, "index index")

                let element = document.getElementById('page' + index)
                if (element) {

                    console.log(element, "element element element")
                    html2canvas(element).then(function (canvas) {
                        console.log(canvas.toDataURL("image/jpeg"), "base64 urllll + ", index)
                        base64Array.push(canvas.toDataURL("image/jpeg"))
                        console.log(base64Array.length, pageCount, "yfgchjkujgh")
                        // this.props.getBase64Array(base64Array)


                        if (base64Array.length == pageCount) {
                            let doc = new jsPDF({
                                orientation: 'p',
                                unit: 'in',
                                format: [8.5, 11]
                            });

                            let width = doc.internal.pageSize.getWidth();
                            let height = doc.internal.pageSize.getHeight();

                            base64Array.map((val, index) => {
                                console.log(val, "val valvalval")
                                doc.addImage(val, 'JPEG', 0, 0, width, height);
                                if (index == base64Array.length - 1) {
                                    doc.save("a4.pdf");
                                } else {
                                    doc.addPage();
                                }
                            })
                        }
                    });
                }
            }
            this.props.stopLoading()
        }, 300);

    }

    renderItems() {
        let perPage = 6;
        let filteredArray = {}
        if (this.props.array.length) {

            this.props.array.forEach((item, index) => {
                if (index) {
                    index += 1;
                }
                let currentPage = Math.ceil(index / perPage) || 1;
                if (filteredArray['page' + currentPage]) {
                    filteredArray['page' + currentPage].push(item)
                } else {
                    filteredArray['page' + currentPage] = [item]
                }
            })
            console.log(filteredArray, 'filteredArray')
            return (this.renderAllPages(filteredArray))

        }
    }

    renderAllPages(filteredArray) {
        console.log(filteredArray, 'filteredArray')
        if (Object.keys(filteredArray).length) {
            return Object.keys(filteredArray).map((key, i) => {
                console.log(filteredArray[key], "filteredArray[key]")
                return <div id={key} key={i} className="pageContainer">
                    <div className="mainPage">
                        {
                            filteredArray[key].map((val, index) => {
                                // let qrstring = JSON.stringify({ 
                                //     'Item': val.item, 
                                //     'Rig Number': val.rigNumber,
                                //     'Main' : val.main,
                                //     'Main size': val.mainSize,
                                //     'Reserve':val.reserve,
                                //     'reserve serial':val.reserveSerial,
                                //     'Reserve repack date':val.repackDate,
                                //     'Aad serial': val.aadSerial
                                // });
                                let qrstring = `${window.ENV?.jumperUrl}/rigs?id=${val._id}`
                                return <div className="qrCode" key={index}>
                                    <div><QRCode value={qrstring} size={150} />
                                    </div>
                                    <div className="mainContainer">
                                        <div className="qrContainer">
                                            <p> Rig name : {val.item}</p>
                                        </div>
                                        <div className="qrContainer">
                                            <p> Main : {val.main}</p>
                                        </div>
                                        <div className="qrContainer">
                                            <p> Reserve : {val.reserve}</p>
                                        </div>
                                        <div className="qrContainer">
                                            <p> Color : {val && val.color ? val.color : 'Red'}</p>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            })
        }
    }


    render() {
        console.log('eeeeee')
        return (
            this.renderItems()

        )
    }

}