/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Button, Modal, ModalHeader, ModalBody, Col, Row, Label, ModalFooter
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

import loadsStore from '../../../stores/loads-store';
import jumpersStore from '../../../stores/jumpers-store';
import JmpModal from './customModal';
import axios from 'axios';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';

class CancelLoad extends Component {

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isModalOpen: false,
      isloading: false
    }
    this.loadEvents = [
      { name: loadsStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
    ];
  }

  componentWillMount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
  }

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
  }

  handleNewActivity = (activityString) => {
    if (activityString) {
      this.setState({
        isModalOpen: false,
        isloading: false,
      })
    }
  }


  componentDidMount = () => {
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  handleSubmit(loadNum) {
    this.setState({
      isloading: true
    })
    let handleSlides = {
      el: document.getElementsByClassName("load-" + loadNum)[0],
      nEl: document.getElementsByClassName("load-" + loadNum)[0].nextSibling,
      flag: false
    }
    if (handleSlides.el && handleSlides.nEl && handleSlides.nEl.classList[0] == 'col') handleSlides.flag = true;

    loadsStore.cancelLoad(loadNum).then(result => {
      if (handleSlides.flag && result.length > 2) document.getElementsByClassName("carousel-back-btn")[0].click();
    });
  }

  render() {
    const { isloading, isModalOpen } = this.state;
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    return (
      <React.Fragment>
        <JmpModal
          isModalOpen={isModalOpen}
          title={`Confirm Action:`}
        >
          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={isloading}
            />
          </div>
          {!isloading ? <span>Confirm Cancel Load? </span> : ''}
          <Row className="form-group m-t-1-rem">
            <Col md={{ size: 10 }}>
              <button className="btn btn-bg lightColor createButton" type="submit" onClick={() => this.handleSubmit(this.props.loadNum)} >Yes</button>
              <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">No</button>
            </Col>
          </Row>
        </JmpModal>
        {/* <Button style={{ padding: '0px', background: 'none', border: 'none' }} onClick={this.toggleModal}><i className="fa fa-close"></i></Button> */}

        <span onClick={this.toggleModal} id={"cancel-load" + this.props.loadNum}></span>

      </React.Fragment>
    );
  }
}

export default CancelLoad
