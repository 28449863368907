
/**
 * http://usejsdoc.org/
 */
import React, { Component, Fragment } from 'react';

import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';

import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';

import scheduleStore from '../../stores/schedule-store';
import { slideDown, slideUp } from '../animation/anim';

class ReservationDetailsModal extends Component {

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpen: false,
      errors: '',
      amount: '',
      newJumperId: '',
      data: [],
      headers: [],
      summary: {},
      Isdata: false,
      isloading: false,
      rowIndex: -1,
      expanded: false
    }
    this.scheduleEvents = [
      {
        name: scheduleStore.getEventPrefix("reservations"),
        func: this.handleGetReservationsEvent
      }
    ];
  }

  componentDidMount = () => {
    this.scheduleEvents.forEach((evnt) => scheduleStore.on(evnt.name, evnt.func));
  }

  componentWillUnmount = () => {
    this.scheduleEvents.forEach((evnt) => scheduleStore.removeListener(evnt.name, evnt.func));
  }

  handleReportDetails = (report) => {
    this.setState({ data: report.data, headers: report.headers, summary: report.summary, Isdata: true });
    setTimeout(() => {
      this.setState({
        isloading: false
      })
    }, 3000)
  }

  toggleModal(e) {
    const { jumperId } = this.props
    scheduleStore.getLatestReservations();
    this.setState({ isModalOpen: !this.state.isModalOpen })
    // reportStore.GetJumperLogs(startDate, endDate, jumperId)
    console.log("Api call", this.props.reportType)
  }

  handleGetReservationsEvent = (data) => {
    console.log(data);
    this.setState({ data: data });
  }

  componentWillReceiveProps(props) {
    // console.log("JumperId", this.props.jumperId);
    // const { jumperId } = this.props

    // if (jumperId) {
    //   this.setState({
    //     isloading: true,
    //     jumperId: jumperId,
    //     Isdata: false
    //   })
    // }
  }

  checkForNegatives = (price) => {

    let priceCell = (price < 0) ?
      {
        value: `($${Math.abs(price).toFixed(2)})`
      } :
      {
        value: `$${price.toFixed(2)}`
      }

    if (price < 0) {
      return <span style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
    }
    return priceCell.value;
  }


  // filterData = () => {
  //   let filterDataArray = [];
  //   let filterOutput = [];
  //   let jumperArray = [];

  //   if (this.state.Isdata === true) {
  //     this.filterDataArray = this.state.filterData;

  //     for (var item in this.filterDataArray) {
  //       filterOutput = [];
  //       var logArray = this.filterDataArray[item];
  //       for (var items in logArray) {
  //         var desc = logArray[items];
  //         let JumperInfo = [];

  //         if (desc.jumperIds !== undefined) {
  //           var otherJumper = desc.jumperIds;
  //           for (var oitem in otherJumper) {
  //             var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
  //             JumperInfo.push({ jumperName: name })
  //           }
  //         }

  //         var date = new Date(desc.createdAt);
  //         var localDate = '';
  //         var type = '';
  //         if (desc.type != undefined) {
  //           if (desc.type === "aff main") {
  //             type = desc.type + ' ' + desc.level;
  //           }
  //           else {
  //             type = desc.type;
  //           }
  //         }
  //         else {
  //           type = desc.jumpType;
  //         }

  //         if (desc.createdAt !== undefined) {
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }
  //         else {
  //           var date = new Date(desc.date);
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }

  //         filterOutput.push({ id: desc._id, type: type, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo })

  //       }
  //       if (logArray.length > 0) {
  //         jumperArray.push({ label: item, jumperStatement: filterOutput })
  //       }
  //     }
  //   }
  //   return jumperArray;
  // }

  formatDate(input) {
    if (input.toString().length == 1) {
      return '0' + input;
    }
    else {
      return input;
    }
  }
  ConvertDate(inputDate) {
    if (inputDate.toString().length > 0) {
      var d = new Date(inputDate);

      var localTime = d.toLocaleTimeString();

      // var localDate = new Date(d.toLocaleDateString()),
      //   month = '' + (localDate.getMonth() + 1),
      //   day = '' + localDate.getDate(),
      //   year = localDate.getFullYear();

      var datestring = d.toLocaleDateString();
      var dateParts = datestring.split("/");
      var dateObject = new Date(dateParts[2], dateParts[1] - 1, +dateParts[0]),
        month = '' + (dateObject.getMonth() + 1),
        day = '' + dateObject.getDate(),
        year = dateObject.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-') + ' ' + localTime;
    }
    else {
      return inputDate;
    }
  }

  onToggle = (item, val) => {

  }

  onToggleClick = (index) => {
    this.setState({
      rowIndex: index
    })
  }

  ToggleExpander = (e, index) => {
    if (e.target.type === 'checkbox') return;
    this.setState({
      rowIndex: index
    })
    if (!this.state.expanded) {
      this.setState(
        { expanded: true },
        () => {
          if (this.refs.expanderBody) {
            slideDown(this.refs.expanderBody);
          }
        }
      );
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => { this.setState({ expanded: false }); }
      });
    }
  }

  renderStudentData = (students) => {
    return students.map((item, index) => {
      return (<Fragment>
        <div><b>Full Name:-</b>{item.firstname} {item.lastname}</div>
        <div><b>Email:-</b>{item.email}</div>
      </Fragment>)
    })
  }

  renderData = () => {
    let { classes } = this.props;
    return this.state.data.map((item, index) => {
      var madeDate = new Date(item.createdAt);
      var resDate = item.tandemStudents && item.tandemStudents.length > 0 ? new Date(item.tandemStudents[0].reservationTime) : new Date(item.reservationTime);
      var offsetInHours = resDate.getTimezoneOffset() / 60;
      console.log(offsetInHours);
      resDate.setHours(resDate.getHours() + offsetInHours);
      const discount = index.discount ? <tr><td>Discount:</td><td> -${index.discountValue.toFixed(2)}</td></tr> : null;

      return (
        <Fragment>
          <tr key={`jumperlogsrow${index}`} onClick={(e) => this.ToggleExpander(e, index)}>

            <td>{madeDate.toLocaleDateString()} {madeDate.toLocaleTimeString()}</td>
            <td>{resDate.toLocaleDateString()} {resDate.toLocaleTimeString()}</td>
            {
              item.tandemStudents.length > 0 &&
              <React.Fragment>
                <td>{item.tandemStudents[0].firstname + " " + item.tandemStudents[0].lastname}</td>
                <td>{item.tandemStudents[0].phone}</td>
                <td>{item.tandemStudents[0].email}</td>
                <td>${item.tandemStudents[0].totalPaid}</td>
                <td><input type="checkbox" id={item.tandemStudents[0]._id} name={item.tandemStudents[0]._id} onChange={(val) => this.onToggle(item, val)} /></td>
              </React.Fragment>
            }
            {
              item.students.length > 0 &&
              <React.Fragment>
                <td>{item.students[0].firstname + " " + item.students[0].lastname}</td>
                <td>{item.students[0].phone}</td>
                <td>{item.students[0].email}</td>
                <td>${item.students[0].totalPaid ? item.students[0].totalPaid : item.jumpsPrice}</td>
                <td><input type="checkbox" id={item.students[0]._id} name={item.students[0]._id} onChange={(val) => this.onToggle(item, val)} /></td>
              </React.Fragment>
            }
            {/* <td>{item.tandemStudents[0].firstname + " " + item.tandemStudents[0].lastname}</td>
            <td>{item.tandemStudents[0].phone}</td>
            <td>{item.tandemStudents[0].email}</td>
            <td>${item.tandemStudents[0].totalPaid}</td>
            <td><input type="checkbox" id={item.tandemStudents[0]._id} name={item.tandemStudents[0]._id} onChange={(val) => this.onToggle(item, val)} /></td> */}
          </tr>
          {
            this.state.rowIndex === index && this.state.expanded && (
              <tr className="expandable" key="tr-expander">
                <td className="uk-background-muted" colSpan={6}>
                  <div ref="expanderBody" className="inner uk-grid">
                    <div><h5>Invoice:</h5></div>
                    <table className="table" style={{ backgroundColor: "transparent" }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th style={{ textAlign: 'center' }}>Count</th>
                          <th style={{ textAlign: 'right' }}>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          item.invoice.map(pkg => {
                            return (
                              <tr>
                                <td>{pkg.item}</td>
                                <td style={{ textAlign: 'center' }}>{pkg.count}</td>
                                <td style={{ textAlign: 'right' }}>${(pkg.value).toFixed(2)}</td>
                              </tr>
                            )
                          })

                        }
                        <tr>
                          <td>Fees:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${item.totalFees.toFixed(2)}</td>
                        </tr>
                        {discount}
                        <tr>
                          <td>Total:</td><td style={{ textAlign: 'center' }}></td><td style={{ textAlign: 'right' }}>${(item.totalValue + item.totalFees).toFixed(2)}</td>
                        </tr>

                      </tbody>
                    </table>
                    {item.site && <div className="reserved-list">site: {item.site}</div>}
                    {item.maskedCardNum && <div className="reserved-list">Masked card: {item.maskedCardNum}</div>}
                    <div><h5>Student:</h5></div>
                    {
                      item.tandemStudents.length > 0 &&
                      this.renderStudentData(item.tandemStudents)

                    }
                    {
                      item.students.length > 0 &&
                      this.renderStudentData(item.students)
                    }
                  </div>
                </td>
              </tr>
            )
          }
        </Fragment>
      )
    })
  }

  renderSummary = () => {
    return (
      <Fragment>
        <tr>
          {
            Object.keys(this.state.summary).map(key => {
              if (key.toLowerCase().includes("credit") || key.toLowerCase().includes("debit") || key.toLowerCase().includes("paid") || key.toLowerCase().includes("amount"))
                return <Fragment><td>{key}</td><td> {this.checkForNegatives(this.state.summary[key])} </td></Fragment>
              return <Fragment><td>{key}</td><td>{this.state.summary[key]}</td></Fragment>
            })
          }
        </tr>
      </Fragment>
    )
  }

  render() {
    const { Isdata, filterData, isloading } = this.state;

    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <React.Fragment>
        <Modal id="modal-report" aria-labelledby="example-custom-modal-styling-title"
          isOpen={this.state.isModalOpen} style={{ zIndex: '10503434', right: '28%' }}>
          <ModalHeader tag="div" style={{ position: 'relative' }}>
            <h5>View Reservations</h5>
            <button className="close" onClick={(e) => { e.preventDefault(); this.toggleModal(); }} style={{ position: 'absolute', left: '17%', top: '14px', color: 'white' }}>x</button>
          </ModalHeader>
          <ModalBody>

            <div className='complete-loading'>
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={35}
                color={'#fff'}
                loading={this.state.isloading}
              />
            </div>

            {
              this.state.isloading ?
                <span style={{ color: 'white', fontSize: '20px', marginLeft: '35% ' }}>Please Wait...</span>
                :
                <Row className="form-group">
                  <Col md={12}>
                    <Table striped style={{ borderTopStyle: "hidden" }}>
                      <thead>
                        <tr>
                          <th>Date made</th>
                          <th>Reservaton Date</th>
                          <th>Name</th>
                          <th>phone</th>
                          <th>email</th>
                          <th>paid</th>
                          <th>action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderData()}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
            }
          </ModalBody>
        </Modal>


        <button className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }} onClick={(e) => this.toggleModal(e)}>
          VIEW RESERVATIONS
        </button>

      </React.Fragment>
    );
  }
}

export default ReservationDetailsModal



