import { Component, createElement } from "react";
import PropTypes from "prop-types";

class NabVelocityCheckout extends Component {
  constructor(props) {
    super(props);
    this.processPayment = this.processPayment.bind(this);
  }
  processPayment(response) {
    this.props.onProcessPayment(response);
  }

  componentWillMount() {
    window.processPayment = this.processPayment;
    const script = document.createElement("script");
    script.onload = function () {
      if (window.Velocity) {
        window.Velocity.autoinit();
      }
    };
    // script.src = "https://api.cert.nabcommerce.com/1.3/button.js";
    script.src = window.ENV?.processorInfo.payformUrl;
    document.head.appendChild(script);
  }

  componentWillUnmount() {
    delete window.processPayment;
    window.callback_function = null;
  }

  render() {
    const {
      component,
      terminalProfileId,
      amount,
      invoiceNum,
      description,
      email,
      billingName,
      billingAddress,
      billingCity,
      billingState,
      billingPostalCode,
      text,
      onProcessPayment,
      ...rest
    } = this.props;
    const Component = createElement(
      'button',
      {
        className: "velocity-button",
        "data-terminal-profile-id": terminalProfileId,
        "data-amount": amount,
        "data-invoice-num": invoiceNum,
        "data-description": description,
        "data-email": email,
        "data-billing-name": billingName,
        "data-billing-address": billingAddress,
        "data-billing-city": billingCity,
        "data-billing-state": billingState,
        "data-billing-postal-code": billingPostalCode,
        "data-callback-function": "processPayment",
        ...rest
      },
      text
    );
    return Component;
  }
}

// NabVelocityCheckout.defaultProps = {
//   text: "Pay Now",
//   component: "button"
// };

// NabVelocityCheckout.displayName = "NabVelocityCheckout";

// NabVelocityCheckout.propTypes = {
//   //component: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
//   onProcessPayment: PropTypes.func.isRequired,
//   //text: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
//   terminalProfileId: PropTypes.string.isRequired,
//   amount: PropTypes.number.isRequired,
//   //invoiceNum: PropTypes.string.isRequired,
//   //description: PropTypes.string,
//   //email: PropTypes.string,
//   //billingName: PropTypes.string,
//   //billingAddress: PropTypes.string,
//   //billingCity: PropTypes.string,
//   //billingState: PropTypes.string,
//   //billingPostalCode: PropTypes.string
// };

export default NabVelocityCheckout;
