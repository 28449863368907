import React, { Component } from 'react'
import { Table } from 'reactstrap'
import moment from 'moment';

import CreateDiscountCode from '../modals/createDiscountCode';
import RemoveItem from '../modals/removeItem';


export default class DiscountCodes extends Component {
    renderDiscounts = () => {
        let { discounts, categories } = this.props;

        if (discounts != undefined) {
            return discounts.map((item, index) => {
                item.category = 'discounts';
                return (
                    <tr key={`discountRow${index}`}>
                        <th key={`discountCell1${index}`} scope="row" data-id={item._id}>{index + 1}</th>
                        <td key={`discountCell2${index}`}>{item.name}</td>
                        <td key={`discountCell3${index}`}>{item.code}</td>
                        <td key={`discountCell4${index}`} style={{ color: 'red' }}>(${Math.abs(item.amount).toFixed(2)})</td>
                        <td key={`discountCell5${index}`}>{moment(item.startDate).format('MM/DD/YYYY')}</td>
                        <td key={`discountCell6${index}`}>{moment(item.endDate).format('MM/DD/YYYY')}</td>
                        <td key={`discountCell7${index}`}>{item.quantity}</td>
                        <td key={`discountCell8${index}`}>{item.description}</td>
                        <td key={`discountCell9${index}`}>
                            <CreateDiscountCode isEdit={true} item={item} categories={categories} />&nbsp;&nbsp;
                            <RemoveItem item={item} />
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    render() {
        let { categories } = this.props;
        return (
            <Table>
                <thead className="main-heading">
                    <tr>
                        <th><CreateDiscountCode categories={categories} /></th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Amount</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Quanity</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="priceHeading">
                        <td colSpan="3">Discount Codes</td>
                    </tr>
                    {/* {this.renderDiscounts(this.state.discounts, 'discounts')} */}
                    {this.renderDiscounts()}

                </tbody>
            </Table>
        )
    }
}
