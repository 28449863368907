
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';

class JmpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      jumpType: null,
      affLevel: null,
      load: props.load,
      isCalender: this.props.isCalender
    };
    this.gearIds = [];
  }

  render() {
    return (
      <React.Fragment>
        <Modal id="modal-main" isOpen={this.props.isModalOpen} >
          <ModalHeader tag="div" style={{ width: '100%' }}>
            <h5> {this.props.title} </h5>
            <div>
              {
                this.props.isComplete
                // &&
                // <button className="btn lightBlueButton" onClick={() => this.setState({ isCalender: true })}>Change Date</button>
              }
              {
                this.state.isCalender && this.props.isComplete &&
                <div style={{ position: 'relative' }}>
                  <SingleDatePicker
                    date={this.state.date}
                    id="your_unique_start_date_id"
                    onDateChange={date => {
                      let startDate = moment(date).format("YYYY-MM-DD");
                      this.setState({
                        date: date
                      })
                      this.props.onDateChange(startDate)
                    }}
                    focused={this.state.focused}
                    isOutsideRange={() => false}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    isOutsideRange={(day) => day.isAfter(moment())}
                  />
                  <button className="btn lightBlueButton" style={{ position: 'absolute', left: '75%' }} onClick={() => { this.setState({ isCalender: false }); this.props.onDateChange(null) }}>x</button>
                </div>
              }
            </div>
          </ModalHeader>

          <ModalBody>
            {this.props.children}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default JmpModal