
/**
 * http://usejsdoc.org/
 */
import React, { Component, Fragment } from 'react';
import {
  Modal, ModalHeader, ModalBody, Col, Row, Table
} from 'reactstrap';

import reportStore from '../../../stores/report-store';
import loadsStore from '../../../stores/loads-store';
import DonutLoader from '../../loadManager/DonutLoader';
import RefundConfirMation from './refundConfirmation';

class JumperDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      errors: '',
      amount: '',
      newJumperId: '',
      filterData: [],
      Isdata: false,
      isloading: false,
    }

    this.reportEvent = [
      { name: reportStore.getEventPrefix('jumperLogEvent'), func: this.handleJumperLogs }
    ];
  }

  componentDidMount = () => {
    this.reportEvent.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
  }

  componentWillUnmount = () => {
    this.reportEvent.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
  }

  handleJumperLogs = (logs) => {
    this.setState({ filterData: logs, Isdata: true, isloading: false });

  }

  toggleModal = (e) => {
    const { jumperId } = this.props

    var startDate = '';
    var endDate = '';
    let sDate = new Date();
    sDate = sDate.setHours(0, 0, 0, 0);
    startDate = new Date(sDate);
    startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

    let eDate = new Date();
    eDate = eDate.setHours(23, 59, 59, 999);
    endDate = new Date(eDate);
    endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

    // reportStore.GetJumperLogs(startDate, endDate, jumperId)
    // console.log("Api call", jumperId)
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      filterData: [],
      Isdata: false,
      isloading: true
    }, (isModalOpen) => {
      if (this.state.isModalOpen) {
        if (this.props.isTandem || this.props.isTandemStudent) {
          reportStore.GetTandemLogs(jumperId);
        }
        else {
          reportStore.GetJumperLastFewLogs(28, jumperId);
        }
      }
    });
  }

  componentWillReceiveProps(props) {
    // console.log("JumperId", this.props.jumperId);
    // const { jumperId } = this.props

    // if (jumperId) {
    //   this.setState({
    //     isloading: true,
    //     jumperId: jumperId,
    //     Isdata: false
    //   })
    // }
  }

  checkForNegatives = (price) => {
		//console.log('check for negatives: ' + price);
		if (price != undefined && price!==null && Math.abs(price)>=0.01) {
			let priceCell = (price <= -0.01) ?
				{
					value: `($${Math.abs(price).toFixed(2)})`
				} :
				{
					value: `$${price.toFixed(2)}`
				}
			if (price <= -0.01) {
				if (window.ENV?.showGreenNegative) {
					return <span style={{ color: 'lime' }}> {priceCell.value}</span>;
				}
				else {
					return <span style={{ color: 'salmon' }}> {priceCell.value}</span>;
				}

			}
			return priceCell.value;
		}
		else {
			return '$0.00';
		}
	}
  /*
  checkForNegatives = (price) => {
    if (price === null || price === undefined) {
      return "null";
    }
    else {
      let priceCell = (price < 0 && Math.abs(price)>0.01) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0 && Math.abs(price)>0.01) {
        if (window.ENV?.showGreenNegative) {
          return <span style={{ color: 'lime' }}> {priceCell.value}</span>;
        }
        else {
          return <span style={{ color: 'salmon' }}> {priceCell.value}</span>;
        }
      }
      return priceCell.value;
    }

  }*/


  // filterData = () => {
  //   let filterDataArray = [];
  //   let filterOutput = [];
  //   let jumperArray = [];

  //   if (this.state.Isdata === true) {
  //     this.filterDataArray = this.state.filterData;

  //     for (var item in this.filterDataArray) {
  //       filterOutput = [];
  //       var logArray = this.filterDataArray[item];
  //       for (var items in logArray) {
  //         var desc = logArray[items];
  //         let JumperInfo = [];

  //         if (desc.jumperIds !== undefined) {
  //           var otherJumper = desc.jumperIds;
  //           for (var oitem in otherJumper) {
  //             var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
  //             JumperInfo.push({ jumperName: name })
  //           }
  //         }

  //         var type = '';
  //         if (desc.type != undefined) {
  //           if (desc.type === "aff main") {
  //             type = desc.type + ' ' + desc.level;
  //           }
  //           else {
  //             type = desc.type;
  //           }
  //         }
  //         else {
  //           type = desc.jumpType;
  //         }
  //              let localDate=this.renderFormatDate(desc)

  //         filterOutput.push({ id: desc._id, type: type, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo })

  //       }
  //       if (logArray.length > 0) {
  //         jumperArray.push({ label: item, jumperStatement: filterOutput })
  //       }
  //     }
  //   }
  //   return jumperArray;
  // }

  filterData = () => {
    let filterDataArray = [];
    let filterOutput = [];
    let jumperArray = [];
    let summaryArray = [];
    let sortedArray = [];
    if (this.state.Isdata === true) {
      this.filterDataArray = this.state.filterData;


      for (var sortItem in this.filterDataArray) {

        if (sortItem !== 'summary') {
          jumperArray.push({ label: this.DateConvert(sortItem), type: 'date', jumperStatement: [], summary: [] })
          var statments = this.filterDataArray[sortItem];

          jumperArray.push({
            label: 'End Day Balance',
            value: statments.endDayBalance,
            taxAmount: statments.endDayTaxBalance,
            totalAmount: statments.endDayBalance + statments.endDayTaxBalance,
            jumperStatement: [],
            summary: []
          })
          if (loadsStore.getSeparateEarnedBalanceSetting()) {
            jumperArray.push({
              label: 'End Day Earning',
              value: statments.endDayEarnedBalance,
              jumperStatement: [],
              summary: []
            })
          }

          for (var item in statments) {
            filterOutput = [];
            var logArray = statments[item];
            for (var items in logArray) {
              var desc = logArray[items];
              let JumperInfo = [];

              if (desc.jumperIds !== undefined) {
                var otherJumper = desc.jumperIds;
                for (var oitem in otherJumper) {
                  if (otherJumper[oitem] && otherJumper[oitem].jumperId) {
                    var name = otherJumper[oitem].jumperId?.firstname + ' ' + otherJumper[oitem].jumperId?.lastname;
                    JumperInfo.push({ jumperName: name })
                  }
                }
              }
              // var otherJumper = desc.jumperIds;
              // console.log(otherJumper, "===>>>otherJumper")
              // for (var oitem in otherJumper) {
              //   var studentName = otherJumper[oitem].jumperId?.firstname + ' ' + otherJumper[oitem].jumperId?.lastname;
              //   console.log(studentName, "===>>>jumperInfoooo")

              // }
              var type = '';
              if (desc.type != undefined) {
                if (desc.type === "aff main") {
                  type = desc.type + ' ' + desc.level;
                }
                else {
                  type = desc.type;
                }
              }
              else {
                type = desc.jumpType;
              }
              let localDate = this.renderFormatDate(desc);
              if (this.props.isTandem) {
                filterOutput.push({ id: desc._id, type: type, block: desc.block, itemized: desc?.itemized, tandemPackage: desc.tandemPackage, videoPackage: desc.videoPackage, TI: desc.TI, OV: desc.OV, tax: desc.tax, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, planeLoad: desc.planeLoad, chargeFrom: desc.chargeFrom, chargeTo: desc.chargeTo, memo: desc.memo, transactionType: desc.transactionType, description: desc.description, maskedCardNum: desc.maskedCardNum, count: desc.count, transactionId: desc.transactionId })

              }
              else {
                filterOutput.push({ id: desc._id, type: type, block: desc.block, itemized: desc?.itemized, tax: desc.tax, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, planeLoad: desc.planeLoad, chargeFrom: desc.chargeFrom, chargeTo: desc.chargeTo, memo: desc.memo, transactionType: desc.transactionType, description: desc.description, maskedCardNum: desc.maskedCardNum, count: desc.count, transactionId: desc.transactionId })
              }
            }
            if (logArray!==undefined && logArray?.length > 0) {
              jumperArray.push({ label: item, jumperStatement: filterOutput, summary: summaryArray })
            }
          }
          //sortedArray.push(jumperArray);
        }
      }
    }
    // return filterOutput;
    return jumperArray;
  }

  formatDate(input) {
    if (input.toString().length == 1) {
      return '0' + input;
    }
    else {
      return input;
    }
  }
  renderFormatDate = (desc) => {
    let date;
    if (desc.createdAt !== undefined) {
      date = new Date(desc.createdAt);
    }
    else {
      date = new Date(desc.date);
    }

    let newDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());

    return newDate;

  }


  renderJumper = (items) => {

    return items?.jumperStatement && items?.jumperStatement?.map((item, index) => {
      return (
        <Fragment>
          <tr key={`jumperlogsrow`}>
            <td
              key={`jumperlogscolumn1`}
            >
              {item.type}
            </td>
            <td key={`jumperlogscolumn1-1`}>
              {item.count}
            </td>
            <td
              key={`jumperlogscolumn2`}
            >
              {new Date(item.date).toLocaleTimeString()}
            </td>

            {this.props.isTandem ?
              <Fragment>
                <td key={`jumperlogscolumn3-01`}>
                  {item.tandemPackage != undefined ? item.tandemPackage : ''}
                </td>
                <td key={`jumperlogscolumn3-02`}>
                  {item.videoPackage != undefined ? item.videoPackage : ''}
                </td>
                <td key={`jumperlogscolumn3-03`}>
                  {item.TI != undefined ? item.TI : 'none'}
                </td>
                <td>
                  {item.OV != undefined ? item.OV : 'none'}
                </td>
              </Fragment> : null
            }

            <td key={`jumperlogscolumn3`}>
              {item.block != undefined && item.block !== null && item.block !== "" ? item.block : ''}
              {item.price != undefined && (item.block === undefined || item.block === "" || item.block === null) ? this.checkForNegatives(item.price) : ''}
              {item.amount != undefined && (item.type.trim() === "charge" || item.type.trim() === "refund" || item.type.trim() === "payout") ? this.checkForNegatives(-item.amount) : ''}
              {item.amount != undefined && (item.type.trim() !== "charge" && item.type.trim() !== "refund" && item.type.trim() !== "payout") ? this.checkForNegatives(item.amount) : ''}
            </td>

            <td key={`jumperlogscolumn3-1`}>
              {item.tax != undefined && (item.type.trim() === "charge" || item.type.trim() === "refund") ? this.checkForNegatives(-item.tax) : ''}
              {item.tax != undefined && (item.type.trim() !== "charge" && item.type.trim() !== "refund") ? this.checkForNegatives(item.tax) : ''}
            </td>

            {item.tax !== undefined && item.tax !== null ?
              <td key={`jumperlogscolumn3-2`}>
                {item.block != undefined && item.block !== null && item.block !== "" ? item.block : ''}
                {item.price != undefined && item.tax !== undefined && (item.block === undefined || item.block === "" || item.block === null) ? this.checkForNegatives(item.tax + item.price) : ''}
                {item.amount != undefined && (item.type.trim() === "charge" || item.type.trim() === "refund") && item.tax !== undefined ? this.checkForNegatives(-item.tax - item.amount) : ''}
              </td>
              :
              <td key={`jumperlogscolumn3-2`}>
                {item.block != undefined && item.block !== null && item.block !== "" ? item.block : ''}
                {item.price != undefined && (item.block === undefined || item.block === "" || item.block === null) ? this.checkForNegatives(item.price) : ''}
                {item.amount != undefined && (item.type.trim() === "charge" || item.type.trim() === "refund") ? this.checkForNegatives(-item.amount) : ''}
              </td>
            }

            <td key={`jumperlogscolumn4`}>
              {item.loadNum != undefined ? item.loadNum : ''}
            </td>
            <td key={`jumperlogscolumn5`}>
              {item.planeLoad != undefined ? item.planeLoad : ''}
            </td>
            <td
              key={`jumperlogscolumn6`}
            >
              {item.chargeTo != undefined ? item.chargeTo : ''}
            </td>
            <td
              key={`jumperlogscolumn7-1`}
            >
              {item.chargeFrom != undefined ? item.chargeFrom : ''}
            </td>
            <td
              key={`jumperlogscolumn7-2`}
            >
              {item.memo != undefined ? item.memo : ''}
            </td>
            <td
              key={`jumperlogscolumn7-3`}
            >
              {item.transactionType != undefined ? item.transactionType : ''}
            </td>
            <td
              key={`jumperlogscolumn7-4`}
            >
              {item.maskedCardNum != undefined ? item.maskedCardNum : ''}
            </td>

            {item.transactionType === "Online" || item.transactionType === "Card key in" || item.transactionType?.includes("Credit") || item.transactionType === "Debit" || item.transactionType === "Card On File" || item.transactionType === "Cash" ?
              <td
                key={`jumperlogscolumn7-5`}
              >
                <RefundConfirMation jumperId={this.props.jumperId}
                  activity={this.props.activity}
                  chargeValue={item}
                  chargeValueData={item}
                  isTandemStudent={this.props.isTandem}
                  showPartialRefundModal={true}
                  toggleModal={this.toggleModal}
                />
              </td> : ""}
          </tr>


          {
            item.type.trim() === "transfer" &&
            <tr
              key={`jumperlogsDescrow`}>
              <td
                colSpan={4}
                key={`jumperlogsDesccolumn`}
                style={{ paddingLeft: '20px' }}
              >
                {item.description && "Description : " + item.description}

                {
                  item.memo && item.description &&
                  <br />
                }


              </td>
            </tr>
          }

          {
            item.type.trim() == "miscellaneous" && item.memo &&
            <tr
              key={`miscellaneouscrow`}>
              <td
                colSpan={4}
                key={`miscellaneous`}
                style={{ paddingLeft: '20px' }}
              >
                {item.memo}
              </td>
            </tr>
          }

          {
            item.type.trim() == "adjustment" && item.memo &&
            <tr
              key={`adjustmentrow`}>
              <td
                colSpan={4}
                key={`adjustment`}
                style={{ paddingLeft: '20px' }}
              >
                {item.memo}
              </td>
            </tr>
          }

          {
            item.JumpType === "funJumps"
              ?
              this.renderOtherJumper(item.JumperArray, index)
              :
              ''
          }
        </Fragment>
      )
    })
  }

  DateConvert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


  renderOtherJumper = (JumperArray, index) => {
    let { classes } = this.props;
    var otherJumpers = "";

    JumperArray.map((item) => {
      if (otherJumpers === "") {
        otherJumpers = item.jumperName;
      }
      else {
        otherJumpers = otherJumpers + ', ' + item.jumperName;
      }
    })

    return (
      <tr key={`otherjumperRow`}>
        <td
          colSpan={4}
          key={`otherjumpercolumn`}
          style={{ paddingLeft: '20px' }}
        >
          {otherJumpers}
        </td>
      </tr>
    )
  }

  render() {
    const { isloading } = this.state;
    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
      <React.Fragment>
        <Modal id="modal-main" className="jumpers" isOpen={this.state.isModalOpen} style={{ maxWidth: this.props.isTandem ? '1580px' : '1280px' }}>

          <ModalHeader>View Details <button style={{ color: 'white' }} onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="close" >&times;</button> </ModalHeader>
          <ModalBody className="modal-body">
            <DonutLoader isLoading={isloading} />

            {
              isloading ?
                <span style={{ color: 'white', fontSize: '20px', marginLeft: '35% ' }}>Please Wait...</span>
                :

                <Row className="form-group">
                  <Col md={12}>
                    <Table striped style={{ borderTopStyle: "hidden" }}>
                      <thead>
                        {this.props.isTandem ?
                          <tr>
                            <th>Jump Type</th>
                            <th>Qty</th>
                            <th>Date</th>
                            <th>Tandem Package</th>
                            <th>Video Package</th>
                            <th>TI</th>
                            <th>Vediographer</th>
                            <th>Price</th>
                            <th>Tax</th>
                            <th>Total</th>
                            <th>Load Num</th>
                            <th>Plane Load</th>
                            <th>Charge To</th>
                            <th>Charge From</th>
                            <th>memo</th>
                            <th>transaction type</th>
                            <th>card used</th>
                          </tr> :
                          <tr>
                            <th>Jump Type</th>
                            <th>Qty</th>
                            <th>Date</th>
                            <th>Price</th>
                            <th>Tax</th>
                            <th>Total</th>
                            <th>Load Num</th>
                            <th>Plane Load</th>
                            <th>Charge To</th>
                            <th>Charge From</th>
                            <th>memo</th>
                            <th>transaction type</th>
                            <th>card used</th>
                          </tr>
                        }

                      </thead>

                      <tbody>
                        {
                          this?.filterData()?.map((row, index) => {
                            return (
                              <Fragment>
                                <tr key={`RowHeader`}>
                                  {
                                    row.label === 'End Day Earning' && loadsStore.getSeparateEarnedBalanceSetting() ?
                                      <th scope="row" colSpan="7">
                                        {row.label.toLocaleUpperCase()} :
                                        {this.checkForNegatives(row.value)}

                                      </th> : null
                                  }
                                  {
                                    row.label !== 'End Day Balance' ?
                                      <th scope="row" colSpan="6" style={{
                                        fontSize: row.type === 'date' ? '24px' : '',
                                        textIndent: row.type === 'date' ? '-0.4rem' : ''
                                      }}>
                                        {row.label.toLocaleUpperCase()}
                                      </th>
                                      :
                                      <th scope="row" colSpan="7">
                                        {row.label.toLocaleUpperCase()} :
                                        &nbsp;{this.checkForNegatives(row.value)}
                                        &nbsp;&nbsp;&nbsp;{loadsStore.isTaxEnabled() ? this.checkForNegatives(row.taxAmount) : null}
                                        &nbsp;&nbsp;&nbsp;{loadsStore.isTaxEnabled() ? this.checkForNegatives(row.totalAmount) : null}
                                      </th>

                                    // <th scope="row" colSpan="6">
                                    //   {row.label.toLocaleUpperCase()} : {this.checkForNegatives(row.value)}
                                    // </th>

                                  }

                                </tr>
                                {this.renderJumper(row)}
                              </Fragment>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
            }
            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 9 }}>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <button className={`btn ${this.props.tandemStudentButton ? 'chargeTandemBtn' : "btn customButton rightButton viewHistory"}`} onClick={this.toggleModal}>
          View History
        </button>
      </React.Fragment>
    );
  }
}

export default JumperDetail
