import React, { Component, Fragment } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import moment from 'moment-timezone';
import { ClipLoader } from 'react-spinners';
import {
	Modal, ModalHeader, ModalBody
} from 'reactstrap';

import jumpersStore from '../../stores/jumpers-store';
import schedulesStore from "../../stores/schedule-store";
import reservationStore from "../../stores/reservation-store";
import Fuse from "fuse.js";
import RightColumnCards from '../../components/sub-components/cards/rightColumnCards'
// css for reservation 
import '../css/reservation.css';
// import schedulesStore from '../../stores/schedule-store';
import booking from '../BookingComponent/booking';
import Send_Multiple_ReminderEmails from '../schedules/sendMultipleReminderEmail';



const Types = {
	ITEM: 'FunJump'
};

const customStyles = {
	control: base => ({
		...base,
		minHeight: 30
	}),
	menu: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		color: "black",
		padding: 20,
		height: 530,
		zIndex: 9999,
	}),
	menuList: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		padding: 20,
		minHeight: 500
	})
};

class SearchJumper extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			isLoading: false,
			otherActivities: [],
			jumperId: '',
			transactionAmount: this.props.transactionAmount,
			transactionId: this.props.transactionId,
			isTandemStudent: false,
			passengers: [],
			reservationData: {},
			selectedUser: null,
			emailTemplates: [],

		};
		this.valRef = null;
		this.inputValue = '';
		this.fuse = null;
		this.isConfirm = true;

		this.scheduleEvents = [
			{ name: schedulesStore.getEventPrefix('fetchPassengers'), func: this.handleChangeReservationResponse },
		];
	}

	componentDidMount = () => {
		if (this.valRef !== null)
			this.valRef.focus();
		this.setFuse(this.props.options);
		this.loadEmailsTemplates()
	}
	// componentDidMount(){
	// 	this.loadEmailsTemplates()
	//   }

	setFuse = (jumperOptions) => {
		var options = {
			shouldSort: true,
			tokenize: true,
			threshold: 0.1,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 2,
			keys: [
				"value.manifestNumber",
				"value.nickname",
				"value.lastname"
			]
		};
		this.fuse = new Fuse(jumperOptions, options);
	}

	componentDidUpdate = () => {
		this.setFuse(this.props.options);
	}

	componentWillMount = () => {
		this.scheduleEvents.forEach(evnt => schedulesStore.on(evnt.name, evnt.func));
		schedulesStore.fetchTodayReservation();
	}


	componentWillUnmount = () => {
		this.scheduleEvents.forEach(evnt =>
			schedulesStore.removeListener(evnt.name, evnt.func)
		);
	}

	handleChangeReservationResponse = (data) => {
		//alert('in handleEventSelectResponse search Reservations');

		this.setState({ isLoading: true })
		let passengers = data.timeslots;
		this.setState({
			passengers,
			isLoading: false,
		})
	};
	loadTandemStudents = (reservation) => {
		// reservation is particular when a option is selected in search
		let passengers = this.state.passengers;
		// console.log(passengers,this.state.selectedUser,"loadTandemStudents")
		let newPassengers = []
		passengers.forEach(slots => {
			// filtered reservation and timeslots according to reservation selected
			slots.reservations.forEach(ele => {
				//match tandem students id
				if (ele._id === reservation._id) {
					newPassengers.push({
						...slots,
						reservations: [reservation]
					})
				}
			})
		})
		// console.log("newPassengers: ",newPassengers);
		this.setState({
			passengers: newPassengers,
			reservationData: reservation,
		})

	}

	handleSelect = (val) => {
		this.setState({ isModalOpen: true, isLoading: true });

		reservationStore.getReservation(val.value)
			.then(reservation => {
				// console.log("detailsssssss:",reservation);
				this.loadTandemStudents(reservation);

				let isTandemStudent = reservation.tandemStudents.length > 0 ? true : false;
				this.setState({
					selectedUser: val.value,
					isTandemStudent,
					isLoading: false
				});
			})
			.catch(err => {
				// console.log(err,"Search Reservation ERR");
				this.setState({
					isTandemStudent: false,
					isLoading: false,
				})
			})
	}
	bookings = (id, singleTimeSlots, _selectedEmailTemplate) => {
		this.setState({ selectedTemplate: _selectedEmailTemplate, isSendEmailModalOpen: true, selectedEventId: id, singleTimeSlots: singleTimeSlots, selectedEmailTemplate: _selectedEmailTemplate });
	};

	promiseOptions = (inputValue) => {
		return new Promise(resolve => {
			// console.log(inputValue);
			jumpersStore.GetReservationOptions(inputValue.toLowerCase())
				.then(reservationData => {
					this.setState({
						passengers: reservationData.timeslots,
					})
					// console.log(reservationData.reservationOptions,"reservationOptions")
					resolve(reservationData.reservationOptions)
				})
		});
	}
	onBlackOutClick = (payload) => {
		schedulesStore.blackOutSlotById(payload);
	}
	handleInputChange = (newValue) => {
		this.inputValue = newValue;
		// this.valRef.focus();
	};
	loadEmailsTemplates = () => {
		console.log("calledschedulesStore");
		schedulesStore
			.getemailtemplates()
			.then((resp) => {
				console.log(resp, "resprespresprespresprespresp");
				this.setState({
					emailTemplates: resp.data,
				});
			})
			.finally(() => {
				// setIsLoading(false);
			});
	};

	closeModal = () => {
		this.setState({ isModalOpen: false });
	}
	renderReservation = () => {
		return (
			<React.Fragment>
				<div className="row">
					<div className="col-12">
						{this.state.isSendEmailModalOpen && (
							<Send_Multiple_ReminderEmails
								currentSelectedDate={this.currentSelectedDate}
								isMemoModalOpen={this.state.isSendEmailModalOpen}
								selectedTemplate={this.state.emailTemplates.find((temp) => temp._id == this.state.selectedTemplate)}
								state={this.state}
								setState={this.setState.bind(this)}
							/>
						)}

						<RightColumnCards
							isCollapsable={true}
							event={this.state.passengers}
							edit={true}
							isCheckBoxShow={true}
							onBlackOutClick={this.onBlackOutClick}
							currentDate={this.showDateTime()}
							templates={this.state.emailTemplates}
							emailTemplates={this.state.emailTemplates}
							_bookings={this.bookings}
							showSingle={true}
							handleSelect = {this.handleSelect}
						/>
					</div>
				</div>
			</React.Fragment>
		)
	}
	showDateTime = () => {
		let { passengers } = this.state;
		// console.log("showDateTime",passengers)
		if (passengers.length > 0) {
			let date = passengers[0].datetime;
			date = new moment(date).format("YYYY-MM-DD");
			return date;
		}
		return ''
	}

	render() {
		const override = `
	        display: block;
	        margin: 0 auto;
	        border-color: red;
	      `;

		if (this.props.mode !== "async") {
			// console.log(this.props.options);
		}

		return (
			<React.Fragment>
				<Modal id="modal-reservation"
					aria-labelledby="example-custom-modal-styling-title"
					isOpen={this.state.isModalOpen} style={{ zIndex: '9999' }}
				>
					<ModalHeader style={{ position: 'relative', color: "#105ec1" }}>
						View Details ({this.showDateTime()})
						<button className="close"
							onClick={(e) => { e.preventDefault(); this.closeModal(); }}
						>
							x
						</button>
					</ModalHeader>
					<ModalBody style={{ color: "#105ec1" }}>
						<div className='complete-loading'>
							<ClipLoader
								css={override}
								sizeUnit={"px"}
								size={35}
								color={'#fff'}
								loading={this.state.isLoading}
							/>
						</div>
						{this.renderReservation()}
					</ModalBody>
				</Modal>
				<div>
					<div style={{ marginBottom: '25px' }}>
						{
							<AsyncSelect
								loadOptions={(newValue) => this.promiseOptions(newValue)}
								onInputChange={(val) => this.handleInputChange(val)}
								onChange={(val) => this.handleSelect(val)}
								placeholder={this.props.placeholder}
								styles={customStyles}
							/>
						}
					</div>
				</div>
			</React.Fragment>
		);
	}
}


export default SearchJumper

