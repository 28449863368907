/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
    Col, Row, Label
} from 'reactstrap';
import DefaultImg from '../../images/giftcard_thumb140.jpg'
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import itemStore from '../../../stores/item-store';
import AdminModal from './customModal';

class EditItem extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
            image: '',
            hideModal: false,
            zoom: 1,
            aspect: 1 / 1,
            croppedImageUrl: null,
            cropBtn: true,
            loading: false,
            imageBase64: "",
            crop: {
                unit: '%',
                aspect: 1 / 1,
                x: 10,
                y: 10,
            },
            errorMsg: false,
            useDefaultImage: true,
            defaultImageUrl: "",
            isImageUpdated: false,
            cropedThumb: null
        }
        //for default image shown in giftcard
        this.defaultImageStyle = {
            backgroundImage: "url('./giftcard_thumb.jpg')",
            backgroundSize: 'cover'
        }

    }
    componentDidUpdate() {
        if (this.state.isImageUpdated) {
            this.setState({ isImageUpdated: false })
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            croppedImageUrl: null,
            image: '',
            hideModal: false,
        });
    }

    componentDidMount() {
        // this.useDefaultImage();
    }

    useDefaultImage = () => {
        console.log("callled useDefaultImage")
        // let url = './images/giftcard_thumb140.jpg';
        var image = new Image();
        image.onload = () => {
            var canvas = document.createElement('canvas');
            canvas.width = 140;
            canvas.height = 140;
            canvas.getContext('2d').drawImage(image, 0, 0);
            canvas.toBlob(defaultImageUrl => {
                this.setState({ defaultImageUrl });
            }, 'image/jpeg');
        };

        image.src = DefaultImg;
    }
    returnFilename = () => {
        let { uniqueIndex, isEdit, item } = this.props;
        let filename = '';
        if (isEdit) {
            filename = item.imageIndex;
        }
        else {
            filename = uniqueIndex
        }
        return filename
    }
    returnFormData = () => {
        let { defaultImageUrl, croppedImageUrl, useDefaultImage } = this.state;
        var form = new FormData()

        if (useDefaultImage) {
            let filename = this.returnFilename();
            form.append('files', defaultImageUrl, filename);
        }
        else {
            let filename = this.returnFilename();
            form.append('files', croppedImageUrl, filename);
        }
        return form;
    }
    sendImg = (res) => {
        let formData = new FormData()
        formData.append('_id', res._id)
        formData.append("thumb", this.state.cropedThumb)
        itemStore.uploadBlob(formData).then(res => {
            if (res.status === 200) {
                this.setState({
                    cropedThumb: null,
                    croppedImageUrl: null,
                    isImageUpdated: true, loading: false,
                })
            }
        }).catch(err => console.log("ERR", err))
    }
    handleSubmit(values) {
        if (
            !values.price ||
            !values.item ||
            !(values.tandemPackageName || values.studentPackageName) ||
            !values.tickets ||
            !values.description ||
            !values.expiryInDays
        ) {
            this.setState({ errorMsg: true, loading: false })
            return
        }

        // return
        var payload = {};
        if (this.props.isEdit && values !== undefined) {
            payload.item = values.item;
            payload.category = "giftcard";
            payload.price = Number(values.price);
            payload.taxableAmount = Number(values.taxableAmount);

            payload.tickets = Number(values.tickets);
            payload.tandemPackageName = values.tandemPackageName;

            payload.videoPackageName = values.videoPackageName ? values.videoPackageName : 'none';
            payload.description = values.description;
            payload.expiryInDays = Number(values.expiryInDays);
            payload.weekType = 'both';
            payload.isActive = values.isActive;


            if (this.state.croppedImageUrl === null) {
                // if image is not updated or edited
                payload.thumbnail = this.props.item.thumbnail;
                payload.imageIndex = this.props.item.imageIndex;

                itemStore.updateItem(this.props.item._id, payload).then(res => {
                    this.setState({
                        cropedThumb: null,
                        croppedImageUrl: null,
                        isImageUpdated: true, loading: false
                    })
                    this.toggleModal();

                }).catch(err => console.log(err, "Errrrrrr"))
            }
            else {
                itemStore.updateItem(this.props.item._id, payload).then(res => {
                    this.sendImg(res)
                    // this.setState({
                    //     croppedImageUrl: null,
                    //     loading: false,
                    //     isImageUpdated: true,
                    // })
                    this.toggleModal();

                }).catch(err => console.log(err, "Errrrrrr"))
            }

        }
        else if (values != undefined) {
            payload.item = values.item;

            payload.category = "giftcard";

            payload.price = Number(values.price);

            payload.taxableAmount = Number(values.taxableAmount);

            payload.tickets = Number(values.tickets);

            payload.tandemPackageName = values.tandemPackageName;

            payload.videoPackageName = values.videoPackageName;

            payload.studentPackageName = values.studentPackageName;

            payload.expiryInDays = Number(values.expiryInDays);

            payload.description = values.description;
            payload.weekType = 'both';
            payload.isActive = values.isActive;
            itemStore.createItem(payload).then(res => {
                this.sendImg(res)
                this.setState({
                    croppedImageUrl: null,
                    isImageUpdated: true, loading: false,
                })
                this.toggleModal();
            }).catch(err => console.log(err, "Errrrrrr"))
        }
        // window.location.reload();
    }

    handleUploadImage(event) {
        if (event.target.files && event.target.files[0]) {

            this.setState({ errorMsg: false })
            let reader = new FileReader();
            reader.onload = (e) => {
                // this.getThumbnail(e.target.result)
                // console.log('e.target.result',e.target.result);
                this.setState({ useDefaultImage: false, image: e.target.result }, () => {
                    this.setState({ hideModal: true })
                })
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    onImageLoaded = image => {
        console.log("onImageLoaded")
        this.imageRef = image;
    };

    onCropComplete = crop => {
        console.log("onCropComplete")
        if (crop.height > 0) {
            this.makeClientCrop(crop);
            this.setState({ cropBtn: false })
        }
    };

    onCropChange = (crop, percentCrop) => {
        console.log("POST")
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                this.setState({ imageBase64: canvas.toDataURL(), cropedThumb: blob }, () => {
                    resolve(blob);
                })
            }, 'image/jpeg');
        });
    }
    getThumbnail() {
        console.log("getThumbnail")
        let { cropBtn } = this.state
        if (cropBtn) {
            return
        } else {
            let { imageBase64 } = this.state
            this.setState({ hideModal: false })
            let myCan = document.createElement('canvas');
            let img = new Image();
            img.src = imageBase64;
            img.onload = () => {
                myCan.id = "myTempCanvas";
                myCan.width = 128
                myCan.height = 128
                if (myCan.getContext) {
                    var cntxt = myCan.getContext("2d");
                    cntxt.drawImage(img, 0, 0, myCan.height, myCan.width);
                    var dataURL = myCan.toDataURL();
                    if (dataURL != null && dataURL != undefined) {
                        var nImg = document.createElement('img');
                        nImg.src = dataURL;
                        document.getElementById("img").appendChild(nImg);
                    }
                    else { alert('unable to get context'); }
                }
            }
        }

    }
    handleCropCancel = () => {
        console.log("handleCropCancel")
        let { useDefaultImage } = this.state;
        if (!useDefaultImage) {
            this.setState({
                hideModal: false,
                croppedImageUrl: null,
                imageBase64: "",
                useDefaultImage: true,
                cropBtn: null
            })
        }
    }

    showImageUploader() {
        // console.log("CLICKED")
        console.log(this.props.item?.thumb?.data?.data, "this.props.item?.thumb?.data?.data")
        var binary = '';
        var bytes = new Uint8Array(this.props.item?.thumb?.data?.data);
        var len = bytes.byteLength;
        console.log(len)
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64String = window.btoa(binary);
        // const base64String = window.btoa(String.fromCharCode(...new Uint32Array(this.props.item?.thumb?.data?.data)));
        console.log(base64String, "base64Stringbase64Stringbase64String")
        if (this.props.isEdit) {
            return (<Col md={9}>
                <div style={{ height: '128px', width: '128px' }}>
                    {this.props.item.thumb ? <img
                        src={`data:image/png;base64,${base64String}`}
                        style={{ height: '100%', width: '100%' }}
                    /> : null
                    }
                    <input type="file" style={{ opacity: 0, height: '100%', width: '128px', position: "absolute", left: 0 }} name="thumbnail" onChange={(e) => this.handleUploadImage(e)} />
                </div> </Col>)
        }
        else {
            return (
                <Col md={9}>
                    <div
                        className="imageContainer"
                    // style={this.state.useDefaultImage?this.defaultImageStyle:null}
                    >
                        <Control.file
                            name="thumbnail"
                            model=".thumbnail"
                            className="form-control uploadImage"
                            onChange={(e) => this.handleUploadImage(e)}
                        />
                        <label htmlFor="file">Choose a file</label>
                    </div>
                </Col>
            )
        }

    }

    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            cropedThumb: null,
            croppedImageUrl: null,
            isImageUpdated: true, loading: false,
        })
        this.toggleModal();
    }


    render() {

        var tandemPackages = itemStore.getTandemPackages();
        var videoPackages = itemStore.getVideoPackages();
        var studentPackages = itemStore.getStudentPackages();

        var item = {
            thumbnail: '', item: "", price: "",
            weekendPrice: "", videoType: "", videoPrice: "",
            instructorPay: "", videoPay: "", wristcamPay: "",
            description: "", isActive: true
        };
        if (this.props.item !== undefined) {
            item = this.props.item;
        }
        var altitudes = itemStore.getAltitudes();
        let { hideModal, image, crop, cropBtn, loading, cropedThumb, errorMsg, useDefaultImage } = this.state

        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Create Giftcard Template`}
                    addClass={'create-giftcard-modal'}
                >
                    <LocalForm encType="multipart/form-data" onSubmit={(values) => this.handleSubmit(values)}>

                        <Row className="form-group">
                            <Label htmlFor="item" md={3}>Item</Label>
                            <Col md={9}>
                                <Control.input onChange={() => this.setState({ errorMsg: false })} name="item" model=".item" className="form-control" defaultValue={item.item}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input onChange={() => this.setState({ errorMsg: false })} type="number" name="price" model=".price" className="form-control" defaultValue={item.price}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="taxableAmount" md={3}>Taxable Amount</Label>
                            <Col md={9}>
                                <Control.input onChange={() => this.setState({ errorMsg: false })} type="number" name="taxableAmount" model=".taxableAmount" className="form-control" defaultValue={item.taxableAmount}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="tickets" md={3}>Number of Tickets</Label>
                            <Col md={9}>
                                <Control.input onChange={() => this.setState({ errorMsg: false })} type="number" name="tickets" model=".tickets" className="form-control" defaultValue={item.tickets}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="tandemPackageName" md={3}>Tandem Package</Label>
                            <Col md={9}>
                                <Control.select onChange={() => this.setState({ errorMsg: false })} name="tandemPackageName" model=".tandemPackageName" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.tandemPackageName}>
                                    <option value="none">none</option>
                                    {tandemPackages.map((alt, idx) => <option key={idx} value={alt.item}>{alt.item}</option>)}
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="videoPackages" md={3}>Video Package</Label>
                            <Col md={9}>
                                <Control.select onChange={() => this.setState({ errorMsg: false })}
                                    name="videoPackageName" model=".videoPackageName" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                    defaultValue={item.videoPackageName}
                                >
                                    <option value="none">none</option>
                                    {videoPackages.map((alt, idx) => <option key={idx} value={alt.item}>{alt.item}</option>)}
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="studentPackages" md={3}>Student Package</Label>
                            <Col md={9}>
                                <Control.select onChange={() => this.setState({ errorMsg: false })}
                                    name="studentPackageName" model=".studentPackageName" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                    defaultValue={item.studentPackageName}
                                >
                                    <option value="none">none</option>
                                    {studentPackages.map((alt, idx) => <option key={idx} value={alt.name}>{alt.name}</option>)}
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="expiryInDays" md={3}>Expiry </Label>
                            <Col md={9}>
                                <Control.input onChange={() => this.setState({ errorMsg: false })} type="number" name="expiryInDays" model=".expiryInDays" className="form-control" defaultValue={item.expiryInDays}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="description" md={3}>Description </Label>
                            <Col md={9}>
                                <Control.textarea onChange={() => this.setState({ errorMsg: false })} name="description" model=".description" className="form-control" defaultValue={item.description}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="isactive" md={3}>Active</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="isactive" model=".isActive"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isActive}
                                    autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="thumbnail" md={3}>Thumbnail </Label>
                            {cropedThumb ? <div id="img" className="imagePreview">
                            </div> : this.showImageUploader()}
                        </Row>

                        {
                            loading ? <div>
                                <Label htmlFor="description" md={3} />
                                <Label md={9}>
                                    Saving data please wait ....
                                </Label></div> : null
                        }
                        {
                            errorMsg ? <div>
                                <Label htmlFor="description" md={3} />
                                <Label md={9} style={{ color: 'red' }}>
                                    please fill all the details
                                </Label></div> : null

                        }

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button disabled={loading} className="btn btn-bg lightColor createButton" type="submit" >
                                    SUBMIT
                                </button>
                                <button disabled={loading}
                                    onClick={(e) => { this.handleCancel(e) }}
                                    className="btn btn-link"
                                >
                                    CANCEL
                                </button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal >
                {!this.props.isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }

                {
                    hideModal ?
                        <div className="crop-container">
                            <ReactCrop
                                locked={false}
                                src={image}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                            <div className="buttonOutMain">

                                <div onClick={() => this.getThumbnail()}>
                                    {cropBtn ? 'Crop to save' : 'Save'}
                                </div>
                                <div onClick={this.handleCropCancel}>
                                    Cancel
                                </div>
                            </div>
                        </div> : null

                }
            </React.Fragment >
        );
    }
}

export default EditItem
