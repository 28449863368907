import { create } from 'apisauce';
import urlgetter from './getMainUrl';
import authStore from '../stores/auth-store';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const addToken = () => {
    let token = authStore.getAuthToken();
    if (token == '') {
        token = localStorage.getItem("Auth-token");
    }
    api.setHeader('Authorization', 'Bearer ' + token);
}

const func = {};

func.getOauthTokenFromSquare = async (payload) => {
    addToken();
    return await api.get(`/oauthToken/square/${payload}`)
}

func.verifySquareOauthTokenExists = async () => {
    addToken();
    return await api.get('/oauthToken/verifySquareOauthtokenExists');
}

func.revokeAccessToken = async () => {
    addToken();
    return await api.get('/oauthToken/revokeAccessToken');
}

export default func;