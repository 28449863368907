/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';


import swal from 'sweetalert';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';
import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';
import { Show_Loader } from '../../Loader/showLoader';

class EditItem extends Component {

    constructor(props) {


        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }



    async handleSubmit(values) {

        //console.log("in handle submit load"+ values);
        this.props.setState({ isLoading: true })
        this.toggleModal();
        var payload = {};
        if (this.props.isEdit && values !== undefined) {
            payload.item = values.item;
            payload.category = "block";
            payload.price = Number(values.price);
            payload.tickets = Number(values.tickets);
            payload.soloTickets = Number(values.soloTickets);
            payload.affTickets = Number(values.affTickets);
            payload.coachTickets = Number(values.coachTickets);
            payload.expiryInDays = Number(values.expiryInDays);

            if (values.plane !== "all") {
                payload.plane = values.plane;

            }
            payload.isTaxable = values.taxable ? true : false;
            payload.forBoogie = values.forBoogie ? true : false;
            payload.isDefault = values.isDefault ? true : false;
            if(!(payload.tickets>=1 || payload.affTickets>=1)){
                swal({
                    title: "Warning",
                    text: "must enter valid number of tickets",
                    icon: "warning",
                    dangerMode: false,
                })
                this.props.setState({ isLoading: false })
                return;
            }
            const result = await itemStore.updateItem(this.props.item._id, payload);
            if (result) {
                const res = await itemStore.loadItems();
                if (res)
                    this.props.setState({ isLoading: false })
            }
        }
        else if (values != undefined) {
            payload.item = values.item;

            payload.category = "block";

            payload.price = Number(values.price);

            payload.tickets = Number(values.tickets);

            payload.soloTickets = Number(values.soloTickets);

            payload.affTickets = Number(values.affTickets);

            payload.coachTickets = Number(values.coachTickets);

            payload.expiryInDays = Number(values.expiryInDays);

            console.log(values.expiryInDays);
            //alert(values.expiryInDays)
            if(isNaN(Number(values.expiryInDays))){
                swal({
                    title: "Warning",
                    text: "must enter valid number of days for expiry (enter 999999 for no expiry)",
                    icon: "warning",
                    dangerMode: false,
                })
                this.props.setState({ isLoading: false })
                return;
            }

            payload.isTaxable = values.taxable ? true : false;

            payload.forBoogie = values.forBoogie ? true : false;

            payload.isDefault = values.isDefault ? true : false;

            if (values.plane !== "all") {
                payload.plane = values.plane;
            }
            //alert(JSON.stringify(payload,null,2));
            if(!(payload.tickets>=1 || payload.affTickets>=1)){
                swal({
                    title: "Warning",
                    text: "must enter valid number of tickets",
                    icon: "warning",
                    dangerMode: false,
                })
                this.props.setState({ isLoading: false })
                return;
            }
            const result = await itemStore.createItem(payload);
            if (result) {
                const res = await itemStore.loadItems();
                if (res)
                    this.props.setState({ isLoading: false })
            }
        }
    }

    render() {
        var item = { item: "", price: "", weekendPrice: "", videoType: "", videoPrice: "", instructorPay: "", videoPay: "", wristcamPay: "", description: "" };

        if (this.props.item !== undefined) {
            item = this.props.item;
            console.log(this.props.item);
            console.log(item);
        }
        var planes = loadStore.getPlanes();
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Block`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="item" md={3}>Item</Label>
                            <Col md={9}>
                                <Control.input name="item" model=".item" className="form-control" defaultValue={item.item}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price" model=".price" className="form-control" defaultValue={item.price}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="tickets" md={3}>Number of Tickets</Label>
                            <Col md={9}>
                                <Control.input name="tickets" model=".tickets" className="form-control" defaultValue={item.tickets}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="affTickets" md={3}>Number of Student Tickets</Label>
                            <Col md={9}>
                                <Control.input name="affTickets" model=".affTickets" className="form-control" defaultValue={item.affTickets}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="coachTickets" md={3}>Number of Coach Tickets</Label>
                            <Col md={9}>
                                <Control.input name="coachTickets" model=".coachTickets" className="form-control" defaultValue={item.coachTickets}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="soloTickets" md={3}>Number of Solo Tickets</Label>
                            <Col md={9}>
                                <Control.input name="soloTickets" model=".soloTickets" className="form-control" defaultValue={item.soloTickets}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="plane" md={3}>specific plane</Label>
                            <Col md={9}>
                                <Control.select name="plane" model=".plane" className="form-control" mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.plane ? item.plane : 'all'} autoComplete="off">
                                    {
                                        [<option key={-1} value="all">All</option>, ...planes.map((plane, index) => {
                                            return (
                                                <option key={index} value={plane.plane}>{plane.plane}-{plane.name}</option>
                                            )
                                        })]
                                    }
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="expiryInDays" md={3}>Expiry </Label>
                            <Col md={9}>
                                <Control.input name="expiryInDays" model=".expiryInDays" className="form-control" defaultValue={item.expiryInDays}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="default" md={3}>Boogie</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox
                                    name="forBoogie"
                                    model=".forBoogie"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.forBoogie}
                                    autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="default" md={3}>Taxable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox
                                    name="taxable"
                                    model=".taxable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isTaxable}
                                    autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="default" md={3}>Default</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox
                                    name="default"
                                    model=".isDefault"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isDefault}
                                    autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {!this.props.isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </React.Fragment>
        );
    }
}

export default EditItem
