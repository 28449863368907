/**
 * http://usejsdoc.org/
 */
import { create } from 'apisauce';
import authStore from '../stores/auth-store';

const func = {};

// define the api
let api;

func.setApi = () => {
	if(window.ENV){
		api = create({
			baseURL: window.ENV?.processorInfo.apiUrl
		});
	}
}
const _formPostBody = (amount, orderId, taxAmount) => {
	var data = {
		"type": "sale",
		"amount": amount,
		"tax_amount": taxAmount,
		"currency": "USD",
		"description": "test transaction",
		"order_id": orderId,
		"po_number": "somePONumber",
		"ip_address": "4.2.2.2",
		"email_receipt": false,
		"email_address": "user@home.com",
		"create_vault_record": true,
		"payment_method": {
			"terminal": {
				"id": window.ENV?.processorInfo.terminalId,
				"print_receipt": "both",
				"signature_required": false
			}
		}
	};
	return data;
}

func.searchTransaction = (searchBody) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)

	return api.post('/transaction/search', searchBody);
}

func.postTransactionv2 = (payload) => {
	// console.log(api.getBaseURL());
	// console.log('in terminal authorize and capture');
	//console.log(postBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)

	return api.post('/transactionv2', payload);
}

func.postTransaction = (amount, orderId, taxAmount) => {
	// console.log(api.getBaseURL());
	// console.log('in terminal authorize and capture');
	var _amount = 0;
	var _taxAmount = 0;
	if (!isNaN(amount)) {
		_amount = amount;
	}
	if (!isNaN(taxAmount)) {
		_taxAmount = taxAmount;
	}
	var postBody = _formPostBody(Math.round(_amount) + Math.round(_taxAmount), orderId, Math.round(_taxAmount));
	//console.log(postBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)

	return api.post('/transaction', postBody);
}

func.voidTransaction = (transactionId, chargeValue, jumperId, isTandemStudent) => {
	// console.log(postBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	var body ={
		...chargeValue,
		jumperId,
		isTandemStudent
	}
	return api.post('/transaction/' + transactionId + '/void', body);
}

func.transAuthorizeAndCapturePartialRefund = (payload) => {
	// console.log('in authorize and capture');
	console.log('in terminal partial refund');
	console.log(payload);
	api.setHeaders({
		"Content-Type": 'application/json'
	})
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post(`/transaction/${payload.chargeValue.transactionId}/refund`, payload);
}


export default func;
