import React, { useState } from "react";
import ItemModal from '../../../accounts/modals/customModal';
import { ClipLoader } from 'react-spinners';
import { Control, LocalForm } from 'react-redux-form';
import { Col, Row, Label } from 'reactstrap';
import { useEffect } from "react";
import reportStore from "../../../../stores/report-store";
import swal from 'sweetalert';

const RefundGiftCardModal = ({
    isModalOpen,
    couponDetail,
    giftcards,
    setState
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isOpenModal, isSetOpenModal] = useState(false);
    const [giftPurchases, setGiftPurchases] = useState();
    const [giftcardGroup, setGiftCardGroup] = useState([]);
    const [refundingValues, setRefundingValues] = useState({
        refundAmount: 0,
        refundTaxAmount: 0,
        refundTotalFee: 0
    })
    const [giftCardObj, setGiftCardObj] = useState({});
    const [firstGiftCardOfGroup, setFirstGiftCardOfGroup] = useState({});
    const override = `display: block;margin: 0 auto;border-color: red;`;
    const reportEvents = [
        { name: reportStore.getEventPrefix('giftpurchase_loaded'), func: handleSuccess }
    ];
    const [isRefundbookingFeeError, setIsRefundbookingFeeError] = useState(false)

    useEffect(() => {
        if (couponDetail && couponDetail._id) {
            getGiftPurchaseByGiftcardId();
        }
    }, [couponDetail, isOpenModal]);


    useEffect(() => {
        isSetOpenModal(isModalOpen);
    }, [isModalOpen])

    const toggleModal = () => {
        setFirstGiftCardOfGroup({});
        setIsRefundbookingFeeError(false);
        isSetOpenModal(!isOpenModal);
        setState({ openRefundModal: !isOpenModal })
    }

    const getGiftPurchaseByGiftcardId = async () => {
        reportEvents.forEach((evnt) => reportStore.once(evnt.name, evnt.func));
        await reportStore.getGiftPurchaseByGiftcardId(couponDetail._id)
    }

    function handleSuccess(giftPurchases) {
        if(!isOpenModal)  return ;
        let giftCardGroup = giftcards.filter(ele => {
            let idx = giftPurchases[0].giftCouponIds.indexOf(ele._id);

            if(idx === 0 && ele.status !== "available") {
                setFirstGiftCardOfGroup(ele);
            }

            if (idx === -1) {
                return false;
            }
            else if(ele.status === "available"){
                return true;
            }else {
                return false;
            }
        })
        const giftCards = {};
        giftCardGroup = giftCardGroup?.map((item) => {
            giftCards[item._id] = {refundAmount:0, refundTax: 0, status:item.status};
            return { ...item, taxAmount : item.taxAmount || 0, refundAmount: 0, refundTax: 0, isError:false }
        })
        setGiftPurchases(giftPurchases[0]);
        setGiftCardGroup(giftCardGroup);
        setIsLoading(false);
        setGiftCardObj(giftCards);
        setRefundingValues({
            refundAmount: 0,
            refundTaxAmount: 0,
            refundTotalFee: 0
        })
    }

    const handleRefundValues = (event, item, index) => {
        let curGiftcardGroup = [...giftcardGroup];
        curGiftcardGroup[index][event.target.name] = event.target.value;
        if(Number(curGiftcardGroup[index][event.target.name]) > curGiftcardGroup[index].price) {
            curGiftcardGroup[index].isError = true;
        }else {
            curGiftcardGroup[index].isError = false;
        }
        curGiftcardGroup[index].refundTax = Number((Number((curGiftcardGroup[index].taxAmount).toFixed(2)) / curGiftcardGroup[index].price) * Number(curGiftcardGroup[index][event.target.name])).toFixed(2);
        let totalRefundingAmount = 0;
        let totalRefundingTax = 0;
        const giftCardsObj = {};
        curGiftcardGroup.forEach((giftcard) => {
            giftCardsObj[giftcard._id] = {refundAmount: Number(giftcard.refundAmount), refundTax: Number(giftcard.refundTax), status : (Number(giftcard.refundAmount) > 0 || Number(giftcard.refundTax) > 0) ? "refunded" : giftcard.status};
            totalRefundingAmount += Number(giftcard.refundAmount);
            totalRefundingTax += Number(giftcard.refundTax);
        })
        setGiftCardObj(giftCardsObj);
        setGiftCardGroup(curGiftcardGroup);
        setRefundingValues({ ...refundingValues, refundAmount: totalRefundingAmount, refundTaxAmount: (totalRefundingTax).toFixed(2) })
    }

    const handleTotalFee = (event) => {
        const totalFee = event.target.value;
        const totalRefundingBookingFee =  (giftPurchases.refundFeesAmount || 0) + Number(totalFee);
        if(!giftPurchases.totalFees || totalRefundingBookingFee > giftPurchases.totalFees) {
            setIsRefundbookingFeeError(true);
        }else {
            setIsRefundbookingFeeError(false);
        }
        setRefundingValues({ ...refundingValues, refundTotalFee: totalFee });
    }

    const showErrorAlert = (options) => {
        swal({
            title: "Error",
            text: options.msg,
            icon: "error",
            dangerMode: true,
        });
    }

    const processPaymentResponseTrans = (r) => {
        if (r && r.data && r.data.status === "success") {
            swal({
                title: "Refund done!",
                icon: "success",
            });
            toggleModal();
        }
        else {
            const errorMsg = r.msg  || "Something failed, please try again";
            showErrorAlert({msg:  r.msg  || "Something failed, please try again"});
        }
    }

    const iSErrorExists = () => {
        if(Number(refundingValues.refundAmount) <= 0) {
            showErrorAlert({msg:"Refunded Amount Can not be Zero"});
            return true; 
        }

        if(giftcardGroup.some(item => item.isError)) {
            showErrorAlert({msg:"Refunding Amount Against A Coupon Should Be Less Than Amount Paid For It."});
            return true; 
        }

        if(isRefundbookingFeeError) {
            showErrorAlert({msg:"Total Refunding Booking Fee Should Be Less Than The Total Paid Booking Fee."});
            return true;
        }

    }

    const handleSubmit = async () => {
        if(iSErrorExists()) {
            return;
        }
        const totalRefundAmount = (Number(refundingValues.refundAmount) + Number(refundingValues.refundTaxAmount) + Number(refundingValues.refundTotalFee)).toFixed(2);
        const transactionId = giftPurchases.transactionId;
        const payload = {
            totalRefundAmount:Number(totalRefundAmount),
            refundAmount: Number(refundingValues.refundAmount),
            refundTax: Number(refundingValues.refundTaxAmount),
            totalRefundedTax: Number(refundingValues.refundTaxAmount),
            refundBookingfees: Number(refundingValues.refundTotalFee),
            giftPurchaseId: giftPurchases._id,
            giftCardIds: giftPurchases.giftCouponIds,
            status:"refunded",
            amount: Number(giftPurchases.totalPaid),
            giftcardItemized:giftCardObj,
            firstGiftCardOfGroup:firstGiftCardOfGroup
        }
        try {
            const result = await reportStore.refundPaymentNGiftCard(transactionId, payload);
            processPaymentResponseTrans(result);
        }catch(err) {
            showErrorAlert({message:"Something failed, please try again"});
        }
    }

    return (
        <React.Fragment>
            <ItemModal
                isModalOpen={isOpenModal}
                title={`Refund Giftcard`}
            >
                <div className='complete-loading'>
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={35}
                        color={'#fff'}
                        loading={isLoading}
                    />
                </div>
                <LocalForm onSubmit={(values) => handleSubmit(values)}>
                    <>
                        <table>
                            <Row className="form-group">
                                <Col md={2}>Name</Col>
                                <Col md={2}>Code</Col>
                                <Col md={2}>Amount</Col>
                                <Col md={2}>Tax</Col>
                                <Col md={2}>Refund Amount</Col>
                                <Col m={2}>Refund Tax</Col>
                            </Row>
                            {
                                giftcardGroup && giftcardGroup.map((item, ind) => {
                                    return (
                                        <Row key={ind}>
                                            <Col md={2} >{item.name}</Col>
                                            <Col md={2} >{item.couponCode}</Col>
                                            <Col md={2} >{item.price}</Col>
                                            <Col md={2} >{(item.taxAmount ? item.taxAmount : 0).toFixed(2)}</Col>
                                            <Col md={2} className="amountInput">
                                                <Control.input name="refundAmount" model={`.refundAmount-${ind}`} className={item.isError ? "form-control refund_giftcard_modal-error" : "form-control"}
                                                    value={item.refundAmount} onChange={(e) => handleRefundValues(e, item, ind)}
                                                />
                                            </Col>
                                            <Col md={2} className="amountInput">
                                                <Control.input name="refundTax" model={`.refundTax-${ind}`} className="form-control pointer-event-none"
                                                    value={item.refundTax}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </table>
                        <Row className="refundInput">
                            <Label htmlFor="partialRefund" md={3}>Total Refund Amount</Label>
                            <Col md={9}>
                                <Label className="refundGiftCardRefundingValues">{refundingValues.refundAmount}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Label htmlFor="partialRefund" md={3}>Total Refund Tax Amount</Label>
                            <Col md={9} style={{ paddingTop: "13px" }}>
                                <Label className="refundGiftCardRefundingValues">{refundingValues.refundTaxAmount}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Label htmlFor="partialRefund" md={3} style={{ paddingTop: "20px" }}>Total Fees</Label>
                            <Col md={9} style={{ paddingTop: "13px" }}>
                                <Control.input name="totalFees" model=".totalFees" className={isRefundbookingFeeError ? "form-control refundGiftCardRefundingValues refund_giftcard_modal-error" : "form-control refundGiftCardRefundingValues"}
                                    value={refundingValues.refundTotalFee} autoComplete="off"
                                    onChange={handleTotalFee}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Label htmlFor="totalAmount" md={3} style={{ paddingTop: "20px" }}>Total Amount</Label>
                            <Col md={9} style={{ paddingTop: "13px" }}>
                                <span>{(Number(refundingValues.refundAmount) + Number(refundingValues.refundTaxAmount) + Number(refundingValues.refundTotalFee)).toFixed(2)}</span>
                            </Col>
                        </Row>
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 9 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit">SUBMIT</button>&nbsp;&nbsp;
                                <button onClick={(e) => { e.preventDefault(); toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </>
                </LocalForm>
            </ItemModal>
        </React.Fragment>
    )

}


export default RefundGiftCardModal;