

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from '../reducers';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
//export const store = createStore(rootReducer);

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunk)
  // other store enhancers if any
));
