/**
 * http://usejsdoc.org/
 */
 import React, { Component } from 'react';
 import {
     Card, CardImg, CardText, CardBody,
     CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
 } from 'reactstrap';
 import { Control, LocalForm, Errors } from 'react-redux-form';
 import jumpersStore from '../../../stores/jumpers-store';
 
 import { components } from 'react-select';
 import AsyncSelect from 'react-select/lib/Async';
 
 import loadsStore from '../../../stores/loads-store';
 import JmpModal from './customModal';
 import Fuse from "fuse.js";
 import ReactDOM from 'react-dom';
 import { ClipLoader } from 'react-spinners';
 import Select from 'react-select';
 
 
 const customStyles = {
     control: base => ({
         ...base,
         minHeight: 30
     }),
     menu: (provided, state) => ({
         ...provided,
         borderBottom: "1px dotted pink",
         color: state.isSelected ? "red" : "blue",
         padding: 20,
         height: 730
     }),
     menuList: (provided, state) => ({
         ...provided,
         borderBottom: "1px dotted pink",
         padding: 20,
         minHeight: 700
     })
 };
 
 class AddJumpers extends Component {
 
     constructor(props) {
 
         super(props);
         this.toggleModal = this.toggleModal.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
         this.handleTransferCredit = this.handleTransferCredit.bind(this);
         this.toggleError = this.toggleError.bind(this);
         // this.updateGear = this.updateGear.bind(this);
         // this.MultiValueLabel = this.MultiValueLabel.bind(this);
 
         this.state = {
             isModalOpen: false,
             jumperOptions: [],
             tandemOptions: [],
             adminDefaultOptions: [],
             ErrorMessage: '',
             placement: 'bottom',
             errors: {},
             fromId: '',
             toId: '',
             isToTandem: false,
             isFromTandem: false,
             isloading: false,
             errorOpen: false,
             transferValue: 0,
             transferTaxValue: 0,
             transferCategory: "",
             amount:!isNaN(Number(this.props.defaultAmount))? Number(this.props.defaultAmount).toFixed(2):0,
             taxAmount: !isNaN(Number(this.props.defaultTaxAmount)) ? Number(this.props.defaultTaxAmount).toFixed(2):0
         };
 
         this.handleJumpersLoaded = this.handleJumpersLoaded.bind(this);
 
         this.jumperEvents = [
             { name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded },
             { name: jumpersStore.getEventPrefix('creditTransferEvent'), func: this.handleTransferCredit }
         ];
     }
 
 
     toggleModal() {
         this.setState({
             isModalOpen: !this.state.isModalOpen,
             isToTandem: false,
             isFromTandem: false,
             errors: {},
             transferCategory:"",
             amount: Number(this.props.defaultAmount).toFixed(2),
             taxAmount: Number(this.props.defaultTaxAmount).toFixed(2)
         });
         // this.gearIds.length = 0;
     }
 
     handleSubmit = (newValue) => {
         var payLoad = {};
         payLoad.toId = this.state.toId;
         let values = ['toTandem','toCategory'];
         for (let i =0; i < values.length; i++){
             this.handleValidation(values[i]);
         }
         if(this.state.errors["toTandem"] !== "" || this.state.errors["toCategory"] !== "" ){
             return ;
         }
         if (this.props.isTandemStudent) {
             payLoad.fromId = this.props.jumperId;
             //payLoad.toId = this.props.tandemInstructorId;
         }
         else {
             payLoad.fromId = this.state.fromId;
         }
         payLoad.fromCategory = this.props.fromCategory;
         payLoad.transferCategory = this.state.transferCategory;
         payLoad.price = this.state.transferValue;
         payLoad.taxAmount = this.state.transferTaxValue;
         payLoad.isFromTandem = true
         payLoad.isToTandem = true;
         payLoad.isInternal = true;
         if (newValue.memo !== "") {
             payLoad.memo = newValue.memo;
         }
 
         console.log(payLoad);
         jumpersStore.transferInternalTandem(payLoad);
         
         
 
         /*
         if (this.state.toId != undefined && this.state.fromId != undefined && !this.props.isTandemStudent) {
             if (this.state.isToTandem) {
                 payLoad.isToTandem = true;
                 jumpersStore.transferCreditToTandem(payLoad);
 
             }
             else {
                 jumpersStore.transferCredit(payLoad);
             }
             this.setState({ isloading: true });
         }
         else if (this.props.isTandemStudent) {
             if (this.state.isToTandem) {
                 payLoad.isToTandem = true;
                 payLoad.isFromTandem = true;
                 jumpersStore.transferCreditToTandem(payLoad);
 
             }
             else {
                 jumpersStore.addTip(payLoad);
             }
         }*/
     };
 
     componentDidMount = () => {
         //console.log('in components did mount accounts');
         this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
 
         var options = jumpersStore.getJumperOptions();
 
         var tandemOptions = jumpersStore.getTandemStudentOptions().filter(option => this.props.tandemIds?.includes(option.value.toString()));
         console.log('in component will receive props');
         var toId = '';
         if (this.props.tandemInstructorId !== undefined) {
             toId = this.props.tandemInstructorId;
             this.setState({
                 fromId: this.props.jumperId,
                 toId: toId
             })
         }
         this.setState({ jumperOptions: options, tandemOptions: tandemOptions, adminDefaultOptions: options.slice(0, 50),amount:this.props.defaultAmount });
     }
 
     componentWillUnmount = () => {
         this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
     }
 
     /*
     componentWillReceiveProps()
     {
       console.log('in component will receive props');
         var toId = '';
         if(this.props.tandemInstructorId !== undefined){
             toId = this.props.tandemInstructorId;
         this.setState({
             fromId: this.props.jumperId,
             toId: toId
         })
         }
 
     }*/
 
     filterJumpers = (inputValue, jumperOptions) => {
         //console.log("in filterJumpers");
         if (inputValue) {
             //console.log(inputValue);
             var filteredOptions = jumperOptions.filter(i =>
                 i.label.toLowerCase().includes(inputValue.toLowerCase()));
             ////console.log(filteredOptions);
             if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
             else return filteredOptions;
         }
         //console.log('no inputValue');
         return jumperOptions.slice(0, 30);
     };
 
     promiseOptions = (inputValue, jumperOptions) => {
         //console.log('in pomiseOptions');
         //console.log(this.inputValue);
         return new Promise(resolve => {
             resolve(this.filterJumpers(this.inputValue, jumperOptions));
         });
     }
 
     handleInputChange = (newValue) => {
         //console.log('handleInputChange');
 
         //const inputValue = newValue.replace(/\W/g, '');
         ////console.log(inputValue);
 
         //this.promiseOptions(newValue, jumperOptions);
         this.inputValue = newValue;
         //return inputValue;
     };
 
     handleFromSelect = (val) => {
         //console.log('in handleSelect');
         if (val.value !== undefined) {
             ////alert(JSON.stringify(val.value));
             // this.onJumperChanged(val.value);
             // jumpersStore.getDailyActivity(val.value._id);
             this.setState({
                 fromId: val.value._id
             });
             //console.log(JSON.stringify(val.value));
         }
     }
 
     handleToSelect = (val) => {
         console.log('in handleSelect');
         if (val.value !== undefined) {
             ////alert(JSON.stringify(val.value));
             // this.onJumperChanged(val.value);
             // jumpersStore.getDailyActivity(val.value._id);
             var transferValue = jumpersStore.getDailyActivityFrontEnd(val.value._id);
             var stateUpdate = {
                 toId: val.value._id,
                 transferValue: transferValue.toFixed(2)
             }
             var transferTaxValue = 0;
             if (loadsStore.isTaxEnabled()) {
                 transferTaxValue = jumpersStore.getDailyActivityTaxFrontEnd(val.value._id);
                 stateUpdate = {
                     toId: val.value._id,
                     transferValue: transferValue.toFixed(2),
                     transferTaxValue: transferTaxValue.toFixed(2)
                 }
             }
             console.log(stateUpdate);
             this.setState(stateUpdate, () => {
                 console.log(this.state);
             });
             //console.log(JSON.stringify(val.value));
         }
     }
 
     handleToTandemSelect = (val) => {
         console.log('in handleToTandemSelect');
         if (val.value !== undefined) {
             // alert(JSON.stringify(val.value));
             // this.onJumperChanged(val.value);
             // jumpersStore.getDailyActivity(val.value._id);

             var transferValue = this.state.amount;
             var stateUpdate = {
                 toId: val.value,
                 transferValue: transferValue,
                 isToTandem: true
             }
             var transferTaxValue = 0;
             if (loadsStore.isTaxEnabled()) {
                 transferTaxValue = this.state.taxAmount;
                 stateUpdate = {
                     toId: val.value,
                     transferValue: transferValue,
                     transferTaxValue: transferTaxValue? transferTaxValue : 0,
                     isToTandem: true
                 }
             }

             this.setState(stateUpdate,()=>{
                 this.handleValidation("toTandem");
             });
             //console.log(JSON.stringify(val.value));
         }
     }
 
     Option = (props) => {
         //console.log(props);
         return (
             <div className="row">
                 <div className="col-12">
                     <components.Option {...props} />
                 </div>
             </div>
         );
     }
 
     handleJumpersLoaded(jumperOptions) {
         //console.log('in handle jumpers loaded in accounts');
         var adminDefaultOptions = jumperOptions.slice(0, 50);
         //alert('handle Jumpers Loaded');
         this.setState({
             jumperOptions: jumperOptions,
             adminDefaultOptions: adminDefaultOptions
         });
     }
 
     toggleError() {
     this.setState({
         errorOpen: !this.state.errorOpen
     });
     }
 
     handleChange = (e) => {
         let amountData = this.state.amount;
         let taxData = this.state.taxAmount;
         if(e.target.name === "amount"){
             if(e.target.value <= this.props.defaultAmount ){
                 amountData = e.target.value;
                 taxData = (Number(this.props.defaultTaxAmount)/Number(this.props.defaultAmount))*amountData;
                 this.setState({ amount : amountData,
                     taxAmount : taxData.toFixed(2)
                 })
             }
             else{
                 this.setState({amountData : this.props.defaultAmount.toFixed(2)})
             }
         }
         if(e.target.name === "tax"){
             if(e.target.value <= this.props.defaultTaxAmount){
                 taxData = e.target.value;
                 this.setState({taxAmount : taxData})
             }
             else{
                 this.setState({taxData : this.props.defaultTaxAmount.toFixed(2)})
             }
             
         }
         this.setState({
             transferValue:amountData,
             transferTaxValue: taxData
         })
     }
 
    handleCategoryChange = (e) => {
        this.setState({
            transferCategory: e.target.value
        },()=>{
            this.handleValidation("toCategory");
        })
    }
 
    handleTransferCredit(data) {
        if (data === true) {
             this.setState({
                 isloading: false,
                 isModalOpen: false
             })
        }
        else {
             this.setState({
                 isloading: false,
                 isModalOpen: false,
                 errorOpen: true
            })
             //console.log('fail');
        }
    }
 
    handleInput = (event, key) => {
       var cache = { ...this.state };
       cache[key] = event.target.value;
       this.setState({ ...cache }, () => {
          this.handleValidation(key);
       });
         // if (!this.state.errors.length)
         //     tandemData.updateFormWithIndex(cache, this.props.index - 1);
    };
 
    handleValidation(name) {
        let fields = this.state;
        let errors = fields.errors;
        if (fields[name] === '') errors[name] = "This field is required";
        else if (name === 'amount' && !fields[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Amount should be enter in two decimal places.";
        else errors[name] = "";
        if(!this.state.isToTandem && name === "toTandem"){
            errors["toTandem"] = "This field is required";
        }
        else if(this.state.isToTandem){
            errors["toTandem"] = "";
        }
        if(!this.state.transferCategory && name === "toCategory"){
            errors["toCategory"] = "Please select an option";
        }else if(this.state.transferCategory){
            errors["toCategory"] = "";
        }
        this.setState({ errors: errors });
        return;
    }
 
 
 
     render() {
         var screenWidth = window.innerWidth;
         var defaultValue = '';
         if (this.state.fromId && this.state.jumperOptions.length > 0 && !this.props.isTandemStudent) {
             var defaultValue = this.state.jumperOptions.filter(x => x.value._id === this.state.fromId)[0]?.label;
         }
 
         var defaultTandemInstructor = '';
 
 
 
         if (this.props.isTandemStudent && this.state.jumperOptions.length > 0 && this.props.tandemInstructorId !== '') {
             console.log(this.props.tandemInstructorId);
             console.log(this.state.jumperOptions.map(option => option._id));
             var defaultTandemInstructor = this.state.jumperOptions.filter(x => x.value._id.toString() === this.props.tandemInstructorId.toString())[0].label;
         }
 
         var modalMargin = '25%';
         if (screenWidth >= 1920) {
             modalMargin = '65%';
         }
 
         const override = `
         display: block;
         margin: 0 auto;
         border-color: red;
         `;
 
         return (
             <React.Fragment>
                 <JmpModal
                     isModalOpen={this.state.isModalOpen}
                     title={`Transfer Credit`}
                 >
                     <div className='complete-loading'>
                         <ClipLoader
                             css={override}
                             sizeUnit={"px"}
                             size={35}
                             color={'#fff'}
                             loading={this.state.isloading}
                         />
                     </div>
 
 
                     <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
 
                         {!this.props.isTandemStudent ?
                             <Row className="form-group">
                                 <Label htmlFor="From" md={3}>From</Label>
                                 <Col md={9}>
                                     <AsyncSelect
                                         loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
                                         defaultInputValue={defaultValue}
                                         styles={customStyles} onInputChange={(val) => this.handleInputChange(val)}
                                         defaultOptions={this.state.adminDefaultOptions}
                                         onChange={(val) => this.handleFromSelect(val)}
                                         components={{ Option: this.Option }}
                                         cacheOptions={true}
 
                                     />
                                 </Col>
                             </Row>
                             :
                             null
                         }
 
 
                         <Row className="form-group">
                             <Label htmlFor="ToTandem" md={3}>To (Tandems)</Label>
                             <Col md={9}>
                                 <Select
                                     name="toTandem"
                                     styles={customStyles}
                                     isClearable
                                     onChange={(val) => this.handleToTandemSelect(val,"toTandem")}
                                     options={this.state.tandemOptions}
                                 />
                                 <span className="text-danger error-validation">{this.state.errors["toTandem"]}</span>
                             </Col>
                         </Row>
 
                         <Row className="form-group">
                             <Label htmlFor="Amount" md={3}>Amount</Label>
                             <Col md={9}>
                                 <Control.input name="amount" model=".amount" className="form-control"
                                     mapProps={{ onChange: (props) => props.onChange }} value={this.state.amount}
                                     onChange={(e)=>this.handleChange(e,'amount')} autoComplete="off" />
                                 <span className="text-danger error-validation">{this.state.errors["amount"]}</span>
                             </Col>
                         </Row>
 
 
                         {loadsStore.isTaxEnabled() ?
                             <Row className="form-group">
                                 <Label htmlFor="Amount" md={3}>Tax Amount</Label>
                                 <Col md={9}>
                                     <Control.input name="tax" model=".tax" className="form-control"
                                         mapProps={{ onChange: (props) => props.onChange }} value={this.state.taxAmount}
                                         onChange={(e) => this.handleChange(e, 'tax')}  autoComplete="off" />
                                     <span className="text-danger error-validation">{this.state.errors["taxAmount"]}</span>
                                 </Col>
                             </Row> : null}
 
                         <Row className="form-group">
                             <Label htmlFor="To Category" md={3}>To Category</Label>
                             <Col md={9}>
                                 <div className='transferCategoryRadioBtn'>
                                     <Control.radio name="toCategory" model=".toCategory" className="form-control"
                                         mapProps={{ onChange: (props) => props.onChange }} value={"video"} label="video"
                                         onChange={this.handleCategoryChange} autoComplete="off" />
                                     <span>Video Paid</span>
                                 </div>
                                 <div className='transferCategoryRadioBtn'>
                                     <Control.radio name="toCategory" model=".toCategory" className="form-control"
                                         mapProps={{ onChange: (props) => props.onChange }} value={"tandem"} label="tandem"
                                         onChange={this.handleCategoryChange} autoComplete="off" />
                                     <span>Tandem Paid</span>
                                 </div>
                                 <div className='transferCategoryRadioBtn'>
                                     <Control.radio name="toCategory" model=".toCategory" className="form-control"
                                         mapProps={{ onChange: (props) => props.onChange }} value={"overweight"} label="tandem"
                                         onChange={this.handleCategoryChange} autoComplete="off" />
                                     <span>Overweight</span>
                                 </div>
                                 <div className='transferCategoryRadioBtn'>
                                     <Control.radio name="toCategory" model=".toCategory" className="form-control"
                                         mapProps={{ onChange: (props) => props.onChange }} value={"others"} label="others"
                                         onChange={this.handleCategoryChange} autoComplete="off" />
                                     <span>Others</span>
                                 </div>
                                 <span className="text-danger error-validation">{this.state.errors["toCategory"]}</span>
                             </Col>
                         </Row>
 
                         <Row className="form-group">
                             <Label htmlFor="Amount" md={3}>Memo</Label>
                             <Col md={9}>
                                 <Control.input name="memo" model=".memo" className="form-control"
                                     mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.value}
                                     onChange={(e) => this.handleInput(e, 'memo')} autoComplete="off" />
                             </Col>
                         </Row>
 
 
                         <Row className="form-group">
                             <Col md={{ size: 10 }}>
                                 <button className="btn btn-bg lightColor createButton" type="submit" >Submit</button>
                                 <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">Cancel</button>
                             </Col>
                         </Row>
 
                     </LocalForm>
                 </JmpModal>
                 <Modal isOpen={this.state.errorOpen} toggle={this.toggleError} className={this.props.className}>
                     <ModalHeader toggle={this.toggleError} className="error-jumperHeader">
                         Error
                     </ModalHeader>
                     <ModalBody>
                         <p style={{ color: 'red', fontSize: '20px', marginLeft: '1%' }}>Something Wrong happened while Transfer Credits !</p>
                     </ModalBody>
                 </Modal>
                 <span className="fa fa-exchange fa-xs" onClick={this.toggleModal}>
 
                 </span>
                 {/* <button className="btn customButton rightButton" style={{ borderRadius: '4px',backgroundColor: '#A7F6FF',color: '#082764',marginTop:'65%',marginBottom:'8px' }} onClick={this.toggleModal}>
                     TRANSFER CREDIT
                 </button> */}
             </React.Fragment>
         );
     }
 }
 
 export default AddJumpers
 