/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import loadsStore from '../../stores/loads-store'
import jumpersStore from '../../stores/jumpers-store'
import itemStore from '../../stores/item-store'
import scheduleStore from "../../stores/schedule-store";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import RenderFunJump from './RenderFunJump';
import RenderTandemJump from './RenderTandemJump';
import RenderCoachJump from './RenderCoachJump';
import RenderAffJump from './RenderAffJump';
import RenderIadJump from "./RenderIadJump";
import RenderPilotCard from "./RenderPilotCard";
import { DropTarget } from 'react-dnd'
import CreateFunJumpCard from './modals/CreateFunJumpCard';
import EditableLoadPlane from './modals/editableLoadPlane';
import LoadCapacity from './modals/loadCapacity';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PrintPdf from '../PrintPdf'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "../css/loadManager.css";
import LoadHeader from './subComponents/LoadHeader';

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    // hovered: monitor.isOver(),
    // item: monitor.getItem(),
  }
}

const loadTarget = {
  hover(props, monitor, component) {
  },
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }

    const dragItem = monitor.getItem();
    const hoverItem = props;

    var targetLoad = props.load.loadNum;
    var loadNum = dragItem.loadNum;
    var jumpId = dragItem.jumpId;
    var jumpType = dragItem.jumpType;

    if (loadNum == targetLoad) {
      return;
    }

    if (dragItem.jumpType === "funJump") {
      var moveJumpType = dragItem.funJump.jumpType;
      var jumperIdArray = [];
      var gearRentArray = [];
      var jumperIds = dragItem.funJump.jumpers;
      var gearRentals = dragItem.funJump.gearRentals;
      jumperIds.forEach(element => {
        jumperIdArray.push(element._id);
      })
      if (gearRentals.length > 0) {
        gearRentals.forEach(element => {
          if (element != null) {
            gearRentArray.push(element);
          }
        })
      }
      var jumpPostBody = {
        jumpType: moveJumpType,
        jumperIds: jumperIdArray,
        gearRentals: gearRentArray
      };
      var removeBody = {
        loadNum: loadNum,
        jumpId: jumpId,
        jumpType: jumpType
      }
      dragItem.handleRemoveFun(targetLoad, jumpPostBody, removeBody);
    }
    else if (dragItem.jumpType === "tandemJump") {
      var today = new Date();
      var isWeekend = (today.getDay() % 6 === 0);


      var instructor = dragItem.tandemJump.instructor._id;
      var student = dragItem.tandemJump.student._id;
      var videographer = "";
      if (dragItem.tandemJump.videographer != undefined) {
        videographer = dragItem.tandemJump.videographer._id;
      }

      var tandemBody = {
        instructorId: instructor,
        studentId: student,
        isWeekend: isWeekend
      };

      if (videographer != "") {
        tandemBody.videographerId = videographer;

      }
      var removeBody = {
        loadNum: loadNum,
        jumpId: jumpId,
        jumpType: jumpType
      }

      dragItem.handleTandemDrop(targetLoad, tandemBody, removeBody);
    }
    else if (dragItem.jumpType === "affJump") {

      var level = dragItem.affJump.level;
      var mainInstructor = dragItem.affJump.mainInstructor._id;
      var student = dragItem.affJump.student._id;
      var secondaryInstructor = "";

      if (dragItem.affJump.secondaryInstructor != undefined) {
        secondaryInstructor = dragItem.affJump.secondaryInstructor._id;
      }

      var videographer = "";
      if (dragItem.affJump.videographer != undefined) {
        videographer = dragItem.affJump.videographer._id;
      }

      jumpPostBody = {
        level: parseInt(level),
        mainInstructorId: mainInstructor,
        studentId: student
      };

      if (secondaryInstructor != "") {
        jumpPostBody.secondaryInstructorId = secondaryInstructor
      }

      if (videographer != "") {
        jumpPostBody.videographerId = videographer
      }

      var removeBody = {
        loadNum: loadNum,
        jumpId: jumpId,
        jumpType: jumpType
      }

      dragItem.handleCreateAff(targetLoad, jumpPostBody, removeBody);
    }
    else if (dragItem.jumpType === "coachJump") {
      var coachBody = {
        coachId: dragItem.coachJump.coach._id,
        jumperId: dragItem.coachJump.jumper._id
      };

      var removeBody = {
        loadNum: loadNum,
        jumpId: jumpId,
        jumpType: jumpType
      }

      dragItem.handleCoachDrop(targetLoad, coachBody, removeBody);
    }
  }
}

class Load extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jumpers: [],
      slotsRemaining: 0,
      load: props.load,
      loadNum: props.loadNum,
      loadIndx: props.loadIndx,
      creatingFunJump: false,
      printData: {},
      timeslots: []
    }
    this.setJumpers = this.setJumpers.bind(this);
    this.handleLoadStatusToggleEvent = this.handleLoadStatusToggleEvent.bind(this);
    this.loadEvents = [
      { name: loadsStore.getEventPrefix('cancelCreateJump'), func: this.handleCancelCreateJump },
      { name: loadsStore.getEventPrefix('updateLoad'), func: this.handleLoadStatusToggleEvent },
      { name: loadsStore.getEventPrefix('toggleLoadStatus'), func: this.handleLoadStatusToggleEvent },
      {
        name: loadsStore.getEventPrefix("updateLoad_" + props.loadNum),
        func: this.handleUpdateThisLoad
      },
      { name: loadsStore.getEventPrefix('createLoad_' + props.loadIndx), func: this.handleCreateNewLoad },

    ];
    this.scheduleEvents = [
      {
        name: scheduleStore.getEventPrefix("todayReservationEvent"),
        func: this.handleReservationEvent
      }
    ];
  }

  DeleteJumper = (id) => {
  }


  handleClick(e, data) {
    // console.log(data.foo);
  }

  handleJumperDrop(loadNum, jumpId, jumpType) {
    if (jumpType === "tandemJump") {
      loadsStore.cancelTandemJump(loadNum, jumpId);
    }
    else if (jumpType === "affJump") {
      loadsStore.cancelAffJump(loadNum, jumpId);
    }
    else if (jumpType === "funJump") {
      loadsStore.cancelFunJump(loadNum, jumpId);
    }
    else if (jumpType === "coachJump") {
      loadsStore.cancelCoachJump(loadNum, jumpId);
    }
  }

  handleCreateFun(loadNum, jumpBody) {
    var that = this;
    Promise.all([loadsStore.createFunJump(loadNum, jumpBody)])
      .then(function (result) {
        if (result[0] === true) {
          // console.log(result[0]);
        }
      })
  }

  handleRemoveFun(loadNum, jumpBody, removeBody) {
    var that = this;
    var removeloadNum = removeBody.loadNum;
    var newLoadNum = loadNum;
    var jumpId = removeBody.jumpId;
    var jumpType = removeBody.jumpType;

    Promise.all([loadsStore.cancelFunJump(removeloadNum, jumpId)])
      .then(function (result) {
        if (result[0] === true) {
          var loadNum = newLoadNum;

          that.handleCreateFun(loadNum, jumpBody);
        }
      })
  }

  handleCreateAff(loadNum, affPostBody, removeBody) {
    var that = this;
    Promise.all([loadsStore.createAffJump(loadNum, affPostBody)])
      .then(function (result) {
        if (result[0] === true) {
          var loadNum = removeBody.loadNum;
          var jumpId = removeBody.jumpId;
          var jumpType = removeBody.jumpType;
          that.handleJumperDrop(loadNum, jumpId, jumpType);
        }
      })
  }

  showPrint = (data, id, loadNum, panelID) => {
    this.setState({
      printData: data,
      loadIdfor: id
    }, () => {
      // const input = document.getElementById("print-panel")
      // input.style.visibility = "visible"
      // console.log("SD")
      this.PrintLoad(id, loadNum, panelID)
    })
  }

  PrintLoad = (id, loadNum, panelID) => {
    const input = document.getElementById(panelID);
    input.style.visibility = "visible";

    var pdfName = id + ".pdf";
    html2canvas(input)
      .then((canvas) => {
        // console.log(canvas)
        const imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 310;
        var imgHeight = (canvas.height * imgWidth / canvas.width);
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm", "a4");
        var position = 0;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        // console.log(doc.output)
        // console.log(doc.output(""))
        doc.save(pdfName);
        input.style.visibility = "hidden"
      });
  }

  handleCoachDrop(loadNum, coachBody, removeBody) {
    var that = this;
    Promise.all([loadsStore.createCoachJump(loadNum, coachBody)])
      .then(function (result) {
        if (result[0] === true) {
          var loadNum = removeBody.loadNum;
          var jumpId = removeBody.jumpId;
          var jumpType = removeBody.jumpType;

          that.handleJumperDrop(loadNum, jumpId, jumpType);
        }
      })
  }

  handleTandemDrop(loadNum, tandemBody, removeBody) {
    var that = this;
    Promise.all([loadsStore.createTandemJump(loadNum, tandemBody)])
      .then(function (result) {
        if (result[0] === true) {
          var loadNum = removeBody.loadNum;
          var jumpId = removeBody.jumpId;
          var jumpType = removeBody.jumpType;
          that.handleJumperDrop(loadNum, jumpId, jumpType);
        }
      })
  }

  handleReservationEvent = data => {
    this.setState({
      timeslots: data.timeslots
    });
  };

  componentDidMount = () => {
    this._isMounted = true;
  }

  componentWillMount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
    this.scheduleEvents.forEach(evnt => scheduleStore.on(evnt.name, evnt.func));
  }
  componentWillUnmount = () => {
    console.log('***** load history in component will unmount ' + this.state.load.loadNum);

    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    this.scheduleEvents.forEach(evnt => scheduleStore.removeListener(evnt.name, evnt.func));
  }

  setJumpers(load) {
    var numJumpers = 0;
    this.setState({
      slotsRemaining: 25 - load.totalJumpers
    })
  }

  toggleLoadStatus(closed) {
    if (closed) {
      loadsStore.openLoad(this.state.load.loadNum);
    }
    else {
      loadsStore.closeLoad(this.state.load.loadNum);
    }
  }

  handleCancelCreateJump = (loadNum) => {
    if (this.state.load.loadNum === loadNum) {
      this.setState({ creatingFunJump: false });
    }
  }

  handleUpdateThisLoad = (load) => {
    //alert('in handle update this load');
    console.log("in handle update This completed load");
    console.log(load.loadNum);
    console.log(load);
    this.setState({ load: load })
  }
  handleCreateNewLoad = (load) => {
    //alert('create new load');
    //console.log('handle create new load');
    //console.log(load.loadNum);
    //console.log(load);

    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));

    this.setState({
      key: load.key ? load.key : null,
      load: load,
      loadNum: load.loadNum ? load.loadNum : null
    });


    this.loadEvents = [
      { name: loadsStore.getEventPrefix('cancelCreateJump'), func: this.handleCancelCreateJump },
      { name: loadsStore.getEventPrefix('updateLoad'), func: this.handleLoadStatusToggleEvent },
      { name: loadsStore.getEventPrefix('toggleLoadStatus'), func: this.handleLoadStatusToggleEvent },
      { name: loadsStore.getEventPrefix('createLoad_' + this.props.loadIndx), func: this.handleCreateNewLoad },
    ];
    if (load.loadNum) {
      this.loadEvents.push({ name: loadsStore.getEventPrefix('updateLoad_' + load.loadNum), func: this.handleUpdateThisLoad });
    }
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
  }

  handleLoadStatusToggleEvent(load) {
    this.setState({
      load: load
    });

    if (load.closed) {
      this.setState({
        background: '#a4abad'
      });
    }
    else {
      this.setState({
        background: '#e7f2f6'
      });
    }
  }

  removeFunJumper = (loadNum, jumpId, jumperId) => {
    loadsStore.removeJumper(loadNum, jumpId, jumperId);
  }


  createFunJump = () => {
    this.setState({ creatingFunJump: true });
  }

  createCoachJump = (e) => {
    var loadNum = e.target.getAttribute('data-key');
    loadsStore.createCoachJump(loadNum, {});
  }


  createAffJump = (e) => {
    var loadNum = e.target.getAttribute('data-key');
    loadsStore.createAffJump(loadNum, {});
  }

  createTandemJump = (e) => {
    var loadNum = e.target.getAttribute('data-key');
    var today = new Date();
    var isWeekend = (today.getDay() % 6 === 0)
    loadsStore.createTandemJump(loadNum, { isWeekend: isWeekend });
  }

  RenderAltGroup = (props) => {
    if (Object.getOwnPropertyNames(this.state.load).length === 0) {
      return <div className="col" style={{ padding: "0px !important" }}></div>;
    }
    else {

      var tandemInstructorOptions = this.props.tandemInstructorOptions;

      var affInstructorOptions = this.props.affInstructorOptions;

      var affStudentOptions = this.props.affStudentOptions;

      var videographerOptions = this.props.videographerOptions;

      var iadStudentOptions = this.props.iadStudentOptions

      var jumperOptions = this.props.jumperOptions;

      var coachOptions = this.props.coachOptions;

      var pilotOptions = jumpersStore.getPilotOptions();

      var tandemStudentOptions = this.props.tandemStudentOptions
        .map(option => {
          return { label: option.label, value: option.value };
        });

      const tandemJumps = props.tandemJumps
        .filter(jump => !jump.isUltra)
        .map((tandemJump, i) => {
          let globalDiscount = 0;
          let discountValue = 0;
          this.state.timeslots.forEach(timeItem => {
            if (timeItem.reservations) {
              timeItem.reservations.forEach(reservation => {
                if (reservation.discountValue && reservation.tandemStudents) {
                  globalDiscount = reservation.discountValue;
                  reservation.tandemStudents.forEach(stud => {
                    if (new Date(stud.reservationTime).getTime() == new Date(tandemJump.student.reservationTime).getTime() && `${stud._id}` == `${tandemJump.student._id}`) {
                      const groupMemebers = reservation.tandemStudents.reduce((acc, value) => {
                        if (new Date(stud.reservationTime).getTime() == new Date(tandemJump.student.reservationTime).getTime() && value.groupId == tandemJump.student.studentGroupId) {
                          acc = acc + 1;
                        }
                        return acc;
                      }, 0)
                      discountValue = globalDiscount / groupMemebers;
                    }
                  })
                }
              })
            }
          })
          return (
            <CSSTransition key={i} timeout={500} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderTandemJump
                  id={"tandem" + i}
                  key={i}
                  isToday={this.props.isToday}
                  tandemJump={tandemJump}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadNum={this.state.load.loadNum}
                  jumpId={tandemJump.jumpId}
                  tandemInstructorOptions={tandemInstructorOptions}
                  videographerOptions={videographerOptions}
                  tandemStudentOptions={tandemStudentOptions}
                  jumpType="tandemJump"
                  price={tandemJump.price}
                  discountValue={discountValue}
                  isCompleted={true}
                  history={this.props.history}
                  loadId={this.state.load._id}
                  handleJumperDrop={this.handleJumperDrop}
                  handleTandemDrop={this.handleTandemDrop}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });

      const affJumps = props.affJumps.map((affJump, i) => {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderAffJump
                history={this.props.history}
                loads={this.props.loads}
                load={this.state.load}
                id={"Coach" + i} key={i} affJump={affJump} isToday={this.props.isToday} loadNum={this.state.load.loadNum} jumpId={affJump.jumpId} affInstructorOptions={affInstructorOptions}
                videographerOptions={videographerOptions} affStudentOptions={affStudentOptions} jumpType="affJump" price={affJump.price} loadId={this.state.load._id}
                handleJumperDrop={this.handleJumperDrop} handleCreateAff={this.handleCreateAff} isCompleted={true}
                loadsToShow={props.loadsToShow}
                columnsPerGroup={props.columnsPerGroup}
                fontSize={props.fontSize}
                isInline={props.isInline}
              />
            </div>
          </CSSTransition>
        );
      });

      const iadJumps = props.iadJumps.map((iadJump, i) => {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderIadJump
                history={this.props.history}
                id={"Iad" + i}
                key={i}
                iadJump={iadJump}
                loadNum={this.state.load.loadNum}
                jumpId={iadJump.jumpId}
                instructorOptions={tandemInstructorOptions}
                videographerOptions={videographerOptions}
                jumperOptions={iadStudentOptions}
                jumpType="iadJump"
                handleJumperDrop={this.handleJumperDrop}
                handleCreateIad={this.handleCreateIad}
                isCompleted={false}
                loadsToShow={props.loadsToShow}
                columnsPerGroup={props.columnsPerGroup}
                fontSize={props.fontSize}
                isInline={props.isInline}
              />
            </div>
          </CSSTransition>
        );
      });


      const coachJumps = props.coachJumps
        .filter(jump => jump.isWingsuit !== true)
        .map((coachJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderCoachJump
                  history={this.props.history}
                  loads={this.props.loads}
                  load={this.state.load}
                  id={"Coach" + i} key={i} isToday={this.props.isToday} coachJump={coachJump} loadNum={this.state.load.loadNum} loadId={this.state.load._id}
                  jumpId={coachJump.jumpId} coachOptions={coachOptions} jumpType="coachJump" price={coachJump.price}
                  handleJumperDrop={this.handleJumperDrop} handleCoachDrop={this.handleCoachDrop} jumperOptions={jumperOptions} isCompleted={true}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });

      const wingsuitCoachJumps = props.coachJumps
        .filter(jump => jump.isWingsuit)
        .map((coachJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderCoachJump
                  history={this.props.history}
                  loads={this.props.loads}
                  load={this.state.load}

                  id={"Coach" + i} key={i} isToday={this.props.isToday} coachJump={coachJump} loadNum={this.state.load.loadNum} loadId={this.state.load._id}
                  jumpId={coachJump.jumpId} coachOptions={coachOptions} jumpType="coachJump" price={coachJump.price}
                  handleJumperDrop={this.handleJumperDrop} handleCoachDrop={this.handleCoachDrop} jumperOptions={jumperOptions} isCompleted={true}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });

      const wingSuitJumps = props.funJumps
        .filter(jump => jump.jumpType === "Wingsuit")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} funJump={funJump} loads={this.props.loads} loadNum={this.state.load.loadNum} disabled={this.state.load.closed}
                  jumpId={funJump.jumpId} jumperOptions={jumperOptions} jumpType="funJump" loadId={this.state.load._id} isCompleted={true}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const crwJumps = props.funJumps
        .filter(jump => jump.jumpType === "CRW")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} funJump={funJump} loads={this.props.loads} loadNum={this.state.load.loadNum} disabled={this.state.load.closed}
                  jumpId={funJump.jumpId} jumperOptions={jumperOptions} jumpType="funJump" loadId={this.state.load._id} isCompleted={true}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const xrwJumps = props.funJumps
        .filter(jump => jump.jumpType === "XRW")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} funJump={funJump} loads={this.props.loads} loadNum={this.state.load.loadNum} disabled={this.state.load.closed}
                  jumpId={funJump.jumpId} jumperOptions={jumperOptions} jumpType="funJump" loadId={this.state.load._id} isCompleted={true}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const tandemPracticeJumps = props.funJumps
        .filter(jump => jump.jumpType === "Tandem Practice")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} funJump={funJump} loads={this.props.loads} loadNum={this.state.load.loadNum} disabled={this.state.load.closed}
                  jumpId={funJump.jumpId} jumperOptions={jumperOptions} jumpType="funJump" loadId={this.state.load._id} isCompleted={true}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });


      const bellyJumpsSolo = props.funJumps
        .filter(
          jump =>
            jump.jumpType === "Belly" &&
            jump.jumpers.length === 1
        )
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          //alert("rendering belly");
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const bellyJumps = props.funJumps
        .filter(
          jump =>
            jump.jumpType === "Belly" &&
            jump.jumpers.length > 1
        )
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          //alert("rendering belly");
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} funJump={funJump} loads={this.props.loads} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const hybridJumps = props.funJumps
        .filter(
          jump =>
            jump.jumpType === "Hybrid"
        )
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          //alert("rendering belly");
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} funJump={funJump} loads={this.props.loads} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const freeflyJumps = props.funJumps
        .filter(jump => jump.jumpType === "Freefly")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const hopnpops = props.funJumps
        .filter(
          jump =>
            jump.jumpType === "HopNPop" || jump.jumpType === "HopNPop Student"
        )
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const solos = props.funJumps
        .filter(jump => jump.jumpType === "Solo Student")
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const highpulls = props.funJumps
        .filter(jump => jump.jumpType === "High Pull")
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const trackingJumps = props.funJumps
        .filter(jump => jump.jumpType === "Tracking" && !jump.trackingFirstOut)
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });


      const trackingJumpsFirstOut = props.funJumps
        .filter(jump => jump.jumpType === "Tracking" && jump.trackingFirstOut)
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });

      const wrangleJumps = props.funJumps
        .filter(jump => jump.jumpType === "WRAngle")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}

                />
              </div>
            </CSSTransition>
          );
        });


      const angleTrack = props.funJumps
        .filter(jump => jump.jumpType === "Angle")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const speed = props.funJumps
        .filter(jump => jump.jumpType === "Speed")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={funJump} loadNum={this.state.load.loadNum} isCompleted={true}
                  disabled={this.state.load.closed} jumpId={funJump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });


      const undecidedJumps = props.funJumps
        .filter(jump => jump.jumpType === "Undecided")
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  loads={this.props.loads}
                  jumpType="funJump"
                  jumperOptions={jumperOptions}
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loadsToShow={props.loadsToShow}
                  columnsPerGroup={props.columnsPerGroup}
                  fontSize={props.fontSize}
                  isInline={props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      var IsEventEnable = this.props.isCompleted ? "none" : "";

      return (
        <React.Fragment>
          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {crwJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {xrwJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {highpulls}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {wingSuitJumps}
            </TransitionGroup>
          </div>


          <div
            className="row"
          >

            <TransitionGroup style={{ width: "100%" }}>
              {wingsuitCoachJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {wrangleJumps}
            </TransitionGroup>
          </div>



          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {trackingJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {tandemPracticeJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {tandemJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {iadJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {affJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {solos}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {freeflyJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {hybridJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {bellyJumpsSolo}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {coachJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {bellyJumps}
            </TransitionGroup>
          </div>
          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {trackingJumpsFirstOut}
            </TransitionGroup>
          </div>
          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {angleTrack}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {speed}
            </TransitionGroup>
          </div>

          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {hopnpops}
            </TransitionGroup>
          </div>
          <div
            className="row"
          >
            <TransitionGroup style={{ width: "100%" }}>
              {undecidedJumps}
            </TransitionGroup>
          </div>
        </React.Fragment>)
    }


  }

  returnColSize = () => {
    let { loadsToShow } = this.props;
    let screenWidth = window.innerWidth;
    if (this.state.load.isWaitlist) {
      return 'col-md-12';
    }
    if (screenWidth >= 1400) {
      if (loadsToShow >= 5 && loadsToShow <= 6) {
        return 'col-md-2';
      } else if (loadsToShow > 6) {
        return 'adjust-width-' + loadsToShow
      }
      else if (loadsToShow === 4) {
        return 'col-md-3'
      }
      else if (loadsToShow === 3) {
        return 'col-md-4'
      }
      else {
        return 'col-md-6';
      }
    }
    else if (screenWidth >= 992) {
      return 'col-md-4'
    }
    else if (screenWidth >= 500) {
      return 'col-md-6'
    }
    else {
      return 'col-md-10'
    }
  }

  render() {
    console.log('rendering completed load ' + this.state.load.loadNum);
    // console.log("printData",this.state.printData)
    var screenWidth = window.innerWidth;
    // let colsize = "";
    var colsize = this.returnColSize();

    // if (screenWidth >= 2600) {
    //   colsize = 'col-md-3 col-lg-3';

    // }
    // else if (screenWidth >= 2000 && screenWidth <= 2599) {
    //   colsize = 'col-md-4 col-lg-4';
    // }
    // else if (screenWidth >= 1366 && screenWidth <= 1999) {
    //   colsize = 'col-md-6 col-lg-6';
    //   // if(!this.props.rightMenuVisibile && this.props.isCompleted){
    //   //   colsize = 'col-md-4 col-lg-4';
    //   // }
    // }
    // else {
    //   colsize = 'col-md-12 col-lg-12';
    // }
    const alljumps = this.state.load?.alljumps?.map((jump, i) => {
      console.log('rendering all jumps');
      console.log(jump);
      if (jump.superType === "tandem") {
        return (
          <CSSTransition key={i} timeout={500} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderTandemJump
                id={"tandem" + i}
                key={i}
                isToday={this.props.isToday}
                tandemJump={jump}
                load={this.state.load}
                loads={this.props.loads}
                loadNum={this.state.load.loadNum}
                jumpId={jump.jumpId}
                tandemInstructorOptions={tandemInstructorOptions}
                videographerOptions={videographerOptions}
                tandemStudentOptions={tandemStudentOptions}
                jumpType="tandemJump"
                price={jump.price}
                discountValue={0}
                isCompleted={true}
                history={this.props.history}
                loadId={this.state.load._id}
                handleJumperDrop={this.handleJumperDrop}
                handleTandemDrop={this.handleTandemDrop}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.props.columnsPerGroup}
                fontSize={this.props.fontSize}
                isInline={this.props.isInline}
              />
            </div>
          </CSSTransition>
        );
      }
      else if (jump.superType == "student") {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderAffJump
                history={this.props.history}
                loads={this.props.loads}
                load={this.state.load}
                id={"Coach" + i} key={i} affJump={jump} isToday={this.props.isToday} loadNum={this.state.load.loadNum} jumpId={jump.jumpId} affInstructorOptions={affInstructorOptions}
                videographerOptions={videographerOptions} affStudentOptions={affStudentOptions} jumpType="affJump" price={jump.price} loadId={this.state.load._id}
                handleJumperDrop={this.handleJumperDrop} handleCreateAff={this.handleCreateAff} isCompleted={true}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.props.columnsPerGroup}
                fontSize={this.props.fontSize}
                isInline={this.props.isInline}
              />
            </div>
          </CSSTransition>
        );
      }
      else if (jump.superType === "coach") {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderCoachJump
                history={this.props.history}
                loads={this.props.loads}
                load={this.state.load}
                id={"Coach" + i} key={i} isToday={this.props.isToday} coachJump={jump} loadNum={this.state.load.loadNum} loadId={this.state.load._id}
                jumpId={jump.jumpId} coachOptions={coachOptions} jumpType="coachJump" price={jump.price}
                handleJumperDrop={this.handleJumperDrop} handleCoachDrop={this.handleCoachDrop} jumperOptions={jumperOptions} isCompleted={true}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.columnsPerGroup}
                fontSize={this.fontSize}
                isInline={this.isInline}
              />

            </div>
          </CSSTransition>
        );
      }
      else {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderFunJump id={"wing" + i} key={i} isToday={this.props.isToday} loads={this.props.loads} funJump={jump} loadNum={this.state.load.loadNum} isCompleted={true}
                disabled={this.state.load.closed} jumpId={jump.jumpId} jumpType="funJump" jumperOptions={jumperOptions}
                handleRemoveFun={this.handleRemoveFun} handleCreateFun={this.handleCreateFun} loadId={this.state.load._id}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.props.columnsPerGroup}
                fontSize={this.props.fontSize}
                isInline={this.props.isInline}
                load={this.state.load}

              />

            </div>
          </CSSTransition>
        )
      }
    })

    if (Object.getOwnPropertyNames(this.state.load).length === 0) {
      return (
        <div className="col" style={{ padding: '0px !important' }}>

        </div>
      );
    }
    else {
      var tandemInstructorOptions = this.props.tandemInstructorOptions;//.filter(option => this.state.load.activeJumperIds.findIndex(id => option.value._id === id) === -1);
      var affInstructorOptions = this.props.affInstructorOptions;//.filter(option => this.state.load.activeJumperIds.findIndex(id => option.value._id === id) === -1);
      var affStudentOptions = this.props.affStudentOptions;//.filter(option => this.state.load.activeJumperIds.findIndex(id => option.value._id === id) === -1);
      var videographerOptions = this.props.videographerOptions;//.filter(option => this.state.load.activeJumperIds.findIndex(id => option.value._id === id) === -1);
      var jumperOptions = this.props.jumperOptions;//.filter(option => this.state.load.activeJumperIds.findIndex(id => option.value._id === id) === -1);
      var coachOptions = this.props.coachOptions;//.filter(option => this.state.load.activeJumperIds.findIndex(id => option.value._id === id) === -1);
      var tandemStudentOptions = this.props.tandemStudentOptions;//.filter(option => this.state.load.activeTandemStudentIds.findIndex(id => option.value === id) === -1).map(option => { return { label: option.label, value: option.value } });
      var pilotOptions = jumpersStore.getPilotOptions();

      var altitudes = itemStore.getAltitudes();
      console.log(altitudes);
      var jumpsByAltitude = altitudes.map(altitude => {

        var tandemJumps = this.state.load.tandemJumps.filter(jump => altitude.name === jump.altitude);
        var affJumps = this.state.load.affJumps.filter(jump => altitude.name === jump.altitude);
        var coachJumps = this.state.load.coachJumps.filter(jump => altitude.name === jump.altitude);
        var funJumps = this.state.load.funJumps.filter(jump => altitude.name === jump.altitude);
       // var iadJumps = this.state.load.iadJumps.filter(jump => altitude.name === jump.altitude);

        return (<this.RenderAltGroup
          tandemJumps={tandemJumps}
          affJumps={affJumps}
          coachJumps={coachJumps}
          funJumps={funJumps}
         // iadJumps={iadJumps}
          loadsToShow={this.props.loadsToShow}
          columnsPerGroup={this.props.columnsPerGroup}
          fontSize={this.props.fontSize}
          isInline={this.props.isInline}
        />)
      })

      const undecidedJumps = this.state.load.funJumps.filter(jump => jump.jumpType === "Undecided").map((funJump, i) => {
        return (
          <CSSTransition
            key={i}
            timeout={700}
            classNames="fade"
          >
            <div id={i} key={i} className="col-12" >
              <RenderFunJump
                isToday={this.props.isToday}
                id={"wing" + i} key={i}
                funJump={funJump}
                loadNum={this.state.load.loadNum}
                disabled={this.state.load.closed}
                jumpId={funJump.jumpId}
                loads={this.props.loads}
                jumpType="funJump"
                jumperOptions={jumperOptions}
                loadId={this.state.load._id}
                handleRemoveFun={this.handleRemoveFun}
                handleCreateFun={this.handleCreateFun}
                isCompleted={true}
              />
            </div>
          </CSSTransition>
        );
      });


      var backgroundColor = this.state.load.closed || this.props.isCompleted ? '#a4abad' : '#e7f2f6';

      const { connectDropTarget, hovered, item } = this.props;

      //var IsEventEnable =   !this.props.isCompleted || !this.props.isHistory  ? 'none': '';
      var IsEventEnable = this.props.isCompleted ? 'none' : '';

      return connectDropTarget(
        <div
          key={this.state.load.loadNum}
          className={`mainContainerHeight ${colsize} load-${this.state.load.loadNum}}`}
          style={{ padding: '0px !important' }}
          id={"Load_" + this.state.load.loadNum + "_Report"}
        >
          <ReactCSSTransitionGroup
            transitionName="load-manger"
            transitionEnterTimeout={500}
            transitionAppear={true}
            transitionAppearTimeout={1000}
            transitionLeave={true}
            transitionLeaveTimeout={1000}
          >
            <div className="centerCards"
              style={{ background: backgroundColor, overflow: 'hidden', marginTop: '3%', height: '95vh' }}
              id={"centercard" + this.state.load.loadNum}
            >
              {/* <div className="centerDivHeading row" >

                <div className="col-10">
                  LOAD {this.state.load.loadNum} <strong>- </strong>

                  <EditableLoadPlane isCompleted={true} Plane={this.state.load.plane} loadNum={this.state.load.loadNum} planeLoadNum = {this.state.load.planeLoadNum} />
                  <span
                    style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                    jumpers manifested: {this.state.load.totalJumpers} out of {this.state.load.capacity}
                  </span>
                  {
                    this.state.load.closed ? <div>CLOSED</div> : null
                  }
                </div>
                <div className="col-2">
                  <div>
                    <span id="btn-load-print"
                      data-pdf={"Load_" + this.state.load.loadNum}
                      onClick={() => { this.showPrint(this.props.load, "Load_" + this.state.load.loadNum + "_Report", this.state.load.loadNum, "print-panel" + this.state.load.loadNum) }}
                       style={{ cursor: 'pointer' }}
                      >
                      <i className="fa fa-print" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div> */}
              <LoadHeader
                loadObj={this.state.load}
                isCompleted={this.props.isCompleted}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.props.columnsPerGroup}
                isDisplayPlaneNameLeft={this.props.isDisplayPlaneNameLeft}
              />

              <div style={{
                overflowY: 'auto', overflowX: 'hidden', fontSize: this.props.fontSize,
              }} id={"complete-innercard" + this.state.load.loadNum}>
                <div className="row">
                  <TransitionGroup style={{ width: '100%' }}>
                    <CSSTransition
                      key={0}
                      timeout={500}
                      classNames="fade"
                    >
                      <div id={0} key={0} className="col-12">

                        <RenderPilotCard
                          history={this.props.history}
                          id={"pilot" + 0}
                          key={0}
                          load={this.state.load}
                          pilot={this.state.load.pilot}
                          pilotPay={this.state.load.pilotPay}
                          loadNum={this.state.load.loadNum}
                          plane={this.state.load.plane}
                          planeLoadNum={this.state.load.planeLoadNum}
                          pilotOptions={pilotOptions}
                          jumpType="pilot"
                          price={this.state.load.pilotPay}
                          isToday={this.props.isToday}
                          isCompleted={true}
                          history={this.props.history}
                          loadId={this.state.load._id}
                          handleJumperDrop={this.handleJumperDrop}
                          handleTandemDrop={this.handleTandemDrop}
                          loadsToShow={this.props.loadsToShow}
                          columnsPerGroup={this.props.columnsPerGroup}
                          fontSize={this.props.fontSize}
                          isInline={this.props.isInline}
                        />
                      </div>
                    </CSSTransition>
                  </TransitionGroup>
                </div>
                <div
                  className="row"
                // style={{ pointerEvents: IsEventEnable }}
                >
                  <TransitionGroup style={{ width: "100%" }}>
                    {alljumps}
                  </TransitionGroup>
                </div>
                {
                  this.state.creatingFunJump ?
                    <CreateFunJumpCard load={this.state.load} disabled={this.state.load.closed} jumperOptions={jumperOptions} />
                    :
                    null
                }
              </div>
            </div>

            {
              this.state.printData &&
              <PrintPdf data={this.state.printData} printId={"print-panel" + this.state.load.loadNum} />
            }
          </ReactCSSTransitionGroup>
        </div>
      );
    }
  }
}

//export default Load;
const LoadH = DropTarget('JUMP', loadTarget, collect)(Load)
export { LoadH }
export default DropTarget('JUMP', loadTarget, collect)(Load);
