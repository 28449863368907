import React, { Component } from 'react';

import { connect } from 'react-redux'
import { LocalForm } from 'react-redux-form';
import Select from 'react-select';
import loadsStore from '../../../stores/loads-store';
import itemsStore from '../../../stores/item-store';
import jumpersStore from '../../../stores/jumpers-store';
import { components } from 'react-select';

import ReactDOM from 'react-dom';
import LoadUpdateModal from './loadModal'
import { UncontrolledTooltip } from 'reactstrap';
import { DropTarget } from "react-dnd";

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem()
  };
}

const attributes = {
  className: "custom-root",
  disabledClassName: "custom-disabled",
  dividerClassName: "custom-divider",
  selectedClassName: "custom-selected"
};

const staffTarget = {
  hover(props, monitor, component) { },
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }
    const dragItem = monitor.getItem();
    let { loadNum, jumpId, type, label } = props;
    var jumperId = dragItem.jumperId;

    //alert(jumperId + " loadNum: " + loadNum + " jumpId: " + jumpId + " type: " + type + " label: " + label);

    if (type === "tandem") {
      if (label === "I") {
        var jumpPostBody = { instructorId: jumperId }
      }
      else if (label === "V") {
        var jumpPostBody = { videographerId: jumperId }
      }
      loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
    }
    else if (type === "aff") {
      if (label === "I1") {
        var jumpPostBody = { mainInstructorId: jumperId }
      }
      else if (label === "I2") {
        var jumpPostBody = { secondaryInstructorId: jumperId }
      }
      else if (label === "V") {
        var jumpPostBody = { videographerId: jumperId }
      }
      else if (label === "S") {
        var jumpPostBody = { studentId: jumperId }
      }
      loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
    }
    else if (type == "coach") {
      if (label === "C") {
        var jumpPostBody = { coachId: jumperId };
        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
      }
      else if (label === "J") {
        var jumpPostBody = { jumperId: jumperId };
        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
      }
    }
  }
}

const customStyles = {
  control: base => ({
    ...base,
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#E7F2F6"
  }),
};
class EditableJumper extends Component {

  constructor(props) {

    super(props);
    this.handelConfirmSubmit = this.handelConfirmSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpen: false,
      jumpType: null,
      load: props.load,
      jumper: props.jumper,
      type: props.type,
      label: props.type,
      jumpId: props.jumpId,
      editMode: props.editMode,
      options: props.options,
      JumperId: '',
      loadNum: props.loadNum,
      menuPlacement: "bottom",
      level: props.level,
      isCompleted: props.isCompleted,
      student: props.student,
      warningPopUp: false
    };
    this.jumperEvents = [
      /*
      { name: jumpersStore.getEventPrefix('singleStaffStatusUpdate'), func: this.handleStaffStatusUpdate },
      { name: jumpersStore.getEventPrefix('staffStatusUpdate'), func: this.handleStaffStatusUpdate },

      { name: jumpersStore.getEventPrefix('tandemInstructorsLoaded'), func: this.handleTandemInstructorsLoaded },
      { name: jumpersStore.getEventPrefix('affInstructorsLoaded'), func: this.handleAffInstructorsLoaded },
      { name: jumpersStore.getEventPrefix('videographersLoaded'), func: this.handleVideographersLoaded },
      { name: jumpersStore.getEventPrefix('coachesLoaded'), func: this.handleCoachesLoaded },
      { name: jumpersStore.getEventPrefix('staffLoaded'), func: this.handleStaffLoaded },
      */
    ];

    this.timerId = null;
    this.inputValue = '';
  }

  componentDidMount = () => {
    console.log('componentDidMount editable Jumper');
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
    this.updateOptions();
  }
  componentWillUnmount = async () => {
    console.log('console Will Unmount');
    // ////console.log('in componentWillUnmount in load manager center');
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
  }

  updateOptions = () => {
    console.log('in update options editable jumper');
    console.log(this.props.activeJumperIdsOnLoad);
    if (this.props.type === "tandem") {
      if (this.props.label === "I") {
        if (this.state.isCompleted) {
          var tandemInstructorOptions = jumpersStore.getTandemInstructorOptions();
        }
        else {
          var tandemInstructorOptions = jumpersStore.getTandemInstructorCheckedInOptions().filter(
            option =>
              this.props.activeJumperIdsOnLoad.findIndex(
                id => option.value._id === id
              ) === -1
          );
        }

        this.setState({ options: tandemInstructorOptions })
      }
      else if (this.props.label === "V") {
        if (this.state.isCompleted) {
          var videographerOptions = jumpersStore.getVideographerOptions()
        }
        else {
          var videographerOptions = jumpersStore.getVideographerCheckedInOptions().filter(
            option =>
              this.props.activeJumperIdsOnLoad.findIndex(
                id => option.value._id === id
              ) === -1
          );
        }

        this.setState({ options: videographerOptions })
      }
    }
    else if(this.props.type === "pilot"){
      if (this.props.label === "P") {
        if (this.state.isCompleted) {
          var pilotOptions = jumpersStore.getPilotOptions()
        }
        else {
          var pilotOptions = jumpersStore.getPilotOptions().filter(
            option =>
              this.props.activeJumperIdsOnLoad?.findIndex(
                id => option.value._id === id
              ) === -1
          );
        }
        this.setState({ options: pilotOptions })

      }
    }
    else if (this.props.type === "aff") {
      if (this.props.label === "V") {
        if (this.state.isCompleted) {
          var videographerOptions = jumpersStore.getVideographerOptions()
        }
        else {
          var videographerOptions = jumpersStore.getVideographerCheckedInOptions().filter(
            option =>
              this.props.activeJumperIdsOnLoad.findIndex(
                id => option.value._id === id
              ) === -1
          );
        }
        this.setState({ options: videographerOptions })
      }
      else {
        if (this.state.isCompleted) {
          var affInstructorOptions = jumpersStore.getAffInstructorOptions();
        }
        else {
          var affInstructorOptions = jumpersStore.getAffInstructorCheckedInOptions().filter(
            option =>
              this.props.activeJumperIdsOnLoad.findIndex(
                id => option.value._id === id
              ) === -1
          );
        }
        this.setState({ options: affInstructorOptions })
      }
    }
    else if (this.props.type === "coach") {
      if (this.state.isCompleted) {
        var coachOptions = jumpersStore.getCoachOptions();
      }
      else {
        var coachOptions = jumpersStore.getCoachCheckedInOptions()
          .filter(
            option =>
              this.props.activeJumperIdsOnLoad.findIndex(
                id => option.value._id === id
              ) === -1
          );
      }

      this.setState({ options: coachOptions })
    }
  }

  handleStaffStatusUpdate = () => {
    console.log('handle staff status update editable jumper');
    // console.log('in Option componenet');
    // console.log(props);
    if (this.state.isCompleted) {
    }
    else {
      //alert('handle staff status update ' + this.props.loadNum)
      this.updateOptions();
    }
  }
  handleChange = (selectedOption, loadNum, jumpId, type, label) => {
    this.setState({ editMode: !this.state.editMode });
    var today = new Date();
    var isWeekend = (today.getDay() % 6 === 0);

    if (selectedOption.value !== undefined) {
      if (type === "tandem") {
        var tandemJump = this.props.tandemJump;

        if (this.state.isCompleted) {
          var jumpPostBody = {
            _id: this.props.tandemJump.jumpId,
            jumpId: this.props.tandemJump.jumpId,
            instructorId: this.props.tandemJump.instructor._id,
            studentId: this.props.tandemJump.student._id,
            videographerId: this.props.tandemJump.videographer._id,
            price: this.props.tandemJump.price,
            videoPrice: this.props.tandemJump.videoPrice,
            instructorPay: this.props.tandemJump.instructorPay,
            videoPay: this.props.tandemJump.videoPay,
            wristcamPay: this.props.tandemJump.wristcamPay,
            videoType: this.props.tandemJump.videoType,
            tandemPackageName: this.props.tandemJump.tandemPackageName,
            videoPackageName: this.props.tandemJump.videoPackageName,
            overweightPay: this.props.tandemJump.overweightPay,
            overweightPrice: this.props.tandemJump.overweightPrice,
            isOverweight: this.props.tandemJump.isOverweight,
            altitude: this.props.tandemJump.altitude,
            loadNum: this.props.tandemJump.loadNum,
            planeLoad: this.props.tandemJump.planeLoad,
          }
          if (label === "I") {
            jumpPostBody.instructorId = selectedOption.value._id;
          }
          else if (label === "V") {
            jumpPostBody.videographerId = selectedOption.value._id;
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }
          var jumpId = this.props.tandemJump.jumpId;
          var loadId = this.props.loadId;
          jumpPostBody.isUltra = this.props.isUltra;
          jumpPostBody.isWeekend = isWeekend;
          jumpPostBody.isPriceUpdate = false;
          jumpPostBody.editPrice = true;
          this.props.updateCompleteTandemJump(loadId, jumpId, jumpPostBody);
        }
        else {
          if (label === "I") {
            var jumpPostBody = { instructorId: selectedOption.value._id }
          }
          else if (label === "V") {
            var jumpPostBody = { videographerId: selectedOption.value._id }
          }
          loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
        }
      }
      else if (type === "tandemUltra") {
        var tandemJump = this.props.tandemJump;
        if (this.state.isCompleted) {
          if (label === "I") {
            var jumpPostBody = {
              instructorId: selectedOption.value._id,
              studentId: this.props.tandemJump.student._id,
              videographerId: this.props.tandemJump.videographer._id,
              price: this.props.tandemJump.price,
              videoPrice: this.props.tandemJump.videoPrice,
              instructorPay: this.props.tandemJump.instructorPay,
              videoPay: this.props.tandemJump.videoPay
            }
          }
          else if (label === "V") {
            var jumpPostBody = {
              videographerId: selectedOption.value._id,
              instructorId: this.props.tandemJump.instructor._id,
              studentId: this.props.tandemJump.student._id,
              price: this.props.tandemJump.price,
              videoPrice: this.props.tandemJump.videoPrice,
              instructorPay: this.props.tandemJump.instructorPay,
              videoPay: this.props.tandemJump.videoPay
            }
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }

          jumpPostBody.isPriceUpdate = false;

          var jumpId = this.props.tandemJump.jumpId;

          var loadId = this.props.loadId;

          jumpPostBody.isUltra = this.props.isUltra;
          jumpPostBody.isWeekend = isWeekend;
          jumpPostBody.editPrice = true;

          // console.log("updateCompleteTandemJump",jumpPostBody);
          loadsStore.updateCompleteTandemJump(loadId, jumpId, jumpPostBody);
        }
        else {
          if (label === "I") {
            var jumpPostBody = { instructorId: selectedOption.value._id }
          }
          else if (label === "V") {
            var jumpPostBody = { videographerId: selectedOption.value._id }
          }
          loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
        }
      }
      else if (type === "aff") {
        if (this.state.isCompleted) {
          var jumpPostBody = {
            _id: this.props.affJump.jumpId,
            jumpId: this.props.affJump.jumpId,
            mainInstructorId: this.props.affJump.mainInstructor._id,
            studentId: this.props.affJump.student._id,
            level: this.props.affJump.level,
            price: this.props.affJump.price,
            block: this.props.affJump.block,
            blockId: this.props.affJump.blockId,
            numInstructors: this.props.affJump.numInstructors,
            videoPrice: this.props.affJump.videoPrice,
            instructor1Pay: this.props.affJump.instructor1Pay,
            instructor2Pay: this.props.affJump.instructor2Pay,
            studentPackageName: this.props.affJump.studentPackageName,
            studentPackageId: this.props.affJump.studentPackageId,
            videoPay: this.props.affJump.videoPay,
            altitude: this.props.affJump.altitude,
            loadNum: this.props.affJump.loadNum,
            planeLoad: this.props.affJump.planeLoad,
          }
          if (label === "I1") {
            jumpPostBody.mainInstructorId = selectedOption.value._id;
            if (this.props.affJump.secondaryInstructor) {
              jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
            }
          }
          else if (label === "I2") {
            jumpPostBody.secondaryInstructorId = selectedOption.value._id;
          }
          else if (label === "V") {
            jumpPostBody.videographerId = selectedOption.value._id;

            if (this.props.affJump.secondaryInstructor) {
              jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
            }
          }
          else if (label === "S") {
            jumpPostBody.studentId = selectedOption.value._id;

            if (this.props.affJump.secondaryInstructor) {
              jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
            }
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }
          jumpPostBody.level = this.state.level;
          jumpPostBody.isWeekend = isWeekend;
          var cjumpId = this.props.affJump.jumpId;
          var loadId = this.props.loadId;
          jumpPostBody.editPrice = true;

          this.props.updateCompletedAffJump(loadId, cjumpId, jumpPostBody);
        }
        else {
          if (label === "I1") {
            var jumpPostBody = { mainInstructorId: selectedOption.value._id }
          }
          else if (label === "I2") {
            var jumpPostBody = { secondaryInstructorId: selectedOption.value._id }
          }
          else if (label === "V") {
            var jumpPostBody = { videographerId: selectedOption.value._id }
          }
          else if (label === "S") {
            var jumpPostBody = { studentId: selectedOption.value._id }
          }
          loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
        }
      }
      else if (type === "coach") {

        if (this.state.isCompleted) {
          if (label === "C") {
            var jumpPostBody = { coachId: selectedOption.value._id };
            if (this.props.isToday) {
              jumpPostBody.isToday = true
            }
            jumpPostBody.jumperId = this.props.coachJump.jumper._id;
            jumpPostBody.price = this.props.coachJump.price;
            jumpPostBody.isWingsuit = this.props.isWingsuit;
            jumpPostBody.coachPay = this.props.coachJump.coachPay;
            var cjumpId = this.props.coachJump.jumpId;
            var loadId = this.props.loadId;
            jumpPostBody.editPrice = true;

            this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);
          }
          else if (label === "J") {
            var jumpPostBody = { jumperId: selectedOption.value._id };
            if (this.props.isToday) {
              jumpPostBody.isToday = true
            }
            jumpPostBody.coachId = this.props.coach._id;
            jumpPostBody.price = this.props.coachJump.price;
            jumpPostBody.coachPay = this.props.coachJump.coachPay;
            var cjumpId = this.props.coachJump.jumpId;
            var loadId = this.props.loadId;
            jumpPostBody.editPrice = true;

            this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);
          }
        }
        else {
          if (label === "C") {
            var jumpPostBody = { coachId: selectedOption.value._id };
            loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
          }
          else if (label === "J") {
            var jumpPostBody = { jumperId: selectedOption.value._id };
            loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
          }
        }

      }
      else if (type === "iad") {
        if (this.state.isCompleted) {

        }
        else {
          if (label === "I") {
            var jumpPostBody = { instructorId: selectedOption.value._id };
            // console.log(jumpPostBody);
            loadsStore.updateIadJump(loadNum, jumpId, jumpPostBody);
          }
        }
      }
      else if (type === "pilot") {
        if (this.state.isCompleted) {
          var jumpPostBody = {
            pilotId: selectedOption.value._id,
            pilotPay: itemsStore.getPilotPay(selectedOption.value._id),
            _id: this.props.loadId,
            plane: this.props.plane,
            planeLoadNum: this.props.planeLoadNum,
            loadNum: this.props.loadNum
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }

          jumpPostBody.isPriceUpdate = false;

          var loadId = this.props.loadId;
          jumpPostBody.editPrice = true;

          // console.log("updateCompleteTandemJump",jumpPostBody);
          loadsStore.updateCompletedPilot(loadId, jumpPostBody);
        }
        else {
          var body = { pilotId: selectedOption.value._id }
          loadsStore.UpdateLoad(loadNum, body);
        }
      }
    }
  }

  cancelOrSubmit = () => {
    var ID = this.state.JumperId;
    var loadNum = this.state.loadNum;
    var jumpId = this.state.jumpId;
    var type = this.state.type;
    var label = this.state.label;

    var jumpPostBody = {};

    if (type === "tandem") {

      if (label === "I") {
        var jumpPostBody = { instructorId: ID }
      }
      else if (label === "V") {
        var jumpPostBody = { videographerId: ID }
      }
      loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
    }
    else if (type === "aff") {
      if (label === "I1") {
        var jumpPostBody = { mainInstructorId: ID }
      }
      else if (label === "I2") {
        var jumpPostBody = { secondaryInstructorId: ID }
      }
      else if (label === "V") {
        var jumpPostBody = { videographerId: ID }
      }
      loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
    }
    else if (type === "coach") {
      if (label === "C") {
        var jumpPostBody = { coachId: ID };
        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
      }
      else if (label === "J") {
        var jumpPostBody = { jumperId: ID };
        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
      }
    }
  }

  checkForNegatives = (price) => {
    //console.log('check for negatives: ' + price);
    if (price != undefined) {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <span style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
      }
      return <span style={{ color: '#00FF00' }}> {priceCell.value}</span>;;
    }
    else {
      return '$0.00';
    }
  }

  wantsEidtable = (data) => {
    // alert('wtf');
    this.setState({
      editMode: data,
      isModalOpen: false
    }, () => {
      if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
        this.setState({ menuPlacement: "top" });
      }
      else {
        this.setState({ menuPlacement: "bottom" });
      }
    }
    )
  }

  changeJumper = (isCompleted) => {
    if (isCompleted) {
      this.setState({
        isModalOpen: true
      })
    }
    else {
      console.log('about to edit jumper');
      this.handleStaffStatusUpdate();
      if (!this.state.editMode) {
        this.setState({
          editMode: true
        })
      }
      if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
        this.setState({ menuPlacement: "top" });
      }
      else {
        this.setState({ menuPlacement: "bottom" });
      }
    }
  }

  onBlur = () => {
    this.setState({
      editMode: false
    })
  }

  handelConfirmSubmit() {
    this.setState({
      warningPopUp: !this.state.warningPopUp
    })
  }

  toggleModal() {
    this.setState({
      warningPopUp: !this.state.warningPopUp
    })
  }

  setSelectRef = ref => {
    if (this.focusInterval) {
      clearInterval(this.focusInterval);
    }
    this.focusInterval = setInterval(() => {
      if (ref) {
        ref.focus();
      } else {
        clearInterval(this.focusInterval);
      }
    }, 500);
  }


  Menu = props => {
    //const optionsLength = getLength(props.options);
    return (
      <React.Fragment>
        <div className="row custom-header">
          <div className="col-4"><strong>Staff</strong></div>
          <div className="col-1"><strong>TOT</strong></div>
          <div className="col-1"><strong>TI</strong></div>
          <div className="col-1"><strong>AFFI</strong></div>
          <div className="col-1"><strong>V</strong></div>
          {window.ENV?.dontshowwristcam ? null :
            <React.Fragment>
              <div className="col-1"><strong>WC</strong></div>
            </React.Fragment>
          }
          <div className="col-1"><strong>OW</strong></div>
          {window.ENV?.arizona === true ? null :
            <div className="col-1"><strong>CH</strong></div>
          }

        </div>
        <components.Menu {...props}>{props.children}</components.Menu>
      </React.Fragment>
    );
  };

  Option = (props) => {

    // console.log('in Option componenet');
    // console.log(props);
    var activeStaff, thisActiveJumper;
    if (this.props.type === "tandem") {

      if (this.props.label === "I") {
        activeStaff = jumpersStore.getTodayActiveTIs();
        thisActiveJumper = activeStaff.filter(staff => staff.jumperId === props.data.value._id)[0];
      }
      else if (this.props.label === "V") {
        activeStaff = jumpersStore.getTodayActiveVideographers();
        // console.log('geting active videographers');
        // console.log(activeStaff);
        thisActiveJumper = activeStaff.filter(staff => staff.jumperId === props.data.value._id)[0];
      }
    }
    else if (this.props.type === "aff") {
      if (this.props.label === "V") {
        activeStaff = jumpersStore.getTodayActiveVideographers();
        // console.log('geting active videographers');
        // console.log(activeStaff);
        thisActiveJumper = activeStaff.filter(staff => staff.jumperId === props.data.value._id)[0];
      }
      else {
        activeStaff = jumpersStore.getTodayActiveAFFIs();
        thisActiveJumper = activeStaff.filter(staff => staff.jumperId === props.data.value._id)[0];
      }

    }
    else if (this.props.type === "coach") {
      activeStaff = jumpersStore.getTodayActiveCoaches();
      thisActiveJumper = activeStaff.filter(staff => staff.jumperId === props.data.value._id)[0];
    }

    // console.log("this.gearIdsthis.gearIds", props.data.value._id, this.gearIds);

    return (
      <React.Fragment>
        <div className="row custom-header">
          <div className="col-4">
            <components.Option {...props} />
          </div>
          {thisActiveJumper ?
            <React.Fragment>
              <div className="col-1" >
                {thisActiveJumper["tandem jumps"].count + thisActiveJumper.affiJumps + thisActiveJumper["video jumps"].count + thisActiveJumper["coach jumps"].count}-{thisActiveJumper.tiManifestedCount + thisActiveJumper.affiManifestedCount + thisActiveJumper.OVManifestedCount + thisActiveJumper.coachManifestedCount}
              </div>
              <div className="col-1" >
                {thisActiveJumper["tandem jumps"].count}-{thisActiveJumper.tiManifestedCount}
              </div>
              <div className="col-1" >
                {thisActiveJumper.affiJumps}-{thisActiveJumper.affiManifestedCount}
              </div>
              <div className="col-1" >
                {thisActiveJumper["video jumps"].count}-{thisActiveJumper.OVManifestedCount}
              </div>
              {window.ENV?.dontshowwristcam ? null :
                <div className="col-1" >
                  {thisActiveJumper["wrist cam"].count}-{thisActiveJumper.wristcamManifestedCount}
                </div>
              }
              <div className="col-1" >
                {thisActiveJumper["overweight pay"].count}
              </div>
              {window.ENV?.arizona === true ? null :
                <div className="col-1" >
                  {thisActiveJumper["coach jumps"].count}-{thisActiveJumper.coachManifestedCount}
                </div>
              }
            </React.Fragment> : null
          }
        </div>
        {thisActiveJumper ?
          <div className="row custom-header">
            {thisActiveJumper.jumpedStatus.length > 0 ? [thisActiveJumper.jumpedStatus[thisActiveJumper.jumpedStatus.length - 1]].map(status => {
              var str = "last jump: " + status.type + " " + " on load " + status.load + " " + status.planeLoad;
              return (<div className="col-9"><h6>{str}</h6></div>);
            }) : null}
            {thisActiveJumper.manifestStatus.map(status => {
              var str = "manifested: " + status.type + " " + " on load " + status.load + " " + status.planeLoad;
              if (status.load === 0) {
                str = "manifested: " + status.type + " " + " on school waitlist";
              }
              return (<div className="col-9"><h6>{str}</h6></div>);
            })}
          </div>
          : null
        }
      </React.Fragment>
    );
  }

  showId = () => {
    if (this.props.dontShowId) {
      return null
    } else {
      if (!this.props.schoolWaitlist) {
        return (<span>{this.props.jumper.memberId}</span>)
      } else {
        return null
      }
    }
  }
  render() {
    console.log('rendering editable jumper');
    //alert(JSON.stringify(this.props.jumper,2));
    var alerts;
    const { connectDropTarget } = this.props;

    var jumper = this.props.jumper;

    var tooltipId = "example" + jumper._id.toString() + "ld" + this.props.loadNum;

    var alerts;

    if (jumper.alerts !== undefined && jumper.alerts.length > 0) {
      alerts = jumper.alerts.map(alert => {
        return (<p>{alert.message}</p>);
      });
    }

    var alertsColor = '#FFFF00';

    if (jumper.alerts !== undefined && jumper.alerts.length > 0 && jumper.alerts.some(alert => alert.level === "urgent")) {
      alertsColor = 'tomato';
    }


    if (jumper.alerts !== undefined && jumper.alerts.length > 0) {
      alerts = jumper.alerts.map(alert => {
        return (<p>{alert.message}</p>);
      });
    }
    // console.log('options');
    //console.log(this.props.options);

    return connectDropTarget(
      <div>
        {
          !this.state.editMode ?
            // <button className="btn lightBlueButton jumper-name" onClick={(e) => { e.preventDefault(); this.changeJumper(this.props.isCompleted); }} type="submit" disabled={this.props.disabled}>
            <div onClick={(e) => { e.preventDefault(); this.changeJumper(this.props.isCompleted); }} disabled={this.props.disabled}>
              {this.props.jumper.firstname !== "NONE" ?
                <div>{this.props.label}: <strong>{this.props.jumper.nickname} {this.props.jumper.lastname !== undefined && this.props.jumper.lastname !== "" && this.props.jumper.lastname !== null ? this.props.jumper.lastname.substr(0, 1) : null} </strong>
                  {
                    jumper.alerts && jumper.alerts.length > 0 ?
                      <React.Fragment>
                        <i className="fa fa-exclamation-triangle" style={{ color: alertsColor }} href="#" id={tooltipId}>

                        </i>
                        <UncontrolledTooltip placement="top" target={tooltipId}>
                          {alerts}
                        </UncontrolledTooltip>
                      </React.Fragment>
                      :
                      null
                  }
                  {this.showId()}
                  {/* {!this.props.schoolWaitlist  ? <div>{this.props.jumper.memberId}</div>:null} */}
                </div>
                :
                <div><i className="fa fa-plus"></i> {this.props.label}</div>
              }

            </div>
            //</button>
            :
            // <button className="btn lightBlueButton jumper-name" disabled={this.props.disabled}>
            <button className="btn editable-Jumper" disabled={this.props.disabled} style={this.props.elementWidth ? this.props.elementWidth : { width: '440px' }}>
              <LocalForm form="editjumpers" model="editjumpers" getDispatch={dispatch => this.formDispatch = dispatch}>
                <Select model=".jumper" name="jumper" ref={this.setSelectRef}
                  options={this.state.options}
                  onChange={(selectedOption) => this.handleChange(selectedOption, this.props.loadNum, this.props.jumpId, this.props.type, this.props.label)}
                  components={{ Option: this.Option, Menu: this.Menu }}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  menuIsOpen={true}
                  menuPlacement={this.state.menuPlacement}
                  onBlur={this.onBlur}
                  autofocus="true"
                />
              </LocalForm>
            </button>
        }
        <LoadUpdateModal
          isOpen={this.state.isModalOpen}
          onClick={this.wantsEidtable}
        />
      </div>
    );
  }
}

const mstp = state => {
  return {
    state
  }
}

const mdtp = dispatch => {
  return {
    updateCompletedAffJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedAffJump(loadId, cjumpId, jumpPostBody)),
    updateCompleteTandemJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody)),
    updateCompletedCoachJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody))

  }
}

export default connect(mstp, mdtp)(DropTarget("JUMP", staffTarget, collect)(EditableJumper))
