import { Component } from 'react'
import { Row, Col, Table } from 'reactstrap'
import HamburgerMenu from 'react-hamburger-menu';
import swal from 'sweetalert';

import background from '../../images/background.svg';
import LeftMenu from '../../menu/LeftMenu1';
import logo from '../../images/logo.svg';
import DonutLoader from '../../loadManager/DonutLoader';
import AddEditEvent from './AddEditEvent'
import scheduleStore from '../../../stores/schedule-store';
import DeleteItem from './DeleteEvent'

class CalenderEvents extends Component {
    state = {
        open: false,
        isLoading: false,
        events: [],
        deleteOpen: false,
        isModalOpen: false
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidMount = () => {
        this.setState({ isLoading: true })
        scheduleStore.getEvents()
            .then(res => {
                this.setState({ events: res.data, isLoading: false })
            })
    }

    handleClick = () => {
        this.setState({
            open: !this.state.open
        });
    }

    toggleDeleteModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    deleteEvent = (item) => {
        this.setState({ isLoading: true })
        let filteredEvent = this.state.events.filter(e => e._id !== item._id)
        //alert(JSON.stringify(item, null,2));
        scheduleStore.deleteCalenderEvent(item)
            .then(res => {
                this.setState({ events: filteredEvent, isLoading: false })
            })
            .catch(err =>{
                this.setState({isLoading:false});
            })
    }

    addEditEvent = (type, item) => {
        this.setState({ isLoading: true })
        if (type === 'add') {
            scheduleStore.createCalenderEvent(item)
                .then(res => {
                    if (!!res.isExist) {
                        swal({
                            title: "Failed!!",
                            text: "Event Already Exist",
                            icon: "error",
                            dangerMode: true,
                        })
                        this.setState({ isLoading: false })
                    }
                    else {
                        scheduleStore.getEvents()
                            .then(res => {
                                this.setState({ events: res.data, isLoading: false })
                            })
                    }
                })
        } else {
            //alert(JSON.stringify(item,null,2));
            scheduleStore.updateCalenderEvent(item)
                .then(res => {
                    scheduleStore.getEvents()
                        .then(res => {
                            this.setState({ events: res.data, isLoading: false })
                        })
                })
        }
    }

    renderItems = () => {
        let { events } = this.state;
        console.log("events-===>", events)
        return events.map((event, index) => {
            return (
                <tr key={`event${index}`}>
                    <td scope="row" data-id={event._id}>{index + 1}</td>
                    <td scope="row">{event.eventName}</td>
                    <td scope="row">{event.packageCategory}</td>
                    <td scope="row" style={{ textAlign: "center" }}>{(event.depositAmount && event.depositAmount !== 0) ? event.depositAmount : "-"}</td>
                    <td style={{
                        cursor: 'pointer',
                        opacity: 1,
                        pointerEvents: "inherit"
                    }}  >
                        <AddEditEvent
                            type='edit'
                            data={event}
                            loading={this.state.isLoading}
                            addEditEvent={this.addEditEvent}
                            priority2={event.priority2}
                            priority={event.priority}
                        />
                    </td>
                    <td style={{
                        cursor: 'pointer',
                        opacity: (event.priority || event.priority2) ? 0.5 : 1,
                        pointerEvents: (event.priority || event.priority2) ? "none" : "inherit"
                    }}  >
                        <DeleteItem
                            isModalOpen={this.state.isModalOpen}
                            toggleModal={this.toggleDeleteModal}
                            deleteEvent={this.deleteEvent}
                            loading={this.state.isLoading}
                            item={event} />
                    </td>
                </tr>
            )
        })
    }

    render() {
        const { open } = this.state
        const { history } = this.props
        const isMobile = window.innerWidth <= 500;
        return (
            <Row className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
                {
                    isMobile ?
                        <div className={`blueBg fullHeightPanes ${open ? "mobileLeftBar" : "hideSideBar"}`}>
                            <div className={`${open ? "show-panel" : "hide-Panel"}`}>
                                <LeftMenu routeHistory={history} showCreate={true} />
                            </div>
                        </div>
                        :
                        <div className="blueBg fullHeightPanes left-container">
                            <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
                            <LeftMenu routeHistory={history} showCreate={true} />
                        </div>
                }
                <div className="middle-container-no-rightmenu">
                    {
                        isMobile ?
                            <Row style={{ width: "100%" }}>
                                <Col md={12}>

                                    <div className="hamBurger-menu">
                                        <HamburgerMenu
                                            isOpen={open}
                                            menuClicked={this.handleClick}
                                            width={22}
                                            height={17}
                                            strokeWidth={1}
                                            rotate={0}
                                            color='white'
                                            borderRadius={0}
                                            animationDuration={0.5}
                                        />

                                    </div>
                                </Col>
                            </Row>
                            :
                            ''
                    }
                    <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />

                    <Row className="justify-content-center store-container">
                        <DonutLoader isLoading={this.state.isLoading} addClass="store" />
                        <Col className="main-div">
                            <Table style={{ width: '75%' }}>
                                <thead className="main-heading">
                                    <tr>
                                        <th>
                                            <AddEditEvent type='add' loading={this.state.isLoading}
                                                addEditEvent={this.addEditEvent} categories={null} />
                                        </th>
                                        <th>Event Name</th>
                                        <th>Category</th>
                                        <th style={{ textAlign: "center" }}>Deposit</th>
                                        <th colSpan="2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderItems()}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
                {/* <div className="blueBg fullHeightPanes rightCol"></div> */}
            </Row>
        )
    }
}
export default CalenderEvents