import React, { Component } from 'react';
import './loginloader.css';

class LoginLoader extends Component {

    render() {
        return <div className="loginloader-container">
            <div id="kapowsin">
                {/* <img src="./loaders/kapowsin.png" alt="" /> */}
                <img src={window.ENV?.logoUrl} alt="" />

            </div>
            <div id="dizio">
                <img src="./loaders/dizio.logo.png" width="194" height="62" alt="Dizio" />
            </div>

            <div id="loader">
                <div className="longfazers">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="loading">LOADING</div>
                <div className="skydiver">
                    <span className="left">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span className="left-hand">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span className="right">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span className="right-hand">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <img src="./loaders/skydiver.png" width="150" alt="" />
                </div>
            </div>
        </div>
    }
}
export default LoginLoader