/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
	Card, CardImg, CardText, CardBody,
	CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import jumpersStore from '../../../stores/jumpers-store';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import loadsStore from '../../../stores/loads-store';
import JmpModal from './customModal';
import { UncontrolledTooltip } from 'reactstrap';

import { updateCompletedCoachJump } from '../../../actions/updateCompletedJump'
import { connect } from 'react-redux'
import EditablePrice from './editablePrice'

class CreatableJumper extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			jumpType: null,
			load: props.load,
			jumper: props.jumper,
			type: props.type,
			label: props.type,
			jumpId: props.jumpId,
			editMode: props.editMode,
			selectedStudent: undefined,
			options: props.options
		};
		this.jumperEvents = [
			{ name: jumpersStore.getEventPrefix('tandemStudentsLoaded'), func: this.handleStudentsLoaded },
			{ name: jumpersStore.getEventPrefix('tandemStudentCreated'), func: this.handleStudentsLoaded },
			{ name: jumpersStore.getEventPrefix('affStudentsLoaded'), func: this.handleStudentsLoaded },
		]

		this.gearIds = [];
		this.valRef = null;
		this.inputValue = '';
	}

	componentDidMount = () => {
		this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
		this.handleStudentsLoaded()
		if (this.valRef !== null)
			this.valRef.focus();
	}
	componentWillUnmount = () => {
		this.jumperEvents.forEach(evnt =>
			jumpersStore.removeListener(evnt.name, evnt.func));
	}

	componentDidUpdate = () => {
		if (this.valRef !== null) {
			this.valRef.focus();
		}
		//this.handleStudentsLoaded();
	}
	handleStudentsLoaded = () => {
		console.log('handleAffStudentsLoaded')
		if (this.props.type === "aff") {
			var options = jumpersStore.getAffStudentOptions();
			if (this.props.activeJumperIdsOnLoad) {
				options = options.filter(
					option =>
						this.props.activeJumperIdsOnLoad.findIndex(
							id => option.value._id === id
						) === -1
				)
			}
			this.setState({
				options: options
			});
		}
		else if (this.props.type === "tandem") {
			this.setState({
				options: jumpersStore.getTandemStudentOptions()
			});
		}
	}
	Option = (props) => {
		var gearModel = ".gear" + props.data.value._id;
		var name = "gear" + props.data.value._id
		return (
			<div className="row">
				<div className="col-8">
					<components.Option {...props} />
				</div>
				<div className="col-4" style={{ paddingTop: '6px' }}>
					gear <Control.checkbox model={gearModel} name={name} onChange={value => { this.updateGear(value) }} />
				</div>
			</div>
		);
	}

	SingleValueLabel = ({ children, ...props }) => {
		return (
			<components.SingleValue {...props}><div className="row"> <div className="col-8">{children}</div>{this.gearIds.indexOf(props.data.value._id) > -1 ? <div className="col-4">-G</div> : null}</div></components.SingleValue>
		);
	}


	handleStudentChange = (selectedOption, loadNum, jumpId, type, label, isCompleted, loadId, affJump, isUltra) => {
		if (isCompleted) {
			if (type === "aff") {
				var jumpPostBody = {
					studentId: selectedOption.value,
					videographerId: this.props.affJump.videographer._id,
					mainInstructorId: this.props.affJump.mainInstructor._id,
					price: this.props.affJump.price,
					instructor1Pay: this.props.affJump.instructor1Pay,
					instructor2Pay: this.props.affJump.instructor2Pay,
					videoPay: this.props.affJump.videoPay,
					videoPrice: this.props.affJump.videoPrice,
					studentPackageName: this.props.affJump.studentPackageName,
					studentPackageId: this.props.affJump.studentPackageId
				}
				if (this.props.affJump.secondaryInstructor) {
					jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
				}
				if (this.props.isToday) {
					jumpPostBody.isToday = true
				}
				this.props.updateCompletedAffJump(loadId, jumpId, jumpPostBody);
			}
		}
		else {
			if (selectedOption !== null) {
				if (type === "tandem") {
					var jumpPostBody = { studentId: selectedOption.value };
					loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
				}
				else if (type === "aff") {
					var jumpPostBody = { studentId: selectedOption.value._id };
					loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
				}
				else if (type === "iad") {
					var jumpPostBody = { studentId: selectedOption.value };
					if (label === "S1") {
						jumpPostBody = { student1Id: selectedOption.value };
					}
					else if (label === "S2") {
						jumpPostBody = { student2Id: selectedOption.value };
					}
					else if (label === "S3") {
						jumpPostBody = { student3Id: selectedOption.value };
					}
					loadsStore.updateIadJump(loadNum, jumpId, jumpPostBody);
				}
			}

		}
		this.setState({ editMode: false })
	}

	handleChange = (selectedOption, loadNum, jumpId, type, label, isCompleted) => {
		console.log('in handleChange');
		console.log(selectedOption)
		var jumpPostBody = { studentId: selectedOption.value };
		if (type === "tandem")
			loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
		else if (type === "aff") {
			loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
		}
		this.setState({ editMode: false })
	}

	changeJumper = () => {
		//////alert('change jumper');
		// console.log(this.valRef);
		this.setState({
			editMode: !this.state.editMode
		})
	}

	filterJumpers = (inputValue, jumperOptions) => {
		// console.log("in filterJumpers");
		if (inputValue) {
			var filteredOptions = jumpersStore.searchJumperOptionsUsingFuse(inputValue.toLowerCase());
			// console.log(filteredOptions);
			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
			else return filteredOptions;
		}
		return jumperOptions;
	};

	promiseOptions = (inputValue, jumperOptions) => {
		return new Promise(resolve => {
			resolve(this.filterJumpers(this.inputValue, jumperOptions));
		});
	}

	handleCreateStudent = (value, loadNum, jumpId, type, label) => {
		var split = value.split(" ");
		var firstname = split[0];
		var lastname = split[1] || ' ';

		var payload = { firstname: firstname, lastname: lastname };
		//console.log(payload)
		if (type === "tandem") {
			jumpersStore.AddWalkinTandemStudent(payload)
				.then(student => {
					// console.log("craete students ", student);
					this.handleCreatedStudent(student, loadNum, jumpId, type, label)
				});
		}
		else if (type === "aff") {
			var payload = { firstname: firstname, lastname: lastname };
			payload.ratings = [{ rating: "AFF STUDENT", number: '11111' }];
			payload.affPriceType = "regular";
			console.log('payload');
			console.log(payload);
			jumpersStore.CreateJumper(payload)
				.then(student => {
					console.log(student);
					this.handleCreatedStudent(student, loadNum, jumpId, type, label)
				});
		}
		else if (type === "iad") {
			var payload = { firstname: firstname, lastname: lastname };
			payload.ratings = [{ rating: "IAD STUDENT", number: '11111' }];
			payload.affPriceType = "regular";
			console.log('payload');
			console.log(payload);
			jumpersStore.CreateJumper(payload)
				.then(student => {
					console.log(student);
					this.handleCreatedStudent(student, loadNum, jumpId, type, label)
				});
		}
	}

	handleCreatedStudent = (student, loadNum, jumpId, type, label) => {
		var studentOption = { label: student.firstname + " " + student.lastname, value: student._id.toString() };
		var options = this.state.options;
		if (options.length > 0) {
			// console.log(options.length);
			this.setState({
				options: [...options, studentOption]
			}, () => {
				this.handleChange(studentOption, loadNum, jumpId, type, label);
			});
		}
		else {
			// console.log(options.length);
			this.setState({
				options: [studentOption]
			}, () => {
				this.handleChange(studentOption, loadNum, jumpId, type, label);
			}
			);
		}
	}

	handleInputChange = (newValue) => {
		this.inputValue = newValue;
	};

	onBlur = (mode, isCompleted) => {
		//if(this.state.editMode && !this.props.isCompleted)
		//{
		if (mode && !isCompleted) {
			this.setState({ editMode: false });
		}
		else if (mode && this.isConfirm && isCompleted) {
			this.setState({ editMode: false });
			this.isConfirm = false;
		}
		else {
			this.isConfirm = true;
		}
	}

	checkForNegatives = (price) => {
		//console.log('check for negatives: ' + price);
		if (price != undefined) {
			let priceCell = (price < 0) ?
				{
					value: `($${Math.abs(price).toFixed(2)})`
				} :
				{
					value: `$${price.toFixed(2)}`
				}

			if (price < 0) {
				return <span style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
			}
			return <span style={{ color: '#00FF00' }}> {priceCell.value}</span>;;
		}
		else {
			return '$0.00';
		}
	}


	render() {
		const { isCompleted, loadId, affJump, isUltra, jumper } = this.props;

		var tooltipId = "example" + jumper._id.toString() + "ld" + this.props.loadNum;
		var alerts;

		if (jumper.alerts !== undefined && jumper.alerts.length > 0) {
			alerts = jumper.alerts.map(alert => {
				return (<p>{alert.message}</p>);
			});
		}

		var alertsColor = '#FFFF00';

		if (jumper.alerts !== undefined && jumper.alerts.length > 0 && jumper.alerts.some(alert => alert.level === "urgent")) {
			alertsColor = 'tomato';
		}


		if (jumper.alerts !== undefined && jumper.alerts.length > 0) {
			alerts = jumper.alerts.map(alert => {
				return (<p>{alert.message}</p>);
			});
		}

		var options = this.state.options;
		if (this.state.options && this.props.activeJumperIdsOnLoad) {
			options = options.filter(
				option =>
					this.props.activeJumperIdsOnLoad.findIndex(
						id => option.value._id === id
					) === -1
			);
		}


		return (
			<React.Fragment>
				{
					!this.state.editMode ?
						// <button className="btn lightBlueButton jumper-name" onClick={this.changeJumper} type="submit" disabled={this.props.disabled}>
						<div onClick={this.changeJumper} disabled={this.props.disabled}>
							{this.props.jumper.nickname !== "NONE" ?
								<div>S: <strong>{this.props.jumper.firstname} {this.props.jumper.lastname}</strong>
									{
										jumper.alerts && jumper.alerts.length > 0 ?
											<React.Fragment>
												<i className="fa fa-exclamation-triangle" style={{ color: alertsColor }} href="#" id={tooltipId}>

												</i>
												<UncontrolledTooltip placement="top" target={tooltipId}>
													{alerts}
												</UncontrolledTooltip>
											</React.Fragment>
											:
											null
									}
								</div>
								:
								<div><i className="fa fa-plus"></i> {this.props.label}</div>
							}
						</div>

						:
						<button className="btn editable-FunJumper" type="submit" disabled={this.props.disabled} style={{ width: '170px' }}>
							<CreatableSelect
								ref={(value) => this.valRef = value}
								isClearable
								isDisabled={false}
								isLoading={false}
								onChange={(selectedOption) => this.handleStudentChange(selectedOption, this.props.loadNum, this.props.jumpId, this.props.type, this.props.label, isCompleted, loadId, affJump, isUltra)}
								onCreateOption={(value) => this.handleCreateStudent(value, this.props.loadNum, this.props.jumpId, this.props.type, this.props.label, isUltra)}
								options={options}
								menuPortalTarget={document.body}
								menuPosition="fixed"
								onBlur={() => this.onBlur(this.state.editMode, this.props.isCompleted)}
							/>
						</button>
				}
			</React.Fragment>
		);
	}
}


const mstp = state => {
	return {
		state
	}
}

const mdtp = dispatch => {
	return {
		updateCompletedCoachJump: (loadId, cjumpId, jumpPostBody) => dispatch(updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)),
		updateCompletedAffJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedAffJump(loadId, cjumpId, jumpPostBody)),
		updateCompleteTandemJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody)),
	}
}

export default connect(mstp, mdtp)(CreatableJumper)
