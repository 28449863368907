import React, { Component } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { components } from 'react-select';
import moment from 'moment';
import { Table } from 'reactstrap';
import QRCode from "qrcode.react";
import { ScaleLoader } from 'react-spinners';

import './index.css'
import 'typeface-roboto'

import jumpersStore from '../../stores/jumpers-store';
import logo from '../images/logo.svg';
import background from '../images/background.svg';
import LeftMenu from '../menu/LeftMenu1';
import reportStore from '../../stores/report-store';
import itemStore from '../../stores/item-store';
import loadStore from '../../stores/loads-store';
import RemoveItem from '../store/modals/removeItem';
import loadsStore from '../../stores/loads-store';
import { GeneratePdf } from './GeneratePdf'
import CreateEditRigs from '../store/modals/CreateEditRigs.js';

const customStyles = {
	control: base => ({
		...base,
		minHeight: 30
	}),
	menu: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		color: state.isSelected ? "red" : "blue",
		padding: 20,
		height: 730,
		marginTop: 0,
		background: "#E7F2F6"
	}),
	menuList: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		padding: 20,
		minHeight: 700
	})
};

class Store extends Component {
	constructor(props) {
		super(props);

		this.state = {
			width: window.innerWidth,

			open: false,
			generatePdf: false,
			dataURLArray: [],
			rightOpen: false,

			jumperOptions: [],

			pdfLoading: false,

			adminDefaultOptions: [],

			tandemStudentOptions: [],

			currentJumper: [],

			cardArray: [],

			loadPlaneArray: [],

			priceSchedule: [],

			paySchedule: [],

			completedLoads: [],

			jumperAlert: false,

			visible: true,

			warning: false,

			velocityButton: false,

			countersaleItems: [],

			tandemRigs: [],

			studentRigs: [],

			sportRigs: [],

			categories: [],
			allRigs: [],

			filterItem: [
				{ text: 'Tandem Instrutor', checked: false },
				{ text: 'Aff Instrutor', checked: false },
				{ text: 'Fun Jumper', checked: false },
				{ text: 'Staff', checked: false },
				{ text: 'Coach', checked: false }
			]
		};
		this.inputValue = '';
		this.onDismiss = this.onDismiss.bind(this);
	}

	handleWarning = () => {
		this.setState({
			warning: true
		})

		setTimeout(() => {
			this.setState({
				warning: false
			})
		}, 2000)
	}

	handleDeleteCard = (data) => {
		jumpersStore.loadAllJumpers();
		this.setState({
			cardArray: data.cardsOnFile
		})
	}

	handelCardAdd = (data) => {
		this.setState({
			cardArray: data
		})
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	handleExistingLoads(loads) {
		this.setState({ completedLoads: loadsStore.completedLoads });
	}

	handleCompleteLoad(loads) {
		////console.log('in handle complete Load');
		this.setState({ completedLoads: loads.completedLoads });
	}


	componentWillUnmount = () => {
		window.removeEventListener('resize', this.handleWindowSizeChange);

	}

	renderItems = () => {

		itemStore.loadItems().then(res => {
			this.updateItems()
		})
	}


	componentDidMount = () => {
		////console.log('in components did mount accounts');
		this.updateItems()

		// console.log("i am here")
		// reportStore.getCoupons().then(res => {
		//     console.log(res,"i am here Resssssssss")
		// })
	}


	updateItems = async () => {

		let allRigs = []
		var options = jumpersStore.getJumperOptions();

		this.setState({ jumperOptions: options, adminDefaultOptions: options.slice(0, 50) });
		////console.log(this.state.adminDefaultOptions);
		////console.log(this.state.jumperOptions);

		var countersaleItems = itemStore.getCountersaleItems();
		var categories = itemStore.getCategories();



		var tandemRigs = itemStore.getTandemRigs();
		var sportRigs = itemStore.getSportRigs();
		var studentRigs = itemStore.getStudentRigs();

		// console.log('tandemRigs: ' + tandemRigs);
		// console.log('studentRigs: ' + sportRigs);
		// console.log('sportRigs: ' + studentRigs);

		var discounts = itemStore.getDiscounts();

		allRigs = allRigs.concat(tandemRigs)
		allRigs = allRigs.concat(studentRigs)
		allRigs = allRigs.concat(sportRigs)


		this.setState({ allRigs, tandemRigs: tandemRigs, studentRigs: studentRigs, sportRigs: sportRigs });


		if (window.globals && window.globals['accountsState']) {
			this.setState({ ...window.globals['accountsState'] });
		}

		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	handleJumperCreated(data) {
		this.setState({
			jumperAlert: true,
			currentJumper: data,
			cardArray: data.cardsOnFile
		}, () => {
			document.getElementsByClassName("rating-button")[0].click();
		})
		setTimeout(() => {
			this.setState({ jumperAlert: false });
		}, 2000)
	}

	// componentDidUpdate = () => {
	// 	////console.log('in component did update accounts');
	// 	window.globals = { ...window.globals };
	// 	window.globals.accountsState = this.state;
	// 	// this.setState({ completedLoads: loadsStore.completedLoads});

	// }

	handleJumpersLoaded(jumperOptions) {
		////console.log('in handle jumpers loaded in accounts');
		var adminDefaultOptions = jumperOptions.slice(0, 50);
		//alert('handle Jumpers Loaded');
		this.setState({
			jumperOptions: jumperOptions,
			adminDefaultOptions: adminDefaultOptions
		});
	}

	handleJumperUpdated = (jumper) => {
		//alert(JSON.stringify(jumper));
		this.setState({ currentJumper: jumper, cardArray: jumper.cardsOnFile });
	}



	handlePlaneLoaded(planeOptions) {
		////console.log('in handle Plane Option');

		var that = this;
		Promise.all([loadStore.loadPlanes()])
			.then(function (result) {
				////console.log(result[0]);
				that.setState({
					loadPlaneArray: result[0]
				})
			})
	}

	handlePriceLoaded(priceOption) {
		var that = this;
		Promise.all([reportStore.loadPrices()])
			.then(function (result) {
				////console.log(result);
				that.setState({
					priceSchedule: result[0].priceSchedule,
					paySchedule: result[0].paySchedule
				})
			})
	}

	onToggle = (index, e) => {
		let newItems = this.state.filterItem.slice();
		newItems[index].checked = !newItems[index].checked
		this.setState({
			filterItem: newItems
		})
		//this.filterJumperOptions(this.state.jumperOptions, this.state.dailyActivities);
	}

	renderCheckbox = () => {
		return this.state.filterItem.map((item, index) => {
			var ratingName = item.text + "_" + index;
			var ratingText = "txt_" + item.text + "_" + index;
			return (
				<div className="col-4" style={{ marginBottom: '12px' }}>
					<input type="checkbox" id={ratingName} name={ratingName} onChange={this.onToggle.bind(this, index)} checked={item.checked} />
					<label style={{ marginLeft: "6px", position: 'absolute' }}>{item.text}</label>
				</div>
			)
		})
	}

	checkForNegatives = (price) => {

		if (price === undefined || price === null) {
			price = 0;
		}

		let priceCell = (price < 0) ?
			{
				value: `($${Math.abs(price).toFixed(2)})`
			} :
			{
				value: `$${price.toFixed(2)}`
			}

		if (price < 0) {
			return <span style={{ color: 'red' }}> {priceCell.value}</span>;
		}
		return <span>{priceCell.value}</span>;
	}

	filterJumpers = (inputValue, jumperOptions) => {
		////console.log("in filterJumpers");
		if (inputValue) {
			//console.log(inputValue);
			var filteredOptions = jumperOptions.filter(i =>
				i.label.toLowerCase().includes(inputValue.toLowerCase()));

			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
			else return filteredOptions;
		}
		////console.log('no inputValue');
		return jumperOptions.slice(0, 30);
	};

	handleClick() {
		this.setState({
			open: !this.state.open
		});
	}

	handleRightClick() {
		this.setState({
			rightOpen: !this.state.rightOpen
		});
	}

	promiseOptions = (inputValue, jumperOptions) => {
		////console.log('in pomiseOptions');
		////console.log(this.inputValue);
		return new Promise(resolve => {
			resolve(this.filterJumpers(this.inputValue, jumperOptions));
		});
	}

	handleInputChange = (newValue) => {
		////console.log('handleInputChange');

		//const inputValue = newValue.replace(/\W/g, '');
		//////console.log(inputValue);

		//this.promiseOptions(newValue, jumperOptions);
		this.inputValue = newValue;
		//return inputValue;
	};

	handleSelect = (val) => {
		////console.log('in handleSelect');
		if (val.value !== undefined) {
			////alert(JSON.stringify(val.value));
			// this.onJumperChanged(val.value);
			// jumpersStore.getDailyActivity(val.value._id);
			this.setState({
				currentJumper: val.value,
				cardArray: val.value.cardsOnFile
			});
			////console.log(JSON.stringify(val.value));
			document.getElementsByClassName("rating-button")[0].click()
		}
	}

	Option = (props) => {
		return (
			<div className="row">

				<div className="col-12">
					<components.Option {...props} />
				</div>
			</div>
		);
	}

	renderSettings = () => {
		var settings = this.state.settings;

		if (settings != undefined) {
			return settings.map((item, index) => {
				return (
					<tr key={`planRow${index}`}>
						<td key={`planCell2${index}`}>{item.name}</td>
						<td key={`planCell3${index}`}>{item.value}</td>
					</tr>
				)
			})
		} else {
			return null;
		}
	}



	deleteItem = (id) => {
		alert('delete item');
		itemStore.deleteItem(id);
	}

	onDismiss() {
		// this.setState({ visible: false });
	}

	handleRemove(e) {
		// ////console.log('test')
		var currentKey = e.currentTarget.attributes['data-key'].value;
		var objectId = JSON.parse(localStorage.getItem("ExpiryID"));
		var newArray = objectId.filter((item) => item !== currentKey);
		localStorage.setItem("ExpiryID", JSON.stringify(newArray));
		this.renderAlert();
		this.forceUpdate();
	}

	Menu = (props) => {
		////console.log(props);
		return (
			<div>
				<div className="row custom-header" style={{ height: '100px' }}>
					{this.renderCheckbox()}
				</div>
				<components.Menu {...props}>
					{props.children}
				</components.Menu>
			</div>
		);
	}


	showNumber = (value) => {
		if (value) {
			if (value > 0) {
				return `$${parseFloat(value)}`
			} else {
				return `($${Math.abs(parseFloat(value).toFixed(2))})`
			}
		} else {
			return 0
		}
	}

	renderRigs = (rigs, type) => {
		var items = rigs;

		if (items != undefined) {
			return items.map((item, index) => {
				// var qrstring = JSON.stringify({ item: item.item, rigNumber: item.rigNumber });
				let qrstring = `${window.ENV?.jumperUrl}/rigs?id=${item._id}`
				return (
					<tr key={`planRow${index}`}>
						<th key={`planCell1${index}`} scope="row" data-id={item._id}>{index + 1}</th>
						<td key={`planCell2${index}`}>{item.item}</td>
						<td key={`planCell2${index}`}>{item.main}</td>
						<td key={`planCell2${index}`}>{item.mainSerial}</td>
						<td key={`planCell2${index}`}>{item.reserve}</td>
						<td key={`planCell2${index}`}>{item.reserveSerial}</td>
						<td key={`planCell2${index}`}>{item.container}</td>
						<td key={`planCell2${index}`}>{item.containerSerial}</td>
						<td key={`planCell2${index}`}>{item.aad}</td>
						<td key={`planCell2${index}`}>{item.aadSerial}</td>
						<td key={`planCell4${index}`}>{moment(item.reserveRepackDate).format('DD-MM-YYYY')}</td>
						<td key={`planCell4${index}`}>{item.color}</td>
						<td key={`planCell4${index}`}>{this.showNumber(item.salePrice)}</td>
						<td key={`planCell4${index}`}>{this.showNumber(item.rentalPrice)}</td>
						<td key={`planCell4${index}`}>{this.showNumber(item.dayRentalRate)}</td>
						<td key={`planCell4${index}`}>{this.showNumber(item.packJobPay)}</td>
						<td key={`planCell4${index}`}>{this.showNumber(item.reserveRepackPay)}</td>
						<td key={`planCell4${index}`}><QRCode value={qrstring} size={64} /></td>
						<td key={`planCell4${index}`}>	<CreateEditRigs onSuccess={() => this.renderItems()} isEdit={true} item={item} />
							&nbsp;&nbsp;
							<RemoveItem onSuccess={() => this.renderItems()} item={item} />
						</td>
						<td key={`planCell4${index}`}><i className="fa fa-search"></i></td>


						{/*<td key={`planCell11${index}`}>
						  <CreateBlock isEdit={true} item={item} />&nbsp;&nbsp;
						  <RemoveItem item={item}  itemType ="block"/>
						</td>*/}
					</tr>
				)
			})
		} else {
			return null;
		}
	}


	handlePDFGenerator = () => {
		const { allRigs } = this.state;
		if (allRigs.length) {
			this.setState({ generatePdf: true, pdfLoading: true })
		}
	}


	saveBase64 = () => {
		setTimeout(() => {
			this.setState({ pdfLoading: false })
		}, 1000);
	}

	render() {

		const { width, warning, allRigs, tandemRigs } = this.state;
		const isMobile = width <= 500;

		// console.log(tandemRigs, "tandemRigs657890875643678")

		return (
			<div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
				{
					isMobile ?
						<div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
							<div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
								<LeftMenu routeHistory={this.props.history} />
							</div>
						</div>
						:
						<div className="blueBg fullHeightPanes left-container">
							<img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
							<LeftMenu routeHistory={this.props.history} showCreate={true} />
						</div>
				}

				<div className="middle-container-no-rightmenu" >

					<div className="buttonsOut">

						<CreateEditRigs onSuccess={(data) => this.renderItems(data)} />
						<button onClick={() => this.handlePDFGenerator()} className="buttonMain"> {this.state.pdfLoading ? "Generating PDF.." : 'Create Label'} </button>
					</div>
					<div className="tableOut">
						<Table style={{ backgroundColor: '#E7F2F6', width: '90%' }}>
							<thead style={{ backgroundColor: '#1C6BCF', color: '#28a6e6' }}>
								<tr>
									<th></th>
									<th>rig name</th>
									<th>main</th>
									<th>main serial</th>
									<th>reserve</th>
									<th>reserve serial</th>
									<th>container</th>
									<th>container serial</th>
									<th>aad</th>
									<th>aad serial</th>
									<th>reserve repack date</th>
									<th>color</th>
									<th>sale price</th>
									<th>rental price</th>
									<th>day rental price</th>
									<th>pack job pay</th>
									<th>reserve repack pay</th>
									<th>qr code</th>
									<th />
									<th />
								</tr>
							</thead>
							<tbody>
								<tr className="priceHeading"><td colSpan="3">Tandem Rigs</td></tr>
								{this.renderRigs(this.state.tandemRigs, "tandemRigs")}

							</tbody>
						</Table>
					</div>
					<div className="tableOut">
						<Table style={{ backgroundColor: '#E7F2F6', width: '90%' }}>
							<thead style={{ backgroundColor: '#1C6BCF', color: '#28a6e6' }}>
								<tr>
									<th></th>
									<th>rig name</th>
									<th>main</th>
									<th>main serial</th>
									<th>reserve</th>
									<th>reserve serial</th>
									<th>container</th>
									<th>container serial</th>
									<th>aad</th>
									<th>aad serial</th>
									<th>reserve repack date</th>
									<th>color</th>
									<th>sale price</th>
									<th>rental price</th>
									<th>day rental price</th>
									<th>pack job pay</th>
									<th>reserve repack pay</th>
									<th>qr code</th>
									<th />
									<th />
								</tr>
							</thead>
							<tbody>

								<tr className="priceHeading"><td colSpan="3">Student Rigs</td></tr>
								{this.renderRigs(this.state.studentRigs, "studentRigs")}
							</tbody>

						</Table>
					</div>
					<div className="tableOut">
						<Table style={{ backgroundColor: '#E7F2F6', width: '90%' }}>
							<thead style={{ backgroundColor: '#1C6BCF', color: '#28a6e6' }}>
								<tr>
									<th></th>
									<th>rig name</th>
									<th>main</th>
									<th>main serial</th>
									<th>reserve</th>
									<th>reserve serial</th>
									<th>container</th>
									<th>container serial</th>
									<th>aad</th>
									<th>aad serial</th>
									<th>reserve repack date</th>
									<th>color</th>
									<th>sale price</th>
									<th>rental price</th>
									<th>day rental price</th>
									<th>pack job pay</th>
									<th>reserve repack pay</th>
									<th>qr code</th>
									<th />
									<th />
								</tr>
							</thead>
							<tbody>
								<tr className="priceHeading"><td colSpan="3">Sport Rigs</td></tr>
								{this.renderRigs(this.state.sportRigs, "sportRigs")}
							</tbody>

						</Table>
					</div>
				</div>
				<div id="opacity" style={{ opacity: 0 }}>
					{this.state.generatePdf ? <GeneratePdf stopLoading={() => this.saveBase64()} array={allRigs} /> : ''}
				</div>
				{
					this.state.pdfLoading ? <div className='sweet-loading' style={{
						textAlign: "center",
						marginTop: "200px"
					}}>
						<ScaleLoader
							height={35}
							width={8}
							margin={'2px'}
							radius={2}
							color={'#123abc'}
							loading={this.state.pdfLoading}
						/>
						<span style={{ color: 'white', fontSize: '20px', marginLeft: '-20px ' }}>Please Wait...</span>
					</div> : null
				}
			</div>
		)
	}
}

export default Store;