import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './login1.css';

import authStore from '../../stores/auth-store'
import LoginLoader from './LoginLoader';
import background from '../images/background.svg';
import logo from '../images/logo.svg';
class LogIn extends Component {

    state = {
        username: '',
        password: '',
        isLoading: false,
    }

    setUsername = (username) => {
        this.setState({ username })
    }
    setPassword = (password) => {
        this.setState({ password })
    }
    hideLoader = (isErr) => {
        if (isErr) {
            this.setState({ isLoading: false })
        }
        else {
            // add fadeout animation class
            let loginContainer = document.querySelector(".loadingContainer");
            loginContainer.classList.add('fade-out-loader');

            setTimeout(() => {
                this.setState({ isLoading: false });
                this.props.history.push('/load-manager')
                window.location.reload(true);

            }, 1000)
        }
    }
    loginSubmit = () => {
        this.setState({ isLoading: true })
        authStore.login({ username: this.state.username, password: this.state.password })
            .then(data => {
                if (data.success) {
                    localStorage.clear();
                    localStorage.setItem("isAuth", true);
                    localStorage.setItem("Auth-token", data.token);
                    if (data.role) { localStorage.setItem("role", data.role) }
                    if (data.jumperInfo) localStorage.setItem("jumperDetails", JSON.stringify(data?.jumperInfo))

                    this.hideLoader(false); //isErr=false
                    this.props.history.push('/load-manager')
                    window.location.reload(true);
                    //window.location.reload(true);

                }
                else {
                    this.hideLoader(true); //isErr=true
                    if (data = 'Unauthorized') {
                        toast.error("Opps.. Invalid Login");
                    }
                }
            }).catch(err => {
                //
                this.hideLoader(true) //isErr=true
                toast.error("Server error. Please try again");
            })
    }
    render() {
        let { username, password, isLoading } = this.state;
        return (
            <div>
                {
                    isLoading ?
                        <div className="loadingContainer">
                            <LoginLoader />
                        </div> :
                        <div className="page-wrapper">
                            <div className="container-fluid p-0" >
                                {/* <!-- login page start--> */}
                                <div className="authentication-main" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="auth-innerright">
                                                <div className="authentication-box">
                                                    <div className="text-center">
                                                        <img src={logo} alt="" />
                                                    </div>
                                                    <div className="card mt-4">
                                                        <div className="card-body auth-cardbody">
                                                            <div className="text-center">
                                                                <h4>LOGIN</h4>
                                                                <h6>{"Enter your Username and Password"} </h6>
                                                            </div>
                                                            <form className="theme-form" >
                                                                <div className="form-group">
                                                                    <label className="col-form-label pt-0">Username</label>
                                                                    <input className="form-control" type="username" name="username"
                                                                        value={username}
                                                                        onChange={e => this.setUsername(e.target.value)}
                                                                        placeholder="Enter Username"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="col-form-label">Password</label>
                                                                    <input className="form-control" type="password" name="password"
                                                                        value={password}
                                                                        onChange={e => this.setPassword(e.target.value)} />

                                                                </div>

                                                                <div className="form-group form-row mt-3 mb-0">
                                                                    <button className="btn btn-primary btn-block" type="button" onClick={this.loginSubmit} >
                                                                        LOGIN
                                                                    </button>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <ToastContainer autoClose={3000} />
                                {/* <!-- login page end--> */}
                            </div>
                        </div>
                }
            </div>
        )
    }
}
export default withRouter(LogIn);
