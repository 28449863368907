


var url = window.location.hostname;

let mainUrl =  'http://localhost:3001';
let reservationUrl =  'http://localhost:4000'

if(url.includes("localhost")){
	mainUrl =  'http://localhost:3001'
    reservationUrl =  'http://localhost:4000'
}

else{
	var dz = url.split('.')[0];
	if(dz){
		mainUrl = `https://${dz}.main-api.dizio.app`;
		reservationUrl = `https://${dz}.reservations-api.dizio.app`;
	}
}

const func = {};
func.getMainApiUrl = () => {
    return mainUrl;
}

func.getReservationApiUrl = () => {
    return reservationUrl;
}
export default func;