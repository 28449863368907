
import React, { Component } from 'react';
import CreateEventModal from '../loadManager/modals/createEvent';
import IncompleteReservation from '../loadManager/modals/incompleteReservation';
import schedulesStore from '../../stores/schedule-store';
import reservationStore from '../../stores/reservation-store';
import RightColumnCards from '../sub-components/cards/rightColumnCards'
import Api from '../../services/reservationapi';
import ChangeSlot from './reservation/changeSlot';
import ViewReservations from './viewReservations';
import SuspendReservation from './SuspendReservation';
import booking from '../BookingComponent/booking';
import Send_Multiple_ReminderEmails from '../schedules/sendMultipleReminderEmail';
//import moment from 'moment';

class SchedulesRight extends Component {
  constructor(props) {
    super(props);
    // this.handleReservationEventClick = this.handleCreateEventClick.bind(this);
    this.state = {
      showModal: false,
      passengers: [],
      currentDate: '',
      close: false,
      isBlackOut: false,
      incompleteReservation: [],
      showincompleteModel: false,
      isIncompleteNullSlot: false,
      suspendData: [],
      emailTemplates: []
    };
    this.IncompleteResevation = this.IncompleteResevation.bind(this);

  }

  componentWillMount = () => {
    // schedulesStore.();
    let eventName = schedulesStore.getEventPrefix('fetchPassengers');
    let eventDate = schedulesStore.getEventPrefix('fetchReserveDate');
    let blackOutEvent = schedulesStore.getEventPrefix('blackOutEvent');
    let slotblackOutEvent = schedulesStore.getEventPrefix('slotblackOutEvent')

    // let eventIncompleteResevation = schedulesStore.getEventPrefix('IncompleteResevation');
    // schedulesStore.on(eventIncompleteResevation, this.handleIncompleteReservation);
    //let eventReservation = schedulesStore.getEventPrefix('closeReservationEvent');

    schedulesStore.on(eventName, this.handleDateSelect);
    schedulesStore.on(eventDate, this.handleReserveDate);
    schedulesStore.on(blackOutEvent, this.handleBlackOut);
    schedulesStore.on(slotblackOutEvent, this.handleSlotBlackout);
    //schedulesStore.on(eventReservation, this.handleReservationClose);

    let eventClose = schedulesStore.getEventPrefix('closeReservationEvent');
    schedulesStore.on(eventClose, this.handleReservationClose);

    if (window.ENV?.processorInfo.processor === "nab") {
      Api.startSession()
        .then(res => {
          console.log(res);
        });
    }
    // var profileId, merchantProfileId, serviceID;

    // Api.getIdentityInfo()
    //   .then(info => {
    //     reservationStore.setProcessorVals(info.data);
    //     profileId = info.data.applicationProfileId;
    //     merchantProfileId = info.data.merchantProfileId;
    //     serviceID = info.data.ServiceID;
    //     return Api.startSession(info.data.IdentityToken);
    //   })
    //   .then(r => {
    //     if (r.data) {
    //       reservationStore.setTokENV?.l(r.data);
    //     }
    //     //console.log(reservationStore.getTokENV?.l());
    //     return Api.getApplicationData(reservationStore.getTokENV?.l(), profileId);
    //   })
    //   .then(r => {
    //     var applicationData = r.data;
    //     //console.log(applicationData);
    //     return Api.getServiceInformation(reservationStore.getTokENV?.l());
    //   })
    //   .then(r => {
    //     return Api.isMerchantProfileInitialized(reservationStore.getTokENV?.l(), merchantProfileId, serviceID);
    //   })
    //   .then(r => {
    //     //console.log(r.data);
    //   })
  }

  handleBlackOut = (blackOut) => {
    this.setState({
      isBlackOut: blackOut
    })
  }

  handleSlotBlackout = (blackout) => {
    if (blackout) {
      alert("BlackOut Successfully")
    }
    else {
      alert("Undo BlackOut Successfully")
    }
  }
  componentDidMount(){
    this.loadEmailsTemplates()
  }

  handleDateSelect = (data) => {
    // var time = moment(date[0].datetime).format("hh:mm:ss a");
    var isBlackedOutEvent = data.timeslots.filter((event) => event._id === this.props.selectedEventId);
    this.setState({
      passengers: data.timeslots,
      isBlackOut: isBlackedOutEvent.length > 0 ? isBlackedOutEvent[0].isBlackedOut : false
    })
  };

  handleIncompleteReservation = (data) => {
    this.setState({ incompleteReservation: data })
    data.forEach(element => {
      if (element.slotId != null) {
        this.setState({ isIncompleteNullSlot: true })
      }
    });
  };

  handleReserveDate = (d) => {
    // console.log("currentDate",d);`
    this.setState({ currentDate: d.date })
  };

  handleReservationClose = () => {
    this.setState({ currentDate: '' })
  };

  handleCreateEventClick = () => this.setState({ showModal: true });
  handleModalCancel = () => this.setState({ showModal: false });

  handleReservationEventClick() {
    schedulesStore.postPassangerEvent();
    //alert('hello');
    //this.props.history.push('/booking')
  }

  handleTandemReservation() {
    this.props.history.push('/booking')
  }

  handleGiftEventClick() {
    this.props.history.push('/giftCard')
  }

  handleCloseClick() {
    schedulesStore.closeReservation();
  }
  onCancelModel = () => {
    this.setState({
      showincompleteModel: false
    })
  }

  generateList = (reservations) => {
    return (
      <React.Fragment>
        { // display no value component when we dont have anything
          reservations.length === 0 &&
          <small style={{ lineHeight: '14px', display: 'block' }}>
            No Details To View, Please Select A Date
          </small>
        }
        <ol>
          {
            reservations &&
            reservations.length > 0 &&
            reservations[0].tandemStudents.map((itm) => <li key={itm._id}>{itm.firstname} {itm.lastname}</li>)
          }
        </ol>
      </React.Fragment>
    )
  }

  bookings = (id, singleTimeSlots , _selectedEmailTemplate) => {
    this.setState({ selectedTemplate: _selectedEmailTemplate, isSendEmailModalOpen: true, selectedEventId: id, singleTimeSlots: singleTimeSlots,selectedEmailTemplate: _selectedEmailTemplate });
  };

  handleBlackout = (blackOut) => {
    console.log('Click', this.state.currentDate);
    var payload = {
      day: this.state.currentDate,
      isBlackedOut: blackOut
    }
    schedulesStore.blackOutSlots(payload);
  }
  showIframe = (val) => {
    // window.open(window.ENV?.reservationLink, "_blank")
    this.props.showIframe(val);
  }
  IncompleteResevation = () => {
    schedulesStore.IncompleteResevation()
      .then(res => {
        this.setState({ incompleteReservation: res }, () => {
          this.setState({ showincompleteModel: true })
        })
        // res.forEach(element => {
        //   if (element.slotId != null) {
        //     this.setState({ isIncompleteNullSlot: true })
        //   }
        // });
        // this.setState({ showincompleteModel: true })
      })
  }
  loadEmailsTemplates = () => {
    console.log("calledschedulesStore");
    schedulesStore
      .getemailtemplates()
      .then((resp) => {
        console.log(resp, "resprespresprespresprespresp");
        this.setState({
          emailTemplates: resp.data,
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  render() {
    // console.log("this.state.passengers ",this.state.passengers )
    const { currentDate } = this.state;

    return (
      <React.Fragment>
      <div style={{ minHeight: 200, padding: '0px' }}>

          <div style={{ height: '100%', overflow: 'hidden' }}>
     
        {/* <div className="col-md-2 col-lg-2 col-sm-12 blueBg fullHeightPanes rightCol" style={{ padding: '0px',zIndex:99,minHeight:'100%' }}> */}
        {this.state.isSendEmailModalOpen && (
          <Send_Multiple_ReminderEmails
            currentSelectedDate={this.currentSelectedDate}
            isMemoModalOpen={this.state.isSendEmailModalOpen}
            selectedTemplate={this.state.emailTemplates.find((temp) => temp._id == this.state.selectedTemplate)}
            state={this.state}
            setState={this.setState.bind(this)}
          />
        )}
        {/* <div style={{ minHeight: 200, padding: '0px' }}>

          <div style={{ height: '100%', overflow: 'hidden' }}> */}
            <div className="columnHeading" >TIME SLOT DETAILS</div>

            {this.state.currentDate != '' ? <p style={{ marginLeft: '13%', marginTop: '10px' }}>Date: {this.state.currentDate}</p> : ''}

            <div className="columnBody">
              <button onClick={this.handleCreateEventClick} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px' }}>
                <i className="fa fa-plus"></i>  CREATE TIMESLOT
              </button>


              <button onClick={() => this.showIframe("RESERVATION")} className="btn btn-block btn-bg"
                style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                CREATE RESERVATION
              </button>

              <ViewReservations />
              {/*<button disabled={true} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                VIEW LIMBO RESERVATIONS
        </button>*/}

             <SuspendReservation/>

              {/* <button disabled={this.state.isIncompleteNullSlot == false ? true : false} onClick={() => this.setState({ showincompleteModel: true })} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}> */}
              <button onClick={this.IncompleteResevation} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                VIEW INCOMPLETE RESERVATIONS
              </button>

              {/* {
                this.state.currentDate && this.state.isBlackOut
                  ?
                  <button onClick={() => this.handleBlackout(false)} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                    Undo Blackout
                  </button>
                  :
                  <button onClick={() => this.handleBlackout(true)} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                    Blackout Day
                 </button>
              } */}

              {/* {
                this.state.currentDate != '' ?
                  <button onClick={this.handleTandemReservation.bind(this)} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                    CREATE RESERVATION
                  </button>
                  : ''
              } */}

              <button onClick={() => this.showIframe("GIFTCARD")} className="btn btn-block btn-bg" style={{ background: '#EB5B5D', color: 'white', marginBottom: '20px', width: '100%', fontSize: '15px' }}>
                CREATE GIFT CARD
              </button>

              {
                this.state.passengers != undefined ?
                  <RightColumnCards event={this.state.passengers} currentDate={currentDate} edit={true}
                  templates={this.state.emailTemplates} 
                  emailTemplates={this.state.emailTemplates}
                  _bookings={this.bookings} /> : ''
                // this.state.passengers.map((evnt) => <RightColumnCards key={evnt._id} evnt={evnt} currentDate={currentDate} edit={true}/>) : ''
              }
            </div>
          </div>
          <div className={`customModal ${this.state.showModal ? "visible" : ""}`}>
            <CreateEventModal handleCancle={this.handleModalCancel} />
          </div>
          {
            this.state.showincompleteModel &&
            <IncompleteReservation incompleteReservation={this.state.incompleteReservation} suspendData={this.state.suspendData} showincompleteModel={this.state.showincompleteModel} onCancelModel={this.onCancelModel} />

          }
          {/* <div className={`customModal ${this.state.showincompleteModel ? "visible" : ""}`}> */}
          {/* <IncompleteReservation incompleteReservation={this.state.incompleteReservation} showincompleteModel={this.state.showincompleteModel} onCancelModel={this.onCancelModel} /> */}
          {/* </div> */}



        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default SchedulesRight;
