import React, { Component } from 'react'
import { Table } from 'reactstrap'
export default class Alerts extends Component {

    renderAlert = () => {
        let { checkExpiry } = this.props;
        //var completedLoads = loadsStore.completedLoads;

        if (localStorage.getItem("ExpiryID") !== null) {
            var completedLoads = JSON.parse(localStorage.getItem("ExpiryID"));
            //var completedLoads = loadsStore.expiryID;

            if (completedLoads !== undefined && completedLoads.length > 0) {
                //return completedLoads.map((item, index) => {
                return (
                    checkExpiry(completedLoads)
                )
                //})
            }
        }

        // if (completedLoads !== undefined && completedLoads.length > 0)
        // 	return completedLoads.map((item, index) => {
        // 		return (
        // 			this.checkExpiry(item.activeJumperIds)
        // 		)
        // 	})
        // else {
        // 	return completedLoads !== undefined && this.state.completedLoads.map((item, index) => {
        // 		return (
        // 			this.checkExpiry(item.activeJumperIds)
        // 		)
        // 	})
        // }
    }
    render() {
        return (
            <div className="col-6" style={{ padding: '11px' }}>
                <Table>
                    <thead className="main-heading">
                        <tr>
                            <th>Expiry Alerts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {this.renderAlert()}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}
