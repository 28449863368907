/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';

import AdminModal from './customModal';

class EditItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        }
    }

    toggleModal = (e) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    handleSubmit = (values) => {
        //console.log("in handle submit load"+ values);
        this.toggleModal();
        var payload = {};
        if (values !== undefined) {
            payload.item = values.item;
            payload.category = "video package";
            payload.videoType = values.videoType;
            payload.videoPrice = Number(values.videoPrice);
            payload.videoPay = Number(values.videoPay);
            payload.wristcamPay = Number(values.wristcamPay);
            payload.description = values.description;
            payload.isBookable = values.bookable;
            payload.isTaxable = values.taxable;
            payload.videoLink = values.videoLink;
        }
        if (this.props.isEdit) {
            // console.log(payload);
            itemStore.updateItem(this.props.item._id, payload);
        }
        else {
            itemStore.createItem(payload);
        }
    }

    render() {

        var item = '';
        if (this.props.item !== undefined) {
            item = this.props.item;
        }
        console.log("items: ", item);
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Video Package`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="item" md={3}>Item</Label>
                            <Col md={9}>
                                <Control.input name="item" model=".item" className="form-control" defaultValue={item.item}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>


                        <Row className="form-group">
                            <Label htmlFor="videoType" md={3}>Video Type</Label>
                            <Col md={9}>
                                <Control.select name="videoType" model=".videoType" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off">
                                    <option value="none">none</option>
                                    <option value="wristcam">wristcam</option>
                                    <option value="outside">outside</option>
                                    <option value="both">both</option>
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="videoPrice" md={3}>Video Price</Label>
                            <Col md={9}>
                                <Control.input name="videoPrice" model=".videoPrice" className="form-control" defaultValue={item.videoPrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="videoPay" md={3}>Video Pay</Label>
                            <Col md={9}>
                                <Control.input name="videoPay" model=".videoPay" className="form-control" defaultValue={item.videoPay}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="wristcamPay" md={3}>Wristcam Pay</Label>
                            <Col md={9}>
                                <Control.input name="wristcamPay" model=".wristcamPay" className="form-control" defaultValue={item.wristcamPay}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="videoLink" md={3}>Video Link</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.textarea name="videoLink" model=".videoLink" className="form-control" defaultValue={item.videoLink}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="description" md={3}>Description</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.textarea name="description" model=".description" className="form-control" defaultValue={item.description}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="bookable" md={3}>Bookable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="bookable" model=".bookable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isBookable}
                                    autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="taxable" md={3}>Taxable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="taxable" model=".taxable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isTaxable}
                                    autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {!this.props.isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </React.Fragment>
        );
    }
}

export default EditItem
