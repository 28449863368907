import React, { Component, Fragment } from 'react';

import { Control, LocalForm, Errors } from 'react-redux-form';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';


import Select from 'react-select';
import loadsStore from '../../../stores/loads-store';
import Fuse from "fuse.js";
import ReactDOM from 'react-dom';
import { DragSource } from 'react-dnd'
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'
import jumpersStore from '../../../stores/jumpers-store';
import accountStore from '../../../stores/account-store';
import scheduleStore from "../../../stores/schedule-store";
import terminalAdapter from '../../../stores/terminalAdapter';
import itemStore from '../../../stores/item-store';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import RefundConfirMation from '../../accounts/modals/refundConfirmation'
import ViewDetail from '../../accounts/modals/jumperDetail';
import TransferCredit from '../../accounts/modals/transferCredit';
import MergeJumpers from '../../accounts/modals/mergeJumpers';
import TransferBlockTickets from '../../accounts/modals/transferBlockTickets';
import ReturnBlockTickets from '../../accounts/modals/returnBlockTickets';
import RemoveBlockTickets from '../../accounts/modals/removeBlockTickets';
import UpgradeBlockTickets from '../../accounts/modals/upgradeBlockTickets';

import TransferInternalTandem from '../../accounts/modals/transferInternalTandem';

import JumperProfile from '../../accounts/modals/jumperProfile'
import ChargeJumper from '../../accounts/modals/chargeJumper';
import AddItem from '../../accounts/modals/addItem';
import AddModal from '../../accounts/modals/addModal';
import WaiverDetail from '../../accounts/modals/waiverDetail';
import CreatableSelect from 'react-select/lib/Creatable';
import JoinTandems from '../../accounts/modals/joinTandems';
import EditableOptions from './editableOptions';
import EditablePrice from './editablePrice';
import JumperDetail from '../../accounts/modals/jumperDetail';
// import schedulesStore from '../stores/schedule-store';
import schedulesStore from '../../../stores/schedule-store';
import FileSaver from 'file-saver';
import {
	Modal, ModalHeader, ModalBody, Col, Row, Label, Badge
} from 'reactstrap';

import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import SubmitStudentLog from './submitStudentLog';
import RatingModal from '../../admin/modals/ratingModal';

const Types = {
	ITEM: 'FunJump'
};


const customStyles = {
	control: base => ({
		...base,
		minHeight: 30
	}),
	menu: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		color: "black",
		padding: 20,
		height: 530
	}),
	menuList: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		padding: 20,
		minHeight: 500
	})
};

class SearchJumper extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			isLoading: true,
			dailyActivity: {},
			otherActivities: [],
			tandemGroup: {},
			cardsOnFile: [],
			accountOnFile: [],
			dizioAchOnFile: [],
			jumperId: '',
			timeslots: [],
			transactionAmount: this.props.transactionAmount,
			transactionId: this.props.transactionId,
			isTandemStudent: false,
			currentJumper: [],
			isTandemGroup: false,
			selectedDues: {},
			selectedItems: [],
			selectedBalance: [],
			event: {}
		};

		this.jumperEvents = [
			{ name: jumpersStore.getEventPrefix('dailyActivity'), func: this.handleDailyActivity },
			{ name: jumpersStore.getEventPrefix('jumperUpdated'), func: this.handleJumperUpdated },
			{ name: jumpersStore.getEventPrefix('updateManifestStatus'), func: this.handleManifestStatus },
			{ name: jumpersStore.getEventPrefix('transfer'), func: this.handleTransfer },
			{ name: jumpersStore.getEventPrefix('tip'), func: this.handleTip },
			{ name: jumpersStore.getEventPrefix('setPackage'), func: this.handleSetPackage },
			{ name: jumpersStore.getEventPrefix('itemsAdded'), func: this.handleItemsAdded },
		];

		this.accountsEvents = [
			{ name: accountStore.getEventPrefix('itemsAdded'), func: this.handleItemsAdded },
			{ name: accountStore.getEventPrefix('chargeAdded'), func: this.handleChargeAdded }
		];

		this.processorEvents = [
			{ name: terminalAdapter.getEventPrefix('chargeAdded'), func: this.handleChargeAdded }
		];


		this.scheduleEvents = [
			{
				name: scheduleStore.getEventPrefix("todayReservationEvent"),
				func: this.handleReservationEvent
			}
		];

		this.gearIds = [];
		this.valRef = null;
		this.inputValue = '';
		this.fuse = null;
		this.isConfirm = true;
	}

	componentDidMount = () => {
		if (this.valRef !== null)
			this.valRef.focus();
		this.setFuse(this.props.options);
	}

	setFuse = (jumperOptions) => {
		var options = {
			shouldSort: true,
			tokenize: true,
			threshold: 0.1,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 2,
			keys: [
				"value.manifestNumber",
				"value.nickname",
				"value.lastname"
			]
		};

		this.fuse = new Fuse(jumperOptions, options);
	}

	componentDidUpdate = () => {
		this.setFuse(this.props.options);
	}

	componentWillMount = () => {
		this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
		this.accountsEvents.forEach((evnt) => accountStore.on(evnt.name, evnt.func));
		this.scheduleEvents.forEach(evnt => scheduleStore.on(evnt.name, evnt.func));
		this.processorEvents.forEach(evnt => terminalAdapter.on(evnt.name, evnt.func));

		// console.log('fetchign today reservations from search Jumper');
		//scheduleStore.fetchTodayReservation();
	}


	componentWillUnmount = () => {
		this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
		this.accountsEvents.forEach((evnt) => accountStore.removeListener(evnt.name, evnt.func));
		this.processorEvents.forEach((evnt) => terminalAdapter.removeListener(evnt.name, evnt.func));
		this.scheduleEvents.forEach(evnt =>
			scheduleStore.removeListener(evnt.name, evnt.func)
		);
	}

	handleJumperUpdated = (jumper) => {
		this.setState({
			currentJumper: jumper,
			cardsOnFile: jumper.cardsOnFile,
			dizioAchOnFile: jumper.dizioAchOnFile,
			accountOnFile: jumper.accountOnFile
		});
	}

	handleReservationEvent = data => {
		this.setState({
			timeslots: data.timeslots
		});
	};


	handleDailyActivity = async (data) => {
		console.log(data, "===>>>>>fjdvhfianjskm,")
		console.log('hande daily activity');
		var cardsOnFile = [];
		var accountOnFile = [];
		let dizioAchOnFile = [];
		// console.log('in handle Daily Activity');
		if (!data.isTandemStudent) {
			cardsOnFile = jumpersStore.getJumperById(data.jumperId).cardsOnFile;
			accountOnFile = jumpersStore.getJumperById(data.jumperId).accountOnFile;
			dizioAchOnFile = jumpersStore.getJumperById(data.jumperId).dizioAchOnFile;
		}
		else {
			// debugger;
			var tandemStudent = jumpersStore.getTandemStudentById(data.jumperId);
			if (tandemStudent !== undefined) {
				cardsOnFile = jumpersStore.getTandemStudentById(data.jumperId).cardOnFile;
			}

		}
		this.setState({
			dailyActivity: data.dailyActivity,
			currentJumper: data.dailyActivity.jumperRef,
			otherActivities: data.otherActivities,
			jumperId: data.jumperId,
			isTandemStudent: data.isTandemStudent,
			isLoading: false,
			cardsOnFile: cardsOnFile,
			accountOnFile: accountOnFile,
			dizioAchOnFile
		});
		var stateObj = {
			dailyActivity: data.dailyActivity,
			otherActivities: data.otherActivities,
			jumperId: data.jumperId,
			isTandemStudent: data.isTandemStudent,
			isLoading: false,
		}
		if (cardsOnFile) {
			stateObj.cardsOnFile = cardsOnFile;
		}
		if (data.tandemGroup) {
			stateObj.tandemGroup = data.tandemGroup;
			stateObj.isTandemGroup = true;
		}
		else {
			console.log('unsetting tandem group');
			stateObj.tandemGroup = null;
			stateObj.isTandemGroup = false;
			stateObj.otherActivities = [];
		}
		this.setState(stateObj);

		// console.log('here in daily activity alert')
		if (this.props.tandems && data.dailyActivity.isTandemStudent || !this.props.tandems && data.dailyActivity.jumperId !== undefined) {
			this.setState({ isModalOpen: true });
		}
		this.setState({ event: data.event })
		// await schedulesStore.fetchPassengers(event);
	}

	handleManifestStatus = (data) => {

		this.setState({
			dailyActivity: data.dailyActivity,
			otherActivities: data.otherActivities
		})
	}

	handleChargeAdded = (data) => {
		let stateObj = {
			dailyActivity: data.dailyActivity,
			otherActivities: data.otherActivities
		};
		if (data.tandemGroup) {
			stateObj.tandemGroup = data.tandemGroup;
			stateObj.isTandemGroup = true;
		}
		this.clearSelectedDues();
		this.clearSelectedBalance();
		this.clearSelectedItems();
		this.setState(stateObj);
	}

	handleSetPackage = (data) => {
		//same as handle DailyActivity for now
		console.log('handleSetPackage');
		let stateObj = {
			dailyActivity: data.dailyActivity,
			otherActivities: data.otherActivities
		};

		if (data.tandemGroup) {
			stateObj.tandemGroup = data.tandemGroup;
			stateObj.isTandemGroup = true;
		}
		console.log(data.tandemGroup);


		this.setState(stateObj);
	}

	handleItemsAdded = (data) => {
		//alert('in handle Items added');
		//same as handle DailyActivity for now
		console.log('handle items added');
		console.log(data);
		let stateObj = {
			dailyActivity: data.dailyActivity,
		};
		if (data.otherActivities) {
			stateObj.otherActivities = data.otherActivities
		}
		if (data.tandemGroup) {
			stateObj.tandemGroup = data.tandemGroup;
			stateObj.isTandemGroup = true;
		}
		console.log(stateObj);
		this.setState(stateObj, () => {
			console.log('after state update');
		});
	}

	handleTransfer = (data) => {

		this.setState({
			dailyActivity: data.fromActivity,
			jumperId: data.fromId
		});
	}

	handleTip = (data) => {
		this.setState({
			dailyActivity: data.fromActivity,
			jumperId: data.fromId,
			isTandemStudent: true
		});
	}

	handleTandemSelect = (val) => {
		//console.log('in handleSelectTandem');
		//console.log(val.value);
		if (val !== null && val.value !== undefined) {
			//this.onJumperChanged(val.value);
			//console.log("tandemid", val)
			jumpersStore.getDailyTandemActivity(val.value);
		}
		this.setState({ isModalOpen: true, isLoading: true });
	}

	updateGear = (value) => {
		var id = value.target.name.substr(4);
		if (value.target.checked) {
			this.gearIds.push(id);
		}
		else {
			this.gearIds = this.gearIds.filter(gearId => gearId !== id);
		}
	}

	Option = (props) => {
		var gearModel = ".gear" + props.data.value._id;
		var name = "gear" + props.data.value._id
		return (
			<div className="row">
				<div className="col-8">
					<components.Option {...props} />
				</div>
				<div className="col-4" style={{ paddingTop: '6px' }}>
					gear <Control.checkbox model={gearModel} name={name} onChange={value => { this.updateGear(value) }} />
				</div>
			</div>
		);
	}

	SingleValueLabel = ({ children, ...props }) => {
		return (
			<components.SingleValue {...props}><div className="row"> <div className="col-12">{children}</div>{this.gearIds.indexOf(props.data.value._id) > -1 ? <div className="col-12">-G</div> : null}</div></components.SingleValue>
		);
	}

	handleSelect = (selectedOption) => {

		//alert(JSON.stringify(selectedOption));
		if (selectedOption.value !== undefined) {
			jumpersStore.getDailyActivity(selectedOption.value._id);
			this.setState({ isModalOpen: true, isLoading: true, jumperId: selectedOption.value._id });
		}
	}

	closeModal = () => {
		this.setState({ selectedItems: [] })
		this.setState({ selectedDues: {} })
		this.setState({ selectedBalance: [] })
		this.setState({ isModalOpen: false });
	}


	filterJumpers = (inputValue, jumperOptions) => {
		console.log('in filterJumpers');
		if (inputValue) {
			var filteredOptions = this.fuse.search(inputValue.toLowerCase());
			console.log(filteredOptions);
			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
			else if (filteredOptions.length == 0) {
				jumpersStore.GetJumperOptions(inputValue.toLowerCase())
					.then(options => {
						console.log('after backend search');
						console.log(options);
						return options
					})
			}
			else return filteredOptions;
		}
		return jumperOptions;
	};

	filterTandems = (inputValue, tandemOptions) => {

		if (inputValue) {

			var filteredOptions = tandemOptions.filter(opt => opt.label.toLowerCase().includes(inputValue.toLowerCase()));

			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);

			else if (filteredOptions.length == 0) {
				jumpersStore.GetTandemOptions(inputValue.toLowerCase())
					.then(options => {
						return options
					})
			}
		}
		return tandemOptions;
	};

	promiseOptions = (inputValue, jumperOptions) => {
		return new Promise(resolve => {
			var options =
				//resolve(this.filterJumpers(this.inputValue, jumperOptions));
				jumpersStore.GetJumperOptions(inputValue.toLowerCase())
					.then(options => {
						resolve(options);
					})
		});
	}

	promiseTandemOptions = (inputValue, jumperOptions) => {
		return new Promise(resolve => {

			// console.log(inputValue);
			jumpersStore.GetTandemOptions(inputValue.toLowerCase())
				.then(options => {
					resolve(options);
				})
		});
	}

	handleInputChange = (newValue) => {
		this.inputValue = newValue;
		//this.valRef.focus();
	};

	onBlur = (mode, isCompleted) => {
		//if(this.state.editMode && !this.props.isCompleted)
		//{
		alert('bluring');
	}

	checkForNegatives = (price) => {
		//console.log('check for negatives: ' + price);
		if (price != undefined && Math.abs(price) >= 0.01) {
			let priceCell = (price <= -0.01) ?
				{
					value: `($${Math.abs(price).toFixed(2)})`
				} :
				{
					value: `$${price.toFixed(2)}`
				}
			if (price <= -0.01) {
				if (window.ENV?.showGreenNegative) {
					return <span style={{ color: 'lime' }}> {priceCell.value}</span>;
				}
				else {
					return <span style={{ color: 'salmon' }}> {priceCell.value}</span>;
				}

			}
			return priceCell.value;
		}
		else {
			return '$0.00';
		}
	}

	renderMemo = (memo) => {
		return <tr><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"memo: " + memo}</td></tr>
	}

	handleCreateTandemStudent = (value) => {
		var split = value.split(" ");
		var firstname = split[0];
		var lastname = split[1] || ' ';

		var payload = { firstname: firstname, lastname: lastname };
		//console.log(payload)
		jumpersStore.AddWalkinTandemStudent(payload)
			.then(student => {
				jumpersStore.getDailyTandemActivity(student._id);
				this.setState({ isModalOpen: true, isLoading: true });
			})
	}

	getLabel = (charge) => {
		if (charge.amount !== undefined && charge.isRefund) {
			return "REFUND";
		}
		else if (charge.amount !== undefined && (charge.transactionType === "Cash Payout" || charge.transactionType === "Check Payout" || charge.transactionType.indexOf("Account-") > -1)) {
			return "PAYOUT"
		}
		else if (charge.amount !== undefined) {
			return "CHARGE"
		}
	}

	getDiscountValue = () => {
		let globalDiscount = 0;
		let discountValue = 0;

		if (this.state.timeslots && this.state.dailyActivity && this.state.dailyActivity.studentData) {
			this.state.timeslots.forEach(timeItem => {
				if (timeItem.reservations) {
					timeItem.reservations.forEach(reservation => {
						if (reservation.discountValue && reservation.tandemStudents) {
							globalDiscount = reservation.discountValue;
							reservation.tandemStudents.forEach(stud => {
								if (new Date(stud.reservationTime).getTime() == new Date(this.state.dailyActivity.studentData.reservationTime).getTime() && stud._id.toString() == this.state.dailyActivity.studentId) {
									const groupMemebers = reservation.tandemStudents.reduce((acc, value) => {
										if (new Date(stud.reservationTime).getTime() == new Date(this.state.dailyActivity.studentData.reservationTime).getTime() && value.groupId === this.state.dailyActivity.studentData.studentGroupId) {
											acc = acc + 1;
										}
										return acc;
									}, 0)
									if (groupMemebers > 1) {
										discountValue = globalDiscount / groupMemebers;
									} else {
										discountValue = globalDiscount;
									}
								}
							})
						}
					})
				}
			})
		}
		return discountValue;
	}

	getTandemPackageOptions = () => {
		var tandemPackages = itemStore.getTandemPackages();
		// console.log(tandemPackages);
		return tandemPackages.map(pkg => pkg.item);
	}

	getVideoPackageOptions = () => {
		var videoPackages = itemStore.getVideoPackages();
		// console.log(videoPackages);
		return videoPackages.map(pkg => pkg.item);
	}

	getActiveLoadOptions = () => {
		// console.log(this.props.tandems);
		var loads = loadsStore.getActiveLoadsForDisplay();
		var schoolWaitlist = loadsStore.getSchoolWaitlist();
		// console.log(loads);
		var opts = [];
		if (schoolWaitlist) {
			opts.push("school waitlist")
		}
		opts.push(...loads.map(load => {
			var remainingSeats = load.capacity - load.totalJumpers;
			return "load " + load.loadNum + " - " + load.plane + " " + load.planeLoadNum + " - " + remainingSeats + " seats remaining";
		}))
		return opts;
	}

	getAffPriceOptions = () => {
		return ["regular", "prepaid", "student", "prepaid student"];
	}

	addObserver = (jumperId, option, tandemPackageName, videoPackageName) => {
		// console.log(option);
		var splitoptions = option.split(" ");
		var loadNum = splitoptions[1];

		// console.log(splitoptions);

		var payload = {
			observerId: jumperId
		}

		//alert("loadNum: " + loadNum);
		loadsStore.addObserver(loadNum, payload)
			.then(ret => {
				//alert("manifested on load " + loadNum);
			})
		//loadsStore.createTandemj
	}
	createJumpForAll = (option) => {
		// console.log(option);
		var loadNum;

		if (option === "school waitlist") {
			loadNum = 0;
		}
		else {
			var splitoptions = option.split(" ");
			loadNum = splitoptions[1];
		}

		var today = new Date();
		var promises = [];
		if (this.state.dailyActivity.tandemPackage.name.toLowerCase() === "ride along") {
			var payload = {
				observerId: this.state.dailyActivity.studentId
			}
			//alert("loadNum: " + loadNum);
			promises.push(loadsStore.addObserver(loadNum, payload));

		}
		else {

			var isWeekend = today.getDay() % 6 === 0;
			var tandemPackages = itemStore.getTandemPackages();
			var videoPackages = itemStore.getVideoPackages();

			var payload = {
				isWeekend: isWeekend,
				studentId: this.state.dailyActivity.studentId
			}

			var tandemPackage = tandemPackages.filter(pkg => pkg.item === this.state.dailyActivity.tandemPackage.name)[0];

			// console.log(tandemPackage);

			if (tandemPackage !== undefined) {
				payload.tandemPackageId = tandemPackage._id;
			}

			var videoPackage = videoPackages.filter(pkg => pkg.item === this.state.dailyActivity.videoPackage.name)[0];

			// console.log(videoPackage);

			if (videoPackage !== undefined) {
				payload.videoPackageId = videoPackage._id;
			}

			// console.log(payload);

			promises.push(loadsStore.createTandemJump(loadNum, payload))
		}
		// console.log('snoop others');

		this.state.otherActivities.forEach(activity => {

			// console.log(alert);
			// console.log(activity);
			if (activity.tandemPackage.name.toLowerCase() === "ride along") {
				var payload = {
					observerId: activity.studentId
				}
				//alert("loadNum: " + loadNum);
				promises.push(loadsStore.addObserver(loadNum, payload));

			}
			else {

				var isWeekend = today.getDay() % 6 === 0;
				var tandemPackages = itemStore.getTandemPackages();
				var videoPackages = itemStore.getVideoPackages();

				var payload = {
					isWeekend: isWeekend,
					studentId: activity.studentId
				}

				var tandemPackage = tandemPackages.filter(pkg => pkg.item === activity.tandemPackage.name)[0];

				// console.log(tandemPackage);

				if (tandemPackage !== undefined) {
					payload.tandemPackageId = tandemPackage._id;
				}

				var videoPackage = videoPackages.filter(pkg => pkg.item === activity.videoPackage.name)[0];

				// console.log(videoPackage);

				if (videoPackage !== undefined) {
					payload.videoPackageId = videoPackage._id;
				}

				// console.log(payload);

				//alert("loadNum: " + loadNum);
				promises.push(loadsStore.createTandemJump(loadNum, payload)); //async
			}
		})
		Promise.all(promises).then(all => {
			jumpersStore.getDailyTandemActivity(this.state.jumperId);
		})
	}
	deleteJump = (loadNum, jumpId, jumperId, type) => {

		if (type === 'tandem') {
			loadsStore.cancelTandemJump(loadNum, jumpId)
				.then(st => {
					jumpersStore.getDailyTandemActivity(this.state.jumperId);
				})
		}
		else if (type === "observer") {
			loadsStore.removeObserver(loadNum, jumperId)
				.then(st => {
					jumpersStore.getDailyTandemActivity(this.state.jumperId);
				})
		}
	}
	createJump = (jumperId, option, tandemPackageName, videoPackageName) => {
		// console.log(option);
		var loadNum;

		if (option === "school waitlist") {
			loadNum = 0;
		}
		else {
			var splitoptions = option.split(" ");
			loadNum = splitoptions[1];
		}
		// console.log(splitoptions);

		var today = new Date();

		if (tandemPackageName.toLowerCase() === "ride along") {
			var payload = {
				observerId: jumperId
			}
			//alert("loadNum: " + loadNum);
			loadsStore.addObserver(loadNum, payload)
				.then(ret => {
					jumpersStore.getDailyTandemActivity(this.state.jumperId);
				})
		}
		else {

			var isWeekend = today.getDay() % 6 === 0;
			var tandemPackages = itemStore.getTandemPackages();
			var videoPackages = itemStore.getVideoPackages();

			var payload = {
				isWeekend: isWeekend,
				studentId: jumperId
			}

			var tandemPackage = tandemPackages.filter(pkg => pkg.item === tandemPackageName)[0];

			// console.log(tandemPackage);

			if (tandemPackage !== undefined) {
				payload.tandemPackageId = tandemPackage._id;
			}

			var videoPackage = videoPackages.filter(pkg => pkg.item === videoPackageName)[0];

			// console.log(videoPackage);

			if (videoPackage !== undefined) {
				payload.videoPackageId = videoPackage._id;
			}

			// console.log(payload);

			//alert("loadNum: " + loadNum);
			loadsStore.createTandemJump(loadNum, payload)
				.then(ret => {
					jumpersStore.getDailyTandemActivity(this.state.jumperId);
				})
		}
		//loadsStore.createTandemj
	}

	selectAffPriceType = (id, priceType) => {
		var payload = {
			affPriceType: priceType
		}
		jumpersStore.setAffPriceType(id, payload);
	}

	selectPackage = (studentId, packageName, type, manifestStatus) => {

		var today = new Date();

		var isWeekend = today.getDay() % 6 === 0;

		var payload = {
			packageName: packageName,
			type: type,
			isWeekend: isWeekend
		}

		jumpersStore.setTandemStudentPackage(studentId, payload);
		// console.log(manifestStatus);
		if (manifestStatus !== undefined && manifestStatus.length > 0) {

			var tandemPackages = itemStore.getTandemPackages();

			var videoPackages = itemStore.getVideoPackages();

			var payload = {};
			if (type === "tandemPackage") {

				var tandemPackage = tandemPackages.filter(pkg => pkg.item === packageName)[0];

				// console.log(tandemPackage);

				if (tandemPackage !== undefined) {
					payload.tandemPackageId = tandemPackage._id;
				}
			}
			else if (type === "videoPackage") {
				var videoPackage = videoPackages.filter(pkg => pkg.item === packageName)[0];

				// console.log(videoPackage);

				if (videoPackage !== undefined) {
					payload.videoPackageId = videoPackage._id;
				}
				else if (packageName.toLowerCase() === "none") {
					payload.removeVideo = true;
				}

			}
			loadsStore.updateTandemJump(manifestStatus[0].load, manifestStatus[0].jumpId, payload)
		}

	}

	applyGiftCode = (studentId, giftCode, type) => {
		var payload = {
			giftCode: giftCode,
			type: type
		};

		jumpersStore.applyGiftCode(studentId, payload);
	}

	updateTandemInfo = async (studentId, firstname, lastname, phone, email, weight, type, dob, isPaperWaiver) => {

		var payload = {
			firstname: firstname,
			lastname: lastname,
			phone: phone,
			email: email,
			weight: weight,
			dob: dob,
			isPaperWaiver: isPaperWaiver
		};

		const response = await jumpersStore.UpdateWalkinTandemInfo(studentId, payload);
		if (response && this.state.event)
			await schedulesStore.fetchPassengers(this.state.event);
	}

	addPayBeforeItem = (studentId, amount, type, manifestStatus) => {
		var payload = {
			amount: amount,
			type: type
		};

		jumpersStore.addPayBeforeItem(studentId, payload);
		if (manifestStatus !== undefined && manifestStatus.length > 0) {



			var payload = {};
			payload.isOverweight = true;
			payload.overweightPrice = amount;
			loadsStore.updateTandemJump(manifestStatus[0].load, manifestStatus[0].jumpId, payload)
		}
	}

	handleLogClick = (jumpid) => {
		document.getElementById(jumpid).click();
	}

	renderAllItems(activity) {
		var keys = Object.keys(activity);
		keys = keys.filter(key => activity[key]?.count > 0);
		//keys = keys.filter(key => (activity[key].count > 0));

		var funjumpActivity = keys.map(key => {
			console.log(activity[key].value, "<<<<<activity[key].value")
			let jumpItem = {
				header: key,
				count: activity[key].count,
				value: activity[key].value,
				tax: activity[key]?.taxAmount || 0,
				totalPaid: activity[key].totalPaid,
				totalTaxPaid: activity[key].totalTaxPaid
			}
			return (
				<React.Fragment>
					<tr>
						{activity[key].value + activity[key].taxAmount - activity[key].totalPaid - activity[key].totalTaxPaid > 0.002 ? <td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleSelectedItems(jumpItem, activity)} /></td> : <td></td>}
						<td>{key?.toUpperCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
						<td>{activity[key].count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
						<td>{this.checkForNegatives(activity[key].value)}</td>
						{loadsStore.isTaxEnabled() && activity[key].value > 0 ?
							<React.Fragment>
								<td>{this.checkForNegatives(activity[key].taxAmount)}</td>
								<td>{this.checkForNegatives(activity[key].taxAmount + activity[key].value)}</td>
							</React.Fragment>
							: null
						}
					</tr>
				</React.Fragment>
			)
		});

		return funjumpActivity;
	}

	renderFunJumps(activity) {
		var keys = Object.keys(activity);
		keys = keys.filter(key => (key.toLowerCase().includes("fun jumps") || key.toLowerCase().includes("hop n pop")));
		//keys = keys.filter(key => (activity[key].count > 0));

		var funjumpActivity = keys.map(key => {
			console.log(activity[key].value, "<<<<<activity[key].value")
			let jumpItem = {
				header: key,
				count: activity[key].count,
				value: activity[key].value,
				tax: activity[key]?.taxAmount || 0,
				totalPaid: activity[key].totalPaid,
				totalTaxPaid: activity[key].totalTaxPaid
			}
			return (
				<React.Fragment>
					<tr>
						{activity[key].value + activity[key].taxAmount - activity[key].totalPaid - activity[key].totalTaxPaid > 0.002 ? <td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleSelectedItems(jumpItem, activity)} /></td> : <td></td>}
						<td>{key?.toUpperCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
						<td>{activity[key].count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
						<td>{this.checkForNegatives(activity[key].value)}</td>
						{loadsStore.isTaxEnabled() && activity[key].value > 0 ?
							<React.Fragment>
								<td>{this.checkForNegatives(activity[key].taxAmount)}</td>
								<td>{this.checkForNegatives(activity[key].taxAmount + activity[key].value)}</td>
							</React.Fragment>
							: null
						}
					</tr>
				</React.Fragment>
			)
		});

		return funjumpActivity;
	}

	renderAffJumps(activity) {

		var keys = Object.keys(activity);
		keys = keys.filter(key => (key.toLowerCase().includes("cat") || key.toLowerCase().includes("rw") || key.toLowerCase().includes("check") || key.toLowerCase().includes("solo") || key.toLowerCase().includes("coach") || key.toLowerCase().includes("static") || key.toLowerCase().includes("delay") || key.toLowerCase().includes("aff") || key.toLowerCase().includes("stp") || key.toLowerCase().includes("asp") || key.toLowerCase().includes("iad")) && activity[key] && activity[key].count > 0);
		keys = keys.filter(ke => ke !== "coachedJumps" && ke !== "solos" && ke !== "coachJumps" && ke !== "overweightPay" && !ke.includes("fun jumps"));
		//keys = keys.filter(key => (activity[key].count > 0));

		var affActivity = keys.map(key => {
			console.log(activity[key].value, "<<<<<activity[key].value")
			let affItem = {
				header: key,
				count: activity[key].count,
				value: activity[key].value,
				tax: activity[key]?.taxAmount || 0,
				totalPaid: activity[key].totalPaid,
				totalTaxPaid: activity[key].totalTaxPaid
			}
			return (
				<React.Fragment>
					<tr>
						{activity[key].value + activity[key].taxAmount - activity[key].totalPaid - activity[key].totalTaxPaid > 0.0001 ? <td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleSelectedItems(affItem, activity)} /></td> : <td></td>}
						<td>{key}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
						<td>{activity[key].count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
						<td>{this.checkForNegatives(activity[key].value)}</td>
						{loadsStore.isTaxEnabled() && activity[key].value > 0 ?
							<React.Fragment>
								<td>{this.checkForNegatives(activity[key].taxAmount)}</td>
								<td>{this.checkForNegatives(activity[key].taxAmount + activity[key].value)}</td>
							</React.Fragment>
							: null
						}
					</tr>
				</React.Fragment>
			)
		});

		return affActivity;
	}

	removeJoinTandems = (id) => {
		// console.log(id);
		var payload = { removeId: id };
		jumpersStore.removeJoinTandems(this.state.jumperId, payload);
	}

	toggleCheckinStatus = (activity) => {
		if (activity.isCheckedIn) {
			jumpersStore.Checkout(activity.jumperId);
		}
		else {
			jumpersStore.Checkin(activity.jumperId);
		}
	}

	toggleLOCheckinStatus = (activity) => {
		if (activity.isLOCheckedIn) {
			jumpersStore.LOCheckout(activity.jumperId);
		}
		else {
			jumpersStore.LOCheckin(activity.jumperId);
		}
	}

	toggleTrackedStatus = (activity) => {
		if (activity.tracked) {
			jumpersStore.stopTrackingJumper(activity.jumperId);
		}
		else {
			jumpersStore.trackJumper(activity.jumperId);
		}
	}

	toggleTeamCheckinStatus = (activity) => {
		if (activity.isTeamCheckedIn) {
			jumpersStore.teamCheckout(activity.jumperId);
		}
		else {
			jumpersStore.teamCheckin(activity.jumperId);
		}
	}

	toggleOVCheckinStatus = (activity) => {
		if (activity.isOVCheckedIn) {
			jumpersStore.OVCheckout(activity.jumperId);
		}
		else {
			jumpersStore.OVCheckin(activity.jumperId);
		}
	}
	printWaiverOneSheet = (waiverId, name) => {
		jumpersStore.printWaiverOneSheet(waiverId)
			.then(data => {
				FileSaver.saveAs(data, `${name}-waiver-data.pdf`);
			})
	}


	handleSelectedItems = (item, activity) => {
		let index;
		let data = [...this.state.selectedItems];
		console.log(data);
		//debugger
		if (!item?._id) {
			index = data?.findIndex(i => i?.item === item?.header)
			if (index === -1) {
				let selectedItem = {};
				selectedItem.id = activity?.isTandemStudent ? activity?.studentId : activity?.jumperId
				selectedItem.name = activity.firstname ? (activity.firstname + ' ' + activity.lastname) : activity.name
				selectedItem.item = item.header;
				selectedItem.count = item.count;
				selectedItem.price = item.value;
				selectedItem.totalPaid = item.totalPaid;
				selectedItem.totalTaxPaid = item.totalTaxPaid;
				selectedItem._id = item._id;
				selectedItem.taxAmount = item?.tax || item?.taxAmount || 0;
				data.push(selectedItem);
			} else {
				data.splice(index, 1)
			}
			this.setState({ selectedItems: data })
		}
		else {
			index = data?.findIndex(i => i?._id === item?._id)
			if (index === -1) {
				item.id = activity?.isTandemStudent ? activity?.studentId : activity?.jumperId
				item._id = item._id;

				item.name = activity.firstname ? (activity.firstname + ' ' + activity.lastname) : activity.name
				data.push(item)
			} else {
				data.splice(index, 1)
			}
			this.setState({ selectedItems: data })
		}
	}





	clearSelectedItems = () => {
		if (this.state.selectedItems.length > 0) {
			this.setState({ selectedItems: [] })
		}
	}

	clearSelectedDues = () => {
		this.setState({ selectedDues: {} })
	}

	clearSelectedBalance = () => {
		if (this.state.selectedBalance.length > 0) {
			this.setState({ selectedBalance: [] })
		}
	}

	handleChange = (item, type) => {
		let dues = { ...this.state.selectedDues }
		let tandemdues = [...this.state.selectedDues.tandem || []]
		let videoDue = [...this.state.selectedDues.video || []]
		let overweightDue = [...this.state.selectedDues.overweight || []]
		if (type === 'tandem') {
			let index = tandemdues.findIndex(i => i.id === item.studentId)
			if (index === -1) {
				tandemdues.push({ id: item.studentId, name: item.firstname + ' ' + item.lastname, price: item.tandemPackage.price - item.totalTandemPaid, taxAmount: item.tandemPackage.taxAmount - item.totalTandemTaxPaid })
			} else {
				tandemdues.splice(index, 1)
			}
		} else if (type === 'video') {
			let index = videoDue.findIndex(i => i.id === item.studentId)

			if (index === -1) {
				videoDue.push({ id: item.studentId, name: item.firstname + ' ' + item.lastname, price: item.videoPackage.price - item.totalVideoPaid, taxAmount: item.videoPackage.taxAmount - item.totalVideoTaxPaid })
			} else {
				videoDue.splice(index, 1)
			}
		} else if (type === 'overweight') {
			let index = overweightDue.findIndex(i => i.id === item.studentId)

			if (index === -1) {
				overweightDue.push({ id: item.studentId, name: item.firstname + ' ' + item.lastname, price: item.payBeforeOverweight - item.totalOverweightPaid, taxAmount: 0 })
			} else {
				overweightDue.splice(index, 1)
			}
		}
		dues.tandem = tandemdues
		dues.video = videoDue
		dues.overweight = overweightDue
		this.setState({ selectedDues: dues })
	}

	handleSelectedBalance = (item, type) => {
		let balance = [...this.state.selectedBalance]
		if (item.studentId || item.jumperId) {
			let index = balance.findIndex(i => i.id === item.studentId || i.id === item.jumperId)
			if (index === -1) {
				balance.push({
					id: item?.isTandemStudent ? item?.studentId : item?.jumperId,
					name: item.firstname ? (item.firstname + ' ' + item.lastname) : item.name,
					price: item.currentBalance,
					taxAmount: item.currentTaxBalance,
					previousPaid: item.totalPreviousPaid,
					previousTaxPaid: item.totalPreviousTaxPaid
				})
			} else {
				balance.splice(index, 1)
			}
			this.setState({ selectedBalance: balance })
		}
	}

	renderSummary(activity, addButton) {
		var postDatedActivity = null;
		if (activity?.postDatedActivities) {
			postDatedActivity = activity?.postDatedActivities?.map(activity => {
				return (<div>{activity}</div>)
			})
		}
		var counterSalesPrice = 0;
		var countersales = null;

		if (activity.countersales) {
			countersales = activity?.countersales.map(sale => {
				counterSalesPrice = counterSalesPrice + sale.price;
				if (sale.item != undefined) {
					return (
						<React.Fragment>
							<tr>
								<td>{sale.price + sale.taxAmount - sale.totalPaid - sale.totalTaxPaid > 0.0001 ? <input className="selectedItems" type="checkbox" onChange={(e) => this.handleSelectedItems(sale, activity)} /> : null}</td>
								<td>{sale.item !== undefined ? sale.item.toUpperCase() : 'Item'}   </td> <td> {sale.count}</td> <td> {this.checkForNegatives(sale.price)}</td>
								{loadsStore.isTaxEnabled() ?
									<React.Fragment>
										<td>{this.checkForNegatives(sale.taxAmount)}</td>
										<td>{this.checkForNegatives(sale.price + sale.taxAmount)}</td>
									</React.Fragment> : null
								}
								{(sale.item !== "transfer" && !sale.item.includes("returned") && sale.item !== "block removed" && !sale.item?.includes("ticket transfer")) ? <td><button className="btn lightBlueButton" onClick={() => {
									{ activity.isTandemStudent ? accountStore.DeleteItemForTandemStudent(activity.studentId, sale._id) : accountStore.DeleteItem(activity.jumperId, sale._id) };

									// { this.handleSelectedItems(sale, activity) }
								}}>


									<i className="fa fa-times" />


								</button>
								</td> : null}
								{sale.isPrintable && window.ENV?.arizona ? <td><button className="btn lightBlueButton" onClick={() => { accountStore.printTickets(sale.item, sale.price / sale.count, sale.count, activity.name, "manual") }}><i className="fa fa-print" /></button></td> : null}


							</tr>
							{sale.description !== undefined ? <tr><td></td><td>{sale.description}</td></tr> : ''}
							{sale.memo !== undefined && sale.memo !== "" ? this.renderMemo(sale.memo) : null}
						</React.Fragment >
					)
				}
			});
		}

		var charges = null;
		if (activity.charges) {
			charges = activity.charges.map(charge => {
				if (charge.amount !== undefined) {
					return (
						<React.Fragment>
							<tr><td></td>
								<td>{this.getLabel(charge)}</td>
								<td></td>
								<td>{this.checkForNegatives(-charge.amount)}</td>
								{loadsStore.isTaxEnabled() ?
									<React.Fragment>
										<td>{this.checkForNegatives(-charge.taxAmount)}</td>
										<td>{this.checkForNegatives(-charge.amount - charge.taxAmount)}</td>
									</React.Fragment> : null
								}
								{
									!charge.isRefund ?
										<td>
											<RefundConfirMation jumperId={activity.isTandemStudent ? activity.studentId : activity.jumperId} chargeValue={charge} isTandemStudent={this.state.isTandemStudent} />
										</td>
										: null
								}
								{window.ENV?.arizona ? <td><button className="btn lightBlueButton" onClick={() => { accountStore.printReceipt(charge) }}><i className="fa fa-print" /></button></td> : null}

							</tr>
							{charge.transactionType === 'Cash' ?
								<>
									<tr>
										<td></td>
										<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{charge.transactionType.toUpperCase()}</td><td></td>
										<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cash In</td>
										<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.checkForNegatives(charge.cashin)}</td><td></td>
										<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cash Out</td>
										<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.checkForNegatives(charge.cashout)}</td>
									</tr>
									{/* <tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cash Remaining</td><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.checkForNegatives(charge.amount + charge.tax - charge.cashin + charge.cashout)}</td></tr> */}
								</>
								:
								<tr>
									<td></td>
									<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{charge.transactionType.toUpperCase()}</td><td></td>
								</tr>
							}
							{charge.maskedCardNum ? <tr><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{charge.maskedCardNum ? charge.maskedCardNum : null} {charge.cardholderName ? charge.cardholderName : null}</td></tr> : null}
							{charge.memo ? this.renderMemo(charge.memo) : null}
						</React.Fragment>
					)
				}
			});
		}

		var payouts = null;
		if (activity.payouts) {
			payouts = activity.payouts.map(charge => {

				if (charge.amount !== undefined) {
					return (
						<React.Fragment>
							<tr><td></td><td>{this.getLabel(charge)}</td>
								<td></td>
								<td>{this.checkForNegatives(-charge.amount)}</td>
								{
									!charge.isRefund ?
										<td>
											{/* <button className="btn lightBlueButton" onClick ={() => this.refundJumper(charge)}>
										<i className="fa fa-undo" />
									</button> */}
											<RefundConfirMation jumperId={this.state.jumperId} chargeValue={charge} isTandemStudent={this.state.isTandemStudent} />
										</td>
										: null
								}</tr>
							<tr><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{charge.transactionType.toUpperCase()}</td></tr>
							{charge.maskedCardNum ? <tr><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{charge.maskedCardNum ? charge.maskedCardNum : null} {charge.cardholderName ? charge.cardholderName : null}</td></tr> : null}
							{charge.memo ? this.renderMemo(charge.memo) : null}
						</React.Fragment>
					)
				}
			});
		}
		const discountValue = this.getDiscountValue();
		// console.log(activity);
		var manifestStati = null
		var jumpedStati = null;

		if (activity.manifestStatus) {
			manifestStati = activity.manifestStatus.map(stat => {
				if (stat.load > 0) {
					return (<React.Fragment><Badge color="success" style={{ scaleX: 1.7, scaleY: 1.7 }} >manifested on load {stat.load} - {stat.planeLoad} {stat.blockName ? "using " + stat.blockName : "at $" + stat.slotPrice?.toFixed(2)} </Badge><i className="fa fa-close" onClick={() => { this.deleteJump(stat.load, stat.jumpId, activity.studentId, stat.type) }} /></React.Fragment>)
				}
				else if (stat.load === 0) {
					return (<React.Fragment><Badge color="success" style={{ scaleX: 1.7, scaleY: 1.7 }} >manifested on school waitlist</Badge><i className="fa fa-close" onClick={() => { this.deleteJump(stat.load, stat.jumpId, activity.studentId, stat.type) }} /></React.Fragment>)

				}
			});
		}
		if (activity.jumpedStatus && activity.jumpedStatus.length > 0) {
			jumpedStati = [activity.jumpedStatus[activity.jumpedStatus.length - 1]].map(stat => {
				return (<Badge color="success" style={{ scaleX: 1.7, scaleY: 1.7 }} >last jumped on load {stat.load} - {stat.planeLoad}</Badge>)
			});
		}

		var alerts, waiverFlags;

		if (activity.alerts !== undefined) {
			alerts = activity.alerts.filter(alert => alert.type !== "money").map(alert => {
				return (<h5><Badge color="warning">{alert.message}</Badge></h5>);
			})
		}

		if (activity.waiverFlags !== undefined) {
			waiverFlags = activity.waiverFlags?.map(flag => {
				return (<h5><Badge color="warning">{flag.type + " ans: " + flag.value}</Badge></h5>);
			})
		}

		var blocks;

		if (activity.blocks !== undefined) {
			blocks = activity.blocks.map((block, indx) => {
				var dateType = "Purchase Date : ";
				var dt = new Date(block.purchaseDate).toLocaleDateString();
				if (block.transferDate) {
					dateType = "Transfer Date : ";
					dt = new Date(block.transferDate).toLocaleDateString();
				}
				else if (block.upgradeDate) {
					dateType = "Upgrade Date : ";
					dt = new Date(block.upgradeDate).toLocaleDateString();
				}
				return (
					<Row><Col>Block name: {block.blockName}&nbsp;&nbsp;&nbsp; <UpgradeBlockTickets saleId={block.saleId} jumperId={this.state.jumperId} /></Col><Col>Unit Price: ${block.unitPrice}</Col>{!isNaN(block.ticketsRemaining) ? <Col>Tickets Remaining : {block.ticketsRemaining}/{block.originalNumTickets}&nbsp;&nbsp;<TransferBlockTickets saleId={block.saleId} unitPrice={block.unitPrice} ticketsRemaining={block.ticketsRemaining} expiry={block.expiry} blockIndx={indx} blockName={block.blockName} plane={block.plane} jumperId={this.state.jumperId} isTandemStudent={this.state.isTandemStudent} tandemInstructorId={this.state.dailyActivity.tandemInstructorId ? this.state.dailyActivity.tandemInstructorId : ''} />
					</Col> : null}
						{!isNaN(block.affTicketsRemaining) && block.affTicketsRemaining >= 0 ? <Col>AFF Tickets Remaining : {block.affTicketsRemaining}/{block.originalNumTickets}</Col> : null}<Col>{dateType + dt}</Col><Col>expiry: {new Date(block.expiry).toLocaleDateString()}&nbsp;&nbsp;&nbsp;
							<ReturnBlockTickets ticketsRemaining={block.ticketsRemaining} expiry={block.expiry} saleId={block.saleId} blockIndx={indx} blockName={block.blockName} plane={block.plane} jumperId={this.state.jumperId} isTandemStudent={this.state.isTandemStudent} tandemInstructorId={this.state.dailyActivity.tandemInstructorId ? this.state.dailyActivity.tandemInstructorId : ''} />&nbsp;&nbsp;&nbsp;
							<RemoveBlockTickets saleId={block.saleId} jumperId={this.state.jumperId} />
						</Col></Row>)
			})
		}

		return (
			<div className="">
				<div>{activity.studentId !== undefined && activity.studentId !== this.state.jumperId ? "_________________________________________________________________________________________________" : null}</div>
				{/* <h4 style={{ marginLeft: '9px' }}>{activity.name}  {this.state.isTandemStudent? <EditableOptions menuOptions={this.getTandemPackageOptions()} callback={this.selectPackage} tandemStudentId={activity.studentId} text="select tandem package" type="tandemPackage" />:null} {this.state.isTandemStudent? <EditableOptions menuOptions={this.getVideoPackageOptions()} callback={this.selectPackage} tandemStudentId={activity.studentId} text="select video package" type="videoPackage" />:null}  {this.state.isTandemStudent? <AddModal callback={this.addPayBeforeItem} tandemStudentId={activity.studentId} text="+ overweight" type="overweight"/>:null} {this.state.isTandemStudent? <AddModal callback={this.addPayBeforeItem} tandemStudentId={activity.studentId} text="+ tip" type="tip"/>:null} </h4> */}
				<div style={{ display: 'flex' }}>
					<div style={{ marginLeft: '9px', width: '15%', float: 'left', fontSize: '20px', fontWeight: '700' }}><h3>{activity.name}</h3> {activity.studentId !== undefined && activity.studentId !== this.state.jumperId && activity.jumperId !== "" ? <button style={{ color: 'white' }} onClick={(e) => { e.preventDefault(); this.removeJoinTandems(activity.studentId); }} className="close" >&times;</button> : null} </div>
					<div className="clearfix" style={{ marginLeft: '9px', fontSize: '16px' }}>
						{!this.state.isTandemStudent ? <button className="btn btn-secondary btn-sm" onClick={() => this.toggleCheckinStatus(activity)} style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }}>{activity.isCheckedIn ? "Check Out" : "Check In"}</button> : null}
						{!this.state.isTandemStudent ? <button className="btn btn-secondary btn-sm" onClick={() => this.toggleTrackedStatus(activity)} style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }}>{activity.tracked ? "stop tracking" : "track"}</button> : null}
						{!this.state.isTandemStudent && activity.isLO ? <button className="btn btn-secondary btn-sm" onClick={() => this.toggleLOCheckinStatus(activity)} style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }}>{activity.isLOCheckedIn ? "LO Check Out" : "LO Check In"}</button> : null}
						{!this.state.isTandemStudent && activity.isTeam ? <button className="btn btn-secondary btn-sm" onClick={() => this.toggleTeamCheckinStatus(activity)} style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }}>{activity.isTeamCheckedIn ? "Team Check Out" : "Team Check In"}</button> : null}
						{loadsStore.getOvSeparateCheckinSetting() && !this.state.isTandemStudent && activity.isVideographer ? <button className="btn btn-secondary btn-sm" onClick={() => this.toggleOVCheckinStatus(activity)} style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }}>{activity.isOVCheckedIn ? "OV Check Out" : "OV Check In"}</button> : null}
						{this.state.isTandemStudent ?
							<EditableOptions
								menuOptions={this.getTandemPackageOptions()}
								callback={this.selectPackage}
								studentId={activity.studentId}
								activity={activity} text="select tandem package" type="tandemPackage" />
							: null}
						{this.state.isTandemStudent ?
							<EditableOptions menuOptions={this.getVideoPackageOptions()}
								callback={this.selectPackage}
								studentId={activity.studentId}
								activity={activity} text="select video package" type="videoPackage" />
							: null
						}
						{this.state.isTandemStudent ?
							<AddModal callback={this.addPayBeforeItem}
								tandemStudentId={activity.studentId} activity={activity} text="+ overweight" type="overweight" />
							: null
						}
						{/*this.state.isTandemStudent ? 
							<AddModal callback={this.addPayBeforeItem} 
							tandemStudentId={activity.studentId} 
							text="+ tip" type="tip" /> 
							: null
						*/}
						{this.state.isTandemStudent ?
							<AddModal callback={this.applyGiftCode}
								tandemStudentId={activity.studentId}
								text="use giftcard" type="giftcard" />
							: null
						}
						{this.state.isTandemStudent ?
							<AddModal callback={this.updateTandemInfo}
								data={{
									firstname: activity.firstname,
									lastname: activity.lastname,
									email: activity.email,
									phone: activity.phone,
									weight: activity.weight,
									dob: activity.dob,
									isPaperWaiver: activity.isPaperWaiver
								}}
								tandemStudentId={activity.studentId}
								text="update info" type="info" />
							: null
						}
						{(this.state.isTandemStudent && activity.firstname === "Hold") && <JoinTandems jumperId={activity.studentId} mode="merge" tinyButton={true} />}
						{this.state.isTandemStudent ?
							<EditableOptions
								menuOptions={this.getActiveLoadOptions()}
								callback={this.createJump}
								tandemPackageName={activity.tandemPackage.name}
								videoPackageName={activity.videoPackage.name}
								studentId={activity.studentId}
								text="+ manifest" type="manifest" />
							: null
						}
						{!(activity.studentId !== undefined && activity.studentId !== this.state.jumperId) && activity.jumperId !== "" && this.state.otherActivities?.length > 0 && this.state.isTandemStudent ?
							<EditableOptions menuOptions={this.getActiveLoadOptions()} callback={this.createJumpForAll} tandemPackageName={activity.tandemPackage.name} videoPackageName={activity.videoPackage.name} studentId={activity.studentId} text="+ manifest all" type="manifestAll" /> : null}
						{addButton && <>
							<ChargeJumper
								selectedItems={this.state.selectedItems}
								selectedDues={this.state.selectedDues}
								clearItems={() => this.clearSelectedItems()}
								clearBalance={() => this.clearSelectedBalance()}
								clearDues={() => this.clearSelectedDues()}
								selectedBalance={this.state.selectedBalance}
								name={activity.lastname}
								jumperId={activity.studentId}
								dizioAchOnFile={activity?.dizioAchOnFile}
								token={activity?.cardsOnFile}
								accountOnFile={activity?.accountOnFile}
								taxValue={
									activity.totalTaxDue > 0
										?
										Number(activity.totalTaxDue).toFixed(2)
										:
										Number(activity.totalTaxBalance).toFixed(2)}
								value={
									activity.totalDue > 0 ?
										Number(activity.totalDue).toFixed(2) :
										Number(activity.totalBalance).toFixed(2)}
								isTandemStudent={activity.isTandemStudent}
								tandemStudentButton={true}
								activityObj={activity}
							/>
							<AddItem
								jumperId={activity.studentId}
								isTandemStudent={activity.isTandemStudent}
								accountOnFile={activity?.accountOnFile}
								token={activity.cardOnFile}
								dizioAchOnFile={activity?.dizioAchOnFile}
								name={activity.lastname}
								tandemStudentButton={true}
							/>
							<TransferCredit
								jumperId={activity.studentId}
								isTandemStudent={activity.isTandemStudent}
								tandemInstructorId={activity.tandemInstructorId ? activity.tandemInstructorId : ''}
								tandemStudentButton={true}
							/>
							<JumperDetail
								jumperId={activity.studentId}
								isTandem={activity.isTandemStudent}
								tandemStudentButton={true}
								activity={activity}
							/>
						</>}
						<h5>{jumpedStati}{manifestStati} {activity.lastJumpDate !== undefined && activity.lastJumpDate !== null ?
							<Badge color="info">last Jump: {(new Date(activity.lastJumpDate)).toLocaleDateString()}</Badge> : null}
							<Badge color="info">Weight: {activity.weight}</Badge> <Badge color="info"> phone: {activity.phone}</Badge>
							<Badge color="info">email: {activity.email}</Badge></h5>
						{activity.dob && <Badge color="info">dob: {activity.dob}</Badge>}
						{activity.isPaperWaiver === true && <Badge color="info">paper waiver: true</Badge>}
					</div>

				</div>
				<div>
					{alerts}
					{waiverFlags}
				</div>
				<div>
					{activity.isCheckedIn || activity.isOVCheckedIn ? <React.Fragment><h5>{activity.isCheckedIn ? <Badge color="info">Checked In</Badge> : null}{activity.isOVCheckedIn ? <Badge color="info">OV Checked In</Badge> : null}</h5><br /></React.Fragment> : null}
				</div>
				<div>
					{activity.isLOCheckedIn ? <React.Fragment><h5>{activity.isLOCheckedIn ? <Badge color="info">LO Checked In</Badge> : null}</h5><br /></React.Fragment> : null}
				</div>
				<div>
					{activity.isTeamCheckedIn ? <React.Fragment><h5>{activity.isTeamCheckedIn ? <Badge color="info">Team Checked In</Badge> : null}</h5><br /></React.Fragment> : null}
				</div>

				{activity.reservationType === "groupon" ? <h5><Badge color="info">Groupon</Badge></h5> : null}

				{activity.giftCode ?
					<React.Fragment>
						<h5><Badge color="info">Gift card: {activity.giftCode}</Badge></h5><br />
					</React.Fragment>
					: null
				}
				{blocks}
				<Row>
					{
						activity.tandemPackage !== undefined && activity.tandemPackage.name !== "" && activity.tandemPackage.name !== "none" ?
							<Col md={4}>
								<tr>
									<td></td>
									<td>Tandem Package: </td>
									<td>{activity.tandemPackage.name}</td>
									<td><EditablePrice price={activity.tandemPackage.price} type="tandemPackage" label="V" jumperId={activity.studentId} manifestStatus={activity.manifestStatus} /></td>
									{loadsStore.isTaxEnabled() ?
										<React.Fragment>
											<td>{this.checkForNegatives(activity.tandemPackage.taxAmount)}</td>
											<td>{this.checkForNegatives(activity.tandemPackage.price + activity.tandemPackage.taxAmount)}</td>
										</React.Fragment> : null
									}
								</tr>
								<tr>
									<td></td>
									<td>Tandem Paid: </td>
									<td></td>
									<td><span>{this.checkForNegatives(-activity.totalTandemPaid)}</span></td>
									{loadsStore.isTaxEnabled() ?
										<React.Fragment>
											<td>{this.checkForNegatives(-activity.totalTandemTaxPaid)}</td>
											<td>{this.checkForNegatives(-activity.totalTandemPaid - activity.totalTandemTaxPaid)}</td>
										</React.Fragment> : null
									}
									<TransferInternalTandem fromCategory="tandem" tandemIds={this.state.tandemGroup?.tandemStudentActivities?.map(activity => activity.studentId)} defaultAmount={activity.totalTandemPaid} defaultTaxAmount={activity.totalTandemTaxPaid} jumperId={activity.studentId} isTandemStudent={activity.isTandemStudent} tandemInstructorId={activity.tandemInstructorId ? activity.tandemInstructorId : ''} />
									<AddItem
										jumperId={activity.studentId}
										isTandemStudent={activity.isTandemStudent}
										accountOnFile={activity?.accountOnFile}
										token={activity.cardOnFile}
										dizioAchOnFile={activity?.dizioAchOnFile}
										name={activity.lastname}
										tandemStudentButton={true}
										adjustTandemPaid={true}
									/>
								</tr>
								<tr>
									<td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleChange(activity, 'tandem')} /></td>
									<td>Tandem Due: </td>
									<td></td>
									<td>{this.checkForNegatives(activity.tandemPackage.price - activity.totalTandemPaid)}</td>
									{loadsStore.isTaxEnabled() ?
										<React.Fragment>
											<td>{this.checkForNegatives(activity.tandemPackage.taxAmount - activity.totalTandemTaxPaid)}</td>
											<td>{this.checkForNegatives(activity.tandemPackage.price + activity.tandemPackage.taxAmount - activity.totalTandemPaid - activity.totalTandemTaxPaid)}</td>
										</React.Fragment> : null
									}
								</tr>
							</Col>
							:
							null
					}
					{
						activity.videoPackage !== undefined && activity.videoPackage.name !== "" && activity.videoPackage.name !== "none" && activity.tandemPackage.name !== "none" ?
							<Col>
								<tr>
									<td></td>
									<td>Video Package: </td>
									<td>{activity.videoPackage.name}</td>
									<td><EditablePrice price={activity.videoPackage.price} type="videoPackage" label="V" jumperId={activity.studentId} manifestStatus={activity.manifestStatus} /></td>
									{loadsStore.isTaxEnabled() ?
										<React.Fragment>
											<td>{this.checkForNegatives(activity.videoPackage.taxAmount)}</td>
											<td>{this.checkForNegatives(activity.videoPackage.price + activity.videoPackage.taxAmount)}</td>
										</React.Fragment> : null
									}
								</tr>
								<tr>
									<td></td>
									<td>Video Paid: </td>
									<td></td>
									<td>{this.checkForNegatives(-activity.totalVideoPaid)}</td>
									{loadsStore.isTaxEnabled() ?
										<React.Fragment>
											<td>{this.checkForNegatives(-activity.totalVideoTaxPaid)}</td>
											<td>{this.checkForNegatives(-activity.totalVideoPaid - activity.totalVideoTaxPaid)}</td>
										</React.Fragment> : null
									}
									<TransferInternalTandem fromCategory="video" tandemIds={this.state.tandemGroup?.tandemStudentActivities?.map(activity => activity.studentId)} defaultAmount={activity.totalVideoPaid} defaultTaxAmount={activity.totalVideoTaxPaid} jumperId={activity.studentId} isTandemStudent={activity.isTandemStudent} tandemInstructorId={activity.tandemInstructorId ? activity.tandemInstructorId : ''} />
									<AddItem
										jumperId={activity.studentId}
										isTandemStudent={activity.isTandemStudent}
										accountOnFile={activity?.accountOnFile}
										token={activity.cardOnFile}
										dizioAchOnFile={activity?.dizioAchOnFile}
										name={activity.lastname}
										tandemStudentButton={true}
										adjustVideoPaid={true}
									/>
								</tr>
								<tr>
									<td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleChange(activity, 'video')} /></td>
									<td>Video Due: </td>
									<td></td>
									<td>{this.checkForNegatives(activity.videoPackage.price - activity.totalVideoPaid)}</td>
									{loadsStore.isTaxEnabled() ?
										<React.Fragment>
											<td>{this.checkForNegatives(activity.videoPackage.taxAmount - activity.totalVideoTaxPaid)}</td>
											<td>{this.checkForNegatives(activity.videoPackage.price + activity.videoPackage.taxAmount - activity.totalVideoPaid - activity.totalVideoTaxPaid)}</td>
										</React.Fragment> : null
									}
								</tr>
							</Col>
							:
							null
					}
					{
						activity.payBeforeOverweight !== undefined && activity.payBeforeOverweight > 0 ?
							<Col>
								<tr>
									<td></td>
									<td>Overweight: </td>
									<td></td>
									<td><EditablePrice type="paybeforeOV" price={activity.payBeforeOverweight} jumperId={activity.studentId} manifestStatus={activity.manifestStatus} /></td>
								</tr>
								<tr>
									<td></td>
									<td>Overweight Paid: </td>
									<td></td>
									<td>{this.checkForNegatives(-activity.totalOverweightPaid)}</td>
									<TransferInternalTandem fromCategory="overweight" tandemIds={this.state.tandemGroup?.tandemStudentActivities?.map(activity => activity.studentId)} defaultAmount={activity.totalOverweightPaid} defaultTaxAmount={activity.totalOverweightTaxPaid} jumperId={activity.studentId} isTandemStudent={activity.isTandemStudent} tandemInstructorId={activity.tandemInstructorId ? activity.tandemInstructorId : ''} />
								</tr>
								<tr>
									<td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleChange(activity, 'overweight')} /></td>
									<td>Overweight Due: </td>
									<td></td>
									<td>{this.checkForNegatives(activity.payBeforeOverweight - activity.totalOverweightPaid)}</td>
								</tr>
							</Col>
							:
							null
					}
				</Row>

				{
					activity.payBeforeTip !== undefined && activity.payBeforeTip > 0 ?
						<tr>
							<td>Tip: </td>
							<td></td>
							<td>{this.checkForNegatives(activity.payBeforeTip)}</td>
						</tr>
						:
						null
				}

				<div>--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</div>
				<table className="StaffSummary">
					<tbody>

						{activity.tandemStudentJumps > 0 ?
							<tr>
								<td></td>
								<td>TANDEMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.tandemStudentJumps}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.tandemValue)}</td>
								{loadsStore.isTaxEnabled() ?
									<React.Fragment>
										<td>{this.checkForNegatives(activity.tandemTaxValue)}</td>
										<td>{this.checkForNegatives(activity.tandemValue + activity.tandemTaxValue)}</td>
									</React.Fragment>
									: null
								}
							</tr>
							: null}
						{discountValue > 0 && activity.tandemStudentJumps > 0 ?
							<tr>
								<td></td>
								<td>DISCOUNT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />{this.state.dailyActivity.discountName}</td>
								<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>({this.checkForNegatives(discountValue)})</td>
							</tr>
							: null}
						{activity.tandemStudentJumps > 0 && activity.videos !== 0 ?
							<tr>
								<td></td>
								<td>OUTSIDE VIDEOS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.videos}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.videosValue)}</td>
								{loadsStore.isTaxEnabled() ?
									<React.Fragment>
										<td>{this.checkForNegatives(activity.videosTaxValue)}</td>
										<td>{this.checkForNegatives(activity.videosValue + activity.videosTaxValue)}</td>
									</React.Fragment>
									: null
								}
							</tr>
							: null}

						{activity.tandemStudentJumps > 0 && activity.wristcamVideos !== 0 ?
							<tr>
								<td></td>
								<td>WRISTCAM VIDEOS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.wristcamVideos}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.wristcamVideosValue)}</td>
								{loadsStore.isTaxEnabled() ?
									<React.Fragment>
										<td>{this.checkForNegatives(activity.wristcamVideosTaxValue)}</td>
										<td>{this.checkForNegatives(activity.wristcamVideosValue + activity.wristcamVideosTaxValue)}</td>
									</React.Fragment>
									: null
								}
							</tr>
							: null
						}
						{activity.tandemStudentJumps > 0 && activity.overweightPrice !== 0 ?
							<tr>
								<td></td>
								<td>OVERWEIGHT PRICE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.overweightCount}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.overweightPrice)}</td>
							</tr>
							: null}

						{activity.observerCount > 0 ?
							<tr>
								<td></td>
								<td>OBSERVER RIDES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.observerCount}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.observerValue)}</td>
							</tr>
							: null
						}
						{this.renderAllItems(activity)}


						{
							activity.withVideoJumps !== undefined && activity.withVideoJumps.count !== 0 ?
								<tr>
									{activity.withVideoJumps.value + activity.withVideoJumps.taxAmount - activity.withVideoJumps.totalPaid - activity.withVideoJumps.totalTaxPaid > 0.001 ? <td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleSelectedItems(activity.withVideoJumps, activity)} /></td> : <td></td>}
									<td>VIDEOS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
									<td>{activity.withVideoJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
									<td>{this.checkForNegatives(activity.withVideoJumps.value)}</td>
								</tr>
								: null
						}

						{countersales}
						{charges}
						{payouts}
						{/* {postDatedActivity} */}
						{this.state.isTandemStudent && this.state.isTandemGroup && this.state.tandemGroup?.tandemStudentActivities?.length > 1 ?
							<>
								<tr className="tandeGroupData tandemGroupHeading"><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Due: </td><td></td><td>{this.checkForNegatives(activity.totalDue)}</td>
									{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.totalTaxDue)}</td>}{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.totalDue + activity.totalTaxDue)}</td>}</tr>
								<tr className="tandeGroupData tandemGroupHeading">
									{activity.currentBalance + activity.currentTaxBalance - activity.totalPreviousPaid - activity.totalPreviousTaxPaid > 0.0001 ? <td><input className="selectedItems" type="checkbox" onChange={(e) => this.handleSelectedBalance(activity, 'previousBalance')} /></td> : <td></td>}
									<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Starting Balance:</td>
									<td></td><td>{this.checkForNegatives(activity.currentBalance)} </td>
									{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.currentTaxBalance)}</td>}{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.currentBalance + activity.currentTaxBalance)}</td>}
								</tr>
								<tr className="tandeGroupData tandemGroupHeading"><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Todays Balance:</td><td></td><td>{this.checkForNegatives(activity.todayBalance)} </td>
									{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.todayTaxBalance)}</td>}{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.todayBalance + activity.todayTaxBalance)}</td>}
								</tr>
								<tr className="tandeGroupData tandemGroupHeading"><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Today Paid:</td><td></td><td>{this.checkForNegatives(-activity.todayPaid)} </td>
									{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(-activity.todayTaxPaid)}</td>}{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(-activity.todayPaid - activity.todayTaxPaid)}</td>}
								</tr>
								<tr className="tandeGroupData tandemGroupHeading"><td></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Balance:</td><td></td><td>{this.checkForNegatives(activity.totalBalance)} </td>
									{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.totalTaxBalance)}</td>}{loadsStore.isTaxEnabled() && <td>{this.checkForNegatives(activity.totalBalance + activity.totalTaxBalance)}</td>}
								</tr>
							</> : null}


					</tbody>
				</table>

			</div>
		)
	}

	render() {
		const override = `
	        display: block;
	        margin: 0 auto;
	        border-color: red;
	      `;
		const discountValue = this.getDiscountValue();
		var activityObj = this.state.dailyActivity;
		if (this.state.isTandemGroup) {
			activityObj = this.state.tandemGroup;
		}

		var currentBalanceString = (!activityObj?.tandemStudentActivities) ? (activityObj.currentBalance >= 0 ? this.checkForNegatives((activityObj.currentBalance) + discountValue) : this.checkForNegatives((activityObj.currentBalance) + discountValue)) : activityObj.currentBalance >= 0 ? this.checkForNegatives(activityObj.currentBalance + discountValue) : this.checkForNegatives(activityObj.currentBalance + discountValue);;
		var todayBalanceString = activityObj.todayBalance >= 0 ? this.checkForNegatives(activityObj.todayBalance - discountValue) : this.checkForNegatives(activityObj.todayBalance - discountValue);
		var totalBalanceString = activityObj.totalBalance >= 0 ? this.checkForNegatives(activityObj.totalBalance) : this.checkForNegatives(activityObj.totalBalance);
		var totalPaidString = activityObj.todayPaid >= 0 ? this.checkForNegatives(-activityObj.todayPaid) : this.checkForNegatives(-activityObj.todayPaid);
		var totalPayoutString = activityObj.todayPayout >= 0 ? this.checkForNegatives(activityObj.todayPayout) : this.checkForNegatives(activityObj.todayPayout);
		var dueString = activityObj.totalDue >= 0 ? this.checkForNegatives(activityObj.totalDue) : this.checkForNegatives(activityObj.totalDue);
		var taxDueString, currentTaxBalanceString, todayTaxBalanceString, totalTaxBalanceString, todayTaxPaidString;
		var totalDueString, totalCurrentBalanceString, totalTodayBalanceString, totalTotalBalanceString, totalTodayPaidString;

		if (loadsStore.isTaxEnabled()) {
			taxDueString = activityObj.totalTaxDue >= 0 ? this.checkForNegatives(activityObj.totalTaxDue) : this.checkForNegatives(activityObj.totalTaxDue);
			currentTaxBalanceString = (!activityObj?.tandemStudentActivities) ? (activityObj.currentTaxBalance >= 0 ? this.checkForNegatives(Number(activityObj.currentTaxBalance)) : this.checkForNegatives(activityObj.currentTaxBalance)) : activityObj.currentTaxBalance >= 0 ? this.checkForNegatives(activityObj.currentTaxBalance) : this.checkForNegatives(activityObj.currentTaxBalance);
			todayTaxBalanceString = activityObj.todayTaxBalance >= 0 ? this.checkForNegatives(activityObj.todayTaxBalance) : this.checkForNegatives(activityObj.todayTaxBalance);
			totalTaxBalanceString = activityObj.totalTaxBalance >= 0 ? this.checkForNegatives(activityObj.totalTaxBalance) : this.checkForNegatives(activityObj.totalTaxBalance);
			todayTaxPaidString = activityObj.todayTaxPaid >= 0 ? this.checkForNegatives(-activityObj.todayTaxPaid) : this.checkForNegatives(-activityObj.todayTaxPaid);

			totalDueString = activityObj.totalTaxDue >= 0 ? this.checkForNegatives(activityObj.totalDue + activityObj.totalTaxDue) : this.checkForNegatives(activityObj.totalDue + activityObj.totalTaxDue);
			totalCurrentBalanceString = (!activityObj?.tandemStudentActivities) ? ((activityObj.currentBalance + Number((activityObj.currentTaxBalance)?.toFixed(2)) - activityObj.totalPreviousTaxPaid - activityObj.totalPreviousPaid) >= 0 ? this.checkForNegatives(activityObj.currentBalance + Number((activityObj.currentTaxBalance)?.toFixed(2))) : this.checkForNegatives(activityObj.currentBalance + Number((activityObj.currentTaxBalance)?.toFixed(2)))) : (activityObj.currentTaxBalance >= 0 ? this.checkForNegatives(activityObj.currentBalance + activityObj.currentTaxBalance) : this.checkForNegatives(activityObj.currentBalance + activityObj.currentTaxBalance));
			totalTodayBalanceString = activityObj.todayTaxBalance >= 0 ? this.checkForNegatives(activityObj.todayBalance + activityObj.todayTaxBalance) : this.checkForNegatives(activityObj.todayBalance + activityObj.todayTaxBalance);
			totalTotalBalanceString = activityObj.totalTaxBalance >= 0 ? this.checkForNegatives(activityObj.totalBalance + activityObj.totalTaxBalance) : this.checkForNegatives(activityObj.totalBalance + activityObj.totalTaxBalance);
			totalTodayPaidString = activityObj.todayTaxPaid >= 0 ? this.checkForNegatives(-activityObj.todayPaid - activityObj.todayTaxPaid) : this.checkForNegatives(-activityObj.todayPaid - activityObj.todayTaxPaid);

		}
		if (loadsStore.getSeparateEarnedBalanceSetting()) {
			var currentEarnedBalanceString = activityObj.currentEarnedBalance >= 0 ? this.checkForNegatives(activityObj.currentEarnedBalance) : this.checkForNegatives(activityObj.currentEarnedBalance);
			var todayEarnedBalanceString = activityObj.todayEarnedBalance >= 0 ? this.checkForNegatives(activityObj.todayEarnedBalance) : this.checkForNegatives(activityObj.todayEarnedBalance);
			var totalEarnedBalanceString = activityObj.totalEarnedBalance >= 0 ? this.checkForNegatives(activityObj.totalEarnedBalance) : this.checkForNegatives(activityObj.totalEarnedBalance);

		}

		if (this.props.mode !== "async") {
			// console.log(this.props.options);
		}

		var tandemActivitiesForRender;


		tandemActivitiesForRender = [this.state.dailyActivity];


		if (this.state.otherActivities !== undefined && this.state.isTandemGroup && this.state.tandemGroup?.tandemStudentActivities?.length > 1) {
			tandemActivitiesForRender = [...tandemActivitiesForRender, ...this.state.otherActivities];
		}

		let addButtons = (tandemActivitiesForRender.length > 1) ? true : false
		//console.log(JSON.stringify(tandemActivitiesForRender, null, 2));
		return (
			<div>
				<Modal id="modal-report" aria-labelledby="example-custom-modal-styling-title"
					isOpen={this.state.isModalOpen} style={{ zIndex: '10503434', right: '28%' }}>
					<ModalHeader style={{ color: 'white' }}>View Details <button className="close" onClick={(e) => { e.preventDefault(); this.closeModal(); }} style={{ color: 'white' }}>x</button></ModalHeader>
					<ModalBody style={{ color: 'white' }}>
						<div className='complete-loading'>
							<ClipLoader
								css={override}
								sizeUnit={"px"}
								size={35}
								color={'#fff'}
								loading={this.state.isLoading}
							/>
						</div>
						<Row className="form-group">
							<Col md={12}>
								{tandemActivitiesForRender?.map(activity => this.renderSummary(activity, addButtons))}
							</Col>
						</Row>

						<div className="ViewAccounts-totalSection-246" style={{ paddingTop: 10, paddingBottom: 10 }}>
							<table className="StaffSummary tandemGroupHeading" style={{ marginLeft: '6px', width: '97%' }}>
								<tbody>
									{loadsStore.isTaxEnabled() ?
										<React.Fragment>
											<td className="checkboxCol"></td>
											<td></td>
											<td>sub total</td>
											<td>tax</td>
											<td>total</td>
										</React.Fragment>
										: null
									}
									{this.state.isTandemStudent ?
										<tr>
											<td className="checkboxCol"></td>
											<td>TOTAL DUE </td>
											<td>{dueString !== "($ undefined)" ? dueString : "$0"}</td>
											{loadsStore.isTaxEnabled() ?
												<React.Fragment>
													<td>{taxDueString !== "($ undefined)" ? taxDueString : "$0"}</td>
													<td>{totalDueString !== "($ undefined)" ? totalDueString : "$0"}</td>
												</React.Fragment>
												: null
											}
										</tr>
										: null
									}
									{loadsStore.getSeparateEarnedBalanceSetting() && !this.state.isTandemStudent ?
										<React.Fragment>
											<tr>
												<td className="checkboxCol"></td>
												<td>STARTING EARNED BALANCE </td>
												<td>{currentEarnedBalanceString !== "($ undefined)" ? currentEarnedBalanceString : "$0"}</td>
											</tr>
											<tr>
												<td className="checkboxCol"></td>
												<td>TODAYS EARNING </td>
												<td>{todayEarnedBalanceString !== "($ undefined)" ? todayEarnedBalanceString : "$0"}</td>
											</tr>
											<tr>
												<td className="checkboxCol"></td>
												<td>TOTAL PAYOUT </td>
												<td>{totalPayoutString !== "($undefined)" ? totalPayoutString : "$0"}</td>
											</tr>
											<tr>
												<td className="checkboxCol"></td>
												<td>TOTAL EARNINGS BALANCE </td>
												<td>{totalEarnedBalanceString !== "($ undefined)" ? totalEarnedBalanceString : "$0"}</td>
											</tr>
										</React.Fragment>
										: null
									}
									<tr>
										{!activityObj?.tandemStudentActivities && activityObj?.currentBalance + activityObj?.currentTaxBalance - activityObj?.totalPreviousPaid - activityObj?.totalPreviousTaxPaid > 0.0001 ? <td className="checkboxCol"><input className="selectedItems" type="checkbox" onChange={(e) => this.handleSelectedBalance(activityObj, 'previousBalance')} /></td> : <td className="checkboxCol"></td>}
										<td>STARTING BALANCE</td>
										<td>{currentBalanceString !== "($ undefined)" ? currentBalanceString : "$0"}</td>
										{loadsStore.isTaxEnabled() ?
											<React.Fragment>
												<td>{currentTaxBalanceString !== "($ undefined)" ? currentTaxBalanceString : "$0"}</td>
												<td>{totalCurrentBalanceString !== "($ undefined)" ? totalCurrentBalanceString : "$0"}</td>
											</React.Fragment> : null
										}
									</tr>

									<tr>
										<td></td>
										<td>TODAYS BALANCE </td>
										<td> {todayBalanceString !== "($undefined)" ? todayBalanceString : "$0"}</td>
										{loadsStore.isTaxEnabled() ?
											<React.Fragment>
												<td>{todayTaxBalanceString !== "($ undefined)" ? todayTaxBalanceString : "$0"}</td>
												<td>{totalTodayBalanceString !== "($ undefined)" ? totalTodayBalanceString : "$0"}</td>
											</React.Fragment> : null
										}
									</tr>

									<tr>
										<td></td>
										<td>TOTAL PAID </td>
										<td>{totalPaidString !== "($undefined)" ? totalPaidString : "$0"}</td>
										{loadsStore.isTaxEnabled() ?
											<React.Fragment>
												<td>{todayTaxPaidString !== "($ undefined)" ? todayTaxPaidString : "$0"}</td>
												<td>{totalTodayPaidString !== "($ undefined)" ? totalTodayPaidString : "$0"}</td>
											</React.Fragment> : null
										}
									</tr>

									{!loadsStore.getSeparateEarnedBalanceSetting() || this.state.isTandemStudent ?
										<tr>
											<td></td>
											<td>TOTAL PAYOUT </td>
											<td>{totalPayoutString !== "($undefined)" ? totalPayoutString : "$0"}</td>
										</tr>
										: null
									}

									<tr>
										<td></td>
										<td>TOTAL BALANCE </td>
										<td>{totalBalanceString !== "($undefined)" ? totalBalanceString : "$0"}</td>

										{loadsStore.isTaxEnabled() ?
											<React.Fragment>
												<td>{totalTaxBalanceString !== "($ undefined)" ? totalTaxBalanceString : "$0"}</td>
												<td>{totalTotalBalanceString !== "($ undefined)" ? totalTotalBalanceString : "$0"}</td>
											</React.Fragment> : null
										}
									</tr>
								</tbody>
							</table>
						</div>

						<Row>
							<Col>
								<ViewDetail isTandem={this.state.isTandemStudent} jumperId={this.state.jumperId} />
								{
									this.state.isTandemStudent ?
										<JoinTandems jumperId={this.state.jumperId} mode="merge" /> : <MergeJumpers jumperId={this.state.jumperId} closeModal={this.closeModal} mode="merge" />
								}
							</Col>
							{
								this.state.isTandemStudent ?
									<Col>
										<JoinTandems jumperId={this.state.jumperId} mode="join" />
									</Col> : null
							}
							<Col>
								{
									this.state.jumperId !== '' &&
									<TransferCredit jumperId={this.state.jumperId} isTandemStudent={this.state.isTandemStudent} tandemInstructorId={this.state.dailyActivity.tandemInstructorId ? this.state.dailyActivity.tandemInstructorId : ''} />
								}
								{!this.state.isTandemStudent &&
									<JumperProfile jumperId={this.state.jumperId} currentJumper = {this.state.currentJumper} />
								}
							</Col>
							<Col>
								<WaiverDetail jumperId={this.state.jumperId} isTandemStudent={this.state.isTandemStudent} />
							</Col>

							<Col>
								<ChargeJumper
									selectedItems={this.state.selectedItems}
									selectedDues={this.state.selectedDues}
									selectedBalance={this.state.selectedBalance}
									clearItems={() => this.clearSelectedItems()}
									clearBalance={() => this.clearSelectedBalance()}
									name={this.state.dailyActivity.lastname}
									jumperId={this.state.jumperId}
									dizioAchOnFile={this.state.dizioAchOnFile}
									token={this.state.cardsOnFile}
									accountOnFile={this.state.accountOnFile}
									taxValue={
										activityObj.totalTaxDue > 0
											?
											Number(activityObj.totalTaxDue).toFixed(2)
											:
											Number(activityObj.totalTaxBalance).toFixed(2)}
									value={
										activityObj.totalDue > 0 ?
											Number(activityObj.totalDue).toFixed(2) :
											Number(activityObj.totalBalance).toFixed(2)}
									isTandemStudent={this.state.isTandemStudent}
									activityObj={activityObj}
								// selectedItems={this.state.selectedItems}
								// clearItems={() => this.clearSelectedItems()}
								// name={this.state.dailyActivity.name}
								// jumperId={this.state.jumperId}
								// dizioAchOnFile={this.state.dizioAchOnFile}
								// token={this.state.cardsOnFile}
								// accountOnFile={this.state.accountOnFile}
								// taxValue={
								// 	this.state.dailyActivity.totalTaxDue > 0
								// 		?
								// 		Number(this.state.dailyActivity.totalTaxDue).toFixed(2)
								// 		:
								// 		Number(this.state.dailyActivity.totalTaxBalance).toFixed(2)}
								// value={
								// 	this.state.dailyActivity.totalDue > 0 ?
								// 		Number(this.state.dailyActivity.totalDue).toFixed(2) :
								// 		Number(this.state.dailyActivity.totalBalance).toFixed(2)}
								// isTandemStudent={this.state.isTandemStudent} 
								/>
							</Col>

							<Col>
								<AddItem
									jumperId={this.state.jumperId}
									isTandemStudent={this.state.isTandemStudent}
									accountOnFile={this.state.accountOnFile}
									token={this.state.cardsOnFile}
									dizioAchOnFile={this.state.dizioAchOnFile}
									name={this.state.dailyActivity.lastname}
								/>
							</Col>

							{window.ENV?.separateTicketButton && !this.state.isTandemStudent && <Col>
								<AddItem
									jumperId={this.state.jumperId}
									isTandemStudent={this.state.isTandemStudent}
									name={this.state.dailyActivity.name}
									accountOnFile={this.state.accountOnFile}
									token={this.state.cardsOnFile}
									tickets={true} />
							</Col>}

							{this.state.dailyActivity.isStudent ?
								<Col>
									<button className="btn customButton rightButton" style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764' }} onClick={() => this.handleLogClick("submit-log" + "0")}>
										View/Submit Student Logs
									</button>
								</Col> : null
							}
						</Row>

						<Row className="form-group m-t-2-rem">
							<Col md={{ size: 9 }}>
								<button onClick={(e) => { e.preventDefault(); this.closeModal(); }} className="btn btn-link">CANCEL</button>
							</Col>
						</Row>
					</ModalBody>
				</Modal>

				<SubmitStudentLog studentId={this.state.jumperId} level={""} jumpId={"0"} instructor1Name={""} />

				<div className="search-fields">
					{
						this.props.mode === "async" ?
							<AsyncSelect
								loadOptions={(newValue) => this.promiseOptions(newValue, this.props.options)}
								onInputChange={(val) => this.handleInputChange(val)}
								onChange={(val) => this.handleSelect(val)}
								placeholder={this.props.placeholder}
								defaultOptions={this.props.options.slice(0, 10)}
								styles={customStyles}
							/>
							:
							<AsyncCreatableSelect
								ref={(value) => this.valRef = value}
								loadOptions={(newValue) => this.promiseTandemOptions(newValue, this.props.options)}
								onInputChange={(val) => this.handleInputChange(val)}
								isClearable
								onChange={(val) => this.handleTandemSelect(val)}
								onCreateOption={(value) => this.handleCreateTandemStudent(value)}
								defaultOptions={this.props.options.slice(0, 10)}
								placeholder={this.props.placeholder}
								styles={customStyles}
							/>
					}
				</div>
			</div>
		);
	}
}


export default SearchJumper