import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import HamburgerMenu from 'react-hamburger-menu';
import { StyleSheet } from '@react-pdf/renderer';

import '../css/Reports.css';

import logo from '../images/logo.svg';
import {
    JUMPER_LOGS,
    NUMBER_SLOTS,
    STAFF_SUMMARY,
    JUMPER_STATEMENT,
    JUMPER_STATE,
    INVOICE_BY_CATEGORY,
    LOAD_HISTORY,
    DETAIL_BY_CATEGORY,
    GIFT_CARD,
    TANDAM_STUDENT,
    SLOTS_BY_PLANE,
    TANDEM_STATE
} from '../constant/reports';
import DataTable from './report/datatable/DataTable';
import { data } from '../../testData/TestData';
import { openModal } from '../../actions/reports';
import reportStore from '../../stores/report-store';
import JumperStore from '../../stores/jumpers-store';
import LeftMenu from '../menu/LeftMenu1';
import background from '../images/background.svg';

import ErrorModal from '../sub-components/Modal/ErrorModal';

const theme = createMuiTheme({
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        text: '#03173f'
    },
    // typography: {
    //     useNextVariants: true,
    // },
});

const PStyles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});


class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTableProp: [],
            activeType: undefined,
            startDate: '',
            endDate: '',
            width: window.innerWidth,
            open: false,
            warning: false,
            giftCardData: [],
            tandemStudents: [],
        };

        this.reportEvents = [
            { name: reportStore.getEventPrefix('warningEvent'), func: this.handleWarning },
            { name: reportStore.getEventPrefix('giftcardEmailSuccess'), func: this.handleEmailSuccess },
            { name: reportStore.getEventPrefix('giftcard_refunded'), func: this.handleGiftcardRefund }
        ];
        this.jumperEvents = [
            { name: JumperStore.getEventPrefix('tandemStudentsLoaded'), func: this.tandemStudentsLoaded },
        ]

    }

    componentDidMount = () => {
        this.reportEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
        this.jumperEvents.forEach((evnt) => JumperStore.on(evnt.name, evnt.func));


        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount = async () => {
        this.reportEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
        this.jumperEvents.forEach((evnt) => JumperStore.removeListener(evnt.name, evnt.func));
    }

    tandemStudentsLoaded = (tandemStudents) => {
        this.setState({ tandemStudents, dataTableProp: tandemStudents })
    }
    handleGiftcardRefund = (payload) => {
        let { giftCardData } = this.state;
        let { status, giftCardIds, firstGiftCardOfGroup } = payload;
        let newArr = giftCardData.map(ele => {
            let index = giftCardIds.indexOf(ele._id);
            if (index !== -1 && payload.giftcardItemized[ele._id]) {
                const gift_card = payload.giftcardItemized[ele._id]
                ele.refundBookingfees = index === 0 ? (ele.refundBookingfees || 0) + Number(payload.refundBookingfees) : "";
                ele.refundAmount = gift_card.refundAmount;
                ele.refundTax = gift_card.refundTax;
                ele.refundedDate = new Date();
                ele.status = gift_card.status;
            }else if(index === 0 && ele._id === firstGiftCardOfGroup._id) {
                ele.refundBookingfees = firstGiftCardOfGroup.refundBookingfees + Number(payload.refundBookingfees);
            }
            return ele;
        })
        this.setState({ giftCardData: newArr })
    }
    handleEmailSuccess = ({ payload }) => {
        let { giftCardData } = this.state;
        let newArr = giftCardData.map(ele => {
            if (ele._id === payload.couponId) {
                ele.email = payload.RecipientEmail;
                ele.name = payload.name;
                ele.message = payload.message;
            }
            return ele;
        })
        this.setState({ giftCardData: newArr });
    }

    handleWarning = () => {
        this.setState({
            warning: true
        })

        setTimeout(() => {
            this.setState({
                warning: false
            })
        }, 2000)
    }

    dataChangeHandler = (dataToBeRendered) => {
        switch (dataToBeRendered) {
            case SLOTS_BY_PLANE:
                {
                    this.props.openModal(SLOTS_BY_PLANE);
                    this.setState({ activeType: SLOTS_BY_PLANE });
                    break;
                }
            case JUMPER_STATE:
                {
                    this.setState({ dataTableProp: data.jumper_logs, activeType: JUMPER_STATE });
                    break;
                }
            case TANDEM_STATE:
                {
                    this.setState({ activeType: TANDEM_STATE });
                    break;
                }
            case INVOICE_BY_CATEGORY:
                {
                    this.props.openModal(INVOICE_BY_CATEGORY);
                    //var data = JSON.stringify(reportStore.getInvoiceByCategory('2018-17-19T08:00:00.000Z','2018-12-21T23:59:59.999Z'))
                    this.setState({ dataTableProp: data.invoice_by_category, activeType: INVOICE_BY_CATEGORY }, () => {
                        console.log(this.state.dataTableProp, "<=============dataTableProp")
                    });
                    break;
                }
            case NUMBER_SLOTS:
                {
                    this.props.openModal(NUMBER_SLOTS);
                    this.setState({ dataTableProp: data.number_slots, activeType: NUMBER_SLOTS });
                    break;
                }
            case JUMPER_LOGS:
                {
                    this.props.openModal(JUMPER_LOGS);
                    this.setState({ dataTableProp: data.jumper_logs, activeType: JUMPER_LOGS });
                    break;
                }
            case STAFF_SUMMARY:
                {
                    this.props.openModal(STAFF_SUMMARY);
                    this.setState({ dataTableProp: data.staff_summary, activeType: STAFF_SUMMARY });
                    break;
                }
            case JUMPER_STATEMENT:
                {
                    this.props.openModal(JUMPER_STATEMENT);
                    this.setState({ dataTableProp: data.jumper_logs, activeType: JUMPER_STATEMENT });
                    break;
                }
            case LOAD_HISTORY:
                {
                    this.props.openModal(LOAD_HISTORY);
                    this.setState({ dataTableProp: data.load_history, activeType: LOAD_HISTORY });
                    break;
                }
            case DETAIL_BY_CATEGORY:
                {
                    this.props.openModal(DETAIL_BY_CATEGORY);
                    this.setState({ dataTableProp: data.load_history, activeType: DETAIL_BY_CATEGORY }, () => {
                        console.log(this.state.dataTableProp, "<=========dataTableProp")
                    });
                    break;
                }
            case GIFT_CARD:
                {
                    this.props.openModal(GIFT_CARD);
                    reportStore.getCoupons().then(res => {
                        //this.setState({ giftCardData: res.couponArray })
                        this.setState({ giftCardData: res.couponArray, dataTableProp: res.couponArray, activeType: GIFT_CARD });
                    })
                    break;
                }
            case TANDAM_STUDENT: {
                this.props.openModal(TANDAM_STUDENT);
                this.setState({ dataTableProp: this.state.tandemStudents, activeType: TANDAM_STUDENT })
            }

            default:
                return this.state;
        }
    }


    dateChangeHandler = (startDate, endDate) => {
        this.setState({ startDate, endDate })
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillMount = () => {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    handleClick() {
        this.setState({
            open: !this.state.open
        });
    }


    render() {

        const { width, warning } = this.state;
        const isMobile = width <= 500;

        return (
            <div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
                {
                    isMobile ?
                        <div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
                            <div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
                                <LeftMenu routeHistory={this.props.history} dataChangeHandler={this.dataChangeHandler} />
                            </div>
                        </div>
                        :
                        <div className="blueBg fullHeightPanes left-container">
                            <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
                            <LeftMenu routeHistory={this.props.history} showCreate={true} dataChangeHandler={this.dataChangeHandler} />
                        </div>
                }

                <div className="middle-container-no-rightmenu">
                    {
                        isMobile ?
                            <div className="row" style={{ width: "100%" }}>
                                <div className="col-md-12">

                                    <div className="hamBurger-menu">
                                        <HamburgerMenu
                                            isOpen={this.state.open}
                                            menuClicked={this.handleClick.bind(this)}
                                            width={22}
                                            height={17}
                                            strokeWidth={1}
                                            rotate={0}
                                            color='white'
                                            borderRadius={0}
                                            animationDuration={0.5}
                                        />

                                    </div>

                                </div>
                            </div>
                            :
                            ''
                    }

                    <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />

                    <div id="divToPrint" className="row" style={{ width: '100%', margin: 'auto' }}>
                        <Fragment>
                            <MuiThemeProvider theme={theme}>
                                <Grid container >
                                    <Grid item xs={8} sm={8} md={10} lg={12} style={{ height: '90vh' }}>
                                        <DataTable
                                            data={this.state.dataTableProp}
                                            tableType={this.state.activeType}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate} />
                                    </Grid>
                                </Grid>
                            </MuiThemeProvider>
                        </Fragment>
                    </div>

                </div>
                {warning && <ErrorModal show={warning} />}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType))
});

export default connect(null, mapDispatchToProps)(Reports);




