import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { components } from 'react-select';
import { Switch, Route } from 'react-router-dom';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './store1.css';

import jumpersStore from '../../stores/jumpers-store';
import itemStore from '../../stores/item-store';
import loadStore from '../../stores/loads-store';
import loadsStore from '../../stores/loads-store';
import reportStore from '../../stores/report-store';
import reservationStore from '../../stores/reservation-store';

import logo from '../images/logo.svg';
import background from '../images/background.svg';
import LeftMenu from '../menu/LeftMenu1';
import HamburgerMenu from 'react-hamburger-menu';
import CreateTandemPackage from './modals/createTandemPackage';
import CreateVideoPackage from './modals/createVideoPackage';
import CreateDiscountCode from './modals/createDiscountCode';

import RemoveItem from './modals/removeItem';
import Api from '../../services/reservationapi';
import ErrorModal from '../sub-components/Modal/ErrorModal';

import Altitudes from './storeComponents/Altitudes';
import Giftcards from './storeComponents/Giftcards';
import Inventory from './storeComponents/Inventory';
import Category from './storeComponents/category';
import Packages from './storeComponents/Packages';
import StudentPackages from './storeComponents/StudentPackages';
import Blocks from './storeComponents/Blocks';
import DiscountCodes from './storeComponents/DiscountCodes';
import GrouponDeals from './storeComponents/GrouponDeals';
import DonutLoader from '../loadManager/DonutLoader';
import Planes from './storeComponents/Planes';
import PayPriceSchdules from './storeComponents/PayPriceSchdules';

class Store extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,

            open: false,

            rightOpen: false,

            jumperOptions: [],

            adminDefaultOptions: [],

            tandemStudentOptions: [],

            currentJumper: [],

            cardArray: [],

            loadPlaneArray: [],

            priceSchedule: [],

            paySchedule: [],

            completedLoads: [],

            jumperAlert: false,

            visible: true,

            warning: false,

            velocityButton: false,

            countersaleItems: [],

            tandemPackages: [],

            studentPackages: [],

            grouponPackages: [],

            videoPackages: [],

            discounts: [],

            categories: [],

            altitudes: [],

            blocks: [],

            giftcards: [],
            isLoading: true,

            filterItem: [
                { text: 'Tandem Instrutor', checked: false },
                { text: 'Aff Instrutor', checked: false },
                { text: 'Fun Jumper', checked: false },
                { text: 'Staff', checked: false },
                { text: 'Coach', checked: false }
            ],
            accountArray: [],
        };

        this.inputValue = '';
        this.handleJumpersLoaded = this.handleJumpersLoaded.bind(this);
        this.handlePlaneLoaded = this.handlePlaneLoaded.bind(this);
        this.handlePriceLoaded = this.handlePriceLoaded.bind(this);
        this.handleExistingLoads = this.handleExistingLoads.bind(this);
        this.handleCompleteLoad = this.handleCompleteLoad.bind(this);
        this.handleJumperCreated = this.handleJumperCreated.bind(this);
        this.onDismiss = this.onDismiss.bind(this);

        this.jumperEvents = [
            { name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded },
            { name: jumpersStore.getEventPrefix('jumperUpdated'), func: this.handleJumperUpdated },
            { name: jumpersStore.getEventPrefix('jumperCreated'), func: this.handleJumperCreated },
            { name: jumpersStore.getEventPrefix('warningEvent'), func: this.handleWarning },
            { name: jumpersStore.getEventPrefix('deleteCardEvent'), func: this.handleDeleteCard },
            { name: jumpersStore.getEventPrefix('cardAddEvent'), func: this.handelCardAdd },
            { name: jumpersStore.getEventPrefix('accountAddEvent'), func: this.handelAccountAdd }
        ];

        this.itemEvents = [
            { name: itemStore.getEventPrefix('itemCreated'), func: this.handleItemCreated },
            { name: itemStore.getEventPrefix('blockCreated'), func: this.handleBlockCreated },
            { name: itemStore.getEventPrefix('blockUpdated'), func: this.handleBlockUpdated },
            { name: itemStore.getEventPrefix('giftcardCreated'), func: this.handleGiftcardCreated },
            { name: itemStore.getEventPrefix('tandemPackageCreated'), func: this.handleTandemPackageCreated },
            { name: itemStore.getEventPrefix('studentPackageCreated'), func: this.handleStudentPackageCreated },
            { name: itemStore.getEventPrefix('videoPackageCreated'), func: this.handleVideoPackageCreated },
            { name: itemStore.getEventPrefix('discountsCreated'), func: this.handleDiscountsCreated },
            { name: itemStore.getEventPrefix('tandemPackagesLoaded'), func: this.itemsLoaded },
            { name: itemStore.getEventPrefix('updateAltitudes'), func: this.updateAltitudes },
            { name: itemStore.getEventPrefix('updateGiftcard'), func: this.updateGiftCards }
        ];

        this.loadEvents = [
            { name: loadStore.getEventPrefix('planeActivity'), func: this.handlePlaneLoaded },
            { name: loadStore.getEventPrefix('planesLoaded'), func: this.handlePlaneLoaded },
            { name: loadStore.getEventPrefix('getExistingLoads'), func: this.handleExistingLoads },
            { name: loadStore.getEventPrefix('completeLoad'), func: this.handleCompleteLoad },
            { name: loadStore.getEventPrefix('warningEvent'), func: this.handleWarning },


        ];

        this.reportEvents = [
            { name: reportStore.getEventPrefix('priceActivity'), func: this.handlePriceLoaded },
            { name: reportStore.getEventPrefix('warningEvent'), func: this.handleWarning }
        ];

        this.reservationEvents = [
            { name: reservationStore.getEventPrefix('createGroupon'), func: this.handleGrouponCreated },
            { name: reservationStore.getEventPrefix('grouponsLoaded'), func: this.handleGrouponsLoaded }
        ];
    }

    handleWarning = () => {
        this.setState({
            warning: true
        })

        setTimeout(() => {
            this.setState({
                warning: false
            })
        }, 2000)
    }
    itemsLoaded = () => {
        this.setAllItems();
    }
    handleDeleteCard = (data) => {
        jumpersStore.loadAllJumpers();
        this.setState({
            cardArray: data.cardsOnFile
        })
    }

    updateGiftCards = (giftcards) => {
        this.setState({ giftcards })
    }

    handelCardAdd = (data) => {
        this.setState({
            cardArray: data
        })
    }
    handlePlaneLoaded = (result) => {
        if (result === true) {
            // if somethings edited
            loadStore.loadPlanes();
        }
        else if (result.length > 0) {
            // if planes are loaded
            this.setState({
                loadPlaneArray: result,
            })
        }
    }

    handelAccountAdd = (data) => {
        this.setState({
            accountArray: data
        })
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    handleExistingLoads(loads) {
        this.setState({ completedLoads: loadsStore.completedLoads });
    }

    handleCompleteLoad(loads) {
        ////console.log('in handle complete Load');
        this.setState({ completedLoads: loads.completedLoads });
    }

    handleItemCreated = (items) => {
        //alert('item created');
        var countersaleItems = itemStore.getCountersaleItemsWithoutBlocks();
        this.setState({ countersaleItems: countersaleItems })
    }
    updateAltitudes = (altitudes) => {
        this.setState({ altitudes })
    }
    handleBlockCreated = (items) => {
        console.log('in handle items created');
        //alert('item created');
        var blocks = itemStore.getBlocks();
        this.setState({ blocks: blocks })
    }
    handleBlockUpdated = (blocks) => {
        this.setState({ blocks })
    }
    handleGiftcardCreated = (items) => {
        console.log('in handle items created');
        //alert('item created');
        itemStore.loadItems();
    }

    handleGrouponCreated = (groupon) => {

        console.log(groupon);
        var grouponPackages = reservationStore.getGrouponPackages();
        this.setState({ grouponPackages: grouponPackages });
    }

    handleGrouponsLoaded = (groupons) => {
        var grouponPackages = reservationStore.getGrouponPackages();
        console.log(grouponPackages);
        this.setState({ grouponPackages: grouponPackages });
    }


    handleTandemPackageCreated = (items) => {
        console.log('in handle tandem packages created');
        //alert('item created');
        var tandemPackages = itemStore.getTandemPackages();
        this.setState({ tandemPackages: tandemPackages })
    }

    handleStudentPackageCreated = (items) => {
        console.log('in handle student packages created');
        //alert('item created');
        var studentPackages = itemStore.getStudentPackages();
        this.setState({ studentPackages: studentPackages })
    }

    handleVideoPackageCreated = (items) => {
        console.log('in handle video package created');
        //alert('item created');
        var videoPackages = itemStore.getVideoPackages();
        this.setState({ videoPackages: videoPackages })
    }

    handleDiscountsCreated = () => {
        var discounts = itemStore.getDiscounts();
        this.setState({ discounts: discounts })
    }
    componentWillMount = () => {
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.setState({
            velocityButton: true
        })
        var loadPriceValue = [];

        var planArray = loadStore.planes;
        if (planArray.length == 0) {
            //loadStore.loadPlanes();
            var that = this;
            Promise.all([loadStore.loadPlanes()])
                .then(function (result) {
                    ////console.log(result[0]);
                    loadPriceValue = result[0]
                })
        }

        var completedArray = loadsStore.completedLoads;
        var priceArray = reportStore.priceSchedule;
        if (priceArray.length == 0) {
            var data = [];
            var that = this;
            Promise.all([reportStore.loadPrices()])
                .then(function (result) {
                    ////console.log(result);
                    that.setState({
                        priceSchedule: result[0].priceSchedule,
                        paySchedule: result[0].paySchedule,
                        loadPlaneArray: loadPriceValue,
                        completedLoads: completedArray
                    })
                })
        }
        else {
            this.setState({
                priceSchedule: priceArray.priceSchedule,
                paySchedule: priceArray.paySchedule,
                loadPlaneArray: planArray,
                completedLoads: completedArray
            })
        }
        if (window.ENV?.processorInfo.processor === "nab") {
            Api.startSession()
                .then(res => {
                    console.log(res);
                });
        }
    }

    componentDidMount = () => {
        this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
        this.loadEvents.forEach((evnt) => loadStore.on(evnt.name, evnt.func));
        this.reportEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
        this.itemEvents.forEach((evnt) => itemStore.on(evnt.name, evnt.func));
        this.reservationEvents.forEach((evnt) => reservationStore.on(evnt.name, evnt.func));
        this.setAllItems();
        if (window.globals && window.globals['accountsState']) {
            this.setState({ ...window.globals['accountsState'] });
        }
        window.addEventListener('resize', this.handleWindowSizeChange);
        var giftcards = itemStore.getGiftcards();
        this.setState({ giftcards: giftcards })
    }
    setAllItems = () => {
        var options = jumpersStore.getJumperOptions();

        this.setState({ jumperOptions: options, adminDefaultOptions: options.slice(0, 50) });
        ////console.log(this.state.adminDefaultOptions);
        ////console.log(this.state.jumperOptions);

        var countersaleItems = itemStore.getCountersaleItemsWithoutBlocks() || [];
        console.log('in set All Items');
        console.log(countersaleItems);
        var categories = itemStore.getCategories();

        var tandemPackages = itemStore.getTandemPackages();
        var videoPackages = itemStore.getVideoPackages();
        var studentPackages = itemStore.getStudentPackages();
        var grouponPackages = reservationStore.getGrouponPackages();
        var blocks = itemStore.getBlocks();
        var giftcards = itemStore.getGiftcards();
        var altitudes = itemStore.getAltitudes();
        var discounts = itemStore.getDiscounts();
        if (altitudes.length > 0 || giftcards.length > 0 || countersaleItems.length > 0) {
            this.setState({
                isLoading: false
            })
        }

        this.setState({ giftcards: giftcards, blocks: blocks, grouponPackages: grouponPackages, studentPackages: studentPackages, categories: categories, tandemPackages: tandemPackages, videoPackages: videoPackages, altitudes: altitudes, discounts, countersaleItems })

    }

    componentWillUnmount = () => {

        // let velocityScript = document.getElementById('velocityScript');
        // // let velocityButton = document.getElementById('btn_AddCard');
        // if (velocityScript) {
        // 	velocityScript.remove();
        // 	// velocityButton.remove();
        // }

        window.removeEventListener('resize', this.handleWindowSizeChange);

        ////console.log('in components will unmount accounts');

        this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
        this.loadEvents.forEach((evnt) => loadStore.removeListener(evnt.name, evnt.func));
        this.reportEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
        this.itemEvents.forEach((evnt) => itemStore.removeListener(evnt.name, evnt.func));
        this.reservationEvents.forEach((evnt) => reservationStore.removeListener(evnt.name, evnt.func));

    }


    handleJumperCreated(data) {
        this.setState({
            jumperAlert: true,
            currentJumper: data,
            cardArray: data.cardsOnFile
        }, () => {
            document.getElementsByClassName("rating-button")[0].click();
        })
        setTimeout(() => {
            this.setState({ jumperAlert: false });
        }, 2000)
    }

    handleJumpersLoaded(jumperOptions) {
        ////console.log('in handle jumpers loaded in accounts');
        var adminDefaultOptions = jumperOptions.slice(0, 50);
        //alert('handle Jumpers Loaded');
        this.setState({
            jumperOptions: jumperOptions,
            adminDefaultOptions: adminDefaultOptions
        });
    }

    handleJumperUpdated = (jumper) => {
        //alert(JSON.stringify(jumper));
        this.setState({ currentJumper: jumper, cardArray: jumper.cardsOnFile });
    }

    handlePriceLoaded(priceOption) {
        var that = this;
        Promise.all([reportStore.loadPrices()])
            .then(function (result) {
                ////console.log(result);
                that.setState({
                    priceSchedule: result[0].priceSchedule,
                    paySchedule: result[0].paySchedule
                })
            })
    }

    onToggle = (index, e) => {
        let newItems = this.state.filterItem.slice();
        newItems[index].checked = !newItems[index].checked
        this.setState({
            filterItem: newItems
        })
        //this.filterJumperOptions(this.state.jumperOptions, this.state.dailyActivities);
    }

    renderCheckbox = () => {
        return this.state.filterItem.map((item, index) => {
            var ratingName = item.text + "_" + index;
            var ratingText = "txt_" + item.text + "_" + index;
            return (
                <div className="col-4" style={{ marginBottom: '12px' }}>
                    <input type="checkbox" id={ratingName} name={ratingName} onChange={this.onToggle.bind(this, index)} checked={item.checked} />
                    <label style={{ marginLeft: "6px", position: 'absolute' }}>{item.text}</label>
                </div>
            )
        })
    }

    checkForNegatives = (price) => {

        if (price === undefined || price === null) {
            price = 0;
        }

        let priceCell = (price < 0) ?
            {
                value: `($${Math.abs(price).toFixed(2)})`
            } :
            {
                value: `$${price.toFixed(2)}`
            }

        if (price < 0) {
            return <span style={{ color: 'red' }}> {priceCell.value}</span>;
        }
        return <span>{priceCell.value}</span>;
    }

    filterJumpers = (inputValue, jumperOptions) => {
        ////console.log("in filterJumpers");
        if (inputValue) {
            //console.log(inputValue);
            var filteredOptions = jumperOptions.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase()));

            if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
            else return filteredOptions;
        }
        ////console.log('no inputValue');
        return jumperOptions.slice(0, 30);
    };

    handleClick() {
        this.setState({
            open: !this.state.open
        });
    }

    handleRightClick() {
        this.setState({
            rightOpen: !this.state.rightOpen
        });
    }

    promiseOptions = (inputValue, jumperOptions) => {
        ////console.log('in pomiseOptions');
        ////console.log(this.inputValue);
        return new Promise(resolve => {
            resolve(this.filterJumpers(this.inputValue, jumperOptions));
        });
    }

    handleInputChange = (newValue) => {
        ////console.log('handleInputChange');

        //const inputValue = newValue.replace(/\W/g, '');
        //////console.log(inputValue);

        //this.promiseOptions(newValue, jumperOptions);
        this.inputValue = newValue;
        //return inputValue;
    };

    handleSelect = (val) => {
        ////console.log('in handleSelect');
        if (val.value !== undefined) {
            ////alert(JSON.stringify(val.value));
            // this.onJumperChanged(val.value);
            // jumpersStore.getDailyActivity(val.value._id);
            this.setState({
                currentJumper: val.value,
                cardArray: val.value.cardsOnFile
            });
            ////console.log(JSON.stringify(val.value));
            document.getElementsByClassName("rating-button")[0].click()
        }
    }

    Option = (props) => {
        return (
            <div className="row">

                <div className="col-12">
                    <components.Option {...props} />
                </div>
            </div>
        );
    }

    renderSettings = () => {
        var settings = this.state.settings;

        if (settings != undefined) {
            return settings.map((item, index) => {
                return (
                    <tr key={`planRow${index}`}>
                        <td key={`planCell2${index}`}>{item.name}</td>
                        <td key={`planCell3${index}`}>{item.value}</td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }

    renderPackages = (packages, packageType) => {
        var items = packages;

        if (items != undefined) {
            return items.map((item, index) => {
                return (
                    <tr key={`planRow${index}`}>
                        <th key={`planCell1${index}`} scope="row" data-id={item._id}>{index + 1}</th>
                        <td key={`planCell2${index}`}>{item.item}</td>
                        {
                            packageType === "tandemPackage" ?
                                <td key={`planCell70{index}`}>{item.displayText}</td>
                                :
                                null
                        }
                        {
                            packageType === "tandemPackage" ?
                                <React.Fragment>
                                    <td key={`planCell3${index}`}>{this.checkForNegatives(item.price)}</td>
                                    <td key={`planCell4${index}`}>{this.checkForNegatives(item.weekendPrice)}</td>
                                </React.Fragment>
                                :
                                null
                        }
                        <td key={`planCell5${index}`}>{item.videoType}</td>
                        <td key={`planCell6${index}`}>{this.checkForNegatives(item.videoPrice)}</td>
                        {
                            packageType === "tandemPackage" ?
                                <td key={`planCell7{index}`}>{this.checkForNegatives(item.pay)}</td>
                                :
                                null
                        }
                        <td key={`planCell8${index}`}>{this.checkForNegatives(item.videoPay)}</td>
                        <td key={`planCell9${index}`}>{this.checkForNegatives(item.wristcamPay)}</td>
                        {
                            packageType === "tandemPackage" ?
                                <td key={`planCell11{index}`}>{item.altitude}</td>
                                :
                                null
                        }
                        <td key={`planCell14{index}`}>{item.isBookable ? "yes" : "no"}</td>
                        <td key={`planCell10${index}`}>{item.description}</td>
                        {packageType === "tandemPackage" ?
                            <td key={`planCell11${index}`}>
                                <CreateTandemPackage isEdit={true} item={item} categories={this.state.categories} />&nbsp;&nbsp;
                                <RemoveItem item={item} />
                            </td>
                            : null
                        }
                        {packageType === "videoPackage" ?
                            <td key={`planCell11${index}`}>
                                <CreateVideoPackage isEdit={true} item={item} categories={this.state.categories} />&nbsp;&nbsp;
                                <RemoveItem item={item} />
                            </td>
                            : null
                        }
                    </tr>
                )
            })
        } else {
            return null;
        }
    }


    renderGrouponPackages = (packages) => {
        var items = packages;

        if (items != undefined) {
            return items.map((item, index) => {
                item.category = 'groupon';
                return (
                    <tr key={`discountRow${index}`}>
                        <th key={`discountCell1${index}`} scope="row" data-id={item._id}>{index + 1}</th>
                        <td key={`discountCell2${index}`}>{item.packageName}</td>
                        <td key={`discountCell3${index}`}>{item.numTickets}</td>
                        <td key={`discountCell3${index}`}>{item.numCodes}</td>
                        <td key={`discountCell3${index}`}>{item.numRedeemed}</td>
                        <td key={`discountCell4${index}`} >${Math.abs(item.price).toFixed(2)}</td>
                        <td key={`discountCell5${index}`}>{item.tandemPackageName}</td>
                        <td key={`discountCell7${index}`}>{item.videoPackageName}</td>

                        <td key={`discountCell9${index}`}>
                            <CreateDiscountCode isEdit={true} item={item} categories={this.state.categories} />&nbsp;&nbsp;
                            <RemoveItem item={item} />
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }

    deleteItem = (id) => {
        alert('delete item');
        itemStore.deleteItem(id);
    }

    renderAlert = () => {
        //var completedLoads = loadsStore.completedLoads;

        if (localStorage.getItem("ExpiryID") !== null) {
            var completedLoads = JSON.parse(localStorage.getItem("ExpiryID"));
            //var completedLoads = loadsStore.expiryID;

            if (completedLoads !== undefined && completedLoads.length > 0) {
                //return completedLoads.map((item, index) => {
                return (
                    this.checkExpiry(completedLoads)
                )
                //})
            }
        }

        // if (completedLoads !== undefined && completedLoads.length > 0)
        // 	return completedLoads.map((item, index) => {
        // 		return (
        // 			this.checkExpiry(item.activeJumperIds)
        // 		)
        // 	})
        // else {
        // 	return completedLoads !== undefined && this.state.completedLoads.map((item, index) => {
        // 		return (
        // 			this.checkExpiry(item.activeJumperIds)
        // 		)
        // 	})
        // }
    }

    checkExpiry(activeID) {
        var waiverExpiry = [];
        var uspaExpiry = [];
        for (var item in activeID) {
            let jumperInfo = jumpersStore.getJumperById(activeID[item]);
            // let jumperInfo= jumpersStore.getJumperById(item);

            var date = new Date();
            if (jumperInfo !== undefined) {
                if (!jumperInfo.isStaff) {
                    if (jumperInfo.uspaExpiry !== undefined && jumperInfo.waiverDate !== undefined) {

                        var uspaDate = new Date(jumperInfo.uspaExpiry);
                        var uspaResult = date.valueOf() > uspaDate.valueOf();

                        var waiverDate = new Date(jumperInfo.waiverDate);
                        var waiverResult = date.valueOf() > waiverDate.valueOf();
                        // console.log(uspaResult + '' + waiverResult)
                        if (uspaResult === true && waiverResult === true) {
                            waiverExpiry.push({ label: "USPA membership and Medical waiver has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
                        }
                        else if (uspaResult === true) {
                            waiverExpiry.push({ label: "USPA membership has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
                        }
                        else if (waiverResult === true) {
                            waiverExpiry.push({ label: "Medical waiver has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
                        }
                    }
                    else if (jumperInfo.uspaExpiry !== undefined && jumperInfo.waiverDate === undefined) {
                        var uspaDate = new Date(jumperInfo.uspaExpiry);
                        var uspaResult = date.valueOf() > uspaDate.valueOf();
                        if (uspaResult) {
                            waiverExpiry.push({ label: "USPA membership has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
                        }
                    }
                    else if (jumperInfo.uspaExpiry === undefined && jumperInfo.waiverDate !== undefined) {
                        var waiverDate = new Date(jumperInfo.waiverDate);
                        var waiverResult = date.valueOf() > waiverDate.valueOf();
                        if (waiverResult) {
                            waiverExpiry.push({ label: "Medical waiver has been expired for " + jumperInfo.firstname + ' ' + jumperInfo.lastname, id: activeID[item] });
                        }
                    }
                }
            }
        }

        //var resultArray= { "Uspa" : uspaExpiry , "Waiver" : waiverExpiry };
        // return (
        // 	<Alert color="success">
        // 		USPA membership has been expired for {item}.
        //  	</Alert>
        // )

        return (
            waiverExpiry.length > 0 && waiverExpiry.map((item, index) => {
                return (
                    <Alert color="warning" style={{ width: '90%' }} isOpen={this.state.visible}  >
                        <i className="fa fa-close" onClick={this.handleRemove.bind(this)} data-key={item.id} style={{ position: 'absolute', right: '10px', top: '2px', cursor: 'pointer' }}>
                        </i>
                        {item.label}
                    </Alert>
                )
            })
        )
    }

    onDismiss() {
        // this.setState({ visible: false });
    }

    handleRemove(e) {
        // ////console.log('test')
        var currentKey = e.currentTarget.attributes['data-key'].value;
        var objectId = JSON.parse(localStorage.getItem("ExpiryID"));
        var newArray = objectId.filter((item) => item !== currentKey);
        localStorage.setItem("ExpiryID", JSON.stringify(newArray));
        this.renderAlert();
        this.forceUpdate();
    }

    Menu = (props) => {
        ////console.log(props);
        return (
            <div>
                <div className="row custom-header" style={{ height: '100px' }}>
                    {this.renderCheckbox()}
                </div>
                <components.Menu {...props}>
                    {props.children}
                </components.Menu>
            </div>
        );
    }


    render() {

        const { width, warning } = this.state;
        const isMobile = width <= 500;


        return (
            <div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
                {
                    isMobile ?
                        <div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
                            <div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
                                <LeftMenu routeHistory={this.props.history} />
                            </div>
                        </div>
                        :
                        <div className="blueBg fullHeightPanes left-container">
                            <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
                            <LeftMenu routeHistory={this.props.history} showCreate={true} />
                        </div>
                }
                <div className="middle-container-no-rightmenu">
                    {
                        isMobile ?
                            <div className="row" style={{ width: "100%" }}>
                                <div className="col-md-12">
                                    <div className="hamBurger-menu">
                                        <HamburgerMenu
                                            isOpen={this.state.open}
                                            menuClicked={this.handleClick.bind(this)}
                                            width={22}
                                            height={17}
                                            strokeWidth={1}
                                            rotate={0}
                                            color='white'
                                            borderRadius={0}
                                            animationDuration={0.5}
                                        />
                                    </div>
                                    <div className="hamBurger-right">
                                        <HamburgerMenu
                                            isOpen={this.state.rightOpen}
                                            menuClicked={this.handleRightClick.bind(this)}
                                            width={22}
                                            height={17}
                                            strokeWidth={1}
                                            rotate={0}
                                            color='white'
                                            borderRadius={0}
                                            animationDuration={0.5}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                    }

                    <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />

                    {
                        this.state.jumperAlert ?
                            <Alert color="success" className="alert-complete">
                                Jumper Added Successfully !
                            </Alert>
                            : ''
                    }
                    <div className="row justify-content-center store-container">
                        <DonutLoader isLoading={this.state.isLoading} addClass="store" />

                        <div className="col-12 main-div">

                            <Switch>
                                <Route path="/store/giftcards" render={() =>
                                    <Giftcards
                                        giftcards={this.state.giftcards}
                                        categories={this.state.categories}
                                    />
                                }
                                />
                                <Route path="/store/packages" render={() =>
                                    <Packages
                                        categories={this.state.categories}
                                        checkForNegatives={this.checkForNegatives}
                                        videoPackages={this.state.videoPackages}
                                        tandemPackages={this.state.tandemPackages}
                                    />
                                }
                                />
                                <Route path="/store/blocks" render={() =>
                                    <Blocks categories={this.state.categories}
                                        checkForNegatives={this.checkForNegatives}
                                        blocks={this.state.blocks}
                                        planes={this.state.loadPlaneArray}
                                    />
                                }
                                />
                                <Route path="/store/planes" render={() =>
                                    <Planes planesArray={this.state.loadPlaneArray}
                                        checkForNegatives={this.checkForNegatives}
                                    />
                                }
                                />
                                <Route path="/store/altitudes" render={() =>
                                    <Altitudes altitudes={this.state.altitudes}
                                        checkForNegatives={this.checkForNegatives}
                                        categories={this.state.categories} />
                                }
                                />
                                <Route path="/store/grouponpackages" render={() =>
                                    <GrouponDeals categories={this.state.categories}
                                        grouponPackages={this.state.grouponPackages}
                                    />
                                }
                                />
                                <Route path="/store/discounts" render={() =>
                                    <DiscountCodes categories={this.state.categories}
                                        discounts={this.state.discounts}
                                    />
                                }
                                />
                                <Route path="/store/schedules" render={() =>
                                    <PayPriceSchdules checkForNegatives={this.checkForNegatives} />
                                }
                                />
                                <Route path="/store/studentpackages" render={() =>
                                    <StudentPackages checkForNegatives={this.checkForNegatives}
                                        studentPackages={this.state.studentPackages} />
                                }
                                />
                                <Route path="/store/items" render={() =>
                                    <Inventory countersaleItems={this.state.countersaleItems} checkForNegatives={this.checkForNegatives} />
                                }
                                />
                                <Route path="/store/category" render={() =>
                                    <Category />
                                }
                                />
                            </Switch>
                            {/* {reportStore.paySchedule.length > 0 ?
                                   'payschdeeskk' : ''
                            } */}
                        </div>
                    </div>
                </div>
                {warning && <ErrorModal show={warning} />}
            </div>
        );
    }
}

export default Store;
