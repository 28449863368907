/**
 * http://usejsdoc.org/
 */
import { create } from 'apisauce';
import authStore from '../stores/auth-store';

// define the api
// alert(window.location.hostname);

import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const func = {};
func.loadFunJumpTypes = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/admin/loadFunJumpTypes');
}

func.loadManifestConfig = () => {

	return api.get('/admin/loadManifestConfig');
}

func.loadSettings = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/admin/settings');
}
func.loadTeams = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/admin/teams');
}
func.deleteTeam = (id) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/admin/teams/' + id);
}
func.createEditTeams = (payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/admin/teams', payload);
}
func.changeSetting = (id, changeBody) => {
	// console.log('in UpdatePlanes');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/admin/settings/' + id, changeBody);
}

func.loadPlanes = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/admin/loadPlanes');
}

func.updatePlanes = (id, planBody) => {
	// console.log('in UpdatePlanes');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/admin/loadPlanes/' + id, planBody);
}

func.createPlanes = (planBody) => {
	// console.log(planBody,'in CreatePlane');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/admin/loadPlanes', planBody);
}

func.deletePlanes = (id) => {
	// console.log('in CreatePlane');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/admin/loadPlanes/' + id);
}

func.createActiveLoad = (data) => {
	var payload = { ...data };
	// console.log(payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads', payload);
}

func.addObserver = (loadNum, jumpPostBody) => {
	// console.log("in addJumperToFunJump");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/observers', jumpPostBody);
}

func.removeObserver = (loadNum, observerId) => {
	// console.log("in addJumperToFunJump");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum + '/observers/' + observerId);
}

func.getAllActiveLoads = (useWaitlist) => {
	// console.log('in get all active loads');
	var queryString = '';
	if (useWaitlist) {
		queryString = '?waitlist=true'
	}
	return api.get('/activeLoads' + queryString);
}


func.createTandemJump = (loadNum, jumpPostBody) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/tandemJumps', jumpPostBody);
}

func.updateCompletedTandem = (loadId, jumpId, jumpPostBody) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadId + '/tandemJumps/' + jumpId, jumpPostBody);
}


func.updateTandemJump = (loadNum, jumpId, jumpPostBody) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum + '/tandemJumps/' + jumpId, jumpPostBody);
}

func.updateJumps = (loadNum, jump, jumpId, jumpPostBody) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum + '/' + jump + '/' + jumpId, jumpPostBody);
}

func.updateFunJump = (loadNum, jumpId, jumpPostBody) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum + '/funJumps/' + jumpId, jumpPostBody);
}

func.cancelTandemJump = (loadNum, jumpId) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum + '/tandemJumps/' + jumpId);
}

func.createCoachJump = (loadNum, jumpPostBody) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/coachJumps', jumpPostBody);
}

func.updateCoachJump = (loadNum, jumpId, jumpPostBody) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum + '/coachJumps/' + jumpId, jumpPostBody);
}

func.updateCompletedCoachJump = (loadNum, jumpId, jumpPostBody) => {

	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}

	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadNum + '/coachJumps/' + jumpId, jumpPostBody);
}

func.cancelCoachJump = (loadNum, jumpId) => {
	// console.log('in deleteCoachJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum + '/coachJumps/' + jumpId);
}

func.createAffJump = (loadNum, jumpPostBody) => {
	// console.log('in createAffJump');
	// console.log(jumpPostBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/affJumps', jumpPostBody);
}

func.updateAffJump = (loadNum, jumpId, jumpPostBody) => {
	// console.log('in updateAffJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum + '/affJumps/' + jumpId, jumpPostBody);
}


func.updateCompletedPilot = (loadId, jumpPostBody) => {
	// console.log('in updateAffJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadId + '/pilot/', jumpPostBody);
}

func.updateCompletedAffJump = (loadNum, jumpId, jumpPostBody) => {
	// console.log('in updateAffJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadNum + '/affJumps/' + jumpId, jumpPostBody);
}

func.deleteCompletedAffJump = (loadNum, jumpId, jumpPostBody) => {
	// console.log('in updateAffJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadNum + '/affJumps/' + jumpId + "/delete", jumpPostBody);
}

func.deleteCompletedCoachJump = (loadNum, jumpId, jumpPostBody) => {
	// console.log('in updateAffJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadNum + '/coachJumps/' + jumpId + "/delete", jumpPostBody);
}

func.deleteCompletedTandemJump = (loadNum, jumpId, jumpPostBody) => {
	console.log('in delete tandem Jump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadNum + '/tandemJumps/' + jumpId + "/delete", jumpPostBody);
}


func.cancelAffJump = (loadNum, jumpId) => {
	// console.log('in deleteAffJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum + '/affJumps/' + jumpId);
}

func.createFunJump = (loadNum, jumpPostBody) => {
	// console.log('in create fun jump');
	// console.log(jumpPostBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/funJumps', jumpPostBody);
}

func.addJumpersToFunJump = (loadNum, jumpId, jumpPostBody) => {
	// console.log("in addJumperToFunJump");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/funJumps/' + jumpId + '/jumpers', jumpPostBody);
}

func.addCompletedJumpersToFunJump = (loadNum, jumpId, jumpPostBody) => {
	// console.log("in addJumperToFunJump");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token);
	return api.put('/completedLoads/' + loadNum + '/funJumps/' + jumpId, jumpPostBody);
	// return api.post('/activeLoads/'+loadNum+'/funJumps/'+jumpId+'/jumpers', jumpPostBody);
}

func.approveJumperForFunJump = (loadNum, jumpId, jumperId) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/activeLoads/' + loadNum + '/funJumps/' + jumpId + '/jumpers/' + jumperId + '/approve');
}

func.removeJumperFromFunJump = (loadNum, jumpId, jumperId) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum + '/funJumps/' + jumpId + '/jumpers/' + jumperId);
}

func.cancelFunJump = (loadNum, jumpId) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum + '/funJumps/' + jumpId);
}

///////////////////////iad jumps endpoints

func.cancelIadJump = (loadNum, jumpId) => {
	// console.log('in deleteCoachJump');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum + '/iadJumps/' + jumpId);
}

func.createIadJump = (loadNum, jumpPostBody) => {
	// console.log('in createAffJump');
	// console.log(jumpPostBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/iadJumps', jumpPostBody);
}

func.updateIadJump = (loadNum, jumpId, jumpPostBody) => {
	console.log('in updateIadJump');
	console.log(jumpPostBody);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum + '/iadJumps/' + jumpId, jumpPostBody);
}

func.addJumpersToIadJump = (loadNum, jumpId, jumpPostBody) => {
	// console.log("in addJumperToFunJump");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/iadJumps/' + jumpId + '/jumpers', jumpPostBody);
}

func.removeJumpersFromIad = (loadNum, jumpId, jumpPostBody) => {
	// console.log("in addJumperToFunJump");
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/activeLoads/' + loadNum + '/iadJumps/' + jumpId + '/jumpers', jumpPostBody);
}


func.setStatus = (loadNum, payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum + '/setStatus', payload);
}

func.closeLoad = (loadNum) => {
	return api.get('/activeLoads/' + loadNum + '/close');
}

func.openLoad = (loadNum) => {
	return api.get('/activeLoads/' + loadNum + '/open');
}

func.completeLoad = (loadNum, date) => {
	if (date == null) {
		return api.get('/activeLoads/' + loadNum + '/complete');
	}
	else {
		return api.get('/activeLoads/' + loadNum + '/complete/?date=' + date);
	}
}

func.updateLoad = (loadNum, payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum, payload);
}

func.cancelLoad = (loadNum) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads/' + loadNum);
}

func.reopenLoad = (loadId) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/completedLoads/' + loadId + "/reopen");
}

func.sendLoadSheet = (loadNum) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/activeLoads/' + loadNum + '/sendLoadSheet');
}

func.printLoadSheet = (loadNum) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/activeLoads/' + loadNum + '/printLoadSheet', {}, {
		responseType: 'blob', headers: {
			'Accept': 'application/pdf'
		}
	});
}

func.printCompletedLoadSheet = (loadId) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/completedLoads/' + loadId + '/printLoadSheet', {}, {
		responseType: 'blob', headers: {
			'Accept': 'application/pdf'
		}
	});
}


func.resetLoads = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/activeLoads');
}

func.resetDay = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/resetDay');
}

func.reloadDay = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reloadDay');
}



func.updatePlaneType = (loadNum, planeBody) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/activeLoads/' + loadNum, planeBody);
}

func.getCompletedLoad = (startdate, endate) => {
	return api.get('/completedLoads/?start=' + startdate + '&' + 'end=' + endate);
}

export default func;
