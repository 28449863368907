import React, { Component } from "react";

import "../css/react-contextmenu.css";
import "../css/loadManager.css";

import loadsStore from "../../stores/loads-store";
import jumpersStore from "../../stores/jumpers-store";
import scheduleStore from "../../stores/schedule-store";
import CompleteLoad from "./modals/completeLoad";
import CancelLoad from "./modals/cancelLoad";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import RenderFunJump from "./RenderFunJump";
import RenderTandemJump from "./RenderTandemJump";
import RenderCoachJump from "./RenderCoachJump";
import RenderAffJump from "./RenderAffJump";
import RenderIadJump from "./RenderIadJump";
import RenderPilotCard from "./RenderPilotCard";
import { DropTarget } from "react-dnd";
import CreateFunJumpCard from "./modals/CreateFunJumpCard";
import LoadCapacity from "./modals/loadCapacity";
import LoadPrice from "./modals/loadPrice";
import MultiPlane from "./modals/multiPlane";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import EditableTimer from "./subComponents/EditableTimer";
import itemStore from "../../stores/item-store";
// import Toast from 'react-bootstrap/Toast'
import JmpModal from "./modals/customModal";
import EditableLoadPlane from './modals/editableLoadPlane';

import LoadHeader from './subComponents/LoadHeader';
import CreateTandemField from './subComponents/CreateTandemField';
import CreateStudentField from "./subComponents/CreateStudentField";
import CreateJumpBtn from "./subComponents/CreateJumpBtn";
import CreateTeamJumpCard from './modals/CreateTeamJumpCard';
import { Button, ButtonGroup } from 'reactstrap';

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    // hovered: monitor.isOver(),
    // item: monitor.getItem()
  };
}

const attributes = {
  className: "custom-root",
  disabledClassName: "custom-disabled",
  dividerClassName: "custom-divider",
  selectedClassName: "custom-selected"
};

const loadTarget = {
  hover(props, monitor, component) { },
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }
    const dragItem = monitor.getItem();
    console.log(dragItem, "dragItem")
    const hoverItem = props;
    var targetLoad = 0;
    if (props.loadNum === 0) {
      targetLoad = 0;
    }
    else {
      targetLoad = loadsStore.getLoadNumByIndx(props.loadIndx);
    }
    if (dragItem?.items) {
      dragItem.items.map((dragStuff, i) => {
        var loadNum = dragStuff?.loadNum;
        var jumpId = dragStuff?.jumpId;
        var jumpType = dragStuff?.jumpType;
        handleMultipleDrag(dragStuff, targetLoad, loadNum, jumpId, jumpType)
      })
    } else {
      var loadNum = dragItem?.loadNum;
      var jumpId = dragItem?.jumpId;
      var jumpType = dragItem?.jumpType;
      handleMultipleDrag(dragItem, targetLoad, loadNum, jumpId, jumpType)
    }
  }
};


const handleMultipleDrag = (dragItem, targetLoad, loadNum, jumpId, jumpType) => {
  if (dragItem?.jumper && dragItem?.jumper._id) {
    var jumpBodyN = {
      jumpType: dragItem.jump.jumpType,
      jumperIds: [{
        jumperId: dragItem.jumper._id,
        price: dragItem.jumper.price,
        block: dragItem.jumper.block,
        blockId: dragItem.jumper.blockId,
        gearPrice: dragItem.jumper.gearPrice,
        gearType: dragItem.jumper.gearType,
        isLOing: dragItem.jumper.isLOing,
        tracked: dragItem.jumper.tracked
      }],
      gearRentals: dragItem.jumper.gearRental ? [dragItem.jumper._id] : [],
      studentgearRentals: dragItem.jumper.studentgearRental ? [dragItem.jumper._id] : [],
      sportgearRentals: dragItem.jumper.sportgearRental ? [dragItem.jumper._id] : [],
      pending: false,
      altitude: dragItem.jump.altitude
    };
    if (loadNum === targetLoad) {
      jumpBodyN.allowClosedOverride = true;
    }
    loadsStore.createFunJump(targetLoad, jumpBodyN)
      .then(r => {
        if (r) {
          loadsStore.removeJumper(dragItem.loadNum, dragItem.jump.jumpId, dragItem.jumper._id);
        }
      })
  }
  if (loadNum == targetLoad) {
    return;
  }
  if (dragItem?.jumpType === "funJump") {
    var moveJumpType = dragItem.funJump.jumpType;

    var jumperIdArray = [];
    var gearRentArray = [];
    var studentgearRentArray = [];
    var sportgearRentArray = [];

    var jumperIds = dragItem.funJump.jumpers.map(jumper => {
      return {
        jumperId: jumper._id,
        price: jumper.price,
        block: jumper.block,
        blockId: jumper.blockId,
        gearPrice: jumper.gearPrice,
        gearType: jumper.gearType,
        isLOing: jumper.isLOing,
        isVideographer: jumper.isVideographer,
        chargeTo: jumper.chargeTo,
        tracked: jumper.tracked
      };
    });


    var gearRentals = dragItem.funJump.gearRentals;
    if (gearRentals.length > 0) {
      gearRentals.forEach(element => {
        if (element != null) {
          gearRentArray.push(element);
        }
      });
    }

    var studentgearRentals = dragItem.funJump.studentgearRentals;

    var sportgearRentals = dragItem.funJump.sportgearRentals;

    if (studentgearRentals && studentgearRentals.length > 0) {
      studentgearRentals.forEach(element => {
        if (element != null) {
          studentgearRentArray.push(element);
        }
      })
    }

    if (sportgearRentals && sportgearRentals.length > 0) {
      sportgearRentals.forEach(element => {
        if (element != null) {
          sportgearRentArray.push(element);
        }
      })
    }

    var jumpPostBody = {
      jumpType: moveJumpType,
      jumperIds: jumperIds,
      gearRentals: gearRentArray,
      studentgearRentals: studentgearRentArray,
      sportgearRentals: sportgearRentArray,
      altitude: dragItem.funJump.altitude,
      gearRentals: gearRentArray,
      note: dragItem.funJump.note,
      teamName: dragItem.funJump.teamName,
      teamId: dragItem.funJump.teamId,
      teamType: dragItem.funJump.teamType
    };

    var removeBody = {
      loadNum: loadNum,
      jumpId: jumpId,
      jumpType: jumpType
    };

    dragItem.handleRemoveFun(targetLoad, jumpPostBody, removeBody);
  } else if (dragItem.jumpType === "tandemJump") {
    var today = new Date();
    var isWeekend = today.getDay() % 6 === 0;

    var instructor = dragItem.tandemJump.instructor._id;
    var student = dragItem.tandemJump.student._id;
    var videographer = "";
    if (dragItem.tandemJump.videographer != undefined) {
      videographer = dragItem.tandemJump.videographer._id;
    }

    var tandemBody = {
      instructorId: instructor,
      studentId: student,
      isWeekend: isWeekend,
      isUltra: dragItem.tandemJump.isUltra,
      instructorPay: dragItem.tandemJump.instructorPay,
      price: dragItem.tandemJump.price,
      videoPay: dragItem.tandemJump.videoPay,
      wristcamPay: dragItem.tandemJump.wristcamPay,
      videoType: dragItem.tandemJump.videoType,
      videoPrice: dragItem.tandemJump.videoPrice,
      withVideo: dragItem.tandemJump.withVideo,
      tandemPackageId: dragItem.tandemJump.tandemPackageId,
      videoPackageId: dragItem.tandemJump.videoPackageId,
      isOverweight: dragItem.tandemJump.isOverweight,
      overweightPrice: dragItem.tandemJump.overweightPrice,
      overweightPay: dragItem.tandemJump.overweightPay,
      callRequest: dragItem.tandemJump.callRequest,
      startedAt: dragItem.tandemJump.startedAt,
      note: dragItem.tandemJump.note,

    };

    if (videographer != "") {
      tandemBody.videographerId = videographer;
    }

    var removeBody = {
      loadNum: loadNum,
      jumpId: jumpId,
      jumpType: jumpType
    };

    dragItem.handleTandemDrop(targetLoad, tandemBody, removeBody);
  } else if (dragItem.jumpType === "affJump") {
    var level = dragItem.affJump.level;
    var mainInstructor = "";
    if (dragItem.affJump.mainInstructor != undefined) {
      mainInstructor = dragItem.affJump.mainInstructor._id;
    }
    var student = dragItem.affJump.student._id;
    var secondaryInstructor = "";

    if (dragItem.affJump.secondaryInstructor != undefined) {
      secondaryInstructor = dragItem.affJump.secondaryInstructor._id;
    }

    var videographer = "";
    if (dragItem.affJump.videographer != undefined) {
      videographer = dragItem.affJump.videographer._id;
    }

    jumpPostBody = {
      studentPackageId: dragItem.affJump.studentPackageId,
      studentPackageName: dragItem.affJump.studentPackageName,
      altitude: dragItem.affJump.altitude,
      studentId: student,
      instructor1Pay: dragItem.affJump.instructor1Pay,
      instructor2Pay: dragItem.affJump.instructor2Pay,
      price: dragItem.affJump.price,
      block: dragItem.affJump.block,
      blockId: dragItem.affJump.blockId,
      videoPrice: dragItem.affJump.videoPrice,
      videoPay: dragItem.affJump.videoPay,
      priceType: dragItem.affJump.priceType,
      studentPackageId: dragItem.affJump.studentPackageId,
      type: dragItem.affJump.type,
      callRequest: dragItem.affJump.callRequest,
      startedAt: dragItem.affJump.startedAt,
      note: dragItem.affJump.note,
    };

    if (mainInstructor != "") {
      jumpPostBody.mainInstructorId = mainInstructor;
    }
    if (secondaryInstructor != "") {
      jumpPostBody.secondaryInstructorId = secondaryInstructor;
    }

    if (videographer != "") {
      jumpPostBody.videographerId = videographer;
    }

    var removeBody = {
      loadNum: loadNum,
      jumpId: jumpId,
      jumpType: jumpType
    };

    dragItem.handleCreateAff(targetLoad, jumpPostBody, removeBody);
  } else if (dragItem.jumpType === "coachJump") {
    var coachBody = {
      coachId: dragItem.coachJump.coach._id,
      jumperId: dragItem.coachJump.jumper._id,
      isWingsuit: dragItem.coachJump.isWingsuit,
      coachPay: dragItem.coachJump.coachPay,
      price: dragItem.coachJump.price,
      note: dragItem.coachJump.note,

    };

    var removeBody = {
      loadNum: loadNum,
      jumpId: jumpId,
      jumpType: jumpType
    };

    dragItem.handleCoachDrop(targetLoad, coachBody, removeBody);
  }
  else if (dragItem.isTeam) {
    let selectedTeam = jumpersStore.getJumperById(dragItem.jumperId);
    console.log(selectedTeam);
    let jumperIds = [], videographerIds = [];

    selectedTeam.teamMembersInfo.forEach(i => {
      if (i.isVideographer) {
        videographerIds.push(i.jumperId)

      }
      else {
        jumperIds.push(i.jumperId);
      }
    })
    // if(Array.isArray(this.state.videoGraphers)){
    //     this.state.videoGraphers.map(i => {
    //       videographerIds.push(i.value._id)
    //     })
    // } else {
    //     videographerIds.push(this.state.videoGraphers.value._id)
    // }

    var jumpPostBody = {
      jumpType: selectedTeam?.jumpType,
      jumperIds: jumperIds,
      videographerIds: videographerIds,
      altitude: selectedTeam?.defaultAltitude ? selectedTeam?.defaultAltitude : itemStore.getDefaultAltitude().name,
      teamName: selectedTeam?.teamname,
      teamId: selectedTeam?._id,
      teamType: selectedTeam?.teamType
    };
    if (selectedTeam.jumpType === "HopNPop" || selectedTeam.jumpType === 'HopNPop Student') {
      jumpPostBody.altitude = itemStore.getLowAltitude().name;
    }
    // let videoGId = this.state.videoGraphers[0].value._id;

    //jumpPostBody.jumperIds.push(videoGId);

    if (jumpType === "HopNPop" || jumpType === 'HopNPop Student') {
      jumpPostBody.altitude = itemStore.getLowAltitude().name;
    }
    console.log('drag team');
    console.log(jumpPostBody);
    loadsStore.createFunJump(targetLoad, jumpPostBody);
  }
}


class Load extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.teamInputRef = React.createRef();
    this.state = {
      jumpers: [],
      slotsRemaining: 0,
      load: props.load,
      loadNum: props.loadNum,
      loadIndx: props.loadIndx,
      // creatingFunJump: false,
      // creatingTeamJump: false,
      headerMenu: false,
      show: false,
      tosterMessage: "",
      tandemPackages: [],
      teams: [],
      teamsOptions: [],
      timeslots: [],
      role: "",
      toggleClass: false,
      loadStatusHide: false,
      dragabbleItems: [],
      waitlistLoad: null
    };

    this.setJumpers = this.setJumpers.bind(this);
    this.handleLoadStatusToggleEvent = this.handleLoadStatusToggleEvent.bind(
      this
    );

    this.loadEvents = [
      {
        name: loadsStore.getEventPrefix("cancelCreateJump"),
        func: this.handleCancelCreateJump
      },
      { name: loadsStore.getEventPrefix('teamsLoaded'), func: this.handelTeamsLoaded },
      { name: loadsStore.getEventPrefix('getWaitlists'), func: this.getWaitlists },      // {
      //   name: loadsStore.getEventPrefix("updateLoad"),
      //   func: this.handleLoadStatusToggleEvent
      // },
      {
        name: loadsStore.getEventPrefix("updateLoad_" + props.loadNum),
        func: this.handleUpdateThisLoad
      },
      { name: loadsStore.getEventPrefix('createLoad_' + props.loadIndx), func: this.handleCreateNewLoad },

      {
        name: loadsStore.getEventPrefix("cancelLoads"),
        func: this.handleCancelLoads
      },
      {
        name: loadsStore.getEventPrefix("toggleLoadStatus"),
        func: this.handleLoadStatusToggleEvent
      },
      {
        name: loadsStore.getEventPrefix("cancelPreviousJump"),
        func: this.handlePreviousJump
      }
    ];

    this.itemEvents = [
      {
        name: itemStore.getEventPrefix("tandemPackagesLoaded"),
        func: this.handleTandemPackagesLoaded
      }
    ];

    this.scheduleEvents = [
      {
        name: scheduleStore.getEventPrefix("todayReservationEvent"),
        func: this.handleReservationEvent
      }
    ];
  }

  /**
   * Set the header menu ref
   */
  setHeaderMenuRef = node => {
    this.headerMenuRef = node;
  };

  toggleToster = (a, b) => {
    this.setState({
      show: a ? a : false,
      tosterMessage: b ? b : ""
    });
  };
  radio(i) {
    this.setState({ radio: i });
  }
  DeleteJumper = id => { };

  handleJumperDrop(loadNum, jumpId, jumpType) {
    if (jumpType === "tandemJump") {
      loadsStore.cancelTandemJump(loadNum, jumpId);
    } else if (jumpType === "affJump") {
      loadsStore.cancelAffJump(loadNum, jumpId);
    } else if (jumpType === "funJump") {
      loadsStore.cancelFunJump(loadNum, jumpId);
    } else if (jumpType === "coachJump") {
      loadsStore.cancelCoachJump(loadNum, jumpId);
    }
  };

  handleReservationEvent = data => {
    this.setState({
      timeslots: data.timeslots
    });
  };

  handleCreateFun(loadNum, jumpBody) {
    var that = this;
    Promise.all([loadsStore.createFunJump(loadNum, jumpBody)]).then(function (
      result
    ) {
      if (result[0] === true) {
        // console.log(result[0]);
      }
    });
  };

  handleRemoveFun(loadNum, jumpBody, removeBody) {
    var that = this;
    var removeloadNum = removeBody.loadNum;
    var newLoadNum = loadNum;
    var jumpId = removeBody.jumpId;
    var jumpType = removeBody.jumpType;

    loadsStore.createFunJump(loadNum, jumpBody)
      .then(result => {
        if (result === true) {
          loadsStore.cancelFunJump(removeloadNum, jumpId)
        }
      })
  };

  handleCreateIad(loadNum, iadPostBody, removeBody) {
    var that = this;
    Promise.all([loadsStore.createIadJump(loadNum, iadPostBody)]).then(function (
      result
    ) {
      if (result[0] === true) {
        var loadNum = removeBody.loadNum;
        var jumpId = removeBody.jumpId;
        var jumpType = removeBody.jumpType;
        that.handleJumperDrop(loadNum, jumpId, jumpType);
      }
    });
  };

  handleCreateAff(loadNum, affPostBody, removeBody) {
    var that = this;
    Promise.all([loadsStore.createAffJump(loadNum, affPostBody)]).then(function (
      result
    ) {
      if (result[0] === true) {
        var loadNum = removeBody.loadNum;
        var jumpId = removeBody.jumpId;
        var jumpType = removeBody.jumpType;
        that.handleJumperDrop(loadNum, jumpId, jumpType);
      }
    });
  };

  handleCoachDrop(loadNum, coachBody, removeBody) {
    var that = this;
    Promise.all([loadsStore.createCoachJump(loadNum, coachBody)]).then(function (
      result
    ) {
      if (result[0] === true) {
        var loadNum = removeBody.loadNum;
        var jumpId = removeBody.jumpId;
        var jumpType = removeBody.jumpType;

        that.handleJumperDrop(loadNum, jumpId, jumpType);
      }
    });
  }

  handleTandemDrop(loadNum, tandemBody, removeBody) {
    var that = this;
    Promise.all([loadsStore.createTandemJump(loadNum, tandemBody)]).then(
      function (result) {
        if (result[0] === true) {
          var loadNum = removeBody.loadNum;
          var jumpId = removeBody.jumpId;
          var jumpType = removeBody.jumpType;
          that.handleJumperDrop(loadNum, jumpId, jumpType);
        }
      }
    );
  }
  /*
  static getDerivedStateFromProps(props, state) {
    console.log('getDerivedStateFromProps');
      if(props.load !== state.load){
          //Change in props
          return{
              load: props.load
          };
      }
      return null; // No change to state
  }*/

  componentWillMount = () => {
    this.loadEvents.forEach(evnt => loadsStore.on(evnt.name, evnt.func));
    this.itemEvents.forEach(evnt => itemStore.on(evnt.name, evnt.func));
    this.scheduleEvents.forEach(evnt => scheduleStore.on(evnt.name, evnt.func));

    document
      .querySelector("body")
      .addEventListener("click", this.handleEventClick, false);
    document.addEventListener("click", this.handleClickOutside, true);
  };

  componentDidMount = () => {
    this._isMounted = true;
    console.log(this.props.load);
    console.log(this.state.load);
    let role = localStorage.getItem("role");
    if (role && role !== this.state.role) {
      this.setState({ role })
    }
  }

  componentWillUnmount = () => {
    console.log('*****in component will unmount ' + this.state.load.loadNum);
    this._isMounted = false;
    this.loadEvents.forEach(evnt =>
      loadsStore.removeListener(evnt.name, evnt.func)
    );
    this.itemEvents.forEach(evnt =>
      itemStore.removeListener(evnt.name, evnt.func)
    );

    this.scheduleEvents.forEach(evnt =>
      scheduleStore.removeListener(evnt.name, evnt.func)
    );
    //loadsStore.on('updateLoad'+this.state.load.loadNum, this.handleUpdateLoad)


    document
      .querySelector("body")
      .removeEventListener("click", this.handleEventClick, false);
    document.removeEventListener("click", this.handleClickOutside, true);
  };

  handleUpdateThisLoad = (load) => {
    //alert('in handle update this load');
    console.log("in handle update This load");
    console.log(load.loadNum);
    console.log(load);
    this.setState({ load: load })
    if (load.loadNum === 0) {
      this.setState({ waitlistLoad: load })
    }
  }
  handleCreateNewLoad = (load) => {
    //alert('create new load');
    //console.log('handle create new load');
    //console.log(load.loadNum);
    //console.log(load);

    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));

    this.setState({
      key: load.key ? load.key : null,
      load: load,
      loadNum: load.loadNum ? load.loadNum : null
    });


    this.loadEvents = [
      {
        name: loadsStore.getEventPrefix("cancelCreateJump"),
        func: this.handleCancelCreateJump
      },
      { name: loadsStore.getEventPrefix('teamsLoaded'), func: this.handelTeamsLoaded },
      // {
      //   name: loadsStore.getEventPrefix("updateLoad"),
      //   func: this.handleLoadStatusToggleEvent
      // },
      { name: loadsStore.getEventPrefix('createLoad_' + this.props.loadIndx), func: this.handleCreateNewLoad },

      {
        name: loadsStore.getEventPrefix("cancelLoads"),
        func: this.handleCancelLoads
      },
      {
        name: loadsStore.getEventPrefix("toggleLoadStatus"),
        func: this.handleLoadStatusToggleEvent
      },
      {
        name: loadsStore.getEventPrefix("cancelPreviousJump"),
        func: this.handlePreviousJump
      }
    ];
    if (load.loadNum) {
      this.loadEvents.push({ name: loadsStore.getEventPrefix('updateLoad_' + load.loadNum), func: this.handleUpdateThisLoad });
    }
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
  }
  handleCancelLoads = () => {
    //alert('in handle cancel loads load manager');
    console.log("in handle cancel loads");
    console.log(this.props.load);
    this.setState({ load: this.props.load })
  }
  handleTandemPackagesLoaded = tandemPackages => {
    this.setState({ tandemPackages: tandemPackages });
  };
  handelTeamsLoaded = (teams) => {
    if (this.state.teams.length !== teams.length) {
      let teamsOptions = teams.map(ele => {
        let newObj = {}
        newObj.label = ele.firstname;
        newObj.value = ele;
        return newObj;
      })
      this.setState({ teams, teamsOptions })
    }
  }
  handleClickOutside = event => {
    if (this.headerMenuRef && !this.headerMenuRef.contains(event.target)) {
      this.setState({
        headerMenu: false
      });
    }
  };

  getWaitlists = (data) => {
    if (!this.state.waitlistLoad || this.state.waitlistLoad.length === 0) {
      this.setState({ waitlistLoad: data.schoolWaitlist })
    }
  }

  handleWaitlistUpdate = (waitlist) => {
    this.setState({ waitlistLoad: waitlist })
  }

  handleEventClick = () => {
    var divsToHide = document.getElementsByClassName("pastepanel");
    for (var i = 0; i < divsToHide.length; i++) {
      divsToHide[i].style.display = "none";
    }
  };

  setJumpers(load) {
    var numJumpers = 0;
    this.setState({
      slotsRemaining: 25 - load.totalJumpers
    });
  }

  // toggleLoadStatus(closed) {
  //   if (closed) {
  //     loadsStore.openLoad(this.props.load.loadNum);
  //   } else {
  //     loadsStore.closeLoad(this.props.load.loadNum);
  //   }
  // }

  // toggleStartupStatus(isStartup) {
  //   loadsStore.setStatus(this.props.load.loadNum, { isStartup: !isStartup });
  // }

  toggleShutdownStatus(isShutdown) {
    loadsStore.setStatus(this.state.load.loadNum, { isShutdown: !isShutdown });
  }

  // toggleLoadFuelStatus(markFuel) {
  //   loadsStore.setStatus(this.props.load.loadNum, { waitFuel: !markFuel });
  // }
  // toggleLoadTimerStatus(isPaused) {
  //   loadsStore.setStatus(this.props.load.loadNum, { isPaused: !isPaused });
  // }

  // toggleLoadTimeStatus(gearUp) {
  //   loadsStore.setStatus(this.props.load.loadNum, { gearUp: !gearUp });
  // }
  // toggleLoadNextStatus(isNext) {
  //   loadsStore.setStatus(this.props.load.loadNum, { isNext: !isNext });
  // }
  // toggleInAirStatus(inAir) {
  //   loadsStore.setStatus(this.props.load.loadNum, { inAir: !inAir });
  // }

  // toggleReserveStatus(reserved) {
  //   loadsStore.setStatus(this.props.load.loadNum, { reserved: !reserved });
  // }

  setPAstatus(call) {
    loadsStore.setStatus(this.state.load.loadNum, { pastatus: call });
  }

  handleCancelCreateJump = (payload) => {
    if (payload.loadNum === this.state.load.loadNum) {
      switch (payload.type) {
        case "teamjump":
          document.querySelector(".isTeamJumpOpen-" + this.state.load.loadNum).style.display = 'none'
          // this.setState({ creatingTeamJump: false });
          break;
        case "funjump":
          document.querySelector(".isFunJumpOpen-" + this.state.load.loadNum).style.display = 'none'
          // this.setState({ creatingFunJump: false });
          break;
        default:
          break;
      }
    }
  };

  handlePreviousJump = loadNum => {
    if (this.state.load.loadNum !== loadNum) {
      //document.querySelector(".isFunJumpOpen-" + this.props.load.loadNum).style.display = 'none'
      // this.setState({ creatingFunJump: false });
    }
  };

  handleLoadStatusToggleEvent(load) {
    this.setState({
      load: load
    });

    if (load.closed) {
      this.setState({
        background: "#a4abad"
      });
    } else {
      this.setState({
        background: "#e7f2f6"
      });
    }
  }

  removeFunJumper = (loadNum, jumpId, jumperId) => {
    loadsStore.removeJumper(loadNum, jumpId, jumperId);
  };

  createFunJump = id => {
    console.log('opening fun jump modal');
    document.querySelector(".isFunJumpOpen-" + this.state.load.loadNum).style.display = 'block'
    this.inputRef.current.focus();
    loadsStore.cancelPreviousJump(this.state.load.loadNum);
    var objDiv = document.getElementById(id);
    objDiv.scrollTop = objDiv.scrollHeight;
    // this.setState({ creatingFunJump: true }, () => {

    // });
  };
  createTeamJump = id => {
    document.querySelector(".isTeamJumpOpen-" + this.state.load.loadNum).style.display = 'block'
    this.teamInputRef.current.focus();
    loadsStore.cancelPreviousJump(this.state.load.loadNum);
    var objDiv = document.getElementById(id);
    objDiv.scrollTop = objDiv.scrollHeight;
    // this.setState({ creatingTeamJump: true }, () => {

    // });
  };
  createCoachJump = () => {
    //alert("loadNum: " + loadNum);
    loadsStore.createCoachJump(this.state.load.loadNum, {});
  };

  createWingsuitCoachJump = () => {
    //alert("loadNum: " + loadNum);
    loadsStore.createCoachJump(this.state.load.loadNum, { isWingsuit: true });
    // loadsStore.createCoachJump(this.state.load.loadNum, {});
  };


  createIadJump = (type) => {
    //alert("loadNum: " + loadNum);
    var postBody = { type: type };

    postBody.studentPackageId = itemStore.getDefaultStudentPackageId("IAD");

    loadsStore.createIadJump(this.state.load.loadNum, postBody);
  };

  createAffJump = (type) => {
    //alert("loadNum: " + loadNum);
    var postBody = { type: type };
    postBody.studentPackageId = type._id.toString();

    loadsStore.createAffJump(this.state.load.loadNum, postBody);
  };

  createTandemJump = packageId => {
    var today = new Date();
    var isWeekend = today.getDay() % 6 === 0;

    loadsStore.createTandemJump(this.state.load.loadNum, {
      isWeekend: isWeekend,
      tandemPackageId: packageId
    });
  };

  createTandemJumpWithVideo = () => {
    var today = new Date();
    var isWeekend = today.getDay() % 6 === 0;
    loadsStore.createTandemJump(this.state.load.loadNum, {
      isWeekend: isWeekend,
      tandemPackageId: itemStore.getDefaultTandemPackage(),
      videoPackageId: itemStore.getDefaultVideoPackage()
    });
  };

  createTandemUltraJump = () => {
    var today = new Date();
    var isWeekend = today.getDay() % 6 === 0;
    loadsStore.createTandemJump(this.state.load.loadNum, {
      isWeekend: isWeekend,
      tandemPackageId: itemStore.getHighAltPackage()
    });
    // loadsStore.createTandemJump(this.state.load.loadNum, {isWeekend: isWeekend});
  };

  PrintLoad(id, loadNum) {
    const input = document.getElementById(id);

    var innercard = "innercard" + loadNum;
    var centercard = "centercard" + loadNum;
    document.getElementById("btn-load-print").style.visibility = "hidden";
    document.getElementById(innercard).style.height = "100vh";
    document.getElementById(centercard).style.height = "100vh";

    var pdfName = id + ".pdf";

    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      document.getElementById("btn-load-print").style.visibility = "visible";
      document.getElementById(innercard).style.height = "65vh";
      document.getElementById(centercard).style.height = "74vh";
      var imgWidth = 210;
      var pageHeight = 310;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(pdfName);
    });
  }

  handlePasteClick = (e, loadNum, uid) => {
    var Value = loadsStore.copyValue;
    if (Value.tandemPackageName) {
      loadsStore.createTandemJump(loadNum, Value).then(d => {
        if (d) {
          if (loadsStore.cut) {
            loadsStore.cut = false;
            const { loadNum, jumpId } = loadsStore.cutValue;
            loadsStore.cancelTandemJump(loadNum, jumpId);
          }
        }
      })
    }
    else {
      loadsStore.createFunJump(loadNum, Value).then(d => {
        if (d) {
          if (loadsStore.cut) {
            loadsStore.cut = false;
            const { loadNum, jumpId } = loadsStore.cutValue;
            loadsStore.cancelFunJump(loadNum, jumpId);
          }
        }
      });
    }


    var element = document.getElementById(uid);
    // element.style.display = "none";
  };

  handleClick = (e, uid, parentId) => {
    e.preventDefault();
    var divsToHide = document.getElementsByClassName("pastepanel");
    for (var i = 0; i < divsToHide.length; i++) {
      divsToHide[i].style.display = "none";
    }

    var element = document.getElementById(uid);
    // element.style.right = e.clientX - 238  + "px";
    // element.style.top = "38px"; //e.clientY + "px";
    element.style.display = "block";
    // setTimeout(() => {
    //   element.style.display = "none";
    // }, 10000)

    //var theThing = document.querySelector("#thing");
    var container = document.getElementById(parentId); //document.querySelector("#contentContainer");

    var xPosition =
      e.clientX -
      container.getBoundingClientRect().left -
      element.clientWidth / 2;
    var yPosition =
      e.clientY -
      container.getBoundingClientRect().top -
      element.clientHeight / 2 -
      115;
    element.style.left = xPosition + "px";
    element.style.top = yPosition + "px";
  };

  toggleHeaderMenu = () => {
    this.setState({
      headerMenu: !this.state.headerMenu
    });
  };

  handleCancelLoad = loadId => {
    document.getElementById(loadId).click();
  };

  handleUpdateLoad = loadId => {
    document.getElementById(loadId).click();
  };

  handleDragabble = (item) => {
    // let data = [...this.state.dragabbleItems]
    // let index = data.findIndex(i => i.jumpId === item.jumpId)
    // if(index === -1){
    //   data.push(item)
    // } else {
    //   data.splice(index, 1)
    // }
    // this.setState({dragabbleItems: data})
  }

  clearDraggableItems = () => {
    this.setState({ dragabbleItems: [] })
  }

  RenderAltGroup = (props) => {

    if (Object.getOwnPropertyNames(this.state.load).length === 0) {
      return <div className="col" style={{ padding: "0px !important" }}></div>;
    }
    else {


      const tandemJumps = props.tandemJumps
        .filter(jump => !jump.isUltra)
        .map((tandemJump, i) => {
          let globalDiscount = 0;
          let discountValue = 0;
          this.state.timeslots.forEach(timeItem => {
            if (timeItem.reservations) {
              timeItem.reservations.forEach(reservation => {
                if (reservation.discountValue && reservation.tandemStudents) {
                  globalDiscount = reservation.discountValue;
                  reservation.tandemStudents.forEach(stud => {
                    if (new Date(stud.reservationTime).toString() == new Date(tandemJump.student.reservationTime).toString() && `${stud._id}` == `${tandemJump.student._id}`) {
                      const groupMemebers = reservation.tandemStudents.reduce((acc, value) => {
                        if (new Date(stud.reservationTime).toString() == new Date(tandemJump.student.reservationTime).toString() && value.groupId === tandemJump.student.studentGroupId) {
                          acc = acc + 1;
                        }
                        return acc;
                      }, 0)
                      discountValue = globalDiscount / groupMemebers;
                    }
                  })
                }
              })
            }
          })
          return (
            <CSSTransition key={i} timeout={500} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderTandemJump
                  history={this.props.history}
                  id={"tandem" + i}
                  key={i}
                  tandemJump={tandemJump}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadNum={this.state.load.loadNum}
                  jumpId={tandemJump.jumpId}
                  jumpType="tandemJump"
                  discountValue={discountValue}
                  price={tandemJump.price}
                  isCompleted={false}
                  handleJumperDrop={this.handleJumperDrop}
                  handleTandemDrop={this.handleTandemDrop}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                // setDragabble={this.handleDragabble}
                // dragabbleItems={loadsStore.getDraggable()}
                // clearItemSelection={this.clearDraggableItems}
                />
              </div>
            </CSSTransition>
          );
        });

      const iadJumps = props.iadJumps.map((iadJump, i) => {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderIadJump
                history={this.props.history}
                id={"Iad" + i}
                key={i}
                iadJump={iadJump}
                loadNum={this.state.load.loadNum}
                jumpId={iadJump.jumpId}
                jumpType="iadJump"
                handleJumperDrop={this.handleJumperDrop}
                handleCreateIad={this.handleCreateIad}
                isCompleted={false}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.props.columnsPerGroup}
                fontSize={this.props.fontSize}
                isInline={this.props.isInline}
              />
            </div>
          </CSSTransition>
        );
      });

      const affJumps = props.affJumps.map((affJump, i) => {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            <div id={i} key={i} className="col-12">
              <RenderAffJump
                history={this.props.history}
                id={"Coach" + i}
                key={i}
                affJump={affJump}
                loadNum={this.state.load.loadNum}
                jumpId={affJump.jumpId}
                jumpType="affJump"
                price={affJump.price}
                handleJumperDrop={this.handleJumperDrop}
                handleCreateAff={this.handleCreateAff}
                isCompleted={false}
                loads={this.props.loads}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.props.columnsPerGroup}
                fontSize={this.props.fontSize}
                isInline={this.props.isInline}
                load={this.state.load}
              // setDragabble={this.handleDragabble}
              // dragabbleItems={this.state.dragabbleItems}
              // clearItemSelection={this.clearDraggableItems}
              />
            </div>
          </CSSTransition>
        );
      });

      const coachJumps = props.coachJumps
        .filter(jump => jump.isWingsuit !== true)
        .map((coachJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderCoachJump
                  id={"Coach" + i}
                  history={this.props.history}
                  key={i}
                  coachJump={coachJump}
                  loadNum={this.state.load.loadNum}
                  jumpId={coachJump.jumpId}
                  jumpType="coachJump"
                  price={coachJump.price}
                  handleJumperDrop={this.handleJumperDrop}
                  handleCoachDrop={this.handleCoachDrop}
                  isCompleted={false}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                // setDragabble={this.handleDragabble}
                // dragabbleItems={this.state.dragabbleItems}
                // clearItemSelection={this.clearDraggableItems}
                />
              </div>
            </CSSTransition>
          );
        });

      const wingsuitCoachJumps = props.coachJumps
        .filter(jump => jump.isWingsuit)
        .map((coachJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderCoachJump
                  id={"Coach" + i}
                  history={this.props.history}
                  key={i}
                  coachJump={coachJump}
                  loadNum={this.state.load.loadNum}
                  jumpId={coachJump.jumpId}
                  jumpType="coachJump"
                  price={coachJump.price}
                  handleJumperDrop={this.handleJumperDrop}
                  handleCoachDrop={this.handleCoachDrop}
                  isCompleted={false}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  load={this.state.load}
                  isInline={this.props.isInline}
                // setDragabble={this.handleDragabble}
                // dragabbleItems={this.state.dragabbleItems}
                // clearItemSelection={this.clearDraggableItems}
                />
              </div>
            </CSSTransition>
          );
        });

      const wingSuitJumps = props.funJumps
        .filter(jump => jump.jumpType === "Wingsuit")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isToShow={this.props.isToShow}
                  isInline={this.props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });

      const crwJumps = props.funJumps
        .filter(jump => jump.jumpType === "CRW")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });

      const tandemPracticeJumps = props.funJumps
        .filter(jump => jump.jumpType === "Tandem Practice")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                  loadsToShow={this.props.loadsToShow}
                />
              </div>
            </CSSTransition>
          );
        });

      const xrwJumps = props.funJumps
        .filter(jump => jump.jumpType === "XRW")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });

      const bellyJumpsSolo = props.funJumps
        .filter(
          jump =>
            jump.jumpType === "Belly" &&
            jump.jumpers.length === 1
        )
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          //alert("rendering belly");
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const bellyJumps = props.funJumps
        .filter(
          jump =>
            jump.jumpType === "Belly" &&
            jump.jumpers.length > 1
        )
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          //alert("rendering belly");
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });
      const hybridJumps = props.funJumps
        .filter(jump => jump.jumpType === "Hybrid")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                />
              </div>
            </CSSTransition>
          );
        });

      const freeflyJumps = props.funJumps
        .filter(jump => jump.jumpType === "Freefly")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });
      const freeflyAndAngleJumps = props.funJumps
        .filter(jump => jump.jumpType === "Freefly" || jump.jumpType === "Angle")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });
      const hopnpops = props.funJumps
        .filter(
          jump =>
            jump.jumpType === "HopNPop" || jump.jumpType === "HopNPop Student"
        )
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const solos = props.funJumps
        .filter(jump => jump.jumpType === "Solo Student")
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const highpulls = props.funJumps
        .filter(jump => jump.jumpType === "High Pull")
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const trackingJumps = props.funJumps
        .filter(jump => jump.jumpType === "Tracking" && !jump.trackingFirstOut)
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  toggleToster={this.toggleToster}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const trackingJumpsFirstOut = props.funJumps
        .filter(jump => jump.jumpType === "Tracking" && jump.trackingFirstOut)
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  toggleToster={this.toggleToster}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const wrangleJumps = props.funJumps
        .filter(jump => jump.jumpType === "WRAngle")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  toggleToster={this.toggleToster}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const angleTrack = props.funJumps
        .filter(jump => jump.jumpType === "Angle")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  toggleToster={this.toggleToster}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      const speed = props.funJumps
        .filter(jump => jump.jumpType === "Speed")
        .sort((a, b) => {
          return a.jumpers.length - b.jumpers.length;
        })
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  toggleToster={this.toggleToster}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });


      const undecidedJumps = props.funJumps
        .filter(jump => jump.jumpType === "Undecided")
        .map((funJump, i) => {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={funJump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={funJump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                />
              </div>
            </CSSTransition>
          );
        });

      var IsEventEnable = this.props.isCompleted ? "none" : "";

      return (
        <React.Fragment>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {crwJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {xrwJumps}
            </TransitionGroup>
          </div>
          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {highpulls}
            </TransitionGroup>
          </div>
          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {wingSuitJumps}
            </TransitionGroup>
          </div>


          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {wingsuitCoachJumps}
            </TransitionGroup>
          </div>



          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {wrangleJumps}
            </TransitionGroup>
          </div>




          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {trackingJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {tandemPracticeJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {tandemJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {iadJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {affJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {solos}
            </TransitionGroup>
          </div>

          {window.ENV?.arizona ?
            <div
              className="row"
              style={{ pointerEvents: IsEventEnable }}
            >
              <TransitionGroup style={{ width: "100%" }}>
                {freeflyAndAngleJumps}
              </TransitionGroup>
            </div>
            :
            <div
              className="row"
              style={{ pointerEvents: IsEventEnable }}
            >
              <TransitionGroup style={{ width: "100%" }}>
                {freeflyJumps}
              </TransitionGroup>
            </div>
          }
          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {hybridJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {bellyJumpsSolo}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {coachJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {bellyJumps}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {trackingJumpsFirstOut}
            </TransitionGroup>
          </div>

          {window.ENV?.arizona !== true ?
            <div
              className="row"
              style={{ pointerEvents: IsEventEnable }}
            >
              <TransitionGroup style={{ width: "100%" }}>
                {angleTrack}
              </TransitionGroup>
            </div>
            : null
          }

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {speed}
            </TransitionGroup>
          </div>

          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {hopnpops}
            </TransitionGroup>
          </div>
          <div
            className="row"
            style={{ pointerEvents: IsEventEnable }}
          >
            <TransitionGroup style={{ width: "100%" }}>
              {undecidedJumps}
            </TransitionGroup>
          </div>
        </React.Fragment>
      )
    }
  }
  isShowButtons = () => {
    if (this.state.role === "school" && !this.props.isRightMenu) {
      return false;
    }
    else {
      return true;
    }
  }

  returnColSize = () => {
    let { loadsToShow } = this.props;
    let screenWidth = window.innerWidth;
    if (this.props.load.isWaitlist) {
      return 'col-md-12';
    }
    if (screenWidth >= 1400) {
      if (loadsToShow >= 5 && loadsToShow <= 6) {
        return 'col-md-2';
      } else if (loadsToShow > 6) {
        return 'adjust-width-' + loadsToShow
      }
      else if (loadsToShow === 4) {
        return 'col-md-3'
      }
      else if (loadsToShow === 3) {
        return 'col-md-4'
      }
      else {
        return 'col-md-6';
      }
    }
    else if (screenWidth >= 992) {
      return 'col-md-4'
    }
    else if (screenWidth >= 500) {
      return 'col-md-6'
    }
    else {
      return 'col-md-10'
    }
  }

  toggleHeaderLoadStatus = (bool) => {
    this.setState({ loadStatusHide: bool })
  }

  toggleFooterClass = (isLoadStatus) => {
    this.setState({ toggleClass: !this.state.toggleClass })
  }

  returnBtnClass = (isLoadStatus) => {
    if (!!isLoadStatus) {
      let loadStatusclass = 'bottom-btns load-status-transition'
      if (this.state.loadStatusHide) {
        loadStatusclass = "bottom-btns load-status-transition-transition"
      }
      return loadStatusclass
    } else {
      let btnclass = 'in-out-transition'
      if (this.state.toggleClass) {
        btnclass = "in-out-transition-out"
      }
      return btnclass
    }
  }


  render() {
    var colsize = this.returnColSize();
    console.log(this.props.loadIndx, "loadIndex")

    if (Object.getOwnPropertyNames(this.state.load).length === 0) {
      return <div className="col" style={{ padding: "0px !important" }}></div>;
    }
    else {
      //console.log(this.props.load);
      var pilotOptions = jumpersStore.getPilotOptions().filter(
        option =>
          this.state.load?.activeJumperIds?.findIndex(
            id => option.value._id === id
          ) === -1
      );

      var jumperOptions = jumpersStore.getJumperOptions().filter(
        option =>
          this.state.load?.activeJumperIds?.findIndex(
            id => option.value._id === id
          ) === -1
      );
      const alljumps = this.state.load?.alljumps?.map((jump, i) => {
        console.log('rendering all jumps');
        console.log(jump);
        if (jump.superType === "tandem") {
          return (
            <CSSTransition key={i} timeout={500} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderTandemJump
                  history={this.props.history}
                  id={"tandem" + i}
                  key={i}
                  tandemJump={jump}
                  load={this.state.load}
                  loads={this.props.loads}
                  loadNum={this.state.load.loadNum}
                  jumpId={jump.jumpId}
                  callRequest={jump.callRequest}
                  schoolWaitlist={true}
                  jumpType="tandemJump"
                  discountValue={0}
                  price={jump.price}
                  isCompleted={false}
                  handleJumperDrop={this.handleJumperDrop}
                  handleTandemDrop={this.handleTandemDrop}
                  isRightMenu={this.props.isRightMenu}
                  isCenter={this.props.isCenter}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                // setDragabble={this.handleDragabble}
                // dragabbleItems={loadsStore.getDraggable()}
                // clearItemSelection={this.clearDraggableItems}
                />
              </div>
            </CSSTransition>
          );
        }
        else if (jump.superType == "student") {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderAffJump
                  history={this.props.history}
                  id={"Coach" + i}
                  key={i}
                  affJump={jump}
                  loadNum={this.state.load.loadNum}
                  load={this.state.load}
                  jumpId={jump.jumpId}
                  callRequest={jump.callRequest}
                  schoolWaitlist={true}
                  jumpType="affJump"
                  price={jump.price}
                  handleJumperDrop={this.handleJumperDrop}
                  handleCreateAff={this.handleCreateAff}
                  isCompleted={false}
                  loads={this.props.loads}
                  isRightMenu={this.props.isRightMenu}
                  isCenter={this.props.isCenter}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                // setDragabble={this.handleDragabble}
                // dragabbleItems={this.state.dragabbleItems}
                // clearItemSelection={this.clearDraggableItems}
                />
              </div>
            </CSSTransition>
          );
        }
        else if (jump.superType === "coach") {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderCoachJump
                  id={"Coach" + i}
                  history={this.props.history}
                  key={i}
                  coachJump={jump}
                  loadNum={this.state.load.loadNum}
                  jumpId={jump.jumpId}
                  callRequest={jump.callRequest}
                  schoolWaitlist={true}
                  jumpType="coachJump"
                  price={jump.price}
                  handleJumperDrop={this.handleJumperDrop}
                  handleCoachDrop={this.handleCoachDrop}
                  isCompleted={false}
                  loads={this.props.loads}
                  isRightMenu={this.props.isRightMenu}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  load={this.state.load}
                  isInline={this.props.isInline}
                />
              </div>
            </CSSTransition>
          );
        }
        else {
          return (
            <CSSTransition key={i} timeout={700} classNames="fade">
              <div id={i} key={i} className="col-12">
                <RenderFunJump
                  id={"wing" + i}
                  key={i}
                  funJump={jump}
                  loadNum={this.state.load.loadNum}
                  disabled={this.state.load.closed}
                  jumpId={jump.jumpId}
                  jumpType="funJump"
                  handleRemoveFun={this.handleRemoveFun}
                  handleCreateFun={this.handleCreateFun}
                  loads={this.props.loads}
                  columnsPerGroup={this.props.columnsPerGroup}
                  fontSize={this.props.fontSize}
                  isInline={this.props.isInline}
                  load={this.state.load}
                  loadsToShow={this.props.loadsToShow}
                />
              </div>
            </CSSTransition>
          )
        }
      })


      const allFunJumps = this.state.load?.funJumps?.map((funJump, i) => {
        return (
          <CSSTransition key={i} timeout={700} classNames="fade">
            {/* <div key={i} className="col-12 ">
                          <this.RenderFunJump funJump={funJump} loadNum={this.state.load.loadNum} disabled={this.state.load.closed} jumpId={funJump.jumpId} jumperOptions={jumperOptions} />
                      </div> */}
            <div id={i} key={i} className="col-12">
              <RenderFunJump
                iscompleted={this.props.isCompleted}
                id={"wing" + i}
                key={i}
                funJump={funJump}
                loadNum={this.state.load.loadNum}
                disabled={this.state.load.closed}
                jumpId={funJump.jumpId}
                jumpType="funJump"
                jumperOptions={jumperOptions}
                loads={this.props.loads}
                loadsToShow={this.props.loadsToShow}
                columnsPerGroup={this.props.columnsPerGroup}
                // handleDrop={(data) => this.handleJumperDrop(data.loadNum, data.jumpId, "funJump")}
                handleRemoveFun={this.handleRemoveFun}
                handleCreateFun={this.handleCreateFun}
                isClosed={funJump.isClosed}
                fontSize={this.props.fontSize}
                isInline={this.props.isInline}
              />
            </div>
          </CSSTransition>
        );
      });

      var allStaffJumps = [];

      let staffJumps = []
      if (this.props.isCenter) {
        let data = this.state.waitlistLoad
        let load1 = { ...data }
        let isMore = data?.staffJumps?.length
        let load2 = {}
        if (isMore > 10) {
          load1.staffJumps = data?.staffJumps?.slice(0, 10)
          load2 = { ...data, staffJumps: data?.staffJumps?.slice(10, isMore.length) }
        }
        if (this.props.waitlistIndex === 1) {
          staffJumps = load1.staffJumps ? load1.staffJumps : this.props.load.staffJumps
        }
        if (this.props.waitlistIndex === 2) {
          staffJumps = load2.staffJumps ? load2.staffJumps : this.props.load.staffJumps
        }
      }

      if (staffJumps?.length > 0 || this.props.load.staffJumps) {
        if ((this.props.waitlistIndex === 2 && staffJumps.length === 1) || !this.props.isCenter) {
          staffJumps = this.props.load.staffJumps
        }
        allStaffJumps = staffJumps?.map((jump, i) => {
          if (jump.tandemPackageName !== undefined && jump.tandemPackageName !== null && jump.tandemPackageName !== "") {
            return (
              <CSSTransition key={i} timeout={500} classNames="fade">
                <div id={i} key={i} className="col-12">
                  <RenderTandemJump
                    history={this.props.history}
                    id={"tandem" + i}
                    key={i}
                    tandemJump={jump}
                    load={this.state.load}
                    loads={this.props.loads}
                    loadNum={this.state.load.loadNum}
                    jumpId={jump.jumpId}
                    callRequest={jump.callRequest}
                    schoolWaitlist={true}
                    jumpType="tandemJump"
                    discountValue={0}
                    price={jump.price}
                    isCompleted={false}
                    handleJumperDrop={this.handleJumperDrop}
                    handleTandemDrop={this.handleTandemDrop}
                    isRightMenu={this.props.isRightMenu}
                    isCenter={this.props.isCenter}
                    loadsToShow={this.props.loadsToShow}
                    columnsPerGroup={this.props.columnsPerGroup}
                    fontSize={this.props.fontSize}
                    isInline={this.props.isInline}
                  // setDragabble={this.handleDragabble}
                  // dragabbleItems={loadsStore.getDraggable()}
                  // clearItemSelection={this.clearDraggableItems}
                  />
                </div>
              </CSSTransition>
            );
          }
          else if (jump.studentPackageName !== undefined && jump.studentPackageName !== null && jump.studentPackageName !== "") {
            return (
              <CSSTransition key={i} timeout={700} classNames="fade">
                <div id={i} key={i} className="col-12">
                  <RenderAffJump
                    history={this.props.history}
                    id={"Coach" + i}
                    key={i}
                    affJump={jump}
                    loadNum={this.state.load.loadNum}
                    load={this.state.load}
                    jumpId={jump.jumpId}
                    callRequest={jump.callRequest}
                    schoolWaitlist={true}
                    jumpType="affJump"
                    price={jump.price}
                    handleJumperDrop={this.handleJumperDrop}
                    handleCreateAff={this.handleCreateAff}
                    isCompleted={false}
                    loads={this.props.loads}
                    isRightMenu={this.props.isRightMenu}
                    isCenter={this.props.isCenter}
                    loadsToShow={this.props.loadsToShow}
                    columnsPerGroup={this.props.columnsPerGroup}
                    fontSize={this.props.fontSize}
                    isInline={this.props.isInline}
                  // setDragabble={this.handleDragabble}
                  // dragabbleItems={this.state.dragabbleItems}
                  // clearItemSelection={this.clearDraggableItems}
                  />
                </div>
              </CSSTransition>
            );
          }
          else {
            return (
              <CSSTransition key={i} timeout={700} classNames="fade">
                <div id={i} key={i} className="col-12">
                  <RenderCoachJump
                    id={"Coach" + i}
                    history={this.props.history}
                    key={i}
                    coachJump={jump}
                    loadNum={this.state.load.loadNum}
                    jumpId={jump.jumpId}
                    callRequest={jump.callRequest}
                    schoolWaitlist={true}
                    jumpType="coachJump"
                    price={jump.price}
                    handleJumperDrop={this.handleJumperDrop}
                    handleCoachDrop={this.handleCoachDrop}
                    isCompleted={false}
                    loads={this.props.loads}
                    isRightMenu={this.props.isRightMenu}
                    loadsToShow={this.props.loadsToShow}
                    columnsPerGroup={this.props.columnsPerGroup}
                    fontSize={this.props.fontSize}
                    load={this.state.load}
                    isInline={this.props.isInline}
                  />
                </div>
              </CSSTransition>
            );
          }
        });
      }


      var altitudes = itemStore.getAltitudes();

      var jumpsByAltitude = altitudes.map(altitude => {

        var tandemJumps = this.state.load?.tandemJumps?.filter(jump => altitude.name === jump.altitude);
        var iadJumps = this.state.load?.iadJumps?.filter(jump => altitude.name === jump.altitude);
        var affJumps = this.state.load?.affJumps?.filter(jump => altitude.name === jump.altitude);
        var coachJumps = this.state.load?.coachJumps?.filter(jump => altitude.name === jump.altitude);
        var funJumps = this.state.load?.funJumps?.filter(jump => altitude.name === jump.altitude);
        return (
          <this.RenderAltGroup tandemJumps={tandemJumps} iadJumps={iadJumps} affJumps={affJumps} coachJumps={coachJumps} funJumps={funJumps} />
        )


      })

      var backgroundColor = "#e7f2f6";
      var backgroundColorHeader = "#1C6CFF";
      var backgroundColorFooter = "#18191D";


      if (this.state.load.closed) {
        backgroundColor = "#a4abad";
        backgroundColorHeader = "#1C6BCF";
        backgroundColorFooter = "#1C6BCF";
      }

      if (this.state.load.isWaitlist) {
        backgroundColor = "#4422ee";
      }

      if (this.state.load.groupId !== undefined) {
        backgroundColor = "#a4abda";
      }

      const { connectDropTarget } = this.props;
      var IsEventEnable = this.props.isCompleted ? "none" : "";
      var uid = "load" + this.state.load.loadNum;

      var loadClass = "";
      var loadFooterClass = "centerDivFooter";

      if (this.state.load.gearUp) {
        loadClass += "fe-pulse";
      }

      var jumpPanelHeight = "700px";

      var isfull = this.state.load.totalJumpers >= this.state.load.capacity;
      var loadNumPrint =
        this.state.load.loadNum > 0 ? this.state.load.loadNum : "";

      var tandemPackages = itemStore.getTandemPackages();
      var studentPackages = itemStore.getStudentPackages();
      let innercard = 'innercard'

      return connectDropTarget(
        // <div></div>
        <div
          key={this.state.load.loadNum}
          className={"mainContainerHeight " + colsize + " load-" + this.state.load.loadNum}
          style={{ padding: "0px !important", position: 'relative' }}
          id={"Load_" + this.state.load.loadNum + "_Report"}
        >
          <ReactCSSTransitionGroup
            transitionName="load-manger"
            transitionEnterTimeout={500}
            transitionAppear={true}
            transitionAppearTimeout={1000}
            transitionLeave={true}
            transitionLeaveTimeout={1000}
          >
            <div
              className={"btn-overflow"}
              // style={{ overflowY: 'clip', overflowX: 'visible'}}
              onContextMenu={e =>
                this.handleClick(
                  e,
                  uid,
                  "Load_" + this.state.load.loadNum + "_Report"
                )
              }
            >
              {!this.props.isRightMenu && <EditableTimer
                isPaused={this.state.load.isPaused}
                timeRemaining={this.state.load.timeRemaining}
                loadNum={this.state.load.loadNum}
              />}
              <div
                className="centerCards"
                style={{ background: backgroundColor, marginTop: "4%" }}
                id={"centercard" + this.state.load.loadNum}
              >
                <LoadHeader
                  loadObj={this.state.load}
                  isRightMenu={this.props.isRightMenu}
                  isCompleted={this.props.isCompleted}
                  loadsToShow={this.props.loadsToShow}
                  columnsPerGroup={this.props.columnsPerGroup}
                  toggleHeaderLoadStatus={this.toggleHeaderLoadStatus}
                  closed={this.props.load.closed}
                  isDisplayPlaneNameLeft={this.props.isDisplayPlaneNameLeft}
                // isWaitlist={}
                />
                <div style={{ fontSize: this.props.fontSize }}>
                  <CancelLoad loadNum={this.state.load.loadNum} />
                  <LoadCapacity loadNum={this.state.load.loadNum} />
                  <LoadPrice loadNum={this.state.load.loadNum} />
                  <MultiPlane loadNum={this.state.load.loadNum} />

                  <div className="row">
                    <div className="col-12">
                      <div
                        className="react-contextmenu pastepanel"
                        id={uid}
                        style={{
                          position: "relative",
                          display: "none",
                          zIndex: 99999,
                          marginBottom: "-36px",
                          width: "68px"
                        }}
                        onClick={e =>
                          this.handlePasteClick(e, this.state.load.loadNum, uid)
                        }
                      >
                        <span className="react-contextmenu-item">Paste Jump</span>
                      </div>
                    </div>
                  </div>

                  {/* <div style={{ height: jumpPanelHeight, overflowY: 'auto', overflowX: 'hidden' }} id={"innercard" + this.state.load.loadNum}> */}

                  <div
                    style={{ overflowY: "auto", overflowX: "hidden" }}
                    id={innercard + this.state.load.loadNum}
                  >

                    {this.state.load.loadNum === 0 ?
                      <div className="row">
                        <TransitionGroup style={{ width: "100%" }}>
                          {allStaffJumps}
                        </TransitionGroup>
                      </div> : null
                    }

                    {this.state.load.loadNum === -1 ?
                      <div className="row">
                        <TransitionGroup style={{ width: "100%" }}>
                          {allFunJumps}
                        </TransitionGroup>
                      </div> : null
                    }

                    {this.state.load.loadNum > 0 ?
                      <React.Fragment>
                        <div className="row" style={{ pointerEvents: IsEventEnable }}>
                          <TransitionGroup style={{ width: '100%' }}>
                            <CSSTransition
                              key={0}
                              timeout={500}
                              classNames="fade"
                            >
                              <div id={0} key={0} className="col-12">

                                <RenderPilotCard
                                  history={this.props.history}
                                  id={"pilot" + 0}
                                  key={0}
                                  load={this.state.load}
                                  pilot={this.state.load.pilot}
                                  pilotPay={this.state.load.pilotPay}
                                  loadNum={this.state.load.loadNum}
                                  pilotOptions={pilotOptions}
                                  jumpType="pilot"
                                  price={this.state.load.pilotPay}
                                  isCompleted={false}
                                  disabled={this.state.load.closed}
                                  handleJumperDrop={this.handleJumperDrop}
                                  handleTandemDrop={this.handleTandemDrop}
                                  loadsToShow={this.props.loadsToShow}
                                  columnsPerGroup={this.props.columnsPerGroup}
                                  fontSize={this.props.fontSize}
                                  isInline={this.props.isInline}
                                />
                              </div>
                            </CSSTransition>
                          </TransitionGroup>
                        </div>

                        <div
                          className="row"
                          style={{ pointerEvents: IsEventEnable }}
                        >
                          <TransitionGroup style={{ width: "100%" }}>
                            {alljumps}
                          </TransitionGroup>
                        </div>

                      </React.Fragment> : null
                    }

                    <CreateFunJumpCard
                      load={this.state.load}
                      activeJumperIdsOnLoad={this.state.load.activeJumperIds}
                      disabled={this.state.load.closed}
                      loadsToShow={this.props.loadsToShow}
                      loadNum={this.state.load.loadNum}
                      jumperOptions={jumperOptions}
                      ref={this.inputRef}
                    />
                    <CreateTeamJumpCard
                      load={this.state.load}
                      teamsOptions={this.state.teamsOptions}
                      teams={this.state.teams}
                      disabled={this.state.load.closed}
                      loadsToShow={this.props.loadsToShow}
                      loadNum={this.state.load.loadNum}
                      ref={this.teamInputRef}
                    />
                  </div>
                </div>
              </div>


              {(!this.props.isCompleted || this.props.isHistory) && this.isShowButtons() ? (
                <div
                  className="centerDivFooter"
                  style={{
                    fontSize: this.props.fontSize,
                    background: backgroundColorFooter,
                    background: "rgb(24, 25, 29)",
                    position: "relative",
                    padding: "5px 0 0"
                  }}
                >
                  {this.props.loadsToShow > 3 &&
                    <div className="" style={{ position: 'relative', zIndex: 15, padding: 5, background: 'rgb(24, 25, 29)' }}>
                      <button onClick={() => this.toggleFooterClass()}>
                        <i className="fa fa-bars" />
                      </button>
                    </div>}
                  <div
                    className={this.props.loadsToShow > 3 ? this.returnBtnClass() : ""}
                  >
                    {this.state.load.loadNum === 0 ?
                      <React.Fragment>
                        {window.ENV?.arizona !== true ?
                          <CreateJumpBtn
                            text="COACH JUMP"
                            disabled={this.props.disabled}
                            createJump={() => this.createCoachJump()}
                          />
                          : null
                        }

                        <CreateStudentField
                          packagesList={studentPackages}
                          createJump={this.createAffJump}
                          type="AFF"
                          disabled={this.props.disabled}
                          loadsToShow={this.props.loadsToShow}
                        />
                        <CreateTandemField
                          packagesList={tandemPackages}
                          createJump={this.createTandemJump}
                          disabled={this.props.disabled}
                          loadsToShow={this.props.loadsToShow}
                        />
                        <CreateJumpBtn
                          text="TANDEM WITH VIDEO"
                          disabled={this.props.disabled}
                          createJump={() => this.createTandemJumpWithVideo()}
                        />
                      </React.Fragment> :
                      <React.Fragment>
                        <CreateJumpBtn
                          text="FUN JUMP"
                          type="funjump"
                          disabled={this.props.disabled}
                          createJump={(v) => this.createFunJump(v)}
                          loadNum={this.state.load.loadNum}
                        />
                        {window.ENV?.arizona || window.ENV?.spaceland || window.ENV?.enableTeamFeature ?
                          <CreateJumpBtn
                            text="TEAM"
                            disabled={this.props.disabled}
                            createJump={(v) => this.createTeamJump(v)}
                            loadNum={this.state.load.loadNum}
                          /> : true

                        }

                        {window.ENV?.arizona !== true ?
                          <React.Fragment>
                            <CreateJumpBtn
                              text="COACH JUMP"
                              disabled={this.props.disabled}
                              createJump={() => this.createCoachJump()}
                            />
                            <CreateJumpBtn
                              text="WINGSUIT JUMP"
                              disabled={this.props.disabled}
                              createJump={() => this.createWingsuitCoachJump()}
                            /></React.Fragment> : null
                        }

                        <CreateStudentField
                          packagesList={studentPackages}
                          createJump={this.createAffJump}
                          type="AFF"
                          disabled={this.props.disabled}
                          loadsToShow={this.props.loadsToShow}
                        />
                        <CreateStudentField
                          packagesList={studentPackages}
                          createJump={this.createIadJump}
                          type="IAD"
                          disabled={this.props.disabled}
                          loadsToShow={this.props.loadsToShow}
                        />
                        <CreateTandemField
                          packagesList={tandemPackages}
                          createJump={this.createTandemJump}
                          disabled={this.props.disabled}
                          loadsToShow={this.props.loadsToShow}
                        />
                        <CreateJumpBtn
                          text="TANDEM WITH VIDEO"
                          disabled={this.props.disabled}
                          createJump={() => this.createTandemJumpWithVideo()}
                        />
                      </React.Fragment>
                    }
                  </div>

                </div>
              ) : (
                <div
                  className="centerDivFooter"
                  style={{ background: backgroundColorFooter, height: "20px" }}
                ></div>
              )}
            </div>

            {window.ENV?.arizona && !this.props.load.isWaitlist ?
              <div className="bottom-btns" style={{ padding: 5, fontSize: this.props.fontSize }} >
                <ButtonGroup>
                  <Button onClick={() => this.setPAstatus(0)} style={{ padding: 2, }} active={this.state.load.pastatus === 0}>no PA call</Button>
                  <Button onClick={() => this.setPAstatus(1)} style={{ padding: 2, }} active={this.state.load.pastatus === 1}>15 min</Button>
                  <Button onClick={() => this.setPAstatus(2)} style={{ padding: 2, }} active={this.state.load.pastatus === 2}>10 min</Button>
                  <Button onClick={() => this.setPAstatus(3)} style={{ padding: 2, }} active={this.state.load.pastatus === 3}>5 min</Button>
                </ButtonGroup>
              </div>
              : null
            }

          </ReactCSSTransitionGroup>
        </div>
      );
    }
  }
}

export default DropTarget("JUMP", loadTarget, collect)(Load);
