/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm } from 'react-redux-form';

import itemStore from '../../../stores/item-store';
import AdminModal from './customModal';

class CreateAltitude extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        }
    }

    toggleModal = (e) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }
    handleSubmit = (values) => {
        // console.log("in handle submit load"+ values);
        this.toggleModal();
        var payload = {};
        if (this.props.isEdit && values !== undefined) {
            payload.name = values.name;
            payload.startAltitude = Number(values.startAltitude);
            payload.endAltitude = Number(values.endAltitude);
            payload.price = Number(values.price);
            payload.isDefault = values.isDefault ? true : false;
            // console.log(payload)
            payload.itemId = this.props.item._id;
            itemStore.updateAltitude(payload);
        }
        else if (values != undefined) {
            payload.name = values.name;
            payload.startAltitude = values.startAltitude;
            payload.price = Number(values.price);
            payload.endAltitude = Number(values.endAltitude);
            payload.isDefault = values.isDefault ? true : false;
            // console.log(payload)
            itemStore.createAltitude(payload);
        }
    }

    render() {
        let item = { name: "", startAltitude: "", price: "", endAltitude: "", isDefault: false };

        if (this.props.item !== undefined) {
            item = this.props.item;
        }

        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={this.props.isEdit ? "Edit item" : "Create Altitudes"}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>Name</Label>
                            <Col md={9}>
                                <Control.input
                                    name="name"
                                    model=".name"
                                    className="form-control"
                                    required
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.name} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="startAltitude" md={3}>Start Altitudes</Label>
                            <Col md={9}>
                                <Control.input
                                    name="startAltitude"
                                    model=".startAltitude"
                                    type="number"
                                    className="form-control"
                                    required
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.startAltitude} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="endAltitude" md={3}>End Altitudes</Label>
                            <Col md={9}>
                                <Control.input
                                    name="endAltitude"
                                    model=".endAltitude"
                                    className="form-control"
                                    required
                                    type="number"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.endAltitude} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price"
                                    required
                                    model=".price" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={item.price} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="default" md={3}>Default</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox
                                    name="default"
                                    model=".isDefault"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isDefault}
                                    autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>

                {!this.props.isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }

            </React.Fragment>
        );
    }
}

export default CreateAltitude
