import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonLast } from 'pure-react-carousel';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux'
import io from 'socket.io-client'
import { Col, Row, } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, } from 'reactstrap';

import 'pure-react-carousel/dist/react-carousel.es.css';
import '../../components/css/App.css';
import './style/custom.css';
import background from '../images/background.svg';
import arrow from '../images/arrow-carousel.png';

import Load from './LoadComponent';
import { LoadH } from './LoadHistoryComponent';
import logo from '../images/logo.svg';
import loadsStore from '../../stores/loads-store';
import jumpersStore from '../../stores/jumpers-store';
import authStore from '../../stores/auth-store';
import LeftMenu from '../menu/LeftMenu1';
import LoadManagerRight from './LoadManagerRight';
import HamburgerMenu from 'react-hamburger-menu';
import { getLoadHistory } from '../../actions/reports'
import ErrorModal from '../sub-components/Modal/ErrorModal';
import DonutLoader from './DonutLoader';
import Settings from './subComponents/NavHamburger/NavContainer';
import '../css/change-loads.css';
import { ToastContainer, toast } from 'react-toastify';


const VISIBLE_ICON = 'fa fa-chevron-right';
const NOTVISIBLE_ICON = 'fa fa-chevron-left';
class LoadPage extends Component {
  constructor(props) {
    super(props);
    this.loadSettings = {}

    if (localStorage.getItem('jumperDetails')) {
      const { columnsPerGroup, fontSize, loadsPerPage, isInline, isDisplayPlaneNameLeft } = JSON.parse(localStorage.getItem('jumperDetails'))
      //alert('laod manager center settings update');
      this.loadSettings = { columnsPerGroup, fontSize, loadsPerPage, isInline, isDisplayPlaneNameLeft }
    }

    this.initialState = {

      emailNotification: false,
      emailNotificationMsg: "",
      loads: [],
      completedLoads: [],//this.props.completedLoads !== undefined ? this.props.completedLoads : [],
      numCompletedSlides: 0,

      numActiveSlides: 1,
      tandemInstructorOptions: [],
      videographerOptions: [],
      affInstructorOptions: [],
      affStudentOptions: [],
      jumperOptions: [],
      tandemStudentOptions: [],
      coachOptions: [],
      staffOptions: [],

      totalSlides: 0,
      width: window.innerWidth,
      isloading: true,
      isCreated: false,
      isAlert: false,
      jumperAlert: false,
      jumpTypeAlert: false,
      affLevelAlert: false,
      jumpAmountAlert: false,
      planeAlert: false,

      tandemWalkinWaiverSubmitAlert: false,
      affWalkinWaiverSubmitAlert: false,
      jumperWaiverSubmitAlert: false,
      walkinName: "",
      waiverAlert: [],
      uspaAlert: [],
      warning: false,
      usingWaitlist: false,
      rightMenuVisibile: true,
      loadsPerPage: this.loadSettings.loadsPerPage,
      columnsPerGroup: this.loadSettings.columnsPerGroup,
      fontSize: this.loadSettings.fontSize,
      isToShow: false,
      isInline: this.loadSettings.isInline,
      waitlistLoad: null,
      currentSlide: 0,
      isDisplayPlaneNameLeft: this.loadSettings.isDisplayPlaneNameLeft

    }

    this.state = this.initialState;

    this.updatePlaneActivity = this.updatePlaneActivity.bind(this);
    // list all events that needs to be listend to
    this.loadEvents = [
      // { name: loadsStore.getEventPrefix('getWaitlists'), func: this.getWaitlists },
      { name: loadsStore.getEventPrefix('updateLoad_0'), func: this.handleWaitlistUpdate },
      { name: loadsStore.getEventPrefix('PLANE_NUM_UPDATE'), func: this.handleNumPlaneUpdate },
      { name: loadsStore.getEventPrefix('createLoad'), func: this.handleNewLoad },
      { name: loadsStore.getEventPrefix('completeLoadReopen'), func: this.handleReopen },
      { name: loadsStore.getEventPrefix('updateLoad'), func: this.handleUpdateLoad },
      { name: loadsStore.getEventPrefix('updateLoad2'), func: this.handleUpdateLoad2 },
      { name: loadsStore.getEventPrefix('completeLoad'), func: this.handleCompleteLoad },
      { name: loadsStore.getEventPrefix('getExistingLoads'), func: this.handleExistingLoads },
      { name: loadsStore.getEventPrefix('resetDay'), func: this.handleResetDay },
      { name: loadsStore.getEventPrefix('updateCompletedLoads'), func: this.handleUpdateCompletedLoads },
      { name: loadsStore.getEventPrefix('clearSession'), func: this.handleSession },
      { name: loadsStore.getEventPrefix('completeJumperActivity'), func: this.handleCompleteJumper },
      //{ name: loadsStore.getEventPrefix('updateJumpType'), func: this.handleUpdateJumpType },
      //{ name: loadsStore.getEventPrefix('updateAffLevel'), func: this.handleAffLevel },
      // { name: loadsStore.getEventPrefix('loadPriceEvent'), func: this.handleJumpAmount },
      { name: loadsStore.getEventPrefix('updatePlaneActivity'), func: this.updatePlaneActivity },
      { name: loadsStore.getEventPrefix('warningEvent'), func: this.handleWarning },
      { name: loadsStore.getEventPrefix('InitilizeLoader'), func: this.InilizeLoader },
      { name: loadsStore.getEventPrefix('updateAllLoads'), func: this.handleUpdateAllLoads },
    ];
    this.jumperEvents = [

      { name: jumpersStore.getEventPrefix('clearSession'), func: this.handleSession },
      { name: jumpersStore.getEventPrefix('warningEvent'), func: this.handleWarning },
      { name: jumpersStore.getEventPrefix('InitilizeLoader'), func: this.InilizeLoader },
      { name: jumpersStore.getEventPrefix('walkinAffWaiverSubmit'), func: this.handleWalkinAffWaiverSubmit },
      { name: jumpersStore.getEventPrefix('walkinTandemWaiverSubmit'), func: this.handleWalkinTandemWaiverSubmit },
      { name: jumpersStore.getEventPrefix('licensedWaiverSubmit'), func: this.handleJumperWaiverSubmit },
    ];
    // this.authEvents = [
    //   { name: authStore.getEventPrefix('settings'), func: this.onSettingRes }

    // ]
  }

  componentDidMount() {
    console.log(' load manager center componentDidMount');

    //alert(' load manager center componentDidMount');
    let body = document.querySelector('body');
    body.style.overflow = 'auto';
    //////console.log('in componentDidmount in load manager center');

    this.NscreenWidth = window.innerWidth;
    // socket.on('changeTandemStudentEmail', payload => {
    //   ////console.log("336 payload", payload)
    //   if (payload) {
    //     this.setState({
    //       emailNotification: true,
    //       emailNotificationMsg: payload
    //     })
    //   }
    // })

    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
    // this.authEvents.forEach((evnt) => authStore.on(evnt.name, evnt.func));

    window.addEventListener('resize', this.handleWindowSizeChange);




    /*
    if (window.globals && window.globals['loadMangerState']) {
      console.log('set state load manager state')
      this.setState({ ...window.globals['loadMangerState'] });
    }*/

    var jumperOptions = jumpersStore.getJumperOptions();
    var tandemInstructorOptions = jumpersStore.getTandemInstructorCheckedInOptions();
    var affInstructorOptions = jumpersStore.getAffInstructorCheckedInOptions();
    var affStudentOptions = jumpersStore.getAffStudentOptions();
    var iadStudentOptions = jumpersStore.getIadStudentOptions();
    var tandemStudentOptions = jumpersStore.getTandemStudentOptions();
    var videographerOptions = jumpersStore.getVideographerCheckedInOptions();
    var coachOptions = jumpersStore.getCoachCheckedInOptions();
    var staffOptions = jumpersStore.getStaffOptions();
    //var activeLoads = loadsStore.getActiveLoadsForDisplay();

    this.setState({
      tandemInstructorOptions: tandemInstructorOptions,

      videographerOptions: videographerOptions,

      affInstructorOptions: affInstructorOptions,

      affStudentOptions: affStudentOptions,

      iadStudentOptions: iadStudentOptions,

      jumperOptions: jumperOptions,

      tandemStudentOptions: tandemStudentOptions,

      coachOptions: coachOptions,

      staffOptions: staffOptions,
    });
    this.setState({ isloading: true });

    if (jumpersStore.jumpersAreLoaded() && jumpersStore.tandemStudentsAreLoaded()) {
      loadsStore.loadAllExistingLoads()
        .then(loads => {
          this.setState({ isloading: false, loads: loads });
        })
        .catch(err => console.log(err));
    }
    this.setState({
      isAlert: false
    })
  }

  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps');
    loadsStore.refreshExistingLoads();

    //this.setState(nextProps);
  }
  componentWillMount = () => {
    //alert('component Will Mount');

    console.log('componentWillMount');

    var loads = loadsStore.getActiveLoadsForDisplay();


    this.setState({
      loads: loads,
      isAlert: false,
      jumperAlert: false,
      jumpTypeAlert: false,
      affLevelAlert: false,
      jumpAmountAlert: false,
      planeAlert: false,
    }, () => {
      // ////console.log("sms hide in didd mount")
    })
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.setState({
      isloading: true
    })
    // this.getCompleteLoads();
  }

  componentWillUnmount = async () => {
    console.log('console Will Unmount');
    // ////console.log('in componentWillUnmount in load manager center');
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
    //this.authEvents.forEach((evnt) => authStore.removeListener(evnt.name, evnt.func));

    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.setState(this.initialState);
    await this.setState({ isAlert: false }, () => {
      return true
    })
  }

  handleStaffLoaded = (staffOptions) => {
    console.log('handle staff loaded');
    this.handleStaffStatusUpdate();
    this.setState({
      staffOptions: staffOptions
    });
  }

  handleWaitlistUpdate = (waitlist) => {
    if (waitlist.staffJumps.length === 11 || waitlist.staffJumps.length === 10) {
      this.setState({ waitlistLoad: waitlist })
    }
  }

  // getWaitlists = (data) => {
  //   if(!this.state.waitlistLoad || this.state.waitlistLoad.length === 0){
  //     this.setState({waitlistLoad: data.schoolWaitlist})
  //   }
  // }

  handleStaffStatusUpdate = () => {
    console.log('handle staff status update');
    var jumperOptions = jumpersStore.getJumperOptions();
    var tandemInstructorOptions = jumpersStore.getTandemInstructorCheckedInOptions();
    var affInstructorOptions = jumpersStore.getAffInstructorCheckedInOptions();
    var affStudentOptions = jumpersStore.getAffStudentOptions();
    var iadStudentOptions = jumpersStore.getIadStudentOptions();
    var tandemStudentOptions = jumpersStore.getTandemStudentOptions();
    var videographerOptions = jumpersStore.getVideographerCheckedInOptions();
    var coachOptions = jumpersStore.getCoachCheckedInOptions();
    var staffOptions = jumpersStore.getStaffOptions();
    //var activeLoads = loadsStore.getActiveLoadsForDisplay();
    //console.log('handle staff status pdate');
    //console.log(tandemInstructorOptions);
    /*
    this.setState({
      tandemInstructorOptions: tandemInstructorOptions,

      videographerOptions: videographerOptions,

      affInstructorOptions: affInstructorOptions,

      affStudentOptions: affStudentOptions,

      iadStudentOptions: iadStudentOptions,

      jumperOptions: jumperOptions,

      tandemStudentOptions: tandemStudentOptions,

      coachOptions: coachOptions,

      staffOptions: staffOptions
    });
    */
  }

  handleWalkinTandemWaiverSubmit = (data) => {

    toast.success(`Tandem waiver submitted for ${data.name}`)

  }

  handleWalkinAffWaiverSubmit = (data) => {

    toast.success(`${window.ENV?.isSTP ? "STP" : "AFF"} waiver submitted for ${data.name}`)

    // this.setState({
    //   affWalkinWaiverSubmitAlert: true,
    //   walkinName: data.name,
    // });

    // setTimeout(() => {
    //   this.setState({ affWalkinWaiverSubmitAlert: false });
    // }, 2000)
  }

  handleJumperWaiverSubmit = (data) => {

    toast.success(`Jumper waiver submitted for ${this.state.walkinName}`)
    // this.setState({
    //   jumperWaiverSubmitAlert: true,
    //   walkinName: data.name,
    // });

    // setTimeout(() => {
    //   this.setState({ jumperWaiverSubmitAlert: false });
    // }, 2000)
  }


  handleTandemStudentCreated = (data) => {
    console.log('handleTandemStudentCreated')

    ////console.log('in handleTandemStudentCreated');
    ////console.log(data);
    if (data) {
      ////console.log('tandem student created');
      var tandemStudents = jumpersStore.getTandemStudentOptions();
      ////console.log(tandemStudents);
      this.setState({ tandemStudentOptions: tandemStudents });
    }
  }

  handleTandemStudentsLoaded = (tandemStudentsOptions) => {
    console.log('handleTandemStudentsLoaded')

    this.setState({
      tandemStudentOptions: tandemStudentsOptions,
      // isloading: false
    });
  }

  handleTandemInstructorsLoaded = (tandemInstructorOptions) => {
    console.log('handleTandemInstructorsLoaded')

    var tandemInstructorOptions = jumpersStore.getTandemInstructorCheckedInOptions();

    this.setState({
      tandemInstructorOptions: tandemInstructorOptions,
      // isloading: false
    });
  }

  handleSession = (data) => {
    console.log('handleSession')

    this.setState(this.initialState);
    this.props.history.push('/login');
  }
  // onSettingRes = (data) => {
  //   console.log("🚀 ~ file: LoadManagerCenter.js ~ line 342 ~ LoadPage ~ data", data)
  //   let { loadsPerPage, columnsPerGroup } = data.settings;
  //   this.setState({ loadsPerPage, columnsPerGroup })
  // }
  handleAffInstructorsLoaded = (affInstructorOptions) => {
    console.log('handleAffInstructorsLoaded')

    var affInstructorOptions = jumpersStore.getAffInstructorCheckedInOptions();

    this.setState({
      affInstructorOptions: affInstructorOptions
    });
  }

  handleAffStudentsLoaded = (affStudentOptions) => {
    console.log('handleAffStudentsLoaded')

    this.setState({
      affStudentOptions: affStudentOptions
    });
  }

  handleIadStudentsLoaded = (iadStudentOptions) => {
    console.log('handleIadStudentsLoaded')

    this.setState({
      iadStudentOptions: iadStudentOptions
    });
  }

  handleVideographersLoaded = (videographerOptions) => {
    console.log('handleVideographersLoaded')

    var videographerOptions = jumpersStore.getVideographerCheckedInOptions();

    this.setState({
      videographerOptions: videographerOptions
    });
  }

  handleCoachesLoaded = (coachOptions) => {
    console.log('handleCoachesLoaded')

    var coachOptions = jumpersStore.getCoachCheckedInOptions();

    this.setState({
      coachOptions: coachOptions
    })
  }

  handleJumpersLoaded = (jumperOptions) => {
    console.log('handleJumpersLoaded')

    this.setState({
      jumperOptions: jumperOptions
    });
  }

  componentDidUpdate = () => {
    //alert('did upate');
    console.log('componentDidUpdate');
    //var loads = loadsStore.getActiveLoadsForDisplay();
    //this.setState({loads: loads})
    window.globals = { ...window.globals };
    window.globals.loadMangerState = this.state;
  }

  handleNewLoad = (loads, cancel) => {
    console.log('handleNewLoad');

    loadsStore.isLoadCreated = false;
    var numActiveSlides = 1;
    if (loads.length > 0) {
      numActiveSlides = Math.ceil(loads.length / this.state.loadsPerPage);
    }
    console.log('number active slides');
    console.log(numActiveSlides);
    if (loads.length === 0 || (this.state.loads.length === 0 && loads.length == 1) || numActiveSlides !== this.state.numActiveSlides) {
      //alert('updating slide numbers: numslide: ' + numActiveSlides);
      //alert('updating num active slides: ' + numActiveSlides);
      this.setState({
        loads: loads,
        numActiveSlides
      });
    }
    //this.handleCurrentSlide('new')
    /*
    if(!cancel){
      this.handleSlides('new');
    }*/
  }

  handleCurrentSlide = (key) => {
    let currentSlide = 0
    if (this.props?.match?.path === '/load-manager') {
      if (key === 'new') {
        currentSlide = (Math.ceil(this.state.completedLoads.length / this.state.loadsPerPage) + Math.ceil(this.state.loads.length / this.state.loadsPerPage) - 1);
      } else if (key === 'completed') {
        currentSlide = Math.ceil(this.state.completedLoads.length / this.state.loadsPerPage - 1);
      } else if (key === 'reopen') {
        currentSlide = 0
      }
    }
    this.setState({ currentSlide: currentSlide })
  }

  handleUpdateCompletedLoads = (loads) => {
    console.log('handleUpdateCompletedLoads');

    ////console.log("loads 353", loads)
    loadsStore.isLoadCreated = false;
    this.setState({ completedLoads: loads });
    //this.handleSlides('new');
  }

  // getCompleteLoads = () => {
  //   var eventToday = new Date();
  //   var startDatea = new Date(eventToday.setHours(0, 0, 0, 0));
  //   var endDatea = new Date(eventToday.setHours(23, 59, 59, 999));
  //   const that = this
  //   Promise.all([loadsStore.getCompletedLoad(startDatea, endDatea)])
  //     .then(function (result) {
  //       if (result[0] != false) {
  //         that.props.updateStore(result[0])
  //       }
  //       else {
  //         that.setState({
  //           completedLoadsHistory: []
  //         })
  //       }
  //     })
  // }

  handleExistingLoads = (loads) => {
    console.log('handleExistingLoads');

    // ////console.log("handleExistingLoads",loads)
    var usingWaitlist = false;
    var numActiveSlides = 1;
    if (loads.activeLoads.length > 0) {
      numActiveSlides = Math.ceil(loads.activeLoads.length / this.state.loadsPerPage);
    }
    var numCompletedSlides = Math.ceil(loads.completedLoads.length / this.state.loadsPerPage);
    if (loads.completedLoads.length > 12 && this.props?.match?.path === '/load-manager') {
      var numCompletedSlides = Math.ceil(12 / this.state.loadsPerPage);
    }

    if (this.props?.match?.path === '/load-manager') {
      this.setState({ loads: loads.activeLoads, completedLoads: loads.completedLoads, usingWaitlist: usingWaitlist, numActiveSlides, numCompletedSlides, isloading: false });
    } else {
      this.setState({ loads: [], completedLoads: loads.completedLoads, usingWaitlist: usingWaitlist, numActiveSlides, numCompletedSlides, isloading: false });
    }

    this.handleSlides();
    //this.getCompleteLoads();
    this.sortLoads(loads.activeLoads);
    this.props.updateStore(loads.completedLoads)
  }

  handleUpdateAllLoads = (loads) => {
    console.log('handleUpdateAllLoads');

    ////console.log('handle update all loads');
    ////console.log(loads);
    this.sortLoads(loads);
  }

  handleUpdateLoad2 = (loads) => {
    console.log('handleUpdateLoad2');

    ////console.log(loads);
    this.sortLoads(loads);
  }

  handleReopen = (data) => {
    this.props.updateStore(data.completedLoads)
    var newLoads = data.activeLoads;
    var numActiveSlides = 1;
    if (newLoads.length > 0) {
      numActiveSlides = Math.ceil(newLoads.length / this.state.loadsPerPage)
    }
    var numCompletedSlides = Math.ceil(data.completedLoads.length / this.state.loadsPerPage);
    if (this.props?.match?.path === '/load-manager' && data.completedLoads.length > 12) {
      numCompletedSlides = Math.ceil(12 / this.state.loadsPerPage)
    }
    let CompletedLoads = (this.props?.match?.path === '/load-manager') ? data.completedLoads.slice(-12) : data.completedLoads;
    this.setState({
      completedLoads: CompletedLoads,
      completedLoadNum: data.load.loadNum,
      loads: (this.props?.match?.path === '/load-manager') ? data.activeLoads : [],
      isloading: false,
      iscompleted: true,
      numCompletedSlides,
      numActiveSlides,
    });

    /*
    setTimeout(() => {
      this.setState({ isAlert: false });
    }, 2000)

    this.handleCurrentSlide('reopen')*/
    this.handleStaffStatusUpdate();
  }

  handleUpdateLoad = (load) => {
    console.log("handle Update Load in load manager center", load);
    var newLoads = this.state.loads.slice();
    let loadIndex = newLoads.findIndex(l => l.loadNum === load.loadNum);
    newLoads[loadIndex] = load;
    console.log(newLoads);
    this.sortLoads(newLoads);
  }

  handleResetDay = (data) => {
    console.log('handleResetDay');

    if (this.props?.match?.path === '/load-manager') {
      this.setState({ loads: data.loads });
    }
  }


  handleWarning = () => {
    console.log('handleWarning');

  }

  InilizeLoader = (value) => {
    console.log('InilizeLoader');

    /*
    this.setState({
      isloading: value
    })*/
  }

  sortLoads = (newLoads) => {

    /*
      var planeNames = newLoads.map(ld => ld.plane);

      var uniquePlaneNames = [... new Set(planeNames)];

      ////console.log(uniquePlaneNames);

      var loadsByPlane = {};
      var sizes = [];
      uniquePlaneNames.forEach(plane => {
        loadsByPlane[plane] = newLoads.filter(ld => ((ld.groupId === undefined && ld.plane === plane) || (ld.groupId !== undefined && ld.masterPlane === plane))).sort((a, b) => a.loadNum - b.loadNum);
        sizes.push(loadsByPlane[plane].length);
      });

      ////console.log(sizes);

      ////console.log(Math.max(...sizes));

      ////console.log(loadsByPlane);

      var sortedLoads2 = [];

      for (var i = 0; i < Math.max(...sizes); i++) {
        for (var j = 0; j < uniquePlaneNames.length; j++) {
          if (i === 0) {
            loadsByPlane[uniquePlaneNames[j]][i].isNext = true;
          }

          if (i < loadsByPlane[uniquePlaneNames[j]].length) {
            if (i > 0 && loadsByPlane[uniquePlaneNames[j]][i].groupId == undefined)
              loadsByPlane[uniquePlaneNames[j]][i].isNext = false;

            sortedLoads2.push(loadsByPlane[uniquePlaneNames[j]][i]);
          }
        }
      }*/
    console.log("sortLoads");
    if (this.props?.match?.path === '/load-manager') {
      this.setState({ loads: newLoads });
    }
  }

  handleCompleteLoad = (data) => {
    console.log("handleCompleteLoad load manager center");
    //this.props.updateStore(data.completedLoads)
    var newLoads = data?.activeLoads;
    var numActiveSlides = 1;
    if (newLoads.length > 0) {
      numActiveSlides = Math.ceil(newLoads?.length / this.state.loadsPerPage)
    }
    var numCompletedSlides = Math.ceil(data.completedLoads?.length / this.state.loadsPerPage);
    if (this.props?.match?.path === '/load-manager' && data.completedLoads.length > 12) {
      numCompletedSlides = Math.ceil(12 / this.state.loadsPerPage)
    }
    if ((numCompletedSlides !== this.state.numCompletedSlides) || (numActiveSlides !== this.state.numActiveSlides) || (data.activeLoads.length === 0)) {
      let CompletedLoads = (this.props?.match?.path === '/load-manager') ? data.completedLoads.slice(-12) : data.completedLoads;
      console.log(CompletedLoads);
      console.log('numCompletedSlides: ' + numCompletedSlides);
      console.log('numActiveSlides: ' + numActiveSlides);
      this.setState({
        completedLoads: CompletedLoads,
        completedLoadNum: data.load.loadNum,
        loads: (this.props?.match?.path === '/load-manager') ? data.activeLoads : [],
        isloading: false,
        iscompleted: true,
        numCompletedSlides,
        numActiveSlides,
      });

      //this.forceUpdate();

      /*
      setTimeout(() => {
        this.setState({ isAlert: false });
      }, 2000)
      //this.handleSlides();
      this.handleCurrentSlide('completed')*/
    }
    // this.sortLoads(newLoads);
    //console.log('handleCompleteLoad before staff options reload');
    this.handleStaffStatusUpdate();

  }


  checkExpiry = (activeID) => {
    var waiverExpiry = [];
    var uspaExpiry = [];
    for (var item in activeID) {
      let jumperInfo = jumpersStore.getJumperById(activeID[item]);
      var date = new Date();
      if (jumperInfo.isStaff) {
        if (jumperInfo.uspaExpiry !== undefined) {
          var uspaDate = new Date(jumperInfo.uspaExpiry);
          var uspaResult = date.valueOf() > uspaDate.valueOf();
          if (uspaResult) {
            uspaExpiry.push(jumperInfo.firstname + ' ' + jumperInfo.lastname);
          }
        }

        if (jumperInfo.waiverDate !== undefined) {
          var waiverDate = new Date(jumperInfo.waiverDate);
          var waiverResult = date.valueOf() > waiverDate.valueOf();
          if (waiverResult) {
            waiverExpiry.push(jumperInfo.firstname + ' ' + jumperInfo.lastname);
          }
        }
      }
    }
    var resultArray = { "Uspa": uspaExpiry, "Waiver": waiverExpiry };
    return resultArray;
  }

  handleSlides = (type = '') => {
    if (this.NscreenWidth >= 2000 && this.NscreenWidth <= 2599) {
      this.setState(prevState => ({
        totalSlides: Math.ceil(prevState.loads.length / 3)
      }));
      if (type === 'new' && this.state.loads.length > 3 && document.getElementsByClassName("carousel-last-btn")[0]) document.getElementsByClassName("carousel-last-btn")[0].click();
    }
    else if (this.NscreenWidth >= 1366 && this.NscreenWidth <= 1999) {
      this.setState(prevState => ({
        totalSlides: Math.ceil(prevState.loads.length / 2)
      }));
      if (type === 'new' && this.state.loads.length > 2 && document.getElementsByClassName("carousel-last-btn")[0]) document.getElementsByClassName("carousel-last-btn")[0].click();
    }
  }

  handleWindowSizeChange = () => {
    console.log("handleWindowSizeChange");
    loadsStore.refreshExistingLoads();
    //this.setState({ width: window.innerWidth });
  };

  handleClick = () => {
    console.log("handleClick");

    this.setState({
      open: !this.state.open
    });
  }

  handleRightClick = () => {
    console.log("handleRightClick");

    this.setState({
      rightOpen: !this.state.rightOpen
    });
  }


  handleCompleteJumper = () => {
    console.log("handleCompleteJumper");

    this.setState({
      jumperAlert: true
    })
    setTimeout(() => {
      this.setState({ jumperAlert: false });
    }, 2000)
  }

  handleUpdateJumpType = () => {
    console.log("handleUpdateJumpType");

    this.setState({
      jumpTypeAlert: true
    })
    setTimeout(() => {
      this.setState({ jumpTypeAlert: false });
    }, 2000)
  }

  handleAffLevel = () => {
    console.log("handleAffLevel");

    this.setState({
      affLevelAlert: true
    })
    setTimeout(() => {
      this.setState({ affLevelAlert: false });
    }, 2000)
  }

  updatePlaneActivity = () => {
    console.log("updatePlaneActivity");

    this.setState({
      planeAlert: true
    },
      () => {
        setTimeout(() => {
          this.setState({ planeAlert: false });
        }, 2000)
      }
    )
  }

  loadCreated = (value) => {
    console.log("loadCreated");

    this.setState({
      isCreated: value
    })
  }
  handleNumPlaneUpdate = ({ planeBody }) => {
    console.log('handleNumPlaneUpdate');
    //this.setState({ totalSlides: planeBody.numPlanes })
  };

  evaluateCurrentAndTotalLoads = (screenWidth, completedLoadsToShow) => {

    let totalLoads = 0;
    let currentSlide = 0;

    if (screenWidth >= 2000 && screenWidth <= 2599) {
      totalLoads = Math.ceil(completedLoadsToShow.length / 3) + Math.ceil((this.state.loads.length) / 3);
      currentSlide = Math.floor(completedLoadsToShow.length / 3);

    } else if (screenWidth >= 1366 && screenWidth <= 1999) {
      // //console.log('completed slides: ', Math.ceil(completedLoadsToShow.length / 2));
      // //console.log('active slides: ', Math.ceil((this.state.loads.length) / 2));

      totalLoads = Math.ceil(completedLoadsToShow.length / 2) + Math.ceil((this.state.loads.length) / 2);
      currentSlide = Math.floor(completedLoadsToShow.length / 2);
    }

    if (this.props?.match?.path === '/load-manager' && this.state.loads.length === 0) {
      totalLoads = totalLoads + 1;
    }

    // //console.log('totalLoads, ' + totalLoads);
    // //console.log('currentSlide, ' + currentSlide);
    return { currentSlide, totalLoads };
  }

  getSettingsData = (payload, isToShow) => {
    //alert('update settings: ' + JSON.stringify(payload,null,2));
    var loads = loadsStore.getActiveLoadsForDisplay();

    var numActiveSlides = 1;
    if (this.state.loads.length > 0) {
      numActiveSlides = Math.ceil(this.state.loads.length / payload.loadsPerPage);
    }
    var numCompletedSlides = Math.ceil(this.state.completedLoads.length / payload.loadsPerPage);
    if (this.state.completedLoads.length > 12) {
      numCompletedSlides = Math.ceil(12 / payload.loadsPerPage);
    }

    this.setState({ ...payload, numActiveSlides, numCompletedSlides })
    this.setState({ isToShow })
    loadsStore.refreshExistingLoads();

  }
  isInlineMode = (bool) => {
    this.setState({ isInline: bool })
  }

  displayPlaneNameLeftMode = (bool) => {
    this.setState({ isDisplayPlaneNameLeft: bool })

    console.log(this.state.isDisplayPlaneNameLeft, "<<<<<<<<<<<<isDisplayPlaneNameLeft")
  }


  renderSlides = (loads, numSlides, screenWidth, iscompleted) => {
    console.log('in rendering slides');
    let { loadsPerPage, columnsPerGroup } = this.state;

    var activeSlides = [];
    let numLoadsPerSlide = 1;

    if (screenWidth >= 1400) {
      numLoadsPerSlide = loadsPerPage;
    }
    else if (screenWidth >= 992) {
      numLoadsPerSlide = 3;
    }
    else if (screenWidth >= 500) {
      numLoadsPerSlide = 2;
    }

    var numarrays = Array.from(Array(numLoadsPerSlide).keys());

    var SettingsClasses = `row show-loads-${numLoadsPerSlide}` + " " + `columngroup-${columnsPerGroup}`;
    for (var index = 0; index < numSlides; index += 1) {
      var nextLoad = '';
      var key = new Date();
      for (var i = 0; i < numarrays.length; i++) {
        if (loads.length && loads[index * numLoadsPerSlide + numarrays[i]]) {
          key = loads[index * numLoadsPerSlide + numarrays[i]].key;
          break;
        }
      }

      if (loads.length > 0) {
        activeSlides.push(
          <Slide index={index} key={key} >
            <div className={`${SettingsClasses}`} style={{ width: '100%', margin: 'auto', position: 'relative' }}>
              {
                numarrays.map(num => {
                  var loadIndx = index * numLoadsPerSlide + num;
                  if (iscompleted) {
                    loadIndx = -(loadIndx + 1)
                  }
                  if (iscompleted) {
                    return (
                      <LoadH
                        rightMenuVisibile={this.state.rightMenuVisibile}
                        history={this.props.history}
                        isCompleted={true}
                        isHistory={false}
                        isToday={true}
                        // load={completedLoadsToShow[index]}
                        load={loads[index * numLoadsPerSlide + num] ? loads[index * numLoadsPerSlide + num] : {}}
                        loadNum={loads[index * numLoadsPerSlide + num] ? loads[index * numLoadsPerSlide + num].loadNum : null}
                        key={loads[index * numLoadsPerSlide + num] ? loads[index * numLoadsPerSlide + num].key : index * numLoadsPerSlide + num}
                        loadIndx={loadIndx}
                        loads={this.state.loads}
                        jumperOptions={this.state.jumperOptions}
                        tandemInstructorOptions={this.state.tandemInstructorOptions}
                        tandemStudentOptions={this.state.tandemStudentOptions}
                        affInstructorOptions={this.state.affInstructorOptions}
                        affStudentOptions={this.state.affStudentOptions}
                        iadStudentOptions={this.state.iadStudentOptions}
                        videographerOptions={this.state.affInstructorOptions}
                        coachOptions={this.state.coachOptions}
                        loadsToShow={this.state.loadsPerPage}
                        columnsPerGroup={this.state.columnsPerGroup}
                        fontSize={this.state.fontSize}
                        isInline={this.state.isInline}
                        isDisplayPlaneNameLeft={this.state.isDisplayPlaneNameLeft}
                      />
                    )
                  }
                  return (
                    <Load
                      key={loads[index * numLoadsPerSlide + num] ? loads[index * numLoadsPerSlide + num].key : index * numLoadsPerSlide + num}
                      history={this.props.history}
                      isCompleted={iscompleted}
                      isHistory={false}
                      // load={this.state.loads[index]}
                      load={loads[index * numLoadsPerSlide + num] ? loads[index * numLoadsPerSlide + num] : {}}
                      loadNum={loads[index * numLoadsPerSlide + num] ? loads[index * numLoadsPerSlide + num].loadNum : null}
                      loads={this.state.loads}
                      loadIndx={loadIndx}
                      jumperOptions={this.state.jumperOptions}
                      tandemInstructorOptions={this.state.tandemInstructorOptions}
                      tandemStudentOptions={this.state.tandemStudentOptions}
                      affInstructorOptions={this.state.affInstructorOptions}
                      affStudentOptions={this.state.affStudentOptions}
                      iadStudentOptions={this.state.iadStudentOptions}
                      videographerOptions={this.state.videographerOptions}
                      coachOptions={this.state.coachOptions}
                      loadsToShow={this.state.loadsPerPage}
                      columnsPerGroup={this.state.columnsPerGroup}
                      fontSize={this.state.fontSize}
                      isInline={this.state.isInline}
                      isDisplayPlaneNameLeft={this.state.isDisplayPlaneNameLeft}
                    // isRightMenu={true}
                    />
                  );

                })
              }
            </div>
          </Slide>
        )
      }

    }

    return activeSlides;
  }

  renderLoad = (load, key) => {
    return <div style={{ width: '50%', position: 'relative' }}>
      <Load
        waitlistIndex={key}
        history={this.props.history}
        isHistory={false}
        loadNum={load.loadNum}
        load={load}
        loads={this.state.loads}
        jumperOptions={this.state.jumperOptions}
        tandemInstructorOptions={this.state.tandemInstructorOptions}
        tandemStudentOptions={this.state.tandemStudentOptions}
        affInstructorOptions={this.state.affInstructorOptions}
        affStudentOptions={this.state.affStudentOptions}
        iadStudentOptions={this.state.iadStudentOptions}
        videographerOptions={this.state.videographerOptions}
        coachOptions={this.state.coachOptions}
        loadsToShow={1}
        columnsPerGroup={4}
        isInline={this.state.isInline}
        isRightMenu={true}
        isCenter={true}
      />
    </div>
  }

  renderWaitlist = (apiData) => {
    // let apiData = loads
    if (apiData && this.state.isloading) {
      this.setState({ isloading: false })
    }
    let load1 = { ...apiData }
    let isMore = apiData?.staffJumps?.length
    let load2 = {}
    if (isMore > 10) {
      load1.staffJumps = apiData?.staffJumps?.slice(0, 10)
      load2 = { ...apiData, staffJumps: apiData?.staffJumps?.slice(10, isMore.length) }
    }
    var activeSlides = [];
    activeSlides.push(
      <Slide index={0} key={1} >
        <div className="tabContentCon" style={{ height: '100vh', display: 'flex' }}>
          {(isMore <= 10 || isMore > 10) && this.renderLoad(load1, 1)}
          {isMore > 10 && this.renderLoad(load2, 2)}
        </div>
      </Slide>
    )
    return activeSlides;
  }
  currentSlide = (length) => {
    if (this.props?.match?.path === '/load-manager') {
      return length ? length : 0
    } else {
      return length ? length - 1 : 0
    }
  }
  render() {
    console.log('IN RENDER LOAD MANAGER CENTER *****');
    var screenWidth = window.innerWidth;
    const { width, warning, loadsPerPage, columnsPerGroup, fontSize, loads, completedLoads } = this.state;
    var completedLength = 0;
    const settingsObj = { loadsPerPage, columnsPerGroup, fontSize }
    let numLoadsPerSlide = 1;

    var totalActiveSlides = 1;


    if (screenWidth >= 1400) {
      totalActiveSlides = Math.ceil(loads.length / loadsPerPage);
      numLoadsPerSlide = loadsPerPage;
    }
    else if (screenWidth >= 992) {
      totalActiveSlides = Math.ceil(loads.length / 3);
      numLoadsPerSlide = 3;
    }
    else if (screenWidth >= 500) {
      totalActiveSlides = Math.ceil(loads.length / 2);
      numLoadsPerSlide = 2;
    }

    if (totalActiveSlides === 0) totalActiveSlides = 1;




    // var SettingsClasses = 'show-load-' + loadsPerPage + " columngroup-" + columnsPerGroup;
    const isMobile = width <= 500;
    const override = ` display: block;  margin: 0 auto; border-color: red;`;
    var allLoads = [];

    if (this.state.loads.length > 0) {
      this.state.loads.forEach(element => {
        allLoads.push(element);
      })
    }

    if (this.state.completedLoads.length > 0) {
      completedLength = this.state.completedLoads.length;
      this.state.completedLoads.forEach(element => {
        allLoads.push(element);
      })
    }

    allLoads.sort(function (a, b) {
      return a.loadNum - b.loadNum
    })

    var completedLoadsToShow = [];
    if (this.props.completedLoads.length > 12 && this.props?.match?.path === '/load-manager') {
      completedLoadsToShow = [...this.props.completedLoads.slice(this.props.completedLoads.length - 12, this.props.completedLoads.length)]
    }
    else {
      completedLoadsToShow = [...this.props.completedLoads];
    }
    completedLength = completedLoadsToShow.length

    // const { currentSlide, totalLoads } = this.evaluateCurrentAndTotalLoads(screenWidth, completedLoadsToShow);
    // var { currentSlide,  } = this.evaluateCurrentAndTotalLoads(screenWidth, completedLoadsToShow);

    //Active Slides

    var totalActiveSlides = 1;
    var totalCompletedSlides = 0;
    var totalSlides;
    var numMaxCompletedSlides = 0;
    if (screenWidth >= 1400) {
      totalActiveSlides = Math.ceil(loads.length / loadsPerPage);
      totalCompletedSlides = Math.ceil(completedLoads.length / loadsPerPage);
      numMaxCompletedSlides = Math.ceil(12 / loadsPerPage);
    }
    else if (screenWidth >= 992) {
      totalActiveSlides = Math.ceil(loads.length / 3);
      totalCompletedSlides = Math.ceil(completedLoads.length / 3);
      numMaxCompletedSlides = 4;
    }
    else if (screenWidth >= 500) {
      totalActiveSlides = Math.ceil(loads.length / 2);
      totalCompletedSlides = Math.ceil(completedLoads.length / 2);
      numMaxCompletedSlides = 4;
    }

    if (totalActiveSlides === 0) totalActiveSlides = 1;

    // totalSlides = numMaxCompletedSlides + totalActiveSlides;
    totalSlides = totalCompletedSlides + totalActiveSlides;


    var currentSlide = 0;

    var loadIndex = loads.map(x => x.loadNum).indexOf(loadsStore.activeloadNum);

    if (/*loadsStore.displayOnly*/ this.props?.match?.path === '/completed-loads') {
      if (screenWidth >= 1400) {
        currentSlide = Math.ceil(completedLoads.length / loadsPerPage);
      }
      else if (screenWidth >= 992) {
        currentSlide = Math.ceil(completedLoads.length / 3)
      }
      else if (screenWidth >= 500) {
        currentSlide = Math.ceil(completedLoads.length / 2);
      }
    }
    else {
      if (screenWidth >= 1400) {
        if (loadIndex === -1) {
          currentSlide = (Math.ceil(completedLoads.length / loadsPerPage) + Math.ceil(loads.length / loadsPerPage));
          // console.log("🚀 ~ file: LoadManagerCenter.js ~ line 884 ~ render ~ currentSlide", currentSlide)

        }
        else {
          loadIndex = loadIndex + 1;
          currentSlide = (Math.ceil(completedLoads.length / loadsPerPage) + Math.ceil(loadIndex / loadsPerPage) - 1);
        }
      }
      else if (screenWidth >= 992) {
        if (loadIndex === -1) {
          currentSlide = (Math.ceil(completedLoads.length / 3) + Math.ceil(loads.length / 3) - 1);
        }
        else {
          loadIndex = loadIndex + 1;
          currentSlide = (Math.ceil(completedLoads.length / 3) + Math.ceil(loadIndex / 3) - 1);
        }
      }
      else if (screenWidth >= 500) {
        if (loadIndex === -1) {
          currentSlide = (Math.ceil(completedLoads.length / 2) + Math.ceil(loads.length / 2) - 1);
        }
        else {
          loadIndex = loadIndex + 1;
          currentSlide = (Math.ceil(completedLoads.length / 2) + Math.ceil(loadIndex / 2) - 1);
        }
      }
    }

    // if (loads.length === 0 && completedLoads.length === 0 && this.state.isloading) {
    //   return (
    //     <div>
    //       <h1 style={{ color: 'white', marginTop: '0px', marginLeft: '10px' }}>
    //         No Loads Active
    //                 </h1>
    //       <DonutLoader isLoading={this.state.isloading} addClass={'inloadmanager'} />
    //     </div>
    //   );
    // }
    var role = localStorage.getItem("role");
    let completedSlides = [];
    let activeSlides = []
    console.log('about to render load manager center');
    console.log("num completed slides: " + this.state.numCompletedSlides);
    console.log("num active Slides: " + this.state.numActiveSlides);
    if (this.props.match.path === '/load-manager') {
      let slides = this.state.completedLoads.length > 12
        ?
        this.renderSlides(this.state.completedLoads.slice(this.state.completedLoads.length - 12, this.state.completedLoads.length), this.state.numCompletedSlides, screenWidth, true)
        :
        this.renderSlides(this.state.completedLoads, this.state.numCompletedSlides, screenWidth, true)
      completedSlides = slides
      activeSlides = this.renderSlides(this.state.loads, this.state.numActiveSlides, screenWidth, false)
      totalSlides = this.state.numActiveSlides + this.state.numCompletedSlides;
    } else if (this.props.match.path === '/waitlist') {
      let waitlistData = loadsStore.getSchoolWaitlist()
      completedSlides = this.renderWaitlist(waitlistData)
      totalSlides = 1
    } else if (this.props?.match?.path === '/completed-loads') {
      completedSlides = this.renderSlides(this.state.completedLoads, this.state.numCompletedSlides, screenWidth, true)
      totalSlides = this.state.numCompletedSlides;
    }

    // let completedSlides = [];
    // let activeSlides = []
    // if (this.props?.match?.path === '/load-manager' && role !== 'video edit') {
    //   let ifmorethan12 = completedLoads.length > 12 ? completedLoads.slice(completedLoads.length - 12, completedLoads.length) : completedLoads
    //   let completedNo = completedLoads.length > 12 ? numMaxCompletedSlides : totalCompletedSlides
    //   completedSlides = this.renderSlides(ifmorethan12, completedNo, screenWidth, true);
    //   activeSlides = this.renderSlides(this.state.loads, totalActiveSlides, screenWidth, false)
    //   totalSlides = completedSlides.length + totalActiveSlides;
    // }
    // else if(this.props?.match?.path === '/completed-loads' ){
    //   completedSlides = this.renderSlides(this.state.completedLoads, totalCompletedSlides, screenWidth, true)
    //   totalSlides = completedSlides.length;
    // } else if(this.props.match.path === '/waitlist'){
    //   completedSlides = this.renderWaitlist(this.state.waitlistLoad)
    //   totalSlides = 1
    // }

    //Ends here
    let styledPad = ''
    let reduceArrorBtnHeight = {}
    let btnPad = ''
    if (this.state.loadsPerPage > 3) {
    }

    if (this.state.loadsPerPage > 3 && this.state.loadsPerPage < 6) {
      styledPad = 'padd-middle-container-till-4-5'
      btnPad = 'corosal-btn-padding-till-4-5'
    }
    else if (this.state.loadsPerPage > 5) {
      reduceArrorBtnHeight.height = 14
      styledPad = 'padd-middle-container'
      btnPad = 'corosal-btn-padding'
    }
    else {
      reduceArrorBtnHeight = {}
      styledPad = 'regular-padd-middle-container'
    }

    let leftContainer = 'left-container'
    let rightCol = 'rightCol'
    let middleContainer = 'middle-container'
    return (
      // <div className={`row ${SettingsClasses}`} style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
      <div className={`row`} style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
        {/* <div className='sweet-loading'>
          <ScaleLoader
            height={35}
            width={8}
            margin={'2px'}
            radius={2}
            color={'#123abc'}
            loading={this.state.isloading}
          />

          {this.state.isloading ?
            <span style={{ color: 'white', fontSize: '20px', marginLeft: '-20px ' }}>Please Wait...</span> : ''}
        </div> */}
        <DonutLoader isLoading={this.state.isloading} addClass={'inloadmanager'} />
        {
          isMobile ?
            <div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
              <div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
                <LeftMenu showSettings={true} isInlineMode={this.isInlineMode} isInline={this.state.isInline} getSettingsData={this.getSettingsData} settingsObj={settingsObj} routeHistory={this.props.history} showCreate={true}
                  displayPlaneNameLeftMode={this.displayPlaneNameLeftMode} isDisplayPlaneNameLeft={this.state.isDisplayPlaneNameLeft}
                />
              </div>
            </div>
            :
            // <div className="col-lg-2 col-md-2 col-sm-12 blueBg fullHeightPanes">
            <div className={`blueBg fullHeightPanes ${leftContainer}`}>
              <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block', width: '100%' }} />
              <LeftMenu showSettings={true} isInlineMode={this.isInlineMode} isInline={this.state.isInline} getSettingsData={this.getSettingsData} settingsObj={settingsObj} routeHistory={this.props.history} showCreate={true}
                displayPlaneNameLeftMode={this.displayPlaneNameLeftMode} isDisplayPlaneNameLeft={this.state.isDisplayPlaneNameLeft}
              />
            </div>
        }
        {/* <div className={`${!this.state.rightMenuVisibile ? 'col-lg-10 col-md-10' : 'col-lg-8 col-md-8'} col-sm-12`}> */}
        <div className={`${!this.state.rightMenuVisibile ? 'col-lg-10 col-md-10' : middleContainer}`}>
          {
            isMobile ?
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-12">

                  <div className="hamBurger-menu">
                    <HamburgerMenu
                      isOpen={this.state.open}
                      menuClicked={this.handleClick.bind(this)}
                      width={22}
                      height={17}
                      strokeWidth={1}
                      rotate={0}
                      color='white'
                      borderRadius={0}
                      animationDuration={0.5}
                    />
                  </div>
                  <div className="hamBurger-right">
                    <HamburgerMenu
                      isOpen={this.state.rightOpen}
                      menuClicked={this.handleRightClick.bind(this)}
                      width={22}
                      height={17}
                      strokeWidth={1}
                      rotate={0}
                      color='white'
                      borderRadius={0}
                      animationDuration={0.5}
                    />
                  </div>
                </div>
              </div>
              :
              ''
          }

          {this.props?.match?.path === '/load-manager' && this.state.loads.length == 0 && !this.state.isloading ? <h1 style={{ color: 'white' }}>
            No Loads Active</h1> : ''}
          {this.props?.match?.path === '/completed-loads' && this.state.completedLoads.length == 0 && !this.state.isloading ? <h1 style={{ color: 'white' }}>
            No Completed Loads</h1> : ''}
          {
            this.state.isAlert ?
              <Alert color="success" className="alert-loadcomplete">
                Load Completed Successfully !
              </Alert>
              : ''
          }


          <div className="webShow" style={{ width: '100%', margin: 'auto' }}>
            <CarouselProvider
              key={new Date()}
              // totalSlides={totalLoads}
              totalSlides={totalSlides}
              dragEnabled={false}
              currentSlide={this.currentSlide(completedSlides.length)}
              disableKeyboard={true}
              visibleSlides={!this.state.rightMenuVisibile ? 1.5 : 1}
            >
              <div className={`col-lg-12 ${styledPad}`} style={{}}>
                <div className="dynamic-font-passer-wrapper" style={{ fontSize }}>
                  {
                    allLoads.length > 0 ?
                      <ButtonBack className={`carousel-back-btn btn arrow-left-btn ${btnPad}`}>
                        <img style={reduceArrorBtnHeight} src={arrow} alt="arrowright" />
                      </ButtonBack> : ''
                  }
                </div>
                  <Slider>
                    {completedSlides}
                    {activeSlides}
                  </Slider>
                {
                  (this.props?.match?.path === '/load-manager' ? allLoads.length : this.state.completedLoads.length) > 0 ?
                    <div style={{ fontSize }}>
                      <ButtonNext className={`carousel-next-btn btn arrow-right-btn ${btnPad}`}>
                        <img style={reduceArrorBtnHeight} src={arrow} alt="arrowright" />
                      </ButtonNext>
                      <ButtonLast className="carousel-last-btn btn lightBlueButton hide">Last</ButtonLast>
                    </div>
                    :
                    ''
                }
              </div>
            </CarouselProvider>
          </div>
          {
            isMobile ?
              <div className="mobileShow" style={{ width: '80%', margin: 'auto' }}>
                <CarouselProvider
                  totalSlides={allLoads.length}
                  dragEnabled={false}
                  currentSlide={this.state.completedLoads.length}
                >
                  <div className="col-lg-12">
                    {
                      this.state.loads.length > 0 ?
                        <ButtonBack className={`carousel-back-btn btn arrow-left-btn`}>
                          <img src={arrow} alt="arrowleft" />
                        </ButtonBack>
                        : ''
                    }
                    <Slider>

                      {
                        this.state.completedLoads.length > 0 && false && this.state.completedLoads.map((loads, index) => {
                          return (
                            <Slide index={index} style={{}} key={index}>
                              <div className="row" style={{ width: '100%', margin: 'auto' }}>
                                {this.state.completedLoads[index] ? <Load history={this.props.history} load={this.state.completedLoads[index]} loads={this.state.loads} jumperOptions={this.state.jumperOptions} tandemInstructorOptions={this.state.tandemInstructorOptions}
                                  tandemStudentOptions={this.state.tandemStudentOptions} affInstructorOptions={this.state.affInstructorOptions} iadStudentOptions={this.state.iadStudentOptions}
                                  affStudentOptions={this.state.affStudentOptions} videographerOptions={this.state.videographerOptions} coachOptions={this.state.coachOptions} iscompleted={true}
                                  isDisplayPlaneNameLeft={this.state.isDisplayPlaneNameLeft}
                                /> : <Load history={this.props.history} load={{}} />}
                              </div>
                            </Slide>
                          )
                        })
                      }

                      {this.state.loads.map((loads, index) => {
                        return (
                          <Slide index={index} style={{}} key={index}>
                            <div className="row" style={{ width: '100%', margin: 'auto' }}>
                              <Load history={this.props.history} key={this.state.loads[index].key} load={this.state.loads[index]} loads={this.state.loads} jumperOptions={this.state.jumperOptions} tandemInstructorOptions={this.state.tandemInstructorOptions}
                                tandemStudentOptions={this.state.tandemStudentOptions} affInstructorOptions={this.state.affInstructorOptions} iadStudentOptions={this.state.iadStudentOptions}
                                affStudentOptions={this.state.affStudentOptions} videographerOptions={this.state.videographerOptions} coachOptions={this.state.coachOptions} iscompleted={false}
                                isDisplayPlaneNameLeft={this.state.isDisplayPlaneNameLeft}
                              />
                            </div>
                          </Slide>
                        )
                      })
                      }
                    </Slider>

                    {
                      this.state.loads.length > 0 ?
                        <div>
                          <ButtonNext className="carousel-next-btn btn arrow-right-btn">
                            <img src={arrow} alt="arrowright" />
                          </ButtonNext>
                          <ButtonLast className="carousel-last-btn btn lightBlueButton hide">Last</ButtonLast>
                        </div>
                        :
                        ''
                    }
                  </div>
                </CarouselProvider>
              </div> : null
          }
        </div>
        {
          isMobile ?
            <div className={` ${this.state.rightOpen ? "right-menu" : "hideSideBar"}`}>
              <div className={`${this.state.rightOpen ? "show-panel" : "hide-Panel"}`}>
                <div className="col-lg-2 col-md-2 col-sm-12 blueBg fullHeightPanes rightCol" style={{ padding: '0px' }}>
                  <LoadManagerRight />
                </div>
              </div>
            </div>
            :
            <div
              className={`blueBg fullHeightPanes ${rightCol} ${(!this.state.rightMenuVisibile ? 'hiddenRightMenuContainer' : '')}`}
              style={{ padding: '0px' }}
            >
              <div onClick={this.toggleMenuVisibility} className="hideShowRightMenu"><i className={this.state.rightMenuVisibile ? VISIBLE_ICON : NOTVISIBLE_ICON}></i></div>
              {this.state.rightMenuVisibile &&
                <LoadManagerRight />
              }
            </div>
        }
        <Modal id="modal-main" isOpen={this.state.emailNotification} >
          <ModalHeader></ModalHeader>
          <ModalBody>
            {this.state.emailNotificationMsg}
            <Row className="form-group m-t-1-rem">
              <Col md={{ size: 10 }}>
                <button onClick={(e) => { e.preventDefault(); this.setState({ emailNotification: false, emailNotificationMsg: '' }); }} className="btn btn-bg lightColor createButton">CLOSE</button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        {warning && <ErrorModal show={warning} />}
        <ToastContainer />
      </div>
    );
  }

  toggleMenuVisibility = () => {
    const { rightMenuVisibile } = this.state;
    this.setState({ rightMenuVisibile: !rightMenuVisibile }, () => this.handleWindowSizeChange());
  }

}

const mapStateToProps = state => {
  return { completedLoads: state.Reports.loadHistory }
}

const mapDispatchToProps = () => dispatch => {
  return {
    updateStore: (data) => dispatch(getLoadHistory(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DragDropContext(HTML5Backend)(withRouter(LoadPage)));
