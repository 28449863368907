/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import reportStore from '../../../stores/report-store';
import axios from 'axios';
import Select from 'react-select';
import CardModal from './customModal';
import PaymentIcon from 'react-payment-icons';
import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type';

import reservationStore from '../../../stores/reservation-store';
import AccountStore from '../../../stores/account-store';

import Api from '../../../services/reservationapi';
import { ClipLoader } from 'react-spinners';
import JumperApi from '../../../services/jumpersapi';

class AddCard extends Component {

    constructor(props) {

        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cardDigit = '';
        this.jumperId = '';
        this.state = {
            isModalOpen: false,
            cardType: '',
            cardNumber: '',
            cardHolder: '',
            expire: '',
            cvv: '',
            selYear: '',
            selMonth: '',
            cardIcon: '',
            isloading: false
        }
    }

    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    handleYearSelect = (event) => this.setState({ selYear: event.target.value.slice(-2) })

    handleMonthSelect = (event) => {
        var month = parseInt(event.target.value);
        if (month > 9) {
            month = month.toString();
        } else {
            month = '0' + month.toString();
        }
        this.setState({ selMonth: month })
    };

    handleSubmit(values, id) {
        //console.log("in handle submit load" + values);
        //console.log(id);
        this.jumperId = id;
        this.cardDigit = this.state.cardNumber;
        var tokenBody = {
            Month: this.state.selMonth,
            year: this.state.selYear,
            Cvv: values.cvv,
            cardNumber: this.state.cardNumber,
            fullName: values.fullName,
            cardType: this.state.cardType
        }
        this.setState({ isloading: true });
        // this.toggleModal();
        // var payload = {};   
        // var body = this.createAuthorizeCaptureBody(tokenBody);
        // var body = JumperApi.createAuthorizeCaptureBody(tokenBody);

        // //console.log(body);
        // var response = Api.authorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), body).then(this.processPaymentResponse);
        var body = tokenBody

        console.log(body);
        var response = Api.authorizeAndCapture("jumper", body).then(this.processPaymentResponse);
    }

    // createAuthorizeCaptureBody = (tokenBody) => {
    //     var amount = "1.00"
    //     var expiry = tokenBody.Month + tokenBody.year;
    //     return {
    //         "$type": "AuthorizeAndCaptureTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
    //         SessionToken: reservationStore.getTokENV?.l(),
    //         ApplicationProfileId: reservationStore.getApplicationProfileId(),
    //         MerchantProfileId: reservationStore.getMerchantProfileId(),
    //         Transaction: {
    //             "$type": "BankcardTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
    //             TenderData: {
    //                 PaymentAccountDataToken: null,
    //                 SecurePaymentAccountData: null,
    //                 CardData: {
    //                     CardType: tokenBody.cardType,//this.state.cardType.toString(), //visa
    //                     CardholderName: tokenBody.fullName,
    //                     PAN: tokenBody.cardNumber,//this.state.cardNumber.toString(), // 4000000000000002
    //                     Expire: expiry,  // 1220
    //                 },
    //                 CardSecurityData: {
    //                     AVSData: {
    //                         CardholderName: tokenBody.fullName,
    //                     },
    //                     CVDataProvided: "Provided",
    //                     CVData: tokenBody.Cvv,
    //                 }
    //             },
    //             TransactionData: {
    //                 Amount: amount,
    //                 CurrencyCode: "USD",
    //                 TransactionDateTime: new Date().toISOString(),
    //                 CustomerPresent: "Ecommerce",
    //                 EmployeeId: "12345",
    //                 EntryMode: "Keyed",
    //                 OrderNumber: "12345",
    //                 SignatureCaptured: false,
    //             }
    //         }
    //     }
    // }

    // cancelAuthorizeCaptureBody = (TransactionId) => {
    // 	return {
    // 		"$type": "Undo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
    // 		"ApplicationProfileId": reservationStore.getApplicationProfileId(),
    // 		"DifferenceData": {
    // 			"$type":
    // 				"BankcardUndo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
    // 			"TransactionId": TransactionId,
    // 			"Addendum": null
    // 		}
    // 	}
    // }

    processPaymentResponse = (r) => {
        //console.log(r);
        if (r.data !== undefined && r.data.Status === "Successful") {
            this.toggleModal();
            this.setState({ isloading: false });
            var id = this.jumperId;
            var cardnum = this.cardDigit;
            var cardNumber = cardnum.substr(cardnum.length - 4);
            var token = r.data.PaymentAccountDataToken;

            var tokenBody = {
                cardNumLast4: cardNumber,
                cardType: this.state.cardType,
                token: token
            }
            jumperStore.addCardOnFile(id, tokenBody);
            var body = {
                TransactionId: r.data.TransactionId
            }

            var response = Api.cancelAuthorizeAndCapture(body).then(this.processUndoResponse);
            // var body = JumperApi.cancelAuthorizeCaptureBody(r.data.TransactionId);
            // //console.log(body);

            // var response = Api.cancelAuthorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), r.data.TransactionId, body).then(this.processUndoResponse);
        }
        else {
            console.log(r);
            alert('something failed, please try again');
        }
    }


    processUndoResponse = (r) => {
        // console.log(r);
        if (r.status !== undefined && r.status === 200) {
            console.log('Refund Successfully');

            var payload = {};
            // alert(jumperId);
            payload.amount = "1";
            payload.transactionType = "Card key in";
            var jumperId = this.jumperId;
            /*
            AccountStore.ChargeJumper(jumperId,payload)
            .then(result => {
                console.log(result);              
            });
            */
        }

        // this.setState({
        //   isloading: false,
        //   isModalOpen: false
        // })
    }

    generateYears = () => {
        var currYear = new Date().getFullYear();
        var yearsArray = [];
        for (let i = 0; i < 20; i++) {
            yearsArray.push(currYear + i);
        }
        return yearsArray;
    }

    handleInput = (event, key) => {
        var data = {};
        if (key === 'cardNumber' && event.target.value % 1 !== 0) {
            data["cardType"] = '';
            this.setState(data);
            return; // since we dont have numeric value
        }

        var cardnumber = event.target.value;
        var cardType = creditCardType(cardnumber);

        // this.cardDigit= event.target.value;
        if (cardnumber != '' && cardType.length > 0) {
            if (cardType.length > 0) {
                if (cardType[0].type == 'american-express') {
                    data["cardType"] = 'AmericanExpress';
                    data["cardIcon"] = 'amex';
                }
                else {
                    data["cardType"] = cardType[0].type;
                    data["cardIcon"] = 'amex';
                }
                if (cardType[0].type == 'visa') {
                    data["cardIcon"] = 'visa';
                }
                if (cardType[0].type == 'mastercard') {
                    data["cardIcon"] = 'mastercard';
                }
                if (cardType[0].type == 'discover') {
                    data["cardIcon"] = 'discover';
                }
            }
        }
        else {
            data["cardType"] = '';
        }

        data[key] = event.target.value;
        this.setState(data);
    };

    render() {
        const override = `
            display: block;
            margin: 0 auto;
            border-color: red;
            `;

        const { _id = '' } = this.props.jumperDetail || {};
        return (
            <React.Fragment>
                <CardModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Card Detail`}>

                    <div className='complete-loading'>
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={35}
                            color={'#fff'}
                            loading={this.state.isloading}
                        />
                    </div>

                    <LocalForm onSubmit={(values) => this.handleSubmit(values, _id)}>

                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>Full name</Label>
                            <Col md={9}>
                                <Control.input name="fullName" model=".fullName" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            {/* <Control.input name="cardNumber" model=".cardNumber" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" /> */}
                            <Label htmlFor="cardNumber" md={3}>Card Number</Label>
                            <Col md={9}>

                                <input
                                    type="text"
                                    className="invoiceFrom form-control"
                                    name="cardNumber"
                                    placeholder=""
                                    onChange={(e) => this.handleInput(e, 'cardNumber')} />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="cardNumber" md={3}>Expiry</Label>

                            <Col md={3}>
                                <select onChange={this.handleMonthSelect} name='month' model=".month" className="invoiceFrom form-control" style={{ width: '100%' }} >
                                    <option>MM</option>
                                    {this.months.map((item, i) => <option key={i} value={(i + 1)}>{item}</option>)}
                                </select>
                            </Col>

                            {/* <Col md={1}> 
                                 <span style={{ width: '10px', 'textAlign': 'center' }} > / </span>
                            </Col> */}

                            <Col md={3}>
                                <select onChange={this.handleYearSelect} name="year" model=".year" className="invoiceFrom form-control" style={{ width: '100%' }} >
                                    <option>YY</option>
                                    <option value="2015">2015</option>
                                    {this.generateYears().map((item) => <option key={item} value={item}>{item}</option>)}
                                </select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="cardNumber" md={3}>Cvv</Label>
                            <Col md={8}>
                                {/* <label className="invoiceFormLabel" data-toggle="tooltip" title="" data-original-title="3 digits code on back side of the card">CVV</label> */}
                                <Control.input name="cvv" model=".cvv" className="form-control" style={{ width: '40%' }}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        {
                            <Row className="form-group">
                                <Col md={8}>

                                    {
                                        this.state.cardType != '' ?
                                            <PaymentIcon
                                                id={this.state.cardIcon}
                                                style={{ margin: 10, width: 100 }}
                                                className="payment-icon"
                                            />
                                            : ''
                                    }
                                </Col>

                            </Row>
                        }

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </CardModal>

                {/* <span onClick={this.toggleModal}>
                    <i className="fa fa-plus"></i>
                </span> */}

                <span>
                    <button className="btn cardButton jumper-add-button" type="submit" style={{ padding: '0.15rem 0.35rem', marginBottom: '8px' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i> card
                    </button>
                </span>

                {/* 
                <span style={{}}>
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{padding: '0.35rem 0.55rem', borderRadius: '100%'}} onClick={this.toggleModal}>
                         <i className="fa fa-plus"></i>
                    </button>
                </span> */}
            </React.Fragment>
        );
    }
}

export default AddCard