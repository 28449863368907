/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import jumpersStore from '../../../stores/jumpers-store';

import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

import loadsStore from '../../../stores/loads-store';
import JmpModal from './customModal';
import Fuse from "fuse.js";
import ReactDOM from 'react-dom';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';
import swal from 'sweetalert'

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 30
    }),
    menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
        height: 730
    }),
    menuList: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        padding: 20,
        minHeight: 700
    })
};

class AddJumpers extends Component {

    constructor(props) {

        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTransferCredit = this.handleTransferCredit.bind(this);
        this.toggleError = this.toggleError.bind(this);
        // this.updateGear = this.updateGear.bind(this);
        // this.MultiValueLabel = this.MultiValueLabel.bind(this);

        this.state = {
            isModalOpen: false,
            jumperOptions: [],
            tandemOptions: [],
            adminDefaultOptions: [],
            ErrorMessage: '',
            placement: 'bottom',
            errors: {},
            isloading: false,
            errorOpen: false,
        };


        this.jumperEvents = [
            { name: jumpersStore.getEventPrefix('ticketsReturnedEvent'), func: this.handleTransferCredit }
        ];
    }


    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            isToTandem: false,
            isFromTandem: false
        });
        // this.gearIds.length = 0;
    }

    handleSubmit = (newValue) => {
        if (this.state.tickets > this.props.ticketsRemaining) {
            swal({
                title: "Fail to Trasfer",
                text: "cant return more than available",
                icon: "error",
            })
            return;
        }

        var payload = {
            saleId: this.props.saleId,
            memo: this.state.memo
        }

        jumpersStore.removeBlockTickets(this.props.jumperId, payload);
        this.setState({ isloading: true });

    };

    componentDidMount = () => {
        //console.log('in components did mount accounts');
        this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));

    }

    componentWillUnmount = () => {
        this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
    }


    toggleError() {
        this.setState({
            errorOpen: !this.state.errorOpen
        });
    }


    handleTransferCredit(data) {
        if (data === true) {
            this.setState({
                isloading: false,
                isModalOpen: false
            })
        }
        else {
            this.setState({
                isloading: false,
                isModalOpen: false,
                errorOpen: true
            })
            //console.log('fail');
        }
    }

    handleInput = (event, key) => {
        var cache = { ...this.state };
        cache[key] = event.target.value;
        this.setState({ ...cache });
        // if (!this.state.errors.length)
        //     tandemData.updateFormWithIndex(cache, this.props.index - 1);
    };



    render() {
        var screenWidth = window.innerWidth;

        var modalMargin = '25%';
        if (screenWidth >= 1920) {
            modalMargin = '65%';
        }

        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

        return (
            <React.Fragment>
                <JmpModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Remove Block Tickets`}
                >
                    <div className='complete-loading'>
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={35}
                            color={'#fff'}
                            loading={this.state.isloading}
                        />
                    </div>


                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>

                        <Row className="form-group">
                            <Label htmlFor="Amount" md={3}>Memo</Label>
                            <Col md={9}>
                                <Control.input name="memo" model=".memo" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.value}
                                    onChange={(e) => this.handleInput(e, 'memo')} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >Submit</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">Cancel</button>
                            </Col>
                        </Row>

                    </LocalForm>
                </JmpModal>
                <Modal isOpen={this.state.errorOpen} toggle={this.toggleError} className={this.props.className}>
                    <ModalHeader toggle={this.toggleError} className="error-jumperHeader">
                        Error
                    </ModalHeader>
                    <ModalBody>
                        <p style={{ color: 'red', fontSize: '20px', marginLeft: '1%' }}>Something Wrong happened while Transfer Credits !</p>
                    </ModalBody>
                </Modal>
                <a><span className="fa fa-remove" onClick={this.toggleModal}>

                </span></a>

                {/* <button className="btn customButton rightButton" style={{ borderRadius: '4px',backgroundColor: '#A7F6FF',color: '#082764',marginTop:'65%',marginBottom:'8px' }} onClick={this.toggleModal}>
                    TRANSFER CREDIT
                </button> */}
            </React.Fragment>
        );
    }
}

export default AddJumpers
