import FixedLeftMenu from "../../Common/Fixed_Left_Menu/fixedLeftMenu";
import logo from '../../images/logo.svg';
import TermsConditionsTable from "./termsConditionsTable";
import './termsConditions.css'
import AddConditions from "./addConditions";
import { useState } from "react";


const TermsConditions = ({ history }) => {

    return (
        <div className="terms_conditions_main">
            <FixedLeftMenu parHistory={history} />
            <div className="right_container">
                <div className="dizio_logo">
                    <img src={logo} alt="logo" className={"dizio_logo"} />
                </div>
                <div className="terms_conditions_right_container_bottom">
                    <AddConditions />
                    <TermsConditionsTable />
                </div>
            </div>
        </div>
    )
}


export default TermsConditions;