/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';

class EditPlanes extends Component {

    constructor(props) {

        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }


    handleColorChange=(e) =>{
        // setColor(e.target.value)
    }
        



    handleSubmit(values) {
        //console.log("in handle submit load"+ values);
        // this.toggleModal();
        var payload = {};
        if (values != undefined) {
            payload.capacity = parseInt(values.capacity);
            payload.plane = values.plane;
            payload.name = values.name;
            payload.price = Number(values.price);
            payload.hopnpopPrice = Number(values.hopnpopPrice);
            payload.usePlanePrice = values.usePlanePrice ? true : false;
            payload.color = values.color;
            payload.icao24 = values.icao24;
            payload.tail = values.tail;
            loadStore.CreatePlane(payload);
        }
         this.toggleModal();
    }
    

    render() {
        const { _id = '', plane = '', name = '', capacity = '', price = '', hopnpopPrice = '', usePlanePrice = false, icao24 = '', tail = '', color='' } = this.props.plan || {};
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Create Plane`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values, _id, plane, capacity, name, price,color)}>
                        <Row className="form-group">
                            <Label htmlFor="plane" md={3}>Plane</Label>
                            <Col md={9}>
                                <Control.input name="plane" model=".plane" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>Name</Label>
                            <Col md={9}>
                                <Control.input name="name" model=".name" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="capacity" md={3}>Capacity</Label>
                            <Col md={9}>
                                <Control.input name="capacity" model=".capacity" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price" model=".price" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="hopnpopPrice" md={3}>HopNPop Price</Label>
                            <Col md={9}>
                                <Control.input name="hopnpopPrice" model=".hopnpopPrice" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="usePlanePrice" md={3}>special event</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="usePlanePrice" model=".usePlanePrice"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="name" for="head" md={3}>Color</Label>
                            <Col md={9}>
                                <Control.input type="color"  id="color" name="color" model=".color" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>
                        

                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>icao24 code</Label>
                            <Col md={9}>
                                <Control.input name="icao24" model=".icao24" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>tail number</Label>
                            <Col md={9}>
                                <Control.input name="tail" model=".tail" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>


                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                    <i className="fa fa-plus"></i>
                </button>
            </React.Fragment>
        );
    }
}

export default EditPlanes
