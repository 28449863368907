import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'

import './index.css';

import App from './components/App';
import registerServiceWorker from './registerServiceWorker';

// ReactDOM.render(<HashRouter><App /></HashRouter>, document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
