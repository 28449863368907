/**
 * http://usejsdoc.org/
 */
import React, { Component } from "react";

import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, ButtonGroup, Modal, ModalHeader, ModalBody, Col, Row, Label,
} from "reactstrap";
import { Control, LocalForm, Errors, actions } from "react-redux-form";
import jumpersStore from "../../../stores/jumpers-store";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/lib/Async";
import loadsStore from "../../../stores/loads-store";
import itemStore from "../../../stores/item-store";
import JmpModal from "./customModal";
import { ClipLoader } from "react-spinners";
import ReactTimeout from "react-timeout";
import Fuse from "fuse.js";
import ReactDOM from 'react-dom';
import swal from 'sweetalert';

class CreateFunJumpCard extends Component {
  constructor(props) {
    super(props);
    this.updateGearValue = this.updateGearValue.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.MultiValueLabel = this.MultiValueLabel.bind(this);
    this.state = {
      isModalOpen: false,
      jumpType: null,
      affLevel: null,
      load: props.load,
      isloading: false,
      ErrorMessage: "",
      isValid: true,
      jumpType: "Undecided",
      jumpers: [],
      menuPlacement: "bottom",
      jumperOptions: [],
      suggestions: [],
    };
    this.inputValue = "";
    this.gearIds = [];
    this.studentgearIds = [];
    this.sportgearIds = [];

    this.jumpersInput = {};
    //this.timerId;
    this.fuse = null;
    this.handleNewActivity = this.handleNewActivity.bind(this);

    this.JumpTypes = [
      "Wingsuit", "WRAngle", "XRW", "CRW", "Tandem Practice", "Belly", "Hybrid", "Freefly", "Tracking", "Angle", "HopNPop", "HopNPop Student", "Solo Student", "High Pull", "Speed",
    ];

    this.loadEvents = [
      {
        name: loadsStore.getEventPrefix("loaderActivity"), func: this.handleNewActivity,
      },
    ];
    this.jumperEvents = [
      {
        name: jumpersStore.getEventPrefix("jumpersLoaded"), func: this.handleJumpersUpdate,
      },
    ];
  }

  componentWillMount = () => {
    // console.log('CreateJump componentWillMount');
    this.JumpTypes = loadsStore.getFunJumpTypes();
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func));
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));

    //this.timerId = setTimeout(this.cancelOrSubmit, 5000); // call the `toggle` function after 5000ms
  };

  cancelOrSubmit = () => {
    //console.log(this.valRef.val);

    // console.log(this.state.jumpType);
    // console.log(this.gearIds);
    //alert("jump type: " + JSON.stringify(this.state.jumpers));
    //this.gearIds = this.gearIds.filter(id => )
    console.log("in cancel or submit");
    if (this.state.jumpers.length > 0) {
      var jumpType;

      if (this.state.jumpType === "Undecided") jumpType = "Undecided";
      else jumpType = this.state.jumpType;
      let defaultAlt = itemStore.getDefaultAltitude();
      if (defaultAlt === undefined) {
        swal({
          title: "no default altitude defined",
          text: "Warning",
          icon: "warning",
          dangerMode: false,
        })
        return;
      }
      var jumpPostBody = {
        jumpType: jumpType,
        jumperIds: this.state.jumpers.map((jumperOption) => jumperOption.value._id),
        altitude: itemStore.getDefaultAltitude().name,
      };

      if (jumpType?.toLowerCase().includes("hopnpop")) {
        jumpPostBody.altitude = itemStore.getLowAltitude().name;
      }

      jumpPostBody.studentgearRentals = this.studentgearIds.filter((id) => jumpPostBody.jumperIds.indexOf(id) >= 0);
      jumpPostBody.sportgearRentals = this.sportgearIds.filter((id) => jumpPostBody.jumperIds.indexOf(id) >= 0);
      // console.log(jumpPostBody.gearRentals);
      // console.log(jumpPostBody);
      // alert(JSON.stringify(jumpPostBody));
      jumpPostBody.jumperIds = Array.from(new Set(jumpPostBody.jumperIds));

      loadsStore.createFunJump(this.state.load.loadNum, jumpPostBody).then((r) => {
        this.setState({ jumpers: [], rSelected: "" });
      });
    } else {
      this.setState({
        isloading: false,
        ErrorMessage: "Invalid Input !",
        isValid: false,
      });

      //this.formDispatch(actions.reset('createjump.jumpers'));
    }

    this.cancelCreateJump();
  };

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
  };

  componentDidMount = () => {
    //alert('create fun jump componentDidMount');
    // console.log(this.valRef);
    console.log("create funjump card did mount");
    if (this.valRef) {
      this.valRef.focus();
    }
    //ReactDOM.findDOMNode(this.valRef).focus();
    var jumperOptions = jumpersStore.getJumperOptions().filter((opt) => opt.value.isTeam !== true);

    if (this.props.activeJumperIdsOnLoad) {
      jumperOptions = jumperOptions.filter(
        (option) =>
          this.props.activeJumperIdsOnLoad.findIndex(
            (id) => option.value._id === id
          ) === -1
      );
    }
    this.setState({ jumperOptions: jumperOptions });
    this.setFuse(jumperOptions);

    // if (ReactDOM.findDOMNode(this.valRef).getBoundingClientRect().y > window.innerHeight / 2) {
    // 	this.setState({ menuPlacement: "top" });
    // }
    // else {
    // 	this.setState({ menuPlacement: "bottom" });
    // }
  };
  handleJumpersUpdate = (jumperOptions) => {
    console.log("in handle Jumpers Update");
    var jumperOptions = jumpersStore.getJumperOptions().filter((opt) => opt.value.isTeam !== true);

    if (this.props.activeJumperIdsOnLoad) {
      jumperOptions = jumperOptions.filter(
        (option) =>
          this.props.activeJumperIdsOnLoad.findIndex(
            (id) => option.value._id === id
          ) === -1
      );
    }
    this.setState({ jumperOptions: jumperOptions });
    this.setFuse(jumperOptions);
  };

  setFuse = (jumperOptions) => {
    console.log("in set fuse");
    var options = {
      shouldSort: true,
      tokenize: false,
      threshold: 0.1,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["label", "value.manifestNumber"],
    };
    //console.log(jumperOptions);

    this.fuse = new Fuse(jumperOptions, options); // "list" is the item array
  };

  handleNewActivity = (activityString) => {
    // console.log('in handleNewActivity');
    // console.log(activityString);

    if (activityString) {
      this.setState({
        isloading: false,
        isModalOpen: false,
      });
    }
  };

  updateGearValue = (value) => {
    // console.log("value");
    // console.log("valuevalue", value.target.name);

    // return false
    var id = value.target.name.substr(5);
    var type = value.target.name.substr(0, 5);

    if (type === "gear1") {
      if (value.target.checked) {
        var old = this.gearIds;
        this.studentgearIds = [...old, id];
        // console.log("gearIdsgearIds",this.gearIds)
      }

      else {
        this.studentgearIds = this.studentgearIds.filter((gearId) => gearId !== id);
      }
    } else if (type === "gear2") {
      if (value.target.checked) {
        var old = this.gearIds;
        this.sportgearIds = [...old, id];
        // console.log("gearIdsgearIds",this.gearIds)
      } else {
        this.sportgearIds = this.sportgearIds.filter((gearId) => gearId !== id);
      }
    }

    // console.log("gearIdsgearIdsgearIdsgearIdsgearIdsgearIds", this.gearIds);
  };

  Option = (props) => {
    var studentgearModel = ".gear1" + props.data.value._id;
    var studentname = "gear1" + props.data.value._id;

    var sportgearModel = ".gear2" + props.data.value._id;
    var sportname = "gear2" + props.data.value._id;

    var isChecked = false;

    this.gearIds.length > 0 &&
      this.gearIds.map((i) => {
        if (i === props.data.value._id) {
          isChecked = true;
        }
      });

    this.studentgearIds.length > 0 &&
      this.studentgearIds.map((i) => {
        if (i === props.data.value._id) {
          isChecked = true;
        }
      });

    this.sportgearIds.length > 0 &&
      this.sportgearIds.map((i) => {
        if (i === props.data.value._id) {
          isChecked = true;
        }
      });
    const { fSize } = JSON.parse(localStorage.getItem("jumperDetails"));
    let fontSize = fSize + 1;
    return (
      <div id={this.props.loadsToShow > 3 ? "select-txt-morethan3" : ""} style={{ margin: "10px 0" }} className="row">
        <div className="col-5" style={{ fontSize: `${fontSize}px` }}>
          <components.Option {...props} />
        </div>
        <div className="col-7" style={{ paddingTop: "6px", fontSize: `${fontSize}px` }} >
          <div style={{ alignItems: "center" }} className="row">
            <span style={{ fontSize: "1em" }}>student gear </span>
            <input type="checkbox"
              style={{ zIndex: 800 }}
              name={studentname}
              // checked={isChecked}
              value={true}
              onChange={(value) => { this.updateGearValue(value); }}
            />
          </div>
          <div
            className="row" style={{ paddingTop: "6px", alignItems: "center" }}
          >
            <span style={{ fontSize: "1em" }}>sport gear</span>{" "}
            <input type="checkbox"
              style={{ zIndex: 800 }}
              name={sportname}
              // checked={isChecked}
              value={true}
              onChange={(value) => { this.updateGearValue(value); }}
            />
          </div>
        </div>
      </div>
    );
  };

  MultiValueLabel = (props) => {
    console.log(props, "loadsPerPage");
    let { loadsToShow } = this.props;
    let screenWidth = window.innerWidth;
    let title = null,
      widthClass = "",
      bigScreen = "";
    //   VbigScreen = "";
    if (screenWidth >= 1440 && this.props.loadsToShow >= 4) {
      title = props.children;
      widthClass = "widthForMoreThan5";
    } else if (screenWidth >= 1440 && this.props.loadsToShow < 4) {
      title = null;
      widthClass = "widthForLessThan5";
    }
    if (screenWidth >= 1440 && this.props.loadsToShow >= 6) {
      title = props.children;
      bigScreen = "widthForMoreThan7";
    }
    if (screenWidth >= 1440 && this.props.loadsToShow == 7) {
      title = props.children;
      widthClass = "widthForLessThan1920-7";
    }


    return (
      <div className={`row ${widthClass}`} style={{ margin: "0px" }}>
        <div
          className={`col-12 ${bigScreen}`}
          style={{ padding: "0px" }}
          title={title}
        >
          <components.MultiValueLabel {...props} />
        </div>
        {this.studentgearIds.indexOf(props.data.value._id) > -1 ||
          this.sportgearIds.indexOf(props.data.value._id) > -1 ? (
          <div className="col-12  ">-G</div>
        ) : null}
      </div>
    );
  };

  filterJumpers = (inputValue, jumperOptions) => {
    console.log("in filter jumpers");
    console.log(inputValue);

    if (inputValue) {
      var filteredOptions = this.fuse.search(inputValue.toLowerCase());
      console.log(filteredOptions);
      if (filteredOptions.length > 30)
        return (filteredOptions = filteredOptions.slice(0, 30));
      else return filteredOptions;
    }
    return jumperOptions;
  };

  promiseOptions = (inputValue, jumperOptions) => {
    return new Promise((resolve) => {
      resolve(this.filterJumpers(this.inputValue, jumperOptions));
    });
  };

  handleInputChange = (newValue) => {
    this.inputValue = newValue;
    if (this.valRef) {
      this.valRef.focus();
    }
  };

  handleChange = (values) => {
    //alert(JSON.stringify(values));

    var jumpers = values;
    if (this.props.jumpType === "Solo Student" || this.props.jumpType === "HopNPop Student" || this.props.jumpType === "HopNPop") {
      jumpers = [values];
    }
    // this.valRef.focus();
    console.log("refocus on input box");
    //var suggestions = [this.props.jumperOptions[0], this.props.jumperOptions[1], this.props.jumperOptions[2], this.props.jumperOptions[3]];
    this.setState({ jumpers: jumpers });

    var payload = {
      jumperIds: jumpers.map((value) => value.value._id),
    };

    if (!(this.props.jumpType === "Solo Student" || this.props.jumpType === "HopNPop Student" || this.props.jumpType === "HopNPop")) {
      jumpersStore.getJumpWithSuggestions(payload)
        .then((suggestions) => {
          suggestions = suggestions.filter((suggestion) => this.state.jumpers.findIndex((value) => value.value._id === suggestion.value._id) < 0);
          this.setState({ suggestions: suggestions });
        });
    }
  };

  addSuggestion = (suggestion) => {
    var jumpers = [...this.state.jumpers, suggestion];
    this.setState({ jumpers: jumpers });

    var payload = {
      jumperIds: jumpers.map((val) => val.value._id),
    };
    jumpersStore.getJumpWithSuggestions(payload).then((suggestions) => {
      console.log("suggestions: ");
      console.log(suggestions);
      console.log("jumpers: " + jumpers);
      console.log(jumpers);

      suggestions = suggestions.filter((suggestion) => this.state.jumpers.findIndex((value) => value.value._id === suggestion.value._id) < 0);
      console.log("suggestions after filter");
      console.log(suggestions);
      this.setState({ suggestions: suggestions });
    });
  };
  cancelCreateJump = () => {
    if (this.valRef) {
      this.valRef.focus();
    }
    loadsStore.cancelCreateJump({ loadNum: this.props.load.loadNum, type: "funjump", });
    this.setState({ jumpers: [], rSelected: "" });
  };

  updateJumpType = (value) => {
    this.setState({ jumpType: value.target.value });
  };

  onRadioBtnClick(type, indx) {
    var jumpTypes = this.JumpTypes;
    this.setState({
      rSelected: indx,
      jumpType: type,
    });
  }

  attach = (node) => {
    console.log("focus attach");

    node.focus();
  };
  focusInput = (component) => {
    console.log("focus");
    if (component) component.focus();
  };

  render() {
    console.log("rendering create fun jump card, " + this.props.loadNum);
    // console.log(props.loadOptions,"renderinfLinsbcdsnc")
    let AsyncSelectInput = (props) => (<AsyncSelect model={props.model} name={props.name}
      value={props.defaultValue} loadOptions={props.loadOptions} defaultOptions={props.defaultOptions} onChange={props.onChange} isMulti={props.isMulti} components={props.components}
      styles={props.styles} onInputChange={props.onInputChange} menuPortalTarget={props.menuPortalTarget} menuPosition={props.menuPosition} menuPlacement={props.menuPlacement} />);
    this.setFuse(this.props.jumperOptions);
    const override = `

            display: block;
            margin: 0 auto;
            border-color: red;
            `;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontSize: "1em",


      }),
      control: (base, state) => ({
        ...base,
        fontSize: "1.2em",
      }),
      menu: (provided, state) => ({
        ...provided,
        width: this.props.loadsToShow > 2 ? 275 : "100%",
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: "0px",
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: "0px",
      }),
    };

    const funJumpTypeButtonGroup = this.JumpTypes.map((type, indx) => {
      return (
        <Button
          color="primary"
          style={{
            fonwidth: "170px",
            marginLeft: "4px",
            padding: 1,
            marginBottom: "4px",
            fontSize: "1em",
          }}
          onClick={() => this.onRadioBtnClick(type, indx)} active={this.state.rSelected === indx} > <strong>{type}</strong></Button>);
    });
    const moreThan3 = this.props.loadsToShow > 3 ? true : false;
    const customClass = moreThan3 ? "outer" : "inner";
    let footerBtnStyles = "regular-btn-styles";
    if (this.props.loadsToShow > 5) {
      footerBtnStyles = "squized-btn-styles";
    }

    var jumperOptions = jumpersStore.getJumperOptions().filter((opt) => opt.value.isTeam !== true);
    if (this.props.activeJumperIdsOnLoad) {
      jumperOptions = jumperOptions.filter(
        (option) =>
          this.props.activeJumperIdsOnLoad.findIndex(
            (id) => option.value._id === id
          ) === -1
      );
    }
    const { fontSize } = JSON.parse(localStorage.getItem("jumperDetails"));
    return (
      <React.Fragment>
        <Card
          className={`funjumpcard ${customClass} isJumpOpen isFunJumpOpen-${this.props.loadNum}`}
        // style={{ position: 'absolute', width: '100%', zIndex: 99, top: '21%', right: 0 }}
        >
          <CardHeader className="jumpcardheader align-items-center row">
            <div className="col-12">
              Create Fun Jump
            </div>
            {/* <div className="col-2">
							<i onClick={this.cancelCreateJump} className="fa fa-close"></i>
						</div> */}
          </CardHeader>
          <CardBody className="custom-card-body" style={{ padding: "10px" }}>
            <div className="complete-loading">
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={35}
                color={"#fff"}
                loading={this.state.isloading}
              />
            </div>
            <LocalForm form="createjump" model="createjump">
              <Row className="form-group">
                <Label htmlFor="jumpType" md={12} style={{ paddingLeft: "21px" }} >{" "}<strong>Jump Type </strong></Label>
                <Col md={12}>
                  <ButtonGroup className="row jump-type-group" style={{ fontWeight: "strong", padding: "0px 20px" }}>
                    {funJumpTypeButtonGroup}
                    {/*
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(1)} active={this.state.rSelected === 1}><strong>Wingsuit</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(9)} active={this.state.rSelected === 1}><strong>XRW</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(10)} active={this.state.rSelected === 1}><strong>CRW</strong></Button>

										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(2)} active={this.state.rSelected === 2}><strong>Belly</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(3)} active={this.state.rSelected === 3}><strong>Freefly</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(4)} active={this.state.rSelected === 4}><strong>Tracking</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(11)} active={this.state.rSelected === 4}><strong>Angle</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(5)} active={this.state.rSelected === 5}><strong>HopNPop</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(8)} active={this.state.rSelected === 8}><strong>High Pull</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(7)} active={this.state.rSelected === 7}><strong>Solo Student</strong></Button>
										<Button color="primary" style={{ fonwidth: '170px', marginLeft: '10px', marginBottom: '4px' }} onClick={() => this.onRadioBtnClick(6)} active={this.state.rSelected === 6}><strong>HopNPop Student</strong></Button>
										*/}
                  </ButtonGroup>
                </Col>
              </Row>
              <Row
                className="form-group"
                style={{ paddingLeft: "8px", paddingRight: "7px" }}
              >
                <Label htmlFor="jumpType" md={12}>
                  <strong>Jumpers </strong>
                </Label>
                <Col md={12}>
                  <AsyncSelect model=".jumpers" name="jumpers" loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)} isMulti
                    // ref={(value) => this.valRef = value}
                    ref={this.props.forwardRef}
                    defaultOptions={this.state.jumperOptions.slice(0, 10)}
                    value={this.state.jumpers}
                    onChange={(values, action) => this.handleChange(values)}
                    onInputChange={(newValue) => this.handleInputChange(newValue)}
                    components={{ Option: this.Option, MultiValueLabel: this.MultiValueLabel, }}
                    menuPortalTarget={document.body}
                    // menuPosition="fixed"
                    menuPlacement="top"
                    styles={customStyles}
                    menuPlacement={this.state.menuPlacement}
                    // menuIsOpen={true}
                    hideSelectedOptions={false}
                  />
                </Col>
              </Row>

              <Row className="form-group" style={{ paddingLeft: "8px", paddingRight: "7px" }}
              >
                <Col>
                  Suggestions:
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.state.suggestions.map((suggestion) => (
                    <Button className={footerBtnStyles} onClick={() => this.addSuggestion(suggestion)} >{suggestion.label}
                    </Button>))}
                </Col>
              </Row>
              <Row className="form-group m-t-1-rem">
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <button className=" btn btn-bg btn-manifest" style={{ fontSize: `${fontSize}px` }} onClick={() => this.cancelOrSubmit()}>Manifest</button>
                  <button onClick={(e) => { e.preventDefault(); this.cancelCreateJump(); }} className="btn manifest-cancel" style={{ fontSize: `${fontSize}px` }}>CANCEL</button>
                </Col>
              </Row>
            </LocalForm>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <CreateFunJumpCard {...props} forwardRef={ref} />
));
