import React, { useState, useEffect } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment";
import swal from "sweetalert";
import reservationStore from '../../stores/reservation-store'
import DonutLoader from "../loadManager/DonutLoader";



export const getSelectedEvent = (events, currentSelectedDate,state) => {
  console.log(events, currentSelectedDate,'currentSelectedDatecurrentSelectedDate');
  return events?.filter((event) => {
    const formattedStartDate = moment(event?.start).format("YYYY-MM-DD");
    return formattedStartDate === currentSelectedDate;
  });
};

const Send_Multiple_ReminderEmails = ({
  currentSelectedDate,
  isMemoModalOpen,
  setState,
  state,
  selectedTemplate,
  isSuspendedData
}) => {
  let count = 1;

  const [passengers, setPassengers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (state.singleTimeSlots === true) {
      const _passengers = state.passengers.filter(event => {
        if (event._id === state.selectedEventId) return event
      })
      setPassengers(_passengers);
    } else {
      setPassengers(state.passengers);
    }
  }, [])

  const sendEmail = () => {
    setIsLoading(true);
    const selectEvent = getSelectedEvent(state?.events, currentSelectedDate);
    const emails = [];
  
    passengers.forEach((value) => {
      value?.reservations?.forEach((detail, idx) => {
        let jumpers = [];
        if (detail.tandemStudents.length > 0) {
          jumpers = detail.tandemStudents;
        } else {
          jumpers = detail.students;
        }
        const invoice = detail.invoice;
  
        if (isSuspendedData) {
          if (detail.isChecked) {
            emails.push({
              email: jumpers[0].email,
              name: jumpers[0].firstname + " " + jumpers[0].lastname,
              phone: jumpers[0].phone,
              calenderId: detail.calender_id,
              ticketCount: detail.ticketCount,
              eventName: value.eventName,
              timeZone: value.timeZone,
              reservationDate: moment(value.datetime)
                .tz(value.timeZone)
                .format("DD/MMM/YYYY, h:mm A"),
              invoice: invoice,
              fees: value.reservations[0].totalFees,
              tax: value.reservations[0].totalTax,
              total: value.reservations[0].totalValue,
            });
          }
        } else {
          emails.push({
            email: jumpers[0].email,
            name: jumpers[0].firstname + " " + jumpers[0].lastname,
            phone: jumpers[0].phone,
            calenderId: detail.calender_id,
            ticketCount: detail.ticketCount,
            eventName: value.eventName,
            timeZone: value.timeZone,
            reservationDate: moment(value.datetime)
              .tz(value.timeZone)
              .format("DD/MMM/YYYY, h:mm A"),
            invoice: invoice,
            fees: value.reservations[0].totalFees,
            tax: value.reservations[0].totalTax,
            total: value.reservations[0].totalValue,
          });
        }
      });
    });
  
    console.log(emails, passengers);
    const data = {
      templateID: selectedTemplate?._id,
      recipients: emails,
    };
  
    if (selectedTemplate) {
      return reservationStore
        .sendConfirmationEmailWithTemplate(data)
        .finally(() => {
          setIsLoading(false);
          setState({ isSendEmailModalOpen: false });
        });
    } else {
      return reservationStore.sendDefaultConfirmationEmail(data).finally(() => {
        setIsLoading(false);
        setState({ isSendEmailModalOpen: false });
      });
    }
  };
  
  const getEmailData = (detail) => {
    return (
      <tr>
      <td><i class="fa fa-ENV?.lope" aria-hidden="true"></i> &nbsp;</td>
      <td >{count++}. &nbsp;</td>
      <td >
        {detail?.tandemStudents && detail?.tandemStudents[0]?.email}
        {detail?.students && detail?.students[0]?.email}
      </td>
    </tr>
    )

  }
  return (
    <Modal id="modal-main1" isOpen={isMemoModalOpen}>
      <ModalHeader style={{ justifyContent: "center", color: "#007bff" }}>
        Send Reservation Reminder Alert Email
      </ModalHeader>
      <ModalBody className="styleSendEmail">
        <div className="form-group">
          <div className="col-md-12">
          <DonutLoader isLoading={isLoading} addClass="sample1" />
            <table>
              <tbody>
                  {passengers?.map((value) => {
                    return value.reservations?.map(detail => {
                      if(isSuspendedData){
                        if(detail?.isChecked){
                        return getEmailData(detail)
                      }
                        else {
                          return null
                        }
                      }
                      else{
                        return getEmailData(detail)
                      }
                    })
                  })}

              </tbody>
            </table>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => sendEmail()} >
          Send
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setState({ isSendEmailModalOpen: false })}
          }
        >
          Close
        </Button>
      </ModalFooter>
      <div dangerouslySetInnerHTML={{__html: selectedTemplate?.content}} ></div>
    </Modal>
  );
};

export default Send_Multiple_ReminderEmails;