

const initialState = {
  isCompleted: "",
  loadNum: "",
  jumpId: "",
  albumId: ""
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "MEDIA_UPLOAD":
      return {
        ...state,
        isCompleted: action.payload.isCompleted,
        loadNum: action.payload.loadNum,
        jumpId: action.payload.jumpId,
        albumId: action.payload.albumId
      }
    default:
      return state
  }
};
