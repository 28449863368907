import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import reportStore from '../../../../stores/report-store';
import { styles } from './DataTableStyle';
import * as constants from '../../../constant/reports';
import { ClipLoader } from 'react-spinners';

import DonutLoader from '../../../loadManager/DonutLoader';

class JumperLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: [],
            Isdata: false,
            isloading: false
        }
        this.handleNewActivity = this.handleNewActivity.bind(this);
        this.loadEvents = [
            { name: reportStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
        ];
    }

    filterData = () => {
        let filterDataArray = [];
        let filterOutput = [];

        if (this.state.Isdata === true) {
            this.filterDataArray = this.state.filterData;

            for (var item in this.filterDataArray) {
                // console.log(item);
                // console.log(this.filterDataArray[item]);
                var logArray = this.filterDataArray[item];
                for (var items in logArray) {
                    var desc = logArray[items];

                    var date = new Date(desc.date);
                    var localDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                    filterOutput.push({ id: desc._id, type: desc.type, date: localDate })
                }
            }
        }


        // const { range, data } = this.props;
        // let { startDate, endDate, jumperName,jumper_id } = range[0];
        // alert("dsfasdfasdf"+jumper_id)
        // let rangeEnd = this.dateConverter(endDate);
        // let rangeStart = this.dateConverter(startDate);
        // let rangeJumperName = this.convertCase(jumperName);

        // const filteredData = data.filter(item => {
        //     let dateOfLog = this.dateConverter(item.date);
        //     let jumperNameOfLog = this.convertCase(item.jumperName);
        //     return (
        //         rangeEnd >= dateOfLog &&
        //         rangeStart <= dateOfLog &&
        //         rangeJumperName === jumperNameOfLog
        //     )
        // })
        return filterOutput;
    }

    convertCase = (stringValue) => (stringValue.toLowerCase())

    dateConverter = (date) => {
        let dateInMilliseconds = new Date(date).getTime();
        return dateInMilliseconds;
    }

    componentWillReceiveProps() {
        const { range } = this.props;
        var startDate = '';
        var endDate = '';
        let JumperId = '';
        if (range.dateRange.length > 0) {
            const index = range.dateRange.findIndex(range => range.modalType === constants.JUMPER_LOGS);
            if (index > -1) {
                this.setState({ isloading: true })

                JumperId = range.dateRange[0].jumper_id;
                // console.log("JummperId:" + JumperId)
                let sDate = new Date(range.dateRange[index].startDate);
                sDate = sDate.setHours(0, 0, 0, 0);
                startDate = new Date(sDate);
                startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                let eDate = new Date(range.dateRange[index].endDate);
                eDate = eDate.setHours(23, 59, 59, 999);
                endDate = new Date(eDate);
                endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');


                var that = this;
                var data = [];
                Promise.all([reportStore.GetJumperLogs(startDate, endDate, JumperId)])
                    .then(function (result) {
                        data = result[0];
                        that.setState({ filterData: data, Isdata: true, isloading: false });
                        that.forceUpdate();
                    }).catch(function (err) {
                        that.setState({ isloading: false });
                    })
            }
        }
    }

    componentWillUnmount = () => {
        this.loadEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }

    handleNewActivity = (activityString) => {
        this.setState({
            isloading: false,
        })

    }

    componentWillMount() {
        this.loadEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func))

        let { dateParam, range } = this.props;
        var startDate = '';
        var endDate = '';
        let JumperId = '';

        if (dateParam.length > 0) {

            const index = dateParam
                .findIndex(range => range.modalType === constants.JUMPER_LOGS);

            if (index > -1) {
                this.setState({ isloading: true })
                JumperId = dateParam[index].jumper_id;
                let sDate = new Date(dateParam[index].startDate);
                sDate = sDate.setHours(0, 0, 0, 0);
                startDate = new Date(sDate);
                startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                let eDate = new Date(dateParam[index].endDate);
                eDate = eDate.setHours(23, 59, 59, 999);
                endDate = new Date(eDate);
                endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                var that = this;
                var data = [];
                Promise.all([reportStore.GetJumperLogs(startDate, endDate, JumperId)])
                    .then(function (result) {
                        data = result[0];
                        that.setState({ filterData: data, Isdata: true, isloading: false });
                        that.forceUpdate();
                    }).catch(function (err) {
                        that.setState({ isloading: false });
                    })
            }
        }
    }

    render() {
        const override = `
            display: block;
            margin: 0 auto;
            border-color: red;
        `;
        const { classes, submit, data, range } = this.props;
        return (
            <Fragment>
                <div className='report-loading inreports'>
                    {/* <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={'#123abc'}
                        loading={this.state.isloading}
                    /> */}
                    <DonutLoader isLoading={this.state.isloading} />

                </div>
                {submit && this.filterData().map((row, index) => {
                    return (
                        <Fragment>
                            <TableRow
                                key={`jumperlogsrow${index}`}>
                                <TableCell
                                    className={classes.tableCell}
                                    key={`jumperlogscolumn1${index}`}
                                >
                                    {row.type}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    className={classes.tableCell}
                                    key={`jumperlogscolumn2${index}`}
                                >
                                    {/* {moment(row.date).format('L')} */}
                                    {row.date}
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    )
                })
                }
            </Fragment>
        )
    }
}

export default withStyles(styles)(JumperLogs);
