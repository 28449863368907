export const styles = theme => ({
    root: {
        height: 250,
        flexGrow: 1,
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        backgroundColor: '#E7F2F6'
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        color: '#03173F',
        listStyleType: 'none',
    },
    defaultText: {
        color: '#03173F',
    },
    input: {
        margin: '-0.35em 0em 0em 0em',
        borderBottomColor: '#E7F2F8',
    },
    itemBox: {
        color: '#E7F2F6',
        fontSize: '18px',
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    inputProps: {
        color: '#E7F2F6',

    },
    autoCompleteInput: {
        color: '#E7F2F6',
        // width: 350,
        textTransform: 'uppercase'
    },
    btn: {
        '&:hover': {
            backgroundColor: '#E7F2F6',
            opacity: 0.97
        },
        backgroundColor: '#E7F2F6',
        color: '#03173F',
        height: '3em',
        margin: '5px auto',
        width: '34%'
    },
    dateBtn: {
        '&:hover': {
            backgroundColor: '#E7F2F6',
            opacity: 0.97
        },
        backgroundColor: '#E7F2F6',
        color: '#03173F',
        //height:'3em',
        margin: '5px auto'
    },
    dateBtnHistory: {
        '&:hover': {
            backgroundColor: '#E7F2F6',
            opacity: 0.97
        },
        backgroundColor: '#E7F2F6',
        color: '#03173F',
        //height:'3em',
        margin: '5px auto'
    },
    dateBtnQuater: {
        '&:hover': {
            backgroundColor: '#E7F2F6',
            opacity: 0.97
        },
        backgroundColor: '#E7F2F6',
        color: '#03173F',
        //height:'3em',
        margin: '5px auto'
    },
    field: {
        display: 'grid',
        margin: '5px 0px',
        padding: '5px 0px',
        gridColumnGap: '20px',
        gridTemplateColumns: '1fr auto 3fr'
    }
});