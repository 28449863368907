import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, TableHead } from '@material-ui/core';
import reportStore from '../../../../stores/report-store';
import { styles } from './DataTableStyle';
import { closeModal } from '../../../../actions/reports';

import * as constants from '../../../constant/reports';
// import JumperStatement from '../../../PrintPdf/jumperStatmentPdf'
import JumperStore from '../../../../stores/jumpers-store';
import DonutLoader from '../../../loadManager/DonutLoader';
import DataTable from 'react-data-table-component';


const customStyles = {
  headCells: {
    style: {
      fontWeight: '600',
      fontSize: "1rem",
      letterSpacing: "1px",
      textAlign: 'center'
    },
  },
  rows: {
    style: {
      padding: '10px 0',
    },
  },
};

class DetailByCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      headers: [],
      summary: {},
      Isdata: false,
      isloading: false,
      itemHeader: [],
      _data: [],
      curType: ""
    }
    this.reportEvent = [
      { name: reportStore.getEventPrefix('reportDetails'), func: this.handleReportDetails }
    ];
  }

  componentDidMount = () => {
    this.reportEvent.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
  }

  componentWillUnmount = () => {
    this.reportEvent.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
  }

  checkForNegatives = (price) => {
    if (price === null || price === undefined) {
      return 'null'
    }
    else {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${Number(price).toFixed(2)}`
        }

      if (price < 0) {
        return <span style={{ color: 'red' }}> {priceCell.value}</span>;
      }
      return priceCell.value;
    }
  }

  handleReportDetails = (report) => {
    this.props.closeModal();
    this.setState({ data: report.data, headers: report.headers, summary: report.summary, Isdata: true, isloading: false });
  }

  componentWillReceiveProps(nextprops) {
    const { range } = nextprops;
    let items_Header = [];
    const getObjForHeader = range.categoryDetailsData[0];

    range?._categoryDetailsHeader?.forEach((item) => {
      const obj = {
        name: item[0],
        selector: item[1],
        sortable: true,
        center: true,
      }
      items_Header.push(obj);
      if (item[1] === "date") {
        const time = {
          name: "Time",
          selector: "time",
          sortable: true,
          center: true,
        }
        items_Header.push(time);
      }
    })

    const _categoryDetailsData = range.categoryDetailsData.map(item => {
      if (item.date) {
        let date = item.date;
        item.date = new Date(date).toLocaleDateString();
        item.time = new Date(date).toLocaleTimeString();
      }
      if (item.fees) {
        item.fees = parseFloat(item?.fees?.toFixed(2));
      }
      item.tax = item.tax ? Number(item.tax).toFixed(2) : (0).toFixed(2);
      item.amount = item.amount ? Number(item.amount).toFixed(2) : (0).toFixed(2);
      item.total = item.amount ? Number(item.total).toFixed(2) : (0).toFixed(2);
      return item
    })
    this.setState({ itemHeader: items_Header, _data: _categoryDetailsData, curType: this.props.type });

    var startDate = '';
    var endDate = '';
    let JumpType = '';
    if (range.dateRange.length > 0 && nextprops.isModal === false && nextprops.submit === true) {
      const index = range.dateRange.findIndex(range => range.modalType === constants.DETAIL_BY_CATEGORY);
      if (index > -1) {
        this.setState({ isloading: true })

        JumpType = range.dateRange[index].JumpType;
        let sDate = new Date(range.dateRange[index].startDate);
        sDate = sDate.setHours(0, 0, 0, 0);
        startDate = new Date(sDate);
        startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        let eDate = new Date(range.dateRange[index].endDate);
        eDate = eDate.setHours(23, 59, 59, 999);
        endDate = new Date(eDate);
        endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        var that = this;
        var data = [];
        // Promise.all([reportStore.GetJumperLogs(startDate, endDate, JumpType)])
        //     .then(function (result) {
        //         data = result[0];
        //         console.log("JumperLogData", data);
        //         localStorage.setItem("JumperStatementData", JSON.stringify(data));
        //         that.setState({ filterData: data, Isdata: true });
        //         that.forceUpdate();
        //     })

        if (JumpType === "tandem jumps") {
          reportStore.getTandemDetails(startDate, endDate, "tandem")
        }
        else if (JumpType === "tandem ultras") {
          reportStore.getTandemDetails(startDate, endDate, "ultra")
        }
        else if (JumpType === "AFF jumps") {
          reportStore.getAffDetails(startDate, endDate)
        }
        else if (JumpType === "fun jumps") {
          reportStore.getFunJumpDetails(startDate, endDate, "regular");
        }
        else if (JumpType === "coach jumps") {
          reportStore.getCoachJumpDetails(startDate, endDate, "all")
        }
        else if (JumpType === "collections") {
          reportStore.getCollectionDetails(startDate, endDate, "all");
        }
        else if (JumpType === 'countersales') {
          reportStore.getCountersaleDetails(startDate, endDate, "all");
        }
        else if (JumpType === 'transfers') {
          reportStore.getCountersaleDetails(startDate, endDate, "transfer");
        }
        else if (JumpType === 'Jumper payouts') {
          reportStore.getJumperPayouts(startDate, endDate, "all");
        }
        else if (JumpType === 'Tandem payouts') {
          reportStore.getTandemPayouts(startDate, endDate, "all");
        }
      }
    }
    else {
      var data = localStorage.getItem("JumperStatementData");
      if (data !== null) {
        this.setState({ filterData: JSON.parse(data), Isdata: true });
      }
    }
  }

  ConvertDate(inputDate) {
    if (inputDate.toString().length > 0) {
      var d = new Date(inputDate);

      var localTime = d.toLocaleTimeString();

      // var localDate = new Date(d.toLocaleDateString()),
      //   month = '' + (localDate.getMonth() + 1),
      //   day = '' + localDate.getDate(),
      //   year = localDate.getFullYear();

      var datestring = d.toLocaleDateString();
      var dateParts = datestring.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]),
        month = '' + (dateObject.getMonth() + 1),
        day = '' + dateObject.getDate(),
        year = dateObject.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-') + ' ' + localTime;
    }
    else {
      return inputDate;
    }
  }

  renderData = () => {
    let { classes } = this.props;
    return this.state.data.map((item, index) => {
      return (
        <Fragment>
          <TableRow key={`detailRow${index}`}>
            {
              Object.keys(item).map(key => {
                if (key.toLowerCase().includes("price") || key.toLowerCase().includes("pay") || key.toLowerCase().includes("amount") || key.toLowerCase().includes("fees") || key.toLowerCase().includes("tax") || key.toLowerCase().includes("total") || key.toLowerCase().includes("discountvalue"))
                  return <TableCell
                    colSpan="2"
                    key={`dataCell${index + key}`}
                    className={classes.detailtableCell}>
                    {this.checkForNegatives(item[key])}
                  </TableCell>
                if (key.toLowerCase().includes("date"))
                  return <TableCell
                    colSpan="2"
                    key={`dataCell${index + key}`}
                    className={classes.detailtableCell}>
                    {(new Date(item[key])).toLocaleDateString()} {(new Date(item[key])).toLocaleTimeString()}
                  </TableCell>
                return <TableCell
                  colSpan="2"
                  key={`dataKeyCell${index + key}`}
                  className={classes.detailtableCell}>
                  {item[key]}
                </TableCell>
              })
            }
          </TableRow>
        </Fragment>
      )
    })
  }

  titleCase = (str) => {
    return str.replace(/\w\S/g, function (t) { return t.toUpperCase() });
  }

  renderReportHeader = () => {
    const { range, classes } = this.props;
    var col1, col2, col3 = '';
    const index = range.dateRange.findIndex(range => range.modalType === constants.DETAIL_BY_CATEGORY);
    if (index != -1) {
      var JumpType = range.dateRange[index].JumpType;
      if (JumpType === "AFF jumps") {
        col1 = 9;
        col2 = 2;
        col3 = 7;
      }
      else if (JumpType === "tandem jumps") {
        col1 = 7;
        col2 = 4;
        col3 = 7;
      }
      else if (JumpType === "coach jumps") {
        col1 = 6;
        col2 = 3;
        col3 = 5;
      }
      else if (JumpType === "fun jumps" || JumpType === "collections") {
        col1 = 3;
        col2 = 2;
        col3 = 5;
      }
      else if (JumpType === "collections") {
        col1 = 2;
        col2 = 6;
        col3 = 3;
      }
      else if (JumpType === "transfers") {
        col1 = 2;
        col2 = 6;
        col3 = 3;
      }

      return (
        // <TableHead key={`statementHeader`}>
        <TableRow key={'detailHeader'}>
          <TableCell
            key={`detailHeaderCell1`}
            colSpan={col1}
            component="th"
            className={classes.subHeadings}>
          </TableCell>
          <TableCell
            key={`detailHeaderCell2`}
            colSpan={col2}
            component="th"
            className={classes.subHeadings}>
            <h3> {JumpType} detail</h3>
          </TableCell>
          <TableCell
            key={`detailHeaderCell3`}
            colSpan={col3}
            component="th"
            className={classes.subHeadings}>
            <b>({moment(range.dateRange[index].startDate).format('L')} - {moment(range.dateRange[index].endDate).format('L')})</b>
          </TableCell>
        </TableRow>
        // </TableHead>
      )
    }
    else
      return null
  }

  renderSummary = () => {
    let { classes } = this.props;
    return (
      <Fragment>
        <TableRow key={`footerRow`}>
          {
            Object.keys(this.state.summary).map((key, index) => {
              if (key.toLowerCase().includes("credit") || key.toLowerCase().includes("debit") || key.toLowerCase().includes("paid") || key.toLowerCase().includes("amount") || key.toLowerCase().includes("fees") || key.toLowerCase().includes("tax") || key.toLowerCase().includes("total") || key.toLowerCase().includes("discountvalue"))
                return <Fragment>
                  <TableCell key={`footerKeyCell${key + index}`} className={classes.detailtableCell} colSpan="2" style={{ border: "none" }}>
                    {key}
                  </TableCell>
                  <TableCell key={`footerSummary${key + index}`} className={classes.detailtableCell} colSpan="2" style={{ border: "none" }}>
                    {this.checkForNegatives(this.state.summary[key])}
                  </TableCell>
                </Fragment>
              return <Fragment>
                <TableCell key={`footerKeyCell${key + index}`} className={classes.detailtableCell} colSpan="2" style={{ border: "none" }}>{key}</TableCell>
                <TableCell key={`footerSummary${key + index}`} className={classes.detailtableCell} colSpan="2" style={{ border: "none" }}>{this.state.summary[key]}</TableCell>
              </Fragment>
            })
          }
        </TableRow>
      </Fragment>
    )
  }

  filterPdfData = () => {
    let mainArray = [];
    let summaryArray = [];
    let { classes, range } = this.props;
    const { data, headers } = this.state;
    let rows = [];
    var rowlength = 3;
    var pageStyle = { display: 'none', width: '23cm' };
    const index = range.dateRange.findIndex(range => range.modalType === constants.DETAIL_BY_CATEGORY);
    if (index != -1) {
      var JumpType = range.dateRange[index].JumpType;
      if (JumpType === "tandem jumps") {
        pageStyle = { display: 'none', width: '32cm' };
      }
      else if (JumpType === "AFF jumps") {
        pageStyle = { display: 'none', width: '42cm' };;
      }
    }


    if (JumpType === "collections") {
      summaryArray.push(
        <Fragment>
          <TableRow key={`pdffooterRow`}>
            {
              Object.keys(this.state.summary).map(key => {
                if (key.toLowerCase().includes("credit") || key.toLowerCase().includes("debit") || key.toLowerCase().includes("paid") || key.toLowerCase().includes("amount") || key.toLowerCase().includes("fees") || key.toLowerCase().includes("tax") || key.toLowerCase().includes("total") || key.toLowerCase().includes("discountvalue"))
                  return <Fragment>
                    <TableCell key={`pdffooterKeyCell${key}`} className={classes.tableCell} colSpan="2">
                      {key}
                    </TableCell>
                    <TableCell key={`pdffooterSummary${key}`} className={classes.tableCell} colSpan="2">
                      {this.checkForNegatives(this.state.summary[key])}
                    </TableCell>
                  </Fragment>
                return <Fragment>
                  <TableCell key={`pdffooterKeyCell${key}`} className={classes.tableCell} colSpan="2">{key}</TableCell>
                  <TableCell key={`pdffooterSummary${key}`} className={classes.tableCell} colSpan="2">{this.state.summary[key]}</TableCell>
                </Fragment>
              })
            }
          </TableRow>
        </Fragment>
      )
    }
    else {
      summaryArray.push(
        <Fragment>
          <TableRow key={`pdffooterRow`}>
            {
              Object.keys(this.state.summary).map(key => {
                if (key.toLowerCase().includes("credit") || key.toLowerCase().includes("debit") || key.toLowerCase().includes("paid") || key.toLowerCase().includes("amount") || key.toLowerCase().includes("fees") || key.toLowerCase().includes("tax") || key.toLowerCase().includes("total") || key.toLowerCase().includes("discountvalue"))
                  return <Fragment>
                    <TableCell key={`pdffooterKeyCell${key}`} className={classes.tableCell} colSpan="2">
                      {key}
                    </TableCell>
                    <TableCell key={`pdffooterSummary${key}`} className={classes.tableCell} colSpan="2">
                      {this.checkForNegatives(this.state.summary[key])}
                    </TableCell>
                  </Fragment>
                return <Fragment>
                  <TableCell key={`pdffooterKeyCell${key}`} className={classes.tableCell} colSpan="2">{key}</TableCell>
                  <TableCell key={`pdffooterSummary${key}`} className={classes.tableCell} colSpan="2">{this.state.summary[key]}</TableCell>
                </Fragment>
              })
            }
          </TableRow>
        </Fragment>
      )
    }

    rows.push(
      this.renderReportHeader()
    )

    rows.push(
      <TableRow key={'headTableRowSubHeadings'}>
        {
          this.state.headers.map((header, index) => {
            return <TableCell
              key={`pdfheaderCell${index}`}
              component="th"
              colSpan="2"
              className={classes.subHeadings}>
              {header}
            </TableCell>
          })
        }
      </TableRow>)

    data.map((item, index) => {
      rowlength++;
      rows.push(
        <Fragment>
          <TableRow key={`pdfdetailRow${index}`} className={classes.pdfRow}>
            {
              Object.keys(item).map((key, index) => {
                if (key.toLowerCase().includes("price") || key.toLowerCase().includes("pay") || key.toLowerCase().includes("amount") || key.toLowerCase().includes("fees") || key.toLowerCase().includes("tax") || key.toLowerCase().includes("total") || key.toLowerCase().includes("discountvalue"))
                  return <TableCell
                    colSpan="2"
                    key={`pdfdataCell${index + key}`}
                    className={classes.detailtableCell}>
                    {this.checkForNegatives(item[key])}
                  </TableCell>
                if (key.toLowerCase().includes("date"))
                  return <TableCell
                    colSpan="2"
                    key={`pdfdataCell${index + key}`}
                    className={classes.detailtableCell}>
                    {(new Date(item[key])).toLocaleDateString()} {(new Date(item[key])).toLocaleTimeString()}
                  </TableCell>
                return <TableCell
                  colSpan="2"
                  key={`pdfdataKeyCell${index + key}`}
                  className={classes.detailtableCell}>
                  {item[key]}
                </TableCell>
              })
            }
          </TableRow>
        </Fragment>
      )


      if (rowlength >= 28 && data.length - 1 === index) {
        mainArray.push(
          <div className="detialsCategoryPanel" style={pageStyle}>
            <div style={{ height: '15px' }}></div>
            {rows}
            {summaryArray}
          </div>);
        rowlength = 1;
        rows = [];
      }
      else if (rowlength >= 28) {
        mainArray.push(
          <div className="detialsCategoryPanel" style={pageStyle}>
            <div style={{ height: '15px' }}></div>
            {rows}
          </div>);
        rowlength = 1;
        rows = [];
      }
      else if (data.length - 1 === index) {
        mainArray.push(
          <div className="detialsCategoryPanel" style={pageStyle}>
            <div style={{ height: '15px' }}></div>
            {rows}
            {summaryArray}
          </div>);
        rowlength = 1;
        rows = [];
      }
    })
    return mainArray;
  }


  render() {
    const override = `
            display: block;
            margin: 0 auto;
            border-color: red;
        `;
    const { filterData, data, Isdata } = this.state;
    const { classes, submit } = this.props;
    return (
      <Fragment>
        <div className='report-loading inreports'>
          <DonutLoader isLoading={this.state.isloading} />
        </div>
        <div className="data_category">
          <DataTable
            center={true}
            striped={true}
            responsive={true}
            pagination={true}
            paginationPerPage={25}
            customStyles={customStyles}
            data={this.state._data}
            columns={this.state.itemHeader}
          />
          {this.renderSummary()}
          {this.filterPdfData()}
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(DetailByCategory));
