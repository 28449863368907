import React, { Component } from 'react';
import jumpersStore from '../../stores/jumpers-store';
import accountStore from '../../stores/account-store';
import reportStore from '../../stores/report-store';
import reservationStore from '../../stores/reservation-store';
import scheduleStore from '../../stores/schedule-store';
import Api from '../../services/reservationapi';
import RefundModal from './modals/refundModal';
import ViewDetail from './modals/jumperDetail';
import {
	Grid,
	Button,
	Typography,
	Hidden,
} from '@material-ui/core';
import AddItem from './modals/addItem';
import ChargeJumper from './modals/chargeJumper';
import TransferCredit from './modals/transferCredit';
import RefundConfirMation from './modals/refundConfirmation'


class AccountsPageRight extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dailyActivity: {},
			otherActivities: [],
			jumperId: '',
			timeslots: [],
			transactionAmount: this.props.transactionAmount,
			transactionId: this.props.transactionId,
			isTandemStudent: false
		}
		this.jumperEvents = [
			{ name: jumpersStore.getEventPrefix('dailyActivity'), func: this.handleDailyActivity },
			{ name: jumpersStore.getEventPrefix('transfer'), func: this.handleTransfer },
		];
		this.accountsEvents = [
			{ name: accountStore.getEventPrefix('itemsAdded'), func: this.handleItemsAdded },
			{ name: accountStore.getEventPrefix('chargeAdded'), func: this.handleChargeAdded }
		];
		this.scheduleEvents = [
			{
				name: scheduleStore.getEventPrefix("todayReservationEvent"),
				func: this.handleReservationEvent
			}
		];
		//console.log("this.props.transactionAmount",this.props.transactionAmount,this.props.transactionId);
		// this.transactionId = '';
		// this.amount = 0;
	}

	componentDidUpdate = () => {
		window.globals = { ...window.globals };
		window.globals.LoadManagerRight = this.state;
	}

	componentWillMount = () => {

		this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
		this.accountsEvents.forEach((evnt) => accountStore.on(evnt.name, evnt.func));
		this.scheduleEvents.forEach(evnt => scheduleStore.on(evnt.name, evnt.func));
		scheduleStore.fetchTodayReservation();

		if (window.globals && window.globals['LoadManagerRight']) {
			this.setState({ ...window.globals['LoadManagerRight'] });
		}
	}

	componentWillUnmount = () => {
		this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
		this.accountsEvents.forEach((evnt) => accountStore.removeListener(evnt.name, evnt.func));
		this.scheduleEvents.forEach(evnt =>
			scheduleStore.removeListener(evnt.name, evnt.func)
		);
	}

	handleItemsAdded = (data) => {
		//same as handle DailyActivity for now
		//console.log('in handleItemsAdded');
		//console.log(data.dailyActivity);

		this.setState({
			dailyActivity: data.dailyActivity
		});
	}

	handleReservationEvent = data => {
		console.log('xxxbundata', data);
		this.setState({
			timeslots: data.timeslots
		});
	};

	getDiscountValue = () => {
		let globalDiscount = 0;
		let discountValue = 0;

		if (this.state.timeslots && this.state.dailyActivity && this.state.dailyActivity.studentData) {
			this.state.timeslots.forEach(timeItem => {
				if (timeItem.reservations) {
					timeItem.reservations.forEach(reservation => {
						if (reservation.discountValue && reservation.tandemStudents) {
							globalDiscount = reservation.discountValue;
							reservation.tandemStudents.forEach(stud => {
								if (new Date(stud.reservationTime).getTime() == new Date(this.state.dailyActivity.studentData.reservationTime).getTime() && stud._id.toString() == this.state.dailyActivity.studentId) {
									const groupMemebers = reservation.tandemStudents.reduce((acc, value) => {
										if (new Date(stud.reservationTime).getTime() == new Date(this.state.dailyActivity.studentData.reservationTime).getTime() && value.groupId === this.state.dailyActivity.studentData.studentGroupId) {
											acc = acc + 1;
										}
										return acc;
									}, 0)
									if (groupMemebers > 1) {
										discountValue = globalDiscount / groupMemebers;
									} else {
										discountValue = globalDiscount;
									}
								}
							})
						}
					})
				}
			})
		}
		return discountValue;
	}

	handleChargeAdded = (data) => {
		//console.log('in handle charge added');
		this.setState({
			dailyActivity: data.dailyActivity,
			otherActivites: data.otherActivites
		})
	}

	handleDailyActivity = (data) => {
		//console.log('in handleDailyActivity');
		//console.log(dailyActivity);
		console.log(data.dailyActivity);
		console.log(data.otherActivities);
		// console.log(data.dailyActivity);
		this.setState({
			dailyActivity: data.dailyActivity,
			otherActivities: data.otherActivities,
			jumperId: data.jumperId,
			isTandemStudent: data.isTandemStudent
		});
	}

	handleTransfer = (data) => {
		//console.log('in handleDailyActivity');
		//console.log(dailyActivity);

		//console.log(data.dailyActivity);
		this.setState({
			dailyActivity: data.fromActivity,
			jumperId: data.fromId
		});
	}

	// componentWillReceiveProps()
	// {

	// 	if(this.props.data)
	// 	{
	// 		this.setState({
	// 			JumperId: this.props.data._id
	// 		});
	// 	}

	// }

	getPrice(priceSchedule, type) {
		var index = priceSchedule.findIndex(x => x.type == type);
		var price = 0
		if (priceSchedule[index]) {
			price = priceSchedule[index].price;
		}
		return price;
	}

	checkForNegatives = (price) => {
		//console.log('check for negatives: ' + price);
		if (price != undefined) {
			let priceCell = (price < 0) ?
				{
					value: `($${Math.abs(price).toFixed(2)})`
				} :
				{
					value: `$${price.toFixed(2)}`
				}

			if (price < 0) {
				return <span style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
			}
			return priceCell.value;
		}
		else {
			return '$0.00';
		}
	}

	renderMemo = (memo) => {
		return <tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"memo: " + memo}</td></tr>
	}

	refundJumper = (charge) => {

		console.log('in refund Jumper account right');
		var body = {
			transactionType: charge.transactionType,
			transactionId: charge.transactionId,
			amount: -charge.amount,
			isRefund: true
		};

		if (charge.transactionType === "Cash") {
			accountStore.ChargeJumper(this.state.jumperId, body);
		}
	}

	getLabel = (charge) => {
		if (charge.amount !== undefined && charge.isRefund) {
			return "REFUND";
		}
		else if (charge.amount !== undefined && (charge.transactionType === "Cash Payout" || charge.transactionType === "Check Payout" || charge.transactionType.indexOf('Account-') > 0)) {
			return "PAYOUT"
		}
		else if (charge.amount !== undefined) {
			return "CHARGE"
		}
	}

	renderSummary = (activity) => {
		console.log(activity);
		var priceSchedule = reportStore.priceSchedule;
		var paySchedule = reportStore.paySchedule;

		var today = new Date();
		var isWeekend = (today.getDay() % 6 === 0);

		var aff1Jumps = 0;
		var aff2_3Jumps = 0;
		var aff4_7Jumps = 0;
		var coachJumps = 0;
		var coachedJumps = 0;
		var funJumps = 0;
		var gearRentals = 0;
		var hopnpops = 0;
		var solos = 0;
		var tandemJumps = 0;
		var videoJumps = 0;

		var tandemJumpsPrice = 0;
		var affInstructorPrice = 0;
		var aff1Price = 0;
		var aff2_3Price = 0;
		var aff4_7Price = 0;
		var videographerJumps = 0;

		if (priceSchedule.length > 0) {

			affInstructorPrice = this.getPrice(paySchedule, "aff instructor");
			aff1Price = this.getPrice(priceSchedule, "AFF1");
			aff2_3Price = this.getPrice(priceSchedule, "AFF2_3");
			aff4_7Price = this.getPrice(priceSchedule, "AFF4_7");
			coachJumps = this.getPrice(paySchedule, "coach");
			coachedJumps = this.getPrice(priceSchedule, 'coach');

			funJumps = this.getPrice(priceSchedule, "fun jump");
			gearRentals = this.getPrice(priceSchedule, "gear rental 1");
			hopnpops = this.getPrice(priceSchedule, "hopnpop");
			solos = this.getPrice(priceSchedule, "solo");
			videoJumps = this.getPrice(priceSchedule, "video");
			videographerJumps = this.getPrice(paySchedule, "videographer");
		}
		var Aff1InstructorPrice = parseInt(activity.aff1Jumps) * parseInt(affInstructorPrice);
		var Aff3InstructorPrice = parseInt(activity.aff2_3Jumps) * parseInt(affInstructorPrice);
		var Aff7InstructorPrice = parseInt(activity.aff4_7Jumps) * parseInt(affInstructorPrice);
		var Aff1Price = parseInt(activity.aff1) * parseInt(aff1Price);
		var Aff3Price = parseInt(activity.aff2_3) * parseInt(aff2_3Price);
		var Aff7Price = parseInt(activity.aff4_7) * parseInt(aff4_7Price);

		var funJumpPrice = parseInt(activity.funJumps) * parseInt(funJumps);
		var videoJumpsPrice = parseInt(activity.videoJumps) * parseInt(videoJumps);
		var coachJumpsPrice = parseInt(activity.coachJumps) * parseInt(coachJumps);
		var coachedJumpsPrice = parseInt(activity.coachedJumps) * parseInt(coachedJumps);
		var hopnpopsPrice = parseInt(activity.hopnpops) * parseInt(hopnpops);
		var solosPrice = parseInt(activity.solos) * parseInt(solos);
		var gearRentalsPrice = parseInt(activity.gearRentals) * parseInt(gearRentals);
		var tandemPrice = parseInt(activity.tandemJumps) * parseInt(tandemJumpsPrice);

		var counterSalesPrice = 0;

		var countersales = null;
		if (activity.countersales) {
			countersales = activity.countersales.map(sale => {
				counterSalesPrice = counterSalesPrice + sale.price;
				// return(<p style={{textAlign:'left',paddingLeft:'8px'}}><div style={{width:'134px !important',display:'inline-block'}}>{sale.item.toUpperCase()}</div> : {sale.count} : ${sale.price}</p>)
				if (sale.item != undefined) {
					return (
						<React.Fragment>
							<tr><td>{sale.item !== undefined ? sale.item.toUpperCase() : 'Item'}</td> <td> {sale.count}</td> <td> {this.checkForNegatives(sale.price)}</td>{sale.item !== "transfer" ? <td><button className="btn lightBlueButton" onClick={() => accountStore.DeleteItem(this.state.jumperId, sale._id)}><i className="fa fa-times" /> </button></td> : null}</tr>
							{sale.description !== undefined ? <tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{sale.description}</td></tr> : ''}
							{sale.memo !== undefined && sale.memo !== "" ? this.renderMemo(sale.memo) : null}
						</React.Fragment>
					)
				}
			});
		}

		var charges = null;
		if (activity.charges) {
			charges = activity.charges.map(charge => {

				if (charge.amount !== undefined) {
					return (
						<React.Fragment>
							<tr><td>{this.getLabel(charge)}</td>
								<td></td>
								<td>{this.checkForNegatives(charge.amount)}</td>
								{
									!charge.isRefund ?
										<td>
											{/* <button className="btn lightBlueButton" onClick ={() => this.refundJumper(charge)}>
										<i className="fa fa-undo" />
									</button> */}

											<RefundConfirMation jumperId={this.state.jumperId} chargeValue={charge} isTandemStudent={this.props.isTandemStudent} />
										</td>
										: null
								}</tr>
							<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{charge.transactionType.toUpperCase()}</td></tr>
							{charge.memo ? this.renderMemo(charge.memo) : null}
						</React.Fragment>
					)
				}
			});
		}

		var totalBalance = Aff1InstructorPrice + Aff3InstructorPrice + Aff7InstructorPrice + funJumpPrice + videoJumpsPrice + coachJumpsPrice + hopnpopsPrice + solosPrice +
			+ gearRentalsPrice + tandemPrice + counterSalesPrice;
		const discountValue = this.getDiscountValue();
		return (
			<div className="">
				<h4 style={{ marginLeft: '9px' }}>{activity.name}</h4>
				{/* <table className="StaffSummary StaffSummary" style={{ marginLeft: '6px', width: '97%', height: '200px', display: 'inline-block', overflow: 'auto' }}> */}
				<table className="StaffSummary">
					<tbody>
						{activity.pilot !== undefined && activity.pilot.count !== 0 ?
							<tr>
								<td>PILOT FLIGHTS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.pilot.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.pilot.value)}</td>
							</tr>
							: null}
						{activity.tandemStudentJumps > 0 ?
							<tr>
								<td>TANDEMS</td>
								<td>{activity.tandemStudentJumps}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.tandemValue)}</td>
							</tr>
							: null}
						{discountValue > 0 && activity.tandemStudentJumps > 0 ?
							<tr>
								<td>DISCOUNT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br /> {this.state.dailyActivity.discountName} </td>
								<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>({this.checkForNegatives(discountValue)})</td>
							</tr>
							: null}
						{activity.tandemStudentJumps > 0 && activity.videos !== 0 ?
							<tr>
								<td>TANDEM VIDEOS</td>
								<td>{activity.videos}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.videosValue)}</td>
							</tr>
							: null
						}
						{activity.tandemStudentJumps > 0 && activity.wristcamVideos !== 0 ?
							<tr>
								<td>WRISTCAM VIDEOS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.wristcamVideos}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.wristcamVideosValue)}</td>
							</tr>
							: null
						}
						{activity.tandemStudentJumps > 0 && activity.overweightPrice !== 0 ?
							<tr>
								<td>OVERWEIGHT PRICE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.overweightCount}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.overweightPrice)}</td>
							</tr>
							: null}

						{activity.observerCount > 0 ?
							<tr>
								<td>OBSERVER RIDES &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{activity.observerCount}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.observerValue)}</td>
							</tr>
							: null
						}
						{activity.aff1 !== undefined && activity.aff1.count !== 0 ?
							<tr>
								<td>AFF 1 JUMPS</td>
								<td>{activity.aff1.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.aff1.value)}</td>
							</tr>
							: null}
						{activity.aff2_3 !== undefined && activity.aff2_3.count !== 0 ?
							<tr>
								<td>AFF 2-3 JUMPS</td>
								<td>{activity.aff2_3.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.aff2_3.value)}</td>
							</tr>
							: null}
						{this.state.dailyActivity.aff4_7 !== undefined && activity.aff4_7.count !== 0 ?
							<tr>
								<td>AFF 4-7 JUMPS</td>
								<td>{activity.aff4_7.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.aff4_7.value)}</td>
							</tr>
							: null}
						{activity.funJumps !== undefined && activity.funJumps.count !== 0 ?
							<tr>
								<td>FUN JUMPS</td>
								<td>{activity.funJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.funJumps.value)}</td>
							</tr>
							: null}
						{activity.aff1Jumps !== undefined && activity.aff1Jumps.count !== 0 ?
							<tr>
								<td>AFF 1 INSTRUCTOR JUMPS</td>
								<td>{activity.aff1Jumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(this.state.dailyActivity.aff1Jumps.value)}</td>
							</tr>
							: null}
						{activity.aff2_3Jumps !== undefined && activity.aff2_3Jumps.count !== 0 ?
							<tr>
								<td>AFF 2-3 INSTRUCTOR JUMPS</td>
								<td>{activity.aff2_3Jumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.aff2_3Jumps.value)}</td>
							</tr>
							: null}
						{activity.aff4_7Jumps !== undefined && activity.aff4_7Jumps.count !== 0 ?
							<tr>
								<td>AFF 4-7 INSTRUCTOR JUMPS</td>
								<td>{activity.aff4_7Jumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.aff4_7Jumps.value)}</td>
							</tr>
							: null}

						{activity.tandemJumps !== undefined && activity.tandemJumps.count !== 0 ?
							<tr>
								<td>TANDEM JUMPS</td>
								<td>{activity.tandemJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.tandemJumps.value)}</td>
							</tr>
							: null}

						{activity.tandemUltraJumps !== undefined && activity.tandemUltraJumps.count !== 0 ?
							<tr>
								<td>TANDEM ULTRAS</td>
								<td>{activity.tandemUltraJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.tandemUltraJumps.value)}</td>
							</tr>
							: null}

						{
							activity.overweightPay !== undefined && activity.overweightPay.count !== 0 ?
								<tr>
									<td>OVERWEIGHT PAY&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
									<td>{activity.overweightPay.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
									<td>{this.checkForNegatives(activity.overweightPay.value)}</td>
								</tr>
								: null
						}

						{
							activity.wristcamJumps !== undefined && activity.wristcamJumps.count !== 0 ?
								<tr>
									<td>WRISTCAM JUMPS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
									<td>{activity.wristcamJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
									<td>{this.checkForNegatives(activity.wristcamJumps.value)}</td>
								</tr>
								: null
						}

						{activity.videoJumps !== undefined && activity.videoJumps.count !== 0 ?
							<tr>
								<td>VIDEO JUMPS </td>
								<td>{activity.videoJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.videoJumps.value)}</td>
							</tr>
							: null}

						{activity.withVideoJumps !== undefined && activity.withVideoJumps.count !== 0 ?
							<tr>
								<td>VIDEOS </td>
								<td>{activity.withVideoJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.withVideoJumps.value)}</td>
							</tr>
							: null}

						{activity.coachJumps !== undefined && activity.coachJumps.count !== 0 ?
							<tr>
								<td>COACH JUMPS </td>
								<td>{activity.coachJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.coachJumps.value)}</td>
							</tr>
							: null}
						{activity.coachedJumps !== undefined && activity.coachedJumps.count !== 0 ?
							<tr>
								<td>COACHED JUMPS </td>
								<td>{activity.coachedJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.coachedJumps.value)}</td>
							</tr>
							: null}


						{activity.wingsuitCoachJumps !== undefined && activity.wingsuitCoachJumps.count !== 0 ?
							<tr>
								<td>WINGSUIT COACH JUMPS </td>
								<td>{activity.wingsuitCoachJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.wingsuitCoachJumps.value)}</td>
							</tr>
							: null}
						{activity.wingsuitCoachedJumps !== undefined && activity.wingsuitCoachedJumps.count !== 0 ?
							<tr>
								<td>WINGSUIT COACHED JUMPS </td>
								<td>{activity.wingsuitCoachedJumps.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.wingsuitCoachedJumps.value)}</td>
							</tr>
							: null}


						{activity.hopnpops !== undefined && activity.hopnpops.count !== 0 ?
							<tr>
								<td>HOP N POPS </td>
								<td>{activity.hopnpops.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.hopnpops.value)}</td>
							</tr>
							: null}

						{activity.solos !== undefined && activity.solos.count !== 0 ?
							<tr>
								<td>SOLO </td>
								<td>{activity.solos.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.solos.value)}</td>
							</tr>
							: null}

						{activity.gearRentals !== undefined && activity.gearRentals.count !== 0 ?
							<tr>
								<td>GEAR RENTALS </td>
								<td>{activity.gearRentals.count}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{this.checkForNegatives(activity.gearRentals.value)}</td>
							</tr>
							: null}

						{countersales}
						{charges}

						<tr style={{ visibility: 'hidden' }}>
							<td>PREVIOUS BALANCE 	</td>
							<td></td>
							<td></td>
						</tr>

					</tbody>
				</table>
				{/*
				{this.state.dailyActivity.funJumps > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}> <div style={{width:'134px',display:'inline-block'}}>FUN JUMPS</div> :  {this.state.dailyActivity.funJumps} : {this.checkForNegatives(funJumpPrice)}</p> : null}
				{this.state.dailyActivity.aff2_3Jumps > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}><div style={{width:'134px',display:'inline-block'}}> AFF 1-3 JUMPS</div> : {this.state.dailyActivity.aff2_3Jumps} : {this.checkForNegatives(Aff3Price)}</p> : null}
				{this.state.dailyActivity.aff4_7Jumps > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}><div style={{width:'134px',display:'inline-block'}}>AFF 4-7 JUMPS </div>:  {this.state.dailyActivity.aff4_7Jumps} : {this.checkForNegatives(Aff7InstructorPrice)}</p> : null}
				{this.state.dailyActivity.tandemJumps > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}><div style={{width:'134px',display:'inline-block'}}>TANDEM JUMPS </div>:  {this.state.dailyActivity.tandemJumps} : {this.checkForNegatives(tandemPrice)}</p> : null}
				{this.state.dailyActivity.videoJumps > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}><div style={{width:'134px',display:'inline-block'}}>VIDEO JUMPS </div>: {this.state.dailyActivity.videoJumps} : {this.checkForNegatives(videoJumpsPrice)} </p> : null}
				{this.state.dailyActivity.coachJumps > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}><div style={{width:'134px',display:'inline-block'}}>COACH JUMPS </div>:  {this.state.dailyActivity.coachJumps} : {this.checkForNegatives(coachJumpsPrice)}</p> : null}
				{this.state.dailyActivity.hopnpops > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}><div style={{width:'134px',display:'inline-block'}}>HOP N POPS </div>:   {this.state.dailyActivity.hopnpops} : {this.checkForNegatives(hopnpopsPrice)}</p> : null}
				{this.state.dailyActivity.gearRentals > 0 ? <p style={{ textAlign: 'left', paddingLeft: '8px' }}><div style={{width:'134px',display:'inline-block'}}>GEAR RENTALS </div>: {this.state.dailyActivity.gearRentals} : {this.checkForNegatives(gearRentalsPrice)} </p> : null}
				{countersales}
				*/}
			</div>
		)
	}

	render() {
		//console.log("this.props.transactionAmount",this.props.transactionAmount,this.props.transactionId);

		// var currentBalanceString = this.state.dailyActivity.currentBalance >= 0 ? this.checkForNegatives(this.state.dailyActivity.currentBalance) : '($' + this.state.dailyActivity.currentBalance + ')';
		// var todayBalanceString = this.state.dailyActivity.todayBalance >= 0 ? this.checkForNegatives(this.state.dailyActivity.todayBalance) : '($' + this.state.dailyActivity.todayBalance + ')';
		// var totalBalanceString = this.state.dailyActivity.totalBalance >= 0 ? this.checkForNegatives(this.state.dailyActivity.totalBalance) : '($' + this.state.dailyActivity.totalBalance + ')';
		// var totalPaidString = this.state.dailyActivity.todayPaid >= 0 ? this.checkForNegatives(this.state.dailyActivity.todayPaid) : '($' + this.state.dailyActivity.todayPaid + ')';
		const discountValue = this.getDiscountValue();
		var currentBalanceString = this.state.dailyActivity.currentBalance >= 0 ? this.checkForNegatives(this.state.dailyActivity.currentBalance + discountValue) : this.checkForNegatives(this.state.dailyActivity.currentBalance + discountValue);
		var todayBalanceString = this.state.dailyActivity.todayBalance >= 0 ? this.checkForNegatives(this.state.dailyActivity.todayBalance - discountValue) : this.checkForNegatives(this.state.dailyActivity.todayBalance - discountValue);
		var totalBalanceString = this.state.dailyActivity.totalBalance >= 0 ? this.checkForNegatives(this.state.dailyActivity.totalBalance) : this.checkForNegatives(this.state.dailyActivity.totalBalance);
		var totalPaidString = this.state.dailyActivity.todayPaid >= 0 ? this.checkForNegatives(this.state.dailyActivity.todayPaid) : this.checkForNegatives(this.state.dailyActivity.todayPaid);
		var totalPayoutString = this.state.dailyActivity.todayPayout >= 0 ? this.checkForNegatives(this.state.dailyActivity.todayPayout) : this.checkForNegatives(this.state.dailyActivity.todayPayout);
		//console.log(this.state.dailyActivity);
		//console.log(todayBalanceString);
		//console.log(totalBalanceString);
		var countersales = null;
		if (this.state.dailyActivity.countersales) {
			countersales = this.state.dailyActivity.countersales.map(sale => {
				return (<p style={{ textAlign: 'left', paddingLeft: '8px' }}>{sale.item != undefined ? sale.item.toUpperCase() : ''} : {sale.count} : {this.checkForNegatives(sale.price)}</p>)
			});
		}
		//alert(JSON.stringify(this.props.data));

		var jumperId = this.state.dailyActivity.isTandemStudent ? this.props.data : this.props.data._id;

		const { chargeValue } = this.state;
		return (
			<React.Fragment>
				{/* <div className="col-md-2 col-lg-2 col-sm-12 blueBg fullHeightPanes rightCol" style={{ padding: '0px',zIndex:99,minHeight:'100%' }}> */}
				<div style={{ height: '40vh', overflow: 'hidden' }}>
					<div className="columnHeading" >SUMMARY (TODAY)</div>

					{this.renderSummary(this.state.dailyActivity)}
					{this.state.otherActivities !== undefined ? this.state.otherActivities.map(activity => this.renderSummary(activity)) : null}
					{/*this.state.otherActivities[0]?this.renderSummary(this.state.otherActivities[0]) : null*/}
					{/*this.state.otherActivities.map(activity => this.renderSummary(activity))*/}

				</div>


				<div className="">

					<div className="ViewAccounts-totalSection-246" style={{ paddingTop: 10, paddingBottom: 10 }}>

						<table className="StaffSummary" style={{ marginLeft: '6px', width: '97%' }}>
							<tbody>
								<tr>
									<td>PREVIOUS BALANCE </td>
									<td>{currentBalanceString !== "($ undefined)" ? currentBalanceString : "$0"}</td>
								</tr>
								<tr>
									<td>TODAYS BALANCE </td>
									<td> {todayBalanceString !== "($undefined)" ? todayBalanceString : "$0"}</td>
								</tr>
								<tr>
									<td>TOTAL PAID </td>
									<td>{totalPaidString !== "($undefined)" ? totalPaidString : "$0"}</td>

								</tr>
								<tr>
									<td>TOTAL PAYOUT </td>
									<td>{totalPayoutString !== "($undefined)" ? totalPayoutString : "$0"}</td>
								</tr>
								<tr>
									<td>TOTAL BALANCE </td>
									<td>{totalBalanceString !== "($undefined)" ? totalBalanceString : "$0"}</td>
								</tr>
							</tbody>
						</table>

					</div>

					{/* {
						!this.props.chargeDisable ?
							<div>
								<RefundModal transactionAmount={this.props.transactionAmount} transactionId={this.props.transactionId} />
							</div>
							:
							<div>
								<button className="btn customButton rightButton" style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764' }} disabled>
									Refund
               	</button>
							</div>
					} */}

					{
						this.state.jumperId !== '' &&
						<ViewDetail jumperId={this.state.jumperId} />
					}

					{
						this.state.jumperId !== '' && !this.state.isTandemStudent &&
						<TransferCredit jumperId={this.state.jumperId} />
					}

					{
						!this.props.isDisabled ?
							<div>
								<ChargeJumper jumperId={this.state.jumperId} token={this.props.data.cardsOnFile} value={Number(this.state.dailyActivity.totalBalance).toFixed(2)} isTandemStudent={this.state.dailyActivity.isTandemStudent} />
								<AddItem jumperId={this.state.jumperId} isTandemStudent={this.state.dailyActivity.isTandemStudent} />
							</div>
							:
							<div>
								<button className="btn customButton rightButton" style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764', marginTop: '65%', marginBottom: '8px' }} disabled>
									CHARGE JUMPER
								</button>
								<button className="btn customButton rightButton" style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764' }} disabled>
									ADD ITEM
								</button>
							</div>
					}

				</div>
				{/* </div> */}
			</React.Fragment>
		);
	}
}

export default AccountsPageRight;
