import React, { Component } from 'react'
import { Table } from 'reactstrap';

import CreateGiftcard from '../modals/createGiftcard';
import RemoveItem from '../modals/removeItem';

export default class Giftcards extends Component {

    renderGiftcards = () => {
        let { giftcards, categories } = this.props;
        console.log("giftcards", giftcards)
        if (giftcards != undefined) {
            return giftcards.map((item, index) => {
                return (
                    <tr key={`discountRow${index}`}>
                        <th scope="row" data-id={item._id}>{index + 1}</th>
                        <td>{item.item}</td>
                        <td>{item.tickets}</td>
                        <td>{item.expiryInDays}</td>
                        <td >${Math.abs(item.price).toFixed(2)}</td>
                        <td >{!isNaN(item.taxableAmount) ? "$" + Math.abs(item.taxableAmount).toFixed(2) : 0}</td>
                        <td>{item.tandemPackageName}</td>
                        <td>{item.videoPackageName}</td>
                        <td>{item.studentPackageName}</td>
                        <td>{item.isActive ? 'yes' : 'no'}</td>
                        <td>
                            <CreateGiftcard isEdit={true} item={item} categories={categories} />&nbsp;&nbsp;
                            <RemoveItem item={item} />
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    returnUniqueIndex = () => {
        let { categories, giftcards } = this.props;
        let uniqueArr = giftcards.map(ele => ele.imageIndex);
        let uniqueIndex = 1;
        for (let i = 0; i < giftcards.length; i++) {
            if (uniqueArr.includes(uniqueIndex)) {
                uniqueIndex = uniqueIndex + 1;
            }
            else {
                break;
            }
        }
        return uniqueIndex;
    }

    render() {
        let { categories, giftcards } = this.props;
        let uniqueIndex = this.returnUniqueIndex();
        return (
            <Table>
                <thead className="main-heading">
                    <tr>
                        <th>
                            <CreateGiftcard categories={categories} uniqueIndex={uniqueIndex} />
                        </th>
                        <th>Name</th>
                        <th>Tickets</th>
                        <th>Num redeemed</th>
                        <th>Price</th>
                        <th>Taxable Amount</th>
                        <th>Tandem Package</th>
                        <th>Video Package</th>
                        <th>Student Package</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="priceHeading">
                        <td colSpan="3"></td>
                    </tr>
                    {this.renderGiftcards()}
                </tbody>
            </Table>
        )
    }
}
