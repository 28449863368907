
import React, { Component } from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import jumpersStore from '../../../stores/jumpers-store';
import AdminModal from './customModal';
import Select, { components } from 'react-select';
import loadStore from '../../../stores/loads-store';


class RemovePlane extends Component {

    constructor(props) {

        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
            jumpType: null,
            load: props.load,
        };
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            jumpType: null
        });
    }



    handleSubmit(values, id, plane, capacity,color) {
        //console.log("in handle submit load"+ values);
        this.toggleModal();
        var payload = {};
        if (values != undefined) {
            loadStore.deletePlane(id);
        }
    }

    render() {
        const { _id = '', plane = '', capacity = '',color='' } = this.props.plan || {};

        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                >
                    <p>Are you Sure you want to delete Plane {plane} ! </p>
                    <LocalForm onSubmit={(values) => this.handleSubmit(values, _id, plane, capacity,color)}>
                        <Row className="form-group">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >Submit</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">Cancel</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                <span onClick={this.toggleModal}>
                    <i className="fa fa-trash"></i>
                </span>
            </React.Fragment>
        );
    }
}

export default RemovePlane

