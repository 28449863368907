/**
 * http://usejsdoc.org/
 */
import { create } from 'apisauce';
// define the api
import authStore from '../stores/auth-store';
import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const ipapi = create({
	baseURL: 'https://api.ipdata.co'
})

const func = {};



func.login = (data) => {
	var payload = { ...data };
	// console.log(payload);
	return api.post('/dropzones/login', payload);
}
func.getSettingsByUser = (payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/dropzones/getSettings');
}
func.updateSettingsOfUser = (payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/dropzones/updateSettings', payload);
}

func.changeSettings = (data) => {
	return api.put(`/dropzones/signUpPublicJumper/${data._id}`, data);
}
func.getIp = () => {
	var key = window.ENV?.ipApiKey;
	return ipapi.get(`/?api-key=${key}`);
}

export default func;