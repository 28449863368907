
import React, { Component } from 'react';
import { Col, Row, Form } from 'reactstrap';
import TeamModal from './customModal';

import loadsStore from '../../../stores/loads-store';
import jumpersStore from '../../../stores/jumpers-store';

class RemoveTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    toggleModal = () => {
        //alert('click');
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { _id } = this.props.item || {};
        let id = _id
        jumpersStore.deleteJumper(id)
            .then(r => {
                if (r) {
                    this.props.deleteTeam(_id);
                }
            })
            .catch(r => {
                console.log("Delete ERR: ", r)
            })
        this.setState({ isModalOpen: false })
    }

    render() {

        return (
            <React.Fragment>
                <TeamModal
                    isModalOpen={this.state.isModalOpen}
                >
                    <p>Are you Sure you want to delete! </p>
                    <Form onSubmit={this.handleSubmit}>
                        <Row className="form-group">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >Submit</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">Cancel</button>
                            </Col>
                        </Row>
                    </Form>
                </TeamModal>
                <span onClick={this.toggleModal}>
                    <i className="fa fa-trash"></i>
                </span>
            </React.Fragment>
        );
    }
}

export default RemoveTeam

