import React, { Component } from 'react'
import JmpModal from './customModal';
import { connect } from 'react-redux';
import { Col, Row, } from 'reactstrap';
import { completedLoadJumperChangeConfirmModal } from '../../../actions/reports';

class LoadUpdateModal extends Component {
  handleConfirm = (type) => {
    this.props.openModel({ isModal: false, editType: '' })
  }

  render() {
    return (
      <JmpModal
        isModalOpen={this.props.isOpen}
        title={`Update Completed Load`}
      >
        <span>You are about to modify a completed load, are you sure you want to proceed</span>
        <Row className="form-group m-t-1-rem">
          <Col md={{ size: 10 }}>
            <button className="btn btn-bg lightColor createButton" type="submit" onClick={() => this.props.onClick(true)} >CONFRIM</button>
            {/* <button className="btn btn-bg lightColor createButton" type="submit" onClick={() => this.handleConfirm(this.props.editType)} >CONFRIM</button> */}
            <button onClick={(e) => this.props.onClick(false)} className="btn btn-link">CANCEL</button>
          </Col>
        </Row>
      </JmpModal>
    )
  }
}

const mstp = state => {
  return {
    // isOpen: state.Reports.completedLoadJumperChangeConfirmModal,
    editType: state.Reports.editType
  }
}
const mdtp = dispatch => {
  return {
    openModel: data => dispatch(completedLoadJumperChangeConfirmModal(data)),
  }
}

export default connect(mstp, mdtp)(LoadUpdateModal) 