import React, { Component } from 'react'
import {
    Button, Modal,
    ModalHeader, ModalBody,
    ModalFooter, Form, FormGroup,
    Label, Input, Col
} from 'reactstrap';

import reportStore from '../../../../stores/report-store';


class EmailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            RecipientEmail: "",
            PurchaserEmail: "",
            PurchaserName: "",
            RecipientName: "",
            message: "",
            error: {},
            isError: false,
        }
        this.reportEvents = [
            { name: reportStore.getEventPrefix('giftcardEmailSuccess'), func: this.handleSuccess }
        ];
    }
    // giftcardEmailSuccess
    componentDidMount() {
        this.reportEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func))
        // console.log("🚀 ~ file: EmailModal.js ~ line 13 ~ EmailModal ~ componentDidMount ~ couponDetail", couponDetail)
    }
    componentWillUnmount() {
        this.reportEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }
    validateEmail = (value) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    }
    handleSuccess = () => {
        this.props.toggleModal();
    }
    handleSubmit = () => {
        let { RecipientEmail, PurchaserEmail, PurchaserName, RecipientName, message } = this.state;
        // console.log("🚀 handleSubmit ~~ RecipientEmail, PurchaserEmail,PurchaserName,RecipientName,message ", RecipientEmail, PurchaserEmail, PurchaserName, RecipientName, message)
        let { couponDetail } = this.props;

        let error = {};
        let r1 = this.validateEmail(RecipientEmail);
        let r2 = this.validateEmail(PurchaserEmail);
        error.recipient = !r1;
        error.purchaser = !r2;
        if (r1 && r2) {
            // if valid email submit
            this.setState({ isError: false, error })
            let name = PurchaserName.length > 0 ? PurchaserName : couponDetail.name;
            let messageData = message.length > 0 ? message : couponDetail.message;

            let payload = {
                RecipientEmail,
                PurchaserEmail,
                name,
                RecipientName,
                message: messageData,
                couponId: couponDetail._id,
                expiryInDays: couponDetail.expiryInDays,
                description: couponDetail.description,
                couponCode: couponDetail.couponCode,
                item: couponDetail.item,
                price: couponDetail.price,
                status: couponDetail.status,
                tandemPackageName: couponDetail.tandemPackageName,
                videoPackageName: couponDetail.videoPackageName,
                createdAt: couponDetail.createdAt
            }
            // console.log("payload: ", payload)
            reportStore.resendGiftcardEmail({ payload })
            //this.sumbitform();
        }
        else {
            this.setState({ isError: true, error })
        }
    }

    handleChange = (e, type) => {
        if (type === 'purchaser') {
            this.setState({ PurchaserEmail: e.target.value, isError: false })
        }
        else {
            this.setState({ RecipientEmail: e.target.value, isError: false })
        }
    }
    handleNameChange = (e, type) => {
        if (type === 'purchaser') {
            this.setState({ PurchaserName: e.target.value })
        }
        else {
            this.setState({ RecipientName: e.target.value })
        }
    }
    handleMessageChange = (e) => {
        this.setState({ message: e.target.value })
    }
    isError = (type) => {
        let { error } = this.state;
        // console.log("🚀 ~ file: EmailModal.js ~ line 52 ~ EmailModal ~ error", error,type)
        if (error[type]) {
            return "Please input valid email address !"
        }
    }
    render() {
        let { isModalOpen, toggleModal, couponDetail } = this.props;
        let { RecipientEmail, PurchaserEmail, PurchaserName, RecipientName, message, isError } = this.state;
        // console.log("🚀 ~ file: EmailModal.js ~ line 21 ~ EmailModal ~ render ~ isModalOpen", isModalOpen)

        return (
            <Modal isOpen={isModalOpen} className="giftcard-resend-modal">
                <ModalHeader>Resend Giftcard Email</ModalHeader>
                <ModalBody>
                    <DetailLabel label={'Coupon Code'} value={couponDetail ? couponDetail.couponCode : "Not found"} />
                    <DetailLabel label={'Recipient Email'} value={couponDetail ? couponDetail.email : "Not found"} />

                    <Form onSubmit={e => e.preventDefalut()}>
                        <FormGroup row>
                            <Label sm={4} for="RecipientEmail">Recipient Email : </Label>
                            <Col sm={8}>
                                <Input
                                    type="email" value={RecipientEmail}
                                    name="RecipientEmail"
                                    id="RecipientEmail"
                                    placeholder="Email"
                                    onChange={(e) => this.handleChange(e, 'recipient')}
                                />
                            </Col>
                            <small>{this.isError('recipient')}</small>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={4} for="PurchaserEmail">Purchaser Email : </Label>
                            <Col sm={8}>
                                <Input type="email"
                                    value={PurchaserEmail}
                                    name="PurchaserEmail"
                                    id="PurchaserEmail"
                                    placeholder="Email"
                                    onChange={(e) => this.handleChange(e, 'purchaser')}
                                />
                            </Col>
                        </FormGroup>
                        <small>{this.isError("purchaser")}</small>

                        <FormGroup row>
                            <Label sm={4} for="PurchaserEmail">Purchaser Name : </Label>
                            <Col sm={8}>
                                <Input type="text"
                                    value={PurchaserName}
                                    sm={8}
                                    name="PurchaserName"
                                    id="PurchaserName"
                                    placeholder="Name"
                                    onChange={(e) => this.handleNameChange(e, 'purchaser')}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={4} for="RecipientName">Recipient Name : </Label>
                            <Col sm={8}>
                                <Input type="text"
                                    value={RecipientName}
                                    name="RecipientName"
                                    id="RecipientName"
                                    placeholder="Name"
                                    onChange={(e) => this.handleNameChange(e, 'recipient')}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={4} for="PurchaserEmail">Message : </Label>
                            <Col sm={8}>
                                <Input type="textarea"
                                    value={message}
                                    name="message"
                                    id="message"
                                    placeholder="Message"
                                    onChange={(e) => this.handleMessageChange(e)}
                                />
                            </Col>
                        </FormGroup>
                    </Form >
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={isError} onClick={this.handleSubmit}>Resend Emails</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default EmailModal;

function DetailLabel({ label, value }) {
    return (
        <p className='detail-label'>
            <strong>{label} : </strong><span>{value}</span>
        </p>
    )
}
