import React, { Component } from 'react';

import { Button, Col, Row, } from 'reactstrap';
import loadsStore from '../../../stores/loads-store';
import JmpModal from './customModal';
import { ClipLoader } from 'react-spinners';

class CancelJump extends Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isModalOpen: false,
      isloading: false
    }

    this.handleNewActivity = this.handleNewActivity.bind(this);
    this.loadEvents = [
      { name: loadsStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
    ];
  }

  componentWillMount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
  }

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
  }

  handleNewActivity = (activityString) => {

    if (activityString) {
      this.setState({
        isloading: false,
        isModalOpen: false
      })
    }
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  deleteJumps = (loadNum, jumpId, jumpType, loadId, isToday, isCompleted) => {
    //alert('in deleteJumps');
    if (isCompleted) {
      console.log('is completed');
      var jumpBody = { isToday: isToday }
      if (jumpType === "affJump") {
        loadsStore.deleteCompletedAffJump(loadId, jumpId, jumpBody);
      }
      else if (jumpType === "tandemJump") {
        console.log('tandem jump completion deletion');
        loadsStore.deleteCompletedTandemJump(loadId, jumpId, jumpBody);
      }
      else if (jumpType === "coachJump") {
        console.log('coach jump completion deletion');
        loadsStore.deleteCompletedCoachJump(loadId, jumpId, jumpBody);
      }
    }
    else {
      if (jumpType === "tandemJump") {
        loadsStore.cancelTandemJump(loadNum, jumpId);
      }
      else if (jumpType === "affJump") {
        loadsStore.cancelAffJump(loadNum, jumpId);
      }
      else if (jumpType === "funJump") {
        loadsStore.cancelFunJump(loadNum, jumpId);
      }
      else if (jumpType === "coachJump") {
        loadsStore.cancelCoachJump(loadNum, jumpId);
      }
    }
  }

  handleSubmit(loadNum, jumpId, jumpType, loadId, isToday, isCompleted) {

    this.setState({
      isloading: true
    })
    let index = loadsStore.getDraggable()?.findIndex(i => i.jumpId === jumpId)
    //alert(index);

    if (index === -1 || index === undefined) {
      this.deleteJumps(loadNum, jumpId, jumpType, loadId, isToday, isCompleted)
    } else {
      loadsStore.getDraggable()?.map((i, ind) => {
        this.deleteJumps(i.loadNum, i.jumpId, i.jumpType, loadId, isToday, i.isCompleted)
        if (ind === loadsStore.getDraggable().length - 1) {
          // this.props.clearItemSelection()
          loadsStore.clearDraggable()
        }
      })
    }
  }

  render() {
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

    const { loadId, isCompleted, isToday } = this.props;

    return (
      <React.Fragment>
        <JmpModal
          isModalOpen={this.state.isModalOpen}
          title={`Cancel Jump`}
        >
          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>
          {!this.state.isloading ? <span>Confirm Cancel Jump? </span> : ''}
          <Row className="form-group m-t-1-rem">
            <Col md={{ size: 10 }}>
              <button className="btn btn-bg lightColor createButton" type="submit" onClick={() => this.handleSubmit(this.props.loadNum, this.props.jumpId, this.props.jumpType, this.props.loadId, this.props.isToday, isCompleted)} >Yes</button>
              <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">No</button>
            </Col>
          </Row>
        </JmpModal>
        {/* <Button style={{ background: 'none', border: 'none', color: 'black', width: '19px' }} close={true} onClick={this.toggleModal}>
          <i className="fa fa-close"></i>
        </Button> */}
        <span onClick={this.toggleModal} style={{ color: 'red' }} id={"delete-jump" + this.props.jumpId}>
        </span>

        {/* <span onClick={this.toggleModal} style={{ color: 'red' }}>
          Delete
        </span> */}
      </React.Fragment>
    );
  }
}

export default CancelJump
