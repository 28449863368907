import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, TableHead } from '@material-ui/core';
import { styles } from './DataTableStyle';

import DonutLoader from '../../../loadManager/DonutLoader';
import EmailModal from '../datatable/EmailModal';
import { GIFT_CARD } from '../../../constant/reports';
import RefundModal from './RefundModal';
import RefundGiftCardModal from './RefundGiftCardModal';

class GiftCardTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: false,
      isModalOpen: false,
      openRefundModal: false,
      couponDetail: null,
      buttonClicked: false,
    }
  }

  componentDidUpdate() {
    let { buttonClicked, couponDetail } = this.state;
    if (buttonClicked && couponDetail !== null) {
      this.setState({ isModalOpen: true, buttonClicked: false })
    }
  }

  toggleModal = (type) => {
    if (type === 'email') {
      this.setState({ isModalOpen: !this.state.isModalOpen });
    }
    else {
      this.setState({ openRefundModal: !this.state.openRefundModal })
    }
  }
  resendGiftcardEmail = (val) => {
    this.setState({ couponDetail: val, buttonClicked: true })
  }
  refundAmount = (val) => {
    this.setState({ couponDetail: val, openRefundModal: true });
  }
  isDisableRefund = (val) => {
    if (val.status === "refunded" || val.status === "voided" || val.status !== "available" ) {
      return true;
    }
  }
  render() {
    const { classes, submit, data, dateRange } = this.props;
    const backgroundColors = ["#e7f2f6", "#fffcfc"]; 
    let backgroundColorInd = 0;
    let newArray = [];
    if (dateRange.length) {
      let index = dateRange.findIndex(ele => ele.modalType === GIFT_CARD);
      let endDate = new Date(dateRange[index].endDate).getTime()
      let startDate = new Date(dateRange[index].startDate).getTime()

      newArray = data.filter(val => {
        let createdDate = new Date(val.createdAt).getTime()
        let result = createdDate < endDate && createdDate > startDate
        return result;
      })
    } else {
      // newArray = data
    }
    // need to change{val.cardData.masked_card}

    // table header ===['Coupon Code', 'Name',"Created At", 'Expiry In Days', 'Status', 'Price', "Tandem Package Name", "Video Package Name", 'Purchaser', 'Recipient','Card used'];

    return (
      <Fragment>
        <div className='report-loading inreports'>
          <DonutLoader isLoading={this.state.isloading} />
        </div>
        <EmailModal
          isModalOpen={this.state.isModalOpen}
          toggleModal={() => this.toggleModal('email')}
          couponDetail={this.state.couponDetail}
        />
        {/* <RefundModal
          isModalOpen={this.state.openRefundModal}
          toggleModal={() => this.toggleModal("refund")}
          couponDetail={this.state.couponDetail}
          giftcards={newArray}
        /> */}
        <RefundGiftCardModal
          isModalOpen={this.state.openRefundModal}
          setState = {this.setState.bind(this)}
          couponDetail={this.state.couponDetail}
          giftcards={newArray}
        />

        {submit &&
          newArray && newArray.length ? newArray.map((val, index) => { 
            backgroundColorInd = (val.newEntry && backgroundColorInd === 0 ) ? 1 : (val.newEntry && backgroundColorInd === 1 ) ? 0 : backgroundColorInd;
            return (<TableRow key={'headTableRowSubHeadings' + index} style = {{background:backgroundColors[backgroundColorInd]}}>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.couponCode}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.item}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.status}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.expiryInDays}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {(val.price).toFixed(2)}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.taxAmount ? val.taxAmount.toFixed(2) : "-"}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                 {val.totalBookingFee ? (val.totalBookingFee).toFixed(2) : "-"}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.refundAmount ? (val.refundAmount).toFixed(2) : "-"}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.refundTax ? (val.refundTax).toFixed(2) : "-"}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.refundBookingfees ? (val.refundBookingfees).toFixed(2) : "-"}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                 {val.refundedDate ? moment(val.refundedDate).format('DD-MM-YYYY') : "-"}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {moment(val.createdAt).format('DD-MM-YYYY')}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.tandemPackageName}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.videoPackageName ? val.videoPackageName : "None"}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.name}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.email}
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                {val.cardData}
              </TableCell>

              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                <button
                  className="btn btn-info btn-small"
                  onClick={() => this.resendGiftcardEmail(val)}
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </TableCell>
              <TableCell
                component="th"
                colSpan="2"
                className={classes.giftCardHeadings}>
                <button
                  disabled={this.isDisableRefund(val)}
                  className="btn btn-info btn-small"
                  onClick={() => this.refundAmount(val)}
                >
                  <i class="fa fa-undo" aria-hidden="true"></i>

                </button>
              </TableCell>
            </TableRow>)

          }) : null
        }
      </Fragment>
    )
  }
}

export default withStyles(styles)(GiftCardTable);
