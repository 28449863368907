/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';


import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';
import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';
import scheduleStore from '../../../stores/schedule-store'
import swal from 'sweetalert';


class EditItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            eventsList: []
        }
    }

    toggleModal = (e) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    componentDidMount = () => {
        scheduleStore.getEvents()
            .then(res => {
                this.setState({ eventsList: res?.data?.filter(el => el?.packageCategory == "AFF") })
            })
    }

    handleSubmit = (values) => {
        var payload = {};
        if (!values.hasOwnProperty("altitude") || values.altitude === "none") {
            swal({
                title: "Warning",
                text: "Please select altitude",
                icon: "warning",
                dangerMode: false,
            })
            return;
        }
        this.toggleModal();
        payload.name = values.name;
        payload.type = values.type;
        payload.calenderEvent = values.calenderEvent;
        payload.price = Number(values.price);
        payload.prepaidPrice = Number(values.prepaidPrice);
        payload.studentPrice = Number(values.studentPrice);
        payload.studentPrepaidPrice = Number(values.studentPrepaidPrice);
        payload.instructor1Pay = Number(values.instructor1Pay);
        payload.instructor2Pay = Number(values.instructor2Pay);
        payload.description = values.description;
        payload.altitude = values.altitude;
        payload.isBookable = values.bookable;
        payload.isTaxable = values.taxable;
        console.log(payload);
        if (this.props.isEdit && values !== undefined) {
            itemStore.updateStudentPackage(this.props.item._id, payload);
        }
        else if (values != undefined) {
            itemStore.createStudentPackage(payload);
        }
    }

    render() {

        console.log(">>>>>>check calender events", this.state.eventsList)
        var item = { item: "", price: "", weekendPrice: "", videoType: "", videoPrice: "", instructorPay: "", videoPay: "", wristcamPay: "", description: "", isTaxable: false, isBookable: false };

        if (this.props.item !== undefined) {
            item = this.props.item;
        }
        console.log("item: ", item);
        var altitudes = itemStore.getAltitudes();
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Tandem Package`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>Name</Label>
                            <Col md={9}>
                                <Control.input name="name" model=".name" className="form-control" defaultValue={item.name}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="type" md={3}>Type</Label>
                            <Col md={9}>
                                <Control.select name="type" model=".type" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.type}>
                                    <option value="none">none</option>
                                    {/* {this.state.eventsList.map(evt => <option value={evt.eventName} >{evt.eventName}</option>)} */}
                                    <option value="AFF">AFF</option>
                                    <option value="STP">STP</option>
                                    <option value="ASP">ASP</option>
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="calenderEvent" md={3}>Calender Event</Label>
                            <Col md={9}>
                                <Control.select name="calenderEvent" model=".calenderEvent" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.calenderEvent}>
                                    <option value="none">none</option>
                                    {this.state.eventsList.map(evt => <option value={evt.eventName} >{evt.eventName}</option>)}
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price" model=".price" className="form-control" defaultValue={item.price}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="prepaidPrice" md={3}>Prepaid Price</Label>
                            <Col md={9}>
                                <Control.input name="prepaidPrice" model=".prepaidPrice" className="form-control" defaultValue={item.prepaidPrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="studentPrice" md={3}>Student Price</Label>
                            <Col md={9}>
                                <Control.input name="studentPrice" model=".studentPrice" className="form-control" defaultValue={item.studentPrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="studentPrepaidPrice" md={3}>Student Prepaid Price</Label>
                            <Col md={9}>
                                <Control.input name="studentPrepaidPrice" model=".studentPrepaidPrice" className="form-control" defaultValue={item.studentPrepaidPrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="instructor1Pay" md={3}>Instructor 1 Pay</Label>
                            <Col md={9}>
                                <Control.input name="instructor1Pay" model=".instructor1Pay" className="form-control" defaultValue={item.instructor1Pay}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="instructor2Pay" md={3}>Instructor 2 Pay</Label>
                            <Col md={9}>
                                <Control.input name="instructor2Pay" model=".instructor2Pay" className="form-control" defaultValue={item.instructor2Pay}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="altitude" md={3}>altitude</Label>
                            <Col md={9}>
                                <Control.select name="altitude" model=".altitude" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.altitude}>
                                    <option value="none">none</option>
                                    {altitudes.map(alt => <option value={alt.name}>{alt.name}</option>)}
                                </Control.select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="taxable" md={3}>Bookable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="bookable" model=".bookable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isBookable}
                                    autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="taxable" md={3}>Taxable</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="taxable" model=".taxable"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={item.isTaxable}
                                    autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="description" md={3}>Description</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.textarea name="description" model=".description" className="form-control" defaultValue={item.description}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>


                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {!this.props.isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </React.Fragment>
        );
    }
}

export default EditItem