
import React, { Component } from 'react';
import { Col, Row, } from 'reactstrap';
import loadsStore from '../../../stores/loads-store';
import JmpModal from './customModal';
import { ClipLoader } from 'react-spinners';

import DonutLoader from '../DonutLoader';


class CompleteLoad extends Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalCouldNotComplete = this.toggleModalCouldNotComplete.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isModalOpen: false,
      isCouldNotCompleteModalOpen: false,
      isloading: false,
      isCalender: false,
      date: null
    }

    // this.handleNewActivity = this.handleNewActivity.bind(this);
    this.loadEvents = [
      { name: loadsStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
    ];
  }

  componentWillMount = () => {
    // console.log('CreateJump componentWillMount');
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
  }

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
  }

  handleNewActivity = (activityString) => {
    // console.log('in handleNewActivity');
    // console.log(activityString);
    if (activityString) {
      this.setState({
        isloading: false,
        isModalOpen: false
      })
    }
  }

  componentDidMount = () => {
    // console.log('CreateLoad componentDidMount');
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  toggleModalCouldNotComplete() {
    this.setState({
      isCouldNotCompleteModalOpen: !this.state.isCouldNotCompleteModalOpen
    });
  }

  loadNotComplete(load) {
    var notComplete = false;
    // console.log()
    notComplete = load.tandemJumps.some(jump => {
      if (jump.instructor.firstname === "HOLD" && jump.instructor.lastname === "" || jump.instructor.firstname === "NONE" && jump.instructor.lastname === "") return true;
      if (jump.student.firstname === "HOLD" && jump.student.lastname === "" || jump.student.firstname === "NONE" && jump.student.lastname === "") return true;
      if (jump.videographer !== undefined && jump.videographer.firstname === "HOLD" && jump.videographer.lastname === "") return true;
      return false;
    });

    if (notComplete) return notComplete;

    notComplete = load.affJumps.some(jump => {

      if (jump.mainInstructor !== undefined)
        if ((jump.mainInstructor.firstname === "HOLD" && jump.mainInstructor.lastname === "") || (jump.mainInstructor.firstname === "NONE" && jump.mainInstructor.lastname === "")) return true;
      if (jump.videographer !== undefined && jump.videographer.firstname === "HOLD" && jump.videographer.lastname === "") return true;
      if (jump.secondaryInstructor !== undefined && ((jump.secondaryInstructor.firstname === "HOLD" && jump.secondaryInstructor.lastname === "") || (jump.secondaryInstructor.firstname === "NONE" && jump.secondaryInstructor.lastname === ""))) return true;
      if (jump.student.firstname === "HOLD" && jump.student.lastname === "" || jump.student.firstname === "NONE" && jump.student.lastname === "") return true;
      return false;
    });

    if (notComplete) return notComplete;

    notComplete = load.funJumps.some(jump => {
      if (jump.jumpType === "Undecided") return true;
      return false;
    });
    if (notComplete) return notComplete;

    notComplete = load.coachJumps.some(jump => {
      if ((jump.coach.firstname === "HOLD" && jump.coach.lastname === "") || (jump.coach.firstname === "NONE" && jump.coach.lastname === "")) return true;
      if ((jump.jumper.firstname === "HOLD" && jump.jumper.lastname === "") || (jump.jumper.firstname === "NONE" && jump.jumper.lastname === "")) return true;
      return false;
    });

    return notComplete;
  }

  handleSubmit(load) {
    this.setState({
      isloading: true
    })
    if (this.loadNotComplete(load)) {
      this.toggleModalCouldNotComplete();
      this.setState({
        isloading: false,
        isModalOpen: false
      })
      this.props.closeIsComplete()
    }
    else {
      loadsStore.completeLoad(load.loadNum, this.state.date);
      this.props.closeIsComplete()
    }
  }

  onDateSelect = (selectedDate) => {
    this.setState({
      date: selectedDate
    })
  }

  render() {
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    return (
      <React.Fragment>
        <JmpModal
          isModalOpen={this.state.isModalOpen}
          title={`Complete Load`}
          isCalender={this.state.isCalender}
          onDateChange={this.onDateSelect}
          isComplete={true}
        >

          <DonutLoader isLoading={this.state.isloading} />

          {!this.state.isloading ? <span>Confirm Complete Load?</span> : ''}

          <Row className="form-group m-t-1-rem">
            <Col md={{ size: 10 }}>
              <button className="btn btn-bg lightColor createButton" type="submit" onClick={() => this.handleSubmit(this.props.load)} >COMPLETE</button>
              <button onClick={(e) => { e.preventDefault(); this.toggleModal(); this.props.closeIsComplete() }} className="btn btn-link">CANCEL</button>
            </Col>
          </Row>
        </JmpModal>
        <JmpModal
          isModalOpen={this.state.isCouldNotCompleteModalOpen}
          title={`Complete Load`}
        >
          Could not complete Load, there are HOLDS or UNDECIDED fun jumps!!
          <Row className="form-group m-t-1-rem">
            <Col md={{ size: 10 }}>
              <button onClick={(e) => { e.preventDefault(); this.toggleModalCouldNotComplete(); this.props.closeIsComplete() }} className="btn btn-bg lightColor createButton">CLOSE</button>
            </Col>
          </Row>
        </JmpModal>
        <button className="btn" onClick={this.toggleModal}>
          <i className="fa fa-check" /> <strong>LOAD{' '}</strong>
        </button>
      </React.Fragment>
    );
  }
}

export default CompleteLoad
