import React, { Component } from 'react'
import './style.css'


export default class PrintPdf extends Component {

  constructor(props) {
    super(props);
    this.state = {
      printData: []
    }
  }

  componentWillReceiveProps(props, nextProps) {
    this.setState({
      printData: props.data
    })
  }

  render() {
    const data = this.props.data
    const { printData } = this.state;

    const RenderAff = printData.affJumps && printData.affJumps.map((i, k) => {
      return (
        <div className="Alist" key={k}>
          <label>{"Aff" + i.level}</label>
          <label className="price">{"$" + i.price}</label>
          <div className="Ablock">
            <span>I1 : {i.mainInstructor.firstname} {i.mainInstructor.memberId}</span>
            {i.secondaryInstructor && <span>I2 : {i.secondaryInstructor.firstname}{i.secondaryInstructor.memberId}</span>}
            <span>S : {i.student.firstname}{i.student.lastname}</span>
            <span>V : {i.videographer.firstname}{i.videographer.memberId}</span>
          </div>
        </div>
      )
    })

    const RenderCoach = printData.coachJumps && printData.coachJumps.map((i, k) => {
      return (
        <div className="Alist" key={k}>
          <label>{i.isWingsuit ? "Wingsuit Coach" : "Coach"}</label>
          <label className="price">{"$" + i.price}</label>
          <div className="Ablock">
            <span>Coach : {i.coach.firstname} {i.coach.memberId}</span>
            <span>Jumper : {i.jumper.firstname}{i.jumper.lastname}</span>
          </div>
        </div>
      )
    })

    const ReanderFun = printData.funJumps && printData.funJumps.map((index, key) => {
      return (<div className="Alist" key={"a" + key}>
        <label>{index.jumpType}</label>
        {index.jumpers && index.jumpers.map((i, k) => {
          return (
            <div className="Ablock" key={k}>
              <span>{i.firstname}{i.memberId}</span>
            </div>
          )
        })
        }
      </div>)
    })

    const RenderTendam = printData.tandemJumps && printData.tandemJumps.map((i, k) => {
      return (<div className="Alist" key={k}>
        <label>{i.isUltra ? "Tandem Ultra" : "Tandem"}</label>
        <label className="price">{"$" + i.price}</label>
        <div className="Ablock">
          <span>I : {i.instructor.firstname}{i.instructor.memberId}</span>
          <span>S : {i.student.firstname} {" " + i.student.firstname}</span>
          <span>V : {i.videographer.firstname}{i.videographer.memberId}</span>
        </div>
      </div>)
    })

    // console.log("ReportData", printData);

    return (
      <div id={this.props.printId} className="Awrapper">
        <div className="Atopbar">
          {/* {console.log('innerdata', printData)} */}
          <h3>Load {printData.loadNum} - <span>{printData.plane}</span></h3>
          <span>jumpers manifested: {printData.totalJumpers} out of {printData.capacity}</span>
        </div>
        <div className="Amain-block">
          {RenderAff}
          {RenderCoach}
          {ReanderFun}
          {RenderTendam}
        </div>
      </div>
    )
  }
}