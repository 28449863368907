import { EventEmitter } from 'events';

import Api from '../services/itemsapi';
import ReservationApi from '../services/itemsapi';
import jumpersitem from './jumpers-store';
import reportsitem from './report-store';
import { updateLoadHistory } from '../actions/reports'
import swal from 'sweetalert';

class Item extends EventEmitter {
	constructor() {
		super();
		this.prefix = 'LOADS_item_';
		this.dailyActivities = {};
		this.planes = [];
		this.loads = [];
		this.completedLoadArray = [];
		this.expiryID = [];
		//this.funLoadNum = '';

		this.cutValue = null
		this.cut = false;
		this.deleteJumpId = null

		this.copied = false;
		this.copyValue = {};
		this.displayLoader = false;
		this.settings = [];
		this.jumperManifestMethodSetting = 'regular';

		this.categories = [];
		this.paySchedule = [];
		this.priceSchedule = [];
		this.staffItems = [];
		this.countersaleItems = [];
		this.tandemPackages = [];
		this.videoPackages = [];
		this.discounts = [];
		this.studentPackages = [];
		this.jumperPackages = [];
		this.giftcards = [];
		this.altitudes = [];
		this.blocks = [];

		this.tandemRigs = [];
		this.studentRigs = [];
		this.sportRigs = [];

		this.defaultAltitude = {};
	}

	setListeners = (socket) => {

		socket.on("quantityUpdated", data => {
			console.log('quantityUpdated');
			//alert('quantity updated');
			console.log(data);
			var itemIndx = this.countersaleItems.findIndex(item => item.item === data.item);
			if (itemIndx >= 0) {

				this.countersaleItems[itemIndx] = data;
			}
			console.log(this.countersaleItems);
		})
	}

	loadCategories = () => {
		return new Promise((resolve, reject) => {
			Api.loadCategories().then((r) => {
				if (r.status == 200) {
					this.categories = r.data;
					//console.log("price data:" + JSON.stringify(r.data))
					resolve(this.categories);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	loadGrouponPackages = () => {
		return new Promise((resolve, reject) => {
			Api.loadItems().then((r) => {
				if (r.status == 200) {
					var prices = r.data;
					this.paySchedule = r.data.paySchedule;
					this.priceSchedule = r.data.priceSchedule;
					this.items = r.data.items;

					this.countersaleItems = r.data.countersaleItems;



					this.videoPackages = r.data.videoPackages;
					this.discounts = r.data.discounts;
					this.tandemPackages = r.data.tandemPackages;
					this.staffItems = r.data.staffItems;
					this.studentPackages = r.data.studentPackages;
					this.jumperPackages = r.data.jumperPackages;
					this.altitudes = r.data.altitudes;
					this.altitudes.sort((a, b) => b.endAltitude - a.endAltitude);

					this.defaultAltitude = this.altitudes.filter(alt => alt.isDefault)[0];
					//alert(JSON.stringify(this.tandemPackages));
					//console.log("price data:" + JSON.stringify(r.data))
					this.emitResults(this.tandemPackages, 'tandemPackagesLoaded');
					resolve(prices);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	loadItems = () => {
		return new Promise((resolve, reject) => {
			Api.loadItems().then((r) => {
				if (r.status == 200) {
					var prices = r.data;
					this.paySchedule = r.data.paySchedule;
					this.priceSchedule = r.data.priceSchedule;
					this.items = r.data.items;
					this.countersaleItems = r.data.countersaleItems;
					this.videoPackages = r.data.videoPackages;
					this.discounts = r.data.discounts;
					this.tandemPackages = r.data.tandemPackages;
					this.staffItems = r.data.staffItems;
					this.studentPackages = r.data.studentPackages;
					this.jumperPackages = r.data.jumperPackages;
					this.altitudes = r.data.altitudes;
					this.blocks = r.data.blocks;
					this.giftcards = r.data.giftcards;

					this.altitudes.sort((a, b) => b.endAltitude - a.endAltitude);

					this.tandemRigs = r.data.tandemRigs;

					this.studentRigs = r.data.studentRigs;

					this.sportRigs = r.data.sportRigs;

					console.log(this.blocks);

					this.defaultAltitude = this.altitudes.filter(alt => alt.isDefault)[0];
					this.emitResults(this.tandemPackages, 'tandemPackagesLoaded');
					resolve(prices);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	deleteStudentPackage = (id, payload) => {
		return new Promise((resolve, reject) => {
			Api.deleteStudentPackage(id, payload).then((r) => {

				if (r.status == 200) {
					var item = r.data;
					var indx = this.studentPackages.findIndex(i => i._id === item._id);
					this.studentPackages.splice(indx, 1);
					this.emitResults(this.studentPackages, 'studentPackageCreated');

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		})
	}
	updateStudentPackage = (id, payload) => {
		return new Promise((resolve, reject) => {
			Api.updateStudentPackage(id, payload).then((r) => {

				if (r.status == 200) {
					var item = r.data;
					var indx = this.studentPackages.findIndex(i => i._id === item._id);
					this.studentPackages[indx] = item;
					this.emitResults(this.studentPackages, 'studentPackageCreated');

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		})
	}

	createStudentPackage = (payload) => {
		return new Promise((resolve, reject) => {
			Api.createStudentPackage(payload).then((r) => {

				if (r.status == 200) {
					var item = r.data;
					this.studentPackages.push(item);
					this.emitResults(this.studentPackages, 'studentPackageCreated');

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		})
	}

	updateOrderStudentPackage = (payload) => {
		return new Promise((resolve, reject) => {
			Api.updateOrderStudentPackage(payload)
				.then((r) => {
					if (r.status === 200) {
						resolve(r.status);
					} else {
						resolve(false);
					}
				})
				.catch(err => {
					reject(false)
				})
		})
	}

	uploadBlob = (payload) => {
		return new Promise((resolve, reject) => {
			console.log(payload, "payloadpayloadpayload")
			Api.uploadThumb(payload)
				.then((r) => {

					this.emitResults(this.giftcards, 'giftcardCreated');
					resolve(r);
				})
				.catch(err => {
					console.log("Upload Image Err: ", err);
				})
		})
	}

	uploadImageAPI = (payload) => {
		return new Promise((resolve, reject) => {
			Api.uploadImage(payload)
				.then((r) => {
					console.log("Imgresposne: ", r)
					Api.uploadImageInReservation(payload)
						.then(r => {
							console.log('giftimage upload res: ', r);
						})

					resolve(r);

				})
				.catch(err => {
					console.log("Upload Image Err: ", err);
				})
		})
	}
	createAltitude = (payload) => {
		return new Promise((resolve, reject) => {
			Api.createAltitude(payload)
				.then(r => {
					if (r.status == 200) {
						return r.data;
					}
					else if (r.status == 401) {
						this.loginExpired();
						this.emitResults(true, 'clearSession');
					}
					else {
						throw new Error("server Error")
					}
				})
				.then(altitudes => {
					this.altitudes = altitudes.sort((a, b) => b.endAltitude - a.endAltitude);
					this.emitResults(this.altitudes, 'updateAltitudes');
					resolve(true)
				})
				.catch(err => {
					// console.log("🚀 ~ file: item-store.js ~ line 291 ~ Item ~ returnnewPromise ~ err", err)
					this.emitError({ message: 'Create Altitude Error!' });
					reject(err);
				})
		})
	}

	updateAltitude = (payload) => {
		return new Promise((resolve, reject) => {
			swal({
				title: "Warning",
				text: "tandem packages and student packages with this assigned altitude will automatically get updated",
				icon: "warning",
				dangerMode: false,
			})
			Api.updateAltitude(payload)

				.then(r => {
					if (r.status == 200) {
						return r.data;
					}
					else if (r.status == 401) {
						this.loginExpired();
						this.emitResults(true, 'clearSession');
					}
					else {
						this.emitError({ message: 'Update Altitude Error!' });
						reject('error');
					}
				})
				.then(altitudes => {
					this.altitudes = altitudes.sort((a, b) => b.endAltitude - a.endAltitude);
					this.emitResults(this.altitudes, 'updateAltitudes');
					this.loadItems();
					resolve(true)
				})
				.catch(err => {
					// console.log("🚀 ~ file: item-store.js ~ line 291 ~ Item ~ returnnewPromise ~ err", err)
					this.emitError({ message: 'Update Altitude Error!' });
					reject(err);
				})
		})
	}
	deleteAltitude = (payload) => {
		return new Promise((resolve, reject) => {
			Api.deleteAltitude(payload)
				.then(r => {
					if (r.status === 200) {
						return r.data;
					}
					else if (r.status == 401) {
						this.loginExpired();
						this.emitResults(true, 'clearSession');
					}
					else {
						throw new Error("server error")
					}
				})
				.then(resp => {
					if (resp.status === "deleted") {
						this.altitudes = this.altitudes.filter(ele => ele._id !== payload.itemId)
						this.altitudes.sort((a, b) => b.endAltitude - a.endAltitude);
						this.emitResults(this.altitudes, 'updateAltitudes');
						resolve(true)
					}
					else if (resp.status === "failed") {
						swal({
							title: "Warning",
							text: resp.message,
							icon: "warning",
							dangerMode: false,
						})
						resolve(true)

					}
					else {
						throw new Error("server error")
					}
				})
				.catch(err => {
					this.emitError({ message: 'Delete Error!' });
					reject(err);
					// console.log("🚀 ~ file: item-store.js ~ line 291 ~ Item ~ returnnewPromise ~ err", err)
				})
		})
	}

	createItem = (payload) => {
		//console.log('in load Prices');
		return new Promise((resolve, reject) => {
			Api.createItem(payload).then((r) => {
				if (r.status == 200) {
					var item = r.data;
					if (item.category === "tandem package") {
						this.tandemPackages.push(item);
						this.emitResults(this.tandemPackages, 'tandemPackageCreated');
					}
					else if (item.category === "video package") {
						this.videoPackages.push(item);
						this.emitResults(this.videoPackages, 'videoPackageCreated');
					}
					else if (item.category === "block") {
						if (this.blocks === undefined) {
							this.blocks = [item];
						}
						else {
							this.blocks.push(item);
						}

						// this.emitResults(this.blocks, 'blockCreated');
					}
					else if (item.category === 'giftcard') {
						this.giftcards.push(item);
					}
					else if (payload.category === "discounts") {
						this.discounts.push(item);
						this.emitResults(this.discounts, 'discountsCreated');
					}
					else {
						this.countersaleItems.push(item);
						//console.log("price data:" + JSON.stringify(r.data))
						this.emitResults(this.countesaleItems, 'itemCreated');
					}

					resolve(item);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	updateItem = (id, payload) => {
		//console.log('in load Prices');
		return new Promise((resolve, reject) => {
			Api.updateItem(id, payload).then((r) => {
				if (r.status == 200) {
					var item = r.data;
					if (item.category === "tandem package") {
						//this.tandemPackages.push(item);

						var indx = this.tandemPackages.findIndex(i => i._id === item._id);
						this.tandemPackages[indx] = item;

						this.emitResults(this.tandemPackages, 'tandemPackageCreated');
					}
					else if (item.category === "video package") {

						var indx = this.videoPackages.findIndex(i => i._id === item._id);
						this.videoPackages[indx] = item;

						this.emitResults(this.videoPackages, 'videoPackageCreated');
					}
					else if (item.category === 'giftcard') {
						var indx = this.giftcards.findIndex(i => i._id === item._id);
						this.giftcards[indx] = item;
						this.emitResults(this.giftcards, 'updateGiftcard');
					}

					else if (payload.category === "discounts") {
						var indx = this.discounts.findIndex(i => i._id === item._id);
						console.log('xxx2', this.discounts, indx);
						this.discounts[indx] = item;

						this.emitResults(this.discounts, 'discountsCreated');
					} else if (payload.category === "block") {
						var indx = this.blocks.findIndex(i => i._id === item._id);
						this.blocks[indx] = item;
						// this.emitResults(this.blocks, 'blockUpdated');
					}
					else {
						var indx = this.countersaleItems.findIndex(i => i._id === item._id);
						this.countersaleItems[indx] = item;
						//this.countersaleItems.push(item);
						//console.log("price data:" + JSON.stringify(r.data))
						this.emitResults(this.countesaleItems, 'itemCreated');
					}

					resolve(item);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	deleteItem = (id, payload) => {
		//console.log('in load Prices');
		return new Promise((resolve, reject) => {

			Api.deleteItem(id, payload).then((r) => {
				if (r.status == 200) {
					var item = r.data;
					if (item.category === "tandem package") {

						//this.tandemPackages.push(item);
						var indx = this.tandemPackages.findIndex(i => i._id === item._id);
						this.tandemPackages.splice(indx, 1);
						this.emitResults(this.tandemPackages, 'tandemPackageCreated');
					}
					else if (item.category === 'giftcard') {
						var indx = this.giftcards.findIndex(i => i._id === item._id);
						this.giftcards.splice(indx, 1);
						this.emitResults(this.giftcards, 'giftcardCreated');
					}
					else if (item.category === "video package") {
						var indx = this.videoPackages.findIndex(i => i._id === item._id);
						this.videoPackages.splice(indx, 1);
						this.emitResults(this.videoPackages, 'videoPackageCreated');
					}
					else if (payload.category === "discounts") {
						var indx = this.discounts.findIndex(i => i._id === item._id);
						this.discounts.splice(indx, 1);
						console.log(this.discounts, indx);
						this.emitResults(this.discounts, 'discountsCreated');
					}
					else {
						var indx = this.countersaleItems.findIndex(i => i._id === item._id);
						this.countersaleItems.splice(indx, 1);
						//this.countersaleItems.push(item);
						//console.log("price data:" + JSON.stringify(r.data))
						this.emitResults(this.countesaleItems, 'itemCreated');
					}

					resolve(item);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'could not load prices.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getCategories = () => {
		return this.categories;
	}

	getCountersaleItems = () => {
		return [...this.countersaleItems, ...this.staffItems, ...this.blocks];
	}

	getCountersaleItemsWithoutBlocks = () => {
		return [...this.countersaleItems, ...this.staffItems];
	}

	getTandemRigs = () => {
		return this.tandemRigs
	}

	getStudentRigs = () => {
		return this.studentRigs
	}

	getSportRigs = () => {
		return this.sportRigs
	}

	getDefaultStudentPackageId = (type) => {
		return this.studentPackages.filter(pkg => pkg.type === type)[0]._id;
	}
	getStudentPackageTypes = () => {
		var types = this.studentPackages.map(pkg => pkg.type);
		var packageTypes = [... new Set(types)];
		return packageTypes;
	}
	getStudentPackages = () => {
		return this.studentPackages;
	}

	getTandemPackages = () => {
		return this.tandemPackages;
	}

	getBlocks = () => {
		return this.blocks;
	}

	getGiftcards = () => {
		return this.giftcards;
	}

	getVideoPackages = () => {
		return this.videoPackages;
	}

	getDiscounts = () => {
		return this.discounts;
	}

	getAltitudes = () => {
		return this.altitudes;
	}

	getDefaultAltitude = () => {
		return this.defaultAltitude;
	}

	getDefaultTeamAltitude = () => {
		console.log('in getDefaultTeam Altitude');
		var teamAlt = this.altitudes.filter(alt => alt.name === "11.5K")[0];
		if (teamAlt) {
			return teamAlt;
		}
		else {
			return this.defaultAltitude;
		}
	}

	getLowAltitude = () => {
		//alert(JSON.stringify(this.altitudes));
		return this.altitudes[this.altitudes.length - 1];
	}

	getStudent = () => {
		//alert(JSON.stringify(this.altitudes));
		return this.altitudes[this.altitudes.length - 1];
	}

	getDefaultTandemPackage = () => {
		var packageId = this.tandemPackages.filter(td => td.videoType === "none")[0]._id;
		return packageId;
	}

	getHighAltPackage = () => {
		var packageId = this.tandemPackages.filter(pkg => pkg.item === "Tandem Ultra")[0]._id;
		return packageId;
	}

	getDefaultVideoPackage = () => {
		var packageId = this.videoPackages[0]._id;
		return packageId;
	}


	cancelCreateJump = (loadnum) => {
		this.emitResults(loadnum, 'cancelCreateJump');
	}

	cancelPreviousJump = (loadnum) => {
		this.emitResults(loadnum, 'cancelPreviousJump');
	}

	completeLoadChange = (data) => {
		this.emitResults(data, 'completeLoadChangeEvent');
	}

	getPilotPay = (id) => {
		if (jumpersitem.isNoPayNoCharge(id)) {
			return 0.00;
		}
		var priceItem = this.paySchedule.filter(item => item.type === 'pilot')[0];
		return priceItem.price;
	}

	getStudentGearPrice = () => {
		var priceItem = this.priceSchedule.filter(item => item.type === 'gear rental 1')[0];
		return priceItem.price;
	}

	getSportGearPrice = () => {
		var priceItem = this.priceSchedule.filter(item => item.type === 'gear rental 2')[0];
		return priceItem.price;
	}

	clearState = () => {
		this.dailyActivities = {};
		this.planes = [];
		this.loads = [];
		this.completedLoadArray = [];
		this.emitResults(true, 'clearSession');
	}

	// itemFunJump = () => {
	// 	this.emitResults(this.funLoadNum, 'checkFunState');
	// }

	emitError = (err) => {
		this.emit('ERROR', err.message);
	}

	getEventPrefix = (func) => {
		return `${this.prefix}${func}`;
	}

	emitResults = (r, func) => {
		if (!r) {
			this.emitError({ alert: true, message: 'No data found' });
		}
		this.setMaxListeners(1000);
		this.emit(`${this.prefix}${func}`, r);
	}

	loginExpired = () => {
		//alert(JSON.stringify(localStorage, null,2));
		if(localStorage.getItem('isAuth')){
			localStorage.clear();
			swal({
				title: "LOGIN EXPIRED",
				text: "please re-login",
				icon: "warning",
				dangerMode: true,
			})
		}

		//window.location =  window.location.origin
		//window.location.reload();
	}

	debug = (r) => {
	}
}

const itemStore = new Item();
window.itemStore = itemStore;
export default itemStore;
