/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import loadsStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';
import ReactDOM from 'react-dom';
import { addCompletedJumpers } from '../../../actions/updateCompletedJump'
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class EditableOptions extends Component {

  constructor(props) {
    super(props);
    this.setEditable = this.setEditable.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      editMode: false,
      load: props.load,
      altitudes: []
    };
  }

  setEditable() {
    this.setState({
      editMode: !this.state.editMode
    });
  }

  handleSubmit(loadNum, jumpId, jumperId) {
    this.setEditable();
    // console.log(loadNum + " jumpId: " + jumpId + " jumperId: " + jumperId);
    loadsStore.removeJumper(loadNum, jumpId, jumperId);
  }
  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    })
  }
  changeJumper = (isCompleted) => {
    if (isCompleted) {
      // this.props.openModel({ isModal: true, editType: false })
      this.setState({
        isModalOpen: true
      })
    }
    else {
      this.setState({
        editMode: !this.state.editMode
      })
    }
  }
  updateJumpType = (value, loadNum, jumpId) => {
    if (value !== "Select..") {
      var jumpBody = {
        jumpType: value
      }
      var that = this;
      var type = value;
      if (this.props.isCompleted) {
        const jumpersIds = this.props.funJump.jumpers.map(index => {
          return index._id
        })
        var jumpPostBody = {
          jumperIds: jumpersIds, //value.map(jumperOption => jumperOption.value._id),
          jumpType: type,
          gearRentals: this.props.funJump.gearRentals
        };
        if (this.props.isToday) {
          jumpPostBody.isToday = true
        }
        var jumpId = this.props.funJump.jumpId;
        var loadId = this.props.loadId;
        this.setState({
          editMode: !this.state.editMode
        });
        this.props.addCompletedJumpers(loadId, jumpId, jumpPostBody);
      }
      else {
        Promise.all([loadsStore.updateFunJump(loadNum, jumpId, jumpBody)])
          .then(function (result) {
            var data = result[0];
            if (data === true) {
              that.setState({ editMode: !that.state.editMode });
            }
            else {
              that.setState({ editMode: !that.state.editMode });
            }
          })
      }
    }
  };
  componentDidMount() {
    var altitudes = itemStore.getAltitudes();
    this.setState({ altitudes: altitudes });
    document.addEventListener('click', this.handleClickOutside, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        editMode: false
      });
    }
  }

  renderJumpSize = () => {
    if (this.props.jumpType === "Belly" || this.props.jumpType === "Freefly" || this.props.jumpType === "Wingsuit" || this.props.jumpType === "Tracking") {
      if (this.props.funJump.jumpers.length == 1) {
        return "Solo";
      }
      else {
        return this.props.funJump.jumpers.length + "-way";
      }
    }
    return null;
  };

  setAltitude = (loadNum, jumpId, altitude) => {
    loadsStore.updateFunJump(loadNum, jumpId, { altitude: altitude });
  }
  toggle = () => {
    !this.props.isCompleted && this.changeJumper(this.props.isCompleted)
  }
  render() {


    var Menu;

    if (this.props.type === "manifest") {
      if (localStorage.getItem("role") === "school") {
        Menu = [this.props.menuOptions[0]].map(menuItem =>
          // <a className="dropdown-item" onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.tandemPackageName, this.props.videoPackageName); this.setState({ editMode: false }) }} >{menuItem}</a>
          <DropdownItem onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.tandemPackageName, this.props.videoPackageName); this.setState({ editMode: false }) }}  >{menuItem}</DropdownItem>

        )
      }
      else {
        Menu = this.props.menuOptions.map(menuItem =>
          // <a className="dropdown-item" onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.tandemPackageName, this.props.videoPackageName); this.setState({ editMode: false }) }} >{menuItem}</a>
          <DropdownItem onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.tandemPackageName, this.props.videoPackageName); this.setState({ editMode: false }) }}  >{menuItem}</DropdownItem>

        )
      }

    }
    else if (this.props.type === "manifest student") {

      Menu = this.props.menuOptions.map(menuItem =>
        // <a className="dropdown-item" onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.tandemPackageName, this.props.videoPackageName); this.setState({ editMode: false }) }} >{menuItem}</a>
        <DropdownItem onClick={() => { this.props.callback(this.props.studentId, menuItem); this.setState({ editMode: false }) }}  >{menuItem}</DropdownItem>

      )
    }
    else if (this.props.type === "manifestAll") {
      if (localStorage.getItem("role") === "school") {
        Menu = [this.props.menuOptions[0]].map(menuItem =>
          // <a className="dropdown-item" onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.tandemPackageName, this.props.videoPackageName); this.setState({ editMode: false }) }} >{menuItem}</a>
          <DropdownItem onClick={() => { this.props.callback(menuItem); this.setState({ editMode: false }) }}  >{menuItem}</DropdownItem>
        )
      }
      else {
        Menu = this.props.menuOptions.map(menuItem =>
          // <a className="dropdown-item" onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.tandemPackageName, this.props.videoPackageName); this.setState({ editMode: false }) }} >{menuItem}</a>
          <DropdownItem onClick={() => { this.props.callback(menuItem); this.setState({ editMode: false }) }}  >{menuItem}</DropdownItem>

        )
      }

    }
    else {

      Menu = this.props.menuOptions.map(menuItem =>
        // <a className="dropdown-item" onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.type); this.setState({ editMode: false }) }} >{menuItem}</a>
        <DropdownItem onClick={() => { this.props.callback(this.props.studentId, menuItem, this.props.type, this.props.activity.manifestStatus); this.setState({ editMode: false }) }} >{menuItem}</DropdownItem>

      )

      Menu.unshift(<DropdownItem onClick={() => { this.props.callback(this.props.studentId, "none", this.props.type, this.props.activity.manifestStatus); this.setState({ editMode: false }) }} >none</DropdownItem>)
      if (this.props.type === "tandemPackage") {
        Menu.unshift(<DropdownItem onClick={() => { this.props.callback(this.props.studentId, "ride along", this.props.type, this.props.activity.manifestStatus); this.setState({ editMode: false }) }} >ride along</DropdownItem>)
      }

    }

    return (
      <React.Fragment>
        {/* {
          !this.state.editMode ?
            // <div className="jumpType-button" onClick={()=>this.changeJumper(this.props.isCompleted)} style={{ padding: '0 1 0 4px' }}>
            //   {this.renderJumpSize()} {this.props.jumpType}
            // </div>
            <button onClick={() => !this.props.isCompleted && this.changeJumper(this.props.isCompleted)} style={{ padding: '0 1 0 4px' }}>
              {this.props.text}
            </button>
            :
            <div className="dropdown-menu" onBlur={() => { this.setState({ editMode: true }) }}>
              <a className="dropdown-item">Select..</a>
              {Menu}
            </div>
        } */}
        <ButtonDropdown direction="down" isOpen={this.state.editMode} toggle={this.toggle}>
          <DropdownToggle caret size="sm" style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }}>
            {this.props.text}
          </DropdownToggle>
          <DropdownMenu>
            {Menu}
          </DropdownMenu>
        </ButtonDropdown>
        <LoadUpdateModal
          isOpen={this.state.isModalOpen}
          onClick={this.wantsEidtable}
        />
      </React.Fragment>
    );
  }
}

const mstp = state => {
  return {
    state
  }
}
const mdtp = dispatch => {
  return {
    addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
  }
}

//  export default EditableFunJumper;
export default connect(mstp, mdtp)(EditableOptions)
