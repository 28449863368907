import { EventEmitter } from 'events';


import api from '../services/reservationapi';
import schedulesStore from './schedule-store';
import jumperStore from './jumpers-store';
import swal from 'sweetalert';
import { resolve } from 'path';

// https://www.youtube.com/watch?v=bvEC6i7CUyE
class TandemData extends EventEmitter {
    constructor() {
        super();
        this.prefix = 'RESERVATION_STORE';
        this.token = '';
        this.serviceID = '';
        this.applicationProfileId = '';
        this.serviceId = '';
        this.merchantProfileId = '';

        this.formData = {
            finalForm: {
                timeslotId: null,
                passengers: []
            }
        };
        this.grouponPackages = []
    }

    setListeners = (socket) => {
        socket.on('makeReservation', data => {
            this.emitResults(true, 'reservationEvent');
        });
    }

    getToken = () => {
        return this.token;
    }

    setToken = (val) => {
        this.token = val;
    }

    setProcessorVals = (values) => {
        this.serviceID = values.ServiceID;
        this.applicationProfileId = values.applicationProfileId;
        this.merchantProfileId = values.merchantProfileId;
    }

    getServiceId = () => {
        return this.serviceID;
    }

    getApplicationProfileId = () => {
        return this.applicationProfileId;
    }

    getMerchantProfileId = () => {
        return this.merchantProfileId;
    }

    clearAll = () => {

        this.formData = {
            finalForm: {
                timeslotId: null,
                passengers: []
            }
        };
    }

    getAll = () => {
        return this.formData;
    }

    updateFormWithIndex = (formData, index) => {
        this.formData.finalForm.passengers[index] = formData;
        this.emit('childFormUpdate', this.formData.finalForm.passengers);
    }

    updateFinalForm = (data) => {
        data.passengers || (data.passengers = []) // setting default
        this.formData.finalForm = data;
    }

    getFormData(index) {
        return this.formData.finalForm.passengers[index] || null;
    }

    generateReservationPdf = (date) => {
        return new Promise((resolve, reject) => {
            api.generateReservationPdf(date).then((r) => {
                if (r.status === 200) {
                    resolve(r.data);
                }
            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getReservation = (id) => {
        return new Promise((resolve, reject) => {
            api.getReservation(id).then((r) => {
                if (r.status == 200) {
                    // var result = r.data;
                    // alert(JSON.stringify(r.data));
                    //alert('Your Reservation has been Canceled');
                    console.log('getReservation: resr-store ', r.data);
                    resolve(r.data);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warnsingEvent');
                    resolve(false);
                }
                else {
                    //   this.emitError({message:'something went wrong while cancelling reservation.'});
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    getsuspendedreservation = (id) => {
        return new Promise((resolve, reject) => {
            api.getsuspendedreservation(id).then((r) => {
                if (r.status == 200) {
                    resolve(r.data);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' })
                    reject(err);
                });
        });
    }

    DeleteReservation = (reservationId, payload) => {
    	return new Promise((resolve, reject)=>{
    		api.cancelReservation(reservationId, payload).then((r)=>{
    			if(r.status==200){
    				var result = r.data;
                    //alert('Your Reservation has been Canceled');
                    swal({
                        title: "Success",
                        text: "Reservation has been Canceled",
                        icon: "success",
                        dangerMode: false,
                    })
                    resolve(true);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    //   this.emitError({message:'something went wrong while cancelling reservation.'});
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    ChangeReservation = (reservationBody) => {
        return new Promise((resolve, reject) => {
            api.changeReservation(reservationBody).then((r) => {
                if (r.status === 200) {
                    var result = r.data;
                    var tandemStudents = r.data.tandemStudents;
                    jumperStore.loadTandemStudents();
                    this.emitResults(r.data.timeslot.datetime, 'slotChangeEvent');
                    resolve(true);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }


    MakeReservation = (payload) => {
        // let passangerBody = { timeslotId: payload[0].timeslotId, tandemStudents: payload[0].tandemStudents, totalPaid: payload[0].totalPaid, numVideos: payload[0].numVideos,transactionId:'Transaction123' };
        //let passangerBody = { timeslotId: payload[0].timeslotId, tandemStudents: payload[0].tandemStudents, totalPaid: 0, numVideos: payload[0].numVideos,transactionId:'Transaction123',TransactionDateTime:'Transaction_Time' };
        let passangerBody = {
            timeslotId: payload.slotId, students: payload.passengers, eventName: payload.eventName,
            totalCharge: parseInt(payload.amount), totalFees: parseInt(payload.totalFees),
            numVideos: parseInt(payload.videoCount), transactionId: payload.transactionId,
            TransactionDateTime: new Date().toISOString()
        };
        return new Promise((resolve, reject) => {
            api.makeReservation(passangerBody).then((r) => {
                if (r.status == 200) {
                    schedulesStore.closeReservation();
                    //jumperStore.addTandemStudents(r.data.students);
                    //console.log(r);
                    this.emitResults(true, 'reservationEvent');
                    resolve(r.data.reservations);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    //console.log({ message: 'Reservation could not be created, please contact support.' });
                    this.emitResults(false, 'reservationEvent');
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
            })
                .catch((err) => {
                    //console.log({ message: 'Incomplete or Invalid data present' });
                    this.emitResults(false, 'reservationEvent');
                    reject(err);
                });
        });
    }

    VerifyGiftCard = (payload) => {
        return new Promise((resolve, reject) => {
            api.verifyGiftCard(payload).then((r) => {
                if (r.status == 200) {
                    console.log(r);
                    resolve(r.data);
                } else {
                    resolve(false);
                    console.log({ message: 'Invalid Card' });
                }
            })
                .catch((err) => {
                    console.log({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    UpdateGiftCard = (payload) => {
        return new Promise((resolve, reject) => {
            api.UpdateGiftCard(payload).then((r) => {
                if (r.status == 200) {
                    console.log(r);
                    resolve(true);
                } else {
                    resolve(false);
                    console.log({ message: 'Invalid Card' });
                }
            })
                .catch((err) => {
                    console.log({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    getAllGrouponPackages = () => {
        return new Promise((resolve, reject) => {
            api.getAllGrouponPackages().then((r) => {
                if (r.status == 200) {
                    console.log(r);
                    this.grouponPackages = r.data;
                    //console.log(this.grouponPackages);
                    //alert('groupons loaded');
                    this.emitResults(r.data, 'grouponsLoaded');
                    resolve(true);
                } else {
                    resolve(false);
                    console.log({ message: 'Invalid Card' });
                }
            })
                .catch((err) => {
                    console.log({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    createGroupon = (payload) => {
        return new Promise((resolve, reject) => {
            api.createGroupon(payload).then((r) => {
                if (r.status == 200) {
                    console.log(r);
                    this.grouponPackages.push(r.data);
                    this.emitResults(r.data, 'createGroupon');
                    resolve(true);
                } else {
                    resolve(false);
                    console.log({ message: 'Invalid Card' });
                }
            })
                .catch((err) => {
                    console.log({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    getGrouponPackages = () => {
        return this.grouponPackages;
    }

    sendEmail = (body) => {
        console.log('in load Prices email sending ');
        return new Promise((resolve, reject) => {
            api.sendEmail(body).then((r) => {
                console.log(r, " email sent");

            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    addReservationMemo = (slotId, payload) => {
        return new Promise((resolve, reject) => {
            api.addReservationMemo(slotId, payload).then((r) => {
                if (r.status === 200) {
                    var result = r.data;
                    swal({
                        title: "Success",
                        text: "Memo Added Successfully",
                        icon: "success",
                        dangerMode: false,
                    })
                    resolve(true);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    sendIsSuspended = (payload) => {
        console.log(payload,"=->payload")
        return new Promise((resolve, reject) => {
            api.sendIsSuspended(payload).then((r) => {
                console.log(r,"=->>>>rrrr")
                // Api.getPassengersOnDate(d).then(r => {
                //     if (this.activeView == "day" && event.resource._id) {
                //     r.data.timeslots = r.data.timeslots.filter(
                //         item => item._id === event.resource._id
                //     );
                //     }
                //     var payload = { date: d };
                //     this.emitResults(r, "fetchPassengers");
                //     this.emitDate(payload, "fetchReserveDate");
                //     resolve(r);
                //     //this.emitDate(d, 'fetchReserveDate');
                // });
                if (r.status === 200) {
                    resolve(r.data);
                }else{
                    resolve(false)
                }
            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    generateReservationPdf = (date) => {
        return new Promise((resolve, reject) => {
    		api.generateReservationPdf(date).then((r)=>{
    			if(r.status === 200) {
                    resolve(r.data);
                }
            })
    		.catch(err=>{
    			this.emitError({message:'Incomplete or Invalid data present'});
    			reject(err);
    		});
    	});
    }

    updateReservationMemo = (slotId, payload) => {
        return new Promise((resolve, reject) => {
            api.updateReservationMemo(slotId, payload).then((r) => {
                if (r.status === 200) {
                    var result = r.data;
                    swal({
                        title: "Success",
                        text: "Memo Deleted Successfully",
                        icon: "success",
                        dangerMode: false,
                    })
                    resolve(true);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    sendConfirmationEmail = (payload) => {
        return new Promise((resolve, reject) => {
            api.sendConfirmationEmail(payload).then((r) => {
                if (r.status === 200) {
                    var result = r.data;
                    swal({
                        title: "Success",
                        text: "Email Sent Successfully",
                        icon: "success",
                        dangerMode: false,
                    })
                    resolve(true);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    sendConfirmationEmailWithTemplate = (payload) => {
        return new Promise((resolve, reject) => {
            api.sendConfirmationEmailWithTemplate(payload)
            .then((r) => {
                if (r.status === 200) {
                    swal({
                        title: "Success",
                        text: "Email Sent Successfully",
                        icon: "success",
                        dangerMode: false,
                    })
                    resolve(true);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }

    sendDefaultConfirmationEmail = (payload) => {
        return new Promise((resolve, reject) => {
            api.sendDefaultConfirmationEmail(payload)
            .then((r) => {
                if (r.status === 200) {
                    swal({
                        title: "Success",
                        text: " default Email Sent Successfully",
                        icon: "success",
                        dangerMode: false,
                    })
                    resolve(true);
                }
                else if (r.status === 500 || r.status === 404) {
                    this.emitResults(true, 'warningEvent');
                    resolve(false);
                }
                else {
                    resolve(false);
                }
            })
                .catch(err => {
                    this.emitError({ message: 'Incomplete or Invalid data present' });
                    reject(err);
                });
        });
    }



    clearState = () => {
        this.token = '';
        this.serviceID = '';
        this.applicationProfileId = '';
        this.serviceId = '';
        this.merchantProfileId = '';
    }

    emitError = (err) => {
        var payload = { ...err };
        this.emit('ERROR', payload);
    }

    getEventPrefix = (func) => {
        return `${this.prefix}${func}`;
    }

    emitResults = (r, func) => {
        //console.log(r);
        this.setMaxListeners(1000);
        this.emit(`${this.prefix}${func}`, r);
    }

    emitDate = (r, func) => {
        this.setMaxListeners(1000);
        this.emit(`${this.prefix}${func}`, r);
    }

    debug = (r) => {
        //console.log(r.data);
    }
}

const tandemData = new TandemData();
window.tandemData = tandemData;
export default tandemData;
