import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import background from "../images/background.svg";
import logo from "../images/logo.svg";
import "./emailtemplates.css";
import LeftMenu from "../menu/LeftMenu1";
import DonutLoader from "../loadManager/DonutLoader";
import EmailModal from "./EmailModal";
import swal from "sweetalert";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import scheduleStore from "../../stores/schedule-store";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "1rem",
      letterSpacing: "1px",
    },
  },
};
export default function ReservationList(props) {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editorDesign, setEditorDesign] = useState(null)
  const [state, setState] = useState({
    loading: false,
    isLoading: false,
    errorMsg: false,
  });
  const [HTMLmarkup, setHTMLmarkup] = useState("");
  const [modalData, setModalData] = useState(null);
  const [addEditLoading, setaddEditLoading] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const deleteConfirmation = (data) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want delte this template?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
      
    }).then((willDelete) => {
      if (willDelete) {
        setIsLoading(true);
        scheduleStore
          .deleteemailtemplates({ _id: data._id })
          .then((resp) => {
            loadEmails();
            swal("Deleted!", "Your template has been deleted!", "success");
          })
          .catch((err) => {
            swal("Something went wrong!!", "fail");
          })
          .finally(() => {
            setIsLoading(false);
            //clossmodal
          });
      } else {
      }
    });
  };

  let tableData = [];
  const [userData, setUserData] = useState([]);

  console.log(userData, "userDatauserDatauserDatauserDatauserData");

  const loadEmails = () => {
    setIsLoading(true);
    scheduleStore
      .getemailtemplates()
      .then((resp) => {
        setUserData(resp.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadEmails();
  }, [scheduleStore]);

  const toggleModal = (e) => {
    setIsModalOpen((prev) => !prev);
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    // setConvertedContent(currentContentAsHTML);
  };


  const handleEditSubmit = ({design,html}) => {
    setaddEditLoading(true);
    console.log(formValues?.name,"shgdvchgfvchfdj")
    scheduleStore
      ?.patchemailtemplates({
        id: modalData?._id,
        name: formValues?.name,
        subject: formValues?.subject,
        content: html,
        design: design,
      })
      ?.then((resp) => {
        setIsModalOpen(false);
        loadEmails();
        setFormValues({});
      })
      .finally(() => {
        setaddEditLoading(false);
        setIsModalOpen(false);
      });
      setFormValues({});
  };


  useEffect(() => {
    
    if (modalData?.name) {
      console.log(modalData,"modalDatamodalData")
      setaddEditLoading(true);
    
   }
  setaddEditLoading(false);
  }, [modalData]);

  const getEmailTemp = async (data) => {
    console.log(
      "getEmailTemp===>", data
    )
    try {
      const response = await scheduleStore.getSingleEmail(data?._id )
      console.log("respo==>", response)
      setModalData(response?.data)
      setIsModalOpen(true)
    }catch(err){
      console.log("errp", err)
    }
  }

  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  // };

  const handleCancel = () => {
    setState((prev) => ({ ...prev, loading: false }));
    toggleModal();
    setModalData(null);
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value ,[e.target.subject]: e.target.subject });
  };

  const returnColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        width: "400px",
      },
      {
        name: "Subject",
        selector: "subject",
        sortable: true,
        width: "500px",
      },
     
      // {
      //   name: "Content",
      //   selector: "content",
      //   sortable: true,
      //   width: "750px",
      //   cell: (row) => (
      //     <div style={{height: "200px", overflowY: "scroll"}} dangerouslySetInnerHTML={{__html: row?.content || ''}}>
      //     </div>
      //   // )
      // },
      {
        name: "Action",
        selector: "action",
        width: "250px",
        sortable: true,
        cell: (row) => (
          <div>
            <i onClick={() => {
              // setIsModalOpen(true)
              // setModalData(row)
              getEmailTemp(row)
            }} className="fa fa-pencil" />
            <i
              onClick={() => deleteConfirmation(row)}
              className="fa fa-trash ml-4"
            />
          </div>
        ),
      },
    ];
  };

  const returnTableData = () => {
    return userData || [];
  };
  const handleSumbit = ({design,html}) => {
    console.log("CALEED", HTMLmarkup, formValues);
    setaddEditLoading(true);
    scheduleStore
      .addemailtemplates({ name: formValues.name , subject: formValues.subject , content: html, design: design })
      .then((res) => {
        setIsModalOpen(false);
        loadEmails();
        setFormValues({});
      })
      .catch((err) => console.log("err=>", err))
      .finally(() => {
        setaddEditLoading(false);
        setIsModalOpen(false);
      });
    setFormValues({});
  };

  const isMobile = window.innerWidth <= 500;

  return (
    <div
      className="row reservation-list-container"
      style={{ backgroundImage: `url(${background})` }}
    >
      {isMobile ? (
        <div
          className={`blueBg fullHeightPanes ${
            this.state.open ? "mobileLeftBar" : "hideSideBar"
          }`}
        >
          <div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
            <LeftMenu showCreate={true} />
          </div>
        </div>
      ) : (
        <div className="blueBg fullHeightPanes left-container">
          <img
            src={logo}
            alt="logo"
            style={{ height: 60, margin: "auto", display: "block" }}
          />
          <LeftMenu showCreate={true} />
        </div>
      )}

      <div
        className="middle-container-no-rightmenu"
        style={{ padding: "0 15px" }}
      >
        <DonutLoader isLoading={isLoading} addClass="sample1" />

        <img
          src={logo}
          alt="logo"
          style={{ height: 60, margin: "auto", display: "block" }}
        />

        <div className="card">
          <div className="card-body datatable-react">
            <DataTable
              title={
                <div className="d-flex align-items-center">
                  <p className="m-0">View Email Templates</p>
                  <span className="ml-4">
                    <i
                      className="fa fa-plus"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          isModalOpen: setIsModalOpen(true),
                        }));
                      }}
                    />
                  </span>
                </div>
              }
              compact={true}
              center={true}
              striped={true}
              responsive={true}
              pagination={true}
              // expandableRows={true}
              expandOnRowClicked={true}
              paginationPerPage={15}
              selectableRows
              persistTableHead
              // contextActions={this.contextActions()}
              customStyles={customStyles}
              // expandableRowsComponent={<EmailModal/>}
              columns={returnColumns()}
              data={returnTableData()}
            />
          </div>
        </div>
      </div>
      {isModalOpen &&  <EmailModal
        modalData={modalData}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleEditSubmit={handleEditSubmit}
        setModalData={setModalData}
        setHTMLmarkup={setHTMLmarkup}
        state={state}
        setFormValues={setFormValues}
        formValues={formValues}
        HTMLmarkup={HTMLmarkup}
        toggleModal={toggleModal}
        handleSumbit={handleSumbit}
        addEditLoading={addEditLoading}
        setState={setState}
        convertContentToHTML={convertContentToHTML}
        handleCancel={handleCancel}
        handleChange={handleChange}
        editorDesign={editorDesign} 
        setEditorDesign={setEditorDesign}
      />}
    </div>
  );
}
