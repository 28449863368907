const returnSettings = (loadsToShow, columnsPerGroup, isInline, fontSize, type) => {
    let moreThan4 = false
    let funjumpClass = 'funjumptype'
    let showDotMenu = ''
    let setPos = ''
    let styledObject = {}

    if (loadsToShow > 0) {
        moreThan4 = true
        if (fontSize < 10 && columnsPerGroup === 1 || isInline) {
            moreThan4 = false
            funjumpClass = 'funjumptype removewidth'
            styledObject = {
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
            }

        }
        if (isInline) {
            moreThan4 = false
            funjumpClass = 'funjumptype removewidth is-block'
            showDotMenu = 'show-dot-menu'
            setPos = 'set-position'
            styledObject = {
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
            }
        }
    }
    else {
        moreThan4 = false
        funjumpClass = 'funjumptype'
    }

    if (columnsPerGroup === 1) {
        styledObject = {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        }
    }
    return {
        settingsMoreThan4: moreThan4,
        settingsFunjumpClass: funjumpClass,
        settingsShowDotMenu: showDotMenu,
        settingsSetPos: setPos,
        settingsStyledObject: styledObject
    }
}

export default returnSettings
