export const OPEN_MODAL = 'OPEN MODAL';
export const EDIT_RANGE = 'EDIT RANGE';
export const CLOSE_MODAL = 'CLOSE MODAL';
export const CLOSE_SEARCH = 'CLOSE SEARCH';
export const JUMPER_NAME = 'JUMPER NAME';
export const DATE_SUBMIT = 'DATE SUBMIT';

export const JUMPER_LOGS = 'JUMPER LOGS';
export const NUMBER_SLOTS = 'NUMBER SLOTS';
export const STAFF_SUMMARY = 'STAFF SUMMARY';
export const COMBINED_TOTAL = 'COMBINED TOTAL';
export const JUMPER_STATEMENT = 'JUMPER STATEMENT';
export const JUMPER_STATE = 'JUMPER STATE';
export const TANDEM_STATE = 'TANDEM STATE';
export const INVOICE_BY_CATEGORY = 'INVOICE BY CATEGORY';
export const JUMPER_STATES = 'JUMPER STATES';
export const LOAD_HISTORY = 'LOAD HISTORY';
export const DETAIL_BY_CATEGORY = "DETAIL BY CATEGORY";
export const GIFT_CARD = "GIFT CARD";
export const TANDAM_STUDENT = "TANDAM_STUDENT";
export const SLOTS_BY_PLANE = 'SLOTS_BY_PLANE';
