
/**
 * http://usejsdoc.org/
 */
import React, { Component, Fragment } from 'react';



import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import ItemModal from './customModal';
import reportStore from '../../../stores/report-store';
import jumpersStore from '../../../stores/jumpers-store';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ViewWaiver extends Component {

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpen: false,
      errors: '',
      amount: '',
      jumperId: this.props.jumperId,
      isTandemStudent: this.props.isTandemStudent,
      filterData: [],
      tandemWaiver: {},
      siteWaiver: {},
      base64pdf: null,
      Isdata: false,
      isloading: false,
      numPages: null,
      pageNumber: 1
    }

    this.reportEvent = [
      { name: reportStore.getEventPrefix('jumperLogEvent'), func: this.handleJumperLogs }
    ];
  }

  componentDidMount = () => {
    this.reportEvent.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
  }

  componentWillUnmount = () => {
    this.reportEvent.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
  }

  handleJumperLogs = (logs) => {
    this.setState({ filterData: logs, Isdata: true });
    setTimeout(() => {
      this.setState({
        isloading: false
      })
    }, 3000)
  }

  toggleModal(e) {
    const { jumperId } = this.props

    var startDate = '';
    var endDate = '';
    let sDate = new Date();
    sDate = sDate.setHours(0, 0, 0, 0);
    startDate = new Date(sDate);
    startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

    let eDate = new Date();
    eDate = eDate.setHours(23, 59, 59, 999);
    endDate = new Date(eDate);
    endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

    // reportStore.GetJumperLogs(startDate, endDate, jumperId)
    // console.log("Api call", jumperId)
    if (this.props.base64pdf)
      this.setState({ isModalOpen: !this.state.isModalOpen, base64pdf: this.props.base64pdf });
    else if (this.props.waiverId) {
      jumpersStore.getWaiverById(this.props.waiverId)
        .then(waiver => {
          this.setState({ isModalOpen: !this.state.isModalOpen, base64pdf: waiver.pdf });
        })
    }
    /*
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      filterData: [],
      Isdata: false,
      isloading: true
    }
    */
  }


  componentWillReceiveProps(props) {
    // console.log("JumperId", this.props.jumperId);
    // const { jumperId } = this.props

    // if (jumperId) {
    //   this.setState({
    //     isloading: true,
    //     jumperId: jumperId,
    //     Isdata: false
    //   })
    // }
  }

  checkForNegatives = (price) => {

    let priceCell = (price < 0) ?
      {
        value: `($${Math.abs(price).toFixed(2)})`
      } :
      {
        value: `$${price.toFixed(2)}`
      }

    if (price < 0) {
      return <span style={{ color: 'red' }}> {priceCell.value}</span>;
    }
    return priceCell.value;
  }


  // filterData = () => {
  //   let filterDataArray = [];
  //   let filterOutput = [];
  //   let jumperArray = [];

  //   if (this.state.Isdata === true) {
  //     this.filterDataArray = this.state.filterData;

  //     for (var item in this.filterDataArray) {
  //       filterOutput = [];
  //       var logArray = this.filterDataArray[item];
  //       for (var items in logArray) {
  //         var desc = logArray[items];
  //         let JumperInfo = [];

  //         if (desc.jumperIds !== undefined) {
  //           var otherJumper = desc.jumperIds;
  //           for (var oitem in otherJumper) {
  //             var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
  //             JumperInfo.push({ jumperName: name })
  //           }
  //         }

  //         var date = new Date(desc.createdAt);
  //         var localDate = '';
  //         var type = '';
  //         if (desc.type != undefined) {
  //           if (desc.type === "aff main") {
  //             type = desc.type + ' ' + desc.level;
  //           }
  //           else {
  //             type = desc.type;
  //           }
  //         }
  //         else {
  //           type = desc.jumpType;
  //         }

  //         if (desc.createdAt !== undefined) {
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }
  //         else {
  //           var date = new Date(desc.date);
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }

  //         filterOutput.push({ id: desc._id, type: type, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo })

  //       }
  //       if (logArray.length > 0) {
  //         jumperArray.push({ label: item, jumperStatement: filterOutput })
  //       }
  //     }
  //   }
  //   return jumperArray;
  // }

  filterData = () => {
    let filterDataArray = [];
    let filterOutput = [];
    let jumperArray = [];
    let summaryArray = [];
    let sortedArray = [];

    if (this.state.Isdata === true) {
      this.filterDataArray = this.state.filterData;
      for (var sortItem in this.filterDataArray) {
        if (sortItem !== 'summary') {
          jumperArray.push({ label: this.DateConvert(sortItem), type: 'date', jumperStatement: [], summary: [] })
          var statments = this.filterDataArray[sortItem];
          for (var item in statments) {
            filterOutput = [];
            var logArray = statments[item];
            for (var items in logArray) {
              var desc = logArray[items];
              let JumperInfo = [];

              if (desc.jumperIds !== undefined) {
                var otherJumper = desc.jumperIds;
                for (var oitem in otherJumper) {
                  var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
                  JumperInfo.push({ jumperName: name })
                }
              }

              var date = new Date(desc.createdAt);
              var localDate = '';
              var type = '';
              if (desc.type != undefined) {
                if (desc.type === "aff main") {
                  type = desc.type + ' ' + desc.level;
                }
                else {
                  type = desc.type;
                }
              }
              else {
                type = desc.jumpType;
              }

              if (desc.createdAt !== undefined) {
                //localDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
              }
              else {
                var date = new Date(desc.date);
                localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
              }

              filterOutput.push({ id: desc._id, type: type, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo, transactionType: desc.transactionType, description: desc.description })

            }
            if (logArray.length > 0) {
              jumperArray.push({ label: item, jumperStatement: filterOutput, summary: summaryArray })
            }
          }
          jumperArray.push({ label: 'EndDayBalance', value: statments.endDayBalance, jumperStatement: [], summary: [] })
          //sortedArray.push(jumperArray);
        }
      }
    }
    // return filterOutput;
    return jumperArray;
  }

  formatDate(input) {
    if (input.toString().length == 1) {
      return '0' + input;
    }
    else {
      return input;
    }
  }


  renderJumper = (items) => {
    let { classes } = this.props;
    return items.jumperStatement.map((item, index) => {
      return (
        <Fragment>
          <tr key={`jumperlogsrow${index}`}>
            <td
              key={`jumperlogscolumn1${index}`}
            >
              {item.type}
            </td>

            <td
              key={`jumperlogscolumn2${index}`}
            >
              {item.date}
            </td>

            <td
              key={`jumperlogscolumn3${index}`}
            >
              {item.price != undefined ? this.checkForNegatives(item.price) : ''}
              {item.amount != undefined ? this.checkForNegatives(item.amount) : ''}
            </td>

            <td
              key={`jumperlogscolumn4${index}`}
            >
              {item.loadNum != undefined ? item.loadNum : ''}
            </td>
          </tr>

          {
            item.type.trim() === "charge" && item.memo &&
            <tr
              key={`jumperlogsMemorow${index}`}>
              <td
                colSpan={4}
                key={`jumperlogsMemocolumnr${index}`}
                style={{ paddingLeft: '20px' }}
              >
                {item.memo}
              </td>
            </tr>
          }

          {
            item.type.trim() === "transfer" &&
            <tr
              key={`jumperlogsDescrow${index}`}>
              <td
                colSpan={4}
                key={`jumperlogsDesccolumnr${index}`}
                style={{ paddingLeft: '20px' }}
              >
                {item.description && "Description : " + item.description}

                {
                  item.memo && item.description &&
                  <br />
                }

                {item.memo !== "" && "Memo : " + item.memo}
              </td>
            </tr>
          }

          {
            item.type.trim() == "miscellaneous" && item.memo &&
            <tr
              key={`miscellaneouscrow${index}`}>
              <td
                colSpan={4}
                key={`miscellaneous${index}`}
                style={{ paddingLeft: '20px' }}
              >
                {item.memo}
              </td>
            </tr>
          }

          {
            item.type.trim() == "adjustment" && item.memo &&
            <tr
              key={`adjustmentrow${index}`}>
              <td
                colSpan={4}
                key={`adjustment${index}`}
                style={{ paddingLeft: '20px' }}
              >
                {item.memo}
              </td>
            </tr>
          }

          {
            item.JumpType === "funJumps"
              ?
              this.renderOtherJumper(item.JumperArray, index)
              :
              ''
          }
        </Fragment>
      )
    })
  }

  DateConvert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


  renderOtherJumper = (JumperArray, index) => {
    let { classes } = this.props;
    var otherJumpers = "";

    JumperArray.map((item) => {
      if (otherJumpers === "") {
        otherJumpers = item.jumperName;
      }
      else {
        otherJumpers = otherJumpers + ', ' + item.jumperName;
      }
    })

    return (
      <tr key={`otherjumperRow${index}`}>
        <td
          colSpan={4}
          key={`otherjumpercolumn${index}`}
          style={{ paddingLeft: '20px' }}
        >
          {otherJumpers}
        </td>
      </tr>
    )
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1,
    });
  };

  changePage = offset => this.setState(prevState => ({
    pageNumber: prevState.pageNumber + offset,
  }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  render() {
    const { Isdata, filterData, isloading, numPages, pageNumber } = this.state;

    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <React.Fragment>
        <Modal id="modal-waiver" isOpen={this.state.isModalOpen} >
          <ModalHeader>View Waiver <button style={{ color: 'white' }} onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="close" >&times;</button> </ModalHeader>
          <ModalBody>
            <Document file={"data:application/pdf;base64," + this.state.base64pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <div>
              <p>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={this.previousPage}
              >
                Previous
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={this.nextPage}
              >
                Next
              </button>
            </div>
          </ModalBody>
        </Modal>
        <a><span className="fa fa-search" onClick={this.toggleModal}>

        </span></a>
      </React.Fragment>
    );
  }
}

export default ViewWaiver
