import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Axios from 'axios'
import { Line } from 'rc-progress';
import loadsStore from '../../stores/loads-store';
import ShowMedia from './ShowMedia';
import io from 'socket.io-client'
import { connect } from 'react-redux'
import LeftMenu from '../menu/LeftMenu1';
import background from '../images/background.svg';
import logo from '../images/logo.svg';
import { ScaleLoader } from 'react-spinners';
import { Modal, ModalHeader, ModalBody, Col, Row, } from 'reactstrap';
import urlgetter from '../../services/getMainUrl';


const socket = io(urlgetter.getMainApiUrl())


function MyDropzone({ mediaInfo, history, updateCompleteTandemJump, updateCompletedCoachJump, updateCompletedAffJump }) {
  const [p, setP] = useState(0)
  const [SP, setSP] = useState(false)
  const [emailNotification, setemailNotification] = useState(false)
  const [emailNotificationMsg, setemailNotificationMsg] = useState('')
  const [media, setmedia] = useState([])
  const [shareAbleLink, setShareAbleLink] = useState('link')
  const [albumId, setAlbumId] = useState('')
  var aid = ''
  var shareableUrl = ''

  useEffect(() => {
    // setShareAbleLink("start linking")
    // socket.on('completeUploadMediaProgress', payload => {
    //   setmedia(payload.mediaItems)
    // });
    socket.on('completeUploadMedia', payload => {
      console.log(payload)
      if (payload.albumId === mediaInfo.jumpId) {
        if (payload.shareableLink && !mediaInfo.albumUrl) {
          var body = mediaInfo.jump
          if (mediaInfo.type === "tandemJump") {
            body = {
              ...body,
              instructorId: body.instructor._id,
              studentId: body.student._id,
              videographerId: body.videographer._id,
              albumUrl: payload.shareableLink,
              isToday: true
            }
            updateCompleteTandemJump(mediaInfo.loadNum, mediaInfo.jumpId, body)
          }
          if (mediaInfo.type === "coachJump") {
            body = {
              ...body,
              coachId: body.coach._id,
              jumperId: body.jumper._id,
              albumUrl: payload.shareableLink,
              isToday: true
            }
            updateCompletedCoachJump(mediaInfo.loadNum, mediaInfo.jumpId, body)
          }
          if (mediaInfo.type === "affJump") {
            var today = new Date();
            var isWeekend = (today.getDay() % 6 === 0);
            body = {
              ...body,
              mainInstructorId: body.mainInstructor._id,
              studentId: body.student._id,
              videographerId: body.videographer._id,
              secondaryInstructorId: body.secondaryInstructor._id,
              albumUrl: payload.shareableLink,
              isToday: true,
              isWeekend
            }
            updateCompletedAffJump(mediaInfo.loadNum, mediaInfo.jumpId, body)
          }
          localStorage.setItem("mediaInfo", JSON.stringify({
            isCompleted: mediaInfo.isCompleted ? true : false,
            loadNum: mediaInfo.loadNum || "",
            jumpId: mediaInfo.jumpId || "",
            albumId: mediaInfo.albumId || "",
            name: mediaInfo.name,
            studentId: mediaInfo.studentId,
            albumUrl: payload.shareableLink || ""
          }))
        }
        setmedia(payload.mediaItems.reverse())
      }
    })
    socket.on('changeTandemStudentEmail', payload => {
      console.log("336 payload", payload)
      if (payload) {
        setemailNotification(true)
        setemailNotificationMsg(payload)
      }
    })
    if (!mediaInfo.isCompleted) {
      checkAlbumId()
    }
    else {
      setAlbumId(mediaInfo.albumId)
      aid = mediaInfo.albumId
      getMedia(mediaInfo.albumId)
    }
  }, [])
  function checkAlbumId() {
    loadsStore.checkAlbumId(mediaInfo.loadNum, mediaInfo.jumpId, {}).then(r => {
      if (!r) {
        history.push('/load-manager')
        return false
      }
      if (r.jump.albumId) {
        setAlbumId(r.jump.albumId)
        aid = r.jump.albumId
        shareableUrl = r.jump.shareableUrl
        console.log("setShareAbleLink --> ", r.jump.shareableUrl)
        setShareAbleLink(r.jump.shareableUrl)
        localStorage.setItem("mediaInfo", JSON.stringify({
          isCompleted: mediaInfo.isCompleted ? true : false,
          loadNum: mediaInfo.loadNum || "",
          jumpId: mediaInfo.jumpId || "",
          albumId: r.jump.albumId || "",
          name: mediaInfo.name,
          studentId: mediaInfo.studentId,
          albumUrl: r.jump.albumUrl || ""
        }))
        getMedia(r.jump.albumId)
      } else {
        updateAlbumId()
      }
    });
  }
  function getMedia(d) {
    Axios.post(`${urlgetter.getMainApiUrl()}/get_album`, {
      "jumpId": mediaInfo.jumpId,
      "jumpType": mediaInfo.type,
      "studentId": mediaInfo.studentId, "albumId": d ? d : aid
    }).then(r => {
      if (r.data.shareableLink && !mediaInfo.albumUrl) {
        console.log(mediaInfo, r.data)
        localStorage.setItem("mediaInfo", JSON.stringify({
          isCompleted: mediaInfo.isCompleted ? true : false,
          loadNum: mediaInfo.loadNum || "",
          jumpId: mediaInfo.jumpId || "",
          albumId: mediaInfo.albumId || "",
          jump: mediaInfo.jump,
          name: mediaInfo.name,
          studentId: mediaInfo.studentId,
          albumUrl: r.data.shareableLink || ""
        }))
        var body = mediaInfo.jump
        if (mediaInfo.type === "tandemJump") {

          body = {
            ...body,
            instructorId: body.instructor._id,
            studentId: body.student._id,
            videographerId: body.videographer._id,
            albumUrl: r.data.shareableLink,
            isToday: true
          }
          updateCompleteTandemJump(mediaInfo.loadNum, mediaInfo.jumpId, body)

        }
        if (mediaInfo.type === "coachJump") {
          body = {
            ...body,
            coachId: body.coach._id,
            jumperId: body.jumper._id,
            albumUrl: r.data.shareableLink,
            isToday: true
          }
          updateCompletedCoachJump(mediaInfo.loadNum, mediaInfo.jumpId, body)
        }
        if (mediaInfo.type === "affJump") {
          var today = new Date();
          var isWeekend = (today.getDay() % 6 === 0);
          body = {
            ...body,
            mainInstructorId: body.mainInstructor._id,
            studentId: body.student._id,
            videographerId: body.videographer._id,
            secondaryInstructorId: body.secondaryInstructor._id,
            albumUrl: r.data.shareableLink,
            isToday: true,
            isWeekend
          }
          updateCompletedAffJump(mediaInfo.loadNum, mediaInfo.jumpId, body)
        }
      }
      if (r.data.mediaItems) {
        setmedia(r.data.mediaItems.reverse())
      }
      else {
        setmedia([])
      }
    })
  }

  function updateAlbumId() {
    var date = new Date()
    date = date.getDate() + "_" + date.getMonth() + "_" + date.getFullYear()
    Axios.post(`${urlgetter.getMainApiUrl()}/create_album`, { "studentId": mediaInfo.studentId, "folderName": "TandemJump_" + mediaInfo.name.replace(" ", "-") + "_" + date }).then(r => {
      console.log("new id for jumper -->", r.data.shareableUrl)
      setAlbumId(r.data.id)
      setShareAbleLink(r.data.shareableUrl)
        // loadsStore.updateTandemJump(mediaInfo.loadNum, mediaInfo.jumpId, { albumId: r.data.id, albumUrl: r.data.shareableUrl }).then(r=>
        //   loadsStore.updateCompleteTandemJump(mediaInfo.loadNum, mediaInfo.jumpId, body)
        //   )
        .then(rs => {
          getMedia(r.data.id)
          aid = r.data.id
          shareableUrl = r.data.shareableUrl
          setShareAbleLink(r.data.shareableUrl)
          localStorage.setItem("mediaInfo", JSON.stringify({
            isCompleted: mediaInfo.isCompleted ? true : false,
            loadNum: mediaInfo.loadNum || "",
            jumpId: mediaInfo.jumpId || "",
            albumId: r.data.id || "",
            name: mediaInfo.name,
            studentId: mediaInfo.studentId,
            albumUrl: r.data.shareableUrl,
            isToday: true,
            type: mediaInfo.type
          }))
        });
    })
  }

  const onDrop = useCallback((acceptedFiles) => {

    var from = new FormData()
    for (var x = 0; x < acceptedFiles.length; x++) {
      from.append('files', acceptedFiles[x])
    }
    from.append('albumId', aid)
    from.append('jumpId', mediaInfo.jumpId)
    from.append('jumpType', mediaInfo.type)
    setSP(true)
    Axios.post(`${urlgetter.getMainApiUrl()}/upload_file`, from,
      {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          // console.log("onUploadProgress", totalLength);
          if (totalLength !== null) {
            setP(Math.round((progressEvent.loaded * 100) / totalLength))
          }
        }
      },
    ).then(r => {
      console.log("folderName", r)
      getMedia(aid)
      setSP(false)
    })
  }, [])

  const onDropRejected = useCallback((error, rejectedFiles) => {
    console.log("onDropRejected", error, rejectedFiles)
  }, [])


  const { getRootProps, getInputProps, isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*,video/*", onDrop, onDropRejected })

  return (
    <div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
      <div className="col-lg-2 col-md-2 col-sm-12 blueBg fullHeightPanes">
        <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
        <LeftMenu routeHistory={history} showCreate={true} />
      </div>
      <div className="col-lg-8 col-md-8 col-sm-12 py-4">
        <div className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p style={{
              width: "100%", height: "150px",
              background: isDragActive ? "#90ee90" : "#d3d3d3",
              position: "relative"
            }} >
              <p style={{
                position: "absolute", top: "44px", bottom: 0,
                fontSize: "25px", width: "100%", textAlign: "center",
              }}>
                {isDragActive ? "Drop the files here ... " : "Drag 'n' drop some files here, or click to select files"}
              </p>
            </p>
          </div>
        </div>
        {SP ?
          p == 100 ?
            <div className='sweet-loading'>
              <ScaleLoader
                height={35}
                width={8}
                margin={'2px'}
                radius={2}
                color={'#123abc'}
                loading={SP}
              />
              <span style={{ color: 'white', fontSize: '20px', marginLeft: '-20px ' }}>Please Wait...</span>
            </div>
            : <Line percent={p} strokeWidth="1" strokeColor="#00FF00" /> : null}
        <ShowMedia data={media} shareableUrl={mediaInfo.albumUrl} />
        <Modal id="modal-main" isOpen={emailNotification} >
          <ModalHeader></ModalHeader>
          <ModalBody>
            {emailNotificationMsg}
            <Row className="form-group m-t-1-rem">
              <Col md={{ size: 10 }}>
                <button onClick={(e) => {
                  e.preventDefault(); setemailNotification(false);
                  setemailNotificationMsg('');
                }} className="btn btn-bg lightColor createButton">CLOSE</button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
}
const mstp = state => {
  return {
    mediaInfo: JSON.parse(localStorage.getItem('mediaInfo'))
  }
}
const mdtp = dispatch => {
  return {
    mediaUpload: (d) => dispatch({ type: "MEDIA_UPLOAD", payload: d }),
    updateCompleteTandemJump: (a, b, c) => dispatch(loadsStore.updateCompleteTandemJump(a, b, c)),
    updateCompletedCoachJump: (a, b, c) => dispatch(loadsStore.updateCompletedCoachJump(a, b, c)),
    updateCompletedAffJump: (a, b, c) => dispatch(loadsStore.updateCompletedAffJump(a, b, c))
  }
}

export default connect(mstp, mdtp)(MyDropzone)