import { useState } from "react";
import logo from '../../images/logo.svg';
import LeftMenu from "../../menu/LeftMenu1"
import './fixedLeftMenu.css'


const FixedLeftMenu = ({ parHistory }) => {
    const isMobile = window.innerWidth <= 500;
    const [state, setState] = useState({
        open: false
    })
    return (
        <div className="left_container">
            <div className="fixed_left_menu_main">
                {
                    isMobile ?
                        <div className={`blueBg fullHeightPanes ${state.open ? "mobileLeftBar" : "hideSideBar"}`}>
                            <div className={`${state.open ? "show-panel" : "hide-Panel"}`}>
                                <LeftMenu routeHistory={parHistory} showCreate={true} />
                            </div>
                        </div>
                        :
                        <div className="blueBg fullHeightPanes">
                            <img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
                            <LeftMenu routeHistory={parHistory} showCreate={true} />
                        </div>
                }
            </div>
        </div>
    )
}


export default FixedLeftMenu;