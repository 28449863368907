/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, } from 'react-redux-form';
import axios from 'axios';
import ReactFileReader from 'react-file-reader';
import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select';
import DatePicker from "react-datepicker";
import itemStore from '../../../stores/item-store';
import reservationStore from '../../../stores/reservation-store';

import Api from '../../../services/itemsapi';
import cc from 'coupon-code';
import AdminModal from './customModal';

const buttonRef = React.createRef()

class CreateGrouponPackage extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
            loading: false,
            error: '',
            codes: [],
            filename: "",
            price: 0
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }
    handleSubmit(values) {
        console.log(values);
        if (JSON.stringify(values) === "{}") {
            return
        }
        var payload = { ...values };
        var item = this.props.item;
        payload.codes = this.state.codes;
        payload.filename = this.state.filename;
        payload.price = this.state.price;

        reservationStore.createGroupon(payload)
            .then(ret => {
                this.setState({ isModalOpen: !this.state.isModalOpen })
            })
    }


    handleFiles = (files) => {
        var reader = new FileReader();
        console.log(files[0]);
        console.log(files[0].name);
        var st = this;
        reader.onload = function (e) {
            // Use reader.result
            var codesString = reader.result;

            var codes = [];
            var str = "";
            for (var i = 0; i < codesString.length; i++) {

                if (codesString[i] !== "\n") {
                    str = str + codesString[i];
                }
                else {
                    codes.push(str);
                    str = "";
                }
            }
            console.log(codes);
            console.log(codes.length);
            var pricestr = files[0].name.split("$")[1].split(".")[0];
            console.log(pricestr);
            var price = Number(pricestr);
            st.setState({ filename: files[0].name, codes: codes, price: price })
        }
        reader.readAsText(files[0]);
    }

    render() {

        var tandemPackages = itemStore.getTandemPackages();

        var videoPackages = itemStore.getVideoPackages();

        var item = this.props.item || '';
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Groupon Package`}
                >
                    {this.state.error && <div style={{ marginBottom: '20px', color: 'red' }}>{this.state.error}</div>}
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>


                        <Row className="form-group">
                            <Label htmlFor="upload" md={3}>Name</Label>
                            <Col md={9}>
                                <ReactFileReader fileTypes={[".csv"]} handleFiles={this.handleFiles}>
                                    <button className='btn bg-white'>upload codes</button>
                                </ReactFileReader>
                            </Col>
                        </Row>


                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>Package Name</Label>
                            <Col md={9}>
                                <Control.input name="name" model=".name" className="form-control" value={this.state.filename}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="numCodes" md={3}>Num Codes</Label>
                            <Col md={9}>
                                <Control.input name="numCodes" model=".numCodes" className="form-control" value={this.state.codes.length}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price" model=".price" className="form-control" value={this.state.price}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="numTickets" md={3}>Num Tickets</Label>
                            <Col md={9}>
                                <Control.input name="numTickets" model=".numTickets" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="tandemPackageName" md={3}>Tandem Package</Label>
                            <Col md={9}>
                                <Control.select name="tandemPackageName" model=".tandemPackageName" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.videoType}>
                                    <option value="none">none</option>
                                    {tandemPackages.map(alt => <option value={alt.item}>{alt.item}</option>)}
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="videoPackages" md={3}>Video Package</Label>
                            <Col md={9}>
                                <Control.select name="videoPackageName" model=".videoPackageName" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.videoType}>
                                    <option value="none">none</option>
                                    {videoPackages.map(alt => <option value={alt.item}>{alt.item}</option>)}
                                </Control.select>
                            </Col>
                        </Row>

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" disabled={this.state.loading} type="submit" >
                                    {this.state.loading ? 'Saving...' : 'SUBMIT'}
                                </button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {!this.props.isEdit ?
                    <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </React.Fragment>
        );
    }
}
export default CreateGrouponPackage

