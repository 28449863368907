/**
 * http://usejsdoc.org/
 */

import React, { Component } from 'react';

import { connect } from 'react-redux'
import { Control, LocalForm } from 'react-redux-form';
import Select from 'react-select';
import loadsStore from '../../../stores/loads-store';
import ReactDOM from 'react-dom';
import LoadUpdateModal from '../modals/loadModal'
import Time from './Timer'

class EditableTimer extends Component {

    constructor(props) {
        super(props);
        this.handelConfirmSubmit = this.handelConfirmSubmit.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            isModalOpen: false,
            jumpType: null,
            load: props.load,
            Timer: props.Timer,
            type: props.type,
            label: props.type,
            jumpId: props.jumpId,
            editMode: props.editMode,
            TimerId: '',
            loadNum: props.loadNum,
            menuPlacement: "bottom",
            level: props.level,
            isCompleted: props.isCompleted,
            student: props.student,
            warningPopUp: false,
            startTime: 0
        };

        this.timerId = null;
        this.valRef = null;
        this.inputValue = '';
    }

    componentDidMount = () => {
        this.setState({ startTime: this.props.timeRemaining })
        if (this.valRef !== null)
            this.valRef.focus();
    }

    componentDidUpdate = () => {
        if (this.valRef !== null)
            this.valRef.focus();
    }

    handleChange = (selectedOption, loadNum, jumpId, type, label) => {
        this.setState({ editMode: !this.state.editMode });
        var today = new Date();
        var isWeekend = (today.getDay() % 6 === 0);

        if (selectedOption.value !== undefined) {
            if (type === "tandem") {
                var tandemJump = this.props.tandemJump;

                if (this.state.isCompleted) {
                    if (label === "I") {
                        var jumpPostBody = {
                            instructorId: selectedOption.value._id,
                            studentId: this.props.tandemJump.student._id,
                            videographerId: this.props.tandemJump.videographer._id,
                            Timer: this.props.tandemJump.Timer,
                            videoTimer: this.props.tandemJump.videoTimer
                        }
                    }
                    else if (label === "V") {
                        var jumpPostBody = {
                            videographerId: selectedOption.value._id,
                            instructorId: this.props.tandemJump.instructor._id,
                            studentId: this.props.tandemJump.student._id,
                            Timer: this.props.tandemJump.Timer,
                            videoTimer: this.props.tandemJump.videoTimer
                        }
                    }
                    if (this.props.isToday) {
                        jumpPostBody.isToday = true
                    }
                    var jumpId = this.props.tandemJump.jumpId;
                    var loadId = this.props.loadId;
                    jumpPostBody.isUltra = this.props.isUltra;
                    jumpPostBody.isWeekend = isWeekend;
                    jumpPostBody.isTimerUpdate = false;
                    this.props.updateCompleteTandemJump(loadId, jumpId, jumpPostBody);
                }
                else {
                    if (label === "I") {
                        var jumpPostBody = { instructorId: selectedOption.value._id }
                    }
                    else if (label === "V") {
                        var jumpPostBody = { videographerId: selectedOption.value._id }
                    }
                    loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
                }
            }
            else if (type === "tandemUltra") {
                var tandemJump = this.props.tandemJump;
                if (this.state.isCompleted) {
                    if (label === "I") {
                        var jumpPostBody = {
                            instructorId: selectedOption.value._id,
                            studentId: this.props.tandemJump.student._id,
                            videographerId: this.props.tandemJump.videographer._id,
                            Timer: this.props.tandemJump.Timer,
                            videoTimer: this.props.tandemJump.videoTimer
                        }
                    }
                    else if (label === "V") {
                        var jumpPostBody = {
                            videographerId: selectedOption.value._id,
                            instructorId: this.props.tandemJump.instructor._id,
                            studentId: this.props.tandemJump.student._id,
                            Timer: this.props.tandemJump.Timer,
                            videoTimer: this.props.tandemJump.videoTimer
                        }
                    }
                    if (this.props.isToday) {
                        jumpPostBody.isToday = true
                    }

                    jumpPostBody.isTimerUpdate = false;

                    var jumpId = this.props.tandemJump.jumpId;

                    var loadId = this.props.loadId;

                    jumpPostBody.isUltra = this.props.isUltra;
                    jumpPostBody.isWeekend = isWeekend;
                    // console.log("updateCompleteTandemJump",jumpPostBody);
                    loadsStore.updateCompleteTandemJump(loadId, jumpId, jumpPostBody);
                }
                else {
                    if (label === "I") {
                        var jumpPostBody = { instructorId: selectedOption.value._id }
                    }
                    else if (label === "V") {
                        var jumpPostBody = { videographerId: selectedOption.value._id }
                    }
                    loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
                }
            }
            else if (type === "aff") {
                if (this.state.isCompleted) {
                    if (label === "I1") {
                        var jumpPostBody = {
                            mainInstructorId: selectedOption.value._id,
                            secondaryInstructorId: this.props.affJump.secondaryInstructor._id,
                            videographerId: this.props.affJump.videographer._id,
                            studentId: this.props.affJump.student._id,
                            Timer: this.props.affJump.Timer
                        }
                        if (this.props.affJump.secondaryInstructor) {
                            jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
                        }
                    }
                    else if (label === "I2") {
                        var jumpPostBody = {
                            secondaryInstructorId: selectedOption.value._id,
                            mainInstructorId: this.props.affJump.mainInstructor._id,
                            videographerId: this.props.affJump.videographer._id,
                            studentId: this.props.affJump.student._id,
                            Timer: this.props.affJump.Timer
                        }
                    }
                    else if (label === "V") {
                        var jumpPostBody = {
                            videographerId: selectedOption.value._id,
                            secondaryInstructorId: this.props.affJump.secondaryInstructor._id,
                            mainInstructorId: this.props.affJump.mainInstructor._id,
                            studentId: this.props.affJump.student._id,
                            Timer: this.props.affJump.Timer
                        }
                        if (this.props.affJump.secondaryInstructor) {
                            jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
                        }
                    }
                    else if (label === "S") {
                        var jumpPostBody = {
                            studentId: selectedOption.value._id,
                            videographerId: this.props.affJump.videographer._id,
                            secondaryInstructorId: selectedOption.value._id,
                            mainInstructorId: this.props.affJump.mainInstructor._id,
                            Timer: this.props.affJump.Timer
                        }
                        if (this.props.affJump.secondaryInstructor) {
                            jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
                        }
                    }
                    if (this.props.isToday) {
                        jumpPostBody.isToday = true
                    }
                    jumpPostBody.level = this.state.level;
                    jumpPostBody.isWeekend = isWeekend;
                    var cjumpId = this.props.affJump.jumpId;
                    var loadId = this.props.loadId;
                    this.props.updateCompletedAffJump(loadId, cjumpId, jumpPostBody);
                }
                else {
                    if (label === "I1") {
                        var jumpPostBody = { mainInstructorId: selectedOption.value._id }
                    }
                    else if (label === "I2") {
                        var jumpPostBody = { secondaryInstructorId: selectedOption.value._id }
                    }
                    else if (label === "V") {
                        var jumpPostBody = { videographerId: selectedOption.value._id }
                    }
                    else if (label === "S") {
                        var jumpPostBody = { studentId: selectedOption.value._id }
                    }
                    loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
                }
            }
            else if (type === "coach") {

                if (this.state.isCompleted) {
                    if (label === "C") {
                        var jumpPostBody = { coachId: selectedOption.value._id };
                        if (this.props.isToday) {
                            jumpPostBody.isToday = true
                        }
                        jumpPostBody.TimerId = this.props.coachJump.Timer._id;
                        jumpPostBody.Timer = this.props.coachJump.Timer;
                        jumpPostBody.isWingsuit = this.props.isWingsuit;
                        var cjumpId = this.props.coachJump.jumpId;
                        var loadId = this.props.loadId;

                        this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);
                    }
                    else if (label === "J") {
                        var jumpPostBody = { TimerId: selectedOption.value._id };
                        if (this.props.isToday) {
                            jumpPostBody.isToday = true
                        }
                        jumpPostBody.coach = this.props.coach._id;
                        jumpPostBody.Timer = this.props.Timer.Timer;
                        var cjumpId = this.props.coachJump.jumpId;
                        var loadId = this.props.loadId;
                        this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);
                    }
                }
                else {
                    if (label === "C") {
                        var jumpPostBody = { coachId: selectedOption.value._id };
                        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
                    }
                    else if (label === "J") {
                        var jumpPostBody = { TimerId: selectedOption.value._id };
                        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
                    }
                }

            }
        }
    }

    cancelOrSubmit = () => {
        var ID = this.state.TimerId;
        var loadNum = this.state.loadNum;
        var jumpId = this.state.jumpId;
        var type = this.state.type;
        var label = this.state.label;

        var jumpPostBody = {};

        if (type === "tandem") {

            if (label === "I") {
                var jumpPostBody = { instructorId: ID }
            }
            else if (label === "V") {
                var jumpPostBody = { videographerId: ID }
            }
            loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
        }
        else if (type === "aff") {
            if (label === "I1") {
                var jumpPostBody = { mainInstructorId: ID }
            }
            else if (label === "I2") {
                var jumpPostBody = { secondaryInstructorId: ID }
            }
            else if (label === "V") {
                var jumpPostBody = { videographerId: ID }
            }
            loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
        }
        else if (type === "coach") {
            if (label === "C") {
                var jumpPostBody = { coachId: ID };
                loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
            }
            else if (label === "J") {
                var jumpPostBody = { TimerId: ID };
                loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
            }
        }
    }

    checkForNegatives = (Timer) => {
        //console.log('check for negatives: ' + Timer);
        if (Timer != undefined) {
            let TimerCell = (Timer < 0) ?
                {
                    value: `($${Math.abs(Timer).toFixed(2)})`
                } :
                {
                    value: `$${Timer.toFixed(2)}`
                }

            if (Timer < 0) {
                return <span className="editableTimer" style={{ color: 'lightsalmon' }}> {TimerCell.value}</span>;
            }
            return <span className="editableTimer" style={{ color: '#00FF00' }}> {TimerCell.value}</span>;;
        }
        else {
            return '$0.00';
        }
    }

    wantsEidtable = (data) => {
        this.setState({
            editMode: data,
            isModalOpen: false
        }, () => {
            if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
                this.setState({ menuPlacement: "top" });
            }
            else {
                this.setState({ menuPlacement: "bottom" });
            }
        }
        )
    }

    changeTimer = (e, isCompleted) => {
        e.preventDefault();
        localStorage.setItem("disabledArrowButton", true)
        if (isCompleted) {
            this.setState({
                isModalOpen: true
            })
        }
        else {
            if (!this.state.editMode) {
                this.setState({
                    editMode: true
                })
            }
        }
    }

    onBlur = () => {
        if (this.state.editMode && !this.props.isCompleted) {
            this.setState({
                editMode: false
            })
        }
    }

    handelConfirmSubmit() {
        this.setState({
            warningPopUp: !this.state.warningPopUp
        })
    }

    toggleModal() {
        this.setState({
            warningPopUp: !this.state.warningPopUp
        })
    }

    onBlur = () => {
        //alert('blurring timer');

        localStorage.setItem("disabledArrowButton", false)
        if (this.state.editMode && !this.props.isCompleted) {
            this.setState({
                editMode: false
            })
        }
        var payload = { timeToAdd: 0 }

        loadsStore.UpdateLoad(this.props.loadNum, payload)
            .then(result => {
                this.setState({ isloading: false });
            })
            .then(result => {
                // console.log('Capacity Updated');
                this.setState({ isloading: false });
            })
    }


    handleInput(e, val) {
        alert(e);
    }

    handleSubmit(values, loadNum) {
        this.setState({ editMode: !this.state.editMode });

        let payload = { timeRemaining: Number(values.timer * 60) }
        loadsStore.UpdateLoad(loadNum, payload)
            .then(result => {
                this.setState({ isloading: false });
            })
            .then(result => {
                // console.log('Capacity Updated');
                this.setState({ isloading: false });
            })
    }
    renderEditBtn = (type) => {
        return <button
            className="btn btn-info edit-btns"
            onClick={() => this.handleTimeChange(type)}
        >
            {type === "inc" ? '+' : '-'}
        </button>
    }
    handleTimeChange = (type) => {
        console.log(type);
        let { timeRemaining, loadNum } = this.props;
        let newTime = Number(timeRemaining);
        var payload = { timeToAdd: 60 }
        if (type === 'inc') {
            payload = { timeToAdd: 60 }
        }
        else if (type === 'dec') {
            payload = { timeToAdd: -60 }
        }

        loadsStore.UpdateLoad(loadNum, payload)
            .then(result => {
                this.setState({ isloading: false });
            })
            .then(result => {
                // console.log('Capacity Updated');
                this.setState({ isloading: false });
            })
    }

    startTimer = () => {
        if (!this.props.isPaused) return;
        loadsStore.setStatus(this.props.loadNum, { isPaused: false })
    }

    render() {
        const { isCompleted, timeRemaining, loadNum, Timer, isPaused, load } = this.props;
        const { isModalOpen, editMode } = this.state;
        return (
            <div className="editable-timer">
                {
                    isPaused ? <span style={{ cursor: 'pointer' }} onClick={() => this.startTimer()}>PAUSED</span>
                        :
                        <div>
                            <div className="show-time-with-btn" >
                                {
                                    !editMode ?
                                        // <span onClick={(e) => this.changeTimer(e, isCompleted)}>{timeRemaining}</span>
                                        <span onClick={(e) => this.changeTimer(e, isCompleted)}><Time load={load} command={"start"} startTime={timeRemaining} loadNum={this.props.loadNum} /></span>
                                        :
                                        <LocalForm
                                            form="editTimers"
                                            style={{ width: '90px', display: 'inline' }}
                                            model="editTimers"
                                            getDispatch={dispatch => this.formDispatch = dispatch}
                                            onSubmit={(values) => this.handleSubmit(values, loadNum)}
                                            className={"cc"}
                                        >
                                            <span>
                                                <Control.input
                                                    name="timer"
                                                    model=".timer"
                                                    style={{ width: '40px', display: "inline-block" }}
                                                    getRef={(node) => this.valRef = node}
                                                    mapProps={{ onChange: (props) => props.onChange }}

                                                    defaultValue={Timer}
                                                    autoComplete="off"
                                                    onBlur={this.onBlur}
                                                />
                                            </span>
                                        </LocalForm>
                                }
                                {/* <span> mins</span> */}
                                {this.renderEditBtn("dec")}
                                {this.renderEditBtn("inc")}

                            </div>
                        </div>
                }
                <LoadUpdateModal
                    isOpen={isModalOpen}
                    onClick={this.wantsEidtable}
                />
            </div>
            // <React.Fragment>
            //     {
            //         !editMode ?
            //             // <button className="btn lightBlueButton Timer-name" onClick={(e) => { e.preventDefault(); this.changeTimer(this.props.isCompleted); }} type="submit" disabled={this.props.disabled}>
            //             <span
            //                 onClick={(e) => { e.preventDefault(); this.changeTimer(isCompleted); }}
            //                 disabled={disabled}>
            //                 {timeRemaining}
            //             </span>
            //             //</button>
            //             :
            //             // <button className="btn lightBlueButton Timer-name" disabled={this.props.disabled}>
            //             <LocalForm
            //                 form="editTimers"
            //                 style={{ display: 'inline' }}
            //                 model="editTimers"
            //                 getDispatch={dispatch => this.formDispatch = dispatch}
            //                 onSubmit={(values) => this.handleSubmit(values, loadNum)}
            //             >
            //                 <span>
            //                     <Control.input
            //                         name="timer"
            //                         model=".timer"
            //                         style={{ width: '40px', display: "inline-block" }}
            //                         getRef={(node) => this.valRef = node}
            //                         mapProps={{ onChange: (props) => props.onChange }} defaultValue={Timer}
            //                         autoComplete="off"
            //                         onBlur={this.onBlur}
            //                     />
            //                 </span>
            //             </LocalForm>
            //     }
            //
            // </React.Fragment>
        );
    }
}

const mstp = state => {
    return {
        state
    }
}
const mdtp = dispatch => {
    return {
        updateCompletedAffJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedAffJump(loadId, cjumpId, jumpPostBody)),
        updateCompleteTandemJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody)),
        updateCompletedCoachJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)),
        addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
    }
}

export default connect(mstp, mdtp)(EditableTimer)
