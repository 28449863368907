import moment from "moment";
import { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import swal from 'sweetalert';
import reservationStore from '../../stores/reservation-store'
import { SendEmailSelect} from "../schedules/schedulesCenter";



const Send_Confirmation_Email = ({ isMemoModalOpen, setState, state ,templates }) => {
    const [selectedTemplate, setSelectedTemplate] = useState();
    const sendEmail = () => {

    let jumpers = []
    if (state.reservation.tandemStudents.length > 0) {
      jumpers = state.reservation.tandemStudents;
    } else {
      jumpers = state.reservation.students;
    }
    const invoice = state?.reservation?.invoice
    let email = {
      email: state.emailId,
      name: jumpers[0].firstname +" " + jumpers[0].lastname,
      phone: jumpers[0].phone,
      amount:state.Amount,
      calender_id: state.reservation.calender_id,
      ticketCount: state.reservation.ticketCount,
      eventName: state.reservation.eventName,
      timeZone: state.event[0].timeZone,
      reservationDate: moment(state?.reservation?.datetime)
        .tz(state.event[0].timeZone)
        .format("DD/MMM/YYYY,  h:mm A"),
        total: state.reservation.totalValue,
        maskedCardNum:state.reservation.maskedCardNum,
        invoice: invoice,
        fees: state.reservation.totalFees,
        tax: state.reservation.totalTax
     }

    const data = {
        templateID: selectedTemplate,
        recipients: [email],
      };


      if (selectedTemplate == "default-template"){
        return  reservationStore.sendDefaultConfirmationEmail(data).finally(() => {
          setState({ isSendEmailModalOpen: false })
        })
      }else {
        return  reservationStore.sendConfirmationEmailWithTemplate(data).finally(() => {
          setState({ isSendEmailModalOpen: false })
        })
      }


    };
   
  return (
    <Modal id="modal-main1" isOpen={isMemoModalOpen} >
      <ModalHeader style={{ justifyContent: "center", color: "#007bff" }}>Send Reminder Email</ModalHeader>
      <ModalBody>
        <div className="form-group">
         <label htmlFor="pilot" className="col-md-8 " style={{ marginLeft: "7px", color: "#007bff" }}>Select Email template:</label>
         <div className="col-md-8 mb-2">
            <SendEmailSelect asSelect onSelect={setSelectedTemplate} templates={templates}/>
         </div>
         <label htmlFor="pilot" className="col-md-4 " style={{ marginLeft: "7px", color: "#007bff" }} > Enter Email-Id :</label>
         <div className="col-md-8">
         <input type="text" name="amount" className="form-control" value={state.emailId} onChange={(e) => setState({ emailId: e.target.value })} autoComplete="off" />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
      <Button disabled={!selectedTemplate} color="primary" onClick={sendEmail} >Send</Button>
        <Button color="primary" onClick={() => setState({ isSendEmailModalOpen: false })}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Send_Confirmation_Email;