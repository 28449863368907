import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import JmpModal from './modals/customModal';
import {
  Card, CardBody, Row, Col, Badge, Modal, ModalHeader, ModalBody,
} from 'reactstrap';

import { DragSource } from 'react-dnd'
import CancelJump from './modals/cancelJump'
import EditableJumper from './modals/editableJumper'
import CreatableJumper from './modals/creatableJumper'
import UpdateAmount from './modals/updateAmount';
import EditablePrice from './modals/editablePrice'
// import GooglePicker from 'react-google-picker';
import Axios from 'axios';
import MyDropzone from './upload'
import loadsStore from '../../stores/loads-store'
import itemStore from '../../stores/item-store'
import { connect } from 'react-redux'
import AddJumpers from './modals/addJumpers'
import RemovableJumper from './modals/removableJumper'
import returnLoadSettings from './helper/returnLoadSettings'


const Types = {
  ITEM: 'JUMP'
};

const itemSource = {
  beginDrag(props) {
    //console.log('dragging');
    const item = props
    return item
  },
  endDrag(props, monitor, component) {
    //console.log('endrag')
    if (!monitor.didDrop()) {
      return;
    }
    //return props.handleJumperDrop(props)
    return props;
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}


class RenderPilotCard extends Component {

  constructor() {
    super();
    this.state = {
      optionMenu: false,
      showUpdate: false,
      uploadVideo: false,
      oToken: "",
      albumId: "",
      media: [],
      videosUploaded: false,
    }
  }


  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  setUploadVideo = (d) => {
    this.setState({
      uploadVideo: d
    })
  }

  addOverweight = (loadNum, jumpId, val) => {
    var jumpPostBody = { isOverweight: true };
    loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
      .then(ret => {
        this.setState({
          optionMenu: false
        });
      })
  }

  addVideoPackage = (loadNum, jumpId, videoPackageId) => {
    var jumpPostBody = { videoPackageId: videoPackageId };
    loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
      .then(ret => {
        this.setState({
          optionMenu: false
        });
      })
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    /*if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        optionMenu: false
      });
    }*/
    if (!domNode || !domNode.contains(event.target)) {
      if (this.state.optionMenu) {
        
          this.setState({
            optionMenu: false
          });
        
      }
    }
  }

  // handleEventClick = () => {
  //   if (this.state.optionMenu) {
  //     this.setState({
  //       optionMenu: false
  //     })
  //   }
  // }

  checkForNegatives = (price, videoPrice) => {
    //console.log('check for negatives: ' + price);
    if (price != undefined) {

      if (videoPrice !== undefined) {
        price += videoPrice;
      }

      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        }
        :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <Badge style={{ color: 'red' }}> {priceCell.value}</Badge>;
      }

      return <Badge>{priceCell.value}</Badge>;
    }
    else {
      return '';
    }
  }

  toggleOptions = () => {
    this.setState({
      optionMenu: !this.state.optionMenu
    })
  }

  renderPilotLabel = (tandemJump) => {
    if (tandemJump.isCombo) {
      return tandemJump.tandemPackageName;
    }
    else if (tandemJump.videoType !== 'none') {
      return "Tandem w/ " + tandemJump.videoPackageName;
    }
    else {
      return "Tandem"
    }
  }

  // handleBlur = () => {
  //   if(this.state.optionMenu)
  //   {
  //     this.setState({
  //       optionMenu:false
  //     })
  //   }
  // }

  handleUpdate = (type, jumpid) => {
    // this.setState({
    //   showUpdate:true
    // },()=>{
    //   alert(this.state.showUpdate);
    // })
    if (jumpid) {
      document.getElementById(type + jumpid).click();
    }
  }

  handleDelete = (jumpId) => {
    document.getElementById(jumpId).click();
  }

  render() {
    console.log('in render pilot card');

    const { pilot, pilotPay, loadNum, disabled, jumpId, pilotOptions, videographerOptions, isDragging, connectDragSource, observerOptions, price, isCompleted, loadId, loadsToShow, fontSize, columnsPerGroup, isInline } = this.props;
    const self = this
    const { settingsMoreThan4, settingsFunjumpClass, settingsShowDotMenu, settingsSetPos, settingsStyledObject } = returnLoadSettings(loadsToShow, columnsPerGroup, isInline, fontSize)
    return connectDragSource(
      <div>
        {
          this.state.optionMenu &&
          <ul className={loadsToShow > 3 ? "menu-option-moreloads" : "menu-option"}>
            <li>
              <span onClick={() => this.handleUpdate("update-Amount", jumpId)}>Add Observer</span>
            </li>
          </ul>
        }
        <Card className="jumpcard" onClick={this.handleBlur}>
          <CardBody style={{ padding: '0px !important' }}>
            <div className={settingsMoreThan4 ? "" : "row"}>
              {/* <Col xs="3" md="12" lg="3" className="jumptype" style={{padding: '0 0 0 9px' }}>
                {this.renderTandemLabel(tandemJump)}
                {price !== undefined ? this.checkForNegatives(price, videoPrice) : ''}
              </Col> */}
              <div className={settingsMoreThan4 ? "funjumptypemoreload" : `${settingsFunjumpClass} col`} style={{ backgroundColor: '#6ca1c9' }}>
                Pilot
              </div>
              <div className={settingsMoreThan4 ? "" : "cardmiddle col"} style={{ margin: 'auto' }}>
                <ul className={`tandem-load ${settingsShowDotMenu}`} style={{ backgroundColor: '#6ca1c9' }}>
                  <li>
                    <EditableJumper
                      type={"pilot"}
                      label={"P"}
                      isToday={this.props.isToday}
                      loadNum={loadNum}
                      activeJumperIdsOnLoad={this.props.load.activeJumperIds}
                      planeLoadNum={this.props.planeLoadNum}
                      plane={this.props.plane}
                      jumpId={jumpId}
                      jumper={pilot}
                      isCompleted={this.props.isCompleted}
                      loadId={this.props.loadId}
                      options={pilotOptions}
                      price={pilotPay}
                    />
                    <EditablePrice isToday={this.props.isToday} isCompleted={this.props.isCompleted} price={pilotPay} disabled={disabled} type="pilot" label="P" jumpId={jumpId} loadNum={this.props.loadNum} planeLoadNum={this.props.planeLoadNum} plane={this.props.plane} loadId={this.props.loadId} jumperId={pilot._id} />

                  </li>
                  {
                    pilot.observers.map(observer => {
                      return (
                        <li>
                          <RemovableJumper isToday={this.props.isToday} loadNum={loadNum} jumper={observer} loadId={loadId}
                            isCompleted={isCompleted} type="observer" />
                          <EditablePrice price={observer.price} type="observer" jumpId={jumpId} loadNum={loadNum} jumperId={observer._id} loadId={loadId} isCompleted={isCompleted} isToday={this.props.isToday} />
                        </li>
                      )
                    })
                  }
                  <span className={settingsMoreThan4 ? "add-jumper-btn" : ""} style={{ background: 'inherit' }}>
                    <AddJumpers loadsToShow={loadsToShow} fontSize={fontSize} columnsPerGroup={columnsPerGroup} isToday={this.props.isToday} loadNum={loadNum} disabled={disabled} jumperOptions={observerOptions}
                      loadId={this.props.loadId} isCompleted={this.props.isCompleted} jumperLength={pilotOptions.length} type="observer" />
                  </span>
                </ul>



                <div className={settingsMoreThan4 ? "right-menu-morethan4" : `right-menu ${settingsSetPos}`} onClick={this.toggleOptions}>
                  <span>
                    <i className="fa fa-ellipsis-v"></i>
                  </span>
                </div>

              </div>
              {/* <Col xs="1" md="2" lg="1" className="jumpClose" style={{ paddingLeft: '4px' }}>
                <UpdateAmount isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"tandemJump"}
                  isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={tandemJump} isUltra= {tandemJump.isUltra} />
                {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} jumpType={"tandemJump"} />}
              </Col> */}
            </div>
          </CardBody>
        </Card>
        {
          // loadsToShow > 3 ? this.renderPilot("isDiv") : this.renderPilot()
        }
        {/* <Card className="jumpcard" onClick={this.handleBlur}>
          <CardBody style={{ padding: '0px !important' }}>
              <Row> */}
        {/* <Col xs="3" md="12" lg="3" className="jumptype" style={{padding: '0 0 0 9px' }}>
                {this.renderTandemLabel(tandemJump)}
                {price !== undefined ? this.checkForNegatives(price, videoPrice) : ''}
              </Col> */}
        {/* <div className="funjumptype col" style={{ backgroundColor: '#6ca1c9' }}>
                Pilot
              </div>
              <div className="cardmiddle col" style={{ margin: 'auto' }}>
                <ul className="tandem-load" style={{ backgroundColor: '#6ca1c9' }}>
                  <li>
                    <EditableJumper
                      type={"pilot"}
                      label={"P"}
                      isToday={this.props.isToday}
                      loadNum={loadNum}
                      jumpId={jumpId}
                      jumper={pilot}
                      isCompleted={this.props.isCompleted}
                      loadId={this.props.loadId}
                      options={pilotOptions}
                      price={pilotPay}
                    />
                    <EditablePrice isToday={this.props.isToday} isCompleted={this.props.isCompleted} price={pilotPay} disabled={disabled} type="pilot" label="P" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={pilot._id} />

                  </li>
                  {
                    pilot.observers.map(observer => {
                      return (
                        <li>
                          <RemovableJumper isToday={this.props.isToday} loadNum={loadNum} jumper={observer} loadId={loadId}
                            isCompleted={isCompleted} type="observer" />
                          <EditablePrice price={observer.price} type="observer" jumpId={jumpId} loadNum={loadNum} jumperId={observer._id} loadId={loadId} isCompleted={isCompleted} isToday={this.props.isToday} />
                        </li>
                      )
                    })
                  }
                  <AddJumpers isToday={this.props.isToday} loadNum={loadNum} disabled={disabled} jumperOptions={observerOptions}
                    loadId={this.props.loadId} isCompleted={this.props.isCompleted} jumperLength={pilotOptions.length} type="observer" />
                </ul>



                <div className="right-menu" onClick={this.toggleOptions}>
                  <span>
                    <i className="fa fa-ellipsis-v"></i>
                  </span>
                </div>

              </div> */}
        {/* <Col xs="1" md="2" lg="1" className="jumpClose" style={{ paddingLeft: '4px' }}>
                <UpdateAmount isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"tandemJump"}
                  isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={tandemJump} isUltra= {tandemJump.isUltra} />
                {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} jumpType={"tandemJump"} />}
              </Col> */}
        {/* </Row>
          </CardBody>
        </Card> */}
      </div>
    )
  }
}

export default DragSource(Types.ITEM, itemSource, collect)(RenderPilotCard)
