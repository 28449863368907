import { LocalForm } from "react-redux-form";
import { Col, Row } from "reactstrap";
import CustomModal from "../../Common/CustomModal";
import './termsConditions.css'
import { deleteCondition } from "./utils";



const DeleteWarning = (props) => {

    return (
        <div>
            <CustomModal isModalOpen={props.isOpenModal} title={"Warning"}>
                <p className="warning_ques">Are you sure you want to delete this condition ?</p>
                <LocalForm>
                    <Row>
                        <Col className="warning-modal-col">
                            <button onClick={(e) => deleteCondition(e, props.conditionId,
                                props.setIsModalOpen)}>Submit</button>
                            <button onClick={() => props.setIsModalOpen(false)}>Cancel</button>
                        </Col>
                    </Row>
                </LocalForm>
            </CustomModal>
        </div>
    )
}


export default DeleteWarning;