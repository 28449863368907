import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import {
  Paper,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../reports/report/datatable/DataTableStyle';
import reportStore from '../../stores/report-store';

class JumperStatementPdf extends Component {
  constructor(props) {
    super(props);
    //alert(JSON.stringify(this.props.data))
    this.state = {
      filterData: [],
      Isdata: false,
      isloading: false
    };
    this.handleNewActivity = this.handleNewActivity.bind(this);
    this.reportEvent = [
      { name: reportStore.getEventPrefix('jumperLogEvent'), func: this.handleNewActivity },
    ];
  }



  componentWillMount() {
    this.reportEvent.forEach((evnt) => reportStore.on(evnt.name, evnt.func))
  }

  componentWillUnmount = () => {
    this.reportEvent.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
  }

  handleNewActivity = (data) => {
    this.setState({
      isloading: false,
      filterData: data,
      Isdata: true
    })
  }

  filterPdfData = () => {
    const { classes } = this.props;

    let filterDataArray = [];
    let filterOutput = [];
    let jumperArray = [];
    let mainArray = [];
    if (this.state.Isdata === true) {
      this.filterDataArray = this.state.filterData;
      for (var item in this.filterDataArray) {
        filterOutput = [];
        var logArray = this.filterDataArray[item];
        for (var items in logArray) {
          var desc = logArray[items];
          let JumperInfo = [];

          if (desc.jumperIds !== undefined) {
            var otherJumper = desc.jumperIds;
            for (var oitem in otherJumper) {
              var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
              JumperInfo.push({ jumperName: name })
            }
          }

          var date = new Date(desc.createdAt);
          var localDate = '';
          var type = '';
          if (desc.type != undefined) {
            if (desc.type === "aff main") {
              type = desc.type + ' ' + desc.level;
            }
            else {
              type = desc.type;
            }
          }
          else {
            type = desc.jumpType;
          }

          if (desc.createdAt !== undefined) {
            localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
          }
          else {
            var date = new Date(desc.date);
            localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
          }

          filterOutput.push({ id: desc._id, type: type, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo })

        }
        if (logArray.length > 0) {
          jumperArray.push({ label: item, jumperStatement: filterOutput })
        }

      }
    }

    let rows = [];
    var rowlength = 1;
    var childrow = [];
    rows.push(
      <TableHead>
        <TableRow key={'jumperlogHeader'}>
          <TableCell
            component="th"
            className={classes.subHeadings}>
            Jump Type
          </TableCell>
          <TableCell
            component="th"
            className={classes.subHeadings}>
            Date
          </TableCell>
          <TableCell
            component="th"
            className={classes.subHeadings}>
            Price
          </TableCell>
          <TableCell
            component="th"
            className={classes.subHeadings}>
            Load Num
          </TableCell>
        </TableRow>
      </TableHead>
    )
    for (var index = 0; index < jumperArray.length; index++) {
      rowlength++;
      childrow = [];
      var jumperList = jumperArray[index].jumperStatement;
      for (var j = 0; j < jumperList.length; j++) {

        rowlength++;
        // if(jumperList[j].JumperArray)
        // {
        //     var jumperLen = Math.ceil(jumperArray.length / 7)
        //     rowlength = rowlength + jumperLen;
        // }
        childrow.push(<Fragment>
          <TableRow
            key={`jumperlogsrow${j}`}>
            <TableCell
              className={classes.tableCell}
              key={`jumperlogscolumn1${j}`}
            >
              {jumperList[j].type}
            </TableCell>

            <TableCell
              className={classes.tableCell}
              key={`jumperlogscolumn2${j}`}
            >
              {jumperList[j].date}
            </TableCell>

            <TableCell
              className={classes.tableCell}
              key={`jumperlogscolumn2${j}`}
            >
              {jumperList[j].price != undefined ? this.checkForNegatives(jumperList[j].price) : ''}
              {jumperList[j].amount != undefined ? this.checkForNegatives(jumperList[j].amount) : ''}
            </TableCell>

            <TableCell
              className={classes.tableCell}
              key={`jumperlogscolumn2${j}`}
            >
              {jumperList[j].loadNum != undefined ? jumperList[j].loadNum : ''}
            </TableCell>
          </TableRow>
          {
            jumperList[j].type.trim() === "charge" && jumperList[j].memo &&
            <TableRow
              key={`jumperlogsrow${j}`}>
              <TableCell
                colSpan={4}
                className={classes.tableCell}
                key={`jumperlogscolumnr1${j}`}
                style={{ paddingLeft: '60px' }}
              >
                {jumperList[j].memo}
              </TableCell>
            </TableRow>
          }
          {
            jumperList[j].JumpType === "funJumps"
              ?

              this.renderOtherJumper(jumperList[j].JumperArray)
              :
              ''
          }
        </Fragment>);
        if (rowlength == 17) {
          rows.push(
            <Fragment>
              <TableRow
                key={`logpdf${index}`}>
                <TableCell
                  className={classes.sectionHeading}
                  key={`logCell${index}`}
                  colSpan={4}
                >
                  {jumperArray[index].label}
                </TableCell>
              </TableRow>
              {childrow}
            </Fragment>)
          mainArray.push(
            <Table
              id="jumperStatementReport"
              className={"jumperLogPanel" + classes.table}>
              <TableHead>
                <TableRow key={'jumperStatementReport'}>

                  <TableCell
                    className={classes.reportTableCell}>
                    <h1
                      className={classes.reportHeadingOne}>
                      Reports
                    </h1>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </Table>);
          rowlength = 1;
          childrow = [];
          rows = [];
        }
        else if (j === jumperList.length - 1 && index == jumperArray.length - 1) {
          rows.push(
            <Fragment>
              <TableRow
                key={`logpdf${index}`}>
                <TableCell
                  className={classes.sectionHeading}
                  key={`logCell${index}`}
                  colSpan={4}
                >
                  {jumperArray[index].label}
                </TableCell>
              </TableRow>
              {childrow}
            </Fragment>)
          mainArray.push(
            // <div className="jumperLogPanel" style={{ width: '25cm', margin: '20px 20px 0px 20px', padding: '20px 20px 0px 80px' }}>
            <Table
              id="jumperStatementReport"
              className={"jumperLogPanel" + classes.table}>
              <TableHead>
                <TableRow key={'jumperStatementReport'}>

                  <TableCell
                    className={classes.reportTableCell}>
                    <h1
                      className={classes.reportHeadingOne}>
                      Reports
                    </h1>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </Table>);
          rowlength = 1;
          childrow = [];
          rows = [];
        }
      }
      rows.push(
        <Fragment>
          <TableRow
            key={`logpdf${index}`}>
            <TableCell
              className={classes.sectionHeading}
              key={`logCell${index}`}
              colSpan={4}
            >
              {jumperArray[index].label}
            </TableCell>
          </TableRow>
          {childrow}
        </Fragment>)

    }
    console.log(rows);

    return mainArray;
  }

  formatDate(input) {
    if (input.toString().length == 1) {
      return '0' + input;
    }
    else {
      return input;
    }
  }

  checkForNegatives = (price) => {

    let priceCell = (price < 0) ?
      {
        value: `($${Math.abs(price).toFixed(2)})`
      } :
      {
        value: `$${price.toFixed(2)}`
      }

    if (price < 0) {
      return <span style={{ color: 'red' }}> {priceCell.value}</span>;
    }
    return priceCell.value;
  }

  renderOtherJumper = (JumperArray) => {
    let { classes } = this.props;
    var otherJumpers = "";


    JumperArray.map((item) => {
      if (otherJumpers === "") {
        otherJumpers = item.jumperName;
      }
      else {
        otherJumpers = otherJumpers + ', ' + item.jumperName;
      }
    })

    return (
      <TableRow>
        <TableCell
          colSpan={4}
          className={classes.tableCell}
          key={`jumperlogscolumn2`}
          style={{ paddingLeft: '60px' }}
        >
          {otherJumpers}
        </TableCell>
      </TableRow>
    )
  }

  convertCase = (stringValue) => (stringValue.toLowerCase())

  dateConverter = (date) => {
    let dateInMilliseconds = new Date(date).getTime();
    return dateInMilliseconds;
  }




  render() {
    let { classes, tableType } = this.props;
    return (
      this.filterPdfData()
    );
  }
}

export default (withStyles(styles)(JumperStatementPdf));
