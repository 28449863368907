import React, { Component } from 'react'

class CustomCounter extends Component {

    handleClick = (type) => {
        let { count } = this.props;
        if (type === 'dec') {
            if (count > 0) {
                this.props.setCounter(count - 1);
            }
        }
        else {
            this.props.setCounter(count + 1);
        }
    }
    handleChange = (e) => {
        this.props.setCounter(e.target.value)
    }
    render() {
        let { count } = this.props;
        return (
            <div className="d-flex align-items-center font-weight-bold">
                <button
                    type="button"
                    className="btn px-3 py-2 btn-info"
                    onClick={() => this.handleClick('dec')}
                >
                    -
                </button>
                <input contentEditable={true}
                    value={count}
                    type="number"
                    onChange={this.handleChange}
                    className="w-25 m-0 mx-1 py-2 px-3 bg-white text-dark" />
                <button type="button"
                    className="btn px-3 py-2 btn-info"
                    onClick={() => this.handleClick('inc')}
                >
                    +
                </button>

            </div>
        )
    }
}
export default CustomCounter;