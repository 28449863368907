import { create } from 'apisauce';
import authStore from '../stores/auth-store';

// define the api


let processorAPI ;

const func = {};

func.setApi = () => {
	if(window.ENV){
		processorAPI = create({
			baseURL: window.ENV?.processorInfo.apiUrl
		});
	}
}

func.terminalAuthCapture = (body) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	processorAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return processorAPI.post(`/terminalCapture`, body);
}

func.cardConnetcAuthTransaction = (body) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	processorAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return processorAPI.post(`/auth`, body);
}

func.cardConnetcCaptureTransaction = (body) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	processorAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return processorAPI.post(`/capture`, body);
}

func.cardConnetcGetTransaction = (transactionId) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	processorAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return processorAPI.get(`/inquire/${transactionId}`);
}

func.cardConnetVoidTransaction = (payload) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	processorAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return processorAPI.post(`/void`, payload);
}

func.cardConnetRefundTransaction = (payload) => {
	// console.log('in authorize and capture');
	// var token = authStore.getAuthToken();
	// if (token == '') {
	// 	token = localStorage.getItem("Auth-token");
	// }
	// console.log(token);
	processorAPI.setHeaders({
		"Content-Type": 'application/json'
	})
	return processorAPI.post(`/refund`, payload);
}



export default func;
