import { create } from 'apisauce';
import authStore from '../stores/auth-store';

// define the api
import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const reservationApi = create({
	baseURL: urlgetter.getReservationApiUrl()
});

const func = {};

func.getCoupons = () => {
	return api.get(`/getCoupons`);
}

func.loadPrices = (startdate, endDate) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/loadPrices');
}
func.resendGiftcardEmail = (payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/reports/resendGiftcardEmails', payload);
}
func.getGiftPurchaseByGiftcardId = (id) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getGiftPurchase' + "?giftcardId=" + id);
}
func.refundGiftcards = (payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/reports/refundGiftcards', payload);
}

func.refundPaymentNGiftCard = (transactionId, body) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post(`transnational/proxy/transaction/${transactionId}/refundGiftCard`, body);
}

func.updatePriceSchedule = (id, priceBody) => {
	// console.log('in UpdatePlanes');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/admin/priceSchedule/' + id, priceBody);
}

func.updatePaySchedule = (id, priceBody) => {
	// console.log('in UpdatePlanes');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/admin/paySchedule/' + id, priceBody);
}


func.GetCountersaleDetails = (startdate, endDate, subType) => {
	// console.log('Get Invoice category');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getCountersaleDetails?start=' + startdate + '&end=' + endDate + "&subType=" + subType);
}

func.GetCollectionDetails = (startdate, endDate, subType) => {
	// console.log('Get Invoice category');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getCollectionDetails?start=' + startdate + '&end=' + endDate + "&subType=" + subType);
}

func.GetJumperPayouts = (startdate, endDate, subType) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getPayoutDetails?start=' + startdate + '&end=' + endDate + "&subType=" + subType);
}

func.GetTandemPayouts = (startdate, endDate, subType) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getTandemPayoutDetails?start=' + startdate + '&end=' + endDate + "&subType=" + subType);
}


func.LoadTodayTandemReservationSummary = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/loadTodayTandemReservationSummary');
}

func.GetTandemDetails = (startdate, endDate, subType) => {
	// console.log('Get Invoice category');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getTandemDetails?start=' + startdate + '&end=' + endDate + "&subType=" + subType);
}

func.GetAffDetails = (startdate, endDate) => {
	// console.log('Get Invoice category');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getAffDetails?start=' + startdate + '&end=' + endDate);
}

func.GetCoachJumpDetails = (startdate, endDate, subType) => {
	// console.log('Get Invoice category');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getCoachDetails?start=' + startdate + '&end=' + endDate + "&subType=" + subType);
}

func.GetFunJumpDetails = (startdate, endDate, subType) => {
	console.log('Get Fun Jump details');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getFunJumpDetails?start=' + startdate + '&end=' + endDate + "&subType=" + subType);
}

func.GetInvoiceByCategory = (startdate, endDate) => {
	// console.log('Get Invoice category');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getInvoiceByCategory?start=' + startdate + '&end=' + endDate);
}

func.GetInvoiceByCategoryPDF = (startdate, endDate) => {
	// console.log('Get Invoice category');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getInvoiceByCategoryPDF?start=' + startdate + '&end=' + endDate, {}, {
		responseType: 'blob', headers: {
			'Accept': 'application/pdf'
		}
	});
}


func.GetSlots = (startdate, endDate) => {
	// console.log('Get Slots');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getSlots?start=' + startdate + '&end=' + endDate);
}

func.GetDateOfLastZeroPostedBalance = (id) => {

	var token = authStore.getAuthToken();

	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)

	return api.get('/reports/getDateOfLastZeroPostedBalance/' + id);
}

func.GetTandemLogs = (id) => {
	// console.log('Get Jumper Logs');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getTandemLogs?jumper=' + id);
}
func.GetJumperLastFewLogs = (numLogs, id) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getLocalJumperLogs?numLogs=' + numLogs + '&jumper=' + id);

}
func.GetJumperLogs = (startdate, endDate, id) => {
	// console.log('Get Jumper Logs');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getLocalJumperLogs?start=' + startdate + '&end=' + endDate + '&jumper=' + id);
}

func.GetJumperLogsPDF = (startdate, endDate, id) => {
	// console.log('Get Jumper Logs');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getLocalJumperLogsPDF?start=' + startdate + '&end=' + endDate + '&jumper=' + id, {}, {
		responseType: 'blob', headers: {
			'Accept': 'application/pdf'
		}
	});
}

func.GetTodayJumperLogs = (endDate, id) => {
	// console.log('Get Jumper Logs');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getLocalJumperLogs?end=' + endDate + '&jumper=' + id);
}

func.GetPlaneReport = (startdate, endDate) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getPlaneReport?start=' + startdate + '&end=' + endDate);
}

func.GetJumperState = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getJumperState');
}

func.GetTandemState = () => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getTandemState');
}

func.GetStaffSummary = (startdate, endDate) => {
	// console.log('Get Staff Summary');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getStaffSummary?start=' + startdate + '&end=' + endDate);
}

func.GetStaffSummaryCSV = (startdate, endDate) => {
	// console.log('Get Staff Summary');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getStaffCSV?start=' + startdate + '&end=' + endDate);
}

func.GetPlaneReportCSV = (startdate, endDate) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getSlotCSV?start=' + startdate + '&end=' + endDate);
}

func.getDropzoneTodayActivity = () => {
	// console.log('Get dropzone today activity');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/reports/getDropzoneTodayActivity');
}

export default func;
