import React, { Component } from 'react';
import moment from 'moment';
import Joi from 'joi-browser';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates'
import schedulesStore from '../../../stores/schedule-store';
import swal from 'sweetalert';

class CreateEvent extends Component {
  constructor(props) {
    super(props);

    this.state = schedulesStore.fetchCreateEventDefaults();
    this.weeks = ["sun", "mon", "tues", "wed", "thur", "fri", "sat"];
    this.reccurences = ["Once", "Weekly", "Bi-weekly", "Monthly"];
    // list all events that needs to be listend to
    this.events = [
      { name: schedulesStore.getEventPrefix('createEvent'), func: this.handleCreateEvent },
      { name: 'ERROR', func: this.handleError }
    ];
  }
  
  componentDidMount = () => {
    schedulesStore.getEvents()
      .then(res => {
        this.setState({ eventsList: res.data})
      })
  }
  componentWillMount = () => this.events.forEach((evnt) => schedulesStore.on(evnt.name, evnt.func))

  componentWillUnmount = () => this.events.forEach((evnt) => { schedulesStore.removeListener(evnt.name, evnt.func) })

  handleCreateEvent = (r) => {
    if (r.length === 0) {
      this.setState({ error: 'No Events were created, Please check input data.' })
      return;
    }

    this.setState({
      ...schedulesStore.fetchCreateEventDefaults()
    });
    this.props.handleCancle();
  }

  handleError = (e) => {
    if (!e.alert) {
      this.setState({ error: e.message });
    }
  }

  handleCancle = () => {
    this.props.handleCancle();
  }

  handleDaysSelect = (e) => {
    const item = e.target.name;
    let selected = [...this.state.days];
    let index = selected.indexOf(item);
    if (index == -1) {
      selected.push(item)
    }
    else {
      selected.splice(index, 1);
    }
    this.setState({ days: selected });
  }

  allowNumberOnly = (d, i) => {
    let newRows = [...this.state.row]
    newRows[i]['seats'] = d.target.value
    if (!isNaN(d.target.value)) {
      this.setState({ row: newRows })
    }
  }
 
  createEvent = (e) => {
    let count = 0
    let data = []
    this.setState({ error: false });
    this.state.row.map(r => {
      let payload = {
        eventName: this.state.eventType,
        eventTime: r.startTime,
        eventStart: this.state.startDate,
        eventEnd: this.state.endDate,
        days: this.state.days,
        recurrence: this.state.reccurence,
        numSeats: parseInt(r.seats),
        eventCategory: this.state.eventCategory
      }

      if(r.startTime == '00:00'){
        swal(
          {
              title: "You Can't Book Reserversation At Midnight",
              text: "Please Enter Valid Day Time",
              icon: "error",
          }
          )
          return;
          }
      console.log(r.startTime,"r.startTime")
      console.log(payload,"payload");
      this.setState({ error: false })
      if (this.validate(payload)) {
        count = count + 1
        data.push(payload)

        if (count === this.state.row.length) {
          schedulesStore.createEvent(data);
        }
        console.log(count,"count")
      }
    })

  }
  validate = (d) => {
    var payload = { ...d };
    var schema = {
      eventName: Joi.string().required().error(new Error('Invalid or empty Event name')),
      eventCategory: Joi.string().required().error(new Error('Invalid or empty Event category')),
      eventTime: Joi.string().required().error(new Error('Invalid or empty Start time')),
      eventStart: Joi.object().required().error(new Error('Invalid or empty Start date')),
      eventEnd: Joi.object().required().error(new Error('Invalid or empty End date')),
      days: Joi.array().min(1).required().error(new Error('Invalid or empty days')),
      recurrence: Joi.string().required().error(new Error('Invalid or empty Recurrence')),
      numSeats: Joi.number().min(1).required().error(new Error('Invalid or empty Number of seats')),
    }
    const { error } = Joi.validate(payload, schema);

    if (error) {
      schedulesStore.emitError({ message: error.message });
      return false;
    } else {
      return true;
    }
  }

  handleEventChange = (event) => {
    if (event) {
      let obj = { eventType: event.target.value }
      if (event.target.value === 'AFF') {
        obj.eventCategory = 'AFF'
      }
      else if (event.target.value === 'Tandem') {
        obj.eventCategory = 'Tandem'
      }
      else {
        const cat = this.state.eventsList.find(evt => evt.eventName === event.target.value)
        obj.eventCategory = cat?.packageCategory
      }
      this.setState(obj)
    }
  }

  addRow = (i, e) => {
    if (i >= 7) {
      e.preventDefault()
    } else {
      let r = [...this.state.row, { startTime: '', seats: 0 }]
      this.setState({
        row: r
      })
    }
  }

  deleteRow = (ind, e) => {
    let r = [...this.state.row]
    const fR = r.filter((r, i) => i !== ind)
    this.setState({
      row: fR
    })

  }

  handleTimeChange = (event, index) => {
    console.log(event,"event",index,"index");
    let newRows = [...this.state.row]
    console.log(newRows,"consoleofnewrows");
    newRows[index]['startTime'] = event.target.value
    console.log(event.target.value,"eventtargetvalue");

    this.setState({ row: newRows })
  }

  // handleSwal=()=>{
  //   swal({
  //     title: "Validation Error",
  //     text: "show me time",
  //     icon: "error",
  //   })
  // }

  render() {

    return (
      <div className="modalBodyContainer styled-scroll" style={{ maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
        <div className="row">
          {this.state.error && <div style={{ color: 'red' }} className="alert alert-danger col-12">{this.state.error}</div>}
          <div className="col-3">Name :</div>
          <div className="col">
            <select
              onChange={(d) => this.handleEventChange(d)}
              value={this.state.eventType}
              name="tandemSelect"
              className="modalSelect">
              <option value="">Please Select</option>
              {/* <option value="Tandem">Tandem Jump</option> */}
              {/* <option value="AFF">{window.ENV?.isSTP ? "STP First Jump" : "AFF First Jump Course"}</option> */}
              {
                (this.state?.eventsList?.map((evt, i) => {
                  return (
                    <option key={i + 1} value={evt.eventName}>{evt.eventName}</option>
                  )
                }))
              }
              {/* <option value="AFF">Pilot Course</option>
                    <option value="AFF">Coach Course</option> */}
            </select>
          </div>
        </div>
        <div className={"hide-me"} >
          {
            this.state.row.map((el, i) => {
              return (
                
                <div style={{ alignItems: 'center' }}
                  className="row"
                >
                  {console.log(el,"el")}
                  <div className="col-2">Time :</div>
                  <div className="col-4">
                    <input
                      type="time"
                      value={el.startTime}
                      onChange={(d) => this.handleTimeChange(d, i)}
                      className="modalForm"
                      placeholder="07:30 AM"
                    />
                  </div>

                  <div className="col-2">Seats :</div>
                  <div className="col-2">
                    <input type="number"
                      value={el.seats}
                      onChange={(d) => this.allowNumberOnly(d, i)}
                      className="modalForm"
                      placeholder="0"
                    />
                  </div>
                  {this.state.row.length > 1 && <div style={{ cursor: 'pointer' }} className="col-1">
                    <i onClick={(e) => this.deleteRow(i, e)} className="fa fa-minus-circle"></i>
                  </div>}

                  {((this.state.row.length - 1 === i) && this.state.row.length < 8) ? <div style={{ cursor: 'pointer' }} className="col-1">
                    <i onClick={(e) => this.addRow(i, e)} className="fa fa-plus-circle"></i>
                  </div> : null}
                </div>
              )
            })
          }
        </div>
        <div className="row">
          <div className="col-12" style={{ paddingTop: '14px' }}>Start & End Dates</div>
          <div className="col-8">
            <DateRangePicker
              startDate={this.state.startDate}
              startDateId="your_unique_start_date_id"
              endDate={this.state.endDate}
              endDateId="your_unique_end_date_id"
              onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              daySize={34}
            />
          </div>
        </div>
        <div className="row smaller">
          <div className="col-12" style={{ fontSize: 18 }}>Reccurence:</div>
          {
            this.reccurences.map((i) =>
              <div className="col" key={i}>
                <input
                  onChange={(d) => this.setState({ reccurence: i })}
                  name="reccurence"
                  type="radio"
                  checked={this.state.reccurence === i}
                /> {i}
              </div>
            )
          }
        </div>
        <div className="row smaller">
          <div className="col-12" style={{ fontSize: 18 }}>Days:</div>
          {
            this.weeks.map(item =>
              <div key={item} className="col">
                <input
                  className="checkbox"
                  type="checkbox"
                  name={item}
                  checked={this.state.days.indexOf(item) != -1}
                  onChange={this.handleDaysSelect}
                /> {item}
              </div>)
          }
        </div>
        <div className="row">
          <div className="col-12" style={{ marginTop: '40px' }}>
            <button type='button' onClick={(e)=>this.createEvent(e)} className="btn btn-bg lightColor createButton" >CREATE</button>
            <button onClick={this.handleCancle} className="btn btn-link">CANCEL</button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEvent;
