import React, { Component } from 'react'
import './iframe.css';

export default class IFrame extends Component {
    render() {
        let { isShowIframe, hideIframe, openTo } = this.props;

        const url = openTo === "RESERVATION" ? window.ENV?.reservationLink : window.ENV?.giftShopLink;

        return (
            isShowIframe ?
                <div className="iframe-container">
                    <button className="btn btn-warning" onClick={hideIframe}>Cancel</button>
                    <iframe src={url} />
                </div>
                : null
        )
    }
}
