import React, { Component } from 'react';
import PassengerDetail from './passengerDetail';
import tandemStore from '../../../stores/reservation-store';

class PassengerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formsData: []
        };
    }

    renderPassenger = () => {
        var passengerFormList = [];
        for (let i = 0; i < this.props.passengerCount; i++) {

            passengerFormList.push(
                <PassengerDetail
                    handleVideoUpdate={(val) => this.props.handleVideoUpdate(val)}
                    index={i + 1}
                    key={i}
                    chkFormVal={this.props.chkFormVal}
                />
            );
        }
        return passengerFormList;
    };

    render() {
        return (
            <div className="col-md-12 containerPadding">
                {this.props.passengerCount > 0 && <span className="customHeading">TICKET INFORMATION</span>}
                <div className="row ticketContainer">{this.renderPassenger().map((item) => item)}</div>
            </div>
        );
    }
}

export default PassengerForm;
