/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import { Col, Row, Label } from 'reactstrap';
import { Control } from 'react-redux-form';
import loadsStore from '../../../stores/loads-store';
import loadStore from '../../../stores/loads-store';

import JmpModal from './customModal';
import { ClipLoader } from 'react-spinners';
import { Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class CreateLoad extends Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isModalOpen: false,
      isloading: false,
      ErrorMessage: '',
      isValid: true,
      defaultCapacity: null,
      plane: '',
      isCreateLoad: false
    }
    this.handleNewActivity = this.handleNewActivity.bind(this);
    var that = this;
    this.loadEvents = [
      { name: loadsStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
    ];

    // this.toggle = this.toggle.bind(this);
    // this.onMouseEnter = this.onMouseEnter.bind(this);
    // this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  toggleModal(e) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      jumpType: null
    });
  }


  handleSubmit(plane) {
    loadsStore.displayLoader = true;
    var pilotOptions = this.props.pilotOptions;
    var payload = {};
    var planes = loadsStore.getPlanes();
    var planeDisplaySetting = loadsStore.getPlaneDisplaySetting();

    if (planeDisplaySetting.value === "plane type") {
      payload.plane = planes[0].plane;
      payload.capacity = planes.filter(plane => plane.plane === planes[0].plane)[0].capacity;
      payload.registrationNumber = planes[0].tail;
      payload.color = planes[0].color;

    }
    else if (planeDisplaySetting.value === "plane name") {
      payload.plane = planes[0].name;
      payload.capacity = planes.filter(plane => plane.name === planes[0].name)[0].capacity;
      payload.registrationNumber = planes[0].tail;
      payload.color = planes[0].color;


    }


    if (pilotOptions[pilotOptions.length - 1] !== undefined) {
      payload.pilotId = pilotOptions[pilotOptions.length - 1].value._id;
    }

    loadsStore.createLoad(payload);
  }

  handleSubmitWithplane(plane) {
    alert(JSON.stringify(plane));
    console.log(plane,"plane000")
    this.setState({
      isCreateLoad: false
    })
    loadsStore.displayLoader = true;
    var pilotOptions = this.props.pilotOptions;
    var payload = {};
    var planes = loadsStore.getPlanes();
    var planeDisplaySetting = loadsStore.getPlaneDisplaySetting();

    if (planeDisplaySetting.value === "plane type") {
      payload.plane = plane.plane;
      payload.color = plane.color;

      payload.capacity = planes.filter(pl => pl.plane === plane.plane)[0].capacity;
      payload.registrationNumber = plane.tail;
    }
    else if (planeDisplaySetting.value === "plane name") {
      payload.plane = plane.name;
      payload.color = plane.color;
      payload.capacity = planes.filter(pl => pl.name === plane.name)[0].capacity;
      payload.registrationNumber = plane.tail;
    }

    console.log(payload);
    alert(JSON.stringify(payload));
    if (pilotOptions && pilotOptions[pilotOptions.length - 1] !== undefined) {
      payload.pilotId = pilotOptions[pilotOptions.length - 1].value._id;
    }

    loadsStore.createLoad(payload);
  }

  componentWillMount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
    // this.handlePlanesLoaded();

  }
  handlePlanesLoaded = () => {
    if (this.state.planeOptions == null) {
      var option = loadsStore.getPlanes();
      console.log("PlaneOptions", option)
      this.setState({
        planeOption: option,
      });
    }

  }

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
  }

  handleNewActivity = (activityString) => {
    if (activityString) {
      this.setState({
        isloading: false,
        isModalOpen: false,
        ErrorMessage: '',
        isValid: true
      })
    }
  }


  // toggle() {
  //   this.setState(prevState => ({
  //     isCreateLoad: !prevState.isCreateLoad
  //   }));
  // }

  // onMouseEnter() {
  //   console.log(this.props.planeOption)
  //   this.setState({isCreateLoad:true});
  //   //this.handlePlanesLoaded();
  // }

  // onMouseLeave() {
  //   this.setState({isCreateLoad:false})
  // }


  updatePlane = (value) => {
    var plane = value.label;
    var capacity = loadsStore.getPlaneCapacity(plane);
    this.setState({ defaultCapacity: capacity, plane: plane });
  }

  CapacityForm = (props) => {
    return (
      <React.Fragment>
        <Row className="form-group">
          <Label htmlFor="intructor" md={3}>Jumper</Label>
          <Col md={9}>
            <Control.custom
              model=".jumpers"
              name="jumpers"
              className="form-control"
              component={props.component}
              loadOptions={props.loadOptions}
              defaultOptions={props.defaultOptions}
              mapProps={props.mapProps}
              onInputChange={props.onInputChange}
              components={{ Option: this.Option, SingleValue: this.SingleValueLabel }}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }


  render() {
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;


    return (
      <React.Fragment>
        <JmpModal
          isModalOpen={this.state.isModalOpen}
          title={`Create Load`}
        >
          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>
        </JmpModal>
        <button
          className="btn customRightButton rightButton"
          style={{ borderRadius: '100px', padding: '12px !important' }}
          onClick={this.handleSubmit}
        >
          <i className="fa fa-plus"></i> CREATE NEW LOAD
        </button>
      </React.Fragment>
    );
  }
}

export default CreateLoad
