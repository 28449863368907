/**
 * http://usejsdoc.org/
 */

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Control, LocalForm } from 'react-redux-form';
import Select from 'react-select';
import loadsStore from '../../../stores/loads-store';
import jumpersStore from '../../../stores/jumpers-store';
import ReactDOM from 'react-dom';
import LoadUpdateModal from './loadModal'

class EditableRequest extends Component {

  constructor(props) {


    super(props);
    this.handelConfirmSubmit = this.handelConfirmSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      warningPopUp: false
    };

    this.timerId = null;
    this.inputValue = '';
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    if (this.refInterval) {
      clearInterval(this.refInterval);
    }
    document.removeEventListener("click", this.handleClickOutside, true);
  }


  handleClickOutside = event => {
    if (this.valRef && !this.valRef.contains(event.target)) {
      this.setState({
        editMode: false
      });
    }
  }

  handleChange = (selectedOption, loadNum, jumpId, type, label) => {
    this.setState({ editMode: !this.state.editMode });
    var today = new Date();
    var isWeekend = (today.getDay() % 6 === 0);

    if (selectedOption.value !== undefined) {
      if (type === "tandem") {
        var tandemJump = this.props.tandemJump;

        if (this.state.isCompleted) {
          if (label === "I") {
            var jumpPostBody = {
              instructorId: selectedOption.value._id,
              studentId: this.props.tandemJump.student._id,
              videographerId: this.props.tandemJump.videographer._id,
              price: this.props.tandemJump.price,
              videoPrice: this.props.tandemJump.videoPrice
            }
          }
          else if (label === "V") {
            var jumpPostBody = {
              videographerId: selectedOption.value._id,
              instructorId: this.props.tandemJump.instructor._id,
              studentId: this.props.tandemJump.student._id,
              price: this.props.tandemJump.price,
              videoPrice: this.props.tandemJump.videoPrice
            }
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }
          var jumpId = this.props.tandemJump.jumpId;
          var loadId = this.props.loadId;
          jumpPostBody.isUltra = this.props.isUltra;
          jumpPostBody.isWeekend = isWeekend;
          jumpPostBody.isPriceUpdate = false;
          jumpPostBody.videoPackageName = this.props.tandemJump.videoPackageName;
          jumpPostBody.tandemPackageName = this.props.tandemJump.tandemPackageName;
          alert(jumpPostBody);
          this.props.updateCompleteTandemJump(loadId, jumpId, jumpPostBody);
        }
        else {
          if (label === "I") {
            var jumpPostBody = { instructorId: selectedOption.value._id }
          }
          else if (label === "V") {
            var jumpPostBody = { videographerId: selectedOption.value._id }
          }
          loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
        }
      }
      else if (type === "tandemUltra") {
        var tandemJump = this.props.tandemJump;
        if (this.state.isCompleted) {
          if (label === "I") {
            var jumpPostBody = {
              instructorId: selectedOption.value._id,
              studentId: this.props.tandemJump.student._id,
              videographerId: this.props.tandemJump.videographer._id,
              price: this.props.tandemJump.price,
              videoPrice: this.props.tandemJump.videoPrice
            }
          }
          else if (label === "V") {
            var jumpPostBody = {
              videographerId: selectedOption.value._id,
              instructorId: this.props.tandemJump.instructor._id,
              studentId: this.props.tandemJump.student._id,
              price: this.props.tandemJump.price,
              videoPrice: this.props.tandemJump.videoPrice
            }
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }

          jumpPostBody.isPriceUpdate = false;

          var jumpId = this.props.tandemJump.jumpId;

          var loadId = this.props.loadId;

          jumpPostBody.isUltra = this.props.isUltra;
          jumpPostBody.isWeekend = isWeekend;
          loadsStore.updateCompleteTandemJump(loadId, jumpId, jumpPostBody);
        }
        else {
          if (label === "I") {
            var jumpPostBody = { instructorId: selectedOption.value._id }
          }
          else if (label === "V") {
            var jumpPostBody = { videographerId: selectedOption.value._id }
          }
          loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
        }
      }
      else if (type === "aff") {
        if (this.state.isCompleted) {
          if (label === "I1") {
            var jumpPostBody = {
              mainInstructorId: selectedOption.value._id,
              secondaryInstructorId: this.props.affJump.secondaryInstructor._id,
              videographerId: this.props.affJump.videographer._id,
              studentId: this.props.affJump.student._id,
              price: this.props.affJump.price
            }
            if (this.props.affJump.secondaryInstructor) {
              jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
            }
          }
          else if (label === "I2") {
            var jumpPostBody = {
              secondaryInstructorId: selectedOption.value._id,
              mainInstructorId: this.props.affJump.mainInstructor._id,
              videographerId: this.props.affJump.videographer._id,
              studentId: this.props.affJump.student._id,
              price: this.props.affJump.price
            }
          }
          else if (label === "V") {
            var jumpPostBody = {
              videographerId: selectedOption.value._id,
              secondaryInstructorId: this.props.affJump.secondaryInstructor._id,
              mainInstructorId: this.props.affJump.mainInstructor._id,
              studentId: this.props.affJump.student._id,
              price: this.props.affJump.price
            }
            if (this.props.affJump.secondaryInstructor) {
              jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
            }
          }
          else if (label === "S") {
            var jumpPostBody = {
              studentId: selectedOption.value._id,
              videographerId: this.props.affJump.videographer._id,
              secondaryInstructorId: selectedOption.value._id,
              mainInstructorId: this.props.affJump.mainInstructor._id,
              price: this.props.affJump.price
            }
            if (this.props.affJump.secondaryInstructor) {
              jumpPostBody.secondaryInstructorId = this.props.affJump.secondaryInstructor._id
            }
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }
          jumpPostBody.level = this.state.level;
          jumpPostBody.isWeekend = isWeekend;
          var cjumpId = this.props.affJump.jumpId;
          var loadId = this.props.loadId;
          this.props.updateCompletedAffJump(loadId, cjumpId, jumpPostBody);
        }
        else {
          if (label === "I1") {
            var jumpPostBody = { mainInstructorId: selectedOption.value._id }
          }
          else if (label === "I2") {
            var jumpPostBody = { secondaryInstructorId: selectedOption.value._id }
          }
          else if (label === "V") {
            var jumpPostBody = { videographerId: selectedOption.value._id }
          }
          else if (label === "S") {
            var jumpPostBody = { studentId: selectedOption.value._id }
          }
          loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
        }
      }
      else if (type === "coach") {

        if (this.state.isCompleted) {
          if (label === "C") {
            var jumpPostBody = { coachId: selectedOption.value._id };
            if (this.props.isToday) {
              jumpPostBody.isToday = true
            }
            jumpPostBody.PriceId = this.props.coachJump.Price._id;
            jumpPostBody.price = this.props.coachJump.price;
            jumpPostBody.isWingsuit = this.props.isWingsuit;
            var cjumpId = this.props.coachJump.jumpId;
            var loadId = this.props.loadId;

            this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);
          }
          else if (label === "J") {
            var jumpPostBody = { PriceId: selectedOption.value._id };
            if (this.props.isToday) {
              jumpPostBody.isToday = true
            }
            jumpPostBody.coach = this.props.coach._id;
            jumpPostBody.price = this.props.Price.price;
            var cjumpId = this.props.coachJump.jumpId;
            var loadId = this.props.loadId;
            this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);
          }
        }
        else {
          if (label === "C") {
            var jumpPostBody = { coachId: selectedOption.value._id };
            loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
          }
          else if (label === "J") {
            var jumpPostBody = { PriceId: selectedOption.value._id };
            loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
          }
        }

      }
      else if (type === "pilot") {
        if (this.state.isCompleted) {
          var jumpPostBody = {
            pilotId: selectedOption.value._id,
            pilotPay: this.props.price
          }
          if (this.props.isToday) {
            jumpPostBody.isToday = true
          }

          jumpPostBody.isPriceUpdate = false;

          var loadId = this.props.loadId;
          loadsStore.updateCompletedPilot(loadId, jumpPostBody);
        }
        else {
          var body = { pilotId: selectedOption.value._id }
          loadsStore.UpdateLoad(loadNum, body);
        }
      }
    }
  }

  cancelOrSubmit = () => {
    var ID = this.state.PriceId;
    var loadNum = this.state.loadNum;
    var jumpId = this.state.jumpId;
    var type = this.state.type;
    var label = this.state.label;

    var jumpPostBody = {};

    if (type === "tandem") {

      if (label === "I") {
        var jumpPostBody = { instructorId: ID }
      }
      else if (label === "V") {
        var jumpPostBody = { videographerId: ID }
      }
      loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody);
    }
    else if (type === "aff") {
      if (label === "I1") {
        var jumpPostBody = { mainInstructorId: ID }
      }
      else if (label === "I2") {
        var jumpPostBody = { secondaryInstructorId: ID }
      }
      else if (label === "V") {
        var jumpPostBody = { videographerId: ID }
      }
      loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
    }
    else if (type === "coach") {
      if (label === "C") {
        var jumpPostBody = { coachId: ID };
        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
      }
      else if (label === "J") {
        var jumpPostBody = { PriceId: ID };
        loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
      }
    }
  }

  checkForNegatives = (price) => {
    if (price != undefined) {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <span className="editablePrice" style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
      }
      return <span className="editablePrice" style={{ color: '#00FF00' }}> {priceCell.value}</span>;;
    }
    else {
      return '$0.00';
    }
  }

  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    }, () => {
      if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
        this.setState({ menuPlacement: "top" });
      }
      else {
        this.setState({ menuPlacement: "bottom" });
      }
    }
    )
  }

  changeRequest = () => {
    if (!this.state.editMode) {
      this.setState({
        editMode: true
      })
    }

  }

  handelConfirmSubmit() {
    this.setState({
      warningPopUp: !this.state.warningPopUp
    })
  }

  toggleModal() {
    this.setState({
      warningPopUp: !this.state.warningPopUp
    })
  }

  onBlur = () => {
    localStorage.setItem("disabledArrowButton", false)
    if (this.state.editMode && !this.props.isCompleted) {
      this.setState({
        editMode: false
      })
    }
  }


  handleInput(e, val) {
    alert(e);
  }

  handleSubmit(values, type, jumperId) {
    console.log(values);
    this.setState({ editMode: !this.state.editMode });
    var payload = {};
    if (type === "callrequest") {
      payload.callRequest = values.request;
    }
    else if (type === "jumprequest") {
      payload.jumpRequest = values.request;
    }
    else if (type === "slotprice") {
      payload.todaySlotPrice = values.request;
    }
    jumpersStore.updateJumpRequest(jumperId, payload)
      .then(ret => {
        this.onBlur();
      })
  }

  setRef = ref => {
    this.valRef = ref;

    if (this.refInterval) {
      clearInterval(this.refInterval);
    }

    this.refInterval = setInterval(() => {
      if (ref) {
        ref.focus();
      } else {
        clearInterval(this.refInterval);
      }
    })
  }

  returnKeyPress = (evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '+') && evt.preventDefault()

  render() {
    //alert(JSON.stringify(this.props.Price,2));
    return (
      <div ref={ref => this.valRef = ref}>
        {
          !this.state.editMode ?
            // <button className="btn lightBlueButton Price-name" onClick={(e) => { e.preventDefault(); this.changePrice(this.props.isCompleted); }} type="submit" disabled={this.props.disabled}>
            <div
              onClick={(e) => { e.preventDefault(); this.changeRequest(); }}
              disabled={this.props.disabled}>
              {this.props.value}
            </div>

            //</button>
            :
            // <button className="btn lightBlueButton Price-name" disabled={this.props.disabled}>
            <LocalForm form="editRequest" model="editRequest" getDispatch={dispatch => this.formDispatch = dispatch} onSubmit={(values) => this.handleSubmit(values, this.props.type, this.props.jumperId)}>
              <Control.input name="request" step="any" model=".request" className="form-control" style={{ width: '80px' }}
                getRef={this.setRef}
                mapProps={{ onChange: (props) => props.onChange }} defaultValue={this.props.price}
                autoComplete="off" onBlur={this.onBlur} />
            </LocalForm>
        }
        <LoadUpdateModal
          isOpen={this.state.isModalOpen}
          onClick={this.wantsEidtable}
        />
      </div>
    );
  }
}

const mstp = state => {
  return {
    state
  }
}
const mdtp = dispatch => {
  return {
    updateCompletedAffJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedAffJump(loadId, cjumpId, jumpPostBody)),
    updateCompleteTandemJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompleteTandemJump(loadId, cjumpId, jumpPostBody)),
    updateCompletedCoachJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)),
    addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
  }
}

export default connect(mstp, mdtp)(EditableRequest)
