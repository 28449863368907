import { useState } from "react";
import { handleInputChange, saveCondition, updateCondition } from "./utils";
import Emitter from "../../Common/emitterServices";


const AddConditions = () => {

    const [newCondition, setNewCondition] = useState("");
    const [emptyTextField, setEmptyTextField] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [conditionId, setConditionId] = useState("")

    Emitter.on("update_condition", (condition) => {
        setEmptyTextField(false)
        setNewCondition(condition.description)
        setIsUpdate(true)
        setConditionId(condition._id)
        Emitter.off("update_condition")
    })

    return (
        <div className={"add_terms_conditions_main"}>
            <div className={'add_terms_conditions_secondary'}>
                <div className="text_area_div">
                    <input value={newCondition} placeholder="enter terms & conditions here.."
                        onChange={(e) => handleInputChange(e, setNewCondition, setEmptyTextField)}
                        className={emptyTextField && 'error'} type="text"
                        ref={input => (input && emptyTextField) && input.focus()}
                    />
                </div>
                <div className="add_update_button_div">
                    <button onClick={() =>
                        !isUpdate ?
                            saveCondition(newCondition, setNewCondition, setEmptyTextField)
                            :
                            updateCondition(newCondition, conditionId, setNewCondition,
                                setEmptyTextField, setIsUpdate)
                    }
                    >
                        {!isUpdate ? "Submit" : "Update"}
                    </button>
                </div>
            </div>
        </div>
    )
}


export default AddConditions;