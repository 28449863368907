

import { create } from 'apisauce';
import authStore from '../stores/auth-store';
import reservationStore from '../stores/reservation-store';
// define the api

let api;

const func = {};

func.setApi = () => {
	if(window.ENV?.processorInfo){
		api = create({
			baseURL: window.ENV?.waiverApi
		});
	}
}

func.getWaiver = (waiverId, withPdf) => {
	// console.log('in loadAllJumpers');
	console.log('in service get Waiver');
	console.log(window.ENV?.smartWaiverKey);

	api.setHeader('sw-api-key', window.ENV?.smartWaiverKey);
	var endpoint = '/waivers/' + waiverId + "?pdf=false";
	if (withPdf) {
		endpoint = '/waivers/' + waiverId + "?pdf=true";
	}
	return api.get(endpoint);
}

export default func;