import React, { Component } from 'react'
import { Table } from 'reactstrap';
import QRCode from 'qrcode.react';
import CreateItem from '../modals/createItem';
import RemoveItem from '../modals/removeItem';
import CreateCategory from '../modals/createCatagory';
import DataTable from 'react-data-table-component';
import categoryStore from '../../../stores/category-store';
import { ToastContainer, toast } from 'react-toastify';

const customStyles = {
    headCells: {
        style: {
            fontWeight: '600',
            fontSize: "1rem",
            letterSpacing: "1px",
            textAlign: 'center'
        },
    },
    rows: {
        style: {
            padding: '10px 0'
        },
    },
};

export default class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],
        }
    }

    componentDidMount = () => {
        categoryStore.getCategories()
            .then(res => {
                this.setState({ categoryList: res })
            })
    }

    catgoryColumns = () => {
        return [
            {
                name: '',
                center: true,
                selector: 'serial',
                width: '60px'
            },
            {
                name: 'Name',
                selector: 'category',
                sortable: true,
            },
            {
                name: 'Edit',
                center: true,
                cell: (row) => <CreateCategory isEdit={true} data={row} getlist={this.getUpdatedList} toast={toast} />
            },
            {
                name: 'Delete',
                selector: 'delete',
                center: true,
                cell: (row) => <RemoveItem item={row} category={true} getlist={this.getUpdatedList} toast={toast} />
            },
        ]
    }

    getUpdatedList = () => {
        categoryStore.getCategories()
            .then(res => {
                this.setState({ categoryList: res })
            })
    }


    render() {
        this.state.categoryList.forEach((item, index) => { item.serial = index + 1; })
        return (
            <>
                <div className='categoryTable'>
                    <CreateCategory getlist={this.getUpdatedList} toast={toast} />
                    <DataTable
                        title={'Category'}
                        center={true}
                        striped={true}
                        responsive={true}
                        pagination={true}
                        paginationPerPage={25}
                        customStyles={customStyles}
                        data={this.state.categoryList}
                        columns={this.catgoryColumns()}
                    />
                </div>
                <ToastContainer autoClose={3000} hideProgressBar={true} />
            </>
        )
    }
}
