/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import AccountApi from '../../../services/accountapi';


import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';

import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import ItemModal from './customModal';
import reservationStore from '../../../stores/reservation-store';
import accountStore from '../../../stores/account-store';

import Api from '../../../services/reservationapi';
import { ClipLoader } from 'react-spinners';
import ChargeJumper from './chargeJumper';
import terminalAdapter from '../../../stores/terminalAdapter';
import JumperStore from '../../../stores/jumpers-store';
import swal from 'sweetalert';
import loadsStore from '../../../stores/loads-store';

var XMLParser = require('react-xml-parser');


class RefundConfirMation extends Component {

  constructor(props) {
    super(props);
    this.itemarr = {};
    this.state = {
      isModalOpen: false,
      isModal2Open: false,
      Jumper_id: '',
      errors: '',
      amount: this.props.transactionAmount,
      transactionId: this.props.transactionId,
      isloading: false,
      partialRefundAmount: '',
      partialRefundTax: '',
      refundAmount: 0,
      refundTax: 0,
      chargeValue: this.props.chargeValueData?.itemized,
      chargeValue_: {}
    }
  }

  componentDidMount() {
    let { chargeValueData } = this.props;

    let charge_value_data = chargeValueData?.itemized && Object.entries(chargeValueData?.itemized)?.map((item) => {
      return Array.isArray(item[1]) && item[1]?.map((detail, ind) => {
        console.log(item[1][ind].price, '<<<<<<<item[1]>>>>>>')
        console.log(item[1][ind].tax, '<<<<<<<<tax')
        return { ...detail, refundamount: Number((0).toFixed(2)), refundtax: Number((0).toFixed(2)) }
      })
    })
    this.setState({ chargeValue: charge_value_data })
  }

  toggleModal = (e) => {
    //alert();
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  toggleModal2 = (e) => {
    let itemTax = 0;
    let itemsCount = 0;
    let getItemized;
    let tandemIds = [];
    console.log(this.props.chargeValue);
    this.state.chargeValue && Object.entries(this.state.chargeValue)?.map((item, ind) => {
      getItemized = item[0];
      item[1]?.map((detail) => {
        let amount = detail.refundamount;
        let tax = detail.refundtax;
        console.log(tax, '<<<<<<<<<<<<<<>>>>>>>>>>>')
        itemsCount += amount;
        itemTax += tax;
      })
    })
    if(this.props.chargeValue.itemized){
      tandemIds = Object.keys(this.props.chargeValue.itemized);
      JumperStore.loadTandems(tandemIds);
    }
 

    //alert();
    this.setState({
      isModal2Open: !this.state.isModal2Open,
      refundAmount: itemsCount,
      refundTax: itemTax,
    });
  }

  handleInput = (e) => {
    this.setState({
      amount: e.target.value
    })
  }

  clickPartialRefund = (values) => {
    const { chargeValueData } = this.props;
    let _chargeValueData = {};
    let itemized;
    let _itemized;
    if (isNaN(Number(this.state.refundAmount)) || isNaN(Number(this.state.refundTax))) {
      swal(
        {
          title: "Warning",
          text: "Values are not Numbers",
          icon: "error",
        }
      )
      return;
    }
    let charge_value = chargeValueData?.itemized && Object.keys(chargeValueData?.itemized)?.map((detail, index) => {
      console.log(detail, "===>>>>>hvcdjkdjk")
      return detail;
    })
    let data = this.state.chargeValue && this.state.chargeValue.map((item, ind) => {
      _itemized = item.map((data) => {
        console.log(data, "====>>>>datatattaa")
        return itemized = {
          item: data?.item,
          price: -data?.refundamount,
          qty: data?.qty,
          tax: -data?.refundtax,
          _id: data?._id,

        }
      })
      _chargeValueData[charge_value[ind]] = [..._itemized];
    })
    this.itemarr = _chargeValueData;

    var refundAmount = Number(values?.partialRefund);
    if (!isNaN(Number(values?.partialRefundTax))) {
      refundAmount += Number(values?.partialRefundTax);
    }
    var partialRefundAmount = values?.partialRefund;
    var partialRefundTax = values?.partialRefundTax;
    this.setState({
      isloading: true
    })
    const { chargeValue, jumperId } = this.props;
    if (window.ENV?.terminalEnabled && (chargeValue.transactionType === "Credit" || chargeValue.transactionType === "Debit")) {
      if (window.ENV?.processorInfo.processor === "transnational") {
        //alert(JSON.stringify(this?.props?.chargeValue, null, 2));
        accountStore.TransnationalPartialRefundTransaction(this?.props?.chargeValue, this.itemarr, this.state.refundAmount, this.state.refundTax, this.props.jumperId, this.props.isTandemStudent)
          .then((r) => {
            console.log(r);
            if (r.status === 200) {
              //this.processPartialRefundResponse({ partialRefundAmount, partialRefundTax, transactionId: r.data.response_body.terminal.id, itemArr: this.itemarr })
              this.setState({ isModal2Open: false })
              this.props.toggleModal();
            }
            else {
              swal(
                {
                  title: "Warning",
                  text: "Check Terminal",
                  icon: "error",
                }
              )
              this.setState({
                isModal2Open: false,
                isloading: false,
              })
            }
          }
          );
      }
      if (window.ENV?.processorInfo.processor === "nab") {
        if (this.props.chargeValue.transactionType === "Credit") {
          var body = {
            transactionId: this?.props?.chargeValue?.transactionId || this?.props.chargeValue?.id,
            refundAmount: refundAmount
          }

          var response = Api.authorizeAndCapture("refund", body).then(this.processUndoResponse({ partialRefundAmount, partialRefundTax }));
        }
        if (this.props.chargeValue.transactionType === "Debit") {
          accountStore.NABReturnUnlinked(refundAmount).then(this.processUndoResponse({ partialRefundAmount, partialRefundTax }));
        }
      }
    } else if (chargeValue.transactionType === "Card key in" || chargeValue.transactionType === "Card On File" || chargeValue.transactionType === "Online") {
      var amount = this.state.refundAmount;
      var tId = chargeValue?.transactionId || chargeValue?.id;
      this.transactionId = tId;
      if (tId != "") {
        if (window.ENV?.processorInfo.processor === "transnational") {
          accountStore.TransnationalPartialRefundTransaction(this?.props?.chargeValue, this.itemarr, this.state.refundAmount, this.state.refundTax, this.props.jumperId, this.props.isTandemStudent)
            .then((r) => {
              
                //this.processPartialRefundResponse({ partialRefundAmount, partialRefundTax, transactionId: r.data.response_body.card.id, itemArr: this.itemarr })
                this.setState({ isModal2Open: false })
                this.props.toggleModal();
              
            }
            );
        }
        else if (window.ENV?.processorInfo.processor === "cardConnect") {
          var response = Api.cardConnetcGetTransaction(tId)
            .then(res => {
              if (res.data.setlstat === "Queued for Capture" || res.data.setlstat === "Authorized") {
                var payload = {
                  "retref": tId
                }
                var responseVoid = Api.cardConnetVoidTransaction(payload).then(this.processUndoResponse);
              }
              else if (res.data.setlstat != "Void") {
                var payload = {
                  "retref": tId,
                  "amount": amount.toString()
                }
                var responseRefund = Api.cardConnetRefundTransaction(payload).then(this.processUndoResponse({ partialRefundAmount, partialRefundTax }));

              }
            })
        }
      }
    }
    else if (chargeValue.transactionType === "Cash" || chargeValue.transactionType === "Military Account" || chargeValue.transactionType === "Credit Manual") {
      var amount = this.state.refundAmount;
      var tax = this.state.refundTax;
      if (window.ENV?.processorInfo.processor === "transnational") {
        var body = {
          transactionType: chargeValue.transactionType,
          transactionId: chargeValue?.transactionId || chargeValue?.id,
          amount: -amount,
          taxAmount: -tax,
          itemized: this.itemarr,
          isRefund: true,
          isTandemStudent: this.props.isTandemStudent ? true : false
        };
        accountStore.ChargeJumper(jumperId, body);
        this.setState({
          isloading: false
        })
        this.setState({ isModal2Open: false })
        this.props.toggleModal();

      }
    }
  }

  clickRefund = (value) => {

    this.setState({
      isloading: true
    })

    const { chargeValue, jumperId } = this.props;
    console.log(this.props, "<=================props")
    if (chargeValue.transactionType === "Credit Manual" || chargeValue.transactionType === "Military Account" || chargeValue.transactionType === "Cash" || chargeValue.transactionType === "Check" || (!window.ENV?.terminalEnabled && (chargeValue.transactionType === "Credit" || chargeValue.transactionType === "Debit"))) {
      if (chargeValue.itemized) {

        Object.keys(chargeValue.itemized).forEach(key => {
          chargeValue.itemized[key].forEach(item => {
            item.price = -item.price;
            item.tax = -item.tax;
          })
        })

      }
      var body = {
        transactionType: chargeValue.transactionType,
        transactionId: chargeValue?.transactionId || chargeValue?.id,
        amount: -chargeValue.amount,
        taxAmount: -chargeValue.taxAmount || -chargeValue.tax,
        itemized: chargeValue.itemized,
        isRefund: true,
        isTandemStudent: this.props.isTandemStudent ? true : false
      };
      accountStore.ChargeJumper(jumperId, body);
      this.setState({
        isloading: false
      })
      this.toggleModal();
    }
    else if (chargeValue.transactionType === "Card key in" || chargeValue.transactionType === "Card On File") {
      var amount = chargeValue.amount;
      var tId = chargeValue?.transactionId || chargeValue?.id;
      this.transactionId = tId;
      if (tId != "") {


        if (window.ENV?.processorInfo.processor === "nab") {
          var body = {
            TransactionId: tId
          }
          var response = Api.cancelAuthorizeAndCapture(body).then(this.processUndoResponse);
        }

        else if (window.ENV?.processorInfo.processor === "transnational") {
          accountStore.TransnationalVoidTransaction(this?.props?.chargeValue?.transactionId || this?.props?.chargeValue?.id, this.props.chargeValue, this.props.jumperId, this.props.isTandemStudent)
          .then(retObj => {
            this.toggleModal();
          })
        }


        else if (window.ENV?.processorInfo.processor === "cardConnect") {
          var response = Api.cardConnetcGetTransaction(tId)
            .then(res => {
              if (res.data.setlstat === "Queued for Capture" || res.data.setlstat === "Authorized") {
                var payload = {
                  "retref": tId
                }
                var responseVoid = Api.cardConnetVoidTransaction(payload).then(this.processUndoResponse);
              }
              else if (res.data.setlstat != "Void") {
                var payload = {
                  "retref": tId,
                  "amount": amount.toString()
                }
                var responseRefund = Api.cardConnetRefundTransaction(payload).then(this.processUndoResponse);

              }
            })
        }
      }
    }
    else if (chargeValue.transactionType.indexOf("Account-") > -1) {
      var payload = {
        "action": "void",
        "authorization_code": chargeValue.forteAuthorizationCode,
        "entered_by": "Dizio Manifest"
      }
      var transactionId = chargeValue?.transactionId || chargeValue?.id;
      Api.ForteAchVoidTransaction(transactionId, payload).then((r) => this.processAchUndoResponse(r));
    }
    else if (chargeValue.transactionType === "Cash Payout" || chargeValue.transactionType === "Check Payout" || (!window.ENV?.terminalEnabled && (chargeValue.transactionType === "Credit" || chargeValue.transactionType === "Debit"))) {
      var body = {
        transactionType: chargeValue.transactionType,
        transactionId: chargeValue?.transactionId || chargeValue?.id,
        amount: -chargeValue.amount,
        taxAmount: -chargeValue?.taxAmount || -chargeValue?.tax,
        isRefund: true,
        isTandemStudent: this.props.isTandemStudent ? true : false
      };
      accountStore.ChargeJumper(jumperId, body);
      this.setState({
        isloading: false
      })
      this.toggleModal();
    }
    else if (window.ENV?.terminalEnabled && (chargeValue.transactionType === "Credit" || chargeValue.transactionType === "Debit")) {
      if (window.ENV?.processorInfo.processor === "transnational") {
        accountStore.TransnationalVoidTransaction(this?.props?.chargeValue?.transactionId || this?.props?.chargeValue?.id, this.props.chargeValue, this.props.jumperId, this.props.isTandemStudent)
        .then(retObj => {
          this.toggleModal();
        })
      }
      else if (window.ENV?.processorInfo.processor === "nab") {

        accountStore.NABVoid(this?.props?.chargeValue?.transactionId || this?.props?.chargeValue?.id).then(this.processUndoResponse);
      }
      else {
        terminalAdapter.doVoidCredit((this?.props?.chargeValue?.transactionId || this?.props?.chargeValue?.id), - this.props.chargeValue.amount)
          .then(retObj => {
            accountStore.ChargeJumper(jumperId, retObj);
            this.toggleModal();
          })
      }
    }
    else {
      alert('why...')
    }


  }

  handleValue = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  processPaymentResponse = (r) => {
    console.log(r);
    if (r.data !== undefined && r.data.Status === "Successful") {
      //alert('Refund Successfully');
      const { chargeValue, jumperId } = this.props;



      var body = {
        transactionType: chargeValue.transactionType,
        transactionId: chargeValue?.transactionId || chargeValue?.id,
        amount: -r.data.Amount,
        isRefund: true
      };
      accountStore.ChargeJumper(jumperId, body);
      this.toggleModal2()
    }
    else {
      alert('something failed, please try again');
    }
    this.setState({
      isloading: false,
      isModalOpen: false
    })
  }

  processPartialRefundResponse = (r) => {
    //alert('partial Refund Successfully');
    const { chargeValue, jumperId } = this.props;

    console.log(this.props.chargeValue);
    /*
    if(r?.partialRefundAmount){
      chargeValue.amount=r?.partialRefundAmount
    }
    if (r?.partialRefundTax) {
      chargeValue.taxAmount = r?.partialRefundTax
    }
    */
    var body = {
      transactionType: chargeValue.transactionType,
      transactionId: r.transactionId,
      amount: -this.state.refundAmount,
      taxAmount: this.state.refundTax ? -this.state.refundTax : 0,
      isRefund: true,
      itemized: r.itemArr,
      isTandemStudent: this.props.isTandemStudent ? true : false
    };
    accountStore.ChargeJumper(jumperId, JSON.stringify(body));
  }

  processAchUndoResponse = (r,val) => {
    const { chargeValue, jumperId } = this.props;

    console.log(r.data);
    if(r.data?.response?.response_desc === "APPROVED"){
      var body = {
        transactionType: chargeValue.transactionType,
        transactionId: chargeValue?.transactionId || chargeValue?.id,
        amount: -chargeValue.amount,
        taxAmount: 0,
        isRefund: true,
        isTandemStudent: this.props.isTandemStudent ? true : false
      };
      accountStore.ChargeJumper(jumperId, body);
    }
    else{
      swal(
        {
          title: "Warning",
          text: "ACH Void error",
          icon: "error",
        }
      )
    }
    this.setState({
      isloading: false,
      isModalOpen: false
    })
  }
  processUndoResponse = (r) => {
    console.log("process undo response");
    console.log(r);
    console.log(this.props.charge);
    const { chargeValue, jumperId } = this.props;

    if (r?.partialRefundAmount) {
      chargeValue.amount = r?.partialRefundAmount
    }
    if (r?.partialRefundTax) {
      chargeValue.taxAmount = r?.partialRefundTax
    }

    if (window.ENV?.processorInfo.processor === "nab") {
      // alert('after refund unlinked');

      var xml = new XMLParser()?.parseFromString(r);    // Assume xmlText contains the example XML
      const resp_text = xml.getElementsByTagName('AUTH_RESP_TEXT')[0].value;
      if (resp_text.includes("APPROVAL")) {
        var body = {
          transactionType: chargeValue.transactionType,
          transactionId: chargeValue?.transactionId || chargeValue?.id,
          amount: -chargeValue.amount,
          isRefund: true,
          isTandemStudent: this.props.isTandemStudent ? true : false
        };
        accountStore.ChargeJumper(jumperId, body);
      }
      else if (resp_text.includes("APPROVED")) { //debit return unlinked
        const refundValue = xml.getElementsByTagName('AUTH_AMOUNT_REQUESTED')[0].value;

        var body = {
          transactionType: chargeValue.transactionType,
          transactionId: chargeValue?.transactionId || chargeValue?.id,
          amount: -Number(refundValue),
          isRefund: true,
          isTandemStudent: this.props.isTandemStudent ? true : false
        };
        accountStore.ChargeJumper(jumperId, body);
        this.toggleModal2()

      }
    }
    else if (window.ENV?.processorInfo.processor === "transnational" && r.status === "success" && r.msg === "success") {
      //alert('Refund Successfully');
      const { chargeValue, jumperId } = this.props;

      console.log(this.props.chargeValue);
      if (chargeValue.itemized) {
        Object.keys(chargeValue.itemized).forEach(key => {
          chargeValue.itemized[key].forEach(item => {
            item.price = -item.price;
            item.tax = -item.tax;
          })
        })
      }
      if (r?.partialRefundAmount) {
        chargeValue.amount = r?.partialRefundAmount
      }
      if (r?.partialRefundTax) {
        chargeValue.taxAmount = r?.partialRefundTax
      }
      var body = {
        transactionType: chargeValue.transactionType,
        transactionId: chargeValue?.transactionId || chargeValue?.id,
        amount: -chargeValue.amount,
        taxAmount: -chargeValue.taxAmount || -chargeValue?.tax,
        itemized: chargeValue.itemized,
        isRefund: true,
        isTandemStudent: this.props.isTandemStudent ? true : false
      };
      accountStore.ChargeJumper(jumperId, body);
    }
    else if (window.ENV?.processorInfo.processor === "cardConnect" && r.data.respstat !== "C" && r.data.respstat !== "B") {
      var body = {
        transactionType: chargeValue.transactionType,
        transactionId: chargeValue?.transactionId || chargeValue?.id,
        amount: -chargeValue.amount,
        isRefund: true,
        isTandemStudent: this.props.isTandemStudent ? true : false
      };
      accountStore.ChargeJumper(jumperId, body);
    }
    else {
      swal(
        {
          title: "Warning",
          text: "Check Terminal",
          icon: "error",
        }
      )

      if (r.status === 400) {
        var transactionId = this?.props?.chargeValue?.transactionId || this?.props?.chargeValue?.id;
        var amount = this.props.chargeValue.amount.toFixed(2);
        // var body = AccountApi.transferAuthorizeCaptureBody(transactionId, amount);
        // var response = Api.authorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), body).then(this.processPaymentResponse);
        var body = {
          transactionId: transactionId,
          refundAmount: amount
        };

        console.log(body);
        var response = Api.authorizeAndCapture("refund", body).then(this.processPaymentResponse);
      }
    }
    this.setState({
      isloading: false,
      isModalOpen: false
    })
  }

  filterOnRange = () => {
    const { chargeValueData } = this.props;
    const studentId = chargeValueData?.itemized && Object.keys(chargeValueData?.itemized).map((item) => {
      return JumperStore.getTandemStudentById(item);
    })
    const studentName = studentId.map((name) => {
      return name !== undefined ? name?.firstname + ' ' + name?.lastname : '';
    })
    return studentName;
  }

  handleTotalAmount = (event, ind, index) => {
    console.log('in handle Total Amount');
    if (event.target.name === "refundAmount") {
      const charge_Value = this.state.chargeValue;
      console.log(charge_Value);

      charge_Value[ind][index].refundamount = event.target.value;
      charge_Value[ind][index].refundtax = (Number(charge_Value[ind][index].tax) / Number(charge_Value[ind][index].price)) * event.target.value


      this.setState({ chargeValue: charge_Value },
        () => {
          let totalAmount = 0;
          let totalTax = 0;
          this.state.chargeValue && Object.entries(this.state.chargeValue)?.forEach((item) => {
            item[1]?.forEach((detail) => {
              let amount = Number(detail.refundamount);
              let tax = (Number(detail?.tax) / Number(detail?.price)) * detail?.refundamount;
              totalAmount += amount;
              totalTax += tax;
              console.log(amount,)
            })
          })
          this.setState({
            refundAmount: totalAmount,
            refundTax: totalTax
          })
        })
    }
    else if (event.target.name === "partialRefundAmount") {
      const charge_Value = this.state.chargeValue;
      console.log(charge_Value);
      console.log(charge_Value[0]);
      if (charge_Value[0].length === 0) {
        var totalAmount = event.target.value;
        console.log(totalAmount);
        this.setState({
          refundAmount: totalAmount,
        })
      }
      //console.log(charge_Value);
    }
    else if (event.target.name === "partialRefundTax") {
      const charge_Value = this.state.chargeValue;
      console.log(charge_Value);
      console.log(charge_Value[0]);
      if (charge_Value[0].length === 0) {
        var totalAmount = event.target.value;
        console.log(totalAmount);
        this.setState({
          refundTax: totalAmount,
        })
      }
      //console.log(charge_Value);
    }
  }

  render() {
    const { chargeValueData } = this.props;
    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    var hasItemized = true;
    if (this.props.chargeValueData?.itemized) {
      var keys = Object.keys(this.props.chargeValueData?.itemized);
      if (keys.length === 1 && this.props.chargeValueData?.itemized[keys[0]].length === 0) {
        console.log('no itemized');
        hasItemized = false;
      }
    }
    return (
      <React.Fragment>
        <ItemModal
          isModalOpen={this.state.isModal2Open}
          title={`Refund Partial Amount`}
        >
          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>
          <LocalForm
            onSubmit={(values) => this.clickPartialRefund(values)}
          >
            <Row className="form-group">
              <Col md={12}>
                <span>Are you Sure you Want to Refund ?</span>
              </Col>
            </Row>
            {
              <table className="jumperDetail">
                <Row className="form-group">
                  <Col md={2}>Name</Col>
                  <Col md={2}>Item</Col>
                  <Col md={2}>Amount</Col>
                  {loadsStore.isTaxEnabled() && <Col md={2}>Tax</Col>}
                  <Col md={2}>Refund Amount</Col>
                  {loadsStore.isTaxEnabled() && <Col m={2}>Refund Tax</Col>}
                </Row>

                {
                  chargeValueData !== undefined ?
                    this.state.chargeValue && Object.entries(this.state.chargeValue)?.map((item, ind) => {
                      return (
                        Array.isArray(item[1]) && item[1]?.map((detail, index) => {

                          return (

                            <Row key={index}>
                              <Col md={2}>{this.filterOnRange()[ind]}</Col>
                              <Col md={2}>{detail?.item}</Col>
                              <Col md={2}>{Number(detail?.price).toFixed(2)}</Col>
                              {loadsStore.isTaxEnabled() && <Col md={2}>{Number(detail?.tax).toFixed(2)}</Col>}
                              <Col md={2}>
                                <Control.input name="refundAmount" model={`.refundAmount-${ind - index}`} className="form-control"
                                  //  onChange={this.handleValue}
                                  value={detail?.refundamount} autoComplete="off" onChange={(e) => this.handleTotalAmount(e, ind, index)}
                                />
                              </Col>
                              {loadsStore.isTaxEnabled() && <Col md={2}>
                                <Control.input name="refundTax" model={`.refundTax-${ind - index}`} className="form-control" onChange={(e) => this.handleTotalAmount(e, ind, index)}
                                  value={detail?.refundtax} autoComplete="off"
                                />
                                
                              </Col>}
                            </Row>
                          )
                        })
                      )
                    }) : ''
                }

              </table>
            }
            <Row>
              <Label htmlFor="partialRefund" md={3}>Refund amount</Label>
              <Col md={9} style={{ paddingTop: "13px" }}>
                {hasItemized ?
                  <Control.input name="partialRefundAmount" model=".partialRefund" className="form-control"
                    // value={this.state.partialRefundAmount}
                    value={Number(this.state.refundAmount).toFixed(2)}
                    onChange={(e) => this.handleTotalAmount(e)}
                    autoComplete="off"
                  //  onChange={this.handleValue} 
                  /> :
                  <Control.input name="partialRefundAmount" model=".partialRefund" className="form-control"
                    // value={this.state.partialRefundAmount}
                    defaultValue={"0.00"}
                    onChange={(e) => this.handleTotalAmount(e)}
                    autoComplete="off"
                  //  onChange={this.handleValue} 
                  />
                }
              </Col>
            </Row>
            {this.props?.showPartialRefundModal && loadsStore.isTaxEnabled()?
              <Row>
                <Label htmlFor="partialRefund" md={3}>Refund Tax Amount</Label>
                <Col md={9} style={{ paddingTop: "13px" }}>
                  {hasItemized ?
                    <Control.input name="partialRefundTax" model=".partialRefundTax" className="form-control"
                      value={Number(this.state.refundTax).toFixed(2)}
                      // value={this.state.partialRefundTax} 
                      onChange={(e) => this.handleTotalAmount(e)}

                    /> :
                    <Control.input name="partialRefundTax" model=".partialRefundTax" className="form-control"
                      defaultValue={"0.00"}
                      // value={this.state.partialRefundTax} 
                      onChange={(e) => this.handleTotalAmount(e)}

                    />
                  }
                </Col>
              </Row> : ""
            }
            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 9 }}>
                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal2(); this.setState({ partialRefundAmount: "" }) }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>

          </LocalForm>
        </ItemModal>

        <ItemModal
          isModalOpen={this.state.isModalOpen}
          title={`Refund Jumper`}
        >

          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>

          <LocalForm onSubmit={(values) => this.clickRefund(values)}>

            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 9 }}>
                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>

          </LocalForm>
        </ItemModal>


        {(window.ENV?.terminalEnabled == false || window.ENV?.processorInfo.processor === "transnational" || window.ENV?.processorInfo.processor === "cardConnect") || (window.ENV?.processorInfo.processor === "nab" && this.props.chargeValue.transactionType !== "Debit") ?
          <button className="btn lightBlueButton" onClick={() => this.props.showPartialRefundModal ? this.toggleModal2() : this.toggleModal()}>
            <i className="fa fa-undo" />
          </button>

          : ""
        }

      </React.Fragment>
    );
  }
}

export default React.memo(RefundConfirMation)
