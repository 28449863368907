import { create } from 'apisauce';
import authStore from '../stores/auth-store';
import reservationStore from '../stores/reservation-store';
import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const func = {};



func.ChargeJumper = (id, payload) => {

	// console.log('in Charge Jumper');
	// console.log(payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)

	return api.post('/jumpers/charge/' + id, payload);
}


func.AddItem = (id, payload) => {
	// console.log('in Add Item');
	// console.log(payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token);

	return api.post('/jumpers/addItem/' + id, payload);
}

func.DeleteItem = (jumperId, itemId) => {
	// console.log('in Add Item');
	// console.log(payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token);

	return api.delete('/jumpers/' + jumperId + "/deleteItem/" + itemId);
}

func.AddItemForTandemStudent = (id, payload) => {
	// console.log('in Add Item to Tandem Student');
	// console.log(payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token);

	return api.post('/jumpers/addItemForTandemStudent/' + id, payload);
}

func.deleteItemForTandemStudent = (jumperId, itemId) => {
	// console.log('in Add Item to Tandem Student');
	// console.log(payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token);

	return api.delete('/jumpers/' + jumperId + "/deleteItemForTandemStudent/" + itemId);
}


func.createCardAuthorizeCaptureBody = (tokenBody) => {
	var expiry = tokenBody.Month + tokenBody.year;
	return {
		"$type": "AuthorizeAndCaptureTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
		SessionToken: reservationStore.getTokENV?.l(),
		ApplicationProfileId: reservationStore.getApplicationProfileId(),
		MerchantProfileId: reservationStore.getMerchantProfileId(),
		Transaction: {
			"$type": "BankcardTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
			TenderData: {
				PaymentAccountDataToken: null,
				SecurePaymentAccountData: null,
				CardData: {
					CardType: tokenBody.cardType,//this.state.cardType.toString(), //visa
					CardholderName: tokenBody.fullName,
					PAN: tokenBody.cardNumber,//this.state.cardNumber.toString(), // 4000000000000002
					Expire: expiry//tokenBody.selMonth + tokenBody.selYear,  // 1220
				},
				CardSecurityData: {
					AVSData: {
						CardholderName: tokenBody.fullName,
					},
					CVDataProvided: "Provided",
					CVData: tokenBody.Cvv,
				}
			},
			TransactionData: {
				Amount: tokenBody.amount,
				CurrencyCode: "USD",
				TransactionDateTime: new Date().toISOString(),
				CustomerPresent: "Ecommerce",
				EmployeeId: "12345",
				EntryMode: "Keyed",
				OrderNumber: "12345",
				SignatureCaptured: false,
			}
		}
	}
}


func.createAuthorizeCaptureBody = (payload) => {
	return {
		"$type": "AuthorizeAndCaptureTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
		SessionToken: reservationStore.getTokENV?.l(),
		ApplicationProfileId: reservationStore.getApplicationProfileId(),
		MerchantProfileId: reservationStore.getMerchantProfileId(),
		Transaction: {
			"$type": "BankcardTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
			TenderData: {
				PaymentAccountDataToken: payload.token
			},
			TransactionData: {
				Amount: payload.amount,
				CurrencyCode: "USD",
				TransactionDateTime: new Date().toISOString(),
				CustomerPresent: "Ecommerce",
				EmployeeId: "12345",
				EntryMode: "Keyed",
				OrderNumber: "12345",
				SignatureCaptured: false,
			}
		}
	}
}

func.cancelAuthorizeCaptureBody = (transactionId) => {
	return {
		"$type": "Undo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
		"ApplicationProfileId": reservationStore.getApplicationProfileId(),
		"DifferenceData": {
			"$type":
				"BankcardUndo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
			"TransactionId": transactionId,
			"Addendum": null
		}
	}
}

func.transferAuthorizeCaptureBody = (transactionId, amount) => {
	return {
		"$type": "ReturnById,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
		"ApplicationProfileId": reservationStore.getApplicationProfileId(),
		"MerchantProfileId": reservationStore.getMerchantProfileId(),
		"DifferenceData": {
			"$type":
				"BankcardReturn,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
			"TransactionId": transactionId,
			"TransactionDateTime": '',
			"Amount": amount,
			"Addendum": null
		}
	}
}

func.transferAuthorizeCaptureTransBody = (amount) => {
	return {
		"amount": parseInt(parseFloat(amount) * 100)
	}
}

export default func;
