import { useState, useRef, useEffect } from "react";
import CustomModal from "./../Common/CustomModal";

import {
  Input,
  Row,
  Col,
  Label,
  ModalBody,
  FormGroup,
  Button,
} from "reactstrap";
import "./emailtemplates.css";
import { EditorState, ContentState } from "draft-js";
import EmailEditor from "react-email-editor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import DonutLoader from "../loadManager/DonutLoader";
import swal from "sweetalert";

export default function EmilModal({
  state,
  handleChange,
  handleSumbit,
  addEditLoading,
  setIsModalOpen,
  isModalOpen,
  handleCancel,
  HTMLmarkup,
  setHTMLmarkup,
  modalData,
  handleEditSubmit,
  setModalData,
  formValues,
  setFormValues,
  editorDesign, 
  setEditorDesign,
}) {
  const [convertedContent, setConvertedContent] = useState(null);
  const emailEditorRef = useRef(null);
  const [userData, setUserData] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    return () => {
      setModalData(null)
      setFormValues({})
     }
  }, [])

 useEffect(() => {
    if (modalData?.name) {
      console.log(modalData,"modalDatamodalData")
      setFormValues(prev => ({ ...prev, name: modalData?.name, subject: modalData?.subject}));
      setHTMLmarkup(modalData?.content);
   }
   
  }, [modalData]);

  // const onLoad = () => {
  //   console.log("<<<<<<<<<>>>>>>>>>11111")
  //   if(modalData) {
  //           emailEditorRef?.current?.editor?.setBodyValues(modalData?.content);

  //     // emailEditorRef?.current?.editor?.loadDesign(modalData?.design || {})
  //   }
  // };

  const onReady = () => {
    if(modalData) {
      emailEditorRef?.current?.editor?.loadDesign(modalData?.design || {})
    }
  };


  const handleSubmit  =() => {
    emailEditorRef?.current?.editor?.exportHtml((data) => {
      if (!formValues?.name || !formValues?.subject ) {
        swal({
          title: "Error",
          text: "Please fill all the necessary fields",
          icon: "error",
          dangerMode: true,
        });
        return;
      }
    
      const { design, html } = data;
      setHTMLmarkup(html);
      setEditorDesign(design);
      
      if (modalData?.name) {
        handleEditSubmit({ design, html });
      } else {
        handleSumbit({ design, html });
      }
    });


  } 

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  console.log("SDEOPDESG+IGN==>", editorDesign)

  return (
    <>
    
      <CustomModal
     
        className="Email_Modal"
        isModalOpen={isModalOpen || !!modalData?.name || !!modalData?.subject }
        title={"Add Email Template"}
        backgroundColor="white"
        color="black"
        
        fullscreen={true}
        // size="lg"
       
      >
          <div className="Donutcss">
            <DonutLoader isLoading={addEditLoading} addClass="sample1" />
          </div>
          <Row>
            <Col md={6} >
          <FormGroup floating>
            <Label style={{ color: "black" }}>Name</Label>
            <Input
              style={{
                width: "30rem",
                backgroundColor: "white",
                color: "black",
              }}
              id="name"
              name="name"
              value={formValues?.name}
              type="text"
              onChange={handleChange}
            />
          </FormGroup>
          </Col>
          <Col  md={6} >
            <FormGroup>
             <Label style={{ color: "black",  }}>Subject</Label>
             <Input
              style={{
                width: "30rem",
                backgroundColor: "white",
                color: "black",
              }}
              id="subject"
              name="subject"
              value={formValues?.subject}
              type="text"
              onChange={handleChange}
            />
          </FormGroup>
          </Col>
          </Row>
          <label style={{ color: "black" }}>Content </label>
          <div style={{ width: "84%"}}>
            {/* <div> */}
            {/* <div style={{width: '62.5%'}} className="mb-2 d-flex justify-content-between align-items-center" >
              <Button color="primary" onClick={exportHtml}>
                Export HTML
              </Button>
              <Button color="primary" onClick={() => {
                emailEditorRef.current.editor.saveDesign(design => {
                    console.log('saveDesign', design)
                    setEditorDesign(design)
                })
              }}>
                Export Design
              </Button>
              </div>
            </div> */} 
            <div className="emailEditorStyle">
              <EmailEditor
                ref={emailEditorRef}
                className="email-editor-style-1920"

                // onLoad={onLoad}
                onReady={onReady}
                style={{
                  minHeight: "67vh",}}
                // // value={formValues?.content}
              />
            </div>
          </div>
          <Row className="form-group m-t-2-rem">
            <Col md={{ size: 10 }}>
              <button
                className="btn btn-bg lightColor createButton"
                type="submit"
                  // disabled={!formValues?.name || !HTMLmarkup}
                onClick={() => {
                  handleSubmit()
                }}
              >
                SUBMIT
              </button>
              <button
                onClick={handleCancel}
                className="btn btn-link"
                type="button"
              >
                CANCEL
              </button>
            </Col>
          </Row>
          <div
            className="preview"
            dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></div>
      </CustomModal>
    </>
  );
}
