export const data = {
    invoice_by_category: [{
        "countersales": {},
        "jumps": {
            "tandems": {
                "count": 3,
                "value": 675
            },
            "tandemMaster": {
                "count": 3,
                "value": -120
            },
            "videos": {
                "count": 3,
                "value": 300
            },
            "afflevel1_3": {
                "count": 0,
                "value": 0
            },
            "afflevel4_7": {
                "count": 0,
                "value": 0
            },
            "videoMaster": {
                "count": 3,
                "value": -45
            },
            "affInstructors": {
                "count": 0,
                "value": 0
            },
            "hopnpops": {
                "count": 0,
                "value": 0
            },
            "funjumps": {
                "count": 0,
                "value": 0
            }
        },
        "misc": {
            "gearRentals": {
                "count": 0,
                "value": 0
            }
        }
    }],



    jumper_logs: [
        { type: 'Belly Jump', date: '2018-09-05', jumperName: 'Josh 789' },
        { type: 'Belly Jump', date: '2018-09-02', jumperName: 'Mike 1334' },
        { type: 'Belly Jump', date: '2018-09-16', jumperName: 'Mike 1334' },
        { type: 'Free Fly ', date: '2018-09-17', jumperName: 'Justin 289' },
        { type: 'Free Fly ', date: '2018-09-17', jumperName: 'katie 787' },
        { type: 'Belly Jump', date: '2018-10-05', jumperName: 'Josh 789' },
        { type: 'Belly Jump', date: '2018-10-02', jumperName: 'Justin 289' },
        { type: 'Tracking', date: '2018-10-16', jumperName: 'Kim 564' },
        { type: 'Tracking', date: '2018-10-17', jumperName: 'Sara 988' },
        { type: 'Tracking', date: '2018-10-17', jumperName: 'Sara 988' }
    ],

    number_slots: [
        { date: '2018-10-09', slots: 10 },
        { date: '2018-10-13', slots: 78 },
        { date: '2018-10-14', slots: 64 },
        { date: '2018-10-15', slots: 14 },
        { date: '2018-10-16', slots: 101 },
        { date: '2018-10-18', slots: 29 },
        { date: '2018-10-29', slots: 104 },
    ],

    staff_summary: [
        { total: -375.00, member: 'Joel Pinock', date: '2018-08-03' },
        { total: -375.00, member: 'Keith forsyth', date: '2018-08-05' },
        { total: -1690.00, member: 'Dennis Robinson', date: '2018-08-12' },
        { total: -1275.00, member: 'Melissa Passmore', date: '2018-08-14' },
        { total: -585.00, member: 'Justin Ducios', date: '2018-08-17' },
        { total: -975.00, member: 'Nick Kramer', date: '2018-08-19' },
        { total: -375.00, member: 'Joel Pinock', date: '2018-08-20' },
        { total: -375.00, member: 'Keith forsyth', date: '2018-08-23' },
        { total: -1690.00, member: 'Dennis Robinson', date: '2018-09-03' },
        { total: -1275.00, member: 'Melissa Passmore', date: '2018-09-05' },
        { total: -585.00, member: 'Justin Ducios', date: '2018-09-12' },
        { total: -975.00, member: 'Nick Kramer', date: '2018-09-14' },
        { total: -375.00, member: 'Joel Pinock', date: '2018-09-17' },
        { total: -375.00, member: 'Keith forsyth', date: '2018-09-19' },
        { total: -1690.00, member: 'Dennis Robinson', date: '2018-09-20' },
        { total: -1275.00, member: 'Melissa Passmore', date: '2018-09-23' },
        { total: -585.00, member: 'Justin Ducios', date: '2018-09-19' },
        { total: -975.00, member: 'Nick Kramer', date: '2018-09-19' },
        { total: -375.00, member: 'Joel Pinock', date: '2018-09-19' },
        { total: -375.00, member: 'Keith forsyth', date: '2018-10-03' },
        { total: -1690.00, member: 'Dennis Robinson', date: '2018-10-05' },
        { total: -1275.00, member: 'Melissa Passmore', date: '2018-10-12' },
        { total: -585.00, member: 'Justin Ducios', date: '2018-10-14' },
        { total: -975.00, member: 'Nick Kramer', date: '2018-10-17' },
        { total: -375.00, member: 'Joel Pinock', date: '2018-10-19' },
        { total: -375.00, member: 'Keith forsyth', date: '2018-10-20' },
        { total: -1690.00, member: 'Dennis Robinson', date: '2018-10-23' },
        { total: -1275.00, member: 'Melissa Passmore', date: '2018-10-23' },
        { total: -585.00, member: 'Justin Ducios', date: '2018-10-25' },
        { total: -975.00, member: 'Nick Kramer', date: '2018-10-26' },
    ]
};

export const languages = [
    {
        name: 'Abdullah',
        year: 1972
    },
    {
        name: 'Mike 1334',
        year: 2000
    },
    {
        name: 'katie 787',
        year: 1983
    },
    {
        name: 'Josh 789',
        year: 2007
    },
    {
        name: 'jolia 1123',
        year: 1972
    },
    {
        name: 'Sara 988',
        year: 2000
    },
    {
        name: 'Justin 289',
        year: 1983
    },
    {
        name: 'Kim 564',
        year: 2007
    },
    {
        name: 'solomon 576',
        year: 1972
    },
    {
        name: 'David 909',
        year: 2000
    },
    {
        name: 'Lynda 453',
        year: 1983
    },
    {
        name: 'Maria 222',
        year: 2007
    },
    {
        name: 'Bil 777',
        year: 1972
    },
    {
        name: 'Harvis 590',
        year: 2000
    },
    {
        name: 'harles 300',
        year: 1983
    },
    {
        name: 'jean 109',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C#',
        year: 2000
    },
    {
        name: 'C++',
        year: 1983
    },
    {
        name: 'Clojure',
        year: 2007
    },
    {
        name: 'Elm',
        year: 2012
    },
    {
        name: 'Go',
        year: 2009
    },
    {
        name: 'Haskell',
        year: 1990
    },
    {
        name: 'Java',
        year: 1995
    },
    {
        name: 'Javascript',
        year: 1995
    },
    {
        name: 'Perl',
        year: 1987
    },
    {
        name: 'PHP',
        year: 1995
    },
    {
        name: 'Python',
        year: 1991
    },
    {
        name: 'Ruby',
        year: 1995
    },
    {
        name: 'Scala',
        year: 2003
    }
];













