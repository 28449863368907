
import { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class CustomModal extends Component {
    render() {
        return (
            <Fragment>
                <Modal id="modal-main" className={this.props?.className ? this.props?.className :  "modalBody123"}
                wrapClassName="YourWrapCustomClass"
                modalClassName= "YourModalCustomClass"
                backdropClassName= "YourBackCustomClass"
                contentClassName="YourContentCustomClass"
                // fullscreen={this.props.fullscreen}
                fullScreen={true}
                // size={ this.props.size ? this.props.size :  "lg"}
                isOpen={this.props.isModalOpen} >
                    <ModalHeader style={{ color: this.props.color, backgroundColor:this.props.backgroundColor,  }}>{this.props.title}</ModalHeader>
                    <ModalBody style={{ backgroundColor:this.props.backgroundColor }}>
                        {this?.props?.children}
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default CustomModal
