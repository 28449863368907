import {
    Home,
    Anchor,
    Calendar,
    BookOpen,
    FileText,
    User
} from 'react-feather';
import {
    JUMPER_LOGS,
    NUMBER_SLOTS,
    STAFF_SUMMARY,
    JUMPER_STATEMENT,
    INVOICE_BY_CATEGORY,
    JUMPER_STATE,
    TANDEM_STATE,
    LOAD_HISTORY,
    DETAIL_BY_CATEGORY,
    GIFT_CARD,
    TANDAM_STUDENT,
    SLOTS_BY_PLANE
} from './reports';
export const MENUITEMS = [
    {
        title: 'Load Manager', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/load-manager', title: 'Loads', type: 'link' },
            { path: '/completed-loads', title: 'Completed Loads', type: 'link' },
            { path: '/waitlist', title: 'Waitlist', type: 'link' },
            // { path: '/dashboard/ecommerce', title: 'Load Overview', type: 'link' },
            // { path: '/dashboard/university', title: 'Tandem board', type: 'link' },
        ]
    },
    {
        title: 'Reservations', icon: Calendar, type: 'sub', active: false, children: [
            { path: '/schedules-tandem-aff', title: 'Calendar', type: 'link' },
            { path: '/calender-events', title: 'Calendar events', type: 'link' },
            { path: '/reservations/emailtemplatelist', title: 'Email Templates', type: 'link' },
            { path: '/reservations/smsTextTemplates', title: 'SMS Text Templates', type: 'link' },
            { path: '/reservations/reservationlist', title: 'Reservation list', type: 'link' },
            { path: '/terms-conditions', title: 'Terms & Conditions', type: 'link' },
        ]
    },
    {
        title: 'View Accounts', icon: BookOpen, type: 'link', path: '/view-accounts', active: false,
        children: [
            { path: '/view-accounts', title: 'Accounts', type: 'link' }
        ]
    },
    {
        title: 'Store', path: '/store/items', icon: Anchor, type: 'link', active: false, children: [
            {
                path: '/store', title: 'Inventory', icon: Anchor, active: true, type: 'link', subChildren: [
                    { path: '/store/items', title: 'Items', type: 'link' },
                    { path: '/store/category', title: 'Category', type: 'link' },
                ]
            },
            { path: '/store/schedules', title: ' Pay/Price schedules', type: 'link' },
            { path: '/store/planes', title: 'Planes', type: 'link' },
            { path: '/store/altitudes', title: 'Altitudes', type: 'link' },
            { path: '/store/packages', title: 'Tandem/Video packages', type: 'link' },
            { path: '/store/blocks', title: 'Blocks', type: 'link' },
            { path: '/store/grouponpackages', title: 'Groupon packages', type: 'link' },
            { path: '/store/giftcards', title: 'Gift Cards', type: 'link' },
            { path: '/store/discounts', title: 'Discounts', type: 'link' },
            { path: '/store/studentpackages', title: 'Student packages', type: 'link' },
        ]
    },
    {
        title: 'Reports', icon: FileText, type: 'link', active: false, path: "/reports", children: [
            { path: SLOTS_BY_PLANE, title: 'Slots by Plane', type: 'functional' },
            { path: JUMPER_STATE, title: 'Jumper Stats', type: 'functional' },
            { path: TANDEM_STATE, title: 'Staff Stats', type: 'functional' },
            { path: INVOICE_BY_CATEGORY, title: 'Invoice by category', type: 'functional' },
            { path: NUMBER_SLOTS, title: 'Number slots', type: 'functional' },
            { path: STAFF_SUMMARY, title: 'Staff summary', type: 'functional' },
            { path: JUMPER_STATEMENT, title: 'Jumper statement', type: 'functional' },
            { path: LOAD_HISTORY, title: 'Load history', type: 'functional' },
            { path: DETAIL_BY_CATEGORY, title: 'Detail by category', type: 'functional' },
            { path: GIFT_CARD, title: 'Giftcard', type: 'functional' },
            { path: TANDAM_STUDENT, title: 'Tandem Students', type: 'functional' },
        ]
    },
    {
        title: 'Admin', path: "/admin", icon: User, type: 'link', active: false, children: [
            { path: '/admin', title: 'Jumper profiles', type: 'link' },
            { path: '/admin/alerts', title: 'Alerts', type: 'link' },
            { path: '/admin/settings', title: 'Settings', type: 'link' },
            { path: '/admin/oauth_settings', title: 'Oauth Settings', type: 'link' },
            { path: '/admin/teams', title: 'Teams', type: 'link' },
            { path: '/admin/national2021', title: 'National 2021', type: 'link' },
            { path: '/admin/mundial2022', title: 'Mundial 2022', type: 'link' }
        ]
    },
    {
        title: 'Rig Management', icon: Anchor, type: 'sub', active: false, children: [
            { path: '/rigs', title: 'rigs', type: 'link' },
            { path: '/rigs/jobs', title: 'rig job requests', type: 'link' },
        ]
    },
    // {
    //     path: 'http://support.pixelstrap.com/help-center', title: 'Raise Support', icon: Headphones, type: 'exteral_link', active: false
    // },
]
