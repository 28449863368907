import { create } from 'apisauce';
import authStore from '../stores/auth-store';
import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const resApi = create({
	baseURL: urlgetter.getReservationApiUrl()
})

const func = {};

func.loadCategories = () => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/items/loadCategories');
}

func.loadItems = () => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/items/');
}

//IMAGE BLOB
func.uploadThumb = (payload, data) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/items/thumbnail/', payload);
}

func.createItem = (payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/items/', payload);
}

func.createAltitude = (payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/items/createAltitude', payload);
}
func.updateAltitude = (payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/items/updateAltitude', payload);
}
func.deleteAltitude = (payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/items/deleteAltitude', payload);
}

func.uploadImage = (payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/giftcard_thumbnail_assets', payload);
}
func.uploadImageInReservation = (payload) => {
	//upload giftcard thumbnail in schduler-backend also
	console.log("api payload: ", payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	resApi.setHeader('Authorization', 'Bearer ' + token)
	return resApi.post('/giftcards/giftcard_thumbnail_assets', payload);
}

func.updateItem = (id, payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/items/' + id, payload);
}

func.createStudentPackage = (payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/items/studentPackages', payload);
}
func.updateOrderStudentPackage = (payload) => {
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.post('/items/updateOrderStudentPackage', payload);
}

func.updateStudentPackage = (id, payload) => {
	console.log('update student package');
	console.log(payload);
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.put('/items/studentPackages/' + id, payload);
}

func.deleteStudentPackage = (id, payload) => {
	console.log('deleting student package api');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/items/studentPackages/' + id, payload);
}

func.deleteItem = (id, payload) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.delete('/items/' + id, payload);
}

func.verifyDiscountCode = (code) => {
	// console.log('load price data');
	var token = authStore.getAuthToken();
	if (token == '') {
		token = localStorage.getItem("Auth-token");
	}
	// console.log(token);
	api.setHeader('Authorization', 'Bearer ' + token)
	return api.get('/items/discount/' + code);
}
















export default func;
