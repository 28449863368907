import React from 'react';
import './style/donutloader.css';

function DonutLoader({ isLoading, addClass }) {
    return (
        isLoading ?
            <div className={`donut-container ${addClass}`}>
                <div id="loader">
                    <div className="donut">
                        <img src="/loaders/donut.png" width="60" height="60" alt="" />
                    </div>
                </div>
            </div>
            : ""
    )
}
export default DonutLoader;