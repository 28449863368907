import React, { Component } from 'react';


import { DropTarget } from 'react-dnd'
import RenderJumperSource from './RenderJumperSource';

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		// hovered: monitor.isOver(),
		// item: monitor.getItem(),
	}
}

const loadTarget = {
	drop(props, monitor, component) {

		const dragItem = monitor.getItem();

		if (dragItem.jumper) {
			var jumperId = dragItem.jumper._id;
			var jumpId = props.jumpId;
			var oldJumpId = dragItem.jumpId;

			if (jumpId == oldJumpId) {
				return;
			}

			var payload = {}
			payload.jumpId = props.jumpId;
			payload.loadNum = props.loadNum;
			payload.jumper = dragItem.jumper;
			payload.oldJumpId = dragItem.jumpId;
			payload.oldLoadNum = dragItem.loadNum;
			dragItem.handleJumperRemove(payload);
		}

	}
}

class RenderJumperTarget extends Component {

	constructor(props) {
		super(props);
		this.state = {
			jumpers: []
		}
	}
	render() {
		const { jump, jumpType, loadNum, jumpId, disabled, jumperOptions, isDragging, connectDragSource, src, connectDropTarget, hovered, item, loadsToShow, moreThan4, columnsPerGroup, fontSize, isInline } = this.props;
		console.log("isCompleted", this.props.isCompleted)
		return connectDropTarget(
			<div>

				<RenderJumperSource
					columnsPerGroup={columnsPerGroup}
					moreThan4={moreThan4}
					load={this.props.load}
					loadsToShow={loadsToShow}
					isToday={this.props.isToday}
					jump={jump}
					jumpType={jumpType}
					loadNum={loadNum}
					jumpId={jumpId}
					disabled={disabled}
					jumperOptions={jumperOptions}
					fontSize={fontSize}
					isDragging={isDragging}
					handleCreateJumper={this.handleCreateJumper}
					handleJumperRemove={this.handleJumperRemove}
					loadId={this.props.loadId}
					isCompleted={this.props.isCompleted}
					isInline={isInline} />

			</div>
		)
	}
}

export default DropTarget('JUMP', loadTarget, collect)(RenderJumperTarget);
