import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import { styles } from './DataTableStyle';
import reportStore from '../../../../stores/report-store';
import * as constants from '../../../constant/reports';
// import { ClipLoader } from 'react-spinners';

import DonutLoader from '../../../loadManager/DonutLoader';

class NumberSlots extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterData: [],
            Isdata: false,
            isloading: false
        }
        this.handleNewActivity = this.handleNewActivity.bind(this);
        this.loadEvents = [
            { name: reportStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
        ];

    }

    dateConverter = (date) => {
        let dateInMilliseconds = new Date(date).getTime();
        return dateInMilliseconds;
    }

    componentWillReceiveProps(nextprops) {
        const { range } = this.props;
        var startDate = '';
        var endDate = '';
        // if (range.dateRange.length > 0) {
        if (range.dateRange.length > 0 && nextprops.isModal === false && nextprops.submit === true) {
            const index = range.dateRange.findIndex(range => range.modalType === constants.NUMBER_SLOTS);
            if (index > -1) {
                this.setState({ isloading: true });

                let sDate = new Date(range.dateRange[index].startDate);
                sDate = sDate.setHours(0, 0, 0, 0);
                startDate = new Date(sDate);
                startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                let eDate = new Date(range.dateRange[index].endDate);
                eDate = eDate.setHours(23, 59, 59, 999);
                endDate = new Date(eDate);
                endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');


                var that = this;
                var data = [];
                Promise.all([reportStore.GetSlots(startDate, endDate)])
                    .then(function (result) {
                        data = result[0];
                        localStorage.setItem("SlotsData", JSON.stringify(data));
                        that.setState({ filterData: data, Isdata: true });
                        that.forceUpdate();
                    })
            }
        }
        else {
            var data = localStorage.getItem("SlotsData");
            if (data !== null) {
                this.setState({ filterData: JSON.parse(data), Isdata: true });
            }
        }
    }

    componentWillUnmount = () => {
        this.loadEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }

    handleNewActivity = (activityString) => {
        this.setState({
            isloading: false,
        })
    }

    componentWillMount() {
        this.loadEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func))

        // let { dateParam } = this.props;
        // var startDate = '';
        // var endDate = '';
        // //alert(JSON.stringify(dateParam))
        // if (dateParam.length > 0) {
        //     const index = dateParam
        //         .findIndex(range => range.modalType === constants.NUMBER_SLOTS);
        //     //alert("slots:"+ JSON.stringify(dateParam[index]));
        //     if (index > -1) {

        //         var data= localStorage.getItem("SlotsData");
        //         if(data !== null)
        //         {
        //             this.setState({ filterData: JSON.parse(data), Isdata: true});
        //         }

        //         // this.setState({ isloading: true })
        //         // let sDate = new Date(dateParam[index].startDate);
        //         // sDate = sDate.setHours(0, 0, 0, 0);
        //         // startDate = new Date(sDate);
        //         // startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // let eDate = new Date(dateParam[index].endDate);
        //         // eDate = eDate.setHours(23, 59, 59, 999);
        //         // endDate = new Date(eDate);
        //         // endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // var that = this;
        //         // var data = [];
        //         // Promise.all([reportStore.GetSlots(startDate, endDate)])
        //         //     .then(function (result) {
        //         //         data = result[0];
        //         //         that.setState({ filterData: data, Isdata: true });
        //         //         that.forceUpdate();
        //         //     })
        //     }
        // }
    }

    filterOnRange = (range, dataToBeFiltered) => {
        // const { startDate, endDate } = range;
        // let endRange = this.dateConverter(endDate);
        // let startRange = this.dateConverter(startDate);

        // let data = dataToBeFiltered.filter(({ date }) => {
        //     let convertedDate = this.dateConverter(date);
        //     if (startRange <= convertedDate && endRange >= convertedDate) {
        //         return true;
        //     };
        // });
        // return data;

        // DeepCopyData.push(this.state.filterData);
        let DeepCopy = this.state.filterData;
        return DeepCopy;
    }


    render() {
        const { classes, data, submit, range } = this.props;
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

        if (this.state.Isdata === true) {
            return (
                <Fragment>
                    <div className='report-loading inreports'>
                        {/* <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            loading={true||this.state.isloading}
                        /> */}
                        <DonutLoader isLoading={this.state.isloading} />
                    </div>
                    {submit &&
                        this.filterOnRange(range[0], data).map((row, index) => {
                            return (
                                <Fragment key={`numberslotsFrag${index}`}>
                                    <TableRow key={`numberslotsrow${index}`}>
                                        <TableCell
                                            colSpan={3}
                                            key={`numberslotscol${index}`}
                                            className={classes.tableCell}
                                        >
                                            {row.totalSlots}
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            )
                        })
                    }
                </Fragment>
            )
        }
        else {
            return (
                <Fragment>
                    <div className='report-loading inreports'>
                        {/* <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            loading={this.state.isloading}
                        /> */}
                        <DonutLoader isLoading={this.state.isloading} />

                    </div>
                    {submit && data && range.length > 0 &&
                        this.filterOnRange(range[0], data).map((row, index) => {
                            return (
                                <Fragment>
                                    <TableRow key={`numberslotsrow${index}`}>
                                        <TableCell
                                            colSpan={3}
                                            key={`numberslotscol${index}`}
                                            className={classes.tableCell}>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            )
                        })
                    }
                </Fragment>
            )
        }
    }
}

export default withStyles(styles)(NumberSlots);
