import { getAllConditions, deleteCondition, editCondition } from "./utils";
import { useEffect, useState } from 'react'
import Emitter from "../../Common/emitterServices";
import DeleteWarning from "./deleteWarning";

const RenderConditions = () => {

    const [conditions, setConditions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [conditionId, setConditionId] = useState("");

    useEffect(() => {
        getAllConditions(setConditions);
    }, [])

    useEffect(() => {
        Emitter.once('conditions_changed', (event, arg) => {
            setIsModalOpen(false)
            getAllConditions(setConditions);
        });
        return () => {
            Emitter.off("conditions_changed")
        };
    });

    return (
        <div>
            <DeleteWarning isOpenModal={isModalOpen} conditionId={conditionId}
                setIsModalOpen={setIsModalOpen}
            />
            {(conditions || []).map((condition, index) =>
                <tr className="body_row" key={index}>
                    <td>{index + 1}.</td>
                    <td>{`${condition.description}`}</td>
                    <td>
                        <button onClick={(e) => { editCondition(e, condition) }}>
                            <i className="fa fa-edit"></i>
                        </button>
                        <button onClick={() => {
                            setIsModalOpen(true)
                            setConditionId(condition._id)
                        }}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            )}
        </div>
    )
}


export default RenderConditions;