
import React, { Component } from 'react';

import loadsStore from '../../../stores/loads-store';
import { DragSource } from 'react-dnd'
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'
import EditablePrice from './editablePrice'
import EditableRotationOrder from '../subComponents/EditableRotationOrder'

import { UncontrolledTooltip } from 'reactstrap';
import jumpersStore from '../../../stores/jumpers-store';
import {
  Button, Col, Row, Label, Badge
} from 'reactstrap';
const Types = {
  ITEM: 'JUMP'
};

const itemSource = {
  beginDrag(props) {
    const item = props
    return item
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    return props;
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class DraggableStaff extends Component {

  constructor(props) {

    super(props);
    this.setEditable = this.setEditable.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isModalOpen: false,
      editMode: false,
      jumpType: null,
      load: props.load,
    };
  }

  // setEditable() {
  //   this.setState({
  //     editMode: !this.state.editMode,
  //     jumpType: null
  //   });
  // }
  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    })
  }
  setEditable = (isCompleted) => {
    if (isCompleted) {
      // this.props.openModel({ isModal: true, editType: false })
      this.setState({
        isModalOpen: true
      })
    }
    else {
      this.setState({
        editMode: !this.state.editMode
      })
    }
  }

  checkForNegatives = (price) => {
    //console.log('check for negatives: ' + price);
    if (price != undefined) {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <span style={{ color: 'red' }}> {priceCell.value}</span>;
      }
      return <span style={{ color: '#00FF00' }}> {priceCell.value}</span>;;
    }
    else {
      return '$0.00';
    }
  }

  handleSubmit(loadNum, jumpId, jumperId, isToday) {
    this.setEditable();
    if (this.props.type === "observer") {
      console.log(jumperId);
      loadsStore.removeObserver(loadNum, jumperId);
    }
    else {
      if (this.props.isCompleted) {
        var jumpPostBody = {
          jumperIds: this.props.jump.jumpers.filter(data => data._id !== jumperId).map(x => { return { jumperId: x._id, price: x.price, block: x.block, blockId: x.blockId, gearPrice: x.gearPrice, gearType: x.gearType } }),
          jumpType: this.props.jump.jumpType,
          studentgearRentals: this.props.jump.studentgearRentals.filter(data => data !== jumperId),
          sportgearRentals: this.props.jump.sportgearRentals.filter(data => data !== jumperId),
        };

        if (isToday) {
          jumpPostBody.isToday = true
        }

        var jumpId = this.props.jump.jumpId;
        var loadId = this.props.loadId;

        this.props.addCompletedJumpers(loadId, jumpId, jumpPostBody);
      }
      else {
        loadsStore.removeJumper(loadNum, jumpId, jumperId);
      }
    }
  }

  approveJumper(loadNum, jumpId, jumperId) {
    this.setEditable();
    loadsStore.approveJumper(loadNum, jumpId, jumperId);
  }

  getJumperDetail(jumperId, type) {

    if (type.toLowerCase() === "tandem" || type.toLowerCase() === "ride along" || type.toLowerCase().includes("observer")) {

      if (this.state.selectedIds.length > 0) {
        var selectedIds = this.state.selectedIds.filter(id => id !== jumperId);
        var payload = { tandemIds: selectedIds };
        jumpersStore.joinTandems(jumperId, payload)
          .then(ret => {

            //alert('now geting daily activities');
            jumpersStore.getDailyTandemActivity(jumperId);
            this.setState({ selectedIds: [] });
          })
      }
      else {
        jumpersStore.getDailyTandemActivity(jumperId);
      }

    }
    else if (type.toLowerCase() === "experienced" || type.toLowerCase() === "aff") {
      jumpersStore.getDailyActivity(jumperId);
    }
  }

  render() {
    const { isDragging, connectDragSource, name, jumperId } = this.props;


    return connectDragSource(
      <div>
        <Button onClick={(e) => { e.preventDefault(); this.getJumperDetail(jumperId, "experienced"); }}
          className="draggable-staff-name-btn">
          {/* <a  onClick={(e) => { e.preventDefault(); this.getJumperDetail(jumperId, "experienced"); }}> */}
          {name}
          {/* </a> */}
        </Button>
        {loadsStore.getOvSeparateCheckinSetting() ?
          <EditableRotationOrder jumperId={jumperId} type={this.props.type} orderNumber={this.props.orderNumber} />
          : null}
      </div>
    );
  }
}
const mstp = state => {
  return {
    state
  }
}
const mdtp = dispatch => {
  return {
    addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
  }
}
export default connect(mstp, mdtp)(DragSource(Types.ITEM, itemSource, collect)(DraggableStaff)
)
