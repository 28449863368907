/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, ModalFooter, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
class AdminModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            //jumpType : null,
            //affLevel: null,
            //load: props.load       
        };
        //this.gearIds = [];
    }

    render() {
        const { primaryButtonText, secondayButtonText, onprimaryButtonClick, onsecondaryButtonClick, addClass } = this.props;
        return (
            <React.Fragment>
                <Modal id="modal-main" isOpen={this.props.isModalOpen} >
                    <ModalHeader className={addClass} style={{ paddingLeft: '42%' }}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default AdminModal