import React from 'react';

export default function CreateJumpBtn({ type, text, createJump, loadNum, disabled }) {

    const callCreateJump = () => {
        if (type = "funjump") {
            createJump("centercard" + loadNum)
        }
        else {
            createJump();
        }
    }
    return (
        <div
            className="jump-create"
            onClick={callCreateJump}
            disabled={disabled}
        >
            <i className="fa fa-plus" />
            <strong>{text}</strong>
        </div>
    );
}
