/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import jumpersStore from '../../../stores/jumpers-store';
import AccountApi from '../../../services/accountapi';

import {
    Card, CardImg, CardText, CardBody, CardHeader, CardFooter,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';
import EditJumper from './editJumper';
import AddCard from './addCard'
import DeleteJumper from './deleteJumper';
import jumperStore from '../../../stores/jumpers-store';
import { ClipLoader } from 'react-spinners';
import DatePicker from "react-datepicker";
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import JumperApi from '../../../services/jumpersapi';
import reservationStore from '../../../stores/reservation-store';
import Api from '../../../services/reservationapi';
import FileSaver from 'file-saver';
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
// import NabVelocity from "react-nab-velocity-checkout";
import NabVelocity from '../../sub-components/nab/nabVelocity';
import RemoveCard from './deleteCard';
import loadsStore from '../../../stores/loads-store';
import WaiverDetail from '../../accounts/modals/waiverDetail';
import ForteACH from '../../sub-components/forteAch/forteAch';
import { DateRangePicker } from 'react-dates';
import { clippingParents } from '@popperjs/core';

var listStyle = { display: 'inline-block', marginRight: '5px', backgroundColor: '#a0c2df', fontSize: '12px', padding: '3px', borderRadius: '5px', marginBottom: '1px' }

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 30
    }),
    menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
        height: 730
    }),
    menuList: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        padding: 20,
        minHeight: 700
    })
};

class RatingModal extends Component {


    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.handleExpiryChange = this.handleExpiryChange.bind(this);
        this.handleWaiverChange = this.handleWaiverChange.bind(this);
        this.handleChargeSelect = this.handleChargeSelect.bind(this);
        this.handleDobChange = this.handleDobChange.bind(this);
        this.handleGearChange = this.handleGearChange.bind(this)
        this.handleEditedInfo = this.handleEditedInfo.bind(this)
        this.state = {
            isModalOpen: false,
            items: [],
            IsEdit: false,
            isloading: false,
            isPayOutCharge: false,
            uspaExpiry: '',
            WaiverDate: '',
            dob: '',
            reserveRepackDate: '',
            jumperOptions: [],
            adminDefaultOptions: [],
            chargeTo: '',
            transactionId: '',
            cardArray: [],
            accountArray: [],
            isGearModal: false,
            date: new Date,
            gearInfo: '',
            gearData: []
        }
        this.handleJumpersLoaded = this.handleJumpersLoaded.bind(this);

        this.jumperEvents = [
            { name: jumpersStore.getEventPrefix('jumperUpdated'), func: this.handleJumperUpdated },
            { name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded },
            { name: jumpersStore.getEventPrefix('deleteEvent'), func: this.handleDelete },
            //{ name: jumpersStore.getEventPrefix('deleteCardEvent'), func: this.handleDeleteCard }
        ];
    }
    // jumperUpdated

    handleDelete = (result) => {
        console.log("Deleted", result);
        setTimeout(() => {
            this.setState({
                isModalOpen: !this.state.isModalOpen,
            });
        }, 4000);
    }

    handleDeleteCard = (data) => {
        jumpersStore.loadAllJumpers();
        this.setState({
            cardArray: data.cardsOnFile
        })
    }

    toggleModal(e) {
        console.log('in toggleModal');
        this.props.closeModal(false)
        this.setState({
            isModalOpen: !this.state.isModalOpen,

        }, () => {
            if (!this.state.isModalOpen) {
                console.log('in here')
                this.setState({
                    uspaExpiry: '',
                    WaiverDate: '',
                    dob: '',
                    reserveRepackDate: ''
                })
            }
            else {
                const { uspaExpiry, waiverDate, reserveRepackDate, dob } = this.props.jumperValue || {};
                console.log('toggling modal');
                console.log(dob);
                this.setState({
                    uspaExpiry, WaiverDate: waiverDate, reserveRepackDate, dob
                })
            }
        });
    }

    componentDidMount = () => {
        if (this.props?.jumperValue?.gear) {
            this.setState({ gearData: this.props?.jumperValue?.gear })
        }
        //console.log('in components did mount accounts');
        this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
        var options = jumpersStore.getJumperOptions();
        this.setState({ jumperOptions: options, adminDefaultOptions: options.slice(0, 50) });
        const { uspaExpiry, waiverDate, reserveRepackDate, dob } = this.props.jumperValue || {};

        this.setState({
            uspaExpiry, WaiverDate: waiverDate, reserveRepackDate, dob
        })

        var ratings = this.props?.jumperValue?.ratings;

        let ratingArray = [
            { text: 'A', checked: false, ratingNumber: '' },
            { text: 'B', checked: false, ratingNumber: '' },
            { text: 'C', checked: false, ratingNumber: '' },
            { text: 'D', checked: false, ratingNumber: '' },
            { text: 'TANDEM INSTRUCTOR', checked: false, ratingNumber: '' },
            { text: 'AFF INSTRUCTOR', checked: false, ratingNumber: '' },
            { text: 'PILOT', checked: false, ratingNumber: '' },
            { text: 'COACH', checked: false, ratingNumber: '' },
            { text: 'SOLO', checked: false, ratingNumber: '' },
            { text: 'AFF STUDENT', checked: false, ratingNumber: '' },
            { text: 'TANDEM STUDENT', checked: false, ratingNumber: '' },
            { text: 'VIDEOGRAPHER', checked: false, ratingNumber: '' },
            { text: 'PROVISIONAL', checked: false, ratingNumber: '' },
            { text: 'PACKER', checked: false, ratingNumber: '' }
        ];

        let newItems = ratingArray.slice();
        for (var item in ratings) {
            var index = newItems.map(e => e.text).indexOf(ratings[item].rating)
            ratingArray[index].checked = !ratingArray[index].checked;
            ratingArray[index].ratingNumber = ratings[item].number;
            ratingArray[index].expiry = ratings[item].expiry;
            ratingArray[index].isUspaVerified = ratings[item].isUspaVerified;

        }

        this.setState({
            items: ratingArray,
            cardArray: this.props.cardArray || this.props.jumperValue.cardsOnFile
        })

        // if (uspaExpiry !== undefined && uspaExpiry !== null) {
        //     var localDate = new Date(uspaExpiry);
        //     this.setState({
        //         uspaExpiry: localDate.getFullYear() + "-" + this.formatDate(localDate.getMonth() + 1) + "-" + this.formatDate(localDate.getDate())
        //     })
        // }

        // if (waiverDate !== undefined && waiverDate !== null) {
        //     var localDate1 = new Date(waiverDate);
        //     this.setState({
        //         WaiverDate: localDate1.getFullYear() + "-" + this.formatDate(localDate1.getMonth() + 1) + "-" + this.formatDate(localDate1.getDate())
        //     })

        // }
        // if (reserveRepackDate !== undefined && reserveRepackDate !== null) {
        //     var localDate2 = new Date(reserveRepackDate);
        //     this.setState({
        //         reserveRepackDate: localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate())
        //     })
        // }
    }

    componentWillUnmount = () => {
        this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
    }

    filterJumpers = (inputValue, jumperOptions) => {
        //console.log("in filterJumpers");
        if (inputValue) {
            //console.log(inputValue);
            var filteredOptions = jumperOptions.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase()));
            ////console.log(filteredOptions);
            if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
            else return filteredOptions;
        }
        //console.log('no inputValue');
        return jumperOptions.slice(0, 30);
    };

    promiseOptions = (inputValue, jumperOptions) => {
        //console.log('in pomiseOptions');
        //console.log(this.inputValue);
        return new Promise(resolve => {
            resolve(this.filterJumpers(this.inputValue, jumperOptions));
        });
    }


    handleInputChange = (newValue) => {
        //console.log('handleInputChange');

        //const inputValue = newValue.replace(/\W/g, '');
        ////console.log(inputValue);

        //this.promiseOptions(newValue, jumperOptions);
        this.inputValue = newValue;
        //return inputValue;
    };

    Option = (props) => {
        //console.log(props);
        return (
            <div className="row">
                <div className="col-12">
                    <components.Option {...props} />
                </div>
            </div>
        );
    }

    handleJumpersLoaded(jumperOptions) {
        //console.log('in handle jumpers loaded in accounts');
        var adminDefaultOptions = jumperOptions.slice(0, 50);
        //alert('handle Jumpers Loaded');
        this.setState({
            jumperOptions: jumperOptions,
            adminDefaultOptions: adminDefaultOptions
        });
    }


    toggleEditModal() {
        //alert('toggle edit modal');
        this.setState({ IsEdit: !this.state.IsEdit })
    }

    handleChargeSelect = (val) => {
        if (val.value !== undefined) {
            ////alert(JSON.stringify(val.value));
            // this.onJumperChanged(val.value);
            // jumpersStore.getDailyActivity(val.value._id);
            this.setState({
                chargeTo: val.value._id
            });
            //console.log(JSON.stringify(val.value));
        }
    }

    updateManifestNumber(id) {
        jumperStore.updateManifestNumber(id);
    }

    handleSubmit(values, jumperDetails) {
        console.log("vlass====>", values, jumperDetails)
        this.setState({ isloading: true })

        //console.log("in handle edit jumper");

        // this.toggleModal();
        var id = jumperDetails._id;
        var payload = {};
        let rating = this.state.items.filter(item => item.checked);
        let ratingArray = [];

        for (var item in rating) {
            ratingArray.push({ "rating": rating[item].text, "number": rating[item].ratingNumber, "expiry": rating[item].expiry, "isUspaVerified": rating[item].isUspaVerified });
        }

        if (values.isRentingGear != undefined) {
            payload.isRentingGear = values.isRentingGear;
        }
        else {
            payload.isRentingGear = jumperDetails.isRentingGear;
        }

        if (values.isLO != undefined) {
            payload.isLO = values.isLO;
        }
        else {
            payload.isLO = jumperDetails.isLO;
        }

        if (values.isStaff != undefined) {
            payload.isStaff = values.isStaff;
        }
        else {
            payload.isStaff = jumperDetails.isStaff;
        }

        if (values.isWaiverReviewed != undefined) {
            payload.isWaiverReviewed = values.isWaiverReviewed;
        }
        else {
            payload.isWaiverReviewed = jumperDetails.isWaiverReviewed;
        }

        if (values.isPaperWaiver != undefined) {
            payload.isPaperWaiver = values.isPaperWaiver;
        }
        else {
            payload.isPaperWaiver = jumperDetails.isPaperWaiver;
        }

        if (values.firstname != undefined) {
            payload.firstname = values.firstname;
        }
        else {
            payload.firstname = jumperDetails.firstname;
        }

        if (values.nickname != undefined) {
            payload.nickname = values.nickname;
        }

        if (values.lastname != undefined) {
            payload.lastname = values.lastname;
        }

        else {
            payload.lastname = jumperDetails.lastname;
        }

        if (values.siteWaiverId != undefined) {
            payload.siteWaiverId = values.siteWaiverId;
        }
        else {
            payload.siteWaiverId = jumperDetails.siteWaiverId;
        }

        if (values.uspaMemberNumber != undefined) {
            payload.uspaMemberNumber = values.uspaMemberNumber;
        }
        else {
            payload.uspaMemberNumber = jumperDetails.uspaMemberNumber;
        }

        if (values.manifestNumber != undefined) {
            payload.manifestNumber = values.manifestNumber;
        }
        else {
            payload.manifestNumber = jumperDetails.manifestNumber;
        }



        if (this.state.WaiverDate != undefined) {
            payload.waiverDate = this.state.WaiverDate;
        }


        if (this.state.uspaExpiry != undefined) {
            payload.uspaExpiry = this.state.uspaExpiry;
        }

        if (this.state.dob !== undefined) {
            payload.dob = this.state.dob;
        }

        if (values.email != undefined) {
            payload.email = values.email;
        }

        if (values.homePhone != undefined) {
            payload.homePhone = values.homePhone;
        }

        if (values.mobilePhone != undefined) {
            payload.mobilePhone = values.mobilePhone;
        }

        if (values.noPayOrCharge != undefined) {
            payload.noPayOrCharge = values.noPayOrCharge;
        }
        else {
            payload.noPayOrCharge = values.noPayOrCharge;
        }

        if (values.multiManifest != undefined) {
            payload.multiManifest = values.multiManifest;
        }
        else {
            payload.multiManifest = values.multiManifest;
        }

        if (values.discountRate != undefined) {
            payload.discountRate = values.discountRate;
        }
        else {
            payload.discountRate = values.discountRate;
        }

        if (values.llcPay != undefined) {
            payload.llcPay = values.llcPay;
        }
        else {
            payload.llcPay = values.llcPay;
        }

        if (this.state.chargeTo != undefined && this.state.chargeTo != '') {
            payload.chargeTo = this.state.chargeTo;
        }
        if (this.state.reserveRepackDate != undefined && this.state.reserveRepackDate != '') {
            payload.reserveRepackDate = this.state.reserveRepackDate;
        }


        payload.ratings = ratingArray;
        payload.gear = this.state.gearData

        jumperStore.updateJumper(id, payload);


    }



    handleJumperUpdated = (jumper) => {
        //alert(JSON.stringify(jumper));
        this.setState({ isloading: false, IsEdit: false, uspaExpiry: jumper.uspaExpiry });
    }

    renderCheckbox = () => {
        // alert("CheckBOxRating"+ JSON.stringify(this.state.items))
        return this.state.items.map((item, index) => {
            //return newItems.map((item, index) => {
            var ratingName = item.text + "_" + index;
            var ratingText = "txt_" + item.text + "_" + index;
            return (
                <Row className="form-group" key={index}>
                    <Col sm={1}>
                        <input type="checkbox" id={ratingName} name={ratingName} onChange={this.onToggle.bind(this, index)} defaultChecked={item.checked} />
                    </Col>

                    <Col sm={4}>
                        <label style={{ marginLeft: "10px" }}>{item.text}</label>
                    </Col>
                    <Col sm={7}>
                        <input type="text" id={ratingText} name={ratingText} className="rating-input"
                            style={{ width: "100% !important" }} onChange={this.handleChange.bind(this, index)} value={item.ratingNumber} />
                    </Col>
                </Row>
            )
        })
    }

    onToggle(index, e) {
        let newItems = this.state.items.slice();
        newItems[index].checked = !newItems[index].checked
        this.setState({
            items: newItems
        })
        //console.log("Checkeditem" + JSON.stringify(this.state.items.filter(item => item.checked)));
    }

    handleChange(index, e) {
        let newItems = this.state.items.slice();
        newItems[index].ratingNumber = e.target.value
        this.setState({
            items: newItems
        })
        //console.log("Checkeditem" + JSON.stringify(this.state.items.filter(item => item.checked)));
    }

    getJumperName(jumperId) {
        if (jumperId != '' && jumperId != undefined) {
            let jumperInfo = jumpersStore.getJumperById(jumperId);
            var JumperName;
            if (jumperInfo) {
                JumperName = jumperInfo.firstname + ' ' + jumperInfo.lastname;
            }
            else {
                JumperName = "something went wrong";
            }
            return JumperName;
        }
        else {
            return '';
        }
    }

    componentWillReceiveProps(newProps) {

        // this.setState({
        //     items: ratingArray,
        //     cardArray: newProps.cardArray
        // })

        //const { ratings } = newProps;
        // var ratings = newProps.jumperValue.ratings;

        // let ratingArray = [
        //     { text: 'A', checked: false, ratingNumber: '' },
        //     { text: 'B', checked: false, ratingNumber: '' },
        //     { text: 'C', checked: false, ratingNumber: '' },
        //     { text: 'D', checked: false, ratingNumber: '' },
        //     { text: 'TANDEM INSTRUCTOR', checked: false, ratingNumber: '' },
        //     { text: 'AFF INSTRUCTOR', checked: false, ratingNumber: '' },
        //     { text: 'PILOT', checked: false, ratingNumber: '' },
        //     { text: 'COACH', checked: false, ratingNumber: '' },
        //     { text: 'SOLO', checked: false, ratingNumber: '' },
        //     { text: 'AFF STUDENT', checked: false, ratingNumber: '' },
        //     { text: 'TANDEM STUDENT', checked: false, ratingNumber: '' },
        //     { text: 'VIDEOGRAPHER', checked: false, ratingNumber: '' },
        //     { text: 'PROVISIONAL', checked: false, ratingNumber: '' },
        //     { text: 'PACKER', checked: false, ratingNumber: '' }
        // ];


        // // const { ratings = [] } = this.props.jumperValue || {};
        // //let newItems = this.state.items.slice();
        // let newItems = ratingArray.slice();
        // console.log(newItems,"newItems")
        // for (var item in ratings) {
        //     var index = newItems.map(e => e.text).indexOf(ratings[item].rating)
        //     ratingArray[index].checked = !ratingArray[index].checked;
        //     ratingArray[index].ratingNumber = ratings[item].number;
        //     ratingArray[index].expiry = ratings[item].expiry;
        //     ratingArray[index].isUspaVerified = ratings[item].isUspaVerified;

        // }
        // console.log(ratingArray,"ratingArrayratingArrayratingArray")
        // this.setState({
        //     items: ratingArray,
        //     cardArray: newProps.cardArray
        //     //cardArray:newProps.jumperValue.cardsOnFile
        // })
    }

    checkForNegatives = (price) => {
        //console.log('check for negatives: ' + price);
        if (price != undefined) {
            let priceCell = (price < 0) ?
                {
                    value: `($${Math.abs(price)})`
                } :
                {
                    value: `$${price}`
                }
            if (price < 0) {
                return <span style={{ color: 'red' }}> {priceCell.value}</span>;
            }

            return <span>{priceCell.value}</span>;
        }
        else {
            return '';
        }
    }

    handleExpiryChange(date) {
        this.setState({
            uspaExpiry: date
        });
    }
    handleDobChange(date) {
        console.log(date, 'date');
        this.setState({
            dob: date
        });
    }

    handleWaiverChange(date) {
        this.setState({
            WaiverDate: date
        });
    }
    handleRepackDateChange = date => {
        this.setState({
            reserveRepackDate: date
        })
    }

    formatDate(input) {
        if (input.toString().length == 1) {
            return '0' + input;
        }
        else {
            return input;
        }
    }

    handleCard = () => {
        if (window.ENV?.processorInfo.processor == "nab") {
            document.getElementsByClassName('velocity-button')[0].click();
        }
        else if (window.ENV?.processorInfo.processor == "cardConnect") {

            window.initializeCardConnect("rating");
            var modal = document.getElementById("myModalCardConnect");
            modal.style.display = "block";
            window.reservationComponent = this;
        }
        else if (window.ENV?.processorInfo.processor == "transnational") {
            let role = localStorage.getItem("role");
            let publicKey = window.ENV?.processorInfo.publicKey;
            if (role === "school") {
                publicKey = window.ENV?.schoolProcessorInfo?.publicKey;
            }
            window.transactionUI("rating", publicKey);
            var modal = document.getElementById("myModal");

            // Get the button that opens the modal
            var btn = document.getElementById("myBtn");

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks the button, open the modal
            // btn.onclick = function () {˚
            modal.style.display = "block";
            // }

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
            }
            window.reservationComponent = this;

            // When the user clicks anywhere outside of the modal, close it
            window.onclick = function (event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            }
        }
    }

    handleDropzone = () => {
        if (window.ENV?.dizioProcessorInfo?.processor === "transnational") {
            let role = localStorage.getItem("role");
            let publicKey = window.ENV?.dizioProcessorInfo?.publicKey;
            window.transactionUI("rating", publicKey, "ach")
            var modal = document.getElementById("myModal");
            var span = document.getElementsByClassName("close")[0];

            modal.style.display = "block";

            span.onclick = function () {
                modal.style.display = "none";
            }

            window.reservationComponent = this;

            window.onclick = function (event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            }
        }
    }

    handleDizioAchResponse = (r) => {
        console.log(r.data);
        var id = this.props.jumperValue._id;

        if (window.ENV?.processorInfo.processor == 'transnational' && r.status === "success") {
            var customerId = r.data.id //e.target.getAttribute("data-accountDataToken");
            var TransactionId = r.data.id;//e.target.getAttribute("data-tid");
            var tokenBody = {
                accountNumber: r.data.data.customer.payments.ach[0].masked_account_number,
                accountType: r.data.data.customer.payments.ach[0].account_type,
                payment_method_id: r.data.data.customer.payments.ach[0].id,
                customerId: r.data.id // for
            }

            this.setState({
                transactionId: TransactionId
            })

            jumperStore.addCardOnFileUpdate(id, tokenBody, 'dizio ach');
        }
    }
    handleCardResponse = (r) => {
        //used in index.html file

        var id = this.props.jumperValue._id;
        const { transactionId } = this.state;

        if (window.ENV?.processorInfo.processor == 'nab' && r.Status === "Successful" && transactionId !== r.TransactionId) {
            var MaskedPAN = r.MaskedPAN;//e.target.getAttribute("data-maskedPAN");
            var CardType = r.CardType;//e.target.getAttribute("data-cardType");
            var PaymentAccountDataToken = r.PaymentAccountDataToken; //e.target.getAttribute("data-accountDataToken");
            var TransactionId = r.TransactionId;//e.target.getAttribute("data-tid");

            var tokenBody = {
                cardNumLast4: MaskedPAN,
                cardType: CardType,
                token: PaymentAccountDataToken
            }

            this.setState({
                transactionId: TransactionId
            })

            jumperStore.addCardOnFileUpdate(id, tokenBody);
            // jumpersStore.loadAllJumpers();
            // var body = JumperApi.cancelAuthorizeCaptureBody(TransactionId);
            var body = {
                TransactionId: TransactionId
            }

            var response = Api.cancelAuthorizeAndCapture(body).then(this.processUndoResponse);
            swal({
                title: "Success",
                text: "Card Added Successfully",
                icon: "success",
                dangerMode: false,
            })
        }
        if (window.ENV?.processorInfo.processor == 'transnational' && r.status === "success") {
            var MaskedPAN = r.data.payment_method.card.masked_card;//e.target.getAttribute("data-maskedPAN");
            var CardType = r.data.payment_method.card.card_type;//e.target.getAttribute("data-cardType");
            var cardId = r.data.payment_method.card.id;
            var customerId = r.data.id //e.target.getAttribute("data-accountDataToken");
            var TransactionId = r.data.id;//e.target.getAttribute("data-tid");
            var tokenBody = {
                cardNumLast4: MaskedPAN,
                cardType: CardType,
                cardId: cardId,
                customerId: customerId
            }

            this.setState({
                transactionId: TransactionId
            })

            jumperStore.addCardOnFileUpdate(id, tokenBody);
        }
        if (window.ENV?.processorInfo.processor == 'cardConnect' && r) {
            // console.log("🚀 ~ file: ratingModal.js ~ line 647 ~ RatingModal ~ r", r)
            MaskedPAN = "xxxxxxxxxx" + r.token.slice(r.token.length - 4);//e.target.getAttribute("data-maskedPAN");
            CardType = r.accttype;//e.target.getAttribute("data-cardType");
            customerId = r.profileid //e.target.getAttribute("data-accountDataToken");
            var tokenBody = {
                cardNumLast4: MaskedPAN,
                cardType: CardType,
                customerId: customerId
            }
            // no zipcode in jumpers
            jumperStore.addCardOnFileUpdate(id, tokenBody);
        }
        else if (transactionId === r.TransactionId) {
            console.log('payment Done');
        }
        else {
            console.log('Error');
            // swal({
            //     title: "Error",
            //     text: "Something failed, please try again",
            //     icon: "error",
            //     dangerMode: true,
            // });
        }
    }

    processUndoResponse = (r) => {
        console.log("refund====>", r)
        if (r.status !== undefined && r.status === 200) {
            console.log('Refund Successfully');
        }
    }

    handleGear = () => {
        this.setState({ isGearModal: !this.state.isGearModal })
    }

    handleDateChange = (data) => {
        this.setState({ date: data })
    }

    handleGearChange = (e) => {
        this.setState({ gearInfo: e?.target.value })
    }

    submit = () => {
        let id = this.props.jumperValue._id
        let gearData = [...this.props.jumperValue.gear];
        gearData = gearData.map(item => {
            delete item._id;
            item.repackDate = new Date(item.repackDate)
            return item;
        });
        let body = {}
        let info = {
            gearInfo: this.state.gearInfo,
            repackDate: this.state.date
        }
        gearData.push(info)
        body.gear = gearData
        jumperStore.updateJumper(id, body).then(r => {
            this.setState({ isGearModal: false })
            this.setState({ gearInfo: '' })
            this.setState({ date: new Date })
            this.setState({ gearData: r.gear })
        })
    }


    renderGearModal = () => {
        console.log('about to render gear modal');
        return <div className="gearModal">
            <div className="modal-header">
                <h5 class="modal-title1">Add Gear</h5>
                <span class="close" onClick={this.handleGear}>&times;</span>
            </div>
            <div className="modal-body">
                <div>Rig Info</div>
                <div className="inputBox"><input value={this.state.gearInfo} type="text" onChange={(e) => this.handleGearChange(e)}></input></div>
                <div>Date</div>
                <div className="inputBox">
                    <DatePicker
                        selected={this.state.date === null ? null : new Date(this.state.date)}
                        onChange={this.handleDateChange}
                        //className="form-control"
                        name="date"
                        model=".date"
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button type="submit" forte-api-login-id="" forte-callback-success="onTokenCreated"
                    forte-callback-error="onTokenFailed" onClick={this.submit}>Submit</button>
            </div>
        </div>
    }

    renderGearInfo = () => {
        let gear = this.state.gearData;
        return gear?.map((v, i) => {
            return <div className="form-group" key={i} style={{ marginBottom: '20px' }}>
                {v.gearInfo} : {(new Date(v.repackDate)).toLocaleDateString()}
            </div>
        })
    }

    handleEditedInfo = (e, key, index) => {
        let gear = this.state.gearData;
        if (key === 'gearInfo') {
            gear[index][key] = e?.target?.value;
        } else {
            gear[index][key] = e;
        }
        this.setState({ gearData: gear })
    }

    deleteGear = (index) => {
        let gear = [...this.state.gearData];
        gear.splice(index, 1)
        this.setState({ gearData: gear })
    }

    editGear = () => {
        console.log('in edit gear');
        let gear = [...this.state.gearData]
        return gear?.length > 0 && gear?.map((item, ind) => {
            return <div key={ind}>
                <input type="text" onChange={(e) => this.handleEditedInfo(e, 'gearInfo', ind)} value={item.gearInfo} className="editGear" ></input>
                <DatePicker
                    selected={item.repackDate === null ? null : new Date(item.repackDate)}
                    onChange={(e) => this.handleEditedInfo(e, 'repackDate', ind)}
                    className="editPicker"
                    name="editdate"
                    model="editdate"
                    style={{ marginRight: 10, marginBottom: 10, borderRadius: 5, border: '#fff', padding: '0px 5px', height: 30 }}
                />
                <span style={{ cursor: 'pointer', fontSize: 22 }} onClick={this.deleteGear.bind(this, ind)}>x</span>
            </div>
        })
    }
    printWaiverOneSheet = (waiverId, name) => {
        jumpersStore.printWaiverOneSheet(waiverId)
            .then(data => {
                FileSaver.saveAs(data, `${name}-waiver-data.pdf`);
            })
    }
    render() {
        console.log(this.state.IsEdit, "aslkdhjkas")
        const { cardArray } = this.state;
        const override = `
                 display: block;
                 margin: 0 auto;
                 border-color: red;
             `;

        var cardsRender = '';

        const jumperVal = this.props?.jumperValue;
        if (this.props?.jumperValue?.cardsOnFile !== undefined) {
            //var cardsRender = this.props.jumperValue.cardsOnFile.map(cardOnFile => {
            var cardsRender = this.props?.jumperValue?.cardsOnFile?.filter(r => r !== null).map(cardOnFile => {
                // var cardNumber = "XXXX-XXXX-XXXX-" + cardOnFile.cardNumLast4;
                var cardNumber = cardOnFile.cardNumLast4;
                var cardType = cardOnFile.cardType;
                return (
                    <Row style={{ marginBottom: '20px' }}>
                        <Col md={{ size: 4 }}>Card Number:  </Col>
                        <Col md={{ size: 7 }}>{cardNumber} {cardType}</Col>
                        <Col md={{ size: 1 }}><RemoveCard jumperId={this.props.jumperValue._id} cardId={cardOnFile._id} jumperVal={jumperVal} /></Col>
                    </Row>
                );
            })
        }
        var accountRender = '';
        if (this.props?.jumperValue?.accountOnFile !== undefined) {
            var accountRender = this.props.jumperValue?.accountOnFile.map(cardOnFile => {
                // var cardNumber = "XXXX-XXXX-XXXX-" + cardOnFile.cardNumLast4;
                var accountNumber = cardOnFile.AccountNumber;
                return (
                    <Row style={{ marginBottom: '20px' }}>
                        <Col md={{ size: 4 }}>Account Number: </Col>
                        <Col md={{ size: 7 }}>{accountNumber}</Col>
                        <Col md={{ size: 1 }}><RemoveCard jumperId={this.props.jumperValue._id} cardId={cardOnFile._id} type="account" jumperVal={jumperVal} /></Col>
                    </Row>
                );
            })
        }

        var dizioAchRender = '';
        if (this.props?.jumperValue?.dizioAchOnFile !== undefined) {
            var dizioAchRender = this.props.jumperValue?.dizioAchOnFile.map(cardOnFile => {
                // var cardNumber = "XXXX-XXXX-XXXX-" + cardOnFile.cardNumLast4;
                var accountNumber = cardOnFile.accountNumber;
                return (
                    <Row style={{ marginBottom: '20px' }}>
                        <Col md={{ size: 4 }}>dizio charge account: </Col>
                        <Col md={{ size: 7 }}>{accountNumber}</Col>
                        <Col md={{ size: 1 }}><RemoveCard jumperId={this.props.jumperValue._id} cardId={cardOnFile._id} type="account" /></Col>
                    </Row>
                );
            })
        }


        // alert("ratingJUmper:"+ JSON.stringify(this.props));
        //console.log("ratingProps");
        //console.log(JSON.stringify(this.props));
        const { siteWaiverId, nickname = '', firstname = '', lastname = '', isUspaVerified, isRentingGear, isStaff, isLO, ratings, homePhone, mobilePhone, email, uspaMemberNumber, manifestNumber, cardsOnFile, currentBalance, noPayOrCharge, multiManifest, llcPay, discountRate, chargeTo, lastFunJumpDate, lastTIJumpDate, lastSIJumpDate, lastCoachJumpDate, lastStudentJumpDate, gear, isWaiverReviewed, isPaperWaiver } = this.props.jumperValue || {};

        if (this.state.jumperValue) {
            const { siteWaiverId, nickname = '', firstname = '', lastname = '', isStaff, isRentingGear, isLO, isUspaVerified, ratings, homePhone, mobilePhone, email, uspaMemberNumber, manifestNumber, cardsOnFile, currentBalance, noPayOrCharge, multiManifest, llcPay, discountRate, chargeTo, lastFunJumpDate, lastTIJumpDate, lastSIJumpDate, lastCoachJumpDate, lastStudentJumpDate, isWaiverReviewed, isPaperWaiver } = this.state.jumperValue || {};
        }

        var waiverDate = this.props?.jumperValue?.waiverDate;
        if (this.state.WaiverDate !== '') {
            waiverDate = this.state.WaiverDate;
        }

        var uspaExpiry = this.props?.jumperValue?.uspaExpiry;
        if (this.state.uspaExpiry !== '') {
            uspaExpiry = this.state.uspaExpiry;
        }

        var dob = this.props?.jumperValue?.dob;
        if (this.state.dob !== '') {
            dob = this.state.dob;
        }
        else if (!isNaN(Date.parse(dob))) {
            dob = this.props?.jumperValue?.dob;
        }
        else {
            dob = null;
        }

        var reserveRepackDate = this.props?.jumperValue?.reserveRepackDate;
        if (this.state.reserveRepackDate !== '') {
            reserveRepackDate = this.state.reserveRepackDate;
        }

        var expiryValue = '';
        var waiverValue = '';
        var reserveRepackvalue = '';
        var dobValue = '';

        var lastFunJumpValue = '';
        var lastTIJumpValue = '';
        var lastSIJumpValue = '';
        var lastStudentJumpValue = '';
        var lastCoachJumpValue = '';

        if (uspaExpiry !== undefined && uspaExpiry !== null) {
            var localDate = new Date(uspaExpiry);
            expiryValue = localDate.getFullYear() + "-" + this.formatDate(localDate.getMonth() + 1) + "-" + this.formatDate(localDate.getDate());
        }
        else {
            expiryValue = null;
        }
        if (waiverDate !== undefined && waiverDate !== null) {
            var localDate1 = new Date(waiverDate);
            waiverValue = localDate1.getFullYear() + "-" + this.formatDate(localDate1.getMonth() + 1) + "-" + this.formatDate(localDate1.getDate());
        }
        else {
            waiverValue = null
        }

        if (reserveRepackDate !== undefined && reserveRepackDate !== null) {
            var localDate2 = new Date(reserveRepackDate);
            reserveRepackvalue = localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate());
        }
        else {
            reserveRepackvalue = null
        }

        if (dob !== undefined && dob !== null) {
            var localDate2 = new Date(dob);
            dobValue = localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate());
        }
        else {
            dobValue = null;
        }

        if (lastFunJumpDate !== undefined && lastFunJumpDate !== null) {
            var localDate2 = new Date(lastFunJumpDate);
            lastFunJumpValue = localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate());
        }
        if (lastTIJumpDate !== undefined && lastTIJumpDate !== null) {
            var localDate2 = new Date(lastTIJumpDate);
            lastTIJumpValue = localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate());
        }
        if (lastSIJumpDate !== undefined && lastSIJumpDate !== null) {
            var localDate2 = new Date(lastSIJumpDate);
            lastSIJumpValue = localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate());
        }
        if (lastStudentJumpDate !== undefined && lastStudentJumpDate !== null) {
            var localDate2 = new Date(lastStudentJumpDate);
            lastStudentJumpValue = localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate());
        }
        if (lastCoachJumpValue !== undefined && lastCoachJumpDate !== null) {
            var localDate2 = new Date(lastCoachJumpDate);
            lastCoachJumpValue = localDate2.getFullYear() + "-" + this.formatDate(localDate2.getMonth() + 1) + "-" + this.formatDate(localDate2.getDate());
        }

        const CardButton = () => {
            return (
                <button className="btn cardButton" type="submit" style={{ marginLeft: '5px', padding: '0.15rem 0.35rem', marginBottom: '8px', cursor: 'pointer' }}>
                    <i className="fa fa-plus"></i>Card
                </button>
            )
        }

        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={true}
                    title={`Rating`}
                >

                    <div className='complete-loading'>
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={35}
                            color={'#fff'}
                            loading={this.state.isloading}
                        />
                    </div>
                    <div style={{ position: 'absolute', right: '40px', zIndex: '9999', cursor: 'pointer' }}>

                        {/* <EditJumper jumperDetail={this.props.jumperValue} /> */}
                        <span>
                            {((firstname?.toLowerCase() !== "hold" && firstname?.toLowerCase() !== "none") || null) && <i className="fa fa-edit" onClick={this.toggleEditModal} style={{ fontSize: '23px' }}></i>}
                        </span>

                        <span>
                            <ForteACH jumper={this.props.jumperValue} />
                        </span>

                        {/*<span> <AddCard jumperDetail={this.props.jumperValue} /> </span> */}
                        {/* <span> */}

                        <button className="btn cardButton jumper-add-button" type="submit" style={{ marginLeft: '5px', padding: '0.15rem 0.35rem', marginBottom: '8px' }} onClick={this.handleCard}>
                            <i className="fa fa-plus"></i>card
                        </button>

                        {firstname === "DIZIO" && lastname === "PAYEE" && <button className="btn cardButton jumper-add-button" type="submit" style={{ marginLeft: '5px', padding: '0.15rem 0.35rem', marginBottom: '8px' }} onClick={this.handleDropzone} >
                            <i className="fa fa-plus"></i> Dropzone ach
                        </button>}
                        <WaiverDetail jumperName={this.props.jumperValue?.firstname + " " + this.props.jumperValue?.lastname} siteWaiverId={this.props.jumperValue?.siteWaiverId} jumperId={this.props.jumperValue?._id} isTandemStudent={this.props.jumperValue?.isTandemStudent} isRatingModel={true} />
                        {
                            window.ENV?.arizona ?
                                <button className="btn cardButton jumper-add-button" type="submit" style={{ marginLeft: '5px', padding: '0.15rem 0.35rem', marginBottom: '8px' }} onClick={() => this.printWaiverOneSheet(this.props.jumperValue?.siteWaiverId, this.props.jumperValue?.firstname + " " + this.props.jumperValue?.lastname)}>
                                    Print Waiver
                                </button> : null
                        }
                        <button className="btn cardButton jumper-add-button" type="submit" style={{ marginLeft: '5px', padding: '0.15rem 0.35rem', marginBottom: '8px' }} onClick={this.handleGear}>
                            <i className="fa fa-plus"></i> gear
                        </button>

                        {
                            window.ENV?.processorInfo.processor == "nab" &&
                            <span id="btn_AddCard" style={{ display: 'none' }}>
                                <NabVelocity
                                    amount={1}
                                    terminalProfileId={loadsStore.getNabHostedFormTerminalId()}
                                    onProcessPayment={this.handleCardResponse}
                                    text="Card" />
                            </span>
                        }
                        {/* <button id="btn_CardResponse" type="button" onClick={(e) => this.handleCardResponse(e)} style={{ display: 'none' }}></button></span> */}

                        <span style={{ position: 'absolute', top: '-3px', right: '10px' }}>
                            {((firstname?.toLowerCase() !== "hold" && firstname?.toLowerCase() !== "none") || null) && <DeleteJumper jumperDetail={this.props.jumperValue} closeModal={this.props.closeModal} />}
                        </span>

                    </div>
                    {this.state.isGearModal && this.renderGearModal()}
                    <LocalForm onSubmit={(values) => this.handleSubmit(values, this.props.jumperValue)}>

                        <Row className="form-group m-t-1-rem">
                            <Col md={{ size: 12 }}>

                                <div style={{ marginTop: this.state.IsEdit ? "28px" : 0, marginLeft: "20px", }}>
                                    {
                                        !this.state.IsEdit
                                            ?
                                            <h5>{firstname}  {lastname} - {firstname !== nickname ? nickname : null}</h5>
                                            : ''
                                    }
                                    <span>
                                        <i className="fa fa-refresh" onClick={() => jumpersStore.syncWithUspa(this.props.jumperValue._id)} style={{ fontSize: '17px' }}>sync with uspa</i>
                                    </span>
                                    <h6>{isRentingGear ?
                                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                                            renting gear
                                        </span>
                                        : null}
                                    </h6>
                                    <h6>{isStaff ?
                                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                                            staff
                                        </span>
                                        : null}</h6>
                                    <h6>{isLO ?
                                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                                            LO
                                        </span>
                                        : null}</h6>
                                    <h6>{noPayOrCharge ?
                                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                                            no Pay Or Charge
                                        </span>
                                        : null}
                                    </h6>
                                    <h6>{isWaiverReviewed ?
                                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                                            Waiver Reviewed
                                        </span>
                                        : null}
                                    </h6>
                                    <h6>{isPaperWaiver ?
                                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                                            Paper Waiver
                                        </span>
                                        : null}
                                    </h6>
                                    <h6>{multiManifest ?
                                        <span style={{ fontSize: '15px', backgroundColor: '#1b5ba1' }} className="badge">
                                            allow multiple manifest
                                        </span>
                                        : null}
                                    </h6>

                                    {this.state.IsEdit &&
                                        <Row>
                                            <Col md={4}>First Name</Col>
                                            <Col md={8}>
                                                <Control.input name="firstname" model=".firstname" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={firstname} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {this.state.IsEdit &&
                                        <Row>
                                            <Col md={4}>Nickname</Col>
                                            <Col md={8}>
                                                <Control.input name="nickname" model=".nickname" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={nickname} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {this.state.IsEdit &&
                                        <Row>
                                            <Col md={4}>Last Name</Col>
                                            <Col md={8}>
                                                <Control.input name="lastname" model=".lastname" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={lastname} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }



                                    {!this.state.IsEdit ?
                                        <Row>
                                            {email !== undefined ?
                                                <Col md={{ size: 8 }}>
                                                    <span style={{ marginRight: '4px' }}><i className="fa fa-ENV?.lope" aria-hidden="true"></i> </span>
                                                    {email}
                                                </Col>
                                                : ''
                                            }
                                        </Row>
                                        :
                                        <Row>
                                            <Col md={4}>Email</Col>
                                            <Col md={8}>
                                                <Control.input name="email" model=".email" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={email} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }
                                    {/* :
                                             null
                                     } */}
                                    {!this.state.IsEdit ?
                                        <Row>
                                            {homePhone !== undefined ?
                                                <Col md={{ size: 8 }}>
                                                    <span style={{ marginRight: '4px' }}><i className="fa fa-phone" aria-hidden="true"></i> </span>
                                                    {homePhone}
                                                </Col>
                                                : ''}
                                        </Row>
                                        :
                                        <Row>
                                            <Col md={4}>home Phone</Col>
                                            <Col md={8}>
                                                <Control.input name="homePhone" model=".homePhone" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={homePhone} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {/* {
                                         mobilePhone !== undefined ? */}

                                    {!this.state.IsEdit ?
                                        <Row>
                                            {mobilePhone !== undefined ?
                                                <Col md={{ size: 8 }}>
                                                    <span style={{ marginRight: '4px' }}><i className="fa fa-phone" aria-hidden="true"></i> </span>
                                                    {mobilePhone}
                                                </Col>
                                                : ''
                                            }
                                        </Row>
                                        :
                                        <Row>
                                            <Col md={4}>Mobile</Col>
                                            <Col md={8}>
                                                <Control.input name="mobilePhone" model=".mobilePhone" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={mobilePhone} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {dob !== undefined && dob !== null ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Date of Birth:  </Col>
                                                        <Col md={{ size: 8 }}>{(new Date(dob)).toLocaleDateString()}</Col>

                                                        {/* <Col md={{ size: 8 }}>{(new Date(waiverDate).toString())}</Col> */}
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col md={{ size: 4 }}>DOB:  </Col>

                                                <Col md={8}>
                                                    {
                                                        <DatePicker
                                                            selected={dob ? new Date(dob) : null}
                                                            onChange={this.handleDobChange}
                                                            className="form-control"
                                                            name="dob"
                                                            model=".dob"
                                                        />}

                                                </Col>
                                            </Row>
                                    }
                                    {/* :
                                             null
                                     } */}

                                    {!this.state.IsEdit ?
                                        <br />
                                        : ''}

                                    {!this.state.IsEdit ?
                                        <div>
                                            {uspaMemberNumber !== undefined ?
                                                <Row>
                                                    <Col md={{ size: 4 }}>Uspa Number:  </Col>
                                                    <Col md={{ size: 8 }}>{uspaMemberNumber}</Col>
                                                </Row>
                                                : ''}
                                        </div>
                                        :
                                        <Row>
                                            <Col md={{ size: 4 }}>Uspa Number:  </Col>
                                            <Col md={8}>
                                                <Control.input name="uspaMemberNumber" model=".uspaMemberNumber" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={uspaMemberNumber} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {siteWaiverId !== undefined ?
                                                <Row>
                                                    <Col md={{ size: 4 }}>Site Waiver Id:  </Col>
                                                    <Col md={{ size: 8 }}>{siteWaiverId}</Col>
                                                </Row>
                                                : ''}
                                        </div>
                                        :
                                        <Row>
                                            <Col md={{ size: 4 }}>Site Waiver Id:  </Col>
                                            <Col md={8}>
                                                <Control.input name="siteWaiverId" model=".siteWaiverId" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={siteWaiverId} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {manifestNumber !== undefined ?
                                                <Row>
                                                    <Col md={{ size: 4 }}>Manifest Number:  </Col>
                                                    <Col md={{ size: 3 }}>{manifestNumber}</Col>
                                                    <Col md={{ size: 5 }}> <i className="fa fa-refresh" onClick={() => this.updateManifestNumber(this.props.jumperValue._id)} style={{ fontSize: '14px' }}> </i> </Col>
                                                </Row>
                                                : ''}
                                        </div>
                                        :
                                        <Row>
                                            <Col md={{ size: 4 }}>Manifest Number:  </Col>
                                            <Col md={8}>
                                                <Control.input name="manifestNumber" model=".manifestNumber" className="form-control"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={manifestNumber} autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }


                                    {!this.state.IsEdit ?
                                        <div>
                                            {uspaExpiry !== undefined && uspaExpiry != null ?
                                                <Row>
                                                    <Col md={{ size: 4 }}>Uspa Expiry:  </Col>
                                                    <Col md={{ size: 4 }}>
                                                        {expiryValue}
                                                        {/* {(new Date(uspaExpiry).toString())} */}
                                                        {/* var date= new Date(desc.date);  */}
                                                        {

                                                            // date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours())
                                                        }
                                                    </Col>
                                                    {
                                                        isUspaVerified ?
                                                            <Col md={{ size: 1 }}>
                                                                <span style={{ fontSize: '13px', backgroundColor: '#1b5ba1' }} className="badge"> uspa verified </span>
                                                            </Col> : null
                                                    }
                                                </Row>
                                                : ''}
                                        </div>
                                        :
                                        <Row>
                                            <Col md={{ size: 4 }}>Uspa Expiry:  </Col>
                                            <Col md={8}>
                                                {/* <Control.input name="uspaExpiry" model=".uspaExpiry" className="form-control"
                                                     mapProps={{ onChange: (props) => props.onChange }} defaultValue={uspaExpiry} autoComplete="off" /> */}
                                                {
                                                    <DatePicker
                                                        selected={expiryValue === null ? null : new Date(this.state.uspaExpiry)}
                                                        onChange={this.handleExpiryChange}
                                                        className="form-control"
                                                        name="uspaExpiry"
                                                        model=".uspaExpiry"
                                                    />}
                                            </Col>
                                        </Row>
                                    }

                                    {/* {
                                         uspaExpiry !== undefined ?
                                             <Row>
                                                 <Col md={{ size: 4 }}>Uspa Expiry:  </Col>
                                                 {!this.state.IsEdit ?
                                                     <Col md={{ size: 8 }}>{(new Date(uspaExpiry).toString())}</Col>
                                                     :
                                                     <Col md={8}>
                                                         <Control.input name="uspaExpiry" model=".uspaExpiry" className="form-control"
                                                             mapProps={{ onChange: (props) => props.onChange }} defaultValue={uspaExpiry} autoComplete="off" />
                                                     </Col>}
                                             </Row>
                                             : ''
                                     } */}


                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {waiverDate !== undefined && waiverDate !== null ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Waiver Date:  </Col>
                                                        <Col md={{ size: 8 }}>{waiverValue}</Col>

                                                        {/* <Col md={{ size: 8 }}>{(new Date(waiverDate).toString())}</Col> */}
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col md={{ size: 4 }}>Waiver Date:  </Col>
                                                <Col md={8}>
                                                    {
                                                        <DatePicker
                                                            selected={waiverValue === null ? null : new Date(this.state.WaiverDate)}
                                                            onChange={this.handleWaiverChange}
                                                            className="form-control"
                                                            name="waiverDate"
                                                            model=".waiverDate"
                                                        />}
                                                </Col>
                                            </Row>
                                    }
                                   
                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {lastTIJumpDate !== undefined && lastTIJumpDate !== null ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Last TI Jump Date:  </Col>
                                                        <Col md={{ size: 8 }}>{lastTIJumpValue}</Col>
                                                        {/* <Col md={{ size: 8 }}>{(new Date(waiverDate).toString())}</Col> */}
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {lastSIJumpDate !== undefined && lastSIJumpDate !== null ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>last Instructor Jump Date:  </Col>
                                                        <Col md={{ size: 8 }}>{lastSIJumpValue}</Col>

                                                        {/* <Col md={{ size: 8 }}>{(new Date(waiverDate).toString())}</Col> */}
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {lastStudentJumpDate !== undefined && lastStudentJumpDate !== null ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Last Student Jump Date:  </Col>
                                                        <Col md={{ size: 8 }}>{lastStudentJumpValue}</Col>

                                                        {/* <Col md={{ size: 8 }}>{(new Date(waiverDate).toString())}</Col> */}
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {lastCoachJumpDate !== undefined && lastCoachJumpDate !== null ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Last Coach Jump Date:  </Col>
                                                        <Col md={{ size: 8 }}>{lastCoachJumpValue}</Col>

                                                        {/* <Col md={{ size: 8 }}>{(new Date(waiverDate).toString())}</Col> */}
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {lastFunJumpDate !== undefined && lastFunJumpDate !== null ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Last Fun Jump Date:  </Col>
                                                        <Col md={{ size: 8 }}>{lastFunJumpValue}</Col>

                                                        {/* <Col md={{ size: 8 }}>{(new Date(waiverDate).toString())}</Col> */}
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {discountRate !== undefined ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Discount:  </Col>
                                                        <Col md={{ size: 8 }}>${discountRate}</Col>
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col md={{ size: 4 }}>Discount:  </Col>
                                                <Col md={8}>
                                                    <Control.input name="discountRate" model=".discountRate" className="form-control"
                                                        mapProps={{ onChange: (props) => props.onChange }} defaultValue={discountRate} autoComplete="off" />
                                                </Col>
                                            </Row>
                                    }
                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {llcPay !== undefined ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>llc extra pay:  </Col>
                                                        <Col md={{ size: 8 }}>${llcPay}</Col>
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col md={{ size: 4 }}>llc extra pay:  </Col>
                                                <Col md={8}>
                                                    <Control.input name="llcPay" model=".llcPay" className="form-control"
                                                        mapProps={{ onChange: (props) => props.onChange }} defaultValue={llcPay} autoComplete="off" />
                                                </Col>
                                            </Row>
                                    }

                                    {
                                        !this.state.IsEdit ?
                                            <div>
                                                {chargeTo !== undefined ?
                                                    <Row>
                                                        <Col md={{ size: 4 }}>Charge To:  </Col>
                                                        <Col md={{ size: 8 }}>{this.getJumperName(chargeTo)}</Col>
                                                    </Row>
                                                    : ''}
                                            </div>
                                            :
                                            <Row className="form-group">
                                                <Label htmlFor="ChargeTo" md={4}>Charge To</Label>
                                                <Col md={8}>
                                                    <AsyncSelect loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
                                                        styles={customStyles} onInputChange={(val) => this.handleInputChange(val)}
                                                        defaultOptions={this.state.adminDefaultOptions} onChange={(val) => this.handleChargeSelect(val)} components={{ Option: this.Option }}
                                                    />
                                                </Col>
                                            </Row>
                                    }



                                    {
                                        currentBalance != undefined ?
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col md={{ size: 4 }}>Current Balance:  </Col> <Col md={{ size: 8 }}>{this.checkForNegatives(currentBalance)}</Col>
                                            </Row>
                                            : ''
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {
                                                noPayOrCharge != undefined && isRentingGear != null && isRentingGear != false
                                                    ?
                                                    <Row>
                                                        <Col md={{ size: 12 }}>is staff</Col>
                                                    </Row>
                                                    : ''
                                            }
                                        </div>
                                        :
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col md={{ size: 4 }}>Renting Gear:  </Col>
                                            <Col md={8}>
                                                <Control.checkbox name="isRentingGear" model=".isRentingGear"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={isRentingGear} checked autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {
                                                noPayOrCharge != undefined && isStaff != null && isStaff != false
                                                    ?
                                                    <Row>
                                                        <Col md={{ size: 12 }}>is staff</Col>
                                                    </Row>
                                                    : ''
                                            }
                                        </div>
                                        :
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col md={{ size: 4 }}>staff:  </Col>
                                            <Col md={8}>
                                                <Control.checkbox name="isStaff" model=".isStaff"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={isStaff} checked autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {
                                                noPayOrCharge != undefined && isLO != null && isLO != false
                                                    ?
                                                    <Row>
                                                        <Col md={{ size: 12 }}>is LO</Col>
                                                    </Row>
                                                    : ''
                                            }
                                        </div>
                                        :
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col md={{ size: 4 }}>LO:  </Col>
                                            <Col md={8}>
                                                <Control.checkbox name="isLO" model=".isLO"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={isLO} checked autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {
                                                !!isWaiverReviewed
                                                    ?
                                                    <Row>
                                                        <Col md={{ size: 12 }}>Waiver Reviewed</Col>
                                                    </Row>
                                                    : ''
                                            }
                                        </div>
                                        :
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col md={{ size: 4 }}>Waiver Reviewed:  </Col>
                                            <Col md={8}>
                                                <Control.checkbox name="isWaiverReviewed" model=".isWaiverReviewed"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={isWaiverReviewed} checked autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {
                                                isPaperWaiver != undefined && isPaperWaiver != null && isPaperWaiver != false
                                                    ?
                                                    <Row>
                                                        <Col md={{ size: 12 }}>Paper Waiver</Col>
                                                    </Row>
                                                    : ''
                                            }
                                        </div>
                                        :
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col md={{ size: 4 }}>Paper Waiver:  </Col>
                                            <Col md={8}>
                                                <Control.checkbox name="isPaperWaiver" model=".isPaperWaiver"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={isPaperWaiver} checked autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {
                                                noPayOrCharge != undefined && noPayOrCharge != null && noPayOrCharge != false
                                                    ?
                                                    <Row>
                                                        <Col md={{ size: 12 }}>No pay or Charge</Col>
                                                    </Row>
                                                    : ''
                                            }
                                        </div>
                                        :
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col md={{ size: 4 }}>NoPayOrCharge:  </Col>
                                            <Col md={8}>
                                                <Control.checkbox name="noPayOrCharge" model=".noPayOrCharge"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={noPayOrCharge} checked autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }

                                    {!this.state.IsEdit ?
                                        <div>
                                            {
                                                multiManifest != undefined && multiManifest != null && multiManifest != false
                                                    ?
                                                    <Row>
                                                        <Col md={{ size: 12 }}>allow multiple manifest</Col>
                                                    </Row>
                                                    : ''
                                            }
                                        </div>
                                        :
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col md={{ size: 4 }}>multiManifest:  </Col>
                                            <Col md={8}>
                                                <Control.checkbox name="multiManifest" model=".multiManifest"
                                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={multiManifest} checked autoComplete="off" />
                                            </Col>
                                        </Row>
                                    }




                                    {
                                        !this.state.IsEdit ?
                                            <hr style={{ background: 'white' }} />
                                            : ''
                                    }


                                    {!this.state.IsEdit ?
                                        <ul style={{ marginTop: '15px', listStyle: 'none' }}>
                                            {ratings != undefined && ratings.map(function (rating, index) {

                                                if (rating.expiry !== undefined && rating.expiry !== null && rating.expiry !== "") {
                                                    return <li key={index}>
                                                        <span style={{ marginRight: '4px' }}> <i className="fa fa-star" aria-hidden="true"></i> </span>
                                                        {rating.rating} : {(new Date(rating.expiry)).toLocaleDateString()} {rating.isUspaVerified ? <span style={{ fontSize: '13px', backgroundColor: '#1b5ba1' }} className="badge">
                                                            uspa verified
                                                        </span> : null}
                                                    </li>;
                                                }
                                                else {
                                                    return <li key={index}>
                                                        <span style={{ marginRight: '4px' }}> <i className="fa fa-star" aria-hidden="true"></i> </span>
                                                        {rating.rating} : {rating.number} {rating.isUspaVerified ? <span style={{ fontSize: '13px', backgroundColor: '#1b5ba1' }} className="badge"> uspa verified </span> : null}
                                                    </li>;
                                                }

                                            })}
                                        </ul>
                                        : ''
                                    }

                                    {this.state.IsEdit ?
                                        <Row className="form-group">
                                            <Label htmlFor="pilot" md={3}>Rating</Label>
                                            <div style={{ paddingLeft: '18px' }}>
                                                {this.renderCheckbox()}
                                            </div>
                                        </Row>
                                        : ''
                                    }
                                    {
                                        cardsRender
                                    }
                                    {
                                        accountRender
                                    }
                                    {
                                        dizioAchRender
                                    }
                                    <hr style={{ background: 'white' }} />
                                    {this.state.IsEdit ?
                                        this.editGear()
                                        :
                                        <Row className="form-group">
                                            <div style={{ paddingLeft: '18px' }}>
                                                {this.renderGearInfo()}
                                            </div>
                                        </Row>
                                    }

                                </div>

                            </Col>
                        </Row>

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 9 }}>
                                {/* <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button> */}
                                {!this.state.IsEdit ?
                                    <button type="submit" onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                                    :
                                    <button className="btn btn-link">SAVE</button>
                                }
                            </Col>
                        </Row>

                    </LocalForm>

                </AdminModal>
                <button className="btn customButton rating-button rightButton" style={{ display: 'none' }} onClick={this.toggleModal}>
                </button>
            </React.Fragment>
        );
    }
}

export default RatingModal
