
/**
 * http://usejsdoc.org/
 */
import { EventEmitter } from 'events';
import JumperApi from '../services/jumpersapi';
import SchedulerApi from '../services/schedulerapi';
import WaiverApi from '../services/waiverapi';

import Fuse from "fuse.js";
import reportsStore from '../stores/report-store';
import ldStore from '../stores/loads-store';

import swal from 'sweetalert';


class Jumpers extends EventEmitter {
	constructor() {

		super();
		this.prefix = 'Jumpers_STORE_';
		this.jumpers = [];
		this.pilots = [];
		this.tandemInstructors = [];
		this.affInstructors = [];
		this.videographers = [];
		this.tandemStudents = [];
		this.repeatTandems = [];
		this.affStudents = [];
		this.iadStudents = [];
		this.coaches = [];
		this.staff = [];
		this.NONEJumperOption = {};
		this.HOLDJumperOption = {};
		this.dailyActivities = {};
		this.tandemDailyActivities = {};
		this.todayWaivers = [];
		this.holdJumperId = undefined;
		this.jumpersLoaded = false;
		this.tandemStudentsLoaded = false;
		this.loadAllJumpers = this.loadAllJumpers.bind(this);
		this.loadTandemStudents = this.loadTandemStudents.bind(this);

		this.shouldTodayWaiversPulse = false;

		this.jumpersAreLoaded = this.jumpersAreLoaded.bind(this);
		this.getJumperOptions = this.getJumperOptions.bind(this);
		this.getCoachOptions = this.getCoachOptions.bind(this);
		this.getTandemInstructorOptions = this.getTandemInstructorOptions.bind(this);
		this.getTandemStudentOptions = this.getTandemStudentOptions.bind(this);
		this.getAffInstructorOptions = this.getAffInstructorOptions.bind(this);
		this.getAffStudentOptions = this.getAffStudentOptions.bind(this);
		this.getIadStudentOptions = this.getIadStudentOptions.bind(this);
		this.getVideographerOptions = this.getVideographerOptions.bind(this);
		this.fuse = null;

	}

	setListeners = (socket) => {

		socket.on('updateManifestStatus', data => {
			console.log('updateManifestStatus socket event')
			var dailyActivity = data.dailyActivity;
			var otherActivities = data.otherActivities;
			this.emitResults({ id: dailyActivity.studentId, dailyActivity: dailyActivity, otherActivities: otherActivities }, 'updateManifestStatus');
		})

		socket.on('updateStudentStatus', data => {
			console.log('updateStudentStatus socket event')

			var dailyActivity = data.dailyActivity;
			var otherActivities = data.otherActivities;
			this.emitResults({ id: dailyActivity.studentId, dailyActivity: dailyActivity, otherActivities: otherActivities }, 'updateStudentStatus');
		})


		socket.on("repeatTandemAdded", data => {
			console.log('repeatTandemAdded socket event')

			var studentIndx = this.tandemStudents.findIndex(st => st._id === data.student._id);
			if (studentIndx < 0) {
				this.addWalkinTandemStudent(data.student);
				this.emitResults(true, 'tandemStudentCreated');
				//this.sendOneTandemToPublicJumper(data.student); //async
			}
			else {
				this.tandemStudents[studentIndx] = data.student
			}

		});

		socket.on("walkinTandemAdded", data => {
			//alert('in walking tandem added');

			console.log('walkinTandemAdded socket event')
			console.log(data);
			//console.log(this.tandemStudents);
			//alert('walkin tandem added');
			var studentIndx = this.tandemStudents.findIndex(st => st?._id === data?.student?._id);
			//console.log(studentIndx);
			if (studentIndx < 0) {
				this.addWalkinTandemStudent(data?.student);
				this.emitResults(true, 'tandemStudentCreated');
				//this.sendOneTandemToPublicJumper(data.student); //async
			}
			else {
				this.tandemStudents[studentIndx] = data?.student
			}

			this.shouldTodayWaiversPulse = true;

			if (data.waiverRow) {
				var indx = this.todayWaivers?.findIndex(rw => rw?.jumperId === data?.waiverRow?.jumperId);
				if (indx < 0) {
					this.todayWaivers.unshift(data?.waiverRow);
				}
				else {
					var waiverRow = this.todayWaivers.splice(indx, 1);
					this.todayWaivers.unshift(data?.waiverRow);
				}
				if (data.dailyActivity) {
					this.setTandemStudentDailyActivity(data?.dailyActivity);
				}
				this.emitResults({ name: data?.student?.firstname + " " + data?.student?.lastname }, 'walkinTandemWaiverSubmit');
				this.emitResults(true, 'waiverUpdate');
			}
			//this.todayWaivers.sort((a,b) => new Date(a.date) - newf Date(b.date));
		});

		socket.on('updateManifestStatusAll', data => {
			console.log('updateManifestStatusAll socket event')
			//alert('update manifest status all');
			this.dailyActivities = data.dailyActivities;
			this.tandemDailyActivities = data.tandemDailyActivities;
			this.emitResults({}, 'staffStatusUpdate');
		})

		socket.on('checkoutAll', data => {
			this.dailyActivities = data.dailyActivities;
			//this.tandemDailyActivities = data.tandemDailyActivities;
			this.emitResults({}, 'staffStatusUpdate');
		})

		socket.on("studentStatusUpdate", data => {
			console.log('studentStatusUpdate socket event')

			//alert('walkin created');
			var jumperActivity = data
			this.dailyActivities[jumperActivity.jumperId] = jumperActivity;
			this.emitResults(jumperActivity, 'studentStatusUpdate');

		});

		socket.on("singleStaffStatusUpdate", data => {
			console.log('singleStaffStatusUpdate socket event')

			//alert('walkin created');
			var checkedInStaff = data;

			this.dailyActivities[checkedInStaff.jumperId] = checkedInStaff;

			this.emitResults("", 'staffStatusUpdate');
			this.emitResults("", 'singleStaffStatusUpdate');
		});

		socket.on("staffStatusUpdate", data => {
			console.log('staffStatusUpdate socket event')

			//alert('walkin created');
			var checkedInStaff = data;
			checkedInStaff.forEach(staff => {
				this.dailyActivities[staff.jumperId] = staff;
			})

			this.emitResults("", 'staffStatusUpdate');
		});

		socket.on("tandemStudentStatusUpdate", data => {
			console.log('tandemStudentStatusUpdate socket event')
			//alert('tandem student status update');
			var t = data;
			this.tandemDailyActivities[data.studentId] = data;
			this.emitResults("", 'tandemStudentStatusUpdate');
		});

		socket.on("staffCheckoutUpdate", data => {
			console.log('staffCheckoutUpdate socket event')

			//alert('walkin created');
			var checkedInStaff = data.checkedInStaff;
			var checkedOutStaff = data.checkOutStaff;
			this.dailyActivities[checkedOutStaff.jumperId] = checkedOutStaff;
			checkedInStaff.forEach(staff => {
				this.dailyActivities[staff.jumperId] = staff;
			})
			this.emitResults("", 'staffStatusUpdate');
		});

		socket.on("studentStatusComplete", data => {
			console.log('studentStatusComplete socket event')

			//alert('walkin created');
			var jumperActivity = data.dailyActivity;
			var student = data.student;

			//splice from aff students;
			var affIndx = this.affStudents.findIndex(stud => stud._id.toString() === student._id.toString);
			if (affIndx >= 0) {
				this.affStudents.splice(affIndx, 1);
			}
			var jumperIndx = this.jumpers.findIndex(stud => stud._id.toString() === student._id.toString);
			if (jumperIndx >= 0) {
				this.jumpers[jumperIndx] = student;
			}
			this.dailyActivities[jumperActivity.jumperId] = jumperActivity;
			this.emitResults(jumperActivity, 'studentStatusUpdate');

		});

		socket.on("waiverAttached", data => {
			console.log('waiverAttached socket event');
			console.log(data);

			var student = data.student;
			var dailyActivity = data.dailyActivity;
			if (data.removedActivity) {
				console.log('removing older tandem activity');
				delete this.tandemDailyActivities[data.removedActivity.studentId]
				console.log(this.tandemDailyActivities)
			}

			var studentIndx = this.tandemStudents.findIndex(st => st._id === data.student._id);

			if (studentIndx < 0) {
				this.addWalkinTandemStudent(data.student);
				this.emitResults(true, 'waiverAttached');
				//this.sendOneTandemToPublicJumper(data.student); //async
			}
			else {
				this.tandemStudents[studentIndx] = data.student;
			}

			this.tandemDailyActivities[dailyActivity.studentId] = dailyActivity;
			this.shouldTodayWaiversPulse = true;
			this.emitResults({ messg: "waivers" }, 'waiverUpdate');

		});

		socket.on('removeTandem', data => {
			console.log('remove tandem sock event');
			this.removeTandemStudent(data.id);
		});

		socket.on("removedJoinedTandems", data => {
			console.log('removeJoinedTandems socket event');
			console.log(data)
			if (data.removedDailyActivity) {
				this.setTandemStudentDailyActivity(data.removedDailyActivity);
			}
			if (data.dailyActivity) {
				this.setTandemStudentDailyActivity(data.dailyActivity);
			}
			if (data.otherAccounts?.length > 0) {
				data.otherAccounts.forEach(activity => {
					this.setTandemStudentDailyActivity(activity);
				})
			}
			//alert('walkin created');
			this.todayWaivers = data.todayWaivers;
			this.shouldTodayWaiversPulse = true;
			this.emitResults({ messg: "waivers" }, 'waiverUpdate');

		});

		socket.on("joinTandems", data => {
			console.log('joinTandems socket event');
			console.log()
			if (data.dailyActivity) {
				this.setTandemStudentDailyActivity(data.dailyActivity);
			}

			if (data.otherAccounts?.length > 0) {
				data.otherAccounts.forEach(activity => {
					this.setTandemStudentDailyActivity(activity);
				})
			}
			//alert('walkin created');
			this.todayWaivers = data.todayWaivers;
			this.shouldTodayWaiversPulse = true;
			this.emitResults({ messg: "waivers" }, 'waiverUpdate');

		});

		socket.on("affWaiverSubmitted", data => {
			console.log('affWaiverSubmitted socket event')

			//alert('walkin created');
			var indx = this.todayWaivers.findIndex(rw => rw.jumperId === data.waiverRow.jumperId);
			if (indx < 0) {
				this.todayWaivers.unshift(data.waiverRow);
			}
			else {
				this.todayWaivers[indx] = data.waiverRow;
			}
			this.emitResults({ name: data.waiverRow.name }, 'walkinAffWaiverSubmit');
			this.emitResults(true, 'waiverUpdate');
			this.shouldTodayWaiversPulse = true;

		});

		socket.on("jumperWaiverSubmitted", data => {
			console.log('jumperWaiverSubmitted socket event')

			var indx = this.todayWaivers.findIndex(rw => rw.jumperId === data.waiverRow.jumperId);
			if (indx < 0) {
				this.todayWaivers.unshift(data.waiverRow);
			}
			else {
				this.todayWaivers[indx] = data.waiverRow;
			}

			this.emitResults({ name: data.waiverRow.name }, 'licensedWaiverSubmit');
			this.emitResults(true, 'waiverUpdate');
			this.shouldTodayWaiversPulse = true;

		});

		socket.on("jumperMerged", data => {
			console.log('jumperMerged socket event')

			var indx = this.todayWaivers.findIndex(rw => rw.jumperId === data.duplicateJumperId);
			if (indx >= 0) {
				this.todayWaivers[indx].jumperId = data.originalJumperId;
			}
			delete this.dailyActivities[data.duplicateJumperId];
			this.dailyActivities[data.dailyActivity.jumperId] = data.dailyActivity;
			//this.emitResults({ name: data.waiverRow.name }, 'licensedWaiverSubmit');
			this.emitResults(true, 'waiverUpdate');
			this.emitResults("", 'staffStatusUpdate');
			this.emitResults(data.dailyActivity, 'studentStatusUpdate');

			this.shouldTodayWaiversPulse = true;

		})

		socket.on('jumpersUpdated', datas => {
			console.log('jumpersUpdated socket event')

			var localjumpers = datas;

			localjumpers.forEach(data => {
				var jumpers = this.jumpers.filter(jumper => jumper._id === data._id);

				var jumper = data;
				if (jumpers.length > 0) {
					//alert('updating jumper list');
					var indx = this.jumpers.findIndex(jumper => jumper._id === data._id);
					this.jumpers[indx] = data;
					//jumper = r.data;
				}

				var pushed = false;
				var indx = this.staff.findIndex(jumper => jumper._id === data._id);

				if (jumper.isStaff && indx >= 0) {
					this.staff[indx] = data;
					pushed = true;
				}
				else if (jumper.isStaff && indx < 0) {
					this.staff.push(data);
				}


				if (jumper.ratings.filter(rating => rating.rating === "PILOT").length > 0) {
					this.pilots.push(jumper);
				}

				if (jumper.ratings.filter(rating => rating.rating === "TANDEM INSTRUCTOR").length > 0) {
					this.tandemInstructors.push(jumper);
				}

				if (jumper.ratings.filter(rating => rating.rating === "VIDEOGRAPHER").length > 0) {
					this.videographers.push(jumper);
				}

				if (jumper.ratings.filter(rating => rating.rating === "AFF INSTRUCTOR").length > 0) {
					this.affInstructors.push(jumper);
				}

				if (jumper.ratings.filter(rating => rating.rating === "COACH").length > 0) {
					this.coaches.push(jumper);
				}

				if (jumper.ratings.filter(rating => rating.rating === "AFF STUDENT").length > 0) {
					this.affStudents.push(jumper);
				}


				if (jumper.ratings.filter(rating => rating.rating === "IAD STUDENT").length > 0) {
					this.iadStudents.push(jumper);
				}

				//alert(JSON.stringify(r.data));
				//alert(JSON.stringify(this.jumpers.filter(jumper => jumper._id === r.data._id)[0]));

			});

			var jumperOptions = this.jumpers.map(jumper => {
				var label = jumper.nickname;
				if (jumper.lastname) {
					label += " " + jumper.lastname;
				}
				if (jumper.manifestNumber) {
					label += " " + jumper.manifestNumber;
				}

				return { "label": label, "value": jumper }
			});

			var staffOptions = this.staff.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

			var affStudentOptions = this.getAffStudentOptions();

			this.emitResults(jumperOptions, 'jumpersLoaded');
			this.emitResults(staffOptions, 'staffLoaded');

			this.emitResults(affStudentOptions, 'affStudentsLoaded');

		});

		socket.on('jumperUpdated', data => {
			console.log('jumperUpdated socket event');
			var jumper = data;

			this.processJumper(jumper);

			//alert(JSON.stringify(r.data));
			//alert(JSON.stringify(this.jumpers.filter(jumper => jumper._id === r.data._id)[0]));
			var jumperOptions = this.jumpers.map(jumper => {
				var label = jumper.nickname;
				if (jumper.lastname) {
					label += " " + jumper.lastname;
				}
				if (jumper.manifestNumber) {
					label += " " + jumper.manifestNumber;
				}

				return { "label": label, "value": jumper }
			});

			var staffOptions = this.staff.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

			var affStudentOptions = this.getAffStudentOptions();

			this.emitResults(jumperOptions, 'jumpersLoaded');
			this.emitResults(staffOptions, 'staffLoaded');
			//this.emitResults(jumper, 'jumperUpdated');
			this.emitResults(affStudentOptions, 'affStudentsLoaded');

		});
		socket.on('jumperDeleted', data => {
			console.log('jumpeDeleted socket event');
			//alert('jumper deleted');
			var jumper = data;

			////console.log(data);

			this.jumpers = this.jumpers.filter(jp => jp._id.toString() !== jumper._id.toString());

			this.tandemInstructors = this.tandemInstructors.filter(jp => jp._id.toString() !== jumper._id.toString());

			this.videographers = this.videographers.filter(jp => jp._id.toString() !== jumper._id.toString());

			this.affInstructors = this.affInstructors.filter(jp => jp._id.toString() !== jumper._id.toString());

			this.coaches = this.coaches.filter(jp => jp._id.toString() !== jumper._id.toString());

			this.affStudents = this.affStudents.filter(jp => jp._id.toString() !== jumper._id.toString());

			this.pilots = this.pilots.filter(jp => jp._id.toString() !== jumper._id.toString());

			this.staff = this.staff.filter(jp => jp._id.toString() !== jumper._id.toString());

			var jumperOptions = this.jumpers.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

			var staffOptions = this.staff.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

			this.emitResults(jumperOptions, 'jumpersLoaded');

			this.emitResults(staffOptions, 'staffLoaded');
		});

		socket.on('newJumperAdded', data => {
			console.log('newJumperAdded socket event');
			console.log(data);
			var jumper = data;
			this.processJumper(jumper);


			var jumperOptions = this.jumpers.map(jumper => {
				var label = jumper.nickname;
				if (jumper.lastname) {
					label += " " + jumper.lastname;
				}
				if (jumper.manifestNumber) {
					label += " " + jumper.manifestNumber;
				}

				return { "label": label, "value": jumper }
			});

			var staffOptions = this.staff.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

			var affStudentOptions = this.getAffStudentOptions();
			var iadStudentOptions = this.getIadStudentOptions();

			this.emitResults(jumperOptions, 'jumpersLoaded');
			this.emitResults(staffOptions, 'staffLoaded');
			this.emitResults(affStudentOptions, 'affStudentsLoaded');
			this.emitResults(iadStudentOptions, 'iadStudentsLoaded');
			//this.emitResults(jumper, 'jumperCreated');
		});

	}

	getData = (prop) => {
		return this[prop];
	}

	saveData = (prop, value) => {
		this[prop] = value;
	}

	getTodayWaivers = () => {
		return this.todayWaivers;
	}
	addWaiverSubmit = (date, name, type, waiverId) => {
		this.todayWaivers.push({ "date": date, "type": type, "name": name, "waiverId": waiverId });
	}
	processJumper = (jumper) => {
		console.log('processJumper');


		var indx = this.jumpers.findIndex(val => val._id.toString() === jumper._id.toString());

		if (indx >= 0) {
			this.jumpers[indx] = jumper;
		}
		else {
			this.jumpers.push(jumper);
		}

		indx = this.staff.findIndex(val => val._id.toString() === jumper._id.toString());
		if (indx < 0) {
			this.staff.push(jumper);
		}

		var pushed = false;
		if (jumper.ratings.filter(rating => rating.rating === "TANDEM INSTRUCTOR").length > 0) {

			var indx = this.tandemInstructors.findIndex(val => val.uspaMemberNumber === jumper.uspaMemberNumber);

			if (indx >= 0) {
				this.tandemInstructors[indx] = jumper;
			}
			else {
				this.tandemInstructors.push(jumper);
			}
		}

		if (jumper.ratings.filter(rating => rating.rating === "VIDEOGRAPHER").length > 0) {

			var indx = this.videographers.findIndex(val => val.uspaMemberNumber === jumper.uspaMemberNumber);
			if (indx >= 0) {
				this.videographers[indx] = jumper;
			}
			else {
				this.videographers.push(jumper);
			}
		}

		if (jumper.ratings.filter(rating => rating.rating === "AFF INSTRUCTOR").length > 0) {

			var indx = this.affInstructors.findIndex(val => val.uspaMemberNumber === jumper.uspaMemberNumber);
			if (indx >= 0) {
				this.affInstructors[indx] = jumper;
			}
			else {
				this.affInstructors.push(jumper);
			}

		}

		if (jumper.ratings.filter(rating => rating.rating === "COACH").length > 0) {
			var indx = this.coaches.findIndex(val => val.uspaMemberNumber === jumper.uspaMemberNumber);
			if (indx >= 0) {
				this.coaches[indx] = jumper;
			}
			else {
				this.coaches.push(jumper);
			}
		}

		if (jumper.ratings.filter(rating => rating.rating === "PILOT").length > 0) {
			var indx = this.pilots.findIndex(val => val._id.toString() === jumper._id.toString());
			if (indx >= 0) {
				this.pilots[indx] = jumper;
			}
			else {
				this.pilots.push(jumper);
			}
		}

		if (jumper.ratings.filter(rating => rating.rating === "AFF STUDENT").length > 0) {
			////console.log('adding to aff student');

			var indx = this.affStudents.findIndex(val => val._id.toString() === jumper._id.toString());

			if (indx >= 0) {
				this.affStudents[indx] = jumper;
			}
			else {
				this.affStudents.push(jumper);
			}
		}

		if (jumper.ratings.filter(rating => rating.rating === "IAD STUDENT").length > 0) {
			////console.log('adding to iad student');

			var indx = this.iadStudents.findIndex(val => val.uspaMemberNumber === jumper.uspaMemberNumber);

			if (indx >= 0) {
				this.iadStudents[indx] = jumper;
			}
			else {
				this.iadStudents.push(jumper);
			}
		}

		var jumperOptions = this.jumpers.map(jumper => {
			var label = jumper.nickname;
			if (jumper.lastname) {
				label += " " + jumper.lastname;
			}
			if (jumper.manifestNumber) {
				label += " " + jumper.manifestNumber;
			}

			return { "label": label, "value": jumper }
		});

		var staffOptions = this.staff.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

		var affStudentOptions = this.getAffStudentOptions();
		var iadStudentOptions = this.getIadStudentOptions();
	}
	/*
	getWaiver = (waiverId, withPDF) => {
		////console.log('about to get waivver');
		return new Promise((resolve, reject) => {
			WaiverApi.getWaiver(waiverId, withPDF)
			.then(r => {
				if (r.status == 200) {
					var waiver = r.data;
					////console.log(waiver);
					//////console.log('retrieved daily activity');
					//////console.log(jumperDailyActivity);
					this.emitResults({ waiver }, 'getWaiver');
					resolve(waiver);
				}
			})
			.catch(e => {
				this.emitError({ message: 'Incomplete or Invalid data present' });
			})
		})
	}*/

	getWaiverById = (waiverId) => {
		return new Promise((resolve, reject) => {
			JumperApi.getWaiverById(waiverId)
				.then(r => {
					if (r.status == 200) {
						var waiver = r.data;
						//console.log(waivers);
						//console.log('retrieved daily activity');
						//console.log(jumperDailyActivity);
						//this.emitResults(waivers, 'getWaiver');
						resolve(waiver);
					}
					else if (r.status == 401) {
						this.loginExpired();
						this.emitResults(true, 'clearSession');
					}
				})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		})
	}

	getWaivers = (studentId) => {
		return new Promise((resolve, reject) => {
			SchedulerApi.getWaivers(studentId)
				.then(r => {
					if (r.status == 200) {
						var waivers = r.data;
						console.log(waivers);
						//console.log('retrieved daily activity');
						//console.log(jumperDailyActivity);
						this.emitResults(waivers, 'getWaiver');
						resolve(waivers);
					}
					else if (r.status == 401) {
						this.loginExpired();
						this.emitResults(true, 'clearSession');
					}
				})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		})
	}
	getTandemStudents = () => {
		return this.tandemStudents;
	}

	getTandemStudentsByIds = (ids) => {
		console.log('in get tandems by id');
		return this.tandemStudents.filter(st => ids.includes(st._id));
	}

	getFunJumps = async (iOptions) => {
		const response = await JumperApi.getFunJumps(iOptions);
		return response.data;
	}

	getTandemJumps = async (iOptions) => {
		const response = await JumperApi.getTandemJumps(iOptions);
		return response;
	}

	toggleWaiverReviewStatus = (siteWaiverId) => {
		return new Promise((resolve, reject) => {
			JumperApi.toggleWaiverReviewStatus(siteWaiverId)

				.then(r => {
					if (r.status == 200) {
						var waiverRow = r.data;
						//console.log(waivers);
						var indx = this.todayWaivers.findIndex(row => row.siteWaiverId === waiverRow.siteWaiverId);

						if (indx >= 0)
							this.todayWaivers[indx] = waiverRow;

						if (this.todayWaivers.every(row => row.reviewed === true)) {
							this.emitResults(false, 'waiverUpdate'); // should not pulse anymore
							this.shouldTodayWaiversPulse = false;
						}
						else {
							this.emitResults(true, 'waiverUpdate'); // should not pulse anymore
							this.shouldTodayWaiversPulse = true;
						}
						//console.log('retrieved daily activity');
						//console.log(jumperDailyActivity);
						//this.emitResults(waivers, 'getWaiver');
						resolve(this.todayWaivers);
					}
					else if (r.status == 401) {
						this.loginExpired();
						this.emitResults(true, 'clearSession');
					}
					//////console.log('retrieved daily activity');
					//////console.log(jumperDailyActivity);
					//this.emitResults(waivers, 'getWaiver');
					resolve(this.todayWaivers);
				})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		})
	}

	getJumperWaivers = (jumperId) => {
		return new Promise((resolve, reject) => {
			JumperApi.getWaivers(jumperId)

				.then(r => {
					if (r.status == 200) {
						var waivers = r.data;
						console.log(waivers);
						//console.log('retrieved daily activity');
						//console.log(jumperDailyActivity);
						this.emitResults(waivers, 'getWaiver');
						resolve(waivers);
					}
					else if (r.status == 401) {
						this.loginExpired();
						this.emitResults(true, 'clearSession');
					}
				})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		})
	}

	getDailyActivity = (jumperId) => {
		return new Promise((resolve, reject) => {
			JumperApi.getDailyActivity(jumperId).then(r => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[jumperId] = jumperDailyActivity;
					this.processJumper(r.data.jumper);

					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					console.log(jumperDailyActivity);
					console.log(otherActivities);
					this.emitResults({ jumperId: jumperId, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitResults({}, 'dailyActivity');
					this.emitError({ message: 'could not get daily activity' });
				}
			})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		});
	}

	getJumpWithSuggestions = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.getJumpWithSuggestions(payload).then(r => {
				if (r.status == 200) {
					var jumpWithSuggestions = r.data.jumpWithSuggestions;

					//////console.log(otherActivities);
					//////console.log('retrieved daily activity');
					//////console.log(jumperDailyActivity);
					resolve(jumpWithSuggestions);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitResults({}, 'dailyActivity');
					this.emitError({ message: 'could not get daily activity' });
				}
			})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		});
	}

	getDailyActivityFrontEnd = (jumperId) => {
		if (this.dailyActivities[jumperId]) {
			return this.dailyActivities[jumperId].totalBalance
		}
		else {
			return 0.00
		}
	}

	getDailyActivityTaxFrontEnd = (jumperId) => {
		if (this.dailyActivities[jumperId]) {
			return this.dailyActivities[jumperId].totalTaxBalance
		}
		else {
			return 0.00
		}
	}

	getTandemDailyActivityFrontEnd = (jumperId) => {
		console.log('in getTandemDailyActivity');
		if (this.tandemDailyActivities[jumperId] && this.tandemDailyActivities[jumperId].totalDue) {
			return this.tandemDailyActivities[jumperId].totalDue
		}
		else if (this.tandemDailyActivities[jumperId] && this.tandemDailyActivities[jumperId].totalBalance) {
			return this.tandemDailyActivities[jumperId].totalBalance
		}
		else {
			return 0.00
		}
	}

	getTandemDailyActivityTaxFrontEnd = (jumperId) => {
		console.log('in getTandemDailyActivityTaxFrontEnd');

		if (this.tandemDailyActivities[jumperId] && this.tandemDailyActivities[jumperId].totalTaxDue) {
			return this.tandemDailyActivities[jumperId].totalTaxDue
		}
		else if (this.tandemDailyActivities[jumperId] && this.tandemDailyActivities[jumperId].totalTaxBalance) {
			return this.tandemDailyActivities[jumperId].totalTaxBalance
		}
		else {
			return 0.00
		}
	}

	getDailyTandemActivity = (tandemStudentId, event) => {
		return new Promise((resolve, reject) => {
			JumperApi.getTandemDailyActivity(tandemStudentId).then(r => {
				if (r.status == 200) {
					////console.log('after getting tandem daily activity from backend');
					////console.log(r.data);
					var jumperDailyActivity = r.data.dailyActivity;

					var otherActivities = r.data.otherAccounts;

					this.tandemDailyActivities[jumperDailyActivity.studentId] = jumperDailyActivity;
					// var cardOnFile=r.data.cardOnFile;

					otherActivities.forEach(activity => {
						activity.isTandemStudent = true;
						this.tandemDailyActivities[activity.studentId] = activity;
					})

					jumperDailyActivity.isTandemStudent = true;

					this.emitResults({ jumperId: jumperDailyActivity.studentId, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: true, event: event, tandemGroup: r.data.tandemGroup }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitResults({}, 'dailyActivity');
					this.emitError({ message: 'could not get daily activity' });
				}
			})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		});
	}

	removeJumper = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.getTandemDailyActivity(payload).then(r => {
				if (r.status == 200) {

				}
			})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		});
	}




	setAffPriceType = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.setAffPriceType(id, payload).then((r) => {
				if (r.status == 200) {
					var otherActivities = r.data.otherAccounts;


					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: otherActivities }, 'setPackage');
					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	setPackagePrice = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.setPackagePrice(id, payload).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					var otherActivities = r.data.otherAccounts;

					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					console.log(r.data.dailyActivity);

					this.tandemDailyActivities[id] = r.data.dailyActivity;
					otherActivities?.forEach(activity => {
						this.tandemDailyActivities[activity.studentId] = activity;
					})
					this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: otherActivities, tandemGroup: r.data.tandemGroup }, 'setPackage');
					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);
					resolve(true);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	setTandemStudentPackage = (id, payload) => {

		return new Promise((resolve, reject) => {
			JumperApi.setTandemStudentPackage(id, payload).then((r) => {
				if (r.status == 200) {
					console.log(r.data);
					var otherActivities = r.data.otherAccounts;

					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					this.tandemDailyActivities[id] = r.data.dailyActivity;
					otherActivities?.forEach(activity => {
						this.tandemDailyActivities[activity.studentId] = activity;
					})
					this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: otherActivities, tandemGroup: r.data.tandemGroup }, 'setPackage');
					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	applyGiftCode = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.applyGiftCode(id, payload).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					var otherActivities = r.data.otherAccounts;

					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					console.log(r.data.dailyActivity);

					this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: otherActivities }, 'setPackage');
					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	addPayBeforeItem = (id, payload) => {

		return new Promise((resolve, reject) => {
			JumperApi.addPayBeforeItem(id, payload).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					var otherActivities = r.data.otherAccounts;

					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					console.log(r.data.dailyActivity);
					this.tandemDailyActivities[id] = r.data.dailyActivity;
					otherActivities.forEach(activity => {
						this.tandemDailyActivities[activity.studentId] = activity;
					})
					this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: otherActivities, tandemGroup: r.data.tandemGroup }, 'setPackage');
					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);
					resolve(r.data);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					reject(false);
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	loadAllDailyActivities = () => {
		//////console.log('in get AllDailyActivities');
		return new Promise((resolve, reject) => {
			JumperApi.getAllDailyActivity().then(r => {
				if (r.status == 200) {

					var jumperAllDailyActivities = r.data.dailyActivities;
					var tandemDailyActivities = r.data.tandemDailyActivities;

					this.dailyActivities = jumperAllDailyActivities;
					this.tandemDailyActivities = tandemDailyActivities;
					//console.log(this.dailyActivities);
					this.emitResults({}, 'staffStatusUpdate');
					this.emitResults({ dailyActivities: jumperAllDailyActivities, tandemDailyActivities: tandemDailyActivities }, 'AllDailyActivities');
					resolve(jumperAllDailyActivities);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitResults({}, 'AllDailyActivities');
					this.emitError({ message: 'could not get daily activity' });
				}
			})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		});
	}

	shouldTodayWaiverPulse = () => {
		return this.shouldTodayWaiversPulse;
	}

	loadTodayWaivers = () => {
		return new Promise((resolve, reject) => {
			JumperApi.getTodayWaivers().then(r => {
				if (r.status == 200) {
					var todayWaivers = r.data;
					this.todayWaivers = todayWaivers;


					if (this.todayWaivers.every(row => row.reviewed === true)) {
						this.emitResults(false, 'waiverUpdate'); // should not pulse anymore
						this.shouldTodayWaiversPulse = false;
					}
					else {
						this.emitResults(true, 'waiverUpdate'); // should not pulse anymore
						this.shouldTodayWaiversPulse = true;
					}

					resolve(todayWaivers);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitResults({}, 'AllDailyActivities');
					this.emitError({ message: 'could not get daily activity' });
				}
			})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		});
	}

	resetTodayWaivers = () => {
		this.todayWaivers.length = 0;
		this.shouldTodayWaiversPulse = false;
	}


	sendOneTandemToPublicJumper = (student) => {
		return new Promise((resolve, reject) => {
			//////console.log('in loadTandemStudents');
			JumperApi.sendOneTandemToPublicJumper(student).then((r) => {
				if (r.status == 200) {
					//////console.log('tandem students sent');
					return resolve({ message: "done" });
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(e => {
					//////console.log(e);
					this.emitError({ message: 'Incomplete or Invalid data present' });
				});
		});
	}


	sendTandemsToPublicJumper = () => {
		return new Promise((resolve, reject) => {
			//////console.log('in loadTandemStudents');
			JumperApi.sendTandemsToPublicJumper(this.tandemStudents).then((r) => {
				if (r.status == 200) {
					//////console.log('tandem students sent');
					return resolve({ message: "done" });
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(e => {
					//////console.log(e);
					this.emitError({ message: 'Incomplete or Invalid data present' });
				});
		});
	}

	UpdateWalkinTandemInfo = (id, payload) => {
		this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			JumperApi.updateWalkinTandemInfo(id, payload).then((r) => {
				if (r.status == 200) {
					//schedulesStore.closeReservation();

					//this.addWalkinTandemStudent(r.data);
					/*
					this.emitResults(true, 'tandemStudentCreated');
					this.sendTandemsToPublicJumper().then((msg) => {
						resolve(r.data);
					});
					*/
					this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts }, 'setPackage');

					resolve(r.data);
					//////console.log(r.data);
					//alert(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					//////console.log({ message: 'Reservation could not be created, please contact support.' });
				}
				this.emitResults(false, 'InitilizeLoader');
			})
				.catch((err) => {
					this.emitResults(false, 'InitilizeLoader');
					//////console.log({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	UpdateTandemStudentInfo = (id, payload) => {
		this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			JumperApi.updateTandemStudentInfo(id, payload).then((r) => {
				if (r.status == 200) {
					this.emitResults({ id: id, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts }, 'setPackage');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				this.emitResults(false, 'InitilizeLoader');
			})
				.catch((err) => {
					this.emitResults(false, 'InitilizeLoader');
					reject(err);
				});
		});
	}


	AddWalkinTandemStudent = (payload) => {
		this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			JumperApi.createTandemWalkin(payload).then((r) => {
				if (r.status == 200) {
					//schedulesStore.closeReservation();

					//this.addWalkinTandemStudent(r.data);
					this.emitResults(true, 'tandemStudentCreated');
					resolve(r.data);
					/*
					this.sendTandemsToPublicJumper().then((msg) => {
						resolve(r.data);
					});*/
					//////console.log(r.data);
					//alert(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					//////console.log({ message: 'Reservation could not be created, please contact support.' });
				}
				this.emitResults(false, 'InitilizeLoader');
			})
				.catch((err) => {
					this.emitResults(false, 'InitilizeLoader');
					//////console.log({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	addWalkinTandemStudent = (student) => {
		this.tandemStudents.push(student);
	}
	loadTandemByDate = (startDate, endDate) => {
		return new Promise((resolve, reject) => {

			startDate = new Date(startDate);
			startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

			endDate = new Date(endDate);
			endDate = endDate.setHours(23, 59, 59, 999);
			endDate = new Date(endDate);
			endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
			JumperApi.loadTandemByDate(startDate, endDate)
				.then(r => {
					// console.log("🚀 ~ file: jumpers-store.js ~ line 1144 ~ Jumpers ~ returnnewPromise ~ r", r)
					this.tandemStudents = r.data;
					this.tandemStudentsLoaded = true;
					this.emitResults(this.tandemStudents, 'tandemStudentsLoaded');
					resolve(true);
				})
				.catch(err => {
					////console.log("ERR:(loadTandemByDate): ", err);
				})
		})


	}

	loadTandems = (tandemIds) => {
		console.log('in loadTandems service');
		console.log(tandemIds);
		return new Promise((resolve, reject) => {
			JumperApi.loadTandems(tandemIds).then((r) => {
				if (r.status == 200) {
					return resolve(r.data);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					return reject(false);
				}
			})
		})
	}

	loadTandemStudents = () => {
		return new Promise((resolve, reject) => {
			//////console.log('in loadTandemStudents');
			JumperApi.loadTodayTandemStudents().then((r) => {
				if (r.status == 200) {
					var Jumpers = r.data;

					var activeJumpersDisplay = [];

					this.tandemStudents = r.data;

					this.tandemStudentsLoaded = true;

					//this.sendTandemsToPublicJumper();

					var tandemStudentOptions = this.tandemStudents.map(jumper => { return { "label": jumper.firstname + " " + jumper.lastname, "value": jumper._id.toString() } });

					this.emitResults(tandemStudentOptions, 'tandemStudentsLoaded');

					return resolve(this.tandemStudentsLoaded);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(e => {
					//////console.log(e);
					this.emitError({ message: 'Incomplete or Invalid data present' });
				});
		});
	}

	//must be called after load tandem students
	loadRepeatTandemStudents = () => {
		console.log('in load repeat tandems');
		return new Promise((resolve, reject) => {

			//////console.log('in loadTandemStudents');
			JumperApi.getRepeatTandems().then((r) => {
				console.log(r);
				if (r.status == 200) {

					var Jumpers = r.data;

					var activeJumpersDisplay = [];
					console.log(r.data.repeatTandems);


					//this.tandemStudents = [...this.tandemStudents, ...r.data.repeatTandems];

					// ////console.log('inside loadRepeatTandemStudents',this.tandemStudents);

					this.tandemStudentsLoaded = true;

					//this.sendTandemsToPublicJumper();

					var tandemStudentOptions = this.tandemStudents.map(jumper => { return { "label": jumper.firstname + " " + jumper.lastname, "value": jumper._id.toString() } });

					//////console.log('tandemStudentOptions');

					//////console.log(t)
					//alert(JSON.stringify(this.tandemStudents));

					//alert(JSON.stringify(tandemStudentOptions));

					//////console.log(this.tandemStudents);

					this.emitResults(tandemStudentOptions, 'tandemStudentsLoaded');

					return resolve(this.tandemStudentsLoaded);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(e => {
					//console.log(e);
					this.emitError({ message: 'Incomplete or Invalid data present' });
				});
		});
	}

	/*
	loadTodayTandemReservations = () => {
		return new Promise((resolve, reject) => {
			ReportsApi.loadTodayTandemReservationSummary().then(r => {
				if (r.status == 200) {
					var jumperAllDailyActivities = r.data;
					this.dailyActivities = jumperAllDailyActivities;
					//////console.log(this.dailyActivities);
					this.emitResults(jumperAllDailyActivities, 'AllDailyActivities');
					resolve(jumperAllDailyActivities);
				}
				else if (r.status == 401) {
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitResults({}, 'AllDailyActivities');
					this.emitError({ message: 'could not get daily activity' });
				}
			})
				.catch(e => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
				})
		});
	}*/

	loadAllJumpers = () => {
		return new Promise((resolve, reject) => {
			JumperApi.initializeLocalJumpers().then((r) => {
				if (r.status == 200) {
					var Jumpers = r.data;
					var activeJumpersDisplay = [];

					this.jumpers = r.data.jumpers;
					this.tandemInstructors = r.data.tandemInstructors;
					this.videographers = r.data.videographers;
					this.affInstructors = r.data.affInstructors;
					this.coaches = r.data.coaches;
					this.affStudents = r.data.affStudents;
					this.iadStudents = r.data.iadStudents;
					this.pilots = r.data.pilots;
					this.staff = r.data.staff;

					this.jumpersLoaded = true;


					var pilotOptions = this.pilots.map(pilot => { return { "label": pilot.nickname + " " + pilot.lastname + " " + pilot.manifestNumber, "value": pilot } });

					var tandemInstructorOptions = this.tandemInstructors.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					var videographerOptions = this.videographers.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					var affInstructorOptions = this.affInstructors.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					var coachOptions = this.coaches.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					var jumperOptions = this.jumpers.map(jumper => {
						var label = jumper.nickname;
						if (jumper.lastname !== undefined) {
							label += " " + jumper.lastname;
						}
						if (jumper.manifestNumber !== undefined) {
							label += " " + jumper.manifestNumber;
						}
						if (jumper.nickname === "CUSTOMER") {
							//alert(label);
						}
						return { "label": label, "value": jumper }
					});

					jumperOptions = jumperOptions.filter(option => option.value.firstname !== "HOLD");

					var staffOptions = this.staff.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					var affStudentOptions = this.affStudents.map(jumper => { return { "label": jumper.firstname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					var iadStudentOptions = this.iadStudents.map(jumper => { return { "label": jumper.firstname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					this.HOLDJumperOption = staffOptions.filter(option => option.value.firstname === "HOLD" && option.value.lastname === "")[0];

					this.NONEJumperOption = staffOptions.filter(option => option.value.firstname === "NONE" && option.value.lastname === "")[0];

					this.holdJumperId = this.HOLDJumperOption.value._id;

					this.HOLDJumperOption.label = "HOLD";

					this.NONEJumperOption.label = "NONE";

					var option = tandemInstructorOptions.filter(option => option.value.firstname === "HOLD" && option.value.lastname === "")[0];
					option.label = "HOLD";

					var option = affInstructorOptions.filter(option => option.value.firstname === "HOLD" && option.value.lastname === "")[0];
					option.label = "HOLD";

					var option = videographerOptions.filter(option => option.value.firstname === "HOLD" && option.value.lastname === "")[0];
					option.label = "HOLD";

					////console.log("HoldJumperOption");
					////console.log(this.HOLDJumperOption);
					////console.log(this.NONEJumperOption);
					this.saveData('pilotOptions', pilotOptions);
					var options = {
						shouldSort: true,
						threshold: 0.5,
						location: 0,
						distance: 100,
						maxPatternLength: 32,
						minMatchCharLength: 1,
						keys: [
							"value.manifestNumber",
							"value.firstname",
							"value.lastname"
						]
					};

					//this.fuse = new Fuse(jumperOptions, options); // "list" is the item array
					////console.log("pilotoption:" pilotOptions);

					this.emitResults(pilotOptions, 'pilotsLoaded');
					this.emitResults(tandemInstructorOptions, 'tandemInstructorsLoaded');
					this.emitResults(videographerOptions, 'videographersLoaded');
					this.emitResults(affInstructorOptions, 'affInstructorsLoaded');
					this.emitResults(affStudentOptions, 'affStudentsLoaded');
					this.emitResults(iadStudentOptions, 'iadStudentsLoaded');
					this.emitResults(coachOptions, 'coachesLoaded');

					this.emitResults(jumperOptions, 'jumpersLoaded');
					this.emitResults(staffOptions, 'staffLoaded');

					//////console.log('befor resolving in loadAllJumpers');
					return resolve(this.JumpersLoaded);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			}, (err) => reject(err))
				.catch((err) => {
					////console.log(err);
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	searchStudentWaivers = (firstname, lastname) => {
		return new Promise((resolve, reject) => {
			JumperApi.searchStudentWaivers(firstname, lastname).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					this.emitResults(r.data, 'waiverSearch');
					resolve(r.data);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	searchTandemWaivers = (firstname, lastname) => {
		return new Promise((resolve, reject) => {
			JumperApi.searchTandemWaivers(firstname, lastname).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					this.emitResults(r.data, 'waiverSearch');
					resolve(r.data);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	attachWaiver = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.attachWaiver(payload).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					this.emitResults(r.data, 'waiverAttached');
					resolve(r.data);
					//this.emitResults(activeLoadDisplay,'createLoad');
				}
				else if (r.status === 500 || r.status === 404) {
					if (r.data && r.data.message) {
						swal({
							title: "Warning",
							text: r.data.message,
							icon: "warning",
							dangerMode: false,
						})
					}

					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	attachStudentWaiver = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.attachStudentWaiver(payload).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					this.emitResults(r.data, 'waiverAttached');
					resolve(r.data);
					//this.emitResults(activeLoadDisplay,'createLoad');
				}
				else if (r.status === 500 || r.status === 404) {
					if (r.data && r.data.message) {
						swal({
							title: "Warning",
							text: r.data.message,
							icon: "warning",
							dangerMode: false,
						})
					}
					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	sendReviewSms = (body) => {
		return new Promise((resolve, reject) => {
			JumperApi.sendReviewSms(body).then((r) => {
				if (r.status == 200) {
					//console.log(r.data);
					this.tandemDailyActivities[body.studentId] = r.data.dailyActivity;
					swal({
						title: "Success",
						text: "Review SMS sent successfully!",
						icon: "success",
						dangerMode: false,
					})
					resolve(true);
					//this.emitResults(activeLoadDisplay,'createLoad');
				} else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				} else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				} else {
					this.emitError({ message: 'Item could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	searchJumperOptionsUsingFuse = (text) => {
		////alert('in using fuse');
		//////console.log('in search Jumper options using fuse');
		//////console.log(this.fuse.search(text));
		return this.fuse.search(text);
	}

	setAllDailyActivities = (dailyActivities, tandemDailyActivities) => {
		this.dailyActivities = dailyActivities;
		if (tandemDailyActivities) {
			this.tandemDailyActivities = tandemDailyActivities;
		}
		////console.log(tandemDailyActivities);
		//alert('setting tandem Daily Activities');

	}

	getAllDailyActivities = () => {
		//////console.log('in getAllDailyActivities');
		return this.dailyActivities;
	}

	getAllTandemDailyActivities = () => {
		//////console.log('in getAllDailyActivities');
		return this.tandemDailyActivities;
	}

	getReviewSmsSent = (id) => {
		if (this.tandemDailyActivities[id]) {
			return this.tandemDailyActivities[id].reviewSmsSent;
		}
		else {
			return false;
		}
	}

	getJumperBlocksById = (id) => {
		console.log('getJumperBlocksById');
		console.log(id);

		if (this.dailyActivities[id.toString()] && this.dailyActivities[id.toString()].blocks) {
			return this.dailyActivities[id.toString()].blocks.filter(block => block.ticketsRemaining > 0);
		}
		var jmper = this.jumpers.filter(jumper => jumper._id.toString() === id.toString())[0];
		if (jmper) {
			return jmper.blocks;
		}
		return [];
	}
	getJumperBlockNamesById = (id) => {
		if (this.dailyActivities[id] && this.dailyActivities.blocks) {
			return this.dailyActivities.blocks.map(blk => blk.blockName);
		}
		var jmper = this.jumpers.filter(jumper => jumper._id.toString() === id.toString())[0];
		if (jmper) {
			return jmper.blocks.map(blk => blk.blockName);
		}
		return [];
	}
	getJumperById = (id) => {
		return this.jumpers.filter(jumper => jumper?._id.toString() === id.toString())[0];
	}
	getChargeJumperById = (id) => {
		return this.jumpers.filter(jumper => jumper?.globalRef === id)[0];
	}

	getTandemStudentById = (id) => {
		console.log(this.tandemStudents);
		console.log(this.repeatTandems);
		return this.tandemStudents.filter(jumper => jumper?._id?.toString() === id?.toString())[0];
	}

	jumpersAreLoaded = () => {
		return this.jumpersLoaded;
	}

	addTandemStudents = (tandemStudents) => {

		this.tandemStudents.push(...tandemStudents);

		this.tandemStudentsLoaded = true;

		this.sendTandemsToPublicJumper();

		var tandemStudentOptions = this.tandemStudents.map(jumper => { return { "label": jumper.firstname + " " + jumper.lastname, "value": jumper._id.toString() } });

		//////console.log('tandemStudentOptions');

		////////console.log(t)
		//alert(JSON.stringify(this.tandemStudents));

		//alert(JSON.stringify(tandemStudentOptions));

		//////console.log(this.tandemStudents);

		this.emitResults(tandemStudentOptions, 'tandemStudentsLoaded');
	}

	tandemStudentsAreLoaded = () => {
		return this.tandemStudentsLoaded;
	}

	getTeams = () => {
		return this.jumpers.filter(j => j.isTeam);
	}

	getTeamOptions = () => {
		let teamOptions = this.jumpers.filter(jmp => jmp.isTeam).map(ele => {
			let newObj = {}
			newObj.label = ele.teamname;
			newObj.value = ele;
			return newObj;
		})
		return teamOptions
	}
	getJumperOptions = () => {
		var jumperOptions = this.jumpers.map(jumper => {
			var label = jumper.nickname;
			if (jumper.lastname) {
				label += " " + jumper.lastname;
			}
			if (jumper.manifestNumber) {
				label += " " + jumper.manifestNumber;
			}

			return { "label": label, "value": jumper }
		});

		return jumperOptions;
	}

	getAdminJumperOptions = () => {
		return this.jumpers.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname, "value": jumper } });
	}

	getTandemInstructorOptions = () => {
		return this.tandemInstructors.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getTandemInstructorCheckedInOptions = () => {
		var activeTis = this.getTodayActiveTIs();
		if (activeTis.length > 0) {
			return [this.HOLDJumperOption, this.NONEJumperOption, ...activeTis.map(activeStaff => this.tandemInstructors.filter(staff => staff._id === activeStaff.jumperId)[0]).filter(jumper => jumper !== undefined).map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } })];
		}
		else {
			return [];
		}
	}

	removeTandemStudent = (id) => {
		var indx = this.tandemStudents.findIndex(stud => stud?._id.toString() === id.toString());
		if (indx >= 0) {
			this.tandemStudents.splice(indx, 1);
		}
	}
	getTandemStudentOptions = () => {
		//////console.log('getting tandem students');
		//////console.log(this.tandemStudents);
		return this.tandemStudents.filter(tandem => tandem !== null).map(jumper => { return { "label": jumper?.firstname + " " + jumper?.lastname, "value": jumper?._id.toString() } });
	}

	getTandemStudentNonGroupOptions = () => {
		//////console.log('getting tandem students');
		//////console.log(this.tandemStudents);
		console.log('in get tandemstudents no group');
		console.log(this.tandemDailyActivities);
		return Object.keys(this.tandemDailyActivities).filter(key => this.tandemDailyActivities[key].groupId === undefined || this.tandemDailyActivities[key].groupId === null || this.tandemDailyActivities[key].groupId === "0" || this.tandemDailyActivities[key].groupId === "")
			.map(key => { return { "label": this.tandemDailyActivities[key].firstname + " " + this.tandemDailyActivities[key].lastname, "value": this.tandemDailyActivities[key].studentId.toString() } });

	}

	getStaffOptions = () => {
		return this.staff.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getAffInstructorOptions = () => {
		return this.affInstructors.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getAffInstructorCheckedInOptions = () => {
		//var activeTis = getTodayActiveTIs();
		var activeAFFis = this.getTodayActiveAFFIs();
		if (activeAFFis.length > 0) {
			return [this.HOLDJumperOption, this.NONEJumperOption, ...activeAFFis.map(activeStaff => this.affInstructors.filter(staff => staff._id === activeStaff.jumperId)[0]).filter(jumper => jumper !== undefined).map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } })];
		}
		else {
			return [];
		}
	}

	getVideographerOptions = () => {

		return this.videographers.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getVideographerCheckedInOptions = () => {
		var activeV = this.getTodayActiveVideographers();
		if (activeV.length > 0) {
			return [this.HOLDJumperOption, this.NONEJumperOption, ...activeV.map(activeStaff => this.videographers.filter(staff => staff._id === activeStaff.jumperId)[0]).filter(jumper => jumper !== undefined).map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } })];
		}
		else {
			return []
		}
	}

	getAffStudentOptions = () => {
		return this.affStudents.map(jumper => { return { "label": jumper.firstname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getIadStudentOptions = () => {
		return this.iadStudents.map(jumper => { return { "label": jumper.firstname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getCoachOptions = () => {
		return this.coaches.filter(jumper => this.dailyActivities[jumper._id] && this.dailyActivities[jumper._id].isCoach && this.dailyActivities[jumper._id].isCheckedIn).map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getCoachCheckedInOptions = () => {
		//var activeTis = getTodayActiveTIs();
		var activeCoaches = this.getTodayActiveCoaches();
		////console.log(activeCoaches);
		////console.log(this.HOLDJumperOption);
		////console.log(this.NONEJumperOption);
		if (activeCoaches.length > 0) {
			return [this.HOLDJumperOption, this.NONEJumperOption, ...activeCoaches.map(activeStaff => [...this.coaches, ...this.affInstructors].filter(staff => staff._id === activeStaff.jumperId)[0]).filter(jumper => jumper !== undefined).map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } })];
		}
		else {
			return [];
		}
	}

	getPilotOptions = () => {
		return this.pilots.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });
	}

	getHoldJumperId = () => {
		return this.holdJumperId;
	}

	mapFunJumpForRender = (funJump) => {
		var funJumpForRender = {};
		funJumpForRender.jumpId = funJump.jumpId;
		funJumpForRender.jumpType = funJump.jumpType;
		//////console.log(this.jumpers);
		funJumpForRender.jumpers = funJump.jumperIds.map(jumperId => {
			////alert(jumperId);
			var jumper = this.jumpers.filter(jumper => jumper._id.toString() === jumperId)[0];

			////alert(jumper);
			if (jumper != undefined) {
				return {
					_id: jumper._id,
					firstname: jumper.nickname,
					lastname: jumper.lastname,
					memberId: jumper.manifestNumber,
					gearRental: funJump.gearRentals.indexOf(jumper._id) >= 0
				};
			}
			else {
				return {
					_id: 'NONE',
					firstname: 'NONE',
					lastname: 'NONE',
					memberId: 'NONE',
					gearRental: 'NONE'
				};
			}
		});


		if (funJump.gearRentals && funJump.gearRentals.length >= 0) {
			funJumpForRender.gearRentals = funJump.gearRentals;
		}
		return funJumpForRender;

	}


	mapCoachJumpForRender = (coachJump) => {
		//////console.log('in mapCoachForRender');
		//////console.log(coachJump);
		var coachJumpForRender = {};
		coachJumpForRender.jumpId = coachJump.jumpId;

		var coach = this.staff.filter((coach) => coach._id.toString() === coachJump.coachId)[0];
		//////console.log(coach)
		coachJumpForRender.coach = {
			_id: coach._id,
			firstname: coach.firstname,
			lastname: coach.lastname,
			memberId: coach.manifestNumber
		};

		var jumper = this.jumpers.filter((jumper) => jumper._id.toString() === coachJump.jumperId)[0];

		//////console.log(jumper)
		coachJumpForRender.jumper = {
			_id: jumper._id,
			firstname: jumper.firstname,
			lastname: jumper.lastname
		}

		if (coachJump.price !== undefined) {
			coachJumpForRender.price = coachJump.price;
		}

		return coachJumpForRender;
	}

	mapTandemForRender = (tandemJump) => {
		//////console.log('in mapTandemForRender');
		//////console.log(tandemJump);
		var tandemJumpForRender = {};
		tandemJumpForRender.jumpId = tandemJump.jumpId;

		var instructor = this.staff.filter((instructor) => instructor._id.toString() === tandemJump.instructorId)[0];
		//////console.log(instructor)
		tandemJumpForRender.instructor = {
			_id: instructor._id,
			firstname: instructor.firstname,
			lastname: instructor.lastname,
			memberId: instructor.manifestNumber,
		};

		var student = this.tandemStudents.filter((student) => student._id.toString() === tandemJump.studentId)[0];

		//////console.log(student)
		if (student) {
			tandemJumpForRender.student = {
				_id: student._id,
				firstname: student.firstname,
				lastname: student.lastname
			}
		}
		else {
			tandemJumpForRender.student = {
				_id: 'none',
				firstname: 'HOLD',
				lastname: ''
			}
		}

		if (tandemJump.videographerId) {
			var videographer = this.staff.filter((videographer) => videographer._id.toString() === tandemJump.videographerId)[0];
			tandemJumpForRender.videographer = {
				_id: videographer._id,
				firstname: videographer.firstname,
				lastname: videographer.lastname,
				memberId: videographer.manifestNumber
			}
		}

		if (tandemJump.price !== undefined) {
			tandemJumpForRender.price = tandemJump.price;
		}

		return tandemJumpForRender;
	}

	mapAffForRender = (affJump) => {
		//////console.log('in mapAffForRender');
		//////console.log(affJump);
		//////console.log(this.affInstructors);
		var affJumpForRender = {};

		affJumpForRender.jumpId = affJump.jumpId;
		affJumpForRender.level = affJump.level;

		var mainInstructor = this.staff.filter((instructor) => instructor._id.toString() === affJump.mainInstructorId)[0];

		//////console.log(mainInstructor);

		//////console.log(student)
		if (mainInstructor !== undefined) {
			affJumpForRender.mainInstructor = {
				_id: mainInstructor._id,
				firstname: mainInstructor.firstname,
				lastname: mainInstructor.lastname,
				memberId: mainInstructor.manifestNumber
			}
		}
		else {
			affJumpForRender.mainInstructor = {
				_id: 'none',
				firstname: 'HOLD',
				lastname: '',
				memberId: ''
			}
		}

		// affJumpForRender.mainInstructor ={
		// 		_id : mainInstructor._id,
		// 		firstname : mainInstructor.firstname,
		// 		lastname : mainInstructor.lastname,
		// 		memberId : mainInstructor.manifestNumber
		// };

		//////console.log(this.affStudents);
		var student = this.affStudents.filter((student) => student._id.toString() === affJump.studentId)[0];
		//////console.log(student);

		if (student !== undefined) {
			affJumpForRender.student = {
				_id: student._id,
				firstname: student.firstname,
				lastname: student.lastname
			}
		}
		else {
			affJumpForRender.student = {
				_id: 'None',
				firstname: 'HOLD',
				lastname: ''
			}
		}

		if (affJump.secondaryInstructorId) {
			var secondaryInstructor = this.staff.filter((instructor) => instructor._id.toString() === affJump.secondaryInstructorId)[0];

			//alert('in secondary instructor aff render : ' + JSON.stringify(secondaryInstructor));
			affJumpForRender.secondaryInstructor = {
				_id: secondaryInstructor._id,
				firstname: secondaryInstructor.firstname,
				lastname: secondaryInstructor.lastname,
				memberId: secondaryInstructor.manifestNumber
			}
		}

		if (affJump.videographerId) {
			var videographer = this.staff.filter((videographer) => videographer._id.toString() === affJump.videographerId)[0];
			affJumpForRender.videographer = {
				_id: videographer._id,
				firstname: videographer.firstname,
				lastname: videographer.lastname,
				memberId: videographer.manifestNumber
			}
		}

		if (affJump.price !== undefined) {
			affJumpForRender.price = affJump.price;
		}

		return affJumpForRender;
	}


	getJumpers = () => {
		//////console.log('in getJumpers()');
		//////console.log(this.jumpers);
		return this.jumpers;
	}


	AddRating = (manifestNumber, rating) => {
		////alert('in add Rating');
		return new Promise((resolve, reject) => {
			JumperApi.addRating(manifestNumber, rating).then((r) => {
				if (r.status == 200) {
					////alert('done adding rating');
					//////console.log(r.data);
					this.loadAllJumpers();
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Rating could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	syncWithUspa = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.syncWithUspa(id).then((r) => {
				if (r.status == 200) {
					//handle in socket
					if (r.data.jumper?._id === undefined) {
						swal({
							title: "Warning",
							text: "Unable to sync with uspa",
							icon: "warning",
							dangerMode: false,
						})
					}
					else {
						var dailyActivity = r.data.dailyActivity;
						if (dailyActivity) {
							this.dailyActivities[dailyActivity.jumperId] = dailyActivity
							this.emitResults({ id: id, dailyActivity: dailyActivity }, 'itemsAdded');
						}
						this.emitResults(r.data.jumper, 'jumperUpdated');
					}
					resolve(true);

					//////console.log(r.data);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					swal({
						title: "Warning",
						text: "Unable to sync with uspa",
						icon: "warning",
						dangerMode: false,
					})
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	updateJumper = (id, JumperBody) => {
		//alert('update Jumper');
		return new Promise((resolve, reject) => {
			JumperApi.UpdateJumper(id, JumperBody).then((r) => {
				if (r.status == 200) {
					//handle in socket
					resolve(r.data.jumper);
					var dailyActivity = r.data.dailyActivity;
					if (dailyActivity) {

						this.emitResults({ id: id, dailyActivity: dailyActivity }, 'itemsAdded');
					}
					this.emitResults(r.data.jumper, 'jumperUpdated');
					//////console.log(r.data);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	LoadGlobalJumperOptions = (searchString) => {
		return new Promise((resolve, reject) => {
			JumperApi.loadGlobalJumperOptions(searchString).then((r) => {
				if (r.status == 200) {
					//////console.log('done getting global options');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetTandemOptions = (searchString) => {
		return new Promise((resolve, reject) => {
			JumperApi.getTandemOptions(searchString).then((r) => {
				if (r.status == 200) {
					//////console.log('done getting global options');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetJumperOptions = (searchString) => {
		return new Promise((resolve, reject) => {
			JumperApi.getJumperOptions(searchString).then((r) => {
				if (r.status == 200) {
					//////console.log('done getting global options');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetReservationOptions = (searchString) => {
		return new Promise((resolve, reject) => {
			JumperApi.getReservationOptions(searchString).then((r) => {
				if (r.status == 200) {
					//////console.log('done getting global options');
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetLastTransaction = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.getLastTransaction(id).then((r) => {
				if (r.status == 200) {
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	deleteJumper = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.deleteJumperLocally(id).then((r) => {
				if (r.status == 200) {
					var jumper = r.data;

					this.jumpers = this.jumpers.filter(jp => jp._id.toString() !== jumper._id.toString());

					this.tandemInstructors = this.tandemInstructors.filter(jp => jp._id.toString() !== jumper._id.toString());

					this.videographers = this.videographers.filter(jp => jp._id.toString() !== jumper._id.toString());

					this.affInstructors = this.affInstructors.filter(jp => jp._id.toString() !== jumper._id.toString());

					this.coaches = this.coaches.filter(jp => jp._id.toString() !== jumper._id.toString());

					this.affStudents = this.affStudents.filter(jp => jp._id.toString() !== jumper._id.toString());

					this.pilots = this.pilots.filter(jp => jp._id.toString() !== jumper._id.toString());

					this.staff = this.staff.filter(jp => jp._id.toString() !== jumper._id.toString());

					resolve(r.data);

					this.emitResults(true, 'deleteEvent');
				}
				else if (r.status === 202) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be deleted, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	GetTandemTransaction = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.getTandemLastCharge(id).then((r) => {
				if (r.status == 200) {
					resolve(r.data);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	teamCheckin = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.teamcheckin(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}
	trackJumper = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.trackjumper(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	LOCheckin = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.LOcheckin(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	OVCheckin = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.OVcheckin(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	Checkin = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.checkin(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	CheckoutAll = () => {
		return new Promise((resolve, reject) => {
			JumperApi.checkoutall().then((r) => {
				if (r.status == 200) {


					//////console.log(otherActivities);
					//////console.log('retrieved daily activity');
					//////console.log(jumperDailyActivity);
					this.emitResults(r.data, 'checkoutAll');
					resolve(true);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	teamCheckout = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.teamcheckout(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});

	}



	stopTrackingJumper = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.stoptrackingjumper(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	LOCheckout = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.LOcheckout(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	OVCheckout = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.OVcheckout(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	Checkout = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.checkout(id).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	updateJumpRequest = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.updateJumpRequest(id, payload).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					//this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	updateStaffOrderNumber = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.updateStaffOrderNumber(id, payload).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[id] = jumperDailyActivity;


					//console.log(otherActivities);
					//console.log('retrieved daily activity');
					//console.log(jumperDailyActivity);
					//this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: false }, 'dailyActivity');
					resolve(jumperDailyActivity);
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	CreateJumper = (payload) => {
		this.emitResults(true, 'InitilizeLoader');
		return new Promise((resolve, reject) => {
			JumperApi.createJumper(payload).then((r) => {
				if (r.status == 200) {
					//////console.log(r);
					//handles in socket listener;
					var jumper = r.data;
					this.emitResults(jumper, 'jumperCreated');
					//alert(r.data);
					resolve(r.data);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
					var result = false;
					resolve(result);
				}
				this.emitResults(false, 'InitilizeLoader');
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					this.emitResults(false, 'InitilizeLoader');
					var result = false;
					resolve(result);
				});
		});
	}



	addCardOnFile = (id, payload) => {
		////alert('in create jumper jumper store');
		return new Promise((resolve, reject) => {
			JumperApi.addCardOnFile(id, payload).then((r) => {
				if (r.status == 200) {

					var jumper = r.data;

					var jumpers = this.jumpers.filter(jumper => jumper._id === r.data._id);

					if (jumpers.length > 0) {
						//alert('updating jumper list');
						var jumpersArr = this.jumpers;

						jumpersArr.filter(jumper => jumper._id === r.data._id)[0].cardsOnFile = r.data.cardsOnFile;
						this.jumpers = jumpersArr;
					}

					//alert(JSON.stringify(r.data));
					//alert(JSON.stringify(this.jumpers.filter(jumper => jumper._id === r.data._id)[0]));
					var jumperOptions = this.jumpers.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					this.emitResults(jumperOptions, 'jumpersLoaded');
					this.emitResults(r.data.cardsOnFile, 'cardAddEvent');
					//////console.log('Success')
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {

					this.emitError({ message: 'Jumper could not be created, please contact support.' });
					resolve(false)
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	addCardOnFileUpdate = (id, payload, mode) => {
		////alert('in create jumper jumper store');
		return new Promise((resolve, reject) => {
			var jumpers = this.jumpers.filter(jumper => jumper._id === id)[0];
			if (mode === "dizio ach") {
				jumpers.dizioAchOnFile.push(payload);
			}
			else {
				jumpers.cardsOnFile.push(payload);
			}
			JumperApi.UpdateJumper(id, jumpers).then((r) => {
				if (r.status == 200) {

					var jumper = r.data.jumper;


					if (jumper != null) {
						//alert('updating jumper list');
						var jumpersArr = this.jumpers;

						jumpersArr.filter(jumper => jumper._id === r.data.jumper._id)[0] = r.data;
						this.jumpers = jumpersArr;
					}

					//alert(JSON.stringify(r.data));
					//alert(JSON.stringify(this.jumpers.filter(jumper => jumper._id === r.data._id)[0]));
					var jumperOptions = this.jumpers.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					this.emitResults(jumperOptions, 'jumpersLoaded');
					this.emitResults(r.data.jumper, 'jumperUpdated')

					if (mode === "dizio ach") {
						//this.emitResults(r.data.cardsOnFile, 'cardAddEvent');
					}
					else {
						this.emitResults(r.data.cardsOnFile, 'cardAddEvent');
					}
					//////console.log('Success')
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {

					this.emitError({ message: 'Jumper could not be created, please contact support.' });
					resolve(false)
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	addAccountOnFile = (id, payload) => {
		////alert('in create jumper jumper store');
		return new Promise((resolve, reject) => {
			var jumpers = this.jumpers.filter(jumper => jumper._id === id)[0];
			jumpers.accountOnFile.push(payload);
			JumperApi.UpdateJumper(id, jumpers).then((r) => {
				if (r.status == 200) {

					var jumper = r.data.jumper;

					// var jumpers = this.jumpers.filter(jumper => jumper._id === r.data._id);

					if (jumper != null) {
						//alert('updating jumper list');
						var jumpersArr = this.jumpers;

						jumpersArr.filter(jumper => jumper._id === r.data.jumper._id)[0] = r.data;
						this.jumpers = jumpersArr;
					}

					//alert(JSON.stringify(r.data));
					//alert(JSON.stringify(this.jumpers.filter(jumper => jumper._id === r.data._id)[0]));
					var jumperOptions = this.jumpers.map(jumper => { return { "label": jumper.nickname + " " + jumper.lastname + " " + jumper.manifestNumber, "value": jumper } });

					this.emitResults(jumperOptions, 'jumpersLoaded');
					this.emitResults(r.data.jumper.accountOnFile, 'accountAddEvent');
					this.emitResults(r.data.jumper, 'jumperUpdated')
					//////console.log('Success')
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Jumper could not be created, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	removeCardOnFile = (jumperid, id) => {
		return new Promise((resolve, reject) => {
			JumperApi.RemoveCardOnFile(jumperid, id).then((r) => {
				if (r.status == 200) {
					var jumper = r.data;
					this.emitResults(jumper, 'deleteCardEvent');
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'card could not be removed, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	removeAccountOnFile = (jumperid, id) => {
		return new Promise((resolve, reject) => {
			JumperApi.RemoveAccountOnFile(jumperid, id).then((r) => {
				if (r.status == 200) {
					var jumper = r.data;
					this.emitResults(jumper, 'deleteAccountEvent');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'card could not be removed, please contact support.' });
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	addTip = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.addTip(payload).then((r) => {
				if (r.status == 200) {
					//alert('added tip');
					console.log('after adding tip');
					console.log(r.data);
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'tip');

					this.emitResults({ id: payload.fromId, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'itemsAdded');

					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	transferInternalTandem = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.TransferInternalTandem(payload).then((r) => {
				if (r.status == 200) {
					console.log('after internal transfer');
					//////console.log('Success');
					console.log(r.data);
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');

					this.tandemDailyActivities[r.data.dailyActivity.studentId] = r.data.dailyActivity;
					r.data?.otherActivities?.forEach(activity => {
						this.tandemDailyActivities[activity.studentId] = activity;
					})

					this.emitResults({ id: r.data?.dailyActivity?.studentId, dailyActivity: r.data?.dailyActivity, otherActivities: r.data?.otherAccounts, tandemGroup: r.data?.tandemGroup }, 'setPackage');

					//this.emitResults({ jumperId: payload.fromId, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherActivities, isTandemStudent: true, isTandemGroup: true, tandemGroup: r.data?.tandemGroup }, 'dailyActivity');
					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	transferCreditToTandem = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.TransferCreditToTandem(payload).then((r) => {
				if (r.status == 200) {
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');
					if (payload.isFromTandem) {
						this.emitResults({ id: payload.fromId, dailyActivity: r.data.dailyActivity, otherActivities: r.data.otherAccounts, tandemGroup: r.data.tandemGroup }, 'itemsAdded');
					}
					else {
						this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					}
					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	transferCredit = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.TransferCredit(payload).then((r) => {
				if (r.status == 200) {
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');
					this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	upgradeBlockTickets = (jumperId, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.UpgradeBlockTickets(jumperId, payload).then((r) => {
				if (r.status == 200) {
					console.log('return block tickets then');

					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.setDailyActivity(r.data.dailyActivity);
					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					this.emitResults({ id: jumperId, dailyActivity: r.data.dailyActivity }, 'itemsAdded');
					this.emitResults(true, 'ticketsReturnedEvent');

					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	returnBlockTickets = (jumperId, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.ReturnBlockTickets(jumperId, payload).then((r) => {
				if (r.status == 200) {
					console.log('return block tickets then');

					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.setDailyActivity(r.data.dailyActivity);
					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					this.emitResults({ id: jumperId, dailyActivity: r.data.dailyActivity }, 'itemsAdded');
					this.emitResults(true, 'ticketsReturnedEvent');

					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	removeBlockTickets = (jumperId, payload) => {

		return new Promise((resolve, reject) => {
			JumperApi.RemoveBlockTickets(jumperId, payload).then((r) => {
				console.log('removed block tickets then');

				if (r.status == 200) {
					console.log('removed block tickets status 200');
					console.log(r.data);
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.setDailyActivity(r.data.dailyActivity);
					//var activeLoadDisplay = this.transformLoad(r.data);
					//var newActivity = "Load " + r.data.loadNum + " created";
					this.emitResults({ id: jumperId, dailyActivity: r.data.dailyActivity }, 'itemsAdded');
					this.emitResults(true, 'ticketsReturnedEvent');

					//console.log(r.data.summary);
					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					if (r.data.message) {
						swal({
							title: "Warning",
							text: r.data.message,
							icon: "warning",
							dangerMode: false,
						})
						this.emitResults(true, 'ticketsReturnedEvent');

					}
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	transferBlockTickets = (payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.TransferBlockTickets(payload).then((r) => {

				console.log(r);

				if (r.status == 200) {
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');
					this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	removeJoinTandems = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.removeJoinTandems(id, payload).then((r) => {
				if (r.status == 200) {
					console.log('remove join tandems');
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					////console.log(otherActivities);
					//this.todayWaivers = r.data.todayWaivers;
					var isTandemGroup = false;
					if (r.data?.tandemGroup) {
						isTandemGroup = true;
					}
					console.log(isTandemGroup);
					//////console.log('retrieved daily activity');
					//////console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: true, isTandemGroup: isTandemGroup, tandemGroup: r.data?.tandemGroup }, 'dailyActivity');
					resolve(jumperDailyActivity);
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	toggleStudentWaitingStatus = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.toggleStudentWaitingStatus(id).then((r) => {
				if (r.status == 200) {
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					//this.emitResults(r.data, 'studentLogSubmitted');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	setStudentToLicensedStatus = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.setStudentToLicensedStatus(id).then((r) => {
				if (r.status == 200) {
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					//this.emitResults(r.data, 'studentLogSubmitted');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {

					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	updateManifestNumber = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.updateManifestNumber(id).then((r) => {
				if (r.status == 200) {
					var dailyActivity = r.data.dailyActivity;
					if (dailyActivity) {
						this.emitResults({ id: id, dailyActivity: dailyActivity }, 'itemsAdded');
					}
					this.emitResults(r.data.jumper, 'jumperUpdated');
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					//this.emitResults(r.data, 'studentLogSubmitted');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);
					resolve(true);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {

					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	getStudentLogs = (id) => {
		return new Promise((resolve, reject) => {
			JumperApi.getStudentLogs(id).then((r) => {
				if (r.status == 200) {
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(r.data, 'studentLogSubmitted');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	submitStudentLog = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.submitStudentLog(id, payload).then((r) => {
				if (r.status == 200) {
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(r.data, 'studentLogSubmitted');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}




	mergeJumpers = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.mergeJumpers(id, payload).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;

					////console.log(otherActivities);
					//////console.log('retrieved daily activity');
					//////console.log(jumperDailyActivity);
					this.emitResults({ jumperId: jumperDailyActivity.jumperId, dailyActivity: jumperDailyActivity, otherActivities: otherActivities }, 'dailyActivity');

					resolve(jumperDailyActivity);
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status === 202) {
					swal({
						title: "Warning",
						text: r.data.message,
						icon: "warning",
						dangerMode: false,
					})
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	mergeTandems = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.mergeTandems(id, payload).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: true }, 'dailyActivity');
					resolve(jumperDailyActivity);
					this.emitResults(true, 'creditTransferEvent');

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	joinTandems = (id, payload) => {
		return new Promise((resolve, reject) => {
			JumperApi.joinTandems(id, payload).then((r) => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					var tandemGroup = r.data.tandemGroup;
					////console.log(otherActivities);
					//////console.log('retrieved daily activity');
					//////console.log(jumperDailyActivity);
					this.emitResults({ jumperId: id, dailyActivity: jumperDailyActivity, otherActivities: otherActivities, isTandemStudent: true, isTandemGroup: true, tandemGroup: tandemGroup }, 'dailyActivity');

					resolve(jumperDailyActivity);
					//////console.log('Success');
					//alert(JSON.stringify(r.data.to, null,2));
					//////console.log(r.data.to);
					//////console.log(r.data.from);
					this.emitResults(true, 'creditTransferEvent');
					//this.emitResults({ toId: payload.toId, fromId: payload.fromId, toActivity: r.data.to, fromActivity: r.data.from }, 'transfer');
					//reportsStore.setDropzoneTodayActivity(r.data.summary);

				}
				else if (r.status === 500 || r.status === 404) {
					if (r.data.message) {
						swal({
							title: "Warning",
							text: r.data.message,
							icon: "warning",
							dangerMode: false,
						})
						this.emitResults(true, 'ticketsReturnedEvent');

					}
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Credit Could Not be transfer, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}


	searchWaiver = (id) => {
		var index = this.tandemStudents.findIndex(row => row?._id === id);
		if (index >= 0) {
			if ((this.tandemStudents[index].siteWaiverId !== undefined && this.tandemStudents[index].siteWaiverId !== "") ||
				this.tandemStudents[index].tandemWaiverId !== undefined && this.tandemStudents[index].tandemWaiverId != "") {
				return this.tandemStudents[index];
			}
			else {
				return {};
			}
		}
		else {
			return {};
		}
	}

	downloadWaivers = () => {
		return new Promise((resolve, reject) => {
			JumperApi.downloadWaivers().then((r) => {
				if (r.status == 200) {

				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {
					this.emitError({ message: 'Could not download waivers, please contact support.' });
					this.emitResults(true, 'creditTransferEvent');
				}
			})
				.catch((err) => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	//clever waiver functionality generate printalbe 1 sheet for skydive AZ
	printWaiverOneSheet = (waiverId) => {
		return new Promise((resolve, reject) => {
			JumperApi.printWaiverOneSheet(waiverId).then((r) => {
				if (r.status == 200) {
					const loadsData = r.data;
					swal({
						title: "Details Downloaded",
						text: r.data.message,
						icon: "success",
						dangerMode: false,
					})
					resolve(loadsData);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	sendRenewWaiver = (waiverId) => {
		return new Promise((resolve, reject) => {
			JumperApi.sendRenewWaiver(waiverId).then((r) => {
				if (r.status == 200) {
					const loadsData = r.data;
					swal({
						title: "Renew Waiver Sent",
						text: r.data.message,
						icon: "success",
						dangerMode: false,
					})
					resolve(loadsData);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else if (r.status === 500 || r.status === 404) {
					this.emitResults(true, 'warningEvent');
					resolve(false);
				}
				else {
					this.emitError({ message: 'Load could not be created, please contact support.' });
				}
			})
				.catch(err => {
					this.emitError({ message: 'Incomplete or Invalid data present' });
					reject(err);
				});
		});
	}

	setTandemStudentDailyActivity = (activity) => {
		console.log('in setTandemStudent')
		this.tandemDailyActivities[activity.studentId] = activity;
	}

	setDailyActivity = (activity) => {
		this.dailyActivities[activity.jumperId] = activity;
	}

	dataLoaded = () => {
		this.emitResults({}, 'staffStatusUpdate');
	}

	getTodayActiveStudents = () => {
		var keys = Object.keys(this.dailyActivities);
		var activeStudents = [];
		keys.forEach(key => {
			if (this.dailyActivities[key].isStudent && this.dailyActivities[key].isCheckedIn) {
				activeStudents.push(this.dailyActivities[key]);
			}
		})
		return activeStudents;
	}

	getTodayActiveTIs = () => {
		////console.log('in getTodayActiveTIs');
		var keys = Object.keys(this.dailyActivities);
		var activeTIs = [];
		keys.forEach(key => {
			if (this.dailyActivities[key].isTI && this.dailyActivities[key].isCheckedIn) {
				activeTIs.push(this.dailyActivities[key]);
			}
		})

		activeTIs = activeTIs.sort((a, b) => a.checkedInOrderNumber - b.checkedInOrderNumber);
		////console.log(activeTIs);
		return activeTIs;
	}

	getTodayActiveAFFIs = () => {
		////console.log('in getTodayActiveAFFIs');
		console.log('wtf get Today Active AFFIs');

		var keys = Object.keys(this.dailyActivities);
		var activeAFFIs = [];
		keys.forEach(key => {
			if (this.dailyActivities[key].isAFFI && this.dailyActivities[key].isCheckedIn) {
				activeAFFIs.push(this.dailyActivities[key]);
			}
		})

		activeAFFIs = activeAFFIs.sort((a, b) => a.checkedInOrderNumber - b.checkedInOrderNumber);

		////console.log(activeAFFIs);

		return activeAFFIs;
	}

	getTodayActiveCoaches = () => {
		////console.log('in getTodayActiveCoaches');
		console.log('wtf get Today Active coaches');

		var keys = Object.keys(this.dailyActivities);
		var activeCoaches = [];
		keys.forEach(key => {
			if ((this.dailyActivities[key].isCoach || this.dailyActivities[key].isAFFI) && this.dailyActivities[key].isCheckedIn) {
				activeCoaches.push(this.dailyActivities[key]);
			}
		})
		return activeCoaches;
	}
	getTodayActiveTeams = () => {
		console.log('in getTodayActiveLOs');
		var keys = Object.keys(this.dailyActivities);
		var activeTeams = [];
		keys.forEach(key => {
			if (this.dailyActivities[key].isTeam && this.dailyActivities[key].isTeamCheckedIn) {
				activeTeams.push(this.dailyActivities[key]);
			}
		})

		//activeLOs = activeLOs.sort((a,b) => a.checkedInOrderNumber - b.checkedInOrderNumber);
		console.log(activeTeams);
		return activeTeams;
	}

	getTodayTrackedJumpers = () => {
		console.log('in getTodayTrackedJumpers');
		var keys = Object.keys(this.dailyActivities);
		var activeTeams = [];
		keys.forEach(key => {
			if (this.dailyActivities[key].tracked) {
				activeTeams.push(this.dailyActivities[key]);
			}
		})
		//activeLOs = activeLOs.sort((a,b) => a.checkedInOrderNumber - b.checkedInOrderNumber);
		console.log(activeTeams);
		return activeTeams;
	}

	getTodayActiveLOs = () => {
		console.log('in getTodayActiveLOs');
		var keys = Object.keys(this.dailyActivities);
		var activeLOs = [];
		keys.forEach(key => {
			if (this.dailyActivities[key].isLO && this.dailyActivities[key].isLOCheckedIn) {
				activeLOs.push(this.dailyActivities[key]);
			}
		})

		//activeLOs = activeLOs.sort((a,b) => a.checkedInOrderNumber - b.checkedInOrderNumber);
		console.log(activeLOs);
		return activeLOs;
	}
	getTodayActiveVideographers = () => {
		var keys = Object.keys(this.dailyActivities);
		var activeVideographers = [];
		console.log('wtf get Today Active Videographers');
		keys.forEach(key => {
			if (ldStore.getOvSeparateCheckinSetting()) {
				if (this.dailyActivities[key].isVideographer && this.dailyActivities[key].isOVCheckedIn) {
					activeVideographers.push(this.dailyActivities[key]);
				}
			}
			else {
				if (this.dailyActivities[key].isVideographer && this.dailyActivities[key].isCheckedIn) {
					activeVideographers.push(this.dailyActivities[key]);
				}
			}

		})

		activeVideographers = activeVideographers.sort((a, b) => a.checkedInOVOrderNumber - b.checkedInOVOrderNumber);

		return activeVideographers;
	}

	getTodayActivePackers = () => {
		var keys = Object.keys(this.dailyActivities);
		var activePackers = [];
		keys.forEach(key => {
			if (this.dailyActivities[key].isPacker && this.dailyActivities[key].isCheckedIn) {
				activePackers.push(this.dailyActivities[key]);
			}
		})
		return activePackers;
	}

	getTotalTandemManifested = (tandemStudents) => {
		var numManifested = 0;
		tandemStudents.forEach(student => {
			if (this.tandemDailyActivities[student._id] && this.tandemDailyActivities[student._id].manifestStatus.length > 0) {
				numManifested++;
			}
		})
		return numManifested;
	}

	getTotalTandemJumped = (tandemStudents) => {
		var numJumped = 0;
		tandemStudents.forEach(student => {
			if (this.tandemDailyActivities[student._id] && this.tandemDailyActivities[student._id].jumpedStatus.length > 0) {
				numJumped++;
			}
		})
		return numJumped;
	}


	getJumperDotOptions = (jumper, jump) => {
		return new Promise((resolve, reject) => {
			JumperApi.getDailyActivity(jumper._id?.toString()).then(r => {
				if (r.status == 200) {
					var jumperDailyActivity = r.data.dailyActivity;
					var otherActivities = r.data.otherAccounts;
					this.dailyActivities[jumper._id?.toString()] = jumperDailyActivity;
					var dotOptions = [];
					dotOptions.push({ txt: "view activity" });
					dotOptions.push({ txt: "remove jumper" });
					if (jumper.isVideographer) {
						dotOptions.push({ txt: 'remove as videographer' });
					}
					else {
						dotOptions.push({ txt: 'mark as videographer' });
					}
					if (jump && jump.teamId) {
						if (jumper.chargeTo) {
							dotOptions.push({ txt: 'charge to jumper' });
						}
						else {
							dotOptions.push({ txt: 'charge to team' });
						}
					}

					var blocks = this.getJumperBlocksById(jumper._id.toString());
					blocks.forEach(blk => {
						dotOptions.push({ txt: "use block-" + blk.blockName, blockName: blk.blockName, blockId: blk.saleId });
					})
					if (jumper.block) {
						dotOptions.push({ txt: "jump on account" });
					}
					resolve(dotOptions);
				}
				else if (r.status == 401) {
					this.loginExpired();
					this.emitResults(true, 'clearSession');
				}
				else {

				}
			})
		})
	}

	getTotalTandemCheckedIn = (tandemStudents) => {
		var numCheckedIn = 0;
		tandemStudents.forEach(student => {
			if (this.tandemDailyActivities[student._id] && this.tandemDailyActivities[student._id].isCheckedIn) {
				numCheckedIn++;
			}
		})
		return numCheckedIn;
	}


	getTotalStudentManifested = (students) => {
		var numManifested = 0;
		students.forEach(student => {
			if (this.dailyActivities[student._id] && this.dailyActivities[student._id].manifestStatus.length > 0) {
				numManifested++;
			}
		})
		return numManifested;
	}

	getTotalStudentJumped = (students) => {
		var numJumped = 0;
		students.forEach(student => {
			if (this.dailyActivities[student._id] && this.dailyActivities[student._id].jumpedStatus.length > 0) {
				numJumped++;
			}
		})
		return numJumped;
	}

	getTotalStudentCheckedIn = (students) => {
		var numCheckedIn = 0;
		students.forEach(student => {
			if (this.dailyActivities[student._id] && this.dailyActivities[student._id].isCheckedIn) {
				numCheckedIn++;
			}
		})
		return numCheckedIn;
	}

	isNoPayNoCharge = (id) => {
		var jumper = this.jumpers.filter(jr => jr._id.toString() === id.toString())[0];

		if (jumper !== undefined) {
			return jumper.noPayOrCharge;
		}
		else {
			return false
		}
	}

	clearState = () => {
		this.tandemInstructors = [];
		this.affInstructors = [];
		this.videographers = [];
		this.tandemStudents = [];
		this.affStudents = [];
		this.coaches = [];
		this.staff = [];
		this.dailyActivities = {};
		this.holdJumperId = undefined;
		this.jumpersLoaded = false;
		this.tandemStudentsLoaded = false;
	}

	emitError = (err) => {
		//////console.log(err);
		this.emit('ERROR', err.message);
	}

	loginExpired = () => {
		//alert(JSON.stringify(localStorage, null,2));
		if(localStorage.getItem('isAuth')){
			localStorage.clear();
			swal({
				title: "LOGIN EXPIRED",
				text: "please re-login",
				icon: "warning",
				dangerMode: true,
			})
		}

		//window.location =  window.location.origin
		//window.location.reload();
	}

	getEventPrefix = (func) => {
		return `${this.prefix}${func}`;
	}

	emitResults = (r, func) => {
		//////console.log('emitResults');
		if (!r) {
			//////console.log(r);
			this.emitError({ message: 'No Jumpers found' });
		}
		this.setMaxListeners(1000);
		//////console.log(r);
		this.emit(`${this.prefix}${func}`, r);
	}

	debug = (r) => {
		//////console.log(r.data);
	}

}

const jumpersStore = new Jumpers();
window.jumpersStore = jumpersStore;
export default jumpersStore;
