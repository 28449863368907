import React, { Component } from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap';
import DatePicker from 'reactstrap-date-picker'

class FilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startValue: null,
            endValue: null,
            isDialogOpen: false,
            filterType: null,
            selectValue: 'none',
        }
    }
    handleDateChange = (v, type) => {
        if (type === 'start') {
            this.setState({ startValue: v, endValue: null });
        } else {
            this.setState({ endValue: v })
        }
    }
    handleSelect = (e) => {
        let value = e.target.value;
        let filterType = null;
        let isDialogOpen = false;
        if (value !== 'none') {
            filterType = value === 'made_date' ? 'Date made' : 'Reservation Date';
            isDialogOpen = true;
        }
        this.setState({ filterType, selectValue: value, isDialogOpen, startValue: null, endValue: null })
        this.props.changeFilterOn(value);
    }
    handleSubmit = () => {
        let { startValue, endValue } = this.state;
        this.setState({ isDialogOpen: false });
        startValue = new Date(startValue).toLocaleDateString();
        endValue = new Date(endValue).toLocaleDateString();
        this.props.filterReservation(startValue, endValue);

    }
    hideDialog = () => {
        this.setState({
            isDialogOpen: false,
            startValue: null,
            endValue: null,
            selectValue: 'none'
        })
        this.props.filterReservation(null, null);
        this.props.changeFilterOn('none')
    }
    showDialog = () => {
        if (this.state.filterType) {
            this.setState({ isDialogOpen: true })
        }
    }
    showDateRange = () => {
        let { startValue, endValue } = this.state;
        if (startValue && endValue) {
            startValue = new Date(startValue).toLocaleDateString();
            endValue = new Date(endValue).toLocaleDateString();
            return <span>{startValue} to {endValue}</span>
        }
        else {
            return <span>dd/mm/yyyy to dd/mm/yyyy</span>
        }

    }
    render() {
        let { startValue, endValue, isDialogOpen, filterType, selectValue } = this.state;
        return (
            <div className="col-12 btns-container filter-container">
                <FormGroup>
                    <Label for="select-filter">Filter by Date</Label>
                    <div className="action-container">
                        <Input type="select" name="select" id="select-filter"
                            onChange={this.handleSelect}
                            onFocus={this.showDialog}
                            value={selectValue}
                        >
                            <option value="none">Select</option>
                            <option value="res_date">Reservation Date</option>
                            <option value="made_date">Created Date</option>
                        </Input>
                    </div>
                </FormGroup>
                <div className="show-date">{this.showDateRange()}</div>
                {isDialogOpen ?
                    <div className="dialog-container">
                        <h5>{filterType}</h5>
                        <FormGroup>
                            <Label>Start Date</Label>
                            <DatePicker id="example-datepicker"
                                value={startValue}
                                dateFormat='MM/DD/YYYY'
                                onChange={(v) => this.handleDateChange(v, 'start')} />
                        </FormGroup>
                        <FormGroup>
                            <Label>End Date</Label>
                            <DatePicker id="example-datepicker"
                                value={endValue}
                                minDate={startValue}
                                dateFormat='MM/DD/YYYY'
                                onChange={(v) => this.handleDateChange(v, 'end')}
                            />
                        </FormGroup>
                        <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                        <button className="btn btn-danger" onClick={this.hideDialog}>Cancel</button>
                    </div>
                    : null}
            </div >
        )
    }
}
export default FilterComponent;