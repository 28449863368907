
/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

//import loadsStore from '../../../stores/loads-store';

import axios from 'axios';
import Select from 'react-select';
// import JmpModal from './customModal';
import ItemModal from './customModal';
import accountStore from '../../../stores/account-store';
import reportStore from '../../../stores/report-store';
import itemStore from '../../../stores/item-store';


class AddModal extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isPaperWaiver: false,
            isModalOpen: false,
            Jumper_id: '',
            errors: '',
            amount: "",
            itemType: {},
            itemsSchedule: [],
            selectedItem: '',
            itemCount: 1,
            displayCount: false,
        }
        this.amount = '';
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            amount: "",
            displayCount: false,
            itemCount: 1,
            //jumpType: null
        });
    }

    componentWillMount() {
        var itemSchedule = itemStore.getCountersaleItems();

        if (itemSchedule.length > 0) {
            this.setState({
                itemsSchedule: itemSchedule
            })
        }
        else {
            reportStore.loadPrices().then((res) => {
                this.setState({
                    itemsSchedule: res.itemsSchedule
                })
            })
        }
    }

    componentWillReceiveProps() {
        //alert(this.props.jumperId)
        this.setState({
            Jumper_id: this.props.jumperId
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleCountChange = (e) => {
        const { selectedItem, itemsSchedule } = this.state;
        var count = e.target.value;
        var item = itemsSchedule.filter(x => x.item === selectedItem)[0];

        var price = 0;
        if (item) {
            price = item.price.toFixed(2);
        }
        var totalAmount = (count * price).toFixed(2);

        this.setState({
            itemCount: count,
            amount: totalAmount
        })
    }

    handleSelect = (e) => {

        const { itemsSchedule, itemCount } = this.state;
        var value = e.target.value;
        var count = false;

        if (value === "pack job" || value === "packing student credit" || value === "packing tandem credit") {
            count = true;
        }

        var displaySelect = value === "Tip";

        var item = itemsSchedule.filter(x => x.item === e.target.value)[0];

        var price = 0;
        if (item) {
            price = item.price.toFixed(2);
        }
        var totalPrice = (price * itemCount).toFixed(2);

        this.setState({
            amount: totalPrice,
            displayCount: count,
            selectedItem: e.target.value
        })
        //console.log(price);
    }

    renderItems = () => {
        var items = this.state.itemsSchedule
        items.sort(function (a, b) {
            var nameA = a.item.toUpperCase(); // ignore upper and lowercase
            var nameB = b.item.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        })
        console.log(items)
        return items.map((val, index) => {
            return (
                <option>{val.item}</option>
            )
        })
    }

    handleSubmit(values, studentId) {
        console.log(values, "<======values")
        var payload = {};
        var jumperId = this.props.jumperId;
        const { amount, gift, firstname, lastname, phone, email, weight, dob, isPaperWaiver } = values;

        if (this.props.type === "giftcard") {
            this.props.callback(studentId, gift, this.props.type);
            this.toggleModal();
        }
        else if (this.props.type === "info") {
            this.props.callback(studentId, firstname, lastname, phone, email, weight, this.props.type, dob, isPaperWaiver);
            this.toggleModal();
        }
        else if (amount !== undefined && amount != "") {

            this.props.callback(studentId, Number(amount), this.props.type, this.props.activity?.manifestStatus);
            this.toggleModal();
        }
        else {
            this.setState({
                errors: "This field is required"
            })
            setTimeout(() => {
                this.setState({ errors: "" });
            }, 2000);
        }
    }

    render() {
        const { displayCount, selectedItem } = this.state;
        var displayMemo = selectedItem === "adjustment" || selectedItem === "miscellaneous" ? true : false;
        return (
            <React.Fragment>
                <ItemModal
                    style={{ color: 'white' }}
                    isModalOpen={this.state.isModalOpen}
                    title={`Add Item`}>
                    <LocalForm onSubmit={(values) => this.handleSubmit(values, this.props.tandemStudentId)}>

                        {this.props.type === "giftcard" ?
                            <Row className="form-group">
                                <Label htmlFor="gift code" md={4}>Enter Gift Code : </Label>
                                <Col md={8}>
                                    <Control.input name="gift" model=".gift" className="form-control" />
                                    <span className="text-danger error-validation">{this.state.errors}</span>
                                </Col>
                            </Row> : null
                        }
                        {this.props.type === "info" ?
                            <React.Fragment>
                                <Row className="form-group">
                                    <Label htmlFor="firstname" md={4}>firstname : </Label>
                                    <Col md={8}>
                                        <Control.input name="firstname" model=".firstname" defaultValue={this.props.data.firstname} className="form-control" />
                                        <span className="text-danger error-validation">{this.state.errors}</span>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Label htmlFor="lastname" md={4}>lastname : </Label>
                                    <Col md={8}>
                                        <Control.input name="lastname" model=".lastname" defaultValue={this.props.data.lastname} className="form-control" />
                                        <span className="text-danger error-validation">{this.state.errors}</span>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Label htmlFor="phone" md={4}>phone : </Label>
                                    <Col md={8}>
                                        <Control.input name="phone" model=".phone" defaultValue={this.props.data.phone} className="form-control" />
                                        <span className="text-danger error-validation">{this.state.errors}</span>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Label htmlFor="email" md={4}>email : </Label>
                                    <Col md={8}>
                                        <Control.input name="email" model=".email" defaultValue={this.props.data.email} className="form-control" />
                                        <span className="text-danger error-validation">{this.state.errors}</span>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Label htmlFor="weight" md={4}>weight : </Label>
                                    <Col md={8}>
                                        <Control.input name="weight" model=".weight" defaultValue={this.props.data?.weight} className="form-control" />
                                        <span className="text-danger error-validation">{this.state.errors}</span>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Label htmlFor="weight" md={4}>dob : </Label>
                                    <Col md={8}>
                                        <Control.input name="dob" model=".dob" defaultValue={this?.props?.data?.dob} className="form-control" type='date' />
                                        <span className="text-danger error-validation">{this.state.errors}</span>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Label htmlFor="paper waiver signed" md={4}>paper waiver: </Label>
                                    <Col md={8} className="isPaperWaiver">
                                        <Control.checkbox name="isPaperWaiver" model=".isPaperWaiver" defaultValue={this.props?.data?.isPaperWaiver} />
                                    </Col>
                                </Row>
                            </React.Fragment> :
                            <Row className="form-group">
                                <Label htmlFor="amount" md={4}>Enter Amount : </Label>
                                <Col md={8}>
                                    <Control.input name="amount" model=".amount" className="form-control" />
                                    <span className="text-danger error-validation">{this.state.errors}</span>
                                </Col>
                            </Row>
                        }
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 9 }}>
                                {
                                    this.state.errors.amount === "" || this.state.errors.amount === undefined ?
                                        <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                        :
                                        <button className="btn btn-bg lightColor createButton" type="submit" disabled>SUBMIT</button>
                                }
                                {/* <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button> */}
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </ItemModal>
                <button className="btn btn-secondary btn-sm" onClick={this.toggleModal} style={{ color: "#000", backgroundColor: "#efefef", borderColor: "#6c757d" }}>
                    {this.props.text}
                </button>
            </React.Fragment>
        );
    }
}

export default AddModal
