import React, { Component } from 'react'
import { Table } from 'reactstrap';
import CreateTeams from '../modals/CreateTeams';
import RemoveTeam from '../modals/RemoveTeam'
import jumpersStore from '../../../stores/jumpers-store';
import loadsStore from '../../../stores/loads-store';
import DonutLoader from '../../loadManager/DonutLoader';

class Teams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jumperOptions: [],
            teams: [],
            isLoading: true,
            isTeamsLoading: true,
        }
        this.jumperEvents = [
            { name: jumpersStore.getEventPrefix("jumpersLoaded"), func: this.handleJumperLoaded },
        ];
        this.loadsEvents = [
            { name: loadsStore.getEventPrefix("teamsLoaded"), func: this.handleTeamsLoaded },
        ];

    }
    componentWillMount = () => {
        this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
        this.loadsEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func));
        loadsStore.loadTeams();
    }
    componentWillUnmount = () => {
        this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
        this.loadsEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    }
    componentDidMount = () => {
        let jumperOptions = jumpersStore.getJumperOptions();
        if (jumperOptions.length > 0) {
            let newList = jumperOptions.map(ele => {
                return { label: ele.label, value: { id: ele.value._id, nickname: ele.value.nickname, firstname: ele.value.firstname, lastname: ele.value.lastname } }
            })
            this.setState({ jumperOptions: newList, isLoading: false })
        }

    }
    returnJumperValues = (jumperIds) => {
        // console.log("🚀 ~ file: Teams.js ~ line 40 ~ Teams ~ jumperIds", jumperIds)
        const { jumperOptions } = this.state;
        // console.log("🚀 ~ file: Teams.js ~ line 41 ~ Teams ~ jumperOptions", jumperOptions)
        let list = [];
        jumperOptions.map(ele => {
            let idx = jumperIds.findIndex(id => id === ele.value.id);
            if (idx >= 0) {
                list.push(ele)
            }
        })
        return list;
    }
    updateTeams = (newteams, isEdit) => {
        let { teams } = this.state;
        let newArr = [...teams];
        if (isEdit) {
            let index = teams.findIndex(ele => ele._id === newteams._id);
            newArr[index] = newteams;
        }
        else {
            newArr = [...teams, newteams];
        }
        this.setState({ teams: newArr });
    }
    deleteTeam = (id) => {
        let { teams } = this.state;
        let newArr = teams.filter(ele => ele._id !== id);
        this.setState({ teams: newArr })
    }

    renderTeams = () => {
        const { teams } = this.state;

        let jValues = [];
        let vValues = []
        let teamsList = teams.map((ele, idx) => {
            let jumperIds = [...ele.teamMembersInfo]
            let onlyJumper = []
            let videographerIds = []
            jumperIds.map(i => {
                if (!i.isVideographer) {
                    onlyJumper.push(i)
                } else {
                    videographerIds.push(i)
                }
            })

            jValues[idx] = this.returnJumperValues(onlyJumper);
            vValues[idx] = this.returnJumperValues(videographerIds);

            let newObj = { ...ele };
            let names = "";

            jValues[idx]?.map(v => names += v?.label + ", ");
            let jumpers = []
            let videographer = []
            ele.teamMembersInfo.map(teamMember => {
                if (!teamMember.isVideographer) {
                    jumpers.push(teamMember.nickname + " " + teamMember.lastname + ", ")
                } else {
                    videographer.push(teamMember.nickname + " " + teamMember.lastname)
                }
            });
            newObj.jumpers = jumpers
            newObj.videographer = videographer
            newObj.defaultAltitude = ele.defaultAltitude;
            return newObj;
        })

        console.log(teamsList);
        let national2021List = teamsList?.filter(i => i.compType === 'nationals2021')
        let national2022List = teamsList?.filter(i => i.compType === 'mundial2022')
        var regularteams = teamsList?.filter(i => i.compType === "none" || i.compType === "")
        if (this.props.path === 'national2021') {
            teamsList = national2021List
        } else if (this.props.path === 'mundial2022') {
            teamsList = national2022List
        }
        else {
            teamsList = regularteams;
        }


        if (teamsList != undefined) {
            return teamsList.map((item, index) => {
                let valueObj = { ...item, jumpers: jValues[index], videoGrapher: vValues[index] };
                // console.log("VAlue obj: " + index, valueObj);
                return (
                    <tr key={index}>
                        <th scope="row" data-id={item._id}>{index + 1}</th>
                        <td>{item.teamname}</td>
                        <td >{item.jumpers}</td>
                        <td>{item.teamType}</td>
                        <td>{item.videographer}</td>
                        <td >{item.jumpType}</td>
                        <td >{item.defaultAltitude}</td>
                        <td>
                            <CreateTeams
                                isEdit={true}
                                jumperOptions={this.state.jumperOptions}
                                updateItem={(v, i) => this.updateTeams(v, i)}
                                item={valueObj}
                            />&nbsp;&nbsp;
                            <RemoveTeam item={valueObj} deleteTeam={this.deleteTeam} />
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }

    handleJumperLoaded = jumperOptions => {
        let newList = jumperOptions.map(ele => {
            return { label: ele.label, value: { id: ele.value._id, nickname: ele.value.nickname, firstname: ele.value.firstname, lastname: ele.value.lastname } }
        })
        this.setState({ jumperOptions: newList, isLoading: false })
    }
    handleTeamsLoaded = (teams => {
        // console.log("🚀 ~ file: Teams.js ~ line 99 ~ Teams ~ teams", teams)
        this.setState({ teams, isTeamsLoading: false })
    })

    render() {
        // console.log("InsideRender", this.state)
        let { isLoading, isTeamsLoading } = this.state;
        return (
            isLoading || isTeamsLoading ?
                <DonutLoader isLoading={isLoading} /> :
                <div className="teams-container">
                    <Table>
                        <thead className="main-heading">
                            <tr>
                                <th>
                                    <CreateTeams
                                        jumperOptions={this.state.jumperOptions}
                                        updateItem={(v, i) => this.updateTeams(v, i)}
                                    />
                                </th>
                                <th>Name</th>
                                <th >Jumper</th>
                                <th>Team Type</th>
                                <th>Videographer</th>
                                <th>Jump Type</th>
                                <th>Default Altitude</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="priceHeading">
                                <td colSpan="3">Teams</td>
                            </tr>
                            {this.renderTeams()}
                        </tbody>
                    </Table>
                </div>

        )
    }
}
export default Teams;
