/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import loadsStore from '../../stores/loads-store';
import jumpersStore from '../../stores/jumpers-store';
import reportStore from '../../stores/report-store';
import accountStore from '../../stores/account-store';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import logo from '../images/logo.svg';
import { Control, LocalForm, Errors } from 'react-redux-form';
import AccountRight from './accountsRight';
import LeftMenu from '../menu/LeftMenu1';
import background from '../images/background.svg';
import HamburgerMenu from 'react-hamburger-menu';
import swal from 'sweetalert';
import Api from '../../services/reservationapi';
import reservationStore from '../../stores/reservation-store';
// import ErroModal from './modals/errorModal';
import TransferCredit from './modals/transferCredit';
import { Alert } from 'reactstrap';
import ErrorModal from '../../components/sub-components/Modal/ErrorModal';
import { ScaleLoader } from 'react-spinners';
import Fuse from "fuse.js";
import DonutLoader from '../loadManager/DonutLoader';

var tempOptions = [
	{ label: "Testing ig 2323", value: "asfsf" },
	{ label: "John Jackson 23323", value: "James jamison" },
	{ label: "ronald storment", value: "jackson height" },
	{ label: "snoop dawg", value: "dawg of snoop" }
];




class AccountsPageCenter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			jumperOptions: [],

			defaultOptions: [],

			defaultTandemOptions: [],

			tandemStudentOptions: [],

			groupedOptions: [],

			dailyActivities: {},

			tandemDailyActivities: {},

			width: window.innerWidth,

			open: false,

			rightOpen: false,

			chargeValue: [],

			isDisable: true,

			chargeDisable: true,

			isAlert: false,

			amount: 0,

			transactionId: '',

			warning: false,

			isloading: false,

			filterItem: [
				{ text: 'Active Today', checked: false },
				{ text: 'Postive Bal.', checked: false },
				{ text: 'Negative Bal.', checked: false },
				{ text: 'Staff', checked: false },
				{ text: 'Students', checked: false},
				{ text: 'Has Tickets', checked: false}
			],

			tandemFilterItem: [
				{ text: 'Active Today', checked: false },
				{ text: 'Postive Balance', checked: false },
				{ text: 'Money on Account', checked: false },
			],
			earnedBalSetting: false
		};
		this.fuse = null;

		this.inputValue = '';

		this.tandemInputValue = '';



		this.handleJumpersLoaded = this.handleJumpersLoaded.bind(this);
		this.onJumperChanged = this.onJumperChanged.bind(this);
		this.jumperEvents = [
			{ name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded },
			{ name: jumpersStore.getEventPrefix('tandemStudentsLoaded'), func: this.handleTandemStudentsLoaded },
			{ name: jumpersStore.getEventPrefix('AllDailyActivities'), func: this.handleJumperActivitiesLoaded },
			{ name: jumpersStore.getEventPrefix('transfer'), func: this.handleTransfer },
			{ name: jumpersStore.getEventPrefix('warningEvent'), func: this.handleWarning }
		];


		this.loadEvents = [
			// {name: loadsStore.getEventPrefix('completeLoad'), func: this.handleUpdatedBalances}
			{ name: loadsStore.getEventPrefix('completeLoadRight'), func: this.handleUpdatedBalances }
		];

		this.accountsEvents = [
			{ name: accountStore.getEventPrefix('itemsAdded'), func: this.handleItemsAdded },
			{ name: accountStore.getEventPrefix('chargeAdded'), func: this.handleChargeAdded },
			{ name: accountStore.getEventPrefix('warningEvent'), func: this.handleWarning }
		];
	}

	handleWarning = () => {
		this.setState({
			warning: true
		})

		setTimeout(() => {
			this.setState({
				warning: false
			})
		}, 2000)
	}

	componentDidMount = () => {
		//console.log('in components did mount accounts');

		//this.loadEvents.forEach((evnt)=>loadsStore.on(evnt.name,evnt.func))
		this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));

		this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func));

		this.accountsEvents.forEach((evnt) => accountStore.on(evnt.name, evnt.func));

		// this.reservationEvent.forEach((evnt) => reservationStore.on(evnt.name, evnt.func));

		var jumperArray = jumpersStore.getJumperOptions();

		var tandemStudentArray = jumpersStore.getTandemStudentOptions();

		////alertt(JSON.stringify(tandemStudentOptions));
		var groupedOptions = [
			{
				label: 'Tandem Students',
				options: tandemStudentArray,
			},
			{
				label: 'Jumpers',
				options: jumperArray,
			}
		]

		//this.setState({ jumperOptions: jumperArray, tandemStudentOptions: tandemStudentArray });


		////console.log(this.state.defaultOptions);

		////console.log(tandemStudentArray);
		var dailyActivities = jumpersStore.getAllDailyActivities();
		var tandemDailyActivities = jumpersStore.getAllTandemDailyActivities();

		if (window.globals && window.globals['accountsState']) {
			this.setState({ ...window.globals['accountsState'] }, () => {
				this.filterJumperOptions2(jumperArray, dailyActivities);
				this.filterTandemOptions(tandemStudentArray, tandemDailyActivities);
			});
		}
		else {
			this.filterJumperOptions2(jumperArray, dailyActivities);
			this.filterTandemOptions(tandemStudentArray, tandemDailyActivities);

		}
		////console.log(dailyActivities);
		this.setState({ jumperOptions: jumperArray, tandemStudentOptions: tandemStudentArray, dailyActivities: dailyActivities, tandemDailyActivities: tandemDailyActivities });
		this.setFuse(jumperArray);

		//this.setState({ dailyActivities: dailyActivities });

		//console.log(this.state.filterItem);
		this.filterJumperOptions2(jumperArray, dailyActivities);

		this.filterTandemOptions(tandemStudentArray, tandemDailyActivities);

		window.addEventListener('resize', this.handleWindowSizeChange);

		this.forceUpdate();

		const earnedBalSetting = loadsStore.getSeparateEarnedBalanceSetting()
		this.setState({ earnedBalSetting: earnedBalSetting })
	}

	componentWillUnmount = () => {
		////console.log('in components will unmount accounts');
		// let velocityScript = document.getElementById('velocityScript');
		// if (velocityScript) {
		// 	velocityScript.remove();
		// }

		this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
		this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
		this.accountsEvents.forEach((evnt) => accountStore.removeListener(evnt.name, evnt.func));
		// this.reservationEvent.forEach((evnt) => reservationStore.removeListener(evnt.name, evnt.func));
		window.removeEventListener('resize', this.handleWindowSizeChange);
		window.globals.filterItem = this.state.filterItem;
		window.globals.tandemFilterItem = this.state.tandemFilterItem;

	}

	componentDidUpdate = () => {
		////console.log('in component did update accounts');
		window.globals = { ...window.globals };
		window.globals.accountsState = this.state;
	}

	componentWillMount = () => {

		// var aScript = document.createElement('script');
		// aScript.type = 'text/javascript';
		// aScript.src = "https://api.cert.nabcommerce.com/1.3/button.js";
		// aScript.setAttribute('id', 'velocityScript');
		// document.head.appendChild(aScript);

		window.addEventListener('resize', this.handleWindowSizeChange);

		var profileId, merchantProfileId, serviceID;
		if (window.ENV?.processorInfo.processor === "nab") {
			Api.startSession()
				.then(res => {
					console.log(res);
				});
		}
		// Api.getIdentityInfo()
		// 	.then(info => {
		// 		reservationStore.setProcessorVals(info.data);
		// 		profileId = info.data.applicationProfileId;
		// 		merchantProfileId = info.data.merchantProfileId;
		// 		serviceID = info.data.ServiceID;
		// 		return Api.startSession(info.data.IdentityToken);
		// 	})
		// 	.then(r => {
		// 		if (r.data) {
		// 			reservationStore.setTokENV?.l(r.data);
		// 		}
		// 		////console.log(reservationStore.getTokENV?.l());
		// 		return Api.getApplicationData(reservationStore.getTokENV?.l(), profileId);
		// 	})
		// 	.then(r => {
		// 		var applicationData = r.data;
		// 		////console.log(applicationData);
		// 		return Api.getServiceInformation(reservationStore.getTokENV?.l());
		// 	})
		// 	.then(r => {
		// 		return Api.isMerchantProfileInitialized(reservationStore.getTokENV?.l(), merchantProfileId, serviceID);
		// 	})
		// 	.then(r => {
		// 		////console.log(r.data);
		// 	})

		this.setState({
			isloading: true
		})
	}

	setFuse = (jumperOptions) => {
		var options = {
			shouldSort: true,
			tokenize: false,
			threshold: 0.1,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: [
				"label",
				"value.manifestNumber"
			]
		};

		this.fuse = new Fuse(jumperOptions, options); // "list" is the item array
	}

	handleJumpersLoaded(jumperOptions) {
		////console.log('in handle jumpers loaded in accounts');
		var defaultOptions = jumperOptions.slice(0, 50);
		this.setState({
			jumperOptions: jumperOptions
		});

		this.filterJumperOptions2(jumperOptions, this.state.dailyActivities);
	}

	handleTandemStudentsLoaded = (tandemStudentOptions) => {
		////console.log('in handle jumpers loaded in accounts');
		//alertt('tandem students loaded even');
		//alertt(JSON.stringify(tandemStudentOptions));
		this.setState({
			tandemStudentOptions: tandemStudentOptions
		});
		this.getDefaultJumperOptions(tandemStudentOptions, this.state.dailyActivities);
	}

	getDefaultJumperOptions = (jumperOptions, dailyActivities) => {
		//console.log('in get default jumper options');
		//alertt('in get Default Options');
		var istoday = this.state.filterItem[0].checked;
		var isPositive = this.state.filterItem[1].checked;
		var isNegative = this.state.filterItem[2].checked;
		var isStaff = this.state.filterItem[3].checked;
		var isStudent = this.state.filterItem[4].checked;
		var hasTickets = this.state.filterItem[5].checked;

		//console.log(istoday, isPositive, isNegative, isStaff);

		var r = false;

		var defaultOptions = jumperOptions.filter(option => {
			var r = false;
			if (dailyActivities[option.value._id] && dailyActivities[option.value._id].totalBalance > 0) {
				r = true;
			}

			if (dailyActivities[option.value._id]) {
				return r;
			}
			else {
				return (option.value.currentBalance > 0)
			}
		});


		defaultOptions = defaultOptions.sort((a, b) => { return a.value.firstname.toLowerCase() < b.value.firstname.toLowerCase() });

		this.setState({
			defaultOptions: defaultOptions
		});
	}

	filterTandemOptions = (tandemOptions, dailyActivities) => {

		console.log('in filter tandem options');

		console.log(dailyActivities);

		console.log(tandemOptions);

		if (tandemOptions.length > 0) {
			this.setState({
				isloading: false
			})
		}
		var istoday = this.state.tandemFilterItem[0].checked;
		var isPositive = this.state.tandemFilterItem[1].checked;
		var isNegative = this.state.tandemFilterItem[2].checked;
		var defaultOptions = [...tandemOptions];
		if (istoday) {

			console.log('in isTOday');
			defaultOptions = defaultOptions.filter(option => {
				if (dailyActivities[option.value] && (dailyActivities[option.value].tandemStudentJumps > 0 || dailyActivities[option.value].observerCount > 0)) {
					return true;
				}
				return false;
			});
		}
		if (isPositive) {

			console.log('in isPositive');
			defaultOptions = defaultOptions.filter(option => {
				if (dailyActivities[option.value] !== undefined) {
					if (dailyActivities[option.value].totalBalance > 0) return true;
					else return false;
				}
				else if (option.value.currentBalance > 0) return true;
				return false;
			})
		}

		if (isNegative) {

			console.log('in isNegative');
			defaultOptions = defaultOptions.filter(option => {
				if (dailyActivities[option.value] !== undefined) {
					if (dailyActivities[option.value].totalBalance < 0) return true;
					else return false;
				}
				else if (option.value.currentBalance < 0) return true;
				return false;
			})
		}

		defaultOptions.sort(function (a, b) {
			if (a.label < b.label) { return -1; }
			if (a.label > b.label) { return 1; }
			return 0;
		})
		//}
		console.log(defaultOptions);

		this.setState({tandemDefaultOptions: defaultOptions});

	}							

	filterJumperOptions2 = (jumperOptions, dailyActivities) => {
		if (jumperOptions.length > 0) {
			this.setState({
				isloading: false																		
			})
		}																					
		var istoday = this.state.filterItem[0].checked;
		var isPositive = this.state.filterItem[1].checked;
		var isNegative = this.state.filterItem[2].checked;
		var isStaff = this.state.filterItem[3].checked;
		var isStudent = this.state.filterItem[4].checked;
		var hasTickets = this.state.filterItem[5].checked;

		var defaultOptions = [...jumperOptions];

		if (istoday) {
			defaultOptions = defaultOptions.filter(option => {
				if (dailyActivities[option.value._id]) {
					return true;
				}
				return false;
			});
		}
		if (isPositive) {
			defaultOptions = defaultOptions.filter(option => {
				if (dailyActivities[option.value._id] !== undefined) {
					if (dailyActivities[option.value._id].totalBalance > 0) return true;
					else return false;
				}
				else if (option.value.currentBalance > 0) return true;
				return false;
			})		
		}							
		if (isNegative) {
			defaultOptions = defaultOptions.filter(option => {
				if (dailyActivities[option.value._id] !== undefined) {
					if (dailyActivities[option.value._id].totalBalance < 0) return true;
					else return false;
				}
				else if (option.value.currentBalance < 0) return true;
				return false;
			})
		}
		if (isStaff) {
			defaultOptions = defaultOptions.filter(option => {
				if (option.value.isStaff) return true;
				return false;
			})
		}
		if (isStudent) {
			defaultOptions = defaultOptions.filter(option => {
				if (option.value.ratings?.filter(rating => rating.rating?.toLowerCase().includes("student")).length>0) return true;
				return false;
			})
		}
		if (hasTickets) {
			defaultOptions = defaultOptions.filter(option => {
				if (option.value.blocks?.filter(block => block?.ticketsRemaining >0).length>0) return true;
				return false;
			})
		}

		if (defaultOptions.length > 200)
			defaultOptions = defaultOptions.slice(0, 200)

		// console.log(defaultOptions);

		var istoday = this.state.filterItem[0].checked;
		var isPositive = this.state.filterItem[1].checked;
		var isNegative = this.state.filterItem[2].checked;
		var isStaff = this.state.filterItem[3].checked;

		//if (istoday || isPositive || isNegative || isStaff) {
		// defaultOptions.sort((a, b) => {
		// 	//console.log(a.value.firstname.substr(0,1).toLowerCase());
		// 	//console.log(b.value.firstname.substr(0,1).toLowerCase());
		// 	// return a.label.substr(0, 1).toLowerCase() - b.label.substr(0, 1).toLowerCase();

		// });
		defaultOptions.sort(function (a, b) {
			if (a.label < b.label) { return -1; }
			if (a.label > b.label) { return 1; }
			return 0;
		})
		//}

		this.setState({
			defaultOptions: defaultOptions
		});
	}

	filterJumperOptions = (jumperOptions, dailyActivities) => {

		var istoday = this.state.filterItem[0].checked;
		var isPositive = this.state.filterItem[1].checked;
		var isNegative = this.state.filterItem[2].checked;
		var isStaff = this.state.filterItem[3].checked;

		//console.log(istoday, isPositive, isNegative, isStaff);

		var defaultOptions = jumperOptions;
		if (!istoday && !isPositive && !isNegative && !isStaff) {
			defaultOptions = jumperOptions.filter(option => {
				var r = false;
				if (dailyActivities[option.value._id] && dailyActivities[option.value._id].totalBalance > 0) {
					r = true;
				}

				if (dailyActivities[option.value._id]) {
					return r;
				}
				else {
					return (option.value.currentBalance > 0)
				}
			});
		}

		if (istoday) {
			defaultOptions = jumperOptions.filter(option => {
				var r = false;
				if (dailyActivities[option.value._id]) {
					r = true;
				}
				return r;
			});
		}

		if (isPositive && istoday) {
			defaultOptions = defaultOptions.filter(option => {
				var r = false;
				if (option.value.totalBalance > 0 || dailyActivities[option.value._id].totalBalance > 0) {
					r = true;
				}
				return r;
			})
		}
		else if (isPositive) {
			defaultOptions = defaultOptions.filter(option => {
				var r = false;
				if (option.value.totalBalance > 0) {
					r = true;
				}
				return r;
			})
		}


		if (isNegative && istoday) {
			defaultOptions = defaultOptions.filter(option => {
				var r = false;
				if (option.value.totalBalance < 0 || dailyActivities[option.value._id].totalBalance < 0) {
					r = true;
				}
				return r;
			})
		}
		else if (isNegative) {
			defaultOptions = defaultOptions.filter(option => {
				var r = false;
				// if (option.value.totalBalance < 0 || option.value.currentBalance < 0) {
				if (option.value.totalBalance < 0) {
					r = true;
				}
				return r;
			})
		}

		if (isStaff) {
			defaultOptions = defaultOptions.filter(option => {
				var r = false;
				if (option.value.isStaff) {
					r = true;
				}
				return r;
			})
		}

		//console.log(defaultOptions.length);
		this.setState({
			defaultOptions: defaultOptions
		});
	}

	handleUpdatedBalances = (data) => {
		////console.log("in handle update Balances")

		var dailyActivities = data.dailyActivities;

		////console.log(dailyActivities);
		//var ids = Object.getOwnPropertyNames(newSummaries);
		//var jumperOptions = [...this.state.jumperOptions];
		/*
		ids.forEach(id =>{
			var jumperOption = jumperOptions.filter(option => option.value._id === id)[0];
			////console.log(jumperOption);
			jumperOption.value.currentBalance = newSummaries[id].totalBalance;
		});
		*/

		this.setState({ dailyActivities: dailyActivities });

		this.filterJumperOptions2(this.state.jumperOptions, dailyActivities);
	}

	handleJumperActivitiesLoaded = (data) => {
		////console.log("in handle update Balances")
		var dailyActivities = data.dailyActivities;
		var tandemDailyActivities = data.tandemDailyActivities;
		////console.log(dailyActivities);
		this.setState({ dailyActivities: dailyActivities, tandemDailyActivities: tandemDailyActivities });
		this.filterJumperOptions2(this.state.jumperOptions, dailyActivities);
	}

	handleItemsAdded = (data) => {
		//same as handle DailyActivity for now
		////console.log('in handleItemsAdded');
		////console.log(data.dailyActivity);
		if (data.dailyActivity.tandemPackage !== undefined) {
			var dailyActivities = { ...this.state.tandemDailyActivities };
			dailyActivities[data.id] = data.dailyActivity;
			jumpersStore.setAllDailyActivities(this.state.dailyActivities, dailyActivities);
			this.setState({ tandemDailyActivities: dailyActivities });
			this.filterTandemOptions(this.state.tandemStudentOptions, dailyActivities);
		}
		else {
			var dailyActivities = { ...this.state.dailyActivities };

			dailyActivities[data.id] = data.dailyActivity;
			////console.log(dailyActivities);
			jumpersStore.setAllDailyActivities(dailyActivities, this.state.tandemDailyActivities);

			this.setState({ dailyActivities: dailyActivities });
			this.filterJumperOptions2(this.state.jumperOptions, dailyActivities);
		}

	}

	handleTransfer = (data) => {
		//same as handle DailyActivity for now
		////console.log('in handleItemsAdded');
		////console.log(data.dailyActivity);
		var dailyActivities = { ...this.state.dailyActivities };

		dailyActivities[data.toId] = data.toActivity;
		dailyActivities[data.fromId] = data.fromActivity;
		////console.log(dailyActivities);

		jumpersStore.setAllDailyActivities(dailyActivities);

		this.setState({ dailyActivities: dailyActivities });
		this.filterJumperOptions2(this.state.jumperOptions, dailyActivities);

	}

	handleChargeAdded = (data) => {
		//same as handle DailyActivity for now
		////console.log('in handleChargeAdded');
		////console.log(data.dailyActivity);

		if (data.dailyActivity.tandemPackage !== undefined) {
			var dailyActivities = { ...this.state.tandemDailyActivities };
			dailyActivities[data.id] = data.dailyActivity;
			jumpersStore.setAllDailyActivities(this.state.dailyActivities, dailyActivities);
			////console.log(dailyActivities);
			this.setState({ tandemDailyActivities: dailyActivities, isAlert: true });
			this.filterTandemOptions(this.state.tandemStudentOptions, dailyActivities);
		}
		else {
			var dailyActivities = { ...this.state.dailyActivities };
			dailyActivities[data.id] = data.dailyActivity;
			jumpersStore.setAllDailyActivities(dailyActivities, this.state.tandemDailyActivities);
			////console.log(dailyActivities);
			this.setState({ dailyActivities: dailyActivities, isAlert: true });
			this.filterJumperOptions2(this.state.jumperOptions, dailyActivities);

		}

		setTimeout(() => {
			this.setState({ isAlert: false });
		}, 2000)
	}

	filterJumpers = (inputValue, jumperOptions) => {
		if (inputValue) {
			var filteredOptions = this.fuse.search(inputValue.toLowerCase());
			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
			else return filteredOptions;
		}
		return jumperOptions;
	};

	promiseOptions = (inputValue, jumperOptions) => {
		////console.log('in pomiseOptions');
		////console.log(this.inputValue);
		return new Promise(resolve => {
			resolve(this.filterJumpers(this.inputValue, jumperOptions));
		});
	};

	handleInputChange = (newValue) => {
		////console.log('handleInputChange');

		//const inputValue = newValue.replace(/\W/g, '');
		//////console.log(inputValue);

		//this.promiseOptions(newValue, jumperOptions);
		this.inputValue = newValue;
		//return inputValue;
	};

	handleTandemInputChange = (newValue) => {
		//console.log('handleTandemInputChange');

		//const inputValue = newValue.replace(/\W/g, '');
		////console.log(inputValue);

		//this.promiseOptions(newValue, jumperOptions);
		this.tandemInputValue = newValue;
		//return inputValue;
	};

	handleSelectTandem = (va) => {

	}

	// handleReservationUpdate(){
	// 	var jumperOptions = jumpersStore.getJumperOptions();
	// 	var tandemStudentOptions = jumpersStore.getTandemStudentOptions();
	// 	//this.setState({ jumperOptions: jumperOptions, tandemStudentOptions: tandemStudentOptions });
	// 	//this.forceUpdate();
	// 	//console.log(this.state.tandemStudentOptions);
	// }

	checkForNegatives = (price) => {
		//console.log('check for negatives: ' + price);
		if (price != undefined) {
			let priceCell = (price < 0) ?
				{
					value: `($${Math.abs(price).toFixed(2)})`
				} :
				{
					value: `$${price.toFixed(2)}`
				}

			// if (price < 0) {
			// 	return <span> {priceCell.value}</span>;
			// }

			return <span>{priceCell.value}</span>;
		}
		else {
			return '';
		}
	}

	_checkForNegatives = (price) => {
		if (price != undefined) {
			let priceCell = (price < 0) ?
				{
					value: `($${Math.abs(price).toFixed(2)})`
				} :
				{
					value: `$${price.toFixed(2)}`
				}

			return priceCell.value;
		}
		else {
			return '-';
		}
	}

	handleSelect = (val) => {

		// var startDate = '';
		// var endDate = '';
		// var jumperId = val.value._id;
		// let sDate = new Date();
		// sDate = sDate.setHours(0, 0, 0, 0);
		// startDate = new Date(sDate);
		// startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

		// let eDate = new Date();
		// eDate = eDate.setHours(23, 59, 59, 999);
		// endDate = new Date(eDate);
		// endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');


		if (val.value !== undefined) {
			this.onJumperChanged(val.value);
			jumpersStore.getDailyActivity(val.value._id);

			jumpersStore.GetLastTransaction(val.value._id).then((history) => {
				var item = history.chargeHistory[0];
				if (item !== undefined) {
					// console.log("history", item.transactionId, item.amount);
					this.setState({
						amount: item.amount,
						transactionId: item.transactionId,
						chargeDisable: false
					})
				}
				else {
					this.setState({
						amount: 0,
						transactionId: '',
						chargeDisable: true
					})
				}
			})
		}

		// reportStore.GetJumperLogs(startDate, endDate, jumperId)

		this.setState({
			isDisable: false,
			isTandemStudent: false
		})
	}

	handleTandemSelect = (val) => {
		//console.log('in handleSelectTandem');
		//console.log(val.value);
		if (val.value !== undefined) {
			this.onJumperChanged(val.value);
			console.log("tandemid 699", val)
			jumpersStore.getDailyTandemActivity(val.value);
			jumpersStore.GetTandemTransaction(val.value).then((history) => {
				if (history !== null && history.chargeHistory.length > 0) {
					var item = history.chargeHistory[0];
					console.log(" charfe 704", item)
					//console.log("history", item.transactionId, item.amount);
					this.setState({
						amount: item.amount,
						transactionId: item.transactionId,
						chargeDisable: false
					})
				}
				else {
					this.setState({
						amount: 0,
						transactionId: '',
						chargeDisable: true
					})
				}
			})
		}
		this.setState({
			isDisable: false,
			isTandemStudent: true
		})
	}


	onJumperChanged(newData) {
		this.setState({ chargeValue: newData }, () => {
			//console.log('Data 1 changed by Sidebar' + JSON.stringify(newData));
		})
	}

	AccountSelect = (props) => {
		return (
			<Control.custom model=".jumpers" name="jumpers" className="form-control" component={props.component} loadOptions={props.loadOptions}
				mapProps={props.mapProps} onInputChange={props.onInputChange} components={{ Option: this.Option, MultiValueLabel: this.MultiValueLabel }}
				styles={{ multiValueLabel: (base) => ({ ...base, width: 200 }) }} />
		);
	}

	downloadCSVFunJumps = () => {
		const { dailyActivities, defaultOptions, earnedBalSetting } = this.state;
	
		// Build data for CSV
		const data = (defaultOptions || []).map((option) => {
			const activity = dailyActivities[option.value._id] || {};
			return {
				Name: option.label,
				Balance: this._checkForNegatives(activity?.totalBalance ?? option.value.currentBalance),
				...(earnedBalSetting && {
					EarnedBalance: this._checkForNegatives(activity?.totalEarnedBalance ?? option.value.currentEarnedBalance)
				})
			};
		});
	
		if (!data.length) {
			return swal({
				title: "Warning",
				text: "There is no data to be downloaded",
				icon: "warning",
				dangerMode: false,
			});
		}
	
		this.downloadCSV(data, "fun_jumps_data.csv");
	};

	downloadCSVTandemJumps = () => {
		const { tandemDefaultOptions } = this.state;
	
		const data = tandemDefaultOptions.map(item => ({ Label: item.label }));
	
		if (!data.length) {
			return swal({
				title: "Warning",
				text: "There is no data to be downloaded",
				icon: "warning",
				dangerMode: false,
			});
		}
	
		this.downloadCSV(data, "tandem_jumps_data.csv");
	};

	downloadCSV = (data, filename) => {
		// Convert data to CSV format
		const csvContent = this.convertToCSV(data);
	
		// Create a blob from the CSV content
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
	
		// Create a link to download the blob
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", filename);
	
		// Append the link to the document and trigger the download
		document.body.appendChild(link);
		link.click();
	
		// Clean up
		document.body.removeChild(link);
		URL.revokeObjectURL(url); // Revoke the object URL to free up memory
	};

	convertToCSV = (array) => {
		const headers = Object.keys(array[0]).join(","); // Extract headers
		const rows = array.map(obj => Object.values(obj).join(",")); // Create rows
		return [headers, ...rows].join("\n"); // Combine headers and rows
	};

	Option = (props) => {
		return (
			<div className="row" >
				<div className={this.state.earnedBalSetting ? "col-6" : "col-8"} style={{ fontSize: '18px' }}>
					<components.Option {...props} />
				</div>
				<div className={this.state.earnedBalSetting ? "col-3" : "col-4"} style={{ paddingTop: '8px', fontSize: '20px' }}>
					{this.state.dailyActivities[props.value._id] ? this.checkForNegatives(this.state.dailyActivities[props.value._id].totalBalance) : this.checkForNegatives(props.value.currentBalance)}
				</div>
				{this.state.earnedBalSetting && <div className="col-3" style={{ paddingTop: '8px', fontSize: '20px' }}>
					{this.state.dailyActivities[props.value._id] ? this.checkForNegatives(this.state.dailyActivities[props.value._id].totalEarnedBalance) : this.checkForNegatives(props.value.currentEarnedBalance)}
				</div>}	
			</div>		
		);
	}

	renderTandemCheckbox = () => {
		//this.filterJumperOptions2(this.state.jumperOptions, this.state.dailyActivities);
		return this.state.tandemFilterItem.map((item, index) => {
			var ratingName = item.text + "_tandem" + index;
			var ratingText = "txt_" + item.text + "_tandem" + index;
			return (
				<div className="col-3">
					<input type="checkbox" id={ratingName} name={ratingName} onChange={this.onTandemToggle.bind(this, index)} checked={item.checked} />
					<label style={{ marginLeft: "12px", position: 'absolute' }}>{item.text}</label>
				</div>
			)
		})

	}

	renderCheckbox = () => {
		//this.filterJumperOptions2(this.state.jumperOptions, this.state.dailyActivities);
		return this.state.filterItem.map((item, index) => {
			var ratingName = item.text + "_" + index;
			var ratingText = "txt_" + item.text + "_" + index;
			return (
				<div className="col-3">
					<input type="checkbox" id={ratingName} name={ratingName} onChange={this.onToggle.bind(this, index)} checked={item.checked} />
					<label style={{ marginLeft: "12px", position: 'absolute' }}>{item.text}</label>
				</div>
			)
		})
	}
		
	async onTandemToggle(index, e) {
		let newItems = this.state.tandemFilterItem.slice();
		newItems[index].checked = !newItems[index].checked
		this.setState({tandemFilterItem: newItems})
		var istoday = this.state.tandemFilterItem[0].checked;
		var isPositive = this.state.tandemFilterItem[1].checked;
		var isNegative = this.state.tandemFilterItem[2].checked;
		const response = await jumpersStore.getTandemJumps({istoday, isPositive, isNegative });
		this.setState({ tandemDefaultOptions: response.data });
	}			

	async onToggle(index, e) {
		
		let newItems = this.state.filterItem.slice();
		newItems[index].checked = !newItems[index].checked
		this.setState({filterItem: newItems})
		var istoday = newItems[0].checked;
		var isPositive = newItems[1].checked;
		var isNegative = newItems[2].checked;
		var isStaff = newItems[3].checked;
		var isStudent = newItems[4].checked;
		var hasTickets = newItems[5].checked;
		const response = await jumpersStore.getFunJumps({istoday, isPositive, isNegative, isStaff, isStudent, hasTickets });
		this.setState({ defaultOptions: response });	

	}																						

	Menu = (props) => {													
		return (	
			<div>
				<div className="row custom-header" style={{ height: '66px' }}>
					{this.renderCheckbox()}
				</div>
				<div className="row custom-header">
					<div className={`${this.state.earnedBalSetting ? 'col-6' : 'col-8'} custom-name`}>
						Name
					</div>
					<div className={`${this.state.earnedBalSetting ? 'col-3' : 'col-4'} custom-balance`} >
						Balance
					</div>
					{this.state.earnedBalSetting && <div className="col-3 custom-balance">
						Earned Bal.
					</div>}
				</div>
				<components.Menu {...props}>
					{props.children}
				</components.Menu>
			</div>
		);
	}

	TandemMenu = (props) => {
		////console.log(props);
		return (
			<div>
				<div className="row custom-header" style={{ height: '66px' }}>
					{this.renderTandemCheckbox()}
				</div>
				<div className="row custom-header">
					<div className="col-8 custom-name">
						Name
					</div>
				</div>
				<components.Menu {...props}>
					{props.children}
				</components.Menu>
			</div>
		);
	}


	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	}

	handleClick() {
		this.setState({
			open: !this.state.open
		});
	}

	handleRightClick() {
		this.setState({
			rightOpen: !this.state.rightOpen
		});
	}

	render() {

		console.log(this.state.earnedBalSetting, "earnedBalSetting>>")
		var wHeight = window.innerHeight;
		var accountHeight = 515;
		var menuHeight = 490;
		if (wHeight > 789) {
			accountHeight = 620;
			menuHeight = 580;

		}

		const customStyles = {
			control: base => ({
				...base,
				minHeight: 30
			}),
			menu: (provided, state) => ({
				...provided,
				borderBottom: "1px dotted pink",
				color: state.isSelected ? "red" : "blue",
				padding: 20,
				height: accountHeight,
				marginTop: 0,
				background: "#E7F2F6"

			}),
			menuList: (provided, state) => ({
				...provided,
				borderBottom: "1px dotted pink",
				padding: 20,
				minHeight: menuHeight
			})
		};

		const { width, warning } = this.state;
		const isMobile = width <= 500;
		//console.log(this.state.amount, this.state.transactionId);
		////alertt(JSON.stringify(this.state.tandemStudentOptions));
		return (
			<div className="row" style={{ backgroundImage: `url(${background})`, minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>

				<DonutLoader isLoading={this.state.isloading} addClass={"accounts"} />
				{
					isMobile ?
						<div className={`blueBg fullHeightPanes ${this.state.open ? "mobileLeftBar" : "hideSideBar"}`}>
							<div className={`${this.state.open ? "show-panel" : "hide-Panel"}`}>
								<LeftMenu routeHistory={this.props.history} />
							</div>
						</div>
						:
						<div className=" blueBg fullHeightPanes left-container">
							<img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />
							<LeftMenu routeHistory={this.props.history} showCreate={true} />
						</div>
				}

				<div className="middle-container">
					{
						isMobile ?
							<div className="row" style={{ width: "100%" }}>
								<div className="col-md-12">

									<div className="hamBurger-menu">
										<HamburgerMenu
											isOpen={this.state.open}
											menuClicked={this.handleClick.bind(this)}
											width={22}
											height={17}
											strokeWidth={1}
											rotate={0}
											color='white'
											borderRadius={0}
											animationDuration={0.5}
										/>

									</div>
									<div className="hamBurger-right">
										<HamburgerMenu
											isOpen={this.state.rightOpen}
											menuClicked={this.handleRightClick.bind(this)}
											width={22}
											height={17}
											strokeWidth={1}
											rotate={0}
											color='white'
											borderRadius={0}
											animationDuration={0.5}
										/>
									</div>
								</div>
							</div>
							:
							''
					}

					<img src={logo} alt="logo" style={{ height: 60, margin: 'auto', display: 'block' }} />

					{
						this.state.isAlert ?
							<Alert color="success">
								Charge Added Successfully !
							</Alert>
							: ''
					}

					<div className="row">
						<div className="col-md-6">								
							<div className="account-selector">				
								<div className="account-header">				
									<span>TODAY'S ACTIVITY - FUN JUMPS</span>			
								</div>														
								<div className='downloadCSVMain'>			
									<span className='downloadCSV' onClick={this.downloadCSVFunJumps}>Download CSV</span>
								</div>					
								<AsyncSelect						
									loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
									styles={customStyles}
									onInputChange={(val) => this.handleInputChange(val)}
									menuIsOpen
									defaultOptions={this.state.defaultOptions}
									onChange={(val) => this.handleSelect(val)}
									components={{ Option: this.Option, Menu: this.Menu }}
								/>
							</div>
						</div>

						<div className="col-md-4">
							<div className="account-selector">
								<div className="account-header">
									<span>TODAY'S ACTIVITY - TANDEMS</span>
								</div>
								<div className='downloadCSVMain'>
									<span className='downloadCSV' onClick={this.downloadCSVTandemJumps}>Download CSV</span>
								</div>
								<Select options={this.state.tandemDefaultOptions}
									styles={customStyles} menuIsOpen onChange={(val) => this.handleTandemSelect(val)} components={{ Menu: this.TandemMenu }} defaultOptions={this.state.tandemDefaultOptions}
								/>
							</div>
						</div>
					</div>
					{/*<div className="row tranferCredit" style={{ zIndex: '999' }}>
						<div className="col-12">
							<TransferCredit />
						</div>
					</div>*/}
				</div>

				{
					isMobile ?
						<div className={` ${this.state.rightOpen ? "right-menu" : "hideSideBar"}`}>
							<div className={`${this.state.rightOpen ? "show-panel" : "hide-Panel"}`}>
								<div className="col-lg-2 col-md-2 col-sm-12 blueBg fullHeightPanes rightCol" style={{ padding: '0px' }}>
									<AccountRight data={this.state.chargeValue} transactionAmount={this.state.amount} transactionId={this.state.transactionId}
										onJumperChanged={this.onJumperChanged} isDisabled={this.state.isDisable} chargeDisable={this.state.chargeDisable} />
								</div>
							</div>
						</div>
						:
						<div className="blueBg fullHeightPanes rightCol" style={{ padding: '0px' }}>
							<AccountRight
								isTandemStudent={this.state.isTandemStudent}
								data={this.state.chargeValue} transactionAmount={this.state.amount} transactionId={this.state.transactionId}
								onJumperChanged={this.onJumperChanged} isDisabled={this.state.isDisable} chargeDisable={this.state.chargeDisable} />
						</div>
				}

				{warning && <ErrorModal show={warning} />}

			</div>
		);
	}
}

export default AccountsPageCenter;
