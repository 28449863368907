import React, { useState, useEffect, memo } from 'react';
import { Control } from 'react-redux-form';
import itemStore from '../../../../stores/item-store';
import loadsStore from '../../../../stores/loads-store';

const ItemsDetails = (props) => {
   const { studentDetails, selectedItems, selectedDues, activityData, checkForNegatives, selectedBalance } = props;
   const [groupItemData, setGroupItemData] = useState([]);
   const [selectedData, setSelectedData] = useState({ Items: selectedItems, tandemDues: selectedDues.tandem, overweightDues: selectedDues.overweight, videoDues: selectedDues.video, Balance: selectedBalance });
   const [singleItemData, setSingleItemData] = useState(activityData);
   const [studentPackages, setStudentPackages] = useState();

   useEffect(() => {
      const student_packages = itemStore.getStudentPackages();
      setStudentPackages(student_packages);
   }, [])

   useEffect(() => {
      let tandem_Stu_Details = studentDetails?.map((detail, ind) => {
         return {
            ...detail,
            chargeTandemAmount: (detail?.tandemPackage?.price - detail?.totalTandemPaid).toFixed(2),
            chargeTandemTax: (detail?.tandemPackage?.taxAmount - detail?.totalTandemTaxPaid).toFixed(2),
            chargePreviousAmount: (detail?.currentBalance - detail?.totalPreviousPaid).toFixed(2),
            chargePreviousTax: (detail?.currentTaxBalance - detail?.totalPreviousTaxPaid).toFixed(2),
            chargeOverweightAmount: (detail?.payBeforeOverweight - detail?.totalOverweightPaid).toFixed(2),
            chargeVideo: {
               videoAmount: (detail?.videoPackage?.price - detail?.totalVideoPaid).toFixed(2),
               videoTax: (detail?.videoPackage?.taxAmount - detail?.totalVideoTaxPaid).toFixed(2)
            },
            chargeOverweightTax: (0).toFixed(2),
            chargeItem: detail?.countersales?.filter(i => i.price + i.taxAmount - i.totalPaid - i.totalTaxPaid > 0.0001).map((i) => {
               return {
                  amount: (i?.price - i?.totalPaid).toFixed(2),
                  tax: (i.taxAmount - i.totalTaxPaid).toFixed(2),
                  item: i?.item,
                  count: i?.count,
                  _id: i?._id
               }
            }),
         }
      })
      setGroupItemData(tandem_Stu_Details);
      props.handleGroupedStudentData(tandem_Stu_Details)

      let selected_Item_Details = selectedData?.Items?.map((item) => {
         return {
            ...item,
            chargePrice: (item?.price - item?.totalPaid).toFixed(2),
            chargeTaxAmount: (item?.taxAmount - item?.totalTaxPaid).toFixed(2)
         }
      })
      let selected_Tandem_Dues = selectedData?.tandemDues?.map((item) => {
         return {
            ...item,
            tandemPrice: (item?.price).toFixed(2),
            tandemTax: (item?.taxAmount).toFixed(2)
         }
      })
      let selected_Overweight_Dues = selectedData?.overweightDues?.map((item) => {
         return {
            ...item,
            overweightPrice: (item?.price).toFixed(2),
            overweightTax: (item?.taxAmount).toFixed(2)
         }
      })
      let selected_videoDues = selectedData?.videoDues?.map((item) => {
         return {
            ...item,
            videoPrice: (item?.price).toFixed(2),
            videoTax: (item?.taxAmount).toFixed(2)
         }
      })
      let selected_balance = selectedData?.Balance?.map((item) => {
         return {
            ...item,
            balancePrice: (item?.price - item?.previousPaid).toFixed(2),
            balanceTax: (item?.taxAmount - item?.previousTaxPaid).toFixed(2)
         }
      })
      setSelectedData({ ...selectedData, Items: selected_Item_Details, tandemDues: selected_Tandem_Dues, overweightDues: selected_Overweight_Dues, videoDues: selected_videoDues, Balance: selected_balance });
      props?.handleSelectedItems({ ...selectedData, Items: selected_Item_Details, tandemDues: selected_Tandem_Dues, overweightDues: selected_Overweight_Dues, videoDues: selected_videoDues, Balance: selected_balance })

      let single_Tandem_Detail = singleItemData && {
         ...singleItemData,
         newTandemPackage: { tandemPrice: ((singleItemData?.tandemPackage?.price) - (singleItemData?.totalTandemPaid))?.toFixed(2), tandemTax: (singleItemData?.tandemPackage?.taxAmount - singleItemData?.totalTandemTaxPaid)?.toFixed(2) },
         newOverweightDetail: { overweightPrice: (singleItemData?.payBeforeOverweight - singleItemData?.totalOverweightPaid)?.toFixed(2), overweightTax: (0)?.toFixed(2) },
         newCounterItems: singleItemData?.countersales?.filter(data => data.price + data.taxAmount - data.totalPaid - data.totalTaxPaid > 0.0001).map((data, ind) => {
            return {
               itemPrice: (data?.price - data?.totalPaid).toFixed(2),
               itemTax: (data?.taxAmount - data?.totalTaxPaid).toFixed(2),
               item: data?.item,
               count: data?.count,
               _id: data?._id,
               price: data?.price,
               taxAmount: data?.taxAmount,
               totalPaid: data?.totalPaid,
               totalTaxPaid: data?.totalTaxPaid,
               category: data?.category,
               memo: data?.memo,

            }
         }),
         newPreviousCharge: {
            previousAmount: (singleItemData?.currentBalance - singleItemData?.totalPreviousPaid).toFixed(2),
            previousTax: (singleItemData?.currentTaxBalance - singleItemData?.totalPreviousTaxPaid).toFixed(2)
         },
         newVideoPackage: { videoAmount: (singleItemData?.videoPackage?.price - singleItemData?.totalVideoPaid)?.toFixed(2), videoTax: (singleItemData?.videoPackage?.taxAmount - singleItemData?.totalVideoTaxPaid)?.toFixed(2) }
      }
      {
         Object.keys(single_Tandem_Detail).filter((key) => {
            if ({ ...single_Tandem_Detail[key] } && single_Tandem_Detail[key]?.hasOwnProperty("value") && single_Tandem_Detail[key]?.hasOwnProperty("totalPaid") && single_Tandem_Detail[key].value + single_Tandem_Detail[key].taxAmount - single_Tandem_Detail[key].totalPaid - single_Tandem_Detail[key].totalTaxPaid > 0.0001) {
               return true;
            }
         }).map(key => {
            let jumpsData = { ...single_Tandem_Detail[key] };
            jumpsData.newTaxAmount = (jumpsData.taxAmount - jumpsData.totalTaxPaid).toFixed(2)
            jumpsData.newValue = (jumpsData.value - jumpsData.totalPaid).toFixed(2)
            single_Tandem_Detail[key] = jumpsData
         })
      }
      setSingleItemData(single_Tandem_Detail)
      props.handleSingleStudentData(single_Tandem_Detail)
   }, [studentDetails, activityData]);

   let isSelectedItems = (selectedItems?.length > 0) ? true : false;
   let isSelectedBalance = (selectedBalance?.length > 0) ? true : false;
   let isSelectedDues = (selectedDues?.tandem?.length > 0 || selectedDues?.video?.length > 0 || selectedDues?.overweight?.length > 0) ? true : false;

   const handleRemoveItem = (ind, item, index, activity, key) => {
      if (activity?.newTandemPackage && item === "tandemPackage") {
         let _singleItemData = { ...singleItemData }
         delete _singleItemData[item];
         delete _singleItemData.newTandemPackage;
         setSingleItemData(_singleItemData);
         props.handleSingleStudentData(_singleItemData)
         props.handleUpdateTotalAmountTaxSingleData(_singleItemData);
      }
      else if (activity?.newVideoPackage && item === "videoPackage") {
         let _singleItemData = { ...singleItemData }
         delete _singleItemData[item];
         delete _singleItemData?.newVideoPackage;
         setSingleItemData(_singleItemData);
         props.handleSingleStudentData(_singleItemData)
         props.handleUpdateTotalAmountTaxSingleData(_singleItemData);
      }
      else if (activity?.newCounterItems && item === "countersales") {
         let _singleItemData = JSON.parse(JSON.stringify(singleItemData));
         const itemIndex = _singleItemData.newCounterItems.findIndex(object => {
            return object._id === _singleItemData[item][index]._id;
         });
         const oldItemIndex = _singleItemData[item].findIndex(object => {
            return object._id === _singleItemData.newCounterItems[index]._id;
         });
         _singleItemData.newCounterItems.splice(itemIndex, 1)
         _singleItemData[item].splice(oldItemIndex, 1)
         setSingleItemData(_singleItemData);
         props.handleSingleStudentData(_singleItemData)
         props.handleUpdateTotalAmountTaxSingleData(_singleItemData);
      }
      else if (activity?.newOverweightDetail && item === "payBeforeOverweight") {
         let _singleItemData = { ...singleItemData }
         delete _singleItemData[item];
         delete _singleItemData.newOverweightDetail;
         setSingleItemData(_singleItemData);
         props.handleSingleStudentData(_singleItemData)
         props.handleUpdateTotalAmountTaxSingleData(_singleItemData);
      }
      else if (item === "jumps" && activity[key].newValue) {
         let _singleItemData = { ...singleItemData }
         delete _singleItemData[key];
         setSingleItemData(_singleItemData);
         props.handleSingleStudentData(_singleItemData)
         props.handleUpdateTotalAmountTaxSingleData(_singleItemData);

      }
      else if (activity?.newPreviousCharge && item === "currentBalance") {
         let _singleItemData = { ...singleItemData }
         delete _singleItemData[item];
         delete _singleItemData.newPreviousCharge;
         setSingleItemData(_singleItemData);
         props.handleSingleStudentData(_singleItemData)
         props.handleUpdateTotalAmountTaxSingleData(_singleItemData);
      }
      else if (activity?.coachedJumps && item === "coachedJumps") {
         let _singleItemData = { ...singleItemData }
         delete _singleItemData[item];
         delete _singleItemData.coachedJumps;
         setSingleItemData(_singleItemData);
         props.handleSingleStudentData(_singleItemData)
         props.handleUpdateTotalAmountTaxSingleData(_singleItemData);
      }
      else {
         let _groupItemData = [...groupItemData];
         if (item === "tandemPackage") {
            delete _groupItemData[ind][item];
            delete _groupItemData[ind].chargeTandemAmount;
            delete _groupItemData[ind].chargeTandemTax;
         }
         if (item === "videoPackage") {
            delete _groupItemData[ind][item];
            delete _groupItemData[ind].chargeVideo;
         }
         if (item === "payBeforeOverweight") {
            delete _groupItemData[ind][item];
            delete _groupItemData[ind].chargeOverweightAmount;
            delete _groupItemData[ind].chargeOverweightTax;
         }
         if (item === "countersales") {
            const itemIndex = _groupItemData[ind].chargeItem.findIndex(object => object._id === _groupItemData[ind][item][index]?._id);
            const oldItemIndex = _groupItemData[ind][item].findIndex(object => {
               return object._id === _groupItemData[ind].chargeItem[index]?._id;
            });
            _groupItemData[ind].chargeItem.splice(itemIndex, 1)
            _groupItemData[ind][item].splice(oldItemIndex, 1)
         }
         setGroupItemData(_groupItemData);
         props.handleUpdateTotalAmountGroupData(_groupItemData);
      }
   }

   const handleSelectedRemoveItem = (ind, item) => {
      if (item === "tandemDues") {
         let selectTandemData = [...selectedData?.tandemDues];
         selectTandemData.splice(ind, 1)
         setSelectedData({ ...selectedData, tandemDues: selectTandemData })
         props.handleSelectedData(selectTandemData)
         props.handleSelectedData({ ...selectedData, tandemDues: selectTandemData })
         props.handleSelectedItems({ ...selectedData, tandemDues: selectTandemData })
      }
      else if (item === "videoDues") {
         let selectVideoData = [...selectedData?.videoDues];
         selectVideoData.splice(ind, 1)
         setSelectedData({ ...selectedData, videoDues: selectVideoData })
         props.handleSelectedData({ ...selectedData, videoDues: selectVideoData })
         props.handleSelectedItems({ ...selectedData, videoDues: selectVideoData })
      }
      else if (item === "overweightDues") {
         let selectOverweightDues = [...selectedData?.overweightDues];
         selectOverweightDues.splice(ind, 1)
         setSelectedData({ ...selectedData, overweightDues: selectOverweightDues })
         props.handleSelectedData({ ...selectedData, overweightDues: selectOverweightDues })
         props.handleSelectedItems({ ...selectedData, overweightDues: selectOverweightDues })
      }
      else if (item === "items") {
         let selectItems = [...selectedData?.Items];
         selectItems.splice(ind, 1)
         setSelectedData({ ...selectedData, Items: selectItems })
         props.handleSelectedData({ ...selectedData, Items: selectItems })
         props.handleSelectedItems({ ...selectedData, Items: selectItems })
      }
      else if (item === "balance") {
         let selectBalance = [...selectedData?.Balance];
         selectBalance.splice(ind, 1)
         setSelectedData({ ...selectedData, Balance: selectBalance })
         props.handleSelectedData({ ...selectedData, Balance: selectBalance })
         props.handleSelectedItems({ ...selectedData, Balance: selectBalance })
      }
   }

   const handle_charge_amount = (event, ind, index, activity, key) => {
      let amount = event.target.value;
      if (event.target.name === `TandemAmount-${ind}`) {
         if (activity.newTandemPackage) {
            let newSingleTandemData = { ...singleItemData };
            newSingleTandemData.newTandemPackage.tandemPrice = amount;
            newSingleTandemData.newTandemPackage.tandemTax = ((Number(activity?.tandemPackage?.taxAmount - activity?.totalTandemTaxPaid) / Number(activity?.tandemPackage?.price - activity?.totalTandemPaid)) * Number(newSingleTandemData.newTandemPackage.tandemPrice)).toFixed(2);
            setSingleItemData(newSingleTandemData);
            props.handleUpdateTotalAmountTaxSingleData(newSingleTandemData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeTandemAmount = amount;
            newGroupData[ind].chargeTandemTax = ((Number(activity?.tandemPackage?.taxAmount - activity?.totalTandemTaxPaid) / Number(activity?.tandemPackage?.price - activity?.totalTandemPaid)) * Number(newGroupData[ind].chargeTandemAmount)).toFixed(2)
            setGroupItemData(newGroupData);
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `PreviousAmount-${ind}`) {
         if (activity.newPreviousCharge) {
            let newSinglePreviousData = { ...singleItemData };
            newSinglePreviousData.newPreviousCharge.previousAmount = amount;
            newSinglePreviousData.newPreviousCharge.previousTax = ((Number(activity?.currentTaxBalance - activity?.totalPreviousTaxPaid) / Number(activity?.currentBalance - activity?.totalPreviousPaid)) * Number(newSinglePreviousData.newPreviousCharge.previousAmount)).toFixed(2);
            setSingleItemData(newSinglePreviousData);
            props.handleUpdateTotalAmountTaxSingleData(newSinglePreviousData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargePreviousAmount = amount;
            newGroupData[ind].chargePreviousTax = ((Number(activity?.currentTaxBalance - activity?.totalPreviousTaxPaid) / Number(activity?.currentBalance - activity?.totalPreviousPaid)) * Number(newGroupData[ind].chargePreviousAmount)).toFixed(2)
            setGroupItemData(newGroupData);
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }

      else if (event.target.name === `OverweightAmount-${ind}`) {
         if (activity?.newOverweightDetail) {
            let newSingleOverweightData = { ...singleItemData };
            newSingleOverweightData.newOverweightDetail.overweightPrice = amount;
            newSingleOverweightData.newOverweightDetail.overweightTax = ((Number(0) / Number(activity?.payBeforeOverweight - activity?.totalOverweightPaid)) * Number(newSingleOverweightData.newOverweightDetail.overweightPrice)).toFixed(2)
            setSingleItemData(newSingleOverweightData)
            props.handleUpdateTotalAmountTaxSingleData(newSingleOverweightData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeOverweightAmount = amount;
            newGroupData[ind].chargeOverweightTax = ((Number(0) / Number(activity?.payBeforeOverweight - activity?.totalOverweightPaid)) * Number()).toFixed(2)
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `CounterItemsAmount-${ind}`) {
         if (activity?.newCounterItems) {
            let newSingleItemsData = { ...singleItemData };
            newSingleItemsData.newCounterItems[index].itemPrice = amount;
            newSingleItemsData.newCounterItems[index].itemTax = ((Number(activity?.newCounterItems[index].taxAmount - activity?.newCounterItems[index].totalTaxPaid) / Number(activity?.newCounterItems[index].price - activity?.newCounterItems[index].totalPaid)) * Number(newSingleItemsData.newCounterItems[index].itemPrice)).toFixed(2)
            setSingleItemData(newSingleItemsData)
            props.handleUpdateTotalAmountTaxSingleData(newSingleItemsData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeItem[index].amount = amount;
            newGroupData[ind].chargeItem[index].tax = ((Number(activity?.countersales[index].taxAmount - activity?.countersales[index].totalTaxPaid) / Number(activity?.countersales[index].price - activity?.countersales[index].totalPaid)) * Number(newGroupData[ind].chargeItem[index].amount)).toFixed(2);
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `VideoAmount-${ind}`) {
         if (activity?.newVideoPackage) {
            let newSingleVideoData = { ...singleItemData };
            newSingleVideoData.newVideoPackage.videoAmount = amount;
            newSingleVideoData.newVideoPackage.videoTax = ((Number(activity?.videoPackage?.taxAmount - activity?.totalVideoTaxPaid) / Number(activity?.videoPackage?.price - activity?.totalVideoPaid)) * Number(newSingleVideoData.newVideoPackage.videoAmount)).toFixed(2)
            setSingleItemData(newSingleVideoData)
            props.handleUpdateTotalAmountTaxSingleData(newSingleVideoData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeVideo.videoAmount = amount;
            newGroupData[ind].chargeVideo.videoTax = ((Number(activity?.videoPackage?.taxAmount - activity?.totalVideoTaxPaid) / Number(activity?.videoPackage?.price - activity?.totalVideoPaid)) * Number(newGroupData[ind].chargeVideo.videoAmount)).toFixed(2)
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `JumpsAmount-${ind}`) {
         let newSingleJumps = { ...singleItemData };
         newSingleJumps[key].newValue = amount;
         newSingleJumps[key].newTaxAmount = ((Number(activity[key]?.taxAmount - activity[key]?.totalTaxPaid) / Number(activity[key]?.value - activity[key]?.totalPaid)) * Number(newSingleJumps[key]?.newValue)).toFixed(2)
         setSingleItemData(newSingleJumps)
         props.handleUpdateTotalAmountTaxSingleData(newSingleJumps, key)

      }
      else if (event.target.name === `SelectedItemsAmount-${ind}`) {
         let newSelectedItems = [...selectedData.Items];
         newSelectedItems[ind].chargePrice = amount;
         newSelectedItems[ind].chargeTaxAmount = ((Number(selectedItems[ind].taxAmount) / Number(selectedItems[ind].price)) * Number(newSelectedItems[ind].chargePrice)).toFixed(2)
         setSelectedData({ ...selectedData, Items: newSelectedItems })
         props.handleSelectedData({ ...selectedData, Items: newSelectedItems })
      }
      else if (event.target.name === `SelectedTandemAmount-${ind}`) {
         let newSelectedTandem = [...selectedData.tandemDues];
         newSelectedTandem[ind].tandemPrice = amount;
         newSelectedTandem[ind].tandemTax = ((Number(selectedDues.tandem[ind].taxAmount) / Number(selectedDues.tandem[ind].price)) * Number(newSelectedTandem[ind].tandemPrice)).toFixed(2)
         setSelectedData({ ...selectedData, tandemDues: newSelectedTandem })
         props.handleSelectedData({ ...selectedData, tandemDues: newSelectedTandem })
      }
      else if (event.target.name === `SelectedVideoAmount-${ind}`) {
         let newSelectedVideo = [...selectedData.videoDues];
         newSelectedVideo[ind].videoPrice = amount;
         newSelectedVideo[ind].videoTax = ((Number(selectedDues.video[ind].taxAmount) / Number(selectedDues.video[ind].price)) * Number(newSelectedVideo[ind].videoPrice)).toFixed(2)
         setSelectedData({ ...selectedData, videoDues: newSelectedVideo })
         props.handleSelectedData({ ...selectedData, videoDues: newSelectedVideo })
      }
      else if (event.target.name === `SelectedOverweightAmount-${ind}`) {
         let newSelectedOverweight = [...selectedData.overweightDues];
         newSelectedOverweight[ind].overweightPrice = amount;
         newSelectedOverweight[ind].overweightTax = ((Number(selectedDues.overweight[ind].taxAmount) / Number(selectedDues.overweight[ind].price)) * Number(newSelectedOverweight[ind].overweightPrice)).toFixed(2)
         setSelectedData({ ...selectedData, overweightDues: newSelectedOverweight })
         props.handleSelectedData({ ...selectedData, overweightDues: newSelectedOverweight })
      }
      else if (event.target.name === `SelectedBalanceAmount-${ind}`) {
         let newSelectedBalance = [...selectedData.Balance];
         newSelectedBalance[ind].balancePrice = amount;
         newSelectedBalance[ind].balanceTax = ((Number(selectedBalance[ind].taxAmount) / Number(selectedBalance[ind].price)) * Number(newSelectedBalance[ind].balancePrice)).toFixed(2)
         setSelectedData({ ...selectedData, Balance: newSelectedBalance })
         props.handleSelectedData({ ...selectedData, Balance: newSelectedBalance })
      }
   }

   const handle_charge_tax = (event, ind, index, activity, key) => {
      let tax = event.target.value;
      if (event.target.name === `TandemTax-${ind}`) {
         if (activity.newTandemPackage) {
            let newSingleTandemData = { ...singleItemData };
            newSingleTandemData.newTandemPackage.tandemTax = tax;
            setSingleItemData(newSingleTandemData)
            props.handleUpdateTotalAmountTaxSingleData(newSingleTandemData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeTandemTax = tax;
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `PreviousTax-${ind}`) {
         if (activity.newPreviousCharge) {
            let newSinglePreviousData = { ...singleItemData };
            newSinglePreviousData.newPreviousCharge.previousTax = tax;
            setSingleItemData(newSinglePreviousData)
            props.handleUpdateTotalAmountTaxSingleData(newSinglePreviousData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargePreviousTax = tax;
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `OverweightTax-${ind}`) {
         if (activity?.newOverweightDetail) {
            let newSingleOverweightData = { ...singleItemData };
            newSingleOverweightData.newOverweightDetail.overweightTax = tax;
            setSingleItemData(newSingleOverweightData)
            props.handleUpdateTotalAmountTaxSingleData(newSingleOverweightData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeOverweightTax = tax;
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `CounterItemsTax-${ind}`) {
         if (activity?.newCounterItems) {
            let newSingleItemsData = { ...singleItemData };
            newSingleItemsData.newCounterItems[index].itemTax = tax;
            setSingleItemData(newSingleItemsData)
            props.handleUpdateTotalAmountTaxSingleData(newSingleItemsData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeItem[index].tax = tax;
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }

      else if (event.target.name === `VideoTax-${ind}`) {
         if (activity?.newVideoPackage) {
            let newSingleVideoData = { ...singleItemData };
            newSingleVideoData.newVideoPackage.videoTax = tax;
            setSingleItemData(newSingleVideoData)
            props.handleUpdateTotalAmountTaxSingleData(newSingleVideoData)
         }
         else {
            let newGroupData = [...groupItemData];
            newGroupData[ind].chargeVideo.videoTax = tax;
            setGroupItemData(newGroupData)
            props.handleUpdateTotalAmountGroupData(newGroupData);
         }
      }
      else if (event.target.name === `JumpsTax-${ind}`) {
         let newSingleJump = { ...singleItemData };
         newSingleJump[key].newTaxAmount = tax;
         setSingleItemData(newSingleJump)
         props.handleUpdateTotalAmountTaxSingleData(newSingleJump)
      }
      else if (event.target.name === `SelectedItemsTax-${ind}`) {
         let newSelectedItems = [...selectedData.Items];
         newSelectedItems[ind].chargeTaxAmount = tax;
         setSelectedData({ ...selectedData, Items: newSelectedItems })
         props.handleSelectedData({ ...selectedData, Items: newSelectedItems })
      }
      else if (event.target.name === `SelectedTandemTax-${ind}`) {
         let newSelectedTandem = [...selectedData.tandemDues];
         newSelectedTandem[ind].tandemTax = tax;
         setSelectedData({ ...selectedData, tandemDues: newSelectedTandem })
         props.handleSelectedData({ ...selectedData, tandemDues: newSelectedTandem })
      }
      else if (event.target.name === `SelectedVideoTax-${ind}`) {
         let newSelectedVideo = [...selectedData.videoDues];
         newSelectedVideo[ind].videoTax = tax;
         setSelectedData({ ...selectedData, videoDues: newSelectedVideo })
         props.handleSelectedData({ ...selectedData, videoDues: newSelectedVideo })
      }
      else if (event.target.name === `SelectedOverweightTax-${ind}`) {
         let newSelectedOverweight = [...selectedData.overweightDues];
         newSelectedOverweight[ind].overweightTax = tax;
         setSelectedData({ ...selectedData, overweightDues: newSelectedOverweight })
         props.handleSelectedData({ ...selectedData, overweightDues: newSelectedOverweight })
      }
      else if (event.target.name === `SelectedBalanceTax-${ind}`) {
         let newSelectedBalance = [...selectedData.Balance];
         newSelectedBalance[ind].balanceTax = tax;
         setSelectedData({ ...selectedData, Balance: newSelectedBalance })
         props.handleSelectedData({ ...selectedData, Balance: newSelectedBalance })
      }
   }

   const handleNonSelected_Items_Dues = (activity, ind) => {
      return <>
         {(activity?.currentBalance + activity?.currentTaxBalance - activity?.totalPreviousPaid - activity?.totalPreviousTaxPaid > 0.0001) &&
            <tr className="itemizedDetail">
               <td>Previous Balance</td>
               <td>1</td>
               <td>{checkForNegatives(activity?.currentBalance - activity?.totalPreviousPaid)}</td>
               <td>{checkForNegatives(activity?.currentTaxBalance - activity?.totalPreviousTaxPaid)}</td>
               <td>
                  <Control.input name={`PreviousAmount-${ind}`} model={`.PreviousAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newPreviousCharge ? activity?.newPreviousCharge?.previousAmount : activity?.chargePreviousAmount} onChange={(e) => { handle_charge_amount(e, ind, "", activity) }}
                  />
               </td>
               {loadsStore.isTaxEnabled() &&<td>
                  <Control.input name={`PreviousTax-${ind}`} model={`.PreviousTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newPreviousCharge ? activity?.newPreviousCharge?.previousTax : activity?.chargePreviousTax} onChange={(e) => { handle_charge_tax(e, ind, "", activity) }}
                  />
               </td>}
               <td>{activity?.isTandemStudent ? (activity?.firstname + ' ' + activity?.lastname) : (activity?.name)}</td>
               <td><i class='fa fa-remove' onClick={() => handleRemoveItem(ind, "currentBalance", "", activity)}></i></td>
            </tr>
         }

         {(activity?.tandemPackage?.price + activity?.tandemPackage?.taxAmount - activity?.totalTandemPaid - activity?.totalTandemTaxPaid > 0.0001) &&
            <tr className="itemizedDetail">
               <td>Tandem Balance</td>
               <td>1</td>
               <td>{checkForNegatives(activity?.tandemPackage?.price - activity?.totalTandemPaid)}</td>
               <td>{checkForNegatives(activity?.tandemPackage?.taxAmount - activity?.totalTandemTaxPaid)}</td>
               <td>
                  <Control.input name={`TandemAmount-${ind}`} model={`.TandemAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newTandemPackage ? activity?.newTandemPackage?.tandemPrice : activity?.chargeTandemAmount} onChange={(e) => { handle_charge_amount(e, ind, "", activity) }}
                  />
               </td>
               {loadsStore.isTaxEnabled() && <td>
                  <Control.input name={`TandemTax-${ind}`} model={`.TandemTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newTandemPackage ? activity?.newTandemPackage?.tandemTax : activity?.chargeTandemTax} onChange={(e) => { handle_charge_tax(e, ind, "", activity) }}
                  />
               </td>}
               <td>{activity?.isTandemStudent ? (activity?.firstname + ' ' + activity?.lastname) : (activity?.name)}</td>
               <td><i class='fa fa-remove' onClick={() => handleRemoveItem(ind, "tandemPackage", "", activity)}></i></td>
            </tr>
         }

         {(activity?.videoPackage?.price + activity?.videoPackage?.taxAmount - activity?.totalVideoPaid - activity?.totalVideoTaxPaid > 0.001) &&
            <tr className="itemizedDetail">
               <td>Video Balance</td>
               <td>1</td>
               <td>{checkForNegatives(activity?.videoPackage?.price - activity?.totalVideoPaid)}</td>
               <td>{checkForNegatives(activity?.videoPackage?.taxAmount - activity?.totalVideoTaxPaid)}</td>
               <td>
                  <Control.input name={`VideoAmount-${ind}`} model={`.VideoAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newVideoPackage ? activity?.newVideoPackage?.videoAmount : activity?.chargeVideo?.videoAmount} onChange={(e) => { handle_charge_amount(e, ind, "", activity) }}
                  />
               </td>
               {loadsStore.isTaxEnabled() &&<td>
                  <Control.input name={`VideoTax-${ind}`} model={`.VideoTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newVideoPackage ? activity?.newVideoPackage?.videoTax : activity?.chargeVideo?.videoTax} onChange={(e) => { handle_charge_tax(e, ind, "", activity) }}
                  />
               </td>}
               <td>{activity?.isTandemStudent ? (activity?.firstname + ' ' + activity?.lastname) : (activity?.name)}</td>
               <td><i class='fa fa-remove' onClick={() => handleRemoveItem(ind, "videoPackage", "", activity)}></i></td>
            </tr>
         }

         {activity?.payBeforeOverweight - activity?.totalOverweightPaid > 0.0001 &&
            <tr className="itemizedDetail">
               <td>Overweight Balance</td>
               <td>1</td>
               <td>{checkForNegatives(activity?.payBeforeOverweight - activity?.totalOverweightPaid)}</td>
               <td>{checkForNegatives(0)}</td>
               <td>
                  <Control.input name={`OverweightAmount-${ind}`} model={`.OverweightAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newOverweightDetail ? activity?.newOverweightDetail?.overweightPrice : activity?.chargeOverweightAmount} onChange={(e) => { handle_charge_amount(e, ind, "", activity) }}
                  />
               </td>
               {loadsStore.isTaxEnabled() &&<td>
                  <Control.input name={`OverweightTax-${ind}`} model={`.OverweightTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newOverweightDetail ? activity?.newOverweightDetail?.overweightTax : activity?.chargeOverweightTax} onChange={(e) => { handle_charge_tax(e, ind, "", activity) }}
                  />
               </td>}
               <td>{activity?.isTandemStudent ? (activity?.firstname + ' ' + activity?.lastname) : (activity?.name)}</td>
               <td><i class='fa fa-remove' onClick={() => handleRemoveItem(ind, "payBeforeOverweight", "", activity)}></i></td>
            </tr>
         }

         {activity?.countersales?.length > 0 && activity?.countersales?.filter(i => i.price + i.taxAmount - i.totalPaid - i.totalTaxPaid > 0.0001).map((i, index) => {
            return <tr className="itemizedDetail">
               <td>{i.item.toUpperCase()}</td>
               <td>{i.count}</td>
               <td>{checkForNegatives(i.price - i.totalPaid)}</td>
               <td>{checkForNegatives(i.taxAmount - i.totalTaxPaid)}</td>
               <td>
                  <Control.input name={`CounterItemsAmount-${ind}`} model={`.CounterItemsAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newCounterItems ? activity?.newCounterItems[index]?.itemPrice : activity?.chargeItem[index]?.amount} onChange={(e) => { handle_charge_amount(e, ind, index, activity) }}
                  />
               </td>
               {loadsStore.isTaxEnabled() &&<td>
                  <Control.input name={`CounterItemsTax-${ind}`} model={`.CounterItemsTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity?.newCounterItems ? activity?.newCounterItems[index]?.itemTax : activity?.chargeItem[index]?.tax} onChange={(e) => { handle_charge_tax(e, ind, index, activity) }}
                  />
               </td>}
               <td>{activity?.isTandemStudent ? (activity?.firstname + ' ' + activity?.lastname) : (activity?.name)}</td>
               <td><i class='fa fa-remove' onClick={() => handleRemoveItem(ind, "countersales", index, activity, i)}></i></td>
            </tr>
         })}

         {Object.keys(activity).filter((key) => {
            if (activity[key]?.hasOwnProperty("value") && activity[key]?.hasOwnProperty("totalPaid") && activity[key].value + activity[key].taxAmount - activity[key].totalPaid - activity[key].totalTaxPaid > 0.002) {
               return true;
            }
         }).map((key, index) => {
            return <tr className="itemizedDetail">
               <td>{key.toUpperCase()}</td>
               <td>{activity[key].count}</td>
               <td>{checkForNegatives(activity[key].value - activity[key].totalPaid)}</td>
               <td>{checkForNegatives(activity[key].taxAmount - activity[key].totalTaxPaid)}</td>
               <td>
                  <Control.input name={`JumpsAmount-${ind}`} model={`.JumpsAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity[key].newValue} onChange={(e) => { handle_charge_amount(e, ind, index, activity, key) }}
                  />
               </td>
               {loadsStore.isTaxEnabled() &&<td>
                  <Control.input name={`JumpsTax-${ind}`} model={`.JumpsTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                     type="number" value={activity[key].newTaxAmount} onChange={(e) => { handle_charge_tax(e, ind, index, activity, key) }}
                  />
               </td>}
               <td>{activity?.isTandemStudent ? (activity?.firstname + ' ' + activity?.lastname) : (activity?.name)}</td>
               <td><i class='fa fa-remove' onClick={() => handleRemoveItem(ind, "jumps", "", activity, key)}></i></td>
            </tr>
         })


         }
      </>
   }

   const handleSeleceted_Items_Dues = () => {
      return <>
         {
            selectedData?.Items?.map((i, ind) => {
               return <tr className="itemizedDetail" key={ind}>
                  <td>{i.item.toUpperCase()}</td>
                  <td>{i.count}</td>
                  <td>{checkForNegatives(i.price - i.totalPaid)}</td>
                  <td>{checkForNegatives(i.taxAmount - i.totalTaxPaid)}</td>
                  <td>
                     <Control.input name={`SelectedItemsAmount-${ind}`} model={`.SelectedItemsAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.chargePrice} onChange={(e) => { handle_charge_amount(e, ind) }}
                     />
                  </td>
                  {loadsStore.isTaxEnabled() &&<td>
                     <Control.input name={`SelectedItemsTax-${ind}`} model={`.SelectedItemsTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.chargeTaxAmount} onChange={(e) => { handle_charge_tax(e, ind) }}
                     />
                  </td>}
                  <td style={{ padding: 5 }}>{i.name}</td>
                  <td><i class='fa fa-remove' onClick={(e) => handleSelectedRemoveItem(ind, "items")}></i></td>
               </tr>
            })
         }
         {
            selectedData?.tandemDues?.map((i, ind) => {
               return <tr className="itemizedDetail" key={ind}>
                  <td>Tandem Balance</td>
                  <td>1</td>
                  <td>{checkForNegatives(i?.price)}</td>
                  <td>{checkForNegatives(i?.taxAmount)}</td>
                  <td>
                     <Control.input name={`SelectedTandemAmount-${ind}`} model={`.SelectedTandemAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.tandemPrice} onChange={(e) => { handle_charge_amount(e, ind) }}
                     />
                  </td>
                  {loadsStore.isTaxEnabled() &&<td>
                     <Control.input name={`SelectedTandemTax-${ind}`} model={`.SelectedTandemTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.tandemTax} onChange={(e) => { handle_charge_tax(e, ind) }}
                     />
                  </td>}
                  <td>{i.name}</td>
                  <td><i class='fa fa-remove' onClick={(e) => handleSelectedRemoveItem(ind, "tandemDues")}></i></td>
               </tr>
            })
         }
         {
            selectedData?.videoDues?.map((i, ind) => {
               return <tr className="itemizedDetail" key={ind}>
                  <td>Video Balance</td>
                  <td>1</td>
                  <td>{checkForNegatives(i.price)}</td>
                  <td>{checkForNegatives(i.taxAmount)}</td>
                  <td>
                     <Control.input name={`SelectedVideoAmount-${ind}`} model={`.SelectedVideoAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.videoPrice} onChange={(e) => { handle_charge_amount(e, ind) }}
                     />
                  </td>
                  {loadsStore.isTaxEnabled() &&<td>
                     <Control.input name={`SelectedVideoTax-${ind}`} model={`.SelectedVideoTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.videoTax} onChange={(e) => { handle_charge_tax(e, ind) }}
                     />
                  </td>}
                  <td>{i.name}</td>
                  <td><i class='fa fa-remove' onClick={(e) => handleSelectedRemoveItem(ind, "videoDues")}></i></td>
               </tr>
            })
         }
         {
            selectedData?.overweightDues?.map((i, ind) => {
               return <tr className="itemizedDetail" key={ind}>
                  <td>Overweight Balance</td>
                  <td>1</td>
                  <td>{checkForNegatives(i.price)}</td>
                  <td>{checkForNegatives(i.taxAmount)}</td>
                  <td>
                     <Control.input name={`SelectedOverweightAmount-${ind}`} model={`.SelectedOverweightAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.overweightPrice} onChange={(e) => { handle_charge_amount(e, ind) }}
                     />
                  </td>
                  {loadsStore.isTaxEnabled() &&<td>
                     <Control.input name={`SelectedOverweightTax-${ind}`} model={`.SelectedOverweightTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.overweightTax} onChange={(e) => { handle_charge_tax(e, ind) }}
                     />
                  </td>}
                  <td>{i.name}</td>
                  <td><i class='fa fa-remove' onClick={(e) => handleSelectedRemoveItem(ind, "overweightDues")}></i></td>
               </tr>
            })
         }
         {
            selectedData?.Balance?.map((i, ind) => {
               return <tr className="itemizedDetail" key={ind}>
                  <td>Previous Balance</td>
                  <td>1</td>
                  <td>{checkForNegatives(i.price - i.previousPaid)}</td>
                  <td>{checkForNegatives(i.taxAmount - i.previousTaxPaid)}</td>
                  <td>
                     <Control.input name={`SelectedBalanceAmount-${ind}`} model={`.SelectedBalanceAmount-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.balancePrice} onChange={(e) => { handle_charge_amount(e, ind) }}
                     />
                  </td>
                  {loadsStore.isTaxEnabled() &&<td>
                     <Control.input name={`SelectedBalanceTax-${ind}`} model={`.SelectedBalanceTax-${ind}`} className="form-control" autoComplete="off" mapProps={{ onChange: (props) => props.onChange }}
                        type="number" value={i?.balanceTax} onChange={(e) => { handle_charge_tax(e, ind) }}
                     />
                  </td>}
                  <td>{i.name}</td>
                  <td><i class='fa fa-remove' onClick={(e) => handleSelectedRemoveItem(ind, "balance")}></i></td>
               </tr>
            })
         }
      </>
   }

   const handletandemPackageItems = (activity, ind) => {
      if (!isSelectedItems && !isSelectedDues && !isSelectedBalance) {
         return handleNonSelected_Items_Dues(activity, ind)
      }
      else if (selectedItems || selectedDues || selectedBalance) {
         return handleSeleceted_Items_Dues()
      }
   }

   const handleAllItems = () => {
      if (groupItemData && !isSelectedItems && !isSelectedDues && !isSelectedBalance) {
         return groupItemData?.map((activity, ind) => {
            return handletandemPackageItems(activity, ind)
         })
      }
      else {
         let activity = singleItemData || {};
         return handletandemPackageItems(activity);
      }
   }

   return (
      <>
         {handleAllItems()}
      </>
   )
}
export default memo(ItemsDetails);


