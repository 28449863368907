/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import loadsStore from '../../../stores/loads-store';
import ReactDOM from 'react-dom';
import { addCompletedJumpers } from '../../../actions/updateCompletedJump'
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'

class RemovableJumper extends Component {

  constructor(props) {
    super(props);
    this.setEditable = this.setEditable.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      editMode: false,
      load: props.load,
      jumpTypes: []
    };
  }

  setEditable() {
    this.setState({
      editMode: !this.state.editMode
    });
  }



  handleSubmit(loadNum, jumpId, jumperId) {
    this.setEditable();
    // console.log(loadNum + " jumpId: " + jumpId + " jumperId: " + jumperId);
    loadsStore.removeJumper(loadNum, jumpId, jumperId);
  }
  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    })
  }
  changeJumper = (isCompleted) => {
    if (isCompleted) {
      // this.props.openModel({ isModal: true, editType: false })
      this.setState({
        isModalOpen: true
      })
    }
    else {
      this.setState({
        editMode: !this.state.editMode
      })
    }
  }
  updateJumpType = (value, loadNum, jumpId) => {
    if (value !== "Select..") {
      var jumpBody = {
        jumpType: value
      }
      var that = this;
      var type = value;
      if (this.props.isCompleted) {
        const jumpersIds = this.props.funJump.jumpers.map(index => {
          return index._id
        })
        var jumpPostBody = {
          jumperIds: jumpersIds, //value.map(jumperOption => jumperOption.value._id),
          jumpType: type,
          gearRentals: this.props.funJump.gearRentals
        };
        if (this.props.isToday) {
          jumpPostBody.isToday = true
        }
        var jumpId = this.props.funJump.jumpId;
        var loadId = this.props.loadId;
        this.setState({
          editMode: !this.state.editMode
        });
        this.props.addCompletedJumpers(loadId, jumpId, jumpPostBody);
      }
      else {
        Promise.all([loadsStore.updateFunJump(loadNum, jumpId, jumpBody)])
          .then(function (result) {
            var data = result[0];
            if (data === true) {
              that.setState({ editMode: !that.state.editMode });
            }
            else {
              that.setState({ editMode: !that.state.editMode });
            }
          })
      }
    }
  };
  componentDidMount() {
    this.setState({ jumpTypes: loadsStore.getFunJumpTypes() })
    document.addEventListener('click', this.handleClickOutside, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        editMode: false
      });
    }
  }

  renderJumpSize = () => {
    if (this.props.jumpType !== "Solo Student") {
      if (this.props.funJump.jumpers.length == 1) {
        return "Solo";
      }
      else {
        var str = "";
        if (this.props.funJump.jumpers.filter(j => j.isVideographer !== true).length == 1) {
          str = "Solo";
        }
        else {
          str = this.props.funJump.jumpers.filter(j => j.isVideographer !== true).length + "-way";
        }
        var numVideographers = this.props.funJump.jumpers.filter(j => j.isVideographer).length;
        if (numVideographers === 0) {
          return <span>{str}</span>;
        }
        if (numVideographers == 1) {
          str += " +";
        }
        else if (numVideographers > 1) {
          str += " + " + numVideographers;
        }
        return <span>{str} <i className="fa fa-xs fa-video-camera"> </i></span>;
      }
    }
    return null;
  };

  render() {
    var jumpMenu = this.state.jumpTypes?.map(type => {
      return (
        <a className="dropdown-item" onClick={() => { this.updateJumpType(type, this.props.loadNum, this.props.jumpId) }} >{type}</a>
      )
    })
    return (
      <React.Fragment>
        {
          !this.state.editMode ?
            // <div className="jumpType-button" onClick={()=>this.changeJumper(this.props.isCompleted)} style={{ padding: '0 1 0 4px' }}>
            //   {this.renderJumpSize()} {this.props.jumpType}
            // </div>
            <div className="funjumpType-button" onClick={() => !this.props.isCompleted && this.changeJumper(this.props.isCompleted)} style={{ padding: '0 1 0 4px' }}>
              {this.renderJumpSize()} {this.props.jumpType}
            </div>
            :
            <div className="dropdown-menu1" onBlur={() => { this.setState({ editMode: true }) }}>
              <a className="dropdown-item">Select..</a>
              {jumpMenu}
            </div>
        }
        <LoadUpdateModal
          isOpen={this.state.isModalOpen}
          onClick={this.wantsEidtable}
        />
      </React.Fragment>
    );
  }
}

const mstp = state => {
  return {
    state
  }
}
const mdtp = dispatch => {
  return {
    addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
  }
}

//  export default EditableFunJumper;
export default connect(mstp, mdtp)(RemovableJumper)
