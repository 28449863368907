import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';

import { styles } from './DataTableStyle';
import DonutLoader from '../../../loadManager/DonutLoader';

class TandemStudentTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading: false,
        }
    }
    render() {
        const { submit, classes, tandemStudents, dateRange } = this.props;
        if (dateRange.length === 0) {
            return null
        }
        // console.log("🚀 ~ file: TandemStudentTable.js ~ line 22 ~ TandemStudentTable ~ render ~ tandemStudents", tandemStudents)

        // console.log("Tandem student: ", newArray);
        //HEADERS:  ['FirstName', 'LastName', 'Email', 'Phone', 'Address', 'Last Jump Date', 'Download CSV']
        return (
            <Fragment>
                <div className='report-loading inreports'>
                    <DonutLoader isLoading={this.state.isloading} />
                </div>

                {submit &&
                    tandemStudents && tandemStudents.length ? tandemStudents.map((val, index) => {
                        return <TableRow key={'headTableRowSubHeadings' + index}>
                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.firstname}
                            </TableCell>
                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.lastname}
                            </TableCell>
                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.email}
                            </TableCell>
                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.phone}
                            </TableCell>

                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.lastTI}
                            </TableCell>
                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.lastOV}
                            </TableCell>
                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.tandemPackage}
                            </TableCell>


                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.streetAddress}
                            </TableCell>
                            <TableCell
                                component="th"
                                colSpan="2"
                                className={classes.giftCardHeadings}>
                                {val.lastJumpDate ? moment(val.lastJumpDate).format('DD-MM-YYYY') : ''}
                            </TableCell>
                        </TableRow>

                    }) : null
                }
            </Fragment>
        )
    }
}

export default withStyles(styles)(TandemStudentTable);
