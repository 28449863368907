import { useState, useEffect } from "react";
import AdminModal from './customModal';
import {
    Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm } from 'react-redux-form';
import categoryStore from '../../../stores/category-store';

const CreateCategory = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [error, setError] = useState('')
    const [category, setCategory] = useState('')

    useEffect(() => {
        if (category.replace(/\s/g, "") != "") {
            setError('')
        }
    }, [category])

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
        setError('')
        setCategory('')
    }

    const handleEdit = () => {
        setIsModalOpen(true)
        setCategory(props.data.category)
    }

    const handleSubmit = () => {
        if (category.replace(/\s/g, "") == "") {
            setError('please fill the required field')
        } else {
            if (props.isEdit) {
                let payload = {}
                payload.category = category
                payload.id = props.data._id
                categoryStore.editCategory(payload).then(r => {
                    props.getlist()
                    props.toast.success("category updated successfully")
                })
                    .catch(err => {
                        props.toast.error(err.message || "Something went wrong!")
                    })
            } else {
                categoryStore.createCategory({ category }).then(r => {
                    props.getlist()
                    props.toast.success("category added successfully")
                })
                    .catch(err => {
                        props.toast.error(err.message || "Something went wrong!")
                    })
            }
            setIsModalOpen(false)
        }
    }

    const handleChange = (e) => {
        setCategory(e.target.value)
    }

    const renderEditIcon = () => {
        if (props.data.category !== 'staff') {
            return <span onClick={handleEdit}>
                <i className="fa fa-edit"></i>
            </span>
        }
    }

    return <>
        <AdminModal
            isModalOpen={isModalOpen}
            title={props.isEdit ? "Edit Category" : "Create Catagory"}
        >
            <LocalForm onSubmit={(values) => handleSubmit(values)}>
                <Row className="form-group">
                    <Label htmlFor="name" md={3}>Name*</Label>
                    <Col md={9}>
                        <Control.input name="name" model=".name" className="form-control"
                            mapProps={{ onChange: (props) => props.onChange }}
                            defaultValue={''}
                            autoComplete="off"
                            value={category}
                            onChange={handleChange}
                        />
                        <span className="text-danger error-validation">{error}</span>
                    </Col>
                </Row>

                <Row className="form-group m-t-2-rem">
                    <Col md={{ size: 10 }}>
                        <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                        <button onClick={toggleModal} className="btn btn-link">CANCEL</button>
                    </Col>
                </Row>
            </LocalForm>
        </AdminModal>

        {props.isEdit
            ?
            renderEditIcon()
            : <span
                className="catagorybtn"
                onClick={toggleModal}
            >
                <i className="fa fa-plus"></i>
            </span>
        }
    </>
}

export default CreateCategory;