import React, { Component } from 'react';
import { Table } from 'reactstrap';
import reportStore from '../../../stores/report-store';
import EditPrice from '../../admin/modals/editPrice';

export default class PayPriceSchdules extends Component {
    constructor(props) {
        super(props)
        this.state = {
            priceSchedule: [],
            paySchedule: [],
        }
        this.reportEvents = [
            { name: reportStore.getEventPrefix('priceActivity'), func: this.handlePriceLoaded },
            { name: reportStore.getEventPrefix('schedulesLoaded'), func: this.handleSchdulesLoaded },

        ];
    }
    componentDidMount() {
        this.reportEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
        this.handleSchdulesLoaded();
    }
    componentWillUnmount() {
        this.reportEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }
    handleSchdulesLoaded = () => {
        let paySchedule = reportStore.getPaySchedule();
        let priceSchedule = reportStore.getPriceSchedule();

        this.setState({ paySchedule, priceSchedule })
    }
    handlePriceLoaded = (priceOption) => {
        var that = this;
        Promise.all([reportStore.loadPrices()])
            .then(function (result) {
                that.setState({
                    priceSchedule: result[0].priceSchedule,
                    paySchedule: result[0].paySchedule
                })
            })
    }

    renderPriceSchedule = () => {
        let { checkForNegatives } = this.props;
        let { priceSchedule } = this.state;
        //var priceSchedule = this.state.priceSchedule;

        // var priceSchedule = [];

        // if (this.state.priceSchedule != undefined) {
        //     priceSchedule = this.state.priceSchedule;
        // }
        // else {
        //     priceSchedule = reportStore.priceSchedule;
        // }

        var pricetype = "PriceSchedule"

        if (priceSchedule != undefined) {
            return priceSchedule.map((item, index) => {
                return (
                    <tr key={`priceRow${index}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.type}</td>
                        <td>{checkForNegatives(item.price)}</td>
                        <td>{item.isTaxable ? 'yes' : 'no'}</td>
                        <td>
                            <EditPrice item={item} updateType={pricetype} />
                        </td>
                    </tr>
                )
            })
        }
        else {
            return null;
        }
    }

    renderPaySchedule = () => {
        let { checkForNegatives } = this.props;
        let { paySchedule } = this.state;
        // var paySchedule = [];

        // if (this.state.paySchedule != undefined) {
        //     paySchedule = this.state.paySchedule;
        // }
        // else {
        //     paySchedule = reportStore.paySchedule;
        // }

        var pricetype = "PaySchedule"
        if (paySchedule !== undefined) {
            return paySchedule.map((item, index) => {
                return (
                    <tr key={`payRow${index}`}>
                        <th scope="row" data-id={item._id} >{index + 1}</th>
                        <td>{item.type}</td>
                        <td>{checkForNegatives(item.price)}</td>
                        <td>
                            <EditPrice item={item} updateType={pricetype} />
                        </td>
                    </tr>
                )
            })
        }
        else {
            return null;
        }
    }
    render() {
        return (
            <React.Fragment>
                <Table>
                    <thead className="main-heading">
                        <tr>
                            <th></th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Tax</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="priceHeading">
                            <td colSpan="3">Price Schedule</td>
                        </tr>
                        {this.renderPriceSchedule()}
                    </tbody>
                </Table>
                <Table>
                    <thead className="main-heading">
                        <tr>
                            <th></th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="priceHeading">
                            <td colSpan="3">Pay Schedule</td>
                        </tr>
                        {this.renderPaySchedule()}
                    </tbody>
                </Table>
            </React.Fragment>

        )
    }
}
