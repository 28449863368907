import { useEffect, useRef } from 'react';
import './loadheader.css';
import React from 'react';
import Timer from 'react-compound-timer'
import loadsStore from '../../../stores/loads-store';

const TimerComponent = (props) => {
    const stopwatchControl = useRef(null);

    useEffect(() => {
        changeTimerState(props.command);

    }, [props.command, props.startTime]);

    const changeTimerState = (timerState) => {
        switch (timerState) {
            case "start":
                stopwatchControl.current.start()
                break;
            case "stop":
                stopwatchControl.current.stop()
                break;
            case "reset":
                stopwatchControl.current.reset();
                break;
            default: console.log("error with stopwatch")
                break;
        }
    }
    console.log("props.startTime", props.startTime)
    console.log("props.startTime", props.loadNum);

    return (
        <div key={props.startTime}>
            <Timer
                initialTime={props.startTime * 1000}
                direction="backward"
                startImmediately={false}
                formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                checkpoints={[
                    {
                        time: 600000,
                        callback: () => loadsStore.setStatus(props.loadNum, { gearUp: true })
                    },
                    {
                        time: 180000,
                        callback: () => loadsStore.setNowStatus(props.loadNum, 180)
                    }
                ]}
            >
                {(control) => {
                    stopwatchControl.current = control
                    return (
                        <div className="timer" >
                            <Timer.Minutes /> :
                            <Timer.Seconds />
                        </div>
                    )
                }}
            </Timer>
        </div>
    );
}

export default TimerComponent;
