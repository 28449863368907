/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';



import { Col, Row, Label } from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import itemStore from '../../../stores/item-store';
import AdminModal from './customModal';
import DatePicker from "react-datepicker";

class CreateEditRigs extends Component {

    constructor(props) {


        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
            reserveRepackDate: '',
            error: {}
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }



    handleSubmit(values) {
        let { error } = this.state
        var payload = {};

        console.log(values, "values valuesvalues")

        if (values.item && values.main && values.category && values.reserve && values.packJobPay && values.reserveRepackPay) {
            if (this.props.isEdit && values !== undefined) {
                payload.item = values.item;
                payload.container = values.container;
                payload.main = values.main;
                payload.reserve = values.reserve;
                payload.aad = values.aad;
                payload.containerSerial = values.containerSerial;
                payload.reserveSerial = values.reserveSerial;
                payload.mainSerial = values.mainSerial;
                payload.aadSerial = values.aadSerial;
                payload.category = values.category;

                if (this.state.reserveRepackDate != undefined && this.state.reserveRepackDate != '') {
                    payload.reserveRepackDate = this.state.reserveRepackDate;
                }

                payload.salePrice = Number(values.salePrice);
                payload.rentalPrice = Number(values.rentalPrice);
                payload.dayRentalRate = Number(values.dayRentalRate);

                payload.packJobPay = Number(values.packJobPay);
                payload.reserveRepackPay = Number(values.reserveRepackPay);
                payload.color = values.color;
                console.log(payload);
                itemStore.updateItem(this.props.item._id, payload).then(res => {
                    this.props.onSuccess(res.data)
                })
            }
            else if (values != undefined) {
                if (this.state.reserveRepackDate != undefined && this.state.reserveRepackDate != '') {
                    payload.reserveRepackDate = this.state.reserveRepackDate;
                }
                payload.item = values.item;
                payload.container = values.container;
                payload.containerSerial = values.containerSerial;
                payload.main = values.main;
                payload.mainSerial = values.mainSerial;
                payload.reserve = values.reserve;
                payload.reserveSerial = values.reserveSerial;
                payload.aad = values.aad;
                payload.aadSerial = values.aadSerial;
                payload.category = values.category;

                payload.salePrice = Number(values.salePrice);
                payload.rentalPrice = Number(values.rentalPrice);
                payload.dayRentalRate = Number(values.dayRentalRate);

                payload.packJobPay = Number(values.packJobPay);
                payload.reserveRepackPay = Number(values.reserveRepackPay);
                payload.color = values.color;
                console.log(payload);
                itemStore.createItem(payload).then(res => {
                    this.props.onSuccess(res.data)
                })
            }
            this.toggleModal();
        } else {
            if (!values.item) {
                error.item = 'Required !'
            }
            if (!values.main) {
                error.main = 'Required !'
            }
            if (!values.reserveRepackPay) {
                error.reserveRepackPay = 'Required !'
            }
            if (!values.packJobPay) {
                error.packJobPay = 'Required !'
            }
            if (!values.category) {
                error.category = 'Required !'
            }
            if (!values.reserve) {
                error.reserve = 'Required !'
            }
            this.setState({ error })
        }

    }

    handleRepackDateChange = date => {
        this.setState({
            reserveRepackDate: date
        })
    }

    handleError = () => {
        this.setState({ error: {} })
    }

    render() {
        var item = {
            item: "",
            rigNumber: '',
            manufacturer: "",
            main: "",
            mainSize: '',
            reserve: "",
            aad: "",
            containerSerial: '',
            mainSerial: '',
            reserveSerial: '',
            aadSerial: '',
            rigNumber: '',
            category: "",
            repackDate: '',
            color: "",
            price: '',
            reserveRepackPay: '',
            packJobPay: ''
        };

        if (this.props.item !== undefined) {
            item = this.props.item;
            console.log(this.props.item);
            console.log(item);
        }
        var altitudes = itemStore.getAltitudes();
        let { error } = this.state
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Add Rigs`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="category" md={3}>Category*</Label>
                            <Col md={9}>
                                <Control.select onChange={() => this.handleError()} name="category" model=".category" className="form-control" mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" defaultValue={item.category}>
                                    <option value="">none</option>
                                    <option value="tandemRig">Tandem Rig</option>
                                    <option value="studentRig">Student Rig</option>
                                    <option value="sportRig">Sport Rig</option>
                                </Control.select>
                                {error && error.category ? <span className="errorMsg">{error.category} </span> : null}

                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="item" md={3}>Rig Name *</Label>
                            <Col md={9}>
                                <Control.input name="item" model=".item" className="form-control" defaultValue={item.item}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.item ? <span className="errorMsg">{error.item} </span> : null}
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="main" md={3}>Main *</Label>
                            <Col md={9}>
                                <Control.input name="main" model=".main" className="form-control" defaultValue={item.main}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.main ? <span className="errorMsg">{error.main} </span> : null}

                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="mainSerial" md={3}>Main Serial</Label>
                            <Col md={9}>
                                <Control.input name="mainSerial" model=".mainSerial" className="form-control" defaultValue={item.mainSerial}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="reserve" md={3}>Reserve *</Label>
                            <Col md={9}>
                                <Control.input name="reserve" model=".reserve" className="form-control" defaultValue={item.reserve}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.reserve ? <span className="errorMsg">{error.reserve} </span> : null}

                            </Col>

                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="reserveSerial" md={3}>Reserve Serial</Label>
                            <Col md={9}>
                                <Control.input name="reserveSerial" model=".reserveSerial" className="form-control" defaultValue={item.reserveSerial}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.reserveSerial ? <span className="errorMsg">{error.reserveSerial} </span> : null}

                            </Col>

                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="manufacturer" md={3}>Container *</Label>
                            <Col md={9}>
                                <Control.input name="container" model=".container" className="form-control" defaultValue={item.container}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="containerSerial" md={3}>Container Serial</Label>
                            <Col md={9}>
                                <Control.input name="containerSerial" model=".containerSerial" className="form-control" defaultValue={item.containerSerial}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="aad" md={3}>Aad *</Label>
                            <Col md={9}>
                                <Control.input name="aad" model=".aad" className="form-control" defaultValue={item.aad}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="aadSerial" md={3}>Aad serial</Label>
                            <Col md={9}>
                                <Control.input name="aadSerial" model=".aadSerial" className="form-control" defaultValue={item.aadSerial}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="uspaExpiry" md={3}>Reserve repack Date *</Label>
                            <Col md={9}>
                                <DatePicker
                                    selected={this.state.reserveRepackDate}
                                    onChange={this.handleRepackDateChange}
                                    className="form-control"
                                    name="reserveRepackDate"
                                    model=".reserveRepackDate"
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="salePrice" md={3}>Sale Price</Label>
                            <Col md={9}>
                                <Control.input type='number' name="salePrice" model=".salePrice" className="form-control" defaultValue={item.salePrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="rentalPrice" md={3}>rental price</Label>
                            <Col md={9}>
                                <Control.input type='number' name="rentalPrice" model=".rentalPrice" className="form-control" defaultValue={item.rentalPrice}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.packJobPay ? <span className="errorMsg">{error.rentalPrice} </span> : null}

                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="packJobPay" md={3}>day rental rate</Label>
                            <Col md={9}>
                                <Control.input type='number' name="dayRentalRate" model=".dayRentalRate" className="form-control" defaultValue={item.dayRentalRate}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.packJobPay ? <span className="errorMsg">{error.dayRentalRate} </span> : null}

                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="packJobPay" md={3}>Pack Job Pay*</Label>
                            <Col md={9}>
                                <Control.input type='number' name="packJobPay" model=".packJobPay" className="form-control" defaultValue={item.packJobPay}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.packJobPay ? <span className="errorMsg">{error.packJobPay} </span> : null}

                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="reserveRepackPay" md={3}>Reserve Repack Pay*</Label>
                            <Col md={9}>
                                <Control.input type='number' name="reserveRepackPay" model=".reserveRepackPay" className="form-control" defaultValue={item.reserveRepackPay}
                                    onChange={() => this.handleError()} mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                                {error && error.reserveRepackPay ? <span className="errorMsg">{error.reserveRepackPay} </span> : null}

                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="color" md={3}>Color</Label>
                            <Col md={9}>
                                <Control.input name="color" model=".color" className="form-control" defaultValue={item.color}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {!this.props.isEdit ?
                    <button className="buttonMain" type="submit" onClick={this.toggleModal}>
                        Add Rigs
                    </button>
                    :
                    <span onClick={this.toggleModal}>
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </React.Fragment>
        );
    }
}

export default CreateEditRigs


//{
// 	item: "Tandem Rig 1",
// 	rigNumber: 1,
// 	manufacturer: "sigma",
// 	main: "sigma",
// 	mainSize: 380,
// 	reserve: "sigma",
// 	aad: "sombody",
// 	containerSerial: '4123',
// 	mainSerial: '1234',
// 	reserveSerial:'432',
// 	aadSerial: '1234',
// 	rigNumber: 1,
// 	category: "tandemRig",
// 	repackDate: new Date(),
// 	price: 1120
// },