import React, { useState, useRef } from 'react'
import {
  Card, CardHeader, CardBody, Row, Col,
} from 'reactstrap';
// import ReactPlayer from 'react-player'
// import screenfull from 'screenfull'
// import { findDOMNode } from 'react-dom'

const ShowMedia = ({ data, shareableUrl }) => {
  const [play, setPaly] = useState({ playing: false, url: "" })
  const myPlayer = useRef(null)
  console.log("shareableUrl from show media", shareableUrl)
  return data.length > 0 && <Row style={{ padding: "7px" }}>
    {
      data.map((i, k) => {
        return (
          <Col key={k} md={3} >
            <Card>
              <CardHeader style={{ color: "#000" }}> {i.filename}</CardHeader>
              <CardBody>
                <a href={JSON.parse(localStorage.getItem('mediaInfo')).albumUrl} target="_blank" >
                  <img onClick={() => { setPaly({ playing: true, url: i.baseUrl }) }} src={i.baseUrl} width="100%" height="200px" />
                </a>
              </CardBody>
            </Card>
          </Col>
        )
      })
    }
    {/* <ReactPlayer
      ref={myPlayer}
      controls
      key={687676698}
      url={"https://lh3.googleusercontent.com/lr/AGWb-e4Ib0SMhHaen-fNWs1cIGvlLWo42AimAbxb-Qq37gp13nmeZlA0my3RsvmmXGwA336G7VXF52OmGhvfNXZNfiO5R65XNtp6kHTctH3f9SQcVsbMBGj_kMNX7TwT6PBhUR7GMSwEIOtXiSm0iX2NxAddeVVlmqPfQxvmhQc2IS-z6kbQvsbiNp4nuZE4EuP5Fb5FNIMa0xLQGHiAY-bS2NxP32UkmhFImnPYxgxDj5yFbiGdJIDRU6Gn7dQ0-Ox_ATVAlJH3cgw3T8g_9fMtgmdczf0hoEN4SfEebezr743EyzMj9a_aKmORx6qiGmEe9BQmfzQEm-DW-Di8q6Pw0BzGyp-ZD8-UzaaUzTF1qkJtC7uTKCFUy1r6Q9ix5UODwFeCKtj_AVJpnPKHwyvCiuJ1wpffrN3gPzgeZCv9f_tcbbXSFYbxog2xpIi_-w12xfraVqL-kxRirMea_XTNBHTToTINF0wd5WX7ZTUUJHzfsgmHjyezt2oD9nGILbDseqoqalRV1IXGOk49fJZzgCUcC3tuZiVN9Av5VvD-zTk-o9MQZda_P3DKSsA2pmPDBo5h80UN8qfbDMxAB2yOSS4E6fYvCfsJ3yT7TX2m-VyJxq882r9wPh0sOF4zBIEX9it9TFHw6Mq7A_xfgu6aL2_SwlPRRiuRdqk1iuwjrGkBp1EdNV4I_IOOFzawnWVplEwFlTHIqyIhNsg9TnHBE5APn0DOOTjEtdWIoklaI5Lr-6j0kDuUY8e_m-jmVa26ZA-vRB7WQfZ0SK_9ZIn1feH4I-2dah0J8QuukvH0oj0rKrjLWgtS6EG8xZkF8hTc5ukAMg=dv"}
    // url={"https://lh3.googleusercontent.com/35bWFPkdr58LNa7uuRDI_PQ7EHHFny4EKmqlk-27vuvhED-DI0jkrU-gAyaOUjwQLBJ0oO-i6QY37bPxLvRpM_hsFuX47jdQV5V63vlC7JulQ6i6-zG0CA6oz8SDvDZJh9VwIN3J=m18"} playing />
    /> */}
  </Row>
}

export default ShowMedia