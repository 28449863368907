import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import { styles } from './DataTableStyle';
import * as constants from '../../../constant/reports';
import { connect } from 'react-redux'
import { getLoadHistory } from '../../../../actions/reports'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonLast } from 'pure-react-carousel';
import Load from '../../../loadManager/LoadHistoryComponent';
import loadsStore from '../../../../stores/loads-store';
import jumpersStore from '../../../../stores/jumpers-store';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../../../../components/css/App.css';
import arrow from '../../../images/arrow-carousel.png';
import HamburgerMenu from 'react-hamburger-menu';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { ClipLoader } from 'react-spinners';
import DonutLoader from '../../../loadManager/DonutLoader';

class LoadHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loads: [],

      loading: false,

      completedLoads: [],

      completedLoadsHistory: [],

      tandemInstructorOptions: [],

      videographerOptions: [],

      affInstructorOptions: [],

      affStudentOptions: [],

      jumperOptions: [],

      tandemStudentOptions: [],

      coachOptions: [],

      staffOptions: [],

      totalSlides: 0,

      width: window.innerWidth,

      isloading: false,

      isAlert: false,

      jumperAlert: false,
      jumpTypeAlert: false,
      affLevelAlert: false,
      jumpAmountAlert: false,
      planeAlert: false,

      waiverAlert: [],
      uspaAlert: []
    }

    this.loadEvents = [
    ];

    this.jumperEvents = [
    ];
  }

  dateConverter = (date) => {
    let dateInMilliseconds = new Date(date).getTime();
    return dateInMilliseconds;
  }

  componentDidMount = () => {
    //console.log('in componentDidmount in load manager center');

    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
    window.addEventListener('resize', this.handleWindowSizeChange);

    if (window.globals && window.globals['loadMangerState']) {
      this.setState({ ...window.globals['loadMangerState'] });
    }

    var jumperOptions = jumpersStore.getJumperOptions();
    var tandemInstructorOptions = jumpersStore.getTandemInstructorOptions();
    var affInstructorOptions = jumpersStore.getAffInstructorOptions();
    var affStudentOptions = jumpersStore.getAffStudentOptions();
    var tandemStudentOptions = jumpersStore.getTandemStudentOptions();
    var videographerOptions = jumpersStore.getVideographerOptions();
    var coachOptions = jumpersStore.getCoachOptions();
    var staffOptions = jumpersStore.getStaffOptions();

    this.setState({
      tandemInstructorOptions: tandemInstructorOptions,

      videographerOptions: videographerOptions,

      affInstructorOptions: affInstructorOptions,

      affStudentOptions: affStudentOptions,

      jumperOptions: jumperOptions,

      tandemStudentOptions: tandemStudentOptions,

      coachOptions: coachOptions,

      staffOptions: staffOptions
    });
    this.setState({ isloading: true });

    if (jumpersStore.jumpersAreLoaded() && jumpersStore.tandemStudentsAreLoaded()) {
      loadsStore.loadAllExistingLoads()
        .then(d => {
          this.setState({ isloading: false });
        })
        .catch(err => {
          this.setState({ isloading: false });
          console.log(err);
        })

    }
  }

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.setState(this.initialState);
  }

  componentWillMount = () => {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.setState({
      isloading: true
    })
  }

  handleSlides(type = '') {
    this.setState(prevState => ({
      totalSlides: Math.ceil(prevState.loads.length / 2)
    }));
    if (type === 'new' && this.state.loads.length > 2) document.getElementsByClassName("carousel-last-btn")[0].click();
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleClick() {
    this.setState({
      open: !this.state.open
    });
  }

  handleRightClick() {
    this.setState({
      rightOpen: !this.state.rightOpen
    });
  }



  componentWillReceiveProps(nextprops) {
    const { range } = this.props;
    var startDate = '';
    var endDate = '';
    let JumperId = '';
    if (range.dateRange.length > 0 && nextprops.isModal === false && nextprops.submit === true) {
      const index = range.dateRange.findIndex(range => range.modalType === constants.LOAD_HISTORY);
      if (index > -1) {
        this.setState({ isloading: true })
        JumperId = range.dateRange[index].jumper_id;
        var that = this;
        var data = [];
        if (this.props.range !== nextprops.range) {
          let sDate = new Date(range.dateRange[index].startDate);
          sDate = sDate.setHours(0, 0, 0, 0);
          startDate = new Date(sDate);
          startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
          let eDate = new Date(range.dateRange[index].endDate);
          eDate = eDate.setHours(23, 59, 59, 999);
          endDate = new Date(eDate);
          endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
          this.setState({ loading: true });
          Promise.all([loadsStore.getCompletedLoad(startDate, endDate)])
            .then(function (result) {
              if (result[0] != false) {
                that.props.updateStore(result[0])
                that.setState({
                  completedLoadsHistory: result[0],
                  loading: false,
                  isloading: false,
                })
              }
              else {
                that.setState({
                  completedLoadsHistory: [],
                  loading: false,
                  isloading: false,
                })
              }
            })
        }
      }
    }
    else {
      var data = localStorage.getItem("JumperStatementData");
      if (data !== null) {
        this.setState({ filterData: JSON.parse(data), Isdata: true });
      }
    }
  }


  render() {
    const { classes, data, submit, range } = this.props;
    const { width } = this.state;
    const isMobile = width <= 500;
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

    // var allLoads = [];
    // if (this.state.loads.length > 0) {
    //   this.state.loads.forEach(element => {
    //     allLoads.push(element);
    //   })
    // }


    // if (this.state.completedLoadsHistory.length > 0) {
    //   this.state.completedLoadsHistory.forEach(element => {
    //     allLoads.push(element);
    //   })
    // }

    // allLoads.sort(function (a, b) {
    //   return a.loadNum - b.loadNum
    // })

    // var totalLoads = Math.ceil(this.state.completedLoadsHistory.length / 2);

    // var currentSlide = 0;

    // if (this.state.loads.length === 0) {
    //   totalLoads = totalLoads + 1;
    // }

    var allLoads = [];
    if (this.props.Loads.length > 0) {
      this.props.Loads.forEach(element => {
        allLoads.push(element);
      })
    }


    if (this.props.Loads.length > 0) {
      this.props.Loads.forEach(element => {
        allLoads.push(element);
      })
    }

    allLoads.sort(function (a, b) {
      return a.loadNum - b.loadNum
    })

    var totalLoads = Math.ceil(this.props.Loads.length / 2);

    var currentSlide = 0;

    if (this.props.Loads.length === 0) {
      totalLoads = totalLoads + 1;
    }


    return (
      <Fragment>
        <TableRow>
          <TableCell
            colSpan={12}
          >
            <div className="row" style={{ minHeight: '100vh', backgroundSize: 'cover', width: '100%', margin: '0px', overflow: 'hidden' }}>
              <div className="col-lg-12 col-md-12 col-sm-12">
                {
                  isMobile ?
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-md-12">
                        <div className="hamBurger-menu">
                          <HamburgerMenu
                            isOpen={this.state.open}
                            menuClicked={this.handleClick.bind(this)}
                            width={22}
                            height={17}
                            strokeWidth={1}
                            rotate={0}
                            color='white'
                            borderRadius={0}
                            animationDuration={0.5}
                          />
                        </div>
                        <div className="hamBurger-right">
                          <HamburgerMenu
                            isOpen={this.state.rightOpen}
                            menuClicked={this.handleRightClick.bind(this)}
                            width={22}
                            height={17}
                            strokeWidth={1}
                            rotate={0}
                            color='white'
                            borderRadius={0}
                            animationDuration={0.5}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    ''
                }
                <div className="webShow" style={{ width: '100%', margin: 'auto' }}>

                  <div className='report-loading inreports'>
                    {/* <ClipLoader
                      css={override}
                      sizeUnit={"px"}
                      size={150}
                      color={'#123abc'}
                      loading={this.state.loading}
                    /> */}
                    <DonutLoader isLoading={this.state.isloading} />
                  </div>
                  <CarouselProvider
                    totalSlides={totalLoads}
                    dragEnabled={false}
                    currentSlide={currentSlide}
                  >
                    <div className="col-lg-12" style={{ paddingLeft: '32px', paddingRight: '32px' }}>
                      {
                        // this.state.completedLoadsHistory.length > 0 ?
                        this.state.completedLoadsHistory.length > 0 && this.props.Loads.length > 0 ?
                          <ButtonBack className="carousel-back-btn btn lightBlueButton">
                            <img src={arrow} alt="arrowright" style={{ height: 24, margin: 'auto', display: 'block' }} />
                          </ButtonBack> : ''
                      }
                      <Slider>
                        {
                          // this.props.Loads.length > 0 && this.props.Loads.map((loads, index) => {
                          this.state.completedLoadsHistory.length > 0 && this.props.Loads.length > 0 ? this.props.Loads.sort((a, b) => a.loadNum - b.loadNum).map((loads, index) => {
                            return index % 2 == 0 ? (
                              <Slide index={index} style={{}} key={index}>
                                <div className="row" style={{ width: '100%', margin: 'auto' }} key={index}>
                                  {this.props.Loads[index] ?
                                    <Load
                                      isCompleted={true}
                                      isHistory={true}
                                      load={this.props.Loads[index]}
                                      jumperOptions={this.state.jumperOptions}
                                      tandemInstructorOptions={this.state.staffOptions}
                                      tandemStudentOptions={this.state.tandemStudentOptions}
                                      affInstructorOptions={this.state.staffOptions}
                                      affStudentOptions={this.state.affStudentOptions}
                                      videographerOptions={this.state.staffOptions}
                                      coachOptions={this.state.staffOptions}
                                    />
                                    : <Load load={{}} />}

                                  {this.props.Loads[index + 1] ? <Load isCompleted={true} isHistory={true} load={this.props.Loads[index + 1]} jumperOptions={this.state.jumperOptions} tandemInstructorOptions={this.state.staffOptions}
                                    tandemStudentOptions={this.state.tandemStudentOptions} affInstructorOptions={this.state.staffOptions}
                                    affStudentOptions={this.state.affStudentOptions} videographerOptions={this.state.staffOptions} coachOptions={this.state.staffOptions} /> : <Load load={{}} />}
                                </div>
                              </Slide>
                            ) : null
                          })
                            : !this.state.loading ? "no data " : ""
                        }
                      </Slider>
                      {
                        // this.state.completedLoadsHistory.length > 0 ?
                        this.state.completedLoadsHistory.length > 0 && this.props.Loads.length > 0 ?
                          <div>
                            <ButtonNext className="carousel-next-btn btn lightBlueButton">
                              <img src={arrow} alt="arrowright" style={{ height: 24, margin: 'auto', display: 'block', transform: 'rotate(180deg)' }} />
                            </ButtonNext>
                            <ButtonLast className="carousel-last-btn btn lightBlueButton hide">Last</ButtonLast>
                          </div>
                          :
                          ''
                      }
                    </div>
                  </CarouselProvider>
                </div>
              </div>
            </div>

          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return { Loads: state.Reports.loadHistory }
}
const mapDispatchToProps = () => dispatch => {
  return {
    updateStore: (data) => dispatch(getLoadHistory(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DragDropContext(HTML5Backend)(LoadHistory)));
