/**
 * http://usejsdoc.org/
 */
import { create } from 'apisauce';
// define the api
import authStore from '../stores/auth-store';

import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});

const func = {};

func.createCategory = (payload) => {
    var token = authStore.getAuthToken();
    if (token == '') {
        token = localStorage.getItem("Auth-token");
    }
    api.setHeader('Authorization', 'Bearer ' + token)
    return api.post('/category/create', payload);
}

func.getCategories = () => {
    var token = authStore.getAuthToken();
    if (token == '') {
        token = localStorage.getItem("Auth-token");
    }
    api.setHeader('Authorization', 'Bearer ' + token)
    return api.get('/category');
}

func.editCategory = (payload) => {
    var token = authStore.getAuthToken();
    if (token == '') {
        token = localStorage.getItem("Auth-token");
    }
    api.setHeader('Authorization', 'Bearer ' + token)
    return api.post('/category/edit', payload);
}

func.deleteCategory = (payload) => {
    var token = authStore.getAuthToken();
    if (token == '') {
        token = localStorage.getItem("Auth-token");
    }
    api.setHeader('Authorization', 'Bearer ' + token)
    return api.post('/category/delete', payload);
}

export default func;