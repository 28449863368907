import React, { Component } from 'react'

export default class ExpandedComponent extends Component {
    returnInvoices = () => {
        let invoices = this.props.data.invoices;
        if (invoices && invoices.length > 0) {
            let list = invoices.map((pkg, idx) => {
                return <tr key={idx}>
                    <td>{pkg.item}</td>
                    <td>{pkg.count}</td>
                    <td>{pkg.value.toFixed(2)}</td>
                </tr>
            })
            return list;
        } else {
            return null
        }
    }
    renderStudents = (studentList) => {
        if (studentList && studentList.length > 0) {
            let list = studentList.map((item) => {
                return <React.Fragment>
                    <p><strong>Full Name :- </strong>{item.firstname + " " + item.lastname}</p>
                    <p><strong>Email :- </strong>{item.email}</p>
                </React.Fragment>
            })
            return list
        }
        return null;
    }
    renderTotalTax = (tax) => {
        if (tax) {
            return <tr>
                <td>Tax</td>
                <td>:</td>
                <td>{tax.toFixed(2)}</td>
            </tr>
        }
        return null;
    }
    render() {
        let Item = this.props.data;

        return (
            <div className="Ext-ended">
                <div>
                    <h6>Invoices</h6>
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th>Name </th>
                                <th>Count </th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody className="table-hover">
                            {this.returnInvoices()}
                            <tr>
                                <td>Fees</td>
                                <td>:</td>
                                <td>{Item.totalFees.toFixed(2)}</td>
                            </tr>
                            {this.renderTotalTax(Item.totalTax)}
                            <tr>
                                <td><strong>Total</strong></td>
                                <td>:</td>
                                <td>{(Item.totalFees + Item.totalValue).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p><strong>Site : </strong>{Item.site ? Item.site : null}</p>
                    <h6>Students : </h6>
                    <div className="students pl-3 mb-2">
                        {this.renderStudents(Item.tandemStudents)}
                        {this.renderStudents(Item.students)}
                    </div>
                </div>
            </div>
        )
    }
}
