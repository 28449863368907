
/**
 * http://usejsdoc.org/
 */
import { create } from 'apisauce';


// define the api

let api;
const func = {};

func.setApi = () => {
	if(window.ENV?.processorInfo){
		api = create({
			baseURL: window.ENV?.processorInfo.terminalApiUrl
		});
	}
}

const _formPostBody = (transactionType, amount) => {
	var type;
	// console.log(transactionType);
	if (transactionType === "Credit") {
		type = 'CCR1'
	}
	else if (transactionType === "Debit") {
		type = 'DB00'
	}
	var bodyString = `<DETAIL>
					     <TRAN_TYPE>${type}</TRAN_TYPE>
	                     <AMOUNT>${amount}</AMOUNT>
	                  </DETAIL>`;
	return bodyString;
}

const _formVoidBody = (transactionId) => {
	var type;
	// console.log(transactionType);

	var bodyString = `<DETAIL>
					     <TRAN_TYPE>CCRX</TRAN_TYPE>
	                     <ORIG_AUTH_GUID>${transactionId}</ORIG_AUTH_GUID> 
	                  </DETAIL>`;
	return bodyString;
}

const _formReturnByIdBody = (transactionId, amount) => {
	var type;
	// console.log(transactionType);

	var bodyString = `<DETAIL>
						<TRAN_TYPE>CCR9</TRAN_TYPE>
						<AMOUNT>${amount}</AMOUNT>
						<ORIG_AUTH_GUID>${transactionId}</ORIG_AUTH_GUID>
					  </DETAIL>`;
	return bodyString;
}

const _formReturnUnlinked = (amount) => {
	var type;
	// console.log(transactionType);

	var bodyString = `<DETAIL>
						<TRAN_TYPE>DB01</TRAN_TYPE>
						<AMOUNT>${amount}</AMOUNT>
					  </DETAIL>`;
	return bodyString;
}

/*
const _formReturnByIdBody = (transactionId, amount) => {
	var type;
	// console.log(transactionType);

	var bodyString = `<DETAIL>
						<TRAN_TYPE>CCR9</TRAN_TYPE>
						<AMOUNT>${amount}</AMOUNT>
						<ORIG_AUTH_GUID>${transactionId}</ORIG_AUTH_GUID>
					  </DETAIL>`;
	return bodyString;
}
*/


func.AuthorizeAndCapture = (transactionType, amount) => {
	// console.log(api.getBaseURL());
	console.log('in terminal authorize and capture');
	console.log(transactionType, amount)
	var postBody = _formPostBody(transactionType, amount);
	// console.log(postBody);
	api.setHeader('Content-Type', 'text/plain');

	process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
	return api.post('', postBody);
}

func.ReturnById = (transactionId, amount) => {
	// console.log(api.getBaseURL());
	// console.log('in terminal authorize and capture');
	var postBody = _formReturnByIdBody(transactionId);
	// console.log(postBody);
	api.setHeader('Content-Type', 'text/plain');

	console.log(postBody)
	return api.post('', postBody);
}

func.ReturnUnlinked = (amount) => {
	// console.log(api.getBaseURL());
	// console.log('in terminal authorize and capture');
	var postBody = _formReturnUnlinked(amount);
	// console.log(postBody);
	api.setHeader('Content-Type', 'text/plain');

	console.log(postBody)
	return api.post('', postBody);
}

func.Void = (transactionId) => {
	// console.log(api.getBaseURL());
	// console.log('in terminal authorize and capture');
	var postBody = _formVoidBody(transactionId);
	// console.log(postBody);
	api.setHeader('Content-Type', 'text/plain');

	console.log(postBody)
	return api.post('', postBody);
}

export default func;