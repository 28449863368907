/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

import jumperStore from '../../../stores/jumpers-store';

import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';
//import "../../css/glyphicon.css";


class RemoveCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        }
        this.jumperEvents = [
            { name: jumperStore.getEventPrefix('deleteCardEvent'), func: this.handleDeleteCard },
            { name: jumperStore.getEventPrefix('deleteAccountEvent'), func: this.handleDeleteCard }
        ];
    }

    toggleModal = (e) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            jumpType: null
        });
    }

    componentDidMount = () => {
        this.jumperEvents.forEach((evnt) => jumperStore.on(evnt.name, evnt.func));
    }

    componentWillUnmount = () => {
        this.jumperEvents.forEach((evnt) => jumperStore.removeListener(evnt.name, evnt.func));
    }

    handleDeleteCard = () => {
        this.toggleModal();
    }


    handleSubmit = (jumperId, cardId) => {

        if (this.props.type && this.props.type == "account") {
            let accountOnFile = this.props.jumperVal?.accountOnFile;
            let cardIndex = accountOnFile.findIndex((card) => card._id === cardId)
            accountOnFile.splice(cardIndex, 1);

            jumperStore.updateJumper(jumperId, this.props.jumperVal);
            this.toggleModal();
        }
        else {
            let cardsOnFile = this.props.jumperVal?.cardsOnFile;
            let cardIndex = cardsOnFile.findIndex((card) => card._id === cardId)
            cardsOnFile.splice(cardIndex, 1);

            jumperStore.updateJumper(jumperId, this.props.jumperVal);
            this.toggleModal();
        }
    }

    render() {
        //const { } = this.props.jumperDetail;

        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Remove Card`}>
                    <LocalForm onSubmit={(values) => this.handleSubmit(this.props.jumperId, this.props.cardId)}>
                        <Row className="form-group">
                            <Label htmlFor="pilot" md={12}>Are you sure you want to remove card on file</Label>
                        </Row>
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>

                <span onClick={this.toggleModal} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-trash" style={{ fontSize: '23px' }}></i>
                </span>

            </React.Fragment>
        );
    }
}

export default RemoveCard
