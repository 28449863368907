import React, { Component } from 'react';
import Hr from './customHr';
import reportStore from '../../../stores/report-store';

class TicketForm extends Component {
    constructor(props) {
        super(props);
        var price = this.getPrice(reportStore.priceSchedule, "video");
        this.state = { ticketNumber: this.props.ticketNumber || 0, videoCount: this.props.videoCount || 0, videoPrice: price }
    }

    ChangeTicketNumber = (event) => {
        if (event.target.value % 1 === 0) {
            this.setState({ ticketNumber: event.target.value });
            this.props.handleTicketUpdate(event.target.value);
        }
    }

    getPrice(priceSchedule, type) {
        var index = priceSchedule.findIndex(x => x.type == type);
        var price = priceSchedule[index].price;
        return price;
    }

    handleRadioButton = (value) => {
        if (value === 'full') {
            this.props.handleFullDeposit(false);
            this.setState({
                giftCard: false
            })
        }
        else if (value === 'deposit') {
            this.props.handleDeposit(!this.state.isDeposit);
            this.setState({
                giftCard: false
            })
        }
        else if (value === 'giftCard') {
            this.props.handleGiftCard('giftCard');
            this.setState({
                giftCard: true,
                ticketNumber: 1
            })
        }
    }

    // ChangeVideoNumber = (event) => {
    //     if(event.target.value%1 === 0){
    //         this.setState({ videoCount: event.target.value });
    //         this.props.handleVideoUpdate(event.target.value);
    //     }
    // }

    render() {
        const { giftCard } = this.state;

        return (
            <div className="col-md-12 containerPadding">
                <div className="row">
                    <div className="col-md-12">
                        <span className="customHeading">ENTER TICKETS</span>
                        <input
                            style={{ width: '30%', maxWidth: '9em' }}
                            value={this.state.ticketNumber}
                            onChange={this.ChangeTicketNumber.bind(this)}
                            onFocus={() => this.state.ticketNumber === 0 && this.setState({ ticketNumber: "" })}
                            onBlur={() => this.state.ticketNumber === "" && this.setState({ ticketNumber: 0 })}
                            type="text"
                            disabled={giftCard}
                            className="form-control col-md-12"
                            id="ticketNumber"
                            placeholder="Enter a number"
                            autoComplete="off"
                        />
                    </div>

                    {/* <div className="col-md-12" style={{marginTop: 20}}>
                        <span className="customHeading">
                            ENTER VIDEOS
                        </span> 
                        <br/>
                        <input
                            style={{ width: '30%', maxWidth: '9em', display: 'inline-block' }}
                            onChange={this.ChangeVideoNumber.bind(this)}
                            onFocus={()=>this.state.videoCount===0&&this.setState({videoCount:""})}
                            onBlur={()=>this.state.videoCount===""&&this.setState({videoCount:0})}
                            type="text"
                            className="form-control col-md-12"
                            id="numberOfVideo"
                            placeholder="Number Of Videos"
                        />
                        <span className="ticketHeading" style={{fontSize:20,letterSpacing:1}}> / Video ${this.state.videoPrice}</span>
                    </div> */}


                    <div className="radio-btns" style={{ marginTop: 20 }}>

                        <input type="radio" id="deposit" name="deposit" style={{ marginRight: '10px' }} onChange={() => this.handleRadioButton('full')} />
                        <span>
                            Pay Full
                        </span>
                        <br />
                        <input type="radio" id="deposit" name="deposit" onChange={() => this.handleRadioButton('deposit')} style={{ marginRight: '10px' }} />
                        <span>
                            Pay Deposit &nbsp;
                            <b>"Pay only $50 (non-refundable) deposit per jump"</b>
                        </span>
                        <br />
                        <input type="radio" id="deposit" name="deposit" onChange={() => this.handleRadioButton('giftCard')} style={{ marginRight: '10px' }} />
                        <span>
                            Use Giftcard Code &nbsp;
                        </span>

                    </div>

                </div>
                <Hr />
            </div>
        );
    }
}

export default TicketForm;