import React, { Component } from 'react';
import tandemData from '../../../stores/reservation-store';
import reportStore from '../../../stores/report-store';

class PassengerDetail extends Component {
  constructor(props) {
    super(props);
    this.videoPrice = this.getPrice(reportStore.priceSchedule, "video");
  }

  state = tandemData.getFormData(this.props.index - 1) || {
    firstname: '',
    lastname: '',
    phone: '',
    streetAddress: '',
    email: '',
    zipcode: '',
    adult: false,
    weight: false,
    video: false,
    errors: {},
  };

  getPrice(priceSchedule, type) {
    var index = priceSchedule.findIndex(x => x.type == type);
    var price = priceSchedule[index].price;
    return price;
  }

  validateEmail = (value) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }

  handleValidation(name) {
    let fields = this.state;
    let errors = fields.errors;
    if (fields[name] === '') errors[name] = "This field is required";
    else if ((name === 'firstname' || name === 'lastname') && !fields[name].match(/^[a-zA-Z]+$/)) errors[name] = "Only letters are allowed";
    else if (name === 'email' && !this.validateEmail(fields[name])) errors[name] = "Please enter valid email";
    else if (name === 'zipcode' && !fields[name].match(/^\d{5}(?:[-\s]\d{4})?$/)) errors[name] = "Zipcode should contain 5 numbers";
    else if (name === 'phone' && !fields[name].match(/^\d{10}(?:[-\s]\d{4})?$/)) errors[name] = "Phone should contain only 10 numbers";
    else if (name === 'weight' && fields[name] === false) errors[name] = "Please check Checkbox";
    else if (name === 'adult' && fields[name] === false) errors[name] = "Please check Checkbox";
    else errors[name] = "";
    this.setState({ errors: errors });
  }

  // handleInput = (event, key) => {
  //   var cache = { ...this.state };
  //   cache[key] = event.target.value;
  //   this.setState({ ...cache },()=>{
  //     this.handleValidation(key);
  //   });
  //   if(!this.state.errors.length)
  //   tandemData.updateFormWithIndex(cache, this.props.index - 1);
  // };

  handleInput = (event, key) => {
    var price = 0;
    var cache = { ...this.state };
    if (key === 'adult' || key === 'weight' || key === 'video') {
      cache[key] = event.target.checked
    }
    else {
      cache[key] = event.target.value;
    }

    this.setState({ ...cache }, () => {
      this.handleValidation(key);
    });

    if (!this.state.errors.length) {
      tandemData.updateFormWithIndex(cache, this.props.index - 1);
      var finalData = tandemData.getAll().finalForm;
      if (finalData.passengers.length > 0) {
        finalData.passengers.forEach(x => {
          console.log(x.video);
          if (x.video) {
            price = price + 1;
          }
        })
      }
    }
    this.props.handleVideoUpdate(price);
  };

  //change componentWillReceiveProps to getDerivedStateFromProps
  componentWillReceiveProps(nextProps) {
    if (nextProps.chkFormVal) {
      for (let key in this.state) {
        if (key != 'errors') this.handleValidation(key);
      }
    }
  }

  render() {
    return (
      <div className="col-md-6 my-3">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title text-center ticketHeading">
              Ticket {this.props.index}
            </h5>
            <div className="htmlForm-group">
              <label className="customLabel">First Name</label>
              <input
                value={this.state.fn}
                onChange={(e) => this.handleInput(e, 'firstname')}
                type="text"
                className="form-control customInputField"
                placeholder="Jhon"
              />
              <span className="text-danger error-validation ">{this.state.errors["firstname"]}</span>
            </div>
            <div className="htmlForm-group">
              <label className="customLabel">Last Name</label>
              <input
                value={this.state.ln}
                onChange={(e) => this.handleInput(e, 'lastname')}
                type="text"
                className="form-control customInputField"
                placeholder="Doe"
              />
              <span className="text-danger error-validation ">{this.state.errors["lastname"]}</span>
            </div>
            {/* <div className="htmlForm-group">
              <label className="customLabel">Address</label>
              <input
                value={this.state.adr}
                onChange={(e) => this.handleInput(e, 'streetAddress')}
                type="text"
                className="form-control customInputField"
                placeholder="Passengers Address"
              />
              <span className="text-danger error-validation">{this.state.errors["streetAddress"]}</span>
            </div> */}
            <div className="htmlForm-group">
              <label className="customLabel">Email</label>
              <input
                value={this.state.ph}
                onChange={(e) => this.handleInput(e, 'email')}
                type="text"
                className="form-control customInputField"
                placeholder="you@domain.com"
              />
              <span className="text-danger error-validation">{this.state.errors["email"]}</span>
            </div>


            <div className="htmlForm-group">
              <label className="customLabel">Above 18</label>
              <br />
              <input
                value={this.state.adult}
                onChange={(e) => this.handleInput(e, 'adult')}
                type="checkbox"
              />
              <br />
              <span className="text-danger error-validation">{this.state.errors["adult"]}</span>
            </div>

            <div className="htmlForm-group">
              <label className="customLabel">Below 250lbs</label>
              <br />
              <input
                value={this.state.weight}
                onChange={(e) => this.handleInput(e, 'weight')}
                type="checkbox"
              />
              <br />
              <span className="text-danger error-validation">{this.state.errors["weight"]}</span>
            </div>

            <div className="htmlForm-group">
              <label className="customLabel">Add Video for ${this.videoPrice}</label>
              <br />
              <input
                value={this.state.weight}
                onChange={(e) => this.handleInput(e, 'video')}
                type="checkbox"
              />
              <br />
            </div>
            {/* <div className="htmlForm-group">
              <label className="customLabel">Zip code</label>
              <input
                value={this.state.ph}
                onChange={(e) => this.handleInput(e, 'zipcode')}
                type="text"
                className="form-control customInputField"
                placeholder="98012"
              />
              <span className="text-danger error-validation">{this.state.errors["zipcode"]}</span>
            </div>

            <div className="htmlForm-group">
              <label className="customLabel">Phone Number</label>
              <input
                value={this.state.ph}
                onChange={(e) => this.handleInput(e, 'phone')}
                type="text"
                className="form-control customInputField"
                placeholder="123456789"
              />
              <span className="text-danger error-validation">{this.state.errors["phone"]}</span>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PassengerDetail;
