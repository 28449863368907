import React, { useEffect, useState, forwardRef } from 'react';
import { Control } from 'react-redux-form';
import { Col, Row, Label } from 'reactstrap';
import loadsStore from '../../../stores/loads-store';
import stringify from 'fast-json-stable-stringify';
import AccountStore from '../../../stores/account-store';
import NabVelocity from '../../sub-components/nab/nabVelocity';
import PaymentIcon from 'react-payment-icons';

const ChargeModalOptions = forwardRef((props, ref) => {

    const [values, setValues] = useState({
        amount: props?.isSubmitCharge ? props?.itemAmount : props?.chargeAmount,
        tax: props?.isSubmitCharge ? props?.itemTax : props?.chargeTax,
        pfees: props?.isSubmitCharge ? 0 : props?.chargePFees,
        pType: '',
        memo: '',
        cashin: '',
        cashout: '',
    })
    const [errors, setErrors] = useState({
        amount: '',
        tax: '',
        pType: '',
        memo: ''
    })

    const [transactionId, setTransactionId] = useState('')
    const [key, setKey] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        setValues({ amount: props?.isSubmitCharge ? props?.itemAmount : props?.chargeAmount, tax: props?.isSubmitCharge ? props?.itemTax : props?.chargeTax, pfees:props?.isSubmitCharge ? 0: props?.chargePFees  })
    }, [props?.itemAmount, props?.itemTax, props?.chargeAmount, props?.chargeTax, props?.chargePFees])

    var token = props.token;
    var accountOnFile = props.accountOnFile
    var cardNumber = '';
    if (token != undefined && token != null && token.length > 0 && token[token.length - 1] !== null) {
        var card = token[token.length - 1].cardNumLast4;
        cardNumber = card;
    }
    var accountNumber = '';
    if (accountOnFile != undefined && accountOnFile.length > 0) {
        var account = accountOnFile[accountOnFile.length - 1].AccountNumber;
        accountNumber = "ACH Payout - Account-" + account;
    }
    var paymentType = [];

    if (cardNumber != '') {
        paymentType = ["Credit", "Debit", cardNumber, "Cash", "Check", "Card key in", "Cash Payout", "Check Payout"];
    }
    else {
        paymentType = ["Credit", "Debit", "Cash", "Check", "Card key in", "Cash Payout", "Check Payout"];
    }
    if (accountNumber != '') {
        paymentType.push(accountNumber);
    }
    if (window.ENV?.arizona) {
        paymentType.unshift("Military Account");
    }

    useEffect(() => {
        handleValidation(key)
    }, [values])

    useEffect(() => {
        props?.isSubmitCharge && props.paymentType(values.pType)
    }, [values.pType])

    const handleValidation = (name) => {
        let error = errors;
        if (values[name] === '') errors[name] = "This field is required";
        else if ((name === 'amount' || name === "tax") && !values[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Amount should be enter in two decimal places.";
        else errors[name] = "";
        setErrors(error)
    }

    const checkForNegatives = (price) => {
        if (price != undefined && price !== "") {
            let priceCell = (price < 0) ?
                {
                    value: `($${Math.abs(price).toFixed(2)})`
                } :
                {
                    value: `$${price.toFixed(2)}`
                }

            if (price < 0) {
                return <span style={{ color: 'salmon' }}> {priceCell.value}</span>;
            }
            return priceCell.value;
        }
        else {
            return '$0.00';
        }
    }

    const handleChange = (e, key) => {
        setIsDisabled(false);
        let data = { ...values }
        data[key] = e.target.value
        if ((key === 'cashin' || key === 'amount') && (parseInt(data['amount']) < parseInt(data['cashin']))) {
            data['cashout'] = stringify(Number(data.cashin) - Number(data.amount) - Number(data.tax)) > 0 ? stringify(Number(data.cashin) - Number(data.amount) - Number(data.tax)) : 0
        } else {
            data['cashout'] = 0
        }
        setValues(data)
        setKey(key)
        if (!props?.isSubmitCharge) {
            props?.setState({ amount: data.amount, taxAmount: data.tax })
        }
    }
    console.log(values.pfees);
    console.log(values.amount);
    return <>
        <span id="btn_CardkeyIn" style={{ display: 'none' }}>
            <NabVelocity
                amount={1}
                terminalProfileId={loadsStore.getNabHostedFormTerminalId()}
                text="Card"
            />
        </span>
        <Row className="form-group">
            {console.log(values.amount, "values.amount")}
            <Label htmlFor="pilot" md={4}>Enter Charge Amount :</Label>
            <Col md={8}>
                <Control.input name="amount" model=".amount" className="form-control"
                    mapProps={{ onChange: (props) => props.onChange }}
                    value={values.amount}
                    step={0.01}
                    type="number"
                    disabled={(Number(props?.chargeAmount) > 0 && isDisabled) ? true : false}
                    onChange={(e) => handleChange(e, 'amount')}
                    autoComplete="off"
                />
                <span className="text-danger error-validation">{errors["amount"]}</span>
            </Col>
        </Row>

        {loadsStore.isTaxEnabled() &&
            <Row className="form-group">
                <Label htmlFor="tax" md={4}>Enter Tax Amount :</Label>
                <Col md={8}>
                    <Control.input name="tax" model=".tax" className="form-control"
                        mapProps={{ onChange: (props) => props.onChange }}
                        type="number"
                        // defaultValue={this.props.taxValue>=0?this.props.taxValue: 0}
                        onChange={(e) => handleChange(e, 'tax')} autoComplete="off"
                        value={values.tax}
                    />
                    <span className="text-danger error-validation">{errors["tax"]}</span>
                </Col>
            </Row>
        }
        { props?.chargePFees > 0 &&
            <Row className="form-group">
                <Label htmlFor="pfees" md={4}>Processing Fees :</Label>
                <Col md={8}>
                    <Control.input name="pfees" model=".pfees" className="form-control"
                        mapProps={{ onChange: (props) => props.onChange }}
                        type="number"
                        // defaultValue={this.props.taxValue>=0?this.props.taxValue: 0}
                        onChange={(e) => handleChange(e, 'pfees')} autoComplete="off"
                        value={parseFloat(values.pfees).toFixed(2)}
                    />
                    <span className="text-danger error-validation">{errors["pfees"]}</span>
                </Col>
            </Row>
        }

        { (loadsStore.isTaxEnabled() || props?.chargePFees>0) && values.amount > 0 &&
            <Row className="form-group">
                <Label htmlFor="total" md={4}>Total :</Label>
                {checkForNegatives(Number(values.amount) + Number(values.tax) + Number(values.pfees))}
            </Row>
        }

        <Row className="form-group">
            <Label htmlFor="pilot" md={4}>memo (optional) :</Label>
            <Col md={8}>
                <Control.input name="memo" model=".memo" className="form-control"
                    mapProps={{ onChange: (props) => props.onChange }}
                    autoComplete="off"
                    onChange={(e) => handleChange(e, 'memo')}
                    value={values.memo}
                />
            </Col>
        </Row>

        <Row className="form-group" style={{ position: 'relative' }}>
            <Label htmlFor="pilot" md={4}>Payment Type:</Label>
            <Col md={8}>
                {
                    props?.chargeTypePayment.map((i) => {
                        return <div className="col" key={i} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                            <input
                                onChange={(d) => props?.setState({ paymentType: i.replace('ACH Payout - ', ''), isSelected: true })}
                                onClick={props?.chargeChangeRadio}
                                name="payymentType"
                                type="radio"
                                data-key={i}
                                value={props?.chargePaymentType}
                                style={{ marginRight: 5 }}
                            /> {i}
                            {props?.chargePaymentType === 'Cash' && i === 'Cash' &&
                                <div>
                                    <Control.input name="cashin" model=".cashin" className="form-control"
                                        onChange={(e) => props?.handleChargeTaxAmount(e, 'cashin')}
                                        value={props?.chargeCashIn}
                                        mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                        placeholder="Cash In"
                                        style={{ border: 'none', borderBottom: '1px solid #fff', borderRadius: 0, padding: 0, textAlign: 'center', height: 22, width: 58, margin: '0px 6px' }}
                                    />
                                </div>}
                            {props?.chargePaymentType === 'Cash' && i === 'Cash' &&
                                <div style={{ display: 'flex', alignItems: 'center', height: 22 }}>
                                    <div>Cash to Return:</div>
                                    <Control.input name="cashout" model=".cashout" className="form-control"
                                        onChange={(e) => props?.handleChargeTaxAmount(e, 'cashout')}
                                        value={Number(props?.chargeCashOut).toFixed(2)}
                                        mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                        readOnly={true}
                                        style={{ border: 'none', borderRadius: 0, padding: 0, textAlign: 'right', width: 58 }}
                                    />
                                </div>
                            }
                        </div>
                    })
                }
            </Col>
        </Row>
        {!props?.isSubmitCharge && props?.chargeShowCredit &&
            <div>
                <Row className="form-group">
                    <Label htmlFor="cardNumber" md={3}>Card Number</Label>
                    <Col md={9}>
                        <input
                            type="text"
                            className="invoiceFrom form-control"
                            name="cardNumber"
                            placeholder=""
                            onChange={(e) => props?.chargeInput(e, 'cardNumber')}
                        />
                    </Col>
                </Row>
                <Row className="form-group">
                    <Label htmlFor="cardNumber" md={3}>Expiry</Label>
                    <Col md={3}>
                        <select onChange={props?.chargeMonthSelect} name='month' model=".month" className="invoiceFrom form-control" style={{ width: '100%' }} >
                            <option>MM</option>
                            {props?.chargeMonths?.map((item, i) => <option key={i} value={(i + 1)}>{item}</option>)}
                        </select>
                    </Col>
                    <Col md={3}>
                        <select onChange={props?.chargeYearSelect} name="year" model=".year" className="invoiceFrom form-control" style={{ width: '100%' }} >
                            <option>YY</option>
                            <option value="2015">2015</option>
                            {props?.chargeGenerateYears().map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>
                    </Col>
                </Row>
                <Row className="form-group">
                    <Label htmlFor="cardNumber" md={3}>Cvv</Label>
                    <Col md={8}>
                        <Control.input name="cvv" model=".cvv" className="form-control" style={{ width: '40%' }}
                            mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                        />
                    </Col>
                </Row>
                {
                    <Row className="form-group">
                        <Col md={8}>
                            {
                                props?.chargeCardType != '' ?
                                    <PaymentIcon
                                        id={props?.chargeCardIcon}
                                        style={{ margin: 10, width: 100 }}
                                        className="payment-icon"
                                    />
                                    : ''
                            }
                        </Col>
                    </Row>
                }
            </div>
        }
        <Row className="form-group m-t-2-rem">
            <Col md={{ size: 9 }}>
                <button className="btn btn-bg lightColor createButton" type="submit" disabled={!props?.chargeSelected || props?.chargeLoading}>SUBMIT</button>
                <button onClick={(e) => { e.preventDefault(); props?.chargeToggleModal() }} className="btn btn-link" disabled={props?.chargeCardProcessing} >CANCEL</button>
            </Col>
        </Row>
    </>
})

export default ChargeModalOptions;