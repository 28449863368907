import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DropTarget, DragSource } from 'react-dnd'
import CancelJump from './modals/cancelJump'
import EditableJumpType from './modals/editableJumpType'
import EditableAltitude from './modals/editableAltitude'
import { Card, CardImg, CardText, CardBody, CardTitle, CardHeader, CardFooter, Row, Col, Button, } from 'reactstrap'
import { Badge } from 'reactstrap';
import RenderJumperTarget from './modals/RenderJumperTarget';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "../css/react-contextmenu.css";
import loadsStore from '../../stores/loads-store';
import itemStore from '../../stores/item-store';
import UpdateAmount from './modals/updateAmount';
import UpdateNote from './modals/updateNote';
import NestedFunJumpMenu from './subComponents/NestedMenus'
import returnLoadSettings from './helper/returnLoadSettings';
import multiselect from './helper/multiselectJumps'
import multiCopyPasteClick from './helper/multiCopyPaste'

const Types = {
    ITEM: 'JUMP'
};

const itemSource = {
    beginDrag(props) {
        let dragabbleItems = loadsStore.getDraggable()
        if (dragabbleItems?.length > 0) {
            return { items: dragabbleItems }
        } else {
            return props;
        }
    },
    endDrag(props, monitor, component) {
        // if (!monitor.didDrop()) {
        //     props.clearItemSelection()
        //     return;
        // }
        // return props;
        const dropResult = monitor.getDropResult();
        if (dropResult) {
            loadsStore.clearDraggable()
        }
    }
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}


const attributes = {
    className: 'custom-root',
    disabledClassName: 'custom-disabled',
    dividerClassName: 'custom-divider',
    selectedClassName: 'custom-selected'
}


class RenderFunJump extends Component {

    constructor() {
        super();
        this.state = {
            menu: false,
            optionMenu: false,
            optionSub: false,
            copyOptionSub: false,
            cutOptionSub: false
        }
    }

    handleCopyClick = (funJump = this.props.funJump, loadNum = this.props.loadNum, uid = -1000) => {
        let data = loadsStore.getDraggable()
        if (data?.length > 0) {
            multiselect(data)
        } else {
            var payload = {};
            payload.jumperIds = funJump.jumpers.map(jumper => {
                return {
                    jumperId: jumper._id,
                    price: jumper.price,
                    block: jumper.block,
                    blockId: jumper.blockId,
                    gearPrice: jumper.gearPrice,
                    gearType: jumper.gearType,
                    isLOing: jumper.isLOing,
                    isVideographer: jumper.isVideographer,
                    chargeTo: jumper.chargeTo,
                    tracked: jumper.tracked

                }
            });

            payload.jumpType = funJump.jumpType;
            payload.gearRentals = funJump.gearRentals;
            payload.studentgearRentals = funJump.studentgearRentals;
            payload.sportgearRentals = funJump.sportgearRentals;
            payload.altitude = funJump.altitude;
            payload.teamName = funJump.teamName;
            payload.teamType = funJump.teamType;
            payload.teamId = funJump.teamId;
            payload.note = funJump.note;

            payload.copy = true;

            loadsStore.copied = true;
            loadsStore.cut = false;
            loadsStore.copyValue = payload;
        }

        // this.props.toggleToster(true, "copied")
        // var element = document.getElementById(uid);
        // element.style.display = "none";
        if (uid !== -1000) {
            var element = document.getElementById(uid);
            element.style.display = "none";
        }
    }
    handleCutClick = (funJump = this.props.funJump, loadNum = this.props.loadNum, uid = "fun" + this.props.loadNum + "_" + this.props.jumpId, jumpId = this.props.jumpId) => {
        // this.setState({
        //     cutOptionSub: true
        // })
        let data = loadsStore.getDraggable()
        if (data?.length > 0) {
            multiselect(data, loadNum)
        } else {
            var payload = {};
            payload.jumperIds = funJump.jumpers.map(jumper => {
                return {
                    jumperId: jumper._id,
                    price: jumper.price,
                    block: jumper.block,
                    blockId: jumper.blockId,
                    gearPrice: jumper.gearPrice,
                    gearType: jumper.gearType,
                    isLOing: jumper.isLOing,
                    isVideographer: jumper.isVideographer,
                    chargeTo: jumper.chargeTo,
                    tracked: jumper.tracked
                }
            });
            payload.jumpType = funJump.jumpType;
            payload.gearRentals = funJump.gearRentals;
            payload.altitude = funJump.altitude;
            payload.teamName = funJump.teamName;
            payload.teamType = funJump.teamType;
            payload.teamId = funJump.teamId;
            payload.studentgearRentals = funJump.studentgearRentals;
            payload.sportgearRentals = funJump.sportgearRentals;
            payload.note = funJump.note;


            loadsStore.cut = true;
            loadsStore.cutValue = { loadNum, jumpId }
            loadsStore.copyValue = payload;
            // this.props.toggleToster(true, "Seleted")
            var element = document.getElementById(uid);
            // element.style.display = "none";
        }
    }

    handleCopyPasteClick = (loadNum) => {
        this.setState({
            optionMenu: !this.state.optionMenu,
            optionSub: false,
            copyOptionSub: false,
            cutOptionSub: false
        })
        var Value = loadsStore.copyValue;
        if (Value?.length && Value.length > 0) {
            multiCopyPasteClick(Value, loadNum)
        } else {
            loadsStore.createFunJump(loadNum, Value).then(d => {
                if (d) {
                    if (loadsStore.cut) {
                        loadsStore.cut = false;
                        const { loadNum, jumpId } = loadsStore.cutValue;
                        loadsStore.cancelFunJump(loadNum, jumpId);
                    }
                }
            });
        }
    };

    componentWillMount() {
        document.querySelector('body').addEventListener("click", this.handleEventClick, false);
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.querySelector('body').removeEventListener("click", this.handleEventClick, false);
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        //console.log('fun jump handle click outside');
        const domNode = ReactDOM.findDOMNode(this);
        let id;
        if (document.querySelector('#copy-funJump-menu')) {
            id = document.querySelector('#copy-funJump-menu')
        } else {
            id = document.querySelector('#move-funJump-menu')
        }

        /*if (!domNode || !domNode.contains(event.target)) {
            if (!id) {
                this.setState({
                    optionMenu: false
                });
            }
        }*/
        if (!domNode || !domNode.contains(event.target)) {
            if (this.state.optionMenu) {
              if (!id) {
                this.setState({
                  optionMenu: false
                });
              }
            }
          }
    }


    handleEventClick = () => {
        //console.log('fun jump handle event click');
        var divsToHide = document.getElementsByClassName("copyPanel");
        for (var i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "none";
        }
    }

    handleClick = (e, uid, isCompleted, parentId) => {
        e.preventDefault();
        var divsToHide = document.getElementsByClassName("copyPanel");
        for (var i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "none";
        }

        if (isCompleted) {
            var element = document.getElementById(uid);

            element.style.display = "block";
            var container = document.getElementById(parentId);//document.querySelector("#contentContainer");

            var xPosition = e.clientX - container.getBoundingClientRect().left - (element.clientWidth / 2);
            var yPosition = (e.clientY - container.getBoundingClientRect().top - (element.clientHeight / 2));
            element.style.left = xPosition + "px";
            element.style.top = yPosition + "px";

            // setTimeout(() => {
            //     element.style.display = "none";
            // }, 10000)
        }
    }

    toggleOptions = () => {
        this.setState({
            optionMenu: !this.state.optionMenu
        })
    }


    // contextMenu = (e) => {
    //     e.preventDefault();
    //     addMenu.popup(e.clientX, e.clientY);
    // }

    // handlePasteClick = (loadNum) => {
    //     console.log('pasteclicked',loadNum);
    //     var Value= loadsStore.copyValue;
    //     loadsStore.createFunJump(loadNum, Value);
    // }

    handleMenuClick = (jumpId) => {
        document.getElementById(jumpId).click();
    }

    setAltitude = (loadNum, jumpId, altitude) => {
        loadsStore.updateFunJump(loadNum, jumpId, { altitude: altitude });
    }

    handleItem = (e, jump) => {
        // this.props.setDragabble(jump)
        loadsStore.setDragabble(jump)
    }

    // isChecked = (item) => {
    //     let index = this.props?.dragabbleItems?.findIndex(i => i.jumpId === item.jumpId)
    //     if(index === -1){
    //       return false
    //     } else {
    //       return true
    //     }
    // }

    render() {
        console.log('in render fun jump: load ' + this.props.loadNum);
        const { funJump, loadNum, jumpId, disabled, jumperOptions, isDragging, connectDragSource, src, isCompleted, loadsToShow, columnsPerGroup, fontSize, isInline } = this.props;
        const opacity = isDragging ? 0 : 1;
        var uid = "fun" + loadNum + "_" + jumpId;
        var parentId = "funParent" + loadNum + "_" + jumpId;
        var menuHeight = this.state.menuHeight ? '158px' : '';
        var altitudes = itemStore.getAltitudes();

        var altitudeMenu = altitudes.map(altitude => {
            return (
                <li onClick={() => this.setAltitude(loadNum, jumpId, altitude.name)}>
                    <span>set altitude to {altitude.name}</span>
                </li>
            );

        });



        const { settingsMoreThan4, settingsFunjumpClass, settingsShowDotMenu, settingsSetPos } = returnLoadSettings(loadsToShow, columnsPerGroup, isInline, fontSize, "isFunJump")
        return connectDragSource(
            <div style={{ opacity }}>
                {
                    <div className="react-contextmenu copyPanel" id={"fun" + loadNum + "_" + jumpId} style={{ position: 'relative', display: 'none', zIndex: 99999, marginBottom: '-36px', width: '68px', }}
                        onClick={() => this.handleCopyClick(funJump, loadNum, uid)}
                    >
                        <span className="react-contextmenu-item" >Copy Jump</span>
                    </div>
                }

                {/* <ContextMenuTrigger id={"fun" + loadNum + "_" + jumpId}> */}
                <div onContextMenu={(e) => this.handleClick(e, uid, isCompleted, parentId)} id={parentId}>

                    {
                        this.state.optionMenu &&
                        <ul className={loadsToShow > 3 ? "menu-option-moreloads" : "menu-option"}>
                            {!this.props.isCompleted && <li>
                                <span onClick={() => this.handleMenuClick("update-Amount" + jumpId)}>Update Amount</span>
                            </li>}
                            {!this.props.isCompleted && <li>
                                <span onClick={() => this.handleMenuClick("update-note" + jumpId)}>add note</span>
                            </li>}
                            {!this.props.isCompleted && <li>
                                <span style={{ color: 'red' }} onClick={() => this.handleMenuClick("delete-jump" + jumpId)}>Delete</span>
                            </li>}

                            <NestedFunJumpMenu
                                txt={"Copy"}
                                loadNum={this.props.loadNum}
                                loads={loadsStore.getActiveLoadsForDisplay()}
                                handleCopyClick={this.handleCopyClick}
                                handleCopyPasteClick={this.handleCopyPasteClick}
                                id={"copy-funJump-menu"}
                            />
                            <NestedFunJumpMenu
                                txt={"Move"}
                                loadNum={this.props.loadNum}
                                loads={loadsStore.getActiveLoadsForDisplay()}
                                handleCopyClick={this.handleCutClick}
                                handleCopyPasteClick={this.handleCopyPasteClick}
                                id={"move-funJump-menu"}
                            />
                            {altitudeMenu}
                        </ul>

                    }
                    <Card className="jumpcard">
                        {funJump.teamName ?
                            <CardHeader className="fun-card-header" >{funJump.teamName}-{funJump.teamType}</CardHeader> : null}
                        <CardBody style={{ padding: '0px !important', height: menuHeight }}>
                            <div className={settingsMoreThan4 ? "" : "row"} style={(this.props.load.isWaitlist && this.props.isCenter) ? { fontSize: 16 } : {}}>
                                {/* <Col xs="12" md="12" lg="3" className="jumptype" style={{ paddingRight: '5px', paddingLeft: '9px' }}>
                                    <EditableJumpType isToday={this.props.isToday} loadId={this.props.loadId} funJump={funJump} isCompleted={this.props.isCompleted} loadNum={loadNum} jumpId={jumpId} jumpType={funJump.jumpType} />
                                </Col> */}
                                {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} jumpType={"funJump"} />}

                                <UpdateAmount showUpdate={this.state.showUpdate} isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"funJump"}
                                    isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={funJump} />
                                <UpdateNote showUpdate={this.state.showUpdate} isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"funJump"}
                                    isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={funJump} />
                                <div style={settingsMoreThan4 ? { background: " #f5f5f5" } : {}}
                                    // className={moreThan4 ? "remove-padding" : "funjumptype remove-padding col"}
                                    className={settingsMoreThan4 ? "remove-padding" : `${settingsFunjumpClass} remove-padding col`}
                                >
                                    {/*funJump.teamName?funJump.teamName:null*/}

                                    <EditableJumpType isToday={this.props.isToday} loadId={this.props.loadId} funJump={funJump} isCompleted={this.props.isCompleted} loadNum={loadNum} jumpId={jumpId} jumpType={funJump.jumpType} />

                                    <EditableAltitude isToday={this.props.isToday} loadId={this.props.loadId} funJump={funJump} altitude={funJump.altitude} isCompleted={this.props.isCompleted} loadNum={loadNum} jumpId={jumpId} jumpType={funJump.jumpType} />
                                </div>
                                <div className={settingsMoreThan4 ? "" : "cardmiddle col"}>
                                    <div className={settingsShowDotMenu}>
                                        <RenderJumperTarget isInline={isInline} columnsPerGroup={columnsPerGroup} moreThan4={settingsMoreThan4} load={this.props.load} loadsToShow={loadsToShow} isToday={this.props.isToday} jump={funJump} loadNum={loadNum} jumpId={jumpId} disabled={disabled} jumperOptions={jumperOptions} isDragging={isDragging} loadId={this.props.loadId} isCompleted={this.props.isCompleted} />
                                        <div className={settingsMoreThan4 ? "right-menu-morethan4" : `right-menu ${settingsSetPos}`}
                                        // style={{ left: "94%" }}
                                        >
                                            {!isCompleted && <div style={{ position: 'absolute', right: 0, top: -18 }}>
                                                <input className="selectable" type="checkbox" style={{ width: 11, height: 11 }} onChange={(e) => this.handleItem(e, this.props)} />
                                            </div>}
                                            <span onClick={this.toggleOptions}>
                                                <i className="fa fa-ellipsis-v"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <Col xs="12" md="2" lg="1" className="jumpClose" style={{ paddingLeft: '4px' }}>
                                    {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"funJump"} />}
                                </Col> */}
                            </div>

                        </CardBody>
                        {funJump.note ?
                            <CardFooter className="fun-card-footer" >{funJump.note}</CardFooter> : null}

                    </Card>
                </div>


                {/* </ContextMenuTrigger>
                <ContextMenu id={"fun" + loadNum + "_" + jumpId}>
                    <MenuItem onClick={() => this.handleCopyClick(funJump,loadNum)} attributes={attributes}>
                        Copy Jump
                    </MenuItem>
                </ContextMenu> */}
            </div>
        )
    }
}


export default DragSource(Types.ITEM, itemSource, collect)(RenderFunJump)
