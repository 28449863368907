import React, { Component } from 'react'
import { Table } from 'reactstrap';

import EditPlan from '../../admin/modals/editPlanes';
import CreatePlane from '../../admin/modals/createPlane';
import RemovePlane from '../../admin/modals/removePlane';
export default class Planes extends Component {

    renderPlanes = () => {
        let { planesArray, checkForNegatives } = this.props;
        if (planesArray != undefined) {
            return planesArray.map((item, index) => {
                console.log(item,"iiiiteeem")
                return (
                    <tr key={`planRow${index}`}>
                        <th key={`planCell1${index}`} scope="row" data-id={item._id}>{index + 1}</th>
                        <td key={`planCell2${index}`}>{item.plane}</td>
                        <td key={`planCell6${index}`}>{item.name}</td>
                        <td key={`planCell3${index}`}>{item.capacity}</td>
                        <td key={`planCell4${index}`}>{checkForNegatives(item.price)}</td>
                        <td key={`planCell4${index}`}>{checkForNegatives(item.hopnpopPrice)}</td>
                        <td key={`planCell4${index}`}>{item.usePlanePrice ? "yes" : "no"}</td>
                        <td key={`planCell3${index}`} style={{backgroundColor: item['color'], color:"white"}}>{item.color}</td>
                        <td key={`planCell3${index}`}>{item.icao24}</td>
                        <td key={`planCell3${index}`}>{item.tail}</td>
                        <td key={`planCell5${index}`}>
                            <EditPlan plan={item} />
                            &nbsp;&nbsp;
                            <RemovePlane plan={item} />
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    render() {

        return (
            <Table>
                <thead className="main-heading">
                    <tr>
                        <th><CreatePlane /></th>
                        <th>Plane</th>
                        <th>Name</th>
                        <th>Capacity</th>
                        <th>Price</th>
                        <th>HopNPop Price</th>
                        <th>Special Event</th>
                        <th>Color</th>
                        <th>Icao24 code</th>
                        <th>Tail</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="priceHeading">
                        <td colSpan="3">Planes</td>
                    </tr>
                    {this.renderPlanes()}
                </tbody>
            </Table>
        )
    }
}
