import { useEffect } from "react";
import { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import schedulesStore from '../../../stores/schedule-store';
import swal from "sweetalert";
import moment from "moment";


const ChangeTimeslotSeatCapacity = ({ isChangeSeatCapacityModalOpen, state, setState}) => {

    const [numberOfBookedSeats, setNumberOfBookedSeats] = useState();
    const [updatedNumOfSeats, setUpdatedNumOfSeats] = useState();

    useEffect(()=> {
        setNumberOfBookedSeats(state.changeSeatCapOfEvent.totalSeats - state.changeSeatCapOfEvent.seatsRemaining);
        setUpdatedNumOfSeats(state.changeSeatCapOfEvent.totalSeats);
    },[state.changeSeatCapOfEvent.totalSeats,state.changeSeatCapOfEvent.seatsRemaining, state]);

    const showAlertMsg = (options) => {
        swal({
            title: options.title,
            text: options.msg,
            icon: options.title.charAt(0).toLowerCase() + options.title.slice(1),
            dangerMode: true,
        });
    }

    const onUpdationSuccess = () => {
        setState({ changeSeatCapacityModalOpen: false });
        setUpdatedNumOfSeats(updatedNumOfSeats);
        showAlertMsg({title:"Success", msg:"Seat capacity changed successfully"});
        const payload = {
            date: new moment(state.changeSeatCapOfEvent.datetime).format("YYYY-MM-DD"),
            id: state.changeSeatCapOfEvent._id
        };
        schedulesStore.fetchReservation(payload);
    }

    const onUpdationFailure = () => {
        showAlertMsg({title:"Error", msg:"Some error occured try again after refreshing the browser"});
        setState({ changeSeatCapacityModalOpen: false });
    } 

    const handleChangeSeatCapSubmit = async (e) => {
        e.preventDefault();
        if(updatedNumOfSeats < numberOfBookedSeats) {
            showAlertMsg({title:"Error", msg:"Total number of seats can not be less than the total number of booked seats"});
            return ;
        }
        const numSeatToUpdate = updatedNumOfSeats - state.changeSeatCapOfEvent.totalSeats;
        const payload = {
            numSeatToUpdate : numSeatToUpdate,
            timeslotId: state.changeSeatCapOfEvent._id
        }
        try {   
            const response = await schedulesStore.modifyTimeslot(payload);
            onUpdationSuccess();
        }catch(error) {
            onUpdationFailure();
        }
    }

    const getSeatsCap = (event) => {
        setUpdatedNumOfSeats(event.target.value)
    }

    return (
        <Modal className='seat_cap_modal' isOpen={isChangeSeatCapacityModalOpen}>
            <ModalHeader className="modal-header" style={{ justifyContent: "center", padding: "1rem 0 1rem 0", width: "100%", color: "#105ec1" }}>Update Seat Capacity
                <div className="closing-modal close-x-icon" onClick={() => setState({ changeSeatCapacityModalOpen: false })}>
                    <i className="fa fa-times fa-x-icon" aria-hidden="true"></i>
                </div>
            </ModalHeader>
            <form onSubmit={(e) => handleChangeSeatCapSubmit(e)}>
                <ModalBody className="modal-body" style={{ color: "#105ec1" }}>
                    <div className="seat_cap_modal_primary">
                        <div className="seat_cap_modal_row"><label>Event Name:-</label><label style={{width:"50%"}}>{state.changeSeatCapOfEvent.eventName}</label></div>
                        <div className="seat_cap_modal_row">
                            <label>Enter Seats Num:-</label>
                            <input type="number" value = {updatedNumOfSeats} className="seat_cap_input" placeholder="Number Of Seats" name="seatsCap"  onChange={getSeatsCap}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="seat_cap_modal_footer">
                    <Button className="seat_cap-add-btn" color="primary" type="submit">Update</Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}


export default ChangeTimeslotSeatCapacity;