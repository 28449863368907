import { useState } from 'react'
import { Row, Input, Button } from 'reactstrap'
import swal from 'sweetalert'
import Api from '../../../../stores/auth-store'

function SettingModal({ toggleModal, settingsObj: { loadsPerPage, columnsPerGroup, fontSize }, getSettingsData, isInlineMode, isInline,
    displayPlaneNameLeftMode, isDisplayPlaneNameLeft }) {
    const [state, setState] = useState({
        loadsPerPage: loadsPerPage || 2,
        columnsPerGroup: columnsPerGroup || 4,
        fontSize: fontSize || 14,

    })
    const [loader, setLoader] = useState(false)

    const returnOptionList = (max, isFont) => {
        const start = isFont ? 8 : 1
        let m = [];
        for (let i = start; i <= max; i++) {
            m.push(<option>{i}</option>)
        }
        return m;
    }

    const isToShow = () => {
        let show = false
        if (window.innerWidth > 700 && loadsPerPage > 0 && columnsPerGroup < 4 && fontSize < 20) {
            show = true
        }
        return show
    }

    const updateSettings = async () => {
        setLoader(true)
        let { loadsPerPage, columnsPerGroup, fontSize } = state;
        const _id = JSON.parse(localStorage.getItem('jumperDetails'))._id || null
        let payload = { loadsPerPage, columnsPerGroup, fontSize, isManifest: true, isInline, isDisplayPlaneNameLeft };
        try {
            await Api.ChangeUserSettings({ ...payload, _id })
            let settings = JSON.parse(localStorage.getItem('jumperDetails'))
            settings.columnsPerGroup = payload.columnsPerGroup
            settings.loadsPerPage = payload.loadsPerPage
            settings.fontSize = payload.fontSize
            settings.isInline = payload.isInline
            settings.isDisplayPlaneNameLeft = payload.isDisplayPlaneNameLeft
            localStorage.setItem("jumperDetails", JSON.stringify(settings))
            setLoader(false)
            getSettingsData(payload)
            toggleModal(false)
        } catch (err) {
            console.log(err)
            setLoader(false)
            toggleModal(false)
            swal({
                title: 'Something went wrong',
                text: 'Please contact admin',
                icon: 'error'
            })
        }
    }
    const handleInlineMode = (e) => {
        isInlineMode(e.target.checked)
    }

    const handleDisplayPlaneNameLeft = (e) => {
        displayPlaneNameLeftMode(e.target.checked)
    }

    return (
        <Row
            id="setting-modal"
            style={{
                background: '#ffff',
                color: 'black',
                padding: 16,
                position: 'absolute',
                zIndex: 9999,
                left: '5%',
                borderRadius: 8
            }} >
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "10px 0" }} >
                    <label style={{ minWidth: '140px' }} >Loads Per Page:</label>
                    <Input value={state.loadsPerPage} onChange={(e) => setState({ ...state, loadsPerPage: +e.target.value })} type="select" name="select" >
                        {returnOptionList(window.innerWidth > 2500 ? 10 : 8)}
                    </Input>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "10px 0" }} >
                    <label style={{ minWidth: '140px' }} >Column per load:</label>
                    <Input value={state.columnsPerGroup} onChange={(e) => setState({ ...state, columnsPerGroup: +e.target.value })} type="select" name="select" >
                        {returnOptionList(4)}
                    </Input>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "10px 0" }} >
                    <label style={{ minWidth: '140px' }} >Select Font:</label>
                    <Input value={state.fontSize} onChange={(e) => setState({ ...state, fontSize: +e.target.value })} type="select" name="select" >
                        {returnOptionList(24, true)}
                    </Input>
                </div>

                {isToShow() && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "10px 0" }} >
                    <label style={{ minWidth: '140px' }} >Inline Mode:</label>
                    <label class="setting-switch">
                        <input type="checkbox" name="check" checked={isInline} onChange={(e) => handleInlineMode(e)} />
                        <span class="settings-slider round" />
                    </label>
                </div>}

                {isToShow() && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "10px 0" }} >
                    <label style={{ minWidth: '140px' }} >Display Mode:</label>
                    <label class="setting-switch">
                        <input type="checkbox" name="check" checked={isDisplayPlaneNameLeft} onChange={(e) => handleDisplayPlaneNameLeft(e)} />
                        <span class="settings-slider round" />
                    </label>
                </div>}

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "10px 0" }} >
                    <Button disabled={!state.columnsPerGroup || !state.fontSize || !state.loadsPerPage} color="success"
                        onClick={() => updateSettings()} >Submit</Button>
                </div>
            </div>
        </Row>
    );
}

export default SettingModal
