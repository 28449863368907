import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import loadsStore from '../../../stores/loads-store';
import jumpersStore from '../../../stores/jumpers-store';

import JmpModal from './customModal';
import { ClipLoader } from 'react-spinners';

class ResetDay extends Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetDay = this.handleResetDay.bind(this);
    this.state = {
      isModalOpen: false,
      isloading: false,
      type: ''

    }
    this.loadEvents = [
      { name: loadsStore.getEventPrefix('resetDay'), func: this.handleResetDay },
      { name: loadsStore.getEventPrefix('resetLoads'), func: this.handleResetDay },
    ];
    this.jumperEvents = [
      { name: jumpersStore.getEventPrefix('checkoutAll'), func: this.handleResetDay }
    ]
  }

  componentWillMount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.on(evnt.name, evnt.func))
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func))
  }

  componentWillUnmount = () => {
    this.loadEvents.forEach((evnt) => loadsStore.removeListener(evnt.name, evnt.func));
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
  }

  toggleModal(type) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      type: type
    });
  }

  handleResetDay(data) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      isloading: false
    });
  }

  handleSubmit() {
    this.setState({
      isloading: true
    });
    if (this.state.type === "day") {
      loadsStore.resetDay();
    }
    else if (this.state.type === "reload") {
      loadsStore.reloadDay();
    }
    else if (this.state.type === "loads") {
      loadsStore.resetActiveLoads();
    }
    else if (this.state.type === "checkout") {
      jumpersStore.CheckoutAll();
    }
  }

  render() {

    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;


    return (
      <React.Fragment>
        <JmpModal
          isModalOpen={this.state.isModalOpen}
          title={`Reset Day`}
        >
          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>
          <Col md={12}>
            Are you sure you want to reset {this.state.type} ?  <br />
            {this.state.type === "day" ? <i>This will remove all saved and unsaved changes for the day</i> : <i>This will remove all active loads</i>}
          </Col>
          <Row className="form-group">
            <Col md={{ size: 10 }}>
              <button className="btn btn-bg lightColor createButton" type="submit" onClick={() => this.handleSubmit()} >SUBMIT</button>
              <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
            </Col>
          </Row>
        </JmpModal>
        <button className="btn customRightButton  rightButton" onClick={() => this.toggleModal('checkout')} style={{ margin: '0px' }}>
          Checkout all
        </button>
        <button className="btn customRightButton  rightButton" onClick={() => this.toggleModal('reload')} style={{ margin: '0px' }}>
          Reload Day
        </button>
        <button className="btn customRightButton  rightButton" onClick={() => this.toggleModal('day')} style={{ margin: '0px' }}>
          Reset Day
        </button>
        <button className="btn customRightButton rightButton" onClick={() => this.toggleModal('loads')} style={{ margin: '0px' }}>
          Reset Loads
        </button>
      </React.Fragment>
    );
  }
}

export default ResetDay
