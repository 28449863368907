
import React, { Component, Fragment, createRef } from 'react';

import loadsStore from '../../../stores/loads-store';
import { DragSource, DropTarget } from 'react-dnd'
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'
import EditablePrice from './editablePrice'
// import AddJumpers from '../helper/addJumper';
import AddJumpers from './addJumpers';
import jumpersStore from '../../../stores/jumpers-store';

import { UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu, ListGroupItem } from 'reactstrap';

const Types = {
  ITEM: 'JUMP'
};

const itemSource = {
  beginDrag(props) {
    const item = props
    return item
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    return props;
  }
}
const loadTarget = {
  drop(props, monitor, component) {

    const dragItem = monitor.getItem();

    if (dragItem.jumper) {
      var jumperId = dragItem.jumper._id;
      var jumpId = props.jumpId;
      var oldJumpId = dragItem.jumpId;

      if (jumpId == oldJumpId) {
        return;
      }

      var payload = {}
      payload.jumpId = props.jumpId;
      payload.loadNum = props.loadNum;
      payload.jumper = dragItem.jumper;
      payload.oldJumpId = dragItem.jumpId;
      payload.oldLoadNum = dragItem.loadNum;
      dragItem.handleJumperRemove(payload);
    }

  }
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class RemovableJumper extends Component {

  constructor(props) {

    super(props);
    this.setEditable = this.setEditable.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isModalOpen: false,
      editMode: false,
      jumpType: null,
      load: props.load,
      customBool: false,
      menuState: false,
      addjumperState: false,

      dotOptions: []

    };
    this.dotMenuRef = createRef();
  }

  // setEditable() {
  //   this.setState({
  //     editMode: !this.state.editMode,
  //     jumpType: null
  //   });
  // }
  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    })
  }
  setEditable = (isCompleted) => {
    if (isCompleted) {
      this.setState({
        isModalOpen: true
      })
    }
    else {
      this.setState({
        editMode: this.props.type == "observer" ? !this.state.editMode : false,
        addjumperState: this.props.type == "observer" ? false : true
      })
    }
  }

  checkForNegatives = (price) => {
    if (price != undefined) {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price).toFixed(2)})`
        } :
        {
          value: `$${price.toFixed(2)}`
        }

      if (price < 0) {
        return <span style={{ color: 'red' }}> {priceCell.value}</span>;
      }
      return <span style={{ color: '#00FF00' }}> {priceCell.value}</span>;;
    }
    else {
      return '$0.00';
    }
  }

  setAddjumperState = (data) => {
    this.setState({
      addjumperState: data
    })
  }

  handleSubmit(loadNum, jumpId, jumperId, isToday) {
    this.setState({ editMode: !this.state.editMode })
    if (this.props.type === "observer") {
      loadsStore.removeObserver(loadNum, jumperId);
    }
    else {
      if (this.props.isCompleted) {
        var jumpPostBody = {
          jumperIds: this.props.jump.jumpers.filter(data => data._id !== jumperId).map(x => { return { jumperId: x._id, price: x.price, block: x.block, blockId: x.blockId, gearPrice: x.gearPrice, gearType: x.gearType, isVideographer: x.isVideographer, tracked: x.tracked, isLOing: x.isLOing, chargeTo: x.chargeTo } }),
          jumpType: this.props.jump.jumpType,
          studentgearRentals: this.props.jump.studentgearRentals.filter(data => data !== jumperId),
          sportgearRentals: this.props.jump.sportgearRentals.filter(data => data !== jumperId),
        };

        if (isToday) {
          jumpPostBody.isToday = true
        }
        jumpPostBody.teamName = this.props.jump.teamName;
        jumpPostBody.teamId = this.props.jump.teamId;
        jumpPostBody.loadNum = this.props.jump.loadNum;
        jumpPostBody.planeLoad = this.props.jump.planeLoad;
        jumpPostBody.editPrice = true;

        var removeJumper = this.props.jump.jumpers.filter(data => data._id === jumperId)[0];
        if (removeJumper && removeJumper.blockId) {
          jumpPostBody.removeBlockFromCompleted = true;
          jumpPostBody.jumperId = jumperId;
          jumpPostBody.removeBlockId = removeJumper.blockId;
        }



        var jumpId = this.props.jump.jumpId;
        var loadId = this.props.loadId;

        this.props.addCompletedJumpers(loadId, jumpId, jumpPostBody);
      }
      else {
        loadsStore.removeJumper(loadNum, jumpId, jumperId);
      }
    }
  }

  approveJumper(loadNum, jumpId, jumperId) {
    this.setEditable();
    loadsStore.approveJumper(loadNum, jumpId, jumperId);
  }
  toggleMenu = () => {
    jumpersStore.getJumperDotOptions(this.props.jumper, this.props.jump)
    .then(options => {
      this.setState(prevState => ({
        menuState: !prevState.menuState,
        dotOptions: options
      }))
    })
  }
  handleRemoveJumper = (jumpId) => {
    this.handleSubmit(this.props.loadNum, this.props.jumpId, this.props.jumper._id, this.props.isToday);
  }
  jumpOnAcc = (jumper, isCompleted) => {
    if (isCompleted) {
      var loadId = this.props.loadId;

      var jumpPostBody = {
        jumperIds: this.props.jump.jumpers.map(jumper => { return { jumperId: jumper._id, price: jumper.price, block: jumper.block, blockId: jumper.blockId, gearPrice: jumper.gearPrice, gearType: jumper.gearType, isVideographer: jumper.isVideographer, tracked: jumper.tracked, isLOing: jumper.isLOing, chargeTo: jumper.chargeTo } }),
        jumpType: this.props.jump.jumpType,
      }

      if (this.props.isToday) {
        jumpPostBody.isToday = true
      }

      jumpPostBody.gearRentals = this.props.jump.gearRentals;
      jumpPostBody.studentgearRentals = this.props.jump.studentgearRentals;
      jumpPostBody.sportgearRentals = this.props.jump.sportgearRentals;
      jumpPostBody.teamName = this.props.jump.teamName;
      jumpPostBody.teamId = this.props.jump.teamId;
      jumpPostBody.loadNum = this.props.jump.loadNum;
      jumpPostBody.planeLoad = this.props.jump.planeLoad;
      jumpPostBody.editPrice = true;
      var item = jumpPostBody.jumperIds.filter(id => id.jumperId.toString() === this.props.jumper._id.toString())[0];

      var prevBlockName = item.block;
      var prevBlockId = item.blockId;

      jumpPostBody.jumpOnAccount = true;
      jumpPostBody.jumperId = this.props.jumper._id;

      if (prevBlockName && prevBlockId) {
        jumpPostBody.removeBlockFromCompleted = true;
        jumpPostBody.removeBlockId = prevBlockId;
      }

      this.props.addCompletedJumpers(loadId, this.props.jumpId, jumpPostBody);
    }
    else {
      var jumpPostBody = {
        jumperId: this.props.jumper._id,
        jumpOnAccount: true
      }
      loadsStore.updateFunJump(this.props.loadNum, this.props.jumpId, jumpPostBody);
    }
  }
  markAsVideographer = (jumper, val) => {
    var jumpPostBody = {
      jumperId: this.props.jumper._id,
      isVideographer: val
    }
    loadsStore.updateFunJump(this.props.loadNum, this.props.jumpId, jumpPostBody);
  }
  chargeToTeam = (jumper, val) => {
    var jumpPostBody = {
      jumperId: this.props.jumper._id,
      chargeToTeam: val
    }
    loadsStore.updateFunJump(this.props.loadNum, this.props.jumpId, jumpPostBody);
  }
  useBlock = (blockName, blockId, isCompleted) => {
    if (isCompleted) {
      var loadId = this.props.loadId;

      var jumpPostBody = {
        jumperIds: this.props.jump.jumpers.map(jumper => { return { jumperId: jumper._id, price: jumper.price, block: jumper.block, blockId: jumper.blockId, gearPrice: jumper.gearPrice, gearType: jumper.gearType, isVideographer: jumper.isVideographer, tracked: jumper.tracked, isLOing: jumper.isLOing, chargeTo: jumper.chargeTo } }),
        jumpType: this.props.jump.jumpType,
      }

      if (this.props.isToday) {
        jumpPostBody.isToday = true
      }

      jumpPostBody.gearRentals = this.props.jump.gearRentals;
      jumpPostBody.studentgearRentals = this.props.jump.studentgearRentals;
      jumpPostBody.sportgearRentals = this.props.jump.sportgearRentals;
      jumpPostBody.teamName = this.props.jump.teamName;
      jumpPostBody.teamId = this.props.jump.teamId;
      jumpPostBody.loadNum = this.props.jump.loadNum;
      jumpPostBody.planeLoad = this.props.jump.planeLoad;
      jumpPostBody.editPrice = true;
      var item = jumpPostBody.jumperIds.filter(id => id.jumperId.toString() === this.props.jumper._id.toString())[0];

      var prevBlockName = item.block;
      var prevBlockId = item.blockId;

      item.block = blockName;
      item.blockId = blockId;
      item.price = 0;

      jumpPostBody.applyBlockToCompleted = true;
      jumpPostBody.applyBlockId = blockId;
      jumpPostBody.jumperId = this.props.jumper._id;

      if (prevBlockName && prevBlockId) {
        jumpPostBody.removeBlockFromCompleted = true;
        jumpPostBody.removeBlockId = prevBlockId;
      }

      this.props.addCompletedJumpers(loadId, this.props.jumpId, jumpPostBody);
    }
    else {
      var jumpPostBody = {
        jumperId: this.props.jumper._id,
        block: blockName,
        blockId: blockId
      }
      loadsStore.updateFunJump(this.props.loadNum, this.props.jumpId, jumpPostBody);
    }
  }
  handleDotSubmit = (el, jumpId, jumper, blockName, blockId, isCompleted) => {
    if (el === 'remove jumper') {
      this.handleSubmit(this.props.loadNum, this.props.jumpId, this.props.jumper._id, this.props.isToday)
    } else if (el === 'view activity') {
      jumpersStore.getDailyActivity(this.props.jumper._id)
    }
    else if (el === 'jump on account') {
      this.jumpOnAcc(jumper, isCompleted)
    } else if (el === 'mark as videographer') {
      this.markAsVideographer(jumper, true);
    } else if (el === "remove as videographer") {
      this.markAsVideographer(jumper, false);
    } else if (el === "charge to team") {
      this.chargeToTeam(jumper, true);
    } else if (el === "charge to jumper") {
      this.chargeToTeam(jumper, false);
    } else if (el.includes('use block-')) {
      var blockName = el.replace('use block-', '');
      this.useBlock(blockName, blockId, isCompleted)
    }
    this.toggleMenu()
  }

  render() {
    const { isDragging, connectDragSource, gearLabel, jumpId, loadId, isCompleted, funJump, jump, jumper, isToday, loadNum, loadsToShow, columnsPerGroup } = this.props;
    const opacity = isDragging ? 0 : 1;




    var tooltipId = "example" + jumper._id.toString() + "ld" + loadNum;
    var moneytooltipId = "money" + jumper._id.toString() + "ld" + loadNum;

    var regularAlerts = [], moneyAlerts = [];
    var alerts, moneyAlertsComponent;

    if (jumper.alerts && jumper.alerts.length > 0) {
      regularAlerts = jumper.alerts.filter(alert => alert.type !== "money");
      moneyAlerts = jumper.alerts.filter(alert => alert.type === "money");
    }


    if (regularAlerts !== undefined && regularAlerts.length > 0) {
      alerts = regularAlerts.map(alert => {
        return (<p>{alert.message}</p>);
      });
    }

    if (moneyAlerts !== undefined && moneyAlerts.length > 0) {
      moneyAlertsComponent = moneyAlerts.map(alert => {
        return (<p>{alert.message}</p>);
      });
    }

    var alertsColor = '#FFFF00';
    var moneyAlertsColor = "white";
    if (regularAlerts !== undefined && regularAlerts.length > 0 && regularAlerts.some(alert => alert.level === "urgent")) {
      alertsColor = 'tomato';
    }

    if (moneyAlerts !== undefined && moneyAlerts.length > 0 && moneyAlerts.some(alert => alert.level === "urgent")) {
      moneyAlertsColor = 'tomato';
    }
    const styleObject = {
      display: 'flex',
      justifyContent: 'flex-start'
    }

    let classname = 'load-to-show-inline ';
    if (loadsToShow > 3) classname = ''
    if (columnsPerGroup === 1) classname = 'load-to-show-inline1 '
    return connectDragSource(
      <div className={"single-jump-card"}>
        {jump ?
          <div className={"dotToggle"}>
            <Dropdown isOpen={this.state.menuState} toggle={this.toggleMenu}>
              <DropdownToggle tag="text" >
                <i className="fa fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropDownMenu">
                {this.state.dotOptions.map(el => (
                  <ListGroupItem onClick={() => this.handleDotSubmit(el.txt, this.props.jumpId, this.props.jumper._id, el.blockName, el.blockId, isCompleted)}>{el.txt}</ListGroupItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div> : null
        }
        <div className="single-jump-card__first_div" >
          <React.Fragment>
            {/* <button className="btn lightBlueButton jumper-name" onClick={()=>this.setEditable(isCompleted)} type="submit" disabled={this.props.disabled}> */}
            {/* <div onClick={()=>this.setEditable(isCompleted)}> */}
            {columnsPerGroup === 1 ? <div style={{ styleObject, display: "flex", justifyContent: "space-between" }} >
              <div style={{ display: "flex" }}>
                <strong style={columnsPerGroup === 1 ? { textAlign: 'left' } : {}} onClick={() => this.setEditable(isCompleted)} className="inline-fun-jump-show-name">
                  {this.props.type === "observer" ? "O: " : null}
                  {this.props.jumper.nickname !== undefined ? `${this.props.jumper.nickname} ` : `${this.props.jumper.firstname}  `}
                  {this.props.jumper.lastname !== undefined && this.props.jumper.lastname !== "" ? this.props.jumper.lastname?.substr(0, 1) : null}
                  {this.props.jumper.chargeTo !== undefined ? "(T)" : null}
                </strong>

                <span onClick={() => this.setEditable(isCompleted)}>
                  {regularAlerts && regularAlerts.length > 0 ?
                    <React.Fragment>
                      <i className="fa fa-exclamation-triangle" style={{ color: alertsColor }} href="#" id={tooltipId} />
                      <UncontrolledTooltip placement="top" target={tooltipId}>
                        {alerts}
                      </UncontrolledTooltip>
                    </React.Fragment>
                    :
                    null
                  }
                </span>

                <span onClick={() => this.setEditable(isCompleted)}>
                  {moneyAlerts && moneyAlerts.length > 0 ?
                    <React.Fragment>
                      <i className="fa fa-dollar" style={{ color: moneyAlertsColor }} href="#" id={moneytooltipId}>


                      </i>
                      <UncontrolledTooltip placement="top" target={moneytooltipId}>
                        {moneyAlertsComponent}
                      </UncontrolledTooltip>
                    </React.Fragment>
                    :
                    null
                  }
                </span>


                <div
                  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
                  className={classname} >
                  <div onClick={() => this.setEditable(isCompleted)} style={{ margin: "0 3px 0 -2px" }} >
                    <span style={{ marginLeft: "4px" }}>
                      {this.props.jumper.memberId}{this.props.jumper.isVideographer ? <i className="fa fa-xs fa-video-camera"> </i> : null}
                    </span>
                  </div>
                  <span onClick={() => this.setEditable(isCompleted)}>
                    {
                      this.state.editMode && this.props.jumper.pending ?
                        <i onClick={(values) => this.approveJumper(this.props.loadNum, this.props.jumpId, this.props.jumper._id)} className='fa fa-check'></i>
                        : null
                    }
                    {this.state.editMode ?
                      <span onClick={() => this.handleSubmit(this.props.loadNum, this.props.jumpId, this.props.jumper._id, isToday)}
                        style={{ position: 'relative', left: '8px' }}
                      >
                        <i className='fa fa-close'></i>
                      </span> : null
                    }
                    {
                      this.state.addjumperState ?
                        <AddJumpers
                          activeJumperIdsOnLoad={this.props.activeJumperIdsOnLoad}
                          columnsPerGroup={columnsPerGroup}
                          isToday={this.props.isToday}
                          loadsToShow={loadsToShow}
                          loadNum={loadNum}
                          jump={jump}
                          jumpType={this.props.jumpType}
                          jumpId={this.props.jumpId}
                          disabled={this.props.disabled}
                          jumperOptions={this.props.jumperOptions}
                          fontSize={this.props.fontSize}
                          loadId={this.props.loadId}
                          jumper={this.props.jumper}
                          isCompleted={this.props.isCompleted}
                          isInline={this.props.isInline}
                          jumperLength={this.props.jumperLength}
                          editModeForAddJumper={this.state.addjumperState}
                          setAddjumperState={this.setAddjumperState}
                          removeJumper={() => this.handleSubmit(this.props.loadNum, this.props.jumpId, this.props.jumper._id, isToday)}
                        />
                        :
                        null
                    }
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <span onClick={() => this.setState({ editMode: false })} >
                  {
                    jumper.block === "" || jumper.block === undefined && this.props.type !== "observer" ?
                      <EditablePrice customProps={this.ctProp} jump={jump} price={jumper.price} type="funJump" label="JP" jumpId={this.props.jumpId} loadNum={loadNum} jumperId={jumper._id} loadId={this.props.loadId} isCompleted={isCompleted} isToday={this.props.isToday} />
                      : <div>{jumper.block}</div>
                  }
                </span>
                {
                  jumper.gearPrice !== undefined ?
                    <EditablePrice jump={jump} price={jumper.gearPrice} type="funJump" label={gearLabel} jumpId={jumpId} loadNum={loadNum} jumperId={jumper._id} loadId={loadId} isCompleted={isCompleted} isToday={this.props.isToday} />
                    : null
                }

              </div>
            </div> : <div>
              <strong onClick={() => this.setEditable(isCompleted)}>
                {this.props.type === "observer" ? "O: " : null}
                {this.props.jumper.nickname !== undefined ? `${this.props.jumper.nickname} ` : `${this.props.jumper.firstname}  `}
                {this.props.jumper.lastname !== undefined && this.props.jumper.lastname !== "" ? this.props.jumper.lastname?.substr(0, 1) : null}
                {this.props.jumper.chargeTo !== undefined ? "(T)" : null}
              </strong>
              <span onClick={() => this.setEditable(isCompleted)}>
                {regularAlerts && regularAlerts.length > 0 ?
                  <React.Fragment>
                    <i className="fa fa-exclamation-triangle" style={{ color: alertsColor }} href="#" id={tooltipId} />
                    <UncontrolledTooltip placement="top" target={tooltipId}>
                      {alerts}
                    </UncontrolledTooltip>
                  </React.Fragment>
                  :
                  null
                }
              </span>
              <span onClick={() => this.setEditable(isCompleted)}>
                {moneyAlerts && moneyAlerts.length > 0 ?
                  <React.Fragment>
                    <i className="fa fa-dollar" style={{ color: moneyAlertsColor }} href="#" id={moneytooltipId}>

                    </i>
                    <UncontrolledTooltip placement="top" target={moneytooltipId}>
                      {moneyAlertsComponent}
                    </UncontrolledTooltip>
                  </React.Fragment>
                  :
                  null
                }
              </span>
              <div
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
                className={classname} >
                <div onClick={() => this.setEditable(isCompleted)} style={{ margin: "0 3px 0 -2px" }} >
                  {this.props.jumper.memberId}{this.props.jumper.isVideographer ? <i className="fa fa-xs fa-video-camera"> </i> : null}
                </div>
                <span onClick={() => this.setEditable(isCompleted)}>
                  {
                    this.state.editMode && this.props.jumper.pending ?
                      <i onClick={(values) => this.approveJumper(this.props.loadNum, this.props.jumpId, this.props.jumper._id)} className='fa fa-check'></i>
                      : null
                  }
                  {this.state.editMode ?
                    <span onClick={() => this.handleSubmit(this.props.loadNum, this.props.jumpId, this.props.jumper._id, isToday)}
                      style={{ position: 'relative', left: '8px' }}
                    >
                      <i className='fa fa-close'></i>
                    </span> : null
                  }
                  {
                    this.state.addjumperState ?
                      <AddJumpers
                        activeJumperIdsOnLoad={this.props.activeJumperIdsOnLoad}
                        columnsPerGroup={columnsPerGroup}
                        isToday={this.props.isToday}
                        loadsToShow={loadsToShow}
                        loadNum={loadNum}
                        jump={jump}
                        jumpType={this.props.jumpType}
                        jumpId={this.props.jumpId}
                        disabled={this.props.disabled}
                        jumperOptions={this.props.jumperOptions}
                        fontSize={this.props.fontSize}
                        loadId={this.props.loadId}
                        jumper={this.props.jumper}
                        isCompleted={this.props.isCompleted}
                        isInline={this.props.isInline}
                        jumperLength={this.props.jumperLength}
                        editModeForAddJumper={this.state.addjumperState}
                        setAddjumperState={this.setAddjumperState}
                        removeJumper={() => this.handleSubmit(this.props.loadNum, this.props.jumpId, this.props.jumper._id, isToday)}
                      />
                      :
                      null
                  }
                </span>
                <span onClick={() => this.setState({ editMode: false })} >
                  {
                    jumper.block === "" || jumper.block === undefined && this.props.type !== "observer" ?
                      <EditablePrice customProps={this.ctProp} jump={jump} price={jumper.price} type="funJump" label="JP" jumpId={this.props.jumpId} loadNum={loadNum} jumperId={jumper._id} loadId={this.props.loadId} isCompleted={isCompleted} isToday={this.props.isToday} />
                      : <div>{jumper.block}</div>
                  }
                </span>
                {
                  jumper.gearPrice !== undefined ?
                    <EditablePrice jump={jump} price={jumper.gearPrice} type="funJump" label={gearLabel} jumpId={jumpId} loadNum={loadNum} jumperId={jumper._id} loadId={loadId} isCompleted={isCompleted} isToday={this.props.isToday} />
                    : null
                }
              </div>
            </div>}
          </React.Fragment>
          {/* </button> */}
          <LoadUpdateModal
            isOpen={this.state.isModalOpen}
            onClick={this.wantsEidtable}
          />
        </div>

      </div>
    );
  }
}
const mstp = state => {
  return {
    state
  }
}
const mdtp = dispatch => {
  return {
    addCompletedJumpers: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.addCompletedJumpers(loadId, cjumpId, jumpPostBody)),
  }
}
RemovableJumper = connect(mstp, mdtp)(DragSource(Types.ITEM, itemSource, collect)(RemovableJumper));
RemovableJumper = connect(mstp, mdtp)(DropTarget(Types.ITEM, loadTarget, collectTarget)(RemovableJumper));
export default RemovableJumper
// export default connect(mstp, mdtp)(DragSource(Types.ITEM, itemSource, collect)(RemovableJumper)
// )
