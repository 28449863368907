import React, { Component } from 'react';
import { LocalForm } from 'react-redux-form';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import loadsStore from '../../../stores/loads-store';
import itemStore from '../../../stores/item-store';
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'
import { completedLoadJumperChangeConfirmModal } from '../../../actions/reports'
import { FormGroup, Input, FormText } from 'reactstrap';
import ReactDOM from 'react-dom';

class EditableJumper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      jumpType: null,
      load: props.load,
      jumper: props.jumper,
      type: props.type,
      label: props.type,
      jumpId: props.jumpId,
      editMode: props.editType,
      JumperId: '',
      loadNum: '',
      level: ''
    };
    this.timerId = null;
    this.valRef = null;
    this.inputValue = '';
  }


  componentDidMount = () => {
    var studentPackages = itemStore.getStudentPackages();
    // console.log(studentPackages);
    this.setState({ studentPackages: studentPackages });
    if (this.valRef !== null)
      this.valRef.focus();
  }

  componentDidUpdate = () => {
    if (this.valRef !== null)
      this.valRef.focus();
  }

  componentWillMount = () => {
    //console.log('CreateJump componentWillMount');
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        editMode: false
      });
    }
  }

  handleAffChange = (selectedOption, loadNum, jumpId, type, label, isCompleted, loadId, jump) => {
    var today = new Date();
    var isWeekend = (today.getDay() % 6 === 0);
    // if (type === "aff" && selectedOption.target.value !== undefined) {
    if (type === "aff" && selectedOption !== undefined) {
      if (isCompleted && label === "level") {
        this.props.openModel({ isModal: false, editType: false })
        var jumpPostBody = {
          mainInstructorId: jump.mainInstructor._id,

          videographerId: jump.videographer._id,
          studentId: jump.student._id,
          price: jump.price,
          level: selectedOption,//.target.value,
          isWeekend: isWeekend,
          isSTP: jump.isSTP
        }
        if (jump.secondaryInstructor) {
          jumpPostBody.secondaryInstructorId = jump.secondaryInstructor._id
        }
        if (this.props.isToday) {
          jumpPostBody.isToday = true
        }

        var cjumpId = jump.jumpId;
        var loadId = loadId;
        this.props.updateCompletedAffJump(loadId, cjumpId, jumpPostBody);
      }
      else {
        if (label === "level") {
          var jumpPostBody = { studentPackageId: selectedOption }//.target.value }
        }
        loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
      }
      this.setState({ editMode: !this.state.editMode });
    }
    else if (type == "iad") {
      if (label === "level") {
        var jumpPostBody = { studentPackageId: selectedOption }//.target.value }
      }
      loadsStore.updateIadJump(loadNum, jumpId, jumpPostBody)
    }
    else if (type === "tandem") {
      var jumpPostBody = { tandemPackageId: selectedOption }//.target.value }
      loadsStore.updateTandemJump(loadNum, jumpId, jumpPostBody)
    }

    this.setState({ editMode: !this.state.editMode });
  }

  cancelOrSubmit = () => {
    var ID = this.state.JumperId;
    var loadNum = this.state.loadNum;
    var jumpId = this.state.jumpId;
    var type = this.state.type;
    var label = this.state.label;
    var jumpPostBody = {};
    if (type === "aff") {
      if (label === "level") {
        var jumpPostBody = { level: ID }
      }
      loadsStore.updateAffJump(loadNum, jumpId, jumpPostBody);
    }
  }
  wantsEidtable = (data) => {
    this.setState({
      editMode: data,
      isModalOpen: false
    })
  }
  changeJumper = (isCompleted) => {
    if (isCompleted) {
      // this.props.openModel({ isModal: true, editType: false })
      this.setState({
        isModalOpen: true
      })
    }
    else {
      this.setState({
        editMode: !this.state.editMode
      })
    }
  }

  affChangeJumper = () => {
    this.setState({
      editMode: true
    })
  }

  onBlur = () => {
    this.setState({ editMode: false });
  }

  onSelect(packageId, isCompleted, loadId, jump) {
    this.setState({
      editMode: true,
      packageId: packageId
    }, () =>
      this.handleAffChange(this.state.packageId, this.props.loadNum, this.props.jumpId, this.props.type, 'level', isCompleted, loadId, jump)
    )
  }

  render() {
    const { isCompleted, loadId, jump, packageName } = this.props;

    var studentPackages;
    if (jump.tandemPackageId) {
      studentPackages = itemStore.getTandemPackages()
    }
    else {
      studentPackages = jump.type ? itemStore.getStudentPackages().filter(pkg => pkg.type === jump.type) : itemStore.getStudentPackages().filter(pkg => pkg.type === this.props.type.toUpperCase());
    }

    var studentPackageMenu = studentPackages.map(pkg =>
      <a className="dropdown-item" onClick={() => { this.onSelect(pkg._id, isCompleted, loadId, jump) }} >{jump.tandemPackageId ? pkg.item : pkg.name}</a>
    )

    return (
      <React.Fragment>
        {
          !this.state.editMode ?
            // <div className="jumpType-button" onClick={() => this.changeJumper(isCompleted)} style={{ padding: '0 1 0 4px' }}> Aff {this.props.level} </div>
            <div className="funjumpType-button" onClick={() => this.changeJumper(isCompleted)}>{packageName} </div>
            :
            <div className="dropdown-menu1" onBlur={() => { this.setState({ editMode: true }) }}>
              <a className="dropdown-item">Select..</a>
              {studentPackageMenu}
            </div>
        }
        <LoadUpdateModal
          isOpen={this.state.isModalOpen}
          onClick={this.wantsEidtable}
        />
      </React.Fragment>
    );
  }
}

const mstp = state => {
  return {
    state,
    isOpen: state.Reports.completedLoadJumperChangeConfirmModal,
    confirm: state.Reports.confirm,
    editType: state.Reports.editType,
  }
}

const mdtp = dispatch => {
  return {
    openModel: data => dispatch(completedLoadJumperChangeConfirmModal(data)),
    updateCompletedAffJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedAffJump(loadId, cjumpId, jumpPostBody))
  }
}
export default connect(mstp, mdtp)(EditableJumper)


// <LocalForm form="addjumpers" model="addjumpers" getDispatch={dispatch => this.formDispatch = dispatch} onSubmit={(values) => this.handleCreateFunJump(values, this.props.load.loadNum, this.props.jumpId, this.props.type, this.props.label)}
            //   style={{ marginRight: '10px' }}
            // >
        //     <Select
        //     value={this.state.age}
        //     onChange={(selectedOption) => {
        //       this.setState({
        //         editMode: true
        //       }, () =>
        //           this.handleAffChange(selectedOption, this.props.loadNum, this.props.jumpId, this.props.type, 'level', isCompleted, loadId, affJump)
        //       )
        //     }
        //     }
        //     open
        //     onClose={(e) => { null }}
        //   >
        //     <MenuItem disabled value="">
        //       <em>select</em>
        //     </MenuItem>
        //     <MenuItem value={1}>1</MenuItem>
        //     <MenuItem value={2}>2</MenuItem>
        //     <MenuItem value={3}>3</MenuItem>
        //     <MenuItem value={4}>4</MenuItem>
        //     <MenuItem value={5}>5</MenuItem>
        //     <MenuItem value={6}>6</MenuItem>
        //     <MenuItem value={7}>7</MenuItem>
        //   </Select>
        //   <FormGroup>
        //     <Label for="exampleSelect">Select</Label>
        //     <Input type="select" name="select"
        //       onChange={(selectedOption) => {
        //         this.setState({
        //           editMode: true,
        //           level: selectedOption.target.value
        //         }, () =>
        //           this.handleAffChange(this.state.level, this.props.loadNum, this.props.jumpId, this.props.type, 'level', isCompleted, loadId, affJump)
        //         )
        //       }}>
        //       <option>1</option>
        //       <option>2</option>
        //       <option>3</option>
        //       <option>4</option>
        //       <option>5</option>
        //       <option>6</option>
        //       <option>7</option>
        //     </Input>
        //   </FormGroup>
        // </LocalForm>