
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { LocalForm } from 'react-redux-form';
import AdminModal from './customModal';
import itemStore from '../../../stores/item-store';
import categoryStore from '../../../stores/category-store'

class RemovePlane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            jumpType: null,
            load: props.load,
        };
    }

    toggleModal = () => {
        //alert('click');
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            jumpType: null
        });
    }
    handleSubmit = () => {
        if (this.props.category) {
            categoryStore.deleteCategory(this.props.item).then(r => {
                this.props.getlist()
                this.props.toast.success("category deleted successfully")
            })
                .catch(err => {
                    this.props.toast.error("Something went wrong!")
                })
        } else {
            const { itemType } = this.props;
            const { _id, item } = this.props.item || {};
            this.toggleModal();
            if (_id != undefined) {
                if (itemType === "student package") {
                    itemStore.deleteStudentPackage(_id);
                }
                else if (itemType === "altitude") {
                    // console.log("ID: ", _id)
                    let payload = { itemId: _id }
                    itemStore.deleteAltitude(payload);
                }
                else {
                    itemStore.deleteItem(_id, item || this.props.item).then(res => {
                        console.log(this.props.item, "item item item")
                        if (this.props.item.category == "tandemRig" || this.props.item.category == 'sportRig' || this.props.item.category == 'studentRig') {
                            this.props.onSuccess()
                        }
                    })
                }
            }
        }
    }

    render() {
        const { item, category } = this.props.item || {};
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                >
                    <p>Are you Sure you want to delete {item || category} ! </p>
                    <LocalForm onSubmit={(values) => this.handleSubmit()}>
                        <Row className="form-group">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >Submit</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">Cancel</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                {this.props.item.category !== 'staff' && <span onClick={this.toggleModal}>
                    <i className="fa fa-trash"></i>
                </span>}
            </React.Fragment>
        );
    }
}

export default RemovePlane

