import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Link } from 'react-router-dom';
// import HamburgerMenu from 'react-hamburger-menu';

import './navcontainer.css';
import SmallModal from './SmallModal';
import SettingsMenu from './SettingMenu';
// import SettingsMenu from './SettingsTab'

class NavContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        }
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                isModalOpen: false,
            });
        }
    }
    handleMenuClick = (type) => {

        this.setState({ isModalOpen: !this.state.isModalOpen })
    }
    toggleModal = (isModalOpen) => {
        this.setState({ isModalOpen })
    }

    render() {
        let { isModalOpen } = this.state;

        return (
            <div className="navigation-container">
                <button className="setting-btn" onClick={() => this.handleMenuClick('setting')}>
                    <i className="fa fa-cog" />
                </button>
                <SmallModal isModalOpen={isModalOpen}>
                    <SettingsMenu toggleModal={this.toggleModal} />
                </SmallModal>
            </div>
        )
    }
}
export default withRouter(NavContainer);