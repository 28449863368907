/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import jumperStore from '../../../stores/jumpers-store';
import loadStore from '../../../stores/loads-store';
import axios from 'axios';
import Select from 'react-select';
import AdminModal from './customModal';

class EditPlanes extends Component {

    constructor(props) {

        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
            error: {}
        }
    }

    toggleModal(e) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    handleSubmit(values, id, plane, capacity, name, price, hopnpopPrice, color, usePlanePrice) {
        //console.log("in handle submit load"+ values);
        this.toggleModal();
        var payload = {};
        if (values != undefined) {
            if (values.capacity != undefined) {
                payload.capacity = parseInt(values.capacity);
                payload.price = Number(values.price);
            }
            else {
                payload.capacity = parseInt(capacity);
                payload.price = Number(price);
            }

            if (values.plane != undefined) {
                payload.plane = values.plane;
                payload.name = values.name;
            }
            else {
                payload.plane = plane;
                payload.name = name;
            }

            if (values.hopnpopPrice != undefined) {
                payload.hopnpopPrice = Number(values.hopnpopPrice);
            }
            else {
                payload.hopnpopPrice = hopnpopPrice;
            }

            if (values.usePlanePrice != undefined) {
                payload.usePlanePrice = values.usePlanePrice ? true : false;
            }
            else {
                payload.usePlanePrice = usePlanePrice;
            }



            if (values.plane != undefined) {
                payload.plane = values.plane;
                payload.name = values.name;
            }
            else {
                payload.plane = plane;
                payload.name = name;
            }


            if (values.icao24 !== undefined) {
                payload.icao24 = values.icao24;
            }
            if (values.tail !== undefined) {
                payload.tail = values.tail;
            }
            if (values.color !== undefined) {
                payload.color = values.color;
            }
            loadStore.UpdateLoadPlane(id, payload);
        }
    }


    render() {

        const { _id = '', plane = '', name = '', capacity = '', price = '', hopnpopPrice = '', usePlanePrice = false, icao24 = '', tail = '', color='' } = this.props.plan || {};
        console.log(" this.props.plan==>",  this.props.plan)
        return (
            <React.Fragment>
                <AdminModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Edit Plane`}
                >
                    <LocalForm onSubmit={(values) => this.handleSubmit(values, _id, plane, capacity, name, price, color)}>
                        <Row className="form-group">
                            <Label htmlFor="plane" md={3}>Plane</Label>
                            <Col md={9}>
                                <Control.input name="plane" model=".plane" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={plane} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="plane" md={3}>Name</Label>
                            <Col md={9}>
                                <Control.input name="name" model=".name" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} defaultValue={name} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="capacity" md={3}>Capacity</Label>
                            <Col md={9}>
                                <Control.input name="capacity" model=".capacity" className="form-control" defaultValue={capacity}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="price" md={3}>Price</Label>
                            <Col md={9}>
                                <Control.input name="price" model=".price" className="form-control" defaultValue={price}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="hopnpopPrice" md={3}>HopNPop Price</Label>
                            <Col md={9}>
                                <Control.input name="hopnpopPrice" model=".hopnpopPrice" className="form-control" defaultValue={hopnpopPrice}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="usePlanePrice" md={3}>special event</Label>
                            <Col md={9} style={{ marginTop: '7px' }}>
                                <Control.checkbox name="usePlanePrice" model=".usePlanePrice"
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    defaultValue={usePlanePrice}
                                    autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>Color</Label>
                            <Col md={9}>
                                <Control.input type="color" id="color" name="color" model=".color" className="form-control"  defaultValue={color}  
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>icao24 code</Label>
                            <Col md={9}>
                                <Control.input name="icao24" model=".icao24" className="form-control" defaultValue={icao24}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Label htmlFor="name" md={3}>tail number</Label>
                            <Col md={9}>
                                <Control.input name="tail" model=".tail" className="form-control" defaultValue={tail}
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off" />

                            </Col>
                        </Row>

                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 10 }}>
                                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </AdminModal>
                <span onClick={this.toggleModal}>
                    <i className="fa fa-edit"></i>
                </span>
            </React.Fragment>
        );
    }
}

export default EditPlanes
