import React, { Fragment } from 'react';

const MainImage = () => {
    const url = '';

    return (
        <Fragment>
            <div className="text-center" style={{ padding: "15px 0" }}>
                <img
                    src={window.ENV?.logoUrl}
                    style={{ width: "100%" }}
                />
            </div>
        </Fragment>
    );
};

export default MainImage;