import loadsStore from "../../../stores/loads-store"

const multiCopyPasteClick = async (Value, loadNum1) => {
    for (const [ind, item] of Value.entries()) {
        if (item.type === 'tandemJump' && item.tandemPackageName) {
            await loadsStore.createTandemJump(loadNum1, item).then(r => {
                if (r) {
                    if (loadsStore.cut) {
                        const { loadNum } = loadsStore.cutValue;
                        loadsStore.cancelTandemJump(loadNum, item.jumpId)
                        if (ind === (Value.length - 1)) {
                            loadsStore.cut = false;
                            loadsStore.clearDraggable()
                        }
                    }
                    else if (ind === (Value.length - 1)) {
                        loadsStore.clearDraggable()
                    }
                } else if (ind === (Value.length - 1)) {
                    loadsStore.clearDraggable()
                }
            })
        } else if (item.type === 'funJump') {
            delete item.type
            //delete item.copy
            await loadsStore.createFunJump(loadNum1, item).then(r => {
                if (r) {
                    if (loadsStore.cut) {
                        const { loadNum } = loadsStore.cutValue;
                        loadsStore.cancelFunJump(loadNum, item.jumpId)
                        if (ind === (Value.length - 1)) {
                            loadsStore.cut = false;
                            loadsStore.clearDraggable()
                        }
                    } else if (ind === (Value.length - 1)) {
                        loadsStore.clearDraggable()
                    }
                } else if (ind === (Value.length - 1)) {
                    loadsStore.clearDraggable()
                }
            })
        } else if (item.type === 'affJump') {
            await loadsStore.createAffJump(loadNum1, item).then(r => {
                if (r) {
                    if (loadsStore.cut) {
                        const { loadNum } = loadsStore.cutValue;
                        loadsStore.cancelAffJump(loadNum, item.jumpId)
                        if (ind === (Value.length - 1)) {
                            loadsStore.cut = false;
                            loadsStore.clearDraggable()
                        }
                    } else if (ind === (Value.length - 1)) {
                        loadsStore.clearDraggable()
                    }
                } else if (ind === (Value.length - 1)) {
                    loadsStore.clearDraggable()
                }
            })

        } else if (item.type === 'coachJump') {
            await loadsStore.createCoachJump(loadNum1, item).then(r => {
                if (r) {
                    if (loadsStore.cut) {
                        const { loadNum } = loadsStore.cutValue;
                        loadsStore.cancelCoachJump(loadNum, item.jumpId)
                        if (ind === (Value.length - 1)) {
                            loadsStore.cut = false;
                            loadsStore.clearDraggable()
                        }
                    } else if (ind === (Value.length - 1)) {
                        loadsStore.clearDraggable()
                    }
                } else if (ind === (Value.length - 1)) {
                    loadsStore.clearDraggable()
                }
            })
        }
    }
}

export default multiCopyPasteClick;
