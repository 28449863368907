import React, { Component } from 'react';

import Hr from './customHr';
import Calendar from 'react-calendar';
import AdminModal from '../../admin/modals/customModal';
import { Col, Row } from 'reactstrap';
import Api from '../../../services/reservationapi';
import SlotSelector from './slotSelector';
import reservationStore from '../../../stores/reservation-store';
import schedulesStore from '../../../stores/schedule-store';
import { ClipLoader } from 'react-spinners';
import * as moment from 'moment';
import swal from 'sweetalert'


const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
      `;


class ChangeSlot extends Component {
  messages = {
    NoSlotAvilable: (
      <div className="alert alert-warning">No Slots available for this day</div>
    )
  };

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      isModalOpen: false,
      slotDates: [],
      message: '',
      oldSlotId: this.props.oldSlotId,
      newSlotId: '',
      isloading: false,
      error: '',
      allowedDates: []
    }
  }

  onChange = (date) => {
    this.setState({ date: date });

    var monthString = "";
    var dayString = ""
    if ((date.getMonth() + 1) < 10) {
      monthString = "0" + (date.getMonth() + 1);
    }
    else {
      monthString = date.getMonth() + 1;
    }

    if (date.getDate() < 10) {
      dayString = "0" + date.getDate();
    }
    else {
      dayString = date.getDate();
    }

    var dateString = (1900 + date.getYear()) + "-" + monthString + "-" + dayString;

    // console.log(dateString);
    var theDate = new Date( // necessary step since default Javascript date was
      // having one day offset
      date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
    );
    var parsedDate = theDate.toISOString().slice(0, 10);
    // console.log(parsedDate);
    Api.GetTimeslots(dateString).then(this.processSlotResponse);
  }

  componentWillMount() {
    var d = new moment();
    var startMonth = d.clone().startOf('month').format("YYYY-MM-DD");
    var endMonth = d.clone().endOf('month').format("YYYY-MM-DD");
    console.log('changeslot 82 be causing some problems');
  }

  updateAllowedDates = (r) => {
    window.debugthis = r.data;
    // console.log(r.data);
    //var allowedDates = r.data.map(elem => elem.datetime.split('T')[0]);
    var allowedDates = r.data.filter(x => x.isBlackedOut === false).map(elem => elem.datetime.split('T')[0]);

    // console.log(allowedDates);
    this.setState({ allowedDates: allowedDates });
  }


  processSlotResponse = (r) => {
    // console.log(r.data);
    this.setState({ slotDates: r.data });
    r.data.length === 0 &&
      this.setState({ message: this.messages.NoSlotAvilable });
  };


  /*
  renderSlotSelector = () => {
    const { newSlotId } = this.state;
    if (this.state.slotDates.length > 0) {
      return this.state.slotDates.map((slot) => (
        <div key={slot._id}>
          <SlotSelector
            selected={newSlotId === slot._id}
            handleClick={this.handleClick}
            slot={slot}
          />
        </div>
      ));
    } else {
      return this.state.message || this.messages.Initial;
    }
  };*/

  renderSlotSelector = (isTandem) => {
    const { newSlotId } = this.state;
    var tandemSlots;
    var affSlots;
    var slots;
    console.log(this.state.slotDates, "<======this.state.slotDates")
    if (isTandem) {
      slots = this.state.slotDates.filter(slot => slot.eventCategory === "Tandem");
    }
    else {
      slots = this.state.slotDates.filter(slot => slot.eventCategory !== "Tandem");
    }
    if (slots.length > 0) {
      return slots.map((slot) => (
        <div key={slot._id}>
          <SlotSelector
            selected={newSlotId === slot._id}
            handleClick={this.handleClick}
            slot={slot}
          />
        </div>
      ));
    } else {
      return this.state.message;
    }
  };

  handleClick = (slot, eventName) => {
    // console.log('selected', slot._id);
    let name = this.props.eventName
    if (!!this.props.eventCategory) {
      name = this.props.eventCategory
    }

    let category = slot.eventName
    if (!!slot?.eventCategory) category = slot?.eventCategory
    if (name !== category) {
      swal({
        title: "Sorry",
        text: "Cannot change package",
        icon: "error",
        dangerMode: true,
      })
    } else {
      this.setState({ newSlotId: slot._id });
    }
  }

  toggleModal = (e) => {
    var d = new moment();
    var startMonth = d.clone().startOf('month').format("YYYY-MM-DD");
    var endMonth = d.clone().endOf('month').format("YYYY-MM-DD");
    Api.getMonthlySlots({ start: startMonth, end: endMonth }).then(this.updateAllowedDates);
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  updateAvailableDatesSlots = (value) => {
    var d = new moment(value.activeStartDate);
    var startMonth = d.clone().startOf('month').format("YYYY-MM-DD");
    var endMonth = d.clone().endOf('month').format("YYYY-MM-DD");
    console.log('changeslot 165 be causing some problems');

    Api.getMonthlySlots({ start: startMonth, end: endMonth }).then(this.updateAllowedDates)
  }

  checkDateAvailability = ({ date }) => {
    let d = new moment(date);
    return (this.state.allowedDates.indexOf(d.format("YYYY-MM-DD")) == -1)
  }



  handleSubmit = () => {
    this.setState({
      isloading: true
    })

    var reservationBody = {
      newslotId: this.state.newSlotId,
      oldslotId: this.state.oldSlotId,
      id: this.props.reservationId
    }

    // console.log(reservationBody);
    if (this.state.newSlotId !== '') {
      reservationStore.ChangeReservation(reservationBody).then((res) => {
        // console.log(res === true);
        this.props.allSucess && this.props.allSucess()
        if (res == true) {
          const { date, oldSlotId } = this.state;

          // var theDate = new Date(
          //   date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
          // );
          // var parsedDate = theDate.toISOString().slice(0, 10);

          var parsedDate = this.props.currentDate;

          var payload = {
            date: parsedDate,
            id: oldSlotId
          }

          // console.log("ReservationPayload", payload);
          //schedulesStore.fetchData(parsedDate);
          schedulesStore.fetchReservation(payload);

          this.setState({
            isModalOpen: false,
            isloading: false,
            error: ''
          })
        }
        else {
          this.setState({
            isloading: false,
            error: 'Something went wrong, Failed to change Reservation.'
          })
          setTimeout(() => {
            this.setState({ error: '' });
          }, 2000)
        }
      })
    }
    else {
      this.setState({
        isloading: false,
        error: 'Please Select New Slot Id.'
      })

      setTimeout(() => {
        this.setState({ error: '' });
      }, 2000)
    }
  }

  render() {
    const { error } = this.state;

    return (
      <React.Fragment>
        <AdminModal
          isModalOpen={this.state.isModalOpen}
          title={`Change Slot`}
        >

          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>

          <div>

            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 12 }}>
                <Calendar
                  onChange={this.onChange}
                  value={this.state.date}
                  minDate={new Date()}
                  minDetail="month"
                  onActiveDateChange={this.updateAvailableDatesSlots}
                  tileDisabled={this.checkDateAvailability}
                  calendarType="US"
                />
              </Col>
            </Row>

            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 12 }}>
                <div className="col-md-12 p-4 containerPadding">
                  {this.state.slotDates.length > 0 && (
                    <div className="row">
                      <hr />
                      <div className="col-md-9">
                        <span className="customHeading">SELECT SLOT</span>
                      </div>
                    </div>
                  )}
                  {
                    this.props.eventCategory.toLowerCase().indexOf('tandem') != -1 &&
                    <button className="btn btn-default fontNoColor" style={{ background: '#c6dee6', color: '#013e8b' }}>
                      Tandem Jumps
                    </button>
                  }

                  {this.props.eventCategory.toLowerCase().indexOf('tandem') != -1 && this.renderSlotSelector(true)}
                  <Hr />
                  {
                    this.props.eventCategory.toLowerCase().indexOf('aff') != -1 &&
                    <button className="btn btn-default fontNoColor" style={{ background: '#c6dee6', color: '#013e8b' }}>
                      AFF First Jump Course
                    </button>
                  }
                  {this.props.eventCategory.toLowerCase().indexOf('aff') != -1 && this.renderSlotSelector(false)
                  }


                </div>
              </Col>
            </Row>


            <Row className="form-group m-t-2-rem">

              <Col md={{ size: 10 }}>
                {
                  error !== '' &&
                  <span className="text-danger error-validation" style={{ fontSize: '16px' }}>{this.state.error}</span>
                }
              </Col>

              <Col md={{ size: 10 }}>
                <button className="btn btn-bg lightColor createButton" type="button" onClick={this.handleSubmit}>SUBMIT</button>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>

            </Row>

          </div>
        </AdminModal>
        {/*
          <button className="btn customButton rightButton" style={{ borderRadius: '100px', width: "94%" }} onClick={this.toggleModal}>
            Change Slots
          </button>
        */}
       { (this.props.suspendReservation) ? "" : <span
        onClick={this.toggleModal}
         style={this.props.isUseButton ? {} : { position: 'absolute', cursor: 'pointer', color: 'blue', right: '33px', top: '25px', marginRight: "20px" }}>
          <i className="fa fa-edit"></i>
        </span>}
      </React.Fragment>
    );
  }
}

export default ChangeSlot;
