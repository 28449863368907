import React, { Component } from 'react'
import { Table } from 'reactstrap'
import CreateStudentPackage from '../modals/createStudentPackage';
import RemoveItem from '../modals/removeItem';
import itemStore from '../../../stores/item-store';
import swal from 'sweetalert';
export default class StudentPackages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentPack: [],
            studentPackage: this.props.studentPackages
        }
    }
    componentDidMount = () => {
        let { studentPackages, checkForNegatives } = this.props;
        studentPackages = studentPackages.map((pack, index) => {
            return { ind: index + 1, ...pack }
        })
        this.setState({ studentPackage: studentPackages }, () => {
        })
    }

    componentWillReceiveProps(nextProps) {
        let { studentPackages } = nextProps;
        studentPackages = studentPackages.map((pack, index) => {
            return { ind: index + 1, ...pack }
        })
        this.setState({ studentPackage: studentPackages }, () => {
        })
    }
    timer;
    handleOrderChange = (event, ind) => {
        let studentPack = this.state.studentPackage;
        studentPack[ind].ind = event.target.value
        this.setState({ studentPackage: studentPack })
        if (!event.target.value) {
            return
        }
        const eValue = event.target.value;
        let payload = { newIndex: Number(eValue), oldIndex: ind };
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.handleResponse(payload, eValue);
        }, 2000)
    };

    handleResponse = async (payload, eValue) => {
        if (eValue <= this.state.studentPackage.length) {
            const response = await itemStore.updateOrderStudentPackage(payload);
            if (response) {
                swal({
                    title: "Success",
                    text: "Student Packages Updated Successfully",
                    icon: "success",
                    dangerMode: false,
                });
                await itemStore.loadGrouponPackages();
            }
        }
        else {
            swal({
                title: "Warning",
                text: "You cannot enter order greater than maximum order range",
                icon: "error",
                dangerMode: true,
            })
        }
    };

    renderStudentPackages = () => {
        let { checkForNegatives } = this.props;
        if (this.state.studentPackage != undefined) {
            return this.state.studentPackage?.map((item, index) => {
                return (
                    <tr key={`planRow${index}`}>
                        <th className="orderCol" scope="row" data-id={item._id}><input className="orderField" value={item.ind} onChange={(e) => { this.handleOrderChange(e, index) }} /></th>
                        <td>{item.name}</td>
                        <td>{item.type}</td>
                        <td>{item.calenderEvent}</td>
                        <td>{checkForNegatives(item.price)}</td>
                        <td>{checkForNegatives(item.prepaidPrice)}</td>
                        <td>{checkForNegatives(item.studentPrice)}</td>
                        <td>{checkForNegatives(item.studentPrepaidPrice)}</td>
                        <td>{checkForNegatives(item.instructor1Pay)}</td>
                        <td>{checkForNegatives(item.instructor2Pay)}</td>
                        <td>{item.isBookable ? 'yes' : 'no'}</td>
                        <td>{item.altitude}</td>
                        <td>{item.isTaxable ? 'yes' : 'no'}</td>
                        <td>{item.description}</td>
                        <td >
                            <CreateStudentPackage isEdit={true} item={item} />&nbsp;&nbsp;
                            <RemoveItem item={item} itemType="student package" />
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    render() {
        return (
            <Table>
                <thead className="main-heading">

                    <tr>
                        <th><CreateStudentPackage /> Order</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Calender Event</th>
                        <th>Price</th>
                        <th>Prepaid price</th>
                        <th>Student price</th>
                        <th>Student prepaid price</th>
                        <th>Instructor 1 pay</th>
                        <th>Instructor 2 pay</th>
                        <th>Bookable</th>
                        <th>Altitude</th>
                        <th>Tax</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                    <tr className="priceHeading"><td colSpan="3">Student Packages</td></tr>
                    {this.renderStudentPackages()}
                </tbody>
            </Table>
        )
    }
}
