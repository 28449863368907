import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { withRouter, Link } from 'react-router-dom'

import jumperStore from '../../stores/jumpers-store';
import reportStore from '../../stores/report-store';
import loadStore from '../../stores/loads-store';
import reservationStore from '../../stores/reservation-store';
import CreateLoad from '../loadManager/modals/createLoad';
import SearchJumper from '../loadManager/modals/searchJumper';
import MainImage from './MainImage';
import { MENUITEMS } from '../constant/menu';
import Setting from '../loadManager/subComponents/NavHamburger/SettingsTab'

import '../css/App.css'
import './leftmenu1.css';

class LeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            pilotOptions: [],
            planeOption: [],
            tandemStudentOptions: [],
            jumperOptions: [],
            isCreateLoad: false,
            planes: [],
            mainmenu: MENUITEMS,
            isModalOpen: false,
        }
        this.timeOut = null;
        this.loadEvents = [
            { name: loadStore.getEventPrefix('planesLoaded'), func: this.handlePlanesLoaded },
            { name: loadStore.getEventPrefix('tandemStudentCreated'), func: this.handleTandemStudentCreated }
        ];

        this.jumperEvents = [
            { name: jumperStore.getEventPrefix('pilotsLoaded'), func: this.handlePilotsLoaded },
            { name: jumperStore.getEventPrefix('tandemStudentCreated'), func: this.handleTandemStudentCreated },
            { name: jumperStore.getEventPrefix('tandemStudentsLoaded'), func: this.handleTandemStudentCreated },
            { name: jumperStore.getEventPrefix('jumperCreated'), func: this.handleJumperCreated },
            { name: jumperStore.getEventPrefix('jumpersLoaded'), func: this.handleJumperCreated }
        ];
    }

    handleTandemStudentCreated = (data) => {
        if (data) {
            var tandemStudents = jumperStore.getTandemStudentOptions();
            this.setState({ tandemStudentOptions: tandemStudents });
        }
    }

    handleJumperCreated = (data) => {
        this.setState({ jumperOptions: jumperStore.getJumperOptions() });
    }

    handlePlanesLoaded = (planeOption) => {
        var option = [];
        planeOption.forEach(element => {
            option.push({ label: element.plane })
        })
        this.setState({
            planeOption: option,
            planes: planeOption
        });
    }
    handlePilotsLoaded = (pilotOptions) => {
        this.setState({
            pilotOptions: pilotOptions,
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentDidUpdate() {
        let isAuth = localStorage.getItem("isAuth");
        let authToken = localStorage.getItem('Auth-token')
        if (isAuth !== 'true' && authToken === null) {
            this.props.history.push('/login')
        }
    }

    componentWillMount = () => {
        this.loadEvents.forEach((evnt) => loadStore.on(evnt.name, evnt.func));
        this.jumperEvents.forEach((evnt) => jumperStore.on(evnt.name, evnt.func));

        this.setState({
            pilotOptions: jumperStore.getData('pilotOptions'),
            planeOption: loadStore.getPlanesOptions(),
            tandemStudentOptions: jumperStore.getTandemStudentOptions(),
            jumperOptions: jumperStore.getJumperOptions(),
            planes: loadStore.getPlanes()
        });
    }

    componentWillUnmount = () => {
        this.loadEvents.forEach((evnt) => loadStore.removeListener(evnt.name, evnt.func));
        this.jumperEvents.forEach((evnt) => jumperStore.removeListener(evnt.name, evnt.func));
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = document.querySelector('#setting-modal')
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                isModalOpen: false,
            });
        }
    }


    handleClick = () => {
        jumperStore.clearState();
        reportStore.clearState();
        loadStore.clearState();
        reservationStore.clearState();
        localStorage.clear();
        this.props.history.push("/login")
    }

    toggle = () => {
        this.setState(prevState => ({
            isCreateLoad: !prevState.isCreateLoad
        }));
    }

    onMouseEnter = () => {
        clearTimeout(this.timeOut);
        if (this.state.planes.length == 0) {
            loadStore.loadPlanes()
                .then(res => {
                    this.setState({ isCreateLoad: true });
                })
        }
        else {
            this.setState({ isCreateLoad: true });
        }
        //this.handlePlanesLoaded();
    }

    onMouseLeave = () => {
        this.timeOut = setTimeout(() => {
            this.setState({ isCreateLoad: false })
        }, 1000)
    }

    getOptions = () => {
        var that = this;
        var planeList = this.state.planes.map((value, index) => {
            console.log(value,"value54")
            // return <DropdownItem onClick={()=>{that.handleSubmitWithplane(value)}}>{value.name}</DropdownItem>
            return <li onClick={() => { this.handleSubmitWithplane(value) }}>{value.plane}-{value.name}</li>
        });
        return planeList;
    }

    handleSubmitWithplane(plane) {
        //alert(JSON.stringify(plane));
        console.log(plane.color,"planeplane")
        this.onMouseLeave();
        loadStore.displayLoader = true;
        var pilotOptions = this.props.pilotOptions;
        var payload = {};
        var planes = loadStore.getPlanes();
        var planeDisplaySetting = loadStore.getPlaneDisplaySetting();
        console.log(plane);
        if (planeDisplaySetting.value === "plane type") {
            payload.plane = plane.plane;
            payload.color = plane.color;
            payload.capacity = plane.capacity?plane.capacity:20;
            payload.registrationNumber = plane.tail;
        }
        else if (planeDisplaySetting.value === "plane name") {
            payload.plane = plane.name;
            payload.color = plane.color;
            payload.capacity = plane.capacity?plane.capacity:20;
            payload.registrationNumber = plane.tail;
        }


        if (pilotOptions && pilotOptions[pilotOptions.length - 1] !== undefined) {
            payload.pilotId = pilotOptions[pilotOptions.length - 1].value._id;
        }

        loadStore.createLoad(payload);
    }

    toggletNavActive = (item) => {
        console.log(item, "<<<<<<<<<<<sub menu item")
        if (!item.active) {
            MENUITEMS.forEach(a => {
                if (MENUITEMS.includes(item))
                    a.active = false
                if (!a.children) return false
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                    if (!b.children) return false
                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false
                        }
                    })
                })
            });
        }
        item.active = !item.active
        this.setMainMenu(MENUITEMS)
    }
    setMainMenu = (MENUITEMS) => {
        this.setState({
            mainmenu: MENUITEMS
        })
    }
    handleReportsClick = (item) => {
        this.props.dataChangeHandler(item);
    }
    addArrow = (active) => {
        if (active) {
            return <i className="fa fa-angle-right pull-right down-arrow"></i>
        }
        else {
            return <i className="fa fa-angle-right pull-right right-arrow"></i>
        }
    }
    toggleModal = (isModalOpen) => {
        this.setState({ isModalOpen })
    }

    // handleIsInline = (bool) => {
    //     this.setState({ checked: bool})
    // }

    renderChild = (childrenItem) => {

        if (!window.ENV?.arizona && childrenItem.path === '/waitlist') {
            return
        } else if (!window.ENV?.arizona && (childrenItem.path === '/admin/national2021' || childrenItem.path === '/admin/mundial2022')) {
            return
        } else {
            return <Link
                to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                className={childrenItem.active ? 'active' : ''}
                onClick={() => this.toggletNavActive(childrenItem)}
            >
                {childrenItem.icon &&
                    this.addArrow(childrenItem.active)
                }
                <i className="fa fa-circle"></i>
                {childrenItem.title}
            </Link>
        }
    }
    render() {
        return (
            <div className="page-sidebar">
                <div className="sidebar custom-scrollbar">
                    <div style={{ display: "flex", justifyContent: 'space-between' }} >
                        <div className="logout-btn" >
                            <p style={{ cursor: 'pointer' }} onClick={this.handleClick}> Logout <i className="fa fa-sign-out" /> </p>
                        </div>
                        {this.props.showSettings &&
                            <div style={{ cursor: 'pointer' }} >
                                <i className="fa fa-cog" onClick={() => this.setState({ isModalOpen: !this.state.isModalOpen })} />
                            </div>
                        }
                    </div>
                    {this.state.isModalOpen &&
                        <Setting
                            getSettingsData={this.props.getSettingsData}
                            settingsObj={this.props.settingsObj}
                            toggleModal={this.toggleModal}
                            isInlineMode={this.props.isInlineMode}
                            isInline={this.props.isInline}
                            isDisplayPlaneNameLeft={this.props.isDisplayPlaneNameLeft}
                            displayPlaneNameLeftMode={this.props.displayPlaneNameLeftMode}
                        />
                    }
                    <SearchJumper
                        options={this.state.jumperOptions}
                        tandems={false} mode="async"
                        placeholder="Search jumper"
                    />
                    <SearchJumper
                        options={this.state.tandemStudentOptions}
                        tandems={true}
                        placeholder="Search tandem student"
                    />
                    <MainImage />

                    <ul className="sidebar-menu" id="myDIV">
                        {
                            MENUITEMS.map((menuItem, i) =>
                                <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                                    {(menuItem.sidebartitle) ?
                                        <div className="sidebar-title">{menuItem.sidebartitle}</div>
                                        : ''
                                    }
                                    {(menuItem.type === 'sub') ?
                                        <a className="sidebar-header"
                                            onClick={() => this.toggletNavActive(menuItem)}
                                        >
                                            <menuItem.icon />
                                            {menuItem.title}
                                            {this.addArrow(menuItem.active)}
                                        </a>
                                        : ''}
                                    {(menuItem.type === 'link') ?
                                        <Link
                                            to={`${process.env?.PUBLIC_URL}${menuItem.path}`}
                                            className={`sidebar-header ${menuItem.active ? 'active' : ''}`}
                                            onClick={() => this.toggletNavActive(menuItem)}
                                        >
                                            <menuItem.icon />
                                            {menuItem.title}
                                            {this.addArrow(menuItem.active)}
                                        </Link>
                                        : ''}
                                    {(menuItem.type === 'exteral_link') ?
                                        <a href={menuItem.path} className={`sidebar-header ${menuItem.active ? 'active' : ''}`} >
                                            <menuItem.icon /><span>{menuItem.title}</span></a>
                                        : ''}
                                    {menuItem.children ?
                                        <ul
                                            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                            style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                        >
                                            {menuItem.children.map((childrenItem, index) =>
                                                <li key={index} className={childrenItem.subChildren ? childrenItem.active ? 'active' : '' : ''}>
                                                    {(childrenItem.type === 'sub') ?
                                                        <a href="#javascript"
                                                            onClick={() => this.toggletNavActive(childrenItem)}
                                                        >
                                                            <i className="fa fa-circle"></i>
                                                            {childrenItem.title}
                                                            <i className="fa fa-angle-right pull-right"></i></a>
                                                        : ''}

                                                    {(childrenItem.type === 'link') ?
                                                        this.renderChild(childrenItem)
                                                        : ''}

                                                    {(childrenItem.type === 'functional') ?
                                                        <p className="func-tag" onClick={() => this.handleReportsClick(childrenItem.path)}>
                                                            <i className="fa fa-circle"></i>
                                                            {childrenItem.title}
                                                        </p> : null
                                                    }
                                                    {childrenItem.subChildren &&
                                                        <ul
                                                            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                                            style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                                        >
                                                            {childrenItem.subChildren.map((subchildren, index) =>
                                                                <li key={index} className={subchildren.children ? subchildren.active ? 'active' : '' : ''}>

                                                                    {(subchildren.type === 'link') ?
                                                                        this.renderChild(subchildren)
                                                                        : ''}

                                                                </li>
                                                            )}
                                                        </ul>
                                                    }
                                                </li>
                                            )}
                                        </ul>
                                        : ''}
                                </li>
                            )
                        }
                        <li className="create-load-btn" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                            <CreateLoad pilotOptions={this.state.pilotOptions} planeOption={this.state.planeOption} />
                            {
                                this.state.isCreateLoad &&
                                <ul className="menu-option" onMouseEnter={this.onMouseEnter}>
                                    {this.getOptions()}
                                </ul>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(LeftMenu);
