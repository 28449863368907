import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function CreateStudentField({ packagesList, createJump, disabled, type, loadsToShow }) {
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);
    let itemClass = ''
    let menuClass = ''
    if (type === 'AFF') {
        if (loadsToShow > 3) {
            itemClass = 'reducePadding';
            menuClass = 'aff-menu'
        }
        else {
        }
    } else {
        menuClass = 'iad-menu'
    }
    const {fontSize} = JSON.parse(localStorage.getItem('jumperDetails'))
    return (

        <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="jump-create select-field"
        >
            <DropdownToggle caret disabled={disabled}>
                <i className="fa fa-plus" /> {type}
            </DropdownToggle>
            <DropdownMenu className={menuClass} >
                {packagesList.map((p, i) => {
                    if (p.type === type) {
                        return <DropdownItem className={itemClass} style={{fontSize: `${fontSize}px`}} onClick={() => createJump(p)} key={`${i}_createStudentButtons`}>
                            <i className="fa fa-plus" />
                            <strong>{" "}{p.name}</strong>
                        </DropdownItem>
                    }
                })}
            </DropdownMenu>
            {/* </div> */}
        </ButtonDropdown>
    );
}
export default CreateStudentField;