import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, TableRow, TableCell, TableHead } from '@material-ui/core';
import reportStore from '../../../../stores/report-store';
import { styles } from './DataTableStyle';
import { connect } from 'react-redux';
import * as constants from '../../../constant/reports';
import { ClipLoader } from 'react-spinners';
import { NONE } from 'apisauce';
import moment from 'moment';
import DonutLoader from '../../../loadManager/DonutLoader';


class InvoiceByCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: [],
            Isdata: false,
            dateRange: [],
            isloading: false
        }
        this.handleNewActivity = this.handleNewActivity.bind(this);
        this.loadEvents = [
            { name: reportStore.getEventPrefix('loaderActivity'), func: this.handleNewActivity },
        ];
    }

    componentWillUnmount = () => {
        this.loadEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }

    handleNewActivity = (activityString) => {
        this.setState({
            isloading: false,
        })
    }

    checkForNegatives = (tax, price, currentClass, salesTax) => {
        let { classes } = this.props;

        let negativeStyle = `${currentClass} ${classes.tableCellNegative}`;
        if (window.ENV?.showGreenNegative) {
            negativeStyle = `${currentClass} ${classes.tableCellNegativeGreen}`;
        }
        let taxCell = '';
        let salesTaxCell = '';
        console.log('line 43 in check for negatives');
        if (salesTax !== null && salesTax !== undefined) {
            salesTaxCell = (salesTax < 0) ?
                {
                    style: negativeStyle,
                    //value: `- $${Math.abs(tax).toFixed(2)}`
                    value: `( $${Math.abs(salesTax).toFixed(2)})`,
                } :
                {
                    style: currentClass,
                    //value: ` $${tax.toFixed(2)}`
                    value: ` $${salesTax.toFixed(2)}`
                };
        }

        if (tax !== null && tax !== undefined) {
            taxCell = (tax < 0) ?
                {
                    style: negativeStyle,
                    //value: `- $${Math.abs(tax).toFixed(2)}`
                    value: `( $${Math.abs(tax).toFixed(2)})`,
                } :
                {
                    style: currentClass,
                    //value: ` $${tax.toFixed(2)}`
                    value: ` $${tax.toFixed(2)}`
                };
        }

        let priceCell = ''
        if (price !== null && price !== undefined) {
            priceCell = (price < 0) ?
                {
                    style: negativeStyle,
                    value: `( $${Math.abs(price).toFixed(2)})`
                } :
                {
                    style: currentClass,
                    value: ` $${price.toFixed(2)}`
                };
        }
        let totalCell = '';
        if (window.ENV.secondSalesTax && salesTax !== null && salesTax !== undefined && price !== null && price !== undefined) {
            totalCell = (price + salesTax < 0) ?
                {
                    style: negativeStyle,
                    value: `( $${Math.abs(price + salesTax).toFixed(2)})`
                } :
                {
                    style: currentClass,
                    value: ` $${(price + salesTax).toFixed(2)}`
                }
        }
        else if (tax !== null && tax !== undefined && price !== null && price !== undefined) {
            totalCell = (price + tax < 0) ?
                {
                    style: negativeStyle,
                    value: `( $${Math.abs(price + tax).toFixed(2)})`
                } :
                {
                    style: currentClass,
                    value: ` $${(price + tax).toFixed(2)}`
                }
        }
        return {
            salesTax: salesTaxCell,
            tax: taxCell,
            price: priceCell,
            total: totalCell
        }
    }

    renderReportHeader = () => {
        const { range, classes } = this.props;
        const index = range.dateRange.findIndex(range => range.modalType === constants.INVOICE_BY_CATEGORY);
        if (index != -1)
            return (
                <TableHead key={`statementHeader`}>
                    <TableRow key={'summaryHeader'}>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="1"
                            component="th"
                            className={classes.subHeadings}>
                        </TableCell>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="4"
                            component="th"
                            className={classes.subHeadings}>
                            <h3>Invoice By Category</h3>
                        </TableCell>
                        <TableCell
                            key={`summaryCell1`}
                            colSpan="3"
                            component="th"
                            className={classes.subHeadings}>
                            <b>({moment(range.dateRange[index].startDate).format('L')} - {moment(range.dateRange[index].endDate).format('L')})</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
            )
        else
            return null
    }


    renderInvoice = (items) => {
        let { classes } = this.props;
        return items.map((item, index) => {
            let { tax, price, total, salesTax } = this.checkForNegatives(item.tax, item.price, classes.tableCell, item.salesTax);
            console.log('line 114:');
            return (
                <TableRow key={`invoicerow${index}`}>
                    <TableCell
                        key={`invoiceCell1${index}`}
                        className={classes.tableCell}>
                        {item.itemName}
                    </TableCell>
                    <TableCell
                        key={`invoiceCell2${index}`}
                        className={classes.tableCell}>
                        {item.count}
                    </TableCell>
                    {window.ENV.secondSalesTax ?
                        <TableCell
                            key={`invoiceCell3${index}`}
                            className={salesTax.style || classes.tableCell}>
                            {salesTax.value}
                        </TableCell> : null
                    }
                    <TableCell
                        key={`invoiceCell3${index}`}
                        className={tax.style || classes.tableCell}>
                        {tax.value}
                    </TableCell>
                    <TableCell
                        key={`invoiceCell4${index}`}
                        className={price.style || classes.tableCell}>
                        {price.value}
                    </TableCell>
                    <TableCell
                        key={`invoiceCell5${index}`}
                        className={(tax.style ? (total.style || tax.style) : price.style) || classes.tableCell}>
                        { (item.tax !== undefined || item.salesTax!==undefined) ? (total.value || tax.value) : (price.value)}
                    </TableCell>
                </TableRow>
            )
        })
    }

    totalEachSectionMethod = (obj) => {
        console.log('line 138: invoice by category: ');
        let { classes } = this.props;
        let count = 0, taxSum = 0, priceSum = 0, totalSum = 0, salesTaxSum = 0;
        for (let i in obj) {
            count = obj[i].count + count;
            if (obj[i].tax) {
                taxSum = obj[i].tax + taxSum;
            }
            if (obj[i].salesTax) {
                salesTaxSum = obj[i].salesTax + salesTaxSum;
            }
            priceSum = obj[i].price + priceSum;

            //priceSum = obj[i].price + priceSum;
        }
        let { tax, price, total, salesTax } = this.checkForNegatives(taxSum, priceSum, classes.sectionTotal, salesTaxSum);
        return <Fragment>
            {this.renderInvoice(obj)}
            <TableRow key={`eachSection${count}`}>
                <TableCell
                    key={`eachcell1Section${count}`}
                    component='th'
                    className={classes.sectionTotal}>
                    Total
                </TableCell>
                <TableCell
                    key={`eachcell2Section${count}`}
                    className={classes.sectionTotal}>
                    {count}
                </TableCell>
                {window.ENV.secondSalesTax ?
                    <TableCell
                        key={`invoiceCell3${count}`}
                        className={salesTax.style || classes.tableCell}>
                        {salesTax.value}
                    </TableCell> : null
                }
                <TableCell
                    key={`eachcell3Section${count}`}
                    className={tax.style}>
                    {tax.value}
                </TableCell>
                <TableCell
                    key={`eachcell4Section${count}`}
                    className={price.style}>
                    {price.value}
                </TableCell>
                <TableCell
                    className={total.style}>
                    {total.value}
                </TableCell>
            </TableRow>
        </Fragment>
    }


    renderCreditDebit = (items) => {
        let { classes } = this.props;

        return items.map((item, index) => {
            //let { tax, price } = this.checkForNegatives(item.tax, item.price, classes.tableCell);
            let { price, total } = this.checkForNegatives(0, item.price, classes.tableCell);
            return (
                <TableRow key={`Creditrow${index}`}>
                    <TableCell
                        key={`creditCell1${index}`}
                        colSpan={3}
                        className={classes.sectionTotal}>
                        {item.itemName}
                    </TableCell>
                    {window.ENV.secondSalesTax ?
                        <TableCell
                            key={`invoiceCell3${index}`}
                            className={price.style || classes.tableCell}>
                        </TableCell> : null
                    }
                    <TableCell
                        key={`creditCell2${index}`}
                        className={price.style}>
                        {price.value}
                    </TableCell>
                    <TableCell
                        key={`creditCell3${index}`}
                        className={total.style}>
                        {total.value}
                    </TableCell>
                </TableRow>
            )
        })

    }

    grandTotal = (data) => {
        const { classes } = this.props;

        let countTotal = 0, priceTotal = 0, taxTotal = 0;
        data && data.map((invoiceObj) => {
            invoiceObj.items.map(item => {
                countTotal += item.count;
                priceTotal += item.price;
                taxTotal = '';
                //taxTotal += item.tax;
            })
        })
        let { tax, price } = this.checkForNegatives(taxTotal, priceTotal, classes.sectionTotal);


        return <Fragment>
            <TableCell
                key={`totalCell1`}
                component='th'
                className={classes.sectionTotal} >
                Grand Total
            </TableCell >
            <TableCell
                key={`totalCell2`}
                className={classes.sectionTotal}>
                {countTotal}
            </TableCell>
            <TableCell
                key={`totalCell3`}
                className={tax.style}>
                {tax.value}
            </TableCell>
            <TableCell
                key={`totalCell4`}
                className={price.style}>
                {price.value}
            </TableCell>
        </Fragment>
    }


    componentWillReceiveProps(nextprops) {

        const { range } = this.props;
        var startDate = '';
        var endDate = '';
        //alert(JSON.stringify(this.props.range))

        if (range.dateRange.length > 0 && nextprops.isModal === false && nextprops.submit === true) {
            //if (range.dateRange.length > 0) {
            const index = range.dateRange.findIndex(range => range.modalType === constants.INVOICE_BY_CATEGORY);
            if (index > -1) {
                this.setState({ isloading: true })

                let sDate = new Date(range.dateRange[index].startDate);
                sDate = sDate.setHours(0, 0, 0, 0);
                startDate = new Date(sDate);
                startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                let eDate = new Date(range.dateRange[index].endDate);
                eDate = eDate.setHours(23, 59, 59, 999);
                endDate = new Date(eDate);
                endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

                var that = this;
                var data = [];
                Promise.all([reportStore.getInvoiceByCategory(startDate, endDate)])
                    .then(function (result) {
                        data = result[0];
                        localStorage.setItem("CateogryData", JSON.stringify(data));
                        that.setState({ filterData: data, Isdata: true });
                        that.forceUpdate();
                    })
            }
        }
        else {
            var data = localStorage.getItem("CateogryData");
            if (data !== null) {
                this.setState({ filterData: JSON.parse(data), Isdata: true });
            }
        }
    }

    componentWillMount() {
        this.loadEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func))

        // let { dateParam } = this.props;
        // var startDate = '';
        // var endDate = '';
        // //alert(JSON.stringify(dateParam))
        // if (dateParam.length > 0) {

        //     const index = dateParam
        //         .findIndex(range => range.modalType === constants.INVOICE_BY_CATEGORY);
        //     //alert(JSON.stringify(dateParam[index]));

        //     if (index > -1) {

        //         var data = localStorage.getItem("CateogryData");
        //         if (data !== null) {
        //             this.setState({ filterData: JSON.parse(data), Isdata: true });
        //         }
        //         // this.setState({ isloading: true })
        //         // let sDate = new Date(dateParam[index].startDate);
        //         // sDate = sDate.setHours(0, 0, 0, 0);
        //         // startDate = new Date(sDate);
        //         // startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // let eDate = new Date(dateParam[index].endDate);
        //         // eDate = eDate.setHours(23, 59, 59, 999);
        //         // endDate = new Date(eDate);
        //         // endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

        //         // var that = this;
        //         // var data = [];
        //         // Promise.all([reportStore.getInvoiceByCategory(startDate, endDate)])
        //         //     .then(function (result) {
        //         //         data = result[0];
        //         //         that.setState({ filterData: data, Isdata: true});
        //         //         that.forceUpdate();
        //         //     })
        //     }
        // }
    }

    filterOnRange = () => {

        let filterArray = [];
        let innerArray = [];
        let creditArray = [];

        var DeepCopyData = [];
        DeepCopyData.push(this.state.filterData);

        if (this.state.Isdata === true) {
            DeepCopyData.forEach(element => {
                for (var item in element) {
                    const desc = Object.getOwnPropertyDescriptor(element, item);
                    innerArray = [];
                    creditArray = [];
                    for (var inneritem in desc.value) {
                        if (inneritem !== 'totalSalesTax' && inneritem !== 'totalTax' && inneritem !== 'totalCredit' && inneritem !== "totalDebit" && inneritem !== "totalCollected" && inneritem !== "totalRefunded" && inneritem !== "totalTaxCollected" && inneritem !== "Total Sales Tax Collected" && inneritem !== "Total Airport Facilities Fee Collected" && inneritem !== "Total Sales Tax Refunded" && inneritem !== "Total Airport Facilities Fee Refunded" && inneritem !== "totalTaxRefunded" && inneritem !== "totalCardCollected" && inneritem !== "totalCashCollected"
                            && inneritem !== "totalPayout" && inneritem !== "totalCashPayout" && inneritem !== "totalCheckPayout") {
                            const innerdesc = Object.getOwnPropertyDescriptor(desc.value, inneritem);
                            innerArray.push({ itemName: inneritem, count: innerdesc.value.count, tax: innerdesc.value.tax, price: innerdesc.value.value, salesTax: innerdesc.value.salesTax })
                        }
                        else {
                            const innerdesc = Object.getOwnPropertyDescriptor(desc.value, inneritem);
                            creditArray.push({ itemName: inneritem, price: innerdesc.value });
                        }
                    }
                    filterArray.push({ label: item, value: desc.value, items: innerArray, credit: creditArray })
                }
            });
        }

        //let DeepCopy = this.state.filterData;
        // Promise.all([reportStore.getInvoiceByCategory('2018-17-19T08:00:00.000Z','2018-12-21T23:59:59.999Z')])
        // .then(function(result){
        //     console.log("apirequestcall"+ JSON.stringify(result[0]));
        // })
        // let data = DeepCopy.map(invoiceObj => {
        //     invoiceObj.items = invoiceObj.items.filter(({ date }) => {
        //         let convertedDate = this.dateConverter(date);
        //         if (startRange <= convertedDate && endRange >= convertedDate) {
        //             return true;
        //         };
        //     })
        //     return invoiceObj;
        // });

        return filterArray;
    }

    filterPdfData = () => {
        const { classes } = this.props;

        let jumperArray = [];
        let innerArray = [];
        let creditArray = [];
        let mainArray = [];

        var DeepCopyData = [];
        DeepCopyData.push(this.state.filterData);
        if (this.state.Isdata === true) {
            this.filterDataArray = this.state.filterData;
            DeepCopyData.forEach(element => {
                for (var item in element) {
                    const desc = Object.getOwnPropertyDescriptor(element, item);
                    innerArray = [];
                    creditArray = [];
                    for (var inneritem in desc.value) {
                        if (inneritem !== 'totalSalesTax' && inneritem !== 'totalTax' && inneritem !== 'totalCredit' && inneritem !== "totalDebit" && inneritem !== "totalCollected" && inneritem !== "totalRefunded" && inneritem !== "totalTaxCollected" && inneritem !== "Total Sales Tax Collected" && inneritem !== "Total Airport Facilities Fee Collected" && inneritem !== "Total Sales Tax Refunded" && inneritem !== "Total Airport Facilities Fee Refunded" && inneritem !== "totalTaxRefunded" && inneritem !== "totalCashCollected" && inneritem !== "totalCardCollected"
                            && inneritem !== "totalPayout" && inneritem !== "totalCashPayout" && inneritem !== "totalCheckPayout") {
                            const innerdesc = Object.getOwnPropertyDescriptor(desc.value, inneritem);
                            innerArray.push({ itemName: inneritem, count: innerdesc.value.count, price: innerdesc.value.value })
                        }
                        else {
                            const innerdesc = Object.getOwnPropertyDescriptor(desc.value, inneritem);
                            creditArray.push({ itemName: inneritem, price: innerdesc.value });
                        }
                    }
                    jumperArray.push({ label: item, value: desc.value, items: innerArray, credit: creditArray })
                }
            });
        }

        let rows = [];
        var rowlength = 2;
        var creditKey = 1;

        rows.push(
            this.renderReportHeader()
        )

        rows.push(
            <TableHead>
                <TableRow key={'jumperlogHeader1'}>
                    <TableCell
                        colSpan="2"
                        key={`jumperlogHeaderCell1`}
                        component="th"
                        className={classes.subHeadings}>
                    </TableCell>
                    <TableCell
                        key={`jumperlogHeaderCell2`}
                        component="th"
                        className={classes.subHeadings}>
                        Count
                    </TableCell>
                    <TableCell
                        key={`jumperlogHeaderCella2`}
                        component="th"
                        className={classes.subHeadings}>
                    </TableCell>
                    <TableCell
                        key={`jumperlogHeaderCell3`}
                        component="th"
                        className={classes.subHeadings}>
                        Tax
                    </TableCell>
                    <TableCell
                        key={`jumperlogHeaderCella3`}
                        component="th"
                        className={classes.subHeadings}>
                    </TableCell>
                    <TableCell
                        key={`jumperlogHeaderCell4`}
                        component="th"
                        className={classes.subHeadings}>
                        Price
                    </TableCell>
                    <TableCell
                        key={`jumperlogHeaderCella4`}
                        component="th"
                        className={classes.subHeadings}>
                    </TableCell>
                </TableRow>
            </TableHead>
        )

        for (var index = 0; index < jumperArray.length; index++) {
            rowlength++;
            var currentItem = jumperArray[index];
            rows.push(
                <TableRow key={`tablerow${currentItem.label}`}>
                    {
                        currentItem.label === "totalSalesTax" || currentItem.label === "totalTax" || currentItem.label === "totalCredit" || currentItem.label === "totalDebit" || currentItem.label === "totalCollected" || currentItem.label === "totalRefunded" || currentItem.label === "totalTaxCollected" || currentItem.label === "Total Sales Tax Collected" || currentItem.label === "Total Airport Facilities Fee Collected" || currentItem.label === "Total Sales Tax Refunded" || currentItem.label === "Total Airport Facilities Fee Refunded" || currentItem.label === "totalTaxRefunded" || currentItem.label === "totalCashCollected" || currentItem.label === "totalCardCollected" || currentItem.label === "totalPayout" || currentItem.label === "totalCashPayout" || currentItem.label === "totalCheckPayout" ?
                            this.renderPdfTotalCredit(currentItem, "p" + index)
                            :
                            <TableCell
                                key={`jumperlogrowCell1${index}`}
                                scope="row"
                                component="th"
                                colSpan={8}
                                className={classes.sectionHeading}
                            >
                                {currentItem.label}
                            </TableCell>
                    }
                </TableRow>
            )

            if (currentItem.label !== "totalSalesTax" && currentItem.label !== "totalTax" && currentItem.label !== "totalCredit" && currentItem.label !== "totalDebit" && currentItem.label !== "totalCollected" && currentItem.label !== "totalRefunded" && currentItem.label !== "totalTaxCollected" && currentItem.label !== "Total Sales Tax Collected" && currentItem.label !== "Total Airport Facilities Fee Collected" && currentItem.label !== "Total Sales Tax Refunded" && currentItem.label !== "Total Airport Facilities Fee Refunded" &&  currentItem.label !== "totalTaxRefunded" && currentItem.label !== "totalCashCollected" && currentItem.label !== "totalCardCollected"
                && currentItem.label !== "totalPayout" && currentItem.label !== "totalCashPayout" && currentItem.label !== "totalCheckPayout") {
                let count = 0, taxSum = 0, priceSum = 0;
                console.log(currentItem);
                for (let l in currentItem.items) {
                    count = currentItem.items[l].count + count;
                    if (currentItem.items[l].tax) {
                        taxSum = currentItem.items[l].tax + taxSum;
                    }
                    priceSum = currentItem.items[l].price + priceSum;
                }

                let { tax, price } = this.checkForNegatives(taxSum, priceSum, classes.sectionTotal);
                for (let l in currentItem.items) {
                    rowlength++;
                    let { tax, price } = this.checkForNegatives(currentItem.items[l].tax, currentItem.items[l].price, classes.tableCell);
                    rows.push(
                        <TableRow key={`pdfinvoicerow${index + l}`} className={classes.pdfRow}>
                            <TableCell
                                key={`pdfinvoiceCell1${index + l}`}
                                className={classes.tableCell}>
                                {currentItem.items[l].itemName}
                            </TableCell>
                            <TableCell
                                key={`pdfinvoiceCella1${index + l}`}
                                className={classes.tableCell}>
                            </TableCell>
                            <TableCell
                                key={`pdfinvoiceCell2${index + l}`}
                                className={classes.tableCell}>
                                {currentItem.items[l].count}
                            </TableCell>
                            <TableCell
                                key={`pdfinvoiceCella2${index + l}`}
                                className={classes.tableCell}>
                            </TableCell>
                            <TableCell
                                key={`pdfinvoiceCell3${index + l}`}
                                className={tax.style}>
                                {tax.value}
                            </TableCell>
                            <TableCell
                                key={`pdfinvoiceCella3${index + l}`}
                                className={classes.tableCell}>
                            </TableCell>
                            <TableCell
                                key={`pdfinvoiceCell4${index + l}`}
                                className={price.style}>
                                {price.value}
                            </TableCell>
                            <TableCell
                                key={`pdfinvoiceCella4${index + l}`}
                                className={classes.tableCell}>
                            </TableCell>
                        </TableRow>
                    )

                    if (rowlength >= 26) {
                        mainArray.push(
                            <div className="invoiceCategoryPanel" style={{ display: 'none', width: '22cm', paddingLeft: '40px' }}>
                                <div style={{ height: '15px' }}></div>
                                {rows}
                            </div>);


                        rowlength = 1;
                        rows = [];

                        rows.push(
                            <TableRow key={`jumperlogHeader${index + 1}`} className={classes.pdfRow}>
                                <TableCell
                                    colSpan="2"
                                    key={`jumperlogcell1${index + 1}`}
                                    component="th"
                                    className={classes.subHeadings}>
                                </TableCell>
                                <TableCell
                                    key={`jumperlogcell2${index + 1}`}
                                    component="th"
                                    className={classes.subHeadings}>
                                    Count
                                </TableCell>
                                <TableCell
                                    key={`jumperlogcella2${index + 1}`}
                                    component="th"
                                    className={classes.subHeadings}>
                                </TableCell>
                                <TableCell
                                    key={`jumperlogcell3${index + 1}`}
                                    component="th"
                                    className={classes.subHeadings}>
                                    Tax
                                </TableCell>
                                <TableCell
                                    key={`jumperlogcella3${index + 1}`}
                                    component="th"
                                    className={classes.subHeadings}>
                                </TableCell>
                                <TableCell
                                    key={`jumperlogcell4${index + 1}`}
                                    component="th"
                                    className={classes.subHeadings}>
                                    Price
                                </TableCell>
                                <TableCell
                                    key={`jumperlogcella4${index + 1}`}
                                    component="th"
                                    className={classes.subHeadings}>
                                </TableCell>
                            </TableRow>
                        )
                    }

                }

                rows.push(
                    <TableRow key={`eachSection${count}`} className={classes.pdfRow}>
                        <TableCell
                            component='th'
                            key={`pTotalCell1${index + 1}`}
                            className={classes.sectionTotal}>
                            Total
                        </TableCell>
                        <TableCell
                            component='th'
                            key={`pTotalCella1${index + 1}`}
                            className={classes.sectionTotal}>
                        </TableCell>
                        <TableCell
                            key={`pTotalCell2${index + 1}`}
                            className={classes.sectionTotal}>
                            {count}
                        </TableCell>
                        <TableCell
                            component='th'
                            key={`pTotalCella2${index + 1}`}
                            className={classes.sectionTotal}>
                        </TableCell>
                        <TableCell
                            key={`pTotalCell3${index + 1}`}
                            className={tax.style}>
                            {tax.value}
                        </TableCell>
                        <TableCell
                            key={`pTotalCella3${index + 1}`}
                            className={tax.style}>
                        </TableCell>
                        <TableCell
                            key={`pTotalCell4${index + 1}`}
                            className={price.style}>
                            {price.value}
                        </TableCell>
                        <TableCell
                            key={`pTotalCella4${index + 1}`}
                            className={price.style}>
                        </TableCell>
                    </TableRow>
                )
                rowlength++;
            }


            for (var j = 0; j < currentItem.credit.length; j++) {
                creditKey++;
                rowlength++;
                var crediItem = currentItem.credit[j];
                let { price } = this.checkForNegatives(0, crediItem.price, classes.tableCell);
                rows.push(
                    <TableRow key={`pdfCreditrow${creditKey}`} className={classes.pdfRow}>
                        <TableCell
                            key={`pdfCreditCell1${creditKey}`}
                            colSpan={6}
                            className={classes.sectionTotal}>
                            {crediItem.itemName}
                        </TableCell>

                        <TableCell
                            key={`pdfCreditCell2${creditKey}`}
                            className={price.style}>
                            {price.value}
                        </TableCell>

                        <TableCell
                            key={`pdfCreditCella2${creditKey}`}
                            className={price.style}>
                        </TableCell>
                    </TableRow>
                )

                if (rowlength >= 26) {
                    mainArray.push(
                        <div className="invoiceCategoryPanel" style={{ display: 'none', width: '22cm', paddingLeft: '40px' }}>
                            <div style={{ height: '15px' }}></div>
                            {rows}
                        </div>);


                    rowlength = 1;
                    rows = [];

                    rows.push(
                        <TableRow key={`jumperlogHeader${index + 1}`} className={classes.pdfRow}>
                            <TableCell
                                colSpan="2"
                                key={`jumperlogcell1${index + 1}`}
                                component="th"
                                className={classes.subHeadings}>
                            </TableCell>
                            <TableCell
                                key={`jumperlogcell2${index + 1}`}
                                component="th"
                                className={classes.subHeadings}>
                                Count
                            </TableCell>
                            <TableCell
                                key={`jumperlogcella2${index + 1}`}
                                component="th"
                                className={classes.subHeadings}>
                            </TableCell>
                            <TableCell
                                key={`jumperlogcell3${index + 1}`}
                                component="th"
                                className={classes.subHeadings}>
                                Tax
                            </TableCell>
                            <TableCell
                                key={`jumperlogcella3${index + 1}`}
                                component="th"
                                className={classes.subHeadings}>
                            </TableCell>
                            <TableCell
                                key={`jumperlogcell4${index + 1}`}
                                component="th"
                                className={classes.subHeadings}>
                                Price
                            </TableCell>
                            <TableCell
                                key={`jumperlogcella4${index + 1}`}
                                component="th"
                                className={classes.subHeadings}>
                            </TableCell>
                        </TableRow>
                    )
                }
            }

            if (index == jumperArray.length - 1) {
                mainArray.push(
                    <div className="invoiceCategoryPanel" style={{ display: 'none', width: '22cm', paddingLeft: '40px' }}>
                        <div style={{ height: '30px' }}></div>
                        {rows}
                    </div>);
                rowlength = 1;
                rows = [];
            }
        }

        // mainArray.push(rows);

        return mainArray;
    }

    dateConverter = (date) => {
        let dateInMilliseconds = new Date(date).getTime();
        return dateInMilliseconds;
    }

    renderTotalCredit = (data, index) => {
        const { classes } = this.props;

        let { tax, price, total } = this.checkForNegatives(0, data.value, classes.sectionTotal);

        return <Fragment>
            <TableCell
                key={`catCell1${index}`}
                colSpan={3}
                component='th'
                className={classes.sectionHeading} >
                {data.label}
            </TableCell>

            <TableCell
                key={`catCell2${index}`}
                className={price.style || classes.sectionHeading}>
                {price.value}
            </TableCell>
            <TableCell
                key={`catCell3${index}`}
                className={total.style || classes.sectionHeading}>
                {price.value}
            </TableCell>
        </Fragment>
    }

    renderPdfTotalCredit = (data, index) => {
        const { classes } = this.props;

        let { tax, price } = this.checkForNegatives(0, data.value, classes.sectionTotal);

        return <Fragment>
            <TableCell
                key={`catCell1${index}`}
                colSpan={6}
                component='th'
                className={classes.sectionHeading} >
                {data.label}
            </TableCell>

            <TableCell
                key={`catCell2${index}`}
                className={price.style}>
                {price.value}
            </TableCell>
            <TableCell
                key={`catCella2${index}`}
                className={price.style}>
            </TableCell>
        </Fragment>
    }

    render() {
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
        const { classes, data, submit, range } = this.props;
        console.log("line 807: ");
        console.log({ classes, data, submit, range });
        if (this.state.Isdata === true) {
            return (
                <Fragment>
                    <div className='report-loading'>
                        {/* <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            loading={this.state.isloading}
                        /> */}
                        <DonutLoader isLoading={this.state.isloading} />

                    </div>
                    {submit && data &&
                        this.filterOnRange().map((row, index) => {
                            return (
                                <Fragment key={`tableFrag${index}`}>
                                    <TableRow key={`tablerow${row.label}`}>
                                        {
                                            row.label === "totalSalesTax" || row.label === "totalTax" || row.label === "totalCredit" || row.label === "totalDebit" || row.label === "totalCollected" || row.label === "totalRefunded" || row.label === "totalTaxCollected" || row.label === "Total Sales Tax Collected" || row.label === "Total Airport Facilities Fee Collected" || row.label === "Total Sales Tax Refunded" || row.label === "Total Airport Facilities Fee Refunded" || row.label === "totalTaxRefunded" || row.label === "totalCashCollected" || row.label === "totalCardCollected" || row.label === "totalPayout" || row.label === "totalCashPayout" || row.label === "totalCheckPayout" ?

                                                this.renderTotalCredit(row, index)
                                                :
                                                <TableCell
                                                    key={`catCell${index}`}
                                                    scope="row"
                                                    component="th"
                                                    colSpan={5}
                                                    className={classes.sectionHeading}
                                                >
                                                    {row.label}
                                                </TableCell>
                                        }
                                    </TableRow>

                                    {row.label !== "totalSalesTax" && row.label !== "totalTax" && row.label !== "totalCredit" && row.label !== "totalDebit" && row.label !== "totalCollected" && row.label !== "totalRefunded" && row.label !== "totalTaxCollected" && row.label !== "Total Sales Tax Collected" && row.label !== "Total Airport Facilities Fee Collected" && row.label !== "Total Sales Tax Refunded" && row.label !== "Total Airport Facilities Fee Refunded" && row.label !== "totalTaxRefunded" && row.label !== "totalCashCollected" && row.label !== "totalCardCollected"
                                        && row.label !== "totalPayout" && row.label !== "totalCashPayout" && row.label !== "totalCheckPayout"
                                        ? this.totalEachSectionMethod(row.items, row.total) : ''}

                                    {this.renderCreditDebit(row.credit)}

                                </Fragment>
                            )
                        })
                    }
                    {submit && data && range.length > 0 &&
                        <TableRow key={'grandTotalRow'}>
                            {this.grandTotal(this.filterOnRange(range[0], data))}
                        </TableRow>}
                    {this.filterPdfData()}
                </Fragment>
            )
        }
        else {
            return (
                <Fragment>
                    <div className='report-loading inreports'>
                        {/* <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            loading={this.state.isloading}
                        /> */}
                        <DonutLoader isLoading={this.state.isloading} />
                    </div>
                    {submit && data && range.length > 0 &&
                        this.filterOnRange(range[0], data).map((row, index) => {
                            return (
                                <Fragment>
                                    <TableRow key={`tablerow${row.label}`}>
                                        <TableCell
                                            key={`headCell${index}`}
                                            scope="row"
                                            component="th"
                                            colSpan={4}
                                            className={classes.sectionHeading}
                                        >
                                            {row.label}
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            )
                        })
                    }
                </Fragment>
            )
        }
    }
}

export default withStyles(styles)(InvoiceByCategory);
