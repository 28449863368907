import React, { Component } from 'react';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import loadsStore from '../../../stores/loads-store';
import Fuse from "fuse.js";
import ReactDOM from 'react-dom';
import { DragSource } from 'react-dnd'
import { connect } from 'react-redux'
import LoadUpdateModal from './loadModal'
import jumpersStore from '../../../stores/jumpers-store';


const Types = {
	ITEM: 'FunJump'
};

const itemSource = {
	beginDrag(props) {
		const item = props
		return item
	},
	endDrag(props, monitor, component) {
		if (!monitor.didDrop()) {
			return;
		}
		return props;
	}
}

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	}
}

class EditableFunJumper extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			jumpType: null,
			load: props.load,
			jumper: props.jumper,
			type: props.type,
			label: props.type,
			jumpId: props.jumpId,
			editMode: props.editMode,
			menuPlacement: "bottom",
			options: []
		};
		this.gearIds = [];
		this.valRef = null;
		this.inputValue = '';
		this.fuse = null;
		this.isConfirm = true;
	}

	componentDidMount = () => {
		if (this.valRef !== null)
			this.valRef.focus();
		var jumperOptions = jumpersStore.getJumperOptions();
		if (this.props.activeJumperIdsOnLoad) {
			jumperOptions = jumperOptions.filter(
				option =>
					this.props.activeJumperIdsOnLoad.findIndex(
						id => option.value._id === id
					) === -1
			);
		}
		this.setState({ options: jumperOptions });
		this.setFuse(jumperOptions);
	}

	setFuse = (jumperOptions) => {
		var options = {
			shouldSort: true,
			tokenize: true,
			threshold: 0.1,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 2,
			keys: [
				"value.manifestNumber",
				"value.nickname",
				"value.lastname"
			]
		};

		this.fuse = new Fuse(jumperOptions, options);
	}

	componentDidUpdate = () => {
		console.log('did update editable fun jumpers');
		if (this.valRef !== null) {
			this.valRef.focus();
		}
		var jumperOptions = jumpersStore.getJumperOptions();
		if (this.props.activeJumperIdsOnLoad) {
			jumperOptions = jumperOptions.filter(
				option =>
					this.props.activeJumperIdsOnLoad.findIndex(
						id => option.value._id === id
					) === -1
			);
		}
		this.setFuse(jumperOptions);
	}

	updateGear = (value) => {
		var id = value.target.name.substr(4);
		if (value.target.checked) {
			this.gearIds.push(id);
		}
		else {
			this.gearIds = this.gearIds.filter(gearId => gearId !== id);
		}
	}

	Option = (props) => {
		var gearModel = ".gear" + props.data.value._id;
		var name = "gear" + props.data.value._id
		return (
			<div className="row">
				<div className="col-8">
					<components.Option {...props} />
				</div>
				<div className="col-4" style={{ paddingTop: '6px' }}>
					gear <Control.checkbox model={gearModel} name={name} onChange={value => { this.updateGear(value) }} />
				</div>
			</div>
		);
	}

	SingleValueLabel = ({ children, ...props }) => {
		return (
			<components.SingleValue {...props}><div className="row"> <div className="col-12">{children}</div>{this.gearIds.indexOf(props.data.value._id) > -1 ? <div className="col-12">-G</div> : null}</div></components.SingleValue>
		);
	}

	handleChange = (selectedOption, loadNum, jumpId, type, label, isCompleted, jump, loadId, isToday, isWingsuit) => {
		//console.log('in handle change');
		this.setState({ editMode: !this.state.editMode })
		//alert(selectedOption.value._id);
		if (isCompleted) {

			var jumpPostBody = { jumperId: selectedOption.value._id };
			if (isToday) {
				jumpPostBody.isToday = true
			}
			jumpPostBody.coachId = jump.coach._id;
			jumpPostBody.price = jump.price;
			jumpPostBody.coachPay = jump.coachPay;
			jumpPostBody.isWingsuit = isWingsuit;
			jumpPostBody.editPrice = true;
			var cjumpId = jump.jumpId;
			var loadId = loadId;
			this.props.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody);
		}
		else {
			if (selectedOption.value !== undefined) {
				if (type === "coach") {
					if (label === "J") {
						var jumpPostBody = {
							jumperId: selectedOption.value._id,
						};
						loadsStore.updateCoachJump(loadNum, jumpId, jumpPostBody);
					}
				}
				else if (type === "iad") {
					var jumpPostBody = {};
					if (label === "S1") {
						var jumpPostBody = {
							student1Id: selectedOption.value._id,
						}
					}
					else if (label === "S2") {
						var jumpPostBody = {
							student2Id: selectedOption.value._id,
						}
					}
					else if (label === "S3") {
						var jumpPostBody = {
							student3Id: selectedOption.value._id,
						}
					}
					loadsStore.updateIadJump(loadNum, jumpId, jumpPostBody);
				}
			}
		}
	}

	wantsEidtable = (data) => {
		this.setState({
			editMode: data,
			isModalOpen: false
		}, () => {
			if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
				this.setState({ menuPlacement: "top" });
			}
			else {
				this.setState({ menuPlacement: "bottom" });
			}
		}
		)
		this.isConfirm = false;
	}

	changeJumper = (isCompleted) => {
		this.isConfirm = false;
		if (isCompleted) {
			this.setState({
				isModalOpen: true
			})
		}
		else {
			if (!this.state.editMode) {
				this.setState({
					editMode: true
				})
			}
			if (ReactDOM.findDOMNode(this).getBoundingClientRect().y > window.innerHeight / 2) {
				this.setState({ menuPlacement: "top" });
			}
			else {
				this.setState({ menuPlacement: "bottom" });
			}
		}
	}

	filterJumpers = (inputValue, jumperOptions) => {
		if (inputValue) {
			var filteredOptions = this.fuse.search(inputValue.toLowerCase());
			if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
			else return filteredOptions;
		}
		return jumperOptions;
	};

	promiseOptions = (inputValue, jumperOptions) => {
		return new Promise(resolve => {
			resolve(this.filterJumpers(this.inputValue, jumperOptions));
		});
	}

	handleInputChange = (newValue) => {
		this.inputValue = newValue;
		this.valRef.focus();
	};

	onBlur = (mode, isCompleted) => {
		//if(this.state.editMode && !this.props.isCompleted)
		//{
		if (mode && !isCompleted) {
			this.setState({ editMode: false });
		}
		else if (mode && this.isConfirm && isCompleted) {
			this.setState({ editMode: false });
			this.isConfirm = false;
		}
		else {
			this.isConfirm = true;
		}
	}

	checkForNegatives = (price) => {
		//console.log('check for negatives: ' + price);
		if (price != undefined) {
			let priceCell = (price < 0) ?
				{
					value: `($${Math.abs(price).toFixed(2)})`
				} :
				{
					value: `$${price.toFixed(2)}`
				}

			if (price < 0) {
				return <span style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
			}
			return <span style={{ color: '#00FF00' }}> {priceCell.value}</span>;;
		}
		else {
			return '$0.00';
		}
	}

	render() {

		const { isDragging, connectDragSource, isCompleted, jump, loadId, isToday, isWingsuit } = this.props;

		var jumperOptions = this.state.options;
		if (this.props.activeJumperIdsOnLoad) {
			jumperOptions = jumperOptions.filter(
				option =>
					this.props.activeJumperIdsOnLoad.findIndex(
						id => option.value._id === id
					) === -1
			);
		}
		return connectDragSource(

			<div>
				<React.Fragment>
					{
						!this.state.editMode ?
							// <button className="btn lightBlueButton jumper-name" onClick={() => this.changeJumper(this.props.isCompleted)} type="submit" disabled={this.props.disabled} >
							<div onClick={() => this.changeJumper(this.props.isCompleted)} disabled={this.props.disabled}>
								{this.props.jumper.nickname !== "NONE" ?
									<div>{this.props.label} : <strong>{this.props.jumper.nickname} {this.props.jumper.lastname !== undefined && this.props.jumper.lastname !== "" ? this.props.jumper.lastname.substr(0, 1) : null}</strong>
										<div>{this.props.jumper.memberId}</div>
									</div>
									:
									<div><i className="fa fa-plus"></i> {this.props.label}</div>
								}
							</div>
							// </button>
							:
							<button className="btn editable-Jumper" type="submit" disabled={this.props.disabled} style={{ position: 'absolute', left: "22%", width: '170px' }}>
								<LocalForm form="addjumpers" model="addjumpers" getDispatch={dispatch => this.formDispatch = dispatch} >
									<AsyncSelect
										model=".jumper"
										name="jumper"
										ref={(value) => this.valRef = value}
										loadOptions={(newValue) => this.promiseOptions(newValue, jumperOptions)}
										defaultOptions={jumperOptions.slice(0, 10)}
										onInputChange={(newValue) => this.handleInputChange(newValue)}
										onChange={(selectedOption) => this.handleChange(selectedOption, this.props.loadNum, this.props.jumpId, this.props.type, this.props.label, isCompleted, jump, loadId, isToday, isWingsuit)}
										//onInputChange={(newValue) => this.handleInputChange(newValue)}
										menuPortalTarget={document.body}
										menuPosition="fixed"
										menuPlacement={this.state.menuPlacement}
										onBlur={() => this.onBlur(this.state.editMode, this.props.isCompleted)}
									/>
								</LocalForm>
							</button>
					}
					<LoadUpdateModal
						isOpen={this.state.isModalOpen}
						onClick={this.wantsEidtable}
					/>

				</React.Fragment>
			</div>
		);
	}
}

const mstp = state => {
	return {
		state
	}
}
const mdtp = dispatch => {
	return {
		updateCompletedCoachJump: (loadId, cjumpId, jumpPostBody) => dispatch(loadsStore.updateCompletedCoachJump(loadId, cjumpId, jumpPostBody)),
	}
}

export default connect(mstp, mdtp)(DragSource(Types.ITEM, itemSource, collect)(EditableFunJumper))
