import React, { useEffect, useState } from 'react'
import reportStore from '../../../../stores/report-store';
import JumperStore from '../../../../stores/jumpers-store';
import { Chart } from 'react-charts'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles"
import DonutLoader from '../../../loadManager/DonutLoader';

const styles = theme => ({
    dropdownStyle: {
        backgroundColor: '#fff',
        width: '100px',
        paddingLeft: '15px'
    },
});

const TandemState = (props) => {

    const [tandems, setTandems] = useState([])
    const [graphData, setGraphData] = useState([])
    const [years, setYears] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        apiCall()
    }, [])


    const apiCall = () => {
        setIsLoading(true)
        reportStore.GetTandemState().then(res => {
            let finalData = getFinalData(res.graphData)
            setTandems(res.by_year)
            setGraphData(finalData)
            if (res?.graphData) {
                let data = Object.keys(finalData)
                setYears(data)
                setIsLoading(false)
            }
        })
    }

    const getFinalData = (data) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        Object.keys(data).map(year => {
            monthNames.filter(month => {
                if (!data[year].some(val => val[0] === month)) {
                    data[year].push([month, 0])
                }
            })
        })
        return data

    }

    const renderDetail = (data) => {
        let array = data.sort(function (a, b) {
            var keyA = new Date(a.count),
                keyB = new Date(b.count);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0
        });
        return array?.map((i, ind) => {
            let jumperInfo = JumperStore.getJumperById(i.instructorId);
            let name = jumperInfo !== undefined ? jumperInfo.firstname + ' ' + jumperInfo.lastname : '';
            if (ind < 5) {
                return <p key={ind}>{name} - {i.count}</p>
            }
        })
    }

    const renderData = () => {
        if (tandems) {
            return Object.keys(tandems)?.map(i => {
                return <div style={{ marginRight: '70px' }}>
                    <h5>{i}</h5>
                    {renderDetail(tandems[i])}
                </div>
            })
        }
    }

    const sortArray = (data) => {
        let monthNames = {
            "January": 1,
            "February": 2,
            "March": 3,
            "April": 4,
            "May": 5,
            "June": 6,
            "July": 7,
            "August": 8,
            "September": 9,
            "October": 10,
            "November": 11,
            "December": 12
        };
        data?.sort(function (a, b) {
            // sort based on the value in the monthNames object
            return monthNames[a[0]] - monthNames[b[0]];
        });
        return data
    }

    const data = React.useMemo(
        () => [
            {
                label: years[0] || '',
                color: '#dc3545',
                data: sortArray(graphData[years[0] || '']) || []
            },
            {
                label: years[1] || '',
                color: '#5b9bd3',
                data: sortArray(graphData[years[1] || '']) || []
            },
            {
                label: years[2] || '',
                color: '#dbb954',
                data: sortArray(graphData[years[2] || '']) || []
            },
        ],
        [years]
    )

    const series = React.useMemo(
        () => ({
            type: 'bar',
            //   maintainAspectRatio: true
        }),
        []
    )
    const axes = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom' },
            { position: 'left', type: 'linear' }
        ],
        []
    )

    return <div style={{ display: 'table', width: '900px', marginTop: '-40px', marginLeft: '40px' }}>
        <div className='report-loading'>
            <DonutLoader isLoading={isLoading} />
        </div>
        <div style={{ display: 'flex' }}>
            {renderData()}
        </div>
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    width: '900px',
                    height: '400px',
                    margin: '20px',
                }}
            >
                <Chart data={data} series={series} axes={axes} tooltip />
            </div>
            <div className="graphKeysCon">
                {years?.map((val, ind) => {
                    return <div className="keyRow">
                        <div className={`colorBox ${((ind === 1) && 'colorBlue')} ${((ind === 2) && 'colorYellow')}`}></div>
                        {val}
                    </div>
                })}
            </div>
        </div>
    </div>
}


export default withStyles(styles)(TandemState);