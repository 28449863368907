import React, { Component } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';


class IncompleteReservation extends Component {
  constructor(props) {
    super(props);
  }
  renderRowsForIncompleteJumper = () => {
    var lstIncompleteReservation = this.props.incompleteReservation.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
    if (lstIncompleteReservation) {
      return lstIncompleteReservation.map((object, index) => {
        if (object.tandemStudents && object.tandemStudents.length > 0 && object.slotId != null) {
          return (
            <tr style={{}}>
              <td >{object.tandemStudents[0].firstname + " " + object.tandemStudents[0].lastname}</td>
              <td >{object.tandemStudents[0].email}</td>
              <td >{object.tandemStudents[0].phone}</td>
              <td >{object.ticketCount}</td>
              <td >{object.eventName}</td>
              {/* <td >{object.slotId != null ? moment(object.slotId.datetime).tz(object.slotId.timeZone).toString() : ''}</td> */}
              <td >{object.numEmailsSent}</td>
              <td>{object.isParkedReservationOpened ? "Yes" : "No"}</td>
              <td >{object.smsOptIn ? "Yes" : "No"}</td>
              <td >{object.termsAccepted ? "Yes" : "No"}</td>
              <td >{moment(object.createdAt).toString()}</td>
            </tr>
          )

        }
        else if (object.students && object.students.length > 0 && object.slotId != null) {

          return (
            <tr >
              <td >{object.students[0].firstname + " " + object.students[0].lastname}</td>
              <td >{object.students[0].email}</td>
              <td >{object.students[0].phone}</td>
              <td >{object.ticketCount}</td>
              <td >{object.eventName}</td>
              {/* <td >{object.slotId != null ? moment(object.slotId.datetime).tz(object.slotId.timeZone).toString() : ''}</td> */}
              <td >{object.numEmailsSent}</td>
              <td>{object.isParkedReservationOpened ? "Yes" : "No"}</td>
              <td >{object.smsOptIn ? "Yes" : "No"}</td>
              <td >{object.termsAccepted ? "Yes" : "No"}</td>
              <td >{moment(object.createdAt).toString()}</td>
            </tr>
          )
        }

      })
    } else {
      return null;
    }
  }
  render() {
    return (
      <React.Fragment>
        <Modal id="modal-report" aria-labelledby="example-custom-modal-styling-title"
          isOpen={this.props.showincompleteModel} style={{ zIndex: '10503434', right: '28%' }}>
          <ModalHeader tag="div" style={{ position: 'relative' }}>
            <h5>View Parked Reservations</h5>
            <button className="close" onClick={(e) => { e.preventDefault(); this.props.onCancelModel(); }} style={{ position: 'absolute', left: '20%', top: '14px', color: 'white' }}>x</button>
          </ModalHeader>
          <ModalBody>
            <Table striped style={{ borderTopStyle: "hidden", margin: 0 }}>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>No. Of Ticket</th>
                  <th>Event Name</th>
                  {/* <th>Reservation Date</th> */}
                  <th>#emails sent</th>
                  <th>Opened From Email</th>
                  <th>opted-in for sms</th>
                  <th>terms accepted</th>
                  <th>Create Date</th>
                </tr>
              </thead>
              <tbody>
                {this.renderRowsForIncompleteJumper()}
              </tbody>

            </Table>

            <div className="row">
              <div className="col-12" style={{ marginTop: '10px' }}>
                <button onClick={this.props.onCancelModel} className="btn btn-link">CANCEL</button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}
export default IncompleteReservation;