import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom';

import EditableLoadPlane from '../modals/editableLoadPlane';
import EditableTimer from '../modals/editableTimer';
import loadsStore from "../../../stores/loads-store";
import CompleteLoad from '../modals/completeLoad';

import FileSaver from 'file-saver';

import './loadheader.css';

export default class LoadHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerMenu: false,
            loadStatusView: true,
            isCompleteLoad: false,
            now: false,
            showPlaneLoadNumOnly: this.props.isDisplayPlaneNameLeft,
        }
        this.loadEvents = [
            {
                name: loadsStore.getEventPrefix("updateLoad"),
                func: this.closeMenu
            },
            {
                name: loadsStore.getEventPrefix("updateLoadNow_" + props.loadObj.loadNum),
                func: this.handleNow
            }
        ];
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        this.loadEvents.forEach(evnt => loadsStore.on(evnt.name, evnt.func));
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        this.loadEvents.forEach(evnt => loadsStore.removeListener(evnt.name, evnt.func));
        this.setState({ isCompleteLoad: false })
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            if (this.state.isCompleteLoad) {
                this.setState({
                    headerMenu: true
                });
            } else {
                this.setState({
                    headerMenu: false
                });
            }
        }
    }

    handleNow = () => {
        alert('in handle now: ' + this.props.loadObj.loadNum);
        this.setState({ now: !this.state.now })
    }
    closeMenu = () => {
        this.setState({ headerMenu: false })
    }
    closeIsComplete = () => {
        this.setState({ isCompleteLoad: false })
    }
    toggleHeaderMenu = () => {
        this.setState({ headerMenu: !this.state.headerMenu })
    }
    handleCancelLoad = loadId => {
        document.getElementById(loadId).click();
        this.hideHeaderMenu()
    };

    handleReopenLoad = loadId => {
        loadsStore.reopenLoad(loadId);
    };

    handleSendLoadSheet = loadNum => {
        loadsStore.sendLoadSheet(loadNum);
    }

    handlePrintLoadSheet = (loadNum, plane, planeLoadNum, loadId, isCompleted) => {
        if (isCompleted) {
            loadsStore.printCompletedLoadSheet(loadId)
                .then(data => {
                    FileSaver.saveAs(data, `${plane}-${planeLoadNum}-load-${loadNum}-loadsheet.pdf`);
                })
        }
        else {
            loadsStore.printLoadSheet(loadNum)
                .then(data => {
                    FileSaver.saveAs(data, `${plane}-${planeLoadNum}-load-${loadNum}-loadsheet.pdf`);
                })
        }
    }

    handleUpdateLoad = loadId => {
        document.getElementById(loadId).click();
        this.hideHeaderMenu()
    };
    hideHeaderMenu = () => {
        this.setState({ headerMenu: false })
    }
    returnHeaderClasses = () => {
        let { showPlaneLoadNumOnly } = this.state;
        let { loadObj, loadsToShow } = this.props;
        let heightClass = ''
        let headClass = ''
        if (window.innerWidth > 2200 && loadsToShow > 3) {
            heightClass = 'decHeight'
        }
        if (window.innerWidth > 1900 && window.innerWidth < 2200 && loadsToShow == 3) {
            { showPlaneLoadNumOnly ? headClass = "" : headClass = 'head-3' }
        }
        if (window.innerWidth > 1900 && window.innerWidth < 2200 && loadsToShow == 6) {
            headClass = 'head-6'
        }
        if (loadObj.gearUp) {
            if (window.innerWidth > 2200 && loadsToShow > 3) {
                return `load-header geared-up decHeight ${headClass}`
            } else {
                return `load-header geared-up ${headClass}`
            }
        }
        else {
            return `load-header ${heightClass} ${headClass}`
        }
    }
    renderTopText = () => {
        let { loadObj, isCompleted, loadsToShow } = this.props;
        let text = "OPEN";
        if (isCompleted) {
            text = 'COMPLETED';
            if (loadObj.inAirTime) {
                text += "\n" + (new Date(loadObj.inAirTime)).toLocaleTimeString();
            }
        }
        // else if (loadObj.totalJumpers >= loadObj.capacity) {
        //     text = "FULL"
        // }
        else if (loadObj.isWaitlist) {
            text = "WAIT LIST"
        }
        else if (loadObj.inAir && loadObj.currentAltitude === 0) {
            text = "IN AIR \n" + (new Date(loadObj.inAirTime)).toLocaleTimeString();
        }
        else if (loadObj.inAir && loadObj.currentAltitude > 0) {
            text = loadObj.currentAltitude + " FT";
        }
        else if (loadObj.gearUp && loadObj.timeRemaining > 180) {
            text = "GEAR UP!"
        }
        else if ((loadObj.gearUp && loadObj.timeRemaining <= 180)) {
            text = "NOW!"
        }
        else {
            if (loadObj.closed) {
                text = "CLOSED"
            }
            else if (loadObj.totalJumpers >= loadObj.capacity) {
                text = "FULL"
                if (loadObj.isNext) {
                    text = "NEXT LOAD" + ' ' + text
                }
            }
            else if (loadObj.isNext) {
                text = "NEXT LOAD"
            }
            else if (loadObj.totalJumpers < loadObj.capacity) {
                text = "OPEN"
            }
        }
        const topTxt = isCompleted ? "top-text-moreloads-completed" : "top-text-moreloads"
        return <p className={loadsToShow > 3 ? topTxt : "top-text"}>{text}</p>
    }
    renderRedText = () => {
        let { loadObj, loadsToShow } = this.props;
        let startClass = ''
        if (loadsToShow > 4) {
            startClass = 'small-font'
        }

        let text = null;
        if (loadObj.waitFuel) {
            text = "FUEL BEFORE"
        }
        return <p className={`red-text ${startClass}`}>{text}</p>

    }

    renderStartupText = () => {
        let { loadObj, loadsToShow } = this.props;
        let isStartupClass = ''
        if (loadsToShow > 4) {
            isStartupClass = 'small-font'
        }

        let text = null;
        if (loadObj.isStartup) {
            text = "STARTUP"
        }
        return <p className={`green-text ${isStartupClass}`}>{text}</p>

    }
    renderBottomText = () => {
        let { loadObj } = this.props;

        let text = "";
        if (loadObj.reserved) {
            text = "RESERVED"
        }
        // if (loadObj.isWaitlist && loadObj.totalJumpers >= loadObj.capacity) {

        if (loadObj.isReopened) {
            text += "\nREOPENED"
        }

        return <p className="bottom-text">{text}</p>
    }
    schoolWaitlistHeader = () => {
        return <div className="load-header school">
            <h3>School Waitlist</h3>
        </div>
    }
    isShowButtons = () => {
        if (this.state.role === "school" && !this.props.isRightMenu) {
            return false;
        }
        else {
            return true;
        }
    }
    toggleLoadStatus(closed) {
        if (closed) {
            loadsStore.openLoad(this.props.loadObj.loadNum).then(() => this.closeMenu())
        } else {
            loadsStore.closeLoad(this.props.loadObj.loadNum).then(() => this.closeMenu())
        }
    }
    toggleLoadTimerStatus(isPaused) {
        loadsStore.setStatus(this.props.loadObj.loadNum, { isPaused: !isPaused }).then(() => this.closeMenu())
    }
    toggleLoadFuelStatus(markFuel) {
        loadsStore.setStatus(this.props.loadObj.loadNum, { waitFuel: !markFuel }).then(() => this.closeMenu())
    }
    toggleStartupStatus(isStartup) {
        loadsStore.setStatus(this.props.loadObj.loadNum, { isStartup: !isStartup }).then(() => this.closeMenu())
    }
    toggleLoadTimeStatus(gearUp) {
        loadsStore.setStatus(this.props.loadObj.loadNum, { gearUp: !gearUp }).then(() => this.closeMenu())
    }
    toggleReserveStatus(reserved) {
        loadsStore.setStatus(this.props.loadObj.loadNum, { reserved: !reserved }).then(() => this.closeMenu())
    }
    toggleLoadNextStatus(isNext) {
        loadsStore.setStatus(this.props.loadObj.loadNum, { isNext: !isNext }).then(() => this.closeMenu())
    }
    toggleInAirStatus(inAir) {
        loadsStore.setStatus(this.props.loadObj.loadNum, { inAir: !inAir }).then(() => this.closeMenu())
    }
    setCompStatus() {
        var planeBody = {
            plane: "comp",
            registrationNumber: this.props.loadObj.registrationNumber
        }
        loadsStore.updatePlaneType(this.props.loadObj.loadNum, planeBody).then(() => this.closeMenu())
    }
    setCPCompStatus() {
        var planeBody = {
            plane: "cp comp",
            registrationNumber: this.props.loadObj.registrationNumber
        }
        loadsStore.updatePlaneType(this.props.loadObj.loadNum, planeBody).then(() => this.closeMenu())
    }
    getsecondClass = () => {
        let secondClass = "second"
        if (this.props.loadsToShow > 3) {
            secondClass = "second-moreloads"
        }
        if (this.props.loadsToShow > 6) {
            secondClass = "second-moreloads ten-loads"
        }

        return secondClass
    }




    renderLoadNum = (loadObj, classname, flexClass, showPlaneLoadNumOnly, loadsToShow) => {
        return (
            <div className={showPlaneLoadNumOnly ? `left-side1 ${flexClass}`
                : `left-side ${flexClass}`}>
                <p className={`load-no ${classname}`} >
                    <EditableTimer
                        timeRemaining={loadObj.loadNum}
                        loadNum={loadObj.loadNum}
                        load={loadObj}
                        isPause={loadObj.isPaused}
                        type="loadNum"
                    />
                </p>
            </div>
        )
    }

    renderPlanenLoadNum = (loadObj, loadsToShow, showPlaneLoadNumOnly, flexClass) => {

        return (
            <div style={showPlaneLoadNumOnly ? {} : { marginRight: 4, marginLeft: loadsToShow < 3 ? '10%' : 0 }}
                className={showPlaneLoadNumOnly ? `left-side ${flexClass} showConfigTruePlanenLoadNum ` : this.getsecondClass()}>
                <EditableLoadPlane
                    planeColor={loadObj.planeColor}
                    Plane={loadObj.plane}
                    Tail={loadObj.registrationNumber}
                    loadNum={loadObj.loadNum}
                    planeLoadNum={loadObj.planeLoadNum}
                    tailLoadNum={loadObj.tailLoadNum}
                    isCompleted={this.props.isCompleted}
                    showPlaneLoadNumOnly={showPlaneLoadNumOnly}
                />
            </div>
        )
    }

    render() {
        let { loadObj, isRightMenu, loadsToShow } = this.props;

        var showPlaneLoadNumOnly = this.props.isDisplayPlaneNameLeft

        const posClass = loadsToShow > 3 ? 'left' : 'right'
        let classname = "";
        let flexClass = ''
        if (loadsToShow === 2) classname = "load-two"
        if (loadsToShow === 3) classname = "load-three"
        if (loadsToShow === 4) classname = "load-four"
        if (loadsToShow === 5) classname = "load-five"
        if (loadsToShow === 6) classname = "load-six"

        if (loadsToShow > 6) {
            classname = "load-small-size";
            flexClass = "flex-class";
        }
        if (window.innerWidth > 2200 && loadsToShow > 6) {
            flexClass = "flex-class"
        }
        return (
            isRightMenu ? this.schoolWaitlistHeader()
                :
                <div className={this.returnHeaderClasses()}>
                    {showPlaneLoadNumOnly ? this.renderPlanenLoadNum(loadObj, loadsToShow, showPlaneLoadNumOnly, flexClass)
                        : this.renderLoadNum(loadObj, classname, flexClass, showPlaneLoadNumOnly, loadsToShow)}

                    <div className={loadObj.gearUp ? "middle-side fe-pulse" : "middle-side"}>
                        <span style={{ color: loadObj.planeColor }}>{this.renderTopText()}</span>
                        {this.renderRedText()}
                        {this.renderStartupText()}
                        {this.renderBottomText()}
                    </div>

                    <div className="right-side">
                        <div className={"top-conatiner"} >
                            {showPlaneLoadNumOnly ? this.renderLoadNum(loadObj, classname, flexClass, showPlaneLoadNumOnly, loadsToShow)
                                : this.renderPlanenLoadNum(loadObj, loadsToShow, showPlaneLoadNumOnly, flexClass)}

                            <div className="first" style={{ marginLeft: "auto" }}>
                                {/* <p className="plane-img"></p> */}
                                <button onClick={this.toggleHeaderMenu}>
                                    <i className="fa fa-ellipsis-v"></i>
                                </button>
                            </div>
                        </div>
                        <div className={showPlaneLoadNumOnly ? "third_showConfigT" : "third d-flex"}>
                            {/* <p className="small-skydiver"></p> */}
                            <p className={loadsToShow > 4 ? "third-loadmore" : ""} >
                                {loadObj.totalJumpers}/ <EditableTimer
                                    timeRemaining={loadObj.capacity}
                                    loadNum={loadObj.loadNum}
                                    isPause={loadObj.isPaused}
                                    type="maxCapacity"
                                />
                            </p>
                        </div>
                    </div>

                    {this.state.headerMenu && (
                        <ul className={`menu-header-option ${posClass}`}

                        >
                            <li>
                                <span
                                    onClick={() =>
                                        this.handleCancelLoad(
                                            "cancel-load" + loadObj.loadNum
                                        )
                                    }
                                >
                                    Cancel Load
                                </span>
                                {/* <CancelLoad loadNum={loadObj.loadNum} /> */}
                            </li>
                            {this.props.isCompleted ?
                                <li>
                                    <span
                                        onClick={() =>
                                            this.handleReopenLoad(loadObj._id)
                                        }
                                    >
                                        Reopen Load
                                    </span>
                                </li>
                                : null
                            }
                            <li>
                                {/* <LoadCapacity loadNum={loadObj.loadNum} /> */}
                                <span
                                    onClick={() =>
                                        this.handleSendLoadSheet(loadObj.loadNum)
                                    }
                                >
                                    Send Load Sheet
                                </span>
                            </li>
                            {/* Print pdf */}
                            {

                            }
                            <li>
                                {/* <LoadCapacity loadNum={loadObj.loadNum} /> */}
                                <span
                                    onClick={() =>
                                        this.handlePrintLoadSheet(loadObj.loadNum, loadObj.plane, loadObj.planeLoadNum, loadObj._id, this.props.isCompleted)
                                    }
                                >
                                    Print Load Sheet
                                </span>
                            </li>

                            <li>
                                {/* <LoadCapacity loadNum={loadObj.loadNum} /> */}
                                <span
                                    onClick={() =>
                                        this.handleUpdateLoad(
                                            "load-capacity" + loadObj.loadNum
                                        )
                                    }
                                >
                                    Update Capacity
                                </span>
                            </li>
                            <li>
                                {/* <LoadCapacity loadNum={loadObj.loadNum} /> */}
                                <span
                                    onClick={() =>
                                        this.handleUpdateLoad(
                                            "load-base-price" + loadObj.loadNum
                                        )
                                    }
                                >
                                    Update Base Price
                                </span>
                            </li>

                            {
                                !this.props.isCompleted &&
                                    !this.props.loadObj.isWaitlist &&
                                    ((this.props.loadObj.groupId !== undefined &&
                                        this.props.loadObj.isMaster) ||
                                        this.props.loadObj.groupId === undefined) &&
                                    this.isShowButtons() ? (
                                    <Fragment>
                                        <li
                                            onClick={() => this.setCompStatus()}
                                        >
                                            <span>
                                                COMP LOAD
                                            </span>
                                        </li>
                                        <li
                                            onClick={() => this.setCPCompStatus()}
                                        >
                                            <span>
                                                CP COMP LOAD
                                            </span>
                                        </li>
                                        <li
                                            onClick={() => this.toggleLoadStatus(this.props.loadObj.closed)}
                                        >
                                            <span>
                                                {this.props.loadObj.closed ? "OPEN LOAD" : "CLOSE LOAD"}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() =>
                                                this.toggleLoadTimerStatus(this.props.loadObj.isPaused)
                                            }
                                        >
                                            <span>
                                                {this.props.loadObj.isPaused ? "START TIMER" : "STOP TIMER"}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() =>
                                                this.toggleLoadFuelStatus(this.props.loadObj.waitFuel)
                                            }
                                        >
                                            <span>
                                                {this.props.loadObj.waitFuel ? "FUEL DONE" : "FUELING BEFORE"}
                                            </span>
                                        </li>
                                        {window.ENV?.arizona ?
                                            <li
                                                onClick={() =>
                                                    this.toggleStartupStatus(this.props.loadObj.isStartup)
                                                }
                                            >
                                                <span>
                                                    {this.props.loadObj.waitFuel ? "NOT STARTUP" : "STARTUP"}
                                                </span>
                                            </li>
                                            :
                                            null
                                        }
                                        <li
                                            onClick={() =>
                                                this.toggleLoadTimeStatus(this.props.loadObj.gearUp)
                                            }
                                        >
                                            <span>
                                                {this.props.loadObj.gearUp ? "CANCEL GEAR" : "GEAR UP"}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() =>
                                                this.toggleReserveStatus(this.props.loadObj.reserved)
                                            }
                                        >
                                            <span>
                                                {this.props.loadObj.gearUp ? "NOT RESERVE" : "EVENT"}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() =>
                                                this.toggleLoadNextStatus(this.props.loadObj.isNext)
                                            }
                                        >
                                            <span>
                                                {this.props.loadObj.isNext ? "NOT NEXT" : "NEXT LOAD"}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() =>
                                                this.toggleInAirStatus(this.props.loadObj.inAir)
                                            }
                                        >
                                            <span>
                                                {this.props.loadObj.inAir ? "NOT IN AIR" : "IN AIR"}
                                            </span>
                                        </li>
                                        {/* <li> */}
                                        <span onClick={() => this.setState({ isCompleteLoad: true })} >
                                            <CompleteLoad closeIsComplete={this.closeIsComplete} load={this.props.loadObj} />

                                            {/* {this.props.loadObj.inAir ? "NOT IN AIR" : "IN AIR"} */}
                                        </span>
                                        {/* </li> */}
                                    </Fragment>
                                ) : null
                            }
                        </ul>
                    )}
                </div>
        )
    }
}
