/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';
import AccountApi from '../../../services/accountapi';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import ItemModal from '../../accounts/modals/customModal';
import reservationStore from '../../../stores/reservation-store';
import Api from '../../../services/reservationapi';
import { ClipLoader } from 'react-spinners';
import schedulesStore from '../../../stores/schedule-store';
import jumpersStore from '../../../stores/jumpers-store';

import accountStore from '../../../stores/account-store';
import swal from 'sweetalert';
import loadsStore from '../../../stores/loads-store';
import { makeid } from '../../../utils/makeUniqueId';

class DeleteReservation extends Component {

  constructor(props) {
    super(props);
    this.ReservationId = '';
    this.reservationInfo = '';
    this.slotId = '';
    this.totalAmount = '';
    this.itemarr = {};
    this.itemId = '';
    this.currentSlotDate = '';
    this.state = {
      isModalOpen: false,
      modalMode: 'settled',
      errors: {},
      isloading: false,
      TransactionId: '',
      TransactionDateTime: '',
      amount: this?.props?.reservation?.tandemStudents?.reduce(
        (previousValue, currentValue) => previousValue + (currentValue.totalVideoPaid + currentValue.totalTandemPaid) || 0,
        0
      ),
      slotId: '',
      paidList: [],
      tandemStu: this.props?.reservation?.tandemStudents,
      itemized: this.props.reservation.itemized,
      refundAmount: 0,
      refundTax: 0,
      totalFees: 0,
    }
  }

  componentDidMount() {
    const { reservation } = this.props;

    let itemizedData = reservation.itemized && Object.entries(reservation?.itemized)?.map(item => {
      return item[1]?.map((data) => {
        return { ...data, refundAmount: 0, refundTax: 0 }
      })
    })
    this.setState({ itemized: itemizedData })

  }

  toggleModal = (e) => {
    //alert();
    //let itemizedStuds = jumpersStore.getTandemStudentsByIds(ids);
    //console.log(itemizedStuds);

    /*
    if (this.state.isModalOpen === false) {
      if (window.ENV?.processorInfo.processor == "transnational" || window.ENV?.processorInfo.processor == "square") {
        this.setState({
          modalMode: 'settled',
        });

      }
      else if (window.ENV?.processorInfo.processor == "nab") {
        this.setState({
          modalMode: 'settled',
        });
      }
    }*/

    if (this.props.reservation.itemized) {
      var tandemIds = Object.keys(this.props.reservation.itemized);
      jumpersStore.loadTandems(tandemIds)
        .then(data => {
          console.log('loaded tandems');
          console.log(this.props?.reservation?.tandemStudents);
          console.log(data.jumpers);
          //let itemizedStuds = jumpersStore.getTandemStudentsByIds(ids);
          let itemizedData = this.props.reservation.itemized && Object.entries(this.props.reservation?.itemized)?.map(item => {
            return item[1]?.map((data) => {
              return { ...data, refundAmount: 0, refundTax: 0 }
            })
          })
          this.setState({ itemized: itemizedData, tandemStu: data.jumpers, isModalOpen: !this.state.isModalOpen, })

        })
    }
    else{
      this.setState({
        isModalOpen: !this.state.isModalOpen,
      });
    }
  }


  handleValidation(name) {
    let fields = this.state;
    let errors = fields.errors;
    if (fields[name] === '') errors[name] = "This field is required";
    else if (name === 'amount' && !fields[name].match(/((\d+)(\.\d{2}))$/)) errors[name] = "Amount should be enter in two decimal places.";
    else errors[name] = "";
    this.setState({ errors: errors });
  }

  handleAmountInput = (event, key) => {
    var cache = { ...this.state };
    cache[key] = event.target.value;
    this.setState({ ...cache }, () => {
      this.handleValidation(key);
    });
  };

  // createAuthorizeCaptureBody = (reservation) => {
  //   return {
  //     //"$type": "AuthorizeAndCaptureTransaction,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
  //     // SessionToken: reservationStore.getTokENV?.l(),
  //     // ApplicationProfileId: reservationStore.getApplicationProfileId(),
  //     // MerchantProfileId: reservationStore.getMerchantProfileId(),
  //     "$type": "ReturnById,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
  //     "ApplicationProfileId": reservationStore.getApplicationProfileId(),
  //     "MerchantProfileId": reservationStore.getMerchantProfileId(),
  //     "DifferenceData": {
  //       "$type":
  //         "BankcardReturn,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
  //       "TransactionId": reservation.transactionId,
  //       "TransactionDateTime": reservation.TransactionDateTime,
  //       "Amount": reservation.totalPaid.toFixed(2),
  //       "Addendum": null
  //     }
  //   }
  // }


  // cancelAuthorizeCaptureBody = (reservation) => {
  //   return {
  //     "$type": "Undo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Rest",
  //     "ApplicationProfileId": reservationStore.getApplicationProfileId(),
  //     "DifferenceData": {
  //       "$type":
  //         "BankcardUndo,http://schemas.ipcommerce.com/CWS/v2.0/Transactions/Bankcard",
  //       "TransactionId": reservation.transactionId,
  //       "Addendum": null
  //     }
  //   }
  // }

  processPaymentResponse = (r) => {
    var refundAmount = r.data.Amount;
    if (r.data !== undefined && r.data.Status === "Successful") {
      reservationStore.DeleteReservation(this.ReservationId, refundAmount, this.itemarr).then((res) => {
        var payload = {
          date: this.currentSlotDate,
          id: this.slotId
        }
        schedulesStore.fetchReservation(payload);
        this.setState({
          isloading: false
        })
      });
    }
    else {
      swal({
        title: "Error",
        text: "Something failed, please try again",
        icon: "error",
        dangerMode: true,
      });
      this.setState({
        isloading: false
      })
    }
  }

  processPaymentResponseTrans = (r, refundAmount, refundSubtotal, refundTax, refundBookingfees) => {
    if (r.data.status === "success") {
      var payload = {
        refundAmount: refundAmount,
        refundSubtotal: refundSubtotal,
        refundTax: refundTax,
        refundBookingfees: refundBookingfees,
        itemized: this.itemarr
      }
      reservationStore.DeleteReservation(this.ReservationId, payload).then((res) => {
        var payload = {
          date: this.currentSlotDate,
          id: this.slotId
        }
        schedulesStore.fetchReservation(payload);
        this.setState({
          isloading: false
        })
      });
    }
    else {
      swal({
        title: "Error",
        text: "Something failed, please try again",
        icon: "error",
        dangerMode: true,
      });
      this.setState({
        isloading: false
      })
    }
  }

  processPaymentResponseCardConnect = (r, refundAmount) => {
    console.log(r)
    if (r.data.respstat !== "C" && r.data.respstat !== "B") {
      reservationStore.DeleteReservation(this.ReservationId, refundAmount).then((res) => {
        var payload = {
          date: this.currentSlotDate,
          id: this.slotId
        }
        schedulesStore.fetchReservation(payload);
        this.setState({
          isloading: false
        })
      });
    }
    else {
      //alert('something failed, please try again');
      swal({
        title: "Error",
        text: "Something failed, please try again",
        icon: "error",
        dangerMode: true,
      });
      this.setState({
        isloading: false
      })
    }
  }

  // processUndoResponse = (r) => {
  //   if (r.status !== undefined && r.status === 200) {
  //     reservationStore.DeleteReservation(this.ReservationId).then((res) => {
  //       var payload = {
  //         date: this.currentSlotDate,
  //         id: this.slotId
  //       }
  //       schedulesStore.fetchReservation(payload);
  //     });
  //   }
  //   else {
  //     if (r.status === 400) {
  //       if (reservation !== undefined) {
  //         var reservation = this.reservationInfo;
  //         var body = this.createAuthorizeCaptureBody(reservation);
  //         var response = Api.authorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), body).then(this.processPaymentResponse);
  //       }
  //     }
  //   }
  // }
  processRefundUndoResponse = (r) => {
    console.log(this.props.charge);
    if (r.status !== undefined && r.status === 200) {
      //alert('Refund Successfully');
      //const { chargeValue, jumperId } = this.props;

      //console.log(this.props.chargeValue);

      reservationStore.DeleteReservation(this.ReservationId).then((res) => {
        var payload = {
          date: this.currentSlotDate,
          id: this.slotId
        }
        schedulesStore.fetchReservation(payload);
      });

      // var body = {
      //   transactionType: chargeValue.transactionType,
      //   transactionId: chargeValue.transactionId,
      //   amount: -chargeValue.amount,
      //   isRefund: true
      // };
      // accountStore.ChargeJumper(jumperId, body);

    }
    else {
      if (r.status === 400) {
        var transactionId = this.transactionId;
        var amount = this.totalAmount;
        // var body = AccountApi.transferAuthorizeCaptureBody(transactionId, amount);
        // var response = Api.authorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), body).then(this.processPaymentResponse);
        var body = {
          transactionId: transactionId,
          refundAmount: amount
        };

        console.log(body);
        var response = Api.authorizeAndCapture("refund", body).then(this.processPaymentResponse);
      }
    }
    this.setState({
      isloading: false,
      isModalOpen: false
    })
  }
  // processRefundPaymentResponse = (r) => {
  //   if (r.data !== undefined && r.data.Status === "Successful") {
  //     alert('Refund Successfully');
  //     const { chargeValue, jumperId } = this.props;

  //     console.log(this.props.chargeValue);

  //     // var body = {
  //     //   transactionType: chargeValue.transactionType,
  //     //   transactionId: chargeValue.transactionId,
  //     //   amount: -chargeValue.amount,
  //     //   isRefund: true
  //     // };
  //     // accountStore.ChargeJumper(jumperId, body);
  //   }
  //   else {
  //     alert('something failed, please try again');
  //   }
  //   this.setState({
  //     isloading: false,
  //     isModalOpen: false
  //   })
  // }

  handleTotalRefund = (event, key, detail) => {
    const { reservation } = this.props;
    const values = [...this.state.paidList];
    let tandemStudents = this.state.tandemStu;
    if (event.target.name === "video_paid") {
      tandemStudents[key].totalVideoPaid = Number(event.target.value)
    }
    else {
      tandemStudents[key].totalTandemPaid = Number(event.target.value)
    }
    this.setState({ tandemStu: tandemStudents });
    let index = values.findIndex(i => Object.keys(i)[0] === key)
    if (index >= 0) {
      values.splice(index, 1);
    }
    else {
      let obj = { [key]: event.target.value };
      values.push(obj);
    }
    const initialValue = 0;
    const totalValue = reservation.tandemStudents.reduce(
      (previousValue, currentValue) => previousValue + (currentValue.totalVideoPaid + currentValue.totalTandemPaid) || 0,
      initialValue
    );
    const totalRefundVal = this.state.tandemStu.reduce(
      (previousValue, currentValue) => previousValue + (currentValue.totalVideoPaid + currentValue.totalTandemPaid) || 0,
      initialValue
    );
    this.setState({ amount: totalRefundVal })
  }

  itemizedData = () => {
    if (this.props.reservation.itemized) {
      let itemizedData = this.state.itemized;
      let oldItemizedData = this.props.reservation.itemized;
      let ids = {};
      let obj = {};
      itemizedData.forEach((item, ind) => {
        let allItemData = {};
        let key = Object.keys(oldItemizedData);
        let itemData = []
        item.forEach((i) => {
          let data = {}
          data.item = i.item
          data.qty = i.qty
          data.price = i.price
          data.tax = i.tax
          data.refundPrice = Number(i.refundAmount)
          data.refundTax = Number(i.refundTax)
          itemData.push(data)
        })
        allItemData[key[ind]] = itemData;
        obj = { ...obj, ...allItemData }
      })
      return obj;
    }
    else {
      return {}
    }
  }

  afterCancelReservation = (resRefund, reservationId) => {
    if (resRefund.status && resRefund.status != 400) {
      var payload = {
        date: this.currentSlotDate,
        id: this.slotId
      }
      if (this.props.showSingle) {
        let val = {
          value: reservationId
        }
        this.props.handleSelect(val);
        //reservationStore.getReservation(reservationId)
        //schedulesStore.getReservation(reservationId);
      }
      else {
        schedulesStore.fetchReservation(payload);
        schedulesStore.fetchData(this.currentSlotDate);
      }
      this.setState({
        isloading: false,
        isModalOpen: false
      })
    } else {
      swal({
        title: "Error",
        text: "Something failed, please try again",
        icon: "error",
        dangerMode: true,
      });
      this.setState({
        isloading: false
      })
    }
  }

  handleSubmit = (values, reservation, currentDate) => {
    this.reservationInfo = reservation;
    this.slotId = reservation._id;
    this.currentSlotDate = currentDate;
    this.ReservationId = reservation._id;
    this.totalAmount = values.amount;
    if (this.state.itemized) {
      this.itemarr = this.itemizedData();
    }
    var totalRefundAmount = Number(this.state.refundAmount) + Number(this.state.refundTax) + Number(this.state.totalFees);

    if (isNaN(totalRefundAmount)) {
      swal({
        title: "Error",
        text: "Please Enter Valid Numbers",
        icon: "error",
        dangerMode: true,
      });
      return
    }
    console.log(this.itemarr);
    console.log(this.state.itemized);
    this.setState({
      isloading: true
    })

    if (reservation.transactionId != "Transaction123") {
      // this.ReservationId = reservation._id;
      // var body = this.cancelAuthorizeCaptureBody(reservation);
      // var response = Api.cancelAuthorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), reservation.transactionId, body).then(this.processUndoResponse);

      var amount = values.amount;

      // var tId = reservation.transactionId;
      // this.transactionId = tId;
      // if (tId != "") {
      //   var body = AccountApi.cancelAuthorizeCaptureBody(tId);
      //   var response = Api.cancelAuthorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), tId, body).then(this.processRefundUndoResponse);
      // }

      var transactionId = reservation.transactionId;
      if (window.ENV?.processorInfo.processor == "nab") {
        // var body = AccountApi.transferAuthorizeCaptureBody(transactionId, amount);
        // console.log('about to cancel');
        // var response = Api.authorizeAndCapture(reservationStore.getTokENV?.l(), reservationStore.getServiceId(), body).then(this.processPaymentResponse);
        var body = {
          transactionId: transactionId,
          refundAmount: amount
        };

        console.log(body);
        var response = Api.authorizeAndCapture("refund", body).then(this.processPaymentResponse);
      }
      else if (window.ENV?.processorInfo.processor == "transnational" || window.ENV?.processorInfo.processor === "square") {
        console.log(this.totalAmount);
        const payload = {
          totalRefundAmount,
          refundAmount: this.state.refundAmount,
          refundTax: this.state.refundTax,
          refundBookingfees: this.state.totalFees,
          itemized: this.itemarr,
          reservationId: this.ReservationId
        }
        var responseVoid = Api.transAuthorizeAndCaptureRefundRes(transactionId, payload).then((resRefund) => {
          this.afterCancelReservation(resRefund, this.ReservationId);
        });
      }
      else if (window.ENV?.processorInfo.processor === "cardConnect") {
        var response = Api.cardConnetcGetTransaction(transactionId)
          .then(res => {
            if (res.data.setlstat === "Queued for Capture" || res.data.setlstat === "Authorized") {
              var payload = {
                "retref": transactionId,
                // "amount":amount.toString()
              }
              var responseVoid = Api.cardConnetVoidTransaction(payload).then((resVoid) => {
                this.processPaymentResponseCardConnect(resVoid, amount)
              });
            }
            else if (res.data.setlstat != "Void") {
              var payload = {
                "retref": transactionId,
                "amount": amount.toString()
              }
              var responseRefund = Api.cardConnetRefundTransaction(payload).then((resRefund) => {
                this.processPaymentResponseCardConnect(resRefund, amount)
              })

            }
          })
      }
    }
    else {
      reservationStore.DeleteReservation(reservation._id).then((res) => {
        var payload = {
          date: this.currentSlotDate,
          id: this.slotId
        }
        schedulesStore.fetchReservation(payload);

        this.setState({
          isloading: false,
          isModalOpen: false
        })
      });
    }
  }

  handleTotalAmount = (event, ind, index) => {
    if (event.target.name === "refundAmount") {
      const itemized_data = this.state.itemized;
      itemized_data[ind][index].refundAmount = event.target.value;
      itemized_data[ind][index].refundTax = Number(((Number(itemized_data[ind][index].tax) / Number(itemized_data[ind][index].price)) * event.target.value).toFixed(2))
      this.setState({ itemized: itemized_data },
        () => {
          let totalAmount = 0;
          let totalTax = 0;
          this.state.itemized && Object.entries(this.state.itemized)?.forEach((item) => {
            item[1]?.forEach((detail) => {
              let amount = Number(detail.refundAmount);
              let tax = Number(((Number(detail?.tax) / Number(detail?.price)) * detail?.refundAmount).toFixed(2));
              totalAmount += amount;
              totalTax += tax;
              console.log(amount,)
            })
          })
          this.setState({
            refundAmount: totalAmount,
            refundTax: totalTax
          })
        })
    }
    else if (event.target.name === "partiallRefundAmount" && !this.state.itemized) {
      let amount = event.target.value;
      this.state.refundAmount = amount;
      this.setState({ refundAmount: this.state.refundAmount })
    }
    else if (event.target.name === "partialRefundTax" && !this.state.itemized) {
      let amount = event.target.value;
      this.state.refundTax = amount;
      this.setState({ refundTax: this.state.refundTax })
    }
    if (event.target.name === "totalFees") {
      let fees = event.target.value;
      this.state.totalFees = fees;
      this.setState({ totalFees: this.state.totalFees })
    }
  }


  render() {
    const { reservation, currentDate } = this.props;
    const { tandemStu } = this.state;
    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    let totalAmount;
    const initialValue = 0;
    
    return (
      <React.Fragment>
        <ItemModal
          isModalOpen={this.state.isModalOpen}
          title={`Delete Reservation`}
        >

          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>

          <LocalForm onSubmit={(values) => this.handleSubmit(values, reservation, currentDate)}>
            {this.state.modalMode === "settled" ?
              <>
                {this.props.reservation.itemized &&
                  <table>
                    <Row className="form-group">
                      <Col md={2}>Name</Col>
                      <Col md={2}>Item</Col>
                      <Col md={2}>Amount</Col>
                      <Col md={2}>Tax</Col>
                      <Col md={2}>Refund Amount</Col>
                      {loadsStore.isTaxEnabled() &&
                        <Col m={2}>Refund Tax</Col>
                      }
                    </Row>
                    {this.state.itemized && Object.entries(this.state.itemized)?.map((item, ind) => {
                      return item[1].map((data, index) => {
                        return (
                          <Row key={index}>
                            {reservation?.evenCategory === "AFF" ? <Col md={2}>{reservation?.students[ind]?.firstname + " " + reservation?.students[ind]?.lastname}</Col> :

                              <Col md={2}>{tandemStu[ind]?.firstname + " " + tandemStu[ind]?.lastname}</Col>}
                            <Col md={2}>{data.item}</Col>
                            <Col md={2}>{Number(data.price).toFixed(2)}</Col>
                            <Col md={2}>{Number(data.tax).toFixed(2)}</Col>
                            <Col md={2} className="amountInput">
                              <Control.input name="refundAmount" model={`.refundAmount-${ind - index}`} className="form-control"
                                value={data.refundAmount} onChange={(e) => this.handleTotalAmount(e, ind, index)}
                              />
                            </Col>
                            {loadsStore.isTaxEnabled() && <Col md={2} className="amountInput">
                              <Control.input name="refundTax" model={`.refundTax-${ind - index}`} className="form-control"
                                value={data.refundTax} onChange={(e) => this.handleTotalAmount(e, ind, index)}
                              />
                            </Col>}
                          </Row>
                        )
                      })
                    })}
                  </table>
                }
                <Row className="refundInput">
                  <Label htmlFor="partialRefund" md={3}>Refund amount</Label>
                  <Col md={9}>
                    <Control.input name="partiallRefundAmount" model=".partiallRefundAmount" className="form-control"
                      value={(this.state.refundAmount)}
                      onChange={(e) => this.handleTotalAmount(e)}
                    />
                  </Col>
                </Row>
                {loadsStore.isTaxEnabled() &&
                  <Row>
                    <Label htmlFor="partialRefund" md={3}>Refund Tax Amount</Label>
                    <Col md={9} style={{ paddingTop: "13px" }}>
                      <Control.input name="partialRefundTax" model=".partialRefundTax" className="form-control"
                        value={(this.state.refundTax)}
                        onChange={(e) => this.handleTotalAmount(e)}
                      />
                    </Col>
                  </Row>
                }
                <Row>
                  <Label htmlFor="partialRefund" md={3} style={{ paddingTop: "20px" }}>Total Fees</Label>
                  <Col md={9} style={{ paddingTop: "13px" }}>
                    <Control.input name="totalFees" model=".totalFees" className="form-control"
                      value={this.state.totalFees} onChange={(e) => this.handleTotalAmount(e)} autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Label htmlFor="totalAmount" md={3} style={{ paddingTop: "20px" }}>Total Refund Amount</Label>
                  <Col md={9} style={{ paddingTop: "13px" }}>
                    <span>{(Number(this.state.refundAmount) + Number(this.state.refundTax) + Number(this.state.totalFees)).toFixed(2)}</span>
                  </Col>
                </Row>
              </>
              : ""
            }

            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 9 }}>
                {
                  this.state.modalMode === "pending" || (!this.state.errors["amount"] && this.state.amount !== '') ?
                    <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                    :
                    <button className="btn btn-bg lightColor createButton" type="submit" disabled>SUBMIT</button>
                }
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>

          </LocalForm>
        </ItemModal>

        {/* <button className="btn customButton rightButton" style={{ borderRadius: '4px', backgroundColor: '#A7F6FF', color: '#082764' }} onClick={() => this.toggleModal()}>
          Refund
				</button> */}

        {(this.props.suspendReservation) ? "" : <span style={{ position: 'absolute', cursor: 'pointer', color: 'blue', right: '33px', top: '24px' }} onClick={() => this.toggleModal()}>
          <i className="fa fa-close" ></i>
        </span>}
      </React.Fragment>
    );
  }
}

export default DeleteReservation
