/**
 * http://usejsdoc.org/
 */
import React, { Component } from 'react';


import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';

import jumpersStore from '../../../stores/jumpers-store';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';
import AdminModal from './customModal';
import { ClipLoader } from 'react-spinners';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

var listStyle = { display: 'inline-block', marginRight: '5px', backgroundColor: '#a0c2df', fontSize: '12px', padding: '3px', borderRadius: '5px', marginBottom: '1px' }

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 30
  }),
  menu: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
    height: 730
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    padding: 20,
    minHeight: 700
  })
};


class CreateJumper extends Component {

  constructor(props) {
    super(props);
    this.errortoggle = this.errortoggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleJumperCreated = this.handleJumperCreated.bind(this);
    this.state = {
      selectedOption: {

      },
      firstname: '',
      lastname: '',
      nickname: '',
      uspaMemberNumber: '',
      isPayOutCharge: false,
      isModalOpen: false,
      isloading: false,
      isError: false,
      uspaExpiry: '',
      WaiverDate: '',
      dob: '',
      reserveRepackDate: '',
      jumperOptions: [],
      adminDefaultOptions: [],
      chargeTo: '',
      items: [
        { text: 'A', checked: false, ratingNumber: '' },
        { text: 'B', checked: false, ratingNumber: '' },
        { text: 'C', checked: false, ratingNumber: '' },
        { text: 'D', checked: false, ratingNumber: '' },
        { text: 'TANDEM INSTRUCTOR', checked: false, ratingNumber: '' },
        { text: 'AFF INSTRUCTOR', checked: false, ratingNumber: '' },
        { text: 'PILOT', checked: false, ratingNumber: '' },
        { text: 'COACH', checked: false, ratingNumber: '' },
        { text: 'SOLO', checked: false, ratingNumber: '' },
        { text: 'AFF STUDENT', checked: false, ratingNumber: '' },
        { text: 'TANDEM STUDENT', checked: false, ratingNumber: '' },
        { text: 'VIDEOGRAPHER', checked: false, ratingNumber: '' },
        { text: 'PROVISIONAL', checked: false, ratingNumber: '' },
        { text: 'PACKER', checked: false, ratingNumber: '' }
      ]
    }

    this.jumperEvents = [
      { name: jumpersStore.getEventPrefix('jumpersLoaded'), func: this.handleJumpersLoaded },
      { name: jumpersStore.getEventPrefix('jumperCreated'), func: this.handleJumperCreated }
    ];
  }

  handleJumpersLoaded = (jumperOptions) => {
    var adminDefaultOptions = jumperOptions.slice(0, 50);
    this.setState({
      jumperOptions: jumperOptions,
      adminDefaultOptions: adminDefaultOptions
    });
  }

  handleChargeSelect = (val) => {
    if (val.value !== undefined) {
      this.setState({
        chargeTo: val.value._id
      });
    }
  }

  getJumperName = (jumperId) => {
    if (jumperId != '' && jumperId != undefined) {
      let jumperInfo = jumpersStore.getJumperById(jumperId);
      let JumperName = jumperInfo.firstname + ' ' + jumperInfo.lastname;
      return JumperName;
    }
    else {
      return '';
    }
  }

  checkForNegatives = (price) => {
    //console.log('check for negatives: ' + price);
    if (price != undefined) {
      let priceCell = (price < 0) ?
        {
          value: `($${Math.abs(price)})`
        } :
        {
          value: `$${price}`
        }
      if (price < 0) {
        return <span style={{ color: 'red' }}> {priceCell.value}</span>;
      }

      return <span>{priceCell.value}</span>;
    }
    else {
      return '';
    }
  }

  handleExpiryChange = (date) => {
    this.setState({
      uspaExpiry: date
    });
  }

  handleDobChange = (date) => {
    this.setState({
      dob: date
    });
  }

  handleWaiverChange = (date) => {
    this.setState({
      WaiverDate: date
    });
  }
  handleRepackDateChange = date => {
    this.setState({
      reserveRepackDate: date
    })
  }
  formatDate(input) {
    if (input.toString().length == 1) {
      return '0' + input;
    }
    else {
      return input;
    }
  }

  updateRating = () => {
    const ratings = this.state.selectedOption.ratings;
    var oldItems = this.state.items

    var newItems = []
    oldItems.map(index => {
      ratings.map(r => {
        if (index.text === r.rating) {
          newItems.push({ text: index.text, checked: true, ratingNumber: r.number })
        } else {
          newItems.push(index)
        }
      })
    })
    this.setState({
      items: newItems
    })
  }


  clearState = () => {
    var oldItems = this.state.items
    var newItems = []

    oldItems.map(index => {
      newItems.push({ text: index.text, checked: false, ratingNumber: '' })
    })

    this.setState({
      items: newItems,
      firstname: '',
      lastname: '',
      uspaMemberNumber: '',
      uspaExpiry: '',
      WaiverDate: '',
      dob: '',
      reserveRepackDate: '',
    })
  }

  toggleModal(e) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      jumpType: null
    });
  }

  componentDidMount = () => {
    //console.log('in components did mount accounts');
    this.jumperEvents.forEach((evnt) => jumpersStore.on(evnt.name, evnt.func));
  }

  componentWillUnmount = () => {
    this.jumperEvents.forEach((evnt) => jumpersStore.removeListener(evnt.name, evnt.func));
  }

  handleSubmit(values) {
    const { firstname, lastname, nickname, uspaMemberNumber } = this.state;

    //console.log("in handle submit load");
    //alert(JSON.stringify(values));
    this.setState({ isloading: true });
    var payload = {};
    let rating = this.state.items.filter(item => item.checked);
    //let rating = this.state.items.filter(item => item.checked);
    let ratingArray = [];

    // payload.firstname = values.firstname;
    // payload.lastname = values.lastname;
    // payload.uspaMemberNumber = values.uspaMemberNumber;

    payload.firstname = firstname;
    payload.lastname = lastname;
    payload.nickname = nickname;

    payload.uspaMemberNumber = uspaMemberNumber;

    for (var item in rating) {
      ratingArray.push({ "rating": rating[item].text, "number": rating[item].ratingNumber })
      if (rating[item].text === "COACH" || rating[item].text === "AFF INSTRUCTOR" || rating[item].text === "TANDEM INSTRUCTOR" || rating[item].text === "VIDEOGRAPHER") {
        payload.isStaff = true;
      }
    }



    if (values.manifestNumber != undefined) {
      payload.manifestNumber = values.manifestNumber;
    }

    if (this.state.WaiverDate != undefined) {
      payload.waiverDate = this.state.WaiverDate;
    }

    if (this.state.uspaExpiry != undefined) {
      payload.uspaExpiry = this.state.uspaExpiry;
    }

    if (this.state.dob != undefined) {
      payload.dob = this.state.dob;
    }

    if (values.email != undefined) {
      payload.email = values.email;
    }

    if (values.homePhone != undefined) {
      payload.homePhone = values.homePhone;
    }

    if (values.mobilePhone != undefined) {
      payload.mobilePhone = values.mobilePhone;
    }

    if (values.noPayOrCharge != undefined) {
      payload.noPayOrCharge = values.noPayOrCharge;
    }

    if (values.multiManifest != undefined) {
      payload.multiManifest = values.multiManifest;
    }

    if (values.isStaff != undefined) {
      payload.isStaff = values.isStaff;
    }


    if (values.discountRate != undefined) {
      payload.discountRate = values.discountRate;
    }

    if (values.llcPay != undefined) {
      payload.llcPay = values.llcPay;
    }

    if (this.state.chargeTo != undefined && this.state.chargeTo != '') {
      payload.chargeTo = this.state.chargeTo;
    }
    if (this.state.reserveRepackDate != undefined && this.state.reserveRepackDate != '') {
      payload.reserveRepackDate = this.state.reserveRepackDate;
    }

    payload.ratings = ratingArray;

    var that = this;
    Promise.all([jumpersStore.CreateJumper(payload)])
      .then(function (result) {
        var data = result[0];
        if (data !== false) {
          // console.log('Success')
          that.setState({
            isModalOpen: false,
            isError: false,
            isloading: false
          })
          that.clearState();
        }
        else {
          that.setState({
            isModalOpen: false,
            isError: true,
            isloading: false
          })
          setTimeout(() => {
            that.setState({ isError: false });
          }, 2000);
          // console.log('Fail to Save');
        }
      })
  }

  onToggle(index, e) {
    let newItems = this.state.items.slice();
    newItems[index].checked = !newItems[index].checked

    this.setState({ items: newItems })
  }

  handleJumperCreated(nothing) {
    this.setState({
      isloading: false
    })
    this.toggleModal();
  }

  renderCheckbox = () => {
    return this.state.items.map((item, index) => {
      var ratingName = item.text + "_" + index;
      var ratingText = "txt_" + item.text + "_" + index;

      return (
        <Row className="form-group" key={index}>
          <Col sm={1}>
            <input type="checkbox" id={ratingName} name={ratingName} onChange={this.onToggle.bind(this, index)} checked={item.checked} />
          </Col>

          <Col sm={4}>
            <label style={{ marginLeft: "10px" }}>{item.text}</label>
          </Col>
          <Col sm={7}>
            <input type="text" id={ratingText} name={ratingText} className="rating-input"
              style={{ width: "100% !important" }} onChange={this.handleChange.bind(this, index)} value={item.ratingNumber} />
          </Col>
        </Row>
      )
    })
  }

  onToggle(index, e) {
    let newItems = this.state.items.slice();
    newItems[index].checked = !newItems[index].checked
    this.setState({
      items: newItems
    })
    //console.log("Checkeditem" + JSON.stringify(this.state.items.filter(item => item.checked)));
  }

  handleChange(index, e) {
    let newItems = this.state.items.slice();
    newItems[index].ratingNumber = e.target.value
    this.setState({
      items: newItems
    })
    //console.log("Checkeditem" + JSON.stringify(this.state.items.filter(item => item.checked)));
  }

  filterJumpers = (inputValue, jumperOptions) => {
    //console.log("in filterJumpers");
    if (inputValue) {
      //console.log(inputValue);
      var filteredOptions = jumperOptions.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase()));
      ////console.log(filteredOptions);
      if (filteredOptions.length > 30) return filteredOptions = filteredOptions.slice(0, 30);
      else return filteredOptions;
    }
    //console.log('no inputValue');
    return jumperOptions.slice(0, 30);
  };

  promiseOptions = (inputValue) => {
    //console.log('in pomiseOptions');

    return new Promise(resolve => {

      jumpersStore.LoadGlobalJumperOptions(this.inputValue)
        .then(jumperOptions => {
          //console.log("jumper Options after api call");
          //console.log(jumperOptions);

          resolve(jumperOptions);
          // //console.log(jumperOptions);
        })
        .catch(err => {
          //console.log(err);
        })
    });
  }

  selectedOption = (s) => {
    this.setState({
      selectedOption: s.value,
      firstname: s.value.firstname,
      lastname: s.value.lastname,
      uspaMemberNumber: s.value.uspaMemberNumber

    }, () => this.updateRating())
  }

  handleInputChange = (newValue) => {
    //console.log('handleInputChange');
    //const inputValue = newValue.replace(/\W/g, '');
    ////console.log(inputValue);
    //this.promiseOptions(newValue, jumperOptions);
    this.inputValue = newValue;
    //return inputValue;
  }

  Option = (props) => {
    //console.log(props);
    return (
      <div className="row">
        <div className="col-12">
          <components.Option {...props} />
        </div>
      </div>
    );
  }


  errortoggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleInputValue = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {

    const { firstname, lastname, uspaMemberNumber } = this.state;

    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
        `;


    let AsyncSelectInput = (props) => (<AsyncSelect model={props.model} name={props.name} loadOptions={props.loadOptions} onChange={props.onChange}
      styles={props.styles} onInputChange={props.onInputChange}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#E7F2F6',
          // primary75: 'red',
          // primary50: 'blue',
          primary25: 'blue', // hover option
          // danger: 'green',
          dangerLight: '#59C5DC', // cross background
          neutral0: 'rgb(0, 65, 157)', // background container
          // neutral5: 'pink',
          neutral10: 'blue', // selected option background
          neutral20: '#E7F2F6', // border input without hover
          neutral30: '#E7F2F6', // border input with hover
          neutral40: 'orange',
          neutral50: '#E7F2F6', // default text
          neutral60: '#E7F2F6', // down icon
          neutral70: 'yellow',
          neutral80: '#E7F2F6', //selected text color
          // neutral90: 'rgb(0, 65, 157)',
        },
      })} />);

    return (
      <React.Fragment>
        <AdminModal
          isModalOpen={this.state.isModalOpen}
          title={`Create Jumper`}
        >

          <div className='complete-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fff'}
              loading={this.state.isloading}
            />
          </div>

          <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
            <Row className="form-group">
              <Label htmlFor="search" md={3}>Search Global: </Label>
              <Col md={9}>
                <Control.custom model=".jumpers" name="jumpers" className="form-control" component={AsyncSelectInput}
                  loadOptions={(newValue) => this.promiseOptions(newValue)}
                  mapProps={{ onChange: (props) => props.onChange }}
                  onChange={this.selectedOption}
                  onInputChange={(newValue) => this.handleInputChange(newValue)}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="firstname" md={3}>First Name</Label>
              <Col md={9}>
                <Control.input name="firstname" model=".firstname" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }}
                  value={this.state.firstname}
                  // onChange={(e) => this.setState(state => {
                  //   state.selectedOption.firstname = e.target.value
                  // })}
                  onChange={this.handleInputValue}
                  autoComplete="off" />
              </Col>
            </Row>

            <Row>
              <Label htmlFor="lastname" md={3}>Last Name</Label>
              <Col md={9}>
                <Control.input name="lastname" model=".lastname" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }}
                  value={this.state.lastname}
                  onChange={this.handleInputValue}
                  autoComplete="off" />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="nickname" md={3}>Nickname</Label>
              <Col md={9}>
                <Control.input name="nickname" model=".nickname" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }}
                  value={this.state.nickname}
                  // onChange={(e) => this.setState(state => {
                  //   state.selectedOption.firstname = e.target.value
                  // })}
                  onChange={this.handleInputValue}
                  autoComplete="off" />
              </Col>
            </Row>

            <Row>
              <Label htmlFor="Email" md={3}>Email</Label>
              <Col md={9}>
                <Control.input name="email" model=".email" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }}
                  //value={this.state.lastname}
                  //onChange={this.handleInputValue}
                  autoComplete="off" />
              </Col>
            </Row>

            <Row>
              <Label htmlFor="homePhone" md={3}>Mobile</Label>
              <Col md={9}>
                <Control.input name="mobilePhone" model=".mobilePhone" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }}
                  //value={this.state.lastname}
                  //onChange={this.handleInputValue}
                  autoComplete="off" />
              </Col>
            </Row>

            <Row>
              <Label htmlFor="UpsaMemberNumber" md={3}>UPSA Number</Label>
              <Col md={9}>
                <Control.input name="uspaMemberNumber" model=".uspaMemberNumber" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                  value={this.state.uspaMemberNumber}
                  onChange={this.handleInputValue}
                />
              </Col>
            </Row>

            <Row>
              <Label htmlFor="manifestNumber" md={3}>Manifest Number</Label>
              <Col md={9}>
                <Control.input name="manifestNumber" model=".manifestNumber" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                // value={this.state.uspaMemberNumber}
                // onChange={this.handleInputValue}
                />
              </Col>
            </Row>

            <Row>
              <Label htmlFor="dob" md={3}>Date of Birth:</Label>
              <Col md={9}>
                <DatePicker
                  selected={this.state.dob}
                  onChange={this.handleDobChange}
                  className="form-control"
                  name="dob"
                  model=".dob" />
              </Col>
            </Row>

            <Row>
              <Label htmlFor="uspaExpiry" md={3}>Uspa Expiry:</Label>
              <Col md={9}>
                <DatePicker
                  selected={this.state.uspaExpiry}
                  onChange={this.handleExpiryChange}
                  className="form-control"
                  name="uspaExpiry"
                  model=".uspaExpiry" />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="uspaExpiry" md={3}>Waiver Date:</Label>
              <Col md={9}>
                <DatePicker
                  selected={this.state.WaiverDate}
                  onChange={this.handleWaiverChange}
                  className="form-control"
                  name="waiverDate"
                  model=".waiverDate" />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="uspaExpiry" md={3}>Reserve repack Date:</Label>
              <Col md={9}>
                <DatePicker
                  selected={this.state.reserveRepackDate}
                  onChange={this.handleRepackDateChange}
                  className="form-control"
                  name="reserveRepackDate"
                  model=".reserveRepackDate"
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="discountRate" md={3}>Discount</Label>
              <Col md={9}>
                <Control.input name="discountRate" model=".discountRate" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                // value={this.state.uspaMemberNumber}
                // onChange={this.handleInputValue}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="llcPay" md={3}>llc extra Pay</Label>
              <Col md={9}>
                <Control.input name="llcPay" model=".llcPay" className="form-control"
                  mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                // value={this.state.uspaMemberNumber}
                // onChange={this.handleInputValue}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="chargeTo" md={3}>Charge To</Label>
              <Col md={9}>
                <AsyncSelect loadOptions={(newValue) => this.promiseOptions(newValue, this.state.jumperOptions)}
                  styles={customStyles} onInputChange={(val) => this.handleInputChange(val)}
                  defaultOptions={this.state.adminDefaultOptions} onChange={(val) => this.handleChargeSelect(val)} components={{ Option: this.Option }}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="noPayOrCharge" md={3}>NoPayOrCharge</Label>
              <Col md={9} style={{ marginTop: '7px' }}>
                <Control.checkbox name="noPayOrCharge" model=".noPayOrCharge"
                  mapProps={{ onChange: (props) => props.onChange }}
                  checked autoComplete="off" />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="isWaiverReviewed" md={3}>WaiverReviewed:</Label>
              <Col md={9} style={{ marginTop: '7px' }}>
                <Control.checkbox name="isWaiverReviewed" model=".isWaiverReviewed"
                  mapProps={{ onChange: (props) => props.onChange }}
                  checked autoComplete="off" />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="isPaperWaiver" md={3}>PaperWaiver:</Label>
              <Col md={9} style={{ marginTop: '7px' }}>
                <Control.checkbox name="isPaperWaiver" model=".isPaperWaiver"
                  mapProps={{ onChange: (props) => props.onChange }}
                  checked autoComplete="off" />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="multiManifest" md={3}>MultiManifest:</Label>
              <Col md={9} style={{ marginTop: '7px' }}>
                <Control.checkbox name="multiManifest" model=".multiManifest"
                  mapProps={{ onChange: (props) => props.onChange }} checked autoComplete="off" />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="isStaff" md={3}>staff:</Label>
              <Col md={9} style={{ marginTop: '7px' }}>
                <Control.checkbox name="isStaff" model=".isStaff"
                  mapProps={{ onChange: (props) => props.onChange }} checked autoComplete="off" />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="pilot" md={3}>Rating</Label>
            </Row>

            {this.renderCheckbox()}

            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 10 }}>
                <button className="btn btn-bg lightColor createButton" type="submit" >SUBMIT</button>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>
          </LocalForm>
        </AdminModal>
        <span style={{ position: 'absolute', left: '15px' }}>
          <button className="btn lightBlueButton jumper-add-button" type="submit" style={{ padding: '0.35rem 0.55rem', borderRadius: '100%' }} onClick={this.toggleModal}>
            <i className="fa fa-plus"></i>
          </button>
        </span>

        {/* <button className="btn customButton rightButton" style={{ borderRadius: '100px', width: "94%" }} onClick={this.toggleModal}>
              + CREATE NEW JUMPER
            </button>
        */}


        {/* <Modal isOpen={this.state.isError} toggle={this.errortoggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} className="error-modalHeader"></ModalHeader>
          <ModalBody>
            <p style={{ color: 'red', fontSize: '20px' }}> Invalid Jumper Information. Failed to Save</p>
          </ModalBody>

        </Modal> */}
      </React.Fragment>


    );
  }
}

export default CreateJumper
