import React, { Component } from 'react'
import {
    Button, Modal,
    ModalHeader, ModalBody,
    ModalFooter,
} from 'reactstrap';
import swal from 'sweetalert';

import reportStore from '../../../../stores/report-store';
import Api from '../../../../services/reservationapi';


class RefundModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            giftPurchases: null,
            giftcardGroup: null,
            isLoading: false,
            isDisable: false,
        }
        this.reportEvents = [
            { name: reportStore.getEventPrefix('giftpurchase_loaded'), func: this.handleSuccess }
        ];
    }
    componentDidMount() {
        this.reportEvents.forEach((evnt) => reportStore.on(evnt.name, evnt.func))

    }
    componentDidUpdate(prevProps, prevState) {
        let { couponDetail } = this.props;
        if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
            reportStore.getGiftPurchaseByGiftcardId(couponDetail._id)
        }
    }
    componentWillUnmount() {
        this.reportEvents.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
    }

    handleSuccess = (giftPurchases) => {
        let { giftcards } = this.props;
        // console.log("🚀 RefundModal.js giftPurchases", giftPurchases, giftcards)
        let giftcardGroup = giftcards.filter(ele => {
            let idx = giftPurchases[0].giftCouponIds.indexOf(ele._id);
            if (idx === -1) {
                return false;
            }
            else {
                return true;
            }
        })
        // console.log("giftcardGroup: ", giftcardGroup);
        this.setState({ giftPurchases: giftPurchases[0], giftcardGroup, isLoading: false })
    }
    processPaymentResponseTrans = (r, status, amount) => {
        // console.log("🚀 ~ RefundModal ~ amount", amount)
        // console.log("🚀 ~ RefundModal ~ r", r)
        let { giftPurchases } = this.state;
        if (r.data.status === "success") {
            //update giftcards and giftcard purchases
            let payload = {
                giftPurchaseId: giftPurchases._id,
                giftCardIds: giftPurchases.giftCouponIds,
                amount,
                status
            }
            reportStore.refundGiftcards(payload).then(r => {
                swal({
                    title: "Refund done!",
                    icon: "success",
                });
                this.props.toggleModal();
            })
                .catch(err => {
                    this.showErrorAlert();
                })
        }
        else {
            this.showErrorAlert();
        }
    }
    showErrorAlert = () => {
        swal({
            title: "Error",
            text: "Something failed, please try again",
            icon: "error",
            dangerMode: true,
        });
    }
    refundUsingTransactional = () => {
        let { giftPurchases } = this.state;
        var body = { "amount": parseFloat(giftPurchases.totalPaid) * 100 }

        var searchQuery = {
            transaction_id: {
                operator: "=",
                value: giftPurchases.transactionId
            }
        }
        Api.transGetTransaction(searchQuery)
            .then(res => {
                if (res.data.status == "success") {
                    //alert('res.data.data[0].status')
                    if (res.data.data[0].status === "settled") {
                        // console.log("setteled")
                        Api.transAuthorizeAndCaptureRefund(giftPurchases.transactionId, body).then((resRefund) => {
                            // console.log("🚀 ~~ Api.transAuthorizeAndCaptureRefund ~ resRefund", resRefund)

                            this.processPaymentResponseTrans(resRefund, 'refunded', giftPurchases.totalPaid)
                        });
                    } else if (res.data.data[0].status === "pending_settlement") {
                        Api.transAuthorizeAndCaptureVoid(giftPurchases.transactionId, body).then((resVoid) => {
                            // console.log("🚀 ~ Api.transAuthorizeAndCaptureVoid ~ resVoid", resVoid)
                            this.processPaymentResponseTrans(resVoid, 'voided', giftPurchases.totalPaid)
                        });
                    }
                }
            })
    }
    handleSubmit = () => {
        if (window.ENV?.processorInfo.processor == "transnational") {
            this.refundUsingTransactional();
        }
        this.setState({ isDisable: true })
    }
    returnCouponCodes = () => {
        let { giftcardGroup } = this.state;
        if (giftcardGroup && giftcardGroup.length > 0) {
            let components = giftcardGroup.map((ele, index) => {
                return <DetailLabel index={index + 1} label="Coupon Code: " value={ele.couponCode} />
            })
            return components;
        }
    }


    render() {
        let { isModalOpen, toggleModal } = this.props;

        let { isDisable, isLoading, giftPurchases } = this.state;
        // console.log("🚀 ~ file: RefundModal.js ~ line 124 ~ RefundModal ~ render ~ giftPurchases", giftPurchases)
        return (
            <Modal isOpen={isModalOpen} className="giftcard-resend-modal">
                <ModalHeader style={{ color: "black" }}>Refund Giftcard</ModalHeader>
                <ModalBody style={{ color: "black" }}>
                    {isLoading ? null :
                        <div>
                            {this.returnCouponCodes()}
                            <DetailLabel label={'Total Paid'} value={giftPurchases ? giftPurchases.totalPaid : "Not found"} type="price" />
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={isDisable} onClick={this.handleSubmit}>Refund</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default RefundModal;

function DetailLabel({ label, value, type, index }) {
    const returnValue = () => {
        if (value !== "Not found" && type === "price") {
            return value ? "$" + value.toFixed(2) : null;
        }
        return value;
    }
    return (
        <p className='detail-label ' style={{ color: "black" }}>
            {index ? <span>{index}. </span> : null}
            <strong>{label} : </strong><span>{returnValue()}</span>
        </p>
    )
}
