import React, { Component } from 'react'
import { Table } from 'reactstrap'

import CreateVideoPackage from '../modals/createVideoPackage';
import CreateTandemPackage from '../modals/createTandemPackage';

import RemoveItem from '../modals/removeItem';
export default class VideoPackages extends Component {
    renderPackages = (packageList, packageType) => {
        let { categories, checkForNegatives } = this.props;

        if (packageList != undefined) {
            return packageList.map((item, index) => {
                return (
                    <tr key={`planRow${index}`}>
                        <th scope="row" data-id={item._id}>{index + 1}</th>
                        <td>{item.item}</td>
                        {
                            packageType === "tandemPackage" ?
                                <td>{item.displayText}</td> : null
                        }
                        {
                            packageType === "tandemPackage" ?
                                <React.Fragment>
                                    <td>{checkForNegatives(item.price)}</td>
                                    <td>{checkForNegatives(item.weekendPrice)}</td>
                                </React.Fragment>
                                :
                                null
                        }
                        <td>{item.videoType}</td>
                        <td>{checkForNegatives(item.videoPrice)}</td>
                        {
                            packageType === "tandemPackage" ?
                                <td>{checkForNegatives(item.pay)}</td>
                                :
                                null
                        }
                        <td>{checkForNegatives(item.videoPay)}</td>
                        <td>{checkForNegatives(item.wristcamPay)}</td>
                        {
                            packageType === "tandemPackage" ?
                                <td>{item.discountCode || ""}</td>
                                :
                                null
                        }
                        {
                            packageType === "tandemPackage" ?
                                <td>{item.altitude}</td>
                                :
                                null
                        }
                        {
                            packageType === "tandemPackage" ?
                                <td>{item.calenderEvent}</td>
                                :
                                null
                        }
                        <td>{item.isBookable ? "yes" : "no"}</td>
                        {packageType === "videoPackage" ?
                            <td>{item.videoLink}</td> : null
                        }
                        <td >{item.description}</td>
                        <td>{item.isTaxable ? 'yes' : 'no'}</td>
                        {packageType === "tandemPackage" ?
                            <td >
                                <CreateTandemPackage isEdit={true} item={item} categories={categories} />&nbsp;&nbsp;
                                <RemoveItem item={item} />
                            </td>
                            : null
                        }
                        {packageType === "videoPackage" ?
                            <td >
                                <CreateVideoPackage isEdit={true} item={item} categories={categories} />&nbsp;&nbsp;
                                <RemoveItem item={item} />
                            </td>
                            : null
                        }
                    </tr>
                )
            })
        } else {
            return null;
        }
    }
    render() {
        let { categories, tandemPackages, videoPackages } = this.props;

        return (
            <React.Fragment>
                <Table>
                    <thead className="main-heading">
                        <tr>
                            <th><CreateVideoPackage categories={categories} /></th>
                            <th>Package</th>
                            <th>Video type</th>
                            <th>Video Price</th>
                            <th>Video pay</th>
                            <th>Wristcam pay</th>
                            <th>Bookable</th>
                            <th>Video Link</th>
                            <th>Description</th>
                            <th>Tax</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="priceHeading">
                            <td colSpan="3">Video Packages</td>
                        </tr>
                        {this.renderPackages(videoPackages, "videoPackage")}

                    </tbody>
                </Table>
                <Table >
                    <thead className="main-heading tandem">
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th><CreateTandemPackage categories={categories} /></th>
                            <th>Package</th>
                            <th>Display text</th>
                            <th>Price</th>
                            <th>Weekend price</th>
                            <th>Video type</th>
                            <th>Video Price</th>
                            <th>Instructor pay</th>
                            <th>Video pay</th>
                            <th>Wristcam pay</th>
                            <th>Discount Code</th>
                            <th>Altitude</th>
                            <th>Calender Event</th>
                            <th>Bookable</th>
                            <th>Description</th>
                            <th>Tax</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="priceHeading">
                            <td colSpan="3">Tandem Packages</td>
                        </tr>
                        {this.renderPackages(tandemPackages, "tandemPackage")}

                    </tbody>
                </Table>
            </React.Fragment>

        )
    }
}
