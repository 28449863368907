import React, { useEffect, useState } from 'react';
import reportStore from '../../../../stores/report-store';
import { TableRow, TableCell } from '@material-ui/core';

const SlotByPlane = (props) => {

    const [data, setData] = useState(null)

    useEffect(() => {
        let range = props?.range?.dateRange
        callApi(range)
    }, [props.range])

    const callApi = (range) => {
        let startDate = ''
        let endDate = ''
        if (range?.length > 0) {
            let sDate = new Date(range[0].startDate);
            sDate = sDate.setHours(0, 0, 0, 0);
            startDate = new Date(sDate);
            startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z')
            let eDate = new Date(range[0].endDate);
            eDate = eDate.setHours(23, 59, 59, 999);
            endDate = new Date(eDate);
            endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');
            reportStore.GetPlaneReport(startDate, endDate).then(res => {
                setData(res)
            })
        }
    }

    const renderData = (val) => {
        let headings = ['plane', 'loads', 'funJumpsSlots', 'hopNPopSlots', 'tandemSlots', 'studentSlots', 'outsideVideoSlots', 'total']
        return headings.map((key, index) => {
            let cellValue = 0
            if (key in val) {
                cellValue = val[key]
            }
            return <TableCell key={index} style={{ padding: '0.5rem 0em 0rem 2.8rem' }}>
                {cellValue}
            </TableCell>
        })
    }

    const renderRow = () => {
        return data && data?.map((v, i) => {
            return <TableRow key={i}>
                {renderData(v)}
            </TableRow>
        })

    }

    return <>
        {renderRow()}
    </>
}


export default React.memo(SlotByPlane);