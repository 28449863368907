import React, { Component } from 'react'
import { FormGroup, Label, Col, Input, Button } from 'reactstrap';
import authStore from '../../../../stores/auth-store';

export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadsPerPage: 1,
            columnsPerGroup: 2,
            isChanged: false,
        }
        this.authEvents = [
            { name: authStore.getEventPrefix('settings'), func: this.onSettingRes },
        ]
    }
    componentWillMount() {
        this.authEvents.forEach((evnt) => authStore.on(evnt.name, evnt.func));

    }
    componentDidMount() {
        authStore.getSettingsByUser()
    }
    componentWillUnmount() {
        this.authEvents.forEach((evnt) => authStore.removeListener(evnt.name, evnt.func));
    }
    onSettingRes = (data) => {
        console.log("🚀 ~ file: SettingMenu.js ~ line 28 ~ Settings ~ data", data)
        let { loadsPerPage, columnsPerGroup } = data.settings;
        console.log("loads:", loadsPerPage, columnsPerGroup)
        this.setState({ loadsPerPage, columnsPerGroup });
    }
    handleSelect = (e, type) => {
        let value = e.target.value;
        if (type === "loads") {
            this.setState({ loadsPerPage: Number(value), isChanged: true })
        }
        else {
            this.setState({ columnsPerGroup: Number(value), isChanged: true })
        }
    }
    updateSettings = () => {
        let { loadsPerPage, columnsPerGroup } = this.state;
        let payload = { loadsPerPage, columnsPerGroup };
        authStore.updateSettingsOfUser(payload);
        this.props.toggleModal(false)
    }
    render() {
        let { isChanged } = this.state;
        return (
            <div className="settings container">
                <Select label="Loads per page" value={this.state.loadsPerPage} max={6}
                    handleSelect={(e) => this.handleSelect(e, 'loads')}
                />
                <Select label="Column per load" value={this.state.columnsPerGroup} max={4}
                    handleSelect={(e) => this.handleSelect(e, 'column')}
                />
                <Button color="success" disabled={!isChanged} onClick={this.updateSettings}>Save</Button>
            </div>
        )
    }
}
function Select({ value, handleSelect, label, max }) {

    const returnOptionList = () => {
        let m = [];
        for (let i = 1; i <= max; i++) {
            m.push(<option>{i}</option>)
        }
        return m;
    }
    return (
        <FormGroup row>
            <Label for="exampleSelect" sm={8} className="px-0">{label}</Label>
            <Col sm={4} className="px-0">
                <Input type="select" name="select" value={value} onChange={handleSelect}>
                    {returnOptionList()}
                </Input>
            </Col>
        </FormGroup>
    )
}
