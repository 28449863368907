
/**
 * http://usejsdoc.org/
 */
import React, { Component, Fragment } from 'react';

import {
  Card, CardImg, CardText, CardBody,
  CardTitle, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Col, Row, Label
} from 'reactstrap';
import { Control, LocalForm, Errors, Field } from 'react-redux-form';
import reportStore from '../../../../stores/report-store';
import { ClipLoader } from 'react-spinners';

import { Table } from 'reactstrap';
import DonutLoader from '../../DonutLoader';

class DetailsModal extends Component {

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpen: false,
      errors: '',
      amount: '',
      newJumperId: '',
      data: [],
      headers: [],
      summary: {},
      Isdata: false,
      isloading: false,
    }

    this.reportEvent = [
      { name: reportStore.getEventPrefix('reportDetails'), func: this.handleReportDetails }
    ];
  }

  componentDidMount = () => {
    this.reportEvent.forEach((evnt) => reportStore.on(evnt.name, evnt.func));
  }

  componentWillUnmount = () => {
    this.reportEvent.forEach((evnt) => reportStore.removeListener(evnt.name, evnt.func));
  }

  handleReportDetails = (report) => {
    this.setState({ isloading: false, data: report.data || report, headers: report.headers, summary: report.summary, Isdata: true });
 
  }

  toggleModal(e) {
    const { jumperId } = this.props

    var startDate = '';
    var endDate = '';
    let sDate = new Date();
    sDate = sDate.setHours(0, 0, 0, 0);
    startDate = new Date(sDate);
    startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

    let eDate = new Date();
    eDate = eDate.setHours(23, 59, 59, 999);
    endDate = new Date(eDate);
    endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

    // reportStore.GetJumperLogs(startDate, endDate, jumperId)
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      data: [],
      Isdata: false,
      isloading: true
    }, (isModalOpen) => {
      if (this.props.reportType === "tandem")
        reportStore.getTandemDetails(startDate, endDate, this.props.subType)
      else if (this.props.reportType === "aff")
        reportStore.getAffDetails(startDate, endDate)
      else if (this.props.reportType === "funJumps")
        reportStore.getFunJumpDetails(startDate, endDate, this.props.subType)
      else if (this.props.reportType === "coach")
        reportStore.getCoachJumpDetails(startDate, endDate, this.props.subType)
      else if (this.props.reportType === "collections")
        reportStore.getCollectionDetails(startDate, endDate, this.props.subType);
      else if (this.props.reportType === 'countersales')
        reportStore.getCountersaleDetails(startDate, endDate, this.props.subType);
      else if (this.props.reportType === 'planeSlots')
        reportStore.GetPlaneReport(startDate, endDate, this.props.subType);
    });
  }

  componentWillReceiveProps(props) {
    // console.log("JumperId", this.props.jumperId);
    // const { jumperId } = this.props

    // if (jumperId) {
    //   this.setState({
    //     isloading: true,
    //     jumperId: jumperId,
    //     Isdata: false
    //   })
    // }
  }

  checkForNegatives = (price) => {

    let priceCell = (price < 0) ?
      {
        value: `($${Math.abs(price).toFixed(2)})`
      } :
      {
        value: `$${price.toFixed(2)}`
      }

    if (price < 0) {
      return <span style={{ color: 'lightsalmon' }}> {priceCell.value}</span>;
    }
    return priceCell.value;
  }


  // filterData = () => {
  //   let filterDataArray = [];
  //   let filterOutput = [];
  //   let jumperArray = [];

  //   if (this.state.Isdata === true) {
  //     this.filterDataArray = this.state.filterData;

  //     for (var item in this.filterDataArray) {
  //       filterOutput = [];
  //       var logArray = this.filterDataArray[item];
  //       for (var items in logArray) {
  //         var desc = logArray[items];
  //         let JumperInfo = [];

  //         if (desc.jumperIds !== undefined) {
  //           var otherJumper = desc.jumperIds;
  //           for (var oitem in otherJumper) {
  //             var name = otherJumper[oitem].jumperId.firstname + ' ' + otherJumper[oitem].jumperId.lastname;
  //             JumperInfo.push({ jumperName: name })
  //           }
  //         }

  //         var date = new Date(desc.createdAt);
  //         var localDate = '';
  //         var type = '';
  //         if (desc.type != undefined) {
  //           if (desc.type === "aff main") {
  //             type = desc.type + ' ' + desc.level;
  //           }
  //           else {
  //             type = desc.type;
  //           }
  //         }
  //         else {
  //           type = desc.jumpType;
  //         }

  //         if (desc.createdAt !== undefined) {
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }
  //         else {
  //           var date = new Date(desc.date);
  //           localDate = date.getFullYear() + "-" + this.formatDate(date.getMonth() + 1) + "-" + this.formatDate(date.getDate()) + " " + this.formatDate(date.getHours()) + ":" + this.formatDate(date.getMinutes());
  //         }

  //         filterOutput.push({ id: desc._id, type: type, price: desc.price, amount: desc.amount, date: localDate, JumpType: item, JumperArray: JumperInfo, loadNum: desc.loadNum, memo: desc.memo })

  //       }
  //       if (logArray.length > 0) {
  //         jumperArray.push({ label: item, jumperStatement: filterOutput })
  //       }
  //     }
  //   }
  //   return jumperArray;
  // }

  formatDate(input) {
    if (input.toString().length == 1) {
      return '0' + input;
    }
    else {
      return input;
    }
  }
  ConvertDate(inputDate) {
    if (inputDate.toString().length > 0) {
      var d = new Date(inputDate);

      var localTime = d.toLocaleTimeString();

      // var localDate = new Date(d.toLocaleDateString()),
      //   month = '' + (localDate.getMonth() + 1),
      //   day = '' + localDate.getDate(),
      //   year = localDate.getFullYear();

      var datestring = d.toLocaleDateString();
      var dateParts = datestring.split("/");
      var dateObject = new Date(dateParts[2], dateParts[1] - 1, +dateParts[0]),
        month = '' + (dateObject.getMonth() + 1),
        day = '' + dateObject.getDate(),
        year = dateObject.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-') + ' ' + localTime;
    }
    else {
      return inputDate;
    }
  }

  renderData = () => {
    return this.state?.data?.map((item, index) => {
      return (
        <Fragment>
          <tr key={`jumperlogsrow${index}`}>
            {
              Object.keys(item).map(key => {
                if (key.toLowerCase().includes("price") || key.toLowerCase().includes("pay") || key.toLowerCase().includes("amount"))
                  return <td> {this.checkForNegatives(item[key])} </td>
                if (key.toLowerCase().includes("date"))
                  return <td>{(new Date(item[key])).toLocaleDateString()} {(new Date(item[key])).toLocaleTimeString()}</td>
                return <td>{item[key]}</td>
              })
            }
          </tr>
        </Fragment>
      )
    })
  }

  renderSummary = () => {
    return (
      <Fragment>
        <tr>
          {
            this.state?.summary && Object.keys(this.state?.summary).map(key => {
              if (key.toLowerCase().includes("credit") || key.toLowerCase().includes("debit") || key.toLowerCase().includes("paid") || key.toLowerCase().includes("amount"))
                return <Fragment><td>{key}</td><td> {this.checkForNegatives(this.state.summary[key])} </td></Fragment>
              return <Fragment><td>{key}</td><td>{this.state.summary[key]}</td></Fragment>
            })
          }
        </tr>
      </Fragment>
    )
  }

  renderHeadings = () => {
    if (this.state?.headers?.length > 0) {
      return this.state.headers.map(header => {
        return <th>{header}</th>
      })
    } else if (this.state?.data[0]) {
      return Object.keys(this.state?.data[0]).map(keys => {
        return <th>{keys}</th>
      })
    }
  }

  render() {
    const { Isdata, filterData, isloading } = this.state;
    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <React.Fragment>
        <Modal id="modal-report" aria-labelledby="example-custom-modal-styling-title"
          isOpen={this.state.isModalOpen} style={{ zIndex: '10503434', right: '28%' }}>
          <ModalHeader tag="div" style={{ position: 'relative' }}>
            <h5>View Details</h5>
            <button className="close" onClick={(e) => { e.preventDefault(); this.toggleModal(); }} style={{ position: 'absolute', left: '11%', top: '14px', color: 'white' }}>x</button>
          </ModalHeader>
          <ModalBody>

            {/* <div className='complete-loading'> */}
            {/* <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={35}
                color={'#fff'}
                loading={this.state.isloading}
              /> */}
            <DonutLoader isLoading={this.state.isloading} />
            {/* </div> */}

            {
              this.state.isloading ? null
                :
                <Row className="form-group">
                  <Col md={12}>
                    <Table striped style={{ borderTopStyle: "hidden" }}>
                      <thead>
                        <tr>
                          {
                            this.renderHeadings()
                          }
                        </tr>
                      </thead>

                      <tbody>
                        {
                          this.renderData()
                        }
                        {
                          this.renderSummary()
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
            }
            <Row className="form-group m-t-2-rem">
              <Col md={{ size: 9 }}>
                <button onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link">CANCEL</button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <div className="d-flex align-items-start">
          <button className="btn pl-0 pr-1 lightBlueButton" onClick={this.toggleModal}><i className="fa fa-align-justify" /> </button>
          <p>{this.props.labelText}</p>
        </div>

      </React.Fragment>
    );
  }
}

export default DetailsModal



