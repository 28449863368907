import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DropTarget, DragSource } from 'react-dnd'
import CancelJump from './modals/cancelJump'
import EditableJumpType from './modals/editableJumpType'
import EditableAltitude from './modals/editableAltitude'
import EditableJumper from './modals/editableJumper'
import CreatableJumper from './modals/creatableJumper'
import EditablePrice from './modals/editablePrice'
import EditableStudentJumpType from './modals/editableAffJump'
import { Card, CardImg, CardText, CardBody, CardTitle, CardHeader, CardFooter, Row, Col, Button, } from 'reactstrap'
import { Badge } from 'reactstrap';
import RenderJumperTarget from './modals/RenderJumperTarget';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "../css/react-contextmenu.css";
import loadsStore from '../../stores/loads-store';
import itemStore from '../../stores/item-store';
import UpdateAmount from './modals/updateAmount';

const Types = {
    ITEM: 'JUMP'
};

const itemSource = {
    beginDrag(props) {
        const item = props
        return item
    },
    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }
        return props;
    }
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}


const attributes = {
    className: 'custom-root',
    disabledClassName: 'custom-disabled',
    dividerClassName: 'custom-divider',
    selectedClassName: 'custom-selected'
}


class RenderIadJump extends Component {

    constructor() {
        super();
        this.state = {
            menu: false,
            optionMenu: false
        }
    }

    handleCopyClick = (funJump, loadNum, uid) => {
        console.log('Copyclicked', funJump, loadNum);
        var payload = {};
        payload.jumperIds = funJump.jumpers.map(x => x._id);
        payload.jumpType = funJump.jumpType;
        payload.gearRentals = funJump.gearRentals;
        loadsStore.copied = true;
        loadsStore.copyValue = payload;
        // this.props.toggleToster(true, "copied")
        // var element = document.getElementById(uid);
        // element.style.display = "none";
        if (uid !== -1000) {
            var element = document.getElementById(uid);
            element.style.display = "none";
        }
    }
    handleCutClick = (funJump, loadNum, uid, jumpId) => {
        var payload = {};
        payload.jumperIds = funJump.jumpers.map(x => x._id);
        payload.jumpType = funJump.jumpType;
        payload.gearRentals = funJump.gearRentals;
        loadsStore.cut = true;
        loadsStore.cutValue = { loadNum, jumpId }
        loadsStore.copyValue = payload;
        // this.props.toggleToster(true, "Seleted")
        var element = document.getElementById(uid);
        // element.style.display = "none";

    }

    componentWillMount() {
        document.querySelector('body').addEventListener("click", this.handleEventClick, false);
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.querySelector('body').removeEventListener("click", this.handleEventClick, false);
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                optionMenu: false
            });
        }
    }


    handleEventClick = () => {
        var divsToHide = document.getElementsByClassName("copyPanel");
        for (var i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "none";
        }
    }

    handleClick = (e, uid, isCompleted, parentId) => {
        e.preventDefault();
        var divsToHide = document.getElementsByClassName("copyPanel");
        for (var i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "none";
        }

        if (isCompleted) {
            var element = document.getElementById(uid);

            element.style.display = "block";
            var container = document.getElementById(parentId);//document.querySelector("#contentContainer");

            var xPosition = e.clientX - container.getBoundingClientRect().left - (element.clientWidth / 2);
            var yPosition = (e.clientY - container.getBoundingClientRect().top - (element.clientHeight / 2));
            element.style.left = xPosition + "px";
            element.style.top = yPosition + "px";

            // setTimeout(() => {
            //     element.style.display = "none";
            // }, 10000)
        }
    }

    toggleOptions = () => {
        this.setState({
            optionMenu: !this.state.optionMenu
        })
    }


    // contextMenu = (e) => {
    //     e.preventDefault();
    //     addMenu.popup(e.clientX, e.clientY);
    // }

    // handlePasteClick = (loadNum) => {
    //     console.log('pasteclicked',loadNum);
    //     var Value= loadsStore.copyValue;
    //     loadsStore.createFunJump(loadNum, Value);
    // }

    handleMenuClick = (jumpId) => {
        document.getElementById(jumpId).click();
    }

    setAltitude = (loadNum, jumpId, altitude) => {
        loadsStore.updateFunJump(loadNum, jumpId, { altitude: altitude });
    }
    render() {
        // console.log("this.iscompleted",isCompleted);
        const { iadJump, loadNum, jumpId, disabled, instructorOptions, jumpType, jumperOptions, isDragging, connectDragSource, src, isCompleted, loadsToShow, columnsPerGroup, fontSize, isInline } = this.props;
        const opacity = isDragging ? 0 : 1;
        var uid = "fun" + loadNum + "_" + jumpId;
        var parentId = "funParent" + loadNum + "_" + jumpId;
        var menuHeight = this.state.menuHeight ? '158px' : '';
        // console.log("this.iscompleted",isCompleted);
        var altitudes = itemStore.getAltitudes();
        var altitudeMenu = altitudes.map(altitude => {
            return (
                <li onClick={() => this.setAltitude(loadNum, jumpId, altitude.name)}>
                    <span>set altitude to {altitude.name}</span>
                </li>
            );

        });
        // const moreThan4 = loadsToShow > 3 ? true : false;
        let moreThan4 = false
        let funjumpClass = 'funjumptype'
        if (loadsToShow > 3) {
            moreThan4 = true
            if (fontSize < 10 && columnsPerGroup === 1 || isInline) {
                moreThan4 = false
                funjumpClass = 'funjumptype removewidth'
            }
        } else {
            moreThan4 = false
            funjumpClass = 'funjumptype'
        }
        let styledObject = {}

        if (columnsPerGroup === 1) {
            styledObject = {
                display: 'flex',
                justifyContent: 'flex-start'
            }
        }
        if (isInline) {
            funjumpClass = 'funjumptype removewidth is-block'
        }
        return connectDragSource(
            <div style={{ opacity }}>
                {
                    <div className="react-contextmenu copyPanel" id={"fun" + loadNum + "_" + jumpId} style={{ position: 'relative', display: 'none', zIndex: 99999, marginBottom: '-36px', width: '68px', }}
                        onClick={() => this.handleCopyClick(iadJump, loadNum, uid)}
                    >
                        <span className="react-contextmenu-item" >Copy Jump</span>
                    </div>
                }

                {/* <ContextMenuTrigger id={"fun" + loadNum + "_" + jumpId}> */}
                <div onContextMenu={(e) => this.handleClick(e, uid, isCompleted, parentId)} id={parentId}>
                    {
                        this.state.optionMenu &&
                        <ul className={loadsToShow > 3 ? "menu-option-moreloads" : "menu-option"}>
                            {!this.props.isCompleted && <li>
                                <span onClick={() => this.handleMenuClick("update-Amount" + jumpId)}>Update Amount</span>
                            </li>}
                            {!this.props.isCompleted && <li>
                                <span style={{ color: 'red' }} onClick={() => this.handleMenuClick("delete-jump" + jumpId)}>Delete</span>
                            </li>}
                            <li>
                                <span onClick={() => this.handleCopyClick(iadJump, loadNum, -1000)}>Copy</span>
                            </li>
                            {!this.props.isCompleted && <li>
                                <span style={{ color: 'red' }} onClick={() => this.handleCutClick(iadJump, loadNum, uid, jumpId)}>Cut</span>
                            </li>}
                            {altitudeMenu}
                        </ul>

                    }
                    <Card className="jumpcard">
                        <CardBody style={{ padding: '0px !important', height: menuHeight }}>
                            <div className={moreThan4 ? "" : "row"} style={(this.props.load.isWaitlist && this.props.isCenter) ? { fontSize: 16 } : {}}>
                                {/* <Col xs="12" md="12" lg="3" className="jumptype" style={{ paddingRight: '5px', paddingLeft: '9px' }}>
                                    <EditableJumpType isToday={this.props.isToday} loadId={this.props.loadId} funJump={funJump} isCompleted={this.props.isCompleted} loadNum={loadNum} jumpId={jumpId} jumpType={funJump.jumpType} />
                                </Col> */}
                                {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} jumpType={"iadJump"} />}

                                <UpdateAmount showUpdate={this.state.showUpdate} isToday={this.props.isToday} loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"iadJump"}
                                    isCompleted={this.props.isCompleted} loadId={this.props.loadId} jumpData={iadJump} />

                                <div className={moreThan4 ? "funjumptypemoreload" : `${funjumpClass} col`}>
                                    IAD JUMP
                                    {iadJump.altitude}
                                </div>
                                <div className={moreThan4 ? "" : "cardmiddle col"}>
                                    <div>
                                        <ul className="coach-load">
                                            <li style={styledObject} >
                                                <EditableJumper
                                                    isToday={this.props.isToday}
                                                    type={"iad"}
                                                    label={"I"}
                                                    loadNum={loadNum}
                                                    loadId={this.props.loadId}
                                                    jumpId={jumpId}
                                                    iadJump={iadJump}
                                                    jumper={iadJump.instructor}
                                                    options={instructorOptions}
                                                    isCompleted={this.props.isCompleted}
                                                    price={iadJump.instructorPay}
                                                />
                                                <EditablePrice isCompleted={this.props.isCompleted} isToday={this.props.isToday} jump={iadJump} price={iadJump.instructorPay} type="iad" label="I" jumpId={jumpId} loadNum={loadNum} loadId={this.props.loadId} jumperId={iadJump.instructor._id} />
                                            </li>
                                            <li style={styledObject} >
                                                <CreatableJumper
                                                    isToday={this.props.isToday}
                                                    type={"iad"}
                                                    label={"S1"}
                                                    loadNum={loadNum}
                                                    jumpId={jumpId}
                                                    jumper={iadJump.student1}
                                                    isCompleted={this.props.isCompleted}
                                                    loadId={this.props.loadId}
                                                    iadJump={this.props.iadJump}
                                                    options={jumperOptions.map(option => { return { label: option.label, value: option.value._id } })}
                                                    price={iadJump.price1} />
                                                <EditablePrice isCompleted={this.props.isCompleted} isToday={this.props.isToday} jump={iadJump} price={iadJump.price1} type="iad" label="S1" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={iadJump.student1._id} />
                                                <EditableStudentJumpType
                                                    type={"iad"}
                                                    label={"S1"}
                                                    isToday={this.props.isToday}
                                                    loadNum={loadNum}
                                                    jumpId={jumpId}
                                                    isCompleted={this.props.isCompleted}
                                                    loadId={this.props.loadId}
                                                    jump={iadJump}
                                                    packageName={iadJump.student1PackageName}
                                                />
                                            </li>
                                            <li style={styledObject} >
                                                <CreatableJumper
                                                    isToday={this.props.isToday}
                                                    type={"iad"}
                                                    label={"S2"}
                                                    loadNum={loadNum}
                                                    jumpId={jumpId}
                                                    jumper={iadJump.student2}
                                                    isCompleted={this.props.isCompleted}
                                                    loadId={this.props.loadId}
                                                    iadJump={this.props.iadJump}
                                                    options={jumperOptions.map(option => { return { label: option.label, value: option.value._id } })}
                                                    price={iadJump.price2} />
                                                <EditablePrice isCompleted={this.props.isCompleted} isToday={this.props.isToday} jump={iadJump} price={iadJump.price2} type="iad" label="S2" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={iadJump.student2._id} />
                                                <EditableStudentJumpType
                                                    type={"iad"}
                                                    label={"S2"}
                                                    isToday={this.props.isToday}
                                                    loadNum={loadNum}
                                                    jumpId={jumpId}
                                                    isCompleted={this.props.isCompleted}
                                                    loadId={this.props.loadId}
                                                    jump={iadJump}
                                                    packageName={iadJump.student2PackageName}
                                                />
                                            </li>
                                            <li style={styledObject}>
                                                <CreatableJumper
                                                    isToday={this.props.isToday}
                                                    type={"iad"}
                                                    label={"S3"}
                                                    loadNum={loadNum}
                                                    jumpId={jumpId}
                                                    jumper={iadJump.student3}
                                                    isCompleted={this.props.isCompleted}
                                                    loadId={this.props.loadId}
                                                    iadJump={this.props.iadJump}
                                                    options={jumperOptions.map(option => { return { label: option.label, value: option.value._id } })}
                                                    price={iadJump.price3} />
                                                <EditablePrice isCompleted={this.props.isCompleted} isToday={this.props.isToday} jump={iadJump} price={iadJump.price3} type="iad" label="S3" jumpId={jumpId} loadNum={this.props.loadNum} loadId={this.props.loadId} jumperId={iadJump.student3._id} />
                                                <EditableStudentJumpType
                                                    type={"iad"}
                                                    label={"S3"}
                                                    isToday={this.props.isToday}
                                                    loadNum={loadNum}
                                                    jumpId={jumpId}
                                                    isCompleted={this.props.isCompleted}
                                                    loadId={this.props.loadId}
                                                    jump={iadJump}
                                                    packageName={iadJump.student3PackageName}
                                                />
                                            </li>
                                        </ul>


                                    </div>
                                    <div className={moreThan4 ? "right-menu-morethan4" : "right-menu"}
                                        // style={{ left: "94%" }}
                                        onClick={this.toggleOptions}>
                                        <span>
                                            <i className="fa fa-ellipsis-v"></i>
                                        </span>
                                    </div>

                                </div>
                                {/* <Col xs="12" md="2" lg="1" className="jumpClose" style={{ paddingLeft: '4px' }}>
                                    {!this.props.isCompleted && <CancelJump loadNum={loadNum} jumpId={jumpId} loadNum={loadNum} jumpType={"funJump"} />}
                                </Col> */}
                            </div>
                        </CardBody>
                    </Card>
                </div>


                {/* </ContextMenuTrigger>
                <ContextMenu id={"fun" + loadNum + "_" + jumpId}>
                    <MenuItem onClick={() => this.handleCopyClick(funJump,loadNum)} attributes={attributes}>
                        Copy Jump
                    </MenuItem>
                </ContextMenu> */}
            </div>
        )
    }
}


export default DragSource(Types.ITEM, itemSource, collect)(RenderIadJump)


