
import React, { Component } from 'react';
import {Col, Row, Label} from 'reactstrap';
import { Control, LocalForm, } from 'react-redux-form';
import ItemModal from './customModal';
import accountStore from '../../../stores/account-store';
import reportStore from '../../../stores/report-store';
import itemStore from '../../../stores/item-store';
import loadsStore from '../../../stores/loads-store';
import CustomCounter from './sub-comp/CustomCounter';
import swal from 'sweetalert';
class AddItem extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isModalOpen: false,
            Jumper_id: '',
            errors: '',
            amount: "",
            taxAmount: "",
            itemType: {},
            itemsSchedule: [],
            selectedItem: '',
            item: {},
            itemCount: 1,
            displayCount: false,
            tickets: '',
            affTickets: '',
            isChargeJumper: false,
            isChargeSuccess: false,
            paymentType: '',
            memo: '',
            isCardProcessing: false,
            isEdited: false
        }
        this.amount = '';
        this.child = React.createRef();
    }

    toggleModal(e) {
        var itemSchedule = itemStore.getCountersaleItems();
        if (itemSchedule.length > 0) {
            this.setState({
                itemsSchedule: itemSchedule
            })
        }
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            amount: "",
            taxAmount: "",
            tickets: "",
            affTickets: "",
            memo: '',
            displayCount: false,
            itemCount: 1,
            isChargeJumper: false,
        });
    }

    componentWillMount() {
        var itemSchedule = itemStore.getCountersaleItems();
        if (itemSchedule.length > 0) {
            this.setState({
                itemsSchedule: itemSchedule
            })
        }
        else {
            reportStore.loadPrices().then((res) => {
                this.setState({
                    itemsSchedule: res.itemsSchedule
                })
            })
        }
    }

    componentWillReceiveProps() {
        this.setState({
            Jumper_id: this.props.jumperId
        })
    }

    calculateTax(val) {
        let selectedItem = this.getSelectedItemDetail()
        let updatedTaxAmount = 0.00
        if (loadsStore.isTaxEnabled() && selectedItem.isTaxable) {
            let amount = val * loadsStore.getTaxRate() * .01
            updatedTaxAmount = isNaN(amount) ? 0.00 : amount
        }
        this.setState({
            amount: val,
            taxAmount: updatedTaxAmount.toFixed(2),
            isEdited: true
        })
    }

    handleChange = (e) => {
        if (e.target.name === 'amount') {
            this.calculateTax(e.target.value)
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    getSelectedItemDetail = () => {
        const { selectedItem, itemsSchedule } = this.state;
        var item = itemsSchedule.filter(x => x.item === selectedItem)[0];
        return item
    }

    handleCountChange = (value) => {
        var count = value;
        var item = this.getSelectedItemDetail()
        if (count > item.quantity && item.trackingQuantity) {
            count = item.quantity;
        }
        var price = 0;
        let ticketsCount = 0;
        var totalTaxAmount = 0;
        let tickets = 0;
        let affTickets = 0;
        if (item) {
            price = (!this.state.isEdited || count == 1) ? item.price.toFixed(2) : this.state.amount / this.state.itemCount;
            if(!isNaN(item.affTickets) && item.affTickets >0){
                ticketsCount = item.affTickets;
                affTickets = (count * ticketsCount);
            }
            else{
                ticketsCount = item.tickets;
                tickets = (count * ticketsCount);
            }
        }
        var totalAmount = (count * price).toFixed(2);

        if (loadsStore.isTaxEnabled() && item.isTaxable) {
            totalTaxAmount = count * price * loadsStore.getTaxRate() * .01;
        }
        this.setState({
            itemCount: count,
            amount: totalAmount,
            tickets: tickets,
            affTickets: affTickets,
            item: item,
            taxAmount: totalTaxAmount.toFixed(2)
        })
    }

    handleBlockChange = (e) => {
        alert('in here');
        const { item } = this.state;
        var count = e.target.value;
        if (e.target.name === "tickets") {
            item.tickets = Number(count);
        }
        else if (e.target.name === "afftickets") {
            item.affTickets = Number(count);
        }

        this.setState({
            item: item
        })
    }

    returnSlicedValue = (value) => {
        let index = value.indexOf('(');// because of extra remaining count in values
        if (index > 0) {
            value = value.slice(0, index - 1);
        }
        return value
    }

    handleSelect = (e) => {
        const { itemsSchedule } = this.state;
        let value = e.target.value;
        let item = itemsSchedule.filter(x => x.item === value)[0];
        let displayCount = true;
        console.log('vaule: ' + value)
        console.log("item: " + JSON.stringify(item, null, 2));
        // if (item.category !== "block") {
        //     displayCount = true;
        // }
        let price = 0;
        let taxAmount = 0;
        if (item) {
            price = item.price !== null ? item.price.toFixed(2) : undefined;

            if (loadsStore.isTaxEnabled() && item.isTaxable) {
                taxAmount = price * loadsStore.getTaxRate() * .01;
            }
        }
        let totalPrice = (price * 1).toFixed(2);
        this.setState({
            amount: totalPrice,
            taxAmount: taxAmount.toFixed(2),
            tickets: item?.tickets,
            affTickets: item?.affTickets,
            displayCount,
            selectedItem: value,
            item: item,
            itemCount: 1,
        })
    }


    renderItems = () => {
        var items = this.state.itemsSchedule;
        let blocksData = items.filter(i => i.category === 'block')
        let data = this.props.tickets ? blocksData : items
        data.sort(function (a, b) {
            var nameA = a.item.toUpperCase(); // ignore upper and lowercase
            var nameB = b.item.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
        return data.map((val, index) => {
            let remainingItem = val.quantity ? val.quantity : undefined;
            if (val.trackingQuantity) {
                return <option value={val.item} >{val.item + " (" + remainingItem + " -remaining)"}</option>
            } else {
                return <option value={val.item}>{val.item}</option>
            }
        })
    }

    async handleAddItem(values, isTandemStudent){
        var payload = {};
        var jumperId = this.props?.jumperId;
        let totalTickets = 0;
        const { amount, itemCount, item, tickets, affTickets, taxAmount } = this.state;

        if (amount !== undefined && amount != "") {
            var check = amount.match(/((\d+)(\.\d{2}))$/);
            if (check != null) {
                payload.item = item.item; // item name
                payload.price = amount;
                payload.category = item.category;
                if (item.tickets) {
                    payload.tickets = tickets;
                    totalTickets = totalTickets + item.tickets;
                }
                if (item.affTickets) {
                    payload.affTickets = affTickets;
                    totalTickets = totalTickets + item.affTickets;
                }
                if (item.coachTickets) {
                    payload.coachTickets = item.coachTickets;
                    totalTickets = totalTickets + item.coachTickets;
                }
                if (item.soloTickets) {
                    payload.soloTickets = item.soloTickets;
                    totalTickets = totalTickets + item.soloTickets;
                }
                if (item.expiryInDays) {
                    payload.expiryInDays = item.expiryInDays;
                }
                if (item.plane) {
                    payload.plane = item.plane;
                }
                if (item.forBoogie) {
                    payload.forBoogie = item.forBoogie;
                }
                if (item.isTaxable || Number(this.state.taxAmount)) {
                    payload.taxAmount = this.state.taxAmount
                }
                payload.count = itemCount;
                if (this.state.memo != "") {
                    payload.memo = this.state.memo;
                }

                const unitPrice=((this.state.amount/(totalTickets*this.state.itemCount)).toFixed(2));

                if (unitPrice) {
                    payload.unitPrice = unitPrice;
                }
                else {
                    payload.unitPrice = "NaN";
                }
                payload.adjustTandemPaid = this.props.adjustTandemPaid;
                payload.adjustVideoPaid = this.props.adjustVideoPaid;

                if (isTandemStudent || this.props.isTandemStudent) {
                    await accountStore.AddItemForTandemStudent(jumperId, payload)
                } else {
                     await accountStore.AddItem(jumperId, payload);
                }
                this.setState({isModalOpen:false});
            }
            else {
                this.setState({
                    errors: "Amount should be enter in two decimal places"
                })
                setTimeout(() => {
                    this.setState({ errors: "" });
                }, 2000);
            }
        }
        else {
            this.setState({
                errors: "This field is required"
            })
            setTimeout(() => {
                this.setState({ errors: "" });
            }, 2000);
        }
    }

    handleSubmit(values, isTandemStudent) {
        this.handleAddItem(values, isTandemStudent)
    }

    render() {
        const { displayCount} = this.state;
        let buttonClass = this.props.tandemStudentButton ? 'btn chargeTandemBtn' : 'btn customButton newBtn rightButton';
        let buttonText = this.props.tickets ? 'ADD TICKETS' : 'ADD ITEM';

        if(this.props.adjustTandemPaid || this.props.adjustVideoPaid){
            buttonClass = "fa fa-plus";
            buttonText  = ""
        }
        return (
            <React.Fragment>
                <ItemModal
                    isModalOpen={this.state.isModalOpen}
                    title={`Add Item`}>
                    <LocalForm onSubmit={(values) => this.handleAddItem(values, this.props.isTandemStudent)}>
                        <Row className="form-group">
                            <Label htmlFor="items" md={4}>Item Name :</Label>
                            <Col md={8}>
                                <Control.select model=".items" name="items"
                                    className="form-control" defaultValue="Select..."
                                    mapProps={{ onChange: (props) => props.onChange }}
                                    onChange={this.handleSelect}
                                    ref="items"
                                >
                                    <option>select...</option>
                                    {this.renderItems()}
                                </Control.select>
                            </Col>
                        </Row>
                        {this.state.item && this.state.item.category === "block" &&
                            <React.Fragment>
                                <Row className="form-group">
                                    <Label htmlFor="tickets" md={4}>Enter Tickets : </Label>
                                    <Col md={8}>
                                        <Control.input name="tickets" model=".tickets" className="form-control"
                                            mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                            value={this.state.tickets}       
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Label htmlFor="afftickets" md={4}>Enter AFF Tickets : </Label>
                                    <Col md={8}>
                                        <Control.input name="afftickets" model=".afftickets" className="form-control"
                                            mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                            value={this.state.affTickets}                                           
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        }
                        {
                            displayCount &&
                            <Row className="form-group">
                                <Label htmlFor="count" md={4}>Enter Count : </Label>
                                <Col md={8}>
                                    <CustomCounter
                                        setCounter={this.handleCountChange}
                                        count={this.state.itemCount}
                                    />
                                </Col>
                            </Row>
                        }
                        <Row className="form-group">
                            <Label htmlFor="memo" md={4}>Enter Memo : </Label>
                            <Col md={8}>
                                <Control.input name="memo" model=".memo" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                    value={this.state.memo}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="amount" md={4}>Enter Amount : </Label>
                            <Col md={8}>
                                <Control.input name="amount" model=".amount" className="form-control"
                                    mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                    value={this.state.amount}
                                    onChange={this.handleChange}
                                />
                                <span className="text-danger error-validation">{this.state.errors}</span>
                            </Col>
                        </Row>
                        {
                        loadsStore.isTaxEnabled()?
                        <React.Fragment>
                            <Row className="form-group">
                                <Label htmlFor="tax" md={4}>tax : </Label>
                                <Col md={8}>
                                    <Control.input name="taxAmount" model=".taxAmount" className="form-control"
                                        mapProps={{ onChange: (props) => props.onChange }} autoComplete="off"
                                        value={this.state.taxAmount}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger error-validation">{this.state.errors}</span>
                                </Col>
                            </Row>
                        </React.Fragment>
                         :null
                        }
                        <Row className="form-group m-t-2-rem">
                            <Col md={{ size: 12 }}>
                                {this.state.errors.amount === "" || this.state.errors.amount === undefined ?
                                    <button className="btn btn-bg lightColor createButton" type="submit" disabled={this.state.isCardProcessing} >SUBMIT</button>
                                    :
                                    <button className="btn btn-bg lightColor createButton" type="submit" disabled>SUBMIT</button>
                                }
                                <button type="submit" onClick={(e) => { e.preventDefault(); this.toggleModal(); }} className="btn btn-link" disabled={this.state.isCardProcessing} >CANCEL</button>
                            </Col>
                        </Row>
                    </LocalForm>
                </ItemModal>
                <span 
                    className={`${buttonClass}`}
                    onClick={this.toggleModal}>
                    {buttonText}
                </span>
            </React.Fragment>
        );
    }
}

export default AddItem