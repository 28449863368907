import { create } from 'apisauce';
import urlgetter from './getMainUrl';

const api = create({
	baseURL: urlgetter.getMainApiUrl()
});
const func = {};

func.getMonthlySlots = (date) => {
  console.log('getMonthlySlots scheduleapi');
  return api.get(`/timeslots/?startDay=${date.start}&endDay=${date.end}`)
}

func.blackoutSlot = (payload) => {
  return api.put(`/timeslots`, payload);
}

func.setFullSlot = (payload) => {
  return api.put(`/timeslots/setFull`, payload);
}

func.addemailtemplates = (payload) => {
console.log(payload,"hasgdchaghjcgfadhgj")
  return api.post(`/emailtemplates`, payload);
}

func.getemailtemplates = (payload) => {
  console.log(payload,"hasgdchjhgghjhaghjcgfadhgj")
    return api.get(`/emailtemplates`, payload);
  }

  func.getSingleEmail = (id) => {
      return api.get(`/emailtemplates/email/${id}`)
    }

  func.deleteemailtemplates = (payload) => {
    console.log(payload,"hasgdchaghjcgfadhgj")
      return api.delete(`/emailtemplates`, payload);
    }

  func.patchemailtemplates = (payload) => {
      console.log(payload,"hasgdchaghjcgfadhgj")
        return api.patch(`/emailtemplates`, payload);
      }

      func.addsmsTextTemplates = (payload) => {
        console.log(payload,"hhhhhhhhhhh")
          return api.post(`/smsTextTemplates`, payload);
        }
        
        func.getsmsTextTemplates = (payload) => {
          console.log(payload,"ljkjhfvfjhjgg")
            return api.get(`/smsTextTemplates`, payload);
          }
        
          func.deletesmsTextTemplates = (payload) => {
            console.log(payload,"sdfgvgvvh")
              return api.delete(`/smsTextTemplates`, payload);
            }
        
          func.patchsmsTextTemplates = (payload) => {
              console.log(payload,"hgvgvgvgrgrhgtghhhgghv")
                return api.patch(`/smsTextTemplates`, payload);
              }
          
  

//Create here

func.blackOutSeriesByDate = payload => {
  return api.post('/timeslots/blackoutseries', payload);
}
func.deleteSlotById = (payload) => {
  // console.log("🚀 ~ file: schedulerapi.js ~ line 16 ~ func.deleteSlotById ~ payload", payload)

  return api.post('/timeslots/deleteById', payload)
}
func.deleteSeriesByDate = payload => {
  return api.post('/timeslots/deleteseries', payload)
}

func.blackoutSlotId = (payload) => {
  return api.put(`/timeslots/blackoutById`, payload);
}

func.modifyTimeslot = (payload) => {
  return api.put(`/timeslots/modify`, payload);
}

func.getPassengersOnDate = (date) => {
  return api.get(`/reservations/?day=${date}`)
}

func.getLatestReservations = () => {
  return api.get(`/reservations/latest`)
}

func.getReservations = (payload) => {
  return api.get(`/reservations/filter`, payload)
}

func.getIncompleteReservation = () => {
  return api.get(`/reservations/Parked/getSavedReservation`)
}

//Calender Events Apis
func.createCalenderEvent = (payload) => {
  return api.post(`/reservations/calenderEvents`, payload)
}

func.getCalenderEvents = () => {
  return api.get(`/reservations/calenderEvents`)
}

func.deleteCalenderEvent = (item) => {
  return api.delete(`/reservations/calenderEvents/${item._id}`, item)
}

func.updateCalenderEvent = (payload) => {
  console.log("payload", payload)
  return api.put(`/reservations/calenderEvents/${payload._id}`, payload)
}

func.addJumperToReservations = (payload) => {
  return api.put(`/reservations/`, payload)
}

func.removeJumperFromReservations = (payload) => {
  return api.put(`/reservations/removejumper`, payload)
}

func.createEventSlot = (data) => {
  var payload = { ...data };
  // console.log(payload);
  api.setHeader('Authorization', window.ENV?.apiKey);

  return api.post(`/timeslots`, payload);
}

func.loadTandemStudents = () => {
  // console.log('in loadTandemStudents');
  return api.get('/tandemStudents');
}

func.addWalkinTandemStudent = (payload) => {
  var body = { firstname: payload.firstname, lastname: payload.lastname, walkin: true };
  return api.post('/tandemStudents', body);
}

func.loadTodayTandemStudents = () => {
  // console.log('in load today tandem students');
  let sDate = new Date();
  sDate = sDate.setHours(0, 0, 0, 0);
  let startDate = new Date(sDate);
  startDate = startDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

  let eDate = new Date();
  eDate = eDate.setHours(23, 59, 59, 999);
  let endDate = new Date(eDate);
  endDate = endDate.toISOString('yyyy-mm-ddThh:mm:ss.000Z');

  return api.get('/tandemStudents?start=' + startDate + '&end=' + endDate);
}

func.getWaivers = (studentId) => {
  console.log('service get waivers');
  return api.get(`/tandemStudents/${studentId}/getWaivers`)
}



export default func;
